//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types'
import { Button, Typography } from "@material-ui/core";
import { MuiPickersUtilsProvider, KeyboardTimePicker } from "@material-ui/pickers";
import MomentUtils from '@date-io/moment'
import CloseIcon from '@material-ui/icons/Close';
import AddCircleIcon from "@material-ui/icons/AddCircle";
import ScheduleIcon from '@material-ui/icons/Schedule';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import MethodSelection from "./Components/MethodSelection";
import ClientSelection from "./Components/ClientSelection";
import InputField from "../../../common/InputField";
import CustomDatePicker from "./Components/CustomDatePicker";
import KgpTeamSelection from "./Components/KgpTeamSelection";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useForm } from "react-hook-form";
import { getActivityMilestoneDatesPayload, milestoneDatesDefaultValues, removeSkipFields } from "../../utils";
import TabLayoutFooter from "../../../TabLayoutFooter";
import { formatDate } from "../../../../utils/date";

const MilestoneDates = forwardRef((props, ref) => {
  const { getActivityLogInfoTabData, handleSaveTabData, readOnly, setParentUpdatedAt } = props
  const [clientInterviews, setClientInterviews] = useState([])
  const [tabInfo, setTabInfo] = useState(null)
  const [currentValues, setCurrentValues] = useState(null)
  const { id, path, searchId, contactId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { register, reset, handleSubmit, getValues, watch, setValue, formState } = useForm({});
  const { dirtyFields } = formState
  const actions = [
    { label: 'Save & Close', className: 'primary-btn mr-3', onClick: () => { handleSave(true) } },
    { label: 'Save', className: 'primary-btn', onClick: () => { handleSave(false, false, true) } }
  ]

  useEffect(() => {
    register('initial_contact_date')
    register('interviews')
    register('schedule')
    register('offer_accepted_date');
    register('kg_interview_date1');
    register('kg_interview_date2');
    register('kg_interview_date3');
    register('kg_interview_from_time1');
    register('kg_interview_from_time2');
    register('kg_interview_from_time3');
    register('kg_interview_to_time1');
    register('kg_interview_to_time2');
    register('kg_interview_to_time3');
    register('kg_interview_method1')
    register('kg_interview_method2')
    register('kg_interview_method3')
    register('kg_interview_client1')
    register('kg_interview_client2')
    register('kg_interview_client3')
    register('created_at');
    register('engaged_date')
    register('outreach_date')
  }, [register])

  useEffect(() => {
    if (currentValues && Array.isArray(currentValues.interviews)) {
      setClientInterviews(currentValues.interviews)
    }
  }, [setClientInterviews, currentValues])

  const getActivityLogInfo = useCallback(async (hideLoader) => {
    const data = await getActivityLogInfoTabData(hideLoader, 'MILESTON_DATES_TAB')
    if (data) {
      setTabInfo(data)
    }
  }, [contactId, id, path, enqueueSnackbar])

  useEffect(() => {
    getActivityLogInfo();
  }, [getActivityLogInfo])

  useEffect(() => {
    let defaultValues = milestoneDatesDefaultValues(tabInfo);
    // if (defaultValues && isRichTextSaved) {
    //   defaultValues = { ...defaultValues, ...cleanupFormValues(getValues({ nest: true })) }
    // }
    if (defaultValues) {
      setCurrentValues(defaultValues)
      reset(defaultValues)
      setParentUpdatedAt(defaultValues.updated_at)
    }
  }, [tabInfo, reset, getValues])

  const handleSave = async (saveAndClose, skipMessage = false, reload) => {
    const formValues = getValues({ nest: true });
    let result

    const payload = await getActivityMilestoneDatesPayload(formValues, dirtyFields);
    if (payload && tabInfo.candidate) { payload.candidate.updated_at = tabInfo.candidate.updated_at }
    result = await handleSaveTabData(payload, saveAndClose, skipMessage, false, 0)
    if (result && reload) {
      await getActivityLogInfo();
    }
    if (!result) {
      handleSubmit(null)
    }
    return result
  }

  useImperativeHandle(ref, () => ({
    saveTabData: (saveAndClose, skipMessage = false, reload) => {
      return handleSave(saveAndClose, skipMessage, reload)
    },
    isDirty: () => {
      let fields = removeSkipFields([...dirtyFields])
      return fields.length > 0
    },
    formData: () => {
      return getValues({ nest: true })
    },
    reload: (hideLoader) => {
      getActivityLogInfo(hideLoader);
    }

  }));

  useEffect(() => {
    if (currentValues) {
      reset(currentValues)
    }
  }, [currentValues])


  const handleChange = (index, field, value) => {
    const currentClientInterviews = [...clientInterviews]
    currentClientInterviews[index][field] = value
    setClientInterviews(currentClientInterviews)
  }


  const addClientInterview = () => {
    setClientInterviews(prevState => ([...prevState, {}]))
  }

  const removeClientInterview = (index) => {
    let interviews = [...clientInterviews];
    interviews.splice(index, 1)
    setClientInterviews(interviews)
  }

  useEffect(() => {
    let interviews = clientInterviews;
    setValue("interviews", interviews);
  }, [clientInterviews, setValue])

  return currentValues && (
    <div>
      <div className='milestone-dates' style={{ width: '100%' }}>
        <Typography className='section-label'>KG Interview</Typography>
        <div className='d-flex'>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <CustomDatePicker
              value={formatDate(watch('kg_interview_date1')) || null}
              InputLabelProps={{ focused: true }}
              className='input-form-field input-field-old'
              label='Interview Date 1'
              style={{ width: "90%" }}
              format="DD/MM/YYYY"
              onChange={(date) => {
                setValue('kg_interview_date1', formatDate(date))
              }}
            />
          </MuiPickersUtilsProvider>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardTimePicker
              value={watch('kg_interview_from_time1') || null}
              InputLabelProps={{ focused: true }}
              className='input-form-field input-field-old'
              label='Interview From Time 1'
              style={{ width: "110%" }}
              keyboardIcon={<ScheduleIcon />}
              onChange={(Time) => {
                setValue('kg_interview_from_time1', Time)
              }}
            />
          </MuiPickersUtilsProvider>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardTimePicker
              value={watch('kg_interview_to_time1') || null}
              InputLabelProps={{ focused: true }}
              className='input-form-field input-field-old'
              label='Interview To Time 1'
              keyboardIcon={<ScheduleIcon />}
              onChange={(Time) => {
                setValue('kg_interview_to_time1', Time)
              }}
            />
          </MuiPickersUtilsProvider>
          <MethodSelection
            value={watch('kg_interview_method1') || null}
            InputLabelProps={{ focused: true }}
            className='input-form-field input-field-old'
            label={'Interview Method 1'}
            onChange={(e, data) => setValue('kg_interview_method1', data ? data.name : null)} />
          <KgpTeamSelection
            searchId={searchId}
            value={watch('kg_interview_client1') || null}
            className='input-form-field input-field-old'
            label='Interview Partner 1'
            InputLabelProps={{ focused: true }}
            onChange={(e, data) => setValue('kg_interview_client1', data)}
          />
        </div>
        <div className='d-flex'>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <CustomDatePicker
              value={formatDate(watch('kg_interview_date2')) || null}
              InputLabelProps={{ focused: true }}
              className='input-form-field input-field-old'
              label={`Interview Date 2`}
              style={{ width: "90%" }}
              format="DD/MM/YYYY"
              onChange={(date) => setValue('kg_interview_date2', formatDate(date))}
            />
          </MuiPickersUtilsProvider>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardTimePicker
              value={watch('kg_interview_from_time2') || null}
              InputLabelProps={{ focused: true }}
              className='input-form-field input-field-old'
              label='Interview From Time 2'
              keyboardIcon={<ScheduleIcon />}
              style={{ width: "110%" }}
              onChange={(Time) => {
                setValue('kg_interview_from_time2', Time)
              }}
            />
          </MuiPickersUtilsProvider>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardTimePicker
              value={watch('kg_interview_to_time2') || null}
              InputLabelProps={{ focused: true }}
              className='input-form-field input-field-old'
              label='Interview To Time 2'
              keyboardIcon={<ScheduleIcon />}
              onChange={(Time) => {
                setValue('kg_interview_to_time2', Time)
              }}
            />
          </MuiPickersUtilsProvider>
          <MethodSelection
            value={watch('kg_interview_method2') || null}
            InputLabelProps={{ focused: true }}
            className='input-form-field input-field-old'
            label={'Interview Method 2'}
            onChange={(e, data) => {
              setValue('kg_interview_method2', data ? data.name : null)
            }} />
          <KgpTeamSelection
            searchId={searchId}
            value={watch('kg_interview_client2') || null}
            className='input-form-field input-field-old'
            label='Interview Partner 2'
            InputLabelProps={{ focused: true }}
            onChange={(e, data) => setValue('kg_interview_client2', data)}
          />
        </div>
        <div className='d-flex'>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <CustomDatePicker
              value={formatDate(watch('kg_interview_date3')) || null}
              InputLabelProps={{ focused: true }}
              className='input-form-field input-field-old'
              format="DD/MM/YYYY"
              label={`Interview Date 3`}
              style={{ width: "90%" }}
              onChange={(date) => setValue('kg_interview_date3', formatDate(date))}
            />
          </MuiPickersUtilsProvider>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardTimePicker
              value={watch('kg_interview_from_time3') || null}
              InputLabelProps={{ focused: true }}
              className='input-form-field input-field-old'
              label='Interview From Time 3'
              keyboardIcon={<ScheduleIcon />}
              style={{ width: "110%" }}
              onChange={(Time) => {
                setValue('kg_interview_from_time3', Time)
              }}
            />
          </MuiPickersUtilsProvider>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <KeyboardTimePicker
              value={watch('kg_interview_to_time3') || null}
              InputLabelProps={{ focused: true }}
              className='input-form-field input-field-old'
              label='Interview To Time 3'
              keyboardIcon={<ScheduleIcon />}
              onChange={(Time) => {
                setValue('kg_interview_to_time3', Time)
              }}
            />
          </MuiPickersUtilsProvider>
          <MethodSelection
            value={watch('kg_interview_method3') || null}
            InputLabelProps={{ focused: true }}
            className='input-form-field input-field-old'
            label={'Interview Method 3'}
            onChange={(e, data) => { setValue('kg_interview_method3', data ? data.name : null) }} />
          <KgpTeamSelection
            searchId={searchId}
            value={watch('kg_interview_client3') || null}
            className='input-form-field input-field-old'
            label='Interview Partner 3'
            InputLabelProps={{ focused: true }}
            onChange={(e, data) => setValue('kg_interview_client3', data)} />
        </div>
        <Typography className='section-label'>Client Interview Dates</Typography>
        {
          clientInterviews.map((item, index) => {
            return (
              <div className="section-sub-content" key={index} style={{ marginTop: '10px' }}>
                <div className='d-flex'>
                  <Typography className="sub-section-label" component={"legend"}>Interview {index + 1}</Typography>
                  <CloseIcon onClick={() => removeClientInterview(index)} className="cursor-pointer" />
                </div>
                <div className='d-flex'>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <CustomDatePicker
                      value={formatDate(item.interview_date) || null}
                      InputLabelProps={{ focused: true }}
                      className='input-form-field input-field-old'
                      format="DD/MM/YYYY"
                      label='Date'
                      onChange={(date) => { handleChange(index, 'interview_date', formatDate(date), true) }}
                    />
                  </MuiPickersUtilsProvider>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardTimePicker
                      value={item.interview_from_time || null}
                      InputLabelProps={{ focused: true }}
                      className='input-form-field input-field-old'
                      label='Interview From Time'
                      keyboardIcon={<ScheduleIcon />}
                      onChange={(Time) => {
                        handleChange(index, 'interview_from_time', Time)
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardTimePicker
                      value={item.interview_to_time || null}
                      InputLabelProps={{ focused: true }}
                      className='input-form-field input-field-old'
                      label='Interview To Time'
                      keyboardIcon={<ScheduleIcon />}
                      onChange={(Time) => {
                        handleChange(index, 'interview_to_time', Time)
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  <MethodSelection
                    value={item.method || null}
                    InputLabelProps={{ focused: true }}
                    className='input-form-field input-field-old'
                    label={'Method'}
                    onChange={(e, data) => { handleChange(index, 'method', data ? data.name : null) }} />
                  <ClientSelection
                    searchId={searchId}
                    value={item.client || null}
                    className='input-form-field input-field-old w-50'
                    InputLabelProps={{ focused: true }}
                    label='Client Name'
                    onChange={(e, data) => { handleChange(index, 'client', data) }}
                  />
                </div>
                <InputField value={item.comments || ''} InputLabelProps={{ focused: true }} className='input-form-field input-field-old' label='Comments' onChange={(e) => { handleChange(index, 'comments', e.currentTarget.value) }} />
              </div>
            )
          })
        }
        <Button
          variant={"text"}
          size='large'
          style={{ marginTop: 10, marginBottom: 5 }}
          startIcon={<AddCircleIcon />}
          onClick={addClientInterview}
          color='primary'>
          Add Interview
        </Button>
        <Typography className='section-label'>Candidate Fit Date</Typography>

        <div className='d-flex'>
          <CustomDatePicker
            value={formatDate(watch('initial_contact_date')) || null}
            InputLabelProps={{ focused: true }}
            label='Initial Contact Date'
            className='input-form-field input-field-old'
            onChange={(date) => setValue('initial_contact_date', formatDate(date))}
          />
          <CustomDatePicker
            value={formatDate(watch('outreach_date')) || null}
            InputLabelProps={{ focused: true }}
            label='Outreach Date'
            className='input-form-field input-field-old'
            onChange={(date) => setValue('outreach_date', formatDate(date))}
          />
        </div>
        <div className='d-flex'>
          <CustomDatePicker
            value={formatDate(watch('engaged_date')) || null}
            InputLabelProps={{ focused: true }}
            label='Engaged Date'
            className='input-form-field input-field-old'
            onChange={(date) => setValue('engaged_date', formatDate(date))}
          />
          <CustomDatePicker
            disabled={true}
            value={formatDate(watch('date_added_to_search')) || null}
            InputLabelProps={{ focused: true }}
            label='Date Added to Search'
            className='input-form-field input-field-old'
          />
        </div>
        <div className='d-flex w-50'>
          <CustomDatePicker
            value={formatDate(watch('offer_accepted_date')) || null}
            InputLabelProps={{ focused: true }}
            label='Offer Accepted'
            className='input-form-field input-field-old'
            onChange={(date) => setValue('offer_accepted_date', formatDate(date))}
          />
        </div>
      </div >
      {!readOnly && <TabLayoutFooter actions={actions} />}
    </div>
  )
})

MilestoneDates.propTypes = {
  getActivityLogInfoTabData: PropTypes.func,
  handleSaveTabData: PropTypes.func,
  readOnly: PropTypes.bool,
  setParentUpdatedAt: PropTypes.func
}

export default MilestoneDates;
