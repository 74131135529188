//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import CloudUploadOutlined from "@material-ui/icons/CloudUploadOutlined";
import { withSnackbar } from "notistack";
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
//----------------------------------------------// Internal Imports // -------------------------------------------------
import { removeItemByIndex } from "./utils";
import { DELETE, SUCCESS, ERROR, API, GET } from "../../../../../services/constantService";
import { searchDocumentApi, dashboardDataApi } from "../../../../../services/ApiService";
import { unableMessage, successMessage } from "../../../../../services/MessageService";
import DocumentTypeSelection from "../../../../AddUser/components/DocumentTypeSelection";
import CloseIcon from '@material-ui/icons/Close'
import AddIcon from '@material-ui/icons/Add';
import Loader from '../../../../../components/common/Loader';
import { disallowFiles } from "../../../../../utils/common";
import ConfirmationPopup from "../../../../ConfirmationPopup";


const defaultValue = [{
  doctype_code: "",
  files: [],
  doc_desc: "",
  show_in_candidatesuite: false
}]
function SearchDocument(props) {
  const { register, setValue, currentValues, enqueueSnackbar, setSearch, unregister, reloadData } = props;
  const [list, setList] = useState(defaultValue);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([])
  const [isRemoved , setIsRemoved] = useState(false)
  const [removeIndex ,setRemoveIndex]=useState(null)
  const [removeItem ,setRemoveItem]=useState(null)

  useEffect(() => {
    getData();
  }, [])

  useEffect(() => {
    register('doc_desc');
    return () => {
      unregister('doc_desc');
    }
  }, [register, unregister])

  useEffect(() => {
    setValue('search_documents', list);
  }, [list])

  useEffect(() => {
    setList(currentValues.search_documents.length > 0 ? currentValues.search_documents : [{ ...defaultValue }])
    register('search_documents')
  }, [currentValues, register]);


  const getData = async () => {
    try {

      const url = `${API.product_setting}/document_type`
      const { status, data } = await dashboardDataApi(GET, url)
      if (status === 200) {
        let array = [];
        data.data.rows.map(data => array.push({ doctype_code: data.doctype_code, allowed_extension: data.allow_extensions }));
        setOptions(array);
      }
    } catch (err) {
      console.log('error in Fetch Token::', err)
    }

  }

  const doctype = (data, index, name) => {
    let allowedExt;

    if (data && data.doctype_code !== '') {
      options.forEach((item) => {
        if (item.doctype_code === data.doctype_code) {
          allowedExt = item.allowed_extension;
        }
      })
    }

    let existingSearchDocuments = [...list];
    if (data) {
      existingSearchDocuments[index] = { ...list[index], ["doctype_code"]: data.doctype_code, ["doc_desc"]: data.doctype_desc, ["allowed_extension"]: allowedExt , ["show_in_candidatesuite"] : false }
      // existingSearchDocuments[index]["doctype_code"] = data.doctype_code
      // existingSearchDocuments[index]["doc_desc"] = data.doctype_desc
      setList(existingSearchDocuments);
      setValue('doc_desc', data.doctype_desc)
    } else {
      const updatedList = [...list];
      updatedList[index] = { ...list[index], [name]: data, ["doctype_code"]: data }
      setList(updatedList)
    }
  }

  const deleteAttachment = async (index, id, name) => {
    setLoading(true);
    const sub_route = `documents/${id}`
    const { status, data } = await searchDocumentApi(DELETE, sub_route)
    if (status === 200) {
      const message = successMessage(`document(${name})`, "deleted")
      setSearch(prevState => ({ ...prevState, search_documents: removeItemByIndex(list, index) }))
      reloadData(true)
      enqueueSnackbar(message, { variant: SUCCESS })
      setIsRemoved(false)
    }
    else {
      const message = unableMessage(`LOE(${name})`, "delete")
      enqueueSnackbar(data?.message || message, { variant: ERROR })
    }
    setLoading(false);
  }



  const removeDocument = (index) => {
    setList(prevState => removeItemByIndex(prevState, index))
    setIsRemoved(false)
  }

  const onSelectCheckbox = (e, index) => {

    const isChecked = e.target.checked;
    let existingSearchDocuments = [...list];
    existingSearchDocuments = existingSearchDocuments.map((doc, i) => {
      if (i === index) {
        return { ...doc, show_in_candidatesuite: isChecked };
      }
      return doc;
    });
    setList(existingSearchDocuments)
  }

  const addSearchDocument = () => {
    const updatedList = [...list, defaultValue];
    setList(updatedList);
  }

  const handleUploadDocument = (e, field ,index) => {
    let existingSearchDocuments = [...list];
    if (e.target.files.length > 0) {
      // existingSearchDocuments[e.target.id][field] = e?.target?.files[0]
      // list.filter((item)=> item.file_name);
      if(!disallowFiles(e?.target?.value, enqueueSnackbar,list,index)) return
      //eslint-disable-next-line
      const result = list.map(({ file_name, file_id, ...rest }) => ({ ...rest }));
      // const result = list.map(({ ...rest }) => ({ ...rest }));
      existingSearchDocuments[e.target.id] = { ...result[e.target.id], [field]: e?.target?.files[0] }
      setList(existingSearchDocuments);
      setIsRemoved(false)

    }
  }

  const removeOnConfirmation=(index, item)=>{
    setIsRemoved(true);
    setRemoveIndex(index)
    setRemoveItem(item)
  }

  const handleConfirmPopupClose = () => {
    setIsRemoved(false)
  }
  
  return (
    <div className='section-content flex-grow-1' id='clientInformation'>
      <Loader show={loading} />
      <ExpansionPanel defaultExpanded={true} className="w-100" style={{ background: '#fff', color: '#fff' }}>
        {(isRemoved) &&
        <ConfirmationPopup
          message="Are you sure you want to remove this search document?"
          onClose={handleConfirmPopupClose}
          onConfirm={() => {removeDocument(removeIndex);
            if (removeItem.id) {
              deleteAttachment(removeIndex, removeItem.id, removeItem.file_name).then(null)}}}
        />
        }
        <ExpansionPanelSummary
          expandIcon={
            <ExpandMoreIcon style={{ color: "#fff" }} />
          }
          aria-controls="panel1a-content"
          style={{ background: '#2a364c' }}
        >
          <div >Upload & Attach Files</div>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className='d-flex flex-column p-0'>

          <div className='section-content team-information-table flex-grow-1 mb-2 p-0'>
            <div className="client-info-table d-flex flex-column">
              <div className="d-flex align-items-center table-main-header">
                <div className="col-0-5"></div>
                <div className="col-3 team-info-field">Document Type</div>
                <div className="col-2 team-info-field">Allowed Extensions</div>
                <div className="col-3-5 team-info-field">Upload</div>
                <div className="col-2 team-info-field">Action</div>
                <div className="col-1 team-info-field text-wrap">Show In Candidatesuite</div>
              </div>
              {list.length > 0 && list.map((item, index) =>

                <div className='d-flex pt-1 doc-upload team-info-field' key={index}>
                  <div className=" d-flex  col-0-5 align-items-center justify-content-center align-self-baseline team-info-field" style={{ color: "black", whiteSpace: "normal" }}>{index + 1} </div>
                  <div className=" d-flex   col-3 align-items-center  justify-content-center align-self-baseline team-info-field fontSize" >
                    <DocumentTypeSelection
                      variant="outlined"
                      className='add-search-input team-info-field'
                      InputLabelProps={{ focused: true }}
                      list={list}

                      value={item.doc_desc ? item.doc_desc : ""}
                      onChange={(e, data) => doctype(data, index, "doc_desc")}
                    /></div>
                  <div className=" d-flex  col-2 align-items-center justify-content-center align-self-baseline team-info-field" style={{ color: "black", whiteSpace: "normal" , wordBreak:'break-word'}}> {item.allowed_extension
                    ? item.allowed_extension
                    : '--'} </div>
                  <div className=" d-flex  col-3-5 align-items-center justify-content-center align-self-baseline team-info-field" style={{ color: "black", whiteSpace: "normal", wordBreak:'break-word' }}> {item.file_name ? item.file_name : item?.files?.name ? item?.files?.name : '--'} </div>

                  <div className="col-2  d-flex align-items-center justify-content-center align-self-baseline team-info-field">
                    <label htmlFor={index} className="mt-2">
                      <input type="file" name="searchDocument" id={index} style={{ display: 'none' }} onChange={(e) => { handleUploadDocument(e, 'files',index) }} />
                      <CloudUploadOutlined cursor='pointer'
                        fontSize='large' style={{ color: "black" }} /></label>

                    <div className="close-action" >
                      <span className="close-icon" >
                        <CloseIcon color='error' fontSize='large' onClick={() => {
                          removeOnConfirmation(index,item)
                        }} />
                      </span>
                    </div>

                  </div>
                  {
                    item.doc_desc === "Letter of Engagement" || item.doc_desc ==="Signed Letter of Engagement" ?
                      <div className="d-flex col-1 align-items-center justify-content-center align-self-baseline team-info-field"> <input
                        type="checkbox"
                        className="custom-checkbox"
                        checked={item.show_in_candidatesuite}
                        onChange={(e) => onSelectCheckbox(e, index)}
                        disabled = {true}
                      /></div>
                
                      :
                      <div className="d-flex col-1 align-items-center justify-content-center align-self-baseline team-info-field"> <input
                        type="checkbox"
                        className="custom-checkbox"
                        checked={item.show_in_candidatesuite}
                        onChange={(e) => onSelectCheckbox(e, index)}
               
                      /></div>
          

                  }
                </div>
              )}
            </div>
            <div className='text-start ml-2'> <Button
              variant={"text"}
              startIcon={<AddIcon style={{ color: "red" }} />}
              onClick={() => addSearchDocument()}
              className="button-text-capitalized"
              style={{ width: "175px" }}
            >
              <span style={{
                fontWeight: "600",
                fontSize: "13px",
                fontStyle: "italic",
              }}>Add File</span>
            </Button>
            </div>
          </div>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </div>

  )
}

SearchDocument.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  currentValues: PropTypes.object,
  setSearch: PropTypes.func,
  enqueueSnackbar: PropTypes.func,
  isEditing: PropTypes.bool,
  unregister: PropTypes.func,
  reloadData: PropTypes.func
}

export default withSnackbar(SearchDocument);
