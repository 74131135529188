//----------------------------------------------// In-built Imports // -------------------------------------------------
import React from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import CommonHeader from "./CommonHeader";
import './index.scss';

export default function Header(props) {
  const { company, jobNumber, jobTitle, confidential, companyDetails, children } = props
  return (
    <div className='d-flex justify-content-between align-items-center position-sticky search-header p-3'>
      <CommonHeader
        companyDetails={companyDetails}
        company={company}
        jobNumber={jobNumber}
        jobTitle={jobTitle}
        confidential={confidential}
      />
      {children}
    </div>
  )
}

Header.propTypes = {
  company: PropTypes.string,
  jobNumber: PropTypes.number,
  jobTitle: PropTypes.string,
  companyDetails: PropTypes.object,
  confidential: PropTypes.bool,
  children: PropTypes.node
}
