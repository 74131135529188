//----------------------------------------------// In-built Imports // -------------------------------------------------
import React from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { Link, withRouter } from 'react-router-dom';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import './index.css';


const SideNavItem = (props) => {
  const { info, location, isRegional } = props;

  return (
    isRegional && <Link to={info.route}>
      <div className={'side-nav-item d-flex' + (location.pathname + location.search === info.route ? ' active ' : '')}>
        {
          info.subItem === true ?
            < span style={{ marginLeft: "10px" }} className="side-nav-text">{info.label}</span> : <span className="side-nav-text">{info.label}</span>
        }
      </div>
    </Link >
  );
}

SideNavItem.propTypes = {
  info: PropTypes.object,
  location: PropTypes.object,
  isRegional: PropTypes.bool
}

export default withRouter(SideNavItem);