
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useEffect } from 'react'

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from 'prop-types'

//-----------------------------------------------------------// Internal Imports // ------------------------------

import JobFunctionSelection from "../../../jobFunctionSelection";
import IndustrySelection from "../../../IndustrySelection";

export default function IndustryAndJobFunctionView(props) {

  const { industries = [], jobFunctions = [], renderLabel, editingField, renderActions, register, setValue, watch } = props;

  useEffect(() => {
    register('industries');
    register('job_functions')
  }, [register])

  const renderIndustriesView = () => {
    return (
      <div className="d-flex contact-details-row">
        <div className="contact-details">
          {renderLabel('Industries', 'industries', editingField === 'industries')}
          {
            editingField === 'industries' ?
              <div className='d-flex'>
                <IndustrySelection
                  multiple
                  filterSelectedOptions
                  size='small'
                  isSearchIndustry={true}
                  label=''
                  className='contact-view-value'
                  value={watch('industries') || []}
                  onChange={(e, data) => {
                    setValue(`industries`, data ? data.map((item) => typeof item === 'string' ? item : item.name) : [])
                  }}
                  InputLabelProps={{ focused: true }}
                />
                {renderActions('industries', true)}
              </div>
              :
              <div className="contact-view-value">{industries && industries.length > 0 ? industries.join(',') : '--'}</div>
          }
        </div>
        <div className="contact-details">
          {renderLabel('Job Functions', 'job_functions', editingField === 'job_functions')}
          {
            editingField === 'job_functions' ?
              <div className='d-flex'>
                <JobFunctionSelection
                  multiple
                  filterSelectedOptions
                  size='small'
                  className='contact-view-value'
                  label=''
                  value={watch('job_functions') || []}
                  InputLabelProps={{ focused: true }}
                  onChange={(e, data) => {
                    setValue('job_functions', data ? data.map((item) => typeof item === 'string' ? item : item.name) : [])
                  }}
                />
                {renderActions('job_functions', true)}
              </div>
              :
              <div className="contact-view-value">{jobFunctions && jobFunctions.length > 0 ? jobFunctions.join(',') : '--'}</div>
          }
        </div>
      </div>
    )
  }

  return (
    <div className="section-container">
      <div className="section-header">Industry and Job Function</div>
      {renderIndustriesView()}
    </div>
  )
}

IndustryAndJobFunctionView.propTypes = {
  industries: PropTypes.array,
  jobFunctions: PropTypes.array,
  renderLabel: PropTypes.func,
  editingField: PropTypes.string,
  setValue: PropTypes.func,
  register: PropTypes.func,
  renderActions: PropTypes.func,
  watch: PropTypes.func,
  defaultValues: PropTypes.object,
}
