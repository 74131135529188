//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useState, useEffect, useRef } from 'react';
import * as yup from "yup";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import NumberFormat from 'react-number-format';
import { Helmet } from "react-helmet";
import { Controller, useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import { InputLabel, TextField } from "@material-ui/core";
import ReactPhoneInput from "react-phone-input-material-ui";
import { KeyboardDatePicker } from '@material-ui/pickers';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import CurrencySelection from '../CurrencySelection';
import StateSelection from '../StateSelection';
import ErrorPopup from "./ErrorPopup";
import Loader from "../common/Loader";
import OffLimitsSelection from "../OffLimitsSelection";
import { POST, SUCCESS, ERROR, THOUSAND_SEPARATOR, DECIMAL_SEPARATOR, EMAIL_REGEX } from '../../services/constantService';
import './index.scss';
import { companyDataApi } from '../../services/ApiService';
import { unableMessage, successMessage, validateMessage } from '../../services/MessageService';
import { separator } from '../../Containers/Commons/Utils';
import CompanySelection from "../CompanySelection";
import CountrySelection from '../CountrySelection';
import IndustrySelection from '../IndustrySelection';
import InputField from '../common/InputField';
import QuickAddComapanySelection from "../AddContact/Components/CompanySelection";
import { useDispatch, useSelector } from 'react-redux';
import { fetchCurrencyDetails } from '../../actions';
import RichTextPopup from '../RichTextPopup';
import { formatDate } from '../../utils/date';
import { customFormValidator, requireValidMessage } from '../../utils/common';
import CapitalStructure from '../ViewSearch/Components/SFPA/Components/Targets/CapitalStructure';

function AddCompany(props) {
  const { enqueueSnackbar } = props;
  let history = useHistory();

  const getDynamicKeys = () => {
    return {
      industries: new Date().getTime() + "i",
      parent_id: new Date().getTime() + "p",
      country: new Date().getTime() + "c",
      off_limits: new Date().getTime() + "o",
      vcpe: new Date().getTime() + "v"
    }
  }

  const [dynamicKeys, setDynamicKeys] = useState(getDynamicKeys());
  const [isCreateAndAddAnother, setIsCreateAndAddAnother] = useState(false);
  const [duplicateCompanies, setDuplicateCompanies] = useState(false);
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const defaultValues = {
    currency_iso_code: "USD"
  };

  const industryRef = useRef()
  const capitalStructureRef = useRef()
  const { register, unregister, handleSubmit, setValue, reset, watch, control, getValues } = useForm({ defaultValues });
  const country = watch('country');
  const currencyDetails = useSelector(state => state.commonReducer[watch('currency_iso_code')] ? state.commonReducer[watch('currency_iso_code')] : {})

  useEffect(() => {
    register({ name: 'parent_id' });
    register('country');
    register('state');
    register({ name: "off_limits" });
    register({ name: 'vc_pe' });
    register('currency_iso_code');
    register('revenue');
    register('capital_structure')
    return () => {
      unregister('parent_id');
      unregister("country");
      unregister("off_limits");
    };
  }, [register, unregister])

  useEffect(() => {
    const currencyHandler = async () => {
      try {
        await fetchCurrencyDetails(watch('currency_iso_code'), dispatch)
      }
      catch (e) {
        console.log("Error found in currencyHandler::", e);
      }
    }
    if (watch('currency_iso_code') && Object.keys(currencyDetails).length === 0) {
      currencyHandler()
    }

  }, [watch('currency_iso_code')])

  const validateEmail = (email) => {
    return email && !email.match(EMAIL_REGEX) ? false : true;
  }

  const CompanySchema = yup.object().shape({
    name: yup.string().required(),
    website: yup.string().required(),
    country: yup.string().required(),
    industries: yup.array().of(yup.object().shape({
      name: yup.string(),
    })).required(),
  });

  const addCompany = async (data) => {
    let isValid = await CompanySchema.isValid(data);
    if (!isValid) {
      let requiredField = [
        { fieldName: "name", label: "Name", type: String },
        { fieldName: "website", label: "Website", type: String },
        { fieldName: "country", label: "Country", type: String },
        { fieldName: "industries", label: "Industry", type: [] },
      ]
      let dirtyField = customFormValidator(data, requiredField)
      if (dirtyField) {
        const message = requireValidMessage(dirtyField)
        enqueueSnackbar(message, { variant: ERROR })
        return
      }
    }
    let validateEmailSyntax = true
    validateEmailSyntax = validateEmail(data.email_syntax)
    if (!validateEmailSyntax) {
      const message = validateMessage("Valid Email Syntax", "enter")
      enqueueSnackbar(message, { variant: ERROR })
      return
    }
    if (Array.isArray(data['vc_pe'])) {
      data.vc_pe = data.vc_pe.map(item => ({ vcpe_id: item.id }));
    } else {
      delete data.vc_pe
    }
    data["industries"] = Array.isArray(data.industries) ? data.industries.map(item => item.name) : []
    Object.keys(data).forEach((key) => {
      if (data[key] === '') {
        data[key] = null
      }
    });
    data["country"] = data.country ? data.country : null;
    setLoading(true);
    let sub_route = '';
    if (data.allowDuplicate) {
      sub_route = sub_route.concat('?allowDuplicate=true')
    }
    let { status, data: res } = await companyDataApi(POST, '', data, sub_route);
    if (status && status === 201) {
      handleClose()
      const message = successMessage("Company", "created")
      enqueueSnackbar(message, { variant: SUCCESS });
    } else if (status === 200) {
      setDuplicateCompanies(res.companies)
    } else {
      const message = unableMessage("company", "add")
      enqueueSnackbar(res.message || message, { variant: ERROR });
    }
    setLoading(false);
  }


  const resetFormFields = () => {
    reset(defaultValues);
    setDynamicKeys(getDynamicKeys())
  }

  const handleClose = () => {
    resetFormFields();

    if (!isCreateAndAddAnother) {
      history.push("/companies");
    }
  }

  const handleAddDuplicate = async () => {
    try {
      await addCompany({ ...getValues({ nest: true }), allowDuplicate: true })
      setDuplicateCompanies(false);
    }
    catch (e) {
      console.log("Error found in handleAddDuplicate::", e);
    }
  }

  const handleShowCompanies = () => {
    const idQuery = duplicateCompanies.map(company => `id=${company.id}`).join('&')
    window.open(`/companies?${idQuery}`)
  }

  const handleCancel = () => {
    setDuplicateCompanies(false);
  }

  const stateChange = (state) => {
    setValue('state', state);
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Add Company - KG Galaxy</title>
      </Helmet>
      {
        duplicateCompanies &&
        <ErrorPopup
          onCancel={handleCancel}
          onView={handleShowCompanies}
          onContinue={handleAddDuplicate} />
      }
      <Loader show={loading} className="company-loaders" />
      <div className="add-company ">
        <div className="add-company-header d-flex align-items-center">
          <Link to="/companies">
            <span className="header-back-icon">
              <ArrowBackIcon color="primary" />
            </span>
          </Link>
          <span className="header-text">Add New Company</span>
        </div>
        <form className="d-flex justify-content-center " id="add-company-form" onSubmit={handleSubmit(addCompany)}>
          <div className="d-flex flex-column align-center mt-3 w-100">
            <div className="d-flex justify-content-between">
              <InputField className="add-company-input" label={<label>Name<span className="MuiInputLabel-asterisk"> *</span></label>} placeholder="Name" name="name" ref={register()} />
              <InputField className="add-company-input" label={<label>Website<span className="MuiInputLabel-asterisk"> *</span></label>} placeholder="Website" name="website" ref={register()} />
              <div className='add-company-input'>
                <ReactPhoneInput
                  ref={register('phone')}
                  value={watch('phone') || ''}
                  onChange={(data, c, e, value) => {
                    if (data.length > 1) {
                      setValue('phone', value)
                    }
                    else {
                      setValue('phone', '')
                    }
                  }
                  }
                  enableSearch={true}
                  inputProps={{
                    label: "Phone Number"
                  }}
                  component={TextField}
                />
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <div className='add-company-input'>
                <ReactPhoneInput
                  ref={register('switchboard')}
                  value={watch('switchboard') || ''}
                  onChange={(data, c, e, value) => {
                    if (data.length > 1) {
                      setValue('switchboard', value)
                    }
                    else {
                      setValue('switchboard', '')
                    }
                  }
                  }
                  enableSearch={true}
                  inputProps={{
                    label: "Switchboard"
                  }}
                  component={TextField}
                />
              </div>
              <InputField className="add-company-input" label="Street" placeholder="Street" name="street" ref={register} />
              <InputField className="add-company-input" label="City" placeholder="City" name="city" ref={register} />
            </div>
            <div className="d-flex justify-content-between">
              <StateSelection
                value={watch('state') || null}
                country={country}
                InputLabelProps={{ focused: false }}
                className='add-company-input'
                register={register}
                onChange={(e, state) => {
                  stateChange(state);
                }}
              />
              <InputField className="add-company-input" label="zip code/postal code" placeholder="zip code/postal code" name="zip_code" ref={register} />
              <CountrySelection
                className="add-company-input"
                key={dynamicKeys["country"]}
                label={<label>Country<span className="MuiInputLabel-asterisk"> *</span></label>}
                onChange={(e, data) => {
                  stateChange('');
                  setValue("country", data?.name);
                }}
              />

            </div>
            <div className="d-flex align-items-start justify-content-start">
              <div className='add-company-input' style={{ width: "34%", marginTop: "20px" }}>
                <InputField className="add-company-input" label="LinkedIn URL" placeholder="LinkedIn URL" name="linkedin_url" ref={register} />
              </div>

              <div className='d-flex text-view-value p-0'>
                <div className='outlined-border rich-text-field transform '>
                  <InputLabel style={{ color: '#5c52c6' }} className="m-0 p-0" >Description</InputLabel>
                  <RichTextPopup
                    ref={register('description')}
                    label='Description'
                    value={watch('description')}
                    name='description'
                    InputLabelProps={{ focused: true }}
                    // placeholder={''}
                    variant="outlined"
                    onChange={(data) => {
                      setValue('description', data);
                    }}
                    title='Description'
                    spfaSkillFlag={true}
                  />
                </div>
              </div>

              {/* <div className="add-company-input" style={{ width: "33%" }}> */}
              <div className='d-flex text-view-value p-0'>
                <div className='outlined-border rich-text-field transform '>
                  <InputLabel style={{ color: '#5c52c6' }} className="p-0 m-0"  >Company News</InputLabel>
                  <RichTextPopup
                    ref={register('news')}
                    label=''
                    value={watch('news')}
                    InputLabelProps={{ focused: true }}
                    // placeholder={''}
                    variant="outlined"
                    name='news'
                    onChange={(data) => {
                      setValue('news', data);
                    }}
                    title='Company News'
                    spfaSkillFlag={true}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex align-items-start">
              <div className="d-flex flex-column left-inputs-container">
                <div className="d-flex align-items-end justify-content-between">
                  <Controller
                    name='industries'
                    key={dynamicKeys["industries"]}
                    as={
                      <IndustrySelection
                        multiple
                        size='small'
                        isSearchIndustry={true}
                        label={<label>Industry<span className="MuiInputLabel-asterisk"> *</span></label>}
                        placeholder="Industry"
                        inputRef={industryRef}
                        filterSelectedOptions={true}
                        className="add-company-input"
                      />}
                    defaultValue={[]}
                    control={control}
                    onFocus={() => { industryRef.current.focus() }}
                    onChange={([, data]) => data}
                  // rules={{ required: true, validate: d => d.length > 0 }}
                  />
                  <QuickAddComapanySelection
                    className="add-company-input"
                    label="Parent"
                    key={dynamicKeys["parent_id"]}
                    placeholder="Parent"
                    onChange={(e, data) => {
                      setValue("parent_id", data ? data.id : null);
                    }}
                  />
                </div>
                <div className="d-flex align-items-end justify-content-between">
                  <InputField className="add-company-input" label="Ticker Symbol" placeholder="Ticker Symbol" name="ticker_sym" ref={register} />
                  <InputField className="add-company-input" label="Employee Size" placeholder="Employee Size" name="employee_size" type="number" inputProps={{ min: 0 }} ref={register} />
                </div>
                <div className="d-flex align-items-end justify-content-between">
                  <CurrencySelection
                    label="Revenue Currency"
                    placeholder="Revenue Currency"
                    value={watch('currency_iso_code') || null}
                    className="add-company-input"
                    onChange={(e, data) => {
                      setValue("currency_iso_code", data ? data.code : null);
                    }}
                  />
                  <div className='add-company-input'>
                    {/* <InputLabel className='add-company-input'>Revenue</InputLabel> */}
                    <NumberFormat
                      customInput={TextField}
                      label='Revenue'
                      placeholder='Revenue'
                      // className='add-company-input'
                      // InputLabelProps={{ focused: true }}
                      hintText="Some placeholder"
                      style={{
                        border: 'none',
                        // borderBottom: '2px solid #a3a3a3',
                        width: '415px'
                      }}
                      value={watch("revenue")}
                      thousandSeparator={separator(watch('currency_iso_code'), THOUSAND_SEPARATOR)}
                      decimalSeparator={separator(watch('currency_iso_code'), DECIMAL_SEPARATOR)}
                      decimalScale={0}
                      isNumericString={true}
                      prefix={currencyDetails ? (currencyDetails.prefix ? currencyDetails.currency_icon : '') : ''}
                      suffix={currencyDetails ? (currencyDetails.prefix === false ? currencyDetails.currency_icon : '') : ''}
                      onValueChange={(values) => {
                        let { value } = values;
                        setValue("revenue", value < 0 ? 0 : value);
                      }}
                    />
                  </div>
                </div>
                <div className="d-flex align-items-end justify-content-between">
                  <InputField className="add-company-input" label="Email Syntax" placeholder="Email Syntax" name="email_syntax" ref={register} />
                  <OffLimitsSelection
                    className="add-company-input"
                    onChange={(e, data) => {
                      setValue("off_limits", data ? data.id : null);
                    }}
                    key={dynamicKeys["off_limits"]}
                  />
                </div>
                <div className="d-flex align-items-start justify-content-between">
                  <div className='d-flex text-view-value p-0 w-100'>
                    <div className='outlined-border rich-text-field transform '>
                      <InputLabel style={{ color: '#5c52c6' }} className="p-0 m-0" >Off Limits Reason</InputLabel>
                      <RichTextPopup
                        ref={register('off_limits_reason')}
                        label=''
                        value={watch('off_limits_reason')}
                        name='off_limits_reason'
                        onChange={(data) => {
                          setValue('off_limits_reason', data);
                        }}
                        title='Off Limits Reason'
                        spfaSkillFlag={true}
                      />
                    </div>
                  </div>
                  <KeyboardDatePicker
                    className="add-company-input"
                    //disableToolbar
                    ref={register({ name: `off_limits_expiration` })}
                    name="off_limits_expiration"
                    autoOk
                    label="Off Limits Expiration Date"
                    variant="inline"
                    format="dd/MM/yyyy"
                    margin="normal"
                    value={formatDate(watch('off_limits_expiration')) || null}
                    placeholder="Off Limits Expiration Date"
                    onChange={(date) => { setValue('off_limits_expiration', formatDate(date)); }}
                  />
                </div>
              </div>
              <div className="d-flex flex-column justify-content-between vc_pe_container">
                <CompanySelection
                  className="add-company-input"
                  multiple
                  InputLabelProps={{ focused: true }}
                  key={dynamicKeys.vcpe}
                  size={'small'}
                  label={'VC PE'}
                  filterSelectedOptions={true}
                  onChange={(e, data) => { setValue('vc_pe', data) }}
                />
                <CapitalStructure
                  name='capital_structure'
                  // multiple
                  label='Capital Structure'
                  placeholder=""
                  inputRef={capitalStructureRef}
                  filterSelectedOptions={true}
                  className="company-details-value update-company-input add-company-input"
                  onChange={(e, data) => {
                    setValue("capital_structure", data?.name ? data.name : null);
                  }}
                  defaultValue={null}
                />
              </div>
            </div>
            <div className="add-company-actions-btns d-flex justify-content-end">
              <Link to="/companies">
                <button className="secondary-btn" type="button">Cancel</button>
              </Link>
              <div className="d-flex flex-row-reverse">
                <input className="primary-btn" onClick={() => { setIsCreateAndAddAnother(false); }} type="submit" value="Create" />
                <input className="secondary-btn-border mr-3" onClick={() => { setIsCreateAndAddAnother(true); }} type="submit" value="Create & add another" />
              </div>
            </div>
          </div>
        </form>
      </div>

    </React.Fragment>
  )
}

AddCompany.propTypes = {
  enqueueSnackbar: PropTypes.func
}
export default withSnackbar(AddCompany);
