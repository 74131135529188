//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from "react";

//----------------------------------------------// External Imports // -------------------------------------------------0
import PropTypes from "prop-types";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";

//----------------------------------------------// Internal Imports // -------------------------------------------------

import { fetchPickList } from "../../../actions";
import { capitalize } from "../../../utils/string";

function RoleSelection(props) {
  const {
    className = 'w-100',
    label,
    InputLabelProps,
    required,
    placeholder = "Role",
    ...rest
  } = props
  const [options, setOptions] = useState([])

  const roles = useSelector(state => state.commonReducer.roles)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!roles) {
      dispatch(fetchPickList('user-roles', 'roles'))
    } else {
      let array = [];
      roles.map(data => array.push(data.name));
      setOptions(array);
    }
  }, [roles, dispatch])

  return (
    <Autocomplete
      {...rest}
      className={className}
      getOptionLabel={option => capitalize(option) }
      options={options}
      renderInput={(params) => (
        <TextField
          {...params}
          required={required}
          InputLabelProps={InputLabelProps}
          label={label}
          placeholder={placeholder}
        />
      )}
    />
  )
}

RoleSelection.propTypes = {
  required: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  InputLabelProps: PropTypes.object
}

export default RoleSelection;
