import React, { useEffect, useRef, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import { withSnackbar } from "notistack";
import CloseIcon from '@material-ui/icons/Close'
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { Link, useHistory } from 'react-router-dom'
import { Helmet } from "react-helmet";
import EditIcon from "@material-ui/icons/Edit";
import InfoIcon from '@material-ui/icons/Info';
import DoneIcon from "@material-ui/icons/Done";
import Launch from "@material-ui/icons/Launch";

//----------------------------------------------// Internal Imports // -------------------------------------------------
import '../AddBD/index.scss';
import { cleanup, BDSchema } from '../AddBD/utils';
import { POST, SUCCESS, ERROR, PUT, BD_PENDING_STATUS_MESSAGE } from '../../services/constantService';
import { bdDataApi, searchDataApi } from '../../services/ApiService';
import { successMessage, unableMessage, validateMessage, VALIDATION_MESSAGE } from '../../services/MessageService';
import { customFormValidator, requireValidMessage } from '../../utils/common';
import { useDispatch, useSelector } from 'react-redux';
import { fetchUserList } from '../../actions';
import Loader from '../common/Loader';
import ConfirmationPopup from '../ConfirmationPopup';
import { checkForError } from '../ViewSearch/utils';
import TabLayoutWithSteppers from '../TabLayoutWithSteppers';
import BasicDetailsView from './BasicDetailsView';
import BillingDetails from './BiilingDetailsBd/index'
import KGPTeamInfoView from '../ViewSearch/Components/KGPTeamInfo/View';
import { InputLabel, Tooltip } from '@material-ui/core';


// export const defaultValues = {
//   vc_pe: [],
//   client_team: [],
//   currency: "USD",
//   stage: 'BD',
//   retainers: [
//     { field: 'Retainer 1' },
//     { field: 'Retainer 2' },
//     { field: 'Retainer 3' },
//     { field: 'Final Retainer' },
//     { field: 'Contingent Fee' },
//     { field: 'Monthly Fee' }
//   ],
//   notes: [{}],
//   partners: [],
//   recruiters: [],
//   researchers: [],
//   eas: [],
//   estimated_percentage_bases: [{}, {}],
//   indirect_fees_searches: [{}, {}],
//   term_type: 'Milestone'
// }

function TabPanel(props) {
  const { children, value, index } = props;
  return (
    value === index && <React.Fragment>
      {children}
    </React.Fragment>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


const EditBdPopup = (props) => {

  const { enqueueSnackbar, closePopup, bd, editForm, defaultValues, setTabValue, tabValue = 0, currencyDetails } = props;
  const [currentValues, setCurrentValues] = useState({ ...defaultValues })
  const { setValue, watch, register, control, getValues, reset } = useForm({
    ...defaultValues
  });
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const companyRef = useRef();
  const userRef = useRef();
  const [options, setOptions] = useState([]);
  const users = useSelector(state => state.rootReducer.users)
  const dispatch = useDispatch()
  const [tabValueOfBusinessDev, setTabValueOfBusinessDev] = useState(0);
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [editingField, setEditingField] = useState('');

  //const [hoveredField, setHoveredField] = useState('');

  useEffect(() => {
    register("projected_start_date");
    register({ name: 'company' })
    register('country');
    register({ name: 'stage' })
    register('brand')
    register('job_number')
    register('job_title')
    register('client_team')
    register('billing_notes')
    register('currency')
    register('estimated_percentage_bases')
    register('indirect_fees_searches')
    register('probability')
    register('bd_status')
    register('fee_percentage')
    register('estimated_percentage_base')
    register('estimated_indirect_total_fee')
    register('estimated_revenue')
  }, [register]);

  const handleTabValue = (value) => {
    setTabValueOfBusinessDev(value)
  }

  const validateKgInfo = (formData) => {
    let isError = false;
    if (formData.partners && formData.partners.length > 0) {
      const fields = {
        origination_credit: 30,
        selling_credit: 20,
        execution_credit: 50
      }
      isError = checkForError(formData.partners, fields);
    }
    if (!isError && formData.recruiters && formData.recruiters.length > 0) {
      const fields = {
        execution_credit: 100
      }
      isError = checkForError(formData.recruiters, fields);
    }
    if (!isError && formData.researchers && formData.researchers.length > 0) {
      const fields = {
        execution_credit: 100
      }
      isError = checkForError(formData.researchers, fields);
    }
    if (!isError && formData.eas && formData.eas.length > 0) {
      const fields = {
        execution_credit: 100
      }
      isError = checkForError(formData.eas, fields);
    }
    if (isError) {
      const message = validateMessage('valid number', 'enter')
      enqueueSnackbar(message, { variant: ERROR });
      return false
    }
    return true;
  }

  const addBD = async (busDev) => {
    const isValid = await BDSchema.isValid(busDev)
    if (!isValid) {
      // let requiredField = [
      //   { fieldName: "company", label: "Company", type: {} },
      //   { fieldName: "job_title", label: "Job Title", type: String },
      //   //{ fieldName: "partners", label: "Partners", type: [] },
      //   { fieldName: "projected_fee", label: "Projected Fee", type: String },
      //   { fieldName: "probability", label: "Probability", type: String },
      //   { fieldName: "brand", label: "Brand", type: String },
      //   { fieldName: "stage", label: "Stage", type: String },
      // ]
      let dirtyField = customFormValidator(busDev)
      if (dirtyField) {
        const message = requireValidMessage(dirtyField)
        enqueueSnackbar(message, { variant: ERROR })
        return
      }
    }

    const isValidKgInfo = validateKgInfo(busDev)
    if (!isValidKgInfo) return;

    setIsLoading(true)
    const payload = cleanup(busDev);
    let { status, data: res } = payload.id ? await searchDataApi(PUT, payload.id, payload) : await bdDataApi(POST, '', payload);
    if (status && status === 201) {
      const jobNumber = res.result.job_number;
      setCurrentValues(prevState => ({ ...prevState, job_number: jobNumber, id: res.result.id }))
      const message = successMessage("Business Development", "created")
      enqueueSnackbar(message, { variant: SUCCESS });
    } else if (status && status === 200) {
      const message = successMessage("Business Development", "updated")
      enqueueSnackbar(message, { variant: SUCCESS });
    } else {
      const message = unableMessage("Business Development", `${payload.id ? 'update' : 'create'}`)
      enqueueSnackbar(res.message || message, { variant: ERROR });
      return false
    }
    setIsLoading(false)
    return true
  };

  const handleClose = () => {
    history.push('/searches/my-searches');
  };

  const handleCancel = () => {
    setShowConfirmationPopup(true)
  }

  const handleChange = async (event, newValue, oldValue) => {

    try {
      console.log(event, newValue, oldValue);
      const formValues = { ...currentValues, ...getValues({ nest: true }), }
      reset({ ...formValues })
      setCurrentValues(formValues);
      const result = await addBD(formValues)
      return result;
    } catch (e) {
      console.log("Error found in handleChange::", e);
    }
  };

  useEffect(() => {
    if (!users) {
      dispatch(fetchUserList('users'))
    }
    else {
      setOptions(users);
    }
  }, [users, dispatch])

  useEffect(() => {
    reset({ ...currentValues })
  }, [reset, currentValues])

  const handleConfirmPopup = async () => {
    setShowConfirmationPopup(false);
    handleClose();
    // setIsLoading(true)
    // const { status } = await searchDataApi(DELETE, currentValues.id);
    // if (status === 200) {
    //   const message = successMessage("deleted", "Business Development")
    //   enqueueSnackbar(message, { variant: SUCCESS })
    //   handleClose();
    // } else {
    //   const message = unableMessage("Business Development", "delete")
    //   enqueueSnackbar(message, { variant: ERROR })
    // }
    // setIsLoading(false)
  }
  const updateField = () => {

  }

  const editFormValue = () => {
    setTabValue(tabValueOfBusinessDev)
    editForm(true)
  }

  // const handleMouseEnter = (field) => {
  //   setHoveredField(field)
  // }

  // const handleMouseLeave = () => {
  //   setHoveredField('')
  // }
  const handleCancelPopup = async () => {
    setShowConfirmationPopup(false)
  }
  // const renderEditIcon = (field) => {
  //   return !(editingField === field) &&
  //     <EditIcon className='ml-2' onClick={() => { setEditingField(field); }} fontSize='inherit' color='secondary' />
  // }
  const renderLabel = (label, field, required = false, tooltip = false, message) => {

    return <InputLabel required={required} className="contact-view-label mr-2 cursor-pointer" >
      {label}
      {tooltip &&
        <span className='m-1'>
          <Tooltip title={message}><InfoIcon style={{ fontSize: '1rem' }} /></Tooltip>
        </span>}
    </InputLabel>
  }
  const renderActions = (field, required = false) => {
    if (editingField === field) {
      return (
        <>
          <span className="action-icon" onClick={() => updateField(field, required)}>
            <DoneIcon cursor='pointer' fontSize="inherit" color='primary' />
          </span>
          <span className="action-icon" onClick={() => setEditingField('')}>
            <CloseIcon cursor='pointer' fontSize="inherit" />
          </span>
        </>
      )
    }
    return null
  }
  return (
    <>
      <Helmet>
        <title>Add BD - KG Galaxy</title>
      </Helmet>
      <div className="w-100 h-100 ">
        <Loader show={isLoading} />
        {showConfirmationPopup &&
          <ConfirmationPopup
            header={VALIDATION_MESSAGE.delete_bd_search}
            onConfirm={handleConfirmPopup}
            onCancel={handleCancelPopup}
            onClose={handleClose}
            cancelText='Cancel'
            confirmText='Ok'
          />
        }
        <div className="d-flex add-bd-header align-items-center justify-content-between">
          {/* <div></div> */}
          <div className="add-bd-header-name">Opportunity# {defaultValues.job_number ? defaultValues.job_number : "--"}</div>
          <div className="bd-close-icon" onClick={closePopup}>
            <CloseIcon fontSize="inherit" />
          </div>
        </div>
        <div className='add-bd-content d-flex flex-column'>
          <TabLayoutWithSteppers
            navItems={['Basic Information', 'Team Info', 'Billing Details']}
            tabChange={handleChange}
            onCancel={handleCancel}
            onClose={handleClose}
            statusMessage={BD_PENDING_STATUS_MESSAGE}
            fieldItem="ViewBd"
            handleTabValue={handleTabValue}
            showTab={tabValue}
            bd={defaultValues}
            steppersProps={{ completed: true }}
          >
            <BasicDetailsView
              register={register}
              setValue={setValue}
              companyRef={companyRef}
              userRef={userRef}
              watch={watch}
              control={control}
              currentValues={currentValues}
              defaultValues={defaultValues}
              currencyDetails={currencyDetails}
            />
            <KGPTeamInfoView
              register={register}
              setValue={setValue}
              userRef={userRef}
              watch={watch}
              control={control}
              currentValues={defaultValues}
              option={options}
              defaultStyle={{}}
              renderLabel={renderLabel}
              renderActions={renderActions}
              editingField={editingField}
            />
            <BillingDetails
              register={register}
              setValue={setValue}
              watch={watch}
              userRef={userRef}
              currentValues={currentValues}
              renderLabel={renderLabel}
              renderActions={renderActions}
              editingField={editingField}
              defaultValues={defaultValues}
              currencyDetails={currencyDetails}
            />
          </TabLayoutWithSteppers>
        </div>
        <div className="bd-view-footer d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center action-edit" onClick={editFormValue}>
            <span className="section-icon">
              <EditIcon fontSize="inherit" />
            </span>
            <span className='edit-details-button'>Edit Details</span>
          </div>
          <Link to={`/searches/${bd?.id}/active-candidates`} target="_blank" className="d-flex text-decoration-none align-items-center action-edit">
            <span className="section-icon">
              <Launch fontSize="inherit" />
            </span>
            <span className='edit-details-button'>View Full Record</span>
          </Link>
        </div>
      </div>
    </>
  )
}
EditBdPopup.propTypes = {
  bd: PropTypes.object,
  enqueueSnackbar: PropTypes.func,
  closePopup: PropTypes.func,
  editForm: PropTypes.func,
  defaultValues: PropTypes.object,
  setTabValue: PropTypes.number,
  tabValue: PropTypes.number,
  currencyDetails: PropTypes.object
};
export default withSnackbar(EditBdPopup)