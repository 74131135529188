//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useState, useEffect } from 'react'

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CircularProgress } from "@material-ui/core";

//----------------------------------------------// Internal Imports // -------------------------------------------------
import httpService from "../../services/httpService";
import InputField from '../common/InputField';

function CountrySelection(props) {

  const {
    label = "Location",
    placeholder = "Location",
    required = false,
    inputRef,
    InputLabelProps,
    disabled = false,
    existingCountry,
    variant='standard',
    ...rest
  } = props;

  const [options, setOptions] = useState([]);
  const [value, setValue] = useState("");
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (value.length < 1) {
      setOptions([]);
      setLoading(false);
      return;
    }
    setLoading(true);
    httpService
      .get("/api/picklists/countries", { name: value })
      .then((response) => {
        const { status, data } = response;
        if (status === 200 && data && data.data) {
          if(existingCountry){
            let newArray = data.data.filter(item=> item.name !== existingCountry.find((it)=> it === item.name))
            setOptions(newArray);
          } else {
            setOptions(data.data);
          }
        }
        setLoading(false);
      });
  }, [value]);

  return (
    <Autocomplete
      {...rest}
      options={options}
      filterOptions={(options) => options}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option
        }
        return option.name
      }}
      onInputChange={(e, val, reason) => {
        if (reason === "input") {
          setValue(val);
        }
      }}
      disabled={disabled}
      renderInput={(params) => (
        <InputField
          {...params}
          ref={inputRef}
          variant={variant}
          InputLabelProps={InputLabelProps}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading ? (
                  <CircularProgress color="primary" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          required={required}
          label={label}
          placeholder={placeholder}
        />
      )}
    />
  )
}

CountrySelection.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  InputLabelProps: PropTypes.object,
  inputRef: PropTypes.object,
  disabled: PropTypes.bool,
  existingCountry: PropTypes.array,
  variant: PropTypes.string
}

export default CountrySelection;

/* import React, { useState, useEffect } from 'react'
import InputField from '../common/InputField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import { fetchPickList } from "../../actions";

function CountrySelection(props) {

  const {
    label = "Country",
    placeholder = "Country",
    required = false,
    inputRef,
    InputLabelProps,
    disabled = false,
    ...rest
  } = props;

  const [options, setOptions] = useState([]);
  const countries = useSelector(state => state.commonReducer.countries)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!countries) {
      dispatch(fetchPickList('countries'))
    } else {
      setOptions(countries);
    }
  }, [countries, dispatch])

  return (
    <Autocomplete
      {...rest}
      options={options}
      disabled={disabled}
      getOptionSelected={(option, value) => {
        if (!value) {
          return false;
        }
        if (typeof value === 'string') {
          return option.name === value;
        }
        return option.name === value.name
      }}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option
        }
        return option.name
      }}

      renderInput={(params) => (
        <InputField
          {...params}
          ref={inputRef}
          variant="standard"
          InputLabelProps={InputLabelProps}
          required={required}
          label={label}
          placeholder={placeholder}
        />
      )}
    />
  )
}

CountrySelection.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  InputLabelProps: PropTypes.object,
  inputRef: PropTypes.object,
  disabled: PropTypes.bool
}

export default CountrySelection; */
