//-----------------------------------------------------------// In-built Imports // ------------------------------
import React, { useEffect,useState } from "react";

//-----------------------------------------------------------// External Imports // ------------------------------
import PropTypes from "prop-types";
import Popup from "reactjs-popup";
import CloseIcon from "@material-ui/icons/Close";
import { Typography } from "@material-ui/core";
import { useSnackbar } from 'notistack';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import DecisionMatrixChart from "../../../components/DecisionMatrixChart";
import { ERROR, GET } from "../../../services/constantService";
import { searchDataApi, sfpaDecisionApi } from "../../../services/ApiService";
import { range } from "../../../utils/common";
import Loader from "../../../components/common/Loader";

import {  VALIDATION_MESSAGE } from "../../../services/MessageService";

const DecisionSelection = (props) => {
  const { open, handelClose, searchId, heliaVersion, initialCoords } = props
  const [inputValue, setInputValue] = useState({})
  const [chartData, setChartData] = useState({})
  const [candidateData, setCandidateData] = useState([])
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar();
  const fetchDecisionData = async () => {
    try {
      const { status, data } = await sfpaDecisionApi(GET, searchId, {}, 'get-all');
      if (status && status === 200) {
        if (data) {
          let inputValues = {}
          data.map((elem, index) => {
            elem.sfpa_decision_questions.map((item, i) => {
              const arrayValue = range(item.low_value_number, item.high_value_number, item.step_size)
              item.rangeOfValue = arrayValue
              let itemValues = {}
              if (index === 0) {
                itemValues[`item_${i + 1}`] = item.search_sfpa_decisions && item.search_sfpa_decisions.answer_value ? item.search_sfpa_decisions.answer_value : 0
              } else if (index === 1) {
                const totalLength = data[index - 1].sfpa_decision_questions.length
                itemValues[`item_${totalLength + i + 1}`] = item.search_sfpa_decisions && item.search_sfpa_decisions.answer_value ? item.search_sfpa_decisions.answer_value : 0
              }
              inputValues = { ...inputValues, ...itemValues }
            })
          })
          setInputValue(inputValues)
        }
      }
    } catch (error) {
      console.log("error", error)
    }
  }

  useEffect(() => {
    const item = {
      input_values: inputValue,
      scatter_data: candidateData,
      initialCoords, 
    }
    setChartData(item)
  }, [inputValue, candidateData])
  useEffect(() => {
    fetchDecisionData()
  }, [])
  useEffect(() => {
    const fetchCandidateData = async () => {
      setLoading(true)
      try {
        const { status, data } = await searchDataApi(GET, searchId, {}, `candidate-decision-metrics?helia_version=${heliaVersion}`);
        if (status === 200) {
          if (data.data && data.data.length) {
            let candidate = []
            data.data.map((elem) => {
              let candidateData = {}
              candidateData = {
                x: elem.coordinates.x,
                y: elem.coordinates.y,
                label: `${elem.inputs.first_name} ${elem.inputs.last_name}`
              }
              candidate.push(candidateData)
            })
            setCandidateData(candidate)
            setLoading(false)
          }else{
            setLoading(false)
          }
        }else {
          setLoading(false)
          enqueueSnackbar(VALIDATION_MESSAGE.candidate_chart_data, { variant: ERROR });
        }
      } catch (error) {
        console.log("error", error)

      }
    }
    fetchCandidateData()
  }, [])
  return (
    <Popup
      className={"decision-selection-popup"}
      open={open}
      onClose={handelClose}
      closeOnEscape={false}
      closeOnDocumentClick={false}
      style={{ height: "900px" }}>
      <Loader show={loading} />
      <div className='h-100 overflow-auto'>
        <div className="quick-add-header" style={{ background: '#2a364c', color: '#fff' }}>
          <Typography className='ml-4'>Decision Matrix</Typography>
          <CloseIcon className="cursor-pointer" onClick={handelClose} />
        </div>
        <DecisionMatrixChart item={chartData} />
      </div>
    </Popup>
  )
}
DecisionSelection.propTypes = {
  open: PropTypes.bool,
  handelClose: PropTypes.func,
  searchId: PropTypes.string,
  heliaVersion: PropTypes.string,
  initialCoords: PropTypes.string
};
export default DecisionSelection