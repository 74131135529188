
//-----------------------------------------------------------// In-built Imports // ------------------------------
import React from "react";

//-----------------------------------------------------------// External Imports // ------------------------------
import PropTypes from "prop-types";

//-----------------------------------------------------------// Internal Imports // ------------------------------
import PopoverStickOnHover from "./PopoverStickOnHover";
import RichTextPopup from "../RichTextPopup";

const RichTextBoxPopover = (props) => {
  const { children, value, placement="right" } = props;

  const renderPopoverContent = () => {
    return (
      <div style={{ padding: '10px', maxHeight: '400px', maxWidth: "400px", overflow: 'auto' }}>
        <RichTextPopup
          setOpen={false}
          value={value}
        />
      </div>
    );
  };

  return (
    value ?
      <PopoverStickOnHover
        component={renderPopoverContent()}
        grid={true}
        placement={placement}
        onMouseEnter={() => { }}
        delay={500}
      >
        {children}
      </PopoverStickOnHover >
      : null
  );
};

RichTextBoxPopover.propTypes = {
  children: PropTypes.node,
  value: PropTypes.string,
  placement: PropTypes.string,
};

export default RichTextBoxPopover;
