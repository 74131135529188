
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from "react";

//-----------------------------------------------------------// Internal Imports // ------------------------------

import Searches from "./Searches";

export default function KGPSearchesList() {
  return (
    <Searches
      searchType="kgsearches"
      columnStateKey="kgSearchesColumns"
      title="KG Searches"
      activeSearches
    />
  );
}
