//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { fetchPickList } from "../../../../../actions";

function SearchTypeSelection(props) {
  const {
    className = 'w-100',
    label = "Search Type",
    placeholder = 'Search Type',
    required = false,
    InputLabelProps,
    disabled = false,
    variant = "standard",
    ...rest
  } = props
  const [options, setOptions] = useState([])

  const searchTypes = useSelector(state => state.commonReducer.searchTypes)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!searchTypes) {
      dispatch(fetchPickList('searchtypes', 'searchTypes'))
    } else {
      // remove 'Product One'from list
      const options = searchTypes.filter(ele => ele.name !== 'Product One')
      setOptions(options);
    }
  }, [searchTypes, dispatch])

  return (
    <Autocomplete
      {...rest}
      className={className}
      disabled={disabled}
      options={options}
      getOptionSelected={(option, value) => {
        if (typeof value === 'string') {
          return option.name === value;
        }
        return option.name === value.name
      }}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option
        }
        return option.name
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          required={required}
          InputLabelProps={InputLabelProps}
          placeholder={placeholder}
          label={label}
          variant={variant}
        />
      )}
    />
  )
}

SearchTypeSelection.propTypes = {
  register: PropTypes.func,
  className: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  InputLabelProps: PropTypes.object,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  variant: PropTypes.string
}

export default SearchTypeSelection;
