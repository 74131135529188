//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { Button, Checkbox, FormControlLabel, Typography } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from "@material-ui/core/styles";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";

//----------------------------------------------// Internal Imports // -------------------------------------------------
import RichTextPopup from "../../../RichTextPopup";
import { getActivityStrengthsAndIssuesTabPayload, removeSkipFields, strengthsAndIssuesTabDefaultValues } from "../../utils";
import TabLayoutFooter from "../../../TabLayoutFooter";
import { cleanupFormValues } from "../../../../utils/common";

const useStyles = makeStyles(() => ({
  label: {
    fontSize: 14,
    color: '#686A7E'
  },
  root: {
    paddingLeft: 10,
    paddingBottom: 10
  }
}))

// eslint-disable-next-line react/display-name
const StrengthsAndPotentialIssues = forwardRef((props, ref) => {
  const { setLoading, autoSaveContactFields, updateContactInfo, saveStatus, getActivityLogInfoTabData, handleSaveTabData, readOnly, isRichTextSaved } = props;
  const classes = useStyles()
  const [strengths, setStrengths] = useState([]);
  const [issues, setIssues] = useState([])
  const [tabInfo, setTabInfo] = useState(null)
  const [currentValues, setCurrentValues] = useState(null)
  const [formDirtyFields, setFormDirtyFields] = useState([])
  const { id, path, contactId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { register, reset, handleSubmit, getValues, setValue, formState, watch } = useForm({});
  const { dirtyFields } = formState
  const actions = [
    { label: 'Save & Close', className: 'primary-btn mr-3', onClick: () => { handleSave(true) } },
    { label: 'Save', className: 'primary-btn', onClick: () => { handleSave(false, false, true) } }
  ]


  const getActivityLogInfo = useCallback(async (hideLoader) => {
    const data = await getActivityLogInfoTabData(hideLoader, 'STRENGTH_AND_ISSUE_TAB')
    if (data) {
      setTabInfo(data)
    }
  }, [contactId, id, path, enqueueSnackbar])

  useEffect(() => {
    getActivityLogInfo();
  }, [getActivityLogInfo])

  useEffect(() => {
    let defaultValues = strengthsAndIssuesTabDefaultValues(tabInfo);
    if (defaultValues && isRichTextSaved) {
      defaultValues = { ...defaultValues, ...cleanupFormValues(getValues({ nest: true })) }
      const forcedDirtyFields = removeSkipFields([...dirtyFields, ...formDirtyFields])
      setFormDirtyFields(forcedDirtyFields);
    }

    if (defaultValues) {
      setCurrentValues(defaultValues)
      reset(defaultValues)
    }
  }, [tabInfo, reset, getValues])

  const handleSave = async (saveAndClose, skipMessage = false, reload) => {
    const formValues = getValues({ nest: true });
    let result

    const newDirtyFields = [...dirtyFields, ...formDirtyFields];
    const payload = await getActivityStrengthsAndIssuesTabPayload(formValues, newDirtyFields);
    result = await handleSaveTabData(payload, saveAndClose, skipMessage, false, 0)
    if (result && reload) {
      setFormDirtyFields([]);
      await getActivityLogInfo();
    }
    if (!result) {
      handleSubmit(null)
    }
    return result
  }

  useImperativeHandle(ref, () => ({
    saveTabData: (saveAndClose, skipMessage = false, reload) => {
      return handleSave(saveAndClose, skipMessage, reload)
    },
    isDirty: () => {
      let fields = removeSkipFields([...dirtyFields, ...formDirtyFields]);
      return fields.length > 0
    },
    formData: () => {
      return getValues({ nest: true })
    },
    reload: (hideLoader) => {
      getActivityLogInfo(hideLoader);
    }
  }));

  useEffect(() => {
    if (currentValues) {
      reset(currentValues)
    }
  }, [currentValues])
  useEffect(() => {
    register('strengths')
    register('issues')
  }, [register])

  useEffect(() => {
    if (currentValues) {
      const { strengths, issues } = currentValues
      if (Array.isArray(strengths)) {
        setStrengths(strengths)
      }
      if (Array.isArray(issues)) {
        setIssues(issues)
      }
    }
  }, [currentValues])

  useEffect(() => {
    setValue('strengths', strengths)
  }, [strengths, setValue])

  useEffect(() => {
    setValue('issues', issues)
  }, [issues, setValue])

  const addStrength = async (length) => {
    setStrengths(prevState => ([...prevState, '']))
    if (length) {
      setLoading(true)
      await updateContactInfo({ ...currentValues, strengths: [...currentValues.strengths, ''] })
      setLoading(false)
      setCurrentValues({ ...currentValues, strengths: [...currentValues.strengths, ''] })
    }
  }

  const addIssue = async (length) => {
    setIssues(prevState => ([...prevState, '']))
    if (length) {
      setLoading(true)
      await updateContactInfo({ ...currentValues, issues: [...currentValues.issues, ''] })
      setLoading(false)
      setCurrentValues({ ...currentValues, issues: [...currentValues.issues, ''] })

    }
  }

  const remove = async (type, index) => {
    const values = [...(type === 'issues' ? issues : strengths)]
    values.splice(index, 1)
    if (type === 'issues') {
      setIssues(values);
      let newCurrentValues = { ...currentValues };
      newCurrentValues.issues.splice(index, 1)
      setLoading(true)
      await updateContactInfo(newCurrentValues)
      setLoading(false)
      setCurrentValues(newCurrentValues);
    }
    else {
      setStrengths(values)
      let newCurrentValues = { ...currentValues };
      newCurrentValues.strengths.splice(index, 1)
      setLoading(true)
      await updateContactInfo(newCurrentValues)
      setLoading(false)
      setCurrentValues(newCurrentValues);
    }
  }

  const handleChange = async (type, index, value) => {
    try {
      const values = [...(type === 'issues' ? issues : strengths)]
      values[index] = value
      const updatedContact = {}
      if (type === "issues") {
        updatedContact.issues = values
        setLoading(true)
        setIssues(values)
        await updateContactInfo(updatedContact)
        setLoading(false)
      }
      else {
        updatedContact.strengths = values
        setLoading(true)
        setStrengths(values)
        await updateContactInfo(updatedContact)
        setLoading(false)
      }
    }
    catch (e) {
      console.log("Error found in handleChange::", e);
    }
  }
  // const handleChange = (type, index, value) => {
  //   debugger
  //   const values = [...(type === 'issues' ? issues : strengths)]
  //   values[index] = value
  //   type === 'issues' ? setIssues(values) : setStrengths(values)
  // }

  const saveStrengthsAndPotentialIssues = async (Data, Index, Type, item, setAutoSaveLoading) => {
    try {
      const updatedContact = {}
      if (Type === "Issue") {
        let currentIssues = [...currentValues.issues]
        currentIssues[Index] = Data[`Issue ${Index + 1}`];
        updatedContact.old = [Data.old]
        updatedContact.issues = currentIssues
        setIssues(currentIssues)
        await autoSaveContactFields(updatedContact, true, setAutoSaveLoading)
      }
      else {
        let currentStrength = [...currentValues.strengths]
        currentStrength[Index] = Data[`Strength ${Index + 1}`];
        updatedContact.old = [Data.old]
        updatedContact.strengths = currentStrength
        setStrengths(currentStrength)
        await autoSaveContactFields(updatedContact, true, setAutoSaveLoading)
      }
    } catch (e) {
      console.log("Error found in saveStrengthsAndPotentialIssues::", e);
    }
  }

  const renderItems = (items, type) => {
    if (items.length === 0) {
      type === 'issues' ? addIssue(items.length) : addStrength(items.length)
    }
    if (items.length) {
      return items.map((item, index) => (
        < div key={index} style={{ paddingBottom: 14 }}>
          <div className='d-flex justify-content-end mb-3'>
            <CloseIcon className="cursor-pointer" onClick={() => remove(type, index)} style={{ width: 18, height: 18, marginTop: 5 }} />
          </div>
          <RichTextPopup
            className='w-100 strength-potential'
            InputProps={{
              startAdornment: <Typography style={{ padding: '0 5px' }} color='primary'>{index + 1}.</Typography>
            }}
            onChange={(data) => {
              handleChange(type, index, data);
            }}
            strengthsAndPotentialIssues={true}
            placeholder={""}
            title={`${type === 'issues' ? 'Issue' : 'Strength'} ${index + 1}`}
            name={`${type === 'issues' ? 'Issue' : 'Strength'} ${index + 1}`}
            value={item || ""}
            autoSave={saveStrengthsAndPotentialIssues}
            item={item}
            index={index}
            commentType={`${type === 'issues' ? 'Issue' : 'Strength'}`}
            StrengthsAndPotentialIssues={true}
            saveStatus={saveStatus}
            displayToolbar={false}
            InputLabelProps={{ focused: true }}
            updatedDate={currentValues.updated_at}
            payloadType={'default'}
          />
        </div >
      ))
    }
  }

  return currentValues && (
    <div className="scroll-strengths-potential-issue">
      <div className='contact-view d-flex' style={{ width: '90%' }}>
        <div className="section-container w-50" style={{ paddingRight: 30 }}>
          <FormControlLabel
            classes={classes}
            className='checkbox-container'
            control={
              <Checkbox
                className='checkbox-component'
                defaultChecked={currentValues.is_strengths_approved_by_partner}
                size='small'
                style={{ padding: '0 5px' }}
                name="is_strengths_approved_by_partner"
                inputRef={register}
              />
            }
            label={
              <Typography
                style={{ color: watch('is_strengths_approved_by_partner') ? '#686A7E' : 'red', fontSize: '14px' }}>
                Approved By Partner to Publish
              </Typography>
            }
          />
          <div className="section-header ">Strengths</div>
          {renderItems(strengths, 'strengths')}
          <Button
            disabled={strengths.length > 7}
            variant={"text"}
            startIcon={<AddCircleIcon />}
            onClick={addStrength}
            color='primary'>
            Add Strength
          </Button>
        </div>
        <div className="section-container w-50" style={{ paddingRight: 30 }}>
          <FormControlLabel
            className='checkbox-container'
            classes={classes}
            control={
              <Checkbox
                className='checkbox-component'
                defaultChecked={currentValues.is_issues_approved_by_partner}
                size='small'
                style={{ padding: '0 5px' }}
                name="is_issues_approved_by_partner"
                inputRef={register}
              />
            }
            label={
              <Typography style={{ color: watch('is_issues_approved_by_partner') ? '#686A7E' : 'red', fontSize: '14px' }}>
                Approved By Partner to Publish
              </Typography>
            }
          />
          <div className="section-header">Potential Issues</div>
          {renderItems(issues, 'issues')}
          <Button
            disabled={issues.length > 7}
            variant={"text"}
            startIcon={<AddCircleIcon />}
            onClick={addIssue}
            color='primary'>
            Add Potential Issue
          </Button>
        </div>
      </div>
      {!readOnly && <TabLayoutFooter actions={actions} />}
    </div>
  )
})

StrengthsAndPotentialIssues.propTypes = {
  setLoading: PropTypes.func,
  autoSaveContactFields: PropTypes.func,
  updateContactInfo: PropTypes.func,
  saveStatus: PropTypes.bool,
  getActivityLogInfoTabData: PropTypes.func,
  handleSaveTabData: PropTypes.func,
  readOnly: PropTypes.bool,
  isRichTextSaved: PropTypes.bool
}

export default StrengthsAndPotentialIssues;