//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from "prop-types";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { fetchPickList } from "../../../actions";

function RegionSelection(props) {
  const {
    register,
    className = 'w-100',
    label = "Region",
    name = 'region',
    placeholder = "Region",
    InputLabelProps,
    variant = "standard",
    ...rest
  } = props
  const [options, setOptions] = useState([])
  const region = useSelector(state => state.commonReducer.region)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!region) {
      dispatch(fetchPickList('region'))
    } else {
      setOptions(region);
    }
  }, [region, dispatch])

  return (
    <Autocomplete
      {...rest}
      className={className}
      options={options}
      getOptionLabel={option => {
        if (typeof option === 'string') {
          return option
        }
        return option.name
      }}
      getOptionSelected={(option, value) => {
        if (!value) {
          return false;
        }
        if (typeof value === 'string') {
          return option.name === value;
        }
        return option.name === value.name
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          inputRef={register}
          name={name}
          InputLabelProps={InputLabelProps}
          label={label}
          placeholder={placeholder}
          variant={variant}
        />
      )}
    />
  )
}

RegionSelection.propTypes = {
  register: PropTypes.func,
  className: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  InputLabelProps: PropTypes.object,
  placeholder: PropTypes.string,
  variant: PropTypes.string
}

export default RegionSelection;
