import React from 'react'

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';

//----------------------------------------------// Internal Imports // -------------------------------------------------

const TotalRevenueView = (props) => {
  /* getIndirectFees is used when calculate for estimated and actual fee calculation */
  const { type = "estimated", getTotalIndirectFee, getRevenue, getTotalPercentageOfBase,billingStatus } = props;
  const label = type === 'estimated' ? 'Estimated' : 'Actual';
  
  const displayTooltipContent = (title) => {
    return <Tooltip title={title} ><span className='pl-4'>&nbsp;&nbsp;{title}</span></Tooltip>
  }

  return (
    <div className={`d-flex flex-column team-information-table revenue-table`}>
      <div className={`d-flex table-header `}>{label} Revenue</div>
      <div className=" d-flex flex-column py-1 px-3">
        { billingStatus &&
        <div className='d-flex p-1'>
          <div className='col-4 px-0'>Base Fee</div>
          <div className='col pr-0 text-truncate'>{displayTooltipContent(getTotalPercentageOfBase(type))}</div>
        </div> }
        <div className='d-flex p-1'>
          <div className='col-4 px-0'>Indirect Fee</div>
          <div className='col pr-0 text-truncate'>{displayTooltipContent(getTotalIndirectFee(type))}</div>
        </div>
        { billingStatus &&  <hr style={{ height: '2px', width: '100%', borderWidth: 0, backgroundColor: '#2A364C' }} /> }
        <div className='d-flex p-1'>
          <div className='col-4 px-0 text-wrap'>Revenue</div>
          <div className='col pr-0 text-truncate'>{displayTooltipContent(getRevenue(type))}</div>
        </div>
      </div>
    </div>
  );
};

TotalRevenueView.propTypes = {
  type: PropTypes.string,
  getTotalIndirectFee: PropTypes.func,
  getRevenue: PropTypes.func,
  getTotalPercentageOfBase: PropTypes.func,
  billingStatus: PropTypes.string

};

export default TotalRevenueView;

