//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { forwardRef, useCallback, useEffect, useImperativeHandle } from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from "prop-types";
import * as yup from 'yup'
import { Overlay, Popover } from "react-bootstrap";
import { Button } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { ExpandableSection } from "./ExpandableSection";
import ActivityHistory from "./GeneralInfo/Components/ActivityHistory";
import LogAnActivity from "./GeneralInfo/Components/LogAnActivity";
import {
  activityFormSchema,
  getDefaultValuesForActivity,
  getLogAnActivityPayload,
  getUpdatedAt,
  isActivityEmpty
} from "../utils";
import { ACTIVITIES, CANDIDATE, CONTACT, GET, POST, ERROR, SUCCESS, ACTIVITIES_TABDATA } from "../../../services/constantService";
import { activityInfoApi, candidateContactDataApi } from "../../../services/ApiService";
import { successMessage, unableMessage } from "../../../services/MessageService";
import { customFormValidator, requireValidMessage } from "../../../utils/common";
// eslint-disable-next-line react/display-name
export const LogAnActivityPopup = forwardRef((props, ref) => {
  const {
    parentUpdatedAt, target, arenaSearch, handleShowHistory, setLoading, setStatusMessage, setActivityCurrentValues,
    activityCurrentValues, getActivityLogInfo: reloadPerent, readOnly
  } = props
  const { register, reset, watch, setValue, getValues } = useForm()
  const { id, path, contactId } = useParams();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const stage = watch('stage')

  useImperativeHandle(ref, () => ({
    saveActivity: () => {
      return handleSave(true)
    },
    isDirty: () => {
      return !isActivityEmpty(getValues({ nest: true }))
    }
  }));

  const getActivityLogInfo = useCallback(async () => {
    setLoading(true);
    let type, param_id;
    if (contactId) {
      type = CONTACT
      param_id = contactId
    } else if (path === "candidates" || path === "all-candidates" || path === "client-suite-report" || path === "my-linkedin-candidates") {
      type = CANDIDATE
      param_id = id
    }
    const sub_route = `${ACTIVITIES_TABDATA}?tabName=${'ACTIVITY_INFO'}&type=${type}&limit=2`;
    const { status, data } = await activityInfoApi(GET, param_id, sub_route);
    if (status && status === 200) {
      setActivityCurrentValues(data);
    } else {
      const message = unableMessage("activity info", "fetch")
      enqueueSnackbar(data?.message || message, { variant: ERROR });
    }
    setLoading(false);
  }, [contactId, id, path, enqueueSnackbar])

  useEffect(() => {
    getActivityLogInfo();
  }, [getActivityLogInfo])

  useEffect(() => {
    const defaultValues = getDefaultValuesForActivity(activityCurrentValues, arenaSearch)
    reset(defaultValues)
  }, [activityCurrentValues, reset])

  const activityFormSchemas = yup.object().shape({
    activity: yup.string().required(),
    type: yup.string().required(),
    subject: yup.string().required(),
    direction: yup.string().required()
  })

  const handleSave = async (checkIfDirty) => {
    const close = checkIfDirty === true
    if (close && isActivityEmpty(getValues({ nest: true }))) {
      return true
    }
    let formValues = getValues({ nest: true })
    const isValid = await activityFormSchemas.isValid(formValues)
    if (!isValid) {
      let requiredField = [
        { fieldName: "activity", label: "Activity", type: String },
        { fieldName: "type", label: "Type", type: String },
        { fieldName: "subject", label: "Subject", type: String },
        { fieldName: "direction", label: "Direction", type: String }
      ]
      let dirtyField = customFormValidator(formValues, requiredField)
      if (dirtyField) {
        const message = requireValidMessage(dirtyField)
        enqueueSnackbar(message, { variant: ERROR })
        return
      }
    }
    if (await activityFormSchema(contactId, stage).isValid(formValues)) {
      const payload = getLogAnActivityPayload(formValues)
      setStatusMessage('Creating Activity')
      setLoading(true)
      let type, param_id;
      if (contactId) {
        type = CONTACT
        param_id = contactId
        delete payload.candidate;
      } else {
        type = CANDIDATE
        param_id = id
        payload.candidate.updated_at = getUpdatedAt(parentUpdatedAt, activityCurrentValues.candidate.updated_at)
      }
      const { status, data } = await candidateContactDataApi(POST, param_id, type, payload, ACTIVITIES);
      setStatusMessage("");
      if (status && (status === 201 || status === 200)) {
        const message = successMessage("Activity", "created")
        enqueueSnackbar(message, { variant: SUCCESS })
        await getActivityLogInfo();
        await reloadPerent(true)
        return true
      } else if (status === 409) {
        const message = unableMessage("details", "save")
        enqueueSnackbar(data?.message || message,
          {
            persist: true,
            variant: ERROR,
            // eslint-disable-next-line react/display-name
            action: key => (
              <Button
                size='small'
                variant='text'
                color='inherit'
                onClick={() => {
                  closeSnackbar(key)
                }}
              >
                Dismiss
              </Button>
            )
          }
        );
      } else {
        const message = unableMessage("activity", "create")
        enqueueSnackbar(data?.message || message, { variant: ERROR });
      }
      setLoading(false);
    }
    return false;
  }

  return (
    activityCurrentValues ?
      <Overlay show={true} target={target} placement='right-end'>
        <Popover id="popover">
          <ExpandableSection title='Log an Activity'>
            <div className='log-an-activity-container input-form'>
              <LogAnActivity
                register={register}
                watch={watch}
                setValue={setValue}
                isContactActivityLog={contactId}
                currentValues={{ comments: watch('comments') }}
                arenaSearch={arenaSearch}
                hideNextSteps={true}
                contact={activityCurrentValues.contact}
                readOnly={readOnly}
              />
              <ActivityHistory
                currentValues={activityCurrentValues}
              />
            </div>
            <div className='bottom-actions'>
              <Button variant="text" className='text-link button' color="primary" onClick={handleShowHistory}>
                View All Activity Logs
              </Button>
              <Button variant='contained' className='button save-activity-btn' disabled={readOnly} onClick={handleSave}>Save Activity</Button>
            </div>
          </ExpandableSection>
        </Popover>
      </Overlay>
      : null
  )
})

LogAnActivityPopup.propTypes = {
  arenaSearch: PropTypes.bool,
  target: PropTypes.any,
  handleShowHistory: PropTypes.func,
  setLoading: PropTypes.func,
  setStatusMessage: PropTypes.func,
  setActivityCurrentValues: PropTypes.func,
  activityCurrentValues: PropTypes.object,
  parentUpdatedAt: PropTypes.string,
  getActivityLogInfo: PropTypes.func,
  readOnly: PropTypes.bool
}
