
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { Component } from "react";

//-----------------------------------------------------------// External Imports // ------------------------------

import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { Button } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";

//-----------------------------------------------------------// Internal Imports // ------------------------------

import MySearchList from "./MySearchList";
import BDList from "./BDList";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowQuery: false,
      isMySearchesExpanded: true,
      isBDExpanded: true,
      showBD: true,
    };
  }

  toggleBD = () => {
    this.setState({
      isBDExpanded: !this.state.isBDExpanded,
    });
  };

  toggleMySearches = () => {
    if (this.state.isShowQuery) {
      return;
    }
    this.setState({
      isMySearchesExpanded: !this.state.isMySearchesExpanded,
    });
  };

  setShowQueryState = (value) => {
    this.setState({ isShowQuery: value });
  };

  handleShowBD = (value) => {
    this.setState({ showBD: value });
  };

  render() {
    return (
      <div className="my-search d-flex flex-column">
        <MySearchList
          searchText={this.props?.match?.params?.searchKey}
          showQuery={this.setShowQueryState}
          toggleMySearches={this.toggleMySearches}
          isMySearchesExpanded={this.state.isMySearchesExpanded}
          handleShowBD={this.handleShowBD}
        />
        {!this.state.isShowQuery && this.state.showBD ? (
          <div
            className={`d-flex flex-column ${this.state.isBDExpanded ? "h-100 expanded" : ""
              }`}
          >
            <ExpansionPanel
              className="my-bd-list w-100 m-0"
              expanded={this.state.isBDExpanded}
            >
              <ExpansionPanelSummary
                expandIcon={
                  <ExpandMoreIcon
                    style={{ padding: 0 }}
                    onClick={this.toggleBD}
                  />
                }
                aria-controls="panel1b-content"
                id="panel1b-header"
                onClick={this.toggleBD}
                style={{ lineHeight: 1 }}
                className="p-0"
              >

                <div className="bus-dev-text w-100 d-flex align-items-center">Business Development</div>
                <div className="d-flex w-100 align-items-center justify-content-end">
                  <Link to="/searches/business-development/add">
                    <Button
                      variant={"text"}
                      startIcon={<AddCircleIcon />}
                      color="primary"
                    >
                      Add Business Development
                    </Button>
                  </Link>
                </div>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className="p-0">
                <BDList />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          </div>
        ) : null}
      </div>
    );
  }
}

App.propTypes = {
  match: PropTypes.object,
};

export default withRouter(App);
