import React, { useEffect, useState } from 'react'

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close'
import { useSnackbar } from 'notistack';
import NumberFormat from 'react-number-format';
import { TextField } from '@material-ui/core';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import FeetypeSelection from '../../../../FeetypeSelection';
import { AddRecordButton, getFixedDecimal } from '../../../../../utils/common';
import { BILLING_VALIDATIONS, ERROR, THOUSAND_SEPARATOR } from '../../../../../services/constantService';
import { useSelector } from 'react-redux';
import { separator } from '../../../../../Containers/Commons/Utils';

const PercentageOfBase = (props) => {
  const { register, setValue, currentValues, watch, type = 'estimated', disabled = false, getValueWithCurrency, fee_type } = props;
  const [percentagesOfBase, setPercentagesOfBase] = useState([])
  const label = type === 'estimated' ? 'Estimated' : 'Actual';
  const fieldType = `${type}_percentage_bases`;
  const { enqueueSnackbar } = useSnackbar();
  const [availableType, setAvailableType] = useState([]);
  const feeTypes = useSelector(state => state.commonReducer['EST_FEE_TYPE'])
  useEffect(() => {
    register(fieldType)
    setValue(fieldType, percentagesOfBase)
  }, [register, percentagesOfBase, setValue])

  useEffect(() => {
    if (currentValues) {
      if (fee_type === 'FIX') {
        const value = currentValues[fieldType].filter(item => item.type === 'FLAT')
        if (value.length > 0) {
          setPercentagesOfBase(value)
        } else {
          setPercentagesOfBase([{
            calculated_estimated_amt: 0,
            short_desc:"Flat Amount",
            type: "FLAT",
          }])
        }
      } else {
        setPercentagesOfBase(currentValues[fieldType] || [])
      }
    }
  }, [currentValues, watch('fee_type')])

  const getValue = (item) => {
    return item.type !== "FLAT" ? item[`calculated_${type}_amt`] : item[`${type}_amt`]
  }

  const handleChangePercentagesOfBase = (data, field, index, shortDesc) => {
    let existingPercentagesOfBase = [...percentagesOfBase];
    // existingPercentagesOfBase[index][field] = data
    existingPercentagesOfBase[index] = { ...existingPercentagesOfBase[index], [field]: data }
    if (field === 'type') {
      existingPercentagesOfBase[index] = { ...existingPercentagesOfBase[index], ['short_desc']: shortDesc }
      if (!watch('fee_percentage') && (shortDesc !== 'FLAT')) {
        enqueueSnackbar(BILLING_VALIDATIONS.FEE_PERCENTAGE, { variant: ERROR });
      }
    }

    if (field === `${type}_amt` || field === 'type') {
      if (existingPercentagesOfBase[index].short_desc === 'Flat Amount') {
        existingPercentagesOfBase[index][`calculated_${type}_amt`] = existingPercentagesOfBase[index][`${type}_amt`];
      } else {
        existingPercentagesOfBase[index][`calculated_${type}_amt`] = watch('fee_type') === 'STD' ? (getFixedDecimal(existingPercentagesOfBase[index][`${type}_amt`] / 3)) : getFixedDecimal((watch('fee_percentage') * (existingPercentagesOfBase[index][`${type}_amt`])) / 100)
      }
    }
    setPercentagesOfBase(existingPercentagesOfBase)
  }

  const validateFeePercentage = (index) => {
    if (!watch('fee_percentage') && percentagesOfBase[index]?.type !== 'FLAT') {
      enqueueSnackbar(BILLING_VALIDATIONS.FEE_PERCENTAGE, { variant: ERROR });
    }
  }

  const calculateAmount = () => {
    setPercentagesOfBase(prevState => [...prevState.map(ele => {
      return {
        ...ele,
        [`calculated_${type}_amt`]: ele.type === 'FLAT' ? ele[`${type}_amt`] : (watch('fee_type') === 'STD' ? (ele[`${type}_amt`] / 3) : (watch('fee_percentage') * ele[`${type}_amt`]) / 100)
      }
    })])
  }

  useEffect(() => {
    if (percentagesOfBase.length) {
      calculateAmount()
    }
  }, [watch('fee_percentage'), watch('fee_type')])

  const handleRemovePercentagesOfBase = (index) => {
    let existingPercentagesOfBase = [...percentagesOfBase];
    existingPercentagesOfBase.splice(index, 1)
    setPercentagesOfBase(existingPercentagesOfBase);
    calculateAmount();
  }
  const handleAddPercentagesOfBase = () => {
    let newPercentagesOfBase = [...percentagesOfBase, {}];
    setPercentagesOfBase(newPercentagesOfBase);
  }

  useEffect(() => {
    setAvailableType(percentagesOfBase.map((ele) => {
      return {
        short_desc: ele.short_desc,
        type: ele.type
      }
    }) || [])
  }, [percentagesOfBase])

  return (
    <div className={`d-flex flex-column team-information-table`}>
      <div className={`d-flex table-header `}>Base Fee - {label}</div>
      <div className=" d-flex flex-column">
        <div className="d-flex align-items-center table-main-header">
          <div className="col-5">Fee Items</div>
          <div className="col-3 text-wrap">Value</div>
          <div className="col-3 text-wrap">Calculated Amount</div>
        </div>
      </div>
      <div className={`table-content `}>
        {percentagesOfBase.map((item, index) => {
          return <div className="d-flex team-info-row align-items-center" key={index}>
            <div className="col-5 team-info-field" >
              {!disabled ? <FeetypeSelection
                filterSelectedOptions
                variant="outlined"
                className=""
                value={item.short_desc || ""}
                InputLabelProps={{ focused: true }}
                label=''
                placeholder="Fee Items"
                type='EST_FEE_TYPE'
                disabled={fee_type === 'FIX'}
                selectedType={availableType}
                onChange={(e, data) => {
                  // handleChangePercentagesOfBase(data ? data.short_desc : null, 'short_desc', index)
                  handleChangePercentagesOfBase(data ? data.field_value : null, 'type', index, data ? data.short_desc : null)
                }}
              /> : <div className="">{item.short_desc || '--'}</div>}
            </div>
            {!disabled ?
              <NumberFormat
                value={item[`${type}_amt`] || ""}
                customInput={TextField}
                // type="number"
                label=''
                thousandSeparator={separator(watch('currency_iso_code'), THOUSAND_SEPARATOR)}
                variant='outlined'
                placeholder=""
                className='col-3 team-info-field'
                InputLabelProps={{ focused: true }}
                decimalScale={2}
                isNumericString={true}
                onChange={() => { validateFeePercentage(index) }}
                onValueChange={(values) => {
                  const { value } = values;
                  handleChangePercentagesOfBase(value ? Number(value) : null, `${type}_amt`, index)
                }}
              /> : <div className="col-3 team-info-field">{item[`${type}_amt`] ? getValueWithCurrency(item[`${type}_amt`]) : '--'}</div>}
            {!disabled ? <NumberFormat
              value={getValue(item) || 0}
              customInput={TextField}
              // type="number"
              label=''
              thousandSeparator={separator(watch('currency_iso_code'), THOUSAND_SEPARATOR)}
              variant='outlined'
              placeholder=""
              className='col-3 team-info-field'
              InputLabelProps={{ focused: true }}
              error={item[`calculated_${type}_amt`] > 1000000000000000}
              decimalScale={2}
              isNumericString={true}
              onValueChange={(values) => {
                const { value } = values;
                handleChangePercentagesOfBase(value ? Number(value) : null, `calculated_${type}_amt`, index)
              }}
              disabled={true}
            /> : <div className="col-3 team-info-field">{item[`calculated_${type}_amt`] ? getValueWithCurrency(item[`calculated_${type}_amt`]) : '--'}</div>}
            <div className="close-action">
              {disabled ? <span>&nbsp;</span> : <span className="close-icon col-1" >
                <CloseIcon onClick={() => handleRemovePercentagesOfBase(index)} color='error' fontSize="inherit" />
              </span>}
            </div>
          </div>
        })}
        {!disabled && <div className='text-start'>
          <AddRecordButton
            onClick={handleAddPercentagesOfBase}
            color={!(feeTypes?.length > availableType.length) ? '' : 'red'}
            disabled={(!(feeTypes?.length > availableType.length) || fee_type === 'FIX')}
          >Add Row</AddRecordButton>
        </div>}
      </div>
    </div>
  )
}

PercentageOfBase.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  currentValues: PropTypes.object,
  watch: PropTypes.func,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  getValueWithCurrency: PropTypes.func,
  fee_type: PropTypes.string
};

export default PercentageOfBase;