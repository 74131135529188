import { createSlice } from "@reduxjs/toolkit";

const initialState = {};


const contactSlice = createSlice({
  name: 'contact',
  initialState,
  reducers: {
    updateContactOfferLetter(state, action) {
      state.contactOfferLetter = action.payload
    },
    updateContactResume(state, action) {
      state.contactResume = action.payload
    },
    updateContactNda(state, action) {
      state.contactNda = action.payload
    },
    updateContactNotes(state, action) {
      state.contactNotes = action.payload
    },
    updateContactAttachments(state, action) {
      state.contactAttachments = action.payload
    },
    updateContactAttributes(state, action) {
      state.contact_attribute = action.payload
    },
    updateContactSearch(state, action) {
      state.contact_search = action.payload
    },
    updateContactAthena(state, action) {
      state.contact_athena = action.payload
    },
    resetCandidateDocList(state) {
      state.contactAttachments = undefined;
      state.contactResume = undefined;
      state.contactOfferLetter = undefined;
      state.contactNda = undefined;
    },
    resetContactState: () => initialState,
  }
})

export const contactActions = contactSlice.actions;

export default contactSlice.reducer;