//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useState } from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import Popup from "reactjs-popup";
import PropTypes from "prop-types";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { withSnackbar } from "notistack";
import CloseIcon from "@material-ui/icons/Close";
import { Button, TextField } from "@material-ui/core";

//----------------------------------------------// Internal Imports // -------------------------------------------------
import './ClientSuiteInvitePopUp.scss'
import { INSIDE_TRACK } from "../../../../../utils/common";
import { clientsuiteInviteApi } from "../../../../../services/ApiService";
import { SUCCESS, ERROR } from "../../../../../services/constantService";
import { unableMessage, successMessage } from "../../../../../services/MessageService";
import Loader from '../../../../../components/common/Loader';

function ClientSuiteInvitePopUp(props) {
  const { client, onClose, enqueueSnackbar, type } = props;
  const [selectedLanguage, setSelectedLanguage] = useState('en_US')
  const [bcc, setBcc] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const inviteType = type === INSIDE_TRACK ? 'Arena' : 'ClientSuite'
  const sendClientSuiteInvite = async (clientId) => {
    setIsLoading(true)
    const sub_route = 'invite';
    const payload = { client_id: clientId, locale: selectedLanguage, bcc: bcc };
    const { status, data } = await clientsuiteInviteApi(sub_route, payload);
    if (status && status === 200) {
      if (data.message) {
        enqueueSnackbar(data.message, { variant: SUCCESS });
      } else {
        const message = successMessage(`The ${inviteType} invite`, "sent")
        enqueueSnackbar(message, { variant: SUCCESS });
      }
    } else {
      const message = unableMessage(`${inviteType} invite`, "send");
      enqueueSnackbar(data?.message || message, { variant: ERROR });
    }
    setIsLoading(false)
    onClose()
  }

  const getName = (client) => {
    const firstName = client?.first_name ? client.first_name : ''
    const lastName = client?.last_name ? client.last_name : ''
    return firstName + ' ' + lastName
  }

  return (
    <Popup open={true} className="client-suite-invite" onClose={onClose} closeOnDocumentClick={false} closeOnEscape={false}>
      <>
        <Loader show={isLoading} />
        <div className="d-flex flex-column h-100">
          <div className="d-flex align-items-center justify-content-between popup-header" style={{ background: '#c3c3c35c' }}>
            <div className="d-flex align-items-center" style={{color:'#fff'}}>
              <h4 className="mr-5">Send {inviteType} invite to client</h4>
            </div>
            <span className="action-icon cursor-pointer" style={{color:'#fff'}} onClick={onClose}>
              <CloseIcon />
            </span>
          </div>
          <div className="client-suite-invite-options">
            <div className="field-details">
              <span className="field-title">Contact Name</span>
              <span className="field-value" style={{flex:1}}>
                {getName(client)}
              </span>
            </div>
            <div className="field-details">
              <span className="field-title">Select Language</span>
              <div className="field-value" style={{flex:'1'}}>
                <Select value={selectedLanguage} onChange={(e) => setSelectedLanguage(e.target.value)}>
                  <MenuItem value='en_US'>English</MenuItem>
                  <MenuItem value='es_ES'>Spanish</MenuItem>
                  <MenuItem value='pt_BR'>Portuguese</MenuItem>
                </Select>
              </div>
            </div>
            <div className="field-details">
              <span className="field-title">BCC</span>
              <TextField className='input-form-field' name={'bcc'} onChange={(event) => { setBcc(event.target.value) }} />
            </div>
          </div>
          <div className="action-buttons" style={{ background: '#c3c3c35c' }}>
            <Button onClick={onClose} color="primary" className="mr-2">Cancel</Button>
            <Button variant="contained" className="button" color="primary" onClick={() => { sendClientSuiteInvite(client.client_id) }} autoFocus>Send</Button>
          </div>
        </div>
      </>
    </Popup>
  )
}

ClientSuiteInvitePopUp.propTypes = {
  client: PropTypes.object,
  onClose: PropTypes.func,
  enqueueSnackbar: PropTypes.func,
  type: PropTypes.string
}

export default withSnackbar(ClientSuiteInvitePopUp);
