
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { Component } from "react";

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import { saveAs } from "file-saver";
import { Helmet } from "react-helmet";
import { withSnackbar } from "notistack";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import { Button, Checkbox, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";

//-----------------------------------------------------------// Internal Imports // ------------------------------

import { RichTextPopupRendered } from "../../../utils/common"
import RichTextBoxEditor from "../../Commons/CellEditors/RichTextBoxEditor";
import ToggleSelection from '../../Commons/CellEditors/ToggleSelection'
import DescriptionEditor from '../../Commons/CellEditors/DescriptionEditor'
import PopupEditor from '../../Commons/CellEditors/PopupEditor'
import StateEditor from '../../Commons/CellEditors/StateEditor'
import NumberEditor from '../../Commons/CellEditors/NumberEditor'
import CompanySearch from "../CompanySearch";
import Query from "../components/Query";
import GenericCellEditor from "../../Commons/CellEditors/GenericCellEditor";
import PhoneEditor from "../../Commons/CellEditors/PhoneEditor";
import {
  columnDefs,
  getQueryParamsString,
  SUCCESS_STATUS_CODE,
  PAGE_LIMIT,
  WebsiteRenderer,
  CustomLoadingOverlayComponent,
  cleanup,
} from "../utils";
import {
  PhoneRenderer,
  DateRenderer,
  DateTimeRenderer,
  saveColumnStateToLocalStorage,
  loadColumnStateFromLocalStorage,
  getCurrencyPrefix,
  currencyValueSetter,
} from "../../Commons/Utils";
import "../../Commons/index.scss";
import {
  defaultColumns,
  CompanyNameRenderer,
  getFilterParamString,
} from "../utils";
import ColumnFilter from "../../Commons/ColumnFilter";
import ActionsPopover from "../../Contacts/ActionsPopover";
import DeletePopup from "../../Commons/DeletePopup";
import CustomFilter from "../../Commons/CustomFilter";
import {
  EXPORT_LIMIT,
  API,
  BLOB,
  POST,
  ERROR,
  WARNING,
  DELETE_CONFIRMATION_POPUP_MESSAGE,
} from "../../../services/constantService";
import Loader from "../../../components/common/Loader";
import { dataSourceUtils } from "../../../utils/dataSource";
import { companyDataApi } from "../../../services/ApiService";
import {
  exportMessage,
  VALIDATION_MESSAGE,
  notFoundMessage,
} from "../../../services/MessageService";
import { SCROLL_TIMEOUT } from "../../../utils/common";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewColumns: false,
      queryData: {},
      anchorEl: null,
      isClassicQuery: false,
      rowCount: 0,
      minimizeQuery: true,
      isAllRowsSelected: false,
      selectedRowsCount: 0,
      isLoading: false,
      showFilterCount: 0
    };
  }

  componentWillUnmount() {
    const columnApi = this.columnApi
    saveColumnStateToLocalStorage("companiesMyClientColumns", { columnApi });
  }

  resetFilter = async () => {
    this.gridApi.setFilterModel(null);
    const customFilterField = this.columnApi.columnController.columnDefs;
    customFilterField.map(item => {
      if (item.filter === 'CustomFilter') {
        this.gridApi.destroyFilter(item.field);
      }
    })
  }

  resetSort = async () => {
    this.gridApi.setSortModel(null);
  }

  getURLs = {
    listURl: `${API.companies}/my-clients`,
    classicURl: `${API.companies}`,
    advanceURL: API.master_query,
    quickQuery: `${API.companies}/my-clients`,
  };

  dataSource = {
    getRows: async (params) => {
      try {
        let value = localStorage.getItem("companiesMyClientColumns")
        if (value === 'true') {
          this.setState({ isClassicQuery: true, minimizeQuery: false });
          // this.resetFilter();
          // this.resetSort();
          params.successCallback([], 0);
          this.setState({ rowCount: 0 });
          return;
        }
        params.filterModel && this.setState({ filterModel: params.filterModel });
        this.setState({ showFilterCount: Object.keys(this.state.filterModel).length })
        // this.gridApi.deselectAll();
        if (params.filterModel && Object.keys(params.filterModel).length > 0)
          this.gridApi.deselectAll();
        const thisValue = { ...this };
        this.gridApi.showLoadingOverlay();
        const obj = {
          params: params,
          context: thisValue,
          pageLimit: PAGE_LIMIT,
          url: this.getURLs,
          subScreen: true,
          companiesSubScreen: true,
          defaultSort: true,
          queryString: `&sortOn=updated_at&sortType=DESC`,
        };
        const { status, data } = await dataSourceUtils(
          obj,
          getFilterParamString,
          getQueryParamsString
        );
        if (status === SUCCESS_STATUS_CODE) {
          const message = notFoundMessage("records");
          if (data?.paging?.totalCount === 0) {
            this.props.enqueueSnackbar(message, { variant: WARNING });
          }
          params.successCallback(data.data, data.paging.totalCount);
          this.setState({ rowCount: data.paging.totalCount });
          this.state.isAllRowsSelected &&
            this.setSelectAllRows(this.state.isAllRowsSelected);
        }
        params.failCallback();
        this.gridApi.hideOverlay();
      } catch (e) {
        console.log("Error found in getRows::", e);
      }
      // localStorage.removeItem("companiesMyClientColumns")
    },
    rowCount: null,
  };

  RevenueRenderer = (params) => {
    const currencyCode =
      params.data && params.data.currency_iso_code
        ? params.data.currency_iso_code
        : "";
    const revenue =
      params.data && params.data.revenue ? params.data.revenue : "";
    return (
      <NumberFormat
        style={{ border: "none", fontSize: "15px" }}
        color="primary"
        value={revenue}
        thousandSeparator={true}
        decimalSeparator="."
        displayType="text"
        prefix={getCurrencyPrefix(currencyCode !== "RUR" ? currencyCode : "")}
        suffix={getCurrencyPrefix(currencyCode === "RUR" ? "RUR" : "")}
        renderText={(data) => {
          const value = currencyValueSetter(data, currencyCode);
          return value ? value : "--";
        }}
      />
    );
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    const columnApi = params.columnApi
    const gridApi = params.api
    params.columnApi.autoSizeColumns();
    loadColumnStateFromLocalStorage(
      "companiesMyClientColumns",
      { columnApi, gridApi }
    );
    this.setState({ isGridReady: true });
  };

  NameRenderer = (params) => {
    return <CompanyNameRenderer company={params?.data} />;
  };

  ParentCompanyNameRenderer = (params) => {
    return <CompanyNameRenderer company={params?.data?.parent} />;
  }

  handleSearchChange = (event, value) => {
    let searchString = "";
    if (typeof value === "string") {
      searchString = value;
    }
    if (value && value.name) {
      searchString = value.name;
    }
    const sportsFilterComponent = this.gridApi.getFilterInstance("name");
    sportsFilterComponent.setModel({
      type: "startsWith",
      filter: searchString,
    });
    this.gridApi.onFilterChanged();
    this.resetFilter();
    this.resetSort();
  };

  handleQuery = (queryData) => {
    this.setState({ queryData: cleanup(queryData) });
    this.gridApi.onFilterChanged();
    localStorage.removeItem('companiesMyClientColumns')
  };
  handleChangeQuery = async () => {
    await this.resetSort();
    await this.resetFilter()
    if (this.state.isAllRowsSelected === true) {
      this.setSelectAllRows(false);
      this.setState({ isAllRowsSelected: true });
    } else {
      this.gridApi.forEachNode((node) => {
        node.setSelected(false);
      });
    }
    this.gridApi.getFilterInstance("name").setModel(null);
    this.gridApi.onFilterChanged();
    this.setState({ isClassicQuery: true, minimizeQuery: false });
    localStorage.setItem("companiesMyClientColumns", true);
    setTimeout(() => {
      window.location.reload(false);
    }, 3000)
  };

  getParams = (selectedRows) => {
    return selectedRows.map((row) => `id=${row.id}`).join("&");
  };

  handleExport = async () => {
    const selectedRows = this.gridApi.getSelectedRows();
    if (this.state.isAllRowsSelected && this.state.rowCount > EXPORT_LIMIT) {
      const message = exportMessage(`${EXPORT_LIMIT}`, "Contacts");
      this.props.enqueueSnackbar(message, { variant: ERROR });
    } else if (
      !this.state.isAllRowsSelected &&
      selectedRows.length > EXPORT_LIMIT
    ) {
      const message = exportMessage(`${EXPORT_LIMIT}`, "Contacts");
      this.props.enqueueSnackbar(message, { variant: ERROR });
    } else {
      if (selectedRows.length > 0) {
        let sub_route = `export-as-excel?`;
        if (this.state.searchString) {
          sub_route = `${sub_route}&name=${this.state.searchString}`;
        } else if (this.state.isClassicQuery) {
          const paramsString = getQueryParamsString(this.state.queryData);
          sub_route = `${sub_route}${paramsString}&classicSelector=${this.state.isClassicQuery}`;
        }
        if (
          this.state.filterModel &&
          Object.keys(this.state.filterModel).length
        ) {
          sub_route = sub_route.concat(
            getFilterParamString(
              this.state.filterModel,
              this.state.isClassicQuery
            )
          );
          sub_route = `${sub_route}&filter=true`;
        }
        let id = [];
        selectedRows.map((data) => id.push(data.id));
        const columnHeaders = this.columnApi
          .getAllDisplayedColumns()
          .map((column) => column.getColDef().headerName);
        this.gridApi.showLoadingOverlay();
        const payload = {
          headers: {
            columnHeaders: columnHeaders,
            selectedRows: { id: id },
            allRowSelected: this.state.isAllRowsSelected,
            type: "MyClients",
            limit: EXPORT_LIMIT,
          },
        };
        let { status, data, headers } = await companyDataApi(
          POST,
          "",
          payload,
          sub_route,
          BLOB
        );
        if (status === 200) {
          let fileName = "Companies.xlsx";
          let fileNameHeader = headers["content-disposition"].split('"');
          if (fileNameHeader && fileNameHeader.length > 2) {
            fileName = fileNameHeader[1];
          }
          saveAs(new File([data], fileName));
          this.gridApi.hideOverlay();
        }
      } else {
        this.props.enqueueSnackbar(VALIDATION_MESSAGE.no_record_selected, {
          variant: ERROR,
        });
      }
    }
  };

  setSelectAllRows = (isAllRowsSelected) => {
    this.setState({ isAllRowsSelected });
    this.gridApi.forEachNode((node) => {
      node.setSelected(isAllRowsSelected);
    });
  };

  handleChange = () => {
    this.setSelectAllRows(!this.state.isAllRowsSelected);
  };

  HeaderCheckbox = () => {
    return (
      <Checkbox
        style={{ padding: 0, width: 16, height: 16 , color: "white" }}
        size="small"
        color="primary"
        onChange={this.handleChange}
      />
    );
  };

  ActionsRenderer = (params) => {
    const list = [
      {
        label: "Delete Record",
        onClick: () => {
          this.setState({ companyId: params.data?.id });
        },
      },
    ];
    return params.data ? <ActionsPopover list={list} /> : null;
  };

  handleClose = (deleted) => {
    if (deleted) {
      this.gridApi.onFilterChanged();
    }
    this.setState({ companyId: null });
  };

  saveColumnState = async () => {
    try {
      this.gridApi && this.gridApi.showLoadingOverlay()
      const columnApi = this.columnApi;
      const gridApi = this.gridApi
      await saveColumnStateToLocalStorage(
        "companiesMyClientColumns",
        { columnApi, gridApi }
      );
      this.gridApi && this.gridApi.hideOverlay()
    } catch (e) {
      console.log("Error found in saveColumnState::", e);
    }
  };

  saveColumnStateForFilter = async () => {
    try {
      this.gridApi && this.gridApi.showLoadingOverlay()
      const columnApi = this.columnApi;
      const gridApi = this.gridApi
      await saveColumnStateToLocalStorage(
        "companiesMyClientColumns",
        { columnApi, gridApi },
        true,
        false
      );
      this.gridApi && this.gridApi.hideOverlay()
    } catch (e) {
      console.log("Error found in saveColumnState::", e);
    }
  };

  saveColumnStateForSort = async () => {
    try {
      this.gridApi && this.gridApi.showLoadingOverlay()
      const columnApi = this.columnApi;
      const gridApi = this.gridApi
      await saveColumnStateToLocalStorage(
        "companiesMyClientColumns",
        { columnApi, gridApi },
        false,
        true
      );
      this.gridApi && this.gridApi.hideOverlay()
    } catch (e) {
      console.log("Error found in saveColumnState::", e);
    }
  };

  loaderChange = async (value) => {
    this.setState({ isLoading: value });
  };

  render() {
    return (
      <div className="list-companies d-flex flex-column">
        <Loader show={this.state.isLoading} />
        <Helmet>
          <title>My Clients - KG Galaxy</title>
        </Helmet>
        {this.state.companyId ? (
          <DeletePopup id={this.state.companyId} onClose={this.handleClose} popupText={DELETE_CONFIRMATION_POPUP_MESSAGE.DELETE_RECORD} />
        ) : null}
        {this.state.isClassicQuery ? (
          <div
            className={"d-flex query-toolbar d-flex"}
            style={{ padding: "0px" }}
          >
            <Helmet>
              <title>Query My Clients - KG Galaxy</title>
            </Helmet>
            <ExpansionPanel
              className="flex-grow-1 w-100 m-0"
              expanded={this.state.isClassicQuery && !this.state.minimizeQuery}
            >
              <ExpansionPanelSummary
                expandIcon={
                  <ExpandMoreIcon
                    onClick={() =>
                      this.setState({
                        minimizeQuery: true,
                      })
                    }
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
                onClick={() =>
                  this.setState({
                    minimizeQuery: !this.state.minimizeQuery,
                  })
                }
                style={{ padding: "0px 14px", lineHeight: 1 }}
              >
                Search Query
              </ExpansionPanelSummary>
              <ExpansionPanelDetails
                style={{ padding: "0px 15px 3px", width: "100%" }}
              >
                <Query
                  handleExport={this.handleExport}
                  gridApi={this.gridApi}
                  isAllRowsSelected={this.state.isAllRowsSelected}
                  columnApi={this.columnApi}
                  handleQuery={this.handleQuery}
                  rowCount={this.state.rowCount}
                  queryData={this.state.queryData}
                />
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <div className="expansion-panel-close-container">
              <span
                className="cursor-pointer expansion-panel-close d-flex"
                onClick={async () => {
                  await this.resetSort();
                  await this.resetFilter()
                  this.setState({
                    isClassicQuery: false,
                    queryData: {},
                    minimizeQuery: false,
                  });
                  this.gridApi.onFilterChanged();
                  localStorage.removeItem("companiesMyClientColumns")
                  setTimeout(() => {
                    window.location.reload(false)
                  }, 3000)
                }}
              >
                <CloseIcon />
              </span>
            </div>
          </div>
        ) : (
          <div className={"toolbar"}>
            <CompanySearch onChange={this.handleSearchChange} />
            <Button
              className={"start-query"}
              variant={"outlined"}
              color={"primary"}
              onClick={this.handleChangeQuery}
            >
              Start Query
            </Button>
          </div>
        )}
        <div
          className="d-flex align-items-center justify-content-end" style={{ padding: '10px 20px 0' }}
        >
          <div
            className="action-container"
            style={{ minWidth: 0 }}
            onClick={() => this.resetFilter()}
          >
            <span className="action-text" >
              Reset Filter
            </span>
          </div>
          <div
            className="action-container"
            style={{ minWidth: 0 }}
            onClick={() => this.resetSort()}
          >
            <span className="action-text" >
              Reset Sort
            </span>
          </div>
          <Button
            className="mr-3"
            disabled={!this.state.selectedRowsCount}
            variant="outlined"
            color="primary"
            onClick={this.handleExport}
          >
            Export List
          </Button>

          <Typography>Total count: {this.state.rowCount}</Typography>
        </div>
        <div className="list-view flex-grow-1">
          {this.state.isGridReady && (
            <ColumnFilter
              columnApi={this.columnApi}
              defaultColumns={defaultColumns}
              showFilterCount={this.state.showFilterCount}
              filterModel={this.state.filterModel}
            />
          )}
          <div id="myGrid" className="ag-theme-alpine">
            <AgGridReact
              onGridReady={this.onGridReady}
              enableBrowserTooltips={true}
              defaultColDef={{
                minWidth: 100,
                resizable: true,
                sortable: true,
                sortingOrder: ['asc', 'desc', null]
              }}
              blockLoadDebounceMillis={SCROLL_TIMEOUT}
              scrollbarWidth={12}
              suppressHorizontalScroll={false}
              cacheBlockSize={PAGE_LIMIT}
              loadingOverlayComponent={"CustomLoadingOverlayComponent"}
              frameworkComponents={{
                ActionsRenderer: this.ActionsRenderer,
                HeaderCheckbox: this.HeaderCheckbox,
                RevenueRenderer: this.RevenueRenderer,
                NameRenderer: this.NameRenderer,
                WebsiteRenderer,
                PhoneRenderer,
                CustomLoadingOverlayComponent,
                DateRenderer,
                DateTimeRenderer,
                PhoneEditor,
                GenericCellEditor,
                CustomFilter,
                RichTextBoxEditor,
                RichTextPopupRendered,
                DescriptionEditor,
                ParentCompanyNameRenderer: this.ParentCompanyNameRenderer,
                PopupEditor,
                ToggleSelection,
                NumberEditor,
                StateEditor,
              }}
              rowModelType={"infinite"}
              rowSelection="multiple"
              datasource={this.dataSource}
              columnDefs={columnDefs(
                this.props.enqueueSnackbar,
                this.loaderChange
              )}
              paginationPageSize={20}
              suppressRowClickSelection={true}
              suppressDragLeaveHidesColumns={true}
              onDisplayedColumnsChanged={this.saveColumnState}
              onDragStopped={this.saveColumnState}
              onSortChanged={this.saveColumnStateForSort}
              onFilterChanged={this.saveColumnStateForFilter}
              onRowSelected={() => {
                // if (!e.node.selected) {
                //   this.setState({ isAllRowsSelected: false })
                // }
                this.setState({
                  selectedRowsCount: this.gridApi.getSelectedRows().length,
                });
              }}
            ></AgGridReact>
          </div>
        </div>
      </div>
    );
  }
}
App.propTypes = {
  enqueueSnackbar: PropTypes.func,
};

export default withSnackbar(App);
