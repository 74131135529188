//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from "react";

//----------------------------------------------// External Imports // -------------------------------------------------0
import PropTypes from "prop-types";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";


//----------------------------------------------// Internal Imports // -------------------------------------------------
//import { capitalize } from "../../../utils/string";
import { userDataApi } from "../../../services/ApiService";
import { BILLING_DESK_ROLE, GET } from "../../../services/constantService";

function AppRoleSelection(props) {
  const {
    className = 'w-100',
    label,
    InputLabelProps,
    required,
    placeholder = "App Role",
    list = null,
    ...rest
  } = props
  const [options, setOptions] = useState([])

  const filterDataOptions = (data) => {
    const isBilingRole = list.findIndex(item => item.name === BILLING_DESK_ROLE)
    if (isBilingRole == 0) {
      return []
    } else {
      return data.filter((item) => {
        return list.every(listItem => listItem?.id !== item?.id && item.name !== BILLING_DESK_ROLE)
      })
    }

  }
  const getAppRole = async () => {
    const sub_route = "app/roles"
    const { status, data } = await userDataApi(GET, "", "", sub_route)
    if (status === 200) {
      if (list !== null && list.length) {
        const filterData = filterDataOptions(data)
        setOptions(filterData);
      } else {
        setOptions(data);
      }
    } else {
      setOptions([])
    }
  }
  useEffect(() => {
    getAppRole()

  }, [list])

  return (
    <Autocomplete
      {...rest}
      options={options}
      className={className}

      getOptionLabel={option => {
        if (typeof option === 'string') {
          return option
        }
        return option.name
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          required={required}
          InputLabelProps={InputLabelProps}
          label={label}
          placeholder={placeholder}
        />
      )}
    />
  )
}

AppRoleSelection.propTypes = {
  required: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  InputLabelProps: PropTypes.object,
  list: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}

export default AppRoleSelection;
