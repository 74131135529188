//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from "prop-types";

//----------------------------------------------// Internal Imports // -------------------------------------------------
import EmploymentDetails from "./EmploymentDetails";
import './index.scss'
import BoardDetails from "./BoardDetails";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import TabLayoutFooter from "../../../TabLayoutFooter";
import { employmentHistoryTabDefaultValues, getActivityEmploymentHistoryTabPayload, removeSkipFields } from "../../utils";
import { ACTIVITY_LOG_MESSAGE, WARNING } from "../../../../services/constantService";

const EmploymentHistory = forwardRef((props, ref) => {
  const { getActivityLogInfoTabData, handleSaveTabData, readOnly } = props;
  const [tabInfo, setTabInfo] = useState(null)
  const [currentValues, setCurrentValues] = useState(null)
  const { id, path, contactId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { register, reset, handleSubmit, getValues, setValue, formState } = useForm({});
  const { dirtyFields } = formState
  const actions = [
    { label: 'Save & Close', className: 'primary-btn mr-3', onClick: () => { handleSave(true) } },
    { label: 'Save', className: 'primary-btn', onClick: () => { handleSave(false, false, true) } }
  ]


  const getActivityLogInfo = useCallback(async (hideLoader) => {
    const data = await getActivityLogInfoTabData(hideLoader, 'EMPLOYMENT_HISTORY_TAB')
    if (data) {
      setTabInfo(data)
    }
  }, [contactId, id, path, enqueueSnackbar])

  useEffect(() => {
    getActivityLogInfo();
  }, [getActivityLogInfo])

  useEffect(() => {
    let defaultValues = employmentHistoryTabDefaultValues(tabInfo);
    if (defaultValues) {
      setCurrentValues(defaultValues)
      reset(defaultValues)
    }
  }, [tabInfo, reset, getValues])

  const handleSave = async (saveAndClose, skipMessage = false, reload) => {
    const formValues = getValues({ nest: true });
    let result
    //  const isValid = await employmentHistoryFormSchema.isValid(formValues)
    let invalidJobHistory = {};
    let invalidBoardDetails = {};
    if (formValues.job_history)
      invalidJobHistory = formValues.job_history.find(ele => (!ele?.company && (ele?.title || ele?.start_year || ele?.end_year))) || {}
    if (formValues.board_details)
      invalidBoardDetails = formValues.board_details.find(ele => (!ele.company && (ele?.title || ele?.start_date || ele?.end_date || ele?.committee))) || {};
    if (Object.keys(invalidJobHistory).length === 0 && Object.keys(invalidBoardDetails).length === 0) {
      const payload = await getActivityEmploymentHistoryTabPayload(formValues, dirtyFields);
      result = await handleSaveTabData(payload, saveAndClose, skipMessage, false, 0)
      if (result && reload) {
        await getActivityLogInfo();
      }
    } else {
      if (Object.keys(invalidJobHistory).length !== 0) {
        enqueueSnackbar(ACTIVITY_LOG_MESSAGE.JOB_HISTORY_REQUIRED_FIELDS, { variant: WARNING })
        result = false
      }
      if (Object.keys(invalidBoardDetails).length !== 0) {
        enqueueSnackbar(ACTIVITY_LOG_MESSAGE.BOARD_DETAILS_HISTORY_REQUIRED_FIELDS, { variant: WARNING })
        result = false
      }
    }
    if (!result) {
      handleSubmit(null)
    }
    return result
  }

  useImperativeHandle(ref, () => ({
    saveTabData: (saveAndClose, skipMessage = false, reload) => {
      return handleSave(saveAndClose, skipMessage, reload)
    },
    isDirty: () => {
      let fields = removeSkipFields([...dirtyFields])
      return fields.length > 0
    },
    formData: () => {
      return getValues({ nest: true })
    },
    reload: (hideLoader) => {
      getActivityLogInfo(hideLoader);
    }
  }));

  useEffect(() => {
    if (currentValues) {
      reset(currentValues)
    }
  }, [currentValues])
  return currentValues && (
    <div>
      <div className='employment-history'>
        <EmploymentDetails
          register={register}
          currentValues={currentValues}
          setValue={setValue}
        />
        <BoardDetails
          register={register}
          currentValues={currentValues}
          setValue={setValue}
        />
      </div>
      {!readOnly && <TabLayoutFooter actions={actions} />}
    </div>
  )
})

EmploymentHistory.propTypes = {
  getActivityLogInfoTabData: PropTypes.func,
  handleSaveTabData: PropTypes.func,
  readOnly: PropTypes.bool,
};

export default EmploymentHistory;
