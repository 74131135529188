//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useState, useEffect } from 'react'

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import Autocomplete from '@material-ui/lab/Autocomplete';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import InputField from '../common/InputField';
import { fetchTypeList } from "../../actions";
import { orderBy } from 'lodash';

function FeetypeSelection(props) {

  const { label = "Fee Type", placeholder = "Fee Type", type, required = false, selectedType, InputLabelProps, variant = "standard", sort = false, ...rest } = props;
  const [value, setValue] = useState("");
  const [options, setOptions] = useState([]);
  const feeTypes = useSelector(state => state.commonReducer[type])
  const dispatch = useDispatch()

  const filterDataOptions = (data) => {
    return data.filter((item) => {
      return selectedType.every(listItem => listItem?.type !== item?.field_value)
    })
  }
  const filterOptions = (data) => {
    return data.filter(item => item.short_desc.match(new RegExp(value, "gi")))
  }

  useEffect(() => {
    if (!feeTypes) {
      dispatch(fetchTypeList(type))
    } else {
      if (selectedType?.length) {
        if (selectedType[0] !== undefined) {
          const filterData = filterDataOptions(feeTypes)
          const filterValue = filterOptions(filterData)
          setOptions(filterValue)
        }
      } else {
        const filterValue = filterOptions(feeTypes)
        setOptions(filterValue);
      }
    }
  }, [feeTypes, selectedType, value, dispatch])

  return (
    <Autocomplete
      {...rest}
      filterOptions={(options) => options}
      options={sort ? orderBy(options, 'short_desc', 'asc') : options}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option
        }
        return option.short_desc
      }}
      getOptionSelected={(option, value) => {
        if (typeof value === 'string') {
          return option?.short_desc === value;
        }
        return option?.short_desc === value?.short_desc
      }}
      onInputChange={(e, val, reason) => {
        if (reason === "input") {
          setValue(val);
        }
      }}
      renderInput={(params) => (
        <InputField
          {...params}
          variant={variant}
          InputLabelProps={InputLabelProps}
          required={required}
          label={label}
          placeholder={placeholder}
        />
      )}
    />
  )
}

FeetypeSelection.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  required: PropTypes.bool,
  InputLabelProps: PropTypes.object,
  variant: PropTypes.string,
  sort: PropTypes.bool,
  selectedType: PropTypes.array,
};

export default FeetypeSelection;

