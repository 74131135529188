import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    updateCompanyNotes(state, action) {
      state.companyNotes = action.payload
    },
    updateCompanyAttachments(state, action) {
      state.companyAttachments = action.payload
    },
    resetCompanyState: () => initialState,
  }
})

export const companyActions = companySlice.actions;

export default companySlice.reducer;