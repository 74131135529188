export const addHttps = (url) => {
  if (url && !(url.startsWith("http://") || url.startsWith("https://"))) {
    url = "//" + url;
  }
  return url;
};

export const capitalize = (word) => {
  if (typeof word !== "string") return "";
  return word.charAt(0).toUpperCase() + word.slice(1);
};

export const getUrlWithTimeStamp = (url) => {
  if (url) {
    return `${url}?${Date.now()}`;
  }
  return null;
};
