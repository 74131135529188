//----------------------------------------------// In-built Imports // -------------------------------------------------

import React, { useEffect, useState } from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types'
import Autocomplete from "@material-ui/lab/Autocomplete";

//----------------------------------------------// Internal Imports // -------------------------------------------------
import InputField from "./common/InputField";

const numericOptions = [{ id: 1, name: "Yes" }, { id: 0, name: "No" }]
const booleanOptions = [{ id: "true", name: "Yes" }, { id: "false", name: "No" }]
export default function YesNoSelection(props) {
  const name = "Lifer"
  const { label = name, placeholder = name, value, isBoolean = false, ...rest } = props
  const [defaultValue, setDefaultValue] = useState({})
  const options = isBoolean ? booleanOptions : numericOptions;
  useEffect(() => {
    if (value) {
      let option = value;
      if (typeof value === 'object') {
        option = options.find(ele => ele.id === value.id);
      }
      setDefaultValue(option)
    } else {
      setDefaultValue({})
    }
  }, [value])
  return (
    <Autocomplete
      {...rest}
      value={defaultValue}
      options={options}
      getOptionSelected={(option, value) => {
        let isEqual = false;
        if (typeof value === 'string') {
          isEqual = option.name === value;
        } else if (value) {
          isEqual = option.name === value.name
        }
        return isEqual
      }}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => (
        <InputField
          {...params}
          variant="standard"
          label={label}
          placeholder={placeholder}
        />
      )}
    />
  )
}

YesNoSelection.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.object,
  isBoolean: PropTypes.bool
}
