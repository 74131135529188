//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useState } from 'react'

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from "prop-types";
import { withSnackbar } from "notistack";
import { Typography, InputLabel, Button } from '@material-ui/core'

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { formatDate } from '../../../utils/date';
import CandidateSuiteInvitePopUp from '../../ViewSearch/Components/SFPA/Components/ClientAppAccess/CandidateSuiteInvitePopUp'

function CandidateSuite(props) {
  const { contactDetails, activityLog, getActivityLogInfo } = props;
  const contact = contactDetails ? contactDetails : activityLog.contact;
  const [isCandidateSuiteInviteOpen, toggleCandidateSuiteOpen] = useState(false)
  const [isDisableSendButton, setIsDisableSendButton] = useState(false)
  const getName = () => {
    const firstName = contact.first_name || '';
    const lastName = contact.last_name || '';
    return firstName + ' ' + lastName
  }

  const openCAndidateSuitePopUp = () => {
    if (!contact.work_email && !contact.private_email) {
      setIsDisableSendButton(true)
    }
    else {
      setIsDisableSendButton(false)
    }
    toggleCandidateSuiteOpen(true);
  }

  const getCandidateSuiteSentUser = () => {
    const firstName = contact?.candidatesuite_sent_user?.first_name ? contact.candidatesuite_sent_user.first_name : ''
    const lastName = contact?.candidatesuite_sent_user?.last_name ? contact.candidatesuite_sent_user.last_name : ''
    if (lastName === '' && firstName === '') return 'N.A'
    else return firstName + ' ' + lastName
  }

  const handleCandidateSuitePopupClose = async (invited) => {
    try {
      toggleCandidateSuiteOpen(false)
      if (invited === true) {
        getActivityLogInfo && await getActivityLogInfo()
      }
    }
    catch (e) {
      console.log("Error found in handleCandidateSuitePopupClose::", e);
    }
  }

  return (
    <div className="d-flex contact-view input-form" style={{ overflow: 'hidden' }}>
      {isCandidateSuiteInviteOpen ? <CandidateSuiteInvitePopUp inviteFor={'candidate'} selectedDropDown={'CandidateSuite'} client={activityLog} isdisableSendButton={isDisableSendButton} onClose={handleCandidateSuitePopupClose} /> : null}
      <div className="w-100 section-content">
        <Typography className="section-label">{getName()}</Typography>
        <div className="d-flex flex-column mb-3">
          <InputLabel style={{ fontSize: 12 }} focused className="m-0" >CandidateSuite Invite Sent on</InputLabel>
          <span>{formatDate(contact?.candidatesuite_invitation_sent_on) || 'N.A'}</span>
        </div>
        <div className="d-flex flex-column mb-3">
          <InputLabel style={{ fontSize: 12 }} focused className="m-0" >CandidateSuite Invite Sent By</InputLabel>
          <span>{getCandidateSuiteSentUser() || 'N.A'}</span>
        </div>
        <div className="d-flex flex-column mb-3">
          <InputLabel style={{ fontSize: 12 }} focused className="m-0" >CandidateSuite First Logged In On</InputLabel>
          <span>{formatDate(contact?.candidatesuite_first_login_date) || 'N.A'}</span>
        </div>
        <div className="d-flex flex-column mb-3">
          <InputLabel style={{ fontSize: 12 }} focused className="m-0" >CandidateSuite Last Logged In On</InputLabel>
          <span>{formatDate(contact?.candidatesuite_last_login_date) || 'N.A'}</span>
        </div>

        {!contactDetails ? <Button onClick={openCAndidateSuitePopUp} variant="contained" color="primary" className="mt-3" style={{ textTransform: 'none' }}>Send Invite for CandidateSuite</Button> : null}
      </div>
    </div>
  )
}

CandidateSuite.propTypes = {
  activityLog: PropTypes.object,
  contactDetails: PropTypes.object,
  getActivityLogInfo: PropTypes.func
}

export default withSnackbar(CandidateSuite);
