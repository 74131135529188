//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from "react";

//-----------------------------------------------------------// Internal Imports // ------------------------------

import InvoiceSummary from "./List";

export default function YetToBeBilled() {
  return (
    <InvoiceSummary
      type="yetToBeBilled"
      storageKey="invoiceSummery"
      title="Yet To Be Billed Invoice"
    />
  );
}