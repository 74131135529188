//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useRef } from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from "prop-types";
import Popup from "reactjs-popup";
import { Button, Typography } from "@material-ui/core";
import { Controller, useForm } from "react-hook-form";
import CloseIcon from "@material-ui/icons/Close";
import * as yup from "yup";

//----------------------------------------------// Internal Imports // -------------------------------------------------
import ContactSelection from "../../ContactSelection/ContactSelection";
import { useSnackbar } from "notistack";
import SearchSelection from "../../SearchSelection";
import { customFormValidator, requireValidMessage } from "../../../utils/common";
import { ERROR } from "../../../services/constantService";
export default function AddContactToWorkbench(props) {
  const { addToWorkbench, handleClose, open, type, title = "Contact" } = props
  const { control, handleSubmit } = useForm()
  const { enqueueSnackbar } = useSnackbar();
  const contactsRef = useRef()
  const searchesRef = useRef();

  const ContactSchema = yup.object().shape({
    contacts: yup.array().required(),
  });

  const SearchSchema = yup.object().shape({
    search_id: yup.string().required(),
  });

  const handleAdd = async (data) => {
    try{  
      let success;
      if(data.contacts){
        const valid = await ContactSchema.isValid(data);
        if(!valid){
          let requiredField = [
            { fieldName: "contacts", label: "Contact Name", type: [] },
          ]
          let dirtyField = customFormValidator(data, requiredField)
          if(dirtyField){
            const message = requireValidMessage(dirtyField)
            enqueueSnackbar(message, { variant: ERROR })
            return
          }
        }
      }
      else {
        const valid = await SearchSchema.isValid(data);
        if(!valid){
          let requiredField = [
            { fieldName: "search_id", label:"Search Name", type: String },
          ]
          let dirtyField = customFormValidator(data, requiredField)
          if(dirtyField){
            const message = requireValidMessage(dirtyField)
            enqueueSnackbar(message, { variant: ERROR })
            return
          }
        }
      } 
      if (type === "Searches") {
        success = await addToWorkbench([data?.search_id]);
      } else {
        success = await addToWorkbench(data.contacts.map(contact => contact.id))
      }
      handleClose(success)
    }catch(e){
      console.log("Error found in handleAdd::", e);
    }
  }

  const handleCancel = () => handleClose()

  return (
    <Popup open={open} className='add-contact-to-workbench' closeOnDocumentClick={false} closeOnEscape={false}>
      <div className='quick-add-contact'>
        <div className="quick-add-header">
          <Typography>Add {title} To Workbench</Typography>
          <CloseIcon cursor='pointer' onClick={handleCancel} />
        </div>
        <div className={'quick-add-container'}>
          <div className='d-flex flex-column h-100'>
            <div className='quick-add-content'>
              {type === 'Searches' ? (
                <Controller
                  name='search_id'
                  defaultValue={null}
                  as={
                    <SearchSelection
                      inputRef={searchesRef}
                      className='field-value'
                      // required={true}
                      label={<label>Search Name<span className="MuiInputLabel-asterisk"> *</span></label>}
                      placeholder={"Search"}
                      // contactId={contactId}
                      searchResult="All"
                    />
                  }
                  control={control}
                  onFocus={() => {
                    searchesRef.current.focus()
                  }}
                  onChange={([, data]) => data ? data.id : null}
                  // rules={{required: true}}
                />
              ) : (
                <Controller
                  name='contacts'
                  as={
                    <ContactSelection
                      multiple={true}
                      // required={true}
                      filterSelectedOptions={true}
                      inputRef={contactsRef}
                      className='input-form-field input-field-old'
                      label={<label>Contact Name<span className="MuiInputLabel-asterisk"> *</span></label>}
                      placeholder={"Search"}
                      InputLabelProps={{ focused: true }}
                    />
                  }
                  defaultValue={[]}
                  control={control}
                  onFocus={() => {
                    contactsRef.current.focus()
                  }}
                  onChange={([, data]) => data}
                  // rules={{ required: true, validate: data => data.length > 0 }}
                />
              )}
            </div>
            <div className="quick-add-footer">
              <Button variant="text" color="primary" className="button" onClick={handleCancel}>Cancel</Button>
              <Button
                variant="contained"
                onClick={handleSubmit(handleAdd)}
                className="button"
                color="primary"
              >
                Add To Workbench
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Popup>
  )
}

AddContactToWorkbench.propTypes = {
  open: PropTypes.bool,
  addToWorkbench: PropTypes.func,
  handleClose: PropTypes.func,
  type: PropTypes.string,
  title: PropTypes.string
}
