
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from "react";

//-----------------------------------------------------------// Internal Imports // ------------------------------

import Contacts from "./Contacts";

export default function MyBDTargetsList() {
  return (
    <Contacts
      type="my-bd-targets"
      storageKey="contactMyBDTargetColumns"
      title="My BD Targets"
    />
  );
}
