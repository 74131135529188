//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from "prop-types";
import { Typography, Button } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import AddCircleIcon from "@material-ui/icons/AddCircle";

//----------------------------------------------// Internal Imports // -------------------------------------------------
import YearSelection from "../../YearSelection";
import CompanySelection from "./CompanySelection";
import InputField from "../../../components/common/InputField";

export default function BoardDetails(props) {
  const { register, setValue, currentValues } = props;
  const [boardDetails, setBoardDetails] = useState([])
  const [startYear, setStartYear] = useState('');

  useEffect(() => {
    register('board_details');
    if (currentValues.board_details.length > 0) {
      setBoardDetails(currentValues.board_details)
    } else {
      setBoardDetails([{}, {}])
    }
  }, [currentValues, register])

  useEffect(() => {
    setValue('board_details', boardDetails);
  }, [boardDetails, setValue])

  const handleChange = (index, field, value) => {
    const currentBoardDetails = [...boardDetails]
    currentBoardDetails[index][field] = value
    if (currentBoardDetails[index]['company'] === null) {
      currentBoardDetails[index]['company_id'] = null
    }
    setBoardDetails(currentBoardDetails)
  }

  const addBoardCompany = () => {
    setBoardDetails(prevState => ([...prevState, {}]))
  }

  const removeBoardCompany = (index) => {
    let details = [...boardDetails];
    details.splice(index, 1)
    setBoardDetails(details)
  }

  return (
    <div className="w-100 section-content">
      <Typography className="section-label">Board Details</Typography>
      {boardDetails.map((item, index) => (
        <div key={props.dynamicKey + 'board' + index} className="section-sub-content">
          <div className='d-flex'>
            <Typography className="sub-section-label" component={"legend"}>BOARD COMPANY {index + 1}</Typography>
            <CloseIcon cursor='pointer' onClick={() => removeBoardCompany(index)} style={{ width: 18, height: 18, marginRight: 15 }} />
          </div>
          <div className="d-flex flex-grow-1"  >
            <CompanySelection
              defaultValue={item.company}
              onChange={(e, company) => {
                handleChange(index, 'company', company)
              }}
              className='input-form-field input-field-old'
              label='Company Name'
              placeholder={"Search"}
              InputLabelProps={{ focused: true }}
            />
            <InputField
              value={item.title || ''}
              className='input-form-field input-field-old'
              label='Title'
              placeholder='Title'
              InputLabelProps={{ focused: true }}
              onChange={(e) => { handleChange(index, 'title', e.target.value) }}
            />
          </div>
          <div className='d-flex flex-grow-1'>
            <YearSelection
              className='input-form-field input-field-old'
              label='Start Year'
              InputLabelProps={{ focused: true }}
              value={item.start_date || null}
              onChange={(e, data) => {
                setStartYear(data)
                handleChange(index, 'start_date', data);
              }}
              yearType={"start"}
            />
            <YearSelection
              className='input-form-field input-field-old'
              label='End Year'
              InputLabelProps={{ focused: true }}
              minYear={Date(BoardDetails.start_year)}
              value={item.end_date || null}
              onChange={(e, data) => {
                handleChange(index, 'end_date', data);
              }}
              yearType={"end"}
              selectedStartYear={startYear}
            />
          </div>
          <InputField
            value={item.committee || ''}
            className='input-form-field input-field-old w-50'
            label='Committee'
            placeholder='Committee'
            InputLabelProps={{ focused: true }}
            onChange={(e) => { handleChange(index, 'committee', e.target.value) }}
          />
        </div>
      ))}
      <Button
        variant={"text"}
        startIcon={<AddCircleIcon />}
        onClick={addBoardCompany}
        color='primary'>
        Add Board Company
      </Button>
    </div>
  );
}
BoardDetails.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  currentValues: PropTypes.object,
  watch: PropTypes.func,
  getValues: PropTypes.func,
  dynamicKey: PropTypes.string
};
