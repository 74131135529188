//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useState, useEffect } from 'react'

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from "prop-types";
import Popup from "reactjs-popup";
import CloseIcon from '@material-ui/icons/Close'
import { Button } from '@material-ui/core';
import { InputLabel, Typography } from '@material-ui/core'
import { KeyboardDatePicker } from "@material-ui/pickers";
import _ from 'lodash';
import './index.scss'
//----------------------------------------------// Internal Imports // -------------------------------------------------

// import InputField from '../../../../common/InputField'

import { Checkbox, FormControl, FormControlLabel } from "@material-ui/core";
import { requireMessage, successMessage, unableMessage } from '../../services/MessageService';
import { ERROR, INVOICE_VALIDATION, PUT, SUCCESS } from '../../services/constantService';
import RichTextPopup from '../../components/RichTextPopup';
import ConfirmationPopup from '../../components/ConfirmationPopup';
import { formatDate, formatTime } from '../../utils/date';
import { searchDataApi } from '../../services/ApiService';
import MessageLoader from "../../components/common/MessageLoader/MessageLoader";

export default function ConfirmProjectedDatePopup(props) {

    const { onClose, projectedData, enqueueSnackbar,gridApi } = props;
    const [isConfirmPopup, setConfirmPopup] = useState(false);
    const [isValueChanged, setValueChanged] = useState(false);
    const [updatedData, setUpdatedData] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [statusMessage, setStatusMessage] = useState('')

    const prepareInvoiceInfoPayload = (formData,updateType) => {
        const payload = {
            id: formData.searchInvoiceInfo.id,
            invoice_info_obj: {
                id: formData.id,
                search_id: formData.search_id,
                projected_bill_date: formData.projected_bill_date,
                comments: formData.comments,
                ready_to_bill: formData.ready_to_bill,
            },
            updateType: updateType,
        };
    
        return payload;
    };
    
      const updateSearchInvoiceInfo = async (message, updateType) => {
        try {
          
          let formData = prepareInvoiceInfoPayload(updatedData.data, updateType)
          setLoading(true);
          setStatusMessage(message)
          const { status, data: response } = await searchDataApi(PUT, updatedData.data.search_id, formData, 'invoice-info')
          if (status === 200) {
            const message = successMessage("Record", updateType + 'd')
            enqueueSnackbar(response.message || message, { variant: SUCCESS })
            return true;
          } else if (status === 409) {
            const message = unableMessage("record", updateType)
            enqueueSnackbar(message, { variant: ERROR })
            setStatusMessage('')
          } else {
            const message = unableMessage("record", updateType)
            enqueueSnackbar(message, { variant: ERROR })
          }
          setLoading(false);
          setStatusMessage('')
        } catch (err) {
          console.log("Error found in updateSearchInvoiceInfo::", err);
        }
    }

    const handleClose = () => {
        const projectedBillDateChanged = updatedData
            ? updatedData.data.projected_bill_date !== projectedData.data.projected_bill_date
            : false;

        if (
            projectedBillDateChanged ||
            projectedData.data.comments !== projectedData.data.comments ||
            projectedData.data.ready_to_bill !== projectedData.data.ready_to_bill
        ) {
            setConfirmPopup(true);
        } else {
            onClose && onClose();
        }
    };
    const handleConfirmPopup = () => {
        onClose && onClose()
    }

    const handleCancelPopup = () => {
        setConfirmPopup(false)
    }

    const onSave = async () => {
        if (!updatedData.data.projected_bill_date) {
            const message = requireMessage('Projected Date', 'is');
            enqueueSnackbar(message, { variant: ERROR });
            return;
        }
    
        if (!updatedData.data.comments && updatedData.data.ready_to_bill) {
            const message = requireMessage('Comments', 'are');
            enqueueSnackbar(message, { variant: ERROR });
            return;
        }
    
        const isDataChanged =
            updatedData.data.projected_bill_date !== projectedData.data.projected_bill_date ||
            updatedData.data.comments !== projectedData.data.comments ||
            updatedData.data.ready_to_bill !== projectedData.data.ready_to_bill;
    
        if (isDataChanged) {
            const res = await updateSearchInvoiceInfo('updating record', 'update');
            if (res) {
                onClose && onClose();

            }
        } else {
            onClose && onClose();
        }
        gridApi.onFilterChanged();
    };
    

    const handleCommentsChange = (data) => {
        if (data.comments === '<p><br /></p>') {
            data.comments = '';
        }
        const updatedProjectedData = { ...projectedData, data: { ...projectedData.data, comments: data.comments } };
        setUpdatedData(updatedProjectedData);
        setValueChanged(true);
    };

    const handleReadyToBilledChange = (e) => {
        const updatedProjectedData = { ...projectedData, data: { ...projectedData.data, ready_to_bill: e } };
        setUpdatedData(updatedProjectedData);
        setValueChanged(true);
    }

    const handleDateChange = (date) => {
        const updatedProjectedData = { ...projectedData, data: { ...projectedData.data, projected_bill_date: date } };
        setUpdatedData(updatedProjectedData);
        setValueChanged(true);
    }

    return (
        <Popup overlayStyle={{ zIndex: '1401 !important' }} className="confirmation-projectDate confirmation-projectDate-popup" open={true} modal closeOnDocumentClick={false} closeOnEscape={false} >
              <MessageLoader show={isLoading} message={statusMessage} />
            <>
                {isConfirmPopup ? <ConfirmationPopup
                    header={INVOICE_VALIDATION.UNSAVED_DATA}
                    onConfirm={handleConfirmPopup}
                    onCancel={handleCancelPopup}
                    onClose={handleCancelPopup}
                    cancelText='No'
                    confirmText='Yes'
                /> : null}
                <div className="" style={{ background: '#2a364c', color: '#fff' }}>
                    <div className="quick-add-header">
                        <Typography className='text-left'>Projected Bill Date</Typography>
                        <CloseIcon className="cursor-pointer" onClick={handleClose} />
                    </div>
                </div>
                <div className='d-flex flex-column py-3'>
                    <div className='d-flex contact-details-row'>
                        <div className='d-flex intersection-page-view'>
                            <InputLabel className="page-label">Projected Bill Date<span className="MuiInputLabel-asterisk"> *</span></InputLabel>
                            <div className='page-label-value'>
                                <KeyboardDatePicker
                                    format='MM/dd/yyyy'
                                    value={updatedData ? updatedData.data.projected_bill_date : projectedData.data.projected_bill_date || null}
                                    autoOk
                                    className='input-form-field padding-remove'
                                    InputLabelProps={{ focused: true }}
                                    label={''}
                                    placeholder={'Projected Date'}
                                    inputVariant="outlined"
                                    onChange={(date) => handleDateChange(date)}
                                />
                            </div>
                        </div>
                        <div className='d-flex intersection-page-view'>
                            <InputLabel className="page-label">Ready To Bill</InputLabel>
                            <div className='page-label-value text-left'>
                                <FormControl className='w-auto' style={{ paddingLeft: '12px' }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                size='small'
                                                checked={updatedData ? updatedData.data.ready_to_bill : projectedData.data.ready_to_bill}
                                                onChange={(e) => {
                                                    handleReadyToBilledChange(e.target.checked)
                                                }}
                                            />
                                        }
                                        label=""
                                        style={{ color: "black" }}
                                    />
                                </FormControl>

                            </div>
                        </div>
                    </div>
                    <div className='d-flex contact-details-row'>
                        <div className='d-flex intersection-page-view'>
                        <InputLabel className="page-label">Comments</InputLabel>
                            {(updatedData ? updatedData.data.ready_to_bill : projectedData.data.ready_to_bill) && (
                                <span className="MuiInputLabel-asterisk"> *</span>
                            )}
                            <div className='page-label-value' style={{ minHeight: '45px', maxHeight: "fit-content" }}>
                                <div className='d-flex contact-view-value'>
                                    <div className='outlined-box rich-text-field transform ' style={{ width: '100%' }}>
                                        <RichTextPopup
                                            className="input-form-field text-left"
                                            label="Comments"
                                            placeholder='Comments'
                                            title="Comments"
                                            InputLabelProps={{ focused: true }}
                                            name='comments'
                                            variant="outlined"
                                            value={updatedData ? updatedData.data.comments : projectedData.data.comments || ''}
                                            onSave={(data) => handleCommentsChange(data)}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='text-left'> <p className='footer-text pl-2'> Last updated by <span style={{ fontWeight: 900 }}>{projectedData?.data?.user ? projectedData?.data?.user?.first_name + ' ' + projectedData?.data?.user?.last_name : ''}</span> On {formatDate(projectedData.data.updated_at ? projectedData.data.updated_at : projectedData.data.created_at) || ''} , {formatTime(projectedData.data.updated_at) || ''}</p></div>
                <div className="page-content-footer">
                    <Button variant="contained" type="submit" className="page-btn-save" disabled={!isValueChanged} onClick={onSave} >Confirm & Save</Button>

                </div>
            </>
        </Popup>
    )
}

ConfirmProjectedDatePopup.propTypes = {
    onClose: PropTypes.func,
    projectedData: PropTypes.object,
    invoiceList: PropTypes.object,
    updateField: PropTypes.func,
    setInvoiceList: PropTypes.func,
    register: PropTypes.func,
    setValue: PropTypes.func,
    currentValues: PropTypes.object,
    watch: PropTypes.func,
    enqueueSnackbar: PropTypes.func
}
