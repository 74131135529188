//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect } from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";

//----------------------------------------------// Internal Imports // -------------------------------------------------
import SearchDocument from "./Components/SearchDocuments";
import Notes from "./Components/Notes";
import Header from "../Header";
import NotesView from "./Components/NotesView";
import SearchDocumentView from "./Components/SearchDocumentsView";
function SearchDocuments(props) {

  const { register, setValue, watch, control, currentValues, isEditing = false, setSearch, search, jobTitle, confidential, handleCancel, handleSave, handleEdit, setUpdatelLoader, reloadData, handelScroll, getValues, unregister } = props

  useEffect(() => {
    setTimeout(() => {
      handelScroll()
    }, 3000)

  }, []);

  return (
    <>
      <Header
        companyDetails={search.company}
        company={search.company && search.company.name}
        jobNumber={search.job_number}
        jobTitle={jobTitle}
        confidential={confidential}
      >
        <div className="d-flex">
          {isEditing ?
            <React.Fragment>
              <Button color='primary' className='button' style={{ color: '#2A364C', border: '1px solid #2A364C', borderRadius: '4px', marginRight: 25 }} variant='text' onClick={handleCancel} >Cancel</Button>
              <Button color='primary' className='button' style={{ backgroundColor: '#4BB543', color: "white" }} variant='contained' onClick={handleSave}>Save</Button>
            </React.Fragment> :
            <React.Fragment>
              <div className="action-container d-flex align-items-center" >
                <Button style={{ background: '#2A364C', color: '#ffff' }} className='button' variant='contained' onClick={handleEdit}>Edit</Button>
              </div>
            </React.Fragment>
          }
        </div>
      </Header>
      <div className='input-form d-flex'>
        <div className='tab-view-content col-sm-12 p-0'>
          <div id='searchDocument'>
            {
              isEditing ?
                <SearchDocument
                  register={register}
                  setValue={setValue}
                  watch={watch}
                  isEditing={isEditing}
                  currentValues={currentValues}
                  setSearch={setSearch}
                  control={control}
                  getValues={getValues}
                  unregister={unregister}
                  reloadData={reloadData}
                /> :
                <SearchDocumentView register={register}
                  setValue={setValue}
                  watch={watch}
                  isEditing={isEditing}
                  currentValues={currentValues}
                  setSearch={setSearch}
                  control={control}
                  getValues={getValues}></SearchDocumentView>
            }
          </div>
          <div id='notes'>
            {
              isEditing ?
                <Notes
                  register={register}
                  setValue={setValue}
                  watch={watch}
                  isEditing={isEditing}
                  control={control}
                  currentValues={currentValues}
                  
                />
                :

                <NotesView
                  setValue={setValue}
                  isEditing={isEditing}
                  currentValues={currentValues}
                  control={control}
                  reloadData={reloadData}
                  setUpdatelLoader={setUpdatelLoader}
                  setSearch={setSearch}
                  register={register}
                  watch={watch}
                />


            }
          </div>
        </div>
      </div>
    </>
  )
}

SearchDocuments.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  watch: PropTypes.func,
  control: PropTypes.object,
  currentValues: PropTypes.object,
  setSearch: PropTypes.func,
  isEditing: PropTypes.bool,
  search: PropTypes.object,
  jobTitle: PropTypes.string,
  confidential: PropTypes.bool,
  candidateStatus: PropTypes.string,
  handleCancel: PropTypes.func,
  handleSave: PropTypes.func,
  handleEdit: PropTypes.func,
  setUpdatelLoader: PropTypes.func,
  reloadData: PropTypes.func,
  handelScroll: PropTypes.func,
  getValues: PropTypes.func,
  unregister: PropTypes.func
}

export default SearchDocuments;
