//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { fetchPickList } from "../../actions";

function ColorSelection(props) {
  const { label = "Type", placeholder = "", InputLabelProps, value, ...rest } = props

  const [options, setOptions] = useState([])
  const colors = useSelector(state => state.commonReducer.colors)
  const dispatch = useDispatch()
  const [defaultColor, setDefaultColor] = useState(value);
  // const [valueColorCode, setValueColorCode] = useState('');

  useEffect(() => {
    if (!colors) {
      dispatch(fetchPickList('colors'))
    } else {
      setOptions(colors);
    }
  }, [colors, dispatch])

  useEffect(() => {
    if (value) {
      const color1 = colors?.find(ele => ele.value === value?.value)
      color1 && setDefaultColor(color1)
    } else {
      setDefaultColor({})
    }
  }, [value, colors])

  return (
    <Autocomplete
      {...rest}
      options={options}
      value={defaultColor}
      getOptionLabel={(option) => {
        // setValueColorCode(option.hex_code);
        return option.name
      }}
      renderOption={option =>
        <>
          <div
            key={option.hex_code}
            className="color-code-container"
            style={{
              background: `#${option.hex_code}`,
              color: `#${option.hex_code}`,
              width: 25,
              height: 25,
              border: '1px solid #DDDDE9'
            }}
          />
          <span style={{ paddingLeft: 4, fontSize: 16, fontWeight: "bold" }}>
            {` ${option.name}`}
          </span>
        </>
      }
      renderInput={(params) => (
        <TextField
          {...params}
          // InputProps={{
          //   ...params.InputProps,
          //   startAdornment: params.inputProps.value ?
          //     <div style={{ border: '1px solid #DDDDE9', width: 20, height: 20, backgroundColor: `#${valueColorCode}` }} />
          //     :
          //     params.inputProps.startAdornment
          // }}
          InputLabelProps={InputLabelProps}
          label={label}
          placeholder={placeholder}
        />
      )}
    />
  )
}

ColorSelection.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  InputLabelProps: PropTypes.object,
  value: PropTypes.object
}

export default ColorSelection;