import React, { forwardRef, useImperativeHandle, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import httpService from "../../../services/httpService";
import { API } from "../../../services/constantService";
import RichTextPopup from "../../../components/RichTextPopup";


const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);


const RichTextBoxEditor = forwardRef((props, ref) => {
  const { stopEditing, value: initialValue, column, data, title, type } = props;
  const [open, setOpen] = React.useState(true);
  const [currentValue, setCurrentValue] = React.useState(initialValue);
  const [saveStatus, setSaveStatus] = useState(true);
  const [updatedTime, setUpdatedTime] = useState(data && data.updated_at ? data.updated_at : '');
  const [isAutoSave, setIsAutoSave] = useState(false);
  const handleClose = () => {
    setOpen(false);
    stopEditing();
  };

  const autoSave = async (value, skipMessage, commentType, item, setAutoSaveLoading) => {
    let body = {}
    body[column.colId] = value[Object.keys(value)[0]]
    setAutoSaveLoading && setAutoSaveLoading(true)
    setIsAutoSave(true)
    setCurrentValue(value[Object.keys(value)[0]])
    const { status } = await httpService.put(`${API[commentType]}/${data.id}`, body);
    if (status === 200) {
      setSaveStatus(true)
      setUpdatedTime(new Date().toLocaleString('en-US'))
    } else if (status === 409) {
      setSaveStatus(false)
    }
    setIsAutoSave(false)
    setAutoSaveLoading && setTimeout(() => {
      setAutoSaveLoading(false)
    }, 1000)
  };

  const onSave = async (data) => {
    setCurrentValue(data[Object.keys(data)[0]]);
  }

  useEffect(() => {
    if ((currentValue !== initialValue) && !isAutoSave) {
      handleClose();
    }
    // eslint-disable-next-line
  }, [initialValue, currentValue]);

  const handleChange = (text) => {
    setCurrentValue(text);
  };

  useImperativeHandle(ref, () => {
    return {
      getValue: () => currentValue,
      afterGuiAttached: () => { },
    };
  });

  return (
    <div style={{ position: "relative" }}>
      <Dialog
        maxWidth="sm"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogContent dividers>
          <RichTextPopup
            onChange={(data) => {
              handleChange(data);
            }}
            autoSave={autoSave}
            onClose={handleClose}
            commentType={type}
            grid={true}
            onSave={onSave}
            value={currentValue}
            saveStatus={saveStatus}
            title={title}
            updatedDate={updatedTime}
            payloadType={'default'}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
});

RichTextBoxEditor.displayName = "RichTextBoxEditor";

RichTextBoxEditor.propTypes = {
  stopEditing: PropTypes.func,
  column: PropTypes.object,
  data: PropTypes.object,
  type: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.string,
};

export default RichTextBoxEditor;
