//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from "prop-types";
import { Typography, Button } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { withSnackbar } from "notistack";
import CloseIcon from "@material-ui/icons/Close";

//----------------------------------------------// Internal Imports // -------------------------------------------------
import ExpertiseSelection from "../../languageExpertise";
import LanguageSelection from "../../LanguageSelection";


const LanguageDetails = (props) => {
  const { register, setValue, currentValues, showTitle = true, disabled = false, show = true, dynamicKey } = props;
  const [languageDetails, setLanguageDetails] = useState([])
  useEffect(() => {
    register('contact_languages');
    if (currentValues.contact_languages && currentValues.contact_languages.length > 0) {
      setLanguageDetails(currentValues.contact_languages)
    } else {
      setLanguageDetails([{}])
    }
  }, [currentValues, register])

  useEffect(() => {
    setValue('contact_languages', languageDetails);
  }, [languageDetails, setValue])

  const handleChange = (index, field, value) => {
    let currentLanguageDetails = [...languageDetails]
    currentLanguageDetails[index][field] = value
    currentLanguageDetails[index]['position'] = index;
    if (field === 'language')
      currentLanguageDetails[index].language_id = value?.id
    if (field === 'language_expertise')
      currentLanguageDetails[index].expertise_id = value?.id
    setLanguageDetails(currentLanguageDetails)
  }

  const addLanguage = () => {
    setLanguageDetails(prevState => ([...prevState, {}]))
  }

  const removeLanguage = (index) => {
    let details = [...languageDetails];
    details.splice(index, 1)
    setLanguageDetails(details)
  }

  return (
    <div className='w-100 section-content education-details'>
      {showTitle && <Typography className='section-label'>Languages</Typography>}
      {languageDetails.map((item, index) => {
        return (
          <div key={dynamicKey + 'board' + index} className="section-sub-content">
            <div className='d-flex'>
              <Typography className="sub-section-label" component={"legend"}>Entry {index + 1}</Typography>
              {show && <CloseIcon cursor='pointer' onClick={() => removeLanguage(index)} style={{ width: 18, height: 18, marginRight: 15 }} />}
            </div>
            <div className='d-flex flex-grow-1'>
              <LanguageSelection
                className='input-form-field input-field-old'
                value={item.language || null}
                onChange={(e, data) => {
                  handleChange(index, 'language', data)
                }}
                InputLabelProps={{ focused: true }}
                disabled={disabled}
              />
              <ExpertiseSelection
                className='input-form-field input-field-old'
                InputLabelProps={{ focused: true }}
                value={item.language_expertise || null}
                onChange={(e, data) => {
                  handleChange(index, 'language_expertise', data)
                }}
              />
            </div>
          </div>
        );
      })}
      <Button variant={"text"} startIcon={<AddCircleIcon />} disabled={disabled} onClick={addLanguage} color='primary'>
        Add Language
      </Button>
    </div>
  );
};

LanguageDetails.propTypes = {
  register: PropTypes.func,
  dynamicKey: PropTypes.string,
  control: PropTypes.object,
  enqueueSnackbar: PropTypes.func,
  setValue: PropTypes.func,
  currentValues: PropTypes.object,
  showTitle: PropTypes.bool,
  disabled: PropTypes.bool,
  show: PropTypes.bool
};

export default withSnackbar(LanguageDetails);
