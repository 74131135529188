//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect } from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types'
import NumberFormat from 'react-number-format';
import Grid from "@material-ui/core/Grid";
import { InputLabel, Typography, TextField } from "@material-ui/core";

//----------------------------------------------// Internal Imports // -------------------------------------------------
import CurrencySelection from "../../../../../CurrencySelection";
import { separator } from "../../../../../../Containers/Commons/Utils";
import { DECIMAL_SEPARATOR, THOUSAND_SEPARATOR } from "../../../../../../services/constantService";
import InputField from "../../../../../common/InputField";

export default function TargetCompensation(props) {
  const { register, unregister, watch, setValue, currencyDetails, readOnly } = props;
  useEffect(() => {
    register('target_base_max');
    register('target_base_min');
    register('target_bonus');
    register('target_equity');
    register('currency');

    return () => {
      unregister('target_base_max');
      // unregister('target_equity');
      unregister("target_base_min");
      unregister("target_bonus");

    };
  }, [register])

  return (
    <div className={`section-content ${readOnly ? 'read-only' : ''}`}>
      <Typography className="section-label">Target Compensation</Typography>
      <Grid item xs={12} >
        <div className="d-flex flex-grow-1">
          <div className='contact-details '>
            <CurrencySelection
              value={watch('currency') || null}
              className="input-form-field"
              InputLabelProps={{ focused: true }}

              label="Currency"
              placeholder="Currency"
              onChange={(e, data) => {
                setValue("currency", data ? data.code : null);
              }}
            />
          </div>
          <div className='contact-details '> <div className="input-form-field"></div></div>
        </div>
        <div className="d-flex flex-grow-1">
          <div className='contact-details'>
            {/* <InputLabel className="contact-view-label " >Base From</InputLabel> */}
            <NumberFormat
              customInput={TextField}
              label="Base From"
              placeholder="Base From"
              InputLabelProps={{ focused: true }}
              className="input-form-field "
              // style={removerBorder}
              value={watch("target_base_min")}
              thousandSeparator={separator(watch('currency'), THOUSAND_SEPARATOR)}
              decimalSeparator={separator(watch('currency'), DECIMAL_SEPARATOR)}
              decimalScale={2}
              isNumericString={true}
              prefix={currencyDetails ? (currencyDetails.prefix ? currencyDetails.currency_icon : '') : ''}
              suffix={currencyDetails ? (currencyDetails.prefix === false ? currencyDetails.currency_icon : '') : ''}
              onValueChange={(values) => {
                const { value } = values;
                setValue("target_base_min", value);
              }}
            />
          </div>
          <div className='contact-details'>
            {/* <InputLabel className="contact-view-label " >Base To</InputLabel> */}
            <NumberFormat
              customInput={TextField}
              label="Base To"
              placeholder="Base To"
              InputLabelProps={{ focused: true }}
              className="input-form-field "
              // style={removerBorder}
              value={watch("target_base_max")}
              thousandSeparator={separator(watch('currency'), THOUSAND_SEPARATOR)}
              decimalSeparator={separator(watch('currency'), DECIMAL_SEPARATOR)}
              decimalScale={2}
              isNumericString={true}
              prefix={currencyDetails ? (currencyDetails.prefix ? currencyDetails.currency_icon : '') : ''}
              suffix={currencyDetails ? (currencyDetails.prefix === false ? currencyDetails.currency_icon : '') : ''}
              onValueChange={(values) => {
                const { value } = values;
                setValue("target_base_max", value);
              }}
            />
          </div>
        </div>
        <div className="d-flex flex-grow-1">
          <div className='contact-details'>
            {/* <InputLabel className="contact-view-label " >Bonus</InputLabel> */}
            <NumberFormat
              customInput={TextField}
              label="Bonus"
              placeholder="Bonus"
              InputLabelProps={{ focused: true }}
              className="input-form-field "
              // style={removerBorder}
              value={watch("target_bonus")}
              thousandSeparator={separator(watch('currency'), THOUSAND_SEPARATOR)}
              decimalSeparator={separator(watch('currency'), DECIMAL_SEPARATOR)}
              decimalScale={2}
              isNumericString={true}
              prefix={currencyDetails ? (currencyDetails.prefix ? currencyDetails.currency_icon : '') : ''}
              suffix={currencyDetails ? (currencyDetails.prefix === false ? currencyDetails.currency_icon : '') : ''}
              onValueChange={(values) => {
                const { value } = values;
                setValue("target_bonus", value);
              }}
            />
          </div>
          <div className='contact-details pt-2'>
            <InputLabel className="contact-view-label " >Total Base and Bonus</InputLabel>
            <NumberFormat
              style={{ border: 'none' }}
              value={(watch('target_base_min') || watch('target_bonus')) ? parseFloat(watch('target_base_min') || 0) + parseFloat(watch('target_bonus') || 0) : ''}
              InputLabelProps={{ focused: true }}
              thousandSeparator={separator(watch('currency'), THOUSAND_SEPARATOR)}
              decimalSeparator={separator(watch('currency'), DECIMAL_SEPARATOR)}
              decimalScale={2}
              isNumericString={true}
              displayType='text'
              prefix={currencyDetails ? (currencyDetails.prefix ? currencyDetails.currency_icon : '') : ''}
              suffix={currencyDetails ? (currencyDetails.prefix === false ? currencyDetails.currency_icon : '') : ''}
            />
          </div>
        </div>
        <div className="d-flex flex-grow-1 w-50">
          <div className='contact-details '>
            <InputField
              // ref={register()}
              value={watch("target_equity") || ""}
              className="input-form-field"
              label="Equity"
              placeholder="Equity"
              InputLabelProps={{ focused: true }}
              name="target_equity"
              onChange={(e) => setValue('target_equity', e.target.value)}
            />
            <div className='contact-details '> <div className="input-form-field"></div></div>
          </div>
        </div>
      </Grid>
    </div>
  );
}

TargetCompensation.propTypes = {
  register: PropTypes.func,
  unregister: PropTypes.func,
  readOnly: PropTypes.bool,
  watch: PropTypes.func,
  setValue: PropTypes.func,
  currencyDetails: PropTypes.object
};
