//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useCallback, useState } from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types'
import { Button, Typography } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import update from 'immutability-helper';
import DragIndicatorOutlinedIcon from '@material-ui/icons/DragIndicatorOutlined';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

//----------------------------------------------// Internal Imports // -------------------------------------------------
import DraggableComponent from '../Draggable/DraggableComponent';
import InputField from "../../../../../common/InputField";
import ItemTypes from '../Draggable/ItemTypes';
export default function Weather(props) {
  const { register, setValue, currentValues } = props
  if (currentValues.cs_location_weather.length === 0) { currentValues.cs_location_weather = [{}] }
  const [weather, setWeather] = useState([{}])
  const skillIndex = 0;
  useEffect(() => {
    setWeather(currentValues.cs_location_weather);
    register('cs_location_weather')
  }, [currentValues,register])
  
  useEffect(() => {
    setValue('cs_location_weather', weather);
  }, [weather, setValue])

  const updateWeather = (index, name, data) => {
    const currentWeather = [...weather];
    currentWeather[index][name] = data;
    setWeather(currentWeather)
  }

  const deleteWeather = (index) => {
    const currentWeather = [...weather];
    currentWeather.splice(index, 1);
    setWeather(currentWeather)
  }

  const addWeather = () => {
    const currentWeather = [...weather, {}];
    setWeather(currentWeather)
  }

  const onSkillElementsChange = useCallback((skillIndex, elements) => {
    setWeather(elements)
  }, [])

  const moveElement = useCallback(
    (dragIndex, hoverIndex) => {
      const dragSkillElement = weather[dragIndex]
      let currentWeather = update(weather, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragSkillElement],
        ],
      });
      onSkillElementsChange(skillIndex, currentWeather)

    },
    [weather, skillIndex, onSkillElementsChange],
  )

  return (
    <div className="section-content">
      <Typography className="section-label">Weather</Typography>
      <DndProvider backend={HTML5Backend}>
        {weather.map((item, index) => <DraggableComponent key={index}
          index={index}
          id={item.id || index}
          moveNode={moveElement}
          componentType={ItemTypes.WEATHER}
          content={(dragHandle, preview) => (
            <div key={index} ref={preview} style={{ marginBottom: 10 }}>
              <Typography className='sub-section-label'>Weather Details {index + 1}</Typography>
              <div className='d-flex align-items-center'>
                <span ref={dragHandle} >
                  <DragIndicatorOutlinedIcon style={{ padding: '0px', color: 'gray' }} />
                </span>
                <InputField
                  label='Title'
                  placeholder='Title'
                  className='input-form-field'
                  value={item.title || ''}
                  InputLabelProps={{ focused: true }}
                  onChange={(e) => {
                    updateWeather(index, 'title', e.target.value)
                  }}
                />
                <InputField
                  label='Description'
                  placeholder='Description'
                  multiline
                  rowsMax="10"
                  className='input-form-field'
                  value={item.description || ''}
                  InputLabelProps={{ focused: true }}
                  onChange={(e) => {
                    updateWeather(index, 'description', e.target.value)
                  }}
                />
                <InputField
                  label='Website url'
                  placeholder='Website url'
                  className='input-form-field'
                  value={item.website_url || ''}
                  InputLabelProps={{ focused: true }}
                  onChange={(e) => {
                    updateWeather(index, 'website_url', e.target.value)
                  }}
                />
                <DeleteIcon
                  fontSize='small'
                  className="cursor-pointer"
                  onClick={() => deleteWeather(index)}
                />
              </div>
            </div>
          )} />)
        }
      </DndProvider>
      {weather.length < 10 ?
        <Button
          variant={"text"}
          startIcon={<AddCircleIcon />}
          onClick={addWeather}
          color="primary"
        >
          Add Weather
        </Button>
        : null
      }
    </div>
  )
}

Weather.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  currentValues: PropTypes.object
}
