//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect } from 'react'

//----------------------------------------------// External Imports // -------------------------------------------------
import Popup from "reactjs-popup";
import CloseIcon from '@material-ui/icons/Close'
import { Typography } from '@material-ui/core'
import PropTypes from "prop-types";
//----------------------------------------------// Internal Imports // -------------------------------------------------

export default function CustomInfoPopup(props) {
  const { onClose, children, className, header="" } = props;
  const closePopup = () => {
    onClose && onClose();
  }
  useEffect(() => {
    function handleEscapeKey(event) {
      if (event.keyCode === 27) {
        onClose();
      }
    }
    document.addEventListener("keydown", handleEscapeKey);
    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
    };
  }, [props]);

  return (
    <Popup className={`${className}`} overlayStyle={{ zIndex: '1401 !important' }} open={true} modal closeOnDocumentClick={false} closeOnEscape={false} >
      <>
        {header && <div className="" style={{ background: '#2a364c', color: '#fff' }}>
          <div className="quick-add-header">
            <Typography >{header}</Typography>
            {onClose && <CloseIcon className="cursor-pointer ml-2" onClick={closePopup} />}
          </div>
        </div>}
        {children}
      </>
    </Popup>
  )
}
CustomInfoPopup.propTypes = {
  onClose: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
  header: PropTypes.string  
}
