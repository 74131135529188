
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useEffect } from "react";

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Typography } from "@material-ui/core";

//-----------------------------------------------------------// Internal Imports // ------------------------------

import { getUserSelector } from '../../selectors';
import { DASHBOARD, ROUTES } from '../../services/constantService';
import { checkArByPartnerDepartment, checkDashboardDepartment, checkRegionalSalesDepartment } from '../Commons/Utils';
import DashboardContent from './DashboardContent';
import './index.scss'
import DashboardReports from "./DashboardReports";

const Dashboard = (props) => {
  const { viewName, showReport = false, dashboardName } = props;
  const userData = useSelector(getUserSelector);
  const history = useHistory()

  useEffect(() => {
    if (userData) {
      const validDepartment = checkDashboardDepartment(userData)
      const isPartner = checkArByPartnerDepartment(userData);
      const regionalSalesVisible = checkRegionalSalesDepartment(userData);
      if (viewName === DASHBOARD.AR_BY_PARTNER || viewName === DASHBOARD.LEGACY_AR_BY_PARTNER) {
        if (!(isPartner) && !validDepartment && !regionalSalesVisible) {
          history.replace(ROUTES.searches)
        }
      } else if (!validDepartment && !regionalSalesVisible) {
        history.replace(ROUTES.searches)
      }
    }
  }, [])

  return (
    <div className="d-flex flex-column w-100 h-100 ">
      <Helmet>
        <title>Insights - KG Galaxy</title>
      </Helmet>
      {
        (viewName === DASHBOARD.LEGACY_AR_BY_PARTNER || viewName === DASHBOARD.LEGACY_SALES_BY_PARTNER || viewName === DASHBOARD.LEGACY_CASH_REPORT || viewName === DASHBOARD.LEGACY_REGIONAL_SALES)
          ?
          <Typography color="primary" className="text-center mt-3" >{DASHBOARD.COOKIE_INFORMATION}<Link target={'_blank'} to={{ pathname: DASHBOARD.INFORMATION_LINK }}>Click Here.</Link>  </Typography> 
          : ""
      }
      <div className='dashboard-container d-flex justify-content-center align-items-center'>
        {showReport ? <DashboardReports viewName={viewName} /> : <DashboardContent viewName={viewName} dashboardName={dashboardName} />}
      </div>
    </div>
  )
};

Dashboard.propTypes = {
  viewName: PropTypes.string,
  showReport: PropTypes.bool,
  dashboardName: PropTypes.string
}

export default Dashboard;
