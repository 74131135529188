
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from "react";

//-----------------------------------------------------------// Internal Imports // ------------------------------

import Contacts from "./Contacts";

export default function MyLinkedinContacts() {
  return (
    <Contacts
      type="my-linkedin-contacts"
      storageKey="myLinkedinContactsColumns"
      title="My LinkedIn Contacts"
      checkStatus={true}
    />
  );
}