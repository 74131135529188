
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useEffect, useState } from "react";

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from "prop-types";
import Popup from "reactjs-popup";
import isEqual from "lodash.isequal";
import { useSnackbar } from "notistack";
import CloseIcon from "@material-ui/icons/Close";
import {
  TableContainer,
  Paper,
  Radio,
  RadioGroup,
  Button,
} from "@material-ui/core";

//-----------------------------------------------------------// Internal Imports // ------------------------------

import { isEmpty } from "../../../utils/common";
import { titles, getValue, getCompanyFieldValue } from "../utils";
import "./merge-companies.scss";
import Loader from "../../../components/common/Loader";
import { getOldValue } from "../../../components/ViewCompany/CompanyDetails/utils";
import ConfirmationPopup from "../../../components/ConfirmationPopup";
import { ERROR, GET, PUT, SUCCESS } from "../../../services/constantService";
import { companyDataApi } from "../../../services/ApiService";
import { successMessage, unableMessage, validateMessage } from "../../../services/MessageService";

const companyKeys = Object.keys(titles);

export default function MergeCompanies(props) {
  const { onClose, companies } = props;
  const [firstComp, secondComp] = companies;
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(true);
  const [conflictingFields, setConflictingFields] = useState([]);
  const [company, setCompany] = useState({});
  const [loading, setLoading] = useState(false);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [firstCompany, setFirstCompany] = useState(firstComp);
  const [secondCompany, setSecondCompany] = useState(secondComp);

  useEffect(() => {
    const getByCompanyId = async (id) => {
      let { status, data } = await companyDataApi(GET, id);
      if (status && status === 200) {
        // if (data === null) enqueueSnackbar(VALIDATION_MESSAGE.company_not_available_message, { variant: ERROR });
        return data
      }
    }
    const getCompanies = async () => {
      try {
        setLoading(true)
        const first = await getByCompanyId(firstComp.id);
        const second = await getByCompanyId(secondComp.id);
        if (first && second) {
          setFirstCompany(first)
          setSecondCompany(second)
        }
        setLoading(false)
      } catch (e) {
        console.log("Error found in getCompanies::", e);
      }
    }
    getCompanies()
  }, [companies])

  useEffect(() => {
    const conflictingKeys = [];
    const mergedCompany = {};
    companyKeys.forEach((key) => {
      const firstCompanyValue = getOldValue(firstCompany, key)[key];
      const secondCompanyValue = getOldValue(secondCompany, key)[key];

      if (isEmpty(firstCompanyValue)) {
        mergedCompany[key] = secondCompanyValue;
      } else if (isEmpty(secondCompanyValue)) {
        mergedCompany[key] = firstCompanyValue;
      } else if (!isEqual(firstCompanyValue, secondCompanyValue)) {
        conflictingKeys.push(key);
      } else {
        mergedCompany[key] = firstCompanyValue;
      }
    });

    setConflictingFields(conflictingKeys);
    setCompany(mergedCompany);
  }, [firstCompany, secondCompany]);

  const handleClose = (merged) => {
    setOpen(false);
    onClose && onClose(merged);
  };

  const handleCancel = () => {
    handleClose(false);
  };

  const handleConfirmPopupClose = () => {
    setShowConfirmPopup(false);
  };

  const changeCompanyField = (field, value) => {
    setCompany((prevState) => {
      prevState[field] = value;
      return prevState;
    });
  };

  const handleMerge = () => {
    if (Object.keys(company).length !== companyKeys.length + 1) {
      const message = validateMessage('fields', 'select all');
      enqueueSnackbar(message, { variant: ERROR })
      return
    }
    setShowConfirmPopup(true);
  };

  const mergeCompanies = async () => {
    setLoading(true);
    const payload = { data: company };

    payload.data.vc_pe = company.vc_pe
      ?.map((item) => ({ vcpe_id: item.vcpe_id }))
      .filter((item) => !isEmpty(item));
    payload.mergeId =
      company.id === firstCompany.id ? secondCompany.id : firstCompany.id;
    if (firstCompany.name !== secondCompany.name) {
      payload.mergeName =
        company.id === firstCompany.id ? secondCompany.name : firstCompany.name;
    }
    if (firstCompany.vc_pe.length > 0 && secondCompany.vc_pe.length > 0) {
      payload.vcpe_selected = true;
    }
    !payload.data.employee_size && (payload.data.employee_size = null)
    const sub_route = "merge";
    const { status, data } = await companyDataApi(
      PUT,
      company.id,
      payload,
      sub_route
    );
    if (status === 200) {
      const message = successMessage('Companies', 'merged',)
      enqueueSnackbar(message, { variant: SUCCESS })
      setLoading(false)
      handleClose(true)
    } else {
      const message = unableMessage('companies', 'merge')
      enqueueSnackbar(data?.message || message, { variant: ERROR })
      setLoading(false)
    }
  };

  const handleConfirmPopup = async () => {
    try {
      handleConfirmPopupClose();
      await mergeCompanies();
    } catch (e) {
      console.log("Error found in handleConfirmPopup::", e);
    }
  };

  const renderRow = (name, firstValue, secondValue, field, key, showRadio) => {
    if (field === "id") {
      showRadio = true;
      firstValue = "id1";
      secondValue = "id2";
    }
    return (
      <RadioGroup
        row
        onChange={(e) => {
          const { value } = e.target;
          changeCompanyField(
            field,
            isEqual(value, getCompanyFieldValue(firstValue, 'value'))
              ? getOldValue(firstCompany, field)[field]
              : getOldValue(secondCompany, field)[field]
          );
        }}
        name={field}
        key={key}
      >
        <div
          className={
            "text-right table-cell table-cell-header " +
            (showRadio && "highlight")
          }
        >
          {name}
        </div>
        <div className="table-cell">
          {showRadio && <Radio value={getCompanyFieldValue(firstValue, 'value')} size="small" />}
          {field !== "id" && getCompanyFieldValue(firstValue, 'component')}
        </div>
        <div className="table-cell">
          {showRadio && <Radio value={getCompanyFieldValue(secondValue, 'value')} size="small" />}
          {field !== "id" && getCompanyFieldValue(secondValue, 'component')}
        </div>
      </RadioGroup>
    );
  };

  return (
    <Popup open={open} modal closeOnEscape={false} closeOnDocumentClick={false}>
      <div className="merge-companies">
        <Loader show={loading} />
        {showConfirmPopup && (
          <ConfirmationPopup
            header="Are you sure you want to merge these companies? This process cannot be undone."
            onConfirm={handleConfirmPopup}
            onClose={handleConfirmPopupClose}
            cancelText={"Cancel"}
            confirmText={"Merge"}
          />
        )}
        <div className="header-section ">
          <span className="view-company-header">Merge Companies</span>
          <CloseIcon onClick={handleCancel} cursor='pointer' />
        </div>
        <div className="view-container popup-height">
          <TableContainer component={Paper} className="table-container">
            <div className="d-flex flex-grow-1 table-header">
              <div className="table-cell"></div>
              <div className="table-cell">{firstCompany.name}</div>
              <div className="table-cell">{secondCompany.name}</div>
            </div>
            <div>
              {renderRow("Master Record", "", "", "id")}
              {conflictingFields.map((field, index) =>
                renderRow(
                  titles[field],
                  getValue(firstCompany, field),
                  getValue(secondCompany, field),
                  field,
                  index,
                  true
                )
              )}
              {companyKeys
                .filter((key) => !conflictingFields.includes(key))
                .map((field, index) =>
                  renderRow(
                    titles[field],
                    getValue(firstCompany, field),
                    getValue(secondCompany, field),
                    field,
                    index
                  )
                )}
            </div>
          </TableContainer>
        </div>
        <div className="actions-container">
          <Button color="primary" onClick={handleCancel}>
            Cancel
          </Button>
          <Button
            className="merge-button"
            variant="contained"
            color="primary"
            onClick={handleMerge}
          >
            Merge
          </Button>
        </div>
      </div>
    </Popup>
  );
}

MergeCompanies.propTypes = {
  onClose: PropTypes.func,
  companies: PropTypes.array,
};
