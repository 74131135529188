
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from "react";

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from "prop-types";
import Popup from "reactjs-popup";
import { Button, Typography } from "@material-ui/core";

//-----------------------------------------------------------// Internal Imports // ------------------------------

import Searches from "../../../components/ViewContact/Searches";
import DetailsPopup from "./DetailsPopup";
import { VALIDATION_MESSAGE } from "../../../services/MessageService";

export default function OffLimitsWarningPopup(props) {
  const { data, handleClose, contactId } = props;
  const contact = data.filter((data) => data.id === contactId);
  const reasons = contact[0] && contact[0].reasons ? contact[0].reasons : [];
  const handleCancel = () => {
    handleClose(false);
  };

  const handleAdd = () => {
    handleClose(true);
  };

  return (
    <Popup
      className="off-limits-warning"
      open={true}
      closeOnDocumentClick={false}
      closeOnEscape={false}
    >
      <div className="popup-container">
        <Typography className="off-limits-heading">
          This Record is Off Limits
        </Typography>
        <div className="inside-container d-flex flex-column">
          {reasons.length === 1 ? (
            <div className="off-limits-reason">
              <Typography className="mb-2">{reasons[0].message}</Typography>
              <Typography>
                Would you like to continue to add this contact to a search?
              </Typography>
            </div>
          ) : (
            contact.length && <DetailsPopup data={contact[0]} />
          )}
          {contactId && (
            <div className="searches-container">
              <Typography className="searches-heading">
                Active Searches
              </Typography>
              <div className="add-contact-section">
                <Searches contact={{ id: contactId }} stage="Open" />
              </div>
            </div>
          )}
        </div>
        <div className="d-flex justify-content-around bottom-actions">
          <Button variant="contained" color="primary" onClick={handleAdd}>
            {VALIDATION_MESSAGE.add_contact_to_search}
          </Button>
          <Button variant="contained" color="primary" onClick={handleCancel}>
            Cancel
          </Button>
        </div>
      </div>
    </Popup>
  );
}

OffLimitsWarningPopup.propTypes = {
  data: PropTypes.object,
  handleClose: PropTypes.func,
  contactId: PropTypes.string,
};
