
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function AlertDialog(props) {
  const {open, handleClose, handleSubmit, isActive} = props
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Mark user as {isActive? 'inactive' : 'active'}?</DialogTitle>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          No
        </Button>
        <Button className='primary-btn' onClick={handleSubmit} color="primary" autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
AlertDialog.propTypes = {
  open: PropTypes.bool,
  isActive: PropTypes.bool,
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func
}
