//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useState, useEffect, useCallback } from 'react'

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Link, withRouter } from 'react-router-dom';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { useSnackbar } from "notistack";
import { useSelector } from 'react-redux';
import _ from 'lodash';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import './index.css';
import { getUserSelector } from '../../../selectors';
import MessageLoader from "../../common/MessageLoader/MessageLoader";
import { candidateSuiteDataApi, clientsuiteDataApi, hlDataApi, reportsDataApi, synclinkDataApi } from '../../../services/ApiService';
import { GET, ERROR, KGU_DASHBOARD, ROUTES } from '../../../services/constantService';
import { VALIDATION_MESSAGE } from '../../../services/MessageService';
import GalaxyGuidePopUp from '../../../Containers/Commons/GalaxyGuidePopUP';
import { checkArByPartnerDepartment, checkDashboardDepartment, checkSearchApprovalRoles, checkRegionalSalesDepartment } from '../../../Containers/Commons/Utils';
import NestedMenuItem from 'material-ui-nested-menu-item';

function HeaderNavItems(props) {
  const { onTabChange, className, location } = props;
  const { enqueueSnackbar } = useSnackbar();

  const navItems = [
    { label: "Searches", route: "/searches", key: "searches" },
    { label: "Contacts", route: "/contacts", key: "contacts" },
    { label: "Companies", route: "/companies", key: "companies" },
    { label: "Workbenches", route: "/workbenches", key: "workbenches" },
    { label: "Users", route: "/users", key: "users" },
    { label: "Product Setup", route: "/productSetUp", key: "productSetUp", },
    { label: "Insights", route: "/insights", key: "insights" },
  ]

  const moreMenuItems = [
    { label: "SyncLink", key: "SyncLink", link: '/', action: 'click' },
    {
      label: "ClientSuite",
      key: "ClientSuite",
      link: '/',
      action: 'click',
      admin: false,
    },
    {
      label: "Hirelogic",
      key: "Hirelogic",
      link: '/',
      action: 'click',
      admin: false,
    },
    // {
    //   label: "Galaxy Guide",
    //   key: "galaxyGuide",
    //   action: 'click',
    //   link: '/',
    //   admin: false,
    // },
    {
      label: "CandidateSuite",
      key: "CandidateSuite",
      link: '/',
      action: 'click',
      admin: false,
    },
    {
      label: "Report Builder",
      key: "ReportBuilder",
      link: '/',
      action: 'click',
      admin: false,
    },
    {
      label: "Approvals",
      key: "SearchApproval",
      link: ROUTES.searchApprovalPending,
      action: 'click',
      admin: false,
    },
    {
      label: "KGU ",
      key: "kguDashboard",
      link: KGU_DASHBOARD,
      admin: false,
    },
    {
      label: "Invoice Summary",
      key: "InvoiceSummary",
      link: ROUTES.invoiceSummary,
      action: 'click',
      admin: false,
    },
  ]

  /* const dashboardNavItems = [
    {
      label: "AR Report",
      key: "arByPartnerDashboard",
      link: ROUTES.arByPartnerDashboard,
      visiblePartner: true,
      subItems: []
    },
    {
      label: "Cash Report",
      key: "cashReportDashboard",
      link: ROUTES.cashReportDashboard,
      visiblePartner: false,
      subItems: []
    },
    {
      label: "Sales Report",
      key: "salesByPartnerDashboard",
      link: ROUTES.salesByPartnerDashboard,
      visiblePartner: false,
      subItems: []
    },
    {
      label: "BD Report",
      key: "bDDashboard",
      link: ROUTES.bDReportDashboard,
      visiblePartner: false,
      subItems: []
    },
  ]; */

  const productSetUpNavItems = [
    {
      label: "Translate Values",
      key: "translateValues",
      link: ROUTES.translateValueProductSetup,
    },
    {
      label: "Document Type",
      key: "documentType",
      link: ROUTES.documentTypeProductSetup,
    },
    /* {
      label: "SFPA Builder",
      key: "SearchSFPA",
      link: ROUTES.sfpaBuilder,
      // action: 'click',
    }, */
    {
      label: "Report Templates",
      key: "SearchReportTemplates",
      link: ROUTES.reportTemplates,
    }

  ]

  const versionMenu = {
    label: `Version ${process.env.REACT_APP_VERSION || '1.1.1'}`,
    key: "Version",
    onClick: "#"
  }
  const legacyInsights = {
    label: "Legacy",
    key: "legacy",
    link: '',
    visiblePartner: true,
    subItems: [
      {
        label: "AR By Partner",
        key: "legacyArByPartnerDashboard",
        link: ROUTES.legacyArByPartnerDashboard,
        visiblePartner: true,
        visibleToRegionalSales: true,
      },
      {
        label: "Sales By Partner",
        key: "legacySalesByPartnerDashboard",
        link: ROUTES.legacySalesByPartnerDashboard,
        visiblePartner: false,
        visibleToRegionalSales: true,
      },
      {
        label: "Cash Report",
        key: "legacyCashReportDashboard",
        link: ROUTES.legacyCashReportDashboard,
        visiblePartner: false,
        visibleToRegionalSales: true,
      },
      {
        label: "Regional Sales",
        key: "legacyRegionalSalesDashboard",
        link: ROUTES.legacyRegionalSalesDashboard,
        visiblePartner: false,
        visibleToRegionalSales: false,
      },
    ]
  }

  const sortMoreMenuItems = (navItemsList, version = false) => {
    let newList = _.sortBy(navItemsList, 'label');
    if (version) {
      newList = [...newList, versionMenu]
    }
    return newList
  }

  const [selected, setSelected] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElDashboard, setAnchorElDashboard] = useState(null);
  const [productSetUpDashboard, setProductSetUpDashboard] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [galaxyGuide, setGalaxyGuide] = useState(false);
  const [isVisible, setVisible] = useState(false);
  const [isPartner, setPartner] = useState(false)
  const [visibleToRegionalSales, setVisibleToRegionalSales] = useState(false)
  const [isSearchApproval, setSearchApproval] = useState(false)
  const userData = useSelector(getUserSelector)
  useEffect(() => {
    if (userData && userData.isAdmin) {
      setIsAdmin(true);
    }
    if (userData) {
      const validDepartment = checkDashboardDepartment(userData)
      setVisible(validDepartment)
      const partnerDepartment = checkArByPartnerDepartment(userData);
      if (partnerDepartment) {
        setPartner(true);
      }
      const regionalSalesVisible = checkRegionalSalesDepartment(userData);
      if (regionalSalesVisible) {
        setVisibleToRegionalSales(true);
      }

      const SearchApprovalRoles = checkSearchApprovalRoles(userData)
      if (SearchApprovalRoles) {
        setSearchApproval(true)
      }
    }
  }, [])
  useEffect(() => {
    if (userData && userData.roles) {
      if (userData.roles[0] === "finance") {
        setIsAdmin(false);
      }
    }
  }, [])

  const findSelectedTab = useCallback(
    (path) => {
      const tab = navItems.find(item => {
        return item.route === "/" + path;
      });
      return tab ? tab.key : null;
    },
    [navItems]
  );
  const handleRedirectToHL = async () => {
    setIsLoading(true)
    const sub_route = `redirect-to-hl`
    let { status, data } = await hlDataApi(GET, sub_route);
    if (status && status === 200) {
      let newTab = window.open('', '_blank');
      if (newTab !== null) {
        newTab.location.href = data.data.redirectLink;
      }
      setIsLoading(false);
    } else {
      enqueueSnackbar(data.message || VALIDATION_MESSAGE.hl_connect, { variant: ERROR });
      setIsLoading(false);
    }
    handleClose();
  }

  // const handleRediretToApprovalPage = async () => {
  //   setIsLoading(true)

  //   setIsLoading(false);
  //   handleClose();
  // }

  const handleRedirectToCandidateSuite = async () => {
    setIsLoading(true)
    const sub_route = `login_url`
    let { status, data } = await candidateSuiteDataApi(GET, sub_route);
    if (status && status === 200) {
      let newTab = window.open('', '_blank');
      if (newTab !== null) {
        newTab.location.href = data.redirectLink;
      }
      setIsLoading(false);
    } else {
      enqueueSnackbar(data.message, { variant: ERROR });
      setIsLoading(false);
    }
    handleClose();
  }

  const handleRedirectToClientSuite = async () => {
    setIsLoading(true)
    const sub_route = `login_url`
    let { status, data } = await clientsuiteDataApi(GET, sub_route);
    if (status && status === 200) {
      let newTab = window.open('', '_blank');
      if (newTab !== null) {
        newTab.location.href = data.redirectLink;
      }
      setIsLoading(false);
    } else {
      enqueueSnackbar(data.message, { variant: ERROR });
      setIsLoading(false);
    }
    handleClose();
  }

  const handleRedirectToSynclink = async () => {
    /* ---------------For Old SyncLink Redirection URL-------------
    const externalLink = `${process.env.REACT_APP_SYNCLINK_URL}/group/sync-link/dashboard`;
    window.open(externalLink, '_blank'); */
    setIsLoading(true)
    const sub_route = `login_url`
    let { status, data } = await synclinkDataApi(GET, sub_route);
    if (status && status === 200) {
      let newTab = window.open('', '_blank');
      if (newTab !== null) {
        newTab.location.href = data.redirectLink;
      }
      setIsLoading(false);
    } else {
      enqueueSnackbar(data.message, { variant: ERROR });
      setIsLoading(false);
    }
    handleClose();
  }

  const handleRedirectToReportBuilder = async () => {
    setIsLoading(true)
    const sub_route = `report-builder/login_url`
    let { status, data } = await reportsDataApi(GET, sub_route);
    if (status && status === 200) {
      let newTab = window.open('', '_blank');
      if (newTab !== null) {
        newTab.location.href = data.redirectLink;
      }
      setIsLoading(false);
    } else {
      enqueueSnackbar(data.message, { variant: ERROR });
      setIsLoading(false);
    }
    handleClose();
  }

  const setSelectedTab = (tab) => {
    setSelected(tab);
    onTabChange && onTabChange();
  }

  useEffect(() => {
    const path = location.pathname.split("/")[1];
    setSelected(findSelectedTab(path));
  }, [location, findSelectedTab])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClickDashboard = (event) => {
    setAnchorElDashboard(event.currentTarget);
  };
  const handleClickProductSetUp = (event) => {
    setProductSetUpDashboard(event.currentTarget);
  }

  const closeGalaxyGuide = () => {
    setGalaxyGuide(false);
  }

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleCloseDashboard = () => {
    setAnchorElDashboard(null);
  };

  const handleCloseProductSetUp = () => {
    setProductSetUpDashboard(null);
  }

  const getDashboardLink = (item) => {
    return (
      <div key={item.key}>
        <Link to='#'>
          <div className={`nav-item d-flex align-items-center ${selected === item.key ? 'active' : ''}`} onClick={handleClickDashboard}>
            Insights
            <span className="more-navs-dropdown d-flex align-items-center">
              <ArrowDropDownIcon fontSize="inherit" />
            </span>
          </div>
        </Link>
        <Menu
          id="simple-menu"
          anchorEl={anchorElDashboard}
          keepMounted
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={Boolean(anchorElDashboard)}
          onClose={handleCloseDashboard}
        >
          {/* As requirement show directly legacy report in Insights menu list instead of legacy menu sub items */}
          {getDashboardMenuItems(legacyInsights.subItems, true)}
        </Menu>
      </div>
    )
  }

  const getProductSetUpLink = (item) => {
    return (
      <div key={item.key}>
        <Link to='#'>
          <div className={`nav-item d-flex align-items-center ${selected === item.key ? 'active' : ''}`} onClick={handleClickProductSetUp}>
            Product Setup
            <span className="more-navs-dropdown d-flex align-items-center">
              <ArrowDropDownIcon fontSize="inherit" />
            </span>
          </div>
        </Link>
        <Menu
          id="simple-menu"
          anchorEl={productSetUpDashboard}
          keepMounted
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={Boolean(productSetUpDashboard)}
          onClose={handleCloseProductSetUp}
        >
          {getProductSetUpMenuItems()}
        </Menu>
      </div>
    )
  }

  const getNavItems = () => {
    if (isAdmin === false) {
      const deleteditem = { label: "Product Setup", route: "/productSetUp", key: "productSetUp" }
      navItems.splice(navItems.findIndex(a => a.key === deleteditem.key), 1)
    }
    return navItems.filter((item) => { return item.admin ? item.admin === isAdmin : true }).map(item => {
      if (item.key === 'productSetUp') {
        return getProductSetUpLink(item);
      }
      if (item.key === 'insights') {
        if (isPartner || visibleToRegionalSales || isVisible) {
          return getDashboardLink(item)
        }
        return
      }
      return <Link key={item.key} to={item.route}><div className={`nav-item d-flex align-items-center ${selected === item.key ? 'active' : ''}`} onClick={() => setSelectedTab(item.key)}>{item.label}</div></Link>
    })
  }

  document.addEventListener("contextmenu", (event) => {
    if (event.currentTarget.activeElement?.target?.length === 0)
      event.preventDefault();
  });

  const getMoreMenuItems = () => {
    return sortMoreMenuItems(moreMenuItems, true).filter((item) => { return (item.admin ? item.admin === isAdmin : true) }).map(item => {
      return item.link ?
        <MenuItem key={item.key}>
          {item.action && item.key === 'ReportBuilder' && (<a href="#!" onClick={handleRedirectToReportBuilder} className="action-label">{item.label}</a>)}
          {item.action && item.key === 'CandidateSuite' && (<a href="#!" onClick={handleRedirectToCandidateSuite} className="action-label">{item.label}</a>)}
          {item.action && item.key === 'ClientSuite' && (<a href="#!" onClick={handleRedirectToClientSuite} className="action-label">{item.label}</a>)}
          {item.action && item.key === 'SyncLink' && (<a href="#!" onClick={handleRedirectToSynclink} className="action-label">{item.label}</a>)}
          {item.action && item.key === 'Hirelogic' && (<a href="#!" onClick={handleRedirectToHL} className="action-label">{item.label}</a>)}
          {!item.action && (<a href={item.link} target="_blank" rel="noopener noreferrer" onClick={handleClose} className="action-label">{item.label}</a>)}
          {item.action && item.key === 'galaxyGuide' && (
            <a href='#!' className="action-label" onClick={() => { setGalaxyGuide(true); handleClose() }} >
              {item.label}
            </a>
          )
          }
          {item.action && item.key === 'SearchApproval' && isSearchApproval && <Link to={item.link} className="action-label" onClick={handleClose}>{item.label}</Link>}
          {item.action && item.key === 'InvoiceSummary' && <Link to={item.link} className="action-label" onClick={handleClose}>{item.label}</Link>}
        </MenuItem >
        : item.onClick ? <MenuItem key={item.key}>{(<a style={{ color: "#5f51cb", cursor: "default" }} onClick={handleClose} >{item.label}</a>)}</MenuItem> :
          <MenuItem key={item.key} onClick={handleClose}>{item.label}</MenuItem>
    })

  }
  
  const getDashboardMenuItems = (dashboardNavList/* , checkLegacy = false */) => {
    let sortedItems = sortMoreMenuItems(dashboardNavList)
    // if (checkLegacy) {sortedItems = [{...legacyInsights}, ...sortedItems]}
    return sortedItems.filter(ele => ((ele.visiblePartner || (ele.visibleToRegionalSales && visibleToRegionalSales) || isVisible))).map(item => {
      return (
        <>
          {item.subItems?.length ? getDashboardSubMenuItems(item) :
            (<MenuItem key={item.key} onClick={handleCloseDashboard}>
              <Link to={item.link} className="action-label">
                <span>
                  {item.label}
                </span>
              </Link>
            </MenuItem >)}
        </>
      )
    })
  }

  const getDashboardSubMenuItems = (nestedItem) => {
    return (
      <div className="action-label">
        <NestedMenuItem
          label={nestedItem.label}
          parentMenuOpen={true}
        >
          {getDashboardMenuItems(nestedItem.subItems)}
        </NestedMenuItem>
      </div>
    )
  }

  const getProductSetUpMenuItems = () => {
    return (productSetUpNavItems).map(item => {
      return (
        <MenuItem key={item.key} onClick={handleCloseProductSetUp}>
          <Link to={item.link} className="action-label">{item.label}</Link>
        </MenuItem >)
    })

  }
  return (
    <React.Fragment>
      {galaxyGuide && <GalaxyGuidePopUp onClose={closeGalaxyGuide} />}
      <MessageLoader show={isLoading} className={'hl-loader'} message="" />
      <div className={`d-flex ${className}`}>
        {getNavItems()}
        <div className="nav-item d-flex align-items-center" onClick={handleClick}>
          More
          <span className="more-navs-dropdown d-flex align-items-center">
            <ArrowDropDownIcon fontSize="inherit" />
          </span>
        </div>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {getMoreMenuItems()}
        </Menu>
      </div>
    </React.Fragment>
  )
}

HeaderNavItems.propTypes = {
  className: PropTypes.string,
  onTabChange: PropTypes.func,
  location: PropTypes.object
}

export default withRouter(HeaderNavItems);
