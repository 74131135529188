//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { Component } from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom'
import { Typography } from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import { withSnackbar } from "notistack";
import BusinessIcon from '@material-ui/icons/Business';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import {
  DateRenderer,
  DateTimeRenderer,
  loadColumnStateFromLocalStorage,
  PhoneRenderer,
  saveColumnStateToLocalStorage
} from "../../Containers/Commons/Utils";
import {
  CompanyNameRenderer,
  CustomLoadingOverlayComponent,
  defaultColumns,
  PAGE_LIMIT,
  SUCCESS_STATUS_CODE, WebsiteRenderer
} from "../../Containers/Companies/utils";
import { SCROLL_TIMEOUT } from "../../utils/common";
import GenericCellEditor from "../../Containers/Commons/CellEditors/GenericCellEditor";
import DescriptionEditor from "../../Containers/Commons/CellEditors/DescriptionEditor";
import PhoneEditor from "../../Containers/Commons/CellEditors/PhoneEditor";
import ToggleSelection from "../../Containers/Commons/CellEditors/ToggleSelection";
import ColumnFilter from "../../Containers/Commons/ColumnFilter";
import { companyColumns } from "./utils";
import { masterSearchApi } from "../../services/ApiService";

class Companies extends Component {
  constructor(props) {
    super(props)
    this.state = {
      viewColumns: false,
      rowCount: 0,
    }
  }

  componentWillUnmount() {
    const columnApi = this.columnApi;
    saveColumnStateToLocalStorage('masterSearchCompanyColumns', { columnApi })
  }

  componentDidUpdate(prevProps) {
    if (this.props.searchKey !== prevProps.searchKey) {
      this.gridApi && this.gridApi.onFilterChanged()
    }
  }

  dataSource = {
    getRows: async (params) => {
      this.gridApi.showLoadingOverlay();
      const value = encodeURIComponent(this.props.searchKey)
      let sub_route = `companies?limit=${PAGE_LIMIT}&page=${params.endRow / PAGE_LIMIT}&searchValue=${value}`
      if (params.sortModel.length > 0) {
        sub_route = sub_route.concat(`&sortOn=${params.sortModel[0].colId}&sortType=${params.sortModel[0].sort.toUpperCase()}`)
      }
      sub_route = `${sub_route}&mastersearch=true`
      const { status, data } = await masterSearchApi(sub_route)
      if (status === SUCCESS_STATUS_CODE) {
        params.successCallback(data.data, data.paging.totalCount)
        this.setState({ rowCount: data.paging.showTotal ? data.paging.showTotal : data.paging.totalCount })
        this.props.setCompaniesCount(data.paging.showTotal ? data.paging.showTotal : data.paging.totalCount)
        this.columnApi.autoSizeAllColumns();
      }
      params.failCallback();
      this.gridApi.hideOverlay()
    },
    rowCount: null
  }

  onGridReady = (params) => {
    this.gridApi = params.api
    this.columnApi = params.columnApi
    const columnApi = params.columnApi
    params.columnApi.autoSizeColumns()
    loadColumnStateFromLocalStorage('masterSearchCompanyColumns', { columnApi })
    this.setState({ isGridReady: true })
  }

  NameRenderer = (params) => {
    return <CompanyNameRenderer company={params?.data} />
  }

  render() {
    const { height = '100%', rowHeight = 42, minHeight = 180 } = this.props
    return (
      <div className="list-companies d-flex flex-column mt-4" style={{ height: this.state.rowCount ? height : minHeight }}>
        <div className="list-header d-flex justify-content-between">
          <Typography className='table-header-text' style={{ paddingLeft: '1.5%' }}><BusinessIcon style={{ fill: '#DE9F6D' }} /> Companies</Typography>
          <Typography className='total-count' style={{ paddingRight: '1.5%' }}>Total count: {this.state.rowCount}</Typography>
        </div>
        <div className="list-view flex-grow-1">
          {this.state.isGridReady && <ColumnFilter defaultColumns={defaultColumns} gridApi={this.gridApi} columnApi={this.columnApi} />}
          <div id="myGrid" className="ag-theme-alpine">
            <AgGridReact
              onGridReady={this.onGridReady}
              enableBrowserTooltips={true}
              defaultColDef={{
                minWidth: 100,
                resizable: true,
                sortable: true,
                sortingOrder: ['asc', 'desc', null]
              }}
              rowHeight={rowHeight}
              blockLoadDebounceMillis={SCROLL_TIMEOUT}
              tooltipShowDelay={0}
              scrollbarWidth={12}
              suppressHorizontalScroll={false}
              cacheBlockSize={PAGE_LIMIT}
              loadingOverlayComponent={'CustomLoadingOverlayComponent'}
              frameworkComponents={{
                NameRenderer: this.NameRenderer,
                WebsiteRenderer,
                PhoneRenderer,
                CustomLoadingOverlayComponent,
                DateTimeRenderer,
                DateRenderer,
                GenericCellEditor,
                DescriptionEditor,
                PhoneEditor,
                ToggleSelection
              }}
              rowModelType={'infinite'}
              datasource={this.dataSource}
              columnDefs={companyColumns}
              paginationPageSize={20}
              colResizeDefault={'shift'}
            >
            </AgGridReact>
            <Link to={{
              pathname: `/companies`,
              state: this.props.searchKey
            }} style={{ fontSize: '18px', float: 'right' }} className="MuiTypography-colorPrimary mt-2">More Records&gt; </Link>
          </div>
        </div>
      </div>
    )
  }
}

Companies.propTypes = {
  enqueueSnackbar: PropTypes.func,
  searchKey: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  rowHeight: PropTypes.number,
  minHeight: PropTypes.number,
  setCompaniesCount: PropTypes.func.isRequired,
}

export default withSnackbar(Companies)
