//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect } from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types'
/* import RightSideNav from "../../../RightSideNav"; */
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, FormControlLabel, Switch } from "@material-ui/core";
import { KeyboardDatePicker, KeyboardTimePicker } from "@material-ui/pickers";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ScheduleIcon from '@material-ui/icons/Schedule';
//----------------------------------------------// Internal Imports // -------------------------------------------------

import { formatDate, getTheDaysDifferences, getTheDaysDifference } from "../../../../utils/date";
import MultiCompanySelection from "../../../CompanySelection";
import CompanySelection from "../../../AddContact/Components/CompanySelection";
import InputField from "../../../common/InputField";
import SearchStageSelection from "../../../CreateSearch/Components/SearchInformation/Components/SearchStageSelection";
import SearchTypeSelection from "../../../CreateSearch/Components/SearchInformation/Components/SearchTypeSelection";
import SearchResultSelection from "../../../CreateSearch/Components/SearchInformation/Components/SearchResultSelection";
import IndustrySelection from "../../../IndustrySelection";
import JobFunctionSelection from "../../../jobFunctionSelection";
import StateSelection from "../../../StateSelection";
import CountrySelection from "../../../CountrySelection";
import PracticeSelection from "../../../AddUser/components/PracticeSelection";
import RegionSelection from "../../../AddUser/components/RegionSelection";
import CapitalStructure from "../SFPA/Components/Targets/CapitalStructure";
import ClientContacts from "./ClientContacts";
// import { rightNav } from "../../../../Containers/Commons/styleUtils"
import BrandSelection from "../../../CreateSearch/Components/SearchInformation/Components/BrandSelection";
// import WorkgroupSelection from "../../../../components/AddUser/components/WorkgroupSelection";
// import BillingStatus from "../../../BillingStatus";
// import ClientInfoViewSection from "./ClientInfoViewSection";
// import BasicInfoViewSection from "./BasicInfoViewSection";
// import AdditionalInfoViewSection from "./AdditionalInfoViewSection";
import { RedirectToCompanies } from "../../../../utils/common";
import UserSelection from "../../../UserSelection";
import ProbabilitySelection from "../../../CreateSearch/Components/SearchInformation/Components/ProbabilitySelection";
import BDStatusSelection from "../../../CreateSearch/Components/SearchInformation/Components/BDStatusSelection";
import SyncFitVersionSelection from "../../../SyncLinkVersionSelection/SyncLinkVersionSelection";
import { HELIA } from "../../../../services/constantService";


// const defaultValue = '--'

export default function SearchInformationView(props) {
  const { search, renderActions, handelScroll, renderFieldLabel, renderSectionLabel, editingField, register, setValue, watch, setRenderActionFlag, isHide, reloadData } = props
  const country = watch('country');

  useEffect(() => {
    const field = ["company", "job_title", "state", "vertical_practice", "horizontal_practice", "region", "type", "stage", "industries", "job_functions", "result", "brand", "on_hold_date", "off_hold_date", "start_date", "close_date", "loe_date", "is_confidential", "is_nda", "country", "city", "next_status_call_date", "next_status_call_time", "capital_structure", "is_executive_search_arena", "encore_number", "vc_pe", "competition", "decision", "work_group", "projected_start_date"]
    field.forEach(field => setValue(field, search[field]))
  }, [editingField])

  const getDate = (date) => {
    return date ? formatDate(date) : "--"
  }
  useEffect(() => {
    register({ name: 'company' })
    register('country');
    register({ name: 'vertical_practice' });
    register({ name: 'horizontal_practice' });
    register({ name: 'region' });
    register('state');
    register('job_title')
    register('city')
    register({ name: 'type' })
    register({ name: 'stage' })
    register('stage_desc')
    register({ name: 'billing_status' })
    register({ name: 'industries' })
    register({ name: 'job_functions' })
    register({ name: 'result' })
    register({ name: 'capital_structure' })
    register('brand')
    register('vc_pe')
    register('competition')
    register({ name: "on_hold_date" })
    register({ name: "off_hold_date" })
    register({ name: "loe_date" })
    register({ name: "next_status_call_date" })
    register({ name: "start_date" })
    register({ name: "close_date" })
    register({ name: "projected_start_date" })
    register('encore_number')
    register('decision')
    register('basic_partners')
    register('probability')
    register('bd_status')
    register('helia_version')
    register('helia_desc')
  }, [register])

  useEffect(() => {
    setTimeout(() => {
      handelScroll()
    }, 3000)

  }, []);

  const handleChanges = (e, data, name) => {
    setValue(name, data ? data.field_value : '');
  }

  const stateChange = (state) => {
    setValue('state', state);
  }

  const handleChange = (name, value) => {
    setValue(name, formatDate(value))
  }
  // It is apply for issue of stringify object display from api
  // const recursiveFunction = (name)=>{
  //   const newName = JSON.parse(name)
  //   if(newName.name.includes('{')){
  //     return recursiveFunction(newName.name)
  //   }
  //   return newName.name
  // }

  // const getFieldData = (field) =>{
  //   return watch(field).map(item =>{
  //     if(item.name && item.name.includes('{')){ 
  //       return recursiveFunction(item.name)
  //     }else {
  //       return item
  //     }
  //   }) || []
  // }


  return (
    <><div className='input-form'>
      <div id='client-info'>
        {/*Client info */}
        <ExpansionPanel defaultExpanded={true} className="panel-bottom-margin" style={{ background: '#fff', color: '#fff' }} >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon style={{ color: "#fff", cursor: 'pointer' }} />}
            aria-controls="panel1a-content"
            style={{ background: '#2a364c' }}
          >
            <div className="d-flex" onClick={(e) => e.stopPropagation()}>
              {renderSectionLabel('Client Info', 'client_info')}
              {renderActions('client_info')}
            </div>
          </ExpansionPanelSummary>
          {/* <Typography className="section-label">Client Info</Typography> */}
          {<ExpansionPanelDetails className='d-flex flex-column'>
            <div className='d-flex contact-details-row'>
              <div className='d-flex intersection-page-view'>
                {renderFieldLabel('Company Name', 'company', editingField === 'client_info')}
                {/* company */}
                {
                  // <div className='d-flex page-label-value'>
                  editingField === "client_info" ?
                    <div className='page-label-value'>
                      <CompanySelection
                        className="input-form-field"
                        defaultValue={watch('company') || null}
                        label=''
                        placeholder={"Search Company"}
                        onChange={(e, data) => {
                          setValue('company', data);
                        }}
                        variant="outlined" />
                      {renderActions('company')}
                    </div>
                    :
                    <div className='page-label-value'>
                      {search.company && search.company.name ?
                        <RedirectToCompanies id={search.company.id} name={search.company.name} />
                        : '--'}
                    </div>}
              </div>
              <div className='d-flex intersection-page-view'>
                {renderFieldLabel('Search Job Title', 'job_title', editingField === 'client_info')}
                {
                  // <div className='d-flex page-label-value'>
                  editingField === "client_info" ?
                    <div className='page-label-value'>
                      <InputField
                        ref={register}
                        defaultValue={watch('job_title') || null}
                        name='job_title'
                        label=''
                        onChange={(e) => {
                          setValue('job_title', e.target.value);
                        }}
                        className="input-form-field"
                        variant="outlined" />
                      {renderActions('job_title')}
                    </div>
                    :
                    <span className='page-label-value'>{search.job_title || '--'}</span>}
              </div>
            </div>
            <div className='d-flex contact-details-row'>
              <div className='d-flex intersection-page-view'>
                {/* <label className="bd-input-label"> Job Location-Region</label> */}
                {renderFieldLabel('Job Location-Region', 'region')}
                {editingField === "client_info" ?
                  <div className='page-label-value'>
                    <RegionSelection
                      className="input-form-field"
                      defaultValue={watch('region') || null}
                      value={watch("region") || null}
                      label={""}
                      onChange={(e, data) => {
                        setValue('region', data ? data.name : '');
                      }}
                      variant="outlined" />
                    {renderActions('region')}
                  </div>
                  :
                  <span className='page-label-value'>{search.region ? search.region : '--'}</span>}
              </div>
              <div className='d-flex intersection-page-view'>
                {renderFieldLabel('Location', 'country', editingField === 'client_info')}
                {editingField === "client_info" ?
                  <div className='page-label-value'>
                    <CountrySelection
                      className="input-form-field"
                      defaultValue={search.country}
                      label=""
                      //value={watch('country') || ""}
                      onChange={(e, data) => {
                        stateChange('');
                        setValue('country', data?.name);
                      }}
                      variant="outlined" />
                    {renderActions('country')}
                  </div>
                  :
                  <span className='page-label-value'>{search.country || "--"}</span>}
              </div>
            </div>
            <div className='d-flex contact-details-row'>
              <div className='d-flex intersection-page-view'>
                {/* <label className="bd-input-label" > state</label> */}
                {renderFieldLabel('State', 'state')}
                {editingField === "client_info" ?
                  <div className='page-label-value'>
                    <StateSelection
                      value={watch('state') || null}
                      className="input-form-field"
                      country={country}
                      register={register}
                      label=''
                      onChange={(e, state) => {
                        stateChange(state);
                      }}
                      variant="outlined" />
                    {renderActions('state')}
                  </div>
                  :
                  <span className='page-label-value'>{search.state || "--"}</span>}
              </div>
              <div className='d-flex intersection-page-view'>
                {/* <label className="bd-input-label" > city </label> */}
                {renderFieldLabel('City', 'city')}
                {editingField === "client_info" ?
                  <div className='page-label-value'>
                    <InputField
                      ref={register}
                      defaultValue={watch('city') || null}
                      className="input-form-field"
                      label=''
                      onChange={(e) => {
                        setValue('city', e.target.value);
                      }}
                      name='city'
                      variant="outlined" />
                    {renderActions('city')}
                  </div>
                  :
                  <span className='page-label-value'>{search.city || "--"}</span>}
              </div>
            </div>
            <div className='d-flex contact-details-row'>
              <div className='d-flex intersection-page-view'>
                {renderFieldLabel('Industries', 'industries', editingField === 'client_info')}
                {editingField === "client_info" ?
                  <div className='page-label-value multiple-selection-list'>
                    <IndustrySelection
                      className='input-form-field'
                      // value={getFieldData('industries')}
                      value={watch('industries') || []}
                      label=''
                      multiple
                      filterSelectedOptions={true}
                      size='small'
                      isSearchIndustry={true}
                      onChange={(e, data) => setValue('industries', data)}
                      variant="outlined" />
                    {renderActions('industries')}
                  </div>
                  :
                  <span
                    className='page-label-value multiple-view-selection-list'>{search.industries ? search.industries.map(item => item?.name || item).join(', ') : "--"}</span>
                  // It is apply for issue of stringify object display from api
                  // <span
                  //   className='page-label-value'>{search.industries ? search.industries.map(item =>{
                  //     if(item.name && item.name.includes('{')){ 
                  //     if(item.name && item.name.includes('{')){ 
                  //     if(item.name && item.name.includes('{')){ 
                  //       return recursiveFunction(item.name)
                  //     }else {
                  //       return item?.name || item
                  //     }
                  //   }).join(', ') : defaultValue}</span>
                }



              </div>
              <div className='d-flex intersection-page-view'>
                {/* <label className="bd-input-label"> horizontal practice  </label> */}
                {renderFieldLabel('Horizontal practice', 'horizontal_practice')}
                {editingField === "client_info" ?
                  <div className='page-label-value'>
                    <PracticeSelection
                      className='input-form-field'
                      defaultValue={watch('horizontal_practice') || null}
                      value={watch('horizontal_practice') || null}
                      label={""}
                      placeholder={"Horizontal Practice"}
                      name="horizontal_practice"
                      onChange={(e, data) => {
                        setValue('horizontal_practice', data ? data.name : '');
                      }}
                      type={'horizontal'}
                      variant="outlined" />
                    {renderActions('horizontal_practice')}
                  </div>
                  :
                  <span className='page-label-value'>{search.horizontal_practice ? search.horizontal_practice : "--"}</span>}
              </div>
            </div>
            <div className='d-flex contact-details-row'>
              <div className='d-flex intersection-page-view'>
                {/* <label className="bd-input-label">Vertical Practice</label> */}
                {renderFieldLabel('Vertical practice', 'vertical_practice')}
                {editingField === "client_info" ?
                  <div className='page-label-value'>
                    <PracticeSelection
                      className='input-form-field'
                      defaultValue={watch('vertical_practice') || null}
                      value={watch('vertical_practice') || null}
                      label={""}
                      onChange={(e, data) => {
                        setValue('vertical_practice', data ? data.name : '');
                      }}
                      type={'vertical'}
                      variant="outlined"
                      placeholder={"Vertical Practice"} />
                    {renderActions('vertical_practice')}
                  </div>
                  :
                  <span className='page-label-value'>{search.vertical_practice ? search.vertical_practice : "--"}</span>
                  // </div>
                }
              </div>
              <div className='d-flex intersection-page-view '>
                {renderFieldLabel('Partners', 'basic_partners', editingField === 'client_info')}
                {editingField === "client_info" ?
                  <div className="page-label-value multiple-selection-list">
                    <UserSelection
                      name='basic_partners'
                      multiple
                      // inputRef={userRef}
                      value={watch('basic_partners') || []}
                      // value={getPartnerValue()}
                      className="input-form-field"
                      InputLabelProps={{ focused: true }}
                      filterSelectedOptions={true}
                      label={""}
                      placeholder={"Select"}
                      required={true}
                      variant='outlined'
                      // onChange={([, data]) => data}
                      onChange={(e, data) => { setValue('basic_partners', data) }}
                    />
                  </div>
                  :
                  <span className='page-label-value multiple-view-selection-list'>{search.basic_partners?.length > 0 ? search.basic_partners.map((partnerDetails, index) => (
                    <p key={index}>{partnerDetails.name}</p>)) : '--'}</span>

                }
              </div>
            </div>
            <div className='d-flex contact-details-row'>
              <div className='d-flex intersection-page-view'>
                {/* <label className="bd-input-label">Vertical Practice</label> */}
                {renderFieldLabel('Framework Version', 'helia_version')}
                {editingField === "client_info" ?
                  <div className='page-label-value'>
                    <SyncFitVersionSelection
                      defaultValue={watch('helia_desc') || HELIA.DEFAULT_VERSION_DESC}
                      className='input-form-field'
                      variant="outlined"
                      onChange={(e, data) => {
                        setValue('helia_version', data ? data.field_value : HELIA.DEFAULT_VERSION)
                        setValue('helia_desc', data ? data.short_desc : HELIA.DEFAULT_VERSION_DESC)
                      }}
                      label={""}
                      InputLabelProps={{ focused: true }}
                      disabled={search?.athena_invited}
                    />
                    {renderActions('helia_desc')}
                  </div>
                  :
                  <span className='page-label-value'>{search.helia_desc ? search.helia_desc : HELIA.DEFAULT_VERSION_DESC}</span>
                  // </div>
                }
              </div>
            </div>
            <div id='clientDetails' className="">
              <ClientContacts renderActions={renderActions} currentValues={search} setValue={setValue} register={register} editingField={editingField} renderSectionLabel={renderSectionLabel} watch={watch} reloadData={reloadData} />
            </div>
          </ExpansionPanelDetails>}
        </ExpansionPanel>
        {/* basic info */}
        <ExpansionPanel defaultExpanded={true} className="panel-bottom-margin" style={{ background: '#fff', color: '#fff' }}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon
              style={{ color: "#fff", cursor: 'pointer' }} />}
            aria-controls="panel1a-content"
            style={{ background: '#2a364c' }}
          >

            <div className="d-flex" onClick={(e) => e.stopPropagation()}>
              {renderSectionLabel('Basic Info', 'basic_info')}
              {renderActions('basic_info')}
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className='d-flex flex-column'>

            <div className="d-flex contact-details-row">
              <div className='d-flex intersection-page-view'>
                {renderFieldLabel('Stage', 'stage_desc', editingField === 'basic_info')}
                {editingField === 'basic_info' ?
                  <div className='page-label-value'>
                    <SearchStageSelection
                      className='input-form-field'
                      value={watch('stage_desc') || null}
                      isStage={watch('stage')}
                      disabled={watch('stage') === 'Pending Acknowledgement'}
                      label=''
                      fullSearch={true}
                      onChange={(e, data) => {
                        setValue('stage', data ? data.field_value : null)
                        setValue('stage_desc', data ? data.short_desc : null)
                      }}
                      variant="outlined" />
                    {renderActions('stage_desc')}
                  </div>
                  :
                  <span className='page-label-value'>{search.stage_desc ? search.stage_desc : (search.stage ? search.stage : "")}</span>}
              </div>
              <div className="d-flex intersection-page-view">
                {/* <label className="contact-view-labels" >Billing Status </label> */}
                {renderFieldLabel('Billing Status', 'billing_status')}
                {editingField === 'basic_info' ?
                  <div className='page-label-value'>
                    {/* <BillingStatus
                className='input-form-field'
                value={watch('billing_status') || null}
                label=''
                onChange={(e, data) => {
                  setValue('billing_status', data ? data : null)
                }
                }
                variant="outlined"
              /> */}

                    <div className="">{watch('billing_desc') === 'None' ? '--' : watch('billing_desc') || '--'}</div>
                  </div>
                  :
                  <span className='page-label-value' style={{ color: `${search && search.billing_desc && "red"}` }}>{search && search.billing_desc === 'None' ? '--' : search.billing_desc ? search.billing_desc : '--'}</span>}
              </div>
            </div>
            <div className='d-flex contact-details-row'>
              <div className='d-flex intersection-page-view'>
                {renderFieldLabel('Search Type', 'type', editingField === 'basic_info')}
                {editingField === 'basic_info' ?
                  <div className='page-label-value'>
                    <SearchTypeSelection
                      className='input-form-field'
                      value={watch('type') || null}
                      label=''
                      onChange={(e, data) => setValue('type', data ? data.name : null)}
                      variant="outlined" />
                    {renderActions('type')}
                  </div>
                  :
                  <span className='page-label-value'>{search.type ? search.type : "--"}</span>}
              </div>
              <div className='d-flex intersection-page-view'>
                {/* <label className="contact-view-labels">Projected Start Date </label> */}
                {renderFieldLabel('Projected Start Date ', 'projected_start_date', editingField === 'basic_info')}
                {editingField === 'basic_info' ?
                  <div className='page-label-value' >
                    <KeyboardDatePicker
                      autoOk
                      // disableToolbar
                      label=''
                      variant='inline'
                      format='MM/dd/yyyy'
                      className='input-form-field'
                      ref={register}
                      inputProps={{ readOnly: true }}
                      name="projected_start_date"
                      value={formatDate(watch('projected_start_date')) || null}
                      // minDate={compareDateWithCurrentDate(watch('projected_start_date'))}
                      onChange={(date) => setValue('projected_start_date', formatDate(date))}
                      inputVariant="outlined" />
                    {renderActions('projected_start_date')}
                  </div>
                  :
                  <span className="page-label-value">{getDate(search.projected_start_date)}</span>}
              </div>

            </div>
            <div className='d-flex contact-details-row'>
              <div className='d-flex intersection-page-view'>
                {/* <label className="contact-view-labels">Brand</label> */}
                {renderFieldLabel('Service Offering', 'brand', editingField === 'basic_info')}
                {editingField === 'basic_info' ?
                  <div className='page-label-value'>
                    <BrandSelection
                      className='input-form-field'
                      defaultValue={search.brand_desc ? watch("brand_desc") : watch('brand') || null}
                      label={""}
                      InputLabelProps={{ focused: true }}
                      onChange={(e, data) => {
                        setValue('brand', data ? data.field_value : '');
                      }}
                      disabled={isHide}
                      variant="outlined" />
                    {renderActions('brand')}
                  </div>
                  :
                  <span className='page-label-value'>{search.brand ? search.brand_desc ? search.brand_desc : search.brand : "--"}</span>}
              </div>
              <div className='d-flex intersection-page-view'>
                {/* <label className="contact-view-labels"> Off Hold Date</label> */}
                {renderFieldLabel('Off Hold Date', 'off_hold_date')}
                {editingField === 'basic_info' ?
                  <div className='page-label-value'>
                    <KeyboardDatePicker
                      autoOk
                      // disableToolbar
                      label=''
                      variant='inline'
                      format='MM/dd/yyyy'
                      className='input-form-field'
                      ref={register}
                      name="off_hold_date"
                      value={formatDate(watch('off_hold_date')) || null}
                      onChange={(date) => handleChange('off_hold_date', date)}
                      inputVariant="outlined" />
                    {renderActions('off_hold_date')}
                  </div>
                  :
                  <span className='page-label-value'>{getDate(search?.off_hold_date)}</span>}
              </div>
            </div>
            <div className='d-flex contact-details-row'>
              <div className='d-flex intersection-page-view'>
                {/* <label className="contact-view-labels"> Results</label> */}
                {renderFieldLabel('Result', 'result')}
                {editingField === 'basic_info' ?
                  <div className='page-label-value'>
                    <SearchResultSelection
                      className='input-form-field'
                      value={watch('result') || null}
                      label=''
                      onChange={(e, data) => setValue('result', data ? data.name : null)}
                      variant="outlined" />
                    {renderActions('result')}
                  </div>
                  :
                  <span className='page-label-value'>{search.result || "--"}</span>}
              </div>
              <div className='d-flex intersection-page-view'>
                {/* <span className='contact-view-labels'>Days Open</span> */}
                {renderFieldLabel('Days Open', 'days_open')}
                {editingField === 'basic_info' ?
                  <div className='page-label-value'>
                    <div className="">{getTheDaysDifferences(search)}</div>
                  </div>
                  :
                  <span className='page-label-value'>{getTheDaysDifferences(search)}</span>}
              </div>
            </div>
            <div className='d-flex contact-details-row'>
              <div className='d-flex intersection-page-view'>
                {/* <label className="contact-view-labels"> SFPA Completed </label> */}
                {renderFieldLabel('Search Roadmap Completed', 'is_sfpa_completed')}
                {editingField === 'basic_info' ?
                  <div className='page-label-value'>
                    <FormControlLabel
                      className='input-form-field checkbox-width'
                      control={<Switch checked={watch('is_sfpa_completed') || false} onChange={(e) => { setValue('is_sfpa_completed', e.target.checked); }} color='primary' inputRef={register} name={'is_sfpa_completed'} />}
                      label="" />
                    {renderActions('is_sfpa_completed')}
                    {/* <FormControlLabel
              style={{ marginLeft: 0 }}
              control={
                <Checkbox
                  size='small'
                  onChange={(e) => {
                    setValue('is_sfpa_completed', e.target.checked);
                  }}
                  checked={watch('is_sfpa_completed') || false}
                  name="is_sfpa_completed"
                  inputRef={register}
                />
              }
              label="Yes"
            /> */}
                  </div>
                  :
                  <span className='page-label-value'>{search.is_sfpa_completed ? 'Yes' : 'No'}</span>}
              </div>
              <div className='d-flex intersection-page-view'>
                {renderFieldLabel('Days On Hold', 'days_on_hold')}

                {editingField === 'basic_info' ?
                  <div className='page-label-value'>
                    <div className="">{getTheDaysDifference(search)}</div></div>
                  :
                  <span className='page-label-value'>{getTheDaysDifference(search)}</span>}

              </div>
            </div>
            <div className='d-flex contact-details-row'>
              <div className='d-flex intersection-page-view'>
                {/* <label className='contact-view-labels'> Non-disclosure agreement</label> */}
                {renderFieldLabel('Non-disclosure agreement', 'is_nda')}
                {editingField === 'basic_info' ?
                  <div className='page-label-value'>
                    {/* <FormControlLabel
              style={{ marginLeft: 0 }}
              control={
                <Checkbox
                  size='small'
                  onChange={(e) => {
                    setValue('is_nda', e.target.checked);
                  }}
                  checked={watch('is_nda') || false}
                  name="is_nda"
                  inputRef={register}
                />
              }
              label="Yes"
            /> */}
                    <FormControlLabel
                      className='input-form-field checkbox-width'
                      control={<Switch checked={watch('is_nda') || false} onChange={(e) => { setValue('is_nda', e.target.checked); }} color='primary' inputRef={register} name={'is_nda'} />}
                      label="" />
                    {renderActions('is_nda')}
                  </div>
                  :
                  <span className='page-label-value'>{search.is_nda ? 'Yes' : 'No'}</span>}
              </div>
              <div className='d-flex intersection-page-view'>
                {/* <label className='contact-view-labels' >Start Date </label> */}
                {renderFieldLabel('Start Date', 'start_date')}
                {editingField === 'basic_info' ?
                  <div className='page-label-value'>
                    <KeyboardDatePicker
                      autoOk
                      //disableToolbar
                      label=''
                      variant='inline'
                      format='MM/dd/yyyy'
                      className='input-form-field'
                      ref={register}
                      name="start_date"
                      value={formatDate(watch('start_date')) || null}
                      onChange={(date) => handleChange("start_date", date)}
                      inputVariant="outlined" />
                    {renderActions('start_date')}
                  </div>
                  :
                  <span className='page-label-value'>{getDate(search.start_date)}</span>}
              </div>
            </div>
            <div className='d-flex contact-details-row'>
              <div className='d-flex intersection-page-view'>
                {/* <label className='contact-view-labels'>Confidential </label> */}
                {renderFieldLabel('Confidential', 'is_confidential')}
                {editingField === 'basic_info' ?
                  <div className='page-label-value'>
                    {/* <FormControlLabel
              style={{ marginLeft: 0 }}
              control={
                <Checkbox
                  size='small'
                  onChange={(e) => {
                    setValue('is_confidential', e.target.checked);
                  }}
                  checked={watch('is_confidential') || false}
                  name="is_confidential"
                  inputRef={register}
                />
              }
              label="Yes"
            /> */}
                    <FormControlLabel
                      control={<Switch checked={watch('is_confidential') || false} onChange={(e) => { setValue('is_confidential', e.target.checked); }} color='primary' inputRef={register} name={'is_confidential'} />}
                      label="" className='input-form-field checkbox-width' />
                    {renderActions('is_confidential')}
                  </div>
                  :
                  <span className='page-label-value'>{search.is_confidential ? 'Yes' : 'No'}</span>}
              </div>
              <div className='d-flex intersection-page-view'>
                {/* <label className='contact-view-labels'> On Hold Date</label> */}
                {renderFieldLabel('On Hold Date', 'on_hold_date')}
                {editingField === 'basic_info' ?
                  <div className='page-label-value'>
                    <KeyboardDatePicker
                      autoOk
                      // disableToolbar
                      label=''
                      variant='inline'
                      format='MM/dd/yyyy'
                      className='input-form-field'
                      ref={register}
                      name="on_hold_date"
                      value={formatDate(watch('on_hold_date')) || null}
                      onChange={(date) => handleChange('on_hold_date', date)}
                      inputVariant="outlined" />
                    {renderActions('on_hold_date')}
                  </div>
                  :
                  <span className='page-label-value'>{getDate(search.on_hold_date)}</span>}
              </div>
            </div>
            <div className='d-flex contact-details-row'>
              <div className='d-flex intersection-page-view'>
                {renderFieldLabel('Probability', 'probability', editingField === 'basic_info')}
                {editingField === 'basic_info' ?
                  <div className="page-label-value">
                    <ProbabilitySelection
                      defaultValue={watch('probability') || null}
                      className='input-form-field'
                      variant="outlined"
                      onChange={(e, date) => handleChanges(e, date, 'probability')}
                      label={""}
                      InputLabelProps={{ focused: true }}
                    />
                    {renderActions('probability')}
                  </div>
                  :
                  <span className='page-label-value'>{search.probability ? search.probability : "--"}</span>}
              </div>
              <div className='d-flex intersection-page-view'>
                {/* <label className='contact-view-labels' > Competition </label> */}
                {renderFieldLabel('BD Status', 'bd_status', editingField === 'basic_info')}
                {editingField === 'basic_info' ?
                  <div className='page-label-value'>
                    <BDStatusSelection
                      defaultValue={watch('bd_status') || "Target Identified"}
                      className='input-form-field'
                      variant="outlined"
                      onChange={(e, date) => handleChanges(e, date, 'bd_status')}
                      label={""}
                      InputLabelProps={{ focused: true }}
                    />
                    {renderActions('bd_status')}
                  </div>
                  :
                  <span className='page-label-value'>{search.bd_status || "Target Identified"}</span>}
              </div>
            </div>
            <div className='d-flex contact-details-row'>
              <div className='d-flex intersection-page-view'>
                {/* <label className='contact-view-labels' > Competition </label> */}
                {renderFieldLabel('Competition', 'competition')}
                {editingField === 'basic_info' ?
                  <div className='page-label-value'>
                    <InputField
                      ref={register}
                      defaultValue={watch('competition') || null}
                      className='input-form-field'
                      onChange={(e) => {
                        setValue('competition', e.target.value);
                      }}
                      label=''
                      name='competition'
                      variant="outlined" />
                    {renderActions('competition')}
                  </div>
                  :
                  <span className='page-label-value'>{search.competition || "--"}</span>}
              </div>
            </div>

          </ExpansionPanelDetails>
        </ExpansionPanel>
        {/* additional information */}
        <ExpansionPanel defaultExpanded={true} className='panel-bottom-margin' style={{ background: '#fff', color: '#fff' }}>
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon
              style={{ color: "#fff", cursor: 'pointer' }} />}
            aria-controls="panel1a-content"
            style={{ background: '#2a364c' }}
          >

            <div className="d-flex" onClick={(e) => e.stopPropagation()}>
              {renderSectionLabel('Additional Info', 'additional_info')}
              {renderActions('additional_info')}
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className='d-flex flex-column'>


            <div className='d-flex contact-details-row'>
              <div className='d-flex intersection-page-view'>

                {renderFieldLabel('Job Function', 'job_functions', editingField === 'additional_info')}
                {editingField === 'additional_info' ?
                  <div className='page-label-value  multiple-selection-list'>
                    <JobFunctionSelection
                      className='input-form-field'
                      value={watch('job_functions') || []}
                      label=''
                      multiple
                      size='small'
                      filterSelectedOptions={true}
                      variant="outlined"
                      onChange={(e, data) => setValue('job_functions', data ? data.map((item) => typeof item === 'string' ? item : item.name) : [])} />
                    {renderActions('job_functions')}
                  </div>
                  :
                  <span className='page-label-value  multiple-view-selection-list'>{search.job_functions ? search.job_functions.map(item => item?.name || item).join(', ') : "--"}</span>}

              </div>
              <div className='d-flex intersection-page-view'>
                {renderFieldLabel('LOE Date', 'loe_date')}
                {editingField === 'additional_info' ?
                  <div className='page-label-value'>
                    <KeyboardDatePicker
                      autoOk
                      //disableToolbar
                      label=''
                      variant='inline'
                      format='MM/dd/yyyy'
                      className='input-form-field'
                      ref={register}
                      name="loe_date"
                      inputVariant="outlined"
                      value={formatDate(watch('loe_date')) || null}
                      onChange={(date) => handleChange('loe_date', date)} />
                    {renderActions('loe_date')}
                  </div>
                  :
                  <span className='page-label-value'>{getDate(search.loe_date)}</span>}

              </div>
            </div>
            <div className='d-flex contact-details-row'>
              <div className='d-flex intersection-page-view'>
                {/* <label className='contact-view-labels' > Next Status Call Date </label> */}
                {renderFieldLabel('Next Status Call Date', 'next_status_call_date')}
                {editingField === 'additional_info' ?
                  <div className='page-label-value'>
                    <KeyboardDatePicker
                      autoOk
                      // disableToolbar
                      label=''
                      variant='inline'
                      format='MM/dd/yyyy'
                      className='input-form-field'
                      ref={register}
                      name="next_status_call_date"
                      inputVariant="outlined"
                      value={watch('next_status_call_date') || null}
                      onChange={(date) => setValue('next_status_call_date', date)} />
                    {renderActions('next_status_call_date')}
                  </div>
                  :
                  <span className='page-label-value'>{search.next_status_call_date ? formatDate(search.next_status_call_date) : "--"}</span>}
              </div>
              <div className='d-flex intersection-page-view'>
                {/* <label className='contact-view-labels' > Next Status Call Time </label> */}
                {renderFieldLabel('Next Status Call Time', 'next_status_call_time')}
                {editingField === 'additional_info' ?
                  <div className='page-label-value'>
                    <KeyboardTimePicker
                      autoOk
                      // disableToolbar
                      label=''
                      variant='inline'
                      className='input-form-field'
                      ref={register('next_status_call_time')}
                      value={watch('next_status_call_time') || null}
                      onChange={(date) => setValue('next_status_call_time', date)}
                      keyboardIcon={<ScheduleIcon fontSize={'small'} />}
                      inputVariant="outlined" />
                    {renderActions('next_status_call_time')}
                  </div>
                  :
                  <span className='page-label-value'>{search.next_status_call_time ? formatDate(search.next_status_call_time, 'LT') : "--"}</span>}

              </div>
            </div>
            <div className='d-flex contact-details-row'>
              <div className='d-flex intersection-page-view'>
                {/* <label className='contact-view-labels' >Capital Structure </label> */}
                {renderFieldLabel('Capital Structure', 'capital_structure')}
                {editingField === 'additional_info' ?
                  <div className='page-label-value multiple-selection-list'>
                    <CapitalStructure
                      multiple
                      filterSelectedOptions
                      label=''
                      className='input-form-field'
                      defaultValue={watch('capital_structure') || []}
                      variant="outlined"
                      onChange={(e, data) => {
                        setValue("capital_structure", Array.isArray(data) ? data.map(item => item?.name || item) : []);
                      }} />
                    {renderActions('capital_structure')}
                  </div>
                  :
                  <span className='page-label-value  multiple-view-selection-list'>{(search.capital_structure && search.capital_structure.map(item => item?.name || item).join(', ')) || "--"}</span>}

              </div>
              <div className='d-flex intersection-page-view'>
                {/* <label className='contact-view-labels' >Executive Search Arena</label> */}
                {renderFieldLabel('Executive Search', 'is_executive_search_arena')}
                {editingField === 'additional_info' ?
                  <div className='page-label-value'>
                    {/* <FormControlLabel
               style={{ marginLeft: 0 }}
               control={
                 <Checkbox
                   size='small'
                   onChange={(e) => {
                     setValue('is_executive_search_arena', e.target.checked);
                   }}
                   checked={watch('is_executive_search_arena') || false}
                   name="is_executive_search_arena"
                   inputRef={register}
                 />
               }
               label="Yes"
             /> */}
                    <FormControlLabel
                      control={<Switch checked={watch('is_executive_search_arena') || false} onChange={(e) => { setValue('is_executive_search_arena', e.target.checked); }} color='primary' inputRef={register} name={'is_executive_search_arena'} />}
                      label="" className='input-form-field checkbox-width' />
                    {renderActions('is_executive_search_arena')}
                  </div>
                  :
                  <span className='page-label-value'>{search.is_executive_search_arena ? 'Yes' : 'No'}</span>}


              </div>
            </div>
            <div className='d-flex contact-details-row'>
              <div className='d-flex intersection-page-view'>
                {/* <label className='contact-view-labels'>Search VC/PE </label> */}
                {renderFieldLabel('Search VC/PE', 'vc_pe')}
                {editingField === 'additional_info' ?
                  <div className='page-label-value multiple-selection-list'>
                    <MultiCompanySelection
                      value={watch("vc_pe") || []}
                      multiple
                      filterSelectedOptions={true}
                      label=''
                      className='input-form-field'
                      variant="outlined"
                      onChange={(e, data) => setValue('vc_pe', data ? data.map(item => ({ name: item.name })) : [])} />
                    {renderActions('vc_pe')}
                  </div>
                  :
                  <span className='page-label-value  multiple-view-selection-list'>
                    {/* It is apply for issue of stringify object display from api */}
                    {/* {search.vc_pe ?
              search.vc_pe.map(item => {
                if(typeof item === 'object' && item.name && item.name.includes('{')){
                  return recursiveFunction(item.name)
                }else {
                  return typeof item === 'object' ? item.name : item
                }
              }).join(', ')
              : defaultValue
            } */}
                    {search.vc_pe && search.vc_pe.length > 0 ?
                      search.vc_pe.map(item => typeof item === 'object' ? item.name : item).join(', ')
                      : "--"}
                  </span>}

              </div>
              <div className='d-flex intersection-page-view'>
                {/* <label className='contact-view-labels'>Encore Number </label> */}
                {renderFieldLabel('Encore Number', 'encore_number')}
                {editingField === 'additional_info' ?
                  <div className='page-label-value'>
                    <InputField
                      ref={register}
                      defaultValue={watch('encore_number') || null}
                      className='input-form-field'
                      onChange={(e) => {
                        setValue('encore_number', e.target.value);
                      }}
                      label=''
                      name='encore_number'
                      variant="outlined" />
                    {renderActions('encore_number')}
                  </div>
                  :
                  <span className='page-label-value'>{search.encore_number || "--"}</span>}

              </div>
            </div>
            <div className='d-flex contact-details-row'>
              <div className='d-flex intersection-page-view'>
                {/* <label className='contact-view-labels' >Decision</label> */}
                {renderFieldLabel('Decision', 'decision')}
                {editingField === 'additional_info' ?
                  <div className='page-label-value'>
                    <InputField
                      ref={register}
                      defaultValue={watch('decision') || null}
                      className='input-form-field'
                      onChange={(e) => {
                        setValue('decision', e.target.value);
                      }}
                      label=''
                      name='decision'
                      variant="outlined" />
                    {renderActions('decision')}
                  </div>
                  :
                  <span className=' page-label-value'>{search.decision || "--"}</span>}

              </div>
              <div className='d-flex intersection-page-view'>
                {/* <label className='contact-view-labels'>End Date</label> */}
                {renderFieldLabel('End Date', 'close_date')}
                {editingField === 'additional_info' ?
                  <div className='page-label-value'>
                    <KeyboardDatePicker
                      autoOk
                      // disableToolbar
                      label=''
                      variant='inline'
                      format='MM/dd/yyyy'
                      className='input-form-field'
                      ref={register}
                      name="close_date"
                      minDate={watch('start_date')}
                      value={formatDate(watch('close_date')) || null}
                      onError={(error) => {
                        if (error) {
                          setRenderActionFlag(true);
                        } else {
                          setRenderActionFlag(false);
                        }
                      }}
                      onChange={(date) => handleChange('close_date', date)}
                      inputVariant="outlined" />
                    {renderActions('close_date')}
                  </div>
                  :
                  <span className='page-label-value'>{getDate(search.close_date)}</span>}

              </div>
            </div>

          </ExpansionPanelDetails>
        </ExpansionPanel>
        <div>&nbsp;</div>
        {/* <div className='d-flex '>
      <div className='contact-details'>
        {renderLabel('New Client', 'new_client')}
        {
          editingField === 'new_client' ?
            <div className='d-flex align-items-center'>
              <FormControlLabel
                style={{ marginLeft: 0 }}
                control={
                  <Checkbox
                    size='small'
                    onChange={(e) => {
                      setValue('new_client', e.target.checked);
                    }}
                    checked={watch('new_client') || false}
                    name="new_client"
                    inputRef={register}
                  />
                }
                label="Yes"
              />
              {renderActions('new_client')}
            </div>
            :
            <span className='page-label-value'>{search.new_client ? 'Yes' : 'No'}</span>
        }
      </div>
      <div className='contact-details'>
        {renderLabel('Region - Job Location', 'region')}
        {
          editingField === 'region' ?
            <div className='d-flex'>
              <RegionSelection
                className='input-form-field'
                defaultValue={watch('region') || null}
                value={watch("region") || null}
                label={""}
                onChange={(e, data) => {
                  setValue('region', data ? data.name : '');
                }}
              />
              {renderActions('region')}
            </div>
            :
            <span className='page-label-value'>{search.region ? search.region : defaultValue}</span>
        }
      </div>
    </div>
    <div className='d-flex contact-details-row'>
      <div className='contact-details'>
        {renderLabel('Vertical Practice', 'vertical_practice')}
        {
          editingField === 'vertical_practice' ?
            <div className='d-flex'>
              <PracticeSelection
                className='input-form-field'
                defaultValue={watch('vertical_practice') || null}
                value={watch('vertical_practice') || null}
                label={""}
                onChange={(e, data) => {
                  setValue('vertical_practice', data ? data.name : '');
                }}
                type={'vertical'}
              />
              {renderActions('vertical_practice')}
            </div>
            :
            <span className='page-label-value'>{search.vertical_practice ? search.vertical_practice : defaultValue}</span>
        }
      </div>
      <div className='contact-details'>
        {renderLabel('Horizontal Practice', 'horizontal_practice')}
        {
          editingField === 'horizontal_practice' ?
            <div className='d-flex'>
              <PracticeSelection
                className='input-form-field'
                defaultValue={watch('horizontal_practice') || null}
                value={watch('horizontal_practice') || null}
                label={""}
                placeholder={"Horizontal Practice"}
                name="horizontal_practice"
                onChange={(e, data) => {
                  setValue('horizontal_practice', data ? data.name : '');
                }}
                type={'horizontal'}
              />
              {renderActions('horizontal_practice')}
            </div>
            :
            <span className='page-label-value'>{search.horizontal_practice ? search.horizontal_practice : defaultValue}</span>
        }
      </div>
      {/* <div className='contact-details'>
        {renderLabel('Product One', 'product_one')}
        {
          editingField === 'product_one' ?
            <div className='d-flex align-items-center'>
              <FormControlLabel
                style={{ marginLeft: 0 }}
                control={
                  <Checkbox
                    size='small'
                    onChange={(e) => setValue('product_one', e.target.checked)}
                    checked={watch('product_one') || false}
                    name="product_one"
                    inputRef={register}
                  />
                }
                label={watch('product_one') ? "Yes" : "No"}
              />
              {renderActions('product_one')}
            </div>
            :
            <span className='page-label-value'>{search.product_one ? 'Yes' : 'No'}</span>
        }
      </div> */}
        {/* </div>
        <div className='d-flex contact-details-row'>
          <div className='contact-details'>
            {renderLabel('Stage', 'stage', editingField === 'stage')}
            {editingField === 'stage' ?
              <div className='d-flex'>
                <SearchStageSelection
                  className='input-form-field'
                  value={watch('stage') || null}
                  label=''
                  onChange={(e, data) => setValue('stage', data ? data.name : null)} />
                {renderActions('stage', true)}
              </div>
              :
              <span className='page-label-value'>{search.stage}</span>}
          </div>
          <div className='contact-details'>
            {renderLabel('Type', 'type', editingField === 'type')}
            {editingField === 'type' ?
              <div className='d-flex'>
                <SearchTypeSelection
                  className='input-form-field'
                  value={watch('type') || null}
                  label=''
                  onChange={(e, data) => setValue('type', data ? data.name : null)} />
                {renderActions('type', true)}
              </div>
              :
              <span className='page-label-value'>{search.type}</span>}
          </div>
        </div>
        <div className='d-flex contact-details-row'>
          <div className='contact-details'>
            {renderLabel('Results', 'result')}
            {editingField === 'result' ?
              <div className='d-flex'>
                <SearchResultSelection
                  className='input-form-field'
                  value={watch('result') || null}
                  label=''
                  onChange={(e, data) => setValue('result', data ? data.name : null)} />
                {renderActions('result')}
              </div>
              :
              <span className='page-label-value'>{search.result || defaultValue}</span>}
          </div>
          <div className='contact-details'>
            {renderLabel('Brand', 'brand')}
            {editingField === 'brand' ?
              <div className='d-flex'>
                <BrandSelection
                  className='input-form-field'
                  defaultValue={watch('brand') || null}
                  label={""}
                  onChange={(e, data) => {
                    setValue('brand', data ? data.name : '');
                  }} />
                {renderActions('brand')}
              </div>
              :
              <span className='page-label-value'>{search.brand ? search.brand : defaultValue}</span>}
          </div> */}

      </div>

      {/* <div className='d-flex '>
        </div> */}
      {/* <div className='d-flex '>
      <div className='contact-details'>
        {renderLabel('Work Group', 'work_group')}
        {
          editingField === 'work_group' ?
            <div className='d-flex'>
              <WorkgroupSelection

                register={register('work_group')}
                style={{ width: '350px' }}
                value={watch("work_group")}
                label=''
                className="input-field"
                onChange={(e, data) =>
                  setValue('work_group', data ? data.name : null)
                }
                InputLabelProps={{ focused: true }}
              />
              {renderActions('work_group', false)}
            </div>
            :
            <span className="page-label-value">{search.work_group || '--'}</span>
        }
      </div>

    </div> */}

    </div>
    {/* <div className="tab-view-right-nav col-sm-3 mt-2" style={{ position: "absolute", right: 0 }}>
      <div className="right-nav-section right-nav-recent-activity-section d-flex flex-column" style={{ position: "relative" }}>
        <a style={rightNav} href='/' className="right-nav-header" onClick={(e) => { e.preventDefault(); handelScroll('clientInformation'); }}>
            Client Information
        </a>
        <a style={rightNav} href='/' className="right-nav-header" onClick={(e) => { e.preventDefault(); handelScroll('clientDetails'); }}>
            Client Details
        </a>
      </div>
    </div> */}
    </>
  )
}

SearchInformationView.propTypes = {
  editSearch: PropTypes.func,
  search: PropTypes.array,
  renderLabel: PropTypes.func,
  renderActions: PropTypes.func,
  editingField: PropTypes.string,
  register: PropTypes.func,
  setValue: PropTypes.func,
  watch: PropTypes.func,
  getValues: PropTypes.func,
  reloadData: PropTypes.func,
  handelScroll: PropTypes.func,
  setInitialLoader: PropTypes.bool,
  setRenderActionFlag: PropTypes.bool,
  renderActionFlag: PropTypes.bool,
  renderSectionLabel: PropTypes.func,
  renderFieldLabel: PropTypes.func,
  isHide: PropTypes.bool,
}