
//-----------------------------------------------------------// External Imports // ------------------------------

import { getAccessToken } from "./cognitoService";
import { notesAttachmentDocumentApi } from "./ApiService";
import { DELETE, GET, POST, PUT } from "./constantService";

export const add = async (
  id,
  attachment,
  path = "company",
  type = "attachments",
  options
) => {
  const url = `/api/${path}/${id}/${type}`;
  return await notesAttachmentDocumentApi(POST, url, attachment, options);
};

export const getAll = async (id, path = "company", type = "attachments") => {
  const url = `/api/${path}/${id}/${type}`;
  return await notesAttachmentDocumentApi(GET, url);
};

export const update = async (
  attachmentId,
  data,
  path = "company",
  type = "attachments"
) => {
  const url = `/api/${path}/${type}/${attachmentId}`;
  return await notesAttachmentDocumentApi(PUT, url, data);
};

export const remove = async (
  attachmentId,
  path = "company",
  type = "attachments"
) => {
  const url = `/api/${path}/${type}/${attachmentId}`;
  return await notesAttachmentDocumentApi(DELETE, url);
};

export const download = async (
  attachmentId,
  path = "company",
  type = "attachments"
) => {
  const token = await getAccessToken();
  return `/api/${path}/${type}/${attachmentId}/download?token=${token}`;
};
