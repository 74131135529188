//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react'

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { withSnackbar } from 'notistack';
import { useSelector } from "react-redux";
// import { Auth } from 'aws-amplify';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import './index.css';
import { getAccessToken, clearAuthFromLocalStorage } from '../../../services/cognitoService';
import { getUserSelector } from "../../../selectors";
import { getHostedUILoginUrl } from '../../../Containers/Commons/urlUtil';
import { API } from '../../../services/constantService';
import { releaseLockApi } from '../../../services/ApiService';

const HeaderProfile = (props) => {
  const { className } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [profileUrl, setProfileUrl] = useState(null);
  const user = useSelector(getUserSelector)

  useEffect(() => {
    if (user?.profile_image_id) {
      getAccessToken().then((token) => {
        setProfileUrl(`${API.users}/${user.id}/profileimage?token=${token}`)
      });
    }
  }, [user])

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onLogout = async () => {
    handleClose();
    try {
      await releaseLockApi();
      clearAuthFromLocalStorage()
      window.location.href = getHostedUILoginUrl();
    } catch (e) {
      console.log('Error found in  onLogout::', e);
    }
  }

  return (
    <>
      <div className={`d-flex align-items-center ${className}`} onClick={handleClick}>
        {profileUrl ?
          <span className="profile-icon d-flex align-items-center">
            <img src={profileUrl} alt="Profile" />
          </span>
          :
          <span className="profile-icon profile-default-icon d-flex align-items-center">
            <AccountCircleIcon fontSize="inherit" />
          </span>
        }
        <span className="profile-name">
          {user?.name || ''}
        </span>
        <span className="profile-dropdown">
          <ArrowDropDownIcon fontSize="inherit" />
        </span>
      </div>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={onLogout}>Logout</MenuItem>
      </Menu>
    </>
  )
}

HeaderProfile.propTypes = {
  className: PropTypes.string,
  enqueueSnackbar: PropTypes.func
}

export default withSnackbar(HeaderProfile)
