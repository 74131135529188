
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useEffect, useState } from 'react'

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from 'prop-types'
import { useSnackbar } from "notistack";
import AddCircleIcon from "@material-ui/icons/AddCircle";

//-----------------------------------------------------------// Internal Imports // ------------------------------

import CompanySelection from "../../../AddContact/Components/CompanySelection";
import InputField from "../../../common/InputField";
import YearSelection from "../../../YearSelection";
import { createRecord, deleteRecord, renderActions, renderDetails, updateRecord } from "../utils";
import { RedirectToCompanies, sortByChronologicalOrder } from '../../../../utils/common';
import { ERROR, GET } from '../../../../services/constantService';
import { VALIDATION_MESSAGE, requireMessage } from '../../../../services/MessageService';
import { contactDataApi } from '../../../../services/ApiService';
import { validateStartYearEndYear } from '../../../../utils/date';
export default function EmploymentHistoryView(props) {
  const { employmentHistory = [], contactId, setIsLoading, setContactData } = props;
  const [startYear, setStartYear] = useState('');
  const [employmentDetails, setEmploymentDetails] = useState([]);
  const [editIndex, setEditIndex] = useState(-1)
  const [hoverIndex, setHoverIndex] = useState(-1)
  const [isAdding, setIsAdding] = useState(false)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [oldRecords, setOldRecords] = useState([]);
  useEffect(() => {
    setEmploymentDetails(employmentHistory)
    setOldRecords(JSON.parse(JSON.stringify(employmentHistory)));
    //  setCancelRecord(employmentHistory);
  }, [employmentHistory])

  const handleChange = (index, field, value, updateProps = false) => {
    // updateProps for update on Perent component
    const details = [...employmentDetails]
    details[index][field] = value
    if (updateProps) {
      setEmploymentDetails(sortByChronologicalOrder(details))
      setContactData(sortByChronologicalOrder(details), 'job_history')
    } else {
      setEmploymentDetails(details)
    }
  }

  const addEmployment = () => {
    setEmploymentDetails(prevState => ([...prevState, {}]))
  }

  const removeEmployment = (index) => {
    let details = [...employmentDetails];
    details.splice(index, 1)
    setEmploymentDetails(sortByChronologicalOrder(details))
    setContactData(sortByChronologicalOrder(details), 'job_history')
  }

  const deleteEmployment = async (index) => {
    try {
      setIsLoading(true);
      await deleteRecord(index, {
        contactId,
        details: employmentDetails,
        enqueueSnackbar,
        removeEmployment,
        field: 'job-history',
        setEditIndex
      })
      setIsLoading(false);
    } catch (e) {
      console.log("Error found in deleteEmployment::", e);
    }

  }

  const handleAdd = () => {
    createRecord(isAdding, setEditIndex, employmentDetails, addEmployment, setIsAdding, enqueueSnackbar)
  }
  const isSameUser = (a, b) => {
    if ((a.company === b.company) && (a.start_year === b.start_year) && (a.end_year === b.end_year) && (a.title === b.title)) {
      return true;
    } else {
      return false;
    }
  }

  const handleUpdate = async index => {
    try {
      if (!employmentDetails[index].company || !employmentDetails[index].company && (employmentDetails[index].title || employmentDetails[index].start_year || employmentDetails[index].end_year)) {
        const message = requireMessage("Company", "is")
        enqueueSnackbar(message, { variant: ERROR })
        return
      }
      let jobHistoryYear = {}
      jobHistoryYear = validateStartYearEndYear(employmentDetails[index])
      if (oldRecords.length === employmentDetails.length) {
        const result = isSameUser(oldRecords[index], employmentDetails[index]);
        if (result === true) {
          handleCancel(index);
        } else {
          if ((jobHistoryYear && Object.keys(jobHistoryYear).length !== 0)) {
            enqueueSnackbar(VALIDATION_MESSAGE.start_end_year, { variant: ERROR });
            return true
          }
          else {
            setIsLoading(true);
            await updateRecord(index, {
              formData: employmentDetails, contactId, enqueueSnackbar, isAdding,
              handleChange, setIsAdding, setEditIndex, field: 'job-history',
              successMessage: 'Employment details saved successfully', errorMessage: 'Unable to delete employment details'
            })
            setIsLoading(false);
            setContactData(sortByChronologicalOrder(JSON.parse(JSON.stringify(employmentDetails))), 'job_history')
            setOldRecords(sortByChronologicalOrder(JSON.parse(JSON.stringify(employmentDetails))))
            await refreshContact()
          }

        }
      } else {
        if ((jobHistoryYear && Object.keys(jobHistoryYear).length !== 0)) {
          enqueueSnackbar(VALIDATION_MESSAGE.start_end_year, { variant: ERROR });
          return true
        }
        else {
          setIsLoading(true);
          await updateRecord(index, {
            formData: employmentDetails, contactId, enqueueSnackbar, isAdding,
            handleChange, setIsAdding, setEditIndex, field: 'job-history',
            successMessage: 'Employment details saved successfully', errorMessage: 'Unable to delete employment details'
          })
          setIsLoading(false);
          setOldRecords(sortByChronologicalOrder(JSON.parse(JSON.stringify(employmentDetails))))
          await refreshContact()
        }
      }
    } catch (e) {
      console.log("Error found in handleUpdate::", e);
    }
  }

  const handleCancel = async (index) => {
    //setEmploymentDetails(oldRecords);
    setIsLoading(true);
    const { status, data } = await contactDataApi(GET, contactId);
    if (status && status == 200)
      setEmploymentDetails(sortByChronologicalOrder(JSON.parse(JSON.stringify(data.job_history))))
    setIsLoading(false);
    if (isAdding) {
      setEmploymentDetails(data.job_history)
      removeEmployment(index)
      setIsAdding(false)
    }
    setEditIndex(-1)
  }

  const refreshContact = async () => {
    setIsLoading(true)
    try {
      const { status, data } = await contactDataApi(GET, contactId);
      if (status && status == 200) {
        setEmploymentDetails(sortByChronologicalOrder(JSON.parse(JSON.stringify(data.job_history))))
      }
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false)
  }

  const renderView = item => {
    return (
      <>
        <div className="d-flex contact-details-row">
          <div className="contact-details">
            <div className="contact-view-label">Company Name</div>
            <div className="contact-view-value">
              <div className="contact-view-value font-weight-bold">
                {item.company && item.company.name ?
                  <RedirectToCompanies id={item.company.id} name={item.company.name} showInLink={true} />
                  : '--'}
              </div>
            </div>
          </div>
          <div className="contact-details">
            <div className="contact-view-label">Title</div>
            <div className="contact-view-value">{item.title || '--'}</div>
          </div>
        </div>
        <div className="d-flex contact-details-row">
          <div className="contact-details">
            <div className="contact-view-label">Job Start Year</div>
            <div className="contact-view-value">{item.start_year || '--'}</div>
          </div>
          <div className="contact-details">
            <div className="contact-view-label">Job End Year</div>
            <div className="contact-view-value">{item.end_year || '--'}</div>
          </div>
        </div>
      </>
    )
  }

  const renderEdit = (item, index) => {
    return (
      <React.Fragment>
        <div className='d-flex flex-grow-1'>
          <CompanySelection
            autoFocus={true}
            defaultValue={item.company}
            onChange={(e, company) => {
              handleChange(index, 'company', company);
            }}
            className='input-form-field input-field-old'
            label='Company Name'
            placeholder={"Search"}
            InputLabelProps={{ focused: true, shrink: true }}
          />
          <InputField
            value={item.title || ''}
            className='input-form-field input-field-old'
            label='Title'
            placeholder='Title'
            InputLabelProps={{ focused: true, shrink: true }}
            onChange={(e) => { handleChange(index, 'title', e.target.value) }}
          />
        </div>
        <div className='d-flex flex-grow-1'>
          <YearSelection
            className='input-form-field input-field-old'
            value={item.start_year || null}
            onChange={(e, data) => {
              setStartYear(data)
              handleChange(index, 'start_year', data);
            }}
            label='Job Start Year'
            InputLabelProps={{ focused: true, shrink: true }}
            yearType='start'
          />
          <YearSelection
            className='input-form-field input-field-old'
            value={item.end_year || null}
            onChange={(e, data) => {
              handleChange(index, 'end_year', data);
            }}
            label='Job End Year'
            InputLabelProps={{ focused: true, shrink: true }}
            yearType='end'
            selectedStartYear={startYear}
          />
        </div>
      </React.Fragment>
    )
  }

  const renderEmploymentHistory = () => {
    return renderDetails(employmentDetails, { setHoverIndex, renderSubHeader, editIndex, renderEdit, renderView })
  }

  const renderSubHeader = (index) => {
    return (
      <div className="secion-sub-header d-flex">
        <span>COMPANY {index + 1}</span>
        {renderActions(index, { isAdding, contactId, editIndex, deleteEmployment, handleUpdate, handleCancel, hoverIndex, setEditIndex, setEmploymentDetails, sortByChronologicalOrder, setIsLoading }, enqueueSnackbar, closeSnackbar)}
      </div>
    )
  }

  return (
    <div className="section-container">
      <div className="section-header">
        Employment History
        <AddCircleIcon className='ml-2 cursor-pointer' fontSize='inherit' color='primary' onClick={handleAdd} />
      </div>
      {renderEmploymentHistory()}
    </div>
  )
}

EmploymentHistoryView.propTypes = {
  employmentHistory: PropTypes.array,
  contactId: PropTypes.string,
  setIsLoading: PropTypes.func,
  setContactData: PropTypes.func
}
