//----------------------------------------------// In-built Imports // -------------------------------------------------
import React from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import Companies from "./Companies";
import Contacts from "./Contacts";
import Searches from "./Searches";
import Workbenches from "./Workbenches";
export default function Records(props) {
  return (
    <div className='master-search-records h-100'>
      <Searches
        height={250}
        setSearchesCount={props.setSearchesCount}
        rowHeight={35}
        searchKey={props.searchKey}
      />
      <Contacts
        height={250}
        setContactsCount={props.setContactsCount}
        rowHeight={35}
        searchKey={props.searchKey}
      />
      <Companies
        height={250}
        setCompaniesCount={props.setCompaniesCount}
        rowHeight={35}
        searchKey={props.searchKey}
      />
      <Workbenches
        height={250}
        setWorkbenchesCount={props.setWorkbenchesCount}
        rowHeight={35}
        searchKey={props.searchKey}
      />
    </div>
  )
}

Records.propTypes = {
  searchKey: PropTypes.string,
  setContactsCount: PropTypes.func.isRequired,
  setCompaniesCount: PropTypes.func.isRequired,
  setSearchesCount: PropTypes.func.isRequired,
  setWorkbenchesCount: PropTypes.func.isRequired
}
