
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from "react";

//-----------------------------------------------------------// Internal Imports // ------------------------------

import Searches from "./Searches";

export default function MyPlacements() {
  return (
    <Searches
      searchType={"mysearches"}
      columnStateKey={"myPlacementsColumns"}
      searchStatus={"Placement"}
      title="My Placements"
      placedSearches
    />
  );
}
