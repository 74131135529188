//----------------------------------------------// In-built Imports // -------------------------------------------------
import React from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from "prop-types";
import { currencyWithCommas, getCurrencyPrefix } from "../../../../../Containers/Commons/Utils";
import TotalRevenueView from "../../../../AddBD/BillingDetails/Components/EstimatedRevenue/TotalRevenueView";
import InvoiceTable from "./InvoiceTable";
import "ag-grid-community/dist/styles/ag-grid.css";
import { getFixedDecimal } from "../../../../../utils/common";
//----------------------------------------------// Internal Imports // -------------------------------------------------


const InvoiceInfoTableView = (props) => {
  const { register, setValue, watch, currentValues, renderFieldLabel, updateField } = props

  const getValueWithCurrency = (data) => {
    return `${getCurrencyPrefix(watch('currency') || '')} ${currencyWithCommas(getFixedDecimal(data, 2).toFixed(2))}`
  }
  const getTotalIndirectFee = (type) => {
    return getValueWithCurrency(currentValues[`${type}_indirect_total_fee`] || 0)
  }
  const getRevenue = (type) => {
    return getValueWithCurrency(currentValues[`${type}_revenue`] || 0)
  }
  const getTotalPercentageOfBase = (type) => {
    return getValueWithCurrency(currentValues[`${type}_percentage_base`] || 0);
  }
  return (
    <div className="section-content flex-grow-1 w-100 m-0 p-3">
      <div className='d-flex flex-column flex-column px-1 py-1'>
        <div className="d-flex contact-details-row">
          <div className='d-flex intersection-page-view'>
            {renderFieldLabel('Term Type', "term_type")}
            <span className='page-label-value'>{watch('term_desc') || '--'}</span>
          </div>
          <div className='d-flex intersection-page-view'>
            {renderFieldLabel('Intervals', "intervals")}
            <span className='page-label-value text-transform-none'>{
              Object.keys(currentValues).length != 0 && currentValues.intervals_desc !== 'Other Interval' ?
            `${watch('intervals_desc') || '--'}` || '--' :
                <>
                  {watch('intervals_desc') || ''}
                </>
            }
            </span>
          </div>
        </div>
        <div className="d-flex contact-details-row">
          <div className='d-flex intersection-page-view'>
            {renderFieldLabel('Terms Of Invoice', "invoice_terms")}
            <span className='page-label-value'>{watch('invoice_terms_desc') || '--'}
            </span>
          </div>
          <div className='d-flex intersection-page-view'>
            {renderFieldLabel('', "")}
            <span className='page-label-value text-transform-none'>{
              Object.keys(currentValues).length != 0 && currentValues.intervals_desc !== 'Other Interval' ? null :
                `${watch('intervals_other') || '--'}` || '--'
            }
            </span>
          </div>
        </div>
        <div className="d-flex col-12 p-0">
          <div className='w-50'>
            <TotalRevenueView
              getTotalIndirectFee={getTotalIndirectFee}
              type='estimated'
              getRevenue={getRevenue}
              getTotalPercentageOfBase={getTotalPercentageOfBase}
            />
          </div>
          <div className='w-50 pl-4'>
            <TotalRevenueView
              getTotalIndirectFee={getTotalIndirectFee}
              type='actual'
              getRevenue={getRevenue}
              getTotalPercentageOfBase={getTotalPercentageOfBase}
            />
          </div>
        </div>
        <div className="mt-5">
          <InvoiceTable
            register={register}
            setValue={setValue}
            currentValues={currentValues}
            watch={watch}
            getValueWithCurrency={getValueWithCurrency}
            updateField={updateField}
          />
        </div>
      </div>
    </div >

  )
}

InvoiceInfoTableView.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  watch: PropTypes.func,
  currentValues: PropTypes.object,
  renderFieldLabel: PropTypes.func,
  updateField: PropTypes.func,
}

export default InvoiceInfoTableView;