
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from "react";

//-----------------------------------------------------------// External Imports // ------------------------------

import { Link } from "react-router-dom";
import { InputAdornment, Typography } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { KeyboardDatePicker } from "@material-ui/pickers";

//-----------------------------------------------------------// Internal Imports // ------------------------------

import { DEFAULT_DATE_FORMAT, formatDate } from "../../utils/date";
import { DateRenderer, getMatchFilterOptions, getMultiValueParam, getSingleValueParam } from "../Commons/Utils";
import { getDateFilterOptions, getFilterQuery } from "../Commons/Utils";
//import UserSelection from "../../components/UserSelection";
import TypeSelection from "../../components/TypeSelection";
import RepListSelection from "../../components/RepListSelection";
import InputField from "../../components/common/InputField";
import { WORKBENCHES_MESSAGE } from "../../services/constantService";

const getCreatedAt = (params) =>
  params.data && params.data.created_at
    ? formatDate(params.data.created_at, DEFAULT_DATE_FORMAT)
    : "";
/* const getUsers = (params) => {
  return params.value?.map((item) => item?.name).join(", ");
}; */
const getLastUpdatedAt = (params) => params.value ? formatDate(params.value, params.column && (DEFAULT_DATE_FORMAT /*+ " " + DEFAULT_TIME_FORMAT */)) : "";
const getTotalCounts = (params) => {
  return params.value;
};
const getDescription = (params) => {
  return params.value;
};
const workbenchNameTooltip = (params) => {
  return params.data?.is_locked ? WORKBENCHES_MESSAGE.TOOLTIP_MESSAGE : null
}

export const columnDefs = (
  checkboxSelection = false,
  showCheckbox = false,
  // showRestore,
  showFilter,
  maxWidth,
  workbenchtype
) => {
  const columnsDef = [
    {
      field: "name",
      colId: "name",
      headerName: "Name",
      cellRenderer: "IdRenderer",
      tooltipValueGetter: workbenchNameTooltip,
      pinned: "left",
      // minWidth: 50,
      // maxWidth: 200,
      lockPinned: true,
      checkboxSelection,
      filter: showFilter && "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains"],
        buttons: ["reset", "apply"],
        suppressAndOrCondition: true,
        closeOnApply: true,
      },
    },
    {
      field: "type",
      colId: "type",
      headerName: "Type",
      sortable: true,
      filter: showFilter && "CustomFilter",
      filterParams: {
        Component: TypeSelection,
        multiple: false,
      },
    },
    /* {
      field: "tagged_users",
      colId: "users",
      headerName: "Users",
      sortable: false,
      cellRenderer: getUsers,
      // filter: showFilter && "CustomFilter",
      // filterParams: {
      //   Component: UserSelection,
      //   multiple: true,
      // },
    }, */
    {
      field: "archive_date",
      colId: "archive_date",
      headerName: "Archive Date",
      cellRenderer: "DateRenderer",
      tooltipValueGetter: DateRenderer,
      filter: showFilter && "agDateColumnFilter",
      filterParams: {
        filterOptions: getDateFilterOptions(),
        buttons: ["reset", "apply"],
        suppressAndOrCondition: true,
        closeOnApply: true,
      },
    },
    {
      field: "created_at",
      colId: "created_at",
      headerName: "Created On",
      cellRenderer: getCreatedAt,
      tooltipValueGetter: DateRenderer,
      filter: showFilter && "agDateColumnFilter",
      filterParams: {
        filterOptions: getDateFilterOptions(),
        buttons: ["reset", "apply"],
        suppressAndOrCondition: true,
        closeOnApply: true,
      },
    },
    {
      field: "updated_at",
      colId: "updated_at",
      headerName: "Updated On",
      cellRenderer: getLastUpdatedAt,
      tooltipValueGetter: DateRenderer,
      filter: showFilter && "agDateColumnFilter",
      filterParams: {
        filterOptions: getDateFilterOptions(),
        buttons: ["reset", "apply"],
        suppressAndOrCondition: true,
        closeOnApply: true,
      },
    },
    {
      field: "description",
      colId: "description",
      headerName: "Description",
      tooltipValueGetter: getDescription,
      filter: showFilter && "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains"],
        buttons: ["reset", "apply"],
        suppressAndOrCondition: true,
        closeOnApply: true,
      },
    },
    {
      field: "actions",
      colId: "actions",
      sortable: false,
      headerName: "Actions",
      cellRenderer: "ActionsRenderer",
    },
    {
      field: "is_replist",
      colId: "is_replist",
      headerName: "Rep List",
      cellRenderer: "ReplistRenderer",
    },
    {
      field: "totalcount",
      colId: "total_records",
      headerName: "# Records",
      cellRenderer: getTotalCounts,
      tooltipValueGetter: getTotalCounts,
      filter: "agNumberColumnFilter",
      filterParams: {
        filterOptions: getMatchFilterOptions(),
        buttons: ["reset", "apply"],
        suppressAndOrCondition: true,
        closeOnApply: true,
      },
    },
  ];
  if (showCheckbox) {
    const columnDefsWithCheckbox = [
      {
        headerName: "",
        field: "check_box",
        colId: "check_box",
        maxWidth: 50,
        minWidth: 50,
        headerComponent: "HeaderCheckbox",
        headerCheckboxSelectionFilteredOnly: false,
        checkboxSelection: true,
        sortable: false,
        pinned: "left",
        lockPinned: true,
        lockPosition: true,
      },
      {
        field: "name",
        colId: "name",
        headerName: "Name",
        tooltipValueGetter: workbenchNameTooltip,
        minWidth: 50,
        maxWidth: 200,
        cellRenderer: "IdRenderer",
        pinned: "left",
        lockPinned: true,
        checkboxSelection,
        filter: showFilter && "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          buttons: ["reset", "apply"],
          suppressAndOrCondition: true,
          closeOnApply: true,
        },
      },
      {
        field: "type",
        colId: "type",
        headerName: "Type",
        minWidth: 50,
        maxWidth: 100,
        sortable: true,
        filter: showFilter && "CustomFilter",
        filterParams: {
          Component: TypeSelection,
          multiple: false,
        },
      },
      /* {
        field: "tagged_users",
        colId: "users",
        headerName: "Users",
        sortable: false,
        minWidth: 50,
        maxWidth: 200,
        cellRenderer: getUsers,
        // filter: showFilter && "CustomFilter",
        // filterParams: {
        //   Component: UserSelection,
        //   multiple: true,
        // },
      }, */
      {
        field: "archive_date",
        colId: "archive_date",
        headerName: "Archive Date",
        cellRenderer: "DateRenderer",
        tooltipValueGetter: DateRenderer,
        filter: showFilter && "agDateColumnFilter",
        minWidth: 50,
        maxWidth: 150,
        filterParams: {
          filterOptions: getDateFilterOptions(),
          buttons: ["reset", "apply"],
          suppressAndOrCondition: true,
          closeOnApply: true,
        },
      },
      {
        field: "created_at",
        colId: "created_at",
        headerName: "Created On",
        minWidth: 50,
        maxWidth: 150,
        cellRenderer: getCreatedAt,
        tooltipValueGetter: DateRenderer,
        filter: showFilter && "agDateColumnFilter",
        filterParams: {
          filterOptions: getDateFilterOptions(),
          buttons: ["reset", "apply"],
          suppressAndOrCondition: true,
          closeOnApply: true,
        },
      },
      {
        field: "updated_at",
        colId: "updated_at",
        headerName: "Updated On",
        minWidth: 50,
        maxWidth: 150,
        cellRenderer: getLastUpdatedAt,
        tooltipValueGetter: DateRenderer,
        filter: showFilter && "agDateColumnFilter",
        filterParams: {
          filterOptions: getDateFilterOptions(),
          buttons: ["reset", "apply"],
          suppressAndOrCondition: true,
          closeOnApply: true,
        },
      },
      {
        field: "description",
        colId: "description",
        minWidth: 50,
        maxWidth: 200,
        headerName: "Description",
        tooltipValueGetter: getDescription,
        filter: showFilter && "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          buttons: ["reset", "apply"],
          suppressAndOrCondition: true,
          closeOnApply: true,
        },
      },
      {
        field: "actions",
        colId: "actions",
        sortable: false,
        headerName: "Actions",
        maxWidth: 104,
        minWidth: 104,
        cellRenderer: "ActionsRenderer",
      },
      {
        field: "totalcount",
        colId: "total_records",
        headerName: "# Records",
        cellRenderer: getTotalCounts,
        tooltipValueGetter: getTotalCounts,
        filter: "agNumberColumnFilter",
        minWidth: 50,
        maxWidth: 120,
        filterParams: {
          filterOptions: getMatchFilterOptions(),
          buttons: ["reset", "apply"],
          suppressAndOrCondition: true,
          closeOnApply: true,
        },
      },
    ];
    if (
      workbenchtype === "user-searches" ||
      workbenchtype === "kg-searches" ||
      workbenchtype === "archived-searches" ||
      workbenchtype === "user" ||
      workbenchtype === "kg" ||
      workbenchtype === "archived"
    ) {
      columnDefsWithCheckbox.push({
        field: "is_replist",
        colId: "is_replist",
        headerName: "Rep List",
        minWidth: 50,
        maxWidth: 120,
        cellRenderer: "ReplistRenderer",
        filter: "CustomFilter",
        filterParams: {
          Component: RepListSelection,
          field: "id",
        },
      });
    }
    return columnDefsWithCheckbox;
  } else {
    return columnsDef;
  }

  // if (showRestore) {
  //   columnsDef.push({
  //     field: "actions",
  //     colId: "actions",
  //     sortable: false,
  //     headerName: "Actions",
  //     cellRenderer: "ActionsRenderer",
  //   });
  // }
};

export const IdRenderer = (params) => {
  let data;
  const linkColor = params.data?.is_locked ? 'error' : 'primary'
  if (params?.data?.type === 'Searches') {
    data = 'searches'
  }
  else {
    data = 'contacts'
  }
  return (
    <Link
      to={`/workbenches/${params?.data?.id}/${data}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Typography className={`text-link`} color={linkColor}>
        {params.value}
      </Typography>
    </Link>
  );
};

export const getWorkbenchTitle = (type) => {
  if (type === "user") {
    return "My Workbenches";
  } else if (type === "user-contacts") {
    return "My Workbenches-Contacts";
  } else if (type === "user-searches") {
    return "My Workbenches-Searches";
  } else if (type === "archived") {
    return "Archived Workbenches";
  } else if (type === "archived-contacts") {
    return "Archived Workbenches-Contacts";
  } else if (type === "archived-searches") {
    return "Archived Workbenches-Searches";
  } else if (type === "kg-contacts") {
    return "KG Workbenches-Contacts";
  } else if (type === "kg-searches") {
    return "KG Workbenches-Searches";
  } else {
    return "KG Workbenches";
  }
};

export const getTodayDate = () => {
  return formatDate(new Date(), "YYYY-MM-DD");
};

export const getArchiveDateFrom = () => {
  const archiveDateFrom = new Date();
  archiveDateFrom.setDate(archiveDateFrom.getDate() + 1);
  archiveDateFrom.setHours(0, 0, 0);
  return archiveDateFrom;
};

export function cleanupWorkbenchQuery(data) {
  if (data.users) {
    data.users = data.users.map((user) => user.id);
  }
  return data;
}

export function getWorkbenchQueryParamsString(data) {
  const name = getSingleValueParam(data, "name");
  const description = getSingleValueParam(data, "description");
  const users = getMultiValueParam(data.users, "users");
  const type = getSingleValueParam(data, "type");
  let fromCreate = "";
  if (data.fromCreate) {
    const fromCreateDate = new Date(data.fromCreate)
    fromCreateDate.setHours(0, 0, 0);
    fromCreate = `&created_at_from=${fromCreateDate}`;
  }
  let toCreate = "";
  if (data.toCreate) {
    const toCreatedDate = new Date(data.toCreate)
    toCreatedDate.setHours(23, 59, 59);
    toCreate = `&created_at_to=${toCreatedDate}`;
  }

  return name + type + description + users + fromCreate + toCreate;
}

export function startAdornment() {
  return (
    <InputAdornment position="start" style={{ color: "#C4C4C4" }}>
      <SearchIcon />
    </InputAdornment>
  );
}

export const getFilterParamStringForWorkbench = (data, prefix) => {
  return getFilterQuery(data, {}, {}, prefix);
};

const dateProps = {
  variant: "inline",
  format: "dd/MM/yyyy",
  placeholder: "Select",
  disableToolbar: false,
  autoOk: true,
};

export const fieldDefinitions = {
  "Workbench Name": {
    component: InputField,
    field: "workbench_name",
    colId: "name",
    props: { placeholder: "Enter" },
  },
  // User: {
  //   component: UserSelection,
  //   field: "users",
  //   colId: "users",
  //   type: "search",
  //   props: {
  //     placeholder: "Select",
  //     multiple: true,
  //     filterSelectedOptions: true,
  //     size: "small",
  //   },
  // },
  Type: {
    component: TypeSelection,
    field: "type",
    colId: "type",
    type: "selection",
    props: {
      placeholder: "Select",
      multiple: false,
      // filterSelectedOptions: true,
      size: "small",
    },
  },
  Description: {
    component: InputField,
    field: "description",
    colId: "description",
    props: { placeholder: "Enter" },
  },
  "Created Date Range": {
    component: KeyboardDatePicker,
    field: "created_at",
    colId: "created_at",
    type: "dateRange",
    props: { ...dateProps },
  },
};
