import React, { useEffect, useRef, useState } from 'react'

//----------------------------------------------// External Imports // -------------------------------------------------
import { AgGridReact } from 'ag-grid-react';
import PropTypes from 'prop-types';
import "ag-grid-community/dist/styles/ag-grid.css";
import EditIcon from "@material-ui/icons/Edit";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import { Typography } from '@material-ui/core'
//----------------------------------------------// Internal Imports // -------------------------------------------------

import { getDateFieldValue, invoiceColumnDefs } from '../../../utils';
import { PAGE_LIMIT } from '../../../../ViewContact/utils';
import { CustomLoadingOverlayComponent, DateRenderer, getCurrencyPrefix } from '../../../../../Containers/Commons/Utils'
import GenericCellEditor from '../../../../../Containers/Commons/CellEditors/GenericCellEditor'
import NumberEditor from '../../../../../Containers/Commons/CellEditors/NumberEditor'
import { useSnackbar } from 'notistack';
import { ERROR, INVOICE_INFO_ROLE, INVOICE_VALIDATION } from '../../../../../services/constantService';
import { AddRecordButton, getFixedDecimal } from '../../../../../utils/common';
import ConfirmationPopup from '../../../../ConfirmationPopup';
import CheckboxSelection from '../../../../CheckboxSelection'
import { validateDate } from '../../../../../utils/date';
import CustomFilter from '../../../../../Containers/Commons/CustomFilter';
import ConfirmProjectedDatePopup from './ConfirmProjectedDatePopup';
import { getUserSelector } from '../../../../../selectors';
import { useSelector } from 'react-redux';
import { ChangeDetectionStrategyType } from 'ag-grid-react/lib/changeDetectionService'


let editIndex = -1;
let forceEditField = false;
let forceEditValue = false
const InvoiceTable = (props) => {
  const gridRef = useRef();
  const { register, setValue, currentValues, watch, getValueWithCurrency, updateField } = props;
  const [invoiceList, setInvoiceList] = useState([{}])
  const [previousList, setPreviousList] = useState([{}]);
  const [isEditing, setEditing] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const invoiceListRef = useRef();
  invoiceListRef.current = invoiceList;
  const [isPopupOpen, setPopupOpen] = useState(false)
  const [isDeletePopup, setDeletePopup] = useState(false)
  const [deleteIndex, setDeleteIndex] = useState(-1);
  const [projectedData, setProjectedData] = useState(null);
  const [isProjectedDateSelected, setIsProjectedDateSelected] = useState(false);
  const userData = useSelector(getUserSelector);
  const [isInvoiceInfoRole, setInvoiceInfoRole] = useState(false);
  useEffect(() => {
    register('invoice_info_searches')
    register('collected_percentage')
    register('total_invoice_amt')
    register('total_collected_amt')
    register('total_invoice_expense')
    setValue('invoice_info_searches', invoiceList)
  }, [register, invoiceList, setValue])

  useEffect(() => {
    if (currentValues) {
      setInvoiceList(currentValues['invoice_info_searches'] || [{}])
      setPreviousList(currentValues['invoice_info_searches'] || [{}])
    }

  }, [currentValues])

  const checkArrayContains = () => {
    if (userData?.all_roles) {
      const containsValue = INVOICE_INFO_ROLE.some(value => userData.all_roles.includes(value));
      return containsValue;
    }
    else {
      return false
    }
  };

  useEffect(() => {
    if (checkArrayContains()) {
      setInvoiceInfoRole(true);
    }
    else {
      setInvoiceInfoRole(false);
    }
  }, [])

  const handleChangeInvoiceList = (data, field, index, type, fromExpenses) => {
    if (field === 'expenses' && data === null) return;
    /* here use invoiceListRef because multiple time state update for all column */
    let existingInvoiceList = [...invoiceListRef.current];
    existingInvoiceList[index] = { ...existingInvoiceList[index], [field]: type === 'Number' ? Number(data) : data }
    if (fromExpenses) {
      // if (field === 'expense') {
      // } 
      if (field === 'billed_date') {
        existingInvoiceList[index].due_by_date = getDateFieldValue({ data: existingInvoiceList[index] }, 'due_by_date', watch('invoice_terms'))
      } else {
        existingInvoiceList[index] = {
          ...existingInvoiceList[index],
          retainer: 0,
          indirect_fee: 0,
          invoice_amt: 0
        }
      }
    } else {
      if (!existingInvoiceList[index].expenses) {
        existingInvoiceList[index].invoice_amt = Number(existingInvoiceList[index]?.retainer || 0) + Number(existingInvoiceList[index]?.indirect_fee || 0)
      }
    }
    existingInvoiceList[index].outstanding_amt = existingInvoiceList[index].invoice_amt - Number(existingInvoiceList[index].collected_amt || 0)
    setInvoiceList(existingInvoiceList)
    invoiceListRef.current = existingInvoiceList
  }

  const validateInvoiceInfo = (item) => {
    let error;
    /* Requirement changes for validations, no fields are required */
    /* 
    let keyList = []

    if (!item?.invoice_label) keyList.push('Invoice Label');
    if (!item.invoice_id) keyList.push('Invoice ID');
    // if (!item.expenses||!item.expenses===0) keyList.push('Expenses');
    if (!item?.collected_date) keyList.push('Date Collected');
    // if (!item?.invoice_amt) keyList.push('Invoice Amount');
    if (item?.collected_amt === null || item?.collected_amt === undefined) keyList.push('Collected Amount');
    const message = keyList.join(', ');
    if (keyList.length) {
      error = keyList.length > 1 ? `${message} fields are required` : `${message} field is required`;
      // } else if (!(item?.invoice_amt > 0)) {
      //   error = 'Invoice Amount should be greater then 0'
    } else if (!(item?.collected_amt > 0)) {
      error = 'Collected Amount should be greater then 0'
      // } else if (validateField('retainer', 'actual_revenue')) {
      //   error = 'Retainer amount must be less then or equal to Actual Revenue.'
    } 
    if (item?.collected_amt > item?.invoice_amt) {
      error = 'Collected amount must be less then or equal to Invoice amount.'
    } */
    let inValidDate = []
    if (!validateDate(item.billed_date)) {
      inValidDate.push('Date Billed')
    }
    if (!validateDate(item.due_by_date)) {
      inValidDate.push('Due By Date')
    }
    if (!validateDate(item.collected_date)) {
      inValidDate.push('Date Collected')
    }
    if (!validateDate(item?.projected_bill_date)) {
      inValidDate.push('Projected Bill Date')
    }
    let keyList = [];

    if (!item?.projected_bill_date && !item?.expenses) keyList.push('Projected Bill Date');
    const message = keyList.join(', ');
    if (inValidDate.length) {
      error = `Please enter valid date for ${inValidDate.length > 1 ? 'fields' : 'field'} ${inValidDate.join(', ')}`
    }
    if (keyList.length) {
      error = keyList.length > 1 ? `${message} are required` : `${message} is required`;
    }
    // if (!item.expenses > 0) {
    // }
    /* if invalid for then re-editing ag-grid row */
    if (error) {
      enqueueSnackbar(error, { variant: ERROR })
      return false;
    }
    return true
  }

  const updateRowData = async (params, checkEmpty) => {
    try {
      if (forceEditField) {
        handleChangeInvoiceList(forceEditValue, forceEditField, editIndex, null, true)
        params.api.refreshCells({ force: true })
        startRowEditing(params, editIndex, forceEditField)
        forceEditField = false;
        forceEditValue = false;
        return
      }
      if (editIndex === -1) return;
      let index = editIndex
      stopRowEditing(params, false, true)
      /* used setTimeOut because update state and form Values and then validate form data and update search */
      setTimeout(async () => {
        const item = watch('invoice_info_searches')[index]
        if (checkEmpty && item && !Object.keys(item).length) {
          stopRowEditing(params, true, true)
          return;
        }
        if (!validateInvoiceInfo(item)) {
          startRowEditing(null, index)
          return;
        } else {
          await updateField(index, `${item.id ? 'Updating' : 'Adding new'} record`, item.id ? 'update' : 'create')
        }
      }, 0)
    } catch (err) {
      console.log('error in updateRowData::', err)
    }
  }

  const existingStartEditRow = () => {
    enqueueSnackbar('Please complete editing or cancel', { variant: ERROR });
    startRowEditing(null, editIndex)
  }

  const handleRemoveInvoiceList = async (index, confirm = false) => {
    if (editIndex !== -1 && editIndex !== invoiceList.length) return existingStartEditRow();
    if (!confirm) {
      setDeleteIndex(index);
      return setDeletePopup(true)
    }
    let existingInvoiceList = [...invoiceList];
    const deletedInvoice = existingInvoiceList.splice(index, 1)
    setInvoiceList(existingInvoiceList);
    setTimeout(async () => await updateField(index, 'Deleting record', 'delete', deletedInvoice[0]), 0)
  }

  const handleAddInvoiceList = () => {
    let newInvoiceList = [...invoiceList, {}];
    setInvoiceList(newInvoiceList);
    editIndex = invoiceList.length
    setTimeout(() => startRowEditing(null, invoiceList.length), 0)
  }

  const getTotalFieldValue = (key, field) => {
    const calculateData = invoiceListRef.current
    const totalValue = calculateData.reduce((prev, curr) => {
      if (!curr.expenses) {
        prev = prev + (curr[key] || 0)
      }
      return prev
    }, 0) || 0;
    if (field) {
      setValue(field, getFixedDecimal(totalValue))
    }
    return totalValue;
  }

  /* const getAvailableField = (fieldKey, compareKey) => {
    if (fieldKey === 'retainer') {
      return (watch('actual_revenue') || 0) - (watch('actual_indirect_total_fee') || 0) - getTotalFieldValue(fieldKey)
    }
    return (watch(compareKey) || 0) - getTotalFieldValue(fieldKey)
  } */

  /* const validateField = (fieldKey, compareKey) => {
    if (getAvailableField(fieldKey, compareKey) < 0) {
      return true
    }
    return false
  } */

  const getPercentageCollected = () => {
    const actualRevenue = currentValues.actual_revenue || 0;
    let percentage = parseFloat(((getTotalFieldValue('collected_amt') || 0) / (currentValues.actual_revenue || 0) || 0) * 100);
    if (actualRevenue === 0) { percentage = 0 }
    percentage = getFixedDecimal(percentage);
    setValue('collected_percentage', percentage)
    return percentage
  }

  const getTotalExpenses = () => {
    const totalExpense = invoiceList.reduce((prev, curr) => {
      if (curr.expenses) {
        prev = prev + curr.invoice_amt
      }
      return prev;
    }, (0)) || 0;
    setValue('total_invoice_expense', getFixedDecimal(totalExpense));
    return totalExpense
  }

  const onGridReady = (params) => {
    //params.api.sizeColumnsToFit();
    // window.onresize = () => {
    //   params.api.sizeColumnsToFit();
    // }
    // params.api.currency = getCurrencyPrefix(watch('currency'))
    console.log(params)
  }

  const startRowEditing = (params, index, field) => {
    let editKeys = ['invoice_label', 'invoice_id', 'expenses', 'retainer', 'indirect_fee', 'invoice_amt', 'projected_bill_date', 'due_by_date', 'billed_date', 'collected_date', 'collected_amt']
    if (field) editKeys = [field, ...editKeys];
    /* for custom index programmatically row editing mode */
    if (index || index === 0) {
      editKeys.forEach(ele => {
        gridRef.current.api.startEditingCell({
          rowIndex: index,
          colKey: ele
        });
      })
      editIndex = index;
      setEditing(true)
      gridRef.current.api.refreshCells({ force: true })
      return
    }

    /* check if existing row editing */
    if (editIndex !== -1 && editIndex !== params.node.rowIndex) {
      existingStartEditRow()
      return;
    }

    /* for custom index programmatically row editing mode */
    editKeys.forEach(ele => {
      params.api.startEditingCell({
        rowIndex: params.node.rowIndex,
        colKey: ele
      })
    })
    editIndex = params.node.rowIndex
    setEditing(true)
    params.api.refreshCells({ force: true })
  }

  const stopRowEditing = (params, reset = false, skipUpdate = false) => {
    params.api.stopEditing();
    editIndex = -1;
    if (reset) {
      setInvoiceList([...previousList])
    }
    setEditing(false)
    params.api.refreshCells({ force: true })
    !skipUpdate && updateRowData(params, true)
  }

  const ActionsRenderer = (params) => {
    const disabled = editIndex !== -1 && params?.node?.rowIndex !== editIndex
    return editIndex === params?.node.rowIndex ? (
      <div className='d-flex justify-content-center'>
        <DoneIcon className='cursor-pointer done-icon' fontSize='large' onClick={() => updateRowData(params, true)} />
        <CloseIcon className='cursor-pointer close-icon' fontSize='large' onClick={() => setPopupOpen(true) /* stopRowEditing(params, true, true) */} />
      </div>

    ) : (
      !disabled ? (<div className='d-flex justify-content-center'>
        <EditIcon className='cursor-pointer edit-icon' fontSize='large' style={{
          opacity: isInvoiceInfoRole ? 0.5 : 1,
          pointerEvents: isInvoiceInfoRole ? 'none' : 'auto',
        }} onClick={() => startRowEditing(params, params.rowIndex)} />
        <CloseIcon className='cursor-pointer edit-icon' fontSize='large' style={{
          opacity: isInvoiceInfoRole ? 0.5 : 1,
          pointerEvents: isInvoiceInfoRole ? 'none' : 'auto',
        }} onClick={() => handleRemoveInvoiceList(params.rowIndex)} />
      </div>) : null
    )
  }


  const ProjectedDateRenderer = (params) => {
    const formattedDate = DateRenderer(params);
    return (
      <>
        {params && params.data && !params.data.expenses ? (
          <Typography
            className="text-link"
            color="primary"
            onClick={() => {
              setIsProjectedDateSelected(true);
              setProjectedData(params);
            }}
          >
            {formattedDate}
          </Typography>
        ) : (
          <span>{formattedDate}</span>
        )}
      </>
    );
  };

  // const onRowEditingStarted = (params) => {
  //   /* when click on ag-grid cells, check if existing row editing or not */
  //   if (isEditing && editIndex !== params.node.rowIndex) {
  //     params.api.stopEditing();
  //     enqueueSnackbar('Please complete editing or cancel', { variant: ERROR });
  //     setTimeout(() => startRowEditing(null, editIndex), 300)

  //   } else {
  //     startRowEditing(null, params.node.rowIndex)
  //   }
  // }

  const handleConfirmPopup = () => {
    setPopupOpen(false);
    stopRowEditing(gridRef.current, true)
  }

  const handleProjectedDateClose = () => {
    setIsProjectedDateSelected(false);
  }

  const handleCancelPopup = () => {
    setPopupOpen(false);
    return
  }

  const handleConfirmDeletePopup = () => {
    setDeletePopup(false);
    setDeleteIndex(-1)
    handleRemoveInvoiceList(deleteIndex, true)
  }

  const handleCancelDeletePopup = () => {
    setDeletePopup(false);
    setDeleteIndex(-1);
    return
  }

  const updateInfoState = (value, field) => {
    forceEditField = field
    forceEditValue = value
  }

  return (
    <div className={`d-flex flex-column team-information-table`}>
      {isPopupOpen ? <ConfirmationPopup
        header={INVOICE_VALIDATION.UNSAVED_DATA}
        onConfirm={handleConfirmPopup}
        onCancel={handleCancelPopup}
        onClose={handleCancelPopup}
        cancelText='No'
        confirmText='Yes'
      /> : null}
      {isDeletePopup ? <ConfirmationPopup
        header={INVOICE_VALIDATION.CONFIRM_TO_DELETE}
        onConfirm={handleConfirmDeletePopup}
        onCancel={handleCancelDeletePopup}
        onClose={handleCancelDeletePopup}
        cancelText='No'
        confirmText='Yes'
      /> : null}
      {isProjectedDateSelected && <ConfirmProjectedDatePopup
        onClose={handleProjectedDateClose}
        projectedData={projectedData}
        invoiceList={invoiceList}
        updateField={updateField}
        setInvoiceList={setInvoiceList}
        setValue={setValue}
        register={register}
        watch={watch}
        currentValues={currentValues}
        enqueueSnackbar={enqueueSnackbar}
      />
      }
      <div className={`d-flex table-header `}>
        <div>Invoice Info</div>
      </div>
      <div className='d-flex align-items-center justify-content-end py-2'>
        <div className='d-flex flex-grow-1'>
          <div className='d-flex col text-start'>
            <span className='invoice-heading-label'>Total  Invoice Amt</span>
            <span className='invoice-heading-value'>{getValueWithCurrency(getTotalFieldValue('invoice_amt', 'total_invoice_amt'))}</span>
          </div>
          <div className='d-flex col text-start'>
            <span className='invoice-heading-label'>% Collected</span>
            <span className='invoice-heading-value'>{getPercentageCollected()}</span>
          </div>
        </div>
      </div>
      <div className='d-flex align-items-center justify-content-end py-2'>
        <div className='d-flex flex-grow-1'>
          <div className='d-flex col text-start'>
            <span className='invoice-heading-label'>Total Collected Amt</span>
            <span className='invoice-heading-value'>{getValueWithCurrency(getTotalFieldValue('collected_amt', 'total_collected_amt'))}</span>
          </div>
          <div className='d-flex col text-start'>
            <span className='invoice-heading-label'>Total Expenses</span>
            <span className='invoice-heading-value'>{getValueWithCurrency(getTotalExpenses())}</span>
          </div>
        </div>
      </div>
      <div className='text-start py-2 px-3'>
        <AddRecordButton
          color={isEditing || isInvoiceInfoRole /* || (getPercentageCollected() >= 100) */ ? '' : 'red'}
          onClick={handleAddInvoiceList}
          disabled={isEditing /* || (getPercentageCollected() >= 100) */ || isInvoiceInfoRole}
        >
          Add Invoice
        </AddRecordButton>
      </div>
      <div className="list-view p-0">
        <div id="myGrid" className="ag-theme-alpine invoice-grid p-0">
          <AgGridReact
            onGridReady={onGridReady}
            enableBrowserTooltips={true}
            defaultColDef={{
              resizable: true,
              sortable: true,
              minWidth: 50,
              maxWidth: 200,
              width: 120,
              sortingOrder: ['asc', 'desc', null],
              suppressMovable: true,
            }}
            cacheBlockSize={PAGE_LIMIT}
            loadingOverlayComponent={"CustomLoadingOverlayComponent"}
            frameworkComponents={{
              CustomLoadingOverlayComponent,
              GenericCellEditor,
              ActionsRenderer,
              ProjectedDateRenderer,
              DateRenderer,
              NumberEditor,
              CheckboxSelection,
              CustomFilter
            }}
            suppressMenuHide={true}
            getRowNodeId={(data) => data.id}
            scrollbarWidth={12}
            rowData={invoiceList}
            columnDefs={invoiceColumnDefs(handleChangeInvoiceList, updateInfoState, getCurrencyPrefix(watch('currency')))}
            paginationPageSize={PAGE_LIMIT}
            suppressMovableColumns={false}
            suppressClickEdit={true}
            key={invoiceList}
            editType={'fullRow'}
            ref={gridRef}
            suppressColumnVirtualisation={true}
            rowDataChangeDetectionStrategy={ChangeDetectionStrategyType.IdentityCheck}
            onRowValueChanged={(params) => updateRowData(params, true)}
          ></AgGridReact>
        </div>
      </div>
      <div className='text-start py-2 px-3'>
        <AddRecordButton
          color={isEditing || isInvoiceInfoRole /* || (getPercentageCollected() >= 100) */ ? '' : 'red'}
          onClick={handleAddInvoiceList}
          disabled={isEditing /* || (getPercentageCollected() >= 100) */ || isInvoiceInfoRole}
        >
          Add Invoice
        </AddRecordButton>
      </div>
    </div>
  )
}

InvoiceTable.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  currentValues: PropTypes.object,
  watch: PropTypes.func,
  getValueWithCurrency: PropTypes.func,
  updateField: PropTypes.func
};

export default InvoiceTable;