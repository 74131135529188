//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useState } from 'react'

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types'
import Popup from 'reactjs-popup'
import DetailsIcon from '@material-ui/icons/LibraryBooks';
import CloseIcon from '@material-ui/icons/Close';
import { Tabs, Tab } from '@material-ui/core'
import { Helmet } from "react-helmet";

//----------------------------------------------// Internal Imports // -------------------------------------------------
import Details from './Details'
import './index.scss'
import { LinkedInRenderer } from "../../utils/common"
import PhoneView from '../ViewContact/ProfileView/PhoneView';
import EmailView from '../ViewContact/ProfileView/EmailView';
function TabPanel(props) {
  const { children, value, index } = props;
  return (
    value === index && <React.Fragment>
      {children}
    </React.Fragment>
  );
}

TabPanel.propTypes = {
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
  children: PropTypes.object
};

function ViewUser(props) {
  const { onClose, user, userUpdate } = props
  const [value, setTabValue] = React.useState(0);
  const [isDataUpdated, setIsDataUpdated] = useState(false);

  const onPopupClose = () => {
    onClose(isDataUpdated);
  }

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Popup open={props.visible} modal onClose={onPopupClose} closeOnDocumentClick={false} closeOnEscape={false}>
      <Helmet>
        <title>{`${user.first_name || ''} ${user.last_name || ''}` || '--'}</title>
      </Helmet>
      <div className="contact-profile-info">
        <div className="company-view-header align-items-center justify-content-between">
          <div className="d-flex align-items-center justify-content-between">
            <div className="contact-profile-name d-flex">{`${user.first_name || ''} ${user.last_name || ''}` || '--'}
              <div className="contact-profile-links ml-2">
                {LinkedInRenderer(user.linkedin_url)}
                <PhoneView contact={user} />
                <EmailView contact={user} />
              </div>
            </div>
            <span className="action-icon" onClick={onPopupClose} style={{ fontSize: 18, cursor: "pointer" }}>
              <CloseIcon fontSize="inherit" />
            </span>
          </div>
          <div className="d-flex align-items-center">
            <div className="contact-profile-info-text contact-profile-domain">{`${user.title || ''}`}</div>
          </div>
          <div className="d-flex align-items-center">
            <div className="contact-profile-info-text contact-profile-location">{`${user.state || ''}`}</div>
          </div>
        </div>
        <React.Fragment>
          <Tabs
            value={value}
            indicatorColor="secondary"
            textColor="primary"
            onChange={handleChange}
            aria-label="disabled tabs example"
          >
            {/* eslint-disable-next-line react/display-name */}
            <Tab component={React.forwardRef((params, ref) => {
              return (
                <div ref={ref} className="details-section align-items-center" onClick={params.onClick}>
                  <span className="action-icon mr-2" style={{ fontSize: 18 }}>
                    <DetailsIcon fontSize="inherit" />
                  </span>
                  <span>Details</span>
                </div>
              )
            })} />
          </Tabs>
          <div className="company-view-container">
            <TabPanel index={0} value={value}>
              <Details user={user} setIsDataUpdated={setIsDataUpdated} userUpdate={userUpdate} />
            </TabPanel>
          </div>
        </React.Fragment>
      </div>
    </Popup>
  )
}

ViewUser.propTypes = {
  onClose: PropTypes.func,
  user: PropTypes.object,
  visible: PropTypes.bool,
  userUpdate: PropTypes.func
}

export default ViewUser
