// -------------------------------------------------// In-built Imports// -------------------------------------------------
import React, { useRef } from 'react'

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from 'prop-types'

//-----------------------------------------------------------// Internal Imports // ------------------------------

import Attachments from '../../Attachments';
import { useDispatch, useSelector } from 'react-redux';
import { contactActions } from '../../../store/contactSlice';

const ContactResume = (props) => {

  const { contactId, disabled = false } = props;
  const contactResume = useSelector(state => state.contactReducer.contactResume)
  const dispatch = useDispatch();
  const resumesRef = useRef();

  const contactDispatch = (data) => {
    dispatch(contactActions.updateContactResume(data))
  }

  return (
    <div className="section-container mb-5">
      <div className="section-header">Resume</div>
      <Attachments ref={resumesRef} dispatchDetails={contactDispatch} attachmentsDetails={contactResume} disabled={disabled} apiConfig={{ parentId: contactId, parentPath: "contact", attachmentType: "resumes" }} uiConfig={{ canEdit: false, showDescription: false, attachmentType: "Resume" }} className="contact-attachments" />
      <button className="primary-btn" disabled={disabled} onClick={() => resumesRef.current.handleAddAttachment()}>Upload</button>
    </div>
  )
}

ContactResume.propTypes = {
  contactId: PropTypes.string,
  disabled: PropTypes.bool
}

export default ContactResume;
