//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useState, useEffect, useCallback } from 'react'

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types'
import DownloadIcon from '@material-ui/icons/GetApp';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import { Input, FormControlLabel, Checkbox, makeStyles } from '@material-ui/core'
import { useForm } from 'react-hook-form'
import { withSnackbar } from 'notistack';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { formatDate } from '../../utils/date'
import * as AttachmentsService from '../../services/AttachmentsService'
import Loader from '../common/Loader'
import { acquireLockApi, releaseLockApi } from '../../services/ApiService';
import { SUCCESS, ERROR, WARNING } from '../../services/constantService';
import { validateMessage, successMessage, unableMessage, VALIDATION_MESSAGE } from '../../services/MessageService';
import { showSnackbar } from '../../Containers/Commons/Utils';

const useStyles = makeStyles(() => ({
  label: {
    fontSize: 14,
    color: '#686A7E'
  },
  root: {
    paddingLeft: 0,
  }
}))

function Attachment(props) {
  const { attachment: attachmentDetails = {}, apiConfig = {}, uiConfig = { canEdit: true, showDescription: true, attachmentType: "Attachment" }, handleDeleteAttachment, handleSaveAttachment, enqueueSnackbar, closeSnackbar, disabled = false } = props
  const [attachment, setAttachment] = useState(attachmentDetails);
  const [uploadPercentage, setUploadPercentage] = React.useState(0);
  const { register, handleSubmit, setValue, watch } = useForm({ defaultValues: { ...attachment } });
  const classes = useStyles()

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setAttachment(attachmentDetails);
  }, [attachmentDetails])

  useEffect(() => {
    register('description')
  }, [register])

  const isFileUploadInProgress = useCallback(() => {
    return uploadPercentage !== 0 && uploadPercentage !== 100;
  }, [uploadPercentage])

  const handleSave = async (payload) => {
    try {
      if (isFileUploadInProgress()) {
        const message = validateMessage("file upload completes", "wait till")
        enqueueSnackbar(message, { variant: WARNING });
        return;
      }

      const file = attachment.file;
      if (file && !isFileUploadInProgress()) {
        setIsLoading(true);
        const options = {
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = Math.floor((loaded * 100) / total)
            if (percent < 100) {
              setUploadPercentage(percent)
            }
          },
          headers: { 'Content-Type': file.type, noAuthorization: true }
        }

        let bodyFormData = new FormData();
        bodyFormData.append('file', file);
        bodyFormData.append('description', payload.description);
        bodyFormData.append('show_in_clientsuite', payload.show_in_clientsuite);
        bodyFormData.append('show_in_candidatesuite', payload.show_in_candidatesuite);

        const { status, data } = await AttachmentsService.add(apiConfig.parentId, bodyFormData, apiConfig.parentPath, apiConfig.attachmentType, options);
        if (status === 200) {
          let updated = {
            ...attachment,
            ...payload,
            id: data.id,
            isEditable: false,
            created_at: new Date().toISOString(),
            file: null
          }
          setIsLoading(false);
          setAttachment(updated)
          handleSaveAttachment(updated);
          const message = successMessage(`${uiConfig.attachmentType}`, "added")
          enqueueSnackbar(data?.message || message, { variant: SUCCESS });
        } else {
          setIsLoading(false);
          const message = unableMessage(`${uiConfig.attachmentType}`, "add")
          enqueueSnackbar(data?.message || message, { variant: ERROR });
        }
      }
    } catch (e) {
      console.log("Error found in handleSave::", e);
    }
  }

  const handleUpdate = async (payload) => {
    try {
      if (isFileUploadInProgress()) {
        const message = validateMessage("current file upload completes", "wait till")
        enqueueSnackbar(message, { variant: WARNING });
        return;
      }

      payload.file_name = attachment.file_name;

      setIsLoading(true)
      const { status, data } = await AttachmentsService.update(attachment.id, payload, apiConfig.parentPath, apiConfig.attachmentType);
      setIsLoading(false)
      if (status === 200) {
        let updated = {
          ...attachment,
          ...payload,
          isEditable: false
        }
        setAttachment(updated);
        handleSaveAttachment(updated);
        const message = successMessage(`${uiConfig.attachmentType}`, VALIDATION_MESSAGE.updated_message)
        enqueueSnackbar(data?.message || message, { variant: SUCCESS });
      } else {
        const message = unableMessage(`${uiConfig.attachmentType}`, "update")
        enqueueSnackbar(data?.message || message, { variant: ERROR });
      }
      releaseLock()
    } catch (e) {
      console.log("Error found in handleUpdate::", e);
    }
  }

  const releaseLock = async () => {
    await releaseLockApi(apiConfig.parentId);
  }
  const handleShowInClientSuiteChnage = (e) => {
    setValue("show_in_clientsuite", e.target.checked);
    if (attachment.id && (uiConfig.attachmentType === 'Resume' || uiConfig.attachmentType === 'NDA')) {
      handleUpdate({
        ...attachment,
        show_in_clientsuite: e.target.checked
      });
    }
  }
  const handleShowInCandidateSuiteChange = (e) => {
    setValue("show_in_candidatesuite", e.target.checked);
    if (attachment.id && (uiConfig.attachmentType === 'Attachment' || uiConfig.attachmentType === 'NDA')) {
      handleUpdate({
        ...attachment,
        show_in_candidatesuite: e.target.checked
      });
    }
  }

  const setEditable = (isEditable) => {
    setAttachment((prev) => ({
      ...prev,
      isEditable: isEditable
    }))
  }

  const isEditMode = () => {
    return attachment.isEditable === true || attachment.id === undefined
  }

  const deleteAttachments = async (attachmentId) => {
    try {
      setIsLoading(true);
      const { status, data } = await AttachmentsService.remove(attachmentId, apiConfig.parentPath, apiConfig.attachmentType);
      setIsLoading(false)
      if (status === 200) {
        handleDeleteAttachment(attachment);
        const message = successMessage(`${uiConfig.attachmentType}`, "deleted")
        enqueueSnackbar(data?.message || message, { variant: SUCCESS });
      } else {
        const message = unableMessage(`${uiConfig.attachmentType}`, "delete")
        enqueueSnackbar(data?.message || message, { variant: ERROR });
      }
    } catch (e) {
      console.log("Error found in deleteAttachments::", e);
    }
  }

  const downloadAttachment = async (attachmentId) => {
    try {
      const url = await AttachmentsService.download(attachmentId, apiConfig.parentPath, apiConfig.attachmentType);
      if (url) {
        window.open(url)
      } else {
        const message = unableMessage(`${uiConfig.attachmentType}`, "download")
        enqueueSnackbar(message, { variant: ERROR });
      }
    } catch (e) {
      console.log("Error found in downloadAttachment::", e);
    }
  }

  const onCancel = () => {
    attachment.id ? setEditable(false) : handleDeleteAttachment(attachment)
    releaseLock()
  }

  const getFormId = (tag) => {
    return `attachment_${tag}_${attachment.id ? attachment.id : attachment.file_name}`;
  }

  return (
    <form className="d-flex position-relative attachment-details">
      <Loader show={isLoading} />
      <div className="d-flex flex-column  flex-grow-1 w-100">
        <div className="d-flex align-items-center">
          <span className="attachment-name flex-grow-1">{attachment.file_name}</span>
          {
            uploadPercentage !== 0 && !attachment.id &&
            <div className="progress" style={{ background: "#EEF2F8", width: "100px", height: "7px" }}>
              <div className="progress-bar" style={{ background: "#5C52C6", width: uploadPercentage }}></div>
            </div>
          }
        </div>
        {uiConfig.showDescription &&
          isEditMode() ?
          <Input onChange={(e) => setValue("description", e.target.value)} name="description" placeholder="Description" defaultValue={watch('description') !== 'undefined' ? watch('description') : ''} className="attachment-description" />
          :
          <span className="attachment-description">{attachment.description !== 'undefined' ? attachment.description : ''}</span>
        }
        {uiConfig.attachmentType === 'Resume' ?
          <FormControlLabel
            className="checkbox-container"
            classes={classes}
            control={<Checkbox 
              className="checkbox-component" size='small' onChange={handleShowInClientSuiteChnage} disabled={disabled} checked={watch('show_in_clientsuite') || false} name="show_in_clientsuite" inputRef={register} />}
            label="Show In Clientsuite"
          /> : null}
        {uiConfig.attachmentType === 'Attachment' ?
          <FormControlLabel
            className="checkbox-container"
            classes={classes}
            control={<Checkbox className="checkbox-component" size='small' onChange={handleShowInCandidateSuiteChange} disabled={disabled} checked={watch('show_in_candidatesuite') || false} name="show_in_candidatesuite" inputRef={register} />}
            label="Show In Candidatesuite"
          /> : null}
        {uiConfig.attachmentType === 'NDA' ?
          <div> <FormControlLabel
            className="checkbox-container"
            classes={classes}
            control={<Checkbox className="checkbox-component" size='small' onChange={handleShowInCandidateSuiteChange} disabled={disabled} checked={watch('show_in_candidatesuite') || false} name="show_in_candidatesuite" inputRef={register} />}
            label="Show In Candidatesuite"
          /> <FormControlLabel
            className="checkbox-container"
            classes={classes}
            control={<Checkbox className="checkbox-component" size='small' onChange={handleShowInClientSuiteChnage} disabled={disabled} checked={watch('show_in_clientsuite') || false} name="show_in_clientsuite" inputRef={register} />}
            label="Show In Clientsuite"
          /> </div>
          : null}
        {attachment.created_at && <span className="attachment-added-on">Added on {formatDate(attachment.created_at)}</span>}
      </div>
      <div className="d-flex notes-attachments-actions">
        {isEditMode() ?
          <>
            <input type="submit" value="submit" id={getFormId("submit")} hidden onClick={attachment.id ? handleSubmit(handleUpdate) : handleSubmit(handleSave)} />
            {
              !isFileUploadInProgress() &&
              <>
                <label htmlFor={getFormId("submit")} className="m-0 d-inline-flex">
                  <span className="action-icon mr-2">
                    <DoneIcon cursor='pointer' fontSize="inherit" color="primary" />
                  </span>
                </label>
                <span className="action-icon" onClick={onCancel}>
                  <CloseIcon cursor='pointer' fontSize="inherit" />
                </span>
              </>
            }
          </>
          :
          <>
            {uiConfig.canEdit && <span className="action-icon" onClick={async () => {
              let { status, data } = await acquireLockApi(apiConfig.parentId);
              if (status && status === 200 && data.message && data.user === false) {
                //if (data.isAdmin) {
                const object = {
                  enqueueSnackbar: enqueueSnackbar,
                  closeSnackbar: closeSnackbar,
                  message: data.message,
                  id: apiConfig.parentId
                }
                showSnackbar(object)
                /* }
                else {
                  enqueueSnackbar(data.message, { variant: WARNING });
                } */
              }
              else {
                setEditable(true)
              }
            }}>
              {!disabled && <EditIcon fontSize="inherit" />}
            </span>}
            <span className="action-icon" onClick={() => downloadAttachment(attachment.id)}>
              <DownloadIcon fontSize="inherit" />
            </span>
            <span className="action-icon" onClick={() => deleteAttachments(attachment.id)}>
              {!disabled && <DeleteIcon fontSize="inherit" />}
            </span>
          </>
        }
      </div>
    </form>
  )
}

Attachment.propTypes = {
  attachment: PropTypes.object,
  apiConfig: PropTypes.object,
  uiConfig: PropTypes.object,
  handleDeleteAttachment: PropTypes.func,
  handleSaveAttachment: PropTypes.func,
  enqueueSnackbar: PropTypes.func,
  closeSnackbar: PropTypes.func,
  disabled: PropTypes.bool
}

export default withSnackbar(Attachment);
