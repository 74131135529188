//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { forwardRef, useEffect, useRef, useState } from "react";

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from "prop-types";

//-----------------------------------------------------------// Internal Imports // ------------------------------

import "./index.scss"
import DecisionMatrixGraph from "./DecisionMatrixGraph";

import { ProductSettingsApi } from "../../services/ApiService";

import { GET } from "../../services/constantService";

// eslint-disable-next-line react/display-name
const DecisionMatrixChart = forwardRef((props, ref) => {
  const { item, setInstruction } = props
  const [chartData, setChartData] = useState(null)
  const chartRef = useRef()
  chartRef.current = chartData
  useEffect(() => {
    const defaultChartDetails = async () => {
      let sub_route = "find?org_id=KG&field_name=SFPA_DECISION_CHART&lang_cd=ENG"
      const { status, data } = await ProductSettingsApi(GET, null, sub_route);
      if (status === 200) {
        if (data) {
          setChartData(data)
          setInstruction(data)
        }
      }
    }
    defaultChartDetails()
  }, [])

  return (
    <>
      {
        chartRef.current && <DecisionMatrixGraph
          centerStyles={item.centerStyles ? item.centerStyles : chartRef.current.centerStyles}
          scatter_data={item.scatter_data}
          quadrantsData={item.quadrantsData ? item.quadrantsData : chartRef.current.quadrantsData}
          quadrantLabelData={item.quadrantLabelData ? item.quadrantLabelData : chartRef.current.quadrantLabelData}
          mainHeadLabelData={item.mainHeadLabelData ? item.mainHeadLabelData : chartRef.current.mainHeadLabelData}
          headLabelData={item.headLabelData ? item.headLabelData : chartRef.current.headLabelData}
          input_values={item.input_values}
          chart_scale={Number(chartRef.current.chart_scale)}
          is_hidden_Legends={item.sfpa ? item.sfpa : false}
          fontSize={item.fontSize ? item.fontSize : chartRef.current.fontSize}
          box_size={item.box_size ? item.box_size : chartRef.current.box_size}
          boxData={ item.boxData ? {...chartRef.current.boxData , ...item.boxData} : chartRef.current.boxData}
          pointData={item.pointData ? item.pointData : chartRef.current.pointData}
          chartAreaBorderData={item.chartAreaBorderData ? item.chartAreaBorderData : chartRef.current.chartAreaBorderData}
          candidateGraphRef={ref}
          client_area_shape_type={chartRef.current.client_area_shape_type}
          clientAreaCircleData={chartRef.current.clientAreaCircleData}
          initialCoords={item?.initialCoords}
        />
      }
    </>
  )
})

DecisionMatrixChart.propTypes = {
  item: PropTypes.object,
  setInstruction: PropTypes.object
}

export default DecisionMatrixChart