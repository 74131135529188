
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useState } from "react";

//-----------------------------------------------------------// External Imports // ------------------------------

import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import { withSnackbar } from "notistack";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

//-----------------------------------------------------------// Internal Imports // ------------------------------

import { POST, SUCCESS } from "../../../services/constantService";
import { userDataApi } from "../../../services/ApiService";
import { successMessage } from "../../../services/MessageService";

function SaveQueryDialog(props) {
  const { open, handleClose, queryData, userId, enqueueSnackbar, type } = props;
  const { register, handleSubmit } = useForm();
  const [error, setError] = useState(false);

  const saveQuery = async (data) => {
    const { status } = await userDataApi(
      POST,
      userId,
      { ...data, query: queryData },
      `${type}/queries`
    );
    if (status === 201) {
      handleClose();
      const message = successMessage("Contact by Query", "saved");
      enqueueSnackbar(`${data.name} ${message}`, { variant: SUCCESS });
    } else {
      setError(true);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle style={{ width: 400 }} id="form-dialog-title">
        Save Query
      </DialogTitle>
      <DialogContent>
        <TextField
          inputRef={register({ required: true })}
          autoFocus
          InputLabelProps={{ focused: true }}
          required={true}
          error={error}
          helperText={error ? "The query with given name already exist" : ""}
          margin="dense"
          name="name"
          label="Query Name"
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit(saveQuery)} color="primary">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

SaveQueryDialog.propTypes = {
  open: PropTypes.bool,
  userId: PropTypes.string,
  type: PropTypes.string,
  queryData: PropTypes.object,
  handleClose: PropTypes.func,
  enqueueSnackbar: PropTypes.func,
};

export default withSnackbar(SaveQueryDialog);
