
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useState } from "react";

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from "prop-types";
import { useSnackbar, withSnackbar } from "notistack";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import { Typography } from "@material-ui/core";

//-----------------------------------------------------------// Internal Imports // ------------------------------

import {
  columnDefs,
  PAGE_LIMIT,
  SUCCESS_STATUS_CODE,
  CustomLoadingOverlayComponent,
  DateTimeRendererLastActivity
} from "../utils";
import { CompanyNameRenderer } from '../../../Containers/Companies/utils';
import { IdRenderer } from "../../../Containers//Searches/Utils"
import { GET, WARNING } from "../../../services/constantService";
import { contactDataApi } from "../../../services/ApiService";
import { notFoundMessage } from "../../../services/MessageService";
import { useDispatch, useSelector } from "react-redux";
import { contactActions } from "../../../store/contactSlice";
import { RichTextPopupRendered } from "../../../utils/common";
let gridApi;
function Searches(props) {
  const { contact, stage } = props;
  const [rowCount, setRowCount] = useState(0)
  const { enqueueSnackbar } = useSnackbar();
  const contact_search = useSelector(state => state.contactReducer.contact_search)
  const dispatch = useDispatch()
  const dataSource = {
    getRows: async (params) => {
      if (!contact_search) {
        gridApi.showLoadingOverlay();
        let sub_route = `searches?limit=${PAGE_LIMIT}&page=${params.endRow / PAGE_LIMIT}`;
        if (stage) {
          sub_route += `&stage=${stage}`
        }
        const { status, data } = await contactDataApi(GET, contact.id, '', sub_route);
        gridApi.hideOverlay()
        if (status === SUCCESS_STATUS_CODE) {
          dispatch(contactActions.updateContactSearch(data));
          if (data?.paging?.totalCount === 0) {
            const message = notFoundMessage("records")
            enqueueSnackbar(message, { variant: WARNING })
          }
          params.successCallback(data.data, data.paging.totalCount);
          setRowCount(data.paging.totalCount);
          if (data.paging.totalCount === 0) {
            gridApi.showNoRowsOverlay()
          }
        }
        else {
          params.failCallback();
        }
      }
      else {
        if (contact_search?.paging?.totalCount === 0) {
          const message = notFoundMessage("records")
          enqueueSnackbar(message, { variant: WARNING })
        }
        params.successCallback(contact_search.data, contact_search.paging.totalCount);
        setRowCount(contact_search.paging.totalCount);
      }
    },
    rowCount: null
  };

  const onGridReady = (params) => {
    gridApi = params.api
    params.columnApi.autoSizeAllColumns();
  };

  const NameRenderer = (params) => {
    return <CompanyNameRenderer company={params?.data?.company} />
  }

  return (
    <React.Fragment>
      <div className="d-flex justify-content-end total-count" style={{ padding: '10px 25px' }}>
        <Typography>Total count: {rowCount}</Typography>
      </div>
      <div className="list-view">
        <div id="myGrid" className="ag-theme-alpine">
          <AgGridReact
            onGridReady={onGridReady}
            enableBrowserTooltips={true}
            defaultColDef={{
              minWidth: 20,
              resizable: true,
              sortable: false,
              sortingOrder: ['asc', 'desc', null]
            }}
            tooltipShowDelay={0}
            scrollbarWidth={12}
            suppressHorizontalScroll={false}
            cacheBlockSize={PAGE_LIMIT}
            loadingOverlayComponent={"CustomLoadingOverlayComponent"}
            frameworkComponents={{
              IdRenderer: IdRenderer,
              CustomLoadingOverlayComponent,
              DateTimeRendererLastActivity,
              NameRenderer: NameRenderer,
              RichTextPopupRendered: RichTextPopupRendered
            }}
            rowModelType={"infinite"}
            datasource={dataSource}
            columnDefs={columnDefs}
            paginationPageSize={20}
          ></AgGridReact>
        </div>
      </div>
    </React.Fragment>
  );
}

Searches.propTypes = {
  contact: PropTypes.object,
  stage: PropTypes.string
};

export default withSnackbar(Searches);
