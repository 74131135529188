
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from "react";

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from "prop-types";

//-----------------------------------------------------------// Internal Imports // ------------------------------

import LanguageDetails from "../../../AddContact/Components/LanguageDetails";

export default function LanguageDetailsEdit(props) {
  const { defaultValues, register, watch, setValue, unregister, control } = props

  return (
    <LanguageDetails
      register={register}
      unregister={unregister}
      watch={watch}
      setValue={setValue}
      currentValues={defaultValues}
      control={control}
    />
  )
}

LanguageDetailsEdit.propTypes = {
  register: PropTypes.func,
  control: PropTypes.object,
  dynamicKey: PropTypes.string,
  watch: PropTypes.func,
  setValue: PropTypes.func,
  unregister: PropTypes.func,
  defaultValues: PropTypes.object
};
