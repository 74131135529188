//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react'
import * as yup from "yup";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types'
import DoneIcon from '@material-ui/icons/Done';
import EditIcon from '@material-ui/icons/Edit';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ReactPhoneInput from "react-phone-input-material-ui";
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Button, Checkbox, FormControlLabel, FormControl, Switch, TextField, Typography } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { withSnackbar } from "notistack";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Tooltip from '@material-ui/core/Tooltip';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import Loader from '../../common/Loader';
import InputField from '../../common/InputField';
import LocationSelection from '../../AddUser/components/LocationSelection';
import StateSelection from '../../StateSelection';
import CountrySelection from '../../CountrySelection';
import TimezoneSelection from '../../AddUser/components/TimezoneSelection';
import DepartmentSelection from '../../AddUser/components/DepartmentSelection';
import SecondaryDepartmentSelection from '../../AddUser/components/SecondaryDepartmentSelection';
import PracticeSelection from '../../AddUser/components/PracticeSelection';
import Reference from '../../AddUser/components/Reference';
import RoleSelection from '../../AddUser/components/RoleSelection';
import WorkgroupSelection from '../../AddUser/components/WorkgroupSelection';

import TextArea from '../../common/TextArea';
import { isAuthorizedUser, prepareDefaultValues } from "./utils";
import { getAccessToken } from '../../../services/cognitoService';
import { determineInitialCountryGuess } from "../../../utils/phone";
import UserSelection from "../../../components/UserSelection";
import { API, ERROR, POST, PUT, SUCCESS } from '../../../services/constantService';
import { userDataApi } from '../../../services/ApiService';
import { successMessage, unableMessage, VALIDATION_MESSAGE } from '../../../services/MessageService';
import RichTextPopup from "../../RichTextPopup";
import { formatDate } from '../../../utils/date';

//import { capitalize } from "../../../utils/string";
import RegionSelection from "../../AddUser/components/RegionSelection"
import { useSelector } from 'react-redux';
import { getUserSelector } from '../../../selectors';
import { customFormValidator, requireValidMessage } from '../../../utils/common';
import AppRoleSelection from '../../AddUser/components/AppRoleSelection';

function Edit(props) {
  const { user = {}, getUser, setIsEditing, enqueueSnackbar, closeSnackbar, setIsDataUpdated, userUpdate } = props;
  const defaultValues = prepareDefaultValues({ ...user })
  const { register, unregister, handleSubmit, setValue, watch } = useForm({ defaultValues: { ...user } })
  const [loading, setLoading] = useState(false)
  const country = watch('country');
  const [profileUrl, setProfileUrl] = useState(null);
  const [isProfilePicUploading, setIsProfilePicUploading] = useState(false);
  const [newProfilePic, setNewProfilePic] = useState(null)
  const [websitePhoto, setWebsitePhoto] = useState(null);
  const [isWebsitePhotoUploading, setIsWebsitePhotoUploading] = useState(false);
  const [newWebsitePhoto, setNewWebsitePhoto] = useState(null)
  const [isLastSaveSuccess, setLastSaveSuccess] = useState(true)
  const [minimizeQuery, setMinimizeQuery] = useState(true);
  const userData = useSelector(getUserSelector)

  useEffect(() => {
    const getProfileURL = async () => {
      const token = await getAccessToken();
      setProfileUrl(`${API.users}/${user.id}/profileimage?token=${token}&time=${Date.now()}`)
    }

    if (user.profile_image_id) {
      getProfileURL();
    }

    const getWebsitePhoto = async () => {
      const token = await getAccessToken();
      setWebsitePhoto(`${API.users}/${user.id}/websitePhoto?token=${token}&time=${Date.now()}`)
    }

    if (user.website_photo_id) {
      getWebsitePhoto();
    }

  }, [user.profile_image_id, user.website_photo_id, user.id])
  useEffect(() => {
    register('roles');
    register('country');
    register('state');
    register('departments');
    register('secondary_departments');
    register('reference_user')
    register('project_manager')
    register('postal_code')
    register('street')
    register('app_roles')
    return () => {
      unregister('roles');
      unregister('country');
      unregister('departments');
      unregister('secondary_departments');
      unregister('reference_user');
      unregister('project_manager')
      unregister('postal_code');
      unregister('street');
      unregister('app_roles')
    }
  }, [register, unregister])

  const cleanup = (userData) => {
    if (userData['kg_number']?.length === 0) {
      delete userData['kg_number']
    }
    if (userData['country']) {
      userData.country = userData.country.name
    }
    if (userData && userData.state && typeof userData.state !== "string") {
      if (userData.state.inputValue) {
        userData.state = userData.state.inputValue
      } else {
        userData.state = userData.state.title
      }
    }

    if (userData['app_roles']) {
      const array = [];
      const app_roles = [];
      userData.app_roles.forEach(element => {
        array.push({ role_id: element.id })
        app_roles.push(element.name)
      })
      userData.app_roles = app_roles;
      userData.user_roles = array;
    }

    //Adding for phone input clear issue
    userData.mobile_phone = userData.mobile_phone === '+' ? null : userData.mobile_phone
    userData.work_phone = userData.work_phone === '+' ? null : userData.work_phone

    return userData
  }

  const uploadProfilePic = async () => {
    if (newProfilePic) {
      const bodyFormData = new FormData();
      bodyFormData.append('profile', newProfilePic);
      setIsProfilePicUploading(true);
      const { status, data } = await userDataApi(POST, user.id, bodyFormData, 'profileimage');
      setNewProfilePic(null);
      if (status === 200) {
        const message = successMessage("Profile pic", "uploaded")
        enqueueSnackbar(data?.message || message, { variant: SUCCESS });
      } else {
        const message = unableMessage("profile pic", "upload")
        enqueueSnackbar(data?.message || message, { variant: ERROR });
      }
      setIsProfilePicUploading(false);
    }
  }
  const uploadWebsitePhoto = async () => {
    if (newWebsitePhoto) {
      const bodyFormData = new FormData();
      bodyFormData.append('website', newWebsitePhoto);
      setIsWebsitePhotoUploading(true);
      const { status, data } = await userDataApi(POST, user.id, bodyFormData, 'websitePhoto');
      setNewWebsitePhoto(null);
      if (status === 200) {
        const message = successMessage("Website photo", "uploaded")
        enqueueSnackbar(data?.message || message, { variant: SUCCESS });
      } else {
        const message = unableMessage("website photo", "upload")
        enqueueSnackbar(data?.message || message, { variant: ERROR });
      }
      setIsWebsitePhotoUploading(false);
    }
  }

  const onFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (event.target.id === 'profile-pic-input') {
        setNewProfilePic(file)
        const fr = new FileReader();
        fr.onload = function () {
          setProfileUrl(fr.result)
        }
        fr.readAsDataURL(file);
      }
      else {
        setNewWebsitePhoto(file)
        const fr = new FileReader();
        fr.onload = function () {
          setWebsitePhoto(fr.result)
        }
        fr.readAsDataURL(file);
      }
    }
  }

  const autoSave = async (data, skipMessage, setAutoSaveLoading) => {
    setAutoSaveLoading && setAutoSaveLoading(true)
    if (setAutoSaveLoading) {
      setAutoSaveLoading(true)
    }
    await updateUserInfo(data, skipMessage)
    if (setAutoSaveLoading) {
      setTimeout(() => {
        setAutoSaveLoading(false)
      }, 1000);
    }
  }

  const updateUserInfo = async (updatedUser, skipMessage = false) => {
    // !skipMessage && setLoading(true)
    let returnResult;
    updatedUser = { ...updatedUser, roles: updatedUser.roles ? updatedUser.roles : user.roles }
    const { status, data } = await userDataApi(PUT, user.id, updatedUser);
    if (status && status === 200) {
      await getUser();
      setIsDataUpdated(true)
      userUpdate(data)
      const message = successMessage("User", VALIDATION_MESSAGE.updated_message)
      !skipMessage && enqueueSnackbar(message, { variant: SUCCESS });
      setLastSaveSuccess(true)
      returnResult = true;
    } else if (status === 409) {
      setLastSaveSuccess(false)
      const message = unableMessage("user", VALIDATION_MESSAGE.updated_message)
      !skipMessage && enqueueSnackbar(data?.message || message,
        {
          persist: true,
          variant: ERROR,
          // eslint-disable-next-line react/display-name
          action: key => (
            <Button
              size='small'
              variant='text'
              color='inherit'
              onClick={() => {
                closeSnackbar(key)
              }}
            >
              Dismiss
            </Button>
          )
        }
      );
      setLoading(false)
      returnResult = false;
    } else {
      setLastSaveSuccess(false)
      const message = unableMessage("update", "user")
      !skipMessage && enqueueSnackbar(data?.message || message, { variant: ERROR });
      setLoading(false)
      returnResult = false;
    }
    return returnResult
  }
  const editUsersSchema = yup.object().shape({
    first_name: yup.string().required(),
    last_name: yup.string().required(),
    title: yup.string().required(),
    email: yup.string().required(),
    roles: yup.array().required(),
    hiring_date: yup.string().required(),
  });

  const editUser = async (userData) => {
    try {
      const isValid = await editUsersSchema.isValid(userData)
      if (!isValid) {
        let requiredField = [
          { fieldName: "first_name", label: "First Name", type: String },
          { fieldName: "last_name", label: "Last Name", type: String },
          { fieldName: "title", label: "Title", type: String },
          { fieldName: "email", label: "Email", type: String },
          { fieldName: "roles", label: "Role", type: [] },
          { fieldName: "hiring_date", label: "Date of Hire", type: String }
        ]
        let dirtyField = customFormValidator(userData, requiredField)
        if (dirtyField) {
          const message = requireValidMessage(dirtyField)
          enqueueSnackbar(message, { variant: ERROR })
          return
        }
      }
      if (userData.kg_number && (userData.kg_number < 0 || userData.kg_number > 9999)) {
        enqueueSnackbar(VALIDATION_MESSAGE.kg_number_validation, { variant: ERROR });
      } else {
        userData.updated_at = user.updated_at
        userData.bio = user.bio
        userData.project_managers = userData.project_manager.length ? userData.project_manager.map(data => data.id) : [];
        userData.reference = userData.reference_user && userData.reference_user.id ? userData.reference_user.id : null;

        // if (userData.app_roles.some(obj => obj?.hasOwnProperty("id"))) {
        //   userData.app_roles = userData.app_roles
        // } else {
        //   userData.app_roles = user.all_roles
        // }
        setLoading(true);
        const body = cleanup({ ...userData })
        const { status, data } = await userDataApi(PUT, user.id, body);
        await uploadProfilePic()
        await uploadWebsitePhoto()
        if (status === 200) {
          await getUser();
          setIsDataUpdated(true)
          userUpdate(data)
          const message = successMessage("User", VALIDATION_MESSAGE.updated_message)
          enqueueSnackbar(message, { variant: SUCCESS });
          setIsEditing(false);
          setLastSaveSuccess(true);
        } else if (status === 409) {
          const message = unableMessage("user", VALIDATION_MESSAGE.updated_message)
          enqueueSnackbar(data?.message || message,
            {
              persist: true,
              variant: ERROR,
              // eslint-disable-next-line react/display-name
              action: key => (<Button size='small' variant='text' color='inherit' onClick={() => { closeSnackbar(key) }}>Dismiss</Button>)
            }
          );
        } else {
          const message = unableMessage("update", "user")
          enqueueSnackbar(data?.message || message, { variant: ERROR });
        }
        setLoading(false)
      }
    } catch (e) {
      console.log("Error found in handleConfirmPopup::", e);
    }
  }

  const onCancel = () => {
    setIsEditing(false);
  }

  const stateChange = (state) => {
    setValue('state', state);
  }

  return (
    <form className="d-flex flex-column h-100 position-relative" onSubmit={handleSubmit(editUser)} autoComplete="off">
      <div className={`company-view-content company-details-section d-flex editing user-edit-popup`}>
        <div className="edit-user d-flex flex-column w-100">
          <Loader show={loading} />
          <div>
            <div className="w-100 m-0 mb-4">
              <div className="d-flex mr-5">
                <div>
                  <Typography color="primary" className="mb-1">Bio Photo</Typography>
                  <Tooltip placement={'right-end'} title="This is a headshot that will show up on your Galaxy Bio and in ClientSuite" >
                    <label className="profile-image-container user-profile-image d-flex align-items-center justify-content-center edit" htmlFor="profile-pic-input">
                      <Loader show={isProfilePicUploading} />
                      <span className="image-container">{profileUrl ? <img src={profileUrl} alt="Profile" /> : <span className="default-profile-icon"><AccountCircleIcon fontSize="inherit" /></span>}</span>
                      <input id="profile-pic-input" type="file" accept="image/*" onClick={(e) => { e.target.value = null; setNewProfilePic(null) }} onChange={onFileChange} hidden />
                      <div className="profile-pic-edit">
                        <EditIcon fontSize="inherit" />
                      </div>
                    </label>
                  </Tooltip>
                </div>
                <div>
                  <Typography color="primary" className="mb-1 ml-4">Website Photo</Typography>
                  <Tooltip placement={'right-end'} title="This is a waist up photo that will show on the website, in Synclink and in CandidateSuite" >
                    <label className="profile-image-container user-profile-image d-flex align-items-center justify-content-center edit ml-5" htmlFor="website-photo-input">
                      <Loader show={isWebsitePhotoUploading} />
                      <span className="image-container">{websitePhoto ? <img src={websitePhoto} alt="Website" /> : <span className="default-profile-icon"><AccountCircleIcon fontSize="inherit" /></span>}</span>
                      <input id="website-photo-input" type="file" accept="image/*" onClick={(e) => { e.target.value = null; setNewWebsitePhoto(null) }} onChange={onFileChange} hidden />
                      <div className="profile-pic-edit">
                        <EditIcon fontSize="inherit" />
                      </div>
                    </label>
                  </Tooltip>
                </div>
              </div>
            </div>
            <ExpansionPanel className="w-100 m-0" expanded={minimizeQuery}>
              <ExpansionPanelSummary
                expandIcon={
                  <ExpandMoreIcon onClick={() => setMinimizeQuery(!minimizeQuery)} />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
                onClick={() => setMinimizeQuery(!minimizeQuery)}
              >
                <div className="contact-profile-name">Job Details and Contact Information</div>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails style={{ padding: '0 0 0 20px' }}>
                <div className='flex-column'>
                  <div className="d-flex ">
                    <InputField
                      ref={register}
                      className="input-field "
                      name={'first_name'}
                      // required={true}
                      label={<label>First Name<span className="MuiInputLabel-asterisk"> *</span></label>}
                      placeholder="First Name"
                      InputLabelProps={{ focused: true }}
                    />
                    <InputField
                      ref={register}
                      className="input-field"
                      name={'last_name'}
                      // required={true}
                      label={<label>Last Name<span className="MuiInputLabel-asterisk"> *</span></label>}
                      placeholder="Last Name"
                      InputLabelProps={{ focused: true }}
                    />
                    <InputField
                      ref={register}
                      className="input-field "
                      name={'company_name'}
                      label="Company Name"
                      placeholder="Company Name"
                      InputLabelProps={{ focused: true }}
                    />
                    <InputField
                      ref={register}
                      className="input-field "
                      name={'title'}
                      label={<label>Title<span className="MuiInputLabel-asterisk"> *</span></label>}
                      placeholder="Title"
                      // required={true}
                      InputLabelProps={{ focused: true }}
                    />
                  </div>
                  <div className="d-flex">
                    <InputField
                      ref={register}
                      className='input-field'
                      type='email'
                      // required={true}
                      label={<label>Email<span className="MuiInputLabel-asterisk"> *</span></label>}
                      placeholder='Email'
                      disabled={true}
                      InputLabelProps={{ focused: true }}
                      name='email'
                    />
                    <InputField
                      ref={register}
                      className='input-field'
                      type='email'
                      label='Personal Email'
                      placeholder='Personal Email'
                      // disabled={true}
                      InputLabelProps={{ focused: true }}
                      name='personal_email'
                    />
                    <LocationSelection
                      defaultValue={defaultValues.location}
                      register={register}
                      className="input-field"
                      name={'location'}
                      label="Location"
                      InputLabelProps={{ focused: true }}
                    />
                    <div className='input-form-field input-field-old input-field '>
                      <ReactPhoneInput
                        ref={register('work_phone')}
                        placeholder='Phone Number'
                        value={watch('work_phone') || ''}
                        onChange={(data, c, e, value) => {
                          if (data.length > 1) {
                            setValue('work_phone', value)
                          }
                          else {
                            setValue('work_phone', '')
                          }
                        }}
                        enableSearch={true}
                        enableSearchField={true}
                        disableInitialCountryGuess={determineInitialCountryGuess(defaultValues.work_phone)}
                        inputProps={{
                          label: 'Work Phone',
                          InputLabelProps: { focused: true }
                        }}
                        component={TextField}
                      />
                    </div>
                  </div>
                  <div className='d-flex'>
                    <InputField
                      ref={register}
                      className="input-field"
                      name={'extension'}
                      label="Extension"
                      placeholder="Extension"
                      InputLabelProps={{ focused: true }}
                    />
                    <div className='input-form-field input-field-old input-field'>
                      <ReactPhoneInput
                        ref={register('mobile_phone')}
                        placeholder='Phone Number'
                        value={watch('mobile_phone') || ''}
                        onChange={(data, c, e, value) => {
                          if (data.length > 1) {
                            setValue('mobile_phone', value)
                          }
                          else {
                            setValue('mobile_phone', '')
                          }
                        }}
                        enableSearch={true}
                        enableSearchField={true}
                        disableInitialCountryGuess={determineInitialCountryGuess(defaultValues.mobile_phone)}
                        inputProps={{
                          label: "Mobile Phone",
                          InputLabelProps: { focused: true }
                        }}
                        component={TextField}
                      />
                    </div>
                    <div className='input-form-field input-field-old input-field'>
                      <InputField
                        ref={register}
                        style={{ width: "95%" }}
                        name={'linkedin_url'}
                        label="LinkedIn URL"
                        placeholder="LinkedIn URL"
                        InputLabelProps={{ focused: true }}
                      />
                    </div>
                    <InputField
                      ref={register}
                      className="input-field"
                      name={'twitter_handle'}
                      label="Twitter Handle"
                      placeholder="Twitter Handle"
                      InputLabelProps={{ focused: true }}
                    />
                  </div>
                  <div className='d-flex w-75'>
                    <KeyboardDatePicker
                      className="input-field"
                      ref={register('hiring_date')}
                      autoOk
                      label={<label>Hire Date<span className="MuiInputLabel-asterisk"> *</span></label>}
                      // required={true}
                      format="dd/MM/yyyy"
                      margin="normal"
                      value={formatDate(watch('hiring_date')) || null}
                      InputLabelProps={{ focused: true }}
                      placeholder="dd/mm/yyyy"
                      onChange={(date) => { setValue('hiring_date', formatDate(date)); }}
                    />
                    <KeyboardDatePicker
                      className="input-field"
                      ref={register('termination_date')}
                      autoOk
                      label="Termination Date"
                      required={false}
                      format="dd/MM/yyyy"
                      margin="normal"
                      value={formatDate(watch('termination_date')) || null}
                      InputLabelProps={{ focused: true }}
                      placeholder="dd/mm/yyyy"
                      onChange={(date) => { setValue('termination_date', formatDate(date)); }}
                    />
                    <KeyboardDatePicker
                      className="input-field"
                      ref={register('birth_date')}
                      autoOk
                      label="Birth Date"
                      required={false}
                      format="dd/MM/yyyy"
                      margin="normal"
                      value={formatDate(watch('birth_date')) || null}
                      InputLabelProps={{ focused: true }}
                      placeholder="dd/mm/yyyy"
                      onChange={(date) => { setValue('birth_date', formatDate(date)); }}
                    />
                  </div>
                </div>
              </ExpansionPanelDetails >
            </ExpansionPanel >
            <ExpansionPanel className="w-100 m-0">
              <ExpansionPanelSummary
                expandIcon={
                  <ExpandMoreIcon /*onClick={() => setMinimizeQuery(!minimizeQuery)} */ />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              // onClick={() => setMinimizeQuery(!minimizeQuery)}
              >
                <div className="contact-profile-name">Location Details</div>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails style={{ padding: '0 0 0 20px' }}>
                <div className='flex-column' >
                  <div className="d-flex" style={{ width: '1000px' }}>
                    <CountrySelection
                      defaultValue={defaultValues.country}
                      className="input-field"
                      style={{ width: "250px" }}
                      label="Country"
                      InputLabelProps={{ focused: true }}
                      onChange={(e, data) => {
                        stateChange('');
                        setValue('country', data)
                      }}
                    />
                    <TimezoneSelection
                      defaultValue={defaultValues.time_zone}
                      register={register}
                      className="input-field"
                      InputLabelProps={{ focused: true }}
                    />
                    <TextArea
                      ref={register}
                      className="input-field"
                      name={'address'}
                      label="Address"
                      placeholder="Address"
                      InputLabelProps={{ focused: true }}
                    />
                    <InputField
                      ref={register}
                      className="input-field"
                      name={'city'}
                      label="City"
                      placeholder="City"
                      InputLabelProps={{ focused: true }}
                    />
                  </div>
                  <div className='d-flex w-75'>
                    <StateSelection
                      value={watch('state') || null}
                      country={country?.name ? country.name : country}
                      defaultValue={defaultValues && defaultValues.state}
                      register={register}
                      className='input-field'
                      onChange={(e, state) => {
                        stateChange(state);
                      }}
                    />
                    <InputField
                      ref={register}
                      className="input-field"
                      name={'postal_code'}
                      label="Postal Code"
                      placeholder="Postal Code"
                      InputLabelProps={{ focused: true }}
                    />
                    <InputField
                      ref={register}
                      className="input-field"
                      name={'street'}
                      label="Street"
                      InputLabelProps={{ focused: true }}
                    />
                  </div>
                </div>
              </ExpansionPanelDetails >
            </ExpansionPanel >
            {isAuthorizedUser(user, userData) && <ExpansionPanel className="w-100 m-0">
              <ExpansionPanelSummary
                expandIcon={
                  <ExpandMoreIcon />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div className="contact-profile-name">Department Info</div>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails style={{ padding: '0 0 0 20px' }}>
                <div className='flex-column' >
                  <div className="d-flex" style={{ width: '1000px' }}>
                    <DepartmentSelection
                      multiple
                      defaultValue={defaultValues.departments || []}
                      disableCloseOnSelect
                      filterSelectedOptions={true}
                      size='small'
                      className='input-field'
                      onChange={(e, data) =>
                        setValue('departments', Array.isArray(data) ? data.map((item) => item.name) : null)
                      }
                      InputLabelProps={{ focused: true }}
                    />
                    <SecondaryDepartmentSelection
                      multiple
                      disableCloseOnSelect
                      defaultValue={defaultValues.secondary_departments || []}
                      size='small'
                      filterSelectedOptions={true}
                      className='input-field'
                      onChange={(e, data) =>
                        setValue('secondary_departments', Array.isArray(data) ? data.map((item) => item.name) : null)
                      }
                      InputLabelProps={{ focused: true }}
                    />
                    <RegionSelection
                      defaultValue={defaultValues.region}
                      register={register}
                      className="input-field"
                      name={'region'}
                      label="Region"
                      InputLabelProps={{ focused: true }}
                    />
                    <PracticeSelection
                      defaultValue={defaultValues.vertical_practice}
                      register={register}
                      className="input-field"
                      name={'vertical_practice'}
                      label="Vertical Practice"
                      InputLabelProps={{ focused: true }}
                      type="vertical"
                    />
                  </div>
                  <div className='d-flex'>
                    <PracticeSelection
                      defaultValue={defaultValues.vertical_practice_2 || []}
                      register={register}
                      className="input-field"
                      name={'vertical_practice_2'}
                      label="Vertical Practice 2"
                      InputLabelProps={{ focused: true }}
                      type="vertical"
                    />
                    <PracticeSelection
                      defaultValue={defaultValues.horizontal_practice}
                      register={register}
                      className="input-field"
                      name={'horizontal_practice'}
                      label="Horizontal Practice"
                      placeholder={"Horizontal Practice"}
                      InputLabelProps={{ focused: true }}
                      type="horizontal"
                    />
                    <WorkgroupSelection
                      defaultValue={defaultValues.work_group}
                      register={register}
                      className="input-field"
                      InputLabelProps={{ focused: true }}
                    />
                    <InputField
                      ref={register('revenue_budget')}
                      type='number'
                      className="input-field"
                      name={'revenue_budget'}
                      label="Revenue Budget"
                      placeholder="Revenue Budget"
                      value={watch('revenue_budget') || null}
                      onChange={(e) => { setValue('revenue_budget', e.target.value === '' ? null : e.target.value) }}
                      InputLabelProps={{ focused: true }}
                    />
                  </div>
                  <div className='d-flex'>
                    <InputField
                      type='number'
                      ref={register('capacity')}
                      className="input-field"
                      name={'capacity'}
                      label="Capacity"
                      placeholder="Capacity"
                      value={watch('capacity') || null}
                      onChange={(e) => { setValue('capacity', e.target.value === "" ? null : e.target.value) }}
                      InputLabelProps={{ focused: true }}
                    />
                    <UserSelection
                      multiple
                      InputLabelProps={{ focused: true }}
                      filterSelectedOptions={true}
                      defaultValue={watch('project_manager') || null}
                      className='input-field'
                      label='Project Managers'
                      placeholder='Project Managers'
                      name='project_manager'
                      size='small'
                      onChange={(e, data) =>
                        setValue('project_manager', Array.isArray(data) ? data : null)
                      }
                    />
                    <Reference
                      className='input-field'
                      defaultValue={watch('reference_user') || null}
                      label="Referred By"
                      name='reference_user'
                      // required={true}
                      InputLabelProps={{ focused: true }}
                      onChange={(e, data) => setValue('reference_user', data ? data : null)}
                    />
                    <FormControl className='input-field'>
                      <Typography className='only-for-labels'>
                        KG Employee
                      </Typography>
                      <FormControlLabel
                        className="checkbox-container"
                        control={
                          <Checkbox
                            className="checkbox-component"
                            size="small"
                            defaultChecked={watch('kg_employee') ? watch('kg_employee') : false}
                            name='kg_employee'
                            ref={register('kg_employee')}
                            onChange={(data) => { setValue('kg_employee', data.target.checked) }}
                          />
                        }
                        label={watch('kg_employee') ? 'Yes' : 'No'}
                      />
                    </FormControl>
                  </div>
                  <div className='d-flex'>
                    {/* <FormControl className='input-field'>
                      <Typography className='only-for-labels'>
                        KG Employee
                      </Typography>
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            defaultChecked={watch('kg_employee') ? watch('kg_employee') : false}
                            name='kg_employee'
                            ref={register('kg_employee')}
                            onChange={(data) => { setValue('kg_employee', data.target.checked) }}
                          />
                        }
                        label={watch('kg_employee') ? 'Yes' : 'No'}
                      />
                    </FormControl> */}
                    <RoleSelection
                      className='input-field'
                      defaultValue={Array.isArray(user.roles) ? user.roles[0] : null}
                      label={<label>Role<span className="MuiInputLabel-asterisk"> *</span></label>}
                      // required={true}
                      InputLabelProps={{ focused: true }}
                      onChange={(e, data) => {
                        setValue('roles', data)
                      }}
                    />
                    <AppRoleSelection
                      multiple
                      ref={register('app_roles')}
                      className='input-field'
                      defaultValue={user.app_roles}
                      list={watch("app_roles")}
                      label={<label>App Role</label>}
                      // required={true}
                      InputLabelProps={{ focused: true }}
                      onChange={(e, data) => {
                        setValue('app_roles', data)
                      }}
                    />
                  </div>
                </div>
              </ExpansionPanelDetails >
            </ExpansionPanel >}
            {isAuthorizedUser(user, userData) && <ExpansionPanel className="w-100 m-0">
              <ExpansionPanelSummary
                expandIcon={
                  <ExpandMoreIcon />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <div className="contact-profile-name">Website</div>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails style={{ padding: '0 0 20px 20px' }}>
                <div className='flex-column' >
                  <div className="d-flex" style={{ width: '1000px' }}>
                    <FormControlLabel
                      style={{ color: '#5c52c6', marginTop: '20px', maxWidth: '31%' }}
                      control={<Switch className="checkbox-component" checked={watch('is_exclude_from_website') || false} onChange={(e) => { setValue('is_exclude_from_website', e.target.checked); }} color='primary' inputRef={register} name={'is_exclude_from_website'} />}
                      label="Exclude from Website"
                      className='input-field checkbox-container'
                    />
                    <div className='input-form-field input-field-old input-field'>
                      <InputField
                        ref={register}
                        style={{ width: "95%" }}
                        name={'website_url'}
                        label="URL"
                        placeholder="Website URL"
                        InputLabelProps={{ focused: true }}
                      />
                    </div>
                  </div>
                  <div className="d-flex" style={{ width: '1000px' }}>
                    <div style={{
                      width: '1000px',
                      marginTop: '5px',
                      position: "relative",
                    }}>
                      <Typography variant="body2" style={{ color: "#5F51CB", position: "absolute", top: "-10px" }}>KG Bio</Typography>
                      <div className='outlined-border transform p-0'>
                        <RichTextPopup
                          ref={register('bio')}
                          onSave={autoSave}
                          onChange={(data) => {
                            setValue(`bio`, data);
                          }}
                          placeholder={"Enter KG Bio"}
                          title="KG Bio"
                          name={'bio'}
                          value={user.bio || ''}
                          autoSave={autoSave}
                          saveStatus={isLastSaveSuccess}
                          InputLabelProps={{ focused: true }}
                          updatedDate={user.updated_at}
                          spfaSkillFlag={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </ExpansionPanelDetails >
            </ExpansionPanel >}
            {/* <div className="d-flex">
              <KeyboardDatePicker
                className="input-field"
                ref={register('birth_date')}
                autoOk
                label="Birth Date"
                variant="inline"
                format="dd/MM/yyyy"
                margin="normal"
                value={formatDate(watch('birth_date')) || null}
                InputLabelProps={{ focused: true }}
                placeholder="dd/mm/yyyy"
                onChange={(date) => { setValue('birth_date', formatDate(date)); }}
              />
              <InputField
                type='number'
                ref={register}
                className="input-field"
                name={'kg_number'}
                label="KG Number"
                onChange={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 4)
                }}
                InputLabelProps={{ focused: true }}
              />
            </div >
            <div className="d-flex">
              <RegionSelection
                defaultValue={defaultValues.region}
                register={register}
                className="input-field"
                name={'region'}
                label="Region"
                InputLabelProps={{ focused: true }}
              />
            </div>
            <div className='d-flex'>
              <InputField
                ref={register}
                className="input-field"
                name={'street'}
                label="Street"
                InputLabelProps={{ focused: true }}
              />
            </div>
            <div className="d-flex">
            </div>
            <div className='d-flex'>

              <PracticeSelection
                defaultValue={defaultValues.practice}
                register={register}
                className="input-field"
                name={'practice'}
                label="Practice"
                InputLabelProps={{ focused: true }}
              />
              <PracticeSelection
                defaultValue={defaultValues.practice_2}
                register={register}
                className="input-field"
                name={'practice_2'}
                label="Practice 2"
                placeholder={"Practice 2"}
                InputLabelProps={{ focused: true }}
              />
            </div>
            <div className='d-flex'>
              <InputField
                ref={register}
                className="input-field"
                name={'postal_code'}
                label="Postal Code"
                InputLabelProps={{ focused: true }}
              />
            </div>
            <div className='d-flex'>
              <WebsiteTagsSelection
                defaultValue={defaultValues.website_tag}
                className='input-field'
                style={{ maxWidth: '31%' }}
                register={register}
                InputLabelProps={{ focused: true }}
              />
            </div> */}
          </div >
        </div >
      </div >
      <div className="actions">
        <div className="d-flex">
          <label htmlFor="submit" className="m-0" >
            <div className="d-flex align-items-center action-edit">
              <span className="action-icon mr-2" style={{ color: "#DE9F6D", fontSize: 18 }}>
                <DoneIcon cursor='pointer' fontSize="inherit" />
              </span>
              <span>Save</span>
            </div>
          </label>
          <input id="submit" hidden className="primary-btn" type="submit" value="Update" />
          <div className="d-flex align-items-center action-edit cursor-pointer" onClick={onCancel}>
            <span>Cancel</span>
          </div>
        </div>
      </div>
    </form >
  )
}

Edit.propTypes = {
  user: PropTypes.object,
  watch: PropTypes.func,
  getUser: PropTypes.func,
  setIsEditing: PropTypes.func,
  setIsDataUpdated: PropTypes.func,
  enqueueSnackbar: PropTypes.func,
  closeSnackbar: PropTypes.func,
  userUpdate: PropTypes.func
}

export default withSnackbar(Edit);
