
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { START_YEAR, UPDATE_EMPLOYMENT_HISTORY_CONFIRMATION_MESSAGE } from '../../services/constantService';
import { DialogContent } from '@material-ui/core';
import YearSelection from '../../components/YearSelection';

export default function EmploymentHistoryConfirmationDialog(props) {
  const { open, handleClose, handleSubmit, contact, employmentHistoryMessageEndYearDate, setEmploymentHistoryEndYearDate } = props
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Update</DialogTitle>
      <DialogContent>
        <div className="jobhistory-message-container">
          <span className='field-details align-items-center jobhistory-content'>{UPDATE_EMPLOYMENT_HISTORY_CONFIRMATION_MESSAGE}</span>
          <div className='field-details align-items-center jobhistory-content'>
            <span className="field-title for-emp-his">Job title</span>
            <span className="field-value">{contact.current_job_title || '--'}</span>
            <span className="field-title for-emp-his">Company</span>
            <span className="field-value">{contact.company?.name || contact.current_company?.name || '--'}</span>
          </div>
          <div className='field-details align-items-center jobhistory-content'>
            <span className="field-title for-emp-his" >Start Year</span>
            <span className="field-value">{contact.current_job_start_year || '--'}</span>
            <span className="field-title for-emp-his">End Year</span>
            <span className="field-value">
              <YearSelection
                value={employmentHistoryMessageEndYearDate || null}
                onChange={(e, data) => {
                  setEmploymentHistoryEndYearDate(data)
                }}
                label=''
                placeholder=''
                InputLabelProps={{ focused: true }}
                yearType='tillEnd'
                selectedStartYear={START_YEAR} />
            </span>
          </div>
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          No
        </Button>
        <Button className='primary-btn' onClick={handleSubmit} color="primary" autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog >
  );
}
EmploymentHistoryConfirmationDialog.propTypes = {
  open: PropTypes.bool,
  contact: PropTypes.object,
  employmentHistoryMessageEndYearDate: PropTypes.string,
  setEmploymentHistoryEndYearDate: PropTypes.func,
  isActive: PropTypes.bool,
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func
}
