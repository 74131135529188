
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { Component } from "react";

//-----------------------------------------------------------// External Imports // ------------------------------

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import NumberFormat from "react-number-format";
import { saveAs } from "file-saver";
import { withSnackbar } from "notistack";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import { Button, Checkbox, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";

//-----------------------------------------------------------// Internal Imports // ------------------------------

import AdvancedQuery from "../Commons/AdvancedQuery";
import Query from "../../../src/Containers/Searches/Components/Query";
import Loader from "../../../src/components/common/Loader";
import { dataSourceUtils } from "../../utils/dataSource";
import {
  columnDefs,
  CustomLoadingOverlayComponent,
  defaultColumns,
  fieldDefinitions,
  getFilterParamString,
  getQueryParamsString,
  IdRenderer,
  TitleRenderer,
  PAGE_LIMIT,
  renderActiveCount,
  renderTargetCount,
  SUCCESS_STATUS_CODE,
  getSelectedSearchIds,
  addSearchesToWorkbench,
  // processAddSearchesResponse,
} from "./Utils";
import Search from "../Commons/Search";
import AddWorkbench from "../../components/AddWorkbench";
import {
  currencyValueSetter,
  DateRenderer,
  DateTimeRenderer,
  getCurrencyPrefix,
  loadColumnStateFromLocalStorage,
  saveColumnStateToLocalStorage,
} from "../Commons/Utils";
import AddContactsToWorkbench from "../Commons/AddContactToWorkbench";
import "../Commons/index.scss";
import "./index.scss";
import CustomFilter from "../Commons/CustomFilter";
import { CompanyNameRenderer } from "../Companies/utils";
import ColumnFilter from "../Commons/ColumnFilter";
import ActionsPopover from "../Contacts/ActionsPopover";
import DeletePopup from "../Commons/DeletePopup";
import GenericCellEditor from "../Commons/CellEditors/GenericCellEditor";
import PopupEditor from "../Commons/CellEditors/PopupEditor";
import {
  API,
  BLOB,
  EXPORT_LIMIT,
  POST,
  ERROR,
  WARNING,
  DELETE_CONFIRMATION_POPUP_MESSAGE,
} from "../../services/constantService";
import { fetchUserList } from "./../../actions";
import { searchDataApi } from "../../services/ApiService";
import {
  exportMessage,
  VALIDATION_MESSAGE,
  notFoundMessage,
} from "../../services/MessageService";
import { SCROLL_TIMEOUT } from "../../utils/common";
import { REFRESH_SEARCH } from "../../types";

class MySearchList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewColumns: false,
      anchorEl: null,
      showQuery: false,
      minimizeQuery: true,
      rowCount: 0,
      queryData: {},
      searchValue: "",
      quickQuery: "",
      isAllRowsSelected: false,
      uiSelector: false,
      masterQuery: "",
      isAdvanceQuery: false,
      isClassicQuery: false,
      queryRun: false,
      flag: true,
      isRowSelected: false,
      isAddToWorkbenchOpen: false,
      searchIds: [],
      isCopyToWorkbenchOpen: false,
      isLoading: false,
      exportSingleValue: false,
      isRemove: false,
      isPostCall: "",
      advanceSelector: false,
      showFilterCount: 0,
      isQuerySelector: false
    };
  }

  resetFilter = async () => {
    this.gridApi.setFilterModel(null);
    const customFilterField = this.columnApi.columnController.columnDefs;
    customFilterField.map(item => {
      if (item.filter === 'CustomFilter') {
        this.gridApi.destroyFilter(item.field);
      }
    })
  }

  resetSort = async () => {
    this.gridApi.setSortModel(null);
  }

  componentWillUnmount() {
    const columnApi = this.columnApi
    saveColumnStateToLocalStorage("mySearchesColumns", { columnApi });
  }

  closeHandler = async () => {
    await this.showQueryState(false);
    this.props.handleShowBD(true);
    this.setState({ isAdvanceQuery: false, isClassicQuery: false, advanceSelector: false });
    this.setState({ queryData: {} });
    this.gridApi.onFilterChanged();
    if (this.state.uiSelector === true) {
      this.setState({ isQuerySelector: true })
    } else {
      this.setState({ isQuerySelector: false })
    }
    // window.location.reload(false)
  };

  isSelectedFromList = false;

  componentDidMount() {
    this.setState({ uiSelector: false });
    if (this.props && this.props.location && this.props.location.state) {
      this.setState({ masterQuery: this.props.location.state });
    }
    if (!this.props.users) {
      this.props.fetchUserList("users");
    }
  }
  componentDidUpdate() {
    if (this.props.refreshSearch) {
      this.gridApi.onFilterChanged();
      this.props.dispatch({ type: REFRESH_SEARCH, payload: false })
    }
  }

  getURLs = {
    listURl: `${API.search}/mysearches`,
    classicURl: API.search,
    advanceURL: API.master_query,
    quickQuery: API.search,
  };

  dataSource = {
    getRows: async (params) => {
      try {
        let value = localStorage.getItem('mySearchesColumns');
        if (value === 'true') {
          this.setState({ showQuery: true, minimizeQuery: false })
          // this.resetFilter();
          // this.resetSort();
          params.successCallback([], 0);
          this.setState({ rowCount: 0 });
          this.props.handleShowBD(false);
          params.filterModel && this.setState({ filterModel: params.filterModel });
          this.setState({ showFilterCount: Object.keys(this.state.filterModel).length })
          return;
        }

        params.filterModel && this.setState({ filterModel: params.filterModel });
        this.setState({ showFilterCount: Object.keys(this.state.filterModel).length })
        //  this.gridApi.deselectAll();

        if (params.filterModel && Object.keys(params.filterModel).length > 0)
          this.gridApi.deselectAll();
        const thisValue = { ...this };

        this.gridApi.showLoadingOverlay();
        if (this.state.masterQuery) {
          this.handleSearchChange("", this.state.masterQuery);
        } else {
          const obj = {
            params: params,
            context: thisValue,
            pageLimit: PAGE_LIMIT,
            url: this.getURLs,
            mySearches: true,
            SearchesSubScreen: true,
          };
          const getData = await dataSourceUtils(
            obj,
            getFilterParamString,
            getQueryParamsString
          );
          const { status, data } = getData;
          if (status === SUCCESS_STATUS_CODE) {
            if (data?.paging) {
              if (data?.paging?.totalCount === 0) {
                const message = notFoundMessage("records");
                this.props.enqueueSnackbar(message, { variant: WARNING });
              }
              params.successCallback(data.data, data.paging.totalCount);
              this.setState({ rowCount: data.paging.totalCount });
            } else if (Object.keys(data).length) {
              params.successCallback([data], 1);
            }
            this.state.isAllRowsSelected &&
              this.setSelectAllRows(this.state.isAllRowsSelected);
          } else {
            params.failCallback();
          }

          this.gridApi.hideOverlay();
        }
      } catch (e) {
        console.log("Error found in downloadAttachment::", e);
      }
      // localStorage.removeItem('mySearchesColumns')
    },
    rowCount: null,
  };

  NameRenderer = (params) => {
    return <CompanyNameRenderer company={params?.data?.company} />;
  };

  FeeAmountRenderer = (params) => {
    const currencyCode =
      params.data && params.data.currency ? params.data.currency : "";
    const feeAmount =
      params.data && params.data.fee_amount ? params.data.fee_amount : "";
    return (
      <NumberFormat
        style={{ border: "none", fontSize: "15px" }}
        color="primary"
        value={feeAmount}
        thousandSeparator={true}
        decimalSeparator="."
        displayType="text"
        prefix={getCurrencyPrefix(currencyCode !== "RUR" ? currencyCode : "")}
        suffix={getCurrencyPrefix(currencyCode === "RUR" ? "RUR" : "")}
        renderText={(data) => {
          const value = currencyValueSetter(data, currencyCode);
          return value ? value : "--";
        }}
      />
    );
  };

  EstimatedAmountRender = (params) => {
    const currencyCode =
      params.data && params.data.currency ? params.data.currency : "";
    const estimatedRevenue =
      params.data && params.data.estimated_revenue ? params.data.estimated_revenue : "";
    return (
      <NumberFormat
        style={{ border: "none", fontSize: "15px" }}
        color="primary"
        value={estimatedRevenue}
        thousandSeparator={true}
        decimalSeparator="."
        displayType="text"
        prefix={getCurrencyPrefix(currencyCode !== "RUR" ? currencyCode : "")}
        suffix={getCurrencyPrefix(currencyCode === "RUR" ? "RUR" : "")}
        renderText={(data) => {
          const value = currencyValueSetter(data, currencyCode);
          return value ? value : "--";
        }}
      />
    );
  };

  EstimatedBaseFeeRender = (params) => {
    const currencyCode =
      params.data && params.data.currency ? params.data.currency : "";
    const estimatedBaseFee =
      params.data && params.data.estimated_percentage_base ? params.data.estimated_percentage_base : "";
    return (
      <NumberFormat
        style={{ border: "none", fontSize: "15px" }}
        color="primary"
        value={estimatedBaseFee}
        thousandSeparator={true}
        decimalSeparator="."
        displayType="text"
        prefix={getCurrencyPrefix(currencyCode !== "RUR" ? currencyCode : "")}
        suffix={getCurrencyPrefix(currencyCode === "RUR" ? "RUR" : "")}
        renderText={(data) => {
          const value = currencyValueSetter(data, currencyCode);
          return value ? value : "--";
        }}
      />
    );
  };

  runQuery = () => {
    this.setState({ queryRun: true });
  };


  onGridReady = (params) => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    const columnApi = params.columnApi
    const gridApi = params.api
    loadColumnStateFromLocalStorage("mySearchesColumns", { columnApi, gridApi });
    this.setState({ isGridReady: true });
  };

  handleCopySearchesToWorkbenchClose = () => {
    this.setState({ isCopyToWorkbenchOpen: false });
  };

  getSelectedSearchIds = () => {
    const SearchIds = [];
    this.gridApi.forEachNode((node) => {
      if (node.selected) {
        SearchIds.push(node.data.id);
      }
    });
    return SearchIds;
  };

  handleCopySearchesToWorkbench = () => {
    const searchIds = this.getSelectedSearchIds();
    this.setState({ isCopyToWorkbenchOpen: true, searchIds });
  };

  handleCreateWorkbench = () => {
    this.setState({ isCreateWorkbenchOpen: true });
  };

  handleCreateWorkbenchClose = async (workbenchId) => {
    try {
      this.setState({ isCreateWorkbenchOpen: false });
      if (workbenchId) {
        this.setState({ isLoading: true });
        if (this.state.showQuery && this.state.isAllRowsSelected) {
          const searchIds = getSelectedSearchIds(this.gridApi);
          await addSearchesToWorkbench(
            workbenchId,
            searchIds,
            this.props.enqueueSnackbar
          );
        } else {
          const searchIds = getSelectedSearchIds(this.gridApi);
          await addSearchesToWorkbench(
            workbenchId,
            searchIds,
            this.props.enqueueSnackbar
          );
        }
        this.setState({ isLoading: false });
      }
    } catch (e) {
      console.log("Error found in handleCreateWorkbenchClose::", e);
    }
  };

  handleQuery = (queryData) => {
    if (this.state.isAllRowsSelected === true) {
      this.setSelectAllRows(false);
      this.setState({ isAllRowsSelected: true });
    } else {
      this.gridApi.forEachNode((node) => {
        node.setSelected(false);
      });
    }
    this.setState({ queryData: queryData });
    localStorage.removeItem('mySearchesColumns')
    this.gridApi.onFilterChanged();
  };

  classicQuery = (queryData) => {
    this.setState({
      isAdvanceQuery: false,
      isClassicQuery: true,
      quickQuery: "",
    });
    this.handleQuery(queryData);
  };

  AdvancedQuery = (queryData) => {
    this.setState({
      isAdvanceQuery: true,
      isClassicQuery: false,
      quickQuery: "",
      advanceSelector: true
    });
    this.handleQuery(queryData);
  };

  showQueryState = async (value) => {
    await this.resetSort();
    await this.resetFilter();
    this.gridApi.onFilterChanged();
    this.isSelectedFromList = false;
    this.setState({ showQuery: value, minimizeQuery: !value });
    localStorage.setItem('mySearchesColumns', value)
    this.setState({ string: "", quickQuery: "", searchValue: "" });
    this.props.showQuery(value);
    this.props.handleShowBD(false);
    // setTimeout(() => {
    //   window.location.reload(false)
    // }, 3000)
  };

  handleSearchChange = (event, value) => {
    this.setState({ isAdvanceQuery: false, isClassicQuery: false });
    this.isSelectedFromList = false;
    if (this.state.masterQuery) {
      this.setState({ masterQuery: "" });
    }
    if (typeof value === "string") {
      this.setState({
        quickQuery: value,
        searchValue: value,
        exportSingleValue: false,
      });
    } else if (value && value.id) {
      this.isSelectedFromList = true;
      this.setState({
        quickQuery: value.id,
        searchValue: value.job_title,
        exportSingleValue: true,
      });
    }

    this.setState(
      { quickQuery: value?.id ? value?.id : value ? value : "" },
      () => {
        this.gridApi.onFilterChanged();
      }
    );
    this.props.handleShowBD(false);
    this.resetFilter();
    this.resetSort();
  };

  getParams = (selectedRows) => {
    // const selectedRows = this.gridApi.getSelectedRows();
    // if (this.state.isAllRowsSelected || selectedRows.length === 0) {
    //   return selectedRows.map(row => `id=${row.id}`).join('&')
    //   // return getQueryParamsString(this.state.queryData).substring(1)
    // } else {
    //   return selectedRows.map(row => `id=${row.id}`).join('&');
    // }
    return selectedRows.map((row) => `id=${row.id}`).join("&");
  };

  exportCallback = async () => {
    const selectedRows = this.gridApi.getSelectedRows();
    if (
      (this.state.isAllRowsSelected && this.state.rowCount > EXPORT_LIMIT) ||
      (!this.state.isAllRowsSelected && selectedRows.length > EXPORT_LIMIT)
    ) {
      const message = exportMessage(`${EXPORT_LIMIT}`, "Search");
      this.props.enqueueSnackbar(message, { variant: ERROR });
    } else {
      if (selectedRows.length > 0) {
        let sub_route = `export-as-excel?`;
        if (this.state.quickQuery) {
          if (this.state.exportSingleValue) {
            sub_route = `${sub_route}&id=${this.state.quickQuery}&quickSearch=true`;
          } else {
            sub_route = `${sub_route}&searchValue=${this.state.quickQuery}&quickSearch=true`;
          }
        } else if (this.state.isClassicQuery) {
          const paramsString = getQueryParamsString(this.state.queryData);
          sub_route = `${sub_route}${paramsString}&classicSelector=${this.state.isClassicQuery}`;
        }
        if (
          this.state.filterModel &&
          Object.keys(this.state.filterModel).length
        ) {
          sub_route = sub_route.concat(
            getFilterParamString(
              this.state.filterModel,
              this.state.showQuery || this.state.quickQuery
            )
          );
          sub_route = `${sub_route}&filter=true`;
        }
        let id = [];
        if (!this.state.isAllRowsSelected) {
          selectedRows.map((data) => id.push(data.id));
        }
        const columnHeaders = this.columnApi
          .getAllDisplayedColumns()
          .map((column) => column.getColDef().headerName);
        this.gridApi.showLoadingOverlay();
        const payload = {
          headers: {
            columnHeaders: columnHeaders,
            selectedRows: { id: id },
            type: "My Searches",
            allRowSelected: this.state.isAllRowsSelected,
            limit: EXPORT_LIMIT,
          },
        };
        if (this.state.advanceSelector) {
          payload.headers.queryData = this.state.queryData;
        }
        let { status, data, headers } = await searchDataApi(
          POST,
          "",
          payload,
          sub_route,
          BLOB
        );
        if (status === 200) {
          let fileName = "Searches.xlsx";
          let fileNameHeader = headers["content-disposition"].split('"');
          if (fileNameHeader && fileNameHeader.length > 2) {
            fileName = fileNameHeader[1];
          }
          saveAs(new File([data], fileName));
        } else {
          this.props.enqueueSnackbar(VALIDATION_MESSAGE.export_fail, {
            variant: ERROR,
          });
        }
        this.gridApi.hideOverlay();
      }
    }
  };

  setSelectAllRows = (isAllRowsSelected) => {
    this.setState({ isAllRowsSelected });
    this.gridApi.forEachNode((node) => {
      node.setSelected(isAllRowsSelected);
    });
  };

  handleChange = () => {
    this.setSelectAllRows(!this.state.isAllRowsSelected);
  };

  HeaderCheckbox = () => {
    return (
      <Checkbox
        style={{ padding: 0, width: 16, height: 16, color: "white" }}
        size="small"
        color="primary"
        onChange={this.handleChange}
      />
    );
  };

  ActionsRenderer = (params) => {
    if (params.data) {
      const list = [
        {
          label: "Delete Record",
          onClick: () => {
            this.setState({ searchId: params.data?.id });
          },
        },
      ];
      return <ActionsPopover list={list} />;
    }
    return null;
  };

  handleClose = (deleted) => {
    if (deleted) {
      this.gridApi.onFilterChanged();
    }
    this.setState({ searchId: null });
  };

  saveColumnState = async () => {
    try {
      if (this.state.isPostCall >= 2) {
        this.gridApi && this.gridApi.showLoadingOverlay()
        const columnApi = this.columnApi;
        const gridApi = this.gridApi
        await saveColumnStateToLocalStorage("mySearchesColumns", { columnApi, gridApi }, undefined, undefined, true);
        this.gridApi && this.gridApi.hideOverlay()
      } else {
        this.setState({ isPostCall: this.state.isPostCall + 1 });
      }
    } catch (e) {
      console.log("Error found in saveColumnState::", e);
    }
  };

  saveColumnStateForFilter = async () => {
    try {
      if (this.state.isPostCall >= 2) {
        this.gridApi && this.gridApi.showLoadingOverlay()
        const columnApi = this.columnApi;
        const gridApi = this.gridApi
        await saveColumnStateToLocalStorage("mySearchesColumns", { columnApi, gridApi }, true, false);
        this.gridApi && this.gridApi.hideOverlay()
      } else {
        this.setState({ isPostCall: this.state.isPostCall + 1 });
      }
    } catch (e) {
      console.log("Error found in saveColumnState::", e);
    }
  };

  saveColumnStateForSort = async () => {
    try {
      if (this.state.isPostCall >= 2) {
        this.gridApi && this.gridApi.showLoadingOverlay()
        const columnApi = this.columnApi;
        const gridApi = this.gridApi
        await saveColumnStateToLocalStorage("mySearchesColumns", { columnApi, gridApi }, false, true);
        this.gridApi && this.gridApi.hideOverlay()
      } else {
        this.setState({ isPostCall: this.state.isPostCall + 1 });
      }
    } catch (e) {
      console.log("Error found in saveColumnState::", e);
    }
  };

  checkFirstDataRendered = () => {
    this.setState({ isPostCall: 0 });
  };

  loaderChange = async (value) => {
    this.setState({ isLoading: value });
  };

  pullData = () => {
    if (this.state.uiSelector === false && this.state.isQuerySelector === false) {
      this.setState({
        uiSelector: true,
        advanceSelector: true,
        isQuerySelector: true
      });
    } else if (this.state.uiSelector === true && this.state.isQuerySelector === true) {
      this.setState({
        uiSelector: false,
        advanceSelector: false,
        isQuerySelector: false
      });
    }
  };
  update = (value) => {
    this.setState({ isRemove: value });
  };
  render() {
    const {
      isMySearchesExpanded,
      toggleMySearches,
      userData,
      enqueueSnackbar,
      id,
      users,
    } = this.props;
    const {
      isRowSelected,
      isCreateWorkbenchOpen,
      searchIds,
      isCopyToWorkbenchOpen,
      isAllRowsSelected,
      isLoading,
    } = this.state;
    return (
      <div
        className={`d-flex flex-column ${isMySearchesExpanded ? "h-100 expanded" : ""
          }`}
        style={{ borderBottom: "1px solid #dddde9" }}
      >
        <Loader show={isLoading} />
        <Helmet>
          <title>My Searches - KG Galaxy</title>
        </Helmet>
        {this.state.searchId ? (
          <DeletePopup
            module="searches"
            id={this.state.searchId}
            onClose={this.handleClose}
            updateParent={this.update}
            popupText={DELETE_CONFIRMATION_POPUP_MESSAGE.DELETE_SEARCH}
          />
        ) : null}
        {isCopyToWorkbenchOpen && (
          <AddContactsToWorkbench
            open={isCopyToWorkbenchOpen}
            searchIds={searchIds}
            onClose={this.handleCopySearchesToWorkbenchClose}
            sourceWorkbenchId={isAllRowsSelected ? id : null}
            type="Searches"
          />
        )}
        {isCreateWorkbenchOpen && (
          <AddWorkbench
            onClose={this.handleCreateWorkbenchClose}
            buttonText={VALIDATION_MESSAGE.create_workbech_add_search}
            DefaultValue={"Searches"}
            Disabled={true}
          />
        )}
        {this.state.showQuery || this.state.showSearch ? (
          <div
            className={"d-flex query-toolbar d-flex"}
            style={{ padding: "0px" }}
          >
            <Helmet>
              <title>Query - My Searches</title>
            </Helmet>
            <ExpansionPanel
              className="w-100 m-0"
              expanded={this.state.showQuery && !this.state.minimizeQuery}
            >
              <ExpansionPanelSummary
                expandIcon={
                  <ExpandMoreIcon
                    onClick={() =>
                      this.setState({
                        minimizeQuery: true,
                      })
                    }
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
                onClick={() =>
                  this.setState({
                    minimizeQuery: !this.state.minimizeQuery,
                  })
                }
                style={{ padding: "0px 20px", lineHeight: 1 }}
              >
                Searches Query
              </ExpansionPanelSummary>
              <ExpansionPanelDetails
                style={{ padding: "0 0 0 20px" }}
                className="w-100"
              >
                {this.state.uiSelector ? (
                  <AdvancedQuery
                    fieldDefinitions={fieldDefinitions}
                    type={"search"}
                    pullData={this.pullData}
                    handleQuery={this.AdvancedQuery}
                  />
                ) : (
                  <Query
                    gridApi={this.gridApi}
                    isAllRowsSelected={this.state.isAllRowsSelected}
                    pullData={this.pullData}
                    columnApi={this.columnApi}
                    handleQuery={this.classicQuery}
                    queryData={this.state.queryData}
                    flag={true}
                    option={users}
                  />
                )}
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <div className="expansion-panel-close-container">
              <span
                className="cursor-pointer expansion-panel-close d-flex"
                onClick={() => {
                  this.closeHandler();
                }}
              >
                <CloseIcon />
              </span>
            </div>
          </div>
        ) : (
          <div className={"toolbar"} style={{ padding: "20px 20px 0" }}>
            <Search
              value={this.state.searchValue}
              onChange={this.handleSearchChange}
              type="quicksearches"
            />
            <Button
              className={"start-query"}
              variant={"outlined"}
              color={"primary"}
              onClick={() => this.showQueryState(true)}
            >
              Start Query
            </Button>
          </div>
        )}
        <ExpansionPanel
          className="my-searches-list w-100 m-0"
          expanded={isMySearchesExpanded}
        >
          <ExpansionPanelSummary
            expandIcon={
              <ExpandMoreIcon
                style={{ padding: 0 }}
                onClick={toggleMySearches}
              />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
            // onClick={toggleMySearches}
            style={{ lineHeight: 1 }}
            className="p-0"
          >
            {!isMySearchesExpanded ? (
              <div className="bus-dev-text">My Searches</div>
            ) : (
              <>
                <div className="d-flex w-100 justify-content-start">
                  <Button
                    variant="outlined"
                    color="primary"
                    className="mr-2"
                    disabled={!isRowSelected}
                    onClick={this.handleCopySearchesToWorkbench}
                  >
                    Add Searches To Workbench
                  </Button>
                  <Button
                    variant="outlined"
                    color="primary"
                    className="mr-2"
                    disabled={!isRowSelected}
                    onClick={this.handleCreateWorkbench}
                  >
                    Create New Workbench
                  </Button>
                </div>
                <div className="d-flex w-100 align-items-center justify-content-end">
                  <div
                    className="action-container"
                    onClick={() => this.resetFilter()}
                  >
                    <span className="action-text" >
                      Reset Filter
                    </span>
                  </div>
                  <div
                    className="action-container"
                    onClick={() => this.resetSort()}
                  >
                    <span className="action-text" >
                      Reset Sort
                    </span>
                  </div>
                  <Button
                    disabled={!isRowSelected}
                    className="mr-3"
                    variant="outlined"
                    color="primary"
                    onClick={this.exportCallback}
                  >
                    Export List
                  </Button>
                  <Typography>Total count: {this.state.rowCount}</Typography>
                </div>
              </>
            )}
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className="p-0">
            <div className="list-view">
              {this.state.isGridReady && (
                <ColumnFilter
                  columnApi={this.columnApi}
                  defaultColumns={defaultColumns}
                  filterModel={this.state.filterModel}
                  showFilterCount={this.state.showFilterCount}
                />
              )}
              <div id="myGrid" className="ag-theme-alpine">
                <AgGridReact
                  onGridReady={this.onGridReady}
                  enableBrowserTooltips={true}
                  defaultColDef={{
                    minWidth: 100,
                    resizable: true,
                    sortable: true,
                    sortingOrder: ['asc', 'desc', null]
                  }}
                  cacheBlockSize={PAGE_LIMIT}
                  loadingOverlayComponent={"CustomLoadingOverlayComponent"}
                  frameworkComponents={{
                    CustomLoadingOverlayComponent,
                    IdRenderer,
                    TitleRenderer,
                    DateTimeRenderer,
                    NameRenderer: this.NameRenderer,
                    DateRenderer,
                    renderActiveCount,
                    renderTargetCount,
                    CustomFilter,
                    HeaderCheckbox: this.HeaderCheckbox,
                    FeeAmountRenderer: this.FeeAmountRenderer,
                    ActionsRenderer: this.ActionsRenderer,
                    EstimatedBaseFeeRender: this.EstimatedBaseFeeRender,
                    EstimatedAmountRender: this.EstimatedAmountRender,
                    GenericCellEditor,
                    PopupEditor,
                  }}
                  onRowSelected={() => {
                    this.setState({
                      isRowSelected: this.gridApi.getSelectedRows().length > 0,
                    });
                  }}
                  getRowNodeId={(data) => data.id}
                  blockLoadDebounceMillis={SCROLL_TIMEOUT}
                  scrollbarWidth={12}
                  suppressHorizontalScroll={false}
                  rowModelType={"infinite"}
                  datasource={this.dataSource}
                  columnDefs={columnDefs(
                    userData?.isAdmin,
                    false,
                    true,
                    enqueueSnackbar,
                    this.loaderChange,
                    users,
                    false,
                    userData.roles[0],
                    this.props.dispatch
                  )}
                  paginationPageSize={PAGE_LIMIT}
                  rowSelection={"multiple"}
                  suppressRowClickSelection={true}
                  suppressMenuHide={true}
                  suppressDragLeaveHidesColumns={true}
                  onDisplayedColumnsChanged={this.saveColumnState}
                  onDragStopped={this.saveColumnState}
                  onFirstDataRendered={this.checkFirstDataRendered}
                  onSortChanged={this.saveColumnStateForSort}
                  onFilterChanged={this.saveColumnStateForFilter}
                ></AgGridReact>
              </div>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    );
  }
}

MySearchList.propTypes = {
  location: PropTypes.object,
  showQuery: PropTypes.func,
  toggleMySearches: PropTypes.func,
  isMySearchesExpanded: PropTypes.bool,
  handleShowBD: PropTypes.func,
  enqueueSnackbar: PropTypes.func,
  userData: PropTypes.object,
  id: PropTypes.string,
  users: PropTypes.array,
  fetchUserList: PropTypes.func,
  resetSort: PropTypes.func,
  resetFilter: PropTypes.func,
  refreshSearch: PropTypes.bool,
  dispatch: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    userData: state.commonReducer.userData,
    users: state.rootReducer.users,
    refreshSearch: state.rootReducer.refreshSearch,
  };
};
const mapDispatchToProps = (dispatch) => ({
  fetchUserList,
  dispatch
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withRouter(MySearchList)));
