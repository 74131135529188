
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { Component } from "react";

//-----------------------------------------------------------// External Imports // ------------------------------

import { Button, Checkbox, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import NumberFormat from "react-number-format";
import { saveAs } from "file-saver";
import { withSnackbar } from "notistack";
import { AgGridReact } from "ag-grid-react";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";

//-----------------------------------------------------------// Internal Imports // ------------------------------

import "../Commons/index.scss";
import "./index.scss";
import "ag-grid-community/dist/styles/ag-grid.css";
import {
  API,
  BLOB,
  DELETE_CONFIRMATION_POPUP_MESSAGE,
  ERROR,
  EXPORT_LIMIT,
  POST,
  WARNING,
} from "../../services/constantService";
import {
  CustomLoadingOverlayComponent,
  IdRenderer,
  PAGE_LIMIT,
  SUCCESS_STATUS_CODE,
  addSearchesToWorkbench,
  columnDefs,
  defaultColumns,
  getFilterParamString,
  getQueryParamsString,
  getSelectedSearchIds,
  processAddSearchesResponse,
  renderActiveCount,
  renderTargetCount,
} from "./Utils";
import {
  DateRenderer,
  DateTimeRenderer,
  EmailRenderer,
  LinkedInRenderer,
  LinksRenderer,
  currencyValueSetter,
  getCurrencyPrefix,
  loadColumnStateFromLocalStorage,
  saveColumnStateToLocalStorage,
} from "../Commons/Utils";
import {
  VALIDATION_MESSAGE,
  exportMessage,
  notFoundMessage,
} from "../../services/MessageService";
import { searchDataApi, workbenchDataApi } from "../../services/ApiService";
import ActionsPopover from "../Contacts/ActionsPopover";
import AddContactsToWorkbench from "../Commons/AddContactToWorkbench";
import AddWorkbench from "../../components/AddWorkbench";
import ColumnFilter from "../Commons/ColumnFilter";
import { CompanyNameRenderer } from "../Companies/utils";
import CustomFilter from "../Commons/CustomFilter";
import DeletePopup from "../Commons/DeletePopup";
import GenericCellEditor from "../Commons/CellEditors/GenericCellEditor";
import LinkedInDialog from "../Commons/LinkedInDialog";
import Loader from "../../../src/components/common/Loader";
import PopupEditor from "../Commons/CellEditors/PopupEditor";
import Query from "./Components/Query";
import Search from "../Commons/Search";
import { dataSourceUtils } from "../../utils/dataSource";
import { fetchUserList } from "../../actions";
import { SCROLL_TIMEOUT } from "../../utils/common";

class Searches extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewColumns: false,
      anchorEl: null,
      isClassicQuery: false,
      minimizeQuery: true,
      rowCount: 0,
      showSearch: false,
      searchValue: "",
      isAllRowsSelected: false,
      isRowSelected: false,
      isAddToWorkbenchOpen: false,
      searchIds: [],
      isCopyToWorkbenchOpen: false,
      selectedRowsCount: 0,
      linkedPopup: false,
      linkedInValue: {},
      isLoading: false,
      showFilterCount: 0
    };
  }

  isSelectedFromList = false;

  componentWillUnmount() {
    const columnApi = this.columnApi
    saveColumnStateToLocalStorage(this.props.columnStateKey, { columnApi });
  }

  resetFilter = async () => {
    this.gridApi.setFilterModel(null);
    const customFilterField = this.columnApi.columnController.columnDefs;
    customFilterField.map(item => {
      if (item.filter === 'CustomFilter') {
        this.gridApi.destroyFilter(item.field);
      }
    })
  }

  resetSort = async () => {
    this.gridApi.setSortModel(null);
  }
  componentDidMount() {
    if (!this.props.users) {
      this.props.fetchUserList("users");
    }
  }
  isSelectedFromList = false;

  getURLs = {
    listURl: `${API.search}/${this.props.searchType || "kgsearches"}`,
    quickQuery: `${API.search}`,
    classicURl: `${API.search}`,
    advanceURL: API.master_query,
  };

  dataSource = {
    getRows: async (params) => {
      try {
        let value = localStorage.getItem(this.props.columnStateKey)
        if (value === 'true') {
          this.setState({
            isClassicQuery: true,
            minimizeQuery: false,
            quickQuery: "",
            searchValue: "",
          });
          // this.resetFilter();
          // this.resetSort();
          return;
        }
        params.filterModel && this.setState({ filterModel: params.filterModel });
        this.setState({ showFilterCount: Object.keys(this.state.filterModel).length })
        //  this.gridApi.deselectAll();
        if (params.filterModel && Object.keys(params.filterModel).length > 0)
          this.gridApi.deselectAll();
        const thisValue = { ...this };
        this.gridApi.showLoadingOverlay();
        const obj = {
          params: params,
          context: thisValue,
          pageLimit: PAGE_LIMIT,
          url: this.getURLs,
          subScreen: true,
          SearchesSubScreen: true,
          queryString: this.props.searchStatus
            ? `&searchStatus=${this.props.searchStatus}`
            : "",
        };
        const { status, data } = await dataSourceUtils(
          obj,
          getFilterParamString,
          getQueryParamsString
        );
        if (status === SUCCESS_STATUS_CODE) {
          if (data?.paging?.totalCount === 0) {
            const message = notFoundMessage("records");
            this.props.enqueueSnackbar(message, { variant: WARNING });
          }
          params.successCallback(data.data, data.paging.totalCount);
          this.setState({ rowCount: data.paging.totalCount });
          this.state.isAllRowsSelected &&
            this.setSelectAllRows(this.state.isAllRowsSelected);
        } else {
          params.failCallback();
        }
        this.gridApi.hideOverlay();
      } catch (e) {
        console.log("Error found in getRows::", e);
      }
      // localStorage.removeItem(this.props.columnStateKey)
    },
    rowCount: null,
  };

  NameRenderer = (params) => {
    return <CompanyNameRenderer company={params?.data?.company} />;
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    const columnApi = params.columnApi
    const gridApi = params.api
    params.api.sizeColumnsToFit();
    loadColumnStateFromLocalStorage(
      this.props.columnStateKey,
      { columnApi, gridApi }
    );
    this.setState({ isGridReady: true });
  };

  handleCopySearchesToWorkbenchClose = () => {
    this.setState({ isCopyToWorkbenchOpen: false });
  };

  linkedInPopupHandler = (props) => {
    this.setState({ linkedPopup: true, linkedInValue: props.value });
  };

  LinkedInRenderer = (params) => {
    return (
      <LinkedInRenderer
        params={params}
        linkedInPopup={this.linkedInPopupHandler}
      />
    );
  };

  LinkedInCloseHandler = () => {
    this.setState({ linkedPopup: false });
  };

  getSelectedSearchIds = () => {
    const SearchIds = [];
    this.gridApi.forEachNode((node) => {
      if (node.selected) {
        SearchIds.push(node.data.id);
      }
    });
    return SearchIds;
  };

  handleCopySearchesToWorkbench = () => {
    const searchIds = this.getSelectedSearchIds();
    this.setState({ isCopyToWorkbenchOpen: true, searchIds });
  };

  handleQuery = (queryData) => {
    if (this.state.isAllRowsSelected === true) {
      this.setSelectAllRows(false);
      this.setState({ isAllRowsSelected: true });
    } else {
      this.gridApi.forEachNode((node) => {
        node.setSelected(false);
      });
    }
    this.setState({ queryData: queryData });
    localStorage.removeItem(this.props.columnStateKey)
    this.gridApi.onFilterChanged();
  };


  FeeAmountRenderer = (params) => {
    const currencyCode =
      params.data && params.data.currency ? params.data.currency : "";
    const feeAmount =
      params.data && params.data.fee_amount ? params.data.fee_amount : "";
    return (
      <NumberFormat
        style={{ border: "none", fontSize: "15px" }}
        color="primary"
        value={feeAmount}
        thousandSeparator={true}
        decimalSeparator="."
        displayType="text"
        prefix={getCurrencyPrefix(currencyCode !== "RUR" ? currencyCode : "")}
        suffix={getCurrencyPrefix(currencyCode === "RUR" ? "RUR" : "")}
        renderText={(data) => {
          const value = currencyValueSetter(data, currencyCode);
          return value ? value : "--";
        }}
      />
    );
  };

  handleSearchChange = (event, value) => {
    this.isSelectedFromList = false;
    if (typeof value === "string") {
      this.setState({ quickQuery: value, searchValue: value });
    } else if (value && value.id) {
      this.isSelectedFromList = true;
      this.setState({ quickQuery: value.id, searchValue: value.job_title });
    }
    this.setState(
      { quickQuery: value?.id ? value?.id : value ? value : "" },
      () => {
        this.gridApi.onFilterChanged();
      }
    );
    this.resetFilter();
    this.resetSort();
  };

  setSelectAllRows = (isAllRowsSelected) => {
    this.setState({ isAllRowsSelected });
    this.gridApi.forEachNode((node) => {
      node.setSelected(isAllRowsSelected);
    });
  };

  handleChange = () => {
    this.setSelectAllRows(!this.state.isAllRowsSelected);
  };

  HeaderCheckbox = () => {
    return (
      <Checkbox
        style={{ padding: 0, width: 16, height: 16 , color: "white"}}
        size="small"
        color="primary"
        onChange={this.handleChange}
      />
    );
  };

  handleAddSearchesToWorkbench = () => {
    const searchIds = getSelectedSearchIds(this.gridApi);
    this.setState({ isAddToWorkbenchOpen: true, searchIds });
  };

  handleCreateWorkbench = () => {
    this.setState({ isCreateWorkbenchOpen: true });
  };

  handleCreateWorkbenchClose = async (workbenchId) => {
    try {
      this.setState({ isCreateWorkbenchOpen: false });
      if (workbenchId) {
        this.setState({ isLoading: true });
        if (this.state.isClassicQuery && this.state.isAllRowsSelected) {
          const payload = { query: this.getQueryData() };
          const sub_route = "searches/copy";
          const { status, data } = await workbenchDataApi(
            POST,
            workbenchId,
            payload,
            sub_route
          );
          processAddSearchesResponse(status, data, this.props.enqueueSnackbar);
        } else {
          const searchIds = getSelectedSearchIds(this.gridApi);
          await addSearchesToWorkbench(
            workbenchId,
            searchIds,
            this.props.enqueueSnackbar
          );
        }
        this.setState({ isLoading: false });
      }
    } catch (e) {
      console.log("Error found in handleCreateWorkbenchClose::", e);
    }
  };

  getParams = (selectedRows) => {
    /* const selectedRows = this.gridApi.getSelectedRows();
    if (this.state.isAllRowsSelected || selectedRows.length === 0) {
      return getQueryParamsString(this.state.queryData).substring(1)
    } else {
      return selectedRows.map(row => `id=${row.id}`).join('&');
    } */
    return selectedRows.map((row) => `id=${row.id}`).join("&");
  };

  exportCallback = async () => {
    const selectedRows = this.gridApi.getSelectedRows();
    if (this.state.isAllRowsSelected && this.state.rowCount > EXPORT_LIMIT) {
      const message = exportMessage(`${EXPORT_LIMIT}`, "Searches");
      this.props.enqueueSnackbar(message, { variant: ERROR });
    } else if (
      !this.state.isAllRowsSelected &&
      selectedRows.length > EXPORT_LIMIT
    ) {
      const message = exportMessage(`${EXPORT_LIMIT}`, "Searches");
      this.props.enqueueSnackbar(message, { variant: ERROR });
    } else {
      if (selectedRows.length > 0) {
        let sub_route = `export-as-excel?`;
        if (this.state.quickQuery) {
          sub_route = `${sub_route}&searchValue=${this.state.quickQuery}&string=true`;
        } else if (this.state.isClassicQuery) {
          const paramsString = getQueryParamsString(this.state.queryData);
          sub_route = `${sub_route}${paramsString}&classicSelector=${this.state.isClassicQuery}`;
        }
        if (
          this.state.filterModel &&
          Object.keys(this.state.filterModel).length
        ) {
          sub_route = sub_route.concat(
            getFilterParamString(
              this.state.filterModel,
              this.state.isClassicQuery
            )
          );
          sub_route = `${sub_route}&filter=true`;
        }
        let id = [];
        if (!this.state.isAllRowsSelected) {
          selectedRows.map((data) => id.push(data.id));
        }
        const columnHeaders = this.columnApi
          .getAllDisplayedColumns()
          .map((column) => column.getColDef().headerName);
        this.gridApi.showLoadingOverlay();
        const payload = {
          headers: {
            columnHeaders: columnHeaders,
            selectedRows: { id: id },
            type: this.props.title,
            allRowSelected: this.state.isAllRowsSelected,
            limit: EXPORT_LIMIT,
          },
        };
        let { status, data, headers } = await searchDataApi(
          POST,
          "",
          payload,
          sub_route,
          BLOB
        );
        if (status === 200) {
          let fileName = "Searches.xlsx";
          let fileNameHeader = headers["content-disposition"].split('"');
          if (fileNameHeader && fileNameHeader.length > 2) {
            fileName = fileNameHeader[1];
          }
          saveAs(new File([data], fileName));
        } else {
          this.props.enqueueSnackbar(VALIDATION_MESSAGE.export_fail, {
            variant: ERROR,
          });
        }
        this.gridApi.hideOverlay();
      }
    }
  };

  ActionsRenderer = (params) => {
    if (params.data) {
      const list = [
        {
          label: "Delete Record",
          onClick: () => {
            this.setState({ searchId: params.data?.id });
          },
        },
      ];
      return <ActionsPopover list={list} />;
    }
    return null;
  };

  handleClose = (deleted) => {
    if (deleted) {
      this.gridApi.onFilterChanged();
    }
    this.setState({ searchId: null });
  };

  saveColumnState = async () => {
    try {
      if (this.columnApi) {
        this.gridApi && this.gridApi.showLoadingOverlay()
        const columnApi = this.columnApi;
        const gridApi = this.gridApi
        await saveColumnStateToLocalStorage(
          this.props.columnStateKey,
          { columnApi, gridApi }
        );
        this.gridApi && this.gridApi.hideOverlay()
      }
    } catch (e) {
      console.log("Error found in saveColumnState::", e);
    }
  };

  saveColumnStateForFilter = async () => {
    try {
      if (this.columnApi) {
        this.gridApi && this.gridApi.showLoadingOverlay()
        const columnApi = this.columnApi;
        const gridApi = this.gridApi
        await saveColumnStateToLocalStorage(
          this.props.columnStateKey,
          { columnApi, gridApi },
          true,
          false
        );
        this.gridApi && this.gridApi.hideOverlay()
      }
    } catch (e) {
      console.log("Error found in saveColumnState::", e);
    }
  };

  saveColumnStateForSort = async () => {
    try {
      if (this.columnApi) {
        this.gridApi && this.gridApi.showLoadingOverlay()
        const columnApi = this.columnApi;
        const gridApi = this.gridApi
        await saveColumnStateToLocalStorage(
          this.props.columnStateKey,
          { columnApi, gridApi },
          false,
          true
        );
        this.gridApi && this.gridApi.hideOverlay()
      }
    } catch (e) {
      console.log("Error found in saveColumnState::", e);
    }
  };

  loaderChange = async (value) => {
    this.setState({ isLoading: value });
  };
  render() {
    const {
      placedSearches,
      activeSearches,
      enqueueSnackbar,
      userData,
      id,
      users,
    } = this.props;
    const {
      linkedInValue,
      linkedPopup,
      isRowSelected,
      isCreateWorkbenchOpen,
      searchIds,
      isCopyToWorkbenchOpen,
      isAllRowsSelected,
      isLoading,
    } = this.state;
    return (
      <div className="list-contacts d-flex flex-column">
        <Loader show={isLoading} />
        <Helmet>
          <title>{this.props.title || "Searches"} - KG Galaxy</title>
        </Helmet>
        <LinkedInDialog
          open={linkedPopup}
          linkedInValue={linkedInValue}
          handleClose={this.LinkedInCloseHandler}
        ></LinkedInDialog>
        {this.state.searchId ? (
          <DeletePopup
            module="searches"
            id={this.state.searchId}
            onClose={this.handleClose}
            popupText={DELETE_CONFIRMATION_POPUP_MESSAGE.DELETE_SEARCH}
          />
        ) : null}
        {isCopyToWorkbenchOpen && (
          <AddContactsToWorkbench
            open={isCopyToWorkbenchOpen}
            searchIds={searchIds}
            onClose={this.handleCopySearchesToWorkbenchClose}
            sourceWorkbenchId={isAllRowsSelected ? id : null}
            type="Searches"
          />
        )}
        {isCreateWorkbenchOpen && (
          <AddWorkbench
            onClose={this.handleCreateWorkbenchClose}
            buttonText={VALIDATION_MESSAGE.create_workbech_add_search}
            DefaultValue={"Searches"}
            Disabled={"true"}
          />
        )}
        {this.state.isClassicQuery || this.state.showSearch ? (
          <div
            className={"d-flex query-toolbar d-flex"}
            style={{ padding: "0px" }}
          >
            <Helmet>
              <title>Query - Searches</title>
            </Helmet>
            {this.state.isClassicQuery ? (
              <ExpansionPanel
                className="flex-grow-1 w-100 m-0"
                expanded={
                  this.state.isClassicQuery && !this.state.minimizeQuery
                }
              >
                <ExpansionPanelSummary
                  expandIcon={
                    <ExpandMoreIcon
                      onClick={() =>
                        this.setState({
                          minimizeQuery: true,
                        })
                      }
                    />
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  onClick={() =>
                    this.setState({
                      minimizeQuery: !this.state.minimizeQuery,
                    })
                  }
                  style={{ padding: "0px 14px", lineHeight: 1 }}
                >
                  Search Query
                </ExpansionPanelSummary>
                <ExpansionPanelDetails
                  style={{ padding: "0px 15px 3px", width: "100%" }}
                >
                  <Query handleQuery={this.handleQuery} option={users} />
                </ExpansionPanelDetails>
              </ExpansionPanel>
            ) : (
              <div
                className={"toolbar flex-grow-1"}
                style={{ padding: "20px" }}
              >
                <Search
                  value={this.state.searchValue}
                  onChange={this.handleSearchChange}
                />
              </div>
            )}
            <div className="expansion-panel-close-container">
              <span
                style={{
                  display: this.state.minimizeQuery ? "inline" : "none",
                }}
                className="cursor-pointer expansion-panel-close d-flex"
                onClick={async () => {
                  await this.resetSort();
                  await this.resetFilter();
                  this.setState({
                    isClassicQuery: false,
                    queryData: {},
                    showSearch: false,
                  });
                  this.gridApi.onFilterChanged();
                  localStorage.removeItem(this.props.columnStateKey)
                  setTimeout(() => {
                    window.location.reload(false)
                  }, 3000)
                }}
              >
                <CloseIcon />
              </span>
            </div>
          </div>
        ) : (
          <div className={"toolbar"}>
            <div style={{ marginRight: "20px" }}>
              <Search onChange={this.handleSearchChange} type="quicksearches" />
            </div>
            <Button
              className={"start-query m-0"}
              variant={"outlined"}
              color={"primary"}
              onClick={async () => {
                await this.resetSort();
                await this.resetFilter();
                this.isSelectedFromList = false;
                this.setState({
                  isClassicQuery: true,
                  minimizeQuery: false,
                  quickQuery: "",
                  searchValue: "",
                });
                localStorage.setItem(this.props.columnStateKey, true)
                setTimeout(() => {
                  window.location.reload(false)
                }, 3000)
              }}
            >
              Start Query
            </Button>
          </div>
        )}
        <div className="d-flex justify-content-start mt-5 ml-4 position-relative">
          <div className="d-flex w-100 justify-content-start position-absolute">
            <Button
              variant="outlined"
              color="primary"
              className="mr-2"
              disabled={!isRowSelected}
              onClick={this.handleCopySearchesToWorkbench}
            >
              Add Searches To Workbench
            </Button>
            <Button
              variant="outlined"
              color="primary"
              className="mr-2"
              disabled={!isRowSelected}
              onClick={this.handleCreateWorkbench}
            >
              Create New Workbench
            </Button>
          </div>
          <div className="d-flex w-100 align-items-center justify-content-end mr-4">
            <div
              className="action-container"
              onClick={() => this.resetFilter()}
              style={{ minWidth: 0 }}
            >
              <span className="action-text" >
                Reset Filter
              </span>
            </div>
            <div
              className="action-container"
              onClick={() => this.resetSort()}
              style={{ minWidth: 0 }}
            >
              <span className="action-text" >
                Reset Sort
              </span>
            </div>
            <Button
              className="mr-3"
              disabled={!this.state.selectedRowsCount}
              variant="outlined"
              color="primary"
              onClick={this.exportCallback}
            >
              Export List
            </Button>

            <Typography>Total count: {this.state.rowCount}</Typography>
          </div>
        </div>
        <div className="list-view flex-grow-1">
          {this.state.isGridReady && (
            <ColumnFilter
              columnApi={this.columnApi}
              defaultColumns={defaultColumns}
              filterModel={this.state.filterModel}
              showFilterCount={this.state.showFilterCount}
            />
          )}
          <div id="myGrid" className="ag-theme-alpine">
            <AgGridReact
              onGridReady={this.onGridReady}
              enableBrowserTooltips={true}
              defaultColDef={{
                minWidth: 100,
                resizable: true,
                sortable: true,
                sortingOrder: ['asc', 'desc', null]
              }}
              blockLoadDebounceMillis={SCROLL_TIMEOUT}
              cacheBlockSize={PAGE_LIMIT}
              loadingOverlayComponent={"CustomLoadingOverlayComponent"}
              frameworkComponents={{
                CustomLoadingOverlayComponent,
                EmailRenderer,
                LinkedInRenderer: this.LinkedInRenderer,
                FeeAmountRenderer: this.FeeAmountRenderer,
                LinksRenderer,
                IdRenderer,
                DateTimeRenderer,
                NameRenderer: this.NameRenderer,
                DateRenderer,
                renderActiveCount,
                renderTargetCount,
                CustomFilter,
                HeaderCheckbox: this.HeaderCheckbox,
                ActionsRenderer: this.ActionsRenderer,
                GenericCellEditor,
                PopupEditor,
              }}
              getRowNodeId={(data) => data.id}
              suppressMenuHide={true}
              scrollbarWidth={12}
              suppressHorizontalScroll={false}
              rowModelType={"infinite"}
              datasource={this.dataSource}
              columnDefs={columnDefs(
                userData?.isAdmin,
                placedSearches,
                activeSearches,
                enqueueSnackbar,
                this.loaderChange,
                users,
                false,
                userData.roles[0]
              )}
              paginationPageSize={PAGE_LIMIT}
              rowSelection={"multiple"}
              suppressRowClickSelection={true}
              suppressDragLeaveHidesColumns={true}
              onDisplayedColumnsChanged={this.saveColumnState}
              onDragStopped={this.saveColumnState}
              onSortChanged={this.saveColumnStateForSort}
              onFilterChanged={this.saveColumnStateForFilter}
              onRowSelected={() => {
                // if (!e.node.selected) {
                //   this.setState({ isAllRowsSelected: false })
                // }
                this.setState({
                  selectedRowsCount: this.gridApi.getSelectedRows().length,
                  isRowSelected: this.gridApi.getSelectedRows().length > 0,
                });
              }}
            ></AgGridReact>
          </div>
        </div>
      </div>
    );
  }
}

Searches.propTypes = {
  searchType: PropTypes.string.isRequired,
  columnStateKey: PropTypes.string.isRequired,
  searchStatus: PropTypes.string,
  title: PropTypes.string,
  placedSearches: PropTypes.bool,
  activeSearches: PropTypes.bool,
  enqueueSnackbar: PropTypes.func,
  userData: PropTypes.object,
  id: PropTypes.string,
  users: PropTypes.array,
  fetchUserList: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    userData: state.commonReducer.userData,
    users: state.rootReducer.users,
  };
};

const mapDispatchToProps = { fetchUserList };

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(Searches));
