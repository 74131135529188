//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { withRouter } from 'react-router-dom'
import { Typography } from "@material-ui/core";

//----------------------------------------------// Internal Imports // -------------------------------------------------
import Records from "./Records";
import Companies from "./Companies";
import Contacts from "./Contacts";
import Searches from "./Searches";
import TabLayoutWithHeader from "../TabLayoutWithHeader";
import Workbenches from "./Workbenches";
import './index.scss'

const headerLayout = (searchKey) => {
  return (
    <div className='d-flex align-items-center' style={{ paddingLeft: '40%' }}>
      <span className='header-text' color='primary' >Search:&nbsp;</span>
      <Typography color='primary' className="search-result" >{searchKey}</Typography>
    </div>
  )
}

const MasterSearch = (props) => {
  const [contactsCount, setContactsCount] = useState(0);
  const [companiesCount, setCompaniesCount] = useState(0);
  const [searchesCount, setSearchesCount] = useState(0);
  const [workbenchesCount, setWorkbenchesCount] = useState(0);
  const searchKey = props.location.state
  useEffect(() => {
    localStorage.removeItem('contactColumns')
    localStorage.removeItem('searchColumns')
    localStorage.removeItem('workbenchColumns')
  }, [])
  return (
    <React.Fragment>
      <Helmet>
        <title>Search Results - KG Galaxy</title>
      </Helmet>
      <TabLayoutWithHeader
        isMasterSearch={true}
        headerLayout={headerLayout(searchKey)}
        navItems={[
          `Total Records (${contactsCount + companiesCount + searchesCount + workbenchesCount})`,
          `Searches (${searchesCount})`,
          `Contacts (${contactsCount})`,
          `Companies (${companiesCount})`,
          `Workbenches(${workbenchesCount})`
        ]}
      >
        <Records
          searchKey={searchKey}
          setSearchesCount={setSearchesCount}
          setContactsCount={setContactsCount}
          setCompaniesCount={setCompaniesCount}
          setWorkbenchesCount={setWorkbenchesCount}
        />
        <Searches searchKey={searchKey} minHeight={'100%'} setSearchesCount={setSearchesCount} />
        <Contacts searchKey={searchKey} minHeight={'100%'} setContactsCount={setContactsCount} />
        <Companies searchKey={searchKey} minHeight={'100%'} setCompaniesCount={setCompaniesCount} />
        <Workbenches searchKey={searchKey} minHeight={'100%'} setWorkbenchesCount={setWorkbenchesCount} />
      </TabLayoutWithHeader>
    </React.Fragment>
  )
}

MasterSearch.propTypes = {
  location: PropTypes.object
}

export default (withRouter(MasterSearch))
