
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { Component } from "react";

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from "prop-types";
import CloseIcon from "@material-ui/icons/Close";
import PaletteIcon from "@material-ui/icons/Palette";

class ColorLegend extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  render() {
    const { colorList, setColorDesc, extraClassName = '' } = this.props;
    return (
      <div className={`action-container ${extraClassName}`}>
        <div
          style={{ textAlign: "center" }}
          onClick={() => this.setState({ open: true })}
        >
          <PaletteIcon className="action-icon" />
          <span className="action-text" style={{ lineHeight: "1.5" }}>
            {" "}
            Color Legend
          </span>
        </div>

        {this.state.open ? (
          <div className="color-list" style={{ border: "2px solid #5f51cb" }}>
            <div
              className="color-legend-bg"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <span
                className="ml-1 "
                style={{ height: "20px", fontWeight: "bold" }}
              >
                Color Legend{" "}
              </span>
              <CloseIcon
                style={{ cursor: "pointer" }}
                onClick={() => {
                  this.setState({ open: false });
                }}
              />
            </div>
            {colorList != null ? (
              <>
                {colorList.map((color, index) => {
                  return (
                    <>
                      <div key={color.id} className="d-flex align-items-center">
                        <div
                          className="color-code-box ml-1 mt-1 mb-1 mr-1 MuiCheckbox-root"
                          style={{ background: `#${color.hex_code}` }}
                        ></div>
                        <input
                          onChange={(event) =>
                            setColorDesc(event, color, index)
                          }
                          name={color.hex_code}
                          value={color.desc || ""}
                          type="text"
                          className="mt-1 mb-1 ml-1 mr-1"
                          style={{ border: "rgba(158, 150, 150, .5)" }}
                          placeholder="Enter Text"
                        ></input>
                      </div>
                    </>
                  );
                })}
              </>
            ) : null}
          </div>
        ) : null}
      </div>
    );
  }
}

ColorLegend.propTypes = {
  colorList: PropTypes.array,
  setColorDesc: PropTypes.func,
  extraClassName: PropTypes.string
};

export default ColorLegend;
