//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react'

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core';
import { withSnackbar } from 'notistack';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import RichTextPopup from '../../../../../RichTextPopup';
import { searchDataApi } from '../../../../../../services/ApiService';
import { SUCCESS, ERROR, PATCH } from "../../../../../../services/constantService";
import { unableMessage, successMessage, VALIDATION_MESSAGE } from '../../../../../../services/MessageService';

function Feedback(props) {
  const { enqueueSnackbar, searchInfo = {}, currentValues, setValue, register, setSfpaData, setCurrentValues, readOnly } = props;
  const [isLastSaveSuccess, setLastSaveSuccess] = useState(true)

  useEffect(() => {
    register("benchmark_feedback");
    register("summary_feedback");
    register("executive_feedback");

    setValue('benchmark_feedback', currentValues.benchmark_feedback)
    setValue('summary_feedback', currentValues.summary_feedback)
    setValue('executive_feedback', currentValues.executive_feedback)
  }, [register, currentValues, setValue])

  const handleChange = (key, value) => {
    setValue(key, value)
  }

  const updateFeedback = async (payload, skipMessage, setAutoSaveLoading) => {
    let returnResult;
    if (setAutoSaveLoading) {
      setAutoSaveLoading(true)
    }
    delete payload.old
    const { status, data } = await searchDataApi(PATCH, searchInfo.id, payload, 'search-autosave-field');
    if (status && status === 200) {
      // setSFPAUpdated(data.updated_at);
      payload.updated_at = data.result.updated_at
      setCurrentValues({ ...currentValues, ...payload })
      setSfpaData(payload);
      setLastSaveSuccess(true)
      const message = successMessage("Feedback", VALIDATION_MESSAGE.updated_message)
      !skipMessage && enqueueSnackbar(data?.message || message, { variant: SUCCESS });
      returnResult = true;
    } else {
      setLastSaveSuccess(false)
      const message = unableMessage("feedback", "update")
      enqueueSnackbar(data?.message || message, { variant: ERROR });
      returnResult = false;
    }
    if (setAutoSaveLoading) {
      setTimeout(() => {
        setAutoSaveLoading(false)
      }, 500);
    }
    return returnResult;
  }

  return (
    <div className={`${readOnly ? 'section-content read-only' : ''}`}>
      <div style={{ marginBottom: '20px' }}>
        <Typography className="section-label" color="primary">Benchmark Feedback</Typography>
        <div className='text-view-value p-0 w-100'>
          <div className='outlined-border transform p-0'>
            <RichTextPopup
              // ref={register(`benchmark_feedback`)}
              value={currentValues.benchmark_feedback || null}
              title="Benchmark Feedback"
              name="benchmark_feedback"
              setOpen={readOnly ? false : true}
              onSave={updateFeedback}
              onChange={(value) => {
                handleChange('benchmark_feedback', value)
              }}
              autoSave={updateFeedback}
              saveStatus={isLastSaveSuccess}
              updatedDate={currentValues.updated_at}
              restrictHeight={true}
            />
          </div>
        </div>
      </div>

      <div style={{ marginBottom: '20px' }}>
        <Typography className="section-label" color="primary">Summary Feedback</Typography>
        <div className='text-view-value p-0 w-100'>
          <div className='outlined-border transform p-0'>
            <RichTextPopup
              // ref={register(`summary_feedback`)}
              value={currentValues.summary_feedback || null}
              title="Summary Feedback"
              setOpen={readOnly ? false : true}
              name="summary_feedback"
              onSave={updateFeedback}
              onChange={(value) => {
                handleChange('summary_feedback', value)
              }}
              autoSave={updateFeedback}
              saveStatus={isLastSaveSuccess}
              updatedDate={currentValues.updated_at}
              restrictHeight={true}
            />
          </div>
        </div>
      </div>

      <div style={{ marginBottom: '20px' }}>
        <Typography className="section-label" color="primary">Executive Feedback</Typography>
        <div className='text-view-value p-0 w-100'>
          <div className='outlined-border transform p-0'>
            <RichTextPopup
              // ref={register(`executive_feedback`)}
              value={currentValues.executive_feedback || null}
              title="Executive Feedback"
              name="executive_feedback"
              setOpen={readOnly ? false : true}
              onSave={updateFeedback}
              onChange={(value) => {
                handleChange('executive_feedback', value)
              }}
              autoSave={updateFeedback}
              saveStatus={isLastSaveSuccess}
              updatedDate={currentValues.updated_at}
              restrictHeight={true}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

Feedback.propTypes = {
  searchInfo: PropTypes.object,
  enqueueSnackbar: PropTypes.func,
  currentValues: PropTypes.object,
  readOnly: PropTypes.bool,
  setValue: PropTypes.func,
  register: PropTypes.func,
  watch: PropTypes.func,
  setSfpaData: PropTypes.func,
  setCurrentValues: PropTypes.func,
}

export default withSnackbar(Feedback);
