//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect } from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types'
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import { FormControlLabel, FormGroup } from "@material-ui/core/";

//----------------------------------------------// Internal Imports // -------------------------------------------------
import InputField from "../../../../../common/InputField";
import DegreeSelection from "../../../../../DegreeSelection";

export default function TargetEducationandExperience(props) {
  const { register, watch, setValue, readOnly } = props;

  useEffect(() => {
    register('is_years_of_experience_mandatory');
    register('degree_name');
    register('is_degree_mandatory');
    register('max_years_of_experience_current_company')
  }, [register])

  const excludeDotFromInput = (event) => {
    if (event.key === '.') {
      event.preventDefault();
    }
  };

  return (
    <div className={`section-content ${readOnly ? 'read-only' : ''}`}>
      <Typography className="section-label" >
        Education and Experience
      </Typography>
      <Grid item xs={12} >
        <div className="d-flex flex-grow-1">
          <div className="input-form-field">
            <div className="d-flex flex-grow-1" >
              <InputField
                ref={register()}
                type="number"
                placeholder="Minimum years of experience"
                className="input-form-field"
                label="Minimum years of experience"
                InputLabelProps={{ focused: true }}
                name="years_of_experience"
              />
              <div className="input-form-field">
                <FormGroup style={{ color: "black", width: "20%" }} row>
                  <FormControlLabel
                    className="checkbox-container"
                    style={{
                      color: "black",
                      margin: "10px 0 0",
                      left: "100%"
                    }}
                    control={
                      <Checkbox
                        className="checkbox-component"
                        name="is_years_of_experience_mandatory"
                        checked={watch(
                          "is_years_of_experience_mandatory"
                        ) || false}
                        onChange={(e) => {
                          setValue("is_years_of_experience_mandatory", e.target.checked);
                        }}
                        inputRef={register()}
                      />
                    }
                    label="Mandatory"
                  />
                </FormGroup>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-grow-1 mb-2">
          <div className="input-form-field">
            <div className="d-flex flex-grow-1">
              <InputField
                ref={register()}
                type="number"
                placeholder="Maximum years of experience in current company"
                className="input-form-field"
                label="Maximum years of experience in current company"
                InputLabelProps={{ focused: true }}
                name="max_years_of_experience_current_company"
                onKeyPress={excludeDotFromInput}
                inputProps={{ min: 0, pattern: '[0-9]*[.,]?[0-9]*', step: '1', }}
                onChange={(e) => setValue('max_years_of_experience_current_company', e.target.value)}
              />
              <div className="input-form-field"></div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-grow-1">
          <div className="input-form-field">
            <div className="d-flex flex-grow-1">
              <DegreeSelection
                className='input-form-field'
                value={watch("degree_name") || ""}
                onChange={(e, data) => setValue('degree_name', data)}
                label="Education 1"
                placeholder="Education 1"
                InputLabelProps={{ focused: true }}
              />
              <div className="input-form-field">
                <FormGroup style={{ color: "black" }} row>
                  <FormControlLabel
                    className="checkbox-container"
                    style={{
                      color: "black",
                      margin: "10px 0 0",
                      left: "100%"
                    }}
                    control={
                      <Checkbox
                        className="checkbox-component"
                        name="is_degree_mandatory"
                        checked={watch("is_degree_mandatory") || false}
                        onChange={(e) => {
                          setValue("is_degree_mandatory", e.target.checked);
                        }}
                        inputRef={register()}
                      />
                    }
                    label="Mandatory"
                  />
                </FormGroup>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-grow-1">
          <div className="input-form-field">
            <div className="d-flex flex-grow-1">
              <InputField
                ref={register()}
                className="input-form-field"
                label="Certification"
                placeholder="Certification"
                InputLabelProps={{ focused: true }}
                name="certifications"
              />
              <div className="input-form-field">
              </div>
            </div>
          </div>
        </div>
      </Grid>
    </div>
  );
}

TargetEducationandExperience.propTypes = {
  register: PropTypes.func,
  watch: PropTypes.func,
  readOnly: PropTypes.bool,
  setValue: PropTypes.func
};
