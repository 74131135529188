//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState, /*useCallback*/ } from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { useSnackbar } from "notistack"
import { Typography, Grid } from "@material-ui/core";
import { useParams } from 'react-router-dom';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import InputField from '../../../../../common/InputField';
import RichTextPopup from "../../../../../RichTextPopup";
import { searchDataApi } from "../../../../../../services/ApiService";
import { SUCCESS, ERROR, PATCH } from "../../../../../../services/constantService";
import { unableMessage } from "../../../../../../services/MessageService";
export default function CompanyDetails(props) {
  const { register, setValue, currentValues, setCurrentValues, sfpa, removeHtmlTags, setSFPAUpdated } = props;
  if (currentValues.company === undefined) { currentValues.company = {} }
  const [company, setCompany] = useState([{}])
  const { enqueueSnackbar } = useSnackbar()
  if(currentValues?.company){
    if (currentValues?.company?.news === undefined) { currentValues.company.news = sfpa && sfpa.company && sfpa.company.news ? sfpa?.company?.news  : ''  }
    if (currentValues?.company?.description === undefined) { currentValues.company.description = sfpa && sfpa.company && sfpa.company.description ? sfpa?.company?.description : '' }
  }
  const [descriptionEditorHtml, setDescriptionEditorHtml] = useState(currentValues?.company?.description)
  const [newsEditorHtml, setNewsEditorHtml] = useState(currentValues?.company?.news)
  const [isLastSaveSuccess, setLastSaveSuccess] = useState(true)
  const { id } = useParams();
  useEffect(() => {
    setCompany(currentValues.company || []);
    register('company')
    register('description')
    register('news')
    setDescriptionEditorHtml(currentValues?.company?.description);
    setNewsEditorHtml(currentValues?.company?.news);
  }, [currentValues.company, register, descriptionEditorHtml, newsEditorHtml]);

  useEffect(() => {
    setValue('company', company);
    setValue('description', descriptionEditorHtml)
    setValue('news', newsEditorHtml)
  }, [setValue]); // adding company and editorHtml in dependency will stop redirecting screen from position profile

  const updateCompany = (name, data) => {
    const currentCompany = { ...company };
    currentCompany[name] = data;
    setCompany(currentCompany);

    // these two lines added to fix the issue of saving company news/other details  
    setValue('company', currentCompany);
    setValue('description', descriptionEditorHtml)
    setValue('news', newsEditorHtml)
  }

  // const getSearchs = useCallback(async (id) => {
  //   const { status, data } = await search_data(GET, id, {}, 'sfpa');
  //   if (status === 200 && data !== null) {
  //     return data;
  //   } else if (status === 200) {
  //     return false;
  //   } else {
  //     return false;
  //   }
  // }, [id]);
 
  const saveCompanyDetails = async (payload, skipMessage, setAutoSaveLoading) => {
    let plainText;
    if (Object.prototype.hasOwnProperty.call(payload, "description")) {
      plainText = removeHtmlTags(payload.description)
      if (plainText.length === 0) { payload.description = plainText }
    }
    if (Object.prototype.hasOwnProperty.call(payload, "news")) {
      plainText = removeHtmlTags(payload.news)
      if (plainText.length === 0) { payload.news = plainText }
    }
    if ((payload.description !== undefined) || (payload.news !== undefined)) {
      let returnResult;
      const sendPayload = {
        website: company.website,
        linkedin_url: company.linkedin_url,
        news: payload.news ||  payload.news  === "" ?  payload.news : company.news,
        description: payload.description || payload.description === "" ?  payload.description :company.description,
        id: sfpa.company.id
      }
      if (setAutoSaveLoading) {
        setAutoSaveLoading(true)
      }
      const response = await searchDataApi(PATCH, id, sendPayload, 'sfpa');
      const { data, status } = response ? response : { null: null };
      if (status && status === 200) {
        !skipMessage && enqueueSnackbar(data.message, { variant: SUCCESS });
        const updatedDate = new Date().toLocaleString('en-US');
        setCurrentValues({ ...currentValues.company, updated_at: updatedDate })
        setSFPAUpdated(false);
        setLastSaveSuccess(true);
        returnResult = true;
      } else {
        const message = unableMessage("Company Details", "update")
        enqueueSnackbar(data?.message || message, { variant: ERROR });
        setLastSaveSuccess(false)
        returnResult = false
      }
      if (setAutoSaveLoading) {
        setTimeout(() => {
          setAutoSaveLoading(false)
        }, 1000);
      }
      return returnResult
    }
  }

  return (
    <div
      style={{
        marginTop: '20px',
        marginLeft: '20px',
        marginBottom: '0px',
        padding: '0px'
      }}>
      <div>
        <Grid container>
          <Grid item xs={12}>
            <Typography
              style={{ float: 'left', fontWeight: 'bold' }}
            >
              Company Details
            </Typography>
          </Grid>
        </Grid>
      </div>
      <Grid container spacing={0}>
        <Grid xs={12}>
          <div className='text-view-value p-0 width-95'>
            <div className='outlined-border transform p-0 mb-2'>
              <RichTextPopup
                onChange={setDescriptionEditorHtml}
                value={descriptionEditorHtml}
                onSave={saveCompanyDetails}
                title="Company Details"
                name='description'
                autoSave={saveCompanyDetails}
                saveStatus={isLastSaveSuccess}
                updatedDate={currentValues?.company?.updated_at}
                restrictHeight={true}
              />
            </div>
          </div>
          <Typography
            style={{ float: 'left', fontWeight: 'bold' }}
          >
            Company website
          </Typography>
          <InputField
            className='input-form-field'
            InputLabelProps={{ focused: true }}
            style={{ width: '98%' }}
            onChange={(e) => {
              updateCompany('website', e.target.value)
            }}
            value={company.website != null ? company.website : ''}
          />
          <Typography
            style={{ float: 'left', fontWeight: 'bold' }}
          >
            Company LinkedIn URL
          </Typography>
          <InputField
            className='input-form-field'
            InputLabelProps={{ focused: true }}
            style={{ width: '98%' }}
            onChange={(e) => {
              updateCompany('linkedin_url', e.target.value)
            }}
            value={company.linkedin_url != null ? company.linkedin_url : ''}
          />
          {/* <Typography
            style={{ float: 'left', fontWeight: 'bold' }}
          >
            Company news
          </Typography>
          <InputField
            multiline
            rowsMax="10"
            className='input-form-field'
            InputLabelProps={{ focused: true }}
            style={{ width: '98%' }}
            onChange={(e) => {
              updateCompany('news', e.target.value)
            }}
            value={company.news != null ? company.news : ''}
          /> */}
          <div>
            <Typography
              style={{ fontWeight: 'bold' }}
            >
              Company news
            </Typography>
            <div className='outlined-border transform p-0 mb-2'>
              <RichTextPopup
                onChange={setNewsEditorHtml}
                value={newsEditorHtml}
                onSave={saveCompanyDetails}
                title="Company news"
                name='news'
                autoSave={saveCompanyDetails}
                saveStatus={isLastSaveSuccess}
                updatedDate={currentValues?.company?.updated_at}
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
CompanyDetails.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  currentValues: PropTypes.object,
  setCurrentValues: PropTypes.func,
  setSFPAUpdated: PropTypes.func,
  sfpa: PropTypes.object,
  removeHtmlTags: PropTypes.func
};
