//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useState, useEffect } from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from "prop-types";
import Popup from "reactjs-popup";
import CloseIcon from "@material-ui/icons/Close";
import { Button, TextField, Typography, RadioGroup, FormControlLabel, FormControl, FormLabel, Checkbox } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";

//----------------------------------------------// Internal Imports // -------------------------------------------------
import TypeSelection from "./TypeSelection";
import UsersSelection from "./UsersSelection";
import Loader from "../../common/Loader";
import { formatDate } from "../../../utils/date";

export default function WorkbenchAddScreen(props) {
  const {
    open, loading, register, control, users, watch, setValue, onClose, Disabled, buttonText, handleSubmit, onSubmit, title, DefaultValue
  } = props

  const [type, setType] = useState('');

  useEffect(() => {
    register('archive_date')
  }, [register])

  useEffect(()=>{
    setType(DefaultValue)
  },[]);
  const getType = (type) => {
    setType(type)
  }
  return (
    <Popup open={open} className={"add-workbench"} closeOnDocumentClick={false} closeOnEscape={false}>
      <React.Fragment>
        <Loader show={loading} />
        <div className='quick-add-company h-100 overflow-auto'>
          <div className="quick-add-header">
            <Typography className='ml-4'>{title}</Typography>
            <CloseIcon className="cursor-pointer" onClick={onClose} />
          </div>
          <div className={'quick-add-container'}>
            <div className='d-flex flex-column h-100'>
              <div className='quick-add-content'>
                <TextField
                  // required
                  name='name'
                  inputRef={register()}
                  label={<label>Workbench Name<span className="MuiInputLabel-asterisk"> *</span></label>}
                  placeholder='Workbench Name'
                  className='input'
                  InputLabelProps={{ focused: true }}
                />
                <TypeSelection  getType={getType} control={control} Disabled={Disabled} />
                <UsersSelection control={control} users={users} />
                <KeyboardDatePicker
                  autoOk
                  //disableToolbar
                  className='input'
                  variant="inline"
                  format="dd/MM/yyyy"
                  label="Archive Date"
                  placeholder="Archive Date"
                  value={formatDate(watch('archive_date')) || null}
                  onChange={(date) => {
                    setValue('archive_date', formatDate(date))
                  }}
                  InputLabelProps={{ focused: true }}
                />
                <TextField
                  placeholder={"Description here"}
                  name='description'
                  inputRef={register}
                  label={'Description'}
                  className='input w-100'
                  InputLabelProps={{ focused: true }}
                />
                {type === 'Searches' ? (
                  <FormControl className="d-flex w-100">
                    <FormLabel
                      focused={true}
                      className='MuiInputLabel-shrink'>
                      Rep List
                    </FormLabel>
                    <RadioGroup row className="radio-group">
                      <FormControlLabel
                        label={'Yes'}
                        control={
                          <Checkbox
                            style={{ padding: '0px 10px' }}
                            defaultChecked={false}
                            name="is_replist"
                            inputRef={register()}
                          />
                        }/>
                    </RadioGroup>
                  </FormControl>
                ) : ''}
              </div>
              <div className="quick-add-footer">
                <Button variant='text' onClick={onClose} color="primary" className='mr-4'>Cancel</Button>
                <Button color="primary" variant="contained" onClick={handleSubmit(onSubmit)}>
                  {buttonText}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    </Popup>
  )
}

WorkbenchAddScreen.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  control: PropTypes.object,
  users: PropTypes.array,
  watch: PropTypes.func,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  handleSubmit: PropTypes.func,
  buttonText: PropTypes.string,
  title: PropTypes.string,
  open: PropTypes.bool,
  loading: PropTypes.bool,
  Disabled: PropTypes.bool,
  DefaultValue:PropTypes.string
};
