
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from 'react'


//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from 'prop-types'
import EmailIcon from '@material-ui/icons/Email';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import './index.scss'
import EmailPopover from '../../../Containers/Contacts/EmailPopover';

export default function EmailView(props) {
  const { contact, increaseFontSize = false } = props;

  const getEmailList = () => {
    let emails = [];
    if (contact.work_email) {
      emails.push({ label: "Work", value: contact.work_email })
    }
    if (contact.private_email) {
      emails.push({ label: "Personal", value: contact.private_email })
    }
    if (contact.email) {
      emails.push({ label: "Personal", value: contact.email })
    }
    return emails;
  }

  const renderEmailView = () => {
    let emails = getEmailList();
    return emails.length > 0 ? <EmailPopover emails={emails}>
      <a
        href={`mailto:${emails[0].value}`}
        target={'noopener'}
        className="contact-profile-link"
      >
        <EmailIcon fontSize={increaseFontSize ? 'default' : 'inherit'} />
      </a>
    </EmailPopover> : <span className="contact-profile-link"><EmailIcon fontSize={increaseFontSize ? 'default' : 'inherit'} color='disabled' /></span>
  }

  return (
    renderEmailView()
  )
}

EmailView.propTypes = {
  contact: PropTypes.object,
  increaseFontSize: PropTypes.bool
}
