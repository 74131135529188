
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from 'react'

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from 'prop-types'
import { withSnackbar } from 'notistack';
import DomainIcon from '@material-ui/icons/Domain';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import PeopleIcon from '@material-ui/icons/People';
import StarIcon from '@material-ui/icons/Star';
import EditIcon from '@material-ui/icons/Edit';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import EmailView from './EmailView';
import PhoneView from './PhoneView';
import VideoView from './VideoView';
import { LinkedInRenderer, RedirectToCompanies } from "../../../utils/common";
import './index.scss'


function ProfileView(props) {
  const { className, contact, video, isEditing, profileUrl, setNewProfilePic, setProfileUrl } = props;

  const onFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      setNewProfilePic(file);
      var fr = new FileReader();
      fr.onload = function () {
        setProfileUrl(fr.result);
      }
      fr.readAsDataURL(file);
    }
  }
  // const getContactTitleAndCompany = () => {
  //   if (contact.current_job_title && contact.company?.name) {
  //     return <>
  //     {`${contact.current_job_title} at `}
  //     {<RedirectToCompanies id={contact.company.id} name={contact.company.name} /> }
  //     </>
  //   } else if (contact.current_job_title) {
  //     return `${contact.current_job_title}`
  //   } else if(contact.company?.name) {
  //     return <RedirectToCompanies id={contact.company.id} name={contact.company.name} />
  //   }
  //   return '--';
  // }

  return (
    <div className={`contact-profile d-flex align-items-center ${className || ''}`}>
      <label
        className={`profile-image-container contact-profile-image d-flex align-items-center justify-content-center ${isEditing ? 'edit' : ''}`}
        htmlFor="profile-pic-input">
        <span className="image-container">{profileUrl ? <img src={profileUrl} alt="Profile" /> :
          <span className="default-profile-icon"><AccountCircleIcon fontSize="inherit" /></span>}</span>
        {isEditing ?
          <>
            <input
              id="profile-pic-input" type="file" accept="image/*" hidden
              onClick={(e) => {
                e.target.value = null;
                setNewProfilePic(null)
              }}
              onChange={onFileChange}
            />
            <div className="profile-pic-edit">
              <EditIcon fontSize="inherit" />
            </div>
          </>
          :
          contact.is_rock_star && <div className="contact-rock-star">
            <StarIcon fontSize="inherit" />
          </div>
        }
      </label>
      {isEditing ? (
        ''
      ) : (
        <>
          <div className="contact-profile-info flex-column">
            <div className="d-flex align-items-center">
              <div
                className="contact-profile-name">{`${contact.first_name || ''} ${contact.last_name || ''}` || '--'}</div>
              <div className="contact-profile-links d-flex">
                {LinkedInRenderer(contact.linkedin_url)}
                <PhoneView contact={contact} />
                <EmailView contact={contact} />
                <VideoView video={video} />
              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="contact-profile-info-icon">
                <DomainIcon fontSize="inherit" />
              </div>
              <div className="contact-profile-info-text contact-profile-domain">
                {`${contact.current_job_title || ''} ${'at'} `}
                {contact.company && contact.company.name ?
                  <RedirectToCompanies id={contact.company.id} name={contact.company.name} />
                  : '--'}

              </div>
            </div>
            <div className="d-flex align-items-center">
              <div className="contact-profile-info-icon">
                <LocationOnIcon fontSize="inherit" />
              </div>
              <div
                className="contact-profile-info-text contact-profile-location">{`${contact.state || ''}${contact.state && contact.country ? ',' : ''} ${contact.country || ''}`}</div>
            </div>
            <div className="d-flex align-items-center">
              <div className="contact-profile-info-icon">
                <PeopleIcon fontSize="inherit" />
              </div>
              <div
                className="contact-profile-info-text contact-profile-type">{contact.is_client ? "Client" : "--"}</div>
            </div>
          </div>
        </>
      )
      }
    </div>
  )
}

ProfileView.propTypes = {
  className: PropTypes.string,
  contact: PropTypes.object,
  video: PropTypes.object,
  isEditing: PropTypes.bool,
  enqueueSnackbar: PropTypes.func,
  setNewProfilePic: PropTypes.func,
  profileUrl: PropTypes.string,
  setProfileUrl: PropTypes.func
}

export default withSnackbar(ProfileView);
