//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState, useCallback } from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from "prop-types";
import Popup from "reactjs-popup";
import { Button, Checkbox, InputLabel, Typography } from "@material-ui/core";
import { useForm } from "react-hook-form";
import CloseIcon from "@material-ui/icons/Close";
import InputField from "../../components/common/InputField";
import "./index.scss";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { useSnackbar } from "notistack";
import { ERROR, LINKEDIN_SCRAPER, LINKEDIN_URL_REGEX, POST, SUCCESS, WARNING } from "../../services/constantService";
import { contactDataApi, searchDataApi } from "../../services/ApiService";
import Loader from "../../components/common/Loader";
import { useDispatch } from "react-redux";
import { REFRESH_CONTACTS } from "../../types";
import { useHistory } from "react-router-dom";
import { linkedinChecker } from "../../utils/common";
import { successMessage, unableMessage } from "../../services/MessageService";
import debounce from "lodash.debounce";

//----------------------------------------------// Internal Imports // -------------------------------------------------
//import SearchSelection from "../../SearchSelection";

export default function AddLinkedinPopup(props) {
  const { handleClose, open, title = "Contact", searchId, isCandidate } = props
  let [contacts, setContacts] = useState([{ first_name: '', last_name: '', linkedin_url: '', position: 0, error: '', duplicate: null, checked: false }])
  const { handleSubmit, register, setValue, getValues } = useForm({ contacts })
  const [isLoading, setLoading] = useState(false)
  // const [checkDbValidation, setDbValidation] = useState(false)
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const history = useHistory()
  useEffect(() => {
    setValue('contacts', contacts)
  }, [contacts, setValue])

  useEffect(() => {
    register('contacts')
  }, [register])

  const handleChange = (index, name, value) => {
    let updatedData = [...contacts];
    updatedData[index][name] = value;
    setContacts(updatedData);
    // if (name !=='checked') {
    //   setDbValidation(true);
    // }
  }

  const debouncedSave = useCallback(
    debounce((nextValue) => { contacts = nextValue; checkDuplicateContact({ contacts }, true) }, 1000),
    [],
  );
  /* const onLinkedinBlur = () => {
    let validateContacts = contacts.reduce((prev, curr) => {
      if (curr?.linkedin_url && !curr?.linkedin_url.match(URL_REGEX)) {
        curr.error = 'Please enter valid linkedin url'
      } else {
        curr.error = '';
        let localDuplicate = contacts.filter(ele => {
          if (linkedinChecker(curr.linkedin_url) === linkedinChecker(ele.linkedin_url)) {
            return true
          }
        })
        if (localDuplicate.length > 1) {
          let contactStrings = localDuplicate.map(ele => `Record ${ele.position + 1}`)
          curr.error = `${contactStrings.join(', ')} have same linkedin url, please change or remove`
        }
      }
      curr.duplicate = null;
      curr.checked=false;
      prev = [...prev, curr]
      return prev
    }, ([]))
    setDbValidation(true);
    setContacts(validateContacts);
  } */

  // const onLinkedinBlur = async () => {
  //   // setDbValidation(true);
  //   await checkDuplicateContact({contacts}, true);
  // } 

  const checkDuplicateContact = async (payload, checkDbValidation) => {
    try {
      setLoading(true)
      const sub_route = LINKEDIN_SCRAPER.CHECK_DUPLICATE_CONTACTS
      const { status, data } = await contactDataApi(POST, undefined, payload, sub_route)
      if (status === 200) {
        const duplicateContacts = data.data;
        let isError = false
        // let contactsList = JSON.parse(JSON.stringify(contacts));
        let validateContacts = payload.contacts.reduce((prev, curr) => {
          curr.error = '';
          curr.duplicate = null;
          if (!curr.linkedin_url) {
            isError = false;
          } else if (curr?.linkedin_url && !curr?.linkedin_url.match(LINKEDIN_URL_REGEX)) {
            curr.error = 'Please provide a valid LinkedIn URL or remove entry.'
            curr.duplicate = null
            isError = true
          } else {
            curr.error = '';
            let localDuplicate = payload.contacts.filter(ele => {
              if (curr.linkedin_url && (linkedinChecker(curr.linkedin_url) === linkedinChecker(ele.linkedin_url))) {
                return true
              }
            })
            if (localDuplicate.length > 1) {
              let contactStrings = localDuplicate.map(ele => `${ele.position + 1}`)
              curr.error = `Record ${contactStrings.join(', ')} have the same LinkedIn URL. Please remove duplicates.`
              curr.duplicate = null
              isError = true
            } else if (duplicateContacts.find(ele => ele.position === curr.position)) {
              const duplicate = duplicateContacts.find(ele => ele.position === curr.position).duplicate
              if (isCandidate) {
                curr.error = `<span>Contact with specified LinkedIn URL is available in galaxy with name <a href='/contacts/${duplicate.id}/' target='blank' >${duplicate.first_name} ${duplicate.last_name}</a>. </span>`
              } else {
                curr.error = `<span>Contact <a href='/contacts/${duplicate.id}/' target='blank'>${duplicate.first_name} ${duplicate.last_name}</a> already exists. Please enter another contact or delete this entry</span>`
                isError = true
              }
              curr.duplicate = duplicate
            }
          }
          prev = [...prev, curr]
          return prev
        }, ([]))
        setLoading(false)
        if (isError || checkDbValidation) {
          setContacts(validateContacts)
          // setDbValidation(false)
          return false
        }
        return true
      } else {
        return false
      }
    } catch (err) {
      console.log('err in checkDuplicateContact::', err)
    }
  }

  const handleAdd = async (data) => {
    try {
      // setDbValidation(false);
      const isValid = await checkDuplicateContact({ contacts }, false)
      if (isValid) {
        setLoading(true)
        for await (const [, contact] of data.contacts.entries()) {
          if (isCandidate && contact.duplicate) {
            if (contact.checked) {
              await addToCandidate(contact.duplicate)
            }
          } else if (!contact.duplicate) {
            await addContactFromLinkedin(contact)
          }
        }
        setLoading(false)
        dispatch({ type: REFRESH_CONTACTS, payload: true })
        if (!isCandidate) {
          history.replace('/contacts/my-linkedin-contacts')
        }
        handleClose(false)
        dispatch({ type: REFRESH_CONTACTS, payload: false })
      } else {
        return false;
      }
    } catch (e) {
      console.log("Error found in handleAdd::", e);
    }
  }

  const handleCancel = () => handleClose(false)

  const addContact = () => {
    setContacts(prevState => [...prevState, { first_name: '', last_name: '', linkedin_url: '', position: contacts.length, error: '', duplicate: null, checked: false }
    ])
  }

  const removeContact = (index) => {
    const { contacts } = getValues({ nest: true });
    let details = [...contacts];
    details.splice(index, 1)
    details = details.map((ele, i) => ({ ...ele, position: i }))
    // setDbValidation(true)
    setContacts(details)
    for (let i = 0; i < details.length; i++) {
      setValue(`contacts[${i}].first_name`, details[i].first_name);
      setValue(`contacts[${i}].last_name`, details[i].last_name);
      setValue(`contacts[${i}].linkedin_url`, details[i].linkedin_url);
    }
    checkDuplicateContact({ contacts: details }, true)
  }

  const addToCandidate = async (contact) => {
    const candidate = {
      contact_id: contact.id,
      stage: 'Target'
    }
    const { status, data: res } = await searchDataApi(
      POST,
      searchId,
      [candidate],
      "candidates"
    );
    if (status === 201) {
      if (res?.candidatesAdded.length) {
        const message = successMessage("Candidate", "added to search");
        enqueueSnackbar(message, { variant: SUCCESS });
      }
      if (res?.candidatesDuplicate.length) {
        const message = `Duplicate candidate found: ${contact?.first_name} ${contact?.last_name}`;
        enqueueSnackbar(message, { variant: WARNING });
      }
      if (res?.candidatesUpdated.length > 0) {
        res.candidatesUpdated.forEach(ele => {
          const message = `This candidate ${ele?.contact.first_name} ${ele?.contact.last_name} is already in the search with stage ${ele.stage}. Now the stage has been changed to ${candidate.stage}.`;
          enqueueSnackbar(message, { variant: SUCCESS, persist: true });
        })
      }
    } else {
      const message = unableMessage("candidate", "add");
      enqueueSnackbar(res.message || message, { variant: ERROR });
    }
  }

  const addContactFromLinkedin = async (contact) => {
    const subRoute = LINKEDIN_SCRAPER.GET_LINKEDIN_TASK;
    const payload = {
      contact,
      searchId,
      isCandidate
    }
    const { status, data } = await contactDataApi(POST, undefined, payload, subRoute)
    if (status === 200) {
      if (data?.data?.task_id) {
        enqueueSnackbar(`Request to add contact ${contact.first_name} ${contact.last_name} to Galaxy from LinkedIn is being processed. Record will be updated in a few minutes.`, { variant: SUCCESS });
        return true
      } else {
        const message = `${`LinkedIn URL ${contact.linkedin_url}`} for Contact(${contact.first_name} ${contact.last_name}) is invalid. A support ticket has been logged in Zendesk.`
        enqueueSnackbar(message, { variant: ERROR, autoHideDuration: 8000 });
        return false;
      }
    } else {
      const message = `Failed to create task for Contact(${contact.first_name} ${contact.last_name}). A support ticket has been logged in Zendesk.`
      enqueueSnackbar(message, { variant: ERROR });
      return false
    }
  }

  const singleLinkedinContact = (item, index) => {
    return <div className="mb-4" key={index}>
      <div className="w-100 d-flex align-items-end mb-3">
        <Typography fontSize={18} className="mr-2">{index + 1}.</Typography>
        <div className="w-100">
          <InputField
            placeholder="First Name"
            required={true}
            inputRef={register({ /* name: `contacts[${index}].first_name`, */ required: true })}
            className="field-value"
            name={`contacts[${index}].first_name`}
            label='First Name'
            InputLabelProps={{ focused: true }}
            onChange={(e) => {
              handleChange(index, 'first_name', e.target.value)
            }}
          //value={item.first_name}
          />
        </div>
        <div className="w-100">
          <InputField
            placeholder="Last Name"
            required={true}
            inputRef={register({ /* name: `contacts[${index}].last_name`, */ required: true })}
            className="field-value"
            name={`contacts[${index}].last_name`}
            label='Last Name'
            InputLabelProps={{ focused: true }}
            onChange={(e) => {
              handleChange(index, 'last_name', e.target.value)
            }}
          // value={item.last_name}
          />
        </div>
        <div className="w-100">
          <InputField
            inputRef={register({ /* name: `contacts[${index}].linkedin_url`, */ required: true })}
            required={true}
            label='LinkedIn URL'
            placeholder='LinkedIn URL'
            className="field-value"
            InputLabelProps={{ focused: true }}
            name={`contacts[${index}].linkedin_url`}
            onChange={(e) => {
              debouncedSave(contacts)
              handleChange(index, 'linkedin_url', e.target.value)
            }}
          //value={item.linkedin_url}
          />
        </div>
        {contacts.length > 1 ?
          <CloseIcon cursor='pointer' onClick={() => removeContact(index)} style={{ width: 18, height: 18, marginRight: 15 }} />
          : null}
      </div>
      {item.error ? (
        <>
          <InputLabel color="error" error="true" for="checkbox">
            <span dangerouslySetInnerHTML={{ __html: item.error }}></span>
            {(isCandidate && item.duplicate) ? <span>
              Add Now
              <Checkbox
                onChange={(e, checked) => handleChange(index, 'checked', checked)}
                defaultChecked={item.checked}
                size='small'
                style={{ padding: '0 2px' }}
                name={`contacts[${index}].checked`}
                inputRef={register}
                id="selection"
              />
            </span> : null}
          </InputLabel>
        </>
      ) : null}
    </div>
  }

  return (
    <Popup open={open} className='quick-add-contact-linkedin' closeOnDocumentClick={false} closeOnEscape={false}>
      {isLoading && <Loader show={true} />}
      <div className=''>
        <div className="quick-add-header">
          <Typography>Add {title} from LinkedIn</Typography>
          <CloseIcon cursor='pointer' onClick={handleCancel} />
        </div>
        <div className={'quick-add-container'}>
          <div className='d-flex flex-column h-100'>
            <div className='quick-add-content flex-row d-flex'>
              <div>
                <Typography color="error">You can add upto 5 {title} in one request. Create new request to add more.</Typography>
                {contacts.map((ele, i) => {
                  return singleLinkedinContact(ele, i)
                })}
                {contacts.length < 5 ? <Button
                  className="mb-2"
                  variant={"text"}
                  startIcon={<AddCircleIcon />}
                  onClick={addContact}
                  color='primary'>
                  Add New {title}
                </Button> : null}
              </div>
            </div>
            <div className="quick-add-footer-linkedin">
              <Button variant="text" color="primary" className="button" onClick={handleCancel}>Cancel</Button>
              <Button
                variant="contained"
                onClick={handleSubmit(handleAdd)}
                className="button"
                color="primary"
                type="submit"
              >
                Proceed
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Popup>
  )
}

AddLinkedinPopup.propTypes = {
  open: PropTypes.bool,
  addToWorkbench: PropTypes.func,
  handleClose: PropTypes.func,
  type: PropTypes.string,
  title: PropTypes.string,
  searchId: PropTypes.string,
  isCandidate: PropTypes.bool
}
