//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useState, useEffect } from 'react'

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from "prop-types";
import Popup from "reactjs-popup";
import CloseIcon from '@material-ui/icons/Close'
import { Button } from '@material-ui/core';
import { InputLabel, Typography } from '@material-ui/core'
import { KeyboardDatePicker } from "@material-ui/pickers";
import { formatDate, formatTime } from '../../../../../utils/date';
import _ from 'lodash';
import './index.scss'
//----------------------------------------------// Internal Imports // -------------------------------------------------

// import InputField from '../../../../common/InputField'
import { ERROR, INVOICE_VALIDATION } from '../../../../../services/constantService';
import { requireMessage } from '../../../../../services/MessageService';
import ConfirmationPopup from '../../../../ConfirmationPopup';
import RichTextPopup from '../../../../RichTextPopup';
import { Checkbox, FormControl, FormControlLabel } from "@material-ui/core";

export default function ConfirmProjectedDatePopup(props) {
  const { onClose, projectedData, invoiceList, updateField, setInvoiceList, setValue, watch, currentValues, enqueueSnackbar } = props;
  const [isConfirmPopup, setConfirmPopup] = useState(false);
  const [isValueChanged, setValueChanged] = useState(false);

  const handleClose = () => {
    if (projectedData.data.comments !== watch('invoice_info_searches')[projectedData.rowIndex].comments || projectedData.data.projected_bill_date !== watch('invoice_info_searches')[projectedData.rowIndex].projected_bill_date || projectedData.data.ready_to_bill !== watch('invoice_info_searches')[projectedData.rowIndex].ready_to_bill)
      setConfirmPopup(true);
    else
      onClose && onClose()
  }
  const handleConfirmPopup = () => {
    onClose && onClose()
  }

  const handleCancelPopup = () => {
    setConfirmPopup(false)
  }

  useEffect(() => {
    if (currentValues) {
      setInvoiceList(currentValues['invoice_info_searches'] || [{}])
    }
  }, [currentValues])

  useEffect(() => {
    setValue('invoice_info_searches', invoiceList)
  }, [invoiceList, setValue])

  const onSave = async () => {
    if (!watch('invoice_info_searches')[projectedData.rowIndex].projected_bill_date) {
      const message = requireMessage('Projected Date', 'is')
      enqueueSnackbar(message, { variant: ERROR })
      return
    }
    if (!watch('invoice_info_searches')[projectedData.rowIndex].comments && watch('invoice_info_searches')[projectedData.rowIndex].ready_to_bill) {
      const message = requireMessage('Comments', 'are')
      enqueueSnackbar(message, { variant: ERROR })
      return
    }
    if (projectedData.data.projected_bill_date !== watch('invoice_info_searches')[projectedData.rowIndex].projected_bill_date || projectedData.data.comments !== watch('invoice_info_searches')[projectedData.rowIndex].comments || projectedData.data.ready_to_bill !== watch('invoice_info_searches')[projectedData.rowIndex].ready_to_bill) {
      let res = await updateField(projectedData.rowIndex, 'updating record', 'update');
      if (res) {
        onClose && onClose()
      }
    } else {
      // setValueChanged(true);
      onClose && onClose()
    }
    // if (!watch('invoice_info_searches')[projectedData.rowIndex].comments) {
    //   const message = requireMessage('Comment', 'is')
    //   enqueueSnackbar(message, { variant: ERROR })
    //   return
    // }

  }

  const handleCommentsChange = (data) => {
    if (data.comments === '<p><br /></p>') {
      data.comments = '';
    }
    let clonedObject = _.cloneDeep(invoiceList);
    clonedObject[projectedData.rowIndex].comments = data.comments;
    setInvoiceList(clonedObject);
    setValueChanged(true);
  };

  const handleReadyToBilledChange = (e) => {
    let clonedObject = _.cloneDeep(invoiceList);
    clonedObject[projectedData.rowIndex].ready_to_bill = e;
    setInvoiceList(clonedObject);
    setValueChanged(true);
  }

  const handleDateChange = (date) => {
    let clonedObject = _.cloneDeep(invoiceList);
    clonedObject[projectedData.rowIndex].projected_bill_date = formatDate(date);
    setInvoiceList(clonedObject);
    setValueChanged(true);
  }

  return (
    <Popup overlayStyle={{ zIndex: '1401 !important' }} className="confirmation-projectDate confirmation-projectDate-popup" open={true} modal closeOnDocumentClick={false} closeOnEscape={false} >
      <>
        {isConfirmPopup ? <ConfirmationPopup
          header={INVOICE_VALIDATION.UNSAVED_DATA}
          onConfirm={handleConfirmPopup}
          onCancel={handleCancelPopup}
          onClose={handleCancelPopup}
          cancelText='No'
          confirmText='Yes'
        /> : null}
        <div className="" style={{ background: '#2a364c', color: '#fff' }}>
          <div className="quick-add-header">
            <Typography className='text-left'>Projected Bill Date</Typography>
            <CloseIcon className="cursor-pointer" onClick={handleClose} />
          </div>
        </div>
        <div className='d-flex flex-column py-3'>
          <div className='d-flex contact-details-row'>
            <div className='d-flex intersection-page-view'>
              <InputLabel className="page-label">Projected Bill Date<span className="MuiInputLabel-asterisk"> *</span></InputLabel>
              <div className='page-label-value'>
                <KeyboardDatePicker
                  format='MM/dd/yyyy'
                  value={formatDate(watch('invoice_info_searches')[projectedData.rowIndex].projected_bill_date || '') || null}
                  autoOk
                  className='input-form-field padding-remove'
                  InputLabelProps={{ focused: true }}
                  label={''}
                  placeholder={'Projected Date'}
                  inputVariant="outlined"
                  onChange={(e) => handleDateChange(e)}
                />
              </div>
            </div>
            <div className='d-flex intersection-page-view'>
              <InputLabel className="page-label">Ready To Bill</InputLabel>
              <div className='page-label-value text-left'>
                <FormControl className='w-auto' style={{ paddingLeft: '12px' }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size='small'
                        checked={watch('invoice_info_searches')[projectedData.rowIndex].ready_to_bill}
                        onChange={(e) => {
                          handleReadyToBilledChange(e.target.checked)
                        }}
                      />
                    }
                    label=""
                    style={{ color: "black" }}
                  />
                </FormControl>

              </div>
            </div>
          </div>
          <div className='d-flex contact-details-row'>
            <div className='d-flex intersection-page-view'>
              <InputLabel className="page-label">Comments</InputLabel>{watch('invoice_info_searches')[projectedData.rowIndex].ready_to_bill && <span className="MuiInputLabel-asterisk"> *</span>}
              <div className='page-label-value' style={{ minHeight: '45px', maxHeight: "fit-content" }}>
                {/* <InputField
                  value={watch('invoice_info_searches')[projectedData.rowIndex].comments || ''}
                  ref={register}
                  className='input-form-field'
                  label=''
                  placeholder='Comments'
                  onChange={(e) => {
                    handleCommentsChange(e)
                  }}
                  InputLabelProps={{ focused: true }}
                  name='comments'
                  variant="outlined"
                  required={true}
                /> */}
                <div className='d-flex contact-view-value'>
                  <div className='outlined-box rich-text-field transform ' style={{ width: '100%' }}>
                    <RichTextPopup
                      className="input-form-field text-left"
                      label="Comments"
                      placeholder='Comments'
                      title="Comments"
                      InputLabelProps={{ focused: true }}
                      name='comments'
                      variant="outlined"
                      value={watch('invoice_info_searches')[projectedData.rowIndex].comments || ''}
                      onSave={(data) => handleCommentsChange(data)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='text-left'> <p className='footer-text pl-2'> Last updated by <span style={{ fontWeight: 900 }}>{projectedData?.data?.user ? projectedData?.data?.user?.first_name + ' ' + projectedData?.data?.user?.last_name : ''}</span> On {formatDate(projectedData.data.updated_at ? projectedData.data.updated_at : projectedData.data.created_at) || ''} , {formatTime(projectedData.data.updated_at) || ''}</p></div>
        <div className="page-content-footer">
          <Button variant="contained" type="submit" className="page-btn-save" disabled={!isValueChanged} onClick={onSave} >Confirm & Save</Button>

        </div>
      </>
    </Popup>
  )
}

ConfirmProjectedDatePopup.propTypes = {
  onClose: PropTypes.func,
  projectedData: PropTypes.object,
  invoiceList: PropTypes.object,
  updateField: PropTypes.func,
  setInvoiceList: PropTypes.func,
  register: PropTypes.func,
  setValue: PropTypes.func,
  currentValues: PropTypes.object,
  watch: PropTypes.func,
  enqueueSnackbar: PropTypes.func
}
