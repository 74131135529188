//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react'

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { TextField, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, InputLabel, Switch, FormControlLabel } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NumberFormat from 'react-number-format';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import InputField from '../../../../common/InputField';
import CurrencySelection from '../../../../CurrencySelection';
import FeetypeSelection from '../../../../FeetypeSelection';
import { currencyWithCommas, getCurrencyPrefix, separator } from '../../../../../Containers/Commons/Utils';
import { DECIMAL_SEPARATOR, THOUSAND_SEPARATOR, BILLING_VALIDATIONS, FEE_TYPES } from '../../../../../services/constantService';
import TotalRevenue from '../../../../AddBD/BillingDetails/Components/EstimatedRevenue';
import PercentageOfBase from '../../../../AddBD/BillingDetails/Components/PercentageOfBase';
import IndirectFee from '../../../../AddBD/BillingDetails/Components/IndirectFee';
import TermsOfInvoiceSelection from '../../../../TermsOfInvoiceSelection';
import TotalRevenueView from '../../../../AddBD/BillingDetails/Components/EstimatedRevenue/TotalRevenueView';
import RevenueMarketSelection from '../../../../RevenueMarketSelection';
import { getFixedDecimal } from '../../../../../utils/common';

export default function FeeInformation(props) {
  const { register, watch, setValue, isEditing = true, currencyDetails, currentValues } = props;
  const [calculationType, setCalculationType] = useState('estimated');
  const [totalEstimate, setTotalEstimate] = useState(0)
  const fe_Cap_amount = watch('fee_cap_amount')
  const is_fee_cap = watch('is_fee_cap')
  // const FEE_TYPE = {
  //   PERCENT_33: '33%',
  //   OTHER_PERCENTAGE: "Other Percentage",
  //   FLAT_FEE: "Flat Fee"
  // }

  // const calculateFees = () => {
  //   const { fee_type } = getValues();

  //   if (fee_type) {
  //     if (fee_type === FEE_TYPE.PERCENT_33) {
  //       const percentage = '33.33';
  //       setValue("fee_percentage", percentage)
  //     }
  //   } else {
  //     setValue("fee_percentage", null);
  //   }
  // }

  useEffect(() => {
    register('fee_type')
    register('estimated_total_comp')
    register('actual_total_comp')
    register('estimated_fee_amount')
    register('currency');
    register('fee_amount');
    register('probability');
    register('bd_status')
    register('intervals_other')
    register('intervals')
    register('term_type')
    register('invoice_terms')
    register('invoice_terms_desc')
    register('actual_overridden_revenue')
    register('actual_percentage_bases')
    register('estimated_percentage_bases')
    register('estimated_revenue')
    register('actual_revenue')
    register('is_actual_overridden_revenue')
    register('actual_percentage_base')
    register('estimated_percentage_base')
    register('estimated_indirect_total_fee')
    register('actual_indirect_total_fee')
    register('fee_cap_amount')
    register('by_product_fee')
    register('old_actual_revenue')
  }, [register])

  useEffect(() => {
    if (watch('actual_percentage_bases')?.find(ele => ele?.type || ele?.actual_amt)) {
      setCalculationType('actual')
    } else {
      setCalculationType('estimated')
    }
  }, [watch('actual_percentage_bases')])

  const handleChange = (e) => {
    setValue(`${e.target.name}`, e.target.value)
  }

  const getTotalPercentageOfBase = (data, key, formattedValue) => {
    const currency = getCurrencyPrefix(watch('currency') || '')
    const percentage = data?.reduce((prev, curr) => {
      if (curr[key]) prev = prev + Number(curr[key])
      return prev;
    }, (0)) || 0;
    return formattedValue ? getValueWithCurrency(percentage) : { currency, number: percentage }
  }

  const getIndirectFees = (type) => {
    if (type === 'actual' && calculationType === 'estimated') return [];
    let estimatedIndirectFees = watch(`indirect_fees_searches`) || [];
    estimatedIndirectFees = estimatedIndirectFees.map(ele => {
      return {
        ...ele,
        calculation_amt: ele.calculation_type === 'Flat Amount' ? ele.calculation_amt : (getTotalPercentageOfBase(watch(`${type}_percentage_bases`), `calculated_${type}_amt`).number * ele.percentage) / 100
      }
    })
    return estimatedIndirectFees
  }

  const getEstimatedGrandTotal = () => {
    const totalPercentageOfBase = getTotalPercentageOfBase(watch('estimated_percentage_bases'), 'calculated_estimated_amt', false)
    const estimatedIndirectFees = getIndirectFees('estimated')
    const totalIndirectFee = getTotalPercentageOfBase(estimatedIndirectFees, 'calculation_amt')
    setValue('estimated_percentage_base', getFixedDecimal(totalPercentageOfBase.number))
    setValue('estimated_indirect_total_fee', getFixedDecimal(totalIndirectFee.number))
    setValue('estimated_revenue', getFixedDecimal(totalPercentageOfBase?.number + totalIndirectFee?.number))
    setTotalEstimate(getFixedDecimal(totalPercentageOfBase?.number + totalIndirectFee?.number))
    return getValueWithCurrency(totalPercentageOfBase?.number + totalIndirectFee?.number);
  }

  const getActualGrandTotal = () => {
    const totalPercentageOfBase = getTotalPercentageOfBase(watch('actual_percentage_bases'), 'calculated_actual_amt', false)
    const actualIndirectFees = getIndirectFees('actual')
    const totalIndirectFee = getTotalPercentageOfBase(actualIndirectFees, 'calculation_amt')
    setValue('old_actual_revenue', getFixedDecimal(currentValues.actual_revenue))
    setValue('actual_percentage_base', getFixedDecimal(totalPercentageOfBase.number))
    setValue('actual_indirect_total_fee', getFixedDecimal(totalIndirectFee.number))
    setValue('actual_revenue', getFixedDecimal(totalPercentageOfBase?.number + totalIndirectFee?.number))
    return getValueWithCurrency(totalPercentageOfBase?.number + totalIndirectFee?.number);
  }

  const getTotalIndirectFee = (type) => {
    return getValueWithCurrency(currentValues[`${type}_indirect_total_fee`] || 0)
  }
  const getRevenue = (type) => {
    return getValueWithCurrency(currentValues[`${type}_revenue`] || 0)
  }
  const getTotalPercentageOfBaseAdd = (type) => {
    setTotalEstimate(currentValues[`${type}_percentage_base`] || 0)
    return getValueWithCurrency(currentValues[`${type}_percentage_base`] || 0);
  }
  const getValueWithCurrency = (data) => {
    return `${getCurrencyPrefix(watch('currency') || '')} ${currencyWithCommas(getFixedDecimal(data || 0).toFixed(2))}`
  }

  return (

    <ExpansionPanel defaultExpanded={true} className="panel-bottom-margin" style={{ background: '#fff', color: '#fff' }}>
      <ExpansionPanelSummary
        expandIcon={
          <ExpandMoreIcon style={{ color: "#fff" }} />
        }
        aria-controls="panel1a-content"
        style={{ background: '#2a364c' }}
      >
        <div >Fee & Admin Info</div>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className='d-flex flex-column'>
        <div className="d-flex contact-details-row ">
          <div className='d-flex intersection-page-view'>
            <InputLabel required className='page-label'>Currency</InputLabel>
            <div className='page-label-value'>
              <CurrencySelection
                customInput={TextField}
                required={true}
                value={watch('currency') || null}
                className="input-form-field"
                disabled={!isEditing}
                InputLabelProps={{ focused: true }}
                onChange={(e, data) => {
                  setValue("currency", data ? data.code : null);
                }}
                label=""
                variant="outlined"
              // style={{
              //   paddingRight: "0px !important"
              // }}
              />
            </div>
          </div>
          <div className='d-flex intersection-page-view'>
            <InputLabel className='page-label'>By Product Fee</InputLabel>
            <div className='page-label-value'>
              <InputField
                ref={register}
                value={watch('by_product_fee') || ''}
                flag="true"
                inputProps={{ min: 0, max: 100 }}  
                error={watch('by_product_fee') > 100}
                onChange={(e) => { handleChange(e); }}
                className="input-form-field"
                variant="outlined"
                label="" type="number" InputLabelProps={{ focused: true }} name="by_product_fee"
              />
              {/* <NumberFormat
                customInput={TextField}
                label=""
                variant='outlined'
                placeholder="By Product Fee"
                InputLabelProps={{ focused: true }}
                className='input-form-field'
                value={watch("by_product_fee")}
                decimalScale={2}
                isNumericString={true}
                displayType={isEditing ? 'input' : 'text'}
                inputProps={{ min: 0, max: 100 }}  
                error={watch('by_product_fee') > 100}
                onValueChange={(values) => {
                  const { value } = values;
                  setValue('by_product_fee', value ? value : '--')
                }}
              /> */}
            </div>
          </div>
          {/* <div className='d-flex intersection-page-view'>
            <InputLabel className='page-label'>Projected Fee</InputLabel>
            <div className='page-label-value'>
              <NumberFormat
                customInput={TextField}
                label=""
                variant='outlined'
                placeholder='Projected Fee'

                InputLabelProps={{ focused: true }}
                className='input-form-field'
                value={watch("projected_fee")}
                thousandSeparator={separator(watch('currency'), THOUSAND_SEPARATOR)}
                decimalSeparator={separator(watch('currency'), DECIMAL_SEPARATOR)}
                decimalScale={2}
                isNumericString={true}
                displayType={isEditing ? 'input' : 'text'}
                prefix={currencyDetails ? (currencyDetails.prefix ? `${currencyDetails.currency_icon} ` : '') : ''}
                suffix={currencyDetails ? (currencyDetails.prefix === false ? ` ${currencyDetails.currency_icon}` : '') : ''}
                onValueChange={(values) => {
                  const { value } = values;
                  setValue('projected_fee', value ? value : '--')
                }}
              />
            </div>
            <NumberFormat
            customInput={TextField}
            label="Estimated Total Compensation"
            placeholder='Estimated Total Compensation'
            fullWidth='true'
            // style={{
            //   width: "450px"
            // }}
            InputLabelProps={{ focused: true }}
            // className='input-form-field'
            value={watch("estimated_total_comp")}
            thousandSeparator={separator(watch('currency'), THOUSAND_SEPARATOR)}
            decimalSeparator={separator(watch('currency'), DECIMAL_SEPARATOR)}
            decimalScale={2}
            isNumericString={true}
            displayType={isEditing ? 'input' : 'text'}
            prefix={currencyDetails ? (currencyDetails.prefix ? currencyDetails.currency_icon : '') : ''}
            suffix={currencyDetails ? (currencyDetails.prefix === false ? currencyDetails.currency_icon : '') : ''}
            onValueChange={(values) => {
              const { value } = values;
              setValue('estimated_total_comp', value ? value : null)
            }}
          />
          </div> */}
        </div>
        <div className="d-flex contact-details-row ">
          <div className='d-flex intersection-page-view'>
            <InputLabel className='page-label'>Fee Cap</InputLabel>
            {/* <FormControl disabled={!isEditing} className="input-form-field d-flex align-items-end flex-row" style={{ marginTop: 28 }}>
                  <Checkbox size='small' checked={watch('is_fee_cap') || false} onChange={(e) => { setValue('is_fee_cap', e.target.checked); }} className="p-0" color="primary" name="is_fee_cap" inputRef={register('is_fee_cap')} />
                  <FormLabel focused={true} className="input-label m-0" >Fee Cap</FormLabel>
                </FormControl> */}
            <div className='page-label-value'>
              <FormControlLabel
                className="input-form-field checkbox-width"
                style={{ color: '#5c52c6', margin: '10px 0 0', justifyContent: 'flex-end' }}
                control={<Switch checked={watch('is_fee_cap') || false} onChange={(e) => { setValue('fee_cap_amount', null); setValue('is_fee_cap', e.target.checked); }} color='primary' inputRef={register('is_fee_cap')} name={'is_po_direct'} />}
                label=""
                labelPlacement="start"
              />
            </div>
            {/* <Switch
                  checked={watch('is_fee_cap') || false}
                  onChange={(e) => {
                    setValue('is_fee_cap', e.target.checked);
                  }}
                  color='primary'
                  inputRef={register}
                  name={'is_fee_cap'}
                /> */}
          </div>
          {watch('is_fee_cap') ?
            <div className='d-flex intersection-page-view'>
              <InputLabel className='page-label'>Fee Cap Amount</InputLabel>
              <div className='page-label-value'>
                <NumberFormat
                  customInput={TextField}
                  label=""
                  variant='outlined'
                  placeholder='Fee Cap Amount'
                  InputLabelProps={{ focused: true }}
                  className='input-form-field'
                  value={watch("fee_cap_amount")}
                  thousandSeparator={separator(watch('currency'), THOUSAND_SEPARATOR)}
                  decimalSeparator={separator(watch('currency'), DECIMAL_SEPARATOR)}
                  decimalScale={2}
                  isNumericString={true}
                  displayType={isEditing ? 'input' : 'text'}
                  prefix={currencyDetails ? (currencyDetails.prefix ? `${currencyDetails.currency_icon} ` : '') : ''}
                  suffix={currencyDetails ? (currencyDetails.prefix === false ? ` ${currencyDetails.currency_icon}` : '') : ''}
                  onValueChange={(values) => {
                    const { value } = values;
                    setValue('fee_cap_amount', value ? value : '--')
                  }}
                />
              </div>
            </div> : <span className='billing-details'>&nbsp;</span>}
        </div>
        <div className="d-flex contact-details-row">
          <div className='d-flex intersection-page-view'>
            <InputLabel className='page-label'>Term Type</InputLabel>
            <div className='page-label-value'>
              <FeetypeSelection
                variant="outlined"
                className="input-form-field"
                defaultValue={watch('term_desc') || null}
                ref={register(`term_desc`)}
                InputLabelProps={{ focused: true }}
                placeholder='Term Type'
                type='TERM_TYPE'
                onChange={(e, data) => {
                  setValue('intervals_desc', null)
                  setValue('intervals', null)
                  setValue('intervals_other', null)
                  setValue('term_type', data ? data.field_value : null)
                  setValue('term_desc', data ? data.short_desc : null)
                }}
                label=""
              />
            </div>
          </div>
          <div className='d-flex intersection-page-view'>
            <InputLabel className='page-label'>Intervals</InputLabel>
            <div className='page-label-value'>
              <FeetypeSelection
                variant="outlined"
                className="input-form-field"
                value={watch('intervals_desc') || null}
                ref={register(`intervals_desc`)}
                InputLabelProps={{ focused: true }}
                disabled={!watch('term_desc')}
                placeholder="Intervals"
                type={watch('term_desc') === 'Milestone' ? 'INTERVAL_MILE' : 'INTERVAL_TIME'}
                onChange={(e, data) => {
                  if (data?.field_value !== 'Other Interval') {
                    setValue('intervals_other', '');
                  }
                  setValue('intervals_desc', data ? data.short_desc : null)
                  setValue('intervals', data ? data.field_value : null)
                }}
                label=""
              />
            </div>
          </div>
        </div>
        {watch('intervals') === 'Other Interval' && <div className="d-flex contact-details-row ">
          <div className='d-flex intersection-page-view'>
            &nbsp;
          </div>
          <div className='d-flex intersection-page-view'>
            <InputLabel className='page-label'>Interval Other</InputLabel>
            <div className='page-label-value'>
              <InputField
                className="input-form-field"
                label=""
                value={watch("intervals_other")}
                placeholder="Interval Other"
                InputLabelProps={{ focused: true }}
                name="intervals_other"
                variant="outlined"
                onChange={(e) => setValue('intervals_other', e.target.value)}
              />
            </div>
          </div>
        </div>}
        <div className="d-flex contact-details-row ">
          {/* <FeetypeSelection
                  className="input-form-field"
                  value={watch('fee_type') || null}
                  InputLabelProps={{ focused: true }}
                  disabled={!isEditing}
                  onChange={(e, data) => {
                    setValue("fee_type", data ? data.name : null);
                    calculateFees();
                  }}
                /> */}
          
          <div className='d-flex intersection-page-view' style={{ flex: '0 0 50%' }}>
            <InputLabel required className="page-label">Geography</InputLabel>
            <div className='page-label-value'>
              <div className='input-form-field'>
                <RevenueMarketSelection
                  value={watch('markets') || null}
                  label=''
                  required={true}
                  ref={register(`markets`)}
                  variant='outlined'
                  className="team-info-field"
                  InputLabelProps={{ focused: true }}
                  onChange={(e, data) => {
                    setValue('markets', data ? data : '');
                  }}
                />
              </div>
            </div>
          </div>
          <div className='d-flex intersection-page-view'>
            <InputLabel className='page-label'>Terms of Invoice</InputLabel>
            <div className='page-label-value'>
              <TermsOfInvoiceSelection
                variant="outlined"
                className="input-form-field"
                //defaultValue={watch('invoice_terms_desc') || null}
                ref={register(`invoice_terms_desc`)}
                defaultValue={watch('invoice_terms_desc')}
                InputLabelProps={{ focused: true }}
                type='INVOICE_TERMS'
                onChange={(e, data) => {
                  setValue('invoice_terms', data ? data.field_value : null)
                  setValue('invoice_terms_desc', data ? data.short_desc : null)
                }}
                label=""
              />
            </div>
          </div>
          {/* <div className='d-flex intersection-page-view'>
            <InputLabel className='page-label'>By Product Fee</InputLabel>
            <div className='page-label-value'>
              <InputField
                    ref={register}
                    disabled={!isEditing}
                    //value={watch('by_product_fee') || ''}
                    className="input-form-field"
                    label=""
                    placeholder="By Product Fee"
                    InputLabelProps={{ focused: true }}
                    type="number"
                    name="by_product_fee"
                    variant="outlined"
                  />
              <NumberFormat
                customInput={TextField}
                label=""
                variant='outlined'
                placeholder="By Product Fee"
                InputLabelProps={{ focused: true }}
                className='input-form-field'
                value={watch("by_product_fee")}
                thousandSeparator={separator(watch('currency'), THOUSAND_SEPARATOR)}
                decimalSeparator={separator(watch('currency'), DECIMAL_SEPARATOR)}
                decimalScale={2}
                isNumericString={true}
                displayType={isEditing ? 'input' : 'text'}
                prefix={currencyDetails ? (currencyDetails.prefix ? `${currencyDetails.currency_icon} ` : '') : ''}
                suffix={currencyDetails ? (currencyDetails.prefix === false ? ` ${currencyDetails.currency_icon}` : '') : ''}
                onValueChange={(values) => {
                  const { value } = values;
                  setValue('by_product_fee', value ? value : '--')
                }}
              />
            </div>
          </div> */}
        </div>
        <div className="d-flex contact-details-row">
          <div className='d-flex intersection-page-view' style={{ flex: '0 0 50%' }}>
            <InputLabel className="page-label">Fee Type</InputLabel>
            <div className='page-label-value'>
              <FeetypeSelection
                variant="outlined"
                className="input-form-field"
                defaultValue={watch('fee_type_desc') || watch('fee_type') || FEE_TYPES.ONE_THIRD}
                ref={register(`fee_type_desc`)}
                InputLabelProps={{ focused: true }}
                placeholder="Fee Type"
                type='FEE_TYPE'
                onChange={(e, data) => {
                  setValue("fee_type", data ? data.field_value : null);
                  setValue("fee_type_desc", data ? data.short_desc : null)
                  setValue('fee_percentage', (!data || data.short_desc === FEE_TYPES.ONE_THIRD) ? 33.33 : (!data || data.short_desc === FEE_TYPES.FIXED_FEE) ? 0 : null)
                }}
                label=""
              />
              {/* <SearchFeeTypeSelection
                variant="outlined"
                placeholder="Fee Type"
                className="input-form-field"
                value={watch('fee_type') || FEE_TYPES.ONE_THIRD}
                onChange={(e, data) => {
                  setValue("fee_type", data?.value ? data.value : FEE_TYPES.ONE_THIRD);
                  setValue('fee_percentage', (!data || data?.value === FEE_TYPES.ONE_THIRD) ? 33.33 : null)
                }}
                label=""
              /> */}
            </div>
          </div>
          <div className='d-flex intersection-page-view'>
            <InputLabel className='page-label'>Fee Percentage</InputLabel>
            <div className='page-label-value'>
              <InputField ref={register}
                value={watch('fee_percentage') || ''}
                flag="true"
                disabled={watch('fee_type_desc') !== FEE_TYPES.OTHER}
                inputProps={{ min: 0, max: 100 }}  
                error={watch('fee_percentage') > 100}
                onChange={(e) => { handleChange(e); }}
                className="input-form-field"
                variant="outlined"
                label="" type="number" InputLabelProps={{ focused: true }} name="fee_percentage" />
            </div>
          </div>
          
        </div>
        {/* <div className="d-flex contact-details-row ">
          <div className='d-flex intersection-page-view'>
            <InputLabel className='page-label'>Terms of Invoice</InputLabel>
            <div className='page-label-value'>
              <TermsOfInvoiceSelection
                variant="outlined"
                className="input-form-field"
                //defaultValue={watch('invoice_terms_desc') || null}
                ref={register(`invoice_terms_desc`)}
                defaultValue={watch('invoice_terms_desc')}
                InputLabelProps={{ focused: true }}
                type='INVOICE_TERMS'
                onChange={(e, data) => {
                  setValue('invoice_terms', data ? data.field_value : null)
                  setValue('invoice_terms_desc', data ? data.short_desc : null)
                }}
                label=""
              />
            </div>
          </div>
        </div> */}


        {/* <div className="d-flex flex-grow-1">
                <div className='contact-details'>
                  <NumberFormat
                    customInput={TextField}
                    label="Actual Total Compensation"
                    placeholder='Actual Total Compensation'
                    InputLabelProps={{ focused: true }}
                    value={watch("actual_total_comp")}
                    thousandSeparator={separator(watch('currency'), THOUSAND_SEPARATOR)}
                    decimalSeparator={separator(watch('currency'), DECIMAL_SEPARATOR)}
                    decimalScale={2}
                    isNumericString={true}
                    displayType={isEditing ? 'input' : 'text'}
                    prefix={currencyDetails ? (currencyDetails.prefix ? currencyDetails.currency_icon : '') : ''}
                    suffix={currencyDetails ? (currencyDetails.prefix === false ? currencyDetails.currency_icon : '') : ''}
                    onValueChange={(values) => {
                      const { value } = values;
                      setValue('actual_total_comp', value ? value : null)
                    }}
                  />
                </div>
                <div className='contact-details'>
                  <NumberFormat
                    className="input-form-field"
                    customInput={TextField}
                    label="Estimated Fee Amount"
                    placeholder='Estimated Fee Amount'
                    InputLabelProps={{ focused: true }}
                    value={watch("estimated_fee_amount")}
                    thousandSeparator={separator(watch('currency'), THOUSAND_SEPARATOR)}
                    decimalSeparator={separator(watch('currency'), DECIMAL_SEPARATOR)}
                    decimalScale={2}
                    isNumericString={true}
                    displayType={isEditing ? 'input' : 'text'}
                    prefix={currencyDetails ? (currencyDetails.prefix ? currencyDetails.currency_icon : '') : ''}
                    suffix={currencyDetails ? (currencyDetails.prefix === false ? currencyDetails.currency_icon : '') : ''}
                    onValueChange={(values) => {
                      const { value } = values;
                      setValue('estimated_fee_amount', value ? value : null)
                    }}
                  />
                </div>
              </div> */}

        {/* <div className="d-flex flex-grow-1">
                <div className='contact-details'>
                  <NumberFormat
                    customInput={TextField}
                    label="Actual Fee Amount"
                    placeholder='Actual Fee Amount'
                    InputLabelProps={{ focused: true }}
                    value={watch("fee_amount")}
                    thousandSeparator={separator(watch('currency'), THOUSAND_SEPARATOR)}
                    decimalSeparator={separator(watch('currency'), DECIMAL_SEPARATOR)}
                    decimalScale={2}
                    isNumericString={true}
                    displayType={isEditing ? 'input' : 'text'}
                    prefix={currencyDetails ? (currencyDetails.prefix ? currencyDetails.currency_icon : '') : ''}
                    suffix={currencyDetails ? (currencyDetails.prefix === false ? currencyDetails.currency_icon : '') : ''}
                    onValueChange={(values) => {
                      const { value } = values;
                      setValue('fee_amount', value)
                    }}
                  />
                </div>
                <div className='contact-details'>
                  <FormControl disabled={!isEditing} className="input-form-field d-flex align-items-end flex-row" style={{ marginTop: 28 }}>
                    <Checkbox size='small' checked={watch('milestones') || false} onChange={(e) => { setValue('milestones', e.target.checked); }} className="p-0" color="primary" name="milestones" inputRef={register('milestones')} />
                    <FormLabel focused={true} className="input-label m-0" >Milestones</FormLabel>
                  </FormControl>
                </div>
              </div> */}

        {/* <div className="d-flex flex-grow-1">
                <NumberFormat
                  customInput={TextField}
                  label="Projected Fee"
                  placeholder='Projected Fee'

                  InputLabelProps={{ focused: true }}
                  className='input-form-field'
                  value={watch("projected_fee")}
                  thousandSeparator={separator(watch('currency'), THOUSAND_SEPARATOR)}
                  decimalSeparator={separator(watch('currency'), DECIMAL_SEPARATOR)}
                  decimalScale={2}
                  isNumericString={true}
                  displayType={isEditing ? 'input' : 'text'}
                  prefix={currencyDetails ? (currencyDetails.prefix ? currencyDetails.currency_icon : '') : ''}
                  suffix={currencyDetails ? (currencyDetails.prefix === false ? currencyDetails.currency_icon : '') : ''}
                  onValueChange={(values) => {
                    const { value } = values;
                    setValue('projected_fee', value ? value : '--')
                  }}
                />
                <NumberFormat
                  className="input-form-field"
                  customInput={TextField}
                  label="Probability"
                  placeholder='Probability'
                  InputLabelProps={{ focused: true }}
                  value={watch("probability")}
                  decimalScale={2}
                  isNumericString={true}
                  displayType={isEditing ? 'input' : 'text'}
                  onValueChange={(values) => {
                    const { value } = values;
                    setValue('probability', value ? value : '--')
                  }}
                />
              </div> */}

        <div className='d-flex mt-2' style={{ color: '#000' }} >
          <div className='flex-grow-1 mr-3'>
            <PercentageOfBase
              register={register}
              setValue={setValue}
              currentValues={currentValues}
              watch={watch}
              fee_type={watch('fee_type')}
              type='estimated'
              getValueWithCurrency={getValueWithCurrency}
              disabled={currentValues.billing_status === 'ACK_DONE_EST' || currentValues.billing_status === 'PND_ACK' || currentValues.billing_status === 'ACK_DONE'}
            />
          </div>
          <div className='w-30'>
            {currentValues.billing_status === 'ACK_DONE_EST' || currentValues.billing_status === 'PND_ACK' || currentValues.billing_status === 'ACK_DONE' ? <TotalRevenueView
              getTotalIndirectFee={getTotalIndirectFee}
              type='estimated'
              getRevenue={getRevenue}
              getTotalPercentageOfBase={getTotalPercentageOfBaseAdd}
              billingStatus={currentValues.billing_desc}
            /> :
              <TotalRevenue
                watch={watch}
                getTotalPercentageOfBase={getTotalPercentageOfBase}
                getGrandTotal={getEstimatedGrandTotal}
                type='estimated'
                getIndirectFees={getIndirectFees}
              />}
            {
              is_fee_cap && Number(totalEstimate) > Number(fe_Cap_amount) && <div style={{ marginTop: "23px", marginLeft: "15px" }}>
                <span style={{ color: "red", marginTop: "20px" }}>{BILLING_VALIDATIONS.FEE_CAP_AMOUNT}</span>
              </div>
            }
          </div>
        </div>
        <div className='d-flex mt-2' style={{ color: '#000' }}>
          <div className='flex-grow-1 mr-3'>
            <PercentageOfBase
              register={register}
              setValue={setValue}
              currentValues={currentValues}
              watch={watch}
              fee_type={watch('fee_type')}
              type='actual'
              disabled={currentValues.stage === 'BD'}
              getValueWithCurrency={getValueWithCurrency}
            />
          </div>
          <div className='w-30'>
            {currentValues.stage === 'BD' ? <TotalRevenueView
              getTotalIndirectFee={getTotalIndirectFee}
              type='actual'
              getRevenue={getRevenue}
              getTotalPercentageOfBase={getTotalPercentageOfBaseAdd}
              billingStatus={currentValues.billing_desc}
            /> :
              <TotalRevenue
                watch={watch}
                getTotalPercentageOfBase={getTotalPercentageOfBase}
                getGrandTotal={getActualGrandTotal}
                type='actual'
                getIndirectFees={getIndirectFees}
                register={register}
                setValue={setValue}
                currencyDetails={currencyDetails}
                currentValues={currentValues}
              />}
          </div>
        </div>
        <div className='mt-2'>
          <IndirectFee
            register={register}
            setValue={setValue}
            currentValues={currentValues}
            watch={watch}
            getTotalPercentageOfBase={getTotalPercentageOfBase}
            type={calculationType}
            getIndirectFees={getIndirectFees}
            getValueWithCurrency={getValueWithCurrency}
          />
        </div>
        {/* <div className='mt-5 mx-5'>
            <IndirectFee
              register={register}
              setValue={setValue}
              currentValues={currentValues}
              watch={watch}
              getTotalPercentageOfBase={getTotalPercentageOfBase}
              type='actual'
            />
          </div> */}
      </ExpansionPanelDetails>
    </ExpansionPanel>

  )
}

FeeInformation.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  control: PropTypes.object,
  watch: PropTypes.func,
  getValues: PropTypes.func,
  isEditing: PropTypes.bool,
  currencyDetails: PropTypes.object,
  currentValues: PropTypes.object
}
