//-----------------------------------------------------------// In-built Imports // ------------------------------
import React, { useCallback, useEffect, useState } from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary, Typography, Card } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Helmet } from "react-helmet";
import { CloudUploadOutlined } from "@material-ui/icons";
import CloseIcon from '@material-ui/icons/Close'
import AddIcon from '@material-ui/icons/Add';
import { useSnackbar } from "notistack";
import { Button } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { saveAs } from 'file-saver';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { API, BLOB, DELETE, ERROR, GET, POST, PUT, SUCCESS, WARNING } from "../../services/constantService";
import { reportTemplateApi } from "../../services/ApiService";
import { successMessage, unableMessage, validateMessage } from "../../services/MessageService";
import { removeItemByIndex } from "../../components/ViewSearch/Components/SearchDocuments/Components/utils";
import MessageLoader from "../../components/common/MessageLoader/MessageLoader";
import './index.scss';
import ReportTypeSelection from "../../components/AddUser/components/ReportTypeSelection";
import ConfirmationPopup from "../../components/ConfirmationPopup";
import { useSelector } from "react-redux";

export default function ReportTemplate() {
  const { register, setValue, unregister } = useForm();
  const [list, setList] = useState([{}]);
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [uploadPercentage, setUploadPercentage] = React.useState(0);
  const [availableType, setAvailableType] = useState([]);
  const [isRemoved, setIsRemoved] = useState(false);
  const [removeIndex ,setRemoveIndex]=useState(null)
  const [removeItem ,setRemoveItem]=useState(null)

  const reportName = useSelector(state => state.commonReducer.report_name)

  useEffect(() => {
    register('report_name');
    return () => {
      unregister('report_name');
    }
  }, [register, unregister])

  useEffect(() => {
    setValue('report_template', list);
  }, [list, setValue])

  useEffect(() => {
    setValue('report_template', list);
  }, [list, setValue])

  useEffect(() => {
    setAvailableType(list.map(ele => ({ report_name: ele.report_name })) || [])
  }, [list])

  const getAttachments = async () => {
    try {
      let url = `${API.reportTemplate}/report-template`
      let { status, data } = await reportTemplateApi(GET, url);
      setIsLoading(true);
      if (status === 200) {
        setList(data)
        setIsRemoved(false)
      }
      else {
        const message = unableMessage("attachments", "fetch")
        enqueueSnackbar(data?.message || message, { variant: ERROR });
      }
      setIsLoading(false);
    }
    catch (e) {
      console.log("Error found in getAttachments::", e);
    }
  }

  useEffect(() => {
    getAttachments();
  }, [enqueueSnackbar])

  const downloadDocument = async (id, name) => {
    setIsLoading(true);
    setStatusMessage('Downloading Report Template');
    const sub_route = `${API.reportTemplate}/${id}/download`
    const { status, data } = await reportTemplateApi(GET, sub_route, {}, {}, BLOB, {}, {}, "download")
    if (status === 200) {
      saveAs(data, name)
    }
    else {
      const message = unableMessage(`${name}`, "download")
      enqueueSnackbar(data?.message || message, { variant: ERROR })
    }
    setIsLoading(false);
    setStatusMessage("");
  }

  const removeDocument = (index) => {
    setList(prevState => removeItemByIndex(prevState, index))
  }
  const deleteAttachment = async (index, id, name) => {
    setIsLoading(true);
    setStatusMessage('Deleting Report Template');
    const sub_route = `${API.reportTemplate}/${id}`
    const { status, data } = await reportTemplateApi(DELETE, sub_route)
    if (status === 200) {
      const message = successMessage(`Report Template(${name})`, "deleted")
      setList(prevState => removeItemByIndex(prevState, index))
      enqueueSnackbar(message, { variant: SUCCESS })
      setIsRemoved(false)
    }
    else {
      const message = unableMessage(`Report Template(${name})`, "delete")
      enqueueSnackbar(data?.message || message, { variant: ERROR })
    }
    getAttachments()
    setIsLoading(false);
  }

  const isFileUploadInProgress = useCallback(() => {
    return uploadPercentage !== 0 && uploadPercentage !== 100;
  }, [uploadPercentage])

  const disallowFileType = (fileName, enqueueSnackbar, list, index) => {
    let extension = fileName && fileName.split(".").pop();
    let report = list && list[index].report_name;
    if (extension.toLowerCase() !== "docx") {
      enqueueSnackbar(
        `${report} does not support  ${extension} type`,
        { variant: ERROR }
      );
      return false;
    }
    return true;
  }

  const processUpload = (status, data, fileName) => {
    if (status === 200) {
      const message = successMessage(`${fileName}`, "uploaded")
      enqueueSnackbar(message, { variant: SUCCESS });
    } else {
      const message = unableMessage(`${fileName}`, "upload")
      enqueueSnackbar(data?.message || message, { variant: ERROR });
    }
    setIsLoading(false);
  }

  const handleUploadDocument = async (e, field, index, item) => {
    if (isFileUploadInProgress()) {
      const message = validateMessage("Report Template upload completes", "wait till")
      enqueueSnackbar(message, { variant: WARNING });
      return;
    }
    if (!list[index].report_name) {
      enqueueSnackbar("Please Select Report Name", { variant: ERROR })
      return true
    }
    if (!disallowFileType(e?.target?.value, enqueueSnackbar, list, index)) return
    if (!item.id) {
      let existingSearchDocuments = [...list];
      if (e.target.files.length > 0) {
        existingSearchDocuments[e.target.id][field] = e?.target?.files[0]
        list && list.filter((item) => item.file_name);
        // eslint-disable-next-line no-unused-vars
        const result = list && list.map(({ file_name, file_id, ...rest }) => ({ ...rest }));
        existingSearchDocuments[e.target.id] = { ...result[e.target.id], [field]: e?.target?.files[0] }
        setList(existingSearchDocuments);
      }
      const file = e?.target?.files[0]
      if (file && !isFileUploadInProgress()) {
        setIsLoading(true);
        setStatusMessage('Uploading Report Template');

        // eslint-disable-next-line no-unused-vars
        const options = {
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = Math.floor((loaded * 100) / total)
            if (percent < 100) {
              setUploadPercentage(percent)
            }
          },
          headers: { 'Content-Type': file.type, noAuthorization: true }
        }
        let bodyFormData = new FormData();
        bodyFormData.append('file', e?.target?.files[0]);
        bodyFormData.append('report_name', list[index].report_name)
        let url = `${API.reportTemplate}/add`
        const { status, data } = await reportTemplateApi(POST, url, bodyFormData);
        processUpload(status, data, file.name)
      }
    }
    else {
      let id = item.id
      let bodyFormData = new FormData();
      bodyFormData.append('file', e?.target?.files[0]);
      bodyFormData.append('report_name', list[index].report_name)
      bodyFormData.append('carbone_template_id', list[index].carbone_template_id)

      let url = `${API.reportTemplate}/${id}`;
      setIsLoading(true)
      setStatusMessage('Uploading Report Template');
      const { status, data } = await reportTemplateApi(PUT, url, bodyFormData, id, BLOB)
      if (status === 200) {
        enqueueSnackbar('Report Template Updated Successfully', { variant: SUCCESS })
      }
      else {
        const message = unableMessage("Report Template", "update")
        enqueueSnackbar(data.message || message, { variant: ERROR })
      }
      setIsLoading(false)
    }
    getAttachments()
  }

  const addRow = () => {
    const updatedList = [...list, {}];
    setList(updatedList);
  }

  const reportType = async (e, data, index, name, item) => {
    let Report = data.short_desc
    if (!item.id) {
      let existingSearchDocuments = [...list];
      if (data) {
        existingSearchDocuments[index] = {
          ...list[index], ["report_name"]: data.short_desc,
          ["allowed_extension"]: "docx"
        }
        setList(existingSearchDocuments);
        setValue('report_name', data.short_desc)
      }
      else {
        const updatedList = [...list];
        updatedList[index] = { ...list[index], [name]: data, ["report_name"]: data }
        setList(updatedList)
      }
    }
    else {
      if (list[index].report_name === Report) return;
      let id = item.id
      let payload = { id: item.id, file_name: item.file_name, report_name: Report }
      let url = `${API.reportTemplate}/${id}`;
      setIsLoading(true)
      setStatusMessage('Updating Report Name');
      const { status, data } = await reportTemplateApi(PUT, url, payload, id)
      if (status === 200) {
        enqueueSnackbar('Report Name Updated Successfully', { variant: SUCCESS })
      }
      else {
        const message = unableMessage("Report Name", "update")
        enqueueSnackbar(data.message || message, { variant: ERROR })
      }
      setIsLoading(false)
      getAttachments();
    }
  }
  
  const removeOnConfirmation=(index, item)=>{
    setIsRemoved(true);
    setRemoveIndex(index)
    setRemoveItem(item)
  }

  const handleConfirmPopupClose = () => {
    setIsRemoved(false)
  }

  return (
    <div className="d-flex flex-column w-100 h-100">
      <MessageLoader show={isLoading} message={statusMessage} />
      <Helmet>
        <title>{"Report Template"}</title>
      </Helmet>
      {(isRemoved ) &&
        <ConfirmationPopup
          message="Are you sure you want to remove this report template?"
          onClose={handleConfirmPopupClose}
          onConfirm={() => {
            removeDocument();
            if (removeItem.id) {
              deleteAttachment(removeIndex, removeItem.id, removeItem.file_name).then(null)
            }}}
        />
      }
      <div className="d-flex justify-content-between align-items-end position-sticky search-header p-3 pl-4 border-bottom-0">
        <Typography className="job-title header-style">
          Report Template
        </Typography>
      </div>
      <Card style={{ boxShadow: 'none' }} >
        <ExpansionPanel defaultExpanded={true} className="w-100 pl-4 pr-4 my-class" style={{ background: '#fff', color: '#fff' }}>
          <ExpansionPanelSummary
            expandIcon={
              <ExpandMoreIcon style={{ color: "#fff" }} />
            }
            aria-controls="panel1a-content"
            style={{ background: '#2a364c' }}
          >
            <div >Upload & Attach Report Template</div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className='d-flex flex-column p-0'>

            <div className='section-content team-information-table flex-grow-1 mb-2 p-0'>
              <div className="client-info-table d-flex flex-column">
                <div className="d-flex align-items-center table-main-header">
                  <div className="col-1"></div>
                  <div className="col-3 team-info-field">Report Name</div>
                  <div className="col-2 team-info-field">Allowed Extensions</div>
                  <div className="col-3-5 team-info-field">Upload</div>
                  <div className="col-2 team-info-field">Action</div>
                </div>

                {list.length > 0 && list.map((item, index) =>
                  <div className='d-flex pt-1 doc-upload' key={index}>
                    <div className=" d-flex  col-1 align-items-center justify-content-center align-self-baseline" style={{ color: "black", whiteSpace: "normal" }}>{index + 1} </div>
                    <div className=" d-flex   col-3 align-items-center align-self-baseline" >
                      <ReportTypeSelection
                        selectedType={availableType}
                        variant="outlined"
                        className='add-search-input team-info-field'
                        InputLabelProps={{ focused: true }}
                        list={list}
                        value={item.report_name ? item.report_name : ""}
                        onChange={(e, data) => reportType(e, data, index, "report_name", item)}
                      /></div>
                    <div className=" d-flex  col-2 align-items-center justify-content-center align-self-baseline" style={{ color: "black", whiteSpace: "normal", wordBreak: 'break-word' }}>{item.report_name ? "docx" : "--"}
                    </div>
                    {item?.file_name ?
                      <div className=" d-flex  col-4 align-items-center justify-content-center align-self-baseline action-text company-redirect-link team-info-field" style={{ whiteSpace: "normal", wordBreak: 'break-word', cursor: 'pointer' }} onClick={() => { downloadDocument(item.id, item.file_name) }}> {item.file_name ? item.file_name : item?.files?.name ? item?.files?.name : '--'} </div>
                      : <div className=" d-flex  col-4 align-items-center justify-content-center align-self-baseline " style={{ color: "black" }}>{"--"}</div>}
                    <div className="col-2  d-flex align-items-center justify-content-center align-self-baseline">
                      <label htmlFor={index} className="mt-2">
                        <input type="file" name="report_template" id={index} style={{ display: 'none' }} onChange={(e) => { handleUploadDocument(e, 'files', index, item) }} />
                        <CloudUploadOutlined cursor='pointer'
                          fontSize='large' style={{ color: "black" }} /></label>

                      <div className="close-action" >
                        <span className="close-icon" >
                          <CloseIcon color='error' fontSize='large' onClick={() =>removeOnConfirmation(index,item)}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {list && list.length < reportName?.length ?
                <div className='text-start ml-2'> <Button
                  variant={"text"}
                  startIcon={<AddIcon style={{ color: "red" }} />}
                  onClick={() => addRow()}
                  className="button-text-capitalized"
                  style={{ width: "175px" }}
                >
                  <span style={{
                    fontWeight: "600",
                    fontSize: "13px",
                    fontStyle: "italic",
                  }}>Add File</span>
                </Button>
                </div> : ""}
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </Card>
    </div>)

}
ReportTemplate.propTypes = {}
