//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useState, useEffect } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withSnackbar } from 'notistack';
import { Checkbox, Grid } from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { formatDate } from '../../../../../../utils/date';
import Feedback from './Feedback';
import ClientSuiteInvitePopUp from '../../../../../CreateSearch/Components/SearchInformation/Components/ClientSuiteInvitePopUp'
import AthenaInvitePopUp from './AthenaInvitePopUp';
import { ERROR } from '../../../../../../services/constantService';
import { VALIDATION_MESSAGE } from '../../../../../../services/MessageService';


const useStyles = makeStyles({
  title: {
    fontSize: 12,
  },
  content: {
    fontSize: 14,
    marginTop: 1,
  },
  gridItem: {
    float: 'left',
    width: 500,
    marginLeft: 10,
    marginTop: 10,
    marginRight: 3,
    paddingLeft: 5,
    paddingTop: 5
  },
  sectionHeader: {
    fontSize: 14,
    color: 'primary'
  },
  hiringManager: {
    padding: 3,
    marginBottom: 3,
    fontSize: 14
  },
  athena: {
    fontSize: 12,
    float: "right"
  }
});

function ClientAppAccess(props) {
  const { register, setValue, currentValues, searchInfo, enqueueSnackbar, watch, setSfpaData, setCurrentValues, readOnly } = props;
  const classes = useStyles();
  const [open, toggleOpen] = useState(false)
  const [isAthenaInviteOpen, toggleAthenaInviteOpen] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [clientTeam, setClientTeam] = useState([{}])
  const [hasDetails, setHasDetails] = useState(false)
  const [hiringManagerIndex, setHiringManagerIndex] = useState(-1)
  const [prevManagerIndex, setPrevManagerIndex] = useState(-1)
  const [clientSuiteInviteClient, setClientSuiteInviteClient] = useState({})
  const [selectedDropDown, setSelectedDropDown] = useState('')

  useEffect(() => {
    const currentClientTeams = currentValues.client_team
    if (currentClientTeams && currentClientTeams[0] && Object.keys(currentClientTeams[0]).length > 0) {
      setHasDetails(true)
    }
    if (Array.isArray(currentClientTeams)) {
      for (let index = 0; index < currentClientTeams.length; index++) {
        if (currentClientTeams[index].is_hiring_manager) {
          setHiringManagerIndex(index)
          setPrevManagerIndex(index)
          break;
        }
      }
      setClientTeam(currentValues.client_team || [{}])
    }
    register('client_team')
  }, [register, currentValues.client_team])

  useEffect(() => {
    setValue('client_team', clientTeam);
  }, [clientTeam, setValue])

  const handleClick = (event, client) => {
    setClientSuiteInviteClient(client);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getName = (client) => {
    const firstName = client && client.first_name ? client.first_name : ''
    const lastName = client && client.last_name ? client.last_name : ''
    return firstName + ' ' + lastName
  }

  const handleHiringManagerChange = (index, isChecked) => {
    const currentClientTeam = [...clientTeam];
    if (prevManagerIndex !== -1) {
      currentClientTeam[prevManagerIndex].is_hiring_manager = false
    }
    currentClientTeam[index].is_hiring_manager = isChecked;
    setPrevManagerIndex(index)
    setClientTeam(currentClientTeam)
    isChecked ? setHiringManagerIndex(index) : setHiringManagerIndex(-1);
  }

  const openAthenaPopUp = (client, selectedDropDown) => {
    if (!client.contact?.work_email && !client.contact?.private_email) {
      enqueueSnackbar(VALIDATION_MESSAGE.athena_email, { variant: ERROR });
      return;
    }
    setAnchorEl(null);
    setSelectedDropDown(selectedDropDown);
    toggleAthenaInviteOpen(true);
  }

  return (
    <div style={{ padding: 20 }}>
      <Feedback readOnly={readOnly} setCurrentValues={setCurrentValues} setSfpaData={setSfpaData} searchInfo={searchInfo} currentValues={currentValues} setValue={setValue} register={register} watch={watch} />
      {hasDetails && clientTeam.map((client, index) => (
        <Card className={`${classes.gridItem} ${readOnly ? 'section-content read-only' : ''}`} key={client.id} variant="outlined" style={{ marginLeft: 0, marginBottom: 0, marginRight: 20, marginTop: 20 }}>
          <CardContent style={{ paddingBottom: '18px' }}>
            <Grid container spacing={3}>
              <Grid item xs={2}>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Client {index + 1}
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <Typography className={classes.athena} color="textSecondary" gutterBottom>
                  {client?.athena_completion_date ? <div><CheckCircleIcon htmlColor="green" style={{ fontSize: '16px', marginRight: '3px' }} />Athena completed on {formatDate(client.athena_completion_date)}</div> : <div>Athena completed on: -</div>}
                </Typography>
              </Grid>
            </Grid>
            <b>{getName(client)}</b><br />
            <Checkbox
              name="checkedB"
              color="primary"
              size="small"
              checked={hiringManagerIndex === index}
              className={classes.hiringManager}
              inputRef={register(`client_team[${index}]`)}
              onChange={(e) => {
                handleHiringManagerChange(index, e.target.checked)
              }}
            />
            <span className={classes.hiringManager}>Hiring Manager</span>
            <Grid container spacing={3} className={classes.content}>
              <Grid item xs={6}>
                <Typography color="textSecondary" className={classes.sectionHeader} gutterBottom>
                  Client Suite Login Details
                </Typography>
                <div>
                  <ul>
                    <li>First Logged in On: {client?.clientsuite_first_login_date ? formatDate(client.clientsuite_first_login_date) : '-'}</li>
                    <li>Last Log in On: {client?.clientsuite_last_login_date ? formatDate(client.clientsuite_last_login_date) : '-'}</li>
                    <li>Invite Sent On: {client?.clientsuite_invitation_sent_on ? formatDate(client.clientsuite_invitation_sent_on) : '-'}</li>
                  </ul>
                </div>
                <Button style={{ marginTop: '8px' }} size="small" variant="contained" color="primary" onClick={() => { toggleOpen(true); setClientSuiteInviteClient(client) }}>{`Send ${searchInfo.type === 'Inside Track' ? 'Arena' : 'Client Suite'} Invite`}</Button>
              </Grid>
              <Grid item xs={6}>
                <Typography color="textSecondary" className={classes.sectionHeader} gutterBottom>
                  Invite Details
                </Typography>
                <div>
                  <ul>
                    <li>Invite Type: {client?.athena_invitation_type ? client?.athena_invitation_type : '-'}</li>
                    <li>Date Sent: {client?.athena_invitation_sent_on ? formatDate(client.athena_invitation_sent_on) : '-'}</li>
                    <li>First Logged in On: {client?.first_athena_login ? formatDate(client.first_athena_login) : '-'}</li>
                  </ul>
                </div>
                <Button style={{ marginTop: '8px', backgroundColor: 'white', color: 'primary' }} size="small" aria-controls="simple-menu" aria-haspopup="true" onClick={(event) => handleClick(event, client)} variant="contained">Invites <ArrowDropDownIcon /></Button>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  {searchInfo.helia_version === 'Athena Questions Motive Pairs'  && <MenuItem onClick={() => { openAthenaPopUp(clientSuiteInviteClient, 'Athena') }}>Invite to Athena only</MenuItem>}
                  <MenuItem onClick={() => { openAthenaPopUp(clientSuiteInviteClient, 'Redo Attribute Selector') }}>Invite to Attribute Selector only</MenuItem>
                  {searchInfo.helia_version === 'Athena Questions Motive Pairs'  && <MenuItem onClick={() => { openAthenaPopUp(clientSuiteInviteClient, 'Athena,Redo Attribute Selector') }}>Invite to Athena & Attribute Selector</MenuItem>}
                </Menu>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ))
      }
      <div>
        {open ? <ClientSuiteInvitePopUp client={clientSuiteInviteClient} type={searchInfo?.type} onClose={() => toggleOpen(false)} /> : null}
        {isAthenaInviteOpen ? <AthenaInvitePopUp inviteFor={'client'} selectedDropDown={selectedDropDown} client={clientSuiteInviteClient} onClose={() => toggleAthenaInviteOpen(false)} /> : null}
      </div>
    </div>);
}

ClientAppAccess.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  currentValues: PropTypes.object,
  readOnly: PropTypes.bool,
  searchInfo: PropTypes.object,
  enqueueSnackbar: PropTypes.func,
  watch: PropTypes.func,
  setSfpaData: PropTypes.func,
  setCurrentValues: PropTypes.func
}

export default withSnackbar(ClientAppAccess);