//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useState, useEffect } from 'react';
import * as yup from "yup";


//----------------------------------------------// External Imports // -------------------------------------------------
import { useForm } from "react-hook-form";
import { useHistory, useParams } from 'react-router-dom'
import { withSnackbar } from "notistack";
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";

//----------------------------------------------// Internal Imports // -------------------------------------------------
import SearchDocuments from "./Components/SearchDocuments";
import KGPTeamInfo from './Components/KGPTeamInfo';
import { cleanupSearchData, formSchema, defaultValues, updateContactDetails, getBillingContactDetails } from "./utils";
import { checkForError, validateByProductFeeField, validateCalculationAmount, validatePercentageFields } from '../ViewSearch/utils'
import MessageLoader from "../common/MessageLoader/MessageLoader";
import ConfirmationPopup from '../ConfirmationPopup';
import TabLayoutWithHeader from '../TabLayoutWithHeader'
import SearchInformation from "./Components/SearchInformation";
import BillingInfo from './Components/BillingInfo';
import { fetchCurrencyDetails, fetchUserList } from "../../../src/actions";
import { notesAttachmentDocumentApi, searchDataApi, dashboardDataApi } from '../../services/ApiService';
import { API, POST, GET, SUCCESS, ERROR, EMAIL_REGEX, SPECIAL_CHARACTERS_REPLACE_REGEX, REPLACE_SPACE_REGEX, BILLING_VALIDATIONS } from '../../services/constantService';
import { unableMessage, VALIDATION_MESSAGE, validateMessage, successMessage } from '../../services/MessageService';
import { customFormValidator, getInvoiceEmail, handelScroll, requireValidMessage } from '../../utils/common';
import { searchActions } from '../../store/searchSlice';
function CreateSearch(props) {

  const history = useHistory()
  const { currentTabs } = useParams();
  const [statusMessage, setStatusMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar } = props
  const [currentValues, setCurrentValues] = useState({ ...defaultValues })
  const { register, setValue, watch, control, getValues, handleSubmit, reset, unregister } = useForm({ defaultValues })
  const users = useSelector(state => state.rootReducer.users)
  const dispatch = useDispatch()
  const [options, setOptions] = useState([]);
  const [renderActionFlag, setRenderActionFlag] = useState(false);
  const [optionValid, setOptionsValid] = useState([]);
  const NAV_ITEMS = ["Search Information", "Billing Info", "KG Team Info", "Search Documents"];
  const newNavItems = NAV_ITEMS.map((item) => {
    item = item.replace(SPECIAL_CHARACTERS_REPLACE_REGEX, 'and');
    return item.toLowerCase().replace(REPLACE_SPACE_REGEX, '-')
  })
  let currentTabIndex = newNavItems.findIndex(item => item === currentTabs);
  currentTabIndex = currentTabIndex === -1 ? history.push(`/searches/create/${newNavItems[0]}`) : currentTabIndex;
  const tabChange = () => {
    const data = getValues({ nest: true })
    const formData = { ...currentValues, ...data }
    //if (currentTabIndex === 0) {
    if (formData.basic_partners) {
      if (formData.basic_partners.length) {
        if (formData.partners) {
          const result = formData.partners.filter(
            obj => !(obj && Object.keys(obj).length === 0)
          );
          if (result.length) {
            const FilterBasicData = formData.basic_partners.filter(item => result.every(elem => elem?.user?.id !== item?.id))
            const resultData = result.filter((item1) => formData.basic_partners.some((item2) => item2?.id === item1?.user?.id));
            const dataFilter = [...resultData, ...FilterBasicData.map((item) => {
              return {
                user: item
              }
            })]
            dispatch(searchActions.basicPartnerData(dataFilter))
          } else {
            dispatch(searchActions.basicPartnerData(formData.basic_partners.map((item) => {
              return {
                user: item
              }
            })))
          }
        } else {
          dispatch(searchActions.basicPartnerData(formData.basic_partners.map((item) => {
            return {
              user: item
            }
          })))
        }
      } else {
        dispatch(searchActions.basicPartnerData(formData.partners))
      }
    }
    // } 
    if (currentTabIndex === 2) {
      if (formData.partners) {
        if (formData.partners.length) {
          let exists = formData.partners.filter(obj => Object.keys(obj).includes("user"));
          if (exists && exists.length) {
            const filterData = exists.filter(item => item?.user !== null)
            if (filterData && filterData.length) {
              dispatch(searchActions.partners(filterData))
            } else {
              dispatch(searchActions.partners([{}]))
            }
          } else {
            dispatch(searchActions.partners([{}]))
          }
        } else {
          dispatch(searchActions.partners([{}]))
        }
      }
    }
    reset({ ...currentValues, ...data })
    setCurrentValues(prevState => ({ ...prevState, ...getValues({ nest: true }) }))
    return true
  }
  const currencyDetails = useSelector(state => state.commonReducer[watch('currency')] ? state.commonReducer[watch('currency')] : {})
  useEffect(() => {
    const currencyHandler = async () => {
      try {
        await fetchCurrencyDetails(watch('currency'), dispatch)
      } catch (e) {
        console.log("Error found in currencyHandler::", e);
      }
    }
    if (watch('currency') && Object.keys(currencyDetails).length === 0) {
      currencyHandler()
    }

  }, [watch('currency')])

  const isDate = (sDate) => {
    if (sDate === undefined) {
      return
    }
    if (sDate.toString() == parseInt(sDate).toString()) return false;
    var tryDate = new Date(sDate);
    return (tryDate && tryDate.toString() != "NaN" && tryDate != "Invalid Date");
  }

  useEffect(() => {
    if (!users) {
      dispatch(fetchUserList('users'))
    }
    else {
      setOptions(users);
    }
  }, [users, dispatch])

  const processUpload = (status, data, fileName) => {
    if (status === 200) {
      const message = successMessage(`${fileName}`, "uploaded")
      enqueueSnackbar(message, { variant: SUCCESS });
    } else {
      const message = unableMessage(`${fileName}`, "upload")
      enqueueSnackbar(data?.message || message, { variant: ERROR });
    }
  }
  const handelNewTabChange = (newtabValue) => {
    if (newtabValue !== undefined || newtabValue !== null) {
      history.push(`/searches/create/${newNavItems[newtabValue]}`)
    }
  }
  const uploadDocuments = async (url, documents) => {
    if (Array.isArray(documents) && documents[0]?.files.name) {
      for (const document of documents) {
        let bodyFormData = new FormData();
        bodyFormData.append('file', document.files);
        bodyFormData.append('doctype_code', document.doctype_code)
        const { status, data } = await notesAttachmentDocumentApi(POST, url, bodyFormData);
        processUpload(status, data, document.files.name)
      }
    }
  }

  const getData = async () => {
    try {

      const url = `${API.product_setting}/document_type`
      const { status, data } = await dashboardDataApi(GET, url)
      if (status === 200) {
        let array = [];
        data.data.rows.map(data => array.push({ doctype_code: data.doctype_code, allowed_extension: data.allow_extensions }));
        setOptionsValid(array);
      }
    } catch (err) {
      console.log('error in Fetch Token::', err)
    }

  }

  useEffect(() => {
    getData()
  }, [])

  const SearchSchema = yup.object().shape({
    company: yup.object().required(),
    job_title: yup.string().required(),
    stage: yup.string().required(),
    type: yup.string().required(),
    industries: yup.array().of(yup.object().shape({
      name: yup.string(),
    })).required(),
    job_functions: yup.array().required(),
    country: yup.string().required(),
    brand: yup.string().required().min(1),
    basic_partners: yup.array().required().min(1),
    probability: yup.string().required(),
    currency: yup.string().required(),
    markets: yup.object().required(),
    billing_region: yup.string().required(),
    projected_start_date: yup.string().required(),
  });

  const cleanup = (searchData) => {
    if (searchData.partners && searchData.partners.some(item => item.user && item.user.name)) {
      const FilterPartnerData = searchData.partners.filter(item => {
        return searchData.basic_partners.every(elem => elem?.id !== item?.user?.id);
      });

      const filteredUsersWithNames = FilterPartnerData
        .filter(item => item.user && item.user.name)
        .map(item => item.user);

      searchData.basic_partners.push(...filteredUsersWithNames);
    }
    return searchData;
  }

  const createSearch = async (formData) => {
    try {
      formData = { ...currentValues, ...formData }
      // if (currentTabIndex === 0) {
      const isValid = await SearchSchema.isValid(formData)
      if (!isValid) {
        let requiredField = [
          { fieldName: "company", label: "Company Name", type: Object },
          { fieldName: "job_title", label: "Search Job Title", type: String },
          { fieldName: "stage", label: "Stage", type: String },
          { fieldName: "type", label: "Search Type", type: String },
          { fieldName: "industries", label: "Industries", type: [] },
          { fieldName: "job_functions", label: "Job Function", type: [] },
          { fieldName: "country", label: "Location", type: String },
          { fieldName: "brand", label: "Service Offering", type: String },
          { fieldName: "basic_partners", label: "Partners", type: [] },
          { fieldName: "probability", label: "Probability", type: String },
          { fieldName: "projected_start_date", label: "Projected Start Date", type: String },
          { fieldName: "currency", label: "Currency Code", type: String },
          { fieldName: "markets", label: "Geography", type: String },
          { fieldName: "billing_region", label: "Billing Region", type: String },
        ]
        let dirtyField = customFormValidator(formData, requiredField)
        if (dirtyField) {
          const message = requireValidMessage(dirtyField)
          enqueueSnackbar(message, { variant: ERROR })
          return
        }
      }
      if (formData?.email_invoice_to) { if (!getInvoiceEmail(formData, enqueueSnackbar)) return; }
      const isValidIndirectFee = validatePercentageFields(formData)
      if (!isValidIndirectFee) {
        enqueueSnackbar(BILLING_VALIDATIONS.FEE_PERCENTAGE_MAX, { variant: ERROR });
        return;
      }
      const isValidByProductFee = validateByProductFeeField(formData)
      if (!isValidByProductFee) {
        enqueueSnackbar(BILLING_VALIDATIONS.BY_PRODUCT_FEE_MAX, { variant: ERROR });
        return;
      }
      const isCalculationAmount = validateCalculationAmount(formData)
      if (!isCalculationAmount) {
        enqueueSnackbar(BILLING_VALIDATIONS.CALCULATION_AMOUNT_MAX, { variant: ERROR });
        return;
      }
      if (formData.estimated_percentage_bases && formData.estimated_percentage_bases.length) {
        const isValid = formData.estimated_percentage_bases.some(item => !item.type && item.estimated_amt)
        if (isValid) {
          enqueueSnackbar(BILLING_VALIDATIONS.FEE_TYPE, { variant: ERROR });
          return
        }
      }
      if (formData.actual_percentage_bases && formData.actual_percentage_bases.length) {
        const isValid = formData.actual_percentage_bases.some(item => !item.type && item.actual_amt)
        if (isValid) {
          enqueueSnackbar(BILLING_VALIDATIONS.FEE_TYPE, { variant: ERROR });
          return
        }
      }
      if (formData.indirect_fees_searches && formData.indirect_fees_searches) {
        const isValid = formData.indirect_fees_searches.some(item => !item.type && item.calculation_type)
        if (isValid) {
          enqueueSnackbar(BILLING_VALIDATIONS.INDIRECT_FEE, { variant: ERROR });
          return
        }
      }
      formData.search_documents = formData.search_documents.filter((item) => {
        if ((item.doc_desc === undefined || item.doc_desc === "" || item.doc_desc === null) && (item.doctype_code === undefined || item.doctype_code === "" || item.doctype_code === null) && (item.files === undefined || item.files === null || Array.isArray(item.files))) { return false }
        return true
      })
      if (formData && formData.search_documents && optionValid.length > 0) {
        formData.search_documents = [...formData.search_documents].map((ele) =>
          ({
            ...ele, required: (ele.doctype_code ? ele.doctype_code === '' : (ele[0] ? ele[0].doctype_code === '' : !ele.doctype_code)) && (ele && (!ele.files && !ele.file_id) || (ele && ele.files ? !ele?.files.name : ele.file_name === '')) ? 'Document type and Files are required'
              :
              (ele.doctype_code ? ele.doctype_code === '' : (ele[0] ? ele[0].doctype_code === '' : !ele.doctype_code)) && (ele && ele.files ? ele.files.name : ele.file_name !== '') ? "Document type is required"
                :
                ((ele.doctype_code ? ele.doctype_code !== '' : (ele[0] ? ele[0].doctype_code === '' : !ele.doctype_code)) && (ele && ele.files === undefined ? (ele.file_name ? ele.file_name === '' : !ele.file_name) : ele.files && ele.files.name ? ele.files.name === '' : !ele.files.name)) ? 'File is required'
                  :
                  ''
          }))
        formData.search_documents.forEach((item) => {
          if (item.required !== '') {
            enqueueSnackbar(item.required, { variant: ERROR });
          }

        })
        const inValid = formData && formData.search_documents.some((ele) => ele.required)
        if (inValid)
          return false;
        if (formData && formData.search_documents && optionValid.length > 0) {
          //  const filterDoc = formData.search_documents.filter(item => item && item.files);

          formData && formData.search_documents.forEach((ele) => {
            if (ele.allowed_extension !== 'all') {
              const findCat = optionValid.find((data) => data.doctype_code === ele.doctype_code);
              let fileName = ele.files ? ele.files.name.split('.').pop() : ele.file_name.split('.').pop();
              const isValid = findCat?.allowed_extension ? findCat.allowed_extension.split(',').map(item => item.trim()).includes(fileName.toLowerCase()) : '';
              ele['errorMessage'] = isValid ? '' : `${ele.doc_desc} does not support  ${fileName} type`;
            }
          })
          const searchDoc = formData.search_documents.filter((item) => item.errorMessage);
          searchDoc.forEach((item) => {
            if (item.errorMessage !== '') {
              enqueueSnackbar(item.errorMessage, { variant: ERROR });

            }

          })
          if (searchDoc.length > 0) {
            return false;
          }
        }
      }
      let isError = false;
      if (formData.partners && formData.partners.length > 0) {
        const fields = {
          origination_credit: 30,
          selling_credit: 20,
          execution_credit: 50
        }
        isError = checkForError(formData.partners, fields);
      }
      if (!isError && formData.recruiters && formData.recruiters.length > 0) {
        const fields = {
          execution_credit: 100
        }
        isError = checkForError(formData.recruiters, fields);
      }
      if (!isError && formData.researchers && formData.researchers.length > 0) {
        const fields = {
          execution_credit: 100
        }
        isError = checkForError(formData.researchers, fields);
      }
      if (!isError && formData.eas && formData.eas.length > 0) {
        const fields = {
          execution_credit: 100
        }
        isError = checkForError(formData.eas, fields);
      }
      if (isError) {
        const message = validateMessage('valid number in KG Team Info tab', 'enter')
        enqueueSnackbar(message, { variant: ERROR });
      }
      else {
        if (formData?.job_title?.includes(`"`)) {
          enqueueSnackbar(VALIDATION_MESSAGE.special_character, { variant: ERROR });
        }
        else {
          const searchData = { ...currentValues, ...formData }
          const validateClientEmails = searchData.client_team ? (searchData.client_team.map(item => (item.work_email && !item.work_email.match(EMAIL_REGEX)) ? false : true)) : []
          const validateBillingWorkEmail = (searchData.work_email ? searchData.work_email.match(EMAIL_REGEX) : false)
          if (validateClientEmails.includes(false) || (searchData.work_email && !validateBillingWorkEmail)) {
            const message = validateMessage("valid email", "enter")
            enqueueSnackbar(message, { variant: ERROR });
            return
          }
          if (searchData.loe_date) {
            let check = isDate(searchData.loe_date)
            if (!check) {
              const message = validateMessage("valid date", "enter")
              enqueueSnackbar(message, { variant: ERROR });
              return
            }
          }
          setIsLoading(true)
          setStatusMessage('Creating Search')
          const valid = await formSchema.isValid(searchData);
          if (valid) {
            const body = cleanup({ ...searchData })
            const payload = cleanupSearchData({ ...body }, false, null, true)
            const { status, data: response } = await searchDataApi(POST, '', payload)
            if (status === 201) {
              const message = successMessage("Search", "created")
              enqueueSnackbar(message, { variant: SUCCESS })
              setStatusMessage('Updating contacts details')
              dispatch(searchActions.basicPartnerData())
              dispatch(searchActions.partners())
              await updateContactDetails([...searchData.client_team, getBillingContactDetails(searchData)], enqueueSnackbar)
              setStatusMessage("Uploading Search Documents")
              await uploadDocuments(`${API.searches}/${response.id}/documents`, searchData.search_documents)

              setIsLoading(false)
              history.push('/searches')
            } else {
              setIsLoading(false)
              const message = unableMessage("search", "create")
              enqueueSnackbar(response.message || message, { variant: ERROR })
            }
          }
          else {
            setIsLoading(false)
            const message = validateMessage("required fields", "fill all")
            enqueueSnackbar(message, { variant: ERROR })
          }
        }
      }
    }
    catch (e) {
      console.log("Error found in createSearch::", e);
    }
  }

  const onCreateFromBDConfirm = () => {
    history.push("/searches/pipeline-searches");
  }

  return (
    <React.Fragment>
      <ConfirmationPopup
        header={"Existing Business Development"}
        message="Do you want to convert the existing pipeline to searches?"
        onConfirm={onCreateFromBDConfirm}
      />
      <MessageLoader show={isLoading} message={statusMessage} />
      <Helmet>
        <title>Start a Search - KG Galaxy</title>
      </Helmet>
      <TabLayoutWithHeader
        header={{ label: "Start a Search", route: "/searches" }}
        navItems={[...NAV_ITEMS]}
        tabChange={tabChange}
        newTabChange={handelNewTabChange}
        currentTab={currentTabIndex}
        actions={[
          { label: "Cancel", className: "secondary-btn", onClick: () => { history.goBack() } },
          {
            label: "Create Search",
            className: "primary-btn",
            onClick: handleSubmit(createSearch)
          },
        ]}
      >
        <SearchInformation
          register={register}
          setValue={setValue}
          watch={watch}
          reset={reset}
          control={control}
          currentValues={currentValues}
          handelScroll={handelScroll}
          setRenderActionFlag={setRenderActionFlag}
          renderActionFlag={renderActionFlag}
          types="AddSearch"
          currentTabIndex={currentTabIndex}
        />
        <BillingInfo
          register={register}
          setValue={setValue}
          currencyDetails={currencyDetails}
          watch={watch}
          control={control}
          getValues={getValues}
          currentValues={currentValues}
          handelScroll={handelScroll}
        />
        <KGPTeamInfo
          option={options}
          register={register}
          setValue={setValue}
          watch={watch}
          control={control}
          currentValues={currentValues}
          handelScroll={handelScroll}
          types="AddSearch"
          currentTabIndex={currentTabIndex}
        />
        <SearchDocuments
          register={register}
          setValue={setValue}
          watch={watch}
          currentValues={currentValues}
          control={control}
          getValues={getValues}
          handelScroll={handelScroll}
          unregister={unregister}
        />
      </TabLayoutWithHeader>
    </React.Fragment>
  )
}

CreateSearch.propTypes = {
  enqueueSnackbar: PropTypes.func
}

export default withSnackbar(CreateSearch);
