//----------------------------------------------// In-built Imports // -------------------------------------------------
import React from 'react'

//----------------------------------------------// External Imports // -------------------------------------------------
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types'

//----------------------------------------------// Internal Imports // -------------------------------------------------
import HistoryItem from './HistoryItem';


export default function ActivityHistory(props) {
  const { currentValues = {} } = props;

  return (
    <div className="w-50 section-content">
      <Typography className="section-label">Last Activity</Typography>
      {currentValues.activities?.length ?
        <>
          {currentValues.activities.slice(0, 2).map(item => {
            return <HistoryItem details={item} key={item.id} />
          })}
        </>
        :
        '--'
      }
    </div>
  )
}

ActivityHistory.propTypes = {
  currentValues: PropTypes.object
}
