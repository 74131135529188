
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { Component } from 'react'

//-----------------------------------------------------------// External Imports // ------------------------------

import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types'
import { saveAs } from 'file-saver';
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-grid.css'
import { Helmet } from "react-helmet";
import NumberFormat from 'react-number-format'
import { withSnackbar } from 'notistack';
import { Button, Checkbox, Typography } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import CloseIcon from '@material-ui/icons/Close'

//-----------------------------------------------------------// Internal Imports // ------------------------------

import { RichTextPopupRendered } from "../../utils/common"
import RichTextBoxEditor from "../Commons/CellEditors/RichTextBoxEditor";
import CompanySearch from './CompanySearch'
import Query from './components/Query'
import GenericCellEditor from '../Commons/CellEditors/GenericCellEditor'
import ToggleSelection from '../Commons/CellEditors/ToggleSelection'
import DescriptionEditor from '../Commons/CellEditors/DescriptionEditor'
import PopupEditor from '../Commons/CellEditors/PopupEditor'
import PhoneEditor from '../Commons/CellEditors/PhoneEditor'
import StateEditor from '../Commons/CellEditors/StateEditor'
import NumberEditor from '../Commons/CellEditors/NumberEditor'
import { dataSourceUtils } from "../../utils/dataSource"
import {
  columnDefs,
  getQueryParamsString,
  SUCCESS_STATUS_CODE,
  PAGE_LIMIT,
  WebsiteRenderer,
  CustomLoadingOverlayComponent,
  cleanup,
  fieldDefinitions
} from './utils'
import {
  PhoneRenderer,
  DateRenderer,
  DateTimeRenderer,
  saveColumnStateToLocalStorage,
  loadColumnStateFromLocalStorage,
  getCurrencyPrefix,
  currencyValueSetter
} from '../Commons/Utils';
import '../Commons/index.scss'
import { defaultColumns, CompanyNameRenderer, getFilterParamString } from "./utils";
import { SCROLL_TIMEOUT } from "../../utils/common";
import ColumnFilter from "../Commons/ColumnFilter";
import ActionsPopover from "../Contacts/ActionsPopover";
import DeletePopup from "../Commons/DeletePopup";
import CustomFilter from "../Commons/CustomFilter";
import MergeCompanies from "./components/MergeCompanies";
import AdvancedQuery from '../Commons/AdvancedQuery'
import { EXPORT_LIMIT, API, BLOB, POST, ERROR, WARNING, DELETE_CONFIRMATION_POPUP_MESSAGE } from '../../services/constantService'
import Loader from '../../../src/components/common/Loader';
import { companyDataApi } from '../../services/ApiService'
import { exportMessage, VALIDATION_MESSAGE, notFoundMessage } from '../../services/MessageService'

class CompaniesList extends Component {
  constructor(props) {
    super(props)
    this.isSelectedFromList = false;
    this.state = {
      viewColumns: false,
      queryData: {},
      anchorEl: null,
      showQuery: false,
      rowCount: 0,
      minimizeQuery: true,
      isAllRowsSelected: false,
      selectedRowsCount: 0,
      uiSelector: false,
      masterQuery: '',
      queryRun: false,
      isAdvanceQuery: false,
      isClassicQuery: false,
      quickQuery: '',
      flag: true,
      searchValue: '',
      filterModel: {},
      isLoading: false,
      exportSingleValue: false,
      isRemove: false,
      advanceSelector: false,
      showFilterCount: 0,
      isQuerySelector: false
    }
  }

  componentWillUnmount() {
    const columnApi = this.columnApi
    saveColumnStateToLocalStorage('companyColumns', { columnApi })
  }

  getParams = (selectedRows) => {
    // const selectedRows = this.gridApi.getSelectedRows();
    // if (this.state.isAllRowsSelected || selectedRows.length === 0) {
    //   return selectedRows.map(row => `id=${row.id}`).join('&')
    //   // return getQueryParamsString(this.state.queryData).substring(1)
    // } else {
    //   return selectedRows.map(row => `id=${row.id}`).join('&');
    // }
    return selectedRows.map(row => `id=${row.id}`).join('&');
  }

  handleExport = async () => {
    const selectedRows = this.gridApi.getSelectedRows();
    if (selectedRows.length > 0) {
      if ((this.state.isAllRowsSelected && this.state.rowCount > EXPORT_LIMIT) || (selectedRows.length > EXPORT_LIMIT)) {
        const message = exportMessage(`${EXPORT_LIMIT}`, "Companies")
        this.props.enqueueSnackbar(message, { variant: ERROR })
      } else {
        let sub_route = `export-as-excel?limit=${EXPORT_LIMIT}`;
        if (this.state.quickQuery) {
          if (this.state.exportSingleValue) {
            sub_route = `${sub_route}&id=${this.state.quickQuery}&quickSearch=true`;
          } else {
            sub_route = `${sub_route}&searchValue=${this.state.quickQuery}&quickSearch=true`;
          }
        }
        else if (this.state.isClassicQuery) {
          const paramsString = getQueryParamsString(this.state.queryData)
          sub_route = `${sub_route}${paramsString}&classicSelector=${this.state.isClassicQuery}`;
        }
        if (Object.keys(this.state.filterModel).length) {
          sub_route = sub_route.concat(getFilterParamString(this.state.filterModel, this.state.showQuery || this.state.quickQuery));
          sub_route = `${sub_route}&filter=true`
        }
        let id = []
        if (!this.state.isAllRowsSelected) {
          selectedRows.map((data) => id.push(data.id));
        }
        const columnHeaders = this.columnApi.getAllDisplayedColumns().map(column => column.getColDef().headerName);
        this.gridApi.showLoadingOverlay()
        const payload = { headers: { columnHeaders: columnHeaders, selectedRows: { id: id }, type: 'Companies', allRowSelected: this.state.isAllRowsSelected } };
        if (this.state.advanceSelector) {
          payload.headers.queryData = this.state.queryData
        }
        let { status, data, headers } = await companyDataApi(POST, '', payload, sub_route, BLOB);
        if (status === 200) {
          let fileName = 'Companies.xlsx';
          let fileNameHeader = headers['content-disposition'].split('"');
          if (fileNameHeader && fileNameHeader.length > 2) {
            fileName = fileNameHeader[1];
          }
          saveAs(new File([data], fileName));
        }
        this.gridApi.hideOverlay()
      }
    } else {
      this.props.enqueueSnackbar(VALIDATION_MESSAGE.no_record_selected, { variant: ERROR })
    }
  };

  update = (value) => {
    this.setState({ isRemove: value })
  }
  classicQuery = (queryData) => {
    this.setState({ isAdvanceQuery: false, isClassicQuery: true, quickQuery: '' })
    this.handleQuery(queryData)
    localStorage.removeItem('companyColumns')
  }

  AdvancedQuery = (queryData) => {
    this.setState({ isAdvanceQuery: true, isClassicQuery: false, quickQuery: '', advanceSelector: true })
    this.handleQuery(queryData)
    localStorage.removeItem('companyColumns')
  }

  componentDidMount() {
    this.setState({ uiSelector: false/* , quickQuery: '', searchValue: ''  */ })
    if (this.props && this.props.location && this.props.location.state) {
      this.setState({ masterQuery: this.props.location.state })
    }
  }
  resetFilter = async () => {
    this.gridApi.setFilterModel(null);
    const customFilterField = this.columnApi.columnController.columnDefs;
    customFilterField.map(item => {
      if (item.filter === 'CustomFilter') {
        this.gridApi.destroyFilter(item.field);
      }
    })
  }

  resetSort = async () => {
    this.gridApi.setSortModel(null);
  }

  getURLs = {
    listURl: `${API.companies}`,
    classicURl: `${API.companies}`,
    advanceURL: API.master_query,
    quickQuery: `${API.companies}`
  };

  dataSource = {
    getRows: async (params) => {
      try {
        let value = localStorage.getItem('companyColumns')
        if (value === 'true') {
          this.setState({ showQuery: true, minimizeQuery: false })
          // this.resetFilter();
          // this.resetSort();
          params.successCallback([], 0);
          this.setState({ rowCount: 0 });
          params.filterModel && this.setState({ filterModel: params.filterModel });
          this.setState({ showFilterCount: Object.keys(this.state.filterModel).length })
          return;
        }
        params.filterModel && this.setState({ filterModel: params.filterModel });
        this.setState({ showFilterCount: Object.keys(this.state.filterModel).length })
        // this.gridApi.deselectAll();
        if (params.filterModel && Object.keys(params.filterModel).length > 0)
          this.gridApi.deselectAll();
        const thisValue = { ...this };

        if (this.state.masterQuery) {
          this.handleSearchChange('', this.state.masterQuery)
        }
        else {
          this.gridApi.showLoadingOverlay();
          const obj = {
            params: params,
            context: thisValue,
            pageLimit: PAGE_LIMIT,
            url: this.getURLs,
            companiesScreen: true
          }
          const getData = await dataSourceUtils(obj, getFilterParamString, getQueryParamsString);
          const { status, data } = getData;
          if (status === SUCCESS_STATUS_CODE) {
            if (data?.paging) {
              if (data?.paging?.totalCount === 0) {
                const message = notFoundMessage("records")
                this.props.enqueueSnackbar(message, { variant: WARNING })
              }
              params.successCallback(data.data, data.paging.totalCount)
              this.setState({ rowCount: data.paging.totalCount })
            }
            else if (Array.isArray(data)) {
              if (data.length === 0) {
                const message = notFoundMessage("records")
                this.props.enqueueSnackbar(message, { variant: WARNING })
              }
              params.successCallback(data, data.length)
              this.setState({ rowCount: data.length })
            }
            else {
              if (data.length === 0) {
                const message = notFoundMessage("records")
                this.props.enqueueSnackbar(message, { variant: WARNING })
              }
              params.successCallback([data], 1)
              this.setState({ rowCount: 1 })
            }
            this.state.isAllRowsSelected && this.setSelectAllRows(this.state.isAllRowsSelected);
          }
          params.failCallback();
          this.gridApi.hideOverlay()
        }
      } catch (e) {
        console.log("Error found in getRows::", e);
      }
      // localStorage.removeItem('companyColumns')
    },
    rowCount: null
  }

  onGridReady = (params) => {
    this.gridApi = params.api
    this.columnApi = params.columnApi
    const columnApi = params.columnApi
    const gridApi = params.api
    params.columnApi.autoSizeColumns()
    loadColumnStateFromLocalStorage('companyColumns', { columnApi, gridApi })
    this.setState({ isGridReady: true })
  }
  NameRenderer = (params) => {
    return <CompanyNameRenderer company={params?.data} />
  }

  ParentCompanyNameRenderer = (params) => {
    return <CompanyNameRenderer company={params?.data?.parent} />
  }

  handleSearchChange = (event, value) => {
    this.setState({ isClassicQuery: false, isAdvanceQuery: false })
    this.isSelectedFromList = false
    if (this.state.masterQuery) {
      this.setState({ masterQuery: '' })
    }
    if (typeof value === 'string') {
      this.setState({ quickQuery: value, searchValue: value, exportSingleValue: false })
    }
    if (value && value.id) {
      this.isSelectedFromList = true
      this.setState({ quickQuery: value.id, searchValue: value.name, exportSingleValue: true })
    }
    this.setState({ quickQuery: value?.id ? value?.id : (value ? value : '') }, () => {
      this.gridApi.onFilterChanged();
    })
    this.resetFilter();
    this.resetSort();
  }

  handleQuery = (queryData) => {
    if (this.state.isAllRowsSelected === true) {
      this.setSelectAllRows(false)
      this.setState({ isAllRowsSelected: true })
    }
    else {
      this.gridApi.forEachNode(node => {
        node.setSelected(false);
      });
    }
    this.setState({ queryData: cleanup(queryData) })
    this.gridApi.onFilterChanged()
  }

  handleChangeQuery = async () => {
    await this.resetSort();
    await this.resetFilter()
    this.isSelectedFromList = false
    this.gridApi.onFilterChanged();
    this.setState({ showQuery: true, minimizeQuery: false })
    localStorage.setItem('companyColumns', true)
    this.setState({ masterQuery: '', quickQuery: '', searchValue: '' })
    // setTimeout(() => {
    //   window.location.reload(false);
    // }, 3000)
  }

  setSelectAllRows = (isAllRowsSelected) => {
    this.setState({ isAllRowsSelected })
    this.gridApi.forEachNode(node => {
      node.setSelected(isAllRowsSelected);
    });
  }

  handleChange = () => {
    this.setSelectAllRows(!(this.state.isAllRowsSelected))
  };

  HeaderCheckbox = () => {
    return (
      <Checkbox
        style={{ padding: 0, width: 16, height: 16, color: "white" }}
        size="small"
        color="primary"
        onChange={this.handleChange}
      />
    )
  }

  RevenueRenderer = (params) => {
    const currencyCode = params.data && params.data.currency_iso_code ? params.data.currency_iso_code : ''
    const revenue = params.data && params.data.revenue ? params.data.revenue : ''
    return <NumberFormat
      style={{ border: 'none', fontSize: '15px' }}
      color="primary"
      value={revenue}
      thousandSeparator={true}
      decimalSeparator='.'
      displayType='text'
      prefix={getCurrencyPrefix(currencyCode !== 'RUR' ? currencyCode : '')}
      suffix={getCurrencyPrefix(currencyCode === 'RUR' ? 'RUR' : '')}
      renderText={(data) => {
        const value = currencyValueSetter(data, currencyCode)
        return value ? value : '--'
      }}
    />
  }

  ActionsRenderer = (params) => {
    const list = [
      {
        label: 'Delete Record',
        onClick: () => {
          this.setState({ companyId: params.data?.id })
        }
      }
    ]
    return params.data ? <ActionsPopover list={list} /> : null
  }
  handleClose = (deleted) => {
    if (deleted) {
      this.gridApi.onFilterChanged()
    }
    this.setState({ companyId: null })
  }

  saveColumnState = async () => {
    try {
      this.gridApi && this.gridApi.showLoadingOverlay()
      const columnApi = this.columnApi;
      const gridApi = this.gridApi
      await saveColumnStateToLocalStorage('companyColumns', { columnApi, gridApi })
      this.gridApi && this.gridApi.hideOverlay()
    } catch (e) {
      console.log("Error found in saveColumnState::", e);
    }

  }

  saveColumnStateForFilter = async () => {
    try {
      this.gridApi && this.gridApi.showLoadingOverlay()
      const columnApi = this.columnApi;
      const gridApi = this.gridApi
      await saveColumnStateToLocalStorage('companyColumns', { columnApi, gridApi }, true, false)
      this.gridApi && this.gridApi.hideOverlay()
    } catch (e) {
      console.log("Error found in saveColumnState::", e);
    }

  }

  saveColumnStateForSort = async () => {
    try {
      this.gridApi && this.gridApi.showLoadingOverlay()
      const columnApi = this.columnApi;
      const gridApi = this.gridApi
      await saveColumnStateToLocalStorage('companyColumns', { columnApi, gridApi }, false, true)
      this.gridApi && this.gridApi.hideOverlay()
    } catch (e) {
      console.log("Error found in saveColumnState::", e);
    }

  }

  handleMergeClick = () => {
    this.setState({ showMergePopup: true });
  }

  runQuery = () => {
    this.setState({ queryRun: true })
  }

  pullData = () => {
    if (this.state.uiSelector === false && this.state.isQuerySelector === false) {
      this.setState({
        uiSelector: true,
        advanceSelector: true,
        isQuerySelector: true
      })
    }
    else if (this.state.uiSelector === true && this.state.isQuerySelector === true) {
      this.setState({
        uiSelector: false,
        advanceSelector: false,
        isQuerySelector: false
      })
    }
  }

  handleMergeClose = (merged) => {
    this.setState({ showMergePopup: false });
    if (merged) {
      this.gridApi.onFilterChanged();
      this.gridApi.deselectAll();
    }
  }
  loaderChange = async (value) => {
    this.setState({ isLoading: value })
  }

  render() {
    return (
      <div className="list-companies d-flex flex-column">
        <Loader show={this.state.isLoading} />
        <Helmet>
          <title>Companies - KG Galaxy</title>
        </Helmet>
        {
          this.state.showMergePopup &&
          <MergeCompanies
            onClose={this.handleMergeClose}
            companies={this.gridApi.getSelectedRows()}
          />
        }
        {this.state.companyId ?
          <DeletePopup id={this.state.companyId} onClose={this.handleClose} updateParent={this.update} popupText={DELETE_CONFIRMATION_POPUP_MESSAGE.DELETE_COMPANY} />
          : null
        }
        {this.state.showQuery ?
          <div className={'d-flex query-toolbar d-flex'} style={{ padding: '0px' }}>
            <Helmet>
              <title>Query Companies - KG Galaxy</title>
            </Helmet>
            <ExpansionPanel className="flex-grow-1 w-100 m-0" expanded={this.state.showQuery && !this.state.minimizeQuery}>
              <ExpansionPanelSummary
                expandIcon={
                  <ExpandMoreIcon onClick={() => this.setState({
                    minimizeQuery: true
                  })} />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
                onClick={() => this.setState({
                  minimizeQuery: !this.state.minimizeQuery
                })}
                style={{ padding: '0px 14px', lineHeight: 1 }}
              >
                Companies Query
              </ExpansionPanelSummary>
              <ExpansionPanelDetails style={{ padding: '0px 15px 3px', width: '100%' }}>
                {
                  this.state.uiSelector ?
                    <AdvancedQuery fieldDefinitions={fieldDefinitions} type={'company'} pullData={this.pullData} handleQuery={this.AdvancedQuery} />
                    :
                    <Query gridApi={this.gridApi} isAllRowsSelected={this.state.isAllRowsSelected} pullData={this.pullData} columnApi={this.columnApi} handleQuery={this.classicQuery} queryData={this.state.queryData} flag={true} />
                }
              </ExpansionPanelDetails >
            </ExpansionPanel >
            <div className="expansion-panel-close-container">
              <span
                style={{ display: this.state.minimizeQuery ? 'inline' : 'none' }}
                className="cursor-pointer expansion-panel-close d-flex"
                onClick={async () => {
                  await this.resetSort();
                  await this.resetFilter()
                  this.setState({ showQuery: false, queryData: {}, minimizeQuery: true })
                  this.gridApi.onFilterChanged()
                  this.setState({ isAdvanceQuery: false, isClassicQuery: false, advanceSelector: false })
                  if (this.state.uiSelector === true) {
                    this.setState({ isQuerySelector: true })
                  } else {
                    this.setState({ isQuerySelector: false })
                  }
                  localStorage.removeItem('companyColumns')
                  // setTimeout(() => {
                  //   window.location.reload(false);
                  // }, 3000)
                }}
              >
                <CloseIcon />
              </span>
            </div>
          </div > :
          <div className={'toolbar'}>
            <CompanySearch value={this.state.searchValue} onChange={this.handleSearchChange} type="quicksearches" />
            <Button className={'start-query'} variant={'outlined'} color={'primary'} onClick={this.handleChangeQuery}>
              Start Query</Button>
          </div >
        }
        <div className="d-flex align-items-center justify-content-end" style={{ padding: '10px 20px 0' }}>
          <div
            className="action-container"
            style={{ minWidth: '0' }}
            onClick={() => this.resetFilter()}
          >
            <span className="action-text" >
              Reset Filter
            </span>
          </div>
          <div
            className="action-container"
            style={{ minWidth: '0' }}
            onClick={() => this.resetSort()}
          >
            <span className="action-text" >
              Reset Sort
            </span>
          </div>
          <Button
            disabled={this.state.selectedRowsCount !== 2}
            className='mr-3'
            color='primary'
            variant='contained'
            onClick={this.handleMergeClick}
          >
            Merge
          </Button>
          <Button className='mr-3' disabled={!this.state.selectedRowsCount} variant='outlined' color='primary' onClick={this.handleExport}>Export List</Button>
          <Typography>Total count: {this.state.rowCount}</Typography>
        </div>
        <div className="list-view flex-grow-1">
          {this.state.isGridReady && <ColumnFilter columnApi={this.columnApi} defaultColumns={defaultColumns} showFilterCount={this.state.showFilterCount} filterModel={this.state.filterModel} />}
          <div id="myGrid" className="ag-theme-alpine">
            <AgGridReact
              onGridReady={this.onGridReady}
              enableBrowserTooltips={true}
              defaultColDef={{
                minWidth: 100,
                resizable: true,
                sortable: true,
                sortingOrder: ['asc', 'desc', null]
              }}
              blockLoadDebounceMillis={SCROLL_TIMEOUT}
              tooltipShowDelay={0}
              scrollbarWidth={12}
              suppressHorizontalScroll={false}
              cacheBlockSize={PAGE_LIMIT}
              loadingOverlayComponent={'CustomLoadingOverlayComponent'}
              frameworkComponents={{
                NameRenderer: this.NameRenderer,
                WebsiteRenderer,
                PhoneRenderer,
                CustomLoadingOverlayComponent,
                DateTimeRenderer,
                DateRenderer,
                GenericCellEditor,
                RichTextBoxEditor,
                RichTextPopupRendered,
                DescriptionEditor,
                ParentCompanyNameRenderer: this.ParentCompanyNameRenderer,
                PopupEditor,
                PhoneEditor,
                ToggleSelection,
                HeaderCheckbox: this.HeaderCheckbox,
                ActionsRenderer: this.ActionsRenderer,
                RevenueRenderer: this.RevenueRenderer,
                NumberEditor,
                StateEditor,
                CustomFilter
              }}
              rowModelType={'infinite'}
              datasource={this.dataSource}
              columnDefs={columnDefs(this.props.enqueueSnackbar, this.loaderChange)}
              paginationPageSize={20}
              rowSelection={'multiple'}
              suppressMenuHide={true}
              suppressRowClickSelection={true}
              suppressDragLeaveHidesColumns={true}
              onDisplayedColumnsChanged={this.saveColumnState}
              onDragStopped={this.saveColumnState}
              onSortChanged={this.saveColumnStateForSort}
              onFilterChanged={this.saveColumnStateForFilter}
              onRowSelected={() => {
                // if (!e.node.selected) {
                //   this.setState({ isAllRowsSelected: false })
                // }
                this.setState({ selectedRowsCount: this.gridApi.getSelectedRows().length })
              }}
              getRowNodeId={data => data.id}
            >
            </AgGridReact>
          </div>
        </div>
      </div >
    )
  }
}

CompaniesList.propTypes = {
  enqueueSnackbar: PropTypes.func,
  location: PropTypes.object,
}
export default withSnackbar(withRouter(CompaniesList));
