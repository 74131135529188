// -------------------------------------------------// In-built Imports// -------------------------------------------------
import React, { useRef } from 'react'

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from 'prop-types'

//-----------------------------------------------------------// Internal Imports // ------------------------------

import Attachments from '../../Attachments';
import { useDispatch, useSelector } from 'react-redux';
import { contactActions } from '../../../store/contactSlice';
import { VALIDATION_MESSAGE } from '../../../services/MessageService';
import { WARNING } from '../../../services/constantService';


const ContactNda = (props) => {

  const { contactId, disabled = false, enqueueSnackbar } = props;
  const contactNda = useSelector(state => state.contactReducer.contactNda)
  const dispatch = useDispatch();
  const ndaRef = useRef();

  const contactDispatch = (data) => {
    dispatch(contactActions.updateContactNda(data))
  }
  const validation = () => {
    if (contactNda.length >= 3) {
      enqueueSnackbar(VALIDATION_MESSAGE.max_ndas, { variant: WARNING })
    } else {
      ndaRef.current.handleAddAttachment()
    }
  }

  return (
    <div className="section-container mb-5">
      <div className="section-header">NDA</div>
      <Attachments ref={ndaRef} dispatchDetails={contactDispatch} attachmentsDetails={contactNda} disabled={disabled} apiConfig={{ parentId: contactId, parentPath: "contact", attachmentType: "ndas" }} uiConfig={{ canEdit: false, showDescription: false, attachmentType: "NDA" }} className="contact-attachments" />
      <button className="primary-btn" disabled={disabled} onClick={() => validation()}>Upload</button>
    </div>
  )
}

ContactNda.propTypes = {
  contactId: PropTypes.string,
  disabled: PropTypes.bool,
  enqueueSnackbar: PropTypes.func,
}

export default ContactNda;
