//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useEffect, useState } from "react";

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from 'prop-types'
import Autocomplete from "@material-ui/lab/Autocomplete";

//-----------------------------------------------------------// Internal Imports // ------------------------------

import InputField from "./common/InputField";

const options = [{ id: "true", name: "Yes" }, { id: "false", name: "No" }]
export default function ExpenseSelection(props) {

  const { label, placeholder, value, ...rest } = props

  const [defaultValue, setDefaultValue] = useState({})

  useEffect(() => {
    if (value) {
      let option = value;
      if (typeof value === 'object') {
        option = options.find(ele => ele.id === value.id);
      }
      setDefaultValue(option)
    } else {
      setDefaultValue({})
    }
  }, [value])


  return (
    <Autocomplete
      {...rest}
      value={defaultValue}
      options={options}
      getOptionSelected={(option, value) => {
        let isEqual = false;
        if (typeof value === 'string') {
          isEqual = option.name === value;
        } else if (value) {
          isEqual = option.name === value.name
        }
        return isEqual
      }}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => (
        <InputField
          {...params}
          variant="standard"
          label={label}
          placeholder={placeholder}
        />
      )}
    />
  )
}

ExpenseSelection.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.object
}
