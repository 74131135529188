//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from "prop-types";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { fetchTypeList } from "../../../../../actions";


const BDStatusSelection = (props) => {
  const {
    register,
    className = 'w-100',
    label = "",
    name = 'bd_status',
    placeholder = "BD Status",
    InputLabelProps,
    required,
    variant = 'standard',
    ...rest
  } = props

  const [options, setOptions] = useState([])
  const bd_status = useSelector(state => state.commonReducer.bd_status)
  const dispatch = useDispatch()
  
  useEffect(() => {
    if (!bd_status) {
      dispatch(fetchTypeList('BD_STATUS', 'bd_status'))
    } else {
      const data = [...bd_status]
      setOptions(data);
    }
  }, [bd_status, dispatch])

  return (
    <Autocomplete
      {...rest}
      className={className}
      options={options}
      getOptionLabel={option => {
        if (typeof option === 'string') {
          return option
        }
        return option.short_desc
      }}
      getOptionSelected={(option, value) => {
        if (!value) {
          return false;
        }
        if (typeof value === 'string') {
          return option.short_desc === value;
        }
        return option.short_desc === value.short_desc
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          inputRef={register}
          name={name}
          InputLabelProps={InputLabelProps}
          label={label}
          required={required}
          placeholder={placeholder}
          variant={variant}
        />
      )}
    />
  )
}

BDStatusSelection.propTypes = {
  register: PropTypes.func,
  className: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  InputLabelProps: PropTypes.object,
  placeholder: PropTypes.string,
  variant: PropTypes.string,
  required:PropTypes.bool
}

export default BDStatusSelection;