
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { Component } from "react";

//-----------------------------------------------------------// External Imports // ------------------------------

import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import { AgGridReact } from "ag-grid-react";
import ContactsIcon from "@material-ui/icons/Contacts";
import PersonIcon from "@material-ui/icons/Person";
import { Button, Checkbox, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import { saveAs } from "file-saver";
import { withSnackbar } from "notistack";

//-----------------------------------------------------------// Internal Imports // ------------------------------

import {
  CustomLoadingOverlayComponent,
  PAGE_LIMIT,
  SUCCESS_STATUS_CODE,
  EmailRenderer,
  LinkedInUserRenderer,
  LinksRenderer,
  TwitterRenderer,
  DateRenderer,
  saveColumnStateToLocalStorage,
  loadColumnStateFromLocalStorage,
  WebsiteUserRenderer,
} from "../Commons/Utils";
import { columnDefs, defaultColumns, PhoneRenderer } from "./utils";
import Loader from "../../components/common/Loader";
import CustomFilter from "../Commons/CustomFilter";
import ColumnFilter from "../Commons/ColumnFilter";
import GenericCellEditor from "../Commons/CellEditors/GenericCellEditor";
import AlertDialog from "./AlertDialog";
import PhoneEditor from "../Commons/CellEditors/PhoneEditor";
import StateEditor from "../Commons/CellEditors/StateEditor";
import Search from "../Commons/Search";
import {
  API,
  BLOB,
  ERROR,
  EXPORT_CONTACT_LIMIT,
  POST,
  SUCCESS,
  WARNING,
  GET
} from "../../services/constantService";
import ViewUser from "../../components/ViewUser";
import { getFilterParamString } from "../Contacts/utils";
import { getAccessToken } from "../../services/cognitoService";
import LinkedInDialog from "../Commons/LinkedInDialog";
import { dataSourceUtils } from "../../utils/dataSource";
import { fetchUserList } from "../../actions";
import { userDataApi } from "../../services/ApiService";
import {
  unableMessage,
  VALIDATION_MESSAGE,
  notFoundMessage,
} from "../../services/MessageService";
import ToggleSelection from '../Commons/CellEditors/ToggleSelection'


class UsersList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewColumns: false,
      anchorEl: null,
      isRowSelected: false,
      isAllRowsSelected: false,
      contactIds: [],
      activeContact: null,
      rowCount: 0,
      contacts: [{}],
      isAthenaPopupOpen: false,
      quickQuery: "",
      searchValue: "",
      exportSingleValue: false,
      open: false,
      toggleUser: {},
      user: null,
      loading: false,
      filterModel: {},
      token: "",
      linkedPopup: false,
      linkedInValue: {},
      isLoading: false,
      urlType: "",
      userData: {},
      showFilterCount: 0
    };
    this.enqueueSnackbar = props.enqueueSnackbar;

  }

  async getUserId() {
    const globalLocation = this.props.location.pathname.split("/");
    if (globalLocation[globalLocation.length - 1] !== 'users' && globalLocation[globalLocation.length - 1] !== '') {
      const { status, data } = await userDataApi(GET, globalLocation[globalLocation.length - 1]);
      if (status === 200)
        this.setState({ userData: data });
    }
    else {
      this.setState({ userData: {} });
      this.props.history.push(`/users`)
    }
  }
  isSelectedFromList = false;
  componentWillUnmount() {
    const columnApi = this.columnApi
    saveColumnStateToLocalStorage('userTableColumns', { columnApi })
  }

  async componentDidMount() {
    if (this.props && this.props.location) {
      this.getUserId()
    }
    const token = await getAccessToken();
    this.setState({ token: token });
    if (!this.props.users) {
      this.props.fetchUserList("users");
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.getUserId()
    }

  }
  getURLs = {
    listURl: `${API.users}`,
    quickQuery: `${API.users}`,
  };
  suppressKeyboardEvent = (params) =>{
    // return true (to suppress) if editing and user hit Enter keys
    const key = params.event.key;
    const isKeyPressEnter = params.editing && ( key === "Enter");
    return isKeyPressEnter;
  }

  dataSource = {
    getRows: async (params) => {
      try {
        params.filterModel && this.setState({ filterModel: params.filterModel });
        this.setState({ showFilterCount: Object.keys(this.state.filterModel).length })
        if (params.filterModel && Object.keys(params.filterModel).length > 0)
          this.gridApi.deselectAll();
        const thisValue = { ...this };
        this.gridApi.showLoadingOverlay();
        const obj = {
          params: params,
          context: thisValue,
          pageLimit: PAGE_LIMIT,
          url: this.getURLs,
          subScreen: true,
          usersScreen: true,
          queryString: !params.filterModel.is_active ? `&is_active=true` : "",
        };

        const { status, data } = await dataSourceUtils(obj, getFilterParamString);
        if (status === SUCCESS_STATUS_CODE) {
          if (data?.paging?.totalCount === 0) {
            const message = notFoundMessage("records");
            this.props.enqueueSnackbar(message, { variant: WARNING });
          }
          params.successCallback(data.data, data.paging.totalCount);
          this.setState({ rowCount: data.paging.totalCount });
          if (this.state.isAllRowsSelected) {
            this.gridApi.forEachNode((node) => {
              node.setSelected(this.state.isAllRowsSelected);
            });
          }
        } else {
          params.failCallback();
        }
        this.gridApi.hideOverlay();
      } catch (e) {
        console.log("Error found in getRows::", e);
      }
    },
    rowCount: null,
  };
  resetFilter = async () => {
    this.gridApi.setFilterModel(null);
    const customFilterField = this.columnApi.columnController.columnDefs;
    customFilterField.map(item => {
      if (item.filter === 'CustomFilter') {
        this.gridApi.destroyFilter(item.field);
      }
    })
  }

  resetSort = async () => {
    this.gridApi.setSortModel(null);
  }

  handleSearchChange = (event, value) => {
    this.isSelectedFromList = false;
    if (typeof value === "string") {
      this.setState({
        quickQuery: value,
        searchValue: value,
        exportSingleValue: false,
      });
    } else if (value && value.id) {
      this.isSelectedFromList = true;
      this.setState({
        quickQuery: value.id,
        searchValue: value.name,
        exportSingleValue: true,
      });
    }
    this.setState(
      { quickQuery: value?.id ? value?.id : value ? value : "" },
      () => {
        this.gridApi.onFilterChanged();

      }
    );
    this.resetFilter();
    this.resetSort();
  };

  updateStatus = async (userId, isActive) => {
    this.setState({ loading: true });
    const payload = { is_active: isActive };
    const sub_route = "status";
    const { status, data } = await userDataApi(POST, userId, payload, sub_route);
    const statusText = isActive ? "Active" : "Inactive";
    if (status === 200) {
      this.props.enqueueSnackbar(`Marked user as ${statusText}`, {
        variant: SUCCESS,
      });
      this.gridApi.onFilterChanged();
    } else {
      const message = unableMessage(`${statusText}`, "mark user as");
      this.props.enqueueSnackbar(data?.message || message, { variant: ERROR });
    }
    this.setState({ loading: false });
  };

  exportCallback = async () => {
    const selectedRows = this.gridApi.getSelectedRows();
    if (selectedRows.length > 0) {
      let sub_route = "export-as-excel?";
      let id = [];
      if (
        Object.keys(this.state.filterModel).length === 0 &&
        this.state.filterModel.constructor === Object
      ) {
        sub_route = `${sub_route}&is_active=true&filter=true`;
      }
      if (this.state.quickQuery) {
        if (this.state.exportSingleValue) {
          sub_route = `export-as-excel?id=${this.state.quickQuery}&quickSearch=true`;
        } else {
          sub_route = `export-as-excel?searchValue=${this.state.quickQuery}&quickSearch=true`;
        }
      }
      if (Object.keys(this.state.filterModel).length) {
        sub_route = sub_route.concat(
          getFilterParamString(
            this.state.filterModel,
            this.state.showQuery || this.state.quickQuery
          )
        );
        sub_route = `${sub_route}&filter=true&is_active=true`;
      }
      if (!this.state.isAllRowsSelected) {
        selectedRows.map((data) => id.push(data.id));
      }
      const columnHeaders = this.columnApi
        .getAllDisplayedColumns()
        .map((column) => column.getColDef().headerName);
      this.gridApi.showLoadingOverlay();
      const payload = {
        headers: {
          columnHeaders: columnHeaders,
          selectedRows: { id: id },
          allRowSelected: this.state.isAllRowsSelected,
          limit: EXPORT_CONTACT_LIMIT,
        },
      };
      let { status, data, headers } = await userDataApi(
        POST,
        "",
        payload,
        sub_route,
        BLOB
      );
      if (status === 200) {
        let fileName = "Contacts.xlsx";
        let fileNameHeader = headers["content-disposition"].split('"');
        if (fileNameHeader && fileNameHeader.length > 2) {
          fileName = fileNameHeader[1];
        }
        saveAs(new File([data], fileName));
      } else {
        this.props.enqueueSnackbar(VALIDATION_MESSAGE.export_fail, {
          variant: ERROR,
        });
      }
      this.gridApi.hideOverlay();
    } else {
      this.props.enqueueSnackbar(VALIDATION_MESSAGE.no_record_selected, {
        variant: ERROR,
      });
    }
  };

  ActionsRenderer = (params) => {
    if (params.data) {
      return (
        <button
          className="secondary-btn"
          onClick={() => {
            if (this.props.userData.roles[0] === "admin") {
              this.setState({ toggleUser: params.data });
              this.setState({ open: true });
            }
          }}
        >
          {params.data.is_active ? "Inactive" : "Active"}
        </button>
      );
    }
    return null;
  };

  linkedInPopupHandler = (props) => {
    let value = props && props.value ? props.value : "";
    if (value && value.indexOf("https:") < 0) {
      value = `https://${value}`;
    }
    this.setState({ linkedPopup: true, linkedInValue: value, urlType: "linkedIn" });
  };
  websitePopupHandler = (props) => {
    let value = props && props.value ? props.value : "";
    if (value && value.indexOf("https:") < 0) {
      value = `https://${value}`;
    }
    this.setState({ linkedPopup: true, linkedInValue: value, urlType: "website" });
  };

  LinkedInRenderer = (params) => {
    return (
      <LinkedInUserRenderer
        params={params}
        linkedInPopup={this.linkedInPopupHandler}
      />
    );
  };

  WebsiteRenderer = (params) => {
    return (
      <WebsiteUserRenderer
        params={params}
        linkedInPopup={this.websitePopupHandler}
      />
    );
  };

  ProfileRenderer = (params) => {
    const pageSize = "USLetter";
    const language = "en_US";
    let queryURL = `${API.reports}/user-profile/${params.data && params.data.id}`;
    queryURL = queryURL.concat(
      `?pageSize=${pageSize}&locale=${language}&token=${this.state.token}`
    );
    if (params.data) {
      return (
        <Link className="action-label" to={queryURL} target="_blank">
          <PersonIcon style={{ color: "rgb(40, 103, 178)" }} />
        </Link>
      );
    }
    return null;
  };

  VCardRendered = (params) => {
    let queryURL = `${API.reports}/v-card/${params.data && params.data.id}`;
    queryURL = queryURL.concat(`?token=${this.state.token}`);
    if (params.data) {
      return (
        <Link className="action-label" to={queryURL} target="_blank">
          <ContactsIcon style={{ color: "rgb(40, 103, 178)" }} />
        </Link>
      );
    }
    return null;
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    const columnApi = params.columnApi
    const gridApi = params.api
    loadColumnStateFromLocalStorage("userTableColumns", { columnApi, gridApi });
    this.setState({ isGridReady: true });
  };

  setSelectAllRows = (isAllRowsSelected) => {
    this.setState({ isAllRowsSelected: isAllRowsSelected });
    this.gridApi.forEachNode((node) => {
      node.setSelected(isAllRowsSelected);
    });
  };

  handleChange = () => {
    this.setSelectAllRows(!this.state.isAllRowsSelected);
  };

  HeaderCheckbox = () => {
    return (
      <Checkbox
        style={{ padding: 0, width: 16, height: 16 , color: "white" }}
        size="small"
        color="primary"
        onChange={this.handleChange}
      />
    );
  };

  NameRenderer = (params) => {
    return (
      <Typography
        className="text-link"
        color="primary"
        onClick={() => {
          this.setState({ user: params.node.data })
          this.props.history.push(`/users/${params.data.id}`)
        }}

      >
        {params.value}
      </Typography>
    );
  };
  userUpdate = (data) => {
    this.setState({ user: data, userData: data });
  };

  onSortChanged = (params) => {
    const sortModel = params.api.getSortModel();
    this.setState({ sortModel });
    this.saveColumnStateForSort()
  };

  saveColumnState = async () => {
    try {
      this.gridApi && this.gridApi.showLoadingOverlay()
      const columnApi = this.columnApi;
      const gridApi = this.gridApi
      await saveColumnStateToLocalStorage("userTableColumns", { columnApi, gridApi });
      this.gridApi && this.gridApi.hideOverlay()
    } catch (e) {
      console.error("Error found in saveColumnState::", e);
    }
  };

  saveColumnStateForFilter = async () => {
    try {
      this.gridApi && this.gridApi.showLoadingOverlay()
      const columnApi = this.columnApi;
      const gridApi = this.gridApi
      await saveColumnStateToLocalStorage("userTableColumns", { columnApi, gridApi }, true, false);
      this.gridApi && this.gridApi.hideOverlay()
    } catch (e) {
      console.error("Error found in saveColumnState::", e);
    }
  };
  saveColumnStateForSort = async () => {
    try {
      this.gridApi && this.gridApi.showLoadingOverlay()
      const columnApi = this.columnApi;
      const gridApi = this.gridApi
      await saveColumnStateToLocalStorage("userTableColumns", { columnApi, gridApi }, false, true);
      this.gridApi && this.gridApi.hideOverlay()
    } catch (e) {
      console.error("Error found in saveColumnState::", e);
    }
  };

  handleRowSelection = () => {
    /* if (!e.node.selected) {
            this.setState({ isAllRowsSelected: false })
       } */
    this.setState({
      isRowSelected: this.gridApi.getSelectedRows().length > 0 ? true : false,
    });
  };

  handleClose = () => {
    this.setState({ open: false, linkedPopup: false });
  };

  handleSubmit = async () => {
    try {
      this.handleClose();
      await this.updateStatus(
        this.state.toggleUser.id,
        !this.state.toggleUser.is_active
      );
    } catch (e) {
      console.log("Error found in handleSubmit::", e);
    }
  };

  loaderChange = async (value) => {
    this.setState({ loading: value });
  };

  render() {
    const {
      searchValue,
      open,
      linkedInValue,
      urlType,
      linkedPopup,
      loading,
      toggleUser,
      isRowSelected,
      rowCount,
      isGridReady,
    } = this.state;
    const { users } = this.props;
    return (
      <div className="d-flex flex-column w-100 h-100">
        <Helmet>
          <title>Users - KG Galaxy</title>
        </Helmet>
        <LinkedInDialog
          open={linkedPopup}
          linkedInValue={linkedInValue}
          handleClose={this.handleClose}
          urlType={urlType}
        ></LinkedInDialog>
        <AlertDialog
          open={open}
          isActive={toggleUser.is_active}
          handleClose={this.handleClose}
          handleSubmit={this.handleSubmit}
        />
        <Loader show={loading} />
        <div className={"toolbar"}>
          <Search
            value={searchValue}
            onChange={this.handleSearchChange}
            type="users"
          />
        </div>
        <div className="d-flex align-items-center justify-content-end mr-4">
          <div
            className="action-container"
            style={{ minWidth: '0' }}
            onClick={() => this.resetFilter()}
          >
            <span className="action-text" >
              Reset Filter
            </span>
          </div>
          <div
            className="action-container"
            style={{ minWidth: '0' }}
            onClick={() => this.resetSort()}
          >
            <span className="action-text" >
              Reset Sort
            </span>
          </div>
          <Button
            className="mr-3"
            variant={"outlined"}
            color={"primary"}
            disabled={!isRowSelected}
            onClick={this.exportCallback}
          >
            Export list
          </Button>
          <Typography>Total count: {rowCount}</Typography>
        </div>
        <div className="list-view flex-grow-1">
          {isGridReady && (
            <ColumnFilter
              columnApi={this.columnApi}
              defaultColumns={defaultColumns}
              showFilterCount={this.state.showFilterCount}
              filterModel={this.state.filterModel}
            />
          )}
          <div id="myGrid" className="ag-theme-alpine">
            <AgGridReact
              enableBrowserTooltips={true}
              suppressHorizontalScroll={false}
              suppressKeyboardEvent = {this.suppressKeyboardEvent}
              datasource={this.dataSource}
              paginationPageSize={PAGE_LIMIT}
              cacheBlockSize={PAGE_LIMIT}
              defaultColDef={{
                minWidth: 100,
                resizable: true,
                sortable: true,
                sortingOrder: ['asc', 'desc', null]
              }}
              loadingOverlayComponent={"CustomLoadingOverlayComponent"}
              frameworkComponents={{
                CustomLoadingOverlayComponent,
                PhoneRenderer,
                EmailRenderer,
                ToggleSelection,
                LinkedInRenderer: this.LinkedInRenderer,
                LinksRenderer,
                TwitterRenderer,
                CustomFilter,
                NameRenderer: this.NameRenderer,
                HeaderCheckbox: this.HeaderCheckbox,
                ActionsRenderer: this.ActionsRenderer,
                ProfileRenderer: this.ProfileRenderer,
                VCardRendered: this.VCardRendered,
                DateRenderer,
                GenericCellEditor,
                PhoneEditor,
                StateEditor,
                WebsiteRenderer: this.WebsiteRenderer
              }}
              scrollbarWidth={12}
              rowSelection={"multiple"}
              rowModelType={"infinite"}
              onGridReady={this.onGridReady}
              columnDefs={columnDefs(
                this.enqueueSnackbar,
                true,
                this.props.userData.roles[0],
                this.loaderChange,
                users
              )}
              getRowNodeId={(data) => data.id}
              onSortChanged={this.onSortChanged}
              onRowSelected={this.handleRowSelection}
              suppressRowClickSelection={true}
              suppressDragLeaveHidesColumns={true}
              onDisplayedColumnsChanged={this.saveColumnState}
              onDragStopped={this.saveColumnState}
              onFilterChanged={this.saveColumnStateForFilter}
            ></AgGridReact>
          </div>
          {Object.keys(this.state.userData).length > 0 && (
            <ViewUser
              visible={true}
              onClose={(isDataUpdated) => {
                if (isDataUpdated) {
                  this.gridApi.onFilterChanged();
                }
                this.setState({ user: null, userData: {} });
                this.props.history.push(`/users`)
              }}
              user={this.state.userData}
              userUpdate={this.userUpdate}
            />
          )}
        </div>
      </div>
    );
  }
}

UsersList.propTypes = {
  enqueueSnackbar: PropTypes.func,
  userData: PropTypes.object,
  users: PropTypes.array,
  fetchUserList: PropTypes.func,
  history: PropTypes.object,
  location: PropTypes.object
};

const mapStateToProps = (state) => {
  return {
    userData: state.commonReducer.userData,
    users: state.rootReducer.users,
  };
};
const mapDispatchToProps = () => ({ fetchUserList });
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withRouter(UsersList)));
