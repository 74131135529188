
//-----------------------------------------------------------// Internal Imports // ------------------------------

import {
  API,
  GET,
  POST,
  PUT,
  DELETE,
  CANDIDATE,
  PATCH,
} from "./constantService";
import httpService from "./httpService";

export const acquireLockApi = async (id, body = {}) => {
  try {
    return await httpService.post(`${API.acquire_lock}/${id}`, body);
  } catch (e) {
    console.log("Error found in acquireLockApi::", e);
  }
};

export const contactDataRefreshApi = async (body) => {
  try {
    return await httpService.post(`${API.contact_data_refresh}`, body);
  } catch (e) {
    console.log("Error found in contactDataRefreshApi::", e)
  }
}

export const pdlContactMergeApi = async (body) => {
  try {
    return await httpService.patch(`${API.pdl_contact_data_merge}`, body);
  } catch (e) {
    console.log("Error found in pdlContactMergeApi::", e)
  }
}

export const releaseLockApi = async (id, body = {}) => {
  try {
    let url = `${API.release_lock}`;
    if (id) {
      url = `${url}/${id}`
    }
    return await httpService.post(url, body);
  } catch (e) {
    console.log("Error found in releaseLockApi::", e);
  }
};

export const renewLockApi = async (id) => {
  try {
    return await httpService.post(`${API.renew_lock}/${id}`);
  } catch (e) {
    console.log("Error found in renewLockApi::", e);
  }
};

export const sfpaDecisionApi
  = async (
    methodName,
    id = "",
    body = {},
    sub_route = "",
    responseType = ""
  ) => {
    try {
      if (methodName === GET) {
        const result =
          id.length > 0
            ? await httpService.get(`${API.sfpa_decision}/${id}/${sub_route}`)
            : await httpService.get(`${API.sfpa_decision}/${sub_route}`);
        return result;
      }
      if (methodName === PUT) {
        const result =
          id.length > 0
            ? await httpService.put(`${API.sfpa_decision}/${id}/${sub_route}`, body)
            : await httpService.put(`${API.sfpa_decision}/${sub_route}`, body);
        return result;
      }
      if (methodName === DELETE) {
        const result =
          id.length > 0
            ? await httpService.delete(`${API.sfpa_decision}/${id}/${sub_route}`, body)
            : await httpService.delete(`${API.sfpa_decision}/${sub_route}`, body);
        return result;
      }
      if (methodName === POST) {
        const result =
          responseType.length > 0
            ? id.length > 0
              ? await httpService.post(
                `${API.sfpa_decision}/${id}/${sub_route}`,
                body,
                responseType
              )
              : await httpService.post(
                `${API.sfpa_decision}/${sub_route}`,
                body,
                responseType
              )
            : id.length > 0
              ? await httpService.post(`${API.sfpa_decision}/${id}/${sub_route}`, body)
              : await httpService.post(`${API.sfpa_decision}/${sub_route}`, body);
        return result;
      }
      if (methodName === PATCH) {
        const result = await httpService.patch(`${API.sfpa_decision}/${id}/${sub_route}`, body);
        return result
      }
    } catch (e) {
      console.log("Error found in searchDataApi::", e);
    }
  };

export const searchDataApi = async (
  methodName,
  id = "",
  body = {},
  sub_route = "",
  responseType = ""
) => {
  try {
    if (methodName === GET) {
      const result =
        id.length > 0
          ? await httpService.get(`${API.search}/${id}/${sub_route}`)
          : await httpService.get(`${API.search}/${sub_route}`);
      return result;
    }
    if (methodName === PUT) {
      const result =
        id.length > 0
          ? await httpService.put(`${API.search}/${id}/${sub_route}`, body)
          : await httpService.put(`${API.search}/${sub_route}`, body);
      return result;
    }
    if (methodName === DELETE) {
      const result =
        id.length > 0
          ? await httpService.delete(`${API.search}/${id}/${sub_route}`, body)
          : await httpService.delete(`${API.search}/${sub_route}`, body);
      return result;
    }
    if (methodName === POST) {
      const result =
        responseType.length > 0
          ? id.length > 0
            ? await httpService.post(
              `${API.search}/${id}/${sub_route}`,
              body,
              responseType
            )
            : await httpService.post(
              `${API.search}/${sub_route}`,
              body,
              responseType
            )
          : id.length > 0
            ? await httpService.post(`${API.search}/${id}/${sub_route}`, body)
            : await httpService.post(`${API.search}/${sub_route}`, body);
      return result;
    }
    if (methodName === PATCH) {
      const result = await httpService.patch(`${API.search}/${id}/${sub_route}`, body);
      return result
    }
  } catch (e) {
    console.log("Error found in searchDataApi::", e);
  }
};

export const userDataApi = async (
  methodName,
  id = "",
  body = {},
  sub_route = "",
  responseType = ""
) => {
  try {
    if (methodName === GET) {
      const result =
        id.length > 0
          ? await httpService.get(`${API.users}/${id}/${sub_route}`)
          : await httpService.get(`${API.users}/${sub_route}`);
      return result;
    }
    if (methodName === PUT) {
      const result =
        id.length > 0
          ? await httpService.put(`${API.users}/${id}/${sub_route}`, body)
          : await httpService.put(`${API.users}/${sub_route}`, body);
      return result;
    }
    if (methodName === DELETE) {
      const result =
        id.length > 0
          ? await httpService.delete(`${API.users}/${id}/${sub_route}`, body)
          : await httpService.delete(`${API.users}/${sub_route}`, body);
      return result;
    }
    if (methodName === POST) {
      const result =
        responseType.length > 0
          ? id.length > 0
            ? await httpService.post(
              `${API.users}/${id}/${sub_route}`,
              body,
              responseType
            )
            : await httpService.post(
              `${API.users}/${sub_route}`,
              body,
              responseType
            )
          : id.length > 0
            ? await httpService.post(`${API.users}/${id}/${sub_route}`, body)
            : await httpService.post(`${API.users}/${sub_route}`, body);
      return result;
    }
  } catch (e) {
    console.log("Error found in userDataApi::", e);
  }
};

export const candidateDataApi = async (
  methodName,
  id = "",
  body = {},
  sub_route = ""
) => {
  try {
    if (methodName === GET) {
      const result =
        id.length > 0
          ? await httpService.get(`${API.candidate}/${id}/${sub_route}`)
          : await httpService.get(`${API.candidate}/${sub_route}`);
      return result;
    }
    if (methodName === PUT) {
      const result =
        id.length > 0
          ? await httpService.put(`${API.candidate}/${id}/${sub_route}`, body)
          : await httpService.put(`${API.candidate}/${sub_route}`, body);
      return result;
    }
    if (methodName === DELETE) {
      const result =
        id.length > 0
          ? await httpService.delete(
            `${API.candidate}/${id}/${sub_route}`,
            body
          )
          : await httpService.delete(`${API.candidate}/${sub_route}`, body);
      return result;
    }
    if (methodName === POST) {
      const result =
        id.length > 0
          ? await httpService.post(`${API.candidate}/${id}/${sub_route}`, body)
          : await httpService.post(`${API.candidate}/${sub_route}`, body);
      return result;
    }
    if (methodName === PATCH) {
      const result =
        id.length > 0
          ? await httpService.patch(`${API.candidate}/${id}/${sub_route}`, body)
          : await httpService.patch(`${API.candidate}/${sub_route}`, body);
      return result;
    }
  } catch (e) {
    console.log("Error found in candidateDataApi::", e);
  }
};

export const activityInfoApi = async (
  methodName,
  id = "",
  sub_route = "",
) => {
  try {
    if (methodName === GET) {
      const result = await httpService.get(`${API.activities}/${id}/${sub_route}`)
      return result;
    }
  } catch (e) {
    console.log("Error found in activityInfoApi::", e);
  }
}
export const invoiceSummaryApi = async (
  methodName,
  id = "",
  body = {},
  sub_route = "",
  responseType = "") => {
  if (methodName === POST) {
    const result =
      responseType.length > 0
        ? id.length > 0
          ? await httpService.post(
            `${API.invoice_summary_export}/${id}/${sub_route}`,
            body,
            responseType
          )
          : await httpService.post(
            `${API.invoice_summary_export}/${sub_route}`,
            body,
            responseType
          )
        : id.length > 0
          ? await httpService.post(`${API.invoice_summary_export}/${id}/${sub_route}`, body)
          : await httpService.post(`${API.invoice_summary_export}/${sub_route}`, body);
    return result;
  }
}

export const contactDataApi = async (
  methodName,
  id = "",
  body = {},
  sub_route = "",
  responseType = ""
) => {
  try {
    if (methodName === GET) {
      const result =
        id.length > 0
          ? await httpService.get(`${API.contact}/${id}/${sub_route}`)
          : await httpService.get(`${API.contact}/${sub_route}`);
      return result;
    }
    if (methodName === PUT) {
      const result =
        id.length > 0
          ? await httpService.put(`${API.contact}/${id}/${sub_route}`, body)
          : await httpService.put(`${API.contact}/${sub_route}`, body);
      return result;
    }
    if (methodName === DELETE) {
      const result =
        id.length > 0
          ? await httpService.delete(`${API.contact}/${id}/${sub_route}`, body)
          : await httpService.delete(`${API.contact}/${sub_route}`, body);
      return result;
    }
    if (methodName === POST) {
      const result =
        responseType.length > 0
          ? id.length > 0
            ? await httpService.post(
              `${API.contact}/${id}/${sub_route}`,
              body,
              responseType
            )
            : await httpService.post(
              `${API.contact}/${sub_route}`,
              body,
              responseType
            )
          : id.length > 0
            ? await httpService.post(`${API.contact}/${id}/${sub_route}`, body)
            : await httpService.post(`${API.contact}/${sub_route}`, body);
      return result;
    }
  } catch (e) {
    console.log("Error found in contactDataApi::", e);
  }
};

export const getGalaxyAndPdlContactDataApi = async (id) => {
  try {
    return await httpService.get(`${API.galaxy_pdl_data}?contactId=${id}`);
  } catch (e) {
    console.log("Error found in contactDataRefreshApi::", e)
  }
}

export const candidateContactDataApi = async (
  methodName,
  id = "",
  type,
  body = {},
  sub_route = ""
) => {
  try {
    if (type === CANDIDATE) {
      return await candidateDataApi(methodName, id, body, sub_route);
    } else {
      return await contactDataApi(methodName, id, body, sub_route);
    }
  } catch (e) {
    console.log("Error found in candidateContactDataApi::", e);
  }
};
export const candidateSuiteDataApi = async (
  methodName,
  sub_route = ""
) => {
  try {
    if (methodName === GET) {
      const result = await httpService.get(`${API.candidatesuite}/${sub_route}`)
      return result;
    }
  } catch (e) {
    console.log("Error found in candidateSuiteDataApi::", e);
  }
};

export const workbenchDataApi = async (
  methodName,
  id = "",
  body = {},
  sub_route = "",
  responseType = ""
) => {
  try {
    if (methodName === GET) {
      const result =
        id.length > 0
          ? await httpService.get(`${API.workbench}/${id}/${sub_route}`)
          : await httpService.get(`${API.workbench}/${sub_route}`);
      return result;
    }
    if (methodName === PUT) {
      const result =
        id.length > 0
          ? await httpService.put(`${API.workbench}/${id}/${sub_route}`, body)
          : await httpService.put(`${API.workbench}/${sub_route}`, body);
      return result;
    }
    if (methodName === DELETE) {
      const result =
        id.length > 0
          ? await httpService.delete(
            `${API.workbench}/${id}/${sub_route}`,
            body
          )
          : await httpService.delete(`${API.workbench}/${sub_route}`, body);
      return result;
    }
    if (methodName === POST) {
      const result =
        responseType.length > 0
          ? id.length > 0
            ? await httpService.post(
              `${API.workbench}/${id}/${sub_route}`,
              body,
              responseType
            )
            : await httpService.post(
              `${API.workbench}/${sub_route}`,
              body,
              responseType
            )
          : id.length > 0
            ? await httpService.post(`${API.workbench}/${id}/${sub_route}`, body)
            : await httpService.post(`${API.workbench}/${sub_route}`, body);
      return result;
    }
  } catch (e) {
    console.log("Error found in workbenchDataApi::", e)
  }
};
export const ProductSettingsApi = async (
  methodName,
  body = {},
  sub_route = "",
  id = "",
  responseType = "",
) => {
  try {
    if (methodName === DELETE) {
      const result =
        await httpService.delete(
          `${API.product_setting}/${sub_route}`,
          body
        )
      return result;
    }
    if (methodName === PUT) {
      const result = await httpService.put(`${API.product_setting}/${sub_route}/${id}`, body)
      return result;
    }
    if (methodName === POST) {
      const result =
        responseType.length > 0
          ? id.length > 0
            ? await httpService.post(
              `${API.product_setting}/${id}/${sub_route}`,
              body,
              responseType
            )
            : await httpService.post(
              `${API.product_setting}/${sub_route}`,
              body,
              responseType
            )
          : id.length > 0
            ? await httpService.post(`${API.product_setting}/${id}/${sub_route}`, body)
            : await httpService.post(`${API.product_setting}/${sub_route}`, body);
      return result;
    }
    if (methodName === GET) {
      return await httpService.get(`${API.product_setting}/${sub_route}`);
    }


  } catch (e) {
    console.log("Error found in ProductSettingsApi::", e)
  }
};
export const SfpaSettingsApi = async (
  methodName,
  body = {},
  sub_route = "",
  id = "",
  responseType = "",
) => {
  try {
    if (methodName === DELETE) {
      const result =
        await httpService.delete(
          `${API.sfpa_setting}/${sub_route}`,
          body
        )

      return result;
    }
    if (methodName === PUT) {
      const result = await httpService.put(`${API.sfpa_setting}/${sub_route}/${id}`, body)
      return result;
    }
    if (methodName === POST) {
      const result =
        responseType.length > 0
          ? id.length > 0
            ? await httpService.post(
              `${API.sfpa_setting}/${id}/${sub_route}`,
              body,
              responseType
            )
            : await httpService.post(
              `${API.sfpa_setting}/${sub_route}`,
              body,
              responseType
            )
          : id.length > 0
            ? await httpService.post(`${API.sfpa_setting}/${id}/${sub_route}`, body)
            : await httpService.post(`${API.sfpa_setting}/${sub_route}`, body);
      return result;
    }

  } catch (e) {
    console.log("Error found in SfpaSettingsApi::", e)
  }
};

export const companyDataApi = async (
  methodName,
  id = "",
  body = {},
  sub_route = "",
  responseType = ""
) => {
  try {
    if (methodName === GET) {
      const result =
        id.length > 0
          ? await httpService.get(`${API.companies}/${id}/${sub_route}`)
          : await httpService.get(`${API.companies}/${sub_route}`);
      return result;
    }
    if (methodName === PUT) {
      const result =
        id.length > 0
          ? await httpService.put(`${API.companies}/${id}/${sub_route}`, body)
          : await httpService.put(`${API.companies}/${sub_route}`, body);
      return result;
    }
    if (methodName === DELETE) {
      const result =
        id.length > 0
          ? await httpService.delete(
            `${API.companies}/${id}/${sub_route}`,
            body
          )
          : await httpService.delete(`${API.companies}/${sub_route}`, body);
      return result;
    }
    if (methodName === POST) {
      const result =
        responseType.length > 0
          ? id.length > 0
            ? await httpService.post(
              `${API.companies}/${id}/${sub_route}`,
              body,
              responseType
            )
            : await httpService.post(
              `${API.companies}/${sub_route}`,
              body,
              responseType
            )
          : id.length > 0
            ? await httpService.post(`${API.companies}/${id}/${sub_route}`, body)
            : await httpService.post(`${API.companies}/${sub_route}`, body);
      return result;
    }
  } catch (e) {
    console.log("Error found in companyDataApi::", e);
  }
};

export const userSearchDataApi = async (methodName) => {
  try {
    if (methodName === GET) {
      return await httpService.get(`${API.user_search}`);
    }
  } catch (e) {
    console.log("Error found in userSearchDataApi::", e);
  }
};

export const currencyInfoApi = async (methodName, code) => {
  try {
    if (methodName === GET) {
      return await httpService.get(`${API.picklists}/currency-details?code=${code}`);
    }
  }
  catch (e) {
    console.log("Error found in currencyInfoApi::", e);
  }
}

export const picklistsDataApi = async (methodName, id = '', sub_route = '') => {
  try {
    if (methodName === GET) {
      const result =
        id.length > 0
          ? sub_route.length > 0 ? await httpService.get(`${API.picklists}/${id}/${sub_route}`) : await httpService.get(`${API.picklists}/${id}`)
          : await httpService.get(`${API.picklists}/${sub_route}`);
      return result;
    }
  } catch (e) {
    console.log("Error found in picklistsDataApi::", e);
  }
};

export const revenueMarketDataApi = async (methodName) => {
  try {
    if (methodName === GET) {
      const result = await httpService.get(`${API.product_setting}/revenue_threshold`);
      return result;
    }
  } catch (e) {
    console.log("Error found in picklistsDataApi::", e);
  }
};

export const picklistsCountryStateDataApi = async (
  methodName,
  sub_route = ""
) => {
  try {
    if (methodName === GET) {
      return await httpService.get(
        `${API.picklists_country_states}${sub_route}`
      );
    }
  } catch (e) {
    console.log("Error found in picklistsCountryStateDataApi::", e);
  }
};

export const picklistsTypeDataApi = async (
  methodName,
  sub_route = ""
) => {
  try {
    if (methodName === GET) {
      const url = `${API.type_list}/${sub_route}`
      return await httpService.get(url);
    }
  } catch (e) {
    console.log("Error found in picklistsCountryStateDataApi::", e);
  }
};

export const picklistsColorsDataApi = async (
  methodName,
  id = "",
  body = {},
  sub_route = "",
  dispatchColorPickList
) => {
  if (methodName === GET) {
    try {
      let url;
      if (id) {
        url = `${API.picklists_colors}/${id}${sub_route}`
      }
      else {
        url = `${API.picklists_colors}`
      }
      const result = await httpService.get(url)
      dispatchColorPickList && dispatchColorPickList(result.data)
      return result;
    } catch (e) {
      console.log("Error found in picklistsColorsDataApi::", e);
    }
  }
  if (methodName === PUT) {
    try {
      const result =
        id.length > 0
          ? await httpService.put(
            `${API.picklists_colors}/${id}${sub_route}`,
            body
          )
          : await httpService.put(`${API.picklists_colors}${sub_route}`, body);
      return result;
    } catch (e) {
      console.log("Error found in picklistsColorsDataApi::", e);
    }
  }
};

export const picklistsSearchDataApi = async (
  methodName,
  id = "",
  sub_route = ""
) => {
  try {
    if (methodName === GET) {
      const result =
        id.length > 0
          ? await httpService.get(`${API.picklists_search}/${id}/${sub_route}`)
          : await httpService.get(`${API.picklists_search}/${sub_route}`);
      return result;
    }
  } catch (e) {
    console.log("Error found in picklistsSearchDataApi::", e);
  }
};

export const clientFeedbackDataApi = async (
  methodName,
  id = "",
  body = {},
  sub_route = ""
) => {
  try {
    if (methodName === GET) {
      const result =
        id.length > 0
          ? await httpService.get(`${API.client_feedback}/${id}/${sub_route}`)
          : await httpService.get(`${API.client_feedback}/${sub_route}`);
      return result;
    }
    if (methodName === POST) {
      const result =
        id.length > 0
          ? await httpService.post(
            `${API.client_feedback}/${id}/${sub_route}`,
            body
          )
          : await httpService.post(`${API.client_feedback}/${sub_route}`, body);
      return result;
    }
    if (methodName === PUT) {
      const result =
        sub_route.length > 0
          ? await httpService.put(
            `${API.client_feedback}/${sub_route}/${id}`,
            body
          )
          : await httpService.put(`${API.client_feedback}/${id}`, body);
      return result;
    }
  } catch (e) {
    console.log("Error found in clientFeedbackDataApi::", e);
  }
};

export const hlDataApi = async (methodName, sub_route = "", body = {}) => {
  try {
    if (methodName === GET) {
      return await httpService.get(`${API.hl}/${sub_route}`);
    }
    if (methodName === PUT) {
      return await httpService.put(`${API.hl}/${sub_route}`, body);
    }
  } catch (e) {
    console.log("Error found in hlDataApi::", e);
  }
};

export const activitiesDataApi = async (
  methodName,
  sub_route = "",
  body = {}
) => {
  try {
    if (methodName === GET) {
      return await httpService.get(`${API.activities}?${sub_route}`);
    }
    if (methodName === PUT) {
      return await httpService.put(`${API.activities}/${sub_route}`, body);
    }
    if (methodName === DELETE) {
      return await httpService.delete(`${API.activities}/${sub_route}`);
    }
  } catch (e) {
    console.log("Error found in activitiesDataApi::", e);
  }
};

export const searchDocumentApi = async (
  methodName,
  sub_route = "",
  params = {},
  customHeaders = {},
  responseType = "",
  body
) => {
  try {
    if (methodName === GET) {
      const result = responseType
        ? await httpService.get(
          `${API.searches}/${sub_route}`,
          params,
          customHeaders,
          responseType
        )
        : await httpService.get(`${API.searches}/${sub_route}`);
      return result;
    }
    if (methodName === DELETE) {
      return await httpService.delete(`${API.searches}/${sub_route}`);
    }
    if (methodName === PUT) {
      return await httpService.put(`${API.searches}/${sub_route}`, body);
    }
    if (methodName === POST) {
      return await httpService.post(`${API.searches}/${sub_route}`);
    }
  } catch (e) {
    console.log("Error found in searchDocumentApi::", e);
  }
};

export const bdDataApi = async (methodName, id = "", body = {}) => {
  try {
    if (methodName === PUT) {
      return await httpService.put(`${API.business_developments}/${id}`, body);
    }
    if (methodName === POST) {
      return await httpService.post(`${API.business_developments}`, body);
    }
  } catch (e) {
    console.log("Error found in bdDataApi::", e);
  }
};

export const contactReferencesApi = async (
  methodName,
  sub_route = "",
  body = {}
) => {
  try {
    if (methodName === GET) {
      return await httpService.get(`${API.contacts}/${sub_route}`);
    }
    if (methodName === PUT) {
      return await httpService.put(`${API.contacts}/${sub_route}`, body);
    }
    if (methodName === POST) {
      return await httpService.post(`${API.contacts}/${sub_route}`, body);
    }
  } catch (e) {
    console.log("Error found in contactReferencesApi::", e);
  }
  if (methodName === DELETE) {
    try {
      const result = await httpService.delete(`${API.contacts}/${sub_route}`);
      return result;
    } catch (e) {
      console.log("Error found in contactReferencesApi::", e);
    }
  }
};

export const offLimitsContactApi = async (methodName, body = {}) => {
  try {
    if (methodName === POST) {
      return await httpService.post(`${API.offlimits}`, body);
    }
  } catch (e) {
    console.log("Error found in offLimitsContactApi::", e);
  }
};

export const userGridApi = async (methodName, sub_route = "", body = {}) => {
  try {
    if (methodName === GET) {
      const data = await httpService.get(`${API.user_grid}?${sub_route}`);
      if (data.data.data.settings === "[]") {
        data.data.data.settings = null;
      }
      return data.data;
    }
    if (methodName === POST) {
      if (body.settings === "[]") {
        return;
      }
      return await httpService.post(`${API.user_grid}`, body);
    }
  } catch (e) {
    console.log("Error found in userGridApi::", e);
  }
};

export const notesAttachmentDocumentApi = async (
  methodName,
  url,
  data = "",
  id = "",
  responseType = ""
) => {
  try {
    if (methodName === GET) {
      return await httpService.get(`${url}`);
    }
    if (methodName === PUT) {
      return await httpService.put(`${url}/${id}`, data)
    }
    if (methodName === DELETE) {
      return await httpService.delete(`${url}`);
    }
    if (methodName === POST) {
      return await httpService.post(`${url}`, data, responseType);
    }
  } catch (e) {
    console.log("Error found in notesAttachmentDocumentApi::", e);
  }
};

export const reportTemplateApi = async (
  methodName,
  url,
  data = "",
  // eslint-disable-next-line no-unused-vars
  id= "",
  responseType = "",
  params = {},
  customHeaders = {},
  key=""
) => {
  try {
    if (methodName === GET && key !== "download") {
      return await httpService.get(`${url}`);
    }
    if (methodName === PUT) {
      return await httpService.put(`${url}`, data)
    }
    if (methodName === DELETE) {
      return await httpService.delete(`${url}`);
    }
    if (methodName === POST) {
      return await httpService.post(`${url}`, data, responseType);
    }
    if (methodName === GET && key === "download") {
      const result = responseType
        ? await httpService.get(
          `${url}`,
          params,
          customHeaders,
          responseType
        )
        : await httpService.get(`${url}`);
      return result;
    }
  } catch (e) {
    console.log("Error found in reportTemplateApi::", e);
  }
};
export const athenaInviteApi = async (sub_route = "", body = {}) => {
  try {
    return await httpService.post(`${API.athena}/${sub_route}`, body);
  } catch (e) {
    console.log("Error found in athenaInviteApi::", e);
  }
};

export const masterSearchApi = async (sub_route = "") => {
  try {
    return await httpService.get(`${API.master_search}/${sub_route}`);
  } catch (e) {
    console.log("Error found in masterSearchApi::", e);
  }
};

export const clientsuiteInviteApi = async (sub_route = "", body = {}) => {
  try {
    return await httpService.post(`${API.clientsuite}/${sub_route}`, body);
  } catch (e) {
    console.log("Error found in clientsuiteInviteApi::", e);
  }
};


export const clientsuiteDataApi = async (
  methodName,
  sub_route = ""
) => {
  try {
    if (methodName === GET) {
      const result = await httpService.get(`${API.clientsuite}/${sub_route}`)
      return result;
    }
  } catch (e) {
    console.log("Error found in clientsuiteDataApi::", e);
  }
};

export const synclinkDataApi = async (
  methodName,
  sub_route = ""
) => {
  try {
    if (methodName === GET) {
      const result = await httpService.get(`${API.synclink}/${sub_route}`)
      return result;
    }
  } catch (e) {
    console.log("Error found in clientsuiteDataApi::", e);
  }
};

export const reportsDataApi = async (
  methodName,
  sub_route = ""
) => {
  try {
    if (methodName === GET) {
      const result = await httpService.get(`${API.reports}/${sub_route}`)
      return result;
    }
  } catch (e) {
    console.log("Error found in reportsDataApi::", e);
  }
};

export const deletePopupApi = async (url = "", body = {}) => {
  try {
    return await httpService.delete(`${url}`, body);
  } catch (e) {
    console.log("Error found in deletePopupApi::", e);
  }
};

export const getMethodWithCancelTokenApi = async (
  url,
  params = {},
  customHeaders = {},
  responseType = "json",
  cancelToken
) => {
  try {
    return await httpService.get(
      url,
      params,
      customHeaders,
      responseType,
      cancelToken
    );
  } catch (e) {
    console.log("Error found in getMethodWithCancelTokenApi::", e);
  }
};

export const workbenchAdvanceSelectorApi = async (
  methodName,
  url,
  body = {}
) => {
  try {
    if (methodName === GET) {
      return await httpService.get(`${url}`);
    }
    if (methodName === POST) {
      return await httpService.post(`${url}`, body);
    }
  } catch (e) {
    console.log("Error found in workbenchAdvanceSelectorApi::", e);
  }
};

export const allCandidateListApi = async (
  methodName,
  url,
  sub_route = ''
) => {
  try {
    if (methodName === GET) {
      return await httpService.get(`${url}?${sub_route}`);
    }
  } catch (e) {
    console.log("Error found in allCandidateListApi::", e);
  }
};

export const dashboardDataApi = async (
  methodName,
  url,
  sub_route = "",
  body = {}
) => {
  try {

    if (methodName === GET) {

      return await httpService.get(`${url}?${sub_route}`);
    }
    if (methodName === POST) {
      return await httpService.post(`${url}`, body);
    }
  } catch (e) {
    console.log("Error found in dashboardDataApi::", e);
  }
};

export const productOneApi = async (
  methodName,
  body = {},
  sub_route = "",
) => {
  try {
    if (methodName === GET) {
      const result = await httpService.get(`${API.product_one}/${sub_route}`);
      return result;
    }
    if (methodName === PUT) {
      const result = await httpService.put(`${API.product_one}/${sub_route}`, body);
      return result;
    }
    if (methodName === POST) {
      const result = await httpService.post(`${API.product_one}/${sub_route}`, body);
      return result;
    }
    if (methodName === PATCH) {
      const result = await httpService.patch(`${API.product_one}/${sub_route}`, body);
      return result
    }
  } catch (e) {
    console.log("Error found in productOneApi::", e);
  }
};
