//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useState, useEffect } from 'react'

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import Autocomplete from '@material-ui/lab/Autocomplete';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import InputField from '../common/InputField';
import { fetchPickList } from "../../actions";

const CurrencySelection = (props) => {
  const { label = "Currency Code", disabled = false, placeholder = "Currency Code", InputLabelProps, required, variant='standard', ...rest } = props;

  const [options, setOptions] = useState([]);
  const currencies = useSelector(state => state.commonReducer.currencies)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!currencies) {
      dispatch(fetchPickList('currencies'))
    } else {
      setOptions(currencies);
    }
  }, [currencies, dispatch])

  return (
    <Autocomplete
      {...rest}
      options={options}
      getOptionSelected={(option, value) => {
        if (typeof value === 'string') {
          return option.code === value;
        }
        return option.code === value.code
      }}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option
        }
        return option.code
      }}
      disabled={disabled}
      renderInput={(params) => (
        <InputField
          {...params}
          InputLabelProps={InputLabelProps}
          required={required}
          variant={variant}
          label={label}
          placeholder={placeholder}
        />
      )}
    />
  )
}

CurrencySelection.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  InputLabelProps: PropTypes.object,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  variant: PropTypes.string
}

export default CurrencySelection;
