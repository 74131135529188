//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useEffect, useState, useRef } from "react";

//-----------------------------------------------------------// External Imports // ------------------------------

import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import difference from "lodash.difference";
import { AgGridReact } from "ag-grid-react";
import axios from "axios";
import Popup from "reactjs-popup";
import { useSnackbar } from "notistack";
import { Typography, Button, InputLabel, Checkbox } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

//-----------------------------------------------------------// Internal Imports // ------------------------------

import Loader from "../../../../components/common/Loader";
import SearchStageSelection from "../../../../components/CreateSearch/Components/SearchInformation/Components/SearchStageSelection";
import {
  CustomLoadingOverlayComponent,
  PAGE_LIMIT,
  SUCCESS_STATUS_CODE,
} from "../../../Commons/Utils";
import { columnsDef, IdRenderer } from "./utils";
import InputField from "../../../../components/common/InputField";
import { isEmpty, SEARCH_TIMEOUT } from "../../../../utils/common";
import CandidateStageSelection from "../../../../components/CandidateStageSelection";
import OffLimitsWarningPopup from "../../../Commons/OffLimitsWarningPopup";
import MultipleContacts from "../../../Commons/OffLimitsWarningPopup/MultipleContacts";
import {
  API,
  DELETE,
  POST,
  WARNING,
  ERROR,
  SUCCESS,
} from "../../../../services/constantService";
import {
  getMethodWithCancelTokenApi,
  offLimitsContactApi,
  searchDataApi,
  workbenchDataApi,
} from "../../../../services/ApiService";
import {
  duplicateMessage,
  unableMessage,
  VALIDATION_MESSAGE,
  validateMessage,
  successMessage,
  requireMessage,
} from "../../../../services/MessageService";
import RichTextPopup from "../../../../components/RichTextPopup";
import ColorSelection from "../../../../components/ColorSelection";

let gridApi;
const CancelToken = axios.CancelToken;

function CopyCandidatesToSearch(props) {
  const {
    header = "Copy Candidates To Job",
    buttonText = "Copy Candidates To Selected Job",
    uiLabel = "candidate(s)",
    open,
    handleClose,
    searchId,
    contactIds = [],
    isAllRowsSelected,
    queryData,
    contacts = [{}],
    workbenchId,
    hide = false,
  } = props;
  const [isLoading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [stage, setStage] = useState("Open");
  const [stages, setStages] = useState("Open");
  const [searchValue, setSearchValue] = useState("");
  const [jobNumber, setJobNumber] = useState(null);
  const [isOffLimitsCheckComplete, setOffLimitsCheckComplete] = useState(false);
  const [showOffLimitsPopup, setShowOffLimitsPopup] = useState(false);
  const [showMultiContactOffLimits, setShowMultiContactOffLimits] =
    useState(false);
  const [offLimitsData, setOffLimitsData] = useState({});
  const [ids, setIds] = useState([]);
  const call = useRef();
  const { register, setValue, getValues, watch } = useForm();
  const { enqueueSnackbar } = useSnackbar();
  const [offLimitsWorkbench, setOffLimitsWorkbench] = useState([]);
  const [offLimitContactIds, setOffLimitContactIds] = useState([]);
  const [nonOffLimitData, setNonOffLimitData] = useState([]);

  useEffect(() => {
    setIds(contactIds);
  }, [contactIds]);

  useEffect(() => {
    const checkIsOffLimits = async () => {
      const payload = {};
      if (workbenchId) {
        payload.workbenchId = workbenchId;
      } else if (searchId) {
        payload.searchId = searchId;
      } else if (!isAllRowsSelected) {
        payload.contacts = contactIds;
      }
      if (!isEmpty(queryData)) {
        payload.query = queryData;
      }
      const { status, data } = await offLimitsContactApi(POST, payload);
      if (status === 200) {
        if (data.offLimitContacts.length) {
          if (contactIds.length === 1) {
            setShowOffLimitsPopup(true);
          } else {
            setShowMultiContactOffLimits(true);
          }

          data.offLimitContacts.forEach((data) => {
            contactIds.forEach((contactData) => {
              if (data.id === contactData) {
                setOffLimitContactIds((offLimitContactIds) => [
                  ...offLimitContactIds,
                  contactData,
                ]);
                setOffLimitsWorkbench((offLimitsWorkbench) => [
                  ...offLimitsWorkbench,
                  data,
                ]);
              }
              return;
            });
          });
          setOffLimitsData(data);
        }
        if (data.validContactIds) {
          setIds(data.validContactIds);
        }
      }
    };
    checkIsOffLimits().then(() => {
      setOffLimitsCheckComplete(true);
    });
  }, [contactIds, workbenchId, searchId, isAllRowsSelected, queryData]);

  useEffect(() => {
    if (offLimitsWorkbench !== null) {
      const array = difference(contactIds, offLimitContactIds);
      setNonOffLimitData(array);
    }
    return;
  }, [offLimitsWorkbench, contactIds, offLimitContactIds]);

  const dataSource = {
    getRows: async (params) => {
      gridApi.showLoadingOverlay();
      let url = `${API.picklists_search}?limit=${PAGE_LIMIT}&page=${params.endRow / PAGE_LIMIT
        }`;
      const { status, data } = await getMethodWithCancelTokenApi(
        url,
        { stage, searchValue, jobNumber },
        {},
        {},
        call.current.token
      );
      if (status === SUCCESS_STATUS_CODE) {
        params.successCallback(data.data, data.paging.totalCount);
      } else {
        params.failCallback();
      }
      gridApi.hideOverlay();
    },
    rowCount: null,
  };

  useEffect(() => {
    register("addToStage");
    register("color");
  }, [register]);

  useEffect(() => {
    if (call.current) {
      call.current.cancel();
    }
    call.current = CancelToken.source();
    const timer = setTimeout(() => {
      gridApi && gridApi.setDatasource(dataSource);
    }, SEARCH_TIMEOUT);
    return () => clearTimeout(timer);
  }, [stage, dataSource, searchValue, jobNumber]);

  const onGridReady = (params) => {
    gridApi = params.api;
    params.api.sizeColumnsToFit();
  };

  const handleStageChange = (e, data) => {
    setStage(data ? data.field_value : null);
    setStages(data ? data.short_desc : null);
  };

  const handleCopyCandidates = async () => {
    if (gridApi.getSelectedRows().length === 0) {
      const message = validateMessage("search", "select");
      enqueueSnackbar(message, { variant: WARNING });
      return;
    }
    const selectedSearch = gridApi.getSelectedRows()[0];
    if (searchId === selectedSearch.id) {
      enqueueSnackbar(VALIDATION_MESSAGE.copy_search, { variant: ERROR });
      return;
    }

    if (!contactIds && !searchId) {
      const message = validateMessage("contact(s)", "select");
      enqueueSnackbar(message, { variant: ERROR });
    }
    const { rationale, addToStage, color } = getValues({ nest: true });
    if (!addToStage) {
      const message = requireMessage("Stage to add", "is");
      enqueueSnackbar(message, { variant: ERROR });
      return;
    }
    setLoading(true);
    let payload, sub_route;
    if (ids.length > 0) {
      payload = ids.map((item) => ({
        contact_id: item,
        stage: addToStage,
        rationale,
        color,
      }));
      sub_route = "candidates";
    }
    const { status, data } = await searchDataApi(
      POST,
      selectedSearch.id,
      payload,
      sub_route
    );
    let duplicateRecords = data?.candidatesDuplicate
      ? contacts?.filter((contact) =>
        data.candidatesDuplicate.includes(contact.contact ? contact.contact.id : contact.id)
      )
      : "";
    duplicateRecords = duplicateRecords
      ? duplicateRecords
        .map((record) => {
          /* For candidate array contact object will be get in contact  */
          const contact = record.contact ? record.contact : record;
          const firstName = contact.first_name ? contact.first_name : "";
          const lastName = contact.last_name ? contact.last_name : "";
          return firstName + " " + lastName;
        })
        .join(", ")
      : "";
    if (status === 200) {
      const message = successMessage(
        "Contacts added to search", `(${selectedSearch.job_title})`
      );
      enqueueSnackbar(message, { variant: SUCCESS });
      handleClose(true);
    }
    if (status === 201) {
      if (data?.candidatesAdded.length) {
        const message = successMessage(
          ` ${uiLabel} added`,
          `to search(${selectedSearch.job_title})`
        );
        enqueueSnackbar(message, { variant: SUCCESS });
      }
      if (data?.candidatesDuplicate.length) {
        const message = duplicateMessage(` for search(${selectedSearch.job_title})`, data?.candidatesDuplicate?.length);
        enqueueSnackbar(`${message} for ${duplicateRecords}`, { variant: SUCCESS });
      }
      if (data?.candidatesUpdated.length > 0) {
        data.candidatesUpdated.forEach(ele => {
          const message = `This candidate ${ele?.contact.first_name} ${ele?.contact.last_name} is already in the search with stage ${ele.stage}. Now the stage has been changed to ${addToStage}.`;
          enqueueSnackbar(message, { variant: SUCCESS, persist: true });
        })
      }

      if (checked) {
        const sub_route = "contacts?";
        const { status } = await workbenchDataApi(
          DELETE,
          workbenchId,
          ids,
          sub_route
        );
        if (status === 200) {
          const message = successMessage(
            "Contact(s) removed from ",
            "workbench"
          );
          enqueueSnackbar(message, { variant: SUCCESS });
        }
      }
      handleClose(true);
    } else if (status !== 200) {
      const message = unableMessage(`to search`, `add ${uiLabel}`);
      enqueueSnackbar(data?.message || message, { variant: ERROR });
    }

    setLoading(false);
  };

  const handleOffLimitsPopupClose = (continueAdd) => {
    if (continueAdd) {
      setShowOffLimitsPopup(false);
      setIds(contactIds);
    } else {
      handleClose(false);
    }
  };

  const handleMultiOffLimitsClose = () => {
    handleClose(false);
  };

  const handleMultiOffLimitsConfirm = (selectedContactIds) => {
    const contactIds = [
      ...new Set([...nonOffLimitData, ...selectedContactIds]),
    ];
    if (contactIds.length === 0) {
      const message = validateMessage("contacts", "select");
      enqueueSnackbar(`${message} to proceed`, { variant: WARNING });
      return;
    }
    setShowMultiContactOffLimits(false);
    setOffLimitsData(null);
    setIds(contactIds);
  };

  const showWarningPopup =
    isOffLimitsCheckComplete &&
    (showOffLimitsPopup || showMultiContactOffLimits);

  return isOffLimitsCheckComplete ? (
    showWarningPopup ? (
      showOffLimitsPopup ? (
        <OffLimitsWarningPopup
          data={offLimitsData.offLimitContacts}
          handleClose={handleOffLimitsPopupClose}
          contactId={contactIds.length === 1 ? contactIds[0] : null}
        />
      ) : (
        <MultipleContacts
          data={offLimitsWorkbench}
          handleAdd={handleMultiOffLimitsConfirm}
          handleCancel={handleMultiOffLimitsClose}
        />
      )
    ) : (
      <Popup
        className="copy-candidates"
        open={open}
        closeOnDocumentClick={false}
        closeOnEscape={false}
      >
        <React.Fragment>
          <Loader show={isLoading} />
          <div className="popup-container">
            <div className="d-flex align-items-center justify-content-between top-bar">
              <Typography className="title">{header}</Typography>
              <CloseIcon className="cursor-pointer" onClick={handleClose} />
            </div>
            <div className="inside-container">
              <div className="d-flex">
                <div
                  className="d-flex align-items-center mr-3"
                  style={{ flex: "1 0" }}
                >
                  <InputLabel
                    className="m-0 font-size-14"
                    focused={true}
                    style={{
                      paddingRight: 5,
                      letterSpacing: "0.09em",
                      // fontSize: 14,
                      flex: "0.7 0",
                    }}
                  >
                    SEARCH STAGE
                  </InputLabel>
                  <SearchStageSelection
                    value={stages}
                    label=""
                    className="w-50"
                    placeholder="Select Search Stage"
                    onChange={handleStageChange}
                  />
                </div>
                <div
                  className="d-flex align-items-center ml-3"
                  style={{ flex: "1 0" }}
                >
                  <InputLabel
                    className="m-0 font-size-14"
                    focused={true}
                    style={{
                      paddingRight: 5,
                      letterSpacing: "0.09em",
                      // fontSize: 14,
                      flex: "0.7 0",
                    }}
                  >
                    Job Title/ Company
                  </InputLabel>
                  <InputField
                    placeholder="Search"
                    className="w-50"
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="d-flex mt-3 mb-3">
                <div className="d-flex mr-3 w-100 align-items-center">
                  <InputLabel
                    className="m-0 font-size-14"
                    focused={true}
                    style={{
                      paddingRight: 5,
                      letterSpacing: "0.09em",
                      // fontSize: 14,
                      flex: "0.7 0",
                    }}
                  >
                    Job Number
                  </InputLabel>
                  <InputField
                    placeholder="Search"
                    className="w-50"
                    onChange={(e) => {
                      setJobNumber(e.target.value);
                    }}
                  />
                </div>
                <div className="d-flex ml-3 w-100 align-items-center">
                  <InputLabel
                    required={true}
                    focused={true}
                    className="m-0 font-size-14"
                    style={{
                      paddingRight: 5,
                      letterSpacing: "0.09em",
                      // fontSize: 14,
                      flex: "0.7 0",
                    }}
                  >
                    Stage to add
                  </InputLabel>
                  <CandidateStageSelection
                    required={true}
                    className="w-50"
                    label=""
                    placeholder="Select"
                    onChange={(e, data) => {
                      setValue("addToStage", data ? data.name : null);
                    }}
                    hideP1RejectedStage={true}
                  />
                </div>
              </div>
              <div className="d-flex mt-3 mb-3">
                <div className="d-flex mr-3 w-100 align-items-center">
                  <InputLabel
                    className="m-0 font-size-14"
                    focused={true}
                    style={{
                      paddingRight: 5,
                      letterSpacing: "0.09em",
                      // fontSize: 14,
                      flex: "0.7 0",
                    }}
                  >
                    Rationale
                  </InputLabel>
                  {/* <div className="w-50"> */}
                  <div className='text-view-value p-0 w-50'>
                    <div className='outlined-border transform p-0 '>
                      <RichTextPopup
                        name="rationale"
                        value={watch("rationale")}
                        ref={register(`rationale`)}
                        onChange={(data) => {
                          setValue(`rationale`, data);
                        }}
                        placeholder={"Text Here"}
                        title="Rationale"
                      />
                    </div>

                  </div>
                  {/* </div> */}
                </div>
                <div className="d-flex ml-3 w-100 align-items-center">
                  <InputLabel
                    focused={true}
                    className="m-0 font-size-14"
                    style={{
                      paddingRight: 5,
                      letterSpacing: "0.09em",
                      // fontSize: 14,
                      flex: "0.7 0",
                    }}
                  >
                    Color
                  </InputLabel>
                  <ColorSelection
                    className="w-50"
                    label=""
                    placeholder="Select"
                    onChange={(e, data) => {
                      setValue("color", data ? data.value : null);
                    }}
                  />
                </div>
              </div>
              <div className="d-flex align-items-center ml-3 w-100"></div>
              <div className="ag-theme-alpine add-contact-section">
                <AgGridReact
                  onGridReady={onGridReady}
                  enableBrowserTooltips={true}
                  defaultColDef={{
                    resizable: true,
                    sortable: false,
                    sortingOrder: ['asc', 'desc', null]
                  }}
                  cacheBlockSize={PAGE_LIMIT}
                  loadingOverlayComponent={"CustomLoadingOverlayComponent"}
                  frameworkComponents={{
                    CustomLoadingOverlayComponent,
                    IdRenderer,
                  }}
                  getRowNodeId={(data) => data.id}
                  scrollbarWidth={12}
                  suppressHorizontalScroll={false}
                  rowModelType={"infinite"}
                  datasource={dataSource}
                  columnDefs={columnsDef}
                  rowSelection={"single"}
                  suppressRowClickSelection={true}
                ></AgGridReact>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-end bottom-actions">
            {hide ? (
              <div style={{ flex: 1 }}>
                <lable>
                  <Checkbox
                    Checked={checked}
                    onChange={(event) => {
                      const value = event.target.checked;
                      setChecked(value);
                    }}
                  />
                  REMOVE FROM WORKBENCH
                </lable>
              </div>
            ) : (
              ""
            )}
            <Button
              variant="contained"
              color="primary"
              onClick={handleCopyCandidates}
            >
              {buttonText}
            </Button>
          </div>
        </React.Fragment>
      </Popup>
    )
  ) : (
    <Loader show={true} />
  );
}

CopyCandidatesToSearch.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  contactIds: PropTypes.array,
  searchId: PropTypes.string,
  enqueueSnackbar: PropTypes.func,
  header: PropTypes.string,
  buttonText: PropTypes.string,
  uiLabel: PropTypes.string,
  isAllRowsSelected: PropTypes.bool,
  queryData: PropTypes.object,
  contacts: PropTypes.object,
  workbenchId: PropTypes.string,
  hide: PropTypes.bool,
};

export default CopyCandidatesToSearch;
