//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect } from 'react'

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { Typography, FormControl, FormLabel, RadioGroup, FormControlLabel, Checkbox, makeStyles, TextField } from '@material-ui/core'
import NumberFormat from 'react-number-format';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { KeyboardDatePicker } from '@material-ui/pickers';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import InputField from '../../common/InputField'
import CurrencySelection from '../../CurrencySelection';
import { currencyValueSetter, isCurrency, separator } from '../../../Containers/Commons/Utils';
import { DECIMAL_SEPARATOR, THOUSAND_SEPARATOR } from '../../../services/constantService';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCurrencyDetails } from '../../../actions';
import { formatDate } from '../../../utils/date';
export const useStyles = makeStyles(() => ({
  root: {
    height: 30,
    '&$selected': {
      color: "#FFFFFF",
      backgroundColor: "#5C52C6",
      '&:hover': {
        backgroundColor: "#5C52C6",
      },
    },
    backgroundColor: "#FCFCFC",
    "&:focus": {
      outline: 'none'
    }
  },
  selected: {
  },
}))


const CompensationDetails = (props) => {
  const { register, setValue, watch } = props
  const classes = useStyles()
  const dispatch = useDispatch()
  const currencyDetails = useSelector(state => state.commonReducer[watch('currency_iso_code')] ? state.commonReducer[watch('currency_iso_code')] : {})
  useEffect(() => {
    register('compensation_expectation')
    register('currency_iso_code')
    register('base_salary')
    register('previous_compensation')
    register('target_bonus_value')
    register('equity')
    register('actual_bonus_value')
    register('bonus_payout_date')
    register('compensation_updated_date')


  }, [register])

  useEffect(() => {
    const currencyHandler = async () => {
      try {
        await fetchCurrencyDetails(watch('currency_iso_code'), dispatch)
      } catch (e) {
        console.log("Error found in currencyHandler::", e);
      }
    }
    if (watch('currency_iso_code') && Object.keys(currencyDetails).length === 0) {
      currencyHandler()
    }

  }, [watch('currency_iso_code')])

  return (
    <div className="input-form d-flex">
      <div className="section-content flex-grow-1 m-0">
        <Typography className="section-label">Compensation Details</Typography>
        <div className="d-flex flex-grow-1">
          <CurrencySelection
            value={watch('currency_iso_code') || null}
            className="input-form-field input-field-old mt-2"
            InputLabelProps={{ focused: true }}
            onChange={(e, data) => {
              setValue("currency_iso_code", data ? data.code : null);
            }}
          />
          <div className='contact-details'>
            {/* <InputLabel className="contact-view-label mr-2 mt-2 mb-2" >Compensation Expectation</InputLabel> */}
            <NumberFormat
              customInput={TextField}
              label='Compensation Expectation'
              placeholder='Compensation Expectation'
              className='input-form-field input-field-old mr-2 mt-2 mb-2'
              InputLabelProps={{ focused: true }}
              // style={removerBorder}
              style={{ width: "450px" }}
              value={watch("compensation_expectation")}
              thousandSeparator={separator(watch('currency_iso_code'), THOUSAND_SEPARATOR)}
              decimalSeparator={separator(watch('currency_iso_code'), DECIMAL_SEPARATOR)}
              decimalScale={2}
              isNumericString={true}
              onPaste={(e) => {
                e.preventDefault()
              }}
              prefix={currencyDetails ? (currencyDetails.prefix ? currencyDetails.currency_icon : '') : ''}
              suffix={currencyDetails ? (currencyDetails.prefix === false ? currencyDetails.currency_icon : '') : ''}
              onValueChange={(values) => {
                const { value } = values;
                setValue("compensation_expectation", value);
              }}
            />
          </div>
        </div>
        <div className="d-flex flex-grow-1">
          <div className="input-form-field input-field-old"></div>
          <div className='contact-details'>
            {/* <InputLabel className="contact-view-label mr-2 mt-2 mb-2" >Base Salary</InputLabel> */}
            <NumberFormat
              customInput={TextField}
              label='Base Salary'
              placeholder='Base Salary'
              className='input-form-field input-field-old mr-2 mt-2 mb-2'
              InputLabelProps={{ focused: true }}
              // style={removerBorder}
              style={{ width: "450px" }}
              value={watch("base_salary")}
              thousandSeparator={separator(watch('currency_iso_code'), THOUSAND_SEPARATOR)}
              decimalSeparator={separator(watch('currency_iso_code'), DECIMAL_SEPARATOR)}
              decimalScale={2}
              isNumericString={true}
              prefix={currencyDetails ? (currencyDetails.prefix ? currencyDetails.currency_icon : '') : ''}
              suffix={currencyDetails ? (currencyDetails.prefix === false ? currencyDetails.currency_icon : '') : ''}
              onValueChange={(values) => {
                const { value } = values;
                setValue("base_salary", value);
              }}
            />
          </div>
        </div>
        <div className="d-flex flex-grow-1">
          <div className="input-form-field input-field-old d-flex align-items-end">
            <div className="d-flex align-items-center">
              <span style={{ color: 'rgba(92, 82, 198)', marginRight: '10px' }}>Target Bonus</span>
              <ToggleButtonGroup
                ref={register({ name: `target_bonus_type` })}
                exclusive
                value={watch('target_bonus_type')}
                onChange={(e, data) => {
                  setValue("target_bonus_type", data);
                }}
                aria-label="target_bonus_type">
                <ToggleButton classes={classes} value="CURRENCY" aria-label="Currency">
                  <span>{watch('currency_iso_code') ? currencyDetails?.currency_icon : '$'}</span>
                </ToggleButton>
                <ToggleButton classes={classes} value="PERCENTAGE" aria-label="Percentage">
                  <span>%</span>
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          </div>
          <div className='contact-details'>
            {/* <InputLabel className="contact-view-label mr-2 mt-2 mb-2" >Target Bonus</InputLabel> */}
            <NumberFormat
              customInput={TextField}
              label='Target Bonus'
              placeholder='Target Bonus'
              className='input-form-field input-field-old mr-2 mt-2 mb-2'
              InputLabelProps={{ focused: true }}
              // style={removerBorder}
              style={{ width: "450px" }}
              value={watch("target_bonus_value")}
              thousandSeparator={isCurrency('target_bonus_value', watch('target_bonus_type')) ? separator(watch('currency_iso_code'), THOUSAND_SEPARATOR) : false}
              decimalSeparator={separator(watch('currency_iso_code'), DECIMAL_SEPARATOR)}
              decimalScale={2}
              isNumericString={true}
              prefix={isCurrency('target_bonus_value', watch('target_bonus_type')) && currencyDetails ? (currencyDetails.prefix ? currencyDetails.currency_icon : '') : ''}
              suffix={isCurrency('target_bonus_value', watch('target_bonus_type')) && currencyDetails ? (currencyDetails.prefix === false ? currencyDetails.currency_icon : '') : ''}
              onValueChange={(values) => {
                const { value } = values;
                setValue("target_bonus_value", value);
              }}
            />
          </div>
        </div>
        <div className="d-flex flex-grow-1">
          <div className="input-form-field input-field-old d-flex align-items-end">
            <div className="d-flex align-items-center">
              <span style={{ color: 'rgba(92, 82, 198)', marginRight: '10px' }}>Actual Bonus</span>
              <ToggleButtonGroup
                ref={register({ name: `actual_bonus_type` })}
                exclusive
                value={watch('actual_bonus_type')}
                onChange={(e, data) => {
                  setValue("actual_bonus_type", data);
                }}
                aria-label="actual_bonus_type">
                <ToggleButton classes={classes} value="CURRENCY" aria-label="Currency">
                  <span>{watch('currency_iso_code') ? currencyDetails?.currency_icon : '$'}</span>
                </ToggleButton>
                <ToggleButton classes={classes} value="PERCENTAGE" aria-label="Percentage">
                  <span>%</span>
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          </div>
          <div className='contact-details'>
            {/* <InputLabel className="contact-view-label mr-2 mt-2 mb-2" >Actual Bonus</InputLabel> */}
            <NumberFormat
              customInput={TextField}
              label='Actual Bonus'
              placeholder='Actual Bonus'
              className='input-form-field input-field-old mr-2 mt-2 mb-2'
              InputLabelProps={{ focused: true }}
              // style={removerBorder}
              style={{ width: "450px" }}
              value={watch("actual_bonus_value")}
              renderText={(value) => currencyValueSetter(value, watch('currency_iso_code'))}
              thousandSeparator={isCurrency('actual_bonus_value', watch('actual_bonus_type')) ? separator(watch('currency_iso_code'), THOUSAND_SEPARATOR) : false}
              decimalSeparator={separator(watch('currency_iso_code'), DECIMAL_SEPARATOR)}
              decimalScale={2}
              isNumericString={true}
              prefix={isCurrency('actual_bonus_value', watch('actual_bonus_type')) && currencyDetails ? (currencyDetails.prefix ? currencyDetails.currency_icon : '') : ''}
              suffix={isCurrency('actual_bonus_value', watch('actual_bonus_type')) && currencyDetails ? (currencyDetails.prefix === false ? currencyDetails.currency_icon : '') : ''}
              onValueChange={(values) => {
                const { value } = values;
                setValue("actual_bonus_value", value);
              }}
            />
          </div>
        </div>
        <div className="d-flex flex-grow-1">
          <div className="input-form-field input-field-old"></div>
          <InputField ref={register} className="input-form-field input-field-old" label="LTI" placeholder='LTI' InputLabelProps={{ focused: true }} name="equity" />
        </div>
        <div className="d-flex flex-grow-1">
          <KeyboardDatePicker
            className="input-form-field input-field-old"
            //disableToolbar
            ref={register}
            name="bonus_payout_date"
            autoOk
            label="Bonus Payout Date"
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            value={formatDate(watch('bonus_payout_date')) || null}
            InputLabelProps={{ focused: true }}
            placeholder="Bonus Payout Date"
            onChange={(date) => { setValue('bonus_payout_date', formatDate(date)); }}
          />
          <KeyboardDatePicker
            className="input-form-field input-field-old"
            //disableToolbar
            ref={register}
            name = "compensation_updated_date"
            autoOk
            label="Compensation Updated Date"
            variant="inline"
            format="dd/MM/yyyy"
            margin="normal"
            value={formatDate(watch('compensation_updated_date'))|| null}
            InputLabelProps={{ focused: true }}
            placeholder="Compensation Updated Date"
            onChange={(date) => { setValue('compensation_updated_date',formatDate(date)); }}
          />
        </div>
        <div className="d-flex flex-grow-1">
          <InputField ref={register} className="input-form-field input-field-old" label="Compensation Notes" placeholder='Compensation Notes' InputLabelProps={{ focused: true }} name="compensation_notes" />
        </div>
        <div className="d-flex flex-grow-1">
          <div className='contact-details'>
            {/* <InputLabel className="contact-view-label mr-2 mt-2 mb-2" >Previous Compensation</InputLabel> */}
            <NumberFormat
              customInput={TextField}
              label='Previous Compensation'
              placeholder='Previous Compensation'
              className='input-form-field input-field-old mr-2 mt-2 mb-2'
              InputLabelProps={{ focused: true }}
              // style={removerBorder}
              style={{ width: "450px" }}
              value={watch("previous_compensation")}
              thousandSeparator={separator(watch('currency_iso_code'), THOUSAND_SEPARATOR)}
              decimalSeparator={separator(watch('currency_iso_code'), DECIMAL_SEPARATOR)}
              decimalScale={2}
              isNumericString={true}
              prefix={currencyDetails ? (currencyDetails.prefix ? currencyDetails.currency_icon : '') : ''}
              suffix={currencyDetails ? (currencyDetails.prefix === false ? currencyDetails.currency_icon : '') : ''}
              onValueChange={(values) => {
                const { value } = values;
                setValue("previous_compensation", value);
              }}
            />
          </div>
          <div className="input-form-field input-field-old"></div>
        </div>
        <div className="d-flex flex-grow-1 mt-2">
          <FormControl component="fieldset" className="w-100">
            <FormLabel focused={true} component="legend">Compensation Release</FormLabel>
            <RadioGroup row aria-label="compensation_release" name="compensationRelease" className="radio-group">
              <FormControlLabel  className="checkbox-container" value="true" control={<Checkbox className="checkbox-component" defaultChecked={watch('compensation_release')} name="compensation_release" inputRef={register} />} label={watch("compensation_release") ? "Yes" : "No"} />
            </RadioGroup>
          </FormControl>
          <FormControl component="fieldset" className="w-100">
            <FormLabel focused={true} component="legend">Compensation Info Available</FormLabel>
            <RadioGroup row aria-label="compensation_info" name="compensation_info_available" className="radio-group">
              <FormControlLabel className="checkbox-container" value="true" control={<Checkbox className="checkbox-component" defaultChecked={watch("is_compensation_info_available")} name="is_compensation_info_available" inputRef={register} />} label={watch("is_compensation_info_available") ? "Yes" : "No"} />
            </RadioGroup>
          </FormControl>
        </div>
        <div className="d-flex flex-grow-1 mt-2">
          <FormControl component="fieldset" className="w-100">
            <FormLabel focused={true} component="legend">Hide Compensation in ClientSuite</FormLabel>
            <RadioGroup row aria-label="hide_compensation_clientsuite" name="compensationRelease" className="radio-group">
              <FormControlLabel className="checkbox-container" value="true" control={<Checkbox  className="checkbox-component" defaultChecked={watch('hide_compensation_clientsuite')} name="hide_compensation_clientsuite" inputRef={register} />} label={watch("hide_compensation_clientsuite") ? "Yes" : "No"} />
            </RadioGroup>
          </FormControl>
        </div>
      </div>
      <div className="d-flex flex-grow-1">
      </div>
    </div>
  )
}

CompensationDetails.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  control: PropTypes.object,
  watch: PropTypes.func,
  getValues: PropTypes.func
}

export default CompensationDetails;
