
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from "react";

//-----------------------------------------------------------// Internal Imports // ------------------------------

import Searches from "./Searches";

export default function KGPPlacements() {
  return (
    <Searches
      searchType="kgsearches"
      columnStateKey="kgPlacementsColumns"
      searchStatus="Placement"
      title="KG Placements"
      placedSearches
    />
  );
}
