
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useState } from "react";

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from "prop-types";
import { withSnackbar } from "notistack";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";

//-----------------------------------------------------------// Internal Imports // ------------------------------

import {
  columnDefs,
  PAGE_LIMIT,
  SUCCESS_STATUS_CODE,
  CustomLoadingOverlayComponent,
  IdRenderer,
  getFilterParamString
} from "../utils";
import CustomFilter from "../../../Containers/Commons/CustomFilter";
import { Typography } from "@material-ui/core";
import { GET, ERROR, WARNING } from "../../../services/constantService";
import { companyDataApi } from "../../../services/ApiService";
import { unableMessage, notFoundMessage } from "../../../services/MessageService";
import { DateRenderer } from "../../../Containers/Commons/Utils"
let columnApi, gridApi;
function Searches(props) {
  const { companyId, enqueueSnackbar } = props;
  const [count, setCount] = useState(0);
  const onGridReady = (params) => {
    params.columnApi.autoSizeColumns();
    columnApi = params.columnApi;
    gridApi = params.api;
  };

  const onSortChanged = (params => params.api.getSortModel())

  const dataSource = {
    getRows: async (params) => {
      let sub_route = `searches?limit=${PAGE_LIMIT}&page=${params.endRow / PAGE_LIMIT}`
      if (params.sortModel.length > 0) {
        sub_route = sub_route.concat(`${sub_route.includes('?') ? '&' : '?'}sortOn=${params.sortModel[0].colId}&sortType=${params.sortModel[0].sort.toUpperCase()}`)
      }
      if (Object.keys(params.filterModel).length) {
        sub_route = sub_route.concat(getFilterParamString(params.filterModel))
      }
      const { status, data } = await companyDataApi(GET, companyId, {}, sub_route);
      if (status === SUCCESS_STATUS_CODE) {
        if (data?.paging?.totalCount === 0) {
          const message = notFoundMessage("records")
          enqueueSnackbar(message, { variant: WARNING })
        }
        params.successCallback(data.data, data.paging.totalCount)
        setCount(data.paging.totalCount)
      } else {
        params.failCallback();
        const message = unableMessage("users", "fetch")
        enqueueSnackbar(data?.message || message, { variant: ERROR })
      }
    },
    rowCount: null
  }

  const resetFilter = async () => {
    gridApi.setFilterModel(null);
    const customFilterField = columnApi.columnController.columnDefs;
    customFilterField.map(item => {
      if (item.filter === 'CustomFilter') {
        gridApi.destroyFilter(item.field);
      }
    })
  }

  const resetSort = async () => {
    gridApi.setSortModel(null);
  }

  return (
    <div className='h-100' style={{ backgroundColor: 'white' }}>
      <div className="d-flex justify-content-end" style={{ padding: '10px 25px 0 25px' }}>
        <div
          className="action-container"
          style={{ minWidth: 0 }}
          onClick={() => resetFilter()}
        >
          <span className="action-text" >
            Reset Filter
          </span>
        </div>
        <div
          className="action-container"
          style={{ minWidth: 0 }}
          onClick={() => resetSort()}
        >
          <span className="action-text" >
            Reset Sort
          </span>
        </div>
        <Typography >Total count: {count}</Typography>
      </div>
      <div id="myGrid" className="ag-theme-alpine">
        <AgGridReact
          onGridReady={onGridReady}
          enableBrowserTooltips={true}
          defaultColDef={{
            minWidth: 20,
            resizable: true,
            sortable: true,
            sortingOrder: ['asc', 'desc', null]
          }}
          tooltipShowDelay={0}
          scrollbarWidth={12}
          suppressHorizontalScroll={false}
          cacheBlockSize={PAGE_LIMIT}
          loadingOverlayComponent={"CustomLoadingOverlayComponent"}
          frameworkComponents={{
            IdRenderer: IdRenderer,
            CustomLoadingOverlayComponent,
            CustomFilter,
            DateRenderer
          }}
          datasource={dataSource}
          rowModelType={"infinite"}
          columnDefs={columnDefs}
          onSortChanged={onSortChanged}
          paginationPageSize={PAGE_LIMIT}
          colResizeDefault={"shift"}
        ></AgGridReact>
      </div>
    </div>
  );
}

Searches.propTypes = {
  companyId: PropTypes.string,
  enqueueSnackbar: PropTypes.func,
};

export default withSnackbar(Searches);
