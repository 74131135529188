
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useEffect, useRef, useState } from "react";

//-----------------------------------------------------------// External Imports // ------------------------------

import Popup from "reactjs-popup";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useSnackbar } from "notistack";
import CloseIcon from "@material-ui/icons/Close";
import { Button, InputLabel, Typography } from "@material-ui/core";

//-----------------------------------------------------------// Internal Imports // ------------------------------

import { PAGE_LIMIT, SUCCESS_STATUS_CODE } from "./Utils";
import httpService from "../../services/httpService";
import { SEARCH_TIMEOUT } from "../../utils/common";
import Loader from "../../components/common/Loader";
import WorkbenchGrid from "./WorkbenchGrid";
import InputField from "../../components/common/InputField";
import UserSelection from "../../components/UserSelection";
import { columnDefs, getArchiveDateFrom } from "../Workbenches/utils";
import { API, WARNING, WORKBENCHES_MESSAGE } from "../../services/constantService";
import { validateMessage } from "../../services/MessageService";
import { useSelector } from "react-redux";
import { checkWorkbenchAccess } from "../../components/ViewWorkbench/utils";

let gridApi;
const CancelToken = axios.CancelToken;

export default function WorkbenchAction(props) {
  const {
    open,
    contact,
    onClose,
    onSubmit,
    buttonText = "",
    contactIds,
    searchIds,
    title,
    type,
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const { register, watch, setValue } = useForm();
  const name = watch("name");
  const users = watch("user");
  const call = useRef();
  const userData = useSelector(state => state.commonReducer.userData);

  const dataSource = {
    getRows: async (params) => {
      let url = `${API.workbench}?limit=${PAGE_LIMIT}&page=${params.endRow / PAGE_LIMIT
        }&archive_date_from=${getArchiveDateFrom()}&type=${type}`;
      gridApi.showLoadingOverlay();
      const { status, data } = await httpService.get(
        url,
        { contact, name, users },
        {},
        {},
        call.current.token
      );
      gridApi.hideOverlay();
      if (status === SUCCESS_STATUS_CODE) {
        if (Array.isArray(data)) {
          params.successCallback(data, 0);
          gridApi.showNoRowsOverlay();
        } else {
          params.successCallback(data.data, data.paging.totalCount);
        }
      } else {
        params.failCallback();
      }
    },
    rowCount: null,
  };

  const handleSubmit = async () => {
    try{  
      if (gridApi.getSelectedRows().length === 0) {
        const message = validateMessage("workbench", "select");
        enqueueSnackbar(message, { variant: WARNING });
        return;
      }
      const selectedWorkbench = gridApi.getSelectedRows()[0];
      const workbenchAccess = checkWorkbenchAccess(selectedWorkbench, userData)
      if (!workbenchAccess) {
        enqueueSnackbar(WORKBENCHES_MESSAGE.VALIDATION_MESSAGE, { variant: WARNING });
        return
      }

      setIsLoading(true);
      if (type === "searches") {
        await onSubmit(selectedWorkbench.id, searchIds, enqueueSnackbar);
      } else {
        await onSubmit(selectedWorkbench.id, contactIds, enqueueSnackbar);
      }

      setIsLoading(false);
      onClose();
    }catch(e){
      console.log("Error found in handleSubmit::", e);
    }

  };

  useEffect(() => {
    register("user");
  }, [register]);

  useEffect(() => {
    if (call.current) {
      call.current.cancel();
    }
    call.current = CancelToken.source();
    const timer = setTimeout(() => {
      gridApi && gridApi.setDatasource(dataSource);
    }, SEARCH_TIMEOUT);
    return () => clearTimeout(timer);
  }, [contact, name, users, dataSource]);

  const onGridReady = (params) => {
    gridApi = params.api;
    params.columnApi.autoSizeColumns();
  };

  return (
    <Popup
      className="copy-candidates"
      open={open}
      closeOnDocumentClick={false}
      closeOnEscape={false}
    >
      <React.Fragment>
        <Loader show={isLoading} />
        <div className="popup-container">
          <div className="d-flex align-items-center justify-content-between top-bar">
            <Typography className="title">{title}</Typography>
            <CloseIcon className="cursor-pointer" onClick={onClose} />
          </div>
          <div className="inside-container">
            <div className="d-flex">
              <div className="input-field">
                <InputLabel className="m-0 mr-3" focused={true}>
                  Workbench Name
                </InputLabel>
                <InputField
                  placeholder="Search"
                  className="w-50"
                  ref={register}
                  name="name"
                />
              </div>
              <div className="input-field">
                <InputLabel className="m-0 mr-3" focused={true}>
                  User
                </InputLabel>
                <UserSelection
                  className="w-50"
                  label=""
                  onChange={(e, data) =>
                    setValue("user", data ? data.id : null)
                  }
                />
              </div>
            </div>
            <div className="add-contact-section">
              <WorkbenchGrid
                onGridReady={onGridReady}
                columnDefs={columnDefs(true, false)}
              />
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-end bottom-actions">
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            {buttonText}
          </Button>
        </div>
      </React.Fragment>
    </Popup>
  );
}

WorkbenchAction.propTypes = {
  contact: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  buttonText: PropTypes.string,
  onSubmit: PropTypes.func,
  contactIds: PropTypes.array,
  title: PropTypes.string,
  type: PropTypes.string,
  searchIds: PropTypes.array,
};
