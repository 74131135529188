
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useRef, forwardRef, useImperativeHandle } from 'react'

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from 'prop-types'

//-----------------------------------------------------------// Internal Imports // ------------------------------

import Attachments from '../../Attachments';
import { useDispatch, useSelector } from 'react-redux';
import { companyActions } from '../../../store/companySlice';

/* eslint-disable-next-line react/display-name */
const CompanyAttachments = forwardRef((props, ref) => {
  const { companyId } = props
  const companyAttachments = useSelector(state => state.companyReducer.companyAttachments)
  const dispatch = useDispatch();

  const companyDispatch = (data) => {
    dispatch(companyActions.updateCompanyAttachments(data))
  }


  const attachmentRef = useRef();

  useImperativeHandle(ref, () => ({
    handleAddAttachment() {
      attachmentRef.current.handleAddAttachment()
    }
  }));

  return (
    <div className="d-flex attachments-section">
      <div className="notes-attachment-label">Attachments</div>
      <Attachments ref={attachmentRef} attachmentsDetails={companyAttachments} dispatchDetails={companyDispatch} apiConfig={{ parentId: companyId, parentPath: "company" }} className="notes-attachment-content" />
    </div>
  )
})

CompanyAttachments.propTypes = {
  companyId: PropTypes.string,
}

export default CompanyAttachments;
