//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from "prop-types";
import { TextField, ExpansionPanelSummary, ExpansionPanel, ExpansionPanelDetails, FormControlLabel, Switch } from "@material-ui/core";
import NumberFormat from "react-number-format";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import CurrencySelection from "../../../../CurrencySelection";
import InputField from "../../../../common/InputField";
import FeetypeSelection from "../../../../FeetypeSelection";
import { checkBillingRole, checkSearchApprovalRoles, currencyWithCommas, getCurrencyPrefix, separator } from "../../../../../Containers/Commons/Utils";
import { DECIMAL_SEPARATOR, THOUSAND_SEPARATOR, BILLING_VALIDATIONS, PENDING_REJECT_BILLING_STATUS, FEE_TYPES } from "../../../../../services/constantService";
import PercentageOfBase from "../../../../AddBD/BillingDetails/Components/PercentageOfBase";
import TotalRevenue from "../../../../AddBD/BillingDetails/Components/EstimatedRevenue";
import IndirectFee from "../../../../AddBD/BillingDetails/Components/IndirectFee";
import TermsOfInvoiceSelection from "../../../../TermsOfInvoiceSelection";
import TotalRevenueView from "../../../../AddBD/BillingDetails/Components/EstimatedRevenue/TotalRevenueView";
import RevenueMarketSelection from "../../../../RevenueMarketSelection";
import { useSelector } from "react-redux";
import { getUserSelector } from "../../../../../selectors";
import { getFixedDecimal } from "../../../../../utils/common";

export default function FeeInformationView(props) {
  const { register, watch, setValue, /* getValues, */ renderActions, renderFieldLabel, currentValues, editingField, currencyDetails, renderSectionLabel } = props
  const [calculationType, setCalculationType] = useState('estimated');
  const [totalEstimate, setTotalEstimate] = useState(0)
  const [isHide, setIsHide] = useState(false)
  const userData = useSelector(getUserSelector)
  const fe_Cap_amount = watch('fee_cap_amount')
  const is_fee_cap = watch('is_fee_cap')
  // const FEE_TYPE = {
  //   PERCENT_33: '33%',
  //   OTHER_PERCENTAGE: "Other Percentage",
  //   FLAT_FEE: "Flat Fee"
  // }

  // const calculateFees = () => {
  //   const {
  //     fee_type = currentValues.fee_type,
  //   } = getValues();

  //   if (fee_type) {
  //     if (fee_type === FEE_TYPE.PERCENT_33) {
  //       const percentage = '33.33';
  //       setValue("fee_percentage", percentage)
  //     }
  //   } else {
  //     setValue("fee_percentage", null);
  //   }
  // }

  useEffect(() => {
    const disableEdit = checkSearchApprovalRoles(userData)
    if (!disableEdit && PENDING_REJECT_BILLING_STATUS.includes(currentValues.billing_status)) {
      setIsHide(true)
    } else if (currentValues.stage === 'Closed') {
      const editable = checkBillingRole(userData)
      if (!editable) {
        setIsHide(true)
      }
    }
  }, [currentValues])

  useEffect(() => {
    register('fee_type')
    register('actual_total_comp')
    register('estimated_total_comp')
    register('estimated_fee_amount')
    register('fee_amount')
    register('fee_percentage')
    register('currency')
    register('probability')
    register('bd_status')
    register('intervals_other')
    register('intervals')
    register('invoice_terms')
    register('invoice_terms_desc')
    register('term_type')
    register('actual_overridden_revenue')
    register('actual_percentage_bases')
    register('estimated_percentage_bases')
    register('estimated_revenue')
    register('actual_revenue')
    register('is_actual_overridden_revenue')
    register('estimated_indirect_total_fee')
    register('actual_indirect_total_fee')
    register('actual_percentage_base')
    register('estimated_percentage_base')
    register('fee_cap_amount')
    register('by_product_fee')
    register('old_actual_revenue')
    register('markets')
  }, [register])

  useEffect(() => {
    if (watch('actual_percentage_bases')?.find(ele => ele?.type || ele?.actual_amt)) {
      setCalculationType('actual')
    } else {
      setCalculationType('estimated')
    }
  }, [watch('actual_percentage_bases')])

  const handleChange = (e) => {

    setValue(`${e.target.name}`, e.target.value)
  }

  const renderInput = (label, field, extraParams, tooltip, message) => {
    return (
      <div className='d-flex intersection-page-view'>
        {renderFieldLabel(label, field, false, tooltip, message)}
        {
          editingField === 'fee_and_admin_info' ?
            <div className='page-label-value'>
              {
                (field === 'estimated_total_comp') || (field === 'fee_amount') || (field === 'actual_total_comp') || (field === 'estimated_fee_amount') || (field === 'projected_fee') || (field === 'fee_cap_amount') 
                  ?
                  <NumberFormat
                    variant="outlined"
                    // style={removerBorder}
                    customInput={TextField}
                    fullWidth='true'
                    // value={watch(field)}
                    thousandSeparator={separator(watch('currency'), THOUSAND_SEPARATOR)}
                    decimalSeparator={separator(watch('currency'), DECIMAL_SEPARATOR)}
                    decimalScale={2}
                    isNumericString={true}
                    prefix={currencyDetails ? (currencyDetails.prefix ? `${currencyDetails.currency_icon} ` : '') : ''}
                    suffix={currencyDetails ? (currencyDetails.prefix === false ? ` ${currencyDetails.currency_icon}` : '') : ''}
                    onValueChange={(values) => {
                      const { value } = values;
                      setValue(field, value);
                    }}
                    {...extraParams}
                    className="input-form-field"
                  />
                  :
                  <InputField variant="outlined" ref={register} className="input-form-field" label="" name={field} {...extraParams} />
              }
              {renderActions(field)}
            </div>
            :

            (

              ((field === 'estimated_total_comp') || (field === 'fee_amount') || (field === 'actual_total_comp') || (field === 'estimated_fee_amount')) || (field === 'projected_fee') && watch(field)
                ?
                <div className="page-label-value">
                  <NumberFormat
                    style={{ border: 'none' }}
                    value={watch(field) ? watch(field) : 0}
                    displayType='text'
                    thousandSeparator={separator(watch('currency'), THOUSAND_SEPARATOR)}
                    decimalSeparator={separator(watch('currency'), DECIMAL_SEPARATOR)}
                    decimalScale={2}
                    isNumericString={true}
                    prefix={currencyDetails ? (currencyDetails.prefix ? `${currencyDetails.currency_icon} ` : '') : ''}
                    suffix={currencyDetails ? (currencyDetails.prefix === false ? ` ${currencyDetails.currency_icon}` : '') : ''}
                    onValueChange={(values) => {
                      const { value } = values;
                      setValue(field, value);
                    }}
                    variant="outlined"
                    className="input-form-field"
                  />
                </div>
                :
                <span className='page-label-value'> {watch(field) ? ((field === 'fee_cap_amount') ? `${currencyDetails.currency_icon} ` + watch(field) : watch(field)) : '--'}</span>)
        }
      </div>
    )

  }

  const getTotalPercentageOfBase = (data, key, formattedValue) => {
    const currency = getCurrencyPrefix(watch('currency') || '')
    const percentage = data?.reduce((prev, curr) => {
      if (curr[key]) prev = prev + Number(curr[key])
      return prev;
    }, (0)) || 0;
    return formattedValue ? getValueWithCurrency(percentage) : { currency, number: percentage }
  }

  const getIndirectFees = (type) => {
    if (type === 'actual' && calculationType === 'estimated') return [];
    let estimatedIndirectFees = watch(`indirect_fees_searches`) || [];
    return estimatedIndirectFees.map(ele => {
      return {
        ...ele,
        calculation_amt: ele.calculation_type === 'Flat Amount' ? ele.calculation_amt : (getTotalPercentageOfBase(watch(`${type}_percentage_bases`), `calculated_${type}_amt`).number * ele.percentage) / 100
      }
    })
  }

  const getTotalIndirectFee = (type) => {
    return getValueWithCurrency(currentValues[`${type}_indirect_total_fee`] || 0)
  }
  const getRevenue = (type) => {
    return getValueWithCurrency(currentValues[`${type}_revenue`] || 0)
  }
  const getTotalPercentageOfBaseAdd = (type) => {
    setTotalEstimate(currentValues[`${type}_percentage_base`] || 0)
    return getValueWithCurrency(currentValues[`${type}_percentage_base`] || 0);
  }
  const getValueWithCurrency = (data) => {
    return `${getCurrencyPrefix(watch('currency') || '')} ${currencyWithCommas(getFixedDecimal(data || 0).toFixed(2))}`
  }

  const getEstimatedGrandTotal = () => {
    const totalPercentageOfBase = getTotalPercentageOfBase(watch('estimated_percentage_bases'), 'calculated_estimated_amt', false)
    const estimatedIndirectFees = getIndirectFees('estimated')
    const totalIndirectFee = getTotalPercentageOfBase(estimatedIndirectFees, 'calculation_amt')
    setValue('estimated_percentage_base', getFixedDecimal(totalPercentageOfBase.number))
    setValue('estimated_indirect_total_fee', getFixedDecimal(totalIndirectFee.number))
    setValue('estimated_revenue', getFixedDecimal(totalPercentageOfBase?.number + totalIndirectFee?.number))
    setTotalEstimate(getFixedDecimal(totalPercentageOfBase?.number + totalIndirectFee?.number))
    return getValueWithCurrency(totalPercentageOfBase?.number + totalIndirectFee?.number);
  }

  const getActualGrandTotal = () => {
    const totalPercentageOfBase = getTotalPercentageOfBase(watch('actual_percentage_bases'), 'calculated_actual_amt', false)
    const actualIndirectFees = getIndirectFees('actual')
    const totalIndirectFee = getTotalPercentageOfBase(actualIndirectFees, 'calculation_amt')
    setValue('old_actual_revenue', getFixedDecimal(currentValues.actual_revenue))
    setValue('actual_percentage_base', getFixedDecimal(totalPercentageOfBase.number))
    setValue('actual_indirect_total_fee', getFixedDecimal(totalIndirectFee.number))
    setValue('actual_revenue', getFixedDecimal(totalPercentageOfBase?.number + totalIndirectFee?.number))
    return getValueWithCurrency(totalPercentageOfBase?.number + totalIndirectFee?.number);
  }

  return (

    <ExpansionPanel defaultExpanded={true} className="panel-bottom-margin" style={{ background: '#fff', color: '#fff' }}>
      <ExpansionPanelSummary
        expandIcon={
          <ExpandMoreIcon style={{ color: "#fff" }} />
        }
        aria-controls="panel1a-content"
        style={{ background: '#2a364c' }}
      >
        <div className="d-flex" onClick={(e) => e.stopPropagation()}>
          {!isHide ? renderSectionLabel('Fee & Admin Info', 'fee_and_admin_info') : renderSectionLabel('Fee & Admin Info', '')}
          {renderActions('fee_and_admin_info')}
        </div>
      </ExpansionPanelSummary>

      {/* <Typography className="section-label">Client Info</Typography> */}
      <ExpansionPanelDetails className='d-flex flex-column'>
        {/* <Typography className="section-label">Fee Information</Typography> */}
        <div className='d-flex contact-details-row'>
          <div className='d-flex intersection-page-view'>
            {renderFieldLabel('Currency', "currency", editingField === 'fee_and_admin_info')}
            {
              editingField === "fee_and_admin_info" ?
                <div className='page-label-value'>
                  <CurrencySelection
                    label=''
                    value={watch('currency') || null}
                    className="input-form-field"
                    onChange={(e, data) => {
                      setValue("currency", data ? data.code : null);
                    }}
                    variant="outlined"
                  />
                  {renderActions('currency', true)}
                </div>
                :
                <span className='page-label-value'>{currentValues.currency || '--'}</span>
            }
          </div>
          {/* {renderInput('Projected Fee', 'projected_fee', {
            value: watch('projected_fee'),
            onChange: (e) => {
              handleChange(e);
            }
          })} */}
          {renderInput('By Product Fee', 'by_product_fee', {
            // type: 'number',
            
            type: 'number',
            value: watch('by_product_fee'),
            flag: 'true',
            error: (watch('by_product_fee') > 100),
            inputProps: { min: 0,max: 100  },
            onChange: (e) => {
              handleChange(e);
            }
          })
          
          }
        </div>
        <div className='d-flex contact-details-row'>
          <div className='d-flex intersection-page-view'>
            {renderFieldLabel('Fee Cap', "is_fee_cap")}
            {
              editingField === "fee_and_admin_info" ?
                <div className='page-label-value'>
                  <FormControlLabel
                    className="input-form-field checkbox-width"
                    style={{ color: '#5c52c6', margin: '10px 0 0', justifyContent: 'flex-end' }}
                    control={<Switch
                      checked={watch('is_fee_cap') || false}
                      onChange={(e) => {
                        if (!e.target.checked) {
                          setValue('fee_cap_amount', null)
                        }
                        setValue('is_fee_cap', e.target.checked)
                      }}
                      color='primary'
                      inputRef={register}
                      name={'is_fee_cap'}
                    />}
                    label=""
                    labelPlacement="start"
                  />
                  {renderActions('is_fee_cap')}
                </div>
                :
                <span className='page-label-value'>{currentValues.is_fee_cap ? "Yes" : 'No'}</span>
            }

          </div>
          {watch('is_fee_cap') ?
            renderInput('Fee Cap Amount', 'fee_cap_amount', {
              value: watch('fee_cap_amount'),
              onChange: (e) => {
                handleChange(e);
              }
            })
            : <span className="search-details d-flex">&nbsp;</span>
          }
        </div>
        <div className='d-flex contact-details-row'>
          <div className='d-flex intersection-page-view'>
            {renderFieldLabel('Term Type', "term_type")}
            {
              editingField === "fee_and_admin_info" ?
                <div className='page-label-value'>
                  <FeetypeSelection
                    label=""
                    variant="outlined"
                    className="input-form-field"
                    placeholder="Term Type"
                    defaultValue={watch('term_desc') || null}
                    ref={register(`term_desc`)}
                    InputLabelProps={{ focused: true }}
                    type='TERM_TYPE'
                    onChange={(e, data) => {
                      setValue('intervals_desc', null)
                      setValue('intervals', null)
                      setValue('intervals_other', null)
                      setValue('term_type', data ? data.field_value : null)
                      setValue('term_desc', data ? data.short_desc : null)
                    }}
                  />
                  {renderActions('term_type')}
                </div>
                :
                <span className='page-label-value'>{watch('term_desc') || '--'}</span>
            }
          </div>
          <div className='d-flex intersection-page-view'>
            {renderFieldLabel('Intervals', "intervals")}
            {
              editingField === "fee_and_admin_info" ?
                <div className='page-label-value'>
                  <FeetypeSelection
                    variant="outlined"
                    placeholder="Intervals"
                    className="input-form-field"
                    value={watch('intervals_desc') || null}
                    ref={register(`intervals_desc`)}
                    InputLabelProps={{ focused: true }}
                    disabled={!watch('term_desc')}
                    type={watch('term_desc') === 'Milestone' ? 'INTERVAL_MILE' : 'INTERVAL_TIME'}
                    onChange={(e, data) => {
                      if (data?.field_value !== 'Other Interval') {
                        setValue('intervals_other', '');
                      }
                      setValue('intervals', data ? data.field_value : null)
                      setValue('intervals_desc', data ? data.short_desc : null)
                    }}
                    label=""
                  />
                  {renderActions('intervals')}
                </div>
                :
                <span className='page-label-value'>{watch('intervals_desc') || watch('intervals') || '--'}</span>
            }
          </div>
          {/* {renderInput('Projected Fee', 'projected_fee', {
          // type: "number",
          value: watch('projected_fee'),
          onChange: (e) => {
            handleChange(e);
          }
        })} */}
        </div>
        {watch('intervals') === 'Other Interval' &&
          <div className='d-flex contact-details-row'>
            <div className='d-flex intersection-page-view'>
              <div>&nbsp;</div>
            </div>
            <div className="d-flex intersection-page-view">
              {renderFieldLabel('Interval Other', "intervals_other")}
              {
                editingField === "fee_and_admin_info" ?
                  <div className='page-label-value'>

                    <InputField
                      value={watch('intervals_other')}
                      className="input-form-field"
                      label=""
                      placeholder="Interval Other"
                      InputLabelProps={{ focused: true }}
                      name="intervals_other"
                      variant="outlined"
                      onChange={(e) => setValue('intervals_other', e.target.value)}
                    />
                  </div> : <span className='page-label-value text-transform-none'>{watch('intervals_other') || '--'}</span>}
            </div>
          </div>}
        {/* <div className="d-flex contact-details-row">
        <div className="w-50">
          {renderInput('Actual Total Compensation', 'actual_total_comp', {
            // type: "number",
            value: watch('actual_total_comp'),
            onChange: (e) => {
              handleChange(e);
            }
          })}
        </div>
        {renderInput('Estimated Fee Amount', 'estimated_fee_amount', {
          // type: "number",
          value: watch('estimated_fee_amount'),
          onChange: (e) => {
            handleChange(e);
          }
        })}
      </div> */}
        <div className='d-flex contact-details-row'>
          {/* <div className='billing-details'>
          {renderLabel('Fee Type', "fee_type", editingField === 'fee_type')}
          {
            editingField === "fee_type" ?
              <div className='d-flex'>
                <FeetypeSelection
                  label=''
                  className="contact-view-value"
                  value={watch('fee_type') || null}
                  onChange={(e, data) => {
                    setValue("fee_type", data ? data.name : null);
                    calculateFees()
                  }}
                />
                {renderActions('fee_type')}
              </div>
              :
              <span className='contact-view-value'>{watch('fee_type') || '--'}</span>
          }
        </div> */}

          <div className='d-flex intersection-page-view'>
            {renderFieldLabel('Geography', "market", editingField === 'fee_and_admin_info')}
            {
              editingField === "fee_and_admin_info" ?
                <div className='page-label-value'>

                  <RevenueMarketSelection
                    defaultValue={watch('markets') || null}
                    label=''
                    required={true}
                    ref={register(`markets`)}
                    variant='outlined'
                    className="input-form-field"
                    InputLabelProps={{ focused: true }}
                    onChange={(e, data) => {
                      setValue('markets', data ? data : '');
                    }}
                  />
                </div>
                :
                <span className='page-label-value'>{currentValues && currentValues.markets ? currentValues.markets.market : '--'}</span>
            }
          </div>
          <div className="d-flex intersection-page-view">
            {renderFieldLabel('Terms of Invoice', "invoice_terms")}
            {
              editingField === "fee_and_admin_info" ?
                <div className='page-label-value'>
                  <TermsOfInvoiceSelection
                    variant="outlined"
                    className="input-form-field"
                    defaultValue={watch('invoice_terms_desc')}
                    ref={register(`invoice_terms_desc`)}
                    InputLabelProps={{ focused: true }}
                    type='INVOICE_TERMS'
                    onChange={(e, data) => {
                      setValue('invoice_terms', data ? data.field_value : null)
                      setValue('invoice_terms_desc', data ? data.short_desc : null)
                    }}
                    label=""
                  />
                  {renderActions('invoice_terms')}
                </div>
                :
                <span className='page-label-value'>{watch('invoice_terms_desc') || '--'}</span>
            }
          </div>
          {/* {renderInput('By Product Fee', 'by_product_fee', {
            // type: 'number',
            value: watch('by_product_fee'),

            onChange: (e) => {
              handleChange(e);
            }
          })
          } */}
        </div >
        <div className='d-flex contact-details-row'>
          <div className="d-flex intersection-page-view">
            {renderFieldLabel('Fee Type', "fee_type")}
            {
              editingField === "fee_and_admin_info" ?
                <div className='page-label-value'>
                  <FeetypeSelection
                    variant="outlined"
                    className="input-form-field"
                    defaultValue={watch('fee_type_desc') || watch('fee_type') || FEE_TYPES.ONE_THIRD}
                    ref={register(`fee_type_desc`)}
                    InputLabelProps={{ focused: true }}
                    placeholder="Fee Type"
                    type='FEE_TYPE'
                    onChange={(e, data) => {
                      setValue("fee_type", data ? data.field_value : null);
                      setValue("fee_type_desc", data ? data.short_desc : null)
                      setValue('fee_percentage', (!data || data.short_desc === FEE_TYPES.ONE_THIRD) ? 33.33 : (!data || data.short_desc === FEE_TYPES.FIXED_FEE) ? 0 : null)
                    }}
                    label=""
                  />

                  {renderActions('fee_type')}
                </div>
                :
                <span className='page-label-value'>{watch('fee_type_desc') || watch('fee_type') || '--'}</span>
            }
          </div>
          {
            renderInput('Fee Percentage', 'fee_percentage',
              {
                type: 'number',
                value: watch('fee_percentage'),
                flag: 'true',
                error: (watch('fee_percentage') > 100),
                disabled: (watch('fee_type_desc') !== FEE_TYPES.OTHER),
                inputProps: { min: 0 },
                onChange: (e) => {
                  handleChange(e);
                }
              })

          }
        </div>

        {/* <div className='d-flex contact-details-row'>
          <div className="d-flex intersection-page-view">
            {renderFieldLabel('Terms of Invoice', "invoice_terms")}
            {
              editingField === "fee_and_admin_info" ?
                <div className='page-label-value'>
                  <TermsOfInvoiceSelection
                    variant="outlined"
                    className="input-form-field"
                    defaultValue={watch('invoice_terms_desc')}
                    ref={register(`invoice_terms_desc`)}
                    InputLabelProps={{ focused: true }}
                    type='INVOICE_TERMS'
                    onChange={(e, data) => {
                      setValue('invoice_terms', data ? data.field_value : null)
                      setValue('invoice_terms_desc', data ? data.short_desc : null)
                    }}
                    label=""
                  />
                  {renderActions('invoice_terms')}
                </div>
                :
                <span className='page-label-value'>{watch('invoice_terms_desc') || '--'}</span>
            }
          </div>
        </div> */}

        {/* <div className="d-flex contact-details-row">
        <div className='billing-details'>
          {renderInput('Actual Fee Amount', 'fee_amount', {
            // type: "number",
            value: watch('fee_amount'),
            onChange: (e) => {
              handleChange(e);
            }
          })}
        </div>
        <div className='billing-details'>
          {renderLabel('Milestones', "milestones")}
          {
            editingField === "milestones" ?
              <div className='d-flex'>
                <FormControlLabel
                  control={<Checkbox
                    size='small'
                    checked={watch('milestones') || false}
                    onChange={(e) => {
                      setValue('milestones', e.target.checked);
                    }}
                    color="primary"
                    name="milestones"
                    inputRef={register}
                  />}
                  label='Yes'
                />
                {renderActions('milestones')}
              </div>
              :
              <span className='contact-view-value'>{currentValues.milestones ? "Yes" : 'No'}</span>
          }
        </div>
      </div> */}
        {/* <div className="d-flex contact-details-row">
        {renderInput('Probability', 'probability', {
          type: "number",
          value: watch('probability'),
          onChange: (e) => {
            handleChange(e);
          }
        })}
      </div> */}

        <div className='d-flex mt-2' style={{ color: '#000' }} >
          <div className='flex-grow-1 mr-3'>
            <PercentageOfBase
              register={register}
              setValue={setValue}
              currentValues={currentValues}
              watch={watch}
              type='estimated'
              getValueWithCurrency={getValueWithCurrency}
              disabled={(editingField !== 'fee_and_admin_info' || currentValues.billing_status === 'ACK_DONE_EST' || currentValues.billing_status === 'PND_ACK' || currentValues.billing_status === 'ACK_DONE')}
            />
          </div>
          <div className='w-30'>
            {currentValues.billing_status === 'ACK_DONE_EST' || currentValues.billing_status === 'PND_ACK' || currentValues.billing_status === 'ACK_DONE' ? <TotalRevenueView
              getTotalIndirectFee={getTotalIndirectFee}
              type='estimated'
              getRevenue={getRevenue}
              getTotalPercentageOfBase={getTotalPercentageOfBaseAdd}
              billingStatus={currentValues.billing_desc}
            /> : <TotalRevenue
              watch={watch}
              getTotalPercentageOfBase={getTotalPercentageOfBase}
              getGrandTotal={getEstimatedGrandTotal}
              type='estimated'
              getIndirectFees={getIndirectFees}
            />} {
              is_fee_cap && Number(totalEstimate) > Number(fe_Cap_amount) && <div style={{ marginTop: "23px", marginLeft: "15px" }}>
                <span style={{ color: "red", marginTop: "20px" }}>{BILLING_VALIDATIONS.FEE_CAP_AMOUNT}</span>
              </div>
            }
          </div>

        </div>
        <div className='d-flex mt-2' style={{ color: '#000' }}>
          <div className='flex-grow-1 mr-3'>
            <PercentageOfBase
              register={register}
              setValue={setValue}
              currentValues={currentValues}
              watch={watch}
              type='actual'
              disabled={(editingField !== 'fee_and_admin_info' || currentValues.stage === 'BD')}
              getValueWithCurrency={getValueWithCurrency}
            />
          </div>
          <div className='w-30'>
            {currentValues.stage === 'BD' ? <TotalRevenueView
              getTotalIndirectFee={getTotalIndirectFee}
              type='actual'
              getRevenue={getRevenue}
              getTotalPercentageOfBase={getTotalPercentageOfBaseAdd}
              billingStatus={currentValues.billing_desc}
            /> :
              <TotalRevenue
                View={false}
                watch={watch}
                getTotalPercentageOfBase={getTotalPercentageOfBase}
                getGrandTotal={getActualGrandTotal}
                type='actual'
                getIndirectFees={getIndirectFees}
                register={register}
                setValue={setValue}
                currencyDetails={currencyDetails}
                disabled={editingField !== 'fee_and_admin_info'}
                currentValues={currentValues}
              />}

          </div>
        </div>
        <div className='mt-2'>
          <IndirectFee
            register={register}
            setValue={setValue}
            currentValues={currentValues}
            watch={watch}
            getTotalPercentageOfBase={getTotalPercentageOfBase}
            type={calculationType}
            disabled={editingField !== 'fee_and_admin_info'}
            getValueWithCurrency={getValueWithCurrency}
          />
        </div>
      </ExpansionPanelDetails >
    </ExpansionPanel >

  )
}

FeeInformationView.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  control: PropTypes.object,
  getValues: PropTypes.func,
  watch: PropTypes.func,
  renderFieldLabel: PropTypes.func,
  renderActions: PropTypes.func,
  editingField: PropTypes.string,
  currentValues: PropTypes.object,
  currencyDetails: PropTypes.object,
  renderSectionLabel: PropTypes.func
}
