//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react'

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Tabs, Tab } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { Link } from 'react-router-dom';
import HelpOutlineRoundedIcon from '@material-ui/icons/HelpOutlineRounded';
import { Button } from "@material-ui/core";
//----------------------------------------------// Internal Imports // -------------------------------------------------
import './index.scss';
function TabPanel(props) {
  const { children, value, index } = props;

  return (
    value === index && <React.Fragment>
      {children}
    </React.Fragment>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


export default function TabLayoutWithHeader(props) {
  const { header, headerLayout, currentTab, navItems, children, commonHeader, newTabChange, actions = [], tabChange, selectedTabIndex = 0, grid = '', isMasterSearch = false, isActionsOnTop = false, classList, ...rest } = props;
  const [tabValue, setTabValue] = useState();
  const [isMenuOpen, setIsMenuOpen] = useState(true);

  const toggleMenu = () => {
    setIsMenuOpen(prev => !prev);
  };

  useEffect(() => {
    if (tabValue) {
      setTabValue(currentTab)
    }
    else {
      setTabValue(currentTab || selectedTabIndex)
    }
  })


  const buildNavItems = () => {
    let tabContents = children.filter(item => item)
    return navItems.map((item, index) => {
      const disabled = tabContents[index].props.disabled ? tabContents[index].props.disabled : false;
      if (disabled) {
        /* eslint-disable-next-line react/display-name */
        return <Tab key={item} component={React.forwardRef(() => {
          return (
            <div style={{ cursor: 'auto', padding: `20px 20px 0px 35px`, fontWeight: 500, letterSpacing: '0.09em' }}>
              <span style={{ color: 'rgba(93, 89, 163, 0.65)', fontSize: '12px' }}>{item}</span>
            </div>
          )
        })} disabled />
      }
      /* eslint-disable-next-line react/display-name */
      return <Tab disableRipple={true} key={item} component={React.forwardRef((params, ref) => {
        return (
          <div ref={ref} className={`side-nav-item d-flex ${index === tabValue ? 'active' : isMasterSearch ? "sub-menu-master" : " "}`} onClick={params.onClick}>
            <span className="side-nav-text">{item}</span>
          </div>
        )
      })} />
    })
  }

  const buildTabDetails = () => {
    return children.filter(item => item).map((item, index) => {
      return <TabPanel key={index} index={index} value={tabValue}>
        {item}
      </TabPanel>
    })
  }

  const buildActionButtons = () => {
    return actions.map((item, index) => {
      const { label, type = "submit", ...rest } = item;
      return <button key={index} type={type} {...rest}>{label}</button>
    })
  }

  const handleChange = async (event, newValue) => {
    try {
      if (tabChange && (grid.length !== 0 ? await tabChange(tabValue, newValue) === false : await tabChange(tabValue) === false)) {
        return;
      }
      if (!isMasterSearch) {
        setTabValue(newValue);
        newTabChange && newTabChange(newValue)
      }
    } catch (e) {
      console.log("Error found in handleChange::", e);
    }
  };

  const openWidget = () => {
    window.zE("webWidget", "show")
    window.zE("webWidget", "open")
    document.querySelector("#zdLauncher").style.opacity = 0
  }

  return (
    <div className="layout-wrapper d-flex flex-column h-100 w-100" {...rest}>
      <div className={`layout-header d-flex align-items-center ${classList}`}>
        {headerLayout ? headerLayout
          :
          <>
            <Link to={header.route}>
              <span className="header-back-icon">
                <ArrowBackIcon color="primary" />
              </span>
            </Link>
            <span className="header-text">{header.label}</span>
          </>
        }
      </div>
      <div className="layout-content-wrapper d-flex">
        <React.Fragment>
          <div className={`layout-side-nav d-flex flex-column h-100 ${isMenuOpen ? "open" : "closed"}`}>
            <div>
              <span className="hamburger-icon d-inline-block" onClick={toggleMenu}>
                <MenuIcon fontSize="inherit" color="primary" />
              </span>
            </div>
            <div className="layout-side-nav-items w-100">
             
              <Tabs
                value={tabValue}
                orientation="vertical"
                onChange={handleChange}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "transparent",
                  }
                }}
                className={isMasterSearch ? "master-search-side-nav" : ""}
              >
                {buildNavItems(props.navItems)}
              </Tabs>
            </div>
            <Button
              startIcon={<HelpOutlineRoundedIcon />}
              className={isMenuOpen ? '' : 'collapsedBtn'}
              color="primary"
              onClick={openWidget}
              id="zdLauncher"
            >
              {isMenuOpen ? 'Help' : ''}
            </Button>
          </div>
          <div className={`d-flex main-content ${isActionsOnTop ? 'flex-column-reverse' : 'flex-column'}`}>
            <div className={`layout-content d-flex flex-column ${classList} ${actions.length > 0 ? "layout-with-actions" : ''}`}>
              {commonHeader}
              {buildTabDetails(props.children)}
            </div>
            {actions.length > 0 &&
              <div className={`layput-actions d-flex justify-content-end ${classList}`}>
                {buildActionButtons()}
              </div>
            }
          </div>
        </React.Fragment>
      </div>
    </div>
  )
}
TabLayoutWithHeader.propTypes = {
  children: PropTypes.node,
  commonHeader: PropTypes.node,
  header: PropTypes.object,
  headerLayout: PropTypes.node,
  navItems: PropTypes.array.isRequired,
  actions: PropTypes.array,
  tabChange: PropTypes.func,
  isActionsOnTop: PropTypes.bool,
  isMasterSearch: PropTypes.bool,
  selectedTabIndex: PropTypes.number,
  currentTab: PropTypes.number,
  newTabChange: PropTypes.func,
  grid: PropTypes.string,
  classList: PropTypes.string
};
