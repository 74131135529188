
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useEffect, useState } from "react";

//-----------------------------------------------------------// External Imports // ------------------------------

import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import {
  Button,
  FormControl,
  Input,
  InputLabel,
  Typography,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";

//-----------------------------------------------------------// Internal Imports // ------------------------------

import IndustrySelection from "../../../components/IndustrySelection";
import CompanySelection from "../../../components/CompanySelection";
import CountrySelection from "../../../components/CountrySelection";
import SavedQueriesSelection from "./SavedQueriesSelection";
import SaveQueryDialog from "./SaveQueryDialog";
import { getUserSelector } from "../../../selectors";

const defaultValues = {
  country: [],
  vcPe: [],
  industries: [],
};

const Query = (props) => {
  const { handleSubmit, setValue, register, reset, watch } = useForm({
    defaultValues,
  });
  const user = useSelector(getUserSelector);
  const [open, setOpen] = React.useState(false);
  const [queryData, setQueryData] = useState({});

  useEffect(() => {
    register("country");
    register({ name: "currentCompany" });
    register({ name: "vcPe" });
    register({ name: "fromDate" });
    register({ name: "toDate" });
    register({ name: "industries" });
  }, [register]);

  const handleClose = () => {
    setOpen(false);
    setQueryData({});
  };

  const handleSaveQuery = (data) => {
    setQueryData(data);
    setOpen(true);
  };

  const handleSaveQueryChange = (e, data) => {
    if (data) {
      reset({ ...data.query });
      props.handleQuery({ ...data.query });
    }
  };

  const advanceHandler = () => {
    props.pullData()
  }

  return (
    <div className="query">
      <SaveQueryDialog
        open={open}
        queryData={queryData}
        userId={user?.id}
        handleClose={handleClose}
      />
      <div className="d-flex align-items-center">
        <CompanySelection
          multiple
          className="query-input"
          value={watch("currentCompany") || []}
          InputLabelProps={{ focused: true, shrink: true }}
          filterSelectedOptions={true}
          label={"CURRENT COMPANY"}
          placeholder={"Select"}
          onChange={(e, data) => {
            setValue("currentCompany", Array.isArray(data) ? data : []);
          }}
        />
        <FormControl className="query-input">
          <CompanySelection
            multiple
            InputLabelProps={{ focused: true, shrink: true }}
            filterSelectedOptions={true}
            value={watch("vcPe") || []}
            label={"VC/PE FIRM"}
            placeholder="search"
            onChange={(e, data) => {
              setValue("vcPe", Array.isArray(data) ? data : []);
            }}
          />
        </FormControl>
        <IndustrySelection
          multiple
          value={watch("industries") || []}
          className="query-input"
          InputLabelProps={{ focused: true, shrink: true }}
          filterSelectedOptions={true}
          placeholder={"Select"}
          label={"INDUSTRY"}
          onChange={(e, data) => {
            setValue("industries", Array.isArray(data) ? data : []);
          }}
        />
        <CountrySelection
          multiple
          className="query-input"
          value={watch("country") || []}
          filterSelectedOptions
          InputLabelProps={{ focused: true, shrink: true }}
          label={"LOCATION"}
          placeholder={"Search"}
          onChange={(e, data) => {
            setValue("country", Array.isArray(data) ? data : []);
          }}
        />
      </div>
      <div className="d-flex align-items-center second-row">
        <div className="d-flex flex-column date-range">
          <InputLabel
            className="range-label"
            focused
            shrink={true}
            htmlFor="date-picker-inline"
          >
            CREATED DATE RANGE
          </InputLabel>
          <div className="d-flex align-items-center ">
            <KeyboardDatePicker
              //disableToolbar
              autoOk
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              placeholder="From date"
              maxinDate={watch("toDate")}
              value={watch("fromDate") || null}
              onChange={(date) => {
                setValue("fromDate", date);
              }}
            />
            <Typography className="to-label">to</Typography>
            <KeyboardDatePicker
              //disableToolbar
              autoOk
              placeholder="End date"
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              minDate={watch("fromDate")}
              value={watch("toDate") || null}
              onChange={(date) => {
                setValue("toDate", date);
              }}
            />
          </div>
        </div>
        <div className="d-flex flex-column revenue-range">
          <InputLabel
            className="range-label"
            focused
            shrink={true}
            htmlFor="revenue"
          >
            REVENUE RANGE ($)
          </InputLabel>
          <div className="d-flex align-items-center">
            <Input
              inputRef={register}
              name="revenueFrom"
              type="number"
              placeholder="Min"
              id="revenue"
            />
            <Typography className="to-label">to</Typography>
            <Input
              inputRef={register}
              name="revenueTo"
              type="number"
              placeholder="Max"
            />
          </div>
        </div>
        {/*  <Button className={"query-button"} variant={"outlined"} color={"primary"} onClick={handleSubmit(props.handleQuery)}>Search</Button>
        <Button className={"save-query"} variant={"outlined"} color={"primary"} onClick={handleSubmit(handleSaveQuery)}>Save Query</Button>
       */}
      </div>

      <div className="d-flex align-items-center second-row mt-3">
        <div style={{ position: "absolute", right: "80px" }}>
          <Button
            className={"query-button"}
            variant={"outlined"}
            color={"primary"}
            onClick={handleSubmit(props.handleQuery)}
          >
            Search
          </Button>
          <Button
            className={"save-query"}
            variant={"outlined"}
            color={"primary"}
            onClick={handleSubmit(handleSaveQuery)}
          >
            Save Query
          </Button>
        </div>
      </div>

      <div className="d-flex align-items-center justify-content-between  mt-4">
        {props.flag && (
          <Button
            className='mr-3'
            variant='outlined'
            color='primary'
            onClick={advanceHandler}>
            Switch to Advanced Query
          </Button>)}
        <SavedQueriesSelection
          onChange={handleSaveQueryChange}
          style={{ marginLeft: "auto", justifySelf: "end" }}
          className="saved-queries"
          refreshQueries={open}
          //className='query-input'
          InputLabelProps={{ focused: true }}
          userId={user?.id}
          disableClearable
        />
      </div>
    </div>
  );
};

Query.propTypes = {
  handleQuery: PropTypes.func.isRequired,
  queryData: PropTypes.object,
  pullData: PropTypes.func,
  classicApi: PropTypes.func,
  setSelectAllRows: PropTypes.func,
  flag: PropTypes.bool,
};
export default Query;
