
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useState, useEffect } from "react";

//-----------------------------------------------------------// External Imports // ------------------------------

import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import { withSnackbar } from "notistack";
import { Typography } from "@material-ui/core";
import EmailIcon from "@material-ui/icons/Email";
import LanguageIcon from "@material-ui/icons/Language";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import StarIcon from "@material-ui/icons/Star";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";


//-----------------------------------------------------------// Internal Imports // ------------------------------

import PopoverStickOnHover from "../../components/common/PopoverStickOnHover";
import Loader from "../../components/common/Loader";
import SearchItem from "../../components/ViewCompany/Searches/SearchItem";
import { GET, ERROR, API } from "../../services/constantService";
import { companyDataApi } from "../../services/ApiService";
import { unableMessage } from "../../services/MessageService";
import { currencyValueSetter, getCurrencyPrefix, removerBorder } from "../Commons/Utils";
import { getAccessToken } from "../../services/cognitoService";

const NamePopover = (props) => {
  const { company: companyInfo = {}, children, enqueueSnackbar } = props;
  const [company, setCompany] = useState(companyInfo);
  const [searches, setSearches] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [profileUrl, setProfileUrl] = useState(null);


  useEffect(() => {
    const getProfileURL = async () => {
      const token = await getAccessToken();
      setProfileUrl(`${API.companies}/${company.id}/image?token=${token}`);
    };
    if (company.image_id) {
      getProfileURL();
    }
  }, [company.id, company.image_id]);

  const getCompanyDetails = async (id) => {
    const { status, data } = await companyDataApi(GET, id);
    if (status === 200 && data !== null) {
      setCompany(data);
    } else {
      const message = unableMessage("contact details", "fetch");
      enqueueSnackbar(data?.message || message, {
        variant: ERROR,
      });
    }
  };

  const getSearches = async (id) => {
    const sub_route = "searches?stage=Open";
    const { status, data } = await companyDataApi(GET, id, {}, sub_route);
    if (status === 200 && data !== null) {
      setSearches(data.data);
    } else {
      const message = unableMessage("contact details", "fetch");
      enqueueSnackbar(data?.message || message, {
        variant: ERROR,
      });
    }
  };

  const getCompanySearchesContacts = async (id) => {
    if (!id) {
      return;
    }
    try {
      setIsLoading(true);
      await getCompanyDetails(id);
      await getSearches(id);
      setIsDataFetched(true);
      setIsLoading(false);
    } catch (e) {
      console.log("Error found in getCompanySearchesContacts::", e);
    }


  };

  const renderPopoverContent = () => {
    return (
      <Typography className="pop-over-name" component={"div"}>
        <Loader show={isLoading} />
        <div className="name-popover-top-section d-flex">
          <div className="d-flex flex-grow-1  mr-4">
            <label
              className={`profile-image-container contact-profile-image d-flex align-items-center justify-content-center`}
            >
              <span className="image-container">
                {profileUrl ? (
                  <img src={profileUrl} alt="Profile" />
                ) : (
                  <span className="default-profile-icon">
                    <AccountCircleIcon fontSize="inherit" />
                  </span>
                )}
              </span>
              {company.is_rock_star && (
                <div className="contact-rock-star">
                  <StarIcon fontSize="inherit" />
                </div>
              )}
            </label>
            <div className="flex-column">
              <div className="d-flex align-items-center">
                <div className="company-profile-name">
                  {`${company.name || ""}` || "--"}
                </div>
              </div>
              <div className="d-flex align-items-center">
                <div className="company-profile-info-icon">
                  <EmailIcon fontSize="inherit" />
                </div>
                <div className="company-profile-info-text company-profile-location">
                  {company.email ? (
                    <a
                      href={`mailto:${company.email}`}
                      target={"noopener"}
                      className="action-label"
                    >
                      {company.email}
                    </a>
                  ) : (
                    "--"
                  )}
                  <span className="company-profile-info-helper-text">
                    {" "}
                    (Work)
                  </span>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <div className="company-profile-info-icon">
                  <LanguageIcon fontSize="inherit" />
                </div>
                <div className="company-profile-info-text company-profile-location">
                  {company.website ? (
                    <a
                      href={`${company.website}`}
                      target={"noopener"}
                      className="action-label"
                    >
                      {company.website}
                    </a>
                  ) : (
                    "--"
                  )}
                  <span className="company-profile-info-helper-text">
                    {" "}
                    (Work)
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="name-popover-content d-flex flex-grow-1">
          <div className="tab-view-content d-flex flex-column">
            <div className="section-container flex-grow-1">
              <div className="field-details industry-details align-items-start">
                <span className="field-title">Description</span>
                <span
                  className="company-details-value"
                  dangerouslySetInnerHTML={{
                    __html: company.description || "--",
                  }}
                ></span>
              </div>
              <div className="field-details industry-details align-items-start">
                <span className="field-title">Industry</span>
                <span className="field-value">
                  {company.industries ? company.industries.join(", ") : "--"}
                </span>
              </div>
              <div className="field-details industry-details align-items-start">
                <span className="field-title">Revenue</span>
                {company.revenue ? (
                  <div className="field-value">
                    <NumberFormat
                      style={removerBorder}
                      value={company.revenue || "--"}
                      renderText={(value) =>
                        currencyValueSetter(value, company.currency_iso_code)
                      }
                      thousandSeparator={true}
                      displayType={'text'}
                      decimalSeparator='.'
                      prefix={getCurrencyPrefix(company.currency_iso_code !== 'RUR' ? company.currency_iso_code : '')}
                      suffix={getCurrencyPrefix(company.currency_iso_code === 'RUR' ? 'RUR' : '')}
                    />
                  </div>
                ) : (
                  <span className="field-value">{"--"}</span>
                )}
              </div>
              <div className="field-details industry-details align-items-start">
                <span className="field-title">Ticker</span>
                <span className="field-value">
                  {company.ticker_sym ? company.ticker_sym : "--"}
                </span>
              </div>
              <div className="field-details industry-details align-items-start">
                <span className="field-title">Off Limits</span>
                <span className="field-value">
                  {company.off_limits ? 'Yes' : "--"}
                </span>
              </div>
              <div className="field-details industry-details align-items-start">
                <span className="field-title">Off Limits Reason</span>
                <span className="field-value">
                  {company.off_limits_reason
                    ? company.off_limits_reason.replace(/(<([^>]+)>)/gi, "")
                    : "--"}
                </span>
              </div>
              <div className="field-details industry-details align-items-start">
                <span className="field-title">Off Limits Expiration</span>
                <span className="field-value">
                  {company.off_limits_expiration
                    ? company.off_limits_expiration
                    : "--"}
                </span>
              </div>
              <div className="field-details industry-details align-items-start">
                <span className="field-title">Email Syntax</span>
                <span className="field-value">
                  {company.email_syntax ? company.email_syntax : "--"}
                </span>
              </div>
              <div className="field-details industry-details align-items-start">
                <span className="field-title">Parent Company</span>
                <span className="field-value">
                  {company.parent ? company.parent.name : "--"}
                </span>
              </div>
              <div className="field-details industry-details align-items-start">
                <span className="field-title">PE/VC</span>
                <span className="field-value">
                  {company.vc_pe
                    ? company.vc_pe.length > 0
                      ? company.vc_pe
                        .map((item) =>
                          item.company !== null ? item.company.name : ""
                        )
                        .join(",")
                      : "--"
                    : "--"}{" "}
                </span>
              </div>
              <div className="field-details industry-details align-items-start">
                <span className="field-title">CAPITAL STRUCTURE</span>
                <span className="field-value">
                  {company.capital_structure ? company.capital_structure : "--"}
                </span>
              </div>
            </div>
            <div className="section-container flex-grow-1">
              <Typography className="section-header">Address</Typography>
              <div className="field-details address-details">
                <span className="field-title">Street</span>
                <span className="field-value">{company.street || "--"}</span>
              </div>
              <div className="field-details address-details">
                <span className="field-title">City</span>
                <span className="field-value">{company.city || "--"}</span>
              </div>
              <div className="field-details address-details">
                <span className="field-title">State</span>
                <span className="field-value">{company.state || "--"}</span>
              </div>
              <div className="field-details address-details">
                <span className="field-title">Zip Code/Postal Code</span>
                <span className="field-value">{company.zip_code || "--"}</span>
              </div>
              <div className="field-details address-details">
                <span className="field-title">Country</span>
                <span className="field-value">{company.country || "--"}</span>
              </div>
            </div>
          </div>
          <div className="tab-view-right-nav">
            <div className="right-nav-section right-nav-searches-section d-flex flex-column">
              <span className="right-nav-header">
                Open Searches{" "}
                <span style={{ color: "#5C52C6", fontSize: 18 }}>
                  <ZoomInIcon fontSize="inherit" />
                </span>
              </span>
              {searches.length ? (
                <>
                  {searches.map((search) => {
                    return <SearchItem search={search} key={search.id} />;
                  })}
                </>
              ) : (
                "--"
              )}
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end" style={{ padding: 10 }}>
          <Link
            to={{
              pathname: `/companies/${company?.id}/searches`,
              search: "?defaultTab=1",
            }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Typography
              style={{ padding: "10px" }}
              color="primary"
              className="cursor-pointer"
            >
              Searches
            </Typography>
          </Link>
          <Link
            to={{
              pathname: `/companies/${company?.id}/contacts`,
              search: "?defaultTab=3",
            }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Typography
              style={{ padding: "10px" }}
              color="primary"
              className="cursor-pointer"
            >
              Contacts
            </Typography>
          </Link>
        </div>
      </Typography>
    );
  };

  return (
    <PopoverStickOnHover
      component={renderPopoverContent()}
      placement="right-start"
      onMouseEnter={() => { }}
      onMouseLeave={() => {
        setIsDataFetched(false)
      }}
      onEntering={() => {
        !isDataFetched && getCompanySearchesContacts(company?.id);
      }}
      delay={500}
    >
      {children}
    </PopoverStickOnHover>
  );
};

NamePopover.propTypes = {
  company: PropTypes.object,
  children: PropTypes.node,
  handleAddToSearch: PropTypes.func,
  enqueueSnackbar: PropTypes.func,
};

export default withSnackbar(NamePopover);
