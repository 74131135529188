
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from "react";

//-----------------------------------------------------------// Internal Imports // ------------------------------

import Contacts from "./Contacts";

export default function MyMergeContactList() {
  return (
    <Contacts
      type="my-merge-contacts"
      storageKey="contactMyMergeColumns"
      title="Enrich Contacts"
    />
  );
}
