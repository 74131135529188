//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types';

//----------------------------------------------// External Imports // -------------------------------------------------
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from '@material-ui/icons/Search';
import { CircularProgress, Typography, makeStyles } from "@material-ui/core";
import PersonIcon from '@material-ui/icons/Person';
import ListAltIcon from '@material-ui/icons/ListAlt';
import BusinessIcon from '@material-ui/icons/Business';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from "react-redux";

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { SEARCH_TIMEOUT } from "../../utils/common";
import InputField from '../common/InputField';
import { masterSearchApi } from '../../services/ApiService';
import { searchActions } from '../../store/searchSlice';
import { RELOAD_ALL_CANDIDATE_GRID, UPDATE_SFPADATA } from '../../types';
import { contactActions } from '../../store/contactSlice';

const useStyles = makeStyles(() => ({
  root: {
    color: 'white',
    width: '100%'
  },
  inputRoot: {
    color: 'white',
    fontSize: 14
  },
  clearIndicator: {
    color: 'white'
  },
  popper: {
    width: 'auto !important',
    maxWidth: '500px'
  }
}))

export default function MasterSearchSelection(props) {
  const classes = useStyles()
  const history = useHistory()
  const dispatch = useDispatch()

  const { label = "", placeholder = "Search", InputLabelProps, ...rest } = props;
  const [value, setValue] = useState('')
  const [options, setOptions] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (!value || value?.length < 3) {
      setOptions([])
      setLoading(false)
      return;
    }
    setLoading(true)
    const timer = setTimeout(() => {
      const sub_route = `?searchValue=${value}`;
      masterSearchApi(sub_route)
        .then(response => {
          const { status, data } = response;
          if (status === 200) {
            setOptions(data?.results || []);
          }
          setLoading(false)
        })
    }, SEARCH_TIMEOUT)
    return () => clearTimeout(timer);
  }, [value])

  const renderOptionElement = (option) => {
    switch (option.master_search_type || option.backup_search_type) {
    case 'company':
      return <Link to={`/companies/${option.id}`}>
        <Typography className='d-flex master-search-item cursor-pointer' color="primary">
          <BusinessIcon className="mr-2" />
          <div className="d-flex flex-column">
            <Typography>{option.name} {option.country ? <span className="master-search-info"> - {option.country}</span> : ''}</Typography>
          </div>
        </Typography>
      </Link>
    case 'contact':
      return <Link to={`/contacts/${option.id}`}>
        <Typography className='d-flex master-search-item cursor-pointer' color="primary">
          <PersonIcon className="mr-2" />
          <div className="d-flex flex-column">
            <Typography>{option.name}{option.company_name ? <span className="master-search-info"> - {option.company_name}</span> : ''}</Typography>
            {option.job_title ? <span className="master-search-info master-search-info-sec">{option.job_title}</span> : ''}
          </div>
        </Typography>
      </Link>
    case 'search':
      return <Link to={`/searches/${option.id}`}>
        <Typography className='d-flex master-search-item cursor-pointer' color="primary">
          <SearchIcon className="mr-2" />
          <div className="d-flex flex-column">
            <Typography>{option.job_title}{option.job_number ? <span className="master-search-info"> - {option.job_number}</span> : '' }{option.stage ? <span className="master-search-info"> - {option.stage}</span> : ''}</Typography>
            {option.search_type ? <span className="master-search-info master-search-info-sec">{option.search_type}</span> : ''}
          </div>
        </Typography>
      </Link>
    case 'work_bench':
      return <Link to={`/workbenches/${option.id}`}>
        <Typography className='d-flex master-search-item cursor-pointer' color="primary">
          <ListAltIcon className="mr-2" />
          <div className="d-flex flex-column">
            <Typography>{option.name}</Typography>
            {option.type ? <span className="master-search-info master-search-info-sec">{`Workbench ${option.type}`}</span> : ''}
          </div>
        </Typography>
      </Link>
    }
  }

  const handleChange = (e, value) => {
    if (typeof value === 'string') {
      history.push({
        pathname: '/master-search',
        state: value
      });
    }
    if (value && value.id) {
      if (value.type === 'company' || value.master_search_type === 'company') {
        history.push(`/companies/${value.id}`)
      } else if (value.type === 'contact' || value.master_search_type === 'contact') {
        dispatch(contactActions.resetContactState())
        history.push(`/contacts/${value.id}`)
      } else if (value.type === 'search' || value.master_search_type === 'search') {
        dispatch(searchActions.resetSearchState())
        dispatch({ type: UPDATE_SFPADATA, payload: { key: 'sfpaData', undefined } })
        dispatch({ type: RELOAD_ALL_CANDIDATE_GRID })
        history.push(`/searches/${value.id}`)
      } else if (value.type === 'work_bench' || value.master_search_type === 'work_bench') {
        history.push(`/workbenches/${value.id}`)
      }
      setValue(null)
    }
  }
  const checkActionKey = (event, val) => {
    if (event.key === 'Enter') {
      let newValue = value
      setValue(value)
      // event.preventDefault()
      handleChange(null, newValue)
    }else {

      setValue(val)
    }
  }

  return (
    <div className="position-relative header-search">
      <Autocomplete
        {...rest}
        freeSolo
        filterOptions={(options) => options}
        loading={false}
        options={options}
        autoSelect={false}
        // onKeyPress={checkActionKey}
        classes={classes}
        getOptionLabel={(option) => option.name || option.job_title}
        onInputChange={(e, val)=>checkActionKey(e, val)}
        getOptionSelected={(option, value) => (option.id === value.id)}
        renderOption={option => renderOptionElement(option)}
        onChange={(e, value) => handleChange(e, value)}
        renderInput={(params) => (
          <InputField
            {...params}
            InputLabelProps={InputLabelProps}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              startAdornment: (
                <span className="search-icon">
                  <SearchIcon fontSize="inherit" />
                </span>
              ),
              endAdornment: (
                <React.Fragment>
                  {isLoading ? <span color="white"><CircularProgress color="inherit" size={20} /></span> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            variant="standard"
            label={label}
            placeholder={placeholder}
          />
        )}
      />
    </div>
  )
}

MasterSearchSelection.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  InputLabelProps: PropTypes.object,
  required: PropTypes.bool,
}
