//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from "prop-types";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { fetchPickList } from "../../../actions";

function PracticeSelection(props) {
  const {
    register,
    className = 'w-100',
    label = "Vertical Practice",
    name = 'vertical_practice',
    placeholder = "Vertical Practice",
    InputLabelProps,
    type,
    variant = "standard",
    ...rest
  } = props
  const reduxStateKey = type === 'vertical' ? 'verticalPractice' : 'horizontalPractice'
  const [options, setOptions] = useState([])
  const practice = useSelector(state => state.commonReducer[reduxStateKey])
  const dispatch = useDispatch()

  useEffect(() => {
    if (!practice) {
      let sub_route = type === 'vertical' ? 'vertical-practice' : 'horizontal-practice';
      dispatch(fetchPickList(sub_route, reduxStateKey))
    } else {
      setOptions(practice);
    }
  }, [practice, dispatch])

  return (
    <Autocomplete
      {...rest}
      className={className}
      options={options}
      getOptionLabel={option => {
        if (typeof option === 'string') {
          return option
        }
        return option.name
      }}
      getOptionSelected={(option, value) => {
        if (!value) {
          return false;
        }
        if (typeof value === 'string') {
          return option.name === value;
        }
        return option.name === value.name
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          inputRef={register}
          name={name}
          InputLabelProps={InputLabelProps}
          label={label}
          placeholder={placeholder}
          variant={variant}
        />
      )}
    />
  )
}

PracticeSelection.propTypes = {
  register: PropTypes.func,
  className: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  InputLabelProps: PropTypes.object,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  variant: PropTypes.string
}

export default PracticeSelection;
