//----------------------------------------------// In-built Imports // -------------------------------------------------
import React from 'react'

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types'
import { CircularProgress, Typography } from '@material-ui/core';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import './index.scss'

export default function Loader(props) {
  const { show, className, loadingMessage } = props;

  return (
    show &&
    <>
      <div className={`loader-container ${className || ''}`}>
        <CircularProgress />
        {loadingMessage && <Typography color='primary' style={{ fontSize: 20 }}  >{loadingMessage}</Typography>}
      </div>
    </>
  )
}

Loader.propTypes = {
  show: PropTypes.bool,
  className: PropTypes.string,
  loadingMessage: PropTypes.string
}
