
//-----------------------------------------------------------// Internal Imports // ------------------------------

import {
  AWS_APP_CLIENT_ID_DEV,
  AWS_DOMAIN_DEV,
  AWS_DOMAIN_PROD,
  AWS_TOKEN_ENDPOINT,
  AWS_APP_CLIENT_ID_PROD,
  AWS_APP_REDIRECT_PATH,
  GALAXY_HL_SSO_URL_DEV,
  GALAXY_HL_SSO_URL_PROD,
  LOCAL_URL,
  STAGE_URL,
  TEST_URL,
  LAB_URL,
  PROD_URL2,
  LOCAL_SOCKET_URL,
  PROD_URL3
} from "../../services/constantService";
import { setEnvironment } from "./TokenValidator";

export const getGalaxyAppRedirectUrl = () => {
  setEnvironment();
  const env = localStorage.getItem("env");
  let url = "";
  if (env === "stage") {
    url = STAGE_URL + AWS_APP_REDIRECT_PATH;
  } else if (env === "prod") {
    url = PROD_URL2 + AWS_APP_REDIRECT_PATH;
  } else if (env === "prod-asia") {
    url = PROD_URL3 + AWS_APP_REDIRECT_PATH;
  } else if (env === "test") {
    url = TEST_URL + AWS_APP_REDIRECT_PATH;
  } else if (env === "lab-test") {
    url = LAB_URL + AWS_APP_REDIRECT_PATH;
  } else if (env === "local-prod") {
    url = LOCAL_URL + AWS_APP_REDIRECT_PATH;
  } else {
    url = LOCAL_URL + AWS_APP_REDIRECT_PATH;
  }
  return url;
};

export const getServerSocketConfig = () => {
  setEnvironment();
  const env = localStorage.getItem("env");
  let url = "";
  const options = { secure: true, upgrade: false, transports: ['websocket'] }
  if (env === "stage") {
    url = STAGE_URL;
  } else if (env === "prod") {
    url = PROD_URL2;
  } else if (env === "prod-asia") {
    url = PROD_URL3;
  } else if (env === "test") {
    url = TEST_URL;
  } else if (env === "lab-test") {
    url = LAB_URL;
  } else if (env === "local-prod") {
    url = LOCAL_SOCKET_URL;
    options.secure = false
  } else {
    url = LOCAL_SOCKET_URL;
    options.secure = false
  }
  return {
    url,
    options
  };
};

export const getHostedUILoginUrl = () => {
  setEnvironment();
  const env = localStorage.getItem("env");
  let url = "";
  if (env === "stage") {
    url = GALAXY_HL_SSO_URL_PROD + STAGE_URL + AWS_APP_REDIRECT_PATH;
  } else if (env === "prod") {
    url = GALAXY_HL_SSO_URL_PROD + PROD_URL2 + AWS_APP_REDIRECT_PATH;
  } else if (env === "prod-asia") {
    url = GALAXY_HL_SSO_URL_PROD + PROD_URL3 + AWS_APP_REDIRECT_PATH;
  } else if (env === "test") {
    url = GALAXY_HL_SSO_URL_DEV + TEST_URL + AWS_APP_REDIRECT_PATH;
  } else if (env === "lab-test") {
    url = GALAXY_HL_SSO_URL_DEV + LAB_URL + AWS_APP_REDIRECT_PATH;
  } else if (env === "local-prod") {
    url = GALAXY_HL_SSO_URL_PROD + LOCAL_URL + AWS_APP_REDIRECT_PATH;
  } else {
    url = GALAXY_HL_SSO_URL_DEV + LOCAL_URL + AWS_APP_REDIRECT_PATH;
  }
  return url;
};

export const getAwsTokenApiEndPoint = () => {
  setEnvironment();
  const env = localStorage.getItem("env");
  let url = "";
  if (env === "stage") {
    // used prod url for stage
    url = AWS_DOMAIN_PROD + AWS_TOKEN_ENDPOINT;
  } else if (env === "prod" || env === "local-prod" || env === "prod-asia") {
    url = AWS_DOMAIN_PROD + AWS_TOKEN_ENDPOINT;
  } else if (env === "test") {
    url = AWS_DOMAIN_DEV + AWS_TOKEN_ENDPOINT;
  } else {
    url = AWS_DOMAIN_DEV + AWS_TOKEN_ENDPOINT;
  }
  return url;
};

export const getAwsAppClientId = () => {
  setEnvironment();
  const env = localStorage.getItem("env");
  let id = "";
  if (env === "stage") {
    // used prod client id for stage
    id = AWS_APP_CLIENT_ID_PROD;
  } else if (env === "prod" || env === "local-prod" || env === "prod-asia") {
    id = AWS_APP_CLIENT_ID_PROD;
  } else if (env === "test") {
    id = AWS_APP_CLIENT_ID_DEV;
  } else {
    id = AWS_APP_CLIENT_ID_DEV;
  }
  return id;
};
