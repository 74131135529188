//----------------------------------------------// In-built Imports // -------------------------------------------------
import React from 'react'

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import InputField from "../../../../common/InputField";

//generates array with decimal numbers EX: [0, 0.1 ... 5]
const options = Array.from(Array(51).keys()).map(key => key/10)

export default function RatingSelection(props) {
  const {label = "", placeholder = "", InputLabelProps, required, ...rest} = props;

  return (
    <Autocomplete
      {...rest}
      options={options}
      getOptionLabel={option => String(option)}
      renderInput={(params) => (
        <InputField
          {...params}
          InputLabelProps={InputLabelProps}
          required={required}
          variant="standard"
          label={label}
          placeholder={placeholder}
        />
      )}
    />
  )
}

RatingSelection.propTypes = {
  label:PropTypes.string,
  placeholder:PropTypes.string,
  InputLabelProps:PropTypes.object,
  required:PropTypes.bool,
  options: PropTypes.array
}
