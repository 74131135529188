//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useState, useEffect } from 'react'

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import Autocomplete from '@material-ui/lab/Autocomplete';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import InputField from './common/InputField';
import { fetchPickList } from "../actions";
import { LABELS_AND_PLACEHOLDERS, ROUTES, ROUTE_KEY } from '../services/constantService';


const ExpertiseSelection = (props) => {
  const { label = LABELS_AND_PLACEHOLDERS.expertiseLevel, disabled = false, placeholder = LABELS_AND_PLACEHOLDERS.expertiseLevel, InputLabelProps, required, ...rest } = props;

  const [options, setOptions] = useState([]);
  const languages_expertise = useSelector(state => state.commonReducer.languageExpertise)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!languages_expertise) {
      dispatch(fetchPickList(ROUTES.languageExpertise, ROUTE_KEY.languageExpertise))
    } else {
      setOptions(languages_expertise);
    }
  }, [languages_expertise, dispatch])

  return (
    <Autocomplete
      {...rest}
      options={options}
      getOptionSelected={(option, value) => {
        if (typeof value === 'string') {
          return option.level === value;
        }
        return option.level === value.level
      }}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option
        }
        return option.level
      }}
      disabled={disabled}
      renderInput={(params) => (
        <InputField
          {...params}
          InputLabelProps={InputLabelProps}
          required={required}
          variant="standard"
          label={label}
          placeholder={placeholder}
        />
      )}
    />
  )
}

ExpertiseSelection.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  InputLabelProps: PropTypes.object,
  required: PropTypes.bool,
  disabled: PropTypes.bool
}

export default ExpertiseSelection;
