//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react'

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import AddCircleIcon from "@material-ui/icons/AddCircle";
import Popup from "reactjs-popup";
import { Button, CircularProgress } from "@material-ui/core";
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import InputField from '../../common/InputField';
import QuickAddCompany from "./QuickAddCompany";
import { SEARCH_TIMEOUT } from "../../../utils/common";
import { GET } from '../../../services/constantService';
import { picklistsDataApi } from '../../../services/ApiService';

const filter = createFilterOptions();
function CompanySelection(props) {
  const {
    label = "Company",
    placeholder = "Company",
    InputLabelProps,
    required,
    defaultValue = null,
    onChange,
    inputRef,
    startAdornment,
    onInputChange,
    freeSolo = false,
    autoFocus,
    includeVcpe,
    disabled = false,
    variant = 'standard',
    ...rest
  } = props;
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, toggleOpen] = React.useState(false);
  const [name, setName] = useState('');
  const [value, setValue] = useState(null);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue, setValue])

  useEffect(() => {
    if (name.length < 1) {
      setOptions([])
      setLoading(false)
      return;
    }
    setLoading(true)
    const timer = setTimeout(() => {
      const value = encodeURIComponent(name);
      const sub_route = `companies?name=${value}&includeVcpe=${includeVcpe}`
      picklistsDataApi(GET, '', sub_route)
        .then(response => {
          const { status, data } = response;
          if (status === 200 && data.data) {
            setOptions(data.data);
          }
          setLoading(false)
        })
    }, SEARCH_TIMEOUT)
    return () => clearTimeout(timer);
  }, [name, includeVcpe])

  const addCompany = (company) => {
    setOptions(prevState => ([company, ...prevState]))
    setValue(company)
    onChange(new Event('onChange'), company);
    toggleOpen(false)
  }

  const handleClose = () => {
    toggleOpen(false)
  }

  return (
    <React.Fragment>
      <Popup open={open} className={"quick-add-company"} onClose={handleClose} closeOnDocumentClick={false} closeOnEscape={false}>
        <QuickAddCompany handleClose={handleClose} addCompany={addCompany} name={name} />
      </Popup>
      <Autocomplete
        freeSolo={freeSolo}
        {...rest}
        value={value}
        selectOnFocus
        options={options}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option
          }
          return option.name
        }}
        onInputChange={(e, data, reason) => {
          if (reason === 'input') {
            setName(data);
          }
          onInputChange && onInputChange(e, data)
        }}
        onChange={(event, newValue) => {
          if (newValue && newValue.inputValue) {
            toggleOpen(true);
            setName(newValue.inputValue)
            return;
          }
          setValue(newValue);
          onChange(event, newValue)
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params)
          if (params.inputValue) {
            filtered.push({
              inputValue: params.inputValue,
            })
          }
          return filtered
        }}
        disabled={disabled}
        renderOption={(option => {
          if (option.inputValue) {
            return (
              <div style={{ width: '100%', borderTop: '1px solid #dfdfdf' }}>
                <Button
                  variant={"text"}
                  startIcon={<AddCircleIcon />}
                  color='primary'>
                  ADD NEW COMPANY
                </Button>
              </div>
            )
          }
          return option.name
        })}
        getOptionSelected={(option, value) => {
          if (typeof value === 'string') {
            return option.name === value;
          }
          return option.id === value.id
        }}
        renderInput={(params) => (
          <InputField
            {...params}
            autoFocus={autoFocus}
            ref={inputRef}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="primary" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
              startAdornment
            }}
            InputLabelProps={InputLabelProps}
            required={required}
            variant={variant}
            label={label}
            placeholder={placeholder}
          />
        )}
      />
    </React.Fragment>
  )
}

CompanySelection.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  InputLabelProps: PropTypes.object,
  required: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  onChange: PropTypes.func,
  inputRef: PropTypes.object,
  startAdornment: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  freeSolo: PropTypes.bool,
  onInputChange: PropTypes.func,
  autoFocus: PropTypes.bool,
  includeVcpe: PropTypes.bool,
  disabled: PropTypes.bool,
  variant: PropTypes.string
}

export default CompanySelection;
