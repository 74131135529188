//----------------------------------------------// In-built Imports // -------------------------------------------------
import React from 'react'

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types'
import { CircularProgress, Typography } from '@material-ui/core';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import './index.scss'


export default function Loader(props) {
  const {show, message, className} = props;

  return (
    show &&
    <div className={`loader-container ${className || ''}`}>
      <Typography color='primary' style={{fontSize:24, margin:20}}>{message}</Typography>
      <CircularProgress  />
    </div>
  )
}

Loader.propTypes = {
  show: PropTypes.bool,
  message:PropTypes.string,
  className: PropTypes.string
}
