//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react'

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types'
import Popup from 'reactjs-popup'
import { withSnackbar } from 'notistack';
import { useForm } from "react-hook-form";

//----------------------------------------------// Internal Imports // -------------------------------------------------

import EditTranslatePopup from './EditTranslatePopup';
import ViewTranslatePopup from './ViewTranslatePopup';
import './index.scss'

function TranslateView(props) {
  const { onClose, translateData, setTranslateData } = props;
  const [isEditing, setIsEditing] = useState(false);
  const [localTranslateValue] = useState(translateData);
  const [isDataUpdated, setIsDataUpdated ] = useState(false);
  const [defaultValues, setDefaultValues] = useState({});

  const { register, reset } = useForm({ defaultValues });

  const isFormInEditMode = () => {
    setIsEditing(true)
  }

  useEffect(() => {
    reset(defaultValues)
  }, [reset, defaultValues])

  useEffect(() => {
    setDefaultValues({
      org_id: localTranslateValue.org_id,
      field_name: localTranslateValue.field_name,
      field_value: localTranslateValue.field_value,
      lang_cd: localTranslateValue.lang_cd,
      short_desc: localTranslateValue.short_desc,
      long_desc: localTranslateValue.long_desc
    })
  }, [localTranslateValue, setDefaultValues])

  useEffect(() => {
    register({ name: "org_id" });
    register({ name: 'field_name' });
    register({ name: "field_value" });
    register({ name: "lang_cd" });
    register({ name: "short_desc" });
    register({ name: "long_desc" });
  }, [register]);

  const onPopupClose = () => {
    onClose(isDataUpdated);

  };
  return (
    <Popup open={props.visible} modal onClose={onPopupClose} className={"add-translate-value"} closeOnDocumentClick={false} closeOnEscape={false} >
      {isEditing ? <EditTranslatePopup translateData={translateData} closePopup={onPopupClose} setIsEditing={setIsEditing} setTranslateData={setTranslateData} setIsDataUpdated={setIsDataUpdated} /> : <ViewTranslatePopup editForm={isFormInEditMode} translateData={translateData} closePopup={onPopupClose} />}
    </Popup>
  )
}

TranslateView.propTypes = {
  onClose: PropTypes.func,
  translateData: PropTypes.object,
  visible: PropTypes.bool,
  enqueueSnackbar: PropTypes.func,
  closeSnackbar: PropTypes.func,
  setTranslateData: PropTypes.func
};

export default withSnackbar(TranslateView)