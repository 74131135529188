//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useEffect } from "react";

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from "prop-types";
import { Checkbox, FormControl, FormControlLabel, TextField, Typography } from "@material-ui/core";
import ReactPhoneInput from 'react-phone-input-material-ui';
import 'react-phone-input-mui/dist/style.css';
import { withSnackbar } from 'notistack';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import InputField from "../../../../components/common/InputField";
import UserSelection from "../../../UserSelection";
import { determineInitialCountryGuess } from "../../../../utils/phone";
import { EMAIL_REGEX } from "../../../../services/constantService";
function CommunicationDetails(props) {
  const { register, watch, setValue, defaultValues, option } = props;
  useEffect(() => {
    register('bd_target')
    register('kg')
  }, [register])

  const isValidEmail = (data) => {

    const result = EMAIL_REGEX.test(data);
    if (data.trim().length > 0 && !result) {
      return false;
    }
    return true;
  }

  return (
    <div className='w-100 section-content'>
      <Typography className='section-label'>Communication</Typography>
      <div className='d-flex flex-grow-1'>
        <div className='input-form-field input-field-old'>
          <ReactPhoneInput
            ref={register('direct_line')}
            placeholder='Direct Line'
            value={watch('direct_line') || ''}
            onChange={(data, c, e, value) => {
              if (data.length > 1) {
                setValue('direct_line', value)
              }
              else {
                setValue('direct_line', '')
              }
            }}
            name='direct_line'
            enableSearch={true}
            disableInitialCountryGuess={determineInitialCountryGuess(defaultValues.direct_line)}
            inputProps={{
              label: 'Direct Line',
              InputLabelProps: { focused: true }
            }}
            component={TextField}
          />
        </div>
        <div className='input-form-field input-field-old'>
          <ReactPhoneInput
            ref={register('mobile_phone')}
            placeholder='Mobile Phone'
            value={watch('mobile_phone') || ''}
            onChange={(data, c, e, value) => {
              if (data.length > 1) {
                setValue('mobile_phone', value)
              }
              else {
                setValue('mobile_phone', '')
              }
            }}
            enableSearch={true}
            disableInitialCountryGuess={determineInitialCountryGuess(defaultValues.mobile_phone)}
            inputProps={{
              label: 'Mobile Phone',
              InputLabelProps: { focused: true }
            }}
            component={TextField}
          />
        </div>
      </div>
      <div className='d-flex flex-grow-1'>
        <div className='input-form-field input-field-old'>
          <ReactPhoneInput
            ref={register('home_phone')}
            placeholder='Home Phone'
            value={watch('home_phone') || ''}
            onChange={(data, c, e, value) => {
              if (data.length > 1) {
                setValue('home_phone', value)
              }
              else {
                setValue('home_phone', '')
              }
            }}
            enableSearch={true}
            disableInitialCountryGuess={determineInitialCountryGuess(defaultValues.home_phone)}
            inputProps={{
              label: 'Home Phone',
              InputLabelProps: { focused: true }
            }}
            component={TextField}
          />
        </div>
        <InputField
          ref={register({ validate: isValidEmail })}
          className='input-form-field input-field-old'
          type='email'
          label='Work Email'
          placeholder='Work Email'
          InputLabelProps={{ focused: true }}
          name='work_email'
        />
      </div>
      <div className='d-flex flex-grow-1'>
        <InputField
          ref={register({ validate: isValidEmail })}
          className='input-form-field input-field-old'
          type='email'
          label='Private Email'
          placeholder='Private Email'
          InputLabelProps={{ focused: true }}
          name='private_email'
        />
        <InputField
          ref={register}
          name='skype_id'
          className='input-form-field input-field-old'
          label="Skype ID"
          placeholder='Skype ID'
          InputLabelProps={{ focused: true }}
        />
      </div>
      <div className="d-flex flex-grow-1">
        <FormControl className='w-100' style={{ paddingRight: 20 }}>
          <Typography className={'input-label'}>
            Contact Preference
          </Typography>
          <FormControlLabel
            className="checkbox-container"
            control={<Checkbox className="checkbox-component" defaultChecked={defaultValues['do_not_call']} size="small" style={{ paddingRight: 5 }} name='do_not_call' inputRef={register} />}
            label='Do Not Call'
          />
        </FormControl>
        {watch('do_not_call') &&
          <InputField
            ref={register}
            className='input-form-field input-field-old'
            label='Do not call Reason'
            placeholder='Do not call Reason'
            InputLabelProps={{ focused: true }}
            name='do_not_call_reason'
          />
        }
      </div>
      <div className='d-flex flex-grow-1'>
        <FormControl className='w-100' style={{ paddingRight: 20 }}>
          <Typography className={'input-label'}>
            Non compete
          </Typography>
          <FormControlLabel
            className="checkbox-container"
            control={<Checkbox className="checkbox-component" defaultChecked={defaultValues.is_non_compete} size="small" style={{ paddingRight: 5 }} name='is_non_compete' inputRef={register} />}
            label='Yes'
          />
        </FormControl>
        <FormControl className='w-100'>
          <Typography className={'input-label'}>
            Executive Assistant
          </Typography>
          <FormControlLabel
            className="checkbox-container"
            control={<Checkbox className="checkbox-component" size="small" style={{ paddingRight: 5 }} name='is_assistant' inputRef={register} />}
            label='Yes'
          />
        </FormControl>
      </div>
      {
        watch('is_assistant') &&
        <div className='d-flex flex-grow-1'>
          <InputField
            ref={register}
            className='input-form-field input-field-old'
            label='Executive Assistant Name'
            placeholder='Executive Assistant Name'
            InputLabelProps={{ focused: true }}
            name='assistant_name'
          />
          <div className='input-form-field input-field-old'>
            <ReactPhoneInput
              ref={register('assistant_direct_line')}
              placeholder='Direct Line'
              value={watch('assistant_direct_line') || ''}
              onChange={(data, c, e, value) => {
                if (data.length > 1) {
                  setValue('assistant_direct_line', value)
                }
                else {
                  setValue('assistant_direct_line', '')
                }
              }}
              enableSearch={true}
              disableInitialCountryGuess={determineInitialCountryGuess(defaultValues.assistant_direct_line)}
              inputProps={{
                label: 'Executive Assistant Direct Line',
                InputLabelProps: { focused: true }
              }}
              component={TextField}
            />
          </div>
        </div>
      }
      {
        watch('is_executive_assistant') &&
        <div className='d-flex flex-grow-1'>
          <div className='input-form-field input-field-old'>
            <ReactPhoneInput
              ref={register('assistant_mobile_phone')}
              placeholder='Home Phone'
              value={watch('assistant_mobile_phone') || ''}
              onChange={(data, c, e, value) => {
                if (data.length > 1) {
                  setValue('assistant_mobile_phone', value)
                }
                else {
                  setValue('assistant_mobile_phone', '')
                }
              }}
              enableSearch={true}
              disableInitialCountryGuess={determineInitialCountryGuess(defaultValues.assistant_mobile_phone)}
              inputProps={{
                label: 'Executive Assistant Mobile'
              }}
              component={TextField}
            />
          </div>
          <InputField
            ref={register}
            type="email"
            className='input-form-field input-field-old'
            label='Executive Assistant Work Email'
            InputLabelProps={{ focused: true }}
            name='assistant_email'
          />
        </div>
      }
      <div className='d-flex'>
        <UserSelection
          option={option}
          value={watch('bd_target') || ''}
          className='input-form-field input-field-old'
          label='BD Target'
          placeholder="BD Target"
          InputLabelProps={{ focused: true }}
          onChange={(e, data) => { setValue('bd_target', data) }}
        />
        <InputField
          ref={register}
          className='input-form-field input-field-old'
          label='Relationship'
          placeholder='Relationship'
          InputLabelProps={{ focused: true }}
          name='relationship'
        />
      </div>
      <div className='d-flex w-50'>
        <UserSelection
          option={option}
          multiple
          InputLabelProps={{ focused: true, shrink: true }}
          filterSelectedOptions={true}
          value={watch('kg') || []}
          className='input-form-field input-field-old'
          label='KG 25'
          name='kg'
          onChange={(e, data) => {
            setValue('kg', Array.isArray(data) ? data : []);
          }}
        />
      </div>
    </div>
  );
}
CommunicationDetails.propTypes = {
  register: PropTypes.func,
  control: PropTypes.object,
  dynamicKey: PropTypes.string,
  watch: PropTypes.func,
  setValue: PropTypes.func,
  defaultValues: PropTypes.object,
  option: PropTypes.array
};

export default withSnackbar(CommunicationDetails)