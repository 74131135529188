//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useEffect, useState } from 'react'

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from 'prop-types';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import { API, ERROR, GET } from '../../services/constantService'
import { dashboardDataApi, picklistsTypeDataApi } from '../../services/ApiService';
import './index.scss'
import { unableMessage } from '../../services/MessageService';
import { useSnackbar } from 'notistack';

const DashboardContent = (props) => {
  const { dashboardName } = props
  const [token, setToken] = useState(null);
  const [dashboardUrl, setDashboardUrl] = useState(null);
  const { enqueueSnackbar } = useSnackbar();

  const getTableauToken = async () => {
    try {
      const url = `${API.dashboard}/authorize`
      const { status, data } = await dashboardDataApi(GET, url)
      if (status === 200) {
        await getDashboardUrl()
        setToken(data.result)
      } else {
        const message = unableMessage("sign token", "get")
        enqueueSnackbar(data?.message || message, { variant: ERROR });
      }
    } catch (err) {
      console.log('error in Fetch Token::', err)
    }
  }

  const getDashboardUrl = async () => {
    try {
      const sub_route = `find?org_id=KG&field_name=TABLEAU_DASHBOARD_URL&field_value=${dashboardName}&lang_cd=ENG`
      const { status, data } = await picklistsTypeDataApi(GET, sub_route)
      if (status === 200) {
        setDashboardUrl(data[0].short_desc)
      } else {
        const message = unableMessage("dashboard url", "get")
        enqueueSnackbar(data?.message || message, { variant: ERROR });
      }
    } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    getTableauToken()
  }, [])

  return (
    <>
      {
        token &&
        <tableau-viz
          id="tableauViz"
          class="tableauViz"
          width='100%'
          height='100%'
          src={dashboardUrl}
          showAppBanner='false'
          toolbar='yes'
          tabs='yes'
          token={token}
        />
      }
    </>
  )
}

DashboardContent.propTypes = {
  dashboardName: PropTypes.string
}

export default DashboardContent