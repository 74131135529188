//----------------------------------------------// External Imports // -------------------------------------------------

import moment from "moment-timezone";
import * as yup from 'yup'
import { intersection } from "lodash";

//----------------------------------------------// Internal Imports // -------------------------------------------------

import { addPositionByIndex, cleanupCompanyDetails, cleanUpCompensation } from "../AddContact/utils";
import { compareByPosition, isEmpty, sortByChronologicalOrder } from "../../utils/common";
import { getDifferenceInDays, formatTime } from "../../utils/date";
// import { OLD_SEARCH_DATE } from '../../services/constantService';

export const qualifiedStages = ['Client Interviewed and Declined', 'Client Declined Background', 'KG Partner Interviewed and Declined', 'KG Declined', 'Candidate Declined', 'Offer Rejected', 'Offer Withdrawn', 'Withdrawn']

/* const companySchema = yup.object().shape({

  company: yup.object().shape({
    id: yup.string(),
    name: yup.string().required(),
  })
}) */

const jobSchema = yup.object().shape({
  company: yup.object().when(['title','start_year', 'end_year'], {
    is: (title, start_year, end_year) => (title || start_year || end_year),
    then: yup.object().shape({
      id: yup.string(),
      name: yup.string().required()
    }),
    otherwise: yup.object().shape({
      id: yup.string(),
      name: yup.string()
    }),
  })
})

export const jobHistorySchema = yup.array().of(jobSchema)

export const formSchema = yup.object().shape({
  current_company: yup.object().shape({
    id: yup.string(),
    name: yup.string().required(),
  }),
  current_job_title: yup.string().required(),
  country: yup.string().required(),
  industries: yup.array().of(yup.string()).required(),
  job_functions: yup.array().of(yup.string()).required()
})

export const employmentHistoryFormSchema = yup.object().shape({
  job_history: jobHistorySchema
})

export const contactSchema = yup.object().shape({
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  current_company: yup.object().shape({
    id: yup.string(),
    name: yup.string().required(),
  }),
  work_email: yup.string(),
  private_email: yup.string(),
  current_job_title: yup.string().required(),
  current_job_start_year: yup.string().required(),
  country: yup.string().required(),
  industries: yup.array().of(yup.string()).required(),
  job_functions: yup.array().of(yup.string()).required(),
  job_history: jobHistorySchema
})

export const activityFormSchema = (isContactActivityLog, stage) => yup.object().shape({
  stage: isContactActivityLog ? yup.string().notRequired() : yup.string().required(),
  reason: qualifiedStages.includes(stage) ? yup.string().required() : yup.mixed(),
  activity: yup.string().required(),
  type: yup.string().required(),
  subject: yup.string().required(),
  direction: yup.string().required()
})

const getCandidateValue = (candidateValues, id, field_name) => {
  if (!candidateValues || candidateValues?.legnth === 0) {
    return { [field_name]: id }
  }
  return candidateValues.find(item => {
    return item[field_name] === id;
  }) || { [field_name]: id }
}

export const getDefaultValues = (activityInfo) => {
  if (isEmpty(activityInfo)) return null;

  const sortedSFPASkills = activityInfo.sfpa?.skills?.sort((a, b) => a.position - b.position).map((skill) => {
    if (skill.elements?.length) {
      skill.elements = skill.elements.sort((a, b) => a.position - b.position);
    }
    return skill;
  });
  const sortedSFPAAttributes = activityInfo.sfpa?.attributes?.sort(compareByPosition);
  const sortedInterviewSchedules = activityInfo.candidate?.schedules?.sort(compareByPosition);
  const sortedJobHistories = sortByChronologicalOrder(activityInfo.contact?.job_history);
  const sortedBoardDetails = activityInfo.contact?.board_details?.sort(compareByPosition);

  return {
    ...activityInfo.contact,
    board_details: sortedBoardDetails,
    job_history: sortedJobHistories,
    skills: sortedSFPASkills,
    skill_ratings: sortedSFPASkills?.map(item => {
      const skill = getCandidateValue(activityInfo.candidate?.skills, item.id, "skill_id")
      if (item.elements?.length) {
        skill.elements = item.elements.sort(compareByPosition).map(skillElement => {
          return getCandidateValue(skill.elements, skillElement.id, "sfpa_skill_element_id")
        });
      }
      return skill;
    }),
    legacy_skills: activityInfo.candidate?.legacy_skills,
    legacy_attributes: activityInfo.candidate?.legacy_attributes,
    strengths: Array.isArray(activityInfo.contact.strengths) ? activityInfo.contact.strengths : [],
    issues: Array.isArray(activityInfo.contact.issues) ? activityInfo.contact.issues : [],
    candidate_name: activityInfo.contact ? `${activityInfo.contact.first_name || ''} ${activityInfo.contact.last_name || ''}` : '',
    current_company: activityInfo.contact?.company,
    home_phone: activityInfo.contact?.home_phone,
    mobile_phone: activityInfo.contact?.mobile_phone,
    work_email: activityInfo.contact?.work_email,
    private_email: activityInfo.contact?.private_email,
    revenue: activityInfo.contact?.company?.revenue,
    phone: activityInfo.contact?.company?.phone,
    stage: activityInfo.candidate?.stage,
    reason: activityInfo.candidate?.reason,
    matrix_notes: activityInfo.candidate?.matrix_notes,
    industries: Array.isArray(activityInfo?.contact?.industries) ? activityInfo?.contact?.industries : [],

    subject: '',
    comments: '',
    direction: null,
    activity_created_at: new Date(),
    international_experience: activityInfo.contact?.international_experience || [],
    education_details: activityInfo.contact?.education_details.sort(compareByPosition),
    activities: activityInfo.activities,
    next_steps: activityInfo.candidate?.next_steps,
    rationale: activityInfo.candidate?.rationale,
    track_comments: activityInfo.candidate?.track_comments,
    arena_stage: activityInfo.candidate?.arena_stage,
    arena_comments: activityInfo.candidate?.arena_comments,
    general_comments: activityInfo.candidate?.general_comments,
    initial_contact_date: activityInfo.candidate?.initial_contact_date,
    kg_interview_date1: activityInfo.candidate?.kg_interview_date1,
    kg_interview_date2: activityInfo.candidate?.kg_interview_date2,
    kg_interview_date3: activityInfo.candidate?.kg_interview_date3,
    kg_interview_from_time1: activityInfo.candidate?.kg_interview_from_time1 ? convertTimeIntoDate(activityInfo.candidate?.kg_interview_from_time1, true) : null,
    kg_interview_from_time2: activityInfo.candidate?.kg_interview_from_time2 ? convertTimeIntoDate(activityInfo.candidate?.kg_interview_from_time2, true) : null,
    kg_interview_from_time3: activityInfo.candidate?.kg_interview_from_time3 ? convertTimeIntoDate(activityInfo.candidate?.kg_interview_from_time3, true) : null,
    kg_interview_to_time1: activityInfo.candidate?.kg_interview_to_time1 ? convertTimeIntoDate(activityInfo.candidate?.kg_interview_to_time1, true) : null,
    kg_interview_to_time2: activityInfo.candidate?.kg_interview_to_time2 ? convertTimeIntoDate(activityInfo.candidate?.kg_interview_to_time2, true) : null,
    kg_interview_to_time3: activityInfo.candidate?.kg_interview_to_time3 ? convertTimeIntoDate(activityInfo.candidate?.kg_interview_to_time3, true) : null,
    kg_interview_method1: activityInfo.candidate?.kg_interview_method1,
    kg_interview_method2: activityInfo.candidate?.kg_interview_method2,
    kg_interview_method3: activityInfo.candidate?.kg_interview_method3,
    kg_interview_client1: activityInfo.candidate?.kg_interview_client1,
    kg_interview_client2: activityInfo.candidate?.kg_interview_client2,
    kg_interview_client3: activityInfo.candidate?.kg_interview_client3,
    interviews: activityInfo.candidate?.interviews ? sortedClientInterviews(activityInfo) : [],
    schedules: sortedInterviewSchedules,
    outreach_date: activityInfo.candidate?.outreach_date,
    engaged_date: activityInfo.candidate?.engaged_date,
    date_added_to_search: activityInfo.candidate?.date_added_to_search,
    offer_accepted_date: activityInfo.candidate?.offer_accepted_date,
    recommendations_profile: activityInfo.contact.recommendations_profile,
    career_snapshot_profile: activityInfo.contact?.career_snapshot_profile,
    personal_profile: activityInfo.contact?.personal_profile,
    screening_notes: activityInfo.contact?.screening_notes === '<p><br /></p>' ? null : activityInfo.contact?.screening_notes,
    published_bio: activityInfo.contact?.published_bio,
    sfpa_attributes: sortedSFPAAttributes,
    attributes: sortedSFPAAttributes?.map(item => {
      const attribute = getCandidateValue(activityInfo.candidate?.attributes, item.id, "search_attribute_id")
      return attribute;
    }),
    is_skills_approved_by_partner: activityInfo.candidate?.is_skills_approved_by_partner,
    candidateUpdatedAt: activityInfo.candidate?.updated_at
  }
}
export const getRootDefaultValues = (activityInfo) => {
  if (isEmpty(activityInfo)) return null;

  return {
    ...activityInfo.contact,
    phone: activityInfo.contact?.company?.phone,
    stage: activityInfo.candidate?.stage,
    candidateUpdatedAt: activityInfo.candidate?.updated_at
  }
}

export const getGeneralNotesDefaultValues = (activityInfo) => {
  if (isEmpty(activityInfo)) return null;
  let tabInfo = {
    ...activityInfo.contact,
    candidate_name: activityInfo.contact ? `${activityInfo.contact.first_name || ''} ${activityInfo.contact.last_name || ''}` : '',
    current_company: activityInfo.contact?.company,
    revenue: activityInfo.contact?.company?.revenue,
    phone: activityInfo.contact?.company?.phone,
    education_details: activityInfo.contact?.education_details.sort(compareByPosition),
    international_experience: activityInfo.contact?.international_experience || []
  }
  if (Object.prototype.hasOwnProperty.call(activityInfo, "candidate")) {
    tabInfo = {
      ...tabInfo,
      matrix_notes: activityInfo.candidate?.matrix_notes,
      next_steps: activityInfo.candidate?.next_steps,
      rationale: activityInfo.candidate?.rationale,
      candidateUpdatedAt: activityInfo.candidate.updated_at
    }
  }
  return tabInfo;
}
export const milestoneDatesDefaultValues = (activityInfo) => {
  if (isEmpty(activityInfo)) return null;
  const sortedInterviewSchedules = activityInfo.candidate?.schedules?.sort(compareByPosition);
  return {
    ...activityInfo.candidate,
    kg_interview_from_time1: activityInfo.candidate?.kg_interview_from_time1 ? convertTimeIntoDate(activityInfo.candidate?.kg_interview_from_time1, true) : null,
    kg_interview_from_time2: activityInfo.candidate?.kg_interview_from_time2 ? convertTimeIntoDate(activityInfo.candidate?.kg_interview_from_time2, true) : null,
    kg_interview_from_time3: activityInfo.candidate?.kg_interview_from_time3 ? convertTimeIntoDate(activityInfo.candidate?.kg_interview_from_time3, true) : null,
    kg_interview_to_time1: activityInfo.candidate?.kg_interview_to_time1 ? convertTimeIntoDate(activityInfo.candidate?.kg_interview_to_time1, true) : null,
    kg_interview_to_time2: activityInfo.candidate?.kg_interview_to_time2 ? convertTimeIntoDate(activityInfo.candidate?.kg_interview_to_time2, true) : null,
    kg_interview_to_time3: activityInfo.candidate?.kg_interview_to_time3 ? convertTimeIntoDate(activityInfo.candidate?.kg_interview_to_time3, true) : null,
    interviews: activityInfo.candidate?.interviews ? sortedClientInterviews(activityInfo) : [],
    schedules: sortedInterviewSchedules,
  }
}

// It use for remove list of empty line in both cases with having <p><br /></p> tag and without <p></p> tag after content  
export const getScreeningNotesDefaultValue = (value) => {
  let newValue = value
  const stringCompare = '<p><br /></p>'
  const emptyStringCompareWithoutBR = '<p></p>';
  if (value) {
    const span = document.createElement('span');
    span.innerHTML = newValue;
    let newNotes = span.textContent
    newNotes = newNotes.trim();

    if (!newNotes.length) {
      newValue = null;
    } else {
      const doc = new DOMParser().parseFromString(value, "text/html");
      const HTMLArray = [...doc.body.children].map(el => el.outerHTML);
      let lastEmptyLines = 0;
      let emptyLinesWithoutBR = 0
      const reverseArray = [...HTMLArray].reverse();
      for (const [index, item] of reverseArray.entries()) {
        /* Here 'emptyString' is different than 'stringCompare' 
        due to DomParser convert <br /> to <br> */
        const emptyString = '<p><br></p>'
        if (item === emptyString && reverseArray[index + 1] === emptyString) {
          lastEmptyLines++;
        } else if(item === emptyStringCompareWithoutBR && reverseArray[index + 1] === emptyStringCompareWithoutBR) {
          emptyLinesWithoutBR++
        } else {
          if (item === emptyString) {
            lastEmptyLines++;
          }else if (item === emptyStringCompareWithoutBR) {
            emptyLinesWithoutBR++;
          }
          break;
        }
      }
      newValue = lastEmptyLines ? newValue.slice(0, value.length - (lastEmptyLines * stringCompare.length)) : newValue;
      newValue = emptyLinesWithoutBR ? newValue.slice(0, newValue.length - (emptyLinesWithoutBR * emptyStringCompareWithoutBR.length) - 1) : newValue;
    }
  }
  return newValue
}
export const screeningNotesTabDefaultValues = (activityInfo) => {
  if (isEmpty(activityInfo)) return null;
  let screeningNotes = getScreeningNotesDefaultValue(activityInfo.contact?.screening_notes,)
  return {
    ...activityInfo.contact,
    screening_notes: activityInfo.contact?.screening_notes === '<p><br /></p>' ? null : screeningNotes,
  }
}
export const employmentHistoryTabDefaultValues = (activityInfo) => {
  if (isEmpty(activityInfo)) return null;
  const sortedJobHistories = sortByChronologicalOrder(activityInfo.contact?.job_history);
  const sortedBoardDetails = activityInfo.contact?.board_details?.sort(compareByPosition);
  return {
    ...activityInfo.contact,
    board_details: sortedBoardDetails,
    job_history: sortedJobHistories,
  }
}
export const skillsTabDefaultValues = (activityInfo) => {
  if (isEmpty(activityInfo)) return null;

  const sortedSFPASkills = activityInfo.sfpa?.skills?.sort((a, b) => a.position - b.position).map((skill) => {
    if (skill.elements?.length) {
      skill.elements = skill.elements.sort((a, b) => a.position - b.position);
    }
    return skill;
  });

  return {
    ...activityInfo.candidate,
    skills: sortedSFPASkills,
    skill_ratings: sortedSFPASkills?.map(item => {
      const skill = getCandidateValue(activityInfo.candidate?.skills, item.id, "skill_id")
      if (item.elements?.length) {
        skill.elements = item.elements.sort(compareByPosition).map(skillElement => {
          return getCandidateValue(skill.elements, skillElement.id, "sfpa_skill_element_id")
        });
      }
      return skill;
    }),
  }
}

export const strengthsAndIssuesTabDefaultValues = (activityInfo) => {
  if (isEmpty(activityInfo)) return null;

  return {
    ...activityInfo.contact,
    strengths: Array.isArray(activityInfo.contact.strengths) ? activityInfo.contact.strengths : [],
    issues: Array.isArray(activityInfo.contact.issues) ? activityInfo.contact.issues : [],
  }
}
export const legacySkillsTabDefaultValues = (activityInfo) => {
  if (isEmpty(activityInfo)) return null;

  return {
    ...activityInfo.candidate,
  }
}

export const attributesTabDefaultValues = (activityInfo) => {
  if (isEmpty(activityInfo)) return null;
  const sortedSFPAAttributes = activityInfo.sfpa?.attributes?.sort(compareByPosition);

  return {
    ...activityInfo.candidate,
    sfpa_attributes: sortedSFPAAttributes,
    attributes: sortedSFPAAttributes?.map(item => {
      const attribute = getCandidateValue(activityInfo.candidate?.attributes, item.id, "search_attribute_id")
      return attribute;
    }),
  }
}
export const legacyAttributesTabDefaultValues = (activityInfo) => {
  if (isEmpty(activityInfo)) return null;

  return {
    ...activityInfo.candidate,
  }
}
export const invitesTabDefaultValues = (activityInfo) => {
  if (isEmpty(activityInfo)) return null;

  return {
    ...activityInfo.contact,
  }
}
export const candidateSnapshotTabDefaultValues = (activityInfo) => {
  if (isEmpty(activityInfo)) return null;

  return {
    ...activityInfo.contact,
  }
}
export const recommendationsTabDefaultValues = (activityInfo) => {
  if (isEmpty(activityInfo)) return null;
  return {
    ...activityInfo.contact,
  }
}

const sortedClientInterviews = (activityInfo) => {
  activityInfo.candidate.interviews.forEach(element => {
    element.interview_from_time = element.interview_from_time ? convertTimeIntoDate(element.interview_from_time) : null;
    element.interview_to_time = element.interview_to_time ? convertTimeIntoDate(element.interview_to_time) : null;
  })
  activityInfo.candidate.interviews.sort(compareByPosition)
  return activityInfo.candidate.interviews;
}

const convertTimeIntoDate = (time) => {
  //var date = new Date(new Date().getFullYear+"-"+new Date().getMonth()+"-"+new Date().getDate()+" "+time)
  if (typeof (time) === 'object') { 
    time = formatTime(time)
  }
  const formatedTime = time.split('.')[0]
  const date = `2011-06-29T${formatedTime}`
  const localDate = new Date(date);
  return localDate;
}

const getCreatedAt = (activityDate) => {
  if (getDifferenceInDays(activityDate, new Date()) !== 0) {
    return activityDate
  }
  return undefined
}

export const SKIP_FIELDS_LIST = ['candidate_name', 'matrix_notes', 'next_steps',
  'rationale', 'recommendations_profile', 'published_bio', 'career_snapshot_profile',
  'personal_profile', 'screening_notes', 'strengths', 'issues'
]

export const unique = (value, index, self) => {
  return self.indexOf(value) === index
}

export const removeSkipFields = (list) => {
  return list.filter(ele => !SKIP_FIELDS_LIST.includes(ele)).filter(unique);
}

export const removeUnusedField = (formValues, dirtyFields) => {
  SKIP_FIELDS_LIST.forEach(ele => delete formValues[ele]);
  let isDirtyFields = dirtyFields
  let newForm = {}
  isDirtyFields.forEach(ele => newForm[ele] = formValues[ele])

  return newForm
}

export const getActivityGenralInfoPayload = (formValues = {}, dirtyFields = []) => {
  const removeUnused = removeUnusedField(formValues, dirtyFields);
  if (removeUnused.current_company || removeUnused.phone || removeUnused.revenue) {
    removeUnused.current_company_id = formValues.current_company?.id;
    removeUnused.company = {
      id: formValues.current_company?.id,
      revenue: parseFloat(formValues.revenue),
      phone: formValues.phone,
      updated_at: formValues.current_company?.updated_at
    }
  }
  if (removeUnused.education_details) {
    delete removeUnused.major
    delete removeUnused.school_name
    removeUnused.education_details = addPositionByIndex(formValues.education_details, formValues.id)
  }
  if (removeUnused.international_experience) {
    removeUnused.international_experience = formValues.international_experience &&
      formValues.international_experience.map(item => item?.name || item)
  }
  if (formValues.job_history) {
    formValues.job_history = formValues.job_history.filter(ele => ele?.company)
    removeUnused.job_history = cleanupCompanyDetails(formValues.job_history)
    if (formValues.current_job_start_year) {
      removeUnused.current_job_start_year = formValues.current_job_start_year
    }
  } else {
    delete removeUnused.current_job_start_year
  }
  if (!Object.keys(removeUnused).length) return false
  return {
    contact: { ...removeUnused }
  }
}

export const getActivityMilestoneDatesPayload = (formValues = {}, dirtyFields = []) => {
  const removeUnused = removeUnusedField(formValues, dirtyFields);
  if (removeUnused.interviews && Array.isArray(formValues.interviews)) {
    removeUnused.client_interviews = formValues.interviews.map((item, position) => ({ ...item, position }))
    delete removeUnused.interviews
  }
  if (removeUnused.schedule && Array.isArray(formValues.schedule)) {
    removeUnused.interview_schedule = formValues.schedule.map((item, position) => ({ ...item, position }))
    delete removeUnused.schedule
  }
  if (removeUnused.client_interviews) {
    removeUnused.client_interviews = addClientIdToInterview(removeUnused.client_interviews)
  }
  if (removeUnused.kg_interview_from_time1) { removeUnused.kg_interview_from_time1 = formValues.kg_interview_from_time1 ? convertDateIntoTime(formValues.kg_interview_from_time1) : null; }
  if (removeUnused.kg_interview_from_time2) { removeUnused.kg_interview_from_time2 = formValues.kg_interview_from_time2 ? convertDateIntoTime(formValues.kg_interview_from_time2) : null; }
  if (removeUnused.kg_interview_from_time3) { removeUnused.kg_interview_from_time3 = formValues.kg_interview_from_time3 ? convertDateIntoTime(formValues.kg_interview_from_time3) : null; }
  if (removeUnused.kg_interview_to_time1) { removeUnused.kg_interview_to_time1 = formValues.kg_interview_to_time1 ? convertDateIntoTime(formValues.kg_interview_to_time1) : null; }
  if (removeUnused.kg_interview_to_time2) { removeUnused.kg_interview_to_time2 = formValues.kg_interview_to_time2 ? convertDateIntoTime(formValues.kg_interview_to_time2) : null; }
  if (removeUnused.kg_interview_to_time3) { removeUnused.kg_interview_to_time3 = formValues.kg_interview_to_time3 ? convertDateIntoTime(formValues.kg_interview_to_time3) : null; }
  if (removeUnused.kg_interview_client1) { removeUnused.kg_interview_client1 = formValues.kg_interview_client1 && formValues.kg_interview_client1.id ? formValues.kg_interview_client1.id : formValues.kg_interview_client1; }
  if (removeUnused.kg_interview_client2) { removeUnused.kg_interview_client2 = formValues.kg_interview_client2 && formValues.kg_interview_client2.id ? formValues.kg_interview_client2.id : formValues.kg_interview_client2; }
  if (removeUnused.kg_interview_client3) { removeUnused.kg_interview_client3 = formValues.kg_interview_client3 && formValues.kg_interview_client3.id ? formValues.kg_interview_client3.id : formValues.kg_interview_client3; }
  if (!Object.keys(removeUnused).length) return false
  return {
    candidate: { ...removeUnused }
  }
}

export const getActivityScreeningNotesTabPayload = (formValues = {}, dirtyFields = [], oldValues) => {
  formValues.actual_bonus_value = parseFloat(formValues.actual_bonus_value) || null
  formValues.target_bonus_value = parseFloat(formValues.target_bonus_value) || null
  const removeUnused = removeUnusedField(formValues, dirtyFields);
  const numberFormatField = ['base_salary', 'target_bonus_value', 'actual_bonus_value']
  if (Object.prototype.hasOwnProperty.call(removeUnused, 'direct_reports')) { removeUnused.direct_reports = parseInt(removeUnused.direct_reports) }
  const numberFormatFields = intersection(numberFormatField, dirtyFields)
  if (numberFormatFields.length) {
    numberFormatFields.forEach(ele => {
      if (+removeUnused[ele] === oldValues[ele]) {
        delete removeUnused[ele];
      }
    })
  }
  if (!Object.keys(removeUnused).length) { return false }
  return {
    contact: { ...removeUnused }
  }
}

export const getActivityEmploymentHistoryTabPayload = (formValues = {}, dirtyFields = []) => {
  const removeUnused = removeUnusedField(formValues, dirtyFields);
  if (removeUnused.job_history) {
    formValues.job_history = formValues.job_history.filter(ele => ele?.company)
    removeUnused.job_history = sortByChronologicalOrder(cleanupCompanyDetails(formValues.job_history, formValues.id));
  }
  if (removeUnused.board_details) {
    formValues.board_details = formValues.board_details.filter(ele => ele?.company) 
    removeUnused.board_details = sortByChronologicalOrder(cleanupCompanyDetails(formValues.board_details, formValues.id)); 
  }

  if (!Object.keys(removeUnused).length) return false
  return {
    contact: { ...removeUnused }
  }
}

export const getActivityStrengthsAndIssuesTabPayload = (formValues = {}, dirtyFields = []) => {
  const removeUnused = removeUnusedField(formValues, dirtyFields);

  if (!Object.keys(removeUnused).length) { return false }
  return {
    contact: { ...removeUnused }
  }
}

export const getActivityInvitesTabPayload = (formValues = {}, dirtyFields = []) => {
  const removeUnused = removeUnusedField(formValues, dirtyFields);

  if (!Object.keys(removeUnused).length) { return false }
  return {
    contact: { ...removeUnused }
  }
}

export const getActivityLegacySkillsTabPayload = (formValues = {}, dirtyFields = []) => {
  const removeUnused = removeUnusedField(formValues, dirtyFields);

  if (!Object.keys(removeUnused).length) { return false }
  return {
    candidate: { ...removeUnused }
  }
}

export const getActivityRecommendationsTabPayload = (formValues = {}, dirtyFields = []) => {
  const removeUnused = removeUnusedField(formValues, dirtyFields);

  if (!Object.keys(removeUnused).length) { return false }
  return {
    contact: { ...removeUnused }
  }
}
export const getActivityCandidateSnapshotTabPayload = (formValues = {}, dirtyFields = []) => {
  const removeUnused = removeUnusedField(formValues, dirtyFields);

  if (!Object.keys(removeUnused).length) { return false }
  return {
    contact: { ...removeUnused }
  }
}

export const getActivityPayload = async (formValues = {}, activityInfo = {}) => {
  if (Array.isArray(formValues.interviews)) {
    formValues.interviews = formValues.interviews.map((item, position) => ({ ...item, position }))
  }
  if (Array.isArray(formValues.schedule)) {
    formValues.schedule = formValues.schedule.map((item, position) => ({ ...item, position }))
  }

  return {
    ...activityInfo,

    contact: {
      ...cleanUpCompensation(formValues),
      // country: formValues.country,
      // current_company_id: formValues.current_company?.id,
      // current_job_title: formValues.current_job_title,
      // current_job_start_year: formValues.current_job_start_year,
      // home_phone: formValues.home_phone,
      // mobile_phone: formValues.mobile_phone,
      // work_email: formValues.work_email,
      // private_email: formValues.private_email,
      // revenue: formValues.revenue,
      // education_details: addPositionByIndex(formValues.education_details),
      base_salary: formValues.base_salary,
      target_bonus_type: formValues.target_bonus_type,
      actual_bonus_type: formValues.actual_bonus_type,
      equity: formValues.equity,
      bonus_payout_date: formValues.bonus_payout_date,
      compensation_expectation: formValues.compensation_expectation,
      compensation_notes: formValues.compensation_notes,
      compensation_updated_date: formValues.compensation_updated_date,
      compensation_release: formValues.compensation_release,
      business_unit_revenue: formValues.business_unit_revenue,
      aum_min: formValues.aum_min,
      aum_max: formValues.aum_max,
      direct_reports: parseInt(formValues.direct_reports),
      reports_to: formValues.reports_to,
      screening_notes: formValues.screening_notes,
      strengths: formValues.strengths,
      issues: formValues.issues,
      job_history: sortByChronologicalOrder(cleanupCompanyDetails(formValues.job_history, formValues.id)),
      board_details: cleanupCompanyDetails(formValues.board_details, formValues.id),
      // company: {
      //   id: formValues.current_company?.id,
      //   revenue: parseFloat(formValues.revenue),
      //   phone: formValues.phone,
      //   updated_at: formValues.current_company?.updated_at
      // },

      // recommendations_profile: formValues.recommendations_profile,
      // career_snapshot_profile: formValues.career_snapshot_profile,
      // personal_profile: formValues.personal_profile,
      // published_bio: formValues.published_bio,

      // industries: formValues.industries,
      // job_functions: formValues.job_functions,

      is_issues_approved_by_partner: formValues.is_issues_approved_by_partner,
      is_strengths_approved_by_partner: formValues.is_strengths_approved_by_partner,
      is_published_bio_approved_by_partner: formValues.is_published_bio_approved_by_partner,
      is_professional_profile_approved_by_partner: formValues.is_professional_profile_approved_by_partner,
      is_personal_profile_approved_by_partner: formValues.is_personal_profile_approved_by_partner,
      gdpr_release: formValues.gdpr_release,
      hide_in_client_suite: formValues.hide_in_client_suite,
      // international_experience: formValues.international_experience &&
      //   formValues.international_experience.map(item => item?.name || item)
    },

    activity: {
      activity: formValues.activity,
      type: formValues.type,
      direction: formValues.direction,
      subject: formValues.subject,
      comments: formValues.comments,
      created_at: getCreatedAt(formValues.activity_created_at)
    },

    candidate: {
      ...activityInfo.candidate,
      reason: formValues.reason,
      // next_steps: formValues.next_steps,
      // rationale: formValues.rationale,
      track_comments: formValues.track_comments,
      arena_comments: formValues.arena_comments,
      general_comments: formValues.general_comments,
      // matrix_notes: formValues.matrix_notes,
      initial_contact_date: formValues.initial_contact_date,
      kg_interview_date1: formValues.kg_interview_date1,
      kg_interview_date2: formValues.kg_interview_date2,
      kg_interview_date3: formValues.kg_interview_date3,
      kg_interview_from_time1: formValues.kg_interview_from_time1 ? convertDateIntoTime(formValues.kg_interview_from_time1) : null,
      kg_interview_from_time2: formValues.kg_interview_from_time2 ? convertDateIntoTime(formValues.kg_interview_from_time2) : null,
      kg_interview_from_time3: formValues.kg_interview_from_time3 ? convertDateIntoTime(formValues.kg_interview_from_time3) : null,
      kg_interview_to_time1: formValues.kg_interview_to_time1 ? convertDateIntoTime(formValues.kg_interview_to_time1) : null,
      kg_interview_to_time2: formValues.kg_interview_to_time2 ? convertDateIntoTime(formValues.kg_interview_to_time2) : null,
      kg_interview_to_time3: formValues.kg_interview_to_time3 ? convertDateIntoTime(formValues.kg_interview_to_time3) : null,
      kg_interview_method1: formValues.kg_interview_method1,
      kg_interview_method2: formValues.kg_interview_method2,
      kg_interview_method3: formValues.kg_interview_method3,
      kg_interview_client1: formValues.kg_interview_client1 && formValues.kg_interview_client1.id ? formValues.kg_interview_client1.id : formValues.kg_interview_client1,
      kg_interview_client2: formValues.kg_interview_client2 && formValues.kg_interview_client2.id ? formValues.kg_interview_client2.id : formValues.kg_interview_client2,
      kg_interview_client3: formValues.kg_interview_client3 && formValues.kg_interview_client3.id ? formValues.kg_interview_client3.id : formValues.kg_interview_client3,
      // work_email: formValues.work_email,
      client_interviews: Array.isArray(formValues.interviews) ? addClientIdToInterview(formValues.interviews) : [],
      interview_schedule: Array.isArray(formValues.schedule) ? addrecruiterToSchedule(formValues.schedule) : [],
      outreach_date: formValues.outreach_date,
      engaged_date: formValues.engaged_date,
      date_added_to_search: formValues.date_added_to_search,
      offer_accepted_date: formValues.offer_accepted_date,

      attributes: formValues.attributes && formValues.attributes.map(item => {
        return {
          ...item,
          rating: parseFloat(item.rating)
        }
      }),
      skills: formValues.skills && formValues.skill_ratings && prepareSkills(formValues.skills, formValues.skill_ratings),
      is_skills_approved_by_partner: formValues.is_skills_approved_by_partner
    }
  }
}

const addClientIdToInterview = (interviews) => {
  return interviews.map((item) => ({
    ...item,
    client_id: item.client ? item.client.id : null,
    interview_from_time: item.interview_from_time ? convertDateIntoTime(item.interview_from_time) : null,
    interview_to_time: item.interview_to_time ? convertDateIntoTime(item.interview_to_time) : null,
  }))
}

const convertDateIntoTime = (time) => {
  //let kgTime = moment.utc(time).format("HH:mm:ss")   this line is commented as it is changing time according to timeZone.
  let kgTime = new Date(time).getHours()+":"+new Date(time).getMinutes()+":"+new Date(time).getSeconds();
  return kgTime
}

const addrecruiterToSchedule = (schedules) => {
  return schedules.map((item) => ({
    ...item,
    recruiter_id: item.recruiter ? item.recruiter.id : null,
    from_time: item.from_time ? item.from_time : null,
    to_time: item.to_time ? item.to_time : null
  }))
}

const prepareSkills = (skills, ratings) => {
  return ratings.map((item, index) => (
    {
      ...item,
      skill_id: skills[index].id,
      elements: Array.isArray(item.elements) ?
        item.elements.map((element, eleIndex) => (
          {
            ...element,
            minimum: element.minimum ? element.minimum : null,
            ideal: element.ideal ? element.ideal : null,
            sfpa_skill_element_id: skills[index].elements[eleIndex]?.id
          }
        )) : []
    }
  ))
}

export const getNavItems = (contactId, arenaSearch, includeLegacyTabs) => {
  const navItems = contactId ?
    ["General Info", "Screening Notes & Compensation", "Employment History", "Strengths & Potential Issues", "Candidate Snapshot", "Recommendations", "References"]
    :
    ["General Info", "Milestone Dates", "Screening Notes & Compensation", "Employment History", "Experience", "Strengths & Potential Issues", "Invites", "Leadership Competencies", "Candidate Snapshot", "Recommendations", "ClientSuite Feedback", "CandidateSuite Feedback", "References"]
  if (arenaSearch) {
    navItems.splice(navItems.length - 1, 0, "Arena Feedback")
  }
  if (includeLegacyTabs) {
    navItems.splice(5, 0, "Legacy Experience")
    navItems.splice(9, 0, "Legacy Attributes")
  }

  return navItems
}

export const getActivityLogTabNames = (contactId, arenaSearch, includeLegacyTabs, index) => {
  const navItems = contactId ?
    ['GENERAL_INFO_TAB', 'SCREENING_NOTES_TAB', 'EMPLOYMENT_HISTORY_TAB', 'STRENGTH_AND_ISSUE_TAB', 'CANDIDATE_SNAPSHOT_TAB', 'RECOMMENDATION_TAB', "REFERENCES_TAB"]
    :
    ['GENERAL_INFO_TAB', 'MILESTON_DATES_TAB', 'SCREENING_NOTES_TAB', 'EMPLOYMENT_HISTORY_TAB', 'EXPERIENCE_TAB', 'STRENGTH_AND_ISSUE_TAB', 'INVITES_TAB', 'LEADERSHIP_COMPETENCIES_TAB', 'CANDIDATE_SNAPSHOT_TAB', 'RECOMMENDATION_TAB', "CLIENTSUITE_FEEDBACK", "CANDIDATESUITE_FEEDBACK", "REFERENCES_TAB"]
  if (arenaSearch) {
    navItems.splice(navItems.length - 1, 0, "ARENA_FEEDBACK_TAB")
  }
  if (includeLegacyTabs) {
    navItems.splice(5, 0, "LEGASY_EXPERIENCE_TAB")
    navItems.splice(9, 0, "LEGASY_ATTRIBUTES_TAB")
  }
  if (index || index === 0) {
    return navItems[index];
  }

  return navItems
}

export const getIncludeLegacyValues = (search) => {
  let includeLegacyTabs = false
  if (search?.start_date && (getDifferenceInDays(search.start_date, '01/01/2020')) < 0) {
    //includeLegacyTabs = true   //for hide legacy tab  
    includeLegacyTabs = false 
  }
  return includeLegacyTabs
}

export const getLogAnActivityPayload = (formValues) => {
  return (
    {
      activity: {
        activity: formValues.activity,
        type: formValues.type,
        direction: formValues.direction,
        subject: formValues.subject,
        comments: formValues.comments,
        created_at: formValues.activity_created_at,
        search: formValues.search && formValues.search.id
      },
      candidate: {
        stage: formValues.stage,
        reason: formValues.reason,
        arena_stage: formValues.arena_stage
      },
      contact: {}
    }
  )
}

export function getUpdatedAt(parentUpdatedAt, childUpdatedAt) {
  return moment(parentUpdatedAt) > moment(childUpdatedAt) ? parentUpdatedAt : childUpdatedAt
}

export const getDefaultValuesForActivity = (activityInfo, arenaSearch) => {
  return ({
    stage: activityInfo?.candidate?.stage,
    reason: activityInfo?.candidate?.reason,
    subject: '',
    comments: '',
    direction: null,
    activity_created_at: new Date(),
    activities: activityInfo?.activities,
    arena_stage: arenaSearch ? activityInfo?.candidate?.arena_stage : undefined
  })
}

export const isActivityEmpty = (activityInfo) => {
  if (!activityInfo.activity && !activityInfo.type && !activityInfo.subject && !activityInfo.direction && !activityInfo.comments) {
    return true
  }
  return false
}

/* const convertDate = (inputFormat) => {
  function pad(s) { return (s < 10) ? '0' + s : s; }
  var d = new Date(inputFormat);
  return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join('/');
}; */

export const oldSearchHandler = () => {
  /*  const oldSearchDate = moment(OLD_SEARCH_DATE, 'DD/MM/YYYY');
    const searchDate = moment(convertDate(createdAt), 'DD/MM/YYYY');
    if (searchDate >= oldSearchDate) { return true; } */
  return false;
}