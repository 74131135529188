//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect } from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { Typography } from "@material-ui/core";

//----------------------------------------------// Internal Imports // -------------------------------------------------
import RichTextPopup from "../../RichTextPopup";

export default function PublishedBio(props) {
  const { setValue: setField, register, unregister, values } = props;
  const handleChange = (data) => {
    setField('published_bio', data);
  }
  useEffect(() => {
    register('published_bio');
    return (() => {
      unregister('published_bio')
    })
  }, [register, unregister, values])
  return (
    <div className="published-bio react-quill-container">
      <Typography className='section-label'>Published Bio</Typography>
      <div className='text-view-value p-0 w-100'>
        <div className='outlined-border transform p-0 '>
          <RichTextPopup
            bounds={'.published-bio'}
            value={values.published_bio}
            onChange={handleChange}
            title="Published Bio"
          />
        </div>
      </div>
    </div>
  );
}

PublishedBio.propTypes = {
  setValue: PropTypes.func,
  register: PropTypes.func,
  unregister: PropTypes.func,
  values: PropTypes.object,
}
