//----------------------------------------------// In-built Imports // -------------------------------------------------
import React from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------

import { KeyboardDatePicker } from "@material-ui/pickers";
import PropTypes from 'prop-types';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import './index.scss';
import CompanySelection from "../AddContact/Components/CompanySelection";
import InputField from "../common/InputField";
import CountrySelection from "../CountrySelection";
import UserSelection from "../UserSelection";
import ContactSelection from "../../components/ContactSelection/ContactSelection";
import { formatDate } from '../../utils/date';
import { InputLabel} from '@material-ui/core';
import BrandSelection from '../CreateSearch/Components/SearchInformation/Components/BrandSelection';
import SearchStageSelection from '../CreateSearch/Components/SearchInformation/Components/SearchStageSelection';
import ProbabilitySelection from '../CreateSearch/Components/SearchInformation/Components/ProbabilitySelection';
import BDStatusSelection from '../CreateSearch/Components/SearchInformation/Components/BDStatusSelection';
// import { separator } from '../../Containers/Commons/Utils';
// import { DECIMAL_SEPARATOR, THOUSAND_SEPARATOR } from '../../services/constantService';
// import NumberFormat from 'react-number-format';
import RevenueMarketSelection from '../RevenueMarketSelection';

const BasicDetails = (props) => {
  const { register, setValue, control, watch, currentValues, isHide } = props

  const handleChange = (e, data,name) => {
    setValue(name, data ? data.field_value : '');
  }

  return (
    <div className="d-flex flex-column">
      <div className='d-flex contact-details-row'>
        <div className='d-flex intersection-page-view'>
          <InputLabel className="page-label">Opportunity #</InputLabel>
          <div className='page-label-value'>
            <InputField
              variant="outlined"
              className='input-form-field'
              label=''
              placeholder='Auto-Generated'
              ref={register}
              name='job_number'
              InputLabelProps={{ focused: true }}
              required={true}
              disabled
            />
          </div>
        </div>
        <div className='d-flex intersection-page-view'>
          <InputLabel required={true} className="page-label">Stage</InputLabel>
          <div className='page-label-value'>
            <SearchStageSelection
              variant='outlined'
              label=''
              className='input-form-field'
              value={watch('stage_desc') || null}
              disabled={true}
              required={true}
              InputLabelProps={{ focused: true }}
              onChange={(e, data) => {
                setValue('stage', data ? data.field_value : null)
                setValue('stage_desc', data ? data.short_desc : null)
              }
              }
            />
          </div>
        </div>
      </div>
      <div className='d-flex contact-details-row'>
        <div className='d-flex intersection-page-view'>
          <InputLabel required={true} className="page-label">Company</InputLabel>
          {/* <Controller
            name='company' */}
          {/* as={ */}
          <div className='page-label-value'>
            <CompanySelection
              defaultValue={watch('company') || null}
              required={true}
              // inputRef={companyRef}
              className='input-form-field'
              label=''
              placeholder={"Search"}
              InputLabelProps={{ focused: true }}
              variant='outlined'
              name='company'
              onChange={(e, data) => { setValue('company', data) }}
            />
          </div>
          {/* } */}
          {/* control={control}
            onFocus={() => { companyRef.current.focus() }} */}
          {/* onChange={([, data]) => data} */}

        </div>
        <div className='d-flex intersection-page-view'>
          <InputLabel className="page-label">Client contacts</InputLabel>
          <div className='page-label-value multiple-selection-list'>
            <ContactSelection
              defaultValue={watch('client_team') || []}
              multiple={true}
              ref={register(`client_team`)}
              className='input-form-field'
              list={watch("client_team") || []}
              label=''
              InputLabelProps={{ focused: true }}
              onChange={(e, data) => {
                setValue("client_team", data ? data : null);
              }}
              variant='outlined'
            />
          </div>
        </div>
      </div>
      <div className='d-flex'>
        <div className='d-flex intersection-page-view'>
          <InputLabel required={true} className="page-label">Job Title</InputLabel>
          <div className='page-label-value'>
            <InputField
              variant="outlined"
              className='input-form-field'
              label=''
              placeholder='Job Title'
              value={watch('job_title') || null}
              ref={register}
              name='job_title'
              InputLabelProps={{ focused: true }}
              required={true}
              onChange={(e) => { setValue('job_title', e.target.value); }}
            />
          </div>
        </div>
        <div className='d-flex intersection-page-view'>
          <InputLabel required className="page-label">Projected Start Date</InputLabel>
          <div className='page-label-value'>
            <KeyboardDatePicker
              className="input-form-field"
              //disableToolbar
              autoOk
              // label="Projected Start Date"
              variant='outlined'
              inputVariant="outlined"
              format="MM/dd/yyyy"
              margin="normal"
              inputProps={{ readOnly: true }}
              value={formatDate(watch('projected_start_date')) || null}
              InputLabelProps={{ focused: true }}
              // minDate={compareDateWithCurrentDate(watch('projected_start_date'))}
              placeholder="Projected Start Date"
              onChange={(date) => { setValue('projected_start_date', formatDate(date)); }}
            />
          </div>
        </div>

      </div>
      <div className='d-flex contact-details-row'>
        <div className='d-flex intersection-page-view'>
          <InputLabel className="page-label" required={true}>Location</InputLabel>
          <div className='page-label-value'>
            <CountrySelection
              className="input-form-field"
              ref={register({ name: 'country' })}
              value={watch('country') || null}
              onChange={(e, country) => {
                setValue("country", country ? country.name : null);
              }}
              label=''
              placeholder={"Search"}
              InputLabelProps={{ focused: true, classes: { root: 'input-label' } }}
              variant='outlined'
              required={true}
            />
          </div>
        </div>
        <div className='d-flex intersection-page-view'>
          <InputLabel required={true} className="page-label">Partners</InputLabel>
          <div className='page-label-value multiple-selection-list'>
            {/* <Controller
            name='basic_partners'
            as={
              }
            onChange={([, data]) => data}
            control={control}
            onFocus={() => userRef.current.focus()}
          /> */}
            <UserSelection
              name='basic_partners'
              multiple
              // inputRef={userRef}
              value={watch('basic_partners') || []}
              // value={getPartnerValue()}
              className="input-form-field"
              InputLabelProps={{ focused: true }}
              filterSelectedOptions={true}
              label={""}
              placeholder={"Select"}
              required={true}
              variant='outlined'
              // onChange={([, data]) => data}
              onChange={(e, data) => {
                setValue('basic_partners', data) }}
            />
          </div>
        </div>

      </div>
      <div className='d-flex contact-details-row'>
        {/* <div className='d-flex intersection-page-view'>
          <InputLabel required={true} className="page-label">Projected Fee</InputLabel>
          <div className='page-label-value'>
            <NumberFormat
              variant="outlined"
              customInput={TextField}
              fullWidth='true'
              name='projected_fee'
              ref={register}
              defaultValue={currentValues.projected_fee}
              thousandSeparator={separator(watch('currency'), THOUSAND_SEPARATOR)}
              decimalSeparator={separator(watch('currency'), DECIMAL_SEPARATOR)}
              decimalScale={2}
              isNumericString={true}
              prefix={currencyDetails ? (currencyDetails.prefix ? `${currencyDetails.currency_icon} ` : '') : ''}
              suffix={currencyDetails ? (currencyDetails.prefix === false ? ` ${currencyDetails.currency_icon}` : '') : ''}
              onValueChange={(values) => {
                const { value } = values;
                setValue('projected_fee', value);
              }}
              className="input-form-field"
            />
          </div>
        </div> */}
        <div className='d-flex intersection-page-view'>
          <InputLabel required={true} className="page-label">Probability %</InputLabel>
          <div className='page-label-value'>
            <ProbabilitySelection
              className='input-form-field'
              variant="outlined"
              onChange={(e, data) => handleChange(e, data ,"probability")}
              name="probability"
              defaultValue={currentValues.probability ? currentValues.probability : null}
              label=''
              control={control}
              InputLabelProps={{ focused: true }}
            />
          </div>
        </div>
        <div className='d-flex intersection-page-view'>
          <InputLabel className="page-label">BD Status</InputLabel>
          <div className='page-label-value'>
            <BDStatusSelection
              variant="outlined"
              onChange={(e, data) => handleChange(e, data ,"bd_status")}
              className='input-form-field'
              defaultValue={currentValues.bd_status ? currentValues.bd_status : 'Target Identified'}
              placeholder='BD Status'
              control={control}
              InputLabelProps={{ focused: true }}
              name="bd_status"
              label=''
            />
          </div>
        </div>
      </div>
      <div className='d-flex contact-details-row'>
        <div className='d-flex intersection-page-view'>
          <InputLabel required={true} className="page-label">Service Offering</InputLabel>
          <div className='page-label-value'>
            <BrandSelection
              defaultValue={currentValues.brand_desc ? currentValues.brand_desc : currentValues.brand || null}
              label=''
              required={true}
              variant='outlined'
              className='input-form-field'
              disabled={isHide}
              InputLabelProps={{ focused: true }}
              control={control}
              onChange={(e, data) => {
                setValue('brand', data ? data.field_value : '')
                setValue('brand_desc', data ? data.short_desc : null);
              }}
            />
          </div>
        </div>
        <div className='d-flex intersection-page-view'>
          <InputLabel required={true} className="page-label">Geography</InputLabel>
          <div className='page-label-value'>
            <RevenueMarketSelection
              value={watch('markets') || null}
              label=''
              required={true}
              disabled={isHide}
              ref={register(`markets`)}
              variant='outlined'
              className='input-form-field'
              InputLabelProps={{ focused: true }}
              onChange={(e, data) => {
                setValue('markets', data ? data : '');
              }}
            />
          </div>
        </div>
      </div>
      <div className='d-flex contact-details-row'>
        <div className='d-flex intersection-page-view'>
          <InputLabel className="page-label">Competition</InputLabel>
          <div className='page-label-value'>
            <InputField
              variant="outlined"
              ref={register({})}
              className='input-form-field'
              // label='Competition'
              placeholder='Competition'
              InputLabelProps={{ focused: true }}
              name={`competition`}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

BasicDetails.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  control: PropTypes.object,
  watch: PropTypes.func,
  userRef: PropTypes.object,
  currentValues: PropTypes.object,
  isHide: PropTypes.bool
}

export default BasicDetails;