//----------------------------------------------// In-built Imports // -------------------------------------------------
import React from 'react'

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';

import { Typography } from '@material-ui/core'
import BusinessIcon from '@material-ui/icons/Business';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { RedirectToCompanies } from '../../../../utils/common';

export default function CommonHeader(props) {
  const { company, jobNumber, jobTitle, companyDetails, confidential } = props
  /* const [isPopupOpen, setPopupOpen] = useState(false);
  const [isRemove, setIsRemove] = useState(false);
  const history = useHistory();
  const { id } = useParams();
  const userData = useSelector(getUserSelector)

  const handleDelete = () => {
    setPopupOpen(true)
    setIsRemove(true);
  }
  const handlePopupClose = (deleted) => {
    setPopupOpen(false)
    if (deleted) {
      history.replace('/searches')
    }
  }
  const update = (nextState) => {
    isRemove && setIsRemove(nextState);
  } */

  return (
    <div>
      {/* {isPopupOpen ? <DeletePopup module='searches' onClose={handlePopupClose} id={id} updateParent={update} popupText={DELETE_CONFIRMATION_POPUP_MESSAGE.DELETE_SEARCH} /> : null} */}
      <div className='d-flex align-items-baseline'>
        <Typography className='job-title m-0'>{confidential ? `${jobTitle}(Confidential)` : jobTitle}</Typography>
        <Typography className='job-number'>Opportunity #{jobNumber}</Typography>
        {/* {userData?.isAdmin ?
          <div className="action-container d-flex align-items-center" onClick={handleDelete} style={{ paddingLeft: 15 }}>
            <span className="action-icon"><Delete fontSize="inherit" /></span>
            <span className="action-text">Delete Record</span>
          </div> : null
        } */}
      </div>
      <div className='d-flex align-items-center'>
        <BusinessIcon style={{ color: '#7d75d1' }} fontSize='small' />
        <Typography className='master-card'>
          {
            <RedirectToCompanies id={companyDetails?.id} name={company} />
          }
        </Typography>
      </div>
    </div>
  )
}

CommonHeader.propTypes = {
  company: PropTypes.string,
  companyDetails: PropTypes.object,
  jobNumber: PropTypes.number,
  jobTitle: PropTypes.string,
  confidential: PropTypes.bool
}
