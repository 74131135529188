
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { Component } from "react";

//-----------------------------------------------------------// External Imports // ------------------------------

import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import "ag-grid-community/dist/styles/ag-grid.css";
import { withSnackbar } from "notistack";
import { AgGridReact } from "ag-grid-react";
import { Typography } from "@material-ui/core";
import BorderColorIcon from "@material-ui/icons/BorderColor";
import { saveAs } from 'file-saver';
//-----------------------------------------------------------// Internal Imports // ------------------------------

import {
  getBdColumnDefs,
  SUCCESS_STATUS_CODE,
  PAGE_LIMIT,
  CustomLoadingOverlayComponent,
  defaultColumns,
  clientContactsRenderer,
  getFilterParamString,
  // IdRenderer,
} from "./Utils";

import {
  DateTimeRenderer,
  saveColumnStateToLocalStorage,
  loadColumnStateFromLocalStorage,
  getCurrencyPrefix,
  currencyValueSetter
} from "../Commons/Utils";

import "../Commons/index.scss";
import "./index.scss";
import ViewBD from "../../components/ViewSearches/viewBD";
import { SCROLL_TIMEOUT } from "../../utils/common";
import { CompanyNameRenderer } from "../Companies/utils";
import ColumnFilter from "../Commons/ColumnFilter";
import CustomFilter from "../Commons/CustomFilter";
import { dataSourceUtils } from "../../utils/dataSource";
import {
  API, POST, WARNING, ERROR,
  SUCCESS, SYNCLINK_CREATED_SUCCESSFULLY, SYNCLINK_FAILED, BUSINESS_DEVELOPMENT, BLOB, GET, LOE_GENERATION
} from "../../services/constantService";
import {
  notFoundMessage, successMessage, unableMessage
} from "../../services/MessageService";
import { searchDataApi, searchDocumentApi } from "../../services/ApiService";
import { connect } from "react-redux";
import { REFRESH_BDLIST } from "../../types";
import NumberFormat from "react-number-format";

class App extends Component {
  constructor(props) {
    super(props);

    this.isFromCreateSearch = props.isFromCreateSearch || false;
    this.state = {
      viewColumns: false,
      anchorEl: null,
      viewBD: false,
      filterModel: null,
      showFilterCount: 0
    };
  }
  saveColumnState = async () => {
    try {
      this.gridApi && this.gridApi.showLoadingOverlay()
      const columnApi = this.columnApi;
      const gridApi = this.gridApi
      await saveColumnStateToLocalStorage("bdListColumns", { columnApi, gridApi });
      this.gridApi && this.gridApi.hideOverlay()
    } catch (e) {
      console.log("Error found in saveColumnState::", e);
    }
  };

  saveColumnStateForFilter = async () => {
    try {
      this.gridApi && this.gridApi.showLoadingOverlay()
      const columnApi = this.columnApi;
      const gridApi = this.gridApi
      await saveColumnStateToLocalStorage("bdListColumns", { columnApi, gridApi }, true, false);
      this.gridApi && this.gridApi.hideOverlay()
    } catch (e) {
      console.log("Error found in saveColumnState::", e);
    }
  };

  saveColumnStateForSort = async () => {
    try {
      this.gridApi && this.gridApi.showLoadingOverlay()
      const columnApi = this.columnApi;
      const gridApi = this.gridApi
      await saveColumnStateToLocalStorage("bdListColumns", { columnApi, gridApi }, false, true);
      this.gridApi && this.gridApi.hideOverlay()
    } catch (e) {
      console.log("Error found in saveColumnState::", e);
    }
  };

  componentDidUpdate() {
    if (this.props[LOE_GENERATION.LOE_DOCUMENT_UPLOADED]) {
      const event = this.props[LOE_GENERATION.LOE_DOCUMENT_UPLOADED];
      let bdFound
      this.gridApi.forEachNode(node => {
        if (node.data?.id === event.obj?.bd?.id) {
          bdFound = node
        }
      })
      if (bdFound) {
        bdFound.setData(event.obj.bd)
        this.gridApi.refreshCells({ force: true });
      }
      this.props.dispatch({ type: LOE_GENERATION.LOE_DOCUMENT_UPLOADED, payload: undefined })
    }
    if (this.props.refreshBD) {
      this.gridApi.onFilterChanged();
      this.props.dispatch({ type: REFRESH_BDLIST, payload: false })
    }
  }

  componentWillUnmount() {
    const columnApi = this.columnApi
    saveColumnStateToLocalStorage("bdListColumns", { columnApi });
  }

  getURLs = {
    listURl: `${API.business_developments}`,
  };

  dataSource = {
    getRows: async (params) => {
      try {
        params.filterModel && this.setState({ filterModel: params.filterModel });
        this.setState({ showFilterCount: Object.keys(this.state.filterModel).length })
        const thisValue = { ...this };
        this.gridApi.showLoadingOverlay();
        const obj = {
          params: params,
          context: thisValue,
          pageLimit: PAGE_LIMIT,
          url: this.getURLs,
          subScreen: true,
        };
        const { status, data } = await dataSourceUtils(obj, getFilterParamString);
        if (status === SUCCESS_STATUS_CODE) {
          if (data.paging) {
            const message = notFoundMessage("records");
            if (data?.paging?.totalCount === 0) {
              this.props.enqueueSnackbar(message, { variant: WARNING });
            }
            params.successCallback(data.data, data.paging.totalCount);
            data.data.forEach(element => {
              if (!element.synclink_url) {
                element['synclink_label'] = 'create synclink'
              }
              else {
                element['synclink_label'] = 'view synclink'
              }
            });
          } else if (Object.keys(data).length) {
            params.successCallback([data], 1);
          }
        } else {
          params.failCallback();
        }
        this.gridApi.hideOverlay();
      } catch (e) {
        console.log("Error found in getRows::", e);
      }
    },
    rowCount: null,
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    const columnApi = params.columnApi
    const gridApi = params.api
    params.api.sizeColumnsToFit();
    loadColumnStateFromLocalStorage("bdListColumns", { columnApi, gridApi });
    this.setState({ isGridReady: true });
  };

  onViewPopupClose = (refreshData) => {
    if (refreshData) {
      this.gridApi.onFilterChanged();
    }
    this.setState({ viewBD: false });
  };

  EditRenderer = (params) => {
    return (
      <Link
        to={{
          pathname: `/searches/${params.data?.id}/active-candidates`,
          state: { isFromBD: true },
        }}
      >
        <Typography style={{ cursor: "pointer" }} color="primary">
          <BorderColorIcon />
        </Typography>
      </Link>
    );
  };

  JobNumberRendererForPipeline = (params) => {
    return (
      <Link
        to={{
          pathname: `/searches/${params.data?.id}/active-candidates`,
          state: { isFromBD: true },
        }}
      >
        <Typography className="text-link" color="primary">
          {params.value}
        </Typography>
      </Link>
    );
  };

  EstimatedAmountRender = (params) => {
    const currencyCode =
      params.data && params.data.currency ? params.data.currency : "";
    const estimatedRevenue =
      params.data && params.data.estimated_revenue ? params.data.estimated_revenue : "";
    return (
      <NumberFormat
        style={{ border: "none", fontSize: "15px" }}
        color="primary"
        value={estimatedRevenue}
        thousandSeparator={true}
        decimalSeparator="."
        displayType="text"
        prefix={getCurrencyPrefix(currencyCode !== "RUR" ? currencyCode : "")}
        suffix={getCurrencyPrefix(currencyCode === "RUR" ? "RUR" : "")}
        renderText={(data) => {
          const value = currencyValueSetter(data, currencyCode);
          return value ? value : "--";
        }}
      />
    );
  };

  EstimatedBaseFeeRender = (params) => {
    const currencyCode =
      params.data && params.data.currency ? params.data.currency : "";
    const estimatedBaseFee =
      params.data && params.data.estimated_percentage_base ? params.data.estimated_percentage_base : "";
    return (
      <NumberFormat
        style={{ border: "none", fontSize: "15px" }}
        color="primary"
        value={estimatedBaseFee}
        thousandSeparator={true}
        decimalSeparator="."
        displayType="text"
        prefix={getCurrencyPrefix(currencyCode !== "RUR" ? currencyCode : "")}
        suffix={getCurrencyPrefix(currencyCode === "RUR" ? "RUR" : "")}
        renderText={(data) => {
          const value = currencyValueSetter(data, currencyCode);
          return value ? value : "--";
        }}
      />
    );
  };

  IdRenderer = (params) => {
    return (
      <Typography
        className="text-link"
        color="primary"
        onClick={() => {
          this.setState({ viewBD: true, BDData: params.data });
        }}
      >
        {params.value}
        {params?.data?.is_confidential === true ? " (Confidential)" : ""}
      </Typography>
    );
  };

  JobNumberRenderer = (params) => {
    return (
      <Typography
        className="text-link"
        color="primary"
        onClick={() => {
          this.setState({ viewBD: true, BDData: params.data });
        }}
      >
        {params.value}
      </Typography>
    );
  };

  NameRenderer = (params) => {
    return <CompanyNameRenderer company={params?.data?.company} />;
  };

  resetFilter = async () => {
    this.gridApi.setFilterModel(null);
    const customFilterField = this.columnApi.columnController.columnDefs;
    customFilterField.map(item => {
      if (item.filter === 'CustomFilter') {
        this.gridApi.destroyFilter(item.field);
      }
    })
  }

  resetSort = async () => {
    this.gridApi.setSortModel(null);
  }

  createSyncLink = async (data, gridApi) => {
    /* TODO: Here is SyncLink older flow */
    /* const externalLink = `${process.env.REACT_APP_SYNCLINK_URL}/group/sync-link/create-proposal?searchId=${data?.id}`;
    window.open(externalLink, '_blank');
    return; */
    /* TODO: To use new SyncLink flow for create synclink from galaxy api  */
    gridApi.showLoadingOverlay();
    const { status, data: res } = await searchDataApi(POST, data.id, data, 'create-synclink');
    if (status === 200) {
      gridApi.hideOverlay();
      data.synclink_label = 'view synclink'
      this.props.enqueueSnackbar(SYNCLINK_CREATED_SUCCESSFULLY, { variant: SUCCESS });
      gridApi.onFilterChanged();
    } else {
      this.props.enqueueSnackbar(res.message || SYNCLINK_FAILED, { variant: ERROR });
    }
    gridApi.hideOverlay();
  }

  viewSyncLink = (data) => {
    if (data.synclink_url) {
      window.open(data.synclink_url, '_blank');
    }
  }

  validateBD = (bdData) => {
    /* const requiredLOEFields = ['job_number', 'job_title', 'company', 'billing_region', 'billing_address', 'city', 'state',
      'zip_code', 'brand', 'projected_fee', 'partners', 'client_team', 'eas', 'currency',
      'term_type', 'intervals', 'fee_percentage', 'estimated_percentage_base', 'estimated_indirect_total_fee'
    ] */
    const requiredLOEFields = ['job_number', 'job_title', 'company', 'billing_region', 'brand', /* 'projected_fee', */ 'partners', 'currency']
    let isValid = true;
    for (const key of requiredLOEFields) {
      if (key === 'partners') {
        if (!bdData[key]?.length || (bdData[key]?.length && !bdData[key].find(ele => ele?.user))) {
          isValid = false
          break;
        }
      } else if (!bdData[key]) {
        isValid = false
        break;
      }
    }

    if (!isValid) {
      this.props.enqueueSnackbar(BUSINESS_DEVELOPMENT.REQUIRED_FIELDS, { variant: ERROR });
      return false;
    }
    return true;
  }

  generateLOE = async (params) => {
    const isValid = this.validateBD(params.data);
    if (!isValid) return;
    try {
      this.gridApi.showLoadingOverlay();
      const sub_route = `${params.data.id}/${BUSINESS_DEVELOPMENT.GENERATE_LOE}`
      const { status } = await searchDocumentApi(POST, sub_route)
      if (status === 200) {
        const message = successMessage('LOE Document', 'generated')
        this.props.enqueueSnackbar(message, { variant: SUCCESS })
        this.gridApi.onFilterChanged();
      } else {
        const message = unableMessage('LOE Document', 'generate');
        this.props.enqueueSnackbar(message, { variant: ERROR })
      }
    } catch (err) {
      console.log('error in generateLOE', err)
    }
    this.gridApi.hideOverlay();
  }

  downloadLOE = async (params) => {
    const loeDocument = params?.data?.search_documents.find(ele => ele.doctype_code === 'LOE')
    if (!loeDocument) {
      const message = notFoundMessage('LOE Document');
      this.props.enqueueSnackbar(message, { variant: ERROR })
      return
    }
    try {
      this.gridApi.showLoadingOverlay();
      const sub_route = `documents/${loeDocument.id}/download`
      const { status, data } = await searchDocumentApi(GET, sub_route, {}, {}, BLOB)
      if (status === 200) {
        saveAs(data, loeDocument.file_name)
      } else {
        const message = unableMessage('LOE Document', 'download');
        this.props.enqueueSnackbar(message, { variant: ERROR })
      }
    } catch (err) {
      console.log('error in generateLOE', err)
    }
    this.gridApi.hideOverlay();
  }

  ActionsRenderer = (params) => {
    const label = params?.data?.synclink_label ? params?.data?.synclink_label : '';
    return (<div
      className="action-label text-capitalize"
      onClick={() => params?.data?.synclink_label === 'create synclink' ? this.createSyncLink(params?.data, this.gridApi) : this.viewSyncLink(params?.data)}
    >
      {label}
    </div>);
    /* const label = 'create synclink';
    return (<div
      className="action-label text-capitalize"
      onClick={() => this.createSyncLink(params?.data, this.gridApi)}
    >
      {label}
    </div>); */
  };

  LOERenderer = (params) => {
    // const isCreatedLOE = params?.data?.loe_date ? true : false;

    /* check loe document from search documents available */
    const isCreatedLOE = params?.data?.search_documents.find(ele => ele.doctype_code === 'LOE')
    if (params.data?.search_loe_tasks.find(ele => ele.status === LOE_GENERATION.PROCESSING)) {
      return (<div className="text-secondary">Download</div>)
    }
    const label = isCreatedLOE ? 'Download' : 'Generate'
    return (<div
      className="action-label"
      onClick={() => isCreatedLOE ? this.downloadLOE(params) : this.generateLOE(params)}
    >
      {label}
    </div>);
  };

  render() {
    return (
      <div className="list-view">
        <div
          className="d-flex align-items-center justify-content-end"
        >

          <div
            className="action-container"
            style={{ minWidth: '0' }}
            onClick={() => this.resetFilter()}
          >

            <span className="action-text" >
              Reset Filter
            </span>
          </div>
          <div
            className="action-container"
            style={{ minWidth: '0' }}
            onClick={() => this.resetSort()}
          >

            <span className="action-text" >
              Reset Sort
            </span>
          </div>
        </div>
        <div className="list-view flex-grow-1">
          {this.state.isGridReady && (
            <ColumnFilter
              columnApi={this.columnApi}
              defaultColumns={defaultColumns}
              showFilterCount={this.state.showFilterCount}
              filterModel={this.state.filterModel}
            />
          )}
          <div id="myGrid" className="ag-theme-alpine">
            <AgGridReact
              onGridReady={this.onGridReady}
              enableBrowserTooltips={true}
              defaultColDef={{
                minWidth: 100,
                resizable: true,
                sortable: true,
                sortingOrder: ['asc', 'desc', null]
              }}
              blockLoadDebounceMillis={SCROLL_TIMEOUT}
              cacheBlockSize={PAGE_LIMIT}
              loadingOverlayComponent={"CustomLoadingOverlayComponent"}
              frameworkComponents={{
                CustomLoadingOverlayComponent,
                JobNumberRenderer: this.JobNumberRenderer,
                EditRenderer: this.EditRenderer,
                DateTimeRenderer,
                IdRenderer: this.IdRenderer,
                JobNumberRendererForPipeline: this.JobNumberRendererForPipeline,
                NameRenderer: this.NameRenderer,
                clientContactsRenderer,
                EstimatedBaseFeeRender: this.EstimatedBaseFeeRender,
                EstimatedAmountRender: this.EstimatedAmountRender,
                ActionsRenderer: this.ActionsRenderer,
                CustomFilter,
                LOERenderer: this.LOERenderer
              }}
              suppressMenuHide={true}
              scrollbarWidth={12}
              suppressHorizontalScroll={false}
              rowModelType={"infinite"}
              datasource={this.dataSource}
              columnDefs={getBdColumnDefs(this.isFromCreateSearch)}
              paginationPageSize={PAGE_LIMIT}
              rowSelection={"multiple"}
              suppressRowClickSelection={true}
              suppressDragLeaveHidesColumns={true}
              onDisplayedColumnsChanged={this.saveColumnState}
              onDragStopped={this.saveColumnState}
              onSortChanged={this.saveColumnStateForSort}
              onFilterChanged={this.saveColumnStateForFilter}
            ></AgGridReact>
          </div>
          {this.state.viewBD && (
            <ViewBD
              visible={this.state.viewBD}
              onClose={this.onViewPopupClose}
              bd={this.state.BDData}
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    [LOE_GENERATION.LOE_DOCUMENT_UPLOADED]: state.rootReducer[LOE_GENERATION.LOE_DOCUMENT_UPLOADED],
    refreshBD: state.rootReducer.refreshBD,
  }
};
const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

App.propTypes = {
  isFromCreateSearch: PropTypes.bool,
  enqueueSnackbar: PropTypes.func,
  [LOE_GENERATION.LOE_DOCUMENT_UPLOADED]: PropTypes.object,
  dispatch: PropTypes.func,
  refreshBD: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(App));
