
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from "react";

//-----------------------------------------------------------// External Imports // ------------------------------

import moment from "moment";
import { CircularProgress } from "@material-ui/core";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import LinkIcon from '@material-ui/icons/Link';
import TwitterIcon from "@material-ui/icons/Twitter";
import Button from "@material-ui/core/Button";

//-----------------------------------------------------------// Internal Imports // ------------------------------

import { formatDate, formatTime, DEFAULT_DATE_FORMAT, DEFAULT_TIME_FORMAT, DATE_FORMAT_WITHOUT_YEAR, validateDate } from "../../utils/date";
import { getAccessToken } from "../../services/cognitoService";
import { addHttps } from "../../utils/string";
import { checkContactFetchingStatus, getFilterParamString } from "../Contacts/utils";
import {
  DELETE,
  ERROR,
  GET,
  LINKEDIN,
  POST,
  PUT,
  SUCCESS,
  WEBSITE,
  VISIBLE_DEPARTMENTS_LIST,
  WARNING,
  AR_BY_PARTNER_DEPARTMENTS,
  PENDING_REJECT_BILLING_STATUS,
  BILLING_DESK_ROLE,
  REGIONAL_ROLE,
  ADMIN_ROLE,
  HIDE_REGIONAL_SALES_FOR_DEPARTMENTS
} from "../../services/constantService";
import {
  candidateDataApi,
  companyDataApi,
  contactDataApi,
  picklistsDataApi,
  releaseLockApi,
  searchDataApi,
  userDataApi,
  userGridApi,
  workbenchDataApi,
  ProductSettingsApi
} from "../../services/ApiService";
import {
  unableMessage,
  requireMessage,
  successMessage,
  VALIDATION_MESSAGE
} from "../../services/MessageService";
import { REFRESH_BDLIST, REFRESH_SEARCH } from "../../types";

export const PAGE_LIMIT = 200;
export const SUCCESS_STATUS_CODE = 200;
export const PhoneRenderer = (params) => {
  const color =
    params.data?.off_limits ||
      (params.data?.search && params.data?.search.length > 0)
      ? "red"
      : "primary";
  return LinksRenderer(params, `tel:${params.value}`, "", color);
};

export const EmailRenderer = (params) => {
  if (!params || !params.value) {
    return null;
  }
  return LinksRenderer(params, `mailto:${params.value}`);
};

export const LinkedInUserRenderer = (params) => {
  return (
    <>
      {params.params && params.params.value ? (
        <>
          <Button
            className="secondary-btn"
            onClick={() => params.linkedInPopup(params.params)}
          >
            <LinkedInIcon style={{ color: "rgb(40, 103, 178)" }} />
          </Button>
        </>
      ) : (
        <Button className="secondary-btn">
          <LinkedInIcon className="linkedIn-btn" color="disabled" />
        </Button>
      )}
    </>
  );
};
export const WebsiteUserRenderer = (params) => {
  return (
    <>
      {params.params && params.params.value ? (
        <>
          <Button
            className="secondary-btn"
            onClick={() => params.linkedInPopup(params.params)}
          >
            <LinkIcon style={{ color: "rgb(40, 103, 178)" }} />
          </Button>
        </>
      ) : (
        <Button className="secondary-btn">
          <LinkIcon className="linkedIn-btn" color="disabled" />
        </Button>
      )}
    </>
  );
};

export const LinkedInRenderer = (params) => {
  let linkedinUrl = params.contact ? params.contact?.linkedin_url : params.params.value
  return linkedinUrl ? (
    <a
      href={addHttps(linkedinUrl)}
      target="_blank"
      rel="noopener noreferrer"
      className={`action-label ${params.className || ''}`}
    >
      <LinkedInIcon style={{ color: "rgb(40, 103, 178)" }} />
    </a>
  ) : (
    <LinkedInIcon className="linkedIn-btn" color="disabled" />
  );
};

export const getQrCodeFields = (urlType) => {
  let lable = '', tooltipMessage = '';
  if (urlType === 'linkedIn') {
    lable = LINKEDIN.LABLE;
    tooltipMessage = LINKEDIN.TOOLTIP_MESSAGE
  } else if (urlType === 'website') {
    lable = WEBSITE.LABLE;
    tooltipMessage = WEBSITE.TOOLTIP_MESSAGE
  }
  return { lable, tooltipMessage }
}

export const TwitterRenderer = (params) => {
  return params.value ? (
    <a
      href={addHttps("twitter.com/" + params.value)}
      target="_blank"
      rel="noopener noreferrer"
      className="action-label"
    >
      <TwitterIcon style={{ color: "rgb(40, 103, 178)" }} />
    </a>
  ) : (
    <TwitterIcon className="linkedIn-btn" color="disabled" />
  );
};

export const CustomLoadingOverlayComponent = () => {
  return <CircularProgress />;
};

export const LinksRenderer = (params, href, target = "", color = "primary") => {
  return (
    <a
      href={href}
      target={target}
      className="action-label"
      style={{ color: color }}
    >
      {params.value}
    </a>
  );
};

export const DateRenderer = (params) => {
  const status = checkContactFetchingStatus(params)
  if (status) return status;
  return params.value ? formatDate(params.value, params.column && (params.column.colId === "birth_date") ? DATE_FORMAT_WITHOUT_YEAR : DEFAULT_DATE_FORMAT) : "";
};

export const DateAndTimeRenderer = (params) => {
  const status = checkContactFetchingStatus(params)
  if (status) return status;
  return params.value ? formatDate(params.value, params.column && (DEFAULT_DATE_FORMAT + " " + DEFAULT_TIME_FORMAT)) : "";
};

export const ReplistRenderer = (params) => {
  return params.value && params.data.type === "Searches"
    ? "Yes"
    : params.value === false && params.data.type === "Searches"
      ? "No"
      : "";
};

export const DateTimeRenderer = (params) => {
  const status = checkContactFetchingStatus(params)
  if (status) return status;
  return (
    <span className="d-flex flex-column">
      <span>{params && formatDate(params.value)}</span>
      <span>{params && formatTime(params.value)}</span>
    </span>
  );
};

export const getList = (items, param) => {
  if (items && items.length) {
    return items.map((item) => encodeURIComponent(item[param]));
  }
  return [];
};

export const getSingleValueParam = (data, param) => {
  return data[param] ? `&${param}=${encodeURIComponent(data[param])}` : "";
};

export const getMultiValueParam = (data, param) => {
  if (data && data.length) {
    let list;
    if (data && data.length && typeof data[0] === "object") {
      list = data.map((value) => `${param}=${value.name}`);
    } else {
      list = data.map((value) => `${param}=${value}`);
    }
    return "&" + list.join("&");
  }
  return "";
};

export const localTimeZoneHandler = async (timeZoneOptions) => {
  let defaultTime = {};
  if (!timeZoneOptions.length) {
    const sub_route = "timezones";
    let { status, data } = await picklistsDataApi(GET, "", sub_route);
    if (status === 200) {
      const filtered = [];
      const country = Intl.DateTimeFormat()
        .resolvedOptions()
        .timeZone.split("/")[1];
      const localTimeZone = new Date().toTimeString().split(" ")[1];
      data.forEach((element) => {
        const time = element.name.replace(/[():]/g, "").split(" ")[0];
        if (localTimeZone === time) {
          filtered.push(element);
        }
      });
      defaultTime = filtered[0];
      for (let i = 0; i < filtered.length; i++) {
        if (filtered[i]?.type?.includes(country)) {
          defaultTime = filtered[i];
        }
      }
    }
    return {
      timeZoneOptions: data,
      defaultLocalTime: defaultTime,
    };
  }
};

export const saveColumnStateToLocalStorage = async (key, gridOptions, filterFlag = false, sortFlag = false) => {
  const { columnApi, gridApi } = gridOptions
  if (columnApi) {
    const token = await getAccessToken();
    if (token) {
      let sort;
      let filter;
      if (gridApi) {
        sort = JSON.stringify(gridApi.getSortModel())
        filter = JSON.stringify(gridApi.getFilterModel())
      }
      const payload = JSON.stringify(columnApi.getColumnState());
      const body = { grid_key: `${key}`, settings: payload };
      if (filterFlag) {
        body.filter = filter;
      }
      if (sortFlag) {
        body.sort = sort;
      }
      await userGridApi(POST, "", body);
    }
  }
};

export const loadColumnStateFromLocalStorage = async (key, gridOptions) => {
  const { columnApi, gridApi } = gridOptions
  const sub_route = `key=${key}`;
  const data = await userGridApi(GET, sub_route);
  const columnState = data?.data?.settings;
  const sort = data?.data?.sort;
  const filter = data?.data?.filter;
  if (columnState) {
    const success = columnApi.setColumnState(JSON.parse(columnState));
    if (!success) {
      columnApi.resetColumnState();
    }
  }
  if (sort) {
    gridApi.setSortModel(JSON.parse(sort));
  }
  if (filter) {
    gridApi.setFilterModel(JSON.parse(filter));
  }
};

export function validate(params, required) {
  if (!params || params.oldValue === params.newValue) {
    return false;
  }
  if (
    required &&
    (!params.newValue ||
      (typeof params.newValue === "string" && params.newValue === "") ||
      (Array.isArray(params.newValue) && params.newValue.length === 0))
  ) {
    return false;
  }
  return true;
}

export const updateUser = async (
  params,
  fieldName,
  enqueueSnackbar,
  required = false,
  SetLoader,
  failureCallback
) => {

  if (!validate(params, required)) {
    const message = requireMessage(`${params.colDef.headerName || fieldName}`, "is");
    enqueueSnackbar && enqueueSnackbar(message, { variant: ERROR });
    return false;
  }
  if (fieldName === "company_name")
    params.data[fieldName] = params.newValue.name || null;
  else if (fieldName === "kg_employee")
    params.data[fieldName] = params.newValue || false;
  else params.data[fieldName] = params.newValue || null;
  SetLoader(true);
  const payload = fieldName === 'state' ? {
    [fieldName]: params.data[fieldName],
    roles: params.data.roles, country: params.data.country || null
  } : {
    [fieldName]: params.data[fieldName],
    roles: params.data.roles
  }
  const { status, data } = await userDataApi(PUT, params.data.id, payload);
  if (status === 200) {
    SetLoader(false);
    const message = successMessage("User", VALIDATION_MESSAGE.updated_message);
    enqueueSnackbar && enqueueSnackbar(message, { variant: SUCCESS });
  } else {
    SetLoader(false);
    const oldData = {
      ...params.data,
      [fieldName]: params.oldValue,
    };
    params.node.setData(oldData);
    const message = unableMessage("user", "update");
    enqueueSnackbar &&
      enqueueSnackbar(VALIDATION_MESSAGE.date_validation_error || message || data.message, { variant: ERROR });
    failureCallback && failureCallback();
    return false;
  }
  params.api && params.api.refreshCells();
  return true;
};

export const updateTranslateValue = async (
  params,
  fieldName,
  enqueueSnackbar,
  SetLoader,
  required = false) => {

  if (!validate(params, required)) {
    const message = requireMessage(`${params.colDef.headerName || fieldName}`, "is");
    enqueueSnackbar && enqueueSnackbar(message, { variant: ERROR });
    return false;
  }
  SetLoader(true);
  params.data[fieldName] = params.newValue;
  const { status, data } = await ProductSettingsApi(PUT, params.data, 'translate_value', params.data.id);
  if (status === 200) {
    const message = successMessage("Translate Values", VALIDATION_MESSAGE.updated_message);
    enqueueSnackbar && enqueueSnackbar(message, { variant: SUCCESS });
    SetLoader(false)
  } else {
    const message = unableMessage("Translate Values", "update");
    enqueueSnackbar &&
      enqueueSnackbar(data?.message || message, { variant: ERROR });
    SetLoader(false)
  }
  params.api && params.api.refreshCells();
  return true;
}

export const updateDocumentType = async (
  params,
  fieldName,
  enqueueSnackbar,
  SetLoader) => {
  SetLoader(true);
  params.data[fieldName] = params.newValue;
  const { status, data } = await ProductSettingsApi(PUT, params.data, 'document_type', params.data.id);
  if (status === 200) {
    const message = successMessage("Document Type", VALIDATION_MESSAGE.updated_message);
    enqueueSnackbar && enqueueSnackbar(message, { variant: SUCCESS });
    SetLoader(false)
  } else {
    const message = unableMessage("Document Type", "update");
    enqueueSnackbar &&
      enqueueSnackbar(data?.message || message, { variant: ERROR });
    SetLoader(false)
  }
  params.api && params.api.refreshCells();
  return true;
}

export const updateContact = async (
  params,
  fieldName,
  enqueueSnackbar,
  required = false,
  SetLoader,
  failureCallback,
  changeJobHistory
) => {
  if (!validate(params, required)) {
    const message = requireMessage(`${params.colDef.headerName || fieldName}`, "is");
    enqueueSnackbar && enqueueSnackbar(message, { variant: ERROR });
    SetLoader && SetLoader(false)
    return false;
  }

  if (params.data.contact) {
    params.data.contact[fieldName] =
      Array.isArray(params.newValue) && fieldName !== "kg"
        ? params.newValue.join(",")
        : params.newValue || null;
  } else {
    params.data[fieldName] =
      Array.isArray(params.newValue) && fieldName !== "kg"
        ? params.newValue.join(",")
        : params.newValue || null;
  }
  const contactId =
    (params.data.contact && params.data.contact.id) || params.data.id;
  let payload = null;
  if (fieldName === "kg") {
    payload = { "kg_25": params.newValue.map((item) => item.id) };
  } else if (fieldName === "state") {
    payload = { [fieldName]: params.newValue || null, country: (params.country ? params.country : params.data.country) || null };
  }
  else {
    payload = { [fieldName]: params.newValue || null };
  }
  if (changeJobHistory) {
    payload.job_history = params.job_history
  }
  SetLoader(true);
  const { status, data } = await contactDataApi(PUT, contactId, payload);
  if (status === 200) {
    SetLoader(false);
    const message = successMessage("Contact", VALIDATION_MESSAGE.updated_message);
    enqueueSnackbar && enqueueSnackbar(message, { variant: SUCCESS });
    await releaseLockApi(contactId);
  } else {
    SetLoader(false);
    let oldData = {
      ...params.data,
      [fieldName]: params.oldValue,
    };
    // update from candidate listing page
    if (params.data.contact) {
      oldData = {
        ...params.data,
        contact: {
          ...params.data.contact,
          [fieldName]: params.oldValue,
        },
      };
    }

    params.node.setData(oldData);
    const message = unableMessage("contact", "update");
    enqueueSnackbar &&
      enqueueSnackbar(data?.message || message, { variant: ERROR });
    failureCallback && failureCallback();
    return false;
  }
  params.api && params.api.refreshCells();
  return true;
};

export const updateCandidate = async (
  params,
  fieldName,
  enqueueSnackbar,
  required = false,
  SetLoader,
  failureCallback,
  changeJobHistory = false
) => {
  if (!validate(params, required)) {
    const message = requireMessage(`${params.colDef.headerName || fieldName}`, "is");
    enqueueSnackbar && enqueueSnackbar(message, { variant: ERROR });
    return false;
  }

  params.newValue = params.newValue || null;
  params.data[fieldName] = params.newValue;
  SetLoader(true);
  const payload = {
    [fieldName]: params.newValue,
  };
  if (changeJobHistory) {
    payload.job_history = params.job_history
  }
  const { status, data } = await candidateDataApi(PUT, params.data.id, payload);
  if (status === 200) {
    SetLoader(false);
    const message = successMessage("Candidate", VALIDATION_MESSAGE.updated_message);
    enqueueSnackbar && enqueueSnackbar(message, { variant: SUCCESS });
    await releaseLockApi(params.data.contact.id);
  } else {
    SetLoader(false);
    const oldData = {
      ...params.data,
      [fieldName]: params.oldValue,
    };
    params.node.setData(oldData);
    const message = unableMessage("candidate", "update");
    enqueueSnackbar &&
      enqueueSnackbar(data?.message || message, { variant: ERROR });
    failureCallback && failureCallback();
    return false;
  }
  params.api && params.api.refreshCells();
  return true;
};

export const updateWorkbench = async (
  params,
  fieldName,
  enqueueSnackbar,
  required = false,
  workbenchId,
  SetLoader,
  failureCallback
) => {
  // this functions just updates notes field. please make it dynamic if want to use more fields from the model workbench
  if (!validate(params, required)) {
    const message = requireMessage(`${params.colDef.headerName || fieldName}`, "is");
    enqueueSnackbar && enqueueSnackbar(message, { variant: ERROR });
    SetLoader(false);
    return false;
  }
  params.newValue = params.newValue || null
  const contactId =
    (params.data.contact && params.data.contact.id) || params.data.id;
  let payload = { notes: params.newValue, contactId: contactId };
  // only work for notes field. If want to add more fields, need to make this dynamic
  params.data.contact_note[0].contact_note = params.newValue;
  SetLoader(true);
  const sub_route = "workbench-contact";
  const { status, data } = await workbenchDataApi(
    PUT,
    workbenchId,
    payload,
    sub_route
  );
  if (status === 200) {
    SetLoader(false);
    const message = successMessage("Contact", "updated");
    enqueueSnackbar && enqueueSnackbar(message, { variant: SUCCESS });
    await releaseLockApi(contactId);
  } else {
    let oldData = {
      ...params.data,
      [fieldName]: params.oldValue,
    };
    // update from candidate listing page
    if (params.data.contact) {
      oldData = {
        ...params.data,
        contact: {
          ...params.data.contact,
          [fieldName]: params.oldValue,
        },
      };
    }
    params.node.setData(oldData);
    const message = unableMessage("contact", "update");
    enqueueSnackbar &&
      enqueueSnackbar(data?.message || message, { variant: ERROR });
    failureCallback && failureCallback();
    return false;
  }
  params.api && params.api.refreshCells();
  return true;
};
export const updateCompany = async (
  params,
  fieldName,
  enqueueSnackbar,
  required = false,
  SetLoader,
  failureCallback
) => {
  if (!validate(params, required)) {
    const message = requireMessage(`${params.colDef.headerName || fieldName}`, "is");
    enqueueSnackbar && enqueueSnackbar(message, { variant: ERROR });
    return false;
  }

  const formData = {};
  if (fieldName === "parent_id") {
    params.data.parent = params.newValue || null;
    formData[fieldName] = params.newValue.id || null;
  } else if (typeof (params.newValue) === 'boolean') {
    params.data[fieldName] = params.newValue;
    formData[fieldName] = params.newValue;
  } else if (fieldName === "state") {
    params.data[fieldName] = params.newValue;
    formData[fieldName] = params.newValue;
    formData["country"] = params.data.country || null
  } else {
    params.data[fieldName] = params.newValue || null;
    formData[fieldName] = params.newValue || null;
  }
  SetLoader(true);
  const { status, data } = await companyDataApi(PUT, params.data.id, formData);
  if (status === 200) {
    SetLoader(false);
    const oldData = {
      ...params.data,
      updated_at: data.updated_at
    };
    params.node.setData(oldData);
    const message = successMessage("Company", VALIDATION_MESSAGE.updated_message);
    enqueueSnackbar && enqueueSnackbar(message, { variant: SUCCESS });
  } else {
    SetLoader(false);
    const oldData = {
      ...params.data,
      [fieldName]: params.oldValue,
    };
    params.node.setData(oldData);
    const message = unableMessage("company", "update");
    enqueueSnackbar &&
      enqueueSnackbar(data?.message || message, { variant: ERROR });
    failureCallback && failureCallback();
    return false;
  }
  params.api && params.api.refreshCells();
  return true;
};

export const getDateFilterOptions = () => [
  {
    displayKey: "lessThan",
    displayName: "Before",
    test: function (filterValue, cellValue) {
      return cellValue < filterValue;
    },
  },
  {
    displayKey: "greaterThan",
    displayName: "After",
    test: function (filterValue, cellValue) {
      return cellValue > filterValue;
    },
  },
];

export const getMatchFilterOptions = (reverse = false) => {
  const options = [
    {
      displayKey: "lessThan",
      displayName: "Less Than Or Equal",
      test: function (filterValue, cellValue) {
        return cellValue <= filterValue;
      },
    },
    {
      displayKey: "greaterThan",
      displayName: "Greater Than Or Equal",
      test: function (filterValue, cellValue) {
        return cellValue >= filterValue;
      },
    },
  ];
  return reverse ? options.reverse() : options;
};

const getFilterParam = (key, filter, queryAliases) => {
  if (filter.filterType === "date" || filter.filterType === "number") {
    return `${key}_${filter.type === "lessThan" ? "to" : "from"}`;
  }
  return queryAliases[key] || key;
};

export const getFilterQuery = (
  data,
  queryAliases,
  queryValueTypes,
  prefix /* AdvancedQuery = false */
) => {
  let queryString = "";
  Object.keys(data).forEach((key) => {
    const filterParam = getFilterParam(key, data[key], queryAliases);
    const filterKey = (prefix ? "sub_" : "") + filterParam;
    let queryKeyValue;
    if (
      (data[key] && (data[key].filter || data[key].filter === 0)) ||
      (data[key] &&
        data[key].filter === 0 &&
        (filterParam === "color" || filterParam === "search_color" || filterParam === "contact_color" || filterParam === "lifer"))
    ) {
      if (
        (typeof data[key].filter === "object" && key === "kg") ||
        key === "created_user" ||
        key === "job_functions" ||
        key === "partners" ||
        key === "recruiters" ||
        key === "researchers" ||
        key === "work_group"
      ) {
        if (
          key === "kg" ||
          key === "created_user" ||
          key === "job_functions" ||
          key === "partners" ||
          key === "recruiters" ||
          key === "researchers" ||
          key === "work_group"
        ) {
          queryKeyValue = data[key].filter;
        } else {
          queryKeyValue = data[key].filter[0].name;
        }
      } else {
        queryKeyValue = data[key].filter;
        if (typeof data[key].filter === "string")
          queryKeyValue = data[key].filter.trim();
      }
    }
    if (Array.isArray(queryKeyValue) && queryKeyValue.length) {
      if (!queryValueTypes[filterParam]?.isValueType) {
        queryKeyValue = getList(
          queryKeyValue,
          queryValueTypes[filterParam]?.field || "id"
        );
      }
      queryString += getMultiValueParam(queryKeyValue, filterKey);
    } else {
      if (data[key].filterType === "date") {
        const { dateFrom, dateTo } = data[key];
        let KeyValue = dateFrom ? dateFrom : dateTo;
        queryKeyValue = moment(KeyValue).format("YYYY-MM-DD");
      }
      queryString +=
        queryKeyValue || queryKeyValue === 0
          ? `&${filterKey}=${queryKeyValue}`
          : "";
    }
  });
  return queryString;
};

export const updateSearch = async (
  params,
  fieldName,
  required,
  enqueueSnackbar = () => { },
  SetLoader,
  dispatch
) => {
  if (!validate(params, required)) {
    const message = requireMessage(`${params.colDef.headerName || fieldName}`, "is");
    enqueueSnackbar(message, { variant: ERROR });
    return false;
  }
  params.data[fieldName] = params.newValue;
  if (fieldName === "on_hold_date") {
    const isValid = validateDate(params.newValue)
    if (!isValid) {
      enqueueSnackbar(VALIDATION_MESSAGE.date_validation_error, { variant: ERROR });
      return false;
    }
  }
  let payload = null;
  if (fieldName === "state") {
    payload = {
      [fieldName]: params.newValue,
      country: params.data.country,
      old: {
        [fieldName]: params.oldValue,
      },
    }
  } else {
    payload = {
      [fieldName]: params.newValue,
      old: {
        [fieldName]: params.oldValue,
      },
    }
  }

  SetLoader(true);
  const { status, data } = await searchDataApi(PUT, params.data?.id, payload);
  if (status === 200) {
    if (fieldName === 'stage') {
      if (payload?.stage === "BD") {
        if (typeof dispatch === 'function') {
          dispatch({ type: REFRESH_BDLIST, payload: true })
          dispatch({ type: REFRESH_SEARCH, payload: true })
          //setTimeout(() => dispatch({ type: REFRESH_SEARCH, payload: false }), 300)
        }
      }
    }
    SetLoader(false);
    const message = successMessage("Search", VALIDATION_MESSAGE.updated_message);
    enqueueSnackbar(message, { variant: SUCCESS });
  } else {
    let oldData = {
      ...params.data,
      [fieldName]: params.oldValue,
    };

    params.node.setData(oldData);
    const message = unableMessage("contact", "update");
    enqueueSnackbar(data?.message || message, { variant: ERROR });
    return false;
  }
  params.api && params.api.refreshCells();
  return true;
};

export const removeContactsFromWorkbench = async (
  workbenchId,
  contacts,
  enqueueSnackbar,
  filterModel
) => {
  let sub_route = `contacts?`;
  if (filterModel) {
    sub_route += getFilterParamString(filterModel);
  }
  const { status, data } = await workbenchDataApi(
    DELETE,
    workbenchId,
    contacts,
    sub_route
  );
  if (status === 200) {
    const message = successMessage("Contact(s) removed", "from workbench");
    enqueueSnackbar(message, { variant: SUCCESS });
    return true;
  } else {
    const message = unableMessage("from workbench", "remove contact(s)");
    enqueueSnackbar(data?.message || message, { variant: ERROR });
  }
};

export const removeSearchesFromWorkbench = async (
  workbenchId,
  contacts,
  enqueueSnackbar,
  filterModel
) => {
  let sub_route = `searches?`;
  if (filterModel) {
    sub_route += getFilterParamString(filterModel);
  }
  const { status, data } = await workbenchDataApi(
    DELETE,
    workbenchId,
    contacts,
    sub_route
  );
  if (status === 200) {
    const message = successMessage("Searches removed", "from workbench");
    enqueueSnackbar(message, { variant: SUCCESS });
    return true;
  } else {
    const message = unableMessage("from workbench", "remove searches");
    enqueueSnackbar(data?.message || message, { variant: ERROR });
  }
};

export const addContactsToWorkbench = async (
  workbenchId,
  contacts,
  enqueueSnackbar
) => {
  const sub_route = "contacts";
  const { status, data } = await workbenchDataApi(
    POST,
    workbenchId,
    contacts,
    sub_route
  );
  return processAddContactsResponse(status, data, enqueueSnackbar);
};

export const addSearchesToWorkbench = async (
  workbenchId,
  searches,
  enqueueSnackbar
) => {
  const sub_route = "searches";
  const { status, data } = await workbenchDataApi(
    POST,
    workbenchId,
    searches,
    sub_route
  );
  return processAddSearchesResponse(status, data, enqueueSnackbar);
};

export const processAddContactsResponse = (status, data, enqueueSnackbar) => {
  if (status === 200) {
    const message = successMessage("Contact(s) added to", "workbench");
    enqueueSnackbar(message, { variant: SUCCESS });
    return true;
  } else {
    const message = unableMessage("workbench", "add contact(s) to");
    enqueueSnackbar(data?.message || message, { variant: ERROR });
  }
  return false;
};

export const processAddSearchesResponse = (status, data, enqueueSnackbar) => {
  if (status === 200) {
    const message = successMessage("Search added to", "workbench");
    enqueueSnackbar(message, { variant: SUCCESS });
    return true;
  } else {
    const message = unableMessage("workbench", "add searches to");
    enqueueSnackbar(data?.message || message, { variant: ERROR });
  }
  return false;
};

export const removerBorder = {
  border: "none",
  borderBottom: "2px solid #a3a3a3",
  width: "450px",
  outline: 'none'
};

export const getCurrencyPrefix = (currency) => {
  if ((currency === ('USD')) || (currency === ('COP')) || (currency === ('CLP')) || (currency === ('ARS'))) {
    return '$'
  }
  else if (currency === ('MXN')) {
    return 'Mex$'
  }
  else if (currency === 'BRL') {
    return 'R$'
  }
  else if (currency === 'CNY/RMB') {
    return '¥'
  }
  else if (currency === 'CRC') {
    return '₡'
  }
  else if (currency === 'EUR') {
    return '€'
  }
  else if (currency === 'GBP') {
    return '£'
  }
  else if (currency === 'ILS') {
    return '₪'
  }
  else if (currency === 'INR') {
    return '₹'
  }
  else if (currency === 'JPY') {
    return '¥'
  }
  else if (currency === 'PAB') {
    return 'B/.'
  }
  else if (currency === 'PEN') {
    return 'S/.'
  }
  else if (currency === 'RUR') {
    return '₽'
  }
  else if (currency === 'VEF') {
    return 'Bs.'
  }
  else if (currency === 'PHP') {
    return '₱'
  }
  else if (currency === 'AED') {
    return 'د.إ'
  } else if (currency === ('SGD')) {
    return 'S$'
  }
  return ''
}

export const currencyValueSetter = (value, currencyCode) => {
  if (
    currencyCode === "ARS" ||
    currencyCode === "BRL" ||
    currencyCode === "EUR"
  ) {
    if (value.includes(".")) {
      let arrayOfValue = value.split(".");
      arrayOfValue[0] = arrayOfValue[0].replace(/,/g, ".");
      value = `${arrayOfValue[0]},${arrayOfValue[1]}`;
      return value;
    }
    value = value.replace(/,/g, ".");
    return value;
  }
  return value;
};

export const showSnackbar = (object) => {

  object.enqueueSnackbar(object.message, {
    variant: WARNING,
    // eslint-disable-next-line react/display-name
    action: key => (
      <>
        <Button
          size='small'
          variant='text'
          color='inherit'
          onClick={() => {
            object.closeSnackbar(key)
            releaseLockApi(object.id);
          }}
        >
        Force Unlock
        </Button>
        <Button
          size='small'
          variant='text'
          color='inherit'
          onClick={async () => {
            object.closeSnackbar(key)
          }}
        >
               CLOSE
        </Button>
      </>
    )
  });
}

export const isCurrency = (field, type) => {
  if (
    (field === "actual_bonus_value" && type === "PERCENTAGE") ||
    (field === "target_bonus_value" && type === "PERCENTAGE")
  ) {
    return false;
  }
  return true;
};

const currencyIdentifier = ["ARS", "BRL", "EUR"];

export const separator = (code, field) => {
  if (field === "ThousandSeparator") {
    if (currencyIdentifier.includes(code)) {
      return ".";
    }
    return ",";
  } else if (field === "DecimalSeparator") {
    if (currencyIdentifier.includes(code)) {
      return ",";
    }
    return ".";
  }
};

export const checkDashboardDepartment = (userData) => {
  if (userData && userData.departments && userData.departments.length) {
    return userData.departments.filter((department) => VISIBLE_DEPARTMENTS_LIST.includes(department)).length ? true : false
  }
  return false;
}

export const checkPmRecruiter = (userData, search, skip = false) => {
  let result = false;
  if (userData) {
    if (search?.recruiters?.length > 0) {
      result = search.recruiters.filter((ele) => ele?.user?.id === userData.id)
      if (skip) {
        if (result && result.length > 0) {
          return true
        }
      } else {
        if (result && result.length > 0 && PENDING_REJECT_BILLING_STATUS.includes(search.billing_desc)) {
          return true
        }
      }
    }
    if (search?.eas?.length > 0) {
      result = search.eas.filter((ele) => ele?.user?.id === userData.id)
    }
    if (skip) {
      if (result && result.length > 0) {
        return true
      } else {
        return false
      }
    } else {
      if (result && result.length > 0 && PENDING_REJECT_BILLING_STATUS.includes(search.billing_desc)) {
        return true
      } else {
        return false
      }
    }
  }
  return false
}

export const checkSearchApprovalRoles = (userData) => {
  if (userData.roles.includes(ADMIN_ROLE)) {
    return true;
  } else {
    if (userData && userData.all_roles && userData.all_roles.length) {
      return userData.all_roles.some((roles) => {
        if (roles.includes(REGIONAL_ROLE)) {
          return true;
        } else if (roles.includes(BILLING_DESK_ROLE)) {
          return true;
        }
      })
    }
  }
  return false;
}

export const checkBillingRole = (userData) => {
  if (userData.roles.includes(ADMIN_ROLE)) {
    return true;
  } else {
    if (userData.all_roles && userData.all_roles.length > 0) {
      return userData.all_roles.some(item => item.includes(BILLING_DESK_ROLE))
    }
  }
}

export const checkRegionRole = (userData) => {
  if (userData.roles.includes(ADMIN_ROLE)) {
    return true;
  } else {
    if (userData.all_roles && userData.all_roles.length > 0) {
      return userData.all_roles.some(item => item.includes(REGIONAL_ROLE))
    }
  }
}

export const checkRegionalOrBillingRoles = (userData) => {
  let role;
  if (userData && userData.roles && userData.roles.length) {
    role = userData.roles.filter((roles) => REGIONAL_ROLE.includes(roles)).length ? true : false
    if (role) {
      return role = 'Regional Head'
    }
  }
  if (userData && userData.roles && userData.roles.length) {
    role = userData.roles.filter((roles) => BILLING_DESK_ROLE.includes(roles)).length ? true : false
    if (role) {
      return role = 'Billing Desk'
    }
  }
  return false;
}

export const checkArByPartnerDepartment = (userData) => {
  if (userData && userData.departments && userData.departments.length) {
    return userData.departments.filter((department) => AR_BY_PARTNER_DEPARTMENTS.includes(department)).length ? true : false
  }
  return false;
}
export const checkRegionalSalesDepartment = (userData) => {
  if (userData && userData.departments && userData.departments.length) {
    return userData.departments.filter((department) => HIDE_REGIONAL_SALES_FOR_DEPARTMENTS.includes(department)).length ? true : false
  }
  return false;
}

export const currencyWithCommas = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}