
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from "prop-types";
import { withSnackbar } from "notistack";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";

//-----------------------------------------------------------// Internal Imports // ------------------------------

import { GET, ERROR, AVAILABLE_COUNTRY_WITH_STATES } from "../../../services/constantService";
import { picklistsCountryStateDataApi } from "../../../services/ApiService";
import { unableMessage } from "../../../services/MessageService";
import { useDispatch, useSelector } from "react-redux";
import { UPDATE_STATE_LIST } from "../../../types";

// eslint-disable-next-line react/display-name
const StateEditor = forwardRef((props, ref) => {
  const { value, data: rowData, enqueueSnackbar, stopEditing } = props;
  const [country] = useState(rowData.country);
  const [state, setState] = useState(value);
  const [stateOptions, setStateOptions] = useState([]);
  const usState = useSelector(state => state.rootReducer.usState)
  const dispatch = useDispatch()

  // const USA = "United States of America (USA)";

  useImperativeHandle(ref, () => {
    return {
      getValue: () => state,
      afterGuiAttached: () => { },
    };
  });

  useEffect(()=>{
    dispatch({ type: UPDATE_STATE_LIST, payload: { key: 'usState', undefined } })
  },[])

  useEffect(() => {
    if (
      // country &&
      // typeof country === "string" &&
      // country.toUpperCase() === USA.toUpperCase()
      country && AVAILABLE_COUNTRY_WITH_STATES.includes(country)
    ) {
      const fetchStates = async () => {
        if (!usState) {
          const sub_route = `?countryName=${country}`;
          let { status, data } = await picklistsCountryStateDataApi(
            GET,
            sub_route
          );
          if (status && status === 200) {
            dispatch({ type: UPDATE_STATE_LIST, payload: { key: 'usState', data } })
            let array = [];
            data.map((data) => array.push(data.name));
            setStateOptions(array);
          } else {
            const message = unableMessage("states list", "fetch");
            enqueueSnackbar(message, { variant: ERROR });
          }
        } else {
          let array = [];
          usState.map((data) => array.push(data.name));
          setStateOptions(array);
        }
      };

      fetchStates().then(null);
    }
  }, [enqueueSnackbar, country]);

  useEffect(() => {
    return () => {
      // eslint-disable-next-line react/prop-types
      props.api.refreshCells(props.column);
      stopEditing();
    };
  }, [stopEditing, props]);

  return (
    <Autocomplete
      style={{ padding: "10px" }}
      value={state}
      autoSelect
      onChange={(e, data) => setState(data ? data : e.target.value)}
      onInputChange={(e, data) => setState(data ? data : e.target.value)}
      options={stateOptions || []}
      freeSolo={!stateOptions.length}
      renderInput={(params) => (
        <TextField {...params} label="" placeholder="State" />
      )}
    />
  );
});

export default withSnackbar(StateEditor);

StateEditor.propTypes = {
  value: PropTypes.string,
  data: PropTypes.object,
  api: PropTypes.object,
  enqueueSnackbar: PropTypes.func,
  stopEditing: PropTypes.func,
};
