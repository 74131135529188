
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { Component } from "react";

//-----------------------------------------------------------// External Imports // ------------------------------

import Checkbox from "@material-ui/core/Checkbox";

export default class HeaderCheckbox extends Component {
  handleChange = (event) => {
    if (event.currentTarget.checked) {
      // eslint-disable-next-line react/prop-types
      this.props.api.forEachNode((node) => {
        node.setSelected(true);
      });
    } else {
      // eslint-disable-next-line react/prop-types
      this.props.api.forEachNode((node) => {
        node.setSelected(false);
      });
    }
  };

  render() {
    return (
      <Checkbox
        style={{ padding: 0, width: 16, height: 16, color: 'white' }}
        size="small"
        onChange={this.handleChange}
      />
    );
  }
}
