//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from "react";

//----------------------------------------------// External Imports // -------------------------------------------------0
import PropTypes from "prop-types";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { fetchTypeList } from "../../../actions";

function ReportTypeSelection(props) {
  const {
    className = 'w-100',
    label,
    InputLabelProps,
    required,
    selectedType,
    placeholder = "Report Name",
    ...rest
  } = props

  const [options, setOptions] = useState([]);
  const reportName = useSelector(state => state.commonReducer.report_name)
  const dispatch = useDispatch()


  const filterDataOptions = (data) => {

    return data.filter((item) => {
      return selectedType && selectedType.every(listItem => listItem?.report_name !== item?.short_desc)
    })
  }

  useEffect(() => {
    if (!reportName) {
      dispatch(fetchTypeList('REPORT_NAME', 'report_name'))
    } else {
      if (selectedType && selectedType[0] && selectedType[0].report_name !== undefined) {
        const filterData = filterDataOptions(reportName)
        setOptions(filterData);
      } else {
        setOptions(reportName);
      }
    }
  }, [reportName, dispatch])

  // useEffect(() => {
  //   if(!feeTypes)return
  //   if (feeTypes){
  //     if (selectedType && selectedType[0] && selectedType[0].report_name !== undefined) {
  //       const filterData = filterDataOptions(feeTypes)
  //       setOptions(filterData)
  //     } else {
  //       setOptions(feeTypes);
  //     }
  //   }     
  // }, [selectedType])

  return (
    <Autocomplete
      {...rest}
      filterOptions={(options) => options}
      disableClearable
      className={className}
      options={options}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option
        }
        return option.short_desc
      }}
      getOptionSelected={(option, value) => {
        if (typeof value === 'string') {
          return option.short_desc === value;
        }
        return option.short_desc === value.short_desc
      }}

      renderInput={(params) => (
        <TextField
          {...params}
          required={required}
          name='report_name'
          InputLabelProps={InputLabelProps}
          label={label}
          placeholder={placeholder}

        />
      )}
    />
  )
}

ReportTypeSelection.propTypes = {
  required: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  InputLabelProps: PropTypes.object,
  list: PropTypes.object,
  selectedType: PropTypes.array
}

export default ReportTypeSelection;
