//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useEffect, useImperativeHandle, useRef, useState } from "react";
import PropTypes from "prop-types";
//-----------------------------------------------------------// External Imports // ------------------------------

import { Chart } from 'chart.js';
import 'chartjs-plugin-annotation';

//-----------------------------------------------------------// Internal Imports // ------------------------------
import "./index.scss"
import calculateBoxCoordinates from "../../utils/getBoxCoordinatesForDecisionMatrix";
// import drawInfoOutlinedIcon from "./utils";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { Box } from "@material-ui/core";

const DecisionMatrixGraph = (props) => {
  const { centerStyles, scatter_data, boxData, client_area_shape_type, quadrantsData, clientAreaCircleData, quadrantLabelData, mainHeadLabelData, headLabelData, input_values, chart_scale, is_hidden_Legends, fontSize, pointData, chartAreaBorderData, candidateGraphRef, initialCoords } = props
  const chartRef = useRef(null);
  const [initialCoordinates, setInitialCoordinates] = useState(initialCoords);
  const [legendsData, setLegendsData] = useState(null);
  // const [clickedIcon, setClickedIcon] = useState(null);
  const [coordinates, setCoordinates] = useState(null)
  const axis_min = chart_scale * (-1);
  const coordinatesRef = useRef();
  coordinatesRef.current = coordinates;


  const client_area_shape_types = client_area_shape_type !== null ? client_area_shape_type.toLowerCase() : client_area_shape_type
  useImperativeHandle(candidateGraphRef, () => ({
    resetCoordinates: () => {
      setInitialCoordinates(null);
    },
    BoxCoordinates: () => {
      return coordinatesRef.current
    }
  }));

  useEffect(() => {
    let axis_max_value = chart_scale;
    let filtered_data = scatter_data;
    if (scatter_data) {
      if (scatter_data.length > 0) {
        filtered_data = scatter_data.filter(point => {
          return Math.abs(point.x) <= axis_max_value && Math.abs(point.y) <= axis_max_value;
        });
      }
    }

    // const customLabels = {
    //   id: 'customLabels',
    //   afterDatasetsDraw: (chart) => {
    //     const { ctx, data, scales } = chart;
    //     const { datasets } = data;

    //     datasets.forEach(dataset => {
    //       const { data: points } = dataset;
    //       points.forEach(point => {
    //         const { x, y, label } = point;
    //         const yPos = scales['y-axis-1'].getPixelForValue(y);
    //         const xPos = scales['x-axis-1'].getPixelForValue(x);
    //         ctx.save();
    //         if( x >(axis_max_value-10 )){
    //           ctx.textAlign = 'right';
    //         }else if( Math.abs(x) > (axis_max_value-10 )){
    //           ctx.textAlign = 'left';
    //         }
    //         else {
    //           ctx.textAlign = 'center';
    //         }
    //         ctx.textBaseline = 'bottom';
    //         const isInsideBox = y>(axis_max_value-5 )
    //         ctx.fillStyle = isPointInsideBox(point.x, point.y, annotations[annotations.length - 1].xMin, annotations[annotations.length - 1].yMin, annotations[annotations.length - 1].xMax, annotations[annotations.length - 1].yMax)
    //         if (label) {
    //           const labelYPos = isInsideBox ? yPos + 20 : yPos - 10; // Adjust the label position based on whether it's inside the box or not
    //           ctx.fillText(label, xPos, labelYPos);
    //         }
    //         ctx.restore();
    //       });
    //     });
    //   }
    // }


    const customLabels = {
      id: 'customLabels',
      afterDatasetsDraw: (chart) => {
        const { ctx, data, scales, chartArea } = chart;
        const { datasets } = data;

        // Function to check if two rectangles overlap
        const doRectanglesOverlap = (rectA, rectB) => {
          return (
            rectA.x < rectB.x + rectB.width &&
            rectA.x + rectA.width > rectB.x &&
            rectA.y < rectB.y + rectB.height &&
            rectA.y + rectA.height > rectB.y
          );
        };


        const drawnLabels = [];

        datasets.forEach((dataset) => {
          const { data: points } = dataset;
          points.forEach((point) => {
            const { x, y, label } = point;
            const yPos = scales['y-axis-1'].getPixelForValue(y);
            const xPos = scales['x-axis-1'].getPixelForValue(x);
            ctx.save();

            if (x > (axis_max_value - 10)) {
              ctx.textAlign = 'right';
            } else if (Math.abs(x) > (axis_max_value - 10)) {
              ctx.textAlign = 'left';
            } else {
              ctx.textAlign = 'center';
            }

            ctx.textBaseline = 'bottom';
            const isInsideBox = y > (axis_max_value - 5);
            if (client_area_shape_types === "circle") {
              const circleSize = ((chartArea.right - chartArea.left) * Number(clientAreaCircleData.client_area_circle_radius)) * 2;
              ctx.fillStyle = isInsideCircle(point.x, point.y, circleSize);
            } else {
              ctx.fillStyle = isPointInsideBox(
                point.x,
                point.y,
                annotations[annotations.length - 1].xMin,
                annotations[annotations.length - 1].yMin,
                annotations[annotations.length - 1].xMax,
                annotations[annotations.length - 1].yMax
              );
            }
            if (label) {
              const labelYPos = isInsideBox ? yPos + 20 : yPos - 10;
              const labelWidth = ctx.measureText(label).width;
              const labelHeight = 10; // Assuming a font size of 10, adjust it accordingly
              const labelBox = {
                x: xPos - labelWidth / 2,
                y: labelYPos - labelHeight,
                width: labelWidth,
                height: labelHeight,
              };

              let labelOverlap = false;

              for (const drawnLabel of drawnLabels) {
                if (doRectanglesOverlap(labelBox, drawnLabel.boundingBox)) {
                  labelOverlap = true;
                  break;
                }
              }

              // Adjust label position if overlap occurs
              if (labelOverlap) {
                let offsetY = 0;

                // Move the label up or down until there is no overlap
                while (labelOverlap) {
                  offsetY += -15; // Adjust the offset based on your preference

                  // Update the label position
                  const newLabelYPos = labelYPos + offsetY;
                  labelBox.y = newLabelYPos - labelHeight;

                  // Check for overlap with previously drawn labels
                  labelOverlap = false;
                  for (const drawnLabel of drawnLabels) {
                    if (doRectanglesOverlap(labelBox, drawnLabel.boundingBox)) {
                      labelOverlap = true;
                      break;
                    }
                  }
                }

                // Update the final label position
                ctx.fillText(label, xPos, labelBox.y + labelHeight);
              } else {
                // Draw the label if no overlap occurs
                ctx.fillText(label, xPos, labelYPos);
              }

              // Add the label and its bounding box to the drawn labels array
              drawnLabels.push({
                label,
                boundingBox: labelBox,
              });
            }

            ctx.restore();
          });
        });
      },
    };

    const chartAreaBorder = {
      id: 'chartAreaBorder',
      beforeDraw: (chart) => {
        const { ctx, chartArea } = chart;
        ctx.save();
        ctx.strokeStyle = 'black';
        ctx.lineWidth = 2;
        ctx.strokeRect(chartArea.left, chartArea.top, chartArea.right - chartArea.left, chartArea.bottom - chartArea.top);
        ctx.restore();
      }
    };

    const quadrants = {
      id: 'quadrants',
      beforeDraw(chart, args, options) {
        const { ctx, chartArea: { left, top, right, bottom }, scales } = chart;
        const x = scales['x-axis-1']
        const y = scales['y-axis-1']
        const midX = x.getPixelForValue(0);
        const midY = y.getPixelForValue(0);
        ctx.save();

        ctx.strokeStyle = 'black';
        ctx.lineWidth = 2;

        ctx.fillStyle = options.quadrant_1_color;
        ctx.fillRect(left, top, midX - left, midY - top);
        ctx.beginPath();
        ctx.moveTo(left, midY);
        ctx.lineTo(midX, midY);
        ctx.lineTo(midX, top);
        ctx.stroke();
        ctx.fillStyle = options.quadrant_2_color;
        ctx.fillRect(midX, top, right - midX, midY - top);
        ctx.beginPath();
        ctx.moveTo(midX, midY);
        ctx.lineTo(right, midY);
        ctx.lineTo(right, top);
        ctx.stroke();
        ctx.fillStyle = options.quadrant_3_color;
        ctx.fillRect(midX, midY, right - midX, bottom - midY);
        ctx.beginPath();
        ctx.moveTo(midX, midY);
        ctx.lineTo(right, midY);
        ctx.lineTo(right, bottom);
        ctx.stroke();
        ctx.fillStyle = options.quadrant_4_color;
        ctx.fillRect(left, midY, midX - left, bottom - midY);
        ctx.beginPath();
        ctx.moveTo(left, midY);
        ctx.lineTo(midX, midY);
        ctx.lineTo(midX, bottom);
        ctx.stroke();
        ctx.restore();
      }
    };

    const quadrantLabel = {
      id: 'quadrantLabel',
      beforeDraw(chart, args, options) {
        const { ctx, chartArea: { left, top, right, bottom }, scales } = chart;
        const x = scales['x-axis-1']
        const y = scales['y-axis-1']
        const midX = x.getPixelForValue(0);
        const midY = y.getPixelForValue(0);
        console.log(right, midY)
        ctx.font = `${fontSize.quadrant_label_font_size}px Arial`;
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        const offset = chart_scale / 3;
        ctx.save();
        ctx.fillStyle = options.quadrant_label_color;
        ctx.fillText(options.quadrant_1_label.label, (left + midX) / 2, top + offset);
        // drawInfoOutlinedIcon(ctx, (left + midX) / 2 + 35, top + offset - 12, options.labelColor, offset);
        ctx.fillStyle = options.quadrant_label_color;
        ctx.fillText(options.quadrant_2_label.label, midX + (midX + top) / 2 - 80, top + offset);
        //  drawInfoOutlinedIcon(ctx, midX + (midX + top) / 2 - 110, top + offset - 12, options.labelColor, offset);
        ctx.fillStyle = options.quadrant_label_color;
        ctx.fillText(options.quadrant_4_label.label, (left + midX) / 2, bottom - offset);
        //  drawInfoOutlinedIcon(ctx, (left + midX) / 2 + 35, bottom - offset - 12, options.labelColor, offset);
        ctx.fillStyle = options.quadrant_label_color;
        ctx.fillText(options.quadrant_3_label.label, midX + (midX + top) / 2 - 80, bottom - offset);
        //  drawInfoOutlinedIcon(ctx, midX + (midX + top) / 2 - 110, bottom - offset - 12, options.labelColor, offset);
        ctx.restore();
        // if(is_hidden_Legends){
        //   ctx.canvas.addEventListener('click', onClickCanvas);

        //   // Click event handler for the canvas
        //   function onClickCanvas(event) {
        //     const rect = ctx.canvas.getBoundingClientRect();
        //     const clickX = event.clientX - rect.left;
        //     const clickY = event.clientY - rect.top;

        //     // Check if the click is within the bounds of each icon
        //     if (isWithinIconBounds(clickX, clickY, (left + midX) / 2 + 35, top + offset - 12)) {
        //       setClickedIcon("quadrant_1_label");
        //       // Handle click event for the first icon here
        //     } else if (isWithinIconBounds(clickX, clickY, midX + (midX + top) / 2 - 110, top + offset - 12)) {
        //       setClickedIcon("quadrant_2_label");
        //       // Handle click event for the second icon here
        //     } else if (isWithinIconBounds(clickX, clickY, (left + midX) / 2 + 35, bottom - offset - 12)) {
        //       setClickedIcon("quadrant_3_label");
        //       // Handle click event for the third icon here
        //     } else if (isWithinIconBounds(clickX, clickY, midX + (midX + top) / 2 - 110, bottom - offset - 12)) {
        //       setClickedIcon("quadrant_4_label");
        //       // Handle click event for the fourth icon here
        //     }
        //   }
        //   function isWithinIconBounds(clickX, clickY, iconX, iconY) {
        //     const iconWidth = 20; // Adjust as needed
        //     const iconHeight = 20; // Adjust as needed

        //     return (
        //       clickX >= iconX &&
        //       clickX <= iconX + iconWidth &&
        //       clickY >= iconY &&
        //       clickY <= iconY + iconHeight
        //     );
        //   }
        // }
      }
    }

    const headTitle = {
      id: "headTitle",
      beforeDraw(chart, args, options) {
        const { ctx, chartArea: { left, top }, scales } = chart;
        const x = scales['x-axis-1']
        const y = scales['y-axis-1']
        const midX = x.getPixelForValue(0);
        const midY = y.getPixelForValue(0);
        ctx.font = `${Number(fontSize.axis_title_label_size)}px Roboto`;
        ctx.textAlign = "center";
        ctx.textBaseline = "bottom";
        const offsetY = 20;
        ctx.save();
        ctx.fillStyle = options.axis_title_label_color;
        ctx.fillText(options.y_axis_title, midX, (top - (offsetY * 2)));

        ctx.save();

        ctx.translate(left, midY + offsetY);
        ctx.rotate(-Math.PI / 2);
        ctx.fillStyle = options.axis_title_label_color;
        ctx.textAlign = 'center';
        ctx.fillText(options.x_axis_title, offsetY, -40);
        ctx.restore();
      }
    }
    const headLabel = {
      id: "headLabel",
      beforeDraw(chart, args, options) {
        const { ctx, chartArea: { left, top, right, bottom }, scales } = chart;
        const x = scales['x-axis-1']
        const y = scales['y-axis-1']
        const midX = x.getPixelForValue(0);
        const midY = y.getPixelForValue(0);
        ctx.font = ` bold ${Number(fontSize.axis_label_font_size)}px Roboto`;
        ctx.textAlign = "center";
        ctx.textBaseline = "bottom";
        const offsetY = 20;
        ctx.fillStyle = options.axis_label_color;
        ctx.fillText(options.y_axis_label_top, midX, (top) - offsetY);
        ctx.fillStyle = options.axis_label_color;
        ctx.fillText(options.y_axis_label_bottom, midX, bottom + offsetY + 13);

        ctx.save();

        ctx.translate(right + ((offsetY * 2) - 5), midY);
        ctx.rotate(-Math.PI / 2);
        ctx.fillStyle = options.axis_label_color;
        ctx.textAlign = 'center';
        ctx.fillText(options.x_axis_label_right, 0, 0);

        ctx.restore();
        ctx.save();

        // Rotate the canvas context by 90 degrees counter-clockwise
        ctx.translate(left - offsetY, midY);
        ctx.rotate(-Math.PI / 2);

        ctx.fillStyle = options.axis_label_color;
        ctx.textAlign = 'center';
        ctx.fillText(options.x_axis_label_left, 0, 0);

        ctx.restore();
      }
    }


    const auraQuadrant = {
      id: 'auraQuadrant',
      beforeDatasetDraw(chart, args, options) {
        const { ctx, chartArea: { left, top, right, bottom }, scales } = chart;

        console.log(args, options);
        const x = scales['x-axis-1']
        const y = scales['y-axis-1']
        ctx.save();

        var center_x = x.getPixelForValue(box_center_x);
        var center_y = y.getPixelForValue(box_center_y);
        var circle_rad = (right - left) * Number(clientAreaCircleData.client_area_circle_radius);
        console.log(left, top, right, bottom);
        var circle_size = Math.max(right - center_x, center_x - left, bottom - center_y, center_y - top);

        var box_size = circle_rad;
        let boxBgColor = boxData.client_area_shape_bg_color
        // eslint-disable-next-line no-useless-escape
        let removedQuotes = boxBgColor.replace(/\"'/g, "")
        let removedBrackets = removedQuotes.slice(0, -1);
        ctx.fillStyle = `${removedBrackets},0.4)`;
        ctx.beginPath();
        ctx.arc(center_x, center_y, box_size, 0, 2 * Math.PI);
        ctx.strokeStyle = `${removedBrackets},0.1)`;
        ctx.stroke();
        ctx.fill();

        ctx.fillStyle = `${removedBrackets},0.1)`;
        ctx.beginPath();
        ctx.arc(center_x, center_y, box_size * 1.5, 0, 2 * Math.PI);
        ctx.fill();

        ctx.fillStyle = `${removedBrackets},0.05)`;
        ctx.beginPath();
        ctx.arc(center_x, center_y, (box_size * 2), 0, 2 * Math.PI);
        ctx.fill();
        var gradient = ctx.createRadialGradient(center_x, center_y, circle_rad, center_x, center_y, (circle_size + 200));
        gradient.addColorStop(0, `${removedBrackets},0.55)`);
        gradient.addColorStop(1, `rgba(255, 255, 255, 0)`);
        ctx.fillStyle = gradient;

        ctx.fillRect(left, top, right - left, bottom - top);
        ctx.restore();
      }
    }

    const initBox = (rectBox) => {
      const annotations = [];
      Object.keys(rectBox).forEach((key) => {
        const { box_center_x, box_center_y, box_size, box_number, box_opacity } = rectBox[key];
        let updatedBoxSize = box_size;
        updatedBoxSize = chart_scale / 4;
        let offset = updatedBoxSize / 4;
        if (box_number > 1) {
          for (let i = 2; i <= box_number; i++) {
            updatedBoxSize += offset;
          }
        }
        const x_multiplier = (box_center_x < 0) ? -1 : 1;
        const y_multiplier = (box_center_y < 0) ? -1 : 1;

        const box_x_min = ((box_center_x * x_multiplier) - (updatedBoxSize / 2));
        const box_y_min = ((box_center_y * y_multiplier) - (updatedBoxSize / 2));
        const box_x_max = box_x_min + updatedBoxSize;
        const box_y_max = box_y_min + updatedBoxSize;

        let boxBgColor = boxData.client_area_shape_bg_color
        // eslint-disable-next-line no-useless-escape
        let removedQuotes = boxBgColor.replace(/\"'/g, "")
        let removedBrackets = removedQuotes.slice(0, -1);
        annotations.push({
          type: client_area_shape_types,
          xScaleID: 'x-axis-1',
          yScaleID: 'y-axis-1',
          xMin: box_x_min * x_multiplier,
          xMax: box_x_max * x_multiplier,
          yMin: box_y_min * y_multiplier,
          yMax: box_y_max * y_multiplier,
          backgroundColor: `${removedBrackets}, ${box_opacity})`,
          drawTime: boxData.client_area_shape_draw_time,
          borderColor: boxData.client_area_shape_border_color,
          label: {
            backgroundColor: boxData.label.client_area_shape_bg_color,
            fontColor: boxData.label.client_area_label_font_color,
            // fontSize: boxData.label.fontSize,
            fontStyle: boxData.label.client_area_label_font_style,
            cornerRadius: boxData.label.client_area_label_corner_radius,
            content: boxData.label.client_area_label_context,
            xPadding: Number(boxData.label.client_area_label_padding_x),
            yPadding: Number(boxData.label.client_area_label_padding_y),
          },
        })
      })
      return annotations
    }
    const updateAnnotations = (box_coordinates, hover) => {
      const rectBox = {
        ...box_coordinates,
      };
      const annotations = initBox(rectBox, hover);
      // Remove existing annotations
      const chartInstance = chartRef.current.chartInstance;
      chartInstance.options.plugins.annotation.annotations = [];
      // Add updated annotations
      chartInstance.options.plugins.annotation.annotations.push(...annotations);
      chartInstance.update();
    };

    let input_values_Data = {}
    if (input_values) {
      if (Object.keys(input_values).length === 0) {
        input_values_Data = {
          item_1: 0,
          item_2: 0,
          item_3: 0,
          item_4: 0,
          item_5: 0,
          item_6: 0,
          item_7: 0,
        }
      } else {
        input_values_Data = input_values
      }
    } else {
      input_values_Data = {
        item_1: 0,
        item_2: 0,
        item_3: 0,
        item_4: 0,
        item_5: 0,
        item_6: 0,
        item_7: 0,
      }
    }

    const box_coordinates = initialCoordinates ? { x: initialCoordinates.x_center, y: initialCoordinates.y_center } : calculateBoxCoordinates(input_values_Data, chart_scale);
    setCoordinates(box_coordinates)

    const box_center_x = box_coordinates.x;
    const box_center_y = box_coordinates.y;

    var rectBox = {};
    const buxNumber = Number(boxData.client_area_shape_num)
    for (var i = 1; i <= buxNumber; i++) {
      if (buxNumber == 1) {
        rectBox.box1 = {
          box_center_x: box_center_x,
          box_center_y: box_center_y,
          box_size: axis_max_value / 4,
          box_number: 3,
          box_opacity: 0.2
        };
      } else {
        rectBox['box' + i] = {
          box_center_x: box_center_x,
          box_center_y: box_center_y,
          box_size: axis_max_value / 4,
          box_number: i,
          box_opacity: 0.8 - (i * 0.1) // Example of varying opacity based on box number
        };
      }
    }


    const annotations = initBox(rectBox)
    const isPointInsideBox = (x, y, xMin, yMin, xMax, yMax) => {
      if (((x >= xMin && x <= xMax) || (x >= xMax && x <= xMin)) && ((y >= yMin && y <= yMax) || (y >= yMax && y <= yMin))) {
        return pointData.active_point_color;
      } else {
        return pointData.point_color;
      }
    }

    function isInsideCircle(x, y, circleSize) {
      const distanceSquared = (x - box_center_x) ** 2 + (y - box_center_y) ** 2;
      if (distanceSquared <= circleSize * 2) {
        return pointData.active_point_color;
      } else {
        return pointData.point_color;
      }
    }
    const legendData = {
      id: "legendData",
      beforeDraw(chart) {
        let candidateData = chart.config.data.datasets;
        let box_data = chart.annotation.options.annotations;
        setLegendsData([{
          candidateData: candidateData,
          box_data: box_data
        }])
      }
    }
    const axis_scales = [{
      ticks: {
        min: axis_min,
        max: chart_scale,
        stepSize: 10,
        display: false
      },
      gridLines: {
        display: false,
      },
    }]

    const ctx = chartRef.current.getContext('2d');
    const myChart = new Chart(ctx, {
      type: 'scatter',
      data: {
        datasets: [
          {
            label: pointData.label,
            data: filtered_data,
            pointBackgroundColor: (context) => {
              const point = context.dataset.data[context.dataIndex];
              const chartAreas = context.chart.chartArea
              const circleSize = ((chartAreas.right - chartAreas.left) * Number(clientAreaCircleData.client_area_circle_radius)) * 2;
              if (client_area_shape_types === "circle") {
                return isInsideCircle(point.x, point.y, circleSize);
              } else {
                return isPointInsideBox(point.x, point.y, annotations[annotations.length - 1].xMin, annotations[annotations.length - 1].yMin, annotations[annotations.length - 1].xMax, annotations[annotations.length - 1].yMax);
              }
            },
            radius: Number(pointData.radius),
            backgroundColor: pointData.backgroundColor,
            pointStyle: pointData.pointStyle,
            hoverRadius: Number(pointData.radius)
          },
        ]
      },
      options: {
        interaction: {
          mode: 'index'
        },
        auraQuadrant: {
          auraColor: `rgba(170, 10, 108, 0.55)`,
          x: box_center_x,
          y: box_center_y
        },
        animation: false,
        hoverRadius: 7,
        hoverBackground: 'white',
        pointRadius: 0,
        responsive: true,
        plugins: {
          annotation: {
            onHover: (event, annotation) => {
              if (annotation) {
                updateAnnotations(annotation.box_coordinates, true);
              } else {
                updateAnnotations(input_values, false);
              }
            },
          },
          chartAreaBorder: {
            borderColor: chartAreaBorderData.borderColor,
            borderWidth: chartAreaBorderData.borderWidth,
            borderDash: [5, 5],
            borderDashOffset: 2,
          },
          title: {
            display: true,
          },

          quadrants: quadrantsData,
          quadrantLabel: quadrantLabelData,
          headTitle: mainHeadLabelData,
          headLabel: headLabelData
        },

        tooltips: {
          enabled: false, // disable the default tooltip
          //custom: lineChartCustomTooltip, // use the custom tooltip
        },
        aspectRatio: 1.3,
        layout: {
          padding: {
            left: 75,
            right: 75,
            top: 85,
            bottom: 75
          }
        },
        scales: {
          xAxes: axis_scales,
          yAxes: axis_scales,
        },
        annotation: {
          annotations: annotations
        },

        legend: {
          display: false,
        },

      },
      plugins: [customLabels, quadrants, quadrantLabel, headTitle, headLabel, chartAreaBorder, legendData, client_area_shape_types === "circle" && auraQuadrant]
    });

    return () => {
      if (myChart) {
        myChart.destroy();
      }
    };
  }, [props]);

  // const isPointInsideQuadrants = (context) => {
  //   let x = context.xLabel
  //   let y = context.yLabel

  //   if (x < 0 && y > 0) {
  //     return quadrantLabelData.quadrant_1_label
  //   } else if (x > 0 && y > 0) {
  //     return quadrantLabelData.quadrant_2_label
  //   } else if (x > 0 && y < 0) {
  //     return quadrantLabelData.quadrant_3_label
  //   } else if (x < 0 && y < 0) {
  //     return quadrantLabelData.quadrant_4_label
  //   }
  //   return "";
  // }

  // var lineChartCustomTooltip = function (tooltip,) {
  //   // Tooltip Element

  //   var tooltipEl = document.getElementById("chartjs-tooltip");


  //   if (!tooltipEl) {
  //     tooltipEl = document.createElement("div");
  //     tooltipEl.id = "chartjs-tooltip";
  //     tooltipEl.innerHTML = `<table style="width:250px; opacity:999;"></table>`;
  //     this._chart.canvas.parentNode.appendChild(tooltipEl);
  //   }

  //   // Hide if no tooltip
  //   if (tooltip.opacity === 0) {
  //     tooltipEl.style.opacity = 0;
  //     return;
  //   }

  //   tooltipEl.classList.remove("above", "below", "no-transform");
  //   if (tooltip.yAlign) {
  //     tooltipEl.classList.add(tooltip.yAlign);
  //   } else {
  //     tooltipEl.classList.add("no-transform");
  //   }

  //   if (tooltip.body) {
  //     var titleLines = tooltip.title || [];

  //     var bodyLines = isPointInsideQuadrants(tooltip.dataPoints[0]);

  //     var innerHtml = "<thead>";

  //     titleLines.forEach(function (title) {
  //       innerHtml += "<tr><th>" + title + "</th></tr>";
  //     });
  //     innerHtml += `</thead><tbody style="text-align: left; white-space; break">`;

  //     var colors = tooltip.labelColors;
  //     var style = "background:" + colors.backgroundColor;
  //     style += "; border-color:" + colors.borderColor;
  //     style += "; border-width: 2px";
  //     style += "; text-align: start;";
  //     // eslint-disable-next-line no-unused-vars
  //     var span =
  //       '<span class="chartjs-tooltip-key" style="' + style + '"></span>';
  //     innerHtml += `<tr><td style='color: rgba(85, 72, 196); font-size:16px'>` + bodyLines.label + `</td></tr>`;
  //     innerHtml += "<tr><td>" + bodyLines.desc + "</td></tr>";
  //     // });
  //     innerHtml += "</tbody>";

  //     var tableRoot = tooltipEl.querySelector("table");
  //     tableRoot.innerHTML = innerHtml;
  //   }
  //   var positionY = this._chart.canvas.offsetTop;
  //   var positionX = this._chart.canvas.offsetLeft;

  //   // Display, position, and set styles for font
  //   let x = tooltip.dataPoints[0].xLabel

  //   tooltipEl.style.opacity = 1;
  //   if (x > (chart_scale - 10)) {
  //     tooltipEl.style.left = positionX + tooltip.caretX - 50 + "px";
  //   } else if (Math.abs(x) > (chart_scale - 10)) {
  //     tooltipEl.style.left = positionX + tooltip.caretX + 50 + "px";
  //   } else {
  //     tooltipEl.style.left = positionX + tooltip.caretX + "px";
  //   }

  //   tooltipEl.style.top = positionY + tooltip.caretY + "px";
  //   tooltipEl.style.fontFamily = tooltip._bodyFontFamily;
  //   tooltipEl.style.fontSize = tooltip.bodyFontSize + "px";
  //   tooltipEl.style.fontStyle = tooltip._bodyFontStyle;
  //   tooltipEl.style.padding = tooltip.yPadding + "px " + tooltip.xPadding + "px";
  // };


  const viewTable = () => {
    return (
      <div className="row m-0 p-0 d-inline-block">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Paper
              sx={{
                width: '500px',
                height: '500px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Grid container >
                {Object.entries(quadrantLabelData).map(([key, value]) => (
                  key.includes('quadrant_') && (

                    <Grid item xs={12} key={key}>
                      {
                        key !== 'quadrant_label_color' && (

                          <Box
                            className="info-box"
                          >
                            <p className="info-box-label">{value.label}</p>
                            <p className="label-desc">{value.desc}</p>
                          </Box>
                        )
                      }
                    </Grid>
                  )
                ))}
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </div>
    )
  }


  return (
    <>
      <div className="decision-selection-container row m-0 p-0">
        <div className=" row d-flex col-12 m-0 p-0" style={{ width: `${parseInt(centerStyles.width)}px` }}>
          <div className="col-sm text-center p-0" style={{ paddingBottom: "5px" }} >
            <div className="row m-0 p-0">
              <div style={{ width: centerStyles?.width, margin: centerStyles?.margin, marginTop: centerStyles?.marginTop }}>
                <canvas ref={chartRef} ></canvas>
              </div>
            </div>

            {/* {
              clickedIcon &&
                (<>
                  <div className="flex-grow-1" style={{color: quadrantLabelData.quadrant_label_color}}>
                    <p className="font-weight-bold" style={{marginLeft:"3vh"}}>{quadrantLabelData[clickedIcon].label}</p>
                  </div>
                  <div>
                    <p style={{marginTop:"2vh" ,marginLeft:"3vh"}}>
                      {quadrantLabelData[clickedIcon].desc}
                    </p>
                  </div>
                </>
                )
              } */}
            {
              is_hidden_Legends && viewTable()
            }
          </div>
          {
            !is_hidden_Legends && (
              <div className="col-sm ml-3 pl-3">
                <div className="header-legend-title"><h4 className="font-weight-bold">Legend</h4></div>
                {
                  client_area_shape_types !== null && legendsData !== null && legendsData.map((item) => {
                    return (<>
                      <div className="  d-flex my-3" style={{ marginTop: '10px' }}>
                        <div className={`${client_area_shape_types === "circle" ? 'circle' : 'box'}`} style={{ backgroundColor: item.box_data[0]?.backgroundColor }}></div>
                        <span className="legends-title">{item.box_data[item.box_data.length - 1].label.content}</span>
                      </div>
                    </>)
                  })
                }

                {
                  legendsData !== null && legendsData.map((item) => {
                    return item.candidateData.map((item) => {
                      return (<>
                        {
                          item.data.length != 0 &&
                          <div className=" d-flex my-3">
                            <div className={`${pointData.pointStyle === 'triangle' ? 'triangle' : 'dot'}`} style={{ backgroundColor: pointData.pointStyle === 'triangle' ? 'transperent' : pointData.point_color, borderBottomColor: pointData.point_color }}></div>
                            <span className="legends-title">{item.label}</span>
                          </div>
                        }
                      </>)
                    })

                  })
                }
                {
                  viewTable()
                }
              </div>
            )
          }
        </div>
      </div>
    </>
  )
}
DecisionMatrixGraph.propTypes = {
  centerStyles: PropTypes.object,
  scatter_data: PropTypes.array,
  quadrantsData: PropTypes.object,
  quadrantLabelData: PropTypes.object,
  mainHeadLabelData: PropTypes.object,
  headLabelData: PropTypes.object,
  input_values: PropTypes.object,
  chart_scale: PropTypes.number,
  is_hidden_Legends: PropTypes.bool,
  fontSize: PropTypes.object,
  sfpa_decision_num_candidate: PropTypes.number,
  // box_size: PropTypes.number,
  boxData: PropTypes.object,
  pointData: PropTypes.object,
  chartAreaBorderData: PropTypes.object,
  candidateGraphRef: PropTypes.object,
  client_area_shape_type: PropTypes.string,
  clientAreaCircleData: PropTypes.object,
  initialCoords: PropTypes.object
}
export default DecisionMatrixGraph;