//----------------------------------------------// External Imports // -------------------------------------------------
import * as yup from "yup";

export const cleanup = (busDev, tabValue) => {
  let payload = { ...busDev }
  //payload = removeSkipFields(payload, [...dirtyFields])
  payload.company_id = busDev.company?.id;
  payload.market = busDev.markets ? busDev.markets.id : null;
  payload.client_team = busDev.client_team ? busDev.client_team.map(client => {
    return {
      contact_id: client?.contact ? client.contact.id : client.id
    }
  }) : []
  /* if update form of team info tab then no need to add partner from basic partners */
  if (tabValue === 0) {
    if (payload.basic_partners) {
      payload.basic_partners = busDev.basic_partners.map(partner => {
        return {
          user_id: partner.id,
          user: partner
        }
      });
    }
    if (payload.partners && payload.basic_partners) {
      payload.partners = payload.partners.filter(ele => !ele?.user ? true : payload.basic_partners.find(el => el?.user_id === ele?.user?.id))
      payload.basic_partners.forEach((ele) => {
        if (payload.partners) {
          const response = payload.partners.find(item => item.user && item.user.id === ele.user_id)
          if (!response) {
            payload.partners.push(ele)
          }
        }
      })
    }
  }

  if (payload.estimated_percentage_bases && payload.estimated_percentage_bases.length > 0) {
    const esimatedPercentageBases = payload.estimated_percentage_bases.filter((ele) => {
      if (Object.keys(ele).length > 0) {
        return ele;
      }
    })
    payload.estimated_percentage_bases = esimatedPercentageBases
  }
  if (payload.indirect_fees_searches && payload.indirect_fees_searches.length > 0) {
    const indirectFeesSearches = payload.indirect_fees_searches.filter((ele) => {
      if (Object.keys(ele).length > 0) {
        return ele;
      }
    })
    payload.indirect_fees_searches = indirectFeesSearches
  }
  if (payload.estimated_percentage_bases && payload.estimated_percentage_bases.length > 0) {
    payload.estimated_percentage_bases.forEach((item, index) => { payload.estimated_percentage_bases[index] = { ...payload.estimated_percentage_bases[index], estimated_amt: item.estimated_amt === "" ? null : item.estimated_amt } })
  }
  if (payload.recruiters && payload.recruiters.length === 1) {
    const data = isEmptyObject(payload.recruiters[0])
    if (data && data.length === 0) {
      payload.recruiters = data
    }
  }
  if (payload.researchers && payload.researchers.length === 1) {
    const data = isEmptyObject(payload.researchers[0])
    if (data && data.length === 0) {
      payload.researchers = data
    }
  }
  if (payload.eas && payload.eas.length === 1) {
    const data = isEmptyObject(payload.eas[0])
    if (data && data.length === 0) {
      payload.eas = data
    }
  }
  // if (payload['projected_fee'] !== null) {
  //   if (payload['projected_fee'].length === 0) {
  //     delete payload['projected_fee']
  //   }
  // }
  if (payload['probability'] !== null) {
    if (payload['probability'].length === 0) {
      delete payload['probability']
    }
  }
  payload.job_number = payload.job_number ? payload.job_number : undefined
  // if (payload.id) {
  //   if (payload.brand === "Advisory") {
  //     payload.recruiters = []
  //     payload.researchers = []
  //   }
  // }
  return payload
};

const isEmptyObject = (obj) => {
  if (Object.keys(obj).length === 0) {
    return [];
  }
}

// const removeSkipFields = (payload, dirtyFields) => {
//   SKIP_FIELDS_LIST.forEach(ele => {
//     const valueFind = dirtyFields.find(item => item === ele)
//     if (!valueFind) {
//       delete payload[ele]
//     }
//   })
//   return payload
// }

export const SKIP_FIELDS_LIST = ['intervals', 'term_type', 'brand', 'stage']

export const BDSchema = yup.object().shape({
  company: yup.object().required(),
  job_title: yup.string().required().min(1),
  basic_partners: yup.array().required().min(1),
  // projected_fee: yup.string().required().min(1),
  brand: yup.string().required().min(1),
  probability: yup.string().required(),
  stage: yup.string().required().min(1),
  markets: yup.object().required(),
  country:yup.string().required(),
  projected_start_date: yup.string().required(),
});
export const BDBillingSchema = yup.object().shape({
  currency: yup.object().required(),
  billing_region: yup.string().required(),
});


export const prepareSearchData = (searchData) => {
  if (!searchData.client_team || searchData.client_team.length === 0) {
    searchData.client_team = []
  }
  else {
    searchData.client_team = searchData.client_team
      .sort((a, b) => a.position - b.position)
      .map((item) => ({ ...item, ...item.contact, client_id: item.id }));
    let isHiringManagerIndex = searchData.client_team.findIndex(ele => ele.is_hiring_manager)
    if (isHiringManagerIndex !== -1) {
      searchData.client_team = searchData.client_team.map((ele, i) => { return i === isHiringManagerIndex ? { ...ele } : { ...ele, is_hiring_manager: false } })
    }
  }
  const basicPartners = searchData.partners ? searchData.partners.map(partner => partner.user).filter(user => user) : []
  searchData.basic_partners = basicPartners
  searchData.recruiters = searchData.recruiters.length ? searchData.recruiters : [{}];
  searchData.researchers = searchData.researchers.length ? searchData.researchers : [{}];
  searchData.eas = searchData.eas.length ? searchData.eas : [{}];
  searchData.estimated_percentage_bases = searchData.estimated_percentage_bases.length ? searchData.estimated_percentage_bases : [{}]
  searchData.indirect_fees_searches = searchData.indirect_fees_searches.length ? searchData.indirect_fees_searches : [{}]
  searchData.intervals_desc = searchData.intervals_desc ? searchData.intervals_desc : searchData.intervals
  return searchData;
}

