
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useRef, useState } from "react";

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from "prop-types";
import { Controller, useForm } from "react-hook-form";
import Popup from "reactjs-popup";
import { Button, InputLabel, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useSnackbar } from "notistack";

//-----------------------------------------------------------// Internal Imports // ------------------------------

import Loader from "../../common/Loader";
import ContactSelection from "../../ContactSelection/ContactSelection";
import SearchSelection from "../../SearchSelection";
import ReferenceTypeSelection from "./ReferenceTypeSelection";
import ReferenceSourceSelection from "./ReferenceSourceSelection";
import { POST, ERROR, SUCCESS } from "../../../services/constantService";
import { contactReferencesApi } from "../../../services/ApiService";
import { unableMessage, validateMessage, successMessage } from "../../../services/MessageService";
import * as yup from "yup";
import { customFormValidator, requireValidMessage } from "../../../utils/common";

export default function AddReference(props) {
  const { handleClose, open, contact, contactId, showEditReference } = props
  const { handleSubmit, control } = useForm()
  const { enqueueSnackbar } = useSnackbar()
  const contactRef = useRef()
  const searchRef = useRef()
  const typeRef = useRef()
  const sourceRef = useRef()
  const [isLoading, setIsLoading] = useState(false)

  const CreateSchema = yup.object().shape({
    ref_contact_id: yup.string().required(),
    source: yup.string().required(),
    type: yup.string().required()
  });

  const createReference = async (payload, showEdit) => {
    const isValid = await CreateSchema.isValid(payload)
    if (!isValid) {
      let requiredField = [
        { fieldName: "ref_contact_id", label: "Contact Name", type: String },
        { fieldName: "source", label: "Source", type: String },
        { fieldName: "type", label: "Reference Type", type: String },
      ]
      let dirtyField = customFormValidator(payload, requiredField)
      if(dirtyField){
        const message = requireValidMessage(dirtyField)
        enqueueSnackbar(message, { variant: ERROR })
        return
      }
    }
    setIsLoading(true);
    if (!payload.ref_contact_id || !payload.source ||
      !payload.type
    ) {
      setIsLoading(false);
      const message = validateMessage("required fields", "fill")
      return enqueueSnackbar(message, { variant: ERROR });
    }
    if (payload.search_id === null || payload.search_id.length === 23) { payload.search_id = null }
    const sub_route = `${contact.id}/references`
    const { status, data } = await contactReferencesApi(POST, sub_route, payload);
    if (status === 200) {
      const message = successMessage("Contact reference", "created")
      enqueueSnackbar(data?.message || message, { variant: SUCCESS })
      if (showEdit) {
        showEditReference(data?.id)
      }
      handleClose(true);
    } else {
      const message = unableMessage("contact reference", "create")
      enqueueSnackbar(data?.message || message, { variant: ERROR })
    }
    setIsLoading(false);
  }

  return (
    <Popup
      open={open}
      className="add-reference"
      onClose={() => handleClose()}
      closeOnDocumentClick={false}
      closeOnEscape={false}
    >
      <React.Fragment>
        <Loader show={isLoading} />
        <div className='h-100 overflow-auto'>
          <div className="quick-add-header">
            <Typography>Add Reference By Contact</Typography>
            <CloseIcon className="cursor-pointer" onClick={() => handleClose()} />
          </div>
          <div className='quick-add-container'>
            <div className='d-flex flex-column h-100'>
              <div className='quick-add-content flex-row d-flex'>
                <div className="left-section">
                  <div className="field-details">
                    <InputLabel required={true} className="field-title" color="primary">Contact Name</InputLabel>
                    <Controller
                      name='ref_contact_id'
                      defaultValue={null}
                      as={
                        <ContactSelection
                          inputRef={contactRef}
                          className='field-value'
                          // required={true}
                          label=''
                          placeholder={"Search here"}
                        />
                      }
                      control={control}
                      onFocus={() => {
                        contactRef.current.focus()
                      }}
                      onChange={([, data]) => data ? data.id : null}
                    // rules={{required: true}}
                    />
                  </div>
                  <div className="field-details">
                    <InputLabel className="field-title" color="primary">Search</InputLabel>
                    <Controller
                      name='search_id'
                      defaultValue={null}
                      as={
                        <SearchSelection
                          inputRef={searchRef}
                          className='field-value'
                          required={true}
                          label=''
                          placeholder={"Search here"}
                          contactId={contactId}
                          contactName={contact.first_name + ' ' + contact.last_name}
                          defaultValue={"Not related to a search"}
                        />
                      }
                      control={control}
                      onFocus={() => {
                        searchRef.current.focus()
                      }}
                      onChange={([, data]) => data ? data.id : null}
                    // rules={{required: true}}
                    />
                  </div>
                  <div className="field-details">
                    <InputLabel required={true} className="field-title" color="primary">Reference Type</InputLabel>
                    <Controller
                      name='type'
                      defaultValue={null}
                      as={
                        <ReferenceTypeSelection
                          inputRef={typeRef}
                          className='field-value'
                          // required={true}
                          label=''
                          placeholder='Select'
                        />
                      }
                      control={control}
                      onFocus={() => {
                        typeRef.current.focus()
                      }}
                      onChange={([, data]) => data?.name}
                    // rules={{required: true}}
                    />
                  </div>
                  <div className="field-details">
                    <InputLabel required={true} className="field-title" color="primary">Source</InputLabel>
                    <Controller
                      name='source'
                      defaultValue={null}
                      as={
                        <ReferenceSourceSelection
                          inputRef={sourceRef}
                          className='field-value'
                          // required={true}
                          label=''
                          placeholder={"Search here"}
                        />
                      }
                      control={control}
                      onFocus={() => {
                        sourceRef.current.focus()
                      }}
                      onChange={([, data]) => data}
                    // rules={{required: true}}
                    />
                  </div>
                </div>
              </div>
              <div className="quick-add-footer">
                <Button
                  variant='outlined' color="primary"
                  onClick={handleSubmit((data) => createReference(data, true))}
                >
                  Go To Reference Details
                </Button>
                <Button
                  color="primary" className='ml-3' variant="contained"
                  onClick={handleSubmit((data) => createReference(data, false))}
                >
                  Save and Close
                </Button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    </Popup>
  )
}

AddReference.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  contact: PropTypes.object,
  showEditReference: PropTypes.func,
  contactId: PropTypes.string
}
