
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from "react";

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from "prop-types";
import QRCode from "react-qr-code";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogTitle from "@material-ui/core/DialogTitle";
import CloseIcon from "@material-ui/icons/Close";
import Button from "@material-ui/core/Button";
import { getQrCodeFields } from "./Utils";

export default function LinkedInDialog(props) {
  const { open, handleClose, linkedInValue, urlType = 'linkedIn' } = props;
  const { lable, tooltipMessage } = getQrCodeFields(urlType)

  const onImageDownload = () => {
    const svg = document.getElementById("QRCode");
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.download = "QRCode";
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <CloseIcon
          style={{ cursor: "pointer" }}
          color="primary"
          onClick={handleClose}
        />
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <DialogTitle>{lable}</DialogTitle>
      </div>
      <DialogActions>
        <a href={linkedInValue} rel="noopener noreferrer" target="_blank">
          <QRCode
            id="QRCode"
            title={tooltipMessage}
            value={linkedInValue}
          />
        </a>
      </DialogActions>
      <DialogActions style={{ justifyContent: "center" }}>
        <Button
          className="primary-btn"
          color="primary"
          onClick={onImageDownload}
        >
          Download QR
        </Button>
      </DialogActions>
    </Dialog>
  );
}
LinkedInDialog.propTypes = {
  open: PropTypes.bool,
  isActive: PropTypes.bool,
  handleClose: PropTypes.func,
  handleSubmit: PropTypes.func,
  linkedInValue: PropTypes.func,
  urlType: PropTypes.string
};
