
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useEffect, useState } from "react";

//-----------------------------------------------------------// External Imports // ------------------------------

import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { Helmet } from "react-helmet";
import moment from "moment-timezone";
import { SnackbarProvider, useSnackbar } from "notistack";
import DateFnsUtils from "@date-io/date-fns";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import CloseIcon from '@material-ui/icons/Close';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import Header from "./components/Header";
import Routes from "./routes";
import SideNav from "./components/SideNav";
//import './amplify';
import Loader from "./components/common/Loader";
import TokenValidator from "./Containers/Commons/TokenValidator";
import { getUserSelector } from "./selectors";
import { getServerSocketConfig } from "./Containers/Commons/urlUtil";
import { io } from 'socket.io-client'
import { UPDATE_SOCKET } from "./types";

import { authenticateUserInZendesk } from "./utils/dataSource";
import { LINKEDIN_SCRAPER, LOE_GENERATION } from "./services/constantService";
moment.tz.setDefault("Etc/UTC");

const defaultTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#5F51CB",
    },
    secondary: {
      main: "#DE9F6D",
    },
    text: {
      primary: "#2A364C",
    },
  },
  overrides: {
    MuiCheckbox: {
      colorSecondary: {
        "&$checked": {
          color: "#5F51CB",
        },
      },
    },
  },
});

const App = () => {
  const userData = useSelector(getUserSelector);
  useEffect(() => {
    window.zE('webWidget', 'updateSettings', {
      webWidget: {
        authenticate: {
          jwtFn: async (callback) => callback(await authenticateUserInZendesk())
        },
        contactForm: {
          fields: [
            { id: 'email', prefill: { '*': userData?.email ? userData.email : "" } },
            { id: 'name', prefill: { '*': userData?.name ? userData.name : "" } },
          ]
        },
      }
    })
    window.zE('webWidget', 'hide');
    window.zE('webWidget:on', 'close', function() {
      window.zE('webWidget', 'hide');
      document.querySelector('#zdLauncher').style.opacity = 1;
    })
  }, [userData]);

  const [user, setUser] = useState("");
  const dispatch = useDispatch()
  const [socket, setSocket] = useState(null);
  
  useEffect(() => {
    if (user) {
      /* socket && socket.emit("newUser", user);
      dispatch({ type: UPDATE_SOCKET, payload: socket }) */
      if (socket) {
        socket.on('connected', (msg) => {
          console.log('message:::', msg);
          socket && socket.emit("newUser", user);
          dispatch({ type: UPDATE_SOCKET, payload: socket })
        });
        socket.on(LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_COMPLETED, (msg, obj) => {
          console.log('message:::', msg);
          dispatch({ type: LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_COMPLETED, payload: { msg, obj } })
        });
        socket.on(LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_NOTIFY_TO_CANDIDATES, (msg, obj) => {
          console.log('message:::', msg);
          dispatch({ type: LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_NOTIFY_TO_CANDIDATES, payload: { msg, obj } })
        });
        socket.on(LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_NOTIFY_TO_PRODUCT_ONE, (msg, obj) => {
          console.log('message:::', msg);
          dispatch({ type: LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_NOTIFY_TO_PRODUCT_ONE, payload: { msg, obj } })
        });
        socket.on(LOE_GENERATION.LOE_DOCUMENT_UPLOADED, (msg, obj) => {
          console.log('message:::', msg);
          dispatch({ type: LOE_GENERATION.LOE_DOCUMENT_UPLOADED, payload: { msg, obj } })
        });
      }
    }
  }, [socket, user]);

  useEffect(() => {
    const { url, options } = getServerSocketConfig()
    const newSocket = io(url, { ...options });
    setSocket(newSocket)
    setUser(userData?.id)
  }, [userData])

  // let [user] = useState(null)
  // const dispatch = useDispatch()
  // useEffect(() => {
  //   let updateUser = () => {
  //     // Auth.currentAuthenticatedUser().then((user) => {
  //     //   setUser(user)
  //     //   dispatch(fetchUserDetails())
  //     //   dispatch(fetchAccessToken())
  //     // }).catch(() => {
  //     //   setUser(null)
  //     // })
  //   }
  //   // Hub.listen('auth', updateUser)
  //   updateUser()
  //   // return () => Hub.remove('auth', updateUser)
  // }, [dispatch]);

  // eslint-disable-next-line react/prop-types
  function SnackbarCloseButton({ snackbarKey }) {
    const { closeSnackbar } = useSnackbar();
  
    return (
      <CloseIcon  className="cursor-pointer" onClick={() => {
        closeSnackbar(snackbarKey)
      }} />
    );
  }

  return userData ? (
    <Router>
      <ThemeProvider theme={defaultTheme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{ horizontal: "center", vertical: "top" }}
            action={(snackbarKey) => <SnackbarCloseButton snackbarKey={snackbarKey} />}
          >
            <div className="main-wrapper d-flex flex-column h-100">
              <Helmet>
                <title>Welcome - KingsleyGate Partners</title>
              </Helmet>
              <Header />
              <div className="content-wrapper d-flex w-100">
                <SideNav />
                <div className="main-content">
                  <Routes />
                </div>
              </div>
            </div>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </Router>
  ) : (
    <div>
      <TokenValidator />
      <Loader show={true} />
    </div>
  );
};
export default App;
