//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useCallback, useEffect, useState } from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from "prop-types";
import { Button, Typography } from "@material-ui/core";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from '@material-ui/icons//Add';
//----------------------------------------------// Internal Imports // -------------------------------------------------
import UserSelection from "../../../../UserSelection";
import InputField from "../../../../common/InputField";
import { getAccessToken } from "../../../../../services/cognitoService";
import { API } from "../../../../../services/constantService";
import '../../../index.scss'
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const getCredits = (list) => {
  let total_origination_credit = 0;
  let total_selling_credit = 0;
  let total_execution_credit = 0;
  for (let i = 0; i < list.length; i++) {
    total_origination_credit += parseFloat(list[i].origination_credit || 0)
    total_selling_credit += parseFloat(list[i].selling_credit || 0)
    total_execution_credit += parseFloat(list[i].execution_credit || 0)
  }
  return { total_origination_credit, total_selling_credit, total_execution_credit }
}

export default function TeamInfoTable(props) {
  const { header = "Team Information", label = "", fields, values, register, field, setValue, renderActions, renderLabel, editingField, option, readOnlyMessage } = props;
  const [list, setList] = useState([])
  const isEditing = editingField === field
  const [isError, setIsError] = useState(false);
  const [credits, setCredits] = useState({});
  const [selectedOption, setSelectedOption] = useState([])

  useEffect(() => {
    if (list.length > 0) {
      const options = option.filter((array_el) => {
        return list.every(anotherOne_el => anotherOne_el?.user?.id !== array_el?.id)
      });
      setSelectedOption(options)
    } else {
      setSelectedOption(option)
    }
  }, [list, register, setValue])

  useEffect(() => {
    register(field)
  }, [register, field])
  useEffect(() => {
    setValue(field, list)
  }, [list, field, setValue])

  useEffect(() => {
    if (Array.isArray(values)) {
      setList(values)
    }
  }, [values, setList])

  const updateProfileURL = useCallback(async (user, index, list) => {
    const token = await getAccessToken();
    const url = `${API.users}/${user.id}/profileimage?token=${token}`

    const updatedList = [...list];
    updatedList[index] = {
      ...list[index],
      user: {
        ...list[index]['user'],
        image_url: url
      }
    }
    setList(updatedList);
  }, [setList])

  useEffect(() => {
    list.forEach((item, index) => {
      if (item.user && item.user.profile_image_id && !item.user.image_url) {
        updateProfileURL(item.user, index, list);
      }
    });
  }, [list, updateProfileURL])

  const handleUserChange = (index, name, data) => {
    const updatedList = [...list];
    updatedList[index] = { ...list[index], [name]: data }

    if (data?.profile_image_id) {
      updateProfileURL(data, index, updatedList);
    }
    setList(updatedList);
  }

  const updateCredits = useCallback((list) => {
    setCredits(getCredits(list))
  }, [])

  const checkForError = useCallback((list) => {
    const { total_origination_credit, total_selling_credit, total_execution_credit } = getCredits(list)
    let isError = false;
    if (fields.origination_credit) {
      if (total_origination_credit > fields.origination_credit) {
        isError = true;
      }
    }
    if (fields.selling_credit) {
      if (total_selling_credit > fields.selling_credit) {
        isError = true;
      }
    }
    if (fields.execution_credit) {
      if (total_execution_credit > fields.execution_credit) {
        isError = true;
      }
    }
    return isError;
  }, [fields])

  useEffect(() => {
    updateCredits(list);
    let isError = checkForError(list);
    setIsError(isError);
  }, [updateCredits, checkForError, list])

  const getTotalCredit = (item) => {
    return parseFloat(item.origination_credit || 0) + parseFloat(item.selling_credit || 0) + parseFloat(item.execution_credit || 0);
  }

  const handleChange = (index, name, data) => {
    const value = data && Math.abs(data)
    const updatedList = [...list]
    updatedList[index] = { ...list[index], [name]: parseFloat(value) }

    let isError = checkForError(updatedList);
    const { total_origination_credit, total_selling_credit, total_execution_credit } = getCredits(updatedList)
    let isErrorRelatedToSameField = false;

    if (isError) {
      if (fields.origination_credit) {
        if (total_origination_credit > fields.origination_credit) {
          isErrorRelatedToSameField = isErrorRelatedToSameField || checkIsErrorRelatedToSameField(name, "origination_credit_error")
        }
      }
      if (fields.selling_credit) {
        if (total_selling_credit > fields.selling_credit) {
          isErrorRelatedToSameField = isErrorRelatedToSameField || checkIsErrorRelatedToSameField(name, "selling_credit_error")
        }
      }
      if (fields.execution_credit) {
        if (total_execution_credit > fields.execution_credit) {
          isErrorRelatedToSameField = isErrorRelatedToSameField || checkIsErrorRelatedToSameField(name, "execution_credit_error")
        }
      }
    }

    if (isError && isErrorRelatedToSameField) {
      updatedList[index][`${name}_error`] = true
    } else {
      updatedList[index][`${name}_error`] = false
    }
    setIsError(isError);
    setList([...updatedList]);
  }

  const addElement = () => {
    const updatedList = [...list, {}];
    updateCredits(updatedList);
    setList(updatedList);
  }

  const removeElement = (index) => {
    let updatedList = [...list];
    updatedList.splice(index, 1)
    updateCredits(updatedList);
    setList(updatedList);
  }

  const checkIsErrorRelatedToSameField = (fieldName, errorField) => {
    return errorField.includes(fieldName)
  }

  const getGrandTotalCredit = () => {
    return (parseFloat(credits.total_origination_credit || 0) + parseFloat(credits.total_selling_credit || 0) + parseFloat(credits.total_execution_credit || 0))
  }

  return (
    <div >
      <div className={`d-flex flex-column  my-2 `}>
        <ExpansionPanel defaultExpanded={true} className="w-100" style={{ background: '#fff', color: '#fff' }}>
          <ExpansionPanelSummary
            expandIcon={
              <ExpandMoreIcon style={{ color: "#fff" }} />
            }
            aria-controls="panel1a-content"
            style={{ background: '#2a364c' }}
          >
            <div className={readOnlyMessage ? 'layout-content read-only' : ''}>
              <div className={`d-flex table-header ${readOnlyMessage ? 'read-only' : ''}`} onClick={(e) => e.stopPropagation()} style={{ color: "white", fontWeight: 500 }}>
                <span className={`${readOnlyMessage ? 'read-only' : ''}`}>{renderLabel(header, field)}</span>
                {editingField === field && renderActions(field)}
              </div>
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={`d-flex flex-column ${readOnlyMessage ? 'layout-content read-only' : ''} p-0`}>
            <div className='section-content team-information-table teamInfo p-0 flex-grow-1 mb-2'>
              <div className=" d-flex flex-column">
                <div className="d-flex align-items-center table-main-header">
                  <div className="team-info-field name-field">Name</div>
                  <div className="team-info-field">Origination Credit</div>
                  <div className="team-info-field">Selling Credit</div>
                  <div className="team-info-field">Execution Credit</div>
                  <div className="team-info-field">Total Credit</div>
                  <div className="close-action"></div>
                </div>
              </div>
              <div className={`table-content ${isError ? 'validation-error' : ''} pt-3 pb-3`}>
                {list.length > 0 ? list.map((item, index) => {
                  return <div className="d-flex team-info-row" key={index}>
                    {
                      <div className={index===0?"mr-1":null}>
                        <Typography >{index + 1}.</Typography>
                      </div>
                    }
                    <div className="team-info-field name-field d-flex align-items-center  align-self-baseline ">
                      {isEditing ?
                        <UserSelection
                          option={selectedOption}
                          label=""
                          placeholder={label}
                          disabled={!isEditing}
                          header="TeamInfo"
                          hide={!isEditing}
                          list={list}
                          value={item.user || null}
                          // InputProps={{
                          //   startAdornment: <Typography style={{ padding: '0 5px' }} color='primary'>{index + 1}.</Typography>
                          // }}
                          onChange={(e, data) => handleUserChange(index, 'user', data)}
                          className="flex-grow-1" />
                        :
                        <div className="mr-2 team-info-field d-flex align-items-center justify-content-start ml-5">{item && item.user ? item.user.name : "--"} {
                          item.user &&
                          <span className="profile-image-container team-profile-image d-flex ml-2">
                            <span className="image-container">{(item.user && item.user.image_url) ?
                              <img src={item.user.image_url} alt="Profile" /> :
                              <span className="default-profile-icon"><AccountCircleIcon fontSize="inherit" /></span>}</span>
                          </span>
                        }</div>
                      }
                      {
                        item.user && isEditing &&
                        <span className="profile-image-container team-profile-image d-flex">
                          <span className="image-container">{(item.user && item.user.image_url) ?
                            <img src={item.user.image_url} alt="Profile" /> :
                            <span className="default-profile-icon"><AccountCircleIcon fontSize="inherit" /></span>}</span>
                        </span>
                      }

                    </div>
                    {fields.origination_credit && isEditing ?
                      <InputField
                        type="number"
                        //variant='outlined'
                        flag="true"
                        value={item.origination_credit || ''}
                        inputProps={{ readOnly: !isEditing, min: 0 }}
                        InputProps={{ disableUnderline: !isEditing }}
                        onChange={(e) => handleChange(index, 'origination_credit', e.target.value)}
                        error={item.origination_credit_error ? true : false}
                        helperText={item.origination_credit_error ? `${label}s Origination Credit total cannot be more than ${fields.origination_credit}%.` : ''}
                        className="team-info-field inputfieldinfo text-wrap" />
                      :
                      <div className="team-info-field">{item.origination_credit || ''}</div>
                    }
                    {fields.selling_credit && isEditing ?
                      <InputField
                        type="number"
                        flag="true"
                        value={item.selling_credit || ''}
                        //variant='outlined'
                        inputProps={{ readOnly: !isEditing, min: 0 }}
                        InputProps={{ disableUnderline: !isEditing }}
                        onChange={(e) => handleChange(index, 'selling_credit', e.target.value)}
                        error={item.selling_credit_error ? true : false}
                        helperText={item.selling_credit_error ? `${label}s Selling Credit total cannot be more than ${fields.selling_credit}%.` : ''}
                        className="team-info-field inputfieldinfo text-wrap" />
                      :
                      <div className="team-info-field">{item.selling_credit || ''}</div>
                    }
                    {fields.execution_credit && isEditing ?
                      <InputField
                        type="number"
                        flag="true"
                        value={item.execution_credit || ''}
                        inputProps={{ readOnly: !isEditing, min: 0 }}
                        InputProps={{ disableUnderline: !isEditing }}
                        onChange={(e) => handleChange(index, 'execution_credit', e.target.value)}
                        error={item.execution_credit_error ? true : false}
                        helperText={item.execution_credit_error ? `${label}s Execution Credit total cannot be more than ${fields.execution_credit}%.` : ''}
                        className="team-info-field inputfieldinfo text-wrap" />
                      :
                      <div className="team-info-field">{item.execution_credit || ''}</div>
                    }
                    {
                      isEditing ?
                        // <InputField
                        //   type="number"
                        //   value={getTotalCredit(item)}
                        //   onChange={(e) => handleChange(index, 'total_credit', e.target.value)}
                        //   InputProps={{ disableUnderline: true }}
                        //   disabled
                        //   className="team-info-field sum-value red" />
                        <div className={`team-info-field sum-value ${getGrandTotalCredit() === 100 && !isError && 'text-success'}`} >
                          {getTotalCredit(item)}
                        </div>

                        :
                        <div className={`team-info-field ${getGrandTotalCredit() === 100 && !isError && 'text-success'}`} >{getTotalCredit(item)}</div>
                    }

                    <div className="close-action">
                      {((label === 'Partner' && list.length > 1) || label !== 'Partner') && <span className="close-icon" onClick={() => removeElement(index)}>
                        {isEditing ? <CloseIcon color="error" fontSize="inherit" /> : <></>}
                      </span>}
                    </div>
                  </div>
                }) : <div className="d-flex">--</div>}
                {list.length > 0 ? <div className="d-flex">
                  <div className="team-info-field name-field"></div>
                  <div style={{ minWidth: '15px' }}></div>
                  {fields.origination_credit ?
                    // <InputField
                    //   type="number"
                    //   value={credits['total_origination_credit'] || 0}
                    //   InputProps={{ disableUnderline: true }}
                    //   disabled
                    //   className="team-info-field input-field-info-sample sum-value " />
                    <div className={`team-info-field sum-value ${!isError ? "content" : "text-red"}`}>
                      {credits['total_origination_credit'] || 0}
                    </div>
                    :
                    <div className="team-info-field text-align-center"></div>
                  }
                  {fields.selling_credit ?
                    <div className={`team-info-field sum-value ${!isError ? "content" : "text-red"}`}  >
                      {credits['total_selling_credit'] || 0}
                    </div>
                    // <InputField
                    //   type="number"
                    //   value={credits['total_selling_credit'] || 0}
                    //   InputProps={{ disableUnderline: true }}
                    //   disabled
                    //   align="right"
                    //   className="team-info-field sum-value text-center" />
                    :
                    <div className="team-info-field"></div>
                  }
                  {fields.execution_credit ?
                    <div className={`team-info-field sum-value ${!isError ? "content" : "text-red"}`}>
                      {credits['total_execution_credit'] || 0}
                    </div>
                    :
                    <div className="team-info-field"></div>
                  }
                  {/* <InputField
              type="number"
              value={parseFloat(credits.total_origination_credit || 0) + parseFloat(credits.total_selling_credit || 0) + parseFloat(credits.total_execution_credit || 0)}
              InputProps={{ disableUnderline: true }}
              disabled
              className="team-info-field sum-value" /> */}
                  <div className="team-info-field sum-value content"  >
                    {getGrandTotalCredit()}
                  </div>

                  <div className="close-action"></div>
                </div> : ''}
                {isEditing && <div className='text-start'><Button
                  variant={"text"}
                  startIcon={<AddIcon style={{ color: "red" }} />}
                  onClick={addElement}
                  className="button-text-capitalized"
                  style={{
                    position: "relative",
                    bottom: "-6px"
                  }}
                >
                  <span style={{
                    fontWeight: "600",
                    fontSize: "13px",
                    fontStyle: "italic"
                  }}>Add new {label}</span>
                </Button></div>}
              </div>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div >

      {readOnlyMessage ? <div className={readOnlyMessage ? 'layout-content read-only' : ''}><div className='mb-2' dangerouslySetInnerHTML={{ __html: readOnlyMessage }}></div> </div> : null}
    </div>

  )
}

TeamInfoTable.propTypes = {
  header: PropTypes.string,
  label: PropTypes.string,
  fields: PropTypes.object.isRequired,
  isEditing: PropTypes.bool,
  register: PropTypes.func,
  values: PropTypes.array,
  field: PropTypes.string,
  setValue: PropTypes.func,
  renderActions: PropTypes.func,
  renderLabel: PropTypes.func,
  editingField: PropTypes.string,
  option: PropTypes.array,
  readOnlyMessage: PropTypes.string
}
