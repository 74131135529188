//----------------------------------------------// In-built Imports // -------------------------------------------------
import React from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { getWorkbenchUsers } from "../../utils";
import { formatDate } from "../../../../utils/date";

export default function WorkbenchInfoView(props) {
  const { workbench } = props

  const replist = workbench?.is_replist === true ? 'Yes' : 'No';

  return (
    <div className='p-4' style={{ width: '66.6%' }}>
      <Typography className="section-label">Workbench Information</Typography>
      <div className='d-flex contact-details-row'>
        <div className='contact-details'>
          <span className='contact-view-label'>Workbench Name</span>
          <span className='contact-view-value'>{workbench.name || '--'}</span>
        </div>
        <div className='contact-details'>
          <span className='contact-view-label'>Workbench Type</span>
          <span className='contact-view-value'>{workbench.type || '--'}</span>
        </div>
      </div>
      <div className='d-flex contact-details-row'>
        {workbench.type === 'Searches' && (
          <div className='contact-details'>
            <span className='contact-view-label'>Rep List</span>
            <span className='contact-view-value'>{replist || 'No'}</span>
          </div>
        )}
        <div className='contact-details'>
          <span className='contact-view-label'>Users</span>
          <span className='contact-view-value'>
            {getWorkbenchUsers(workbench.tagged_users)}
          </span>
        </div>
      </div>
      <div className='d-flex contact-details-row'>
        <div className='contact-details'>
          <span className='contact-view-label'>Archive Date</span>
          <span className='contact-view-value'>{formatDate(workbench.archive_date) || '--'}</span>
        </div>
        <div className='contact-details'>
          <span className='contact-view-label'>Description</span>
          <span className='contact-view-value'>{workbench.description || '--'}</span>
        </div>
      </div>
      <div className='d-flex contact-details-row'>
        <div className='contact-details'>
          <span className='contact-view-label'>Lock Workbench</span>
          <span className='contact-view-value'>{workbench.is_locked ? 'Yes' : 'No'}</span>
        </div>
        <div className='contact-details'>
          <span className='contact-view-label'>Created On</span>
          <span className='contact-view-value'>{formatDate(workbench.created_at) || '--'}</span>
        </div>
      </div>
    </div>
  )
}

WorkbenchInfoView.propTypes = {
  workbench: PropTypes.object,
  isEditing: PropTypes.bool
}
