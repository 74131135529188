//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from "react";

//-----------------------------------------------------------// Internal Imports // ------------------------------

import InvoiceSummary from "./List";

export default function ReadyToBill() {
  return (
    <InvoiceSummary
      type="readyToBill"
      storageKey="invoiceSummery"
      title="Ready To Bill"
    />
  );
}