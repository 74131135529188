
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useState, useEffect } from "react";

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from "prop-types";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import { Button } from "@material-ui/core";

//-----------------------------------------------------------// Internal Imports // ------------------------------

import ActivityHistories from "../../../components/ActivityLog/Components/GeneralInfo/Components/ActivityHistory/ActivityHistories";
import HistoryItem from "../../../components/ActivityLog/Components/GeneralInfo/Components/ActivityHistory/HistoryItem";
import { GET } from "../../../services/constantService";
import { activitiesDataApi } from "../../../services/ApiService";

export default function RecentActivities(props) {
  const { contact, showMore = true } = props;
  const [asActivity, setAsActivity] = useState(false);
  const [activityHistories, setActivityHistories] = useState([]);

  useEffect(() => {
    const getData = async (id) => {
      const sub_route = `contactId=${id}&limit=2`
      const { status, data } = await activitiesDataApi(GET, sub_route)
      if (status && status === 200) {
        setActivityHistories(data.data);
      }
    };
    if (contact?.id) {
      getData(contact.id);
    }
  }, [contact.id]);

  function showActivityLogs() {
    setAsActivity(true);
  }
  return (
    <div className="right-nav-section right-nav-recent-activity-section d-flex flex-column">
      <span className="right-nav-header" style={{ "marginBottom": "6%" }}>
        Recent Activity{" "}
        <span style={{ color: "#5C52C6", fontSize: 18 }}>
          <TrendingUpIcon fontSize="inherit" />
        </span>
      </span>
      {activityHistories.length ? (
        <>
          {activityHistories.slice(0, 2).map((item) => {
            return <HistoryItem details={item} key={item.id} />;
          })}
          <div>
            {showMore &&
              <Button
                style={{
                  textTransform: 'none',
                  outline: 'none',
                  textDecoration: 'underline',
                }}
                onClick={showActivityLogs}
                color="primary"
              >
                View Activity Logs
              </Button>
            }
          </div>
        </>
      ) : (
        "--"
      )}
      {asActivity ? (
        <ActivityHistories
          setActivityHistories={setActivityHistories}
          contact={{
            id: contact.id,
            first_name: contact.first_name,
            last_name: contact.last_name,
          }}
          onPopupClose={() => {
            setAsActivity(false);
          }}
        />
      ) : (
        <div></div>
      )}
    </div>
  );
}

RecentActivities.propTypes = {
  showMore: PropTypes.bool,
  contact: PropTypes.object,
};
