//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useState, useEffect } from 'react'

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from "prop-types";

//----------------------------------------------// Internal Imports // -------------------------------------------------
import TeamInfoTable from './TeamInfoTable'

export default function RecruiterInformation(props) {
  const {register, setValue, currentValues = {}, isEditing = true,option, message  } = props

  const fields = {
    execution_credit: 100
  }

  const [recruiters, setRecruiters] = useState([])
  const [selectedRecruiters,setSelectedRecruiters]=useState([])

  useEffect(() => {
    register('recruiters')
    setValue('recruiters', recruiters)
  }, [register, recruiters, setValue])

  useEffect(() => {
    setRecruiters(currentValues.recruiters)
  }, [currentValues.recruiters])

  useEffect(()=>{
    if(recruiters && recruiters.length > 0) {
      const options  = option.filter((array_el)=>{
        return recruiters.every(anotherOne_el=>anotherOne_el?.user?.id !== array_el?.id)
      });
      setSelectedRecruiters(options)
    }else {
      setSelectedRecruiters(option)
    }
  },[register, recruiters, setValue])
  return (
    <TeamInfoTable readOnlyMessage={message} header={"Recruiter Information"} label="Recruiter" inputConfig={{list: recruiters, fields, setter: setRecruiters}} isEditing = {isEditing} option={selectedRecruiters}/>
  )
}

RecruiterInformation.propTypes = {
  register: PropTypes.func,
  control: PropTypes.object,
  setValue: PropTypes.func,
  watch: PropTypes.func,
  currentValues: PropTypes.object,
  isEditing: PropTypes.bool,
  option:PropTypes.array,
  message: PropTypes.string
}
