//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from "prop-types";
import { Typography, Button, Checkbox, FormControl, FormControlLabel, FormLabel } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { withSnackbar } from "notistack";
import CloseIcon from "@material-ui/icons/Close";

//----------------------------------------------// Internal Imports // -------------------------------------------------
import DegreeSelection from "../../DegreeSelection";
import InputField from "../../../components/common/InputField";
import YearSelection from "../../../components/YearSelection";

const EducationDetails = (props) => {
  const { register, setValue, currentValues, showTitle = true, disabled = false, show = true } = props;
  const [educationDetails, setEducationDetails] = useState([])
  const [schoolNameError, setSchoolNameError] = useState([]);
  const [majorError, setMajorError] = useState(false);
  useEffect(() => {
    register('education_details');
    if (currentValues.education_details.length > 0) {
      setEducationDetails(currentValues.education_details)
      let nameError = new Array(currentValues.education_details.length).fill(false)
      let majorError = new Array(currentValues.education_details.length).fill(false)
      setSchoolNameError(nameError)
      setMajorError(majorError)
    } else {
      setEducationDetails([{}])
    }
  }, [currentValues, register])

  useEffect(() => {
    setValue('education_details', educationDetails);
  }, [educationDetails, setValue])

  const handleChange = (index, field, value) => {
    const currentEducationDetails = [...educationDetails]
    currentEducationDetails[index][field] = value
    if (currentEducationDetails[index]['degree_name'] === null) {
      currentEducationDetails[index]['verify'] = false;
    }
    setEducationDetails(currentEducationDetails)
  }

  const addEducation = () => {
    setEducationDetails(prevState => ([...prevState, {}]))
  }

  const removeEducation = (index) => {
    let details = [...educationDetails];
    details.splice(index, 1)
    setEducationDetails(details)
  }

  /* const checkForError = (index, field, value) => {
    if (/^[A-Za-z\s]*$/.test(value)) {
      if (field === 'school_name') {
        const updateNameError = [...schoolNameError]
        updateNameError[index] = false
        setSchoolNameError(updateNameError)
      }
      if (field === 'major') {
        const updateMajorError = [...majorError]
        updateMajorError[index] = false
        setMajorError(updateMajorError)
      }
      return true
    }
    else {
      if (field === 'school_name') {
        const updateNameError = [...schoolNameError]
        updateNameError[index] = true
        setSchoolNameError(updateNameError)
      }
      if (field === 'major') {
        const updateMajorError = [...majorError]
        updateMajorError[index] = true
        setMajorError(updateMajorError)
      }
      handleChange(index, field, '')
      return false
    }
  } */

  return (
    <div className='w-100 section-content education-details'>
      {showTitle && <Typography className='section-label'>Education Details</Typography>}
      {educationDetails.map((item, index) => {
        return (
          <div key={index} className="section-sub-content">
            <div className='d-flex'>
              <Typography className="sub-section-label" component={"legend"}>ENTRY {index + 1}</Typography>
              {show && <CloseIcon cursor='pointer' onClick={() => removeEducation(index)} style={{ width: 18, height: 18, marginRight: 15 }} />}
            </div>
            <div className='d-flex flex-grow-1'>
              <InputField
                className='input-form-field input-field-old'
                label='School Name'
                placeholder='School Name'
                name='school_name'
                defaultValue={item.school_name}
                ref={register()}
                InputLabelProps={{ focused: true }}
                disabled={disabled}
                error={schoolNameError[index]}
                helperText={schoolNameError[index] ? 'Enter Valid Value ' : ''}
                onChange={(e) => {
                  /* checkForError(index, 'school_name', e.target.value) && */
                  handleChange(index, 'school_name', e.target.value)
                }}
              />
              <DegreeSelection
                className='input-form-field input-field-old'
                value={item.degree_name || null}
                onChange={(e, data) => {
                  handleChange(index, 'degree_name', data)
                }}
                label='Degree Name'
                placeholder='Degree Name'
                InputLabelProps={{ focused: true }}
                disabled={disabled}
              />
            </div>
            <div className='d-flex flex-grow-1'>
              <InputField
                ref={register()}
                defaultValue={item.major}
                className='input-form-field input-field-old'
                label='Major'
                name='major'
                placeholder='Major'
                InputLabelProps={{ focused: true }}
                disabled={disabled}
                error={majorError[index]}
                helperText={majorError[index] ? 'Enter Valid Value ' : ''}
                onChange={(e) => {
                  /* checkForError(index, 'major', e.target.value) && */
                  handleChange(index, 'major', e.target.value)
                }}
              />
              {/* <InputField
                ref={register()}
                defaultValue={item.degree_year}
                className='input-form-field input-field-old'
                label='Degree Date'
                name='degree_year'
                InputLabelProps={{ focused: true }}
                disabled={disabled}
                onChange={(e) => {
                  handleChange(index, 'degree_year', e.target.value)
                }}
              /> */}
              <YearSelection
                ref={register()}
                defaultValue={item.degree_year}
                className='input-form-field input-field-old'
                disabled={disabled}
                // value={item.degree_year || null}
                onChange={(e, data) => {
                  handleChange(index, 'degree_year', data);
                }}
                label='Degree Year'
                InputLabelProps={{ focused: true }}
                yearType='start'
              />
            </div>
            {item.degree_name &&
              <div className='d-flex flex-grow-1'>
                <FormControl className="input-form-field input-field-old">
                  <FormLabel focused={true} className='input-label' >Degree Verified</FormLabel>
                  <FormControlLabel
                    className="checkbox-container"
                    control={
                      <Checkbox
                        className="checkbox-component"
                        defaultChecked={item.verify}
                        size="small" style={{ paddingRight: 5 }}
                        name='verify'
                        disabled={disabled}
                        onChange={(e) => { handleChange(index, 'verify', e.target.checked) }} />}
                    label='Yes'
                  />
                </FormControl>
              </div>
            }
          </div>
        );
      })}
      <Button variant={"text"} startIcon={<AddCircleIcon />} disabled={disabled} onClick={addEducation} color='primary'>
        Add Education
      </Button>
    </div>
  );
};
EducationDetails.propTypes = {
  register: PropTypes.func,
  control: PropTypes.object,
  enqueueSnackbar: PropTypes.func,
  setValue: PropTypes.func,
  currentValues: PropTypes.object,
  showTitle: PropTypes.bool,
  disabled: PropTypes.bool,
  show: PropTypes.bool
};

export default withSnackbar(EducationDetails);
