
//-----------------------------------------------------------// Internal Imports // ------------------------------

import { isEmpty } from "../../../utils/common";

export const cleanupCompany = (company, richText) => {
  Object.keys(company).forEach((key) => {
    if (company[key] === '') {
      company[key] = null
    }
  });
  //Adding for phone input clear issue
  company.phone = company.phone === '+' ? null : company.phone

  company.industries = company.industries?.map(item => typeof item === "string" ? item : item.name)
  company.vc_pe = company.vc_pe?.map(item => ({ vcpe_id: item.id }))
  company.country = company.country && company.country.name

  if (!richText) {
    delete company.description
    delete company.off_limits_reason
    delete company.news
  }
  if (company && company.state && typeof company.state !== "string") {
    if (company.state.inputValue) {
      company.state = company.state.inputValue
    } else {
      company.state = company.state.title
    }
  }
  return company
}

export const getOldValue = (company, field) => {
  const oldValue = { [field]: company[field] };
  if (field === 'parent_id') {
    oldValue[field] = company.parent?.id
  } else if (field === 'vc_pe') {
    oldValue[field] = company.vc_pe?.map(item => ({ ...item, vcpe_id: item.company?.id }))
  }
  return oldValue
}

const getVcPe = (company) => {
  if (Array.isArray(company.vc_pe)) {
    return company.vc_pe.map(item => item.company).filter(item => !isEmpty(item))
  }
  return []
}

export const getDefaultValues = (data) => {
  const values = { ...data };

  delete values.industries;

  values.country = typeof data.country === 'object' ? data.country : { name: data.country };
  values.vc_pe = getVcPe(data);

  return values
}
