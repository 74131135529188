//----------------------------------------------// In-built Imports // -------------------------------------------------
import React from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from "prop-types";
import { MenuItem, Select } from "@material-ui/core";


//----------------------------------------------// Internal Imports // -------------------------------------------------


const options = [
  { label: 'Milestone', value: 'Milestone' },
  { label: 'Time Based', value: 'Time Based' },
];

const TermTypeSelection = (props) => {
  const { defaultValue, onChange, variant, className, name } = props;
  return (
    <Select
      defaultValue={defaultValue}
      className={className}
      variant={variant}
      onChange={onChange}
      name={name}
    >
      {options.map((item, index) => (
        <MenuItem key={index} value={item.value}>{item.label}</MenuItem>
      ))}
    </Select>
  )
};

TermTypeSelection.propTypes = {
  defaultValue: PropTypes.number,
  onChange: PropTypes.func,
  variant: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string
}

export default TermTypeSelection;