
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from "react";

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from "prop-types";
import { Button } from "@material-ui/core";

const buttonStyle = {
  height: 33,
  textTransform: "none",
  fontSize: 13,
  borderRadius: 3,
  backgroundColor: "#fff",
  fontWeight: 600,
  padding: "5px 10px",
  marginLeft: 12,
  lineHeight: 1.5,
};

class CustomFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: null,
    };
  }
  isFilterActive() {
    return this.state.value !== null;
  }

  afterGuiAttached(params) {
    this.hidePopup = params.hidePopup;
  }

  setModel(model) {
    if (this.props.field) {
      this.setState({
        value: { [this.props.field]: model ? model.filter : null },
      });
    } else {
      this.setState({ value: model ? model.filter : null });
    }
  }

  getModel() {
    if (this.state.value) {
      const filter = this.props.field
        ? this.state.value[this.props.field]
        : this.state.value;
      return { filter };
    }
    return null;
  }

  handleApply = () => {
    if (this.state.value) {
      this.props.filterChangedCallback();
    }
    this.hidePopup();
  };

  handleReset = () => {
    if (this.state.value) {
      this.setState({ value: null }, () => {
        this.props.filterChangedCallback();
      });
    }
    this.hidePopup();
  };

  render() {
    const {
      Component,
      multiple = false,
      option,
      placeholder = "",
      isSearchIndustry,
      isBoolean = false
    } = this.props;
    const { value } = this.state;
    return (
      <React.Fragment>
        <div style={{ padding: 12 }}>
          <Component
            option={option}
            label=""
            placeholder={placeholder}
            openOnFocus
            disableClearable
            isSearchIndustry ={isSearchIndustry}
            filterSelectedOptions={multiple}
            size={multiple ? "small" : "medium"}
            multiple={multiple}
            style={{ backgroundColor: "#FFF", padding: 5 }}
            classes={{ option: "ag-custom-component-popup" }}
            value={multiple ? (value ? value : []) : value}
            onChange={(e, value) => {
              this.setState({ value });
            }}
            isBoolean={isBoolean}
          />
        </div>
        <div style={{ padding: "12px 10px", borderTop: "1px solid #DDDDE9" }}>
          <Button
            onClick={this.handleReset}
            style={buttonStyle}
            variant="outlined"
            color="primary"
          >
            Reset Filter
          </Button>
          <Button
            onClick={this.handleApply}
            style={buttonStyle}
            variant="outlined"
            color="primary"
          >
            Apply Filter
          </Button>
        </div>
      </React.Fragment>
    );
  }
}

CustomFilter.propTypes = {
  field: PropTypes.string,
  filterChangedCallback: PropTypes.func.isRequired,
  Component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  multiple: PropTypes.bool,
  option: PropTypes.array,
  placeholder: PropTypes.string,
  isSearchIndustry:PropTypes.bool,
  isBoolean:PropTypes.bool,
};

export default CustomFilter;
