import { InputLabel } from '@material-ui/core'
import React from 'react'
import { formatDate } from '../../utils/date'
import PropTypes from 'prop-types'

const BasicDetailsView = ({ defaultValues  }) => {
  return (
    <div className="d-flex flex-column">
      <div className='d-flex contact-details-row'>
        <div className='d-flex intersection-page-view'>
          <InputLabel className="page-label">Opportunity #</InputLabel>
          <span className="page-label-value">{defaultValues.job_number ? defaultValues.job_number : '--'}</span>
        </div>
        <div className='d-flex intersection-page-view'>
          <InputLabel className="page-label">Stage</InputLabel>
          <span className="page-label-value">{defaultValues.stage_desc ? defaultValues.stage_desc : '--'}</span>

        </div>
      </div>
      <div className='d-flex contact-details-row'>
        <div className='d-flex intersection-page-view remove-capitalize'>
          <InputLabel className="page-label">Company</InputLabel>
          <span className="page-label-value">{defaultValues.company ? defaultValues.company.name : '--'}</span>
        </div>
        <div className='d-flex intersection-page-view'>
          <InputLabel className="page-label">Client contacts</InputLabel>
          <span className="page-label-value multiple-view-selection-list">
            {defaultValues.client_team?.length > 0 ? defaultValues.client_team.map((contact, index) => (
              <p key={index}>{contact.first_name || '--'} {contact.last_name || '--'}</p>)) : '--'}
          </span>
        </div>
      </div>
      <div className='d-flex contact-details-row'>
        <div className='d-flex intersection-page-view'>
          <InputLabel className="page-label">Job Title</InputLabel>
          <span className="page-label-value">{defaultValues.job_title || '--'}</span>
        </div>
        <div className='d-flex intersection-page-view'>
          <InputLabel className="page-label">Projected Start Date</InputLabel>
          <span className="page-label-value">{formatDate(defaultValues.projected_start_date) || '--'}</span>
        </div>
      </div>
      <div className='d-flex contact-details-row'>
        <div className='d-flex intersection-page-view'>
          <InputLabel className="page-label">Location</InputLabel>
          <span className="page-label-value">{defaultValues.country || '--'}</span>

        </div>
        <div className='d-flex intersection-page-view'>
          <InputLabel className="page-label">Partners</InputLabel>
          <span className="page-label-value multiple-view-selection-list">
            {defaultValues.basic_partners?.length > 0 ? defaultValues.basic_partners.map((partnerDetails, index) => (
              <p key={index}>{partnerDetails.name}</p>)) : '--'}
          </span>
        </div>

      </div>
      <div className='d-flex contact-details-row'>
        {/* <div className='d-flex intersection-page-view'>
          <InputLabel className="page-label">Projected Fee</InputLabel>
          <span className="page-label-value">{defaultValues.projected_fee ? `${currencyDetails.currency_icon} ${defaultValues.projected_fee}` : "--"}</span>
        </div> */}
        <div className='d-flex intersection-page-view'>
          <InputLabel className="page-label">Probability %</InputLabel>
          <span className="page-label-value">{defaultValues.probability !== undefined ? (defaultValues.probability === 0 ? 0 : defaultValues.probability) : '--'}</span>

        </div>
        <div className='d-flex intersection-page-view'>
          <InputLabel className="page-label">BD Status</InputLabel>
          <span className="page-label-value">{defaultValues.bd_status || 'Target Identified'}</span>
        </div>
      </div>
      <div className='d-flex contact-details-row'>
        <div className='d-flex intersection-page-view'>
          <InputLabel className="page-label">Service Offering</InputLabel>
          <span className="page-label-value">{defaultValues.brand ? defaultValues.brand_desc ? defaultValues.brand_desc : defaultValues.brand : "--"}</span>

        </div>
        <div className='d-flex intersection-page-view'>
          <InputLabel className="page-label">Geography</InputLabel>
          <span className="page-label-value">{defaultValues.markets ? defaultValues.markets.market : '--'}</span>
        </div>
      </div>
      <div className='d-flex contact-details-row'>
        <div className='d-flex intersection-page-view'>
          <InputLabel className="page-label">Competition</InputLabel>
          <span className="page-label-value">{defaultValues.competition || '--'}</span>
        </div>
      </div>
    </div>

  )
}

BasicDetailsView.propTypes = {
  defaultValues: PropTypes.object
}
  

export default BasicDetailsView