
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { Component } from "react";

//-----------------------------------------------------------// External Imports // ------------------------------

import { connect } from "react-redux";
import PropTypes from "prop-types";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import { withSnackbar } from "notistack";

import {
  Button,
  Typography,
  Checkbox,
} from "@material-ui/core";

//-----------------------------------------------------------// Internal Imports // ------------------------------

import {
  defaultColumns,
  productOneCandidateColumnDef,
  OffLimitRenderer
} from "./utils";
import {
  CustomLoadingOverlayComponent,
  PAGE_LIMIT,
  LinkedInRenderer,
  saveColumnStateToLocalStorage,
  loadColumnStateFromLocalStorage,
} from "../../Commons/Utils";
import "../../Commons/index.scss";
import "./index.scss";
import { checkContactFetchingStatus, ContactNameRenderer } from "../../Contacts/utils";
import { CompanyNameRenderer } from "../../Companies/utils";
import Header from "../../../components/ViewSearch/Components/Header";
import GenericCellEditor from "../../Commons/CellEditors/GenericCellEditor";
import CustomFilter from "../../Commons/CustomFilter";
import ReasonEditor from "../../Commons/CellEditors/ReasonEditor";
import ColumnFilter from "../../Commons/ColumnFilter";
import Loader from "../../../components/common/Loader";
import { isLoggedInUserAdmin } from "../../../services/cognitoService";
import {
  API, ERROR, GET, POST, PRODUCTONE_LOAD_CANDIDATES, PRODUCTONE_TERMINATE_EXTENDED, PRODUCT_ONE, PRODUCT_ONE_MESSAGE, SUCCESS, WARNING, EXTENDED_SEARCH_LOAD_CANDIDATES, LINKEDIN_SCRAPER, SFPA_DATA
} from "../../../services/constantService";
import AddContactsToWorkbench from "../../Commons/AddContactToWorkbench";
import LinkedInDialog from "../../Commons/LinkedInDialog";

import { RELOAD_ALL_CANDIDATE_GRID_WITHOUT_PRODUCTONE, UPDATE_CANDIDATE_LIST } from "../../../types";
import { notFoundMessage, successMessage, unableMessage, VALIDATION_MESSAGE } from "../../../services/MessageService";
import ConfirmationPopup from "../../../components/ConfirmationPopup";
import { productOneApi } from "../../../services/ApiService";
import { fetchSfpaData, fetchTypeList } from "../../../actions";
import { filterBlankContactTarget } from "../../../components/ViewSearch/Components/SFPA/utils";
import PopupEditor from '../../Commons/CellEditors/PopupEditor'
import { dataSourceUtils } from "../../../utils/dataSource";
import { checkFilterAndSort, getColorDot, getFlagIndicator } from "../../../utils/common";
import { getFilterParamString, SUCCESS_STATUS_CODE } from "../Utils";
import ActionsPopover from "../../../Containers/Contacts/ActionsPopover";
import ScoresDefinitionPopup from "../../../components/ScoresDefinitionPopup";
import ScoresAdvanceFilterPopup from "../../../components/ScoresAdvanceFilterPopup";
import FeetypeSelection from "../../../components/FeetypeSelection";

const productOneStages = ['P1', 'P1 Rejected']
class ProductOneCandidatesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isRowSelected: false,
      isAllRowsSelected: false,
      rowCount: 0,
      isLoading: false,
      isAdmin: false,
      linkedPopup: false,
      linkedInValue: {},
      loadingMessage: null,
      initialLoad: false,
      inprogressZoomExtended: false,
      inprogressTetrasExtended: false,
      extendedType: null,
      process: null,
      taskId: null,
      showPopup: false,
      canTerminate: false,
      filterValue: null,
      sfpaValidatePopup: false,
      invalidSfpaMessage: '',
      showScoresDefinition: false,
      showAdvanceFilter: false,
      candidateParams: {},
      showReasonPopup: false
    };
  }

  componentWillUnmount() {
    const columnApi = this.columnApi
    saveColumnStateToLocalStorage(
      `candidatesColumns-${this.props.candidateStatus || ""}`,
      { columnApi }
    );
    this.props.dispatch({ type: 'resetLinkedinScraperEvents' })
    this.props.dispatch({ type: UPDATE_CANDIDATE_LIST, payload: { key: "productOneCandidates", data: undefined } })
  }

  componentDidUpdate() {
    // if (!this.props.productOneCandidates && this.state.initialLoad) {
    //   this.getRowData();
    // }
    // if (this.props.socket) {
    //   this.props.socket.on(LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_NOTIFY_TO_PRODUCT_ONE, (msg, obj) => {
    //     let candidateFound
    //     this.gridApi.forEachNode(node => {
    //       if (node.data?.id === obj?.candidate?.id) {
    //         candidateFound = node
    //       }
    //     })
    //     if (candidateFound) {
    //       candidateFound.setData(obj.candidate)
    //       this.props.dispatch({ type: RELOAD_ALL_CANDIDATE_GRID_WITHOUT_PRODUCTONE })
    //       this.gridApi.refreshCells({ force: true });
    //       let reduxCandidate = [...this.props.productOneCandidates]
    //       reduxCandidate = reduxCandidate.map((ele) => ele.id === obj.candidate.id ?  obj.candidate : ele)
    //       this.props.dispatch({ type: UPDATE_CANDIDATE_LIST, payload: { key: "productOneCandidates", data: reduxCandidate } })
    //     }
    //   })
    // }
    if (this.props[LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_NOTIFY_TO_PRODUCT_ONE]) {
      const event = this.props[LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_NOTIFY_TO_PRODUCT_ONE];
      let candidateFound
      this.gridApi.forEachNode(node => {
        if (node.data?.id === event.obj?.candidate?.id) {
          candidateFound = node
        }
      })
      if (candidateFound) {
        candidateFound.setData(event.obj.candidate)
        this.props.dispatch({ type: RELOAD_ALL_CANDIDATE_GRID_WITHOUT_PRODUCTONE })
        this.gridApi.refreshCells({ force: true });
        let reduxCandidate = this.props.productOneCandidates;
        reduxCandidate.data = reduxCandidate.data.map((ele) => ele.id === event.obj.candidate.id ? event.obj.candidate : ele)
        this.props.dispatch({ type: UPDATE_CANDIDATE_LIST, payload: { key: "productOneCandidates", data: reduxCandidate } })
      }
      this.props.dispatch({ type: LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_NOTIFY_TO_PRODUCT_ONE, payload: undefined })
    }
    if (this.props.P1_GRID_HEADERS) {
      this.loadColDefHeaderNames();
    }
  }

  getURLs = {
    listURl: `${API.search}/${this.props.id}/candidates`,
  };

  dataSource = {
    getRows: async (params) => {
      try {
        params.filterModel && this.setState({ filterValue: params.filterModel });
        this.setState({ showFilterCount: Object.keys(this.state.filterValue).length })
        if (this.state.forceLoad || this.state.load || !this.props.productOneCandidates || Object.keys(params.filterModel).length !== 0 ||
          params.sortModel.length !== 0 ||
          this.state.resetGrid === 0
        ) {
          params.filterModel &&
            this.setState({ filterModel: params.filterModel });
          this.gridApi.deselectAll();
          const thisValue = { ...this };
          // this.setState({ isLoading: true });
          this.props.setInitialLoader(true)
          const obj = {
            params: params,
            context: thisValue,
            pageLimit: PAGE_LIMIT,
            url: this.getURLs,
            subScreen: true,
            queryString: `&candidateStatus=${this.props.candidateStatus}`,
          };
          const { status, data } = await dataSourceUtils(
            obj,
            getFilterParamString
          );
          // data.data.forEach((item) => {
          //   item.title_score = 90;
          //   item.industry_score = 50;
          //   item.job_function_score = 70;
          //   item.company_score = 95;
          //   item.tenure_weight = 30;
          //   item.stint_weight = 72;
          //   item.recency_weight = 85;
          //   item['lifer'] = 1.0
          // })
          if (checkFilterAndSort(params.filterModel, params.sortModel)) {
            this.props.dispatch({
              type: UPDATE_CANDIDATE_LIST,
              payload: { key: "productOneCandidates", data }
            })
          }

          if (status === SUCCESS_STATUS_CODE) {
            if (data?.paging?.totalCount === 0) {
              const message = notFoundMessage("records");
              this.props.enqueueSnackbar(message, { variant: WARNING });
            }
            params.successCallback(data.data, data.paging.totalCount);
            this.setState({
              contacts: data.data,
              rowCount: data.paging.totalCount,
            });
            this.state.isAllRowsSelected &&
              this.setSelectAllRows(this.state.isAllRowsSelected);
          } else {
            params.failCallback();
          }
          // this.setState({ isLoading: false });
          this.props.setInitialLoader(false)
          this.gridApi.hideOverlay();
        } else {
          if (this.props.productOneCandidates?.paging?.totalCount === 0) {
            const message = notFoundMessage("records");
            this.props.enqueueSnackbar(message, { variant: WARNING });
          }
          params.successCallback(
            this.props.productOneCandidates.data,
            this.props.productOneCandidates.paging.totalCount
          );
          this.setState({
            contacts: this.props.productOneCandidates.data,
            rowCount: this.props.productOneCandidates.paging.totalCount,
          });
          this.state.isAllRowsSelected &&
            this.setSelectAllRows(this.state.isAllRowsSelected);
        }
        this.setState({ forceLoad: false })
      } catch (e) {
        console.log("Error found in downloadAttachment::", e);
      }
    },
    rowCount: null,
  };

  loadColDefHeaderNames = async () => {
    if (this.props.P1_GRID_HEADERS && !this.state.columnHeaders) {
      const exactCompanyMatchHeader = this.props.P1_GRID_HEADERS.find(ele => ele.field_value === 'EXACT_COMPANY_MATCH');
      console.log(exactCompanyMatchHeader)
      this.setState({ columnHeaders: { [exactCompanyMatchHeader.field_value]: exactCompanyMatchHeader.short_desc } })
    }
  }

  onGridReady = async (params) => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    const columnApi = params.columnApi
    const gridApi = params.api
    loadColumnStateFromLocalStorage(
      `candidatesColumns-${this.props.candidateStatus || ""}`,
      { columnApi, gridApi }
    );
    // params.api.sizeColumnsToFit();
    await this.loadColDefHeaderNames()
    this.setState({ isGridReady: true });
  };

  handleCopySearchesToWorkbench = () => {
    this.setState({ isCopyToWorkbenchOpen: true });
  };

  handleCopySearchesToWorkbenchClose = () => {
    this.setState({ isCopyToWorkbenchOpen: false });
  };

  setSelectAllRows = (isAllRowsSelected) => {
    this.setState({ isAllRowsSelected });
    this.gridApi.forEachNode((node) => {
      node.setSelected(isAllRowsSelected);
    });
  };

  handleChange = () => {
    this.setSelectAllRows(!this.state.isAllRowsSelected);
  };

  linkedInPopupHandler = (props) => {
    this.setState({ linkedPopup: true, linkedInValue: props.value });
  };

  LinkedInRenderer = (params) => {
    return (
      <LinkedInRenderer
        params={params}
        linkedInPopup={this.linkedInPopupHandler}
      />
    );
  };

  LinkedInCloseHandler = () => {
    this.setState({ linkedPopup: false });
  };

  HeaderCheckbox = () => {
    return (
      <Checkbox
        style={{ padding: 0, width: 16, height: 16, color: "white" }}
        size="small"
        color="primary"
        onChange={this.handleChange}
      />
    );
  };

  checkUserRole = () => {
    let isAdminUser = isLoggedInUserAdmin();
    isAdminUser = isAdminUser === "true" ? true : false;
    this.setState({ isAdmin: isAdminUser });
  };

  componentDidMount = async () => {
    this.checkUserRole();
    // this.getRowData();
    if (!this.props.sfpaData) {
      await fetchSfpaData(this.props.dispatch, this.props.id, this.props.enqueueSnackbar);
    }
    this.props.dispatch({ type: 'resetLinkedinScraperEvents' })
    if (!this.props.P1_GRID_HEADERS) {
      this.props.dispatch(fetchTypeList('P1_GRID_HEADERS'))
    }
  };

  CompanyNameRenderer = (params) => {
    const status = checkContactFetchingStatus(params)
    if (status) return status;
    return <CompanyNameRenderer hidePopover={true} company={params?.data?.contact?.company} />;
  };

  NameRenderer = (params) => {
    return (
      <div className="product-one-score-content">
        {params?.data?.contact?.linkedin_url &&
          <LinkedInRenderer
            params={params}
            linkedInPopup={this.linkedInPopupHandler}
            className="d-inline-flex align-self-start"
            contact={params?.data?.contact}
          />
        }
        <ContactNameRenderer
          params={params}
          handleAddToSearch={this.handleAddToSearch}
        /></div>
    );
  };

  ColorDotRenderer = (params) => {
    const status = checkContactFetchingStatus(params)
    if (status) return status;

    /* Check if Field is Exact_company_match and value <= 65 then hide the score */
    const scoreValue = params?.colDef?.colId === 'exact_company_match'
      ? (params?.value > 65 ? params.value : null)
      : params?.value;

    return (
      <div className="d-flex">
        {(getColorDot(scoreValue))}
      </div>
    );
  };

  LiferRenderer = (params) => {
    return (
      getFlagIndicator(params.value)
    );
  }
  /* ExactCompanyMatchRenderer = (params) => {
    const iconColor = '#26C137';
    return (
      getFlagIndicator(params.value, true, iconColor)
    );
  } */

  ActionsRenderer = (params) => {
    const status = checkContactFetchingStatus(params)
    if (status) return status;
    const label = 'Agree/Disagree';
    const list = [
      {
        label: "Agree & Add",
        checkDisable: this.checkProductOneStages(params?.data?.stage) ? false : true,
        onClick: async () => {
          this.changeStageToTargets(params);
        }
      },
      {
        label: "Disagree",
        checkDisable: params && params.data && params.data.disagree ? params.data.disagree : '',
        onClick: async () => { this.setRowChange(params, 'disagree') }
      },
    ]
    return <ActionsPopover list={list} label={label} />;
  };

  getParams = (selectedRows) => {
    if (this.state.isAllRowsSelected) {
      if (this.props.candidateStatus) {
        return `candidateStatus=${this.props.candidateStatus}`;
      }
      return "";
    } else {
      return selectedRows.map((row) => `id=${row.id}`).join("&");
    }
  };

  onFilterChanged = (params) => {
    const filterModel = params.api.getFilterModel();
    this.setState({ filterModel });
    this.setState({ resetGrid: Object.keys(filterModel).length });
    let filteredCount = 0
    const filter = JSON.parse(JSON.stringify(filterModel))
    filter && this.setState({ filterValue: filter });
    this.setState({ showFilterCount: Object.keys(this.state.filterValue).length })
    this.gridApi.forEachNode(() => filteredCount++)
    this.setState({ rowCount: filteredCount });
    this.saveColumnStateForFilter()
  };

  onSortChanged = (params) => {
    const sortModel = params.api.getSortModel();
    this.setState({ sortModel });
    this.saveColumnStateForSort()
  };

  onFirstDataRendered = () => {
    const locationState = this.props.location.state;
    if (locationState) {
      const sortModel = locationState.sortModel;
      if (sortModel) {
        this.gridApi.setSortModel(sortModel);
      }

      const filterModel = locationState.filterModel;
      if (filterModel) {
        this.gridApi.setFilterModel(filterModel);
      }
    }
  };

  saveColumnState = async () => {
    try {
      this.gridApi && this.gridApi.showLoadingOverlay()
      const columnApi = this.columnApi;
      const gridApi = this.gridApi
      await saveColumnStateToLocalStorage(
        `candidatesColumns-${this.props.candidateStatus || ""}`,
        { columnApi, gridApi }
      );
      this.gridApi && this.gridApi.hideOverlay()
    } catch (e) {
      console.log("Error found in saveColumnState::", e);
    }
  };

  saveColumnStateForFilter = async () => {
    try {
      this.gridApi && this.gridApi.showLoadingOverlay()
      const columnApi = this.columnApi;
      const gridApi = this.gridApi
      await saveColumnStateToLocalStorage(
        `candidatesColumns-${this.props.candidateStatus || ""}`,
        { columnApi, gridApi },
        true,
        false
      );
      this.gridApi && this.gridApi.hideOverlay()
    } catch (e) {
      console.log("Error found in saveColumnState::", e);
    }
  };

  saveColumnStateForSort = async () => {
    try {
      this.gridApi && this.gridApi.showLoadingOverlay()
      const columnApi = this.columnApi;
      const gridApi = this.gridApi
      await saveColumnStateToLocalStorage(
        `candidatesColumns-${this.props.candidateStatus || ""}`,
        { columnApi, gridApi },
        false,
        true
      );
      this.gridApi && this.gridApi.hideOverlay()
    } catch (e) {
      console.log("Error found in saveColumnState::", e);
    }
  };

  loaderChange = async (value) => {
    this.setState({ isLoading: value });
  };

  getRowData = async () => {
    try {
      if (!this.props.productOneCandidates) {
        const newData = await this.getProductOneDataFromGalaxy()
        if (!newData.length) {
          const message = notFoundMessage("records");
          this.props.enqueueSnackbar(message, { variant: WARNING });
        }
        this.setState({ rowCount: newData.length, initialLoad: true })
        this.props.dispatch({
          type: UPDATE_CANDIDATE_LIST,
          payload: { key: "productOneCandidates", data: newData },
        });

      } else {
        this.setState({ rowCount: this.props.productOneCandidates.length, initialLoad: true })
      }
    } catch (e) {
      console.log("Error found in getRowData::", e);
    }
  }

  getProductOneDataFromGalaxy = async () => {
    const subRoute = `${PRODUCTONE_LOAD_CANDIDATES}?id=${this.props.id}`
    let newData = [];
    const { status, data } = await productOneApi(GET, null, subRoute);
    if (status === 200) {
      newData = data
    }
    return newData;
  }

  getTerminateAccess() {
    if ((this.state.requestedUser?.id === this.props.userData.id || this.props.userData.roles[0] === 'admin') && this.state.process != 'Quick') {
      return true
    }
    return false
  }

  validateSfpaInfo = () => {
    const {
      target_job_functions: functions,
      target_industries: industries,
      search_industry_rankings: industryRanking,
      contact_targets: contacts = [],
    } = this.props.sfpaData;

    const contactTarget = filterBlankContactTarget(contacts)
    let fields = [];
    if (!functions?.length) {
      fields = [...fields, 'Functions']
    }
    if (!industries?.length || !industryRanking?.length) {
      fields = [...fields, 'Industries or Sub Industries']
    }
    if (!contactTarget?.length) {
      fields = [...fields, 'Target Titles']
    }
    if (!fields.length) {
      return true
    }

    const message = `The ${fields.join(', ')} section has missing or incomplete data`
    this.setState({ invalidSfpaMessage: message, sfpaValidatePopup: true })
    return false
  }

  handleCloseSfpaPopup = () => {
    this.setState({ sfpaValidatePopup: false, invalidSfpaMessage: '' })
  }

  handleConfirmSfpaPopup = () => {
    this.setState({ sfpaValidatePopup: false, invalidSfpaMessage: '' })
    this.props.history.push(`/searches/${this.props.id}/search-roadmap/scope`);
    this.props.checkSfpaLock()
  }

  getCandidatesFromZoom = async () => {
    const isValid = this.validateSfpaInfo()
    if (!isValid) return false;

    this.setState({ loadingMessage: `Zoom quick ${PRODUCT_ONE_MESSAGE.QUICK_SEARCH_PROCESS_STARTED}`, isLoading: true })
    const subRoute = EXTENDED_SEARCH_LOAD_CANDIDATES

    const obj = { id: this.props.id, "candidates_recs": true, type: PRODUCT_ONE.QUICK, sourceType: PRODUCT_ONE.ZOOM }
    const { status, data } = await productOneApi(POST, obj, subRoute);
    if (status === 200) {
      const message = 'Zoom Quick Search Complete';
      this.props.enqueueSnackbar(message, {
        variant: SUCCESS,
      });
      this.setState({ forceLoad: true })
      this.gridApi.onFilterChanged();
      // const newData = data.map(ele => ({ ...ele, dq_reason: ele.dq_reason?.split(', ') || [] /* accept: false, disagree: false, isFromGalaxy: false */ }))
      // const filteredData = newData.filter(ele => {
      //   return ele.contact_id !== this.props.productOneCandidates.find(el => el.contact_id === ele.contact_id)?.contact_id
      // })
      // this.setState({ rowCount: [...this.props.productOneCandidates, ...filteredData].length })
      // this.props.dispatch({
      //   type: UPDATE_CANDIDATE_LIST,
      //   payload: { key: "productOneCandidates", data: [...this.props.productOneCandidates, ...filteredData] },
      // });
    } else if (status === 400) {
      this.props.enqueueSnackbar(data?.message || SFPA_DATA.sfpa_invalid_data, {
        variant: ERROR,
      });
    } else {
      const message = this.getUnableMessage(PRODUCT_ONE.QUICK_LABEL);
      this.props.enqueueSnackbar(message, {
        variant: ERROR,
      });
    }
    this.props.dispatch({ type: RELOAD_ALL_CANDIDATE_GRID_WITHOUT_PRODUCTONE })
    this.setState({ loadingMessage: '', isLoading: false })
    this.state.isAllRowsSelected &&
      this.setSelectAllRows(this.state.isAllRowsSelected);
  }
  getCandidatesFromZoomExtended = async () => {
    const isValid = this.validateSfpaInfo()
    if (!isValid) return false;

    this.setState({ isLoading: true })
    const subRoute = EXTENDED_SEARCH_LOAD_CANDIDATES
    const obj = { id: this.props.id, "candidates_recs": true, type: PRODUCT_ONE.EXTENDED, sourceType: PRODUCT_ONE.ZOOM }

    const { status, data } = await productOneApi(POST, obj, subRoute);
    if (status === 200) {
      if (data.message) {
        const requestedUser = data?.data?.requested_user
        this.setState({ inprogressZoomExtended: true, extendedType: PRODUCT_ONE.ZOOM, showPopup: true, requestedUser })
      } else {
        this.props.enqueueSnackbar(data?.message || PRODUCT_ONE_MESSAGE.STARTED, {
          variant: SUCCESS,
        });
      }
    } else if (status === 400) {
      this.props.enqueueSnackbar(data?.message || SFPA_DATA.sfpa_invalid_data, {
        variant: ERROR,
      });
    } else {
      const message = this.getUnableMessage(PRODUCT_ONE.EXTENDED_LABEL);
      this.props.enqueueSnackbar(message, {
        variant: ERROR,
      });
    }
    this.setState({ isLoading: false })
    this.state.isAllRowsSelected &&
      this.setSelectAllRows(this.state.isAllRowsSelected);
  }

  getCandidatesFromTetras = async () => {
    const isValid = this.validateSfpaInfo()
    if (!isValid) return false;

    this.setState({ loadingMessage: `Galaxy quick ${PRODUCT_ONE_MESSAGE.QUICK_SEARCH_PROCESS_STARTED}`, isLoading: true })
    const subRoute = EXTENDED_SEARCH_LOAD_CANDIDATES;
    const obj = { id: this.props.id, "candidates_recs": true, type: PRODUCT_ONE.QUICK, sourceType: PRODUCT_ONE.TATRAS }
    try {
      const { status, data } = await productOneApi(POST, obj, subRoute);
      if (status === 200) {
        if (data && data.data) {
          const requestedUser = data?.data?.requested_user;
          this.setState({ process: 'Quick', extendedType: PRODUCT_ONE.TATRAS, showPopup: true, requestedUser })
        } else {
          const message = 'Galaxy Quick Search Complete';
          this.props.enqueueSnackbar(message, {
            variant: SUCCESS,
          });
          this.setState({ forceLoad: true });
          this.gridApi.onFilterChanged();
        }
        // const newData = data.map(ele => ({ ...ele, dq_reason: ele.dq_reason?.split(', ') || [] /* accept: false, disagree: false, isFromGalaxy: false */ }))
        // const filteredData = newData.filter(ele => {
        //   return ele.contact_id !== this.props.productOneCandidates.find(el => el.contact_id === ele.contact_id)?.contact_id
        // })
        // this.setState({ rowCount: [...this.props.productOneCandidates, ...filteredData].length })
        // this.props.dispatch({
        //   type: UPDATE_CANDIDATE_LIST,
        //   payload: { key: "productOneCandidates", data: [...this.props.productOneCandidates, ...filteredData] },
        // });
      } else if (status === 400) {
        this.props.enqueueSnackbar(data?.message || SFPA_DATA.sfpa_invalid_data, {
          variant: ERROR,
        });
      } else {
        const message = this.getUnableMessage(PRODUCT_ONE.QUICK_LABEL);
        this.props.enqueueSnackbar(message, {
          variant: ERROR,
        });
      }
    } catch (err) {
      console.log('err in getCandidatesFromTetras::', err);
      const message = PRODUCT_ONE_MESSAGE.quick_search_timeout;
      this.props.enqueueSnackbar(message, {
        variant: ERROR,
      });
    }
    this.props.dispatch({ type: RELOAD_ALL_CANDIDATE_GRID_WITHOUT_PRODUCTONE })
    this.setState({ loadingMessage: '', isLoading: false })
    this.state.isAllRowsSelected &&
      this.setSelectAllRows(this.state.isAllRowsSelected);
  }

  getCandidatesFromTetrasExtended = async () => {
    const isValid = this.validateSfpaInfo()
    if (!isValid) return false;

    this.setState({ isLoading: true })
    const subRoute = EXTENDED_SEARCH_LOAD_CANDIDATES;
    const obj = { id: this.props.id, "candidates_recs": true, type: PRODUCT_ONE.EXTENDED, sourceType: PRODUCT_ONE.TATRAS }
    const { status, data } = await productOneApi(POST, obj, subRoute);
    if (status === 200) {
      if (data.message) {
        const requestedUser = data?.data?.requested_user;
        const taskId = data?.data?.extended_search_task_id;
        this.setState({ process: 'Extended', inprogressTetrasExtended: true, extendedType: PRODUCT_ONE.TATRAS, showPopup: true, requestedUser, taskId })
      } else {
        this.props.enqueueSnackbar(data?.message || PRODUCT_ONE_MESSAGE.STARTED, {
          variant: SUCCESS,
        });
      }
    } else if (status === 400) {
      this.props.enqueueSnackbar(data?.message || SFPA_DATA.sfpa_invalid_data, {
        variant: ERROR,
      });
    } else {
      const message = this.getUnableMessage(PRODUCT_ONE.EXTENDED_LABEL);
      this.props.enqueueSnackbar(message, {
        variant: ERROR,
      });
    }
    this.setState({ isLoading: false })
    this.state.isAllRowsSelected &&
      this.setSelectAllRows(this.state.isAllRowsSelected);
  }

  getUnableMessage = (type) => {
    const searchData = this.props.search
    const message = `${type} Search for Job ${searchData.job_number} - ${searchData.company.name} - ${searchData.job_title}, ${PRODUCT_ONE_MESSAGE.UNABLE_TO_FETCH_MESSAGE}`;
    return <span dangerouslySetInnerHTML={{ __html: message }}></span>
  }

  getPayloadForProductOne = (contacts) => {
    const payload = contacts.map(ele => {
      if (ele.stage !== 'Target') { ele.reason = null }
      const item = {
        ...ele,
        ...ele.contact,
        dq_reason: ele.dq_reason?.join(', ') || '',
        company_name: ele?.contact?.company?.name,
        stage: ele.disagree === true ? PRODUCT_ONE.REJECTED_STAGE : 'Target',
      }
      if (!ele.disagree) item.date_added_to_search = new Date();
      if (item.isFromGalaxy === false) {
        delete item.id
      } else {
        item.id = item.contact_id
      }
      item.user_id = this.props.userData?.id;
      delete item.contact_id
      delete item.company
      delete item.contact
      delete item.disagree
      // delete item.accept
      delete item.isFromGalaxy
      return item
    });
    return payload
  }

  changeStageToTargets = async (params, contactObj) => {
    try {
      this.setState({ isLoading: true })
      const contacts = [];
      /* In inline edit it will direct update by obj
       else iterate selected rows and change stage to target  */
      if (contactObj) {
        contactObj.reason = null;
        contacts.push(contactObj);
      } else {
        this.gridApi.forEachNode((node) => {
          if (node.selected) {
            node.data.dq_reason = [];
            node.data.disagree = false;
            if (node.data.stage !== 'Target') { node.data.reason = null }
            node.data.stage = 'Target'
            contacts.push(node.data);
          }
        });
        if (contacts.length === 0) {
          params.data.dq_reason = [];
          params.data.disagree = false;
          if (params.data.stage !== 'Target') { params.data.reason = null }
          params.data.stage = 'Target'
          contacts.push(params.data);
        }
      }

      // const contactIds = contacts.map(ele => ele.id);
      const payload = this.getPayloadForProductOne(contacts)
      let duplicatedList = [];
      let successCounter = 0;
      for await (const [, contact] of payload.entries()) {
        const subRoute = `searches/${this.props.id}/candidate-galaxy`
        const { status, data } = await productOneApi(POST, contact, subRoute);
        if (status === 200) {

          successCounter++;
          if (data.candidatesDuplicate.length) {
            // candidateId = data.candidatesDuplicate[0];
            duplicatedList = [...duplicatedList, data.candidatesDuplicate[0]]
          }
        }
      }
      if (successCounter) {
        const message = successMessage('Details', VALIDATION_MESSAGE.updated_message)
        this.props.enqueueSnackbar(message, {
          variant: SUCCESS,
        });
      } else {
        const message = unableMessage('update', 'details')
        this.props.enqueueSnackbar(message, {
          variant: ERROR,
        });
      }

      let newContactData = this.props.productOneCandidates.data.map(ele => {
        const contact = contacts.find(el => el.id === ele.id)
        if (contact) {
          return { ...contact, stage: contact.disagree === true ? PRODUCT_ONE.REJECTED_STAGE : 'Target' }
        } else {
          return ele
        }
      })

      this.props.dispatch({
        type: UPDATE_CANDIDATE_LIST,
        payload: { key: "productOneCandidates", data: { data: newContactData } },
      });

      this.gridApi.refreshCells({ force: true })
      this.setState({ isLoading: false })
      this.gridApi.forEachNode((rowNode) => {
        let contact = contacts.find(el => el.id === rowNode.data.id)
        if (contact) {
          let selectable = this.checkProductOneStages(contact.disagree === true ? PRODUCT_ONE.REJECTED_STAGE : 'Target') ? true : false
          rowNode.setRowSelectable(selectable)
          rowNode.setSelected(false)
        }
      })
      this.gridApi.refreshCells({ force: true })
      // this.gridApi.onFilterChanged();
      this.props.dispatch({ type: RELOAD_ALL_CANDIDATE_GRID_WITHOUT_PRODUCTONE })
      // const newContactData = this.props.productOneCandidates.filter(ele => !(contactIds.includes(ele.id) || duplicatedList.includes(ele.contact_id)))
      // const galaxyCandidates = await this.getProductOneDataFromGalaxy();
      // if (galaxyCandidates.length) {
      //   const filteredData = galaxyCandidates.filter(ele => {
      //     return !(ele.id === newContactData.find(el => el.id === ele.id)?.id);
      //   })
      //   const combinedData = [...filteredData, ...newContactData];
      //   this.props.dispatch({
      //     type: UPDATE_CANDIDATE_LIST,
      //     payload: { key: "productOneCandidates", data: combinedData },
      //   });
      //   this.props.dispatch({ type: RELOAD_ALL_CANDIDATE_GRID_WITHOUT_PRODUCTONE })
      //   this.setState({ rowCount: combinedData.length })
      // }
      // this.setState({ isLoading: false })
      // this.state.isAllRowsSelected &&
      //   this.setSelectAllRows(this.state.isAllRowsSelected);
    } catch (e) {
      console.log("Error found in changeStageToTargets::", e);
    }
  }

  setRowChange = async (params, field, value) => {
    params.data[field] = value ? value : params.newValue
    let contactData;
    for (let ele of this.props.productOneCandidates.data) {
      if (ele.id === params.data.id) {
        ele[field] = value ? value : params.newValue
        if (field === 'dq_reason') {
          ele.disagree = true;
          params.data['disagree'] = true;
          params.data.stage = PRODUCT_ONE.REJECTED_STAGE
        }
        if (field === 'disagree') {
          params.data['disagree'] = false;
        }
        contactData = ele;
        break;
      }
    }

    if (field === 'disagree') {
      this.setState({ candidateParams: params })
      this.setState({ showReasonPopup: true })
      // params.api.startEditingCell({
      //   rowIndex: params.node.rowIndex,
      //   colKey: 'dq_reason'
      // })
      return;
    }
    await this.changeStageToTargets(params, contactData);
  }

  handleTerminateExtendedSearch = async (type) => {
    try {
      this.setState({ isLoading: true });
      const subRoute = `${PRODUCTONE_TERMINATE_EXTENDED}?id=${this.props.id}`
      const { status, data } = await productOneApi(POST, { sourceType: type, taskId: this.state.taskId }, subRoute);
      if (status === 200) {
        this.props.enqueueSnackbar(data.message, {
          variant: SUCCESS,
        });
      } else {
        const message = unableMessage('Terminate', 'extended search')
        this.props.enqueueSnackbar(message, {
          variant: ERROR,
        });
      }
      this.setState({ isLoading: false });
    } catch (e) {
      console.log("Error found in handleTerminateExtendedSearch::", e);
    }
  }

  handleClosePopup = () => {
    this.setState({ showPopup: false, requestedUser: null })
  }

  handleCloseShowDefinition = () => {
    this.setState({ showScoresDefinition: false })
  }

  handleCloseAdvanceFilter = () => {
    this.setState({ showAdvanceFilter: false })
  }

  handleConfirmPopup = () => {
    this.setState({ showPopup: false, requestedUser: null })
    this.handleTerminateExtendedSearch(this.state.extendedType)
  }
  resetFilter = async () => {
    this.gridApi.setFilterModel(null);
    const customFilterField = this.columnApi.columnController.columnDefs;
    customFilterField.map(item => {
      if (item.filter === 'CustomFilter') {
        this.gridApi.destroyFilter(item.field);
      }
    })
  }
  advanceFilter = async () => {
    this.setState({ showAdvanceFilter: true });
  }

  scoresDefinition = async () => {
    this.setState({ showScoresDefinition: true });
  }
  resetSort = async () => {
    this.gridApi.setSortModel(null);
  }

  checkProductOneStages = (stage) => {
    return stage && productOneStages.includes(stage)
  }

  closeReasonPopup = () => {
    this.setState({ candidateParams: {}, showReasonPopup: false });
  }

  render() {
    const {
      linkedInValue,
      linkedPopup,
      isLoading,
      rowCount,
      isCopyToWorkbenchOpen,
      isRowSelected,
      extendedType,
      showPopup,
      process,
      showScoresDefinition,
      showAdvanceFilter,
      requestedUser,
      sfpaValidatePopup,
      invalidSfpaMessage,
      showReasonPopup,
      candidateParams,
    } = this.state;
    const {
      search = {},
      jobTitle,
      confidential,
      // eslint-disable-next-line react/prop-types
      initialLoader,
    } = this.props;

    return (
      <div className="all-candidates d-flex flex-column h-100">
        <Header
          companyDetails={search.company}
          company={search.company && search.company.name}
          jobNumber={search.job_number}
          jobTitle={jobTitle}
          confidential={confidential}
        >
          <LinkedInDialog
            open={linkedPopup}
            linkedInValue={linkedInValue}
            handleClose={this.LinkedInCloseHandler}
          ></LinkedInDialog>
          <div className="d-flex">
            <Button
              onClick={this.handleCopySearchesToWorkbench}
              className="action-button mr-3"
              variant="outlined"
              color="primary"
            >
              Add to Search Workbench
            </Button>
          </div>
        </Header>
        <div className="d-flex flex-column w-100 h-100">
          {showPopup &&
            <ConfirmationPopup
              header={`The ${process} Search in ${extendedType === PRODUCT_ONE.ZOOM ? 'zoom' : 'galaxy'} is currently being run by ${requestedUser?.name}`}
              message={`${this.getTerminateAccess() ? PRODUCT_ONE_MESSAGE.CONFIRM_TERMINATE : ''}`}
              onClose={this.handleClosePopup}
              onConfirm={this.handleClosePopup}
              onCancel={this.handleConfirmPopup}
              disabledCancel={!this.getTerminateAccess()}
              confirmText={`${this.getTerminateAccess() ? 'No' : 'Close'}`}
              cancelText='Yes'
            />
          }
          {showScoresDefinition &&
            <ScoresDefinitionPopup
              onClose={this.handleCloseShowDefinition}
              showScoresDefinition={showScoresDefinition}
              columnHeaders={this.state.columnHeaders}
            />
          }
          {showAdvanceFilter &&
            <ScoresAdvanceFilterPopup
              onClose={this.handleCloseAdvanceFilter}
              gridApi={this.gridApi}
              columnApi={this.columnApi}
              columnHeaders={this.state.columnHeaders}
            />
          }
          {sfpaValidatePopup &&
            <ConfirmationPopup
              header={PRODUCT_ONE_MESSAGE.VALIDATE_POPUP_HEADER}
              message={invalidSfpaMessage}
              onClose={this.handleCloseSfpaPopup}
              onConfirm={this.handleConfirmSfpaPopup}
              onCancel={this.handleCloseSfpaPopup}
              confirmText='Go to Search Roadmap'
              cancelText='Close'
            />
          }
          {isCopyToWorkbenchOpen && (
            <AddContactsToWorkbench
              open={isCopyToWorkbenchOpen}
              searchIds={[this.props.id]}
              onClose={this.handleCopySearchesToWorkbenchClose}
              sourceWorkbenchId={null}
              type="Searches"
            />
          )}
          {showReasonPopup &&
            <PopupEditor
              InputComponent={FeetypeSelection}
              title='Reason'
              enqueueSnackbar={this.props.enqueueSnackbar}
              validateDqReason={true}
              type='PRODUCT_ONE_REASONS'
              sort={true}
              placeholder='Reason'
              value={[]}
              stopEditing={this.closeReasonPopup}
              onSave={(value) => { this.setRowChange(candidateParams, 'dq_reason', value); this.closeReasonPopup(); }}
            />
          }
          {!initialLoader && <Loader className={'sync-message'} loadingMessage={this.state.loadingMessage} show={isLoading} />}
          <div className="all-candidates-actions d-flex">
            <Button
              disabled={!isRowSelected}
              onClick={() => {
                this.changeStageToTargets();
              }}
              variant="outlined"
              color="primary"
              className="mr-2"
            >
              Add to Target
            </Button>
            {/* <Button
              onClick={this.getCandidatesFromZoom}
              variant="outlined"
              color="primary"
              className="mr-2"
            >
              Zoom - Quick Search
            </Button> */}

            <Button
              onClick={this.getCandidatesFromTetras}
              variant="outlined"
              color="primary"
              className="mr-2"
            >
              Quick Search
            </Button>
            {/* <Button
              variant="outlined"
              color="primary"
              className="mr-2"
              onClick={this.getCandidatesFromZoomExtended}
            >
              Zoom - Extended Search
            </Button> */}
            <Button
              variant="outlined"
              color="primary"
              className="mr-2"
              onClick={this.getCandidatesFromTetrasExtended}
            >
              Extended Search
            </Button>
          </div>

          <div className="d-flex justify-content-between ">
            <div className="count-container pt-3">
              <div
                className="action-container"
                onClick={() => this.scoresDefinition()}
              >
                <span className="action-text" >
                  Scores Definition
                </span>
              </div>
            </div>

            <div className="count-container pt-3">
              <div
                className="action-container"
                onClick={() => this.advanceFilter()}
              >
                <span className="action-text" >
                  Advanced Filter
                </span>
              </div>
              <div
                className="action-container"
                onClick={() => this.resetFilter()}
              >
                <span className="action-text" >
                  Reset Filter
                </span>
              </div>
              <div
                className="action-container"
                onClick={() => this.resetSort()}
              >
                <span className="action-text" >
                  Reset Sort
                </span>
              </div>
              <Typography>Total count: {rowCount}</Typography>
            </div>
          </div>
          {/* {this.props.productOneCandidates ?  */}
          <div className="list-view">
            {this.state.isGridReady && (
              <ColumnFilter
                columnApi={this.columnApi}
                defaultColumns={defaultColumns}
                showFilterCount={this.state.showFilterCount}
                filterModel={this.state.filterValue}
              />
            )}
            <div id="myGrid" className="ag-theme-alpine">
              <AgGridReact
                onGridReady={this.onGridReady}
                enableBrowserTooltips={true}
                defaultColDef={{
                  resizable: true,
                  sortable: true,
                  minWidth: 150,
                  sortingOrder: ['asc', 'desc', null]
                }}
                isRowSelectable={(params) => {
                  const status = checkContactFetchingStatus(params)
                  if (status) return false;
                  if (!this.checkProductOneStages(params?.data?.stage)) return false;
                  return true;
                }}
                cacheBlockSize={PAGE_LIMIT}
                loadingOverlayComponent={"CustomLoadingOverlayComponent"}
                frameworkComponents={{
                  CustomLoadingOverlayComponent,
                  CustomFilter,
                  HeaderCheckbox: this.HeaderCheckbox,
                  NameRenderer: this.NameRenderer,
                  LiferRenderer: this.LiferRenderer,
                  CompanyNameRenderer: this.CompanyNameRenderer,
                  ColorDotRenderer: this.ColorDotRenderer,
                  ActionsRenderer: this.ActionsRenderer,
                  GenericCellEditor,
                  OffLimitRenderer,
                  ReasonEditor,
                  PopupEditor
                }}
                suppressMenuHide={true}
                getRowNodeId={(data) => data.id}
                scrollbarWidth={12}
                suppressHorizontalScroll={false}
                // rowData={this.props.productOneCandidates}
                columnDefs={productOneCandidateColumnDef(this.setRowChange, this.props.enqueueSnackbar, this.state.columnHeaders)}
                paginationPageSize={PAGE_LIMIT}
                rowModelType={"infinite"}
                rowSelection={"multiple"}
                onRowSelected={() => {
                  this.setState({
                    isRowSelected:
                      this.gridApi.getSelectedRows().length > 0 ? true : false,
                  });
                }}
                datasource={this.dataSource}
                suppressRowClickSelection={true}
                suppressDragLeaveHidesColumns={true}
                onFilterChanged={this.onFilterChanged}
                onFirstDataRendered={this.onFirstDataRendered}
                onDisplayedColumnsChanged={this.saveColumnState}
                onDragStopped={this.saveColumnState}
                onSortChanged={this.onSortChanged}

              ></AgGridReact>
            </div>
          </div>
          {/* : null} */}
        </div>
      </div>
    );
  }
}

ProductOneCandidatesList.propTypes = {
  id: PropTypes.string,
  search: PropTypes.object,
  jobTitle: PropTypes.string,
  confidential: PropTypes.bool,
  enqueueSnackbar: PropTypes.func,
  candidateStatus: PropTypes.string,
  location: PropTypes.object,
  history: PropTypes.object,
  dispatch: PropTypes.func,
  initialLoader: PropTypes.bool,
  productOneCandidates: PropTypes.array,
  userData: PropTypes.object,
  sfpaData: PropTypes.object,
  checkSfpaLock: PropTypes.func,
  socket: PropTypes.object,
  setInitialLoader: PropTypes.func,
  P1_GRID_HEADERS: PropTypes.func
};

const mapStateToProps = (state) => {
  return {
    productOneCandidates: state.rootReducer.productOneCandidates,
    userData: state.commonReducer.userData,
    sfpaData: state.rootReducer.sfpaData,
    socket: state.rootReducer.socket,
    P1_GRID_HEADERS: state.commonReducer.P1_GRID_HEADERS,
    [LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_NOTIFY_TO_PRODUCT_ONE]: state.rootReducer[LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_NOTIFY_TO_PRODUCT_ONE]
  };
};

const mapDispatchToProps = (dispatch) => ({
  dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(ProductOneCandidatesList));
