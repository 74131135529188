
//-----------------------------------------------------------// External Imports // ------------------------------

import Axios from "axios";

//-----------------------------------------------------------// Internal Imports // ------------------------------

import axios from "./axios";
// import { Auth } from 'aws-amplify';
import { AWS_TOKEN_GRANT_TYPE_REFRESH_TOKEN } from "../services/constantService";
import {
  getAwsAppClientId,
  getAwsTokenApiEndPoint,
} from "../Containers/Commons/urlUtil";
import { clearAuthFromLocalStorage } from "./cognitoService";
import { RedirectToLogin } from "../Containers/Commons/TokenValidator";

const defaultHeaders = {
  "Content-Type": "application/json",
  Accept: "application/json",
};
//const moment = require('moment')
const addAuthorizationHeader = async (headers) => {
  let token;
  try {
    // token = await (await Auth.currentSession()).getAccessToken().getJwtToken()
    token = localStorage.getItem("token");
  } catch (e) {
    console.log("Error found in addAuthorizationHeader::", e);
    // await Auth.signOut();
  }
  return {
    ...headers,
    Authorization: "Bearer " + token,
  };
};

const requestHandler = async (request) => {
  const { noAuthorization = false, ...rest } = request.headers;
  request.headers = { ...defaultHeaders, ...rest };
  if (!noAuthorization) {
    request.headers = await addAuthorizationHeader(request.headers);
  }
  return request;
};
axios.interceptors.request.use((request) => requestHandler(request));

const getNewAccessToken = async () => {
  try {
    //client without client secret
    const body = {
      grant_type: AWS_TOKEN_GRANT_TYPE_REFRESH_TOKEN,
      refresh_token: localStorage.getItem("refresh_token"), // fetched from query params
      client_id: getAwsAppClientId(),
    };
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: "",
    };
    const response = await Axios.post(getAwsTokenApiEndPoint(), null, {
      headers: headers,
      params: body,
    });
    if (response && response.data && response.data.access_token) {
      localStorage.setItem("token", response.data.access_token);
    }
  } catch (err) {
    console.log("Error found in getNewAccessToken::", err);
    if (err.response.data.error === 'invalid_request') {
      console.log("error while fetching refresh token : ", err);
      if (
        localStorage.getItem("refresh_token") == null &&
        localStorage.getItem("token") == null
      ) {
        RedirectToLogin();
      }
    } else if (err.response.data.error === 'invalid_grant') {
      console.log("Expired token : ", err);
      clearAuthFromLocalStorage();
      RedirectToLogin();
    }
  }
};

const responseHandler = (response) => {
  return response;
};

const responseErrorHandler = async (error) => {
  const originalRequest = error.config;
  if (Axios.isCancel(error)) {
    return error;
  }
  if (error.response?.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;
    try {
      // const cognitoUser = await Auth.currentAuthenticatedUser();
      // const currentSession = cognitoUser.signInUserSession;
      // cognitoUser.refreshSession(currentSession.refreshToken, async () => {
      //   axios.defaults.headers.common = await addAuthorizationHeader(axios.defaults.headers.common);
      //   return axios(originalRequest);
      // });
      await getNewAccessToken();
      return axios(originalRequest);
    } catch (e) {
      console.log("Error found in responseErrorHandler::", e);
      // await Auth.signOut();
    }
  }
  return error.response;
};

axios.interceptors.response.use(responseHandler, responseErrorHandler);

const httpService = {
  get: (
    url,
    params,
    customHeaders = {},
    responseType = "json",
    cancelToken
  ) => {
    return axios({
      url,
      headers: { ...customHeaders },
      params,
      responseType,
      cancelToken,
    });
  },
  post: (url, data, responseType = "json", options) => {
    return axios.post(url, data, { responseType, ...options });
  },
  patch: (url, data, options) => {
    return axios.patch(url, data, options);
  },
  put: (url, data, options) => {
    return axios.put(url, data, options);
  },
  delete: (url, data) => {
    return axios.delete(url, { data });
  },
};

export default httpService;
