// import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import reducer from "../reducers";
import { configureStore } from '@reduxjs/toolkit';
import companySlice from "./companySlice";
import searchSlice from "./searchSlice";
import contactSlice from "./contactSlice";
import commonSlice from "./commonSlice";
// import workbenchSlice from "./workbenchSlice";
import pickListSlice from "./pickListSlice";

// export default function configureStore(initialState) {
//   return createStore(
//     reducer,
//     initialState,
//     composeWithDevTools(applyMiddleware(thunk))
//   );
// }
// const initialState = {};

const store = configureStore({
  // initialState,
  reducer: {
    rootReducer: reducer,
    pickListReducer: pickListSlice,
    companyReducer: companySlice,
    contactReducer: contactSlice,
    commonReducer: commonSlice,
    searchReducer: searchSlice,
    /* workbenchReducer: workbenchSlice */
  },
  middleware: [thunk]
});

export default store;
