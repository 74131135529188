//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { Component } from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import { withSnackbar } from "notistack";
import PersonOutlineIcon from '@material-ui/icons/PersonOutline';
import { Link } from "react-router-dom";

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { CompanyNameRenderer } from "../../Containers/Companies/utils";
import { CompensationRenderer, SCROLL_TIMEOUT } from "../../utils/common";
import { columnDefs } from "./utils";
import {
  DateRenderer,
  LinkedInRenderer,
  loadColumnStateFromLocalStorage,
  saveColumnStateToLocalStorage
} from "../../Containers/Commons/Utils";
import ColumnFilter from "../../Containers/Commons/ColumnFilter";
import {
  ActionsRenderer,
  ContactNameRenderer, CustomLoadingOverlayComponent, defaultColumns, EmailRenderer,
  PAGE_LIMIT, PhoneRenderer, ResumeRenderer,
  SUCCESS_STATUS_CODE
} from "../../Containers/Contacts/utils";
import { masterSearchApi } from "../../services/ApiService";

class Contacts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowCount: 0,
    }
    this.enqueueSnackbar = props.enqueueSnackbar;
  }

  componentWillUnmount() {
    const columnApi = this.columnApi;
    saveColumnStateToLocalStorage('masterSearchContactColumns', { columnApi })
  }

  componentDidUpdate(prevProps) {
    if (this.props.searchKey !== prevProps.searchKey) {
      this.gridApi && this.gridApi.onFilterChanged()
    }
  }

  dataSource = {
    getRows: async (params) => {
      this.gridApi.showLoadingOverlay();
      const value = encodeURIComponent(this.props.searchKey)
      let sub_route = `contacts?limit=${PAGE_LIMIT}&page=${params.endRow / PAGE_LIMIT}&searchValue=${value}`
      if (params.sortModel.length > 0) {
        sub_route = sub_route.concat(`&sortOn=${params.sortModel[0].colId}&sortType=${params.sortModel[0].sort.toUpperCase()}`)
      }
      sub_route = `${sub_route}&mastersearch=true`
      const { status, data } = await masterSearchApi(sub_route)
      if (status === SUCCESS_STATUS_CODE) {
        params.successCallback(data.data, data.paging.totalCount)
        this.setState({ rowCount: data.paging.showTotal ? data.paging.showTotal : data.paging.totalCount })
        this.props.setContactsCount(data.paging.showTotal ? data.paging.showTotal : data.paging.totalCount)
      }
      params.failCallback();
      this.gridApi.hideOverlay()
    },
    rowCount: null
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    const columnApi = params.columnApi
    params.columnApi.autoSizeAllColumns();
    loadColumnStateFromLocalStorage('masterSearchContactColumns', { columnApi })
    this.setState({ isGridReady: true });
  };

  NameRenderer = (params) => {
    return <ContactNameRenderer params={params} handleAddToSearch={this.handleAddToSearch} />
  }

  CompanyNameRenderer = (params) => {
    return <CompanyNameRenderer company={params?.data?.company} />
  }

  LinkedInRenderer = (params) => {
    return (
      <LinkedInRenderer params={params} linkedInPopup={this.linkedInPopupHandler} />
    )
  }

  render() {
    const { height = '100%', rowHeight = 42, minHeight = 180 } = this.props
    return (
      <div className="list-contacts d-flex flex-column mt-4" style={{ height: this.state.rowCount ? height : minHeight }}>
        <div className="list-header d-flex justify-content-between">
          <Typography className='table-header-text' style={{ paddingLeft: '1.5%' }}><PersonOutlineIcon style={{ fill: '#DE9F6D' }} />Contacts</Typography>
          <Typography className='total-count' style={{ paddingRight: '1.5%' }}>Total count: {this.state.rowCount}</Typography>
        </div>
        <div className='list-view flex-grow-1'>
          {this.state.isGridReady && <ColumnFilter defaultColumns={defaultColumns} gridApi={this.gridApi} columnApi={this.columnApi} />}
          <div id="myGrid" className="ag-theme-alpine">
            <AgGridReact
              onGridReady={this.onGridReady}
              enableBrowserTooltips={true}
              defaultColDef={{
                minWidth: 100,
                resizable: true,
                sortable: true,
                sortingOrder: ['asc', 'desc', null]
              }}
              rowHeight={rowHeight}
              cacheBlockSize={PAGE_LIMIT}
              loadingOverlayComponent={'CustomLoadingOverlayComponent'}
              frameworkComponents={{
                CustomLoadingOverlayComponent,
                LinkedInRenderer: this.LinkedInRenderer,
                PhoneRenderer,
                EmailRenderer,
                ActionsRenderer,
                NameRenderer: this.NameRenderer,
                ResumeRenderer,
                DateRenderer,
                CompanyNameRenderer: this.CompanyNameRenderer,
                CompensationRenderer
              }}
              blockLoadDebounceMillis={SCROLL_TIMEOUT}
              scrollbarWidth={12}
              suppressHorizontalScroll={false}
              rowModelType={'infinite'}
              datasource={this.dataSource}
              columnDefs={columnDefs}
              paginationPageSize={PAGE_LIMIT}
              colResizeDefault={'shift'}
              getRowNodeId={data => data.id}
              suppressRowClickSelection={true}
            >
            </AgGridReact>
            <Link to={{
              pathname: `/contacts`,
              state: this.props.searchKey
            }} style={{ fontSize: '18px', float: 'right' }} className="MuiTypography-colorPrimary mt-2">More Records&gt; </Link>
          </div>
        </div>
      </div>
    )
  }
}

Contacts.propTypes = {
  enqueueSnackbar: PropTypes.func,
  searchKey: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  rowHeight: PropTypes.number,
  minHeight: PropTypes.number,
  setContactsCount: PropTypes.func.isRequired
};

export default withSnackbar(Contacts)
