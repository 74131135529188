
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useState, useEffect } from "react";

//-----------------------------------------------------------// External Imports // ------------------------------

import { useHistory } from "react-router-dom";
import NumberFormat from "react-number-format";
import PropTypes from "prop-types";
import { withSnackbar } from "notistack";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import LocalPhoneIcon from "@material-ui/icons/LocalPhone";
import EmailIcon from "@material-ui/icons/Email";
import DomainIcon from "@material-ui/icons/Domain";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import PeopleIcon from "@material-ui/icons/People";
import StarIcon from "@material-ui/icons/Star";
import Popover from "@material-ui/core/Popover";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";

//-----------------------------------------------------------// Internal Imports // ------------------------------

import PopoverStickOnHover from "../../components/common/PopoverStickOnHover";
import SearchItem from "../../components/ViewCompany/Searches/SearchItem";
import RecentActivities from "../../components/ViewContact/RecentSearchesActivities/RecentActivities";
import { getMessage } from "../../Containers/Commons/OffLimitsWarningPopup/utils";
import { getAccessToken } from "../../services/cognitoService";
import Loader from "../../components/common/Loader";
import { LinkedInRenderer, compareByPosition } from "../../utils/common";
import { API, GET, ERROR, WARNING } from "../../services/constantService";
import {
  acquireLockApi,
  contactDataApi,
  releaseLockApi,
} from "../../services/ApiService";
import { unableMessage } from "../../services/MessageService";
import { currencyValueSetter, getCurrencyPrefix, removerBorder } from "../Commons/Utils";

const NamePopover = (props) => {
  const {
    contact: contactInfo = {},
    children,
    handleAddToSearch,
    enqueueSnackbar,
    closeSnackbar,
  } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [profileUrl, setProfileUrl] = useState(null);
  const [contact, setContact] = useState(contactInfo);
  const [isLoading, setIsLoading] = useState(false);
  const [isDataFetched, setIsDataFetched] = useState(false);
  const [searches, setSearches] = useState([]);
  const history = useHistory();
  
  const getConactDetails = async (id) => {
    try{  
      setIsDataFetched(true);
      await getSearches(id);
      setIsLoading(true);
      const { status, data } = await contactDataApi(GET, id);
      setIsLoading(false);
      if (status === 200 && data !== null) {
        data.job_history = Array.isArray(data.job_history)
          ? data.job_history.sort(compareByPosition)
          : [];
        setContact(data);
      } else {
        const message = unableMessage("contact details", "fetch");
        enqueueSnackbar(data?.message || message, {
          variant: ERROR,
        });
      }
    }catch(e){
      console.log("Error found in getConactDetails::", e);
    }
  };

  const getSearches = async (id) => {
    const sub_route = "searches?stage=Open";
    const { status, data } = await contactDataApi(GET, id, {}, sub_route);
    if (status === 200 && data !== null) {
      setSearches(data.data);
    } else {
      const message = unableMessage("contact details", "fetch");
      enqueueSnackbar(data?.message || message, {
        variant: ERROR,
      });
    }
  };


  const getFirstValueFromList = (data, outerKey, valueKey) => {
    if (data && data[outerKey] && data[outerKey].length) {
      return data[outerKey][0][valueKey];
    }
    return "";
  };

  useEffect(() => {
    const getProfileURL = async () => {
      const token = await getAccessToken();
      setProfileUrl(`${API.contact}/${contact.id}/image?token=${token}`);
    };
    if (contact.image_id) {
      getProfileURL();
    }
  }, [contact.id, contact.image_id]);

  const onAddToSearch = () => {
    handleAddToSearch(contact.id);
  };
  const handleLogAnActivity = async () => {
    let { status, data } = await acquireLockApi(contact?.id);
    // let data = await acquireLockApi(contact?.id);
    if (status && status === 200 && data.message === undefined) {
      history.replace(`/contacts/${contact.id}/details`, {
        data: { isEdit: true },
      });
    } else {
      //if (data.isAdmin) {
      enqueueSnackbar(data.message, {
        variant: WARNING,
        // eslint-disable-next-line react/display-name
        action: (key) => (
          <>
            <Button
              size="small"
              variant="text"
              color="inherit"
              onClick={() => {
                closeSnackbar(key);
                releaseLock();
              }}
            >
            Force Unlock
            </Button>
            <Button
              size='small'
              variant='text'
              color='inherit'
              onClick={async () => {
                closeSnackbar(key)
              }}
            >
               CLOSE
            </Button>
          </>
        ),
      });
      /* }
      else {
        enqueueSnackbar(data.message, { variant: WARNING })
      } */
    }
  };

  const releaseLock = async () => {
    await releaseLockApi(contact?.id);
    handleLogAnActivity();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const displayOffLimitsMessage = () => {
    if (contact.offlimit?.length) {
      return showOffLimitWarningMessage();
    }
    return "";
  };
  const showOffLimitWarningMessage = () => {
    const offLimitPopOverOpen = Boolean(anchorEl);
    const id = offLimitPopOverOpen ? "simple-popover" : undefined;
    const contactName = contact.offlimit[0].name;
    const reasons = contact.offlimit[0].reasons;
    const open = Boolean(anchorEl);

    if (reasons.length > 1) {
      return (
        <React.Fragment>
          <Typography className="off-limit-badge" component="div">
            {contactName} is Off Limits for
            <Typography
              color="inherit"
              aria-describedby={id}
              className="details-link cursor-pointer"
              onClick={handleClick}
            >
              multiple reasons
            </Typography>
          </Typography>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            className="reason-container"
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <TableContainer component={Paper}>
              <Table aria-label="spanning table">
                <TableHead>
                  <TableRow style={{ backgroundColor: "#5c52c6" }}>
                    <TableCell
                      style={{ color: "#FFFFFF" }}
                      align="center"
                      colSpan={3}
                    >
                      Off Limits Reasons for {contactName}
                    </TableCell>
                  </TableRow>
                  <TableRow
                    style={{ backgroundColor: "#e9e9ea", color: "black" }}
                  >
                    <TableCell align="center">Off Limits Cause</TableCell>
                    <TableCell align="center">Details</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {reasons.map((item, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell
                          align="center"
                          style={{ backgroundColor: "red", color: "white" }}
                        >
                          {item.cause}
                        </TableCell>
                        <TableCell align="center">
                          {getMessage(item, contactName)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Popover>
        </React.Fragment>
      );
    } else {
      return (
        <Typography className="off-limit-badge">
          {reasons[0].message}
        </Typography>
      );
    }
  };
  const renderPopoverContent = () => {
    return (
      <Typography className="pop-over-name" component={"div"}>
        <Loader show={isLoading} />
        <div className="name-popover-top-section d-flex" >
          <div className="d-flex flex-grow-1 contact-profile mr-4">
            <label
              className={`profile-image-container contact-profile-image d-flex align-items-center justify-content-center`}
            >
              <span className="image-container">
                {profileUrl ? (
                  <img src={profileUrl} alt="Profile" />
                ) : (
                  <span className="default-profile-icon">
                    <AccountCircleIcon fontSize="inherit" />
                  </span>
                )}
              </span>
              {contact.is_rock_star && (
                <div className="contact-rock-star">
                  <StarIcon fontSize="inherit" />
                </div>
              )}
            </label>
            <div className="contact-profile-info flex-column nameover-top">
              <div className="d-flex align-items-center">
                <div className="contact-profile-name text-capitalize">
                  {`${contact.first_name || ""} ${contact.last_name || ""}` ||
                    "--"}
                </div>
                <div className="contact-profile-links d-flex">
                  {LinkedInRenderer(contact.linkedin_url)}
                </div>
              </div>
              <div className="d-flex align-items-center">
                <div className="contact-profile-info-icon">
                  <DomainIcon fontSize="inherit" />
                </div>
                <div className="contact-profile-info-text contact-profile-domain">{`${contact.current_job_title || ""
                  } at ${contact.company ? contact.company.name : "--"}`}</div>
              </div>
              <div className="d-flex align-items-center">
                <div className="contact-profile-info-icon">
                  <LocationOnIcon fontSize="inherit" />
                </div>
                <div className="contact-profile-info-text contact-profile-location">{`${contact.state || ""
                  }${contact.state && contact.country ? "," : ""} ${contact.country || ""
                  }`}</div>
              </div>
              <div className="d-flex align-items-center">
                <div className="contact-profile-info-icon">
                  <LocalPhoneIcon fontSize="inherit" />
                </div>
                <div className="contact-profile-info-text contact-profile-location">
                  {contact.mobile_phone ? (
                    <a
                      href={`tel:${contact.mobile_phone}`}
                      target={"noopener"}
                      className="action-label"
                    >
                      {contact.mobile_phone}
                    </a>
                  ) : (
                    "--"
                  )}
                  <span className="contact-profile-info-helper-text">
                    {" "}
                    (mobile)
                  </span>
                  <span> | </span>
                  {contact.home_phone ? (
                    <a
                      href={`tel:${contact.home_phone}`}
                      target={"noopener"}
                      className="action-label"
                    >
                      {contact.home_phone}
                    </a>
                  ) : (
                    "--"
                  )}
                  <span className="contact-profile-info-helper-text">
                    {" "}
                    (Switchboard)
                  </span>
                </div>
              </div>
              <div className="d-flex align-items-start">
                <div className="contact-profile-info-icon">
                  <EmailIcon fontSize="inherit" />
                </div>
                <div className="contact-profile-info-text contact-profile-location nameover-top-email">
                  {contact.work_email ? (
                    <a
                      href={`mailto:${contact.work_email}`}
                      target={"noopener"}
                      className="action-label text-wrap"
                    >
                      {contact.work_email}
                    </a>
                  ) : (
                    "--"
                  )}
                  <span className="contact-profile-info-helper-text">
                    {" "}
                    (Work)
                  </span>
                  <span> | </span>
                  {contact.private_email ? (
                    <a
                      href={`mailto:${contact.private_email}`}
                      target={"noopener"}
                      className="action-label"
                    >
                      {contact.private_email}
                    </a>
                  ) : (
                    "--"
                  )}
                  <span className="contact-profile-info-helper-text">
                    {" "}
                    (Personal)
                  </span>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <div className="contact-profile-info-icon">
                  <PeopleIcon fontSize="inherit" />
                </div>
                <div className="contact-profile-info-text contact-profile-type">
                  {contact.is_client ? "Client" : "--"}
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-column">
            <button
              className="primary-btn"
              style={{ minWidth: 167 }}
              onClick={onAddToSearch}
            >
              Add to Search
            </button>
            <button
              className="secondary-btn-border mt-2"
              onClick={handleLogAnActivity}
            >
              Edit Contact
            </button>
          </div>
        </div>
        {displayOffLimitsMessage()}
        <div className="name-popover-content d-flex flex-grow-1">
          <div className="tab-view-content d-flex flex-column">
            <div className="section-container flex-grow-1">
              <Typography className="section-header">
                Compensation Details
              </Typography>
              <div className="field-details compensation-details">
                <span className="field-title">BASE SALARY</span>
                {contact.base_salary ? (
                  <div className="field-value">
                    <NumberFormat
                      style={removerBorder}
                      value={contact.base_salary || "--"}
                      renderText={(value) =>
                        currencyValueSetter(value, contact.currency_iso_code)
                      }
                      thousandSeparator={true}
                      displayType={'text'}
                      decimalSeparator='.'
                      prefix={getCurrencyPrefix(contact.currency_iso_code !== 'RUR' ? contact.currency_iso_code : '')}
                      suffix={getCurrencyPrefix(contact.currency_iso_code === 'RUR' ? 'RUR' : '')}
                    />
                  </div>
                ) : (
                  <span className="field-value">{"--"}</span>
                )}
              </div>
              <div className="field-details compensation-details">
                <span className="field-title">BONUS</span>
                {contact.actual_bonus_value ? (
                  <div className="field-value">
                    <NumberFormat
                      style={removerBorder}
                      value={contact.actual_bonus_value || "--"}
                      renderText={(value) =>
                        currencyValueSetter(value, contact.currency_iso_code)
                      }
                      thousandSeparator={true}
                      displayType={'text'}
                      decimalSeparator='.'
                      prefix={getCurrencyPrefix(contact.currency_iso_code !== 'RUR' ? contact.currency_iso_code : '')}
                      suffix={getCurrencyPrefix(contact.currency_iso_code === 'RUR' ? 'RUR' : '')}
                    />
                  </div>
                ) : (
                  <span className="field-value">{"--"}</span>
                )}
              </div>
              <div className="field-details compensation-details">
                <span className="field-title">EQUITY</span>
                <span className="field-value">{contact.equity || "--"}</span>
              </div>
              <div className="field-details compensation-details notes-field-details">
                <span className="field-title">NOTES</span>
                <span className="field-value">
                  {contact.compensation_notes || "--"}
                </span>
              </div>
            </div>
            {/* For Employment Details */}
            <div className="section-container flex-grow-1">
              <Typography className="section-header">
                Employment History
              </Typography>
              {contact.job_history && contact.job_history.length !== 0
                ? contact.job_history.map((item, index) => {
                  return (
                    <>
                      <div className="secion-sub-header d-flex">
                        <span>COMPANY {index + 1}</span>
                      </div>
                      <div className="d-flex contact-details-row">
                        <div className="contact-details">
                          <div className="contact-view-label">
                            Company Name
                          </div>
                          <div className="contact-view-value">
                            {(item.company && item.company.name) || "--"}
                          </div>
                        </div>
                        <div className="contact-details">
                          <div className="contact-view-label">Title</div>
                          <div className="contact-view-value">
                            {item.title || "--"}
                          </div>
                        </div>
                      </div>
                      <div className="d-flex contact-details-row">
                        <div className="contact-details">
                          <div className="contact-view-label">Start Year</div>
                          <div className="contact-view-value">
                            {item.start_year || "--"}
                          </div>
                        </div>
                        <div className="contact-details">
                          <div className="contact-view-label">End Year</div>
                          <div className="contact-view-value">
                            {item.end_year || "--"}
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })
                : "--"}
            </div>
            {/* For Board Details */}
            <div className="section-container flex-grow-1">
              <Typography className="section-header">Board History</Typography>
              {contact.board_details && contact.board_details.length !== 0
                ? contact.board_details.map((item, index) => {
                  return (
                    <>
                      <div className="secion-sub-header d-flex">
                        <span>BOARD COMPANY {index + 1}</span>
                      </div>
                      <div className="d-flex contact-details-row">
                        <div className="contact-details">
                          <div className="contact-view-label">
                            Company Name
                          </div>
                          <div className="contact-view-value">
                            {(item.company && item.company.name) || "--"}
                          </div>
                        </div>
                        <div className="contact-details">
                          <div className="contact-view-label">Title</div>
                          <div className="contact-view-value">
                            {item.title || "--"}
                          </div>
                        </div>
                      </div>
                      <div className="d-flex contact-details-row">
                        <div className="contact-details">
                          <div className="contact-view-label">Start Date</div>
                          <div className="contact-view-value">
                            {item.start_date || "--"}
                          </div>
                        </div>
                        <div className="contact-details">
                          <div className="contact-view-label">End Date</div>
                          <div className="contact-view-value">
                            {item.end_date || "--"}
                          </div>
                        </div>
                      </div>
                      <div className="d-flex contact-details-row">
                        <div className="contact-details">
                          <div className="contact-view-label">Committee</div>
                          <div className="contact-view-value">
                            {item.committee || "--"}
                          </div>
                        </div>
                        <div className="contact-details">
                          <div className="contact-view-label">
                            Company Ticker
                          </div>
                          <div className="contact-view-value">
                            {(item.company && item.company.ticker_sym) ||
                              "--"}
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })
                : "--"}
            </div>
            <div className="section-container flex-grow-1">
              <Typography className="section-header">
                Industry & Job Function
              </Typography>
              <div className="field-details industry-details align-items-start">
                <span className="field-title">INDUSTRY</span>
                <span className="field-value">
                  {contact.industries ? contact.industries.join(", ") : "--"}
                </span>
              </div>
              <div className="field-details industry-details align-items-start">
                <span className="field-title">FUNCTION</span>
                <span className="field-value">
                  {contact.job_functions
                    ? contact.job_functions.join(", ")
                    : "--"}
                </span>
              </div>
            </div>
            <div className="section-container flex-grow-1">
              <Typography className="section-header">Education</Typography>
              <div className="field-details education-details">
                <span className="field-title">SCHOOL NAME</span>
                <span className="field-value">
                  {getFirstValueFromList(
                    contact,
                    "education_details",
                    "school_name"
                  ) || "--"}
                </span>
              </div>
              <div className="field-details education-details">
                <span className="field-title">DEGREE NAME</span>
                <span className="field-value">
                  {getFirstValueFromList(
                    contact,
                    "education_details",
                    "degree_name"
                  ) || "--"}
                </span>
              </div>
              <div className="field-details education-details">
                <span className="field-title">MAJOR</span>
                <span className="field-value">
                  {getFirstValueFromList(
                    contact,
                    "education_details",
                    "major"
                  ) || "--"}
                </span>
              </div>
              <div className="field-details education-details">
                <span className="field-title">DEGREE YEAR</span>
                <span className="field-value">
                  {getFirstValueFromList(
                    contact,
                    "education_details",
                    "degree_year"
                  ) || "--"}
                </span>
              </div>
            </div>
          </div>
          <div className="tab-view-right-nav">
            {/* For Open Searches */}
            <div className="right-nav-section right-nav-searches-section d-flex flex-column">
              <span className="right-nav-header">
                Open Searches{" "}
                <span style={{ color: "#5C52C6", fontSize: 18 }}>
                  <ZoomInIcon fontSize="inherit" />
                </span>
              </span>
              {searches.length ? (
                <>
                  {searches.map((search) => {
                    return <SearchItem search={search} key={search.id} />;
                  })}
                </>
              ) : (
                <div className="right-nav-section right-nav-recent-activity-section d-flex flex-column">
                  <span className="right-nav-header mt-3"> --</span>
                </div>
              )}
            </div>
            <RecentActivities contact={contact} showMore={false} />
          </div>
        </div>
        {/* <div className="tab-view-right-nav">
          <RecentSearches contactId={contact?.id} />
        </div> */}
      </Typography>
    );
  };

  return (
    <PopoverStickOnHover
      component={renderPopoverContent()}
      placement="right-start"
      onMouseEnter={() => { }}
      onMouseLeave={() => {
        setIsDataFetched(false)
      }}
      onEntering={() => {
        !isDataFetched && getConactDetails(contact.id);
      }}
      delay={500}
    >
      {children}
    </PopoverStickOnHover>
  );
};

NamePopover.propTypes = {
  contact: PropTypes.object,
  children: PropTypes.node,
  handleAddToSearch: PropTypes.func,
  enqueueSnackbar: PropTypes.func,
  closeSnackbar: PropTypes.func,
};

export default withSnackbar(NamePopover);
