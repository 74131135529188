const companiesOptions = {
  addAction: {
    label: "Add new",
    route: "/companies/add"
  },
  navItems: [{
    header: "View",
    list: [{
      route: "/companies",
      label: "Recent Companies"
    },
    {
      route: "/companies/clients",
      label: "My Clients"
    },
    {
      route: "/companies/targets",
      label: "My Targets"
    }
    ]
  }]
}

const contactsOptions = {
  addAction: {
    label: "Add new",
    route: "/contacts/add/details"
  },
  addLinkedinAction: {
    label: "Add New From LinkedIn",
    route: "/contacts/add/details"
  },
  navItems: [{
    header: "View",
    list: [{
      route: "/contacts",
      label: "Recent"
    }, {
      route: "/contacts/my-clients",
      label: "My Clients"
    }, {
      route: "/contacts/my-targets",
      label: "My BD Targets"
    }, 
    {
      route: "/contacts/my-mergecontacts",
      label: "Enrich Contacts"
    }, {
      route: "/contacts/my-linkedin-contacts",
      label: "My LinkedIn Contacts"
    }
    ]
  }]
}

const searchesOptions = {
  addAction: {
    label: "Add new",
    route: "/searches/create/search-information"
  },
  navItems: [{
    header: "View",
    list: [
      {
        route: "/searches/my-searches",
        label: "My Searches"
      },
      {
        route: "/searches/my-placements",
        label: "My Placements"
      },
      {
        route: "/searches/kg-searches",
        label: "KG Searches"
      },
      {
        route: "/searches/kg-placements",
        label: "KG Placements"
      }
    ]
  }]
}

const searchApprovalOptions = {
  navItems: [{
    header: "View",
    list: [
      {
        route: "/search-approval/pending",
        label: "Pending Approval"
      },
      {
        route: "/search-approval/rejected",
        label: "Rejected",
        isHide: true
      }
    ]
  }]
}

const workbenchesOptions = {
  addAction: {
    label: "Add new",
    route: "/workbenches/add"
  },
  navItems: [{
    header: "View",
    list: [
      {
        route: "/workbenches/my-workbenches",
        label: "My Workbenches",
      },
      {
        route: "/workbenches/my-workbenches/contacts",
        label: "Contacts",
        subItem: true

      },
      {
        route: "/workbenches/my-workbenches/searches",
        label: "Searches",
        subItem: true
      },
      {
        route: "/workbenches/kg-workbenches",
        label: "KG Workbenches"
      },
      {
        route: "/workbenches/kg-workbenches/contacts",
        label: "Contacts",
        subItem: true
      },
      {
        route: "/workbenches/kg-workbenches/searches",
        label: "Searches",
        subItem: true
      },
      {
        route: "/workbenches/archived-workbenches",
        label: "Archived Workbenches"
      },
      {
        route: "/workbenches/archived-workbenches/contacts",
        label: "Contacts",
        subItem: true
      },
      {
        route: "/workbenches/archived-workbenches/searches",
        label: "Searches",
        subItem: true
      }
    ]
  }]
}



const sideNavData = {
  "companies/add*": {
    isShow: false
  },
  "/companies/clients": companiesOptions,
  "/companies/targets": companiesOptions,
  "companies/([a-zA-Z0-9]+)": {
    isShow: false
  },
  "/companies*": companiesOptions,
  "contacts/add*": {
    isShow: false
  },
  "contacts/my-clients": contactsOptions,
  "contacts/my-targets": contactsOptions,
  "contacts/my-mergecontacts": contactsOptions,
  "contacts/my-linkedin-contacts": contactsOptions,
  "contacts/([a-zA-Z0-9]+)": {
    isShow: false
  },

  "users/add*": {
    isShow: false
  },
  "users*": {
    addAction: {
      label: "Add new",
      route: "/users/add"
    },
  },
  "searches/create*": {
    isShow: false
  },
  "/searches/my-searches": searchesOptions,
  "/searches/my-placements": searchesOptions,
  "/searches/kg-searches": searchesOptions,
  "/searches/kg-placements": searchesOptions,
  "searches/([a-zA-Z0-9]+)": {
    isShow: false
  },
  "/workbenches/my-workbenches": workbenchesOptions,
  "/workbenches/kg-workbenches": workbenchesOptions,
  "/workbenches/archived-workbenches": workbenchesOptions,
  "/workbenches/([a-zA-Z0-9]+)": {
    isShow: false
  },
  "/workbenches/*": workbenchesOptions,
  "/master-search/*": {
    isShow: false
  },
  "/contacts*": contactsOptions,
  "searches*": searchesOptions,
  '/insights*': {
    isShow: false
  },
  '/product-setup*': {
    isShow : false
  },
  '/search-approval': searchApprovalOptions,
  '/invoice-summary/*': {
    isShow: false
  },
}

export function getNavItemsFromPath(path) {
  let keys = Object.keys(sideNavData);
  for (let i = 0, iLen = keys.length; i < iLen; i++) {
    let key = keys[i];
    if (RegExp(key).test(path)) {
      return sideNavData[key];
    }
  }
  return {};
}
