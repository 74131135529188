//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useState } from 'react'

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types'
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import { withSnackbar } from 'notistack';
import { Input } from '@material-ui/core';
import { useForm } from 'react-hook-form';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import * as NotesService from '../../services/NotesService'
import Loader from '../common/Loader'
import { acquireLockApi, releaseLockApi } from '../../services/ApiService';
import { SUCCESS, ERROR } from '../../services/constantService';
import { unableMessage, successMessage, VALIDATION_MESSAGE } from '../../services/MessageService';
import { showSnackbar } from '../../Containers/Commons/Utils';
function Note(props) {
  const { note: noteDetails = {}, apiConfig, handleDeleteNote, handleSaveNote, enqueueSnackbar, closeSnackbar, setIsAdding } = props
  const [note, setNote] = useState(noteDetails);
  const { register, handleSubmit } = useForm();

  const [isLoading, setIsLoading] = useState(false);

  const handleSave = async (payload) => {
    try {
      setIsLoading(true);
      const { status, data } = await NotesService.add(apiConfig.parentId, payload, apiConfig.parentPath);
      setIsLoading(false);
      if (status === 200) {
        let updated = {
          ...payload,
          id: data.id,
          isEditable: false
        }
        setNote(updated);
        handleSaveNote(updated);
        setIsAdding(false);
        const message = successMessage("Note", "added")
        enqueueSnackbar(data?.message || message, { variant: SUCCESS });
      } else {
        const message = unableMessage("note", "add")
        enqueueSnackbar(data?.message || message, { variant: ERROR });
      }
    } catch (e) {
      console.log("Error found in handleSave::", e);
    }
  }

  const handleUpdate = async (payload) => {
    try {
      setIsLoading(true);
      const { status, data } = await NotesService.update(note.id, payload, apiConfig.parentPath);
      setIsLoading(false);
      if (status === 200) {
        let updated = {
          ...note,
          ...payload,
          isEditable: false
        }
        setNote(updated);
        handleSaveNote(updated);
        setIsAdding(false);
        const message = successMessage("Note", VALIDATION_MESSAGE.updated_message)
        enqueueSnackbar(data?.message || message, { variant: SUCCESS });
      } else {
        const message = unableMessage("note", "update")
        enqueueSnackbar(data?.message || message, { variant: ERROR });
      }
      releaseLock()
    } catch (e) {
      console.log("Error found in handleUpdate::", e);
    }
  }
  const releaseLock = async () => {
    await releaseLockApi(apiConfig.parentId);
  }
  const setEditable = (isEditable) => {
    setIsAdding(true);
    setNote((prev) => ({
      ...prev,
      isEditable: isEditable
    }))
  }

  const onCancel = () => {
    note.id ? setEditable(false) : handleDeleteNote()
    releaseLock()
    setIsAdding(false);
  }

  const isEditMode = () => {
    return note.isEditable === true || note.id === undefined;
  }

  const getFormId = (tag) => {
    return `note_${tag}_${note.id ? note.id : note.title}`;
  }

  const deleteNote = async (noteId) => {
    try {
      setIsLoading(true);
      const { status, data } = await NotesService.remove(noteId, apiConfig.parentPath);
      setIsLoading(false);
      if (status === 200) {
        handleDeleteNote(noteId);
        const message = successMessage("Note", "deleted")
        enqueueSnackbar(data?.message || message, { variant: SUCCESS });
      } else {
        const message = unableMessage("note", "delete")
        enqueueSnackbar(data?.message || message, { variant: ERROR });
      }
    } catch (e) {
      console.log("Error found in deleteNote::", e);
    }
  }

  return (
    <form className="d-flex position-relative">
      <Loader show={isLoading} />
      <div className="d-flex flex-column flex-grow-1 w-100">
        {isEditMode() ?
          <>
            <Input inputRef={register} name="title" placeholder="Title" defaultValue={note.title} className="notes-title" />
            <Input multiline inputRef={register} name="description" placeholder="Description" defaultValue={note.description} className="notes-description" />
          </>
          :
          <>
            <span className="notes-title">{note.title || '--'}</span>
            <span className="notes-description">{note.description || '--'}</span>
          </>
        }
      </div>
      <div className="d-flex notes-attachments-actions">
        {isEditMode() ?
          <>
            <input type="submit" value="submit" id={getFormId("submit")} hidden onClick={note.id ? handleSubmit(handleUpdate) : handleSubmit(handleSave)} />
            <label htmlFor={getFormId("submit")} className="m-0 d-inline-flex">
              <span className="action-icon mr-2">
                <DoneIcon cursor='pointer' fontSize="inherit" color="primary" />
              </span>
            </label>
            <span className="action-icon" onClick={onCancel}>
              <CloseIcon cursor='pointer' fontSize="inherit" />
            </span>
          </>
          :
          <>
            <span className="action-icon" onClick={async () => {
              let { status, data } = await acquireLockApi(apiConfig.parentId);
              if (status && status === 200 && data.message && data.user === false) {
                //if (data.isAdmin) {
                const object = {
                  enqueueSnackbar: enqueueSnackbar,
                  closeSnackbar: closeSnackbar,
                  message: data.message,
                  id: apiConfig.parentId
                }
                showSnackbar(object)
                /*  }
                 else {
                   enqueueSnackbar(data.message, { variant: WARNING });
                 } */
              }
              else {
                setEditable(true)
              }
            }}>
              <EditIcon fontSize="inherit" />
            </span>
            <span className="action-icon" onClick={() => deleteNote(note.id)}>
              <DeleteIcon fontSize="inherit" />
            </span>
          </>
        }
      </div>
    </form>
  )
}

Note.propTypes = {
  note: PropTypes.object,
  handleDeleteNote: PropTypes.func,
  handleSaveNote: PropTypes.func,
  apiConfig: PropTypes.object,
  enqueueSnackbar: PropTypes.func,
  closeSnackbar: PropTypes.func,
  setIsAdding: PropTypes.func
}

export default withSnackbar(Note);
