
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from "react";

//-----------------------------------------------------------// External Imports // ------------------------------

import { forwardRef, useEffect, useState, useImperativeHandle, useRef } from "react";
import PropTypes from "prop-types";
import { formatDate } from "../../../utils/date";

// eslint-disable-next-line react/display-name
const GenericCellEditor = forwardRef((props, ref) => {
  const {
    value: initialValue,
    InputComponent,
    stopEditing,
    option,
    type = "",
    onChange = () => { },
    ...rest
  } = props;
  const [value, setValue] = useState(initialValue);
  const [selectedValue, setSelectedValue] = useState(null)
  let selectedValueRef = useRef()
  selectedValueRef.current = null
  useImperativeHandle(ref, () => {
    return {
      getValue: () => value,
      afterGuiAttached: () => { },
    };
  });
  useEffect(() => {
    return () => {
      // eslint-disable-next-line react/prop-types
      props.api.refreshCells(props.column);

      stopEditing();
    };
  }, [stopEditing, props]);
  console.log("selectedValue", selectedValue)
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      if (selectedValueRef.current === null) {
        // eslint-disable-next-line react/prop-types
        props.api.refreshCells(props.column);
        stopEditing();
      }
    }
  };
  return (
    // eslint-disable-next-line react/prop-types
    <InputComponent
      {...rest}
      onChange={(e, data) => {
        selectedValueRef.current = data
        let input =
          data ||
          (e && e.target && e.target.value) ||
          (e && !e.nativeEvent && e);
        if (input) {
          setValue(input);
          onChange(e, data, props);
          setSelectedValue(input)
        }
        else {
          setValue("");
          onChange(e, "", props)
          setSelectedValue('')
        }
      }}
      option={option}
      value={type === "Date" ? formatDate(value) : value}
      onKeyDown={handleKeyDown}
    />
  );
});

GenericCellEditor.propTypes = {
  InputComponent: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  stopEditing: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
    PropTypes.array,
    PropTypes.func,
  ]),
  type: PropTypes.string,
  option: PropTypes.array,
  onChange: PropTypes.func
};
export default GenericCellEditor;
