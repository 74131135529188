//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from "react";

//-----------------------------------------------------------// Internal Imports // ------------------------------

import InvoiceSummary from "./List";

export default function Overdue() {
  return (
    <InvoiceSummary
      type="overdue"
      storageKey="invoiceSummery"
      title="Overdue Invoice"
    />
  );
}