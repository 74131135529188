
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { Component } from "react";
import * as yup from "yup";

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from 'prop-types'
import { AgGridReact } from "ag-grid-react";
import { Button, Typography } from "@material-ui/core";
import { withSnackbar } from "notistack";
import "ag-grid-community/dist/styles/ag-grid.css";
import Popup from "reactjs-popup";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//-----------------------------------------------------------// Internal Imports // ------------------------------

import AthenaPopover from "../../../Containers/Searches/AllCandidates/Components/AthenaPopover";
import { formatDate } from "../../../utils/date";
import AthenaInvitePopUpContact from "../../../components/ViewSearch/Components/SFPA/Components/ClientAppAccess/AthenaInvitePopUpContact";
import Loader from '../../../components/common/Loader';
import {
  addAsMyBDTarget,
  columnDefs,
  ContactNameRenderer,
  CustomLoadingOverlayComponent,
  defaultColumns,
  EmailRenderer,
  getFilterParamString,
  PAGE_LIMIT,
  PhoneRenderer,
  ResumeRenderer,
  SUCCESS_STATUS_CODE,
  acquire as acquireUtil
} from "../../../Containers/Contacts/utils";
import {
  DateTimeRenderer,
  LinkedInRenderer,
  loadColumnStateFromLocalStorage,
  saveColumnStateToLocalStorage
} from "../../../Containers/Commons/Utils";
import { CompanyNameRenderer } from '../../../Containers/Companies/utils'
import ActivityHistories from "../../ActivityLog/Components/GeneralInfo/Components/ActivityHistory/ActivityHistories";
import CopyCandidatesToSearch from "../../../Containers/Searches/AllCandidates/CopyCandidatesToSearch";
import QuickAddContact from "../../ContactSelection/QuickAddContact";
import ActionsPopover from "../../../Containers/Contacts/ActionsPopover";
import GenericCellEditor from '../../../Containers/Commons/CellEditors/GenericCellEditor'
import ColumnFilter from "../../../Containers/Commons/ColumnFilter";
import HeaderCheckbox from '../../../Containers/Contacts/HeaderCheckbox'
import { CompensationRenderer, customFormValidator, requireValidMessage, sortByChronologicalOrder } from "../../../utils/common";
import ContactsMergePopup from "./ContactsMergePopup";
import AddContactToWorkbench from "../../../Containers/Commons/AddContactToWorkbench";
import RemoveContactFromWorkbench from "../../../Containers/Commons/RemoveContactFromWorkbench";
import CustomFilter from "../../../Containers/Commons/CustomFilter";
import PhoneEditor from "../../../Containers/Commons/CellEditors/PhoneEditor";
import LinkedInDialog from "../../../Containers/Commons/LinkedInDialog";
import { API, POST, WARNING, SUCCESS, ERROR } from "../../../services/constantService";
import { dataSourceUtils } from "../../../utils/dataSource";
import { acquireLockApi, contactDataApi, releaseLockApi } from "../../../services/ApiService";
import { unableMessage, successMessage, notFoundMessage, VALIDATION_MESSAGE } from "../../../services/MessageService";
import { fetchUserList } from "../../../actions"
import AddContactAsClient from "../../../Containers/Contacts/components/AddContactAsClient";
import { cleanupCompanyDetails } from "../../AddContact/utils";
import EmploymentHistoryConfirmationDialog from "../../../Containers/Commons/EmploymentHistoryConfirmationDialog";

class ContactsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewColumns: false,
      anchorEl: null,
      contactIds: [],
      activeContact: false,
      isQuickAddOpen: false,
      showMergePopup: false,
      contacts: [{}],
      rowCount: 0,
      isAthenaPopupOpen: false,
      linkedPopup: false,
      linkedInValue: {},
      isLoading: false,
      showJobTitleChangedPopup: false,
      employmentHistoryMessageEndYearDate: null,
      isUpdateEmploymentHistory: false,
      skipCheckJobTitle: false,
      currentContact: null,
      newContactData: null,
      showFilterCount: 0
    };
    this.company = props.company;
    this.enqueueSnackbar = props.enqueueSnackbar;
    this.closeSnackbar = props.closeSnackbar;
  }

  dataSource = {
    getRows: async params => {
      try {
        const getURLs = {
          listURl: `${API.companies}/${this.company.id}/contacts`,
        };
        params.filterModel && this.setState({ filterModel: params.filterModel });
        this.setState({ showFilterCount: Object.keys(this.state.filterModel).length })
        //this.gridApi.deselectAll();
        if (params.filterModel && Object.keys(params.filterModel).length > 0)
          this.gridApi.deselectAll();
        const thisValue = { ...this };
        this.gridApi.showLoadingOverlay();
        const obj = {
          params: params,
          context: thisValue,
          pageLimit: PAGE_LIMIT,
          url: getURLs,
          subScreen: true,
          defaultSort: true,
          queryString: `&sortOn=updated_at&sortType=DESC`
        }
        const { status, data } = await dataSourceUtils(obj, getFilterParamString);
        if (status === SUCCESS_STATUS_CODE) {
          if (data.paging) {
            const message = notFoundMessage("records")
            if (data?.paging?.totalCount === 0) { this.props.enqueueSnackbar(message, { variant: WARNING }) }
            this.setState({ contacts: data.data, rowCount: data.paging.totalCount })
            params.successCallback(data.data, data.paging.totalCount);
          }
        } else {
          params.failCallback();
        }
        this.gridApi.hideOverlay();
      } catch (e) {
        console.log("Error found in getRows::", e);
      }
    },
    rowCount: null
  };

  handleAthenaPopupClose = () => {
    this.setState({ isAthenaPopupOpen: false });
    this.gridApi.onFilterChanged()
  }
  AthenaRenderer = (params) => {
    const handleInvite = () => {
      this.setState({
        isAthenaPopupOpen: true,
        athenaData: {
          candidate: { id: params.data?.candidates },
          contact: params.data
        }
      })
    }
    if (params.data) {
      if (params.data.athena_completion_date || params.data.athena_invitation_sent_on) {
        return (
          <AthenaPopover contactDetails={params.data} handleInvite={handleInvite}>
            <Typography style={{ fontSize: 'inherit', cursor: 'pointer' }} color="primary">
              {params.data.athena_completion_date ?
                `Completed on: ${formatDate(params.data.athena_completion_date)}`
                :
                `Invited on: ${formatDate(params.data.athena_invitation_sent_on)}`
              }
            </Typography>
          </AthenaPopover>
        )
      } else if (params.data.candidates.length > 0) {
        return (
          <Button
            className='invite-button athena-button'
            variant='contained'
            color='primary'
            onClick={handleInvite}
          >
            Invite to Athena
          </Button>
        )
      } else {
        return (
          <Typography style={{ fontSize: 'inherit', cursor: 'pointer' }} color="primary">Contact Is Not Associated With Any Search</Typography>
        )
      }
    }
    return null
  }
  onGridReady = params => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this.setState({ isGridReady: true });
  };


  componentDidMount() {
    if (!this.props.users) { this.props.fetchUserList('users') }
  }

  componentDidUpdate() {
    if (this.state.skipCheckJobTitle) {
      this.handleUpdateJobHistory()
    }
  }

  NameRenderer = params => {
    return (
      <ContactNameRenderer
        params={params}
        handleAddToSearch={this.handleAddToSearch}
      />
    );
  };

  linkedInPopupHandler = (props) => {
    this.setState({ linkedPopup: true, linkedInValue: props.value })
  }

  LinkedInRenderer = (params) => {
    return (
      <LinkedInRenderer params={params} linkedInPopup={this.linkedInPopupHandler} />
    )
  }

  LinkedInCloseHandler = () => {
    this.setState({ linkedPopup: false });
  }

  AddContactToRenderer = (params) => {
    const list = [
      {
        label: 'To Search',
        onClick: () => this.handleAddToSearch(params?.data?.id)
      },
      {
        label: 'To Workbench',
        onClick: () => this.handleAddToWorkbench(params.data?.id)
      },
      {
        label: 'As Client',
        onClick: () => this.handleAddAsClient(params?.data?.id)
      },
      {
        label: 'As BD Target',
        onClick: () => addAsMyBDTarget(params?.data, this.props.userData, this.gridApi, this.enqueueSnackbar)
      }
    ]
    return params.data ? <ActionsPopover list={list} /> : null
  }

  ActionsRenderer = (params) => {
    const list = [
      {
        label: "Log An Activity",
        onClick: async () => {
          this.acquire(params?.data?.id)
        }
      },
      {
        label: "Remove From Workbench",
        onClick: () => this.setState({ isRemoveFromWorkbenchOpen: true, contactId: params.data?.id })
      },
      {
        label: "Show History",
        onClick: params.colDef ? () => params.colDef.cellRendererParams.showActivityHistory(params.data) : null
      },
    ]
    return <ActionsPopover list={list} />;
  };
  acquire = async (contactId) => {
    let { status, data } = await acquireLockApi(contactId);
    if (status && status === 200 && data.message === undefined) {
      if (this.state.isRemove) {
        this.setState({ isDeleteOpen: true, contactId: contactId })
      }
      else {
        await releaseLockApi(contactId);
        this.props.history.replace(
          `/contacts/${contactId}/log-an-activity/general-info`,
          {
            sortModel: this.state.sortModel,
            fromContact: true,
            previousPath: this.props.location.pathname
          }
        )
      }
    } else {
      //if (data.isAdmin) {
      this.enqueueSnackbar(data.message, {
        variant: WARNING,
        // eslint-disable-next-line react/display-name
        action: key => (
          <>
            <Button
              size='small'
              variant='text'
              color='inherit'
              onClick={() => {
                this.closeSnackbar(key)
                this.releaseLock(contactId)
              }}
            >
              Force Unlock
            </Button>
            <Button
              size='small'
              variant='text'
              color='inherit'
              onClick={() => {
                this.closeSnackbar(key)
                this.props.history.replace(
                  `/contacts/${contactId}/log-an-activity/read-only/general-info`,
                  {
                    sortModel: this.state.sortModel,
                    fromContact: true,
                    previousPath: this.props.location.pathname
                  }
                )
              }}
            >
              Open Read Only View
            </Button>
            <Button
              size='small'
              variant='text'
              color='inherit'
              onClick={async () => {
                this.closeSnackbar(key)
              }}
            >
               CLOSE
            </Button>
            
          </>
        )
      });
      /* }
      else {
        this.enqueueSnackbar(data.message, {
          variant: WARNING,
          action: key => (
            <Button
              size='small'
              variant='text'
              color='inherit'
              onClick={() => {
                this.closeSnackbar(key)
                this.props.history.replace(
                  `/contacts/${contactId}/log-an-activity/read-only`,
                  {
                    sortModel: this.state.sortModel,
                    fromContact: true,
                    previousPath: this.props.location.pathname
                  }
                )
              }}
            >
              Open Read Only View
            </Button>
          )
        });
      } */
      this.setState({ isRemove: false })
    }
  }
  releaseLock = async (contactId) => {
    await releaseLockApi(contactId);
    this.acquire(contactId)
  }
  handleAddToSearch = contactId => {
    this.setState({ isAddToSearchOpen: true, contactIds: [contactId] });
  };

  handleAddToSearchClose = () => {
    this.setState({ isAddToSearchOpen: false, contactIds: [] });
  };

  handleAddAsClient = (contactId) => {
    this.setState({ isAddAsClientOpen: true, contactIds: [contactId] })
  }

  handleAddAsClientClose = () => {
    this.setState({ isAddAsClientOpen: false, contactIds: [] })
  }

  showContactActivityHistory = contact => {
    this.setState({
      activeContact: contact
    });
  };

  handleQuickAddContact = () => {
    this.setState({
      isQuickAddOpen: true
    });
  };

  handleQuickAddContactClose = contact => {
    this.setState({ isQuickAddOpen: false });
    if (contact) {
      this.setState({ addContact: contact, isAddCandidateOpen: true });
    }
  };

  handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ isQuickAddOpen: false });
  };

  CreateSchema = yup.object().shape({
    first_name: yup.string().required(),
    last_name: yup.string().required(),
    current_company: yup.object().required(),
    current_job_title: yup.string().required(),
    current_job_start_year: yup.string().required(),
    country: yup.string().required(),
    industries: yup.array().of(yup.string()).required(),
    job_functions: yup.array().of(yup.string()).required()
  });

  addContact = async (contact, allowDuplicate) => {
    const isValid =  await this.CreateSchema.isValid(contact)
    if (!isValid) {
      let requiredField = [
        { fieldName: "first_name", label : "First Name",  type: String },
        { fieldName: "last_name", label : "Last Name",  type: String },
        { fieldName: "current_company", label : "Company Name",  type: Object },
        { fieldName: "current_job_title", label : "Job Title",  type: String },
        { fieldName: "current_job_start_year", label : "Job Start Year",  type: String },
        { fieldName: "country", label : "Location",  type: String },
        { fieldName: "industries", label : "Industry",  type: [] },
        { fieldName: "job_functions", label : "Job Functions",  type: [] },
      ]
      let dirtyField = customFormValidator(contact, requiredField)
      if(dirtyField){
        const message = requireValidMessage(dirtyField)
        this.enqueueSnackbar(message, { variant: ERROR })
        return
      }
    }
    contact.current_company_id = contact.current_company.id;
    const sub_route = allowDuplicate ? '?allowDuplicate=true' : '';
    const { status, data } = await contactDataApi(POST, '', contact, sub_route);
    if (status === 201) {
      const message = successMessage("Contact", "created")
      this.enqueueSnackbar(data?.message || message, { variant: SUCCESS })
    } else if (status === 200) {
      return data.contacts;
    } else {
      const message = unableMessage("contact", "add")
      this.enqueueSnackbar(data?.message || message, { variant: ERROR })
    }

    this.gridApi.onFilterChanged();
    this.setState({ isQuickAddOpen: false });
  };

  CompanyNameRenderer = (params) => {
    return <CompanyNameRenderer company={params?.data?.company} />
  }

  onFirstDataRendered = (params) => {
    const columnApi = params.columnApi
    const gridApi = params.api
    loadColumnStateFromLocalStorage('companyContactsColumns', { columnApi, gridApi })
  }

  saveColumnState = async () => {
    try {
      this.gridApi && this.gridApi.showLoadingOverlay()
      const columnApi = this.columnApi;
      const gridApi = this.gridApi
      await saveColumnStateToLocalStorage('companyContactsColumns', { columnApi, gridApi })
      this.gridApi && this.gridApi.hideOverlay()
    } catch (e) {
      console.log("Error found in saveColumnState::", e);
    }
  }

  saveColumnStateForFilter = async () => {
    try {
      this.gridApi && this.gridApi.showLoadingOverlay()
      const columnApi = this.columnApi;
      const gridApi = this.gridApi
      await saveColumnStateToLocalStorage('companyContactsColumns', { columnApi, gridApi }, true, false)
      this.gridApi && this.gridApi.hideOverlay()
    } catch (e) {
      console.log("Error found in saveColumnState::", e);
    }
  }

  saveColumnStateForSort = async () => {
    try {
      this.gridApi && this.gridApi.showLoadingOverlay()
      const columnApi = this.columnApi;
      const gridApi = this.gridApi
      await saveColumnStateToLocalStorage('companyContactsColumns', { columnApi, gridApi }, false, true)
      this.gridApi && this.gridApi.hideOverlay()
    } catch (e) {
      console.log("Error found in saveColumnState::", e);
    }
  }

  handleMergeClick = () => {
    this.setState({ showMergePopup: true })
  }


  handleMergeClose = (merged) => {
    this.setState({ showMergePopup: false });
    if (merged) {
      this.gridApi.onFilterChanged();
      this.gridApi.deselectAll();
    }
  }

  handleAddToWorkbench = (contactId) => {
    this.setState({ isAddToWorkbenchOpen: true, contactIds: [contactId] })
  }

  handleAddToWorkbenchClose = () => {
    this.setState({ isAddToWorkbenchOpen: false })
  }

  handleRemoveFromWorkbenchClose = () => {
    this.setState({ isRemoveFromWorkbenchOpen: false })
  }

  loaderChange = async (value) => {
    this.setState({ isLoading: value })
  }

  setEmploymentHistoryEndYearDate = (endDate) => {
    this.setState({ employmentHistoryMessageEndYearDate: endDate });
  }

  handleConfirmJobTitlePopup = async () => {
    this.setState({ isUpdateEmploymentHistory: true, showJobTitleChangedPopup: false, skipCheckJobTitle: true })
  }
  handleCancelJobTitlePopup = async () => {
    this.setState({ isUpdateEmploymentHistory: false, showJobTitleChangedPopup: false, skipCheckJobTitle: true })
  }

  setJobHistoryPopupStates = (data) => {
    this.setState({ ...data })
  }

  handleUpdateJobHistory = async () => {
    this.setState({ skipCheckJobTitle: false })
    const params = this.state.newContactData;
    if (this.state.isUpdateEmploymentHistory) {
      this.setState({ isLoading: true })
      const contact = this.state.currentContact
      let newJobHistory = [{
        company: contact.company,
        title: contact.current_job_title,
        start_year: contact.current_job_start_year,
        end_year: this.state.employmentHistoryMessageEndYearDate || new Date().getFullYear()
      }];
      newJobHistory = [...newJobHistory, ...contact.job_history].filter(item => item?.company)
      params.job_history = sortByChronologicalOrder(cleanupCompanyDetails(newJobHistory, contact.id))
    }
    await acquireUtil(params, 'current_job_title', this.props.enqueueSnackbar, this.props.closeSnackbar, true, this.loaderChange, undefined, undefined, true)
    this.setJobHistoryPopupStates({
      employmentHistoryMessageEndYearDate: null,
      isUpdateEmploymentHistory: false,
      currentContact: null,
      newContactData: null
    })
  }
  resetFilter = async () => {
    this.gridApi.setFilterModel(null);
    const customFilterField = this.columnApi.columnController.columnDefs;
    customFilterField.map(item => {
      if (item.filter === 'CustomFilter') {
        this.gridApi.destroyFilter(item.field);
      }
    })
  }

  resetSort = async () => {
    this.gridApi.setSortModel(null);
  }

  render() {
    const {
      linkedInValue, linkedPopup, isAddToWorkbenchOpen, contactIds, activeContact, isAddToSearchOpen, contacts, isQuickAddOpen, showMergePopup,
      rowCount, selectedRowsCount, isRemoveFromWorkbenchOpen, contactId, isAthenaPopupOpen, isLoading
    } = this.state

    return (
      <div
        className="list-contacts d-flex flex-column"
        style={{ backgroundColor: "white" }}
      >
        <LinkedInDialog open={linkedPopup} linkedInValue={linkedInValue} handleClose={this.LinkedInCloseHandler} />
        <Loader show={isLoading} />
        {this.state.showJobTitleChangedPopup &&
          <EmploymentHistoryConfirmationDialog
            header='Update'
            employmentHistoryMessageEndYearDate={this.state.employmentHistoryMessageEndYearDate}
            setEmploymentHistoryEndYearDate={this.setEmploymentHistoryEndYearDate}
            open={this.state.showJobTitleChangedPopup}
            contact={this.state.currentContact}
            handleSubmit={this.handleConfirmJobTitlePopup}
            handleClose={this.handleCancelJobTitlePopup}
            cancelText='No'
            confirmText='Yes'
            extraClassName="jobhistory-popup"
          />
        }
        {activeContact && (
          <ActivityHistories
            contact={{
              id: activeContact.id,
              first_name: activeContact.first_name,
              last_name: activeContact.last_name,
              company: activeContact?.company,
              current_job_title: activeContact?.current_job_title
            }}
            onPopupClose={() => this.showContactActivityHistory(false)}
          />
        )}
        <AddContactAsClient header={VALIDATION_MESSAGE.add_contact_as_client} buttonText={VALIDATION_MESSAGE.add_contact_as_client} uiLabel="contact" open={this.state.isAddAsClientOpen} contactId={this.state.contactIds.length ? this.state.contactIds[0] : null} isAllRowsSelected={this.state.isAllRowsSelected} handleClose={this.handleAddAsClientClose} />
        {isAddToSearchOpen && (
          <CopyCandidatesToSearch
            header={VALIDATION_MESSAGE.add_contact_to_search}
            buttonText={VALIDATION_MESSAGE.add_contact_to_search}
            uiLabel="contact"
            open={true}
            contactIds={contactIds}
            handleClose={this.handleAddToSearchClose}
            contacts={contacts}
          />
        )}
        {isQuickAddOpen && (
          <Popup
            open={isQuickAddOpen}
            className={"page-popup-view"}
            onClose={() => true}
            closeOnDocumentClick={false}
            closeOnEscape={false}
          >
            <QuickAddContact
              handleClose={this.handleQuickAddContactClose}
              currentCompany={this.company}
              addContact={this.addContact}
            />
          </Popup>
        )}
        {
          showMergePopup &&
          <ContactsMergePopup
            onClose={this.handleMergeClose}
            contacts={this.gridApi.getSelectedRows()}
          />
        }
        {
          isAddToWorkbenchOpen &&
          <AddContactToWorkbench
            open={isAddToWorkbenchOpen}
            contactIds={contactIds}
            onClose={this.handleAddToWorkbenchClose}
            contacts={contacts}
            type="Contacts"
          />
        }
        {
          isRemoveFromWorkbenchOpen &&
          <RemoveContactFromWorkbench
            contactId={contactId}
            onClose={this.handleRemoveFromWorkbenchClose}
            open={isRemoveFromWorkbenchOpen}
          />
        }
        {isAthenaPopupOpen ? <AthenaInvitePopUpContact inviteFor={'candidate'} selectedDropDown={'Athena'} client={this.state.athenaData} onClose={this.handleAthenaPopupClose} /> : null}
        <div className='d-flex justify-content-between'>
          <div style={{ padding: "20px 20px 0 20px" }}>
            <Button
              onClick={this.handleQuickAddContact}
              variant="outlined"
              color="primary"
            >
              Quick Add Contact
            </Button>
            <Button
              variant='outlined'
              color='primary'
              className='ml-3'
              onClick={this.handleMergeClick}
              disabled={selectedRowsCount !== 2}
            >
              Merge
            </Button>

          </div>
          <div className='d-flex  align-items-center' style={{ padding: "20px 20px 0 20px" }}>
            <div
              className="action-container"
              style={{ minWidth: 0 }}
              onClick={() => this.resetFilter()}
            >
              <span className="action-text" >
                Reset Filter
              </span>
            </div>
            <div
              className="action-container"
              style={{ minWidth: 0 }}
              onClick={() => this.resetSort()}
            >
              <span className="action-text" >
                Reset Sort
              </span>
            </div>
            <Typography>Total count: {rowCount}</Typography>
          </div>
        </div>
        <div className="list-view flex-grow-1">
          {this.state.isGridReady && <ColumnFilter columnApi={this.columnApi} defaultColumns={defaultColumns} showFilterCount={this.state.showFilterCount} filterModel={this.state.filterModel} />}
          <div id="myGrid" className="ag-theme-alpine">
            <AgGridReact
              onGridReady={this.onGridReady}
              defaultColDef={{
                minWidth: 100,
                resizable: true,
                sortable: true,
                sortingOrder: ['asc', 'desc', null]
              }}
              cacheBlockSize={PAGE_LIMIT}
              loadingOverlayComponent={"CustomLoadingOverlayComponent"}
              frameworkComponents={{
                CustomLoadingOverlayComponent,
                LinkedInRenderer: this.LinkedInRenderer,
                PhoneRenderer,
                EmailRenderer,
                ActionsRenderer: this.ActionsRenderer,
                AddContactToRenderer: this.AddContactToRenderer,
                NameRenderer: this.NameRenderer,
                ResumeRenderer,
                DateTimeRenderer,
                CompanyNameRenderer: this.CompanyNameRenderer,
                GenericCellEditor,
                PhoneEditor,
                HeaderCheckbox,
                CompensationRenderer,
                CustomFilter,
                AthenaRenderer: this.AthenaRenderer,
              }}
              scrollbarWidth={12}
              suppressHorizontalScroll={false}
              suppressMenuHide={true}
              rowModelType={"infinite"}
              datasource={this.dataSource}
              columnDefs={columnDefs(this.showContactActivityHistory, this.enqueueSnackbar, this.closeSnackbar, this.loaderChange, this.props.users, undefined, this.setJobHistoryPopupStates)}
              paginationPageSize={PAGE_LIMIT}
              getRowNodeId={data => data.id}
              suppressRowClickSelection={true}
              rowSelection={'multiple'}
              onDisplayedColumnsChanged={this.saveColumnState}
              onRowSelected={() => {
                this.setState({ selectedRowsCount: this.gridApi.getSelectedRows().length })
              }}
              onDragStopped={this.saveColumnState}
              onFirstDataRendered={this.onFirstDataRendered}
              onSortChanged={this.saveColumnStateForSort}
              onFilterChanged={this.saveColumnStateForFilter}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    userData: state.commonReducer.userData,
    users: state.rootReducer.users
  }
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchUserList: (data) => dispatch(fetchUserList(data))
  }
};

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(withRouter(ContactsList)));

ContactsList.propTypes = {
  company: PropTypes.object,
  enqueueSnackbar: PropTypes.func,
  updateCompany: PropTypes.func,
  users: PropTypes.object,
  fetchUserList: PropTypes.func,
  userData: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  closeSnackbar: PropTypes.func,
  companyContacts: PropTypes.object,
}
