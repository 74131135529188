//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useState, useEffect } from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from "prop-types";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { CircularProgress } from "@material-ui/core";

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { GET } from "../../services/constantService";
import InputField from "../common/InputField";
import { picklistsDataApi } from "../../services/ApiService";

function JobTitleSelection(props) {
  const {
    label,
    placeholder,
    InputLabelProps,
    required = false,
    ...rest
  } = props;

  const [options, setOptions] = useState([]);
  const [value, setValue] = useState("");
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (value.length < 1) {
      setOptions([]);
      setLoading(false);
      return;
    }
    setLoading(true);
    const sub_route = `jobtitle/search?name=${value}`
    picklistsDataApi(GET, '', sub_route)
      .then((response) => {
        const { status, data } = response;
        if (status === 200 && data && data.data) {
          setOptions(data?.data);
        }
        setLoading(false);
      });
  }, [value]);

  return (
    <Autocomplete
      {...rest}
      options={options}
      filterOptions={(options) => options}
      getOptionLabel={(option) => {
        if (!option) {
          return "";
        }
        if (typeof option === "string") {
          return option;
        }
        return option.job_number;
      }}
      onInputChange={(e, val, reason) => {
        if (reason === "input") {
          setValue(val);
        }
      }}
      getOptionSelected={(option, value) => (option.id === value.id)}
      renderInput={(params) => (
        <InputField
          {...params}
          required={required}
          variant="standard"
          InputLabelProps={InputLabelProps}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading ? (
                  <CircularProgress color="primary" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
          label={label}
          placeholder={placeholder}
        />
      )}
    />
  );
}

JobTitleSelection.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  InputLabelProps: PropTypes.object,
  required: PropTypes.bool,
};
export default JobTitleSelection;
