//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useState, useEffect, useCallback } from 'react'

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types'
import { KeyboardDatePicker } from '@material-ui/pickers';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { Button, InputLabel, Tooltip, TextField, FormControlLabel, Switch, Checkbox } from "@material-ui/core";
import ReactPhoneInput from "react-phone-input-material-ui";
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import { useSelector } from 'react-redux';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import RichTextPopup from "../../RichTextPopup";
import { addHttps } from '../../../utils/string';
import { formatDate } from '../../../utils/date';
import { getAccessToken } from '../../../services/cognitoService';
import { getUserSelector } from '../../../selectors';
import { API, ERROR, GET, POST, PUT, SUCCESS, WARNING, EMAIL_REGEX, INLINE_EDIT_RECORD_VALIDATION_MESSAGE } from '../../../services/constantService';

import { acquireLockApi, releaseLockApi, renewLockApi, userDataApi } from '../../../services/ApiService';
import InputField from '../../common/InputField';
import { requireMessage, successMessage, unableMessage, validateMessage, VALIDATION_MESSAGE } from '../../../services/MessageService';
import Loader from '../../common/Loader';
import UserSelection from '../../UserSelection';
import { determineInitialCountryGuess } from '../../../utils/phone';
import { isAuthorizedUser, prepareDefaultValues } from "./utils";
import CountrySelection from '../../CountrySelection';
import StateSelection from '../../StateSelection';
import TimezoneSelection from '../../AddUser/components/TimezoneSelection';
import TextArea from '../../common/TextArea';
import DepartmentSelection from '../../AddUser/components/DepartmentSelection';
import PracticeSelection from '../../AddUser/components/PracticeSelection';
import RoleSelection from '../../AddUser/components/RoleSelection';
import { capitalize } from "../../../utils/string";
import Reference from '../../AddUser/components/Reference';
import WorkgroupSelection from '../../AddUser/components/WorkgroupSelection';
import LocationSelection from '../../AddUser/components/LocationSelection';
import RegionSelection from "../../AddUser/components/RegionSelection"
import SecondaryDepartmentSelection from '../../AddUser/components/SecondaryDepartmentSelection';
import { showSnackbar } from '../../../Containers/Commons/Utils';
import { customFormValidatorInline, tooltipValue } from '../../../utils/common';
import AppRoleSelection from '../../AddUser/components/AppRoleSelection';

function View(props) {
  const { user, setIsEditing, getUser, isEditing, setUser, userUpdate, setIsDataUpdated } = props
  const [profileURL, setProfileURL] = useState(null);
  const [websitePhoto, setWebsitePhoto] = useState(null);
  const [minimizeQuery, setMinimizeQuery] = useState(true);
  const userData = useSelector(getUserSelector)
  const [editingField, setEditingField] = useState('');
  const [hoveredField, setHoveredField] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const defaultValues = prepareDefaultValues({ ...user })
  const { register, setValue, watch, getValues, reset } = useForm({ defaultValues: { ...user } })
  const [isProfilePicUploading, setIsProfilePicUploading] = useState(false);
  const [newProfilePic, setNewProfilePic] = useState(null)
  const [newWebsitePhoto, setNewWebsitePhoto] = useState(null)
  const [isWebsitePhotoUploading, setIsWebsitePhotoUploading] = useState(false);
  const [isLastSaveSuccess, setLastSaveSuccess] = useState(true)
  const country = watch('country');
  useEffect(() => {
    reset(user);
  }, [user])

  const cancelProfileUrl = useCallback(async () => {
    if (user.profile_image_id || isProfilePicUploading) {
      const token = await getAccessToken();
      setProfileURL(`${API.users}/${user.id}/profileimage?token=${token}&time=${Date.now()}}`);
    } else {
      setProfileURL(null)
    }
  })

  const cancelWebsitePhoto = useCallback(async () => {
    if (user.website_photo_id || isWebsitePhotoUploading) {
      const token = await getAccessToken();
      setWebsitePhoto(`${API.users}/${user.id}/websitePhoto?token=${token}&time=${Date.now()}`)
    } else {
      setWebsitePhoto(null)
    }
  })

  useEffect(() => {
    const getProfileURL = async () => {
      const token = await getAccessToken();
      setProfileURL(`${API.users}/${user.id}/profileimage?token=${token}&time=${Date.now()}`)
    }
    if (user && user.profile_image_id) {
      getProfileURL();
    }
    const getWebsitePhoto = async () => {
      const token = await getAccessToken();
      setWebsitePhoto(`${API.users}/${user.id}/websitePhoto?token=${token}&time=${Date.now()}`)
    }

    if (user.website_photo_id) {
      getWebsitePhoto();
    }
  }, [user, user.id])

  const defaultValue = '--'
  const getDate = (date, format) => {
    return date ? formatDate(date, format) : defaultValue
  }

  const handleEdit = async () => {
    if (editingField !== '') {
      enqueueSnackbar(INLINE_EDIT_RECORD_VALIDATION_MESSAGE, {
        variant: WARNING,
        // eslint-disable-next-line react/display-name
        action: key => (
          <>
            <Button
              size='small'
              variant='text'
              color='inherit'
              onClick={async () => {
                await getUser();
                setEditingField('');
                closeSnackbar(key)
                setIsEditing(true)
              }}
            >
            Force Edit
            </Button>
            <Button
              size='small'
              variant='text'
              color='inherit'
              onClick={async () => {
                closeSnackbar(key)
              }}
            >
               CLOSE
            </Button>
            
          </>
        )
      });
    } else {
      await getUser();
      setIsEditing(true)
    }
  }

  const showUsers = () => {
    const users = [];
    user.project_manager.map(user => {
      return users.push(user.name)
    });
    return users.join(', ');
  }

  const renderLabel = (label, field, required = false, minControl) => {
    return <InputLabel
      required={required}
      className="contact-view-label mr-2 cursor-pointer"
      onMouseEnter={() => handleMouseEnter(field)}
      onMouseLeave={handleMouseLeave}>
      {label}{isAuthorizedUser(user, userData, minControl) && renderEditIcon(field)}
    </InputLabel>
  }

  const handleMouseEnter = (field) => {
    setHoveredField(field)
  }

  const handleMouseLeave = () => {
    setHoveredField('')
  }

  const autoSave = async (data, skipMessage, setAutoSaveLoading) => {
    setAutoSaveLoading && setAutoSaveLoading(true)
    if (setAutoSaveLoading) {
      setAutoSaveLoading(true)
    }
    await updateUserInfo(data, skipMessage)
    if (setAutoSaveLoading) {
      setTimeout(() => {
        setAutoSaveLoading(false)
      }, 1000);
    }
  }

  const updateUserInfo = async (updatedUser, skipMessage = false) => {
    // !skipMessage && setLoading(true)
    let returnResult;
    updatedUser = { ...updatedUser, roles: updatedUser.roles ? updatedUser.roles : user.roles }
    const { status, data } = await userDataApi(PUT, user.id, updatedUser);
    if (status && status === 200) {
      await getUser();
      setIsDataUpdated(true)
      userUpdate(data)
      const message = successMessage("User", VALIDATION_MESSAGE.updated_message)
      !skipMessage && enqueueSnackbar(message, { variant: SUCCESS });
      setLastSaveSuccess(true)
      setIsLoading(false)
      returnResult = true
    } else if (status === 409) {
      setLastSaveSuccess(false)
      const message = unableMessage("user", VALIDATION_MESSAGE.updated_message)
      !skipMessage && enqueueSnackbar(data?.message || message,
        {
          persist: true,
          variant: ERROR,
          // eslint-disable-next-line react/display-name
          action: key => (
            <Button
              size='small'
              variant='text'
              color='inherit'
              onClick={() => {
                closeSnackbar(key)
              }}
            >
              Dismiss
            </Button>
          )
        }
      );
      setIsLoading(false)
      returnResult = false
    } else {
      setLastSaveSuccess(false)
      const message = unableMessage("update", "user")
      !skipMessage && enqueueSnackbar(data?.message || message, { variant: ERROR });
      setIsLoading(false)
      returnResult = false;
    }
    return returnResult
  }
  const renderEditIcon = (field) => {
    return !(editingField === field || hoveredField !== field) &&
      <EditIcon className='ml-2' onClick={async () => {
        let { status, data } = await acquireLockApi(user.id);
        if (data && status && status === 200 && data.message && data.user === false) {
          //if (data.isAdmin) {
          const object = {
            enqueueSnackbar: enqueueSnackbar,
            closeSnackbar: closeSnackbar,
            message: data.message,
            id: user.id
          }
          showSnackbar(object)

          /* }
          else {
            enqueueSnackbar(data.message, { variant: 'warning' });
          } */
        }
        else {
          setEditingField(field);
          setHoveredField('')
          window.addEventListener("beforeunload", releaseLock);
          editLockRenewTimer = setInterval(renewLock, halfAnHour);
        }
      }} fontSize='inherit' color='secondary' />
  }

  let retryCount = 0;
  const halfAnHour = 30 * 60 * 1000;
  let editLockRenewTimer;

  const releaseLock = async () => {
    if (isEditing || editLockRenewTimer) {
      await releaseLockApi(user.id);
      editLockRenewTimer = clearInterval(editLockRenewTimer);
    }
    window.removeEventListener('beforeunload', releaseLock, true);
  }

  const renewLock = () => {
    if (document.hidden) {
      if (retryCount > 3) {
        clearInterval(editLockRenewTimer);
      }
      retryCount++;
    } else {
      retryCount = 0;
      renewLockApi(user.id);
    }
  }
  const renderActions = (field, required = false) => {
    if (field !== 'profileURL' && field !== 'websitePhoto') {
      const result = getValues({ nest: true });
      if (Object.keys(result).length > 1) reset(user)
    }
    if (editingField === field) {
      return (
        <>
          <span className="action-icon" onClick={() => updateField(field, required, '', false)}>
            <DoneIcon cursor='pointer' fontSize="inherit" color='primary' />
          </span>
          <span className="action-icon" onClick={async () => {
            await releaseLockApi(user.id);
            editLockRenewTimer = clearInterval(editLockRenewTimer);
            setEditingField('')
            if (field === 'profileURL') {
              cancelProfileUrl();
            } else {
              cancelWebsitePhoto();
            }
          }}>
            <CloseIcon cursor='pointer' fontSize="inherit" />
          </span>
        </>
      )
    }
    return null
  }

  const updateField = async (field, required, listValues, skipMessage) => {
    try {
      if (field === 'profileURL') { await uploadProfilePic() }
      if (field === 'websitePhoto') { await uploadWebsitePhoto() }
      const formData = listValues ? { [field]: listValues } : getValues({ nest: true });
      if (JSON.stringify(formData[field]) !== JSON.stringify(user[field])) {
        const data = { [field]: formData[field] }
        data.old = { [field]: user[field] }
        if (field === "state") {
          data.country = user.country
        }

        const isEmpty = !(Array.isArray(data[field]) ? data[field].length : data[field])
        if (required && isEmpty) {
          let requiredField = [
            { fieldName: "first_name", label: "First Name", type: String },
            { fieldName: "last_name", label: "Last Name", type: String },
            { fieldName: "title", label: "Title", type: String },
            { fieldName: "email", label: "Email", type: String },
            { fieldName: "roles", label: "Role", type: [] },
            { fieldName: "hiring_date", label: "Date of Hire", type: String }
          ]
          const dirtyField = customFormValidatorInline(field, requiredField);
          if (dirtyField) {
            const message = requireMessage(dirtyField, "is")
            enqueueSnackbar(message, { variant: ERROR });
            return
          }
        }
        setEditingField('')
        if (field === "app_roles") {
          const cleanupRole = cleanupRolesData(data, defaultValues.all_roles)
          const array = [];
          const app_roles = [];
          cleanupRole.all_roles.forEach(element => {
            array.push({ role_id: element.id })
            app_roles.push(element.name)
          })
          data.app_roles = app_roles;
          data.user_roles = array;
        }
        if (field === 'personal_email' && data.personal_email && !data.personal_email.match(EMAIL_REGEX)) {
          setValue('personal_email', user.personal_email)
          const message = validateMessage("valid personal email", "enter")
          enqueueSnackbar(message, { variant: ERROR });
          return
        }
        if (field === 'project_manager')
          data.project_managers = formData.project_manager.length ? formData.project_manager.map(data => data.id) : [];
        if (field === 'reference_user')
          data.reference = data.reference_user && data.reference_user.id ? data.reference_user.id : null;
        return await individualFieldSave(data, skipMessage)
      }

      setEditingField('')
    } catch (e) {
      console.log("Error found in updateField::", e);
    }
  }
  const cleanupRolesData = (data, defaultValue) => {
    let cleanupData = { all_roles: [] }
    for (var index in data.app_roles) {
      if (!(data.app_roles[index]?.hasOwnProperty('id'))) {
        if (data.app_roles[index] === defaultValue[index].name)
          cleanupData.all_roles = [...cleanupData.all_roles, defaultValue[index]]
      } else {
        cleanupData.all_roles = [...cleanupData.all_roles, data.app_roles[index]]
      }
    }
    return cleanupData
  }
  const individualFieldSave = async (updatedUser, skipMessage) => {
    return saveAPI(updatedUser, skipMessage);
  }

  const uploadProfilePic = async () => {
    if (newProfilePic) {
      const bodyFormData = new FormData();
      bodyFormData.append('profile', newProfilePic);
      setIsProfilePicUploading(true);
      const { status, data } = await userDataApi(POST, user.id, bodyFormData, 'profileimage');
      setNewProfilePic(null);
      if (status === 200) {
        const message = successMessage("Profile pic", "uploaded")
        enqueueSnackbar(data?.message || message, { variant: SUCCESS });
      } else {
        const message = unableMessage("profile pic", "upload")
        enqueueSnackbar(data?.message || message, { variant: ERROR });
      }
      setIsProfilePicUploading(false);
    }
  }
  const uploadWebsitePhoto = async () => {
    if (newWebsitePhoto) {
      const bodyFormData = new FormData();
      bodyFormData.append('website', newWebsitePhoto);
      setIsWebsitePhotoUploading(true);
      const { status, data } = await userDataApi(POST, user.id, bodyFormData, 'websitePhoto');
      setNewWebsitePhoto(null);
      if (status === 200) {
        const message = successMessage("Website photo", "uploaded")
        enqueueSnackbar(data?.message || message, { variant: SUCCESS });
      } else {
        const message = unableMessage("website photo", "upload")
        enqueueSnackbar(data?.message || message, { variant: ERROR });
      }
      setIsWebsitePhotoUploading(false);
    }
  }

  const saveAPI = async (updatedUser, skipMessage) => {
    !skipMessage && setIsLoading(true)
    updatedUser = { ...updatedUser, roles: updatedUser.roles ? updatedUser.roles : user.roles }
    const { status, data } = await userDataApi(PUT, user.id, updatedUser);
    if (status === 200) {
      setIsDataUpdated(true)
      const { status, data } = await userDataApi(GET, user.id);
      if (status === 200) {
        setUser(data)
        userUpdate(data)
      }
      else {
        const message = unableMessage("user details", "fetch")
        enqueueSnackbar(data?.message || message, { variant: ERROR });
      }
      const message = successMessage("User", VALIDATION_MESSAGE.updated_message)
      !skipMessage && enqueueSnackbar(message, { variant: SUCCESS });
      await releaseLockApi(user.id);
      editLockRenewTimer = clearInterval(editLockRenewTimer);
      setIsLoading(false)
      return true;
    } else if (status === 409) {
      const message = unableMessage("user", "update")
      !skipMessage && enqueueSnackbar(data?.message || message,
        {
          persist: true,
          variant: ERROR,
          // eslint-disable-next-line react/display-name
          action: key => (
            <Button
              size='small'
              variant='text'
              color='inherit'
              onClick={() => {
                closeSnackbar(key)
              }}
            >
              Dismiss
            </Button>
          )
        }
      );
      setIsLoading(false)
      return false;
    } else {
      const message = unableMessage("user details", "update")
      !skipMessage && enqueueSnackbar(data?.message || message, { variant: ERROR })
      setIsLoading(false)
      return false;
    }
  }
  const onFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (event.target.id === 'profile-pic-input') {
        setNewProfilePic(file)
        const fr = new FileReader();
        fr.onload = function () {
          setProfileURL(fr.result)
        }
        fr.readAsDataURL(file);
      }
      else {
        setNewWebsitePhoto(file)
        const fr = new FileReader();
        fr.onload = function () {
          setWebsitePhoto(fr.result)
        }
        fr.readAsDataURL(file);
      }
    }
  }
  const stateChange = (state) => {
    setValue('state', state);
  }

  return (
    <>
      <div className={`company-view-content company-details-section d-flex editing h-100 flex-column position-relative`} style={{ padding: '20px', overflow: 'auto' }}>
        <div className="edit-user d-flex flex-column w-100">
          <Loader show={isLoading} />
          <div className="user-profile-details-popup">
            <div className="d-flex w-25" style={{ margin: '20px' }}>
              <div className='contact-details w-25'>
                {renderLabel('Bio Photo', 'profileURL', false, 'user')}
                {
                  editingField === 'profileURL' ?
                    <div className='d-flex' style={{ marginLeft: '-10px', marginTop: '10px' }}>
                      <Tooltip placement={'right-end'} title="This is a headshot that will show up on your Galaxy Bio and in ClientSuite" >
                        <label className="profile-image-container user-profile-image d-flex align-items-center justify-content-center edit" htmlFor="profile-pic-input">
                          <Loader show={isProfilePicUploading} />
                          <span className="image-container">{profileURL ? <img src={profileURL} alt="Profile" /> : <span className="default-profile-icon"><AccountCircleIcon fontSize="inherit" /></span>}</span>
                          <input id="profile-pic-input" type="file" accept="image/*" onClick={(e) => { e.target.value = null; setNewProfilePic(null) }} onChange={onFileChange} hidden />
                          <div className="profile-pic-edit">
                            <EditIcon fontSize="inherit" />
                          </div>
                        </label>
                      </Tooltip>
                      {renderActions('profileURL', false)}
                    </div>
                    :
                    <span className="contact-view-value" style={{ marginLeft: '-10px', marginTop: '10px' }}>
                      <span className="profile-image-container user-profile-image d-flex">
                        <span className="image-container">{profileURL ? <img src={profileURL} alt="Profile" /> : <span className="default-profile-icon"><AccountCircleIcon fontSize="inherit" /></span>}</span>
                      </span>
                    </span>
                }
              </div>
              <div className='contact-details w-25'>
                {renderLabel('Website Photo', 'websitePhoto', false, 'user')}
                {
                  editingField === 'websitePhoto' ?
                    <div className='d-flex' style={{ marginTop: '10px' }}>
                      <Tooltip placement={'right-end'} title="This is a waist up photo that will show on the website, in Synclink and in CandidateSuite" >
                        <label className="profile-image-container user-profile-image d-flex align-items-center justify-content-center edit" htmlFor="website-photo-input">
                          <Loader show={isWebsitePhotoUploading} />
                          <span className="image-container">{websitePhoto ? <img src={websitePhoto} alt="Website" /> : <span className="default-profile-icon"><AccountCircleIcon fontSize="inherit" /></span>}</span>
                          <input id="website-photo-input" type="file" accept="image/*" onClick={(e) => { e.target.value = null; setNewWebsitePhoto(null) }} onChange={onFileChange} hidden />
                          <div className="profile-pic-edit">
                            <EditIcon fontSize="inherit" />
                          </div>
                        </label>
                      </Tooltip>
                      {renderActions('websitePhoto', false)}
                    </div>
                    :
                    <span className="contact-view-value" style={{ marginTop: '10px' }}>
                      <span className="profile-image-container user-profile-image d-flex">
                        <span className="image-container">{websitePhoto ? <img src={websitePhoto} alt="Profile" /> : <span className="default-profile-icon"><AccountCircleIcon fontSize="inherit" /></span>}</span>
                      </span>
                    </span>
                }
              </div>
            </div>
            <ExpansionPanel className="w-100 m-0" expanded={minimizeQuery}>
              <ExpansionPanelSummary
                expandIcon={
                  <ExpandMoreIcon onClick={() => setMinimizeQuery(!minimizeQuery)} />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
                onClick={() => setMinimizeQuery(!minimizeQuery)}
                style={{ padding: '0px 20px', lineHeight: 1 }}
              >
                <div className="contact-profile-name">Job Details and Contact Information</div>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails style={{ padding: '0 0 0 20px' }} className="w-100">
                <div className='d-flex w-100'>
                  <div className='section-content flex-grow-1'>
                    <div className='d-flex contact-details-row w-100'>
                      <div className='contact-details'>
                        {renderLabel('First Name', 'first_name', editingField === 'first_name', 'user')}
                        {
                          editingField === 'first_name' ?
                            <div className='d-flex'>
                              <InputField
                                ref={register('first_name')}
                                value={watch('first_name') || null}
                                className="input-field"
                                onChange={(e) => {
                                  setValue('first_name', e.target.value)
                                }}
                                name={'first_name'}
                                InputLabelProps={{ focused: true }}
                              />
                              {renderActions('first_name', true)}
                            </div>
                            :
                            <span className="contact-view-value">{user.first_name || '--'}</span>
                        }
                      </div>
                      <div className='contact-details'>
                        {renderLabel('Last Name', 'last_name', editingField === 'last_name', 'user')}
                        {
                          editingField === 'last_name' ?
                            <div className='d-flex' >
                              <InputField
                                ref={register('last_name')}
                                value={watch('last_name') || null}
                                className="input-field"
                                onChange={(e) => {
                                  setValue('last_name', e.target.value)
                                }}
                                name={'last_name'}
                                InputLabelProps={{ focused: true }}
                              />
                              {renderActions('last_name', true)}
                            </div>
                            :
                            <span className="contact-view-value">{user.last_name || '--'}</span>
                        }
                      </div>
                      <div className='contact-details'>
                        {renderLabel('Company Name', 'company_name', false, 'user')}
                        {
                          editingField === 'company_name' ?
                            <div className='d-flex'>
                              <InputField
                                ref={register('company_name')}
                                value={watch('company_name') || null}
                                className="input-field"
                                onChange={(e) => {
                                  setValue('company_name', e.target.value)
                                }}
                                name={'company_name'}
                                InputLabelProps={{ focused: true }}
                              />
                              {renderActions('company_name', false)}
                            </div>
                            :
                            <span className="contact-view-value">{user.company_name || '--'}</span>
                        }
                      </div>
                      <div className='contact-details'>
                        {renderLabel('Title', 'title', editingField === 'title', 'user')}
                        {
                          editingField === 'title' ?
                            <div className='d-flex'>
                              <InputField
                                ref={register('title')}
                                value={watch('title') || null}
                                className="input-field"
                                onChange={(e) => {
                                  setValue('title', e.target.value)
                                }}
                                name={'title'}
                                InputLabelProps={{ focused: true }}
                              />
                              {renderActions('title', true)}
                            </div>
                            :
                            <span className="contact-view-value">{user.title || '--'}</span>
                        }
                      </div>
                    </div>
                    <div className={`d-flex contact-details-row ${(userData.isAdmin === true || userData.id === user.id) ? 'w-100' : 'w-50'}`}>
                      <div className='contact-details '>
                        <span className="contact-view-label mr-2">Email</span>
                        <span className="contact-view-value">
                          {user.email ? <a
                            href={`mailto:${user.email}`}
                            target={'noopener'}
                          >
                            {user.email}
                          </a> : '--'}
                        </span>
                      </div>
                      {(userData.isAdmin === true || userData.id === user.id) &&
                        <div className='contact-details'>
                          {renderLabel('Personal Email', 'personal_email', false, 'user')}
                          {
                            editingField === 'personal_email' ?
                              <div className='d-flex'>
                                <InputField
                                  ref={register('personal_email')}
                                  className="input-field"
                                  type="email"
                                  name={'personal_email'}
                                  value={watch('personal_email') || null}
                                  onChange={(e) => {
                                    setValue('personal_email', e.target.value)
                                  }}
                                  InputLabelProps={{ focused: true }}
                                />
                                {renderActions('personal_email', false)}
                              </div>
                              :
                              <span className="contact-view-value">
                                {user.personal_email ? <a
                                  href={`mailto:${user.personal_email}`}
                                  target={'noopener'}
                                >
                                  {user.personal_email}
                                </a> : '--'}
                              </span>
                          }
                        </div>}
                      {(userData.isAdmin === true || userData.id === user.id) && <div className='contact-details'>
                        {renderLabel('Location', 'location', false, 'user')}
                        {
                          editingField === 'location' ?
                            <div className='d-flex'>
                              {/* <InputField
                                ref={register('location')}
                                className="input-field"
                                name={'location'}
                                value={watch('location') || null}
                                onChange={(e) => {
                                  setValue('location', e.target.value)
                                }}
                                InputLabelProps={{ focused: true }}
                              /> */}
                              <LocationSelection
                                defaultValue={defaultValues.location}
                                ref={register('location')}
                                className="input-field"
                                name={'location'}
                                label=""
                                InputLabelProps={{ focused: true }}
                                onChange={(e, data) => {
                                  setValue('location', data?.name || '')
                                }}
                              />
                              {/* <CountrySelection
                                ref={register('country')}
                                defaultValue={defaultValues.country}
                                className="input-field"
                                style={{ width: "250px" }}
                                InputLabelProps={{ focused: true }}
                                label=""
                                onChange={(e, data) => {
                                  setValue('country', data.name)
                                }}
                              /> */}
                              {renderActions('location', false)}
                            </div>
                            :
                            <span className="contact-view-value">{user.location || '--'}</span>
                        }
                      </div>}
                      <div className='contact-details'>
                        {renderLabel('Work Phone', 'work_phone', false, 'user')}
                        {
                          editingField === 'work_phone' ?
                            <div className='d-flex'>
                              <ReactPhoneInput
                                ref={register('work_phone')}
                                placeholder='Phone Number'
                                value={watch('work_phone') || ''}
                                onChange={(data, c, e, value) => {
                                  if (data.length > 1) {
                                    setValue('work_phone', value)
                                  }
                                  else {
                                    setValue('work_phone', '')
                                  }
                                }}
                                enableSearch={true}
                                enableSearchField={true}
                                disableInitialCountryGuess={determineInitialCountryGuess(defaultValues.work_phone)}
                                inputProps={{
                                  InputLabelProps: { focused: true }
                                }}
                                component={TextField}
                              />
                              {renderActions('work_phone', false)}
                            </div>
                            :
                            <span className="contact-view-value">
                              {user.work_phone ?
                                <a href={`tel:${user.work_phone}`}> {user.work_phone}</a>
                                : '--'
                              }
                            </span>
                        }
                      </div>
                    </div>
                    <div className='d-flex contact-details-row w-100'>
                      <div className='contact-details'>
                        {renderLabel('Extension', 'extension', false, 'user')}
                        {
                          editingField === 'extension' ?
                            <div className='d-flex'>
                              <InputField
                                ref={register('extension')}
                                className="input-field"
                                name={'extension'}
                                InputLabelProps={{ focused: true }}
                                value={watch('extension') || null}
                                onChange={(e) => {
                                  setValue('extension', e.target.value)
                                }}
                              />
                              {renderActions('extension', false)}
                            </div>
                            :
                            <span className="contact-view-value">{user.extension || '--'}</span>
                        }
                      </div>
                      <div className='contact-details'>
                        {renderLabel('Mobile Phone', 'mobile_phone', false, 'user')}
                        {
                          editingField === 'mobile_phone' ?
                            <div className='d-flex'>
                              <ReactPhoneInput
                                ref={register('mobile_phone')}
                                placeholder='Phone Number'
                                value={watch('mobile_phone') || ''}
                                onChange={(data, c, e, value) => {
                                  if (data.length > 1) {
                                    setValue('mobile_phone', value)
                                  }
                                  else {
                                    setValue('mobile_phone', '')
                                  }
                                }}
                                enableSearchField={true}
                                enableSearch={true}
                                disableInitialCountryGuess={determineInitialCountryGuess(defaultValues.mobile_phone)}
                                inputProps={{
                                  InputLabelProps: { focused: true }
                                }}
                                component={TextField}
                              />
                              {renderActions('mobile_phone', false)}
                            </div>
                            :
                            <span className="contact-view-value">
                              {user.mobile_phone ?
                                <a href={`tel:${user.mobile_phone}`}> {user.mobile_phone}</a>
                                : '--'
                              }
                            </span>
                        }

                      </div>
                      <div className='contact-details'>
                        {renderLabel('LinkedIn URL', 'linkedin_url', false, 'user')}
                        {
                          editingField === 'linkedin_url' ?
                            <div className='d-flex'>
                              <InputField
                                ref={register('linkedin_url')}
                                className="input-field"
                                name={'linkedin_url'}
                                value={watch('linkedin_url') || null}
                                onChange={(e) => {
                                  setValue('linkedin_url', e.target.value)
                                }}
                                InputLabelProps={{ focused: true }}
                              />
                              {renderActions('linkedin_url', false)}
                            </div>
                            :
                            <span className="contact-view-value">
                              {user.linkedin_url ? <a href={addHttps(user.linkedin_url)}
                                rel='noopener noreferrer'
                                target="_blank">{tooltipValue(user.linkedin_url)}</a> : '--'}
                            </span>
                        }
                      </div>
                      <div className='contact-details'>
                        {renderLabel('Twitter Handle', 'twitter_handle', false, 'user')}
                        {
                          editingField === 'twitter_handle' ?
                            <div className='d-flex'>
                              <InputField
                                ref={register('twitter_handle')}
                                className="input-field"
                                name={'twitter_handle'}
                                InputLabelProps={{ focused: true }}
                                value={watch('twitter_handle') || null}
                                onChange={(e) => {
                                  setValue('twitter_handle', e.target.value)
                                }}
                              />
                              {renderActions('twitter_handle', false)}
                            </div>
                            :
                            <span className="contact-view-value">
                              {user.twitter_handle ? <a href={addHttps(user.twitter_handle)}
                                rel='noopener noreferrer'
                                target="_blank">{user.twitter_handle}</a> : '--'}
                            </span>
                        }

                      </div>
                    </div>
                    <div className='d-flex contact-details-row w-75'>
                      <div className='contact-details '>
                        {renderLabel('Hire Date', 'hiring_date', editingField === 'hiring_date', 'user')}
                        {
                          editingField === 'hiring_date' ?
                            <div className='d-flex'>
                              <KeyboardDatePicker
                                className="input-field"
                                ref={register('hiring_date')}
                                autoOk
                                label=""
                                required={true}
                                format="dd/MM/yyyy"
                                margin="normal"
                                value={formatDate(watch('hiring_date')) || null}
                                InputLabelProps={{ focused: true }}
                                placeholder="dd/mm/yyyy"
                                onChange={(date) => { setValue('hiring_date', formatDate(date)); }}
                              />                              {renderActions('hiring_date', true)}
                            </div>
                            :
                            <span className="contact-view-value">{formatDate(user.hiring_date) || '--'}</span>
                        }
                      </div>
                      <div className='contact-details '>
                        {renderLabel('Termination Date', 'termination_date', false, 'user')}
                        {
                          editingField === 'termination_date' ?
                            <div className='d-flex'>
                              <KeyboardDatePicker
                                autoOk
                                className="input-field"
                                ref={register('termination_date')}
                                label=""
                                format="dd/MM/yyyy"
                                value={formatDate(watch('termination_date')) || null}
                                InputLabelProps={{ focused: true }}
                                placeholder="dd/mm/yyyy"
                                onChange={(date) => { setValue('termination_date', formatDate(date)); }}
                              />
                              {renderActions('termination_date')}
                            </div>
                            :
                            <span className="contact-view-value">{getDate(watch('termination_date'))}</span>
                        }
                      </div>
                      <div className='contact-details '>
                        {renderLabel('Birth Date', 'birth_date', false, 'user')}
                        {
                          editingField === 'birth_date' ?
                            <div className='d-flex'>
                              <KeyboardDatePicker
                                className="input-field"
                                ref={register('birth_date')}
                                autoOk
                                label=""
                                required={false}
                                format="dd/MM/yyyy"
                                margin="normal"
                                value={formatDate(watch('birth_date')) || null}
                                InputLabelProps={{ focused: true }}
                                placeholder="dd/mm/yyyy"
                                onChange={(date) => { setValue('birth_date', formatDate(date)); }}
                              />                              {renderActions('birth_date', false)}
                            </div> :
                            <span className="contact-view-value">{getDate(watch('birth_date'), "DD MMM")}</span>

                        }
                      </div>
                    </div>
                  </div>
                </div>
              </ExpansionPanelDetails >
            </ExpansionPanel >
            <ExpansionPanel className="w-100 m-0">
              <ExpansionPanelSummary
                expandIcon={
                  <ExpandMoreIcon />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
                style={{ padding: '0px 20px', lineHeight: 1 }}
              >
                <div className="contact-profile-name">Location Details</div>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails style={{ padding: '0 0 0 20px' }} className="w-100">
                <div className='d-flex w-100'>
                  <div className='section-content flex-grow-1'>
                    <div className={`d-flex contact-details-row ${(userData.isAdmin === true || userData.id === user.id) ? 'w-100' : 'w-50'}`}>
                      {(userData.isAdmin === true || userData.id === user.id) &&
                        <div className='contact-details'>
                          {renderLabel('Country', 'country', false, 'user')}
                          {
                            editingField === 'country' ?
                              <div className='d-flex'>
                                <CountrySelection
                                  ref={register('country')}
                                  defaultValue={defaultValues.country}
                                  className="input-field"
                                  style={{ width: "250px" }}
                                  InputLabelProps={{ focused: true }}
                                  label=""
                                  onChange={(e, data) => {
                                    stateChange('')
                                    setValue('country', data ? data.name : "")
                                  }}
                                />
                                {renderActions('country', false)}
                              </div>
                              :
                              <span className="contact-view-value">{user.country || '--'}</span>
                          }
                        </div>}
                      <div className='contact-details'>
                        {renderLabel('Timezone', 'time_zone', false, 'user')}
                        {
                          editingField === 'time_zone' ?
                            <div className='d-flex'>
                              <TimezoneSelection
                                defaultValue={defaultValues.time_zone}
                                register={register('time_zone')}
                                style={{ width: '250px' }}
                                label=''
                                className="input-field"
                                onChange={(e, data) => {
                                  setValue("time_zone", data ? data.name : null);
                                }}
                                InputLabelProps={{ focused: true }}
                              />
                              {renderActions('time_zone', false)}
                            </div>
                            :
                            <span className="contact-view-value" style={{ width: '250px' }}>{user.time_zone || '--'}</span>
                        }

                      </div>
                      {(userData.isAdmin === true || userData.id === user.id) &&
                        <div className='contact-details'>
                          {renderLabel('Address', 'address', false, 'user')}
                          {
                            editingField === 'address' ?
                              <div className='d-flex'>
                                <TextArea
                                  ref={register('address')}
                                  className="input-field"
                                  name={'address'}
                                  InputLabelProps={{ focused: true }}
                                  value={watch('address') || null}
                                  onChange={(e) => {
                                    setValue('address', e.target.value)
                                  }}
                                />
                                {renderActions('address', false)}
                              </div>
                              :
                              <span className="contact-view-value">{user.address || '--'}</span>
                          }
                        </div>}
                      {(userData.isAdmin === true || userData.id === user.id) &&
                        <div className='contact-details'>
                          {renderLabel('City', 'city', false, 'user')}
                          {
                            editingField === 'city' ?
                              <div className='d-flex'>
                                <InputField
                                  ref={register('city')}
                                  className="input-field"
                                  name={'city'}
                                  value={watch('city') || null}
                                  onChange={(e) => {
                                    setValue('city', e.target.value)
                                  }}
                                  InputLabelProps={{ focused: true }}
                                />
                                {renderActions('city', false)}
                              </div>
                              :
                              <span className="contact-view-value">{user.city || '--'}</span>
                          }
                        </div>}
                    </div>
                    <div className={`d-flex contact-details-row w-75`}>
                      {(userData.isAdmin === true || userData.id === user.id) &&
                        <div className='contact-details'>
                          {renderLabel('State', 'state', false, 'user')}
                          {
                            editingField === 'state' ?
                              <div className='d-flex'>
                                {/* <InputField
                                  ref={register('state')}
                                  className="input-field"
                                  name={'state'}
                                  value={watch('state') || null}
                                  onChange={(e) => {
                                    setValue('state', e.target.value)
                                  }}
                                  InputLabelProps={{ focused: true }}
                                /> */}
                                <StateSelection
                                  value={watch('state') || null}
                                  className="input-field"
                                  defaultValue={user && user.state}
                                  InputLabelProps={{ focused: true }}
                                  label=""
                                  country={country}
                                  register={register('state')}
                                  onChange={(e, state) => {
                                    stateChange(state);
                                  }}
                                />
                                {renderActions('state', false)}
                              </div>
                              :
                              <span className="contact-view-value">{user.state || '--'}</span>
                          }
                        </div>}
                      {(userData.isAdmin === true || userData.id === user.id) &&
                        <div className='contact-details'>
                          {renderLabel('Postal Code', 'postal_code', false, 'user')}
                          {
                            editingField === 'postal_code' ?
                              <div className='d-flex'>
                                <InputField
                                  ref={register('postal_code')}
                                  className="input-field"
                                  name={'postal_code'}
                                  value={watch('postal_code') || null}
                                  onChange={(e) => {
                                    setValue('postal_code', e.target.value)
                                  }}
                                  InputLabelProps={{ focused: true }}
                                />
                                {renderActions('postal_code', false)}
                              </div>
                              :
                              <span className="contact-view-value">{user.postal_code || '--'}</span>
                          }
                        </div>}
                      {(userData.isAdmin === true || userData.id === user.id) &&
                        <div className='contact-details'>
                          {renderLabel('Street', 'street', false, 'user')}
                          {
                            editingField === 'street' ?
                              <div className='d-flex'>
                                <InputField
                                  ref={register('street')}
                                  className="input-field"
                                  name={'street'}
                                  value={watch('street') || null}
                                  onChange={(e) => {
                                    setValue('street', e.target.value)
                                  }}
                                  InputLabelProps={{ focused: true }}
                                />
                                {renderActions('street', true)}
                              </div>
                              :
                              <span className="contact-view-value">{user.street || '--'}</span>
                          }
                        </div>}
                    </div>
                  </div>
                </div>
              </ExpansionPanelDetails >
            </ExpansionPanel >
            <ExpansionPanel className="w-100 m-0">
              <ExpansionPanelSummary
                expandIcon={
                  <ExpandMoreIcon />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
                style={{ padding: '0px 20px', lineHeight: 1 }}
              >
                <div className="contact-profile-name">Department Info</div>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails style={{ padding: '0 0 0 20px' }} className="w-100">
                <div className='d-flex w-100'>
                  <div className='section-content flex-grow-1'>
                    <div className='d-flex contact-details-row'>
                      <div className='contact-details  w-25'>
                        {renderLabel('Department', 'departments')}
                        {
                          editingField === 'departments' ?
                            <div className='d-flex'>
                              <DepartmentSelection
                                ref={register('departments')}
                                multiple
                                defaultValue={defaultValues.departments || []}
                                disableCloseOnSelect
                                filterSelectedOptions={true}
                                style={{ width: '250px' }}
                                label=""
                                size='small'
                                className='input-field'
                                onChange={(e, data) =>
                                  setValue('departments', Array.isArray(data) ? data.map((item) => item.name) : null)
                                }
                                InputLabelProps={{ focused: true }}
                              />
                              {renderActions('departments', false)}
                            </div>
                            :
                            <span className="contact-view-value">{user.departments && user.departments.length > 0 ? user.departments.map((department, index) => (
                              <p key={index}>{department}</p>)) : '--'}</span>
                        }
                      </div>
                      <div className='contact-details  w-25 mr-4'>
                        {renderLabel('Secondary Department', 'secondary_departments')}
                        {
                          editingField === 'secondary_departments' ?
                            <div className='d-flex'>
                              <SecondaryDepartmentSelection
                                ref={register('secondary_departments')}
                                multiple
                                defaultValue={defaultValues.secondary_departments || []}
                                disableCloseOnSelect
                                filterSelectedOptions={true}
                                style={{ width: '250px' }}
                                size='small'
                                label=''
                                className='input-field'
                                onChange={(e, data) =>
                                  setValue('secondary_departments', Array.isArray(data) ? data.map((item) => item.name) : null)
                                }
                                InputLabelProps={{ focused: true }}
                              />
                              {renderActions('secondary_departments', false)}
                            </div>
                            :
                            <span className="contact-view-value">{user.secondary_departments && user.secondary_departments.length > 0 ? user.secondary_departments.map((secondary_department, index) => (
                              <p key={index}>{secondary_department}</p>)) : '--'}</span>
                        }
                      </div>
                      <div className='contact-details  w-25'>
                        {renderLabel('Region', 'region')}
                        {
                          editingField === 'region' ?
                            <div className='d-flex'>
                              <RegionSelection
                                defaultValue={defaultValues.region}
                                ref={register('region')}
                                className="input-field"
                                name={'region'}
                                label=""
                                InputLabelProps={{ focused: true }}
                                onChange={(e, data) => {
                                  setValue('region', data?.name || '')
                                }}
                              />
                              {renderActions('region', false)}
                            </div>
                            :
                            <span className="contact-view-value">{user.region || '--'}</span>
                        }

                      </div>
                      <div className='contact-details  w-25'>
                        {renderLabel('Vertical Practice', 'vertical_practice')}
                        {
                          editingField === 'vertical_practice' ?
                            <div className='d-flex'>
                              <PracticeSelection
                                defaultValue={defaultValues.vertical_practice}
                                register={register('vertical_practice')}
                                style={{ width: '250px' }}
                                className="input-field"
                                name={'vertical_practice'}
                                label=""
                                onChange={(e, data) =>
                                  setValue('vertical_practice', data ? data.name : null)
                                }
                                InputLabelProps={{ focused: true }}
                                type="vertical"
                              />
                              {renderActions('vertical_practice', false)}
                            </div>
                            :
                            <span className="contact-view-value">{user.vertical_practice || '--'}</span>
                        }

                      </div>
                    </div>
                    <div className='d-flex contact-details-row'>
                      <div className='contact-details  w-25 '>
                        {renderLabel('Vertical Practice 2', 'vertical_practice_2')}
                        {
                          editingField === 'vertical_practice_2' ?
                            <div className='d-flex'>
                              <PracticeSelection
                                defaultValue={defaultValues.vertical_practice_2}
                                register={register('vertical_practice_2')}
                                style={{ width: '250px' }}
                                className="input-field"
                                name={'vertical_practice_2'}
                                label=""
                                onChange={(e, data) =>
                                  setValue('vertical_practice_2', data ? data.name : null)
                                }
                                InputLabelProps={{ focused: true }}
                                type="vertical"
                              />
                              {renderActions('vertical_practice_2', false)}
                            </div>
                            :
                            <span className="contact-view-value">{user.vertical_practice_2 || '--'}</span>
                        }
                      </div>
                      <div className='contact-details  w-25 mr-4'>
                        {renderLabel('Horizontal Practice', 'horizontal_practice')}
                        {
                          editingField === 'horizontal_practice' ?
                            <div className='d-flex'>
                              <PracticeSelection
                                defaultValue={defaultValues.horizontal_practice}
                                register={register('horizontal_practice')}
                                className="input-field"
                                name={'horizontal_practice'}
                                label=""
                                placeholder={"Horizontal Practice"}
                                onChange={(e, data) =>
                                  setValue('horizontal_practice', data ? data.name : null)
                                }
                                InputLabelProps={{ focused: true }}
                                type="horizontal"
                              />
                              {renderActions('horizontal_practice', false)}
                            </div>
                            :
                            <span className="contact-view-value">{user.horizontal_practice || '--'}</span>
                        }
                      </div>
                      <div className='contact-details  w-25'>
                        {renderLabel('Work Group', 'work_group')}
                        {
                          editingField === 'work_group' ?
                            <div className='d-flex'>
                              <WorkgroupSelection
                                defaultValue={defaultValues.work_group}
                                register={register('work_group')}
                                style={{ width: '250px' }}
                                label=''
                                className="input-field"
                                onChange={(e, data) =>
                                  setValue('work_group', data ? data.name : null)
                                }
                                InputLabelProps={{ focused: true }}
                              />
                              {renderActions('work_group', false)}
                            </div>
                            :
                            <span className="contact-view-value">{user.work_group || '--'}</span>
                        }
                      </div>
                      <div className='contact-details  w-25'>
                        {renderLabel('Revenue Budget', 'revenue_budget')}
                        {
                          editingField === 'revenue_budget' ?
                            <div className='d-flex'>
                              <InputField
                                ref={register('revenue_budget')}
                                className="input-field"
                                name={'revenue_budget'}
                                value={watch('revenue_budget') || null}
                                onChange={(e) => {
                                  setValue('revenue_budget', e.target.value === '' ? null : e.target.value)
                                }}
                                InputLabelProps={{ focused: true }}
                              />
                              {renderActions('revenue_budget', false)}
                            </div>
                            :
                            <span className="contact-view-value">{user.revenue_budget || '--'}</span>
                        }
                      </div>
                    </div>
                    <div className='d-flex contact-details-row'>
                      <div className='contact-details  w-25'>
                        {renderLabel('Capacity', 'capacity')}
                        {
                          editingField === 'capacity' ?
                            <div className='d-flex'>
                              <InputField
                                ref={register('capacity')}
                                className="input-field"
                                name={'capacity'}
                                value={watch('capacity') || null}
                                onChange={(e) => {
                                  setValue('capacity', e.target.value === "" ? null : e.target.value)
                                }}
                                InputLabelProps={{ focused: true }}
                              />
                              {renderActions('capacity', false)}
                            </div>
                            :
                            <span className="contact-view-value">{user.capacity || '--'}</span>
                        }
                      </div>
                      <div className='contact-details  w-25 mr-4'>
                        {renderLabel('Project Managers', 'project_manager')}
                        {
                          editingField === 'project_manager' ?
                            <div className='d-flex'>
                              <UserSelection
                                multiple
                                InputLabelProps={{ focused: true, shrink: true }}
                                style={{ width: '250px' }}
                                ref={register('project_manager')}
                                filterSelectedOptions={true}
                                defaultValue={watch('project_manager') || null}
                                className='input-field'
                                label=''
                                placeholder='Project Managers'
                                name='project_manager'
                                size='small'
                                onChange={(e, data) =>
                                  setValue('project_manager', Array.isArray(data) ? data : null)
                                }
                              />
                              {renderActions('project_manager', false)}
                            </div>
                            :
                            <span className="contact-view-value">{user.project_manager && user.project_manager.length > 0 ? showUsers() : '--'}</span>
                        }
                      </div>
                      <div className='contact-details  w-25'>
                        {renderLabel('Referred By', 'reference_user')}
                        {
                          editingField === 'reference_user' ?
                            <div className='d-flex'>
                              <Reference
                                className='input-field'
                                ref={register('reference_user')}
                                defaultValue={watch('reference_user') || null}
                                label=""
                                name='reference_user'
                                required={true}
                                InputLabelProps={{ focused: true }}
                                onChange={(e, data) => setValue('reference_user', data ? data : null)}
                              />
                              {renderActions('reference_user', false)}
                            </div>
                            :
                            <span className="contact-view-value">{user.reference_user && user.reference_user.name ? user.reference_user.name : '--'}</span>
                        }
                      </div>
                      <div className="contact-details w-25">
                        {renderLabel('KG Employee', 'kg_employee')}
                        {
                          editingField === 'kg_employee' ?
                            <div className='d-flex'>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    size="small"
                                    defaultChecked={user['kg_employee'] ? user['kg_employee'] : false}
                                    ref={register('kg_employee')}
                                    onChange={(data) => setValue('kg_employee', data.target.checked)}
                                  />
                                }
                                label={watch('kg_employee') ? 'Yes' : 'No'}
                              />
                              {renderActions('kg_employee')}
                            </div>
                            :
                            <div className="contact-view-value">{user.kg_employee != null ? user.kg_employee ? 'Yes' : 'No' : '--'}</div>
                        }
                      </div>
                      {/* <div className='contact-details  w-25'>
                        {renderLabel('Role', 'roles', editingField === 'roles')}
                        {
                          editingField === 'roles' ?
                            <div className='d-flex'>
                              <RoleSelection
                                multiple
                                ref={register('roles')}
                                className='input-field'
                                style={{ width: '250px' }}
                                defaultValue={watch('roles') || []}
                                label=""
                                required={true}
                                InputLabelProps={{ focused: true }}
                                onChange={(e, data) => setValue('roles', data)}
                              />
                              {renderActions('roles', true)}
                            </div>
                            :
                            <span className="contact-view-value">{user.roles && user.roles.length > 0 ? user.roles.map((role, index) => (
                              <p key={index}>{capitalize(role)}</p>)) : '--'}</span>
                        }
                      </div> */}
                    </div>
                    <div className='d-flex contact-details-row w-50'>
                      {/* <div className="contact-details w-25">
                        {renderLabel('KG Employee', 'kg_employee')}
                        {
                          editingField === 'kg_employee' ?
                            <div className='d-flex'>
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    size="small"
                                    defaultChecked={user['kg_employee'] ? user['kg_employee'] : false}
                                    ref={register('kg_employee')}
                                    onChange={(data) => setValue('kg_employee', data.target.checked)}
                                  />
                                }
                                label={watch('kg_employee') ? 'Yes' : 'No'}
                              />
                              {renderActions('kg_employee')}
                            </div>
                            :
                            <div className="contact-view-value">{user.kg_employee != null ? user.kg_employee ? 'Yes' : 'No' : '--'}</div>
                        }
                      </div> */}
                      <div className='contact-details  w-25'>
                        {renderLabel('Role', 'roles', editingField === 'roles')}
                        {
                          editingField === 'roles' ?
                            <div className='d-flex'>
                              <RoleSelection
                                ref={register('roles')}
                                className='input-field'
                                style={{ width: '250px' }}
                                defaultValue={Array.isArray(user.roles) ? user.roles[0] : null}
                                label=""
                                required={true}
                                InputLabelProps={{ focused: true }}
                                onChange={(e, data) => setValue('roles', data)}
                              />
                              {renderActions('roles', true)}
                            </div>
                            :
                            <span className="contact-view-value">{user.roles && user.roles.length > 0 ? user.roles.map((role, index) => (
                              <p key={index}>{capitalize(role)}</p>)) : '--'}</span>
                        }
                      </div>
                      <div className='contact-details  w-25'>
                        {renderLabel('App Role', 'app_roles')}
                        {
                          editingField === 'app_roles' ?
                            <div className='d-flex'>
                              <AppRoleSelection
                                multiple
                                ref={register('app_roles')}
                                className='input-field'
                                style={{ width: '250px' }}
                                defaultValue={user.app_roles}
                                list={watch("app_roles")}
                                label=""
                                // required={true}
                                InputLabelProps={{ focused: true }}
                                onChange={(e, data) => setValue('app_roles', data)}
                              />
                              {renderActions('app_roles', true)}
                            </div>
                            :
                            <span className="contact-view-value">{user.app_roles && user.app_roles.length > 0 ? user.app_roles.map((role, index) => (
                              <p key={index}>{capitalize(role.name)}</p>)) : '--'}</span>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </ExpansionPanelDetails >
            </ExpansionPanel >
            <ExpansionPanel className="w-100 m-0">
              <ExpansionPanelSummary
                expandIcon={
                  <ExpandMoreIcon />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
                style={{ padding: '0px 20px', lineHeight: 1 }}
              >
                <div className="contact-profile-name">Website</div>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails style={{ padding: '0 0 0 20px' }} className="w-100">
                <div className='d-flex w-100'>
                  <div className='section-content flex-grow-1'>
                    <div className='d-flex contact-details-row w-75'>
                      <div className='contact-details w-25'>
                        {renderLabel('Exclude from Website', 'is_exclude_from_website')}
                        {
                          editingField === 'is_exclude_from_website' ?
                            <div>
                              <FormControlLabel
                                style={{ color: '#5c52c6', marginTop: '20px', maxWidth: '31%' }}
                                control={<Switch checked={watch('is_exclude_from_website') || false} onChange={(e) => { setValue('is_exclude_from_website', e.target.checked); }} color='primary' inputRef={register('is_exclude_from_website')} name={'is_exclude_from_website'} />}
                                className='input-field'
                              />
                              {renderActions('is_exclude_from_website', false)}
                            </div>
                            :
                            <span className="contact-view-value">{user.is_exclude_from_website != null ? user.is_exclude_from_website ? 'Yes' : 'No' : '--'}</span>
                        }
                      </div>
                      <div className='contact-details w-25'>
                        {renderLabel('URL', 'website_url')}
                        {
                          editingField === 'website_url' ?
                            <div className='d-flex'>
                              <InputField
                                ref={register('website_url')}
                                className="input-field"
                                name={'website_url'}
                                value={watch('website_url') || null}
                                onChange={(e) => {
                                  setValue('website_url', e.target.value)
                                }}
                                InputLabelProps={{ focused: true }}
                              />
                              {renderActions('website_url', false)}
                            </div>
                            :
                            <span className="contact-view-value">
                              {user.website_url ? <a href={addHttps(user.website_url)}
                                rel='noopener noreferrer'
                                target="_blank">{user.website_url}</a> : '--'}
                            </span>
                        }
                      </div>
                      <div className='contact-details w-25'>
                        {renderLabel('KG Bio', 'bio')}
                        {
                          editingField === 'bio' ?
                            <div className='d-flex'>
                              <div className='outlined-border transform p-0'>
                                <RichTextPopup
                                  ref={register('bio')}
                                  onChange={(data) => {
                                    setValue(`bio`, data);
                                  }}
                                  placeholder={"Enter KG Bio"}
                                  title="KG Bio"
                                  style={{ width: '250px' }}
                                  name={'bio'}
                                  value={user.bio || ''}
                                  onSave={autoSave}
                                  autoSave={autoSave}
                                  saveStatus={isLastSaveSuccess}
                                  InputLabelProps={{ focused: true }}
                                  updatedDate={user.updated_at}
                                  spfaSkillFlag={true}
                                />
                              </div>
                              {renderActions('bio')}
                            </div>
                            :
                            <span className="contact-view-value">
                              <RichTextPopup
                                StrengthsAndPotentialIssues={true}
                                defaultValue={user.bio}
                                setOpen={false}
                                value={user.bio || '--'}
                                placeholder=''
                                spfaSkillFlag={true}
                              />
                            </span>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </ExpansionPanelDetails >
            </ExpansionPanel >
          </div >
        </div >
      </div >
      {/* <div className={`company-view-content company-details-section d-flex `}> */}
      {/* <div className="d-flex flex-column left-section"> */}
      {/* <div className={`company-details d-flex`}> */}
      {/* {renderLabel('Project Managers', 'project_manager', editingField === 'project_manager')}
            {
              editingField === 'project_manager' ?
                <>
                  <UserSelection
                    multiple
                    InputLabelProps={{ focused: true, shrink: true }}
                    style={{ width: "250px" }}
                    ref={register('project_manager')}
                    filterSelectedOptions={true}
                    defaultValue={watch('project_manager') || null}
                    className='input-field'
                    name='project_manager'
                    size='small'
                    onChange={(e, data) =>
                      setValue('project_manager', Array.isArray(data) ? data : null)
                    }
                  />
                  {renderActions('project_manager', true)}
                </>
                :
                <span className="contact-view-value">{user.project_manager && user.project_manager.length > 0 ? showUsers() : ''}</span>
            } */}
      {/* </div> */}
      {/* <div className={`company-details d-flex`}>
            <span className="contact-view-label mr-2">Skype ID</span>
            <span className="contact-view-value">{user.skype_id || ''}</span>
          </div> */}
      {/* <div className={`company-details d-flex`}>
            <span className="contact-view-label mr-2">Birth Date</span>
            <span className="contact-view-value">{formatDate(user.birth_date) || ''}</span>
          </div> */}
      {/* <div className={`company-details d-flex`}> */}
      {/* <span className="contact-view-label mr-2">Country</span>
            <span className="contact-view-value">{user.country || ''}</span> */}
      {/* </div> */}
      {/* <div className={`company-details d-flex`}>
              {renderLabel('Timezone', 'time_zone', editingField === 'time_zone')}
              {
                editingField === 'time_zone' ?
                  <>
                    <TimezoneSelection
                      defaultValue={defaultValues.time_zone}
                      register={register}
                      style={{ width: '250px' }}
                      className="input-field"
                      InputLabelProps={{ focused: true }}
                    />
                    {renderActions('time_zone', true)}
                  </>
                  :
                  <span className="contact-view-value">{user.time_zone || ''}</span>
              }
            </div> */}
      {/* <div className={`company-details d-flex`}>
              {renderLabel('State', 'state', editingField === 'state')}
              {
                editingField === 'state' ?
                  <>
                    <InputField
                      ref={register('state')}
                      className="input-field"
                      name={'state'}
                      value={watch('state') || null}
                      onChange={(e) => {
                        setValue('state', e.target.value)
                      }}
                      InputLabelProps={{ focused: true }}
                    />
                    {renderActions('state', true)}
                  </>
                  :
                  <span className="contact-view-value">{user.state || ''}</span>
              }
            </div> */}
      {/* <div className={`company-details d-flex`}>
              {renderLabel('City', 'city', editingField === 'city')}
              {
                editingField === 'city' ?
                  <>
                    <InputField
                      ref={register('city')}
                      className="input-field"
                      name={'city'}
                      value={watch('city') || null}
                      onChange={(e) => {
                        setValue('city', e.target.value)
                      }}
                      InputLabelProps={{ focused: true }}
                    />
                    {renderActions('city', true)}
                  </>
                  :
                  <span className="contact-view-value">{user.city || ''}</span>
              }
            </div> */}
      {/* <div className={`company-details d-flex`}>
              {renderLabel('Postal Code', 'postal_code', editingField === 'postal_code')}
              {
                editingField === 'postal_code' ?
                  <>
                    <InputField
                      ref={register('postal_code')}
                      className="input-field"
                      name={'postal_code'}
                      value={watch('postal_code') || null}
                      onChange={(e) => {
                        setValue('postal_code', e.target.value)
                      }}
                      InputLabelProps={{ focused: true }}
                    />
                    {renderActions('postal_code', true)}
                  </>
                  :
                  <span className="contact-view-value">{user.postal_code || ''}</span>
              }
            </div> */}
      {/* <div className={`company-details d-flex`}>
              {renderLabel('Address', 'address', editingField === 'address')}
              {
                editingField === 'address' ?
                  <>
                    <TextArea
                      ref={register('address')}
                      className="input-field"
                      name={'address'}
                      InputLabelProps={{ focused: true }}
                      value={watch('address') || null}
                      onChange={(e) => {
                        setValue('address', e.target.value)
                      }}
                    />
                    {renderActions('address', true)}
                  </>
                  :
                  <TextArea
                    className="contact-view-value"
                    value={user.address || ''}
                    InputLabelProps={{ focused: false }}
                  />
              }
            </div> */}
      {/* </div> */}
      {/* <div className="d-flex flex-column right-section"> */}
      {/* <div className={`company-details d-flex  align-items-center`}>
            {renderLabel('Website Photo', 'websitePhoto', editingField === 'websitePhoto')}
            {
              editingField === 'websitePhoto' ?
                <>
                  <Tooltip placement={'right-end'} title="This is a waist up photo that will show on the website, in Synclink and in CandidateSuite" >
                    <label className="profile-image-container user-profile-image d-flex align-items-center justify-content-center edit ml-5" htmlFor="website-photo-input">
                      <Loader show={isWebsitePhotoUploading} />
                      <span className="image-container">{websitePhoto ? <img src={websitePhoto} alt="Website Photo" /> : <span className="default-profile-icon"><AccountCircleIcon fontSize="inherit" /></span>}</span>
                      <input id="website-photo-input" type="file" accept="image/*" onClick={(e) => { e.target.value = null; setNewWebsitePhoto(null) }} onChange={onFileChange} hidden />
                      <div className="profile-pic-edit">
                        <EditIcon fontSize="inherit" />
                      </div>
                    </label>
                  </Tooltip>
                  {renderActions('websitePhoto', true)}
                </>
                :
                <span className="contact-view-value">
                  <span className="profile-image-container user-profile-image d-flex">
                    <span className="image-container">{websitePhoto ? <img src={websitePhoto} alt="Profile" /> : <span className="default-profile-icon"><AccountCircleIcon fontSize="inherit" /></span>}</span>
                  </span>
                </span>
            }
          </div> */}
      {/* <div className={`company-details d-flex`}>
              <span className="contact-view-label mr-2">Active</span>
              <span className="contact-view-value">{user.is_active != null ? user.is_active ? 'Yes' : 'No' : ''}</span>
            </div> */}
      {/* <div className={`company-details d-flex`}>
              <span className="contact-view-label mr-2">Practice</span>
              <span className="contact-view-value">{user.practice}</span>
            </div>
            <div className={`company-details d-flex`}>
              <span className="contact-view-label mr-2">Practice 2</span>
              <span className="contact-view-value">{user.practice_2}</span>
            </div> */}
      {/* <div className={`company-details d-flex`}>
              {renderLabel('Region', 'region', editingField === 'region')}
              {
                editingField === 'region' ?
                  <>
                    <RegionSelection
                      defaultValue={defaultValues.region}
                      register={register}
                      className="input-field"
                      name={'region'}
                      label="Region"
                      InputLabelProps={{ focused: true }}
                    />
                    {renderActions('region', true)}
                  </>
                  :
                  <span className="contact-view-value">{user.region || ''}</span>
              }
              <span className="contact-view-label mr-2">Region</span>
              <span className="contact-view-value">{user.region}</span>
            </div> */}
      {/* <div className={`company-details d-flex`}> */}
      {/* {renderLabel('Department', 'departments', editingField === 'departments')}
            {
              editingField === 'departments' ?
                <>
                  <DepartmentSelection
                    ref={register('departments')}
                    multiple
                    defaultValue={defaultValues.departments || []}
                    disableCloseOnSelect
                    filterSelectedOptions={true}
                    style={{ width: '250px' }}
                    size='small'
                    className='input-field'
                    onChange={(e, data) =>
                      setValue('departments', Array.isArray(data) ? data.map((item) => item.name) : null)
                    }
                    InputLabelProps={{ focused: true }}
                  />
                  {renderActions('departments', true)}
                </>
                :
                <span className="contact-view-value">{user.departments && user.departments.length > 0 ? user.departments.map((department, index) => (
                  <p key={index}>{department}</p>)) : ''}</span>
            } */}
      {/* <span className="contact-view-label mr-2">Department</span>
              <span className="contact-view-value">{user.departments && user.departments.length > 0 ? user.departments.map((department, index) => (
                <p key={index}>{department}</p>)) : ''}</span> */}
      {/* </div> */}
      {/* <div className={`company-details d-flex`}> */}
      {/* {renderLabel('Secondary Department', 'secondary_departments', editingField === 'secondary_departments')}
            {
              editingField === 'secondary_departments' ?
                <>
                  <DepartmentSelection
                    ref={register('secondary_departments')}
                    multiple
                    defaultValue={defaultValues.secondary_departments || []}
                    disableCloseOnSelect
                    filterSelectedOptions={true}
                    style={{ width: '250px' }}
                    size='small'
                    className='input-field'
                    onChange={(e, data) =>
                      setValue('secondary_departments', Array.isArray(data) ? data.map((item) => item.name) : null)
                    }
                    InputLabelProps={{ focused: true }}
                  />
                  {renderActions('secondary_departments', true)}
                </>
                :
                <span className="contact-view-value">{user.secondary_departments && user.secondary_departments.length > 0 ? user.secondary_departments.map((secondary_department, index) => (
                  <p key={index}>{secondary_department}</p>)) : ''}</span>
            } */}
      {/* </div>
          <div className={`company-details d-flex`}> */}
      {/* {renderLabel('Practice', 'practice', editingField === 'practice')}
            {
              editingField === 'practice' ?
                <>
                  <PracticeSelection
                    defaultValue={defaultValues.practice}
                    register={register}
                    style={{ width: '250px' }}
                    className="input-field"
                    name={'practice'}
                    label="Practice"
                    InputLabelProps={{ focused: true }}
                  />
                  {renderActions('practice', true)}
                </>
                :
                <span className="contact-view-value">{user.practice}</span>
            } */}
      {/* </div>
          <div className={`company-details d-flex`}> */}
      {/* {renderLabel('Practice 2', 'practice_2', editingField === 'practice_2')}
            {
              editingField === 'practice_2' ?
                <>
                  <PracticeSelection
                    defaultValue={defaultValues.practice_2}
                    register={register}
                    className="input-field"
                    name={'practice_2'}
                    label="Secondary Practice"
                    placeholder={"Secondary Practice"}
                    InputLabelProps={{ focused: true }}
                  />
                  {renderActions('practice_2', true)}
                </>
                :
                <span className="contact-view-value">{user.practice_2}</span>
            } */}
      {/* </div>
          <div className={`company-details d-flex`}> */}
      {/* {renderLabel('Speciality', 'specialities', editingField === 'specialities')}
            {
              editingField === 'specialities' ?
                <>
                  <SpecialitySelection
                    register={register('specialities')}
                    multiple
                    disableCloseOnSelect
                    style={{ width: '250px' }}
                    defaultValue={defaultValues.specialities || []}
                    size='small'
                    filterSelectedOptions={true}
                    className='input-field'
                    onChange={(e, data) =>
                      setValue('specialities', Array.isArray(data) ? data.map((item) => item.name) : null)
                    }
                    InputLabelProps={{ focused: true }}
                  />
                  {renderActions('specialities', true)}
                </>
                :
                <span className="contact-view-value">{user.specialities && user.specialities.length > 0 ? user.specialities.map((speciality, index) => (
                  <p key={index}>{speciality}</p>)) : ''}</span>
            } */}
      {/* </div>
          <div className={`company-details d-flex`}> */}
      {/* {renderLabel('Work Group', 'work_group', editingField === 'work_group')}
            {
              editingField === 'work_group' ?
                <>
                  <WorkgroupSelection
                    defaultValue={defaultValues.work_group}
                    register={register}
                    style={{ width: '250px' }}
                    className="input-field"
                    InputLabelProps={{ focused: true }}
                  />
                  {renderActions('work_group', true)}
                </>
                :
                <span className="contact-view-value">{user.work_group || ''}</span>
            } */}
      {/* </div>
          <div className={`company-details d-flex`}> */}
      {/* <span className="contact-view-label mr-2">Revenue Budget</span>
            <span className="contact-view-value">{user.revenue_budget || ''}</span> */}
      {/* </div>
          <div className={`company-details d-flex`}> */}
      {/* {renderLabel('Capacity', 'capacity', editingField === 'capacity')}
            {
              editingField === 'capacity' ?
                <>
                  <InputField
                    ref={register('capacity')}
                    className="input-field"
                    name={'capacity'}
                    value={watch('capacity') || null}
                    onChange={(e) => {
                      setValue('capacity', e.target.value)
                    }}
                    InputLabelProps={{ focused: true }}
                  />
                  {renderActions('capacity', true)}
                </>
                :
                <span className="contact-view-value">{user.capacity || ''}</span>
            } */}
      {/* </div> */}
      {/* <div className={`company-details d-flex`}>
              <span className="contact-view-label mr-2">KG Number</span>
              <span className="contact-view-value">{user.kg_number || ''}</span>
            </div> */}
      {/* <div className={`company-details d-flex`}> */}
      {/* <span className="contact-view-label mr-2">Referred By</span>
            <span className="contact-view-value">{user.reference_user && user.reference_user.name ? user.reference_user.name : ''}</span> */}
      {/* </div>
          <div className={`company-details d-flex`}> */}
      {/* {renderLabel('Role', 'roles', editingField === 'roles')}
            {
              editingField === 'roles' ?
                <>
                  <RoleSelection
                    ref={register('roles')}
                    className='input-field'
                    style={{ width: '250px' }}
                    defaultValue={Array.isArray(user.roles) ? user.roles[0] : null}
                    label="Role"
                    required={true}
                    InputLabelProps={{ focused: true }}
                    onChange={(e, data) => setValue('roles', data)}
                  />
                  {renderActions('roles', true)}
                </>
                :
                <span className="contact-view-value">{user.roles && user.roles.length > 0 ? user.roles.map((role, index) => (
                  <p key={index}>{role}</p>)) : ''}</span>
            } */}
      {/* </div>
          <div className={`company-details d-flex`}> */}
      {/* {renderLabel('Exclude from Website', 'is_exclude_from_website', editingField === 'is_exclude_from_website')}
            {
              editingField === 'is_exclude_from_website' ?
                <>
                  <FormControlLabel
                    style={{ color: '#5c52c6', marginTop: '20px', maxWidth: '31%' }}
                    control={<Switch checked={watch('is_exclude_from_website') || false} onChange={(e) => { setValue('is_exclude_from_website', e.target.checked); }} color='primary' inputRef={register('is_exclude_from_website')} name={'is_exclude_from_website'} />}
                    label="Exclude from Website"
                    className='input-field'
                  />
                  {renderActions('is_exclude_from_website', false)}
                </>
                :
                <span className="contact-view-value">{user.is_exclude_from_website != null ? user.is_exclude_from_website ? 'Yes' : 'No' : ''}</span>
            } */}
      {/* </div> */}
      {/* <div className={`company-details d-flex`}>
              <span className="contact-view-label mr-2">Website Tag</span>
              <span className="contact-view-value">{user.website_tag || ''}</span>
            </div> */}
      {/* <div className={`company-details d-flex`}> */}
      {/* {renderLabel('KG Bio', 'bio', editingField === 'bio')}
            {
              editingField === 'bio' ?
                <>
                  <RichTextPopup
                    ref={register('bio')}
                    onChange={(data) => {
                      setValue(`bio`, data);
                    }}
                    placeholder={"Enter KG Bio"}
                    title="KG Bio"
                    style={{ width: '250px' }}
                    name={'bio'}
                    value={user.bio || ''}
                    saveStatus={true}
                    InputLabelProps={{ focused: true }}
                    updatedDate={user.updated_at}
                  />
                  {renderActions('bio', true)}
                </>
                :
                <span className="contact-view-value">
                  <RichTextPopup
                    StrengthsAndPotentialIssues={true}
                    defaultValue={user.bio}
                    setOpen={false}
                    value={user.bio || ''}
                  />
                </span>
            } */}
      {/* </div> */}
      {/* <div className={`company-details d-flex`}>
              <span className="contact-view-label mr-2">Created At</span>
              <span className="contact-view-value">{formatDate(user.created_at) || ''}</span>
            </div>
            <div className={`company-details d-flex`}>
              <span className="contact-view-label mr-2">Created By</span>
              <span className="contact-view-value">{user.created_user ? user.created_user.name : ''}</span>
            </div>
            <div className={`company-details d-flex`}>
              <span className="contact-view-label mr-2">Updated At</span>
              <span className="contact-view-value">{formatDate(user.updated_at) || ''}</span>
            </div>
            <div className={`company-details d-flex`}>
              <span className="contact-view-label mr-2">Updated By</span>
              <span className="contact-view-value">{user.updated_user ? user.updated_user.name : ''}</span>
            </div> */}
      {/* </div>
      </div> */}
      <div className="actions">
        {(userData.isAdmin === true || userData.id === user.id) && <div className="d-flex align-items-center action-edit" onClick={handleEdit} >
          <span className="action-icon mr-2" style={{ color: "rgba(0, 0, 0, 0.54)", fontSize: 18 }}>
            <EditIcon fontSize="inherit" />
          </span>
          <span>Edit Details</span>
        </div>}
      </div>
    </>
  )
}

View.propTypes = {
  user: PropTypes.object,
  setIsEditing: PropTypes.func,
  isEditing: PropTypes.bool,
  setUser: PropTypes.func,
  userUpdate: PropTypes.func,
  setIsDataUpdated: PropTypes.func,
  getUser: PropTypes.func
}

export default View