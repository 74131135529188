//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useRef } from 'react'

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from 'prop-types'

//-----------------------------------------------------------// Internal Imports // ------------------------------

import Attachments from '../../Attachments';
import { contactActions } from '../../../store/contactSlice';
import { useDispatch, useSelector } from 'react-redux';

const ContactAttachments = (props) => {

  const { contactId, disabled = false } = props;
  const contactAttachments = useSelector(state => state.contactReducer.contactAttachments)
  const dispatch = useDispatch();
  const attachmentsRef = useRef();

  const contactDispatch = (data) => {
    dispatch(contactActions.updateContactAttachments(data))
  }

  return (
    <div className="section-container mb-5">
      <div className="section-header">Attachments</div>
      <Attachments disabled={disabled} dispatchDetails={contactDispatch} attachmentsDetails={contactAttachments} ref={attachmentsRef} apiConfig={{ parentId: contactId, parentPath: "contact" }} className="contact-attachments" />
      <button className="primary-btn" disabled={disabled} onClick={() => attachmentsRef.current.handleAddAttachment()}>Upload</button>
    </div>
  )
}

ContactAttachments.propTypes = {
  contactId: PropTypes.string,
  disabled: PropTypes.bool
}

export default ContactAttachments;
