
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from "react";

//-----------------------------------------------------------// External Imports // ------------------------------

import { CircularProgress } from "@material-ui/core";
import { Link } from "react-router-dom";
import { Typography } from "@material-ui/core";

//-----------------------------------------------------------// Internal Imports // ------------------------------

import { getFilterQuery, getDateFilterOptions } from '../../Containers/Commons/Utils';
import CountrySelection from "../../components/CountrySelection";
import IndustrySelection from "../../components/IndustrySelection";
export const PAGE_LIMIT = 200;
export const SUCCESS_STATUS_CODE = 200;

export const getFilterParamString = (data, prefix, AdvancedQuery = false) => {
  const queryAliases = { job_title: 'title'/* 'job_title' */, job_number: /* 'job_number' */'jobNumber' };
  const queryValueTypes = {
    job_functions: { isValueType: true },
    country: { field: 'name' },
    industries: { field: 'name' },
    stage: { field: 'name' },
    work_group: { field: 'name' },
    result: { field: 'name' }
  };
  return getFilterQuery(data, queryAliases, queryValueTypes, prefix, AdvancedQuery);
}

export const columnDefs = [
  {
    field: "job_number",
    colId: 'job_number',
    headerName: "Job Number",
    pinned: "left",
    lockPinned: true,
    cellRenderer: "IdRenderer",
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['equal to'],
      buttons: ['reset', 'apply'],
      suppressAndOrCondition: true,
      closeOnApply: true
    },
  },
  {
    field: "job_title",
    colId: 'job_title',
    headerName: "Search Title",
    cellRenderer: "IdRenderer",
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      buttons: ['reset', 'apply'],
      suppressAndOrCondition: true,
      closeOnApply: true
    },
  },
  {
    field: "country",
    colId: 'country',
    headerName: "Location",
    filter: 'CustomFilter',
    filterParams: {
      Component: CountrySelection,
      multiple: true
    }
  },
  {
    field: "company.website",
    colId: 'company.website',
    headerName: "Website",
    sortable: false
  },
  {
    field: "industries",
    colId: 'industries',
    headerName: "Industry",
    filter: 'CustomFilter',
    filterParams: {
      Component: IndustrySelection,
      multiple: true
    }
  },
  {
    field: "start_date",
    colId: 'start_date',
    headerName: "Start Date",
    cellRenderer: "DateRenderer",
    filter: 'agDateColumnFilter',
    filterParams: {
      filterOptions: getDateFilterOptions(),
      buttons: ['reset', 'apply'],
      suppressAndOrCondition: true,
      closeOnApply: true
    }
  },
  {
    field: "close_date",
    colId: 'close_date',
    headerName: "End Date",
    cellRenderer: "DateRenderer",
    filter: 'agDateColumnFilter',
    filterParams: {
      filterOptions: getDateFilterOptions(),
      buttons: ['reset', 'apply'],
      suppressAndOrCondition: true,
      closeOnApply: true
    }
  },
];

export const CustomLoadingOverlayComponent = () => {
  return <CircularProgress />;
};

export const IdRenderer = (params) => {
  return (
    <Link to={`/searches/${params?.data?.id}/active-candidates`} target="_blank" rel='noopener noreferrer'>
      <Typography
        className="text-link"
        color="primary"
      >
        {params.value}
      </Typography>
    </Link>
  );
};
