//----------------------------------------------// In-built Imports // -------------------------------------------------
import React from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import Grid from "@material-ui/core/Grid";

//----------------------------------------------// Internal Imports // -------------------------------------------------
import TargetDetails from './TargetDetails';
import TargetCompanies from './TargetCompanies'
import TargetCompensation from './TargetCompensation'
import TargetEducationandExperience from './TargetEducationandExperience'
import TargetOffLimits from "./TargetOffLimits";
import RepresentativeTargets from "./RepresentativeTargets";
import HeliaDetails from "./HeliaDetails";

export default function Targets(props) {
  const { register, unregister, watch, setValue, currentValues, currencyDetails, readOnly } = props;

  return (
    <div className="input-form target-section">
      <Grid container spacing={3}>
        <Grid item xs={9}>
          <HeliaDetails readOnly={readOnly} register={register} unregister={unregister} watch={watch} setValue={setValue} />
          <TargetDetails readOnly={readOnly} currencyDetails={currencyDetails} register={register} unregister={unregister} watch={watch} setValue={setValue} currentValues={currentValues} />
          <RepresentativeTargets readOnly={readOnly} currencyDetails={currencyDetails} unregister={unregister} register={register} setValue={setValue} watch={watch} currentValues={currentValues} />
          <TargetCompensation readOnly={readOnly} currencyDetails={currencyDetails} unregister={unregister} register={register} watch={watch} setValue={setValue} />
          <TargetEducationandExperience readOnly={readOnly} register={register} watch={watch} setValue={setValue} />
        </Grid>
        <Grid item xs={3} >
          <TargetCompanies readOnly={readOnly} register={register} setValue={setValue} unregister={unregister} currentValues={currentValues} />
          <TargetOffLimits readOnly={readOnly} register={register} setValue={setValue} unregister={unregister} currentValues={currentValues} />
        </Grid>
      </Grid>
    </div>
  );
}

Targets.propTypes = {
  register: PropTypes.func,
  unregister: PropTypes.func,
  readOnly: PropTypes.bool,
  watch: PropTypes.func,
  setValue: PropTypes.func,
  currentValues: PropTypes.object,
  currencyDetails: PropTypes.object
};
