
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from "react";

//-----------------------------------------------------------// External Imports // ------------------------------

import { AgGridReact } from "ag-grid-react";

//-----------------------------------------------------------// Internal Imports // ------------------------------

import {
  CustomLoadingOverlayComponent,
  DateRenderer,
  PAGE_LIMIT,
  ReplistRenderer,
} from "./Utils";
import { IdRenderer } from "../Workbenches/utils";

export default function WorkbenchGrid(props) {
  return (
    <div id="myGrid" className="ag-theme-alpine add-contact-section">
      <AgGridReact
        {...props}
        enableBrowserTooltips={true}
        defaultColDef={{
          resizable: true,
          sortable: false,
          sortingOrder: ['asc', 'desc', null]
        }}
        cacheBlockSize={PAGE_LIMIT}
        loadingOverlayComponent={"CustomLoadingOverlayComponent"}
        frameworkComponents={{
          DateRenderer,
          CustomLoadingOverlayComponent,
          IdRenderer,
          ReplistRenderer,
        }}
        getRowNodeId={(data) => data.id}
        scrollbarWidth={12}
        suppressHorizontalScroll={false}
        rowModelType={"infinite"}
        rowSelection={"single"}
        suppressRowClickSelection={true}
      ></AgGridReact>
    </div>
  );
}
