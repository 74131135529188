import { sortByChronologicalOrder } from "../../utils/common"

export const getCleanedContact = (data, skipKgField = false) => {
  const contact = cleanUpCompensation(data)
  contact.first_name = contact.first_name?.trim()
  contact.last_name = contact.last_name?.trim()
  // Backend expect the value if none selected removing
  if (!contact['gender']) {
    delete contact['gender']
  }

  delete contact['image_id']

  if (!contact.age) {
    contact.age = null
  }
  if (!skipKgField) {
    if (contact.kg) {
      const arr = [];
      contact.kg.forEach(element => { arr.push(element.id) });
      contact.kg = arr;
    }
    // rename kg to kg_25 as per requirement for db schema
    delete Object.assign(contact, { 'kg_25': contact['kg'] });
    delete contact.kg
  }
  // Adding as per expected keys
  contact['current_company_id'] = contact['current_company']?.id
  contact['country'] = contact['country']?.name || contact.country
  if (contact.bd_target) {
    contact.bd_target_id = contact.bd_target.id
    delete contact.bd_target
  } else {
    contact.bd_target_id = null;
  }
  if (contact.mailing_list) {
    contact.mailing_list = contact.mailing_list.map((data) => {
      return data.id
    });
  }

  if (contact && contact.state && typeof contact.state !== "string") {
    if (contact.state.inputValue) {
      contact.state = contact.state.inputValue
    } else {
      contact.state = contact.state.title
    }
  }
  //Adding for phone input clear issue
  contact.direct_line = contact.direct_line === '+' ? null : contact.direct_line
  contact.mobile_phone = contact.mobile_phone === '+' ? null : contact.mobile_phone
  contact.home_phone = contact.home_phone === '+' ? null : contact.home_phone
  contact.assistant_direct_line = contact.assistant_direct_line === '+' ? null : contact.assistant_direct_line
  contact.assistant_mobile_phone = contact.assistant_mobile_phone === '+' ? null : contact.assistant_mobile_phone

  contact['job_history'] = contact.job_history && sortByChronologicalOrder(cleanupCompanyDetails(contact['job_history'], contact.id))
  contact['board_details'] = contact.board_details && cleanupCompanyDetails(contact['board_details'], contact.id)
  contact['education_details'] = contact.education_details && addPositionByIndex(contact.education_details, contact.id)
  contact['international_experience'] = contact.international_experience &&
    contact.international_experience.map(item => item?.name || item)

  return contact;
}

export const addPositionByIndex = (details, contact_id) => {
  if (Array.isArray(details) && details.length !== 0) {
    return details.map((item, position) => ({ ...item, position, contact_id }))
  }
  return [{}]
}

export const cleanupCompanyDetails = (companyDetails, contact_id) => {
  if (Array.isArray(companyDetails) && companyDetails.length !== 0) {
    return companyDetails.filter((element) => {
      if (element?.company && element?.company?.id) {
        return element
      }
    }).map((item, position) => {
      return {
        ...item,
        company_id: item.company.id,
        position,
        contact_id: contact_id
      }
    })
  }
  return [{}]
}

export const cleanUpCompensation = (data) => {
  let contact = { ...data };
  contact['target_bonus_value'] = parseFloat(contact['target_bonus_value'])
  contact['actual_bonus_value'] = parseFloat(contact['actual_bonus_value'])
  if (contact['currency_iso_code'] && contact['currency_iso_code'].code) {
    contact['currency_iso_code'] = contact['currency_iso_code'].code
  }
  return contact
}
