//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useState, useEffect } from "react";
import * as yup from "yup";


//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { useForm } from "react-hook-form";
import { useLocation, useHistory, useParams } from 'react-router-dom'
import { useSnackbar, withSnackbar } from "notistack";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close'
import { Typography } from "@material-ui/core";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";

//----------------------------------------------// Internal Imports // -------------------------------------------------
import BoardDetails from "./Components/BoardDetails";
import AddCandidateByContact from "../../Containers/Searches/AllCandidates/AddCandidateByContact";
import ErrorPopup from "../AddCompany/ErrorPopup";
import { POST, ERROR, WARNING, EMAIL_REGEX, SPECIAL_CHARACTERS_REPLACE_REGEX, REPLACE_SPACE_REGEX } from "../../services/constantService";
import { fetchUserList } from "../../../src/actions";
import { contactDataApi } from "../../services/ApiService";
import { validateMessage, unableMessage, VALIDATION_MESSAGE } from "../../services/MessageService";
import LanguageDetails from "./Components/LanguageDetails";
import { getCleanedContact } from "./utils";
import Loader from "../common/Loader";
import ProfileDetails from "./Components/ProfileDetails";
import EmploymentHistory from "./Components/EmploymentHistory";
import EducationDetails from "./Components/EducationDetails";
import CommunicationDetails from "./Components/Communication";
import IndustryAndJobFunction from "./Components/IndustryAndJobFunction";
import TabLayoutWithHeader from "../TabLayoutWithHeader";
import Compensation from "./Components/Compensation";
import PublishedBio from "./Components/PublishedBio";
import { contactSchema } from "../ActivityLog/utils";
import { customFormValidator, handelScroll, requireValidMessage } from "../../utils/common";
import { rightNav } from "../../Containers/Commons/styleUtils";
import { validateStartYearEndYear } from '../../utils/date';


const defaultValues = {
  job_history: [],
  education_details: [],
  board_details: [],
  current_company: null,
  published_bio: '',
  country: '',
  direct_line: null,
  mobile_phone: null,
  home_phone: null,
  current_job_start_year: '',
  current_job_end_year: 'Present',
  contact_industry: [{}]
};

const getDynamicKey = () => {
  return new Date().getTime()
}

const AddContact = (props) => {
  const { currentTabs } = useParams();
  const [dynamicKey, setDynamicKey] = useState(getDynamicKey())
  const [currentValues, setCurrentValues] = useState({ ...defaultValues })
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [addAnother, setAddAnother] = useState(false)
  const [contact, setContact] = useState({});
  const [duplicateContacts, setDuplicateContacts] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { register, unregister, setValue, errors, reset, triggerValidation, control, getValues, watch } = useForm({
    defaultValues: currentValues
  });
  const users = useSelector(state => state.rootReducer.users)
  const dispatch = useDispatch()
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()
  const location = useLocation();
  const NAV_ITEMS = ["Details", "Compensation"];
  const newNavItems = NAV_ITEMS.map((item) => {
    item = item.replace(SPECIAL_CHARACTERS_REPLACE_REGEX, 'and');
    return item.toLowerCase().replace(REPLACE_SPACE_REGEX, '-')
  })
  let currentTabIndex = newNavItems.findIndex(item => item === currentTabs);
  const locationState = location?.state?.contact;
  currentTabIndex = currentTabIndex === -1 ? history.push(`/contacts/add/${newNavItems[0]}`) : currentTabIndex;

  useEffect(() => {
    if (!users) {
      dispatch(fetchUserList('users'))
    }
    else {
      setOptions(users);
    }
  }, [users, dispatch])

  useEffect(() => {
    if (locationState) {
      reset(locationState)
      setCurrentValues(locationState)
    }
  }, [locationState])


  const resetFields = () => {
    setCurrentValues(defaultValues);
    reset();
    reset(defaultValues);
    setDynamicKey(getDynamicKey())
  }
  const validateEmail = (email) => {
    return email && !email.match(EMAIL_REGEX) ? false : true;
  }

  const CreateSchema = yup.object().shape({
    first_name: yup.string().required(),
    last_name: yup.string().required(),
    current_company: yup.object().required(),
    current_job_title: yup.string().required(),
    current_job_start_year: yup.string().required(),
    country: yup.string().required(),
    industries: yup.array().of(yup.string()).required(),
    job_functions: yup.array().of(yup.string()).required(),
  });
  const createContact = async (allowDuplicate) => {
    await triggerValidation()
    const contact = { ...currentValues, ...getValues({ nest: true }) }
    const empHistory = contact?.job_history;
    const BoardDetails = contact?.board_details;
    let invalidJobHistory = {};
    let invalidBoardDetails = {};

    let jobHistoryYear = {}
    let boardYear = {}
    jobHistoryYear = validateStartYearEndYear(empHistory)
    boardYear = validateStartYearEndYear(BoardDetails)

    if (currentTabIndex === 0) {
      const isValid = await CreateSchema.isValid(contact)
      if (!isValid) {
        let requiredField = [
          { fieldName: "first_name", label: "First Name", type: String },
          { fieldName: "last_name", label: "Last Name", type: String },
          { fieldName: "current_company", label: "Company Name", type: Object },
          { fieldName: "current_job_title", label: "Job Title", type: String },
          { fieldName: "current_job_start_year", label: "Job Start Year", type: String },
          { fieldName: "country", label: "Location", type: String },
          { fieldName: "industries", label: "Industry", type: [] },
          { fieldName: "job_functions", label: "Job Functions", type: [] },

        ]
        let dirtyField = customFormValidator(contact, requiredField)
        if (dirtyField) {
          const message = requireValidMessage(dirtyField)
          enqueueSnackbar(message, { variant: ERROR })
          return
        }
      }
    }
    if (empHistory && empHistory.length > 0)
      invalidJobHistory = empHistory.find(ele => (!ele?.company && (ele?.title || ele?.start_year || ele?.end_year))) || {};
    if (BoardDetails && BoardDetails.length > 0)
      invalidBoardDetails = BoardDetails.find(ele => (!ele.company && (ele?.title || ele?.start_date || ele?.end_date || ele?.committee))) || {};
    if (Object.keys(errors).length !== 0 || Object.keys(invalidJobHistory).length !== 0 || Object.keys(invalidBoardDetails).length !== 0) {
      if (Object.keys(invalidJobHistory).length !== 0) {
        const message = validateMessage("company name in employee history tab", "fill")
        props.enqueueSnackbar(message, { variant: WARNING })
      }
      else if (Object.keys(invalidBoardDetails).length !== 0) {
        const message = validateMessage("company name in board details tab", "fill")
        props.enqueueSnackbar(message, { variant: WARNING })
      }
      else {
        contactSchema.isValid(contact).then(async (valid) => {
          if (!valid) {
            const message = validateMessage("required fields in Details tab", "fill all")
            props.enqueueSnackbar(message, { variant: ERROR })
          }
        })
          .catch(async (error) => {
            if (error.path === 'private_email') {
              const message = validateMessage("valid private email", "enter")
              enqueueSnackbar(message, { variant: ERROR })
            }
            if (error.path === 'work_email') {
              const message = validateMessage("valid work email", "enter")
              enqueueSnackbar(message, { variant: ERROR })
            }

          })
      }
    } else {
      const valid = await contactSchema.isValid(contact)
      if (!valid) {
        const message = validateMessage("required fields in Details tab", "fill all")
        props.enqueueSnackbar(message, { variant: ERROR })
        return;
      }
      let validateWorkEmail = true;
      let validatePrivateEmail = true;
      validateWorkEmail = validateEmail(contact.work_email)
      validatePrivateEmail = validateEmail(contact.private_email)
      if (!validateWorkEmail || !validatePrivateEmail) {
        const message = validateMessage(`valid email`, "enter")
        enqueueSnackbar(message, { variant: ERROR });
        return;
      }

      if ((jobHistoryYear && Object.keys(jobHistoryYear).length !== 0) || (boardYear && Object.keys(boardYear).length !== 0)) {
        enqueueSnackbar(VALIDATION_MESSAGE.start_end_year, { variant: ERROR });
        return false
      }

      setIsLoading(true)
      const final = getCleanedContact(contact);
      const sub_route = allowDuplicate ? '?allowDuplicate=true' : '';
      const { status, data } = await contactDataApi(POST, '', final, sub_route);
      if (status === 201) {
        setContact({ id: data.id, first_name: contact.first_name, last_name: contact.last_name })
        setDuplicateContacts(false)
        setIsPopupOpen(true)
      } else if (status === 200) {
        setDuplicateContacts(data.contacts);
      } else {
        const message = unableMessage("contact", "create")
        props.enqueueSnackbar(data?.message || message, { variant: ERROR })
      }
      setIsLoading(false)

    }
  }
  const addContact = async () => {
    try {
      setAddAnother(false);
      await createContact();
    } catch (e) {
      console.log("Error found in addContact::", e);
    }
  }
  const addAnotherContact = async () => {
    try {
      setAddAnother(true);
      await createContact()
    } catch (e) {
      console.log("Error found in addAnotherContact::", e);
    }
  }
  const tabChange = () => {
    reset({ ...currentValues, ...getValues({ nest: true }) })
    setCurrentValues(prevState => ({ ...prevState, ...getValues({ nest: true }) }))
  }
  const handelNewTabChange = (newtabValue) => {
    if (newtabValue !== undefined || newtabValue !== null) {
      history.push(`/contacts/add/${newNavItems[newtabValue]}`)
    }
  }
  const handleClose = () => {
    setIsPopupOpen(false)
    setContact({})
    if (addAnother) {
      resetFields()
    }
    else {
      history.push('/contacts')
    }
  }

  const getHeaderText = () => {
    return <div className='d-flex add-to-search-header' style={{ background: 'white' }}>
      <div className="d-flex flex-column align-items-center flex-grow-1">
        <CheckCircleIcon style={{ width: 41, height: 41, marginTop: 30 }} color={"primary"} />
        <Typography className="success-title">Contact Successfully Created</Typography>
        <Typography className="search-sub-title">{VALIDATION_MESSAGE.add_contact_to_search}</Typography>
      </div>
      <CloseIcon className="cursor-pointer" style={{ margin: 15 }} onClick={handleClose} />
    </div>
  }

  const handleAddDuplicate = async () => {
    try {
      await createContact(true)
    } catch (e) {
      console.log("Error found in handleAddDuplicate::", e);
    }
  }

  const handleShowContacts = () => {
    const idQuery = duplicateContacts.map(contact => `id=${contact.id}`).join('&')
    window.open(`/contacts?${idQuery}`)
  }

  const handleCancel = () => {
    setDuplicateContacts(false);
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>Add Contact - KG Galaxy</title>
      </Helmet>
      {duplicateContacts ?
        <ErrorPopup
          header={VALIDATION_MESSAGE.contact_already_exist}
          viewButtonText={"View/Edit existing contacts"}
          onView={handleShowContacts}
          onCancel={handleCancel}
          onContinue={handleAddDuplicate}
        />
        : null
      }
      <Loader show={isLoading} />
      <AddCandidateByContact open={isPopupOpen} handleClose={handleClose} contact={contact} header={getHeaderText()} />
      <TabLayoutWithHeader
        tabChange={tabChange}
        header={{ label: "Add New Contact", route: "/contacts" }}
        navItems={[...NAV_ITEMS]}
        newTabChange={handelNewTabChange}
        currentTab={currentTabIndex}
        actions={[
          { label: "Cancel", className: "secondary-btn", onClick: () => { history.goBack() } },
          { label: "Create & add another", className: "secondary-btn-border mr-3", onClick: addAnotherContact },
          {
            label: "Create",
            className: "primary-btn",
            onClick: addContact,
          },
        ]}>
        <div className='d-flex contact-view w-100'>
          <form className='flex-grow-1 input-form flex-column tab-view-content overflow-auto col-9'>
            <div className='d-flex' id='profile'>
              <ProfileDetails
                option={options}
                register={register}
                unregister={unregister}
                control={control}
                watch={watch}
                getValues={getValues}
                setValue={setValue}
                dynamicKey={dynamicKey.toString()}
                values={currentValues}
              />
            </div>
            <div id='communication'>
              <CommunicationDetails
                option={options}
                register={register}
                control={control}
                watch={watch}
                getValues={getValues}
                setValue={setValue}
                dynamicKey={dynamicKey.toString()}
              />
            </div>
            <div id='jobExperience' className='d-flex '>
              <EmploymentHistory
                currentValues={currentValues}
                register={register}
                control={control}
                getValues={getValues}
                setValue={setValue}
                dynamicKey={dynamicKey.toString()}
              />
            </div>
            <BoardDetails
              currentValues={currentValues}
              register={register}
              control={control}
              watch={watch}
              getValues={getValues}
              setValue={setValue}
              dynamicKey={dynamicKey.toString()}
            />
            <div id='industryAndFunction' className='w-100'>
              <IndustryAndJobFunction
                register={register}
                control={control}
                watch={watch}
                getValues={getValues}
                setValue={setValue}
              />
            </div>
            <div id='education'>
              <EducationDetails
                register={register}
                currentValues={currentValues}
                setValue={setValue}
              />
            </div>
            <div id='languages'>
              <LanguageDetails
                currentValues={currentValues}
                register={register}
                watch={watch}
                getValues={getValues}
                setValue={setValue}
                dynamicKey={dynamicKey.toString()}
              />
            </div>
            <div id='publishedBio'>
              <PublishedBio key={dynamicKey + 'bio'} values={currentValues} defaultValue={currentValues['published_bio']} register={register} unregister={unregister} setValue={setValue} />
            </div>
          </form>
          <div className="tab-view-right-nav d-flex flex-column col-3 add-contact-side-nav" style={{ position: "absolute", right: '0' }}>
            <div className="right-nav-section  d-flex flex-column">
              <a style={rightNav} href='/' className="right-nav-header" onClick={(e) => { e.preventDefault(); handelScroll('profile') }}>
                Profile
              </a>
              <a style={rightNav} href='/' className="right-nav-header" onClick={(e) => { e.preventDefault(); handelScroll('communication') }}>
                Communication
              </a>
              <a style={rightNav} href='/' className="right-nav-header" onClick={(e) => { e.preventDefault(); handelScroll('industryAndFunction') }}>
                Industry and Function
              </a>
              <a style={rightNav} href='/' className="right-nav-header" onClick={(e) => { e.preventDefault(); handelScroll('jobExperience') }}>
                Job Experience
              </a>
              <a style={rightNav} href='/' className="right-nav-header" onClick={(e) => { e.preventDefault(); handelScroll('education') }}>
                Education
              </a>
              <a style={rightNav} href='/' className="right-nav-header" onClick={(e) => { e.preventDefault(); handelScroll('languages') }}>
                Languages
              </a>
              <a style={rightNav} href='/' className="right-nav-header" onClick={(e) => { e.preventDefault(); handelScroll('publishedBio') }}>
                Published Bio
              </a>
            </div>
          </div>
        </div>
        <Compensation
          register={register}
          control={control}
          errors={errors}
          watch={watch}
          values={currentValues}
          getValues={getValues}
          setValue={setValue}
        />
      </TabLayoutWithHeader>
    </React.Fragment>
  );
};

AddContact.propTypes = {
  enqueueSnackbar: PropTypes.func
}

export default withSnackbar(AddContact);
