
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useRef } from "react";

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from "prop-types";
import Popup from "reactjs-popup";
import CloseIcon from "@material-ui/icons/Close";
import { Button, InputLabel } from "@material-ui/core";

//-----------------------------------------------------------// Internal Imports // ------------------------------

import "./index.scss";
import WorkspaceSelection from "../../components/WorkspaceSelection/index";

function HireLogic(props) {
  const { company, jobTitle, workspace, options } = props;
  const { onClose, handleWorkspaceChange, handleWorkspaceSubmit } = props;
  const userRef = useRef();

  return (
    <Popup
      open={true}
      className="report-options"
      onClose={onClose}
      closeOnDocumentClick={false}
      closeOnEscape={false}
    >
      <div>
        <div
          className="quick-add-header mb-3"
          
        >
          <div className="d-flex align-items-center">
            <h4 className="mr-5">New Hire Logic Postition</h4>
          </div>
          <span className="action-icon cursor-pointer" onClick={onClose}>
            <CloseIcon />
          </span>
        </div>
        <div className="container">
          <span className="ml-3">
            Which workspace would you like to create{" "}
            <b>
              {jobTitle} - {company}
            </b>{" "}
            in?
          </span>
          <div className="hl-integration">
            <div className="d-flex align-items-center input-form-field input-field-old">
              <InputLabel
                required={true}
                className="label"
                style={{ minWidth: `150px`, color: `rgba(92, 82, 198, 0.8)` }}
              >
                Select Workspace
              </InputLabel>
              <WorkspaceSelection
                inputRef={userRef}
                InputLabelProps={{ focused: true }}
                filterSelectedOptions={true}
                label={""}
                placeholder={""}
                required={true}
                workspace={workspace}
                options={options}
                handleWorkspaceChange={handleWorkspaceChange}
              />
            </div>
          </div>
        </div>
        <div className="action-buttons" style={{ background: "#c3c3c35c" }}>
          <Button color="primary" className="mr-2" onClick={onClose}>
            Cancel
          </Button>
          <Button
            variant="contained"
            className="button"
            color="primary"
            onClick={() => handleWorkspaceSubmit()}
          >
            Ok
          </Button>
        </div>
      </div>
    </Popup>
  );
}

HireLogic.propTypes = {
  onClose: PropTypes.func,
  company: PropTypes.any,
  jobTitle: PropTypes.string,
  workspace: PropTypes.array,
  options: PropTypes.array,
  handleWorkspaceChange: PropTypes.func,
  handleWorkspaceSubmit: PropTypes.func,
};

export default HireLogic;
