import { createSlice } from "@reduxjs/toolkit";

const initialState = {};

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    updatePicklistData(state, action) {
      state[action.payload.key] = action.payload.data
    },
    updateUserData(state, action) {
      state.userData = action.payload
    },
  }
})

export const commonActions = commonSlice.actions;

export default commonSlice.reducer;