
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useRef, useEffect } from 'react'

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from 'prop-types'
import EditIcon from '@material-ui/icons/Edit';
import { useDispatch } from 'react-redux';


//-----------------------------------------------------------// Internal Imports // ------------------------------

import UploadIcon from '../upload.png';
import './index.scss';
import CompanyNotes from './CompanyNotes';
import CompanyAttachments from './CompanyAttachments';
import { companyActions } from '../../../store/companySlice';

function NotesAttachments(props) {
  const { company = {} } = props

  const dispatch = useDispatch()
  const notesRef = useRef();
  const attachmentsRef = useRef();

  useEffect(() => {
    return () => {
      dispatch(companyActions.resetCompanyState())
    };
  }, [company.id]);
  return (
    <>
      <div className="company-view-content d-flex flex-column">
        <CompanyNotes ref={notesRef} companyId={company.id} />
        <CompanyAttachments ref={attachmentsRef} companyId={company.id} />
      </div>
      <div className="actions d-flex align-items-center">
        <div className="d-flex align-items-center action-edit" onClick={() => notesRef.current.handleAddNotes()}>
          <span className="section-icon">
            <EditIcon fontSize="inherit" />
          </span>
          <span>Add Notes</span>
        </div>
        <div className="d-flex align-items-center action-edit" onClick={() => attachmentsRef.current.handleAddAttachment()}>
          <img src={UploadIcon} alt="Edit" className="section-icon"/>
          <span>Add Attachment</span>
        </div>
      </div>
    </>
  )
}

NotesAttachments.propTypes = {
  company: PropTypes.object,
}

export default NotesAttachments;
