
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { Component } from "react";

//-----------------------------------------------------------// External Imports // ------------------------------

import { connect } from "react-redux";
import PropTypes from "prop-types";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import { saveAs } from "file-saver";
import { withSnackbar } from "notistack";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import TimelineIcon from "@material-ui/icons/Timeline";
import {
  Checkbox,
  Button,
  Typography,
} from "@material-ui/core";
import ControlPointDuplicateIcon from '@material-ui/icons/ControlPointDuplicate';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import ColorLegend from "../../Commons/ColorLegend";
import {
  columnDefs,
  defaultColumns,
  StageRenderer,
  FQRenderer,
  ResumeRenderer,
  getFilterParamString,
} from "./utils";
import { checkFilterAndSort, CompensationRenderer, sortByChronologicalOrder } from "../../../utils/common";
import {
  CustomLoadingOverlayComponent,
  PAGE_LIMIT,
  SUCCESS_STATUS_CODE,
  LinkedInRenderer,
  DateTimeRenderer,
  DateRenderer,
  saveColumnStateToLocalStorage,
  loadColumnStateFromLocalStorage,
  addContactsToWorkbench,
  localTimeZoneHandler,
} from "../../Commons/Utils";
import AddCandidateByContact from "./AddCandidateByContact";
import CopyCandidatesToSearch from "./CopyCandidatesToSearch";
import QuickAddContact from "./Components/QuickAddContact";
import "../../Commons/index.scss";
import "./index.scss";
import ActivityHistories from "../../../components/ActivityLog/Components/GeneralInfo/Components/ActivityHistory/ActivityHistories";
import StageEditor from "./Components/StageEditor";
import ActionsPopover from "../../Contacts/ActionsPopover";
import DocsPopup from "../../../components/ViewContact/NotesAttachments/DocsPopup";
import { checkContactFetchingStatus, checkRecordCanDelete, ContactNameRenderer, EmailRenderer } from "../../Contacts/utils";
import { CompanyNameRenderer } from "../../Companies/utils";
//import { HireLogicLinkRenderer } from '../../HireLogic/utils'
import Header from "../../../components/ViewSearch/Components/Header";
import LiveSearchMetrics from "./LiveSearchMetrics";
import GenericCellEditor from "../../Commons/CellEditors/GenericCellEditor";
import RichTextBoxEditor from "../../Commons/CellEditors/RichTextBoxEditor";
import { RichTextPopupRendered } from "../../../utils/common"
import CustomFilter from "../../Commons/CustomFilter";
import ReasonEditor from "../../Commons/CellEditors/ReasonEditor";
import ReportOptions from "../../../components/common/ReportOptions";
import StatusReportWithOptions from "../../../components/common/ReportOptions/StatusReportWithOptions";
import ColumnFilter from "../../Commons/ColumnFilter";
import AthenaPopover from "./Components/AthenaPopover";
import CandidateSuitePopover from "./Components/CandidateSuitePopover";
import { formatDate } from "../../../utils/date";
import AthenaInvitePopUp from "../../../components/ViewSearch/Components/SFPA/Components/ClientAppAccess/AthenaInvitePopUp";
import CandidateSuiteInvitePopUp from "../../../components/ViewSearch/Components/SFPA/Components/ClientAppAccess/CandidateSuiteInvitePopUp";
import AddContactToWorkbench from "../../Commons/AddContactToWorkbench";
import RemoveContactFromWorkbench from "../../Commons/RemoveContactFromWorkbench";
import { fetchPickList } from "../../../actions";
import Loader from "../../../components/common/Loader";
import AddWorkbench from "../../../components/AddWorkbench";
import HireLogic from "../../Commons/HireLogicPopup";
import { isLoggedInUserAdmin } from "../../../services/cognitoService";
import { acquire as acquireUtil } from "./utils";
import {
  API,
  BLOB,
  DELETE,
  EMAIL_REGEX,
  GET,
  POST,
  PUT,
  ERROR,
  SUCCESS,
  WARNING,
  HIRELOGIC_STAGES,
  HL_SYNC_CANDIDATES,
  DELETE_CONFIRMATION_POPUP_MESSAGE,
  LINKEDIN_SCRAPER,
  HELIA
} from "../../../services/constantService";
import { initializeSSO } from "../../../services/SsoService";
import AddContactsToWorkbench from "../../Commons/AddContactToWorkbench";
import LinkedInDialog from "../../Commons/LinkedInDialog";
import { dataSourceUtils } from "../../../utils/dataSource";
import { fetchUserList } from "../../../actions";
import {
  acquireLockApi,
  candidateDataApi,
  releaseLockApi,
  searchDataApi,
  hlDataApi,
  allCandidateListApi,
  reportsDataApi
} from "../../../services/ApiService";
import {
  unableMessage,
  VALIDATION_MESSAGE,
  notFoundMessage,
  successMessage,
  validationMessage,
} from "../../../services/MessageService";
import DeletePopup from "../../Commons/DeletePopup";
import { UPDATE_CANDIDATE_LIST, UPDATE_TIMEZONE_DATA, RELOAD_ALL_CANDIDATE_GRID_WITHOUT_PRODUCTONE, RELOAD_ALL_CANDIDATE_GRID, REFRESH_CONTACTS } from "../../../types";
import SyncAllCandidateToHlPopup from "./SyncCandidatesToHLPopup";
import ColorRenderer from "./ColorRenderer";
import EmploymentHistoryConfirmationDialog from "../../Commons/EmploymentHistoryConfirmationDialog";
import { cleanupCompanyDetails } from "../../../components/AddContact/utils";
import AddLinkedinPopup from "../../../Containers/Commons/AddLinkedinPopup"
import SearchCloning from "./SearchCloning";
import DecisionSelection from "./DecisionSelection";
import { Link } from "react-router-dom";
import { searchActions } from "../../../store/searchSlice";

class CandidatesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      viewColumns: false,
      anchorEl: null,
      isRowSelected: false,
      isCopyCandidatesOpen: false,
      isAddCandidateOpen: false,
      isLinkedinPopup: false,
      isAllRowsSelected: false,
      isQuickAddOpen: false,
      isRemove: false,
      addContact: null,
      contactIds: [],
      activeContact: false,
      rowCount: 0,
      isAddToSearchOpen: false,
      isMetricsOpen: false,
      activeContactDocs: false,
      contacts: [{}],
      isReportsOpen: false,
      hireLogic: false,
      reportsParams: {},
      isStatusWithOptionOpen: false,
      isMatrixWithOptionOpen: false,
      isAthenaPopupOpen: false,
      isCandidateSuitePopupOpen: false,
      sortModel: [],
      filterModel: [],
      isLoading: false,
      isAdmin: false,
      options: [{}],
      workspace: [],
      candidateId: "",
      contactId: "",
      linkedPopup: false,
      linkedInValue: {},
      defaultLocalTime: {},
      timeZoneOptions: [],
      load: true,
      resetGrid: false,
      isDisableSendButton: false,
      IsSyncAllCandidate: false,
      validCandidatesForHl: [],
      invalidCandidatesForHl: [],
      isCandidatesSyncPopup: false,
      showReason: false,
      headerMessage: '',
      loadingMessage: null,
      showJobTitleChangedPopup: false,
      employmentHistoryMessageEndYearDate: null,
      isUpdateEmploymentHistory: false,
      skipCheckJobTitle: false,
      currentContact: null,
      newContactData: null,
      linkedinContactData: null,
      showFilterCount: 0,
      filterValue: null,
      isDeleteContactOpen: false,
      showCloningSearchPopup: false,
      searchData: [],
      isDecisionSelection: false
    };
  }

  componentWillUnmount() {
    const columnApi = this.columnApi
    saveColumnStateToLocalStorage(
      `candidatesColumns-${this.props.candidateStatus || ""}`,
      { columnApi }
    );
    this.props.dispatch({ type: 'resetLinkedinScraperEvents' })
  }
  componentDidMount() {
    if (!this.props.users) {
      this.props.dispatch(fetchUserList("users"));
    }
    this.props.dispatch({ type: 'resetLinkedinScraperEvents' })
  }

  componentDidUpdate() {
    if (this.state.skipCheckJobTitle) {
      this.handleUpdateJobHistory()
    }
    if (this.props.location.pathname && this.props.search?.helia_version) {
      const isDecisionTab = this.props.location.pathname.includes('decision-matrix')
      if (isDecisionTab && !this.state.isDecisionSelection) {
        if (this.props.search?.helia_version === HELIA.VERSION_1) {
          this.props.history.push(`/searches/${this.props.id}/active-candidates`)
        } else {
          this.setState({ isDecisionSelection: true });
        }
      }
    }
    // if (this.props.socket) {
    //   console.log('socket changes found:: ', this.props.socket);
    //   this.props.socket.on(LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_NOTIFY_TO_CANDIDATES, (msg, obj) => {
    //     console.log('socket event found:: ', LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_NOTIFY_TO_CANDIDATES, msg, obj)
    //     let candidateFound
    //     this.gridApi.forEachNode(node => {
    //       console.log('node', node.data?.id)
    //       console.log('socket obj::', obj?.candidate?.id)
    //       if (node.data?.id === obj?.candidate?.id) {
    //         candidateFound = node
    //       }
    //     })
    //     console.log('socket event candidate found:: ', candidateFound)
    //     if (candidateFound) {
    //       candidateFound.setData(obj.candidate)
    //       this.gridApi.refreshCells({ force: true });
    //       this.props.dispatch({ type: RELOAD_ALL_CANDIDATE_GRID })
    //     }
    //   })
    // }
    if (this.props[LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_NOTIFY_TO_CANDIDATES]) {
      const event = this.props[LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_NOTIFY_TO_CANDIDATES];
      let contactFound
      this.gridApi.forEachNode(node => {
        if (node.data?.id === event.obj?.candidate?.id) {
          contactFound = node
        }
      })
      if (contactFound) {
        contactFound.setData(event.obj.candidate)
        this.gridApi.refreshCells({ force: true });
        this.props.dispatch({ type: RELOAD_ALL_CANDIDATE_GRID })
      }
      this.props.dispatch({ type: LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_NOTIFY_TO_CANDIDATES, payload: undefined })
    }
    if (this.props.refreshContact) {
      this.props.dispatch({ type: RELOAD_ALL_CANDIDATE_GRID })
      this.gridApi.onFilterChanged();
      this.props.dispatch({ type: REFRESH_CONTACTS, payload: false })
    }
  }

  getURLs = {
    listURl: `${API.search}/${this.props.id}/candidates`,
  };

  dataSource = {
    getRows: async (params) => {
      try {
        params.filterModel && this.setState({ filterValue: params.filterModel });
        this.setState({ showFilterCount: Object.keys(this.state.filterValue).length })
        if (
          this.state.load ||
          (this.props.candidateStatus === undefined &&
            !this.props.allCandidate) ||
          Object.keys(params.filterModel).length !== 0 ||
          params.sortModel.length !== 0 ||
          this.state.resetGrid === 0
        ) {
          params.filterModel &&
            this.setState({ filterModel: params.filterModel });
          this.gridApi.deselectAll();
          const thisValue = { ...this };
          this.setState({ isLoading: true });
          const obj = {
            params: params,
            context: thisValue,
            pageLimit: PAGE_LIMIT,
            url: this.getURLs,
            subScreen: true,
            queryString: this.props.candidateStatus
              ? `&candidateStatus=${this.props.candidateStatus}`
              : "",
          };
          const { status, data } = await dataSourceUtils(
            obj,
            getFilterParamString
          );
          if (checkFilterAndSort(params.filterModel, params.sortModel) && this.props.candidateStatus === undefined) {
            this.props.dispatch({
              type: UPDATE_CANDIDATE_LIST,
              payload: { key: "allCandidate", data }
            })
          }
          else if (checkFilterAndSort(params.filterModel, params.sortModel) && this.props.candidateStatus === "ACTIVE") {
            this.props.dispatch({
              type: UPDATE_CANDIDATE_LIST,
              payload: { key: "activeCandidate", data },
            });
          }
          else if (checkFilterAndSort(params.filterModel, params.sortModel) && this.props.candidateStatus === "CLIENTSUITEANDREPORTS") {
            this.props.dispatch({
              type: UPDATE_CANDIDATE_LIST,
              payload: { key: "clientsuiteandreports", data },
            });
          }
          else if (checkFilterAndSort(params.filterModel, params.sortModel) && this.props.candidateStatus === LINKEDIN_SCRAPER.MY_LINKEDIN_CANDIDATES) {
            this.props.dispatch({
              type: UPDATE_CANDIDATE_LIST,
              payload: { key: LINKEDIN_SCRAPER.myLinkedinCandidatesRedux, data },
            });
          }
          if (status === SUCCESS_STATUS_CODE) {
            if (data?.paging?.totalCount === 0) {
              const message = notFoundMessage("records");
              this.props.enqueueSnackbar(message, { variant: WARNING });
            }
            params.successCallback(data.data, data.paging.totalCount);
            this.setState({
              contacts: data.data,
              rowCount: data.paging.totalCount,
            });
            this.state.isAllRowsSelected &&
              this.setSelectAllRows(this.state.isAllRowsSelected);
          } else {
            params.failCallback();
          }
          this.setState({ isLoading: false });
          this.gridApi.hideOverlay();
        } else if (
          (this.props.candidateStatus === "ACTIVE" &&
            !this.props.activeCandidate) ||
          Object.keys(params.filterModel).length !== 0 ||
          params.sortModel.length !== 0 ||
          this.state.resetGrid === 0
        ) {
          params.filterModel &&
            this.setState({ filterModel: params.filterModel });
          // this.gridApi.deselectAll();
          const thisValue = { ...this };
          this.setState({ isLoading: true });
          const obj = {
            params: params,
            context: thisValue,
            pageLimit: PAGE_LIMIT,
            url: this.getURLs,
            subScreen: true,
            queryString: this.props.candidateStatus
              ? `&candidateStatus=${this.props.candidateStatus}`
              : "",
          };
          const { status, data } = await dataSourceUtils(
            obj,
            getFilterParamString
          );
          this.props.dispatch({
            type: UPDATE_CANDIDATE_LIST,
            payload: { key: "activeCandidate", data },
          });
          if (status === SUCCESS_STATUS_CODE) {
            if (data?.paging?.totalCount === 0) {
              const message = notFoundMessage("records");
              this.props.enqueueSnackbar(message, { variant: WARNING });
            }
            params.successCallback(data.data, data.paging.totalCount);
            this.setState({
              contacts: data.data,
              rowCount: data.paging.totalCount,
            });
            this.state.isAllRowsSelected &&
              this.setSelectAllRows(this.state.isAllRowsSelected);
          } else {
            params.failCallback();
          }
          this.setState({ isLoading: false });
          this.gridApi.hideOverlay();
        } else if (
          (this.props.candidateStatus === "CLIENTSUITEANDREPORTS" &&
            !this.props.clientsuiteandreports) ||
          Object.keys(params.filterModel).length !== 0 ||
          params.sortModel.length !== 0 ||
          this.state.resetGrid === 0
        ) {
          params.filterModel &&
            this.setState({ filterModel: params.filterModel });
          this.gridApi.deselectAll();
          const thisValue = { ...this };
          this.setState({ isLoading: true });
          const obj = {
            params: params,
            context: thisValue,
            pageLimit: PAGE_LIMIT,
            url: this.getURLs,
            subScreen: true,
            queryString: this.props.candidateStatus
              ? `&candidateStatus=${this.props.candidateStatus}`
              : "",
          };
          const { status, data } = await dataSourceUtils(
            obj,
            getFilterParamString
          );
          this.props.dispatch({
            type: UPDATE_CANDIDATE_LIST,
            payload: { key: "clientsuiteandreports", data },
          });
          if (status === SUCCESS_STATUS_CODE) {
            if (data?.paging?.totalCount === 0) {
              const message = notFoundMessage("records");
              this.props.enqueueSnackbar(message, { variant: WARNING });
            }
            params.successCallback(data.data, data.paging.totalCount);
            this.setState({
              contacts: data.data,
              rowCount: data.paging.totalCount,
            });
            this.state.isAllRowsSelected &&
              this.setSelectAllRows(this.state.isAllRowsSelected);
          } else {
            params.failCallback();
          }
          this.setState({ isLoading: false });
          this.gridApi.hideOverlay();
        } else if (
          (this.props.candidateStatus === LINKEDIN_SCRAPER.MY_LINKEDIN_CANDIDATES &&
            !this.props[LINKEDIN_SCRAPER.myLinkedinCandidatesRedux]) ||
          Object.keys(params.filterModel).length !== 0 ||
          params.sortModel.length !== 0 ||
          this.state.resetGrid === 0
        ) {
          params.filterModel &&
            this.setState({ filterModel: params.filterModel });
          //   this.gridApi.deselectAll();
          const thisValue = { ...this };
          this.setState({ isLoading: true });
          const obj = {
            params: params,
            context: thisValue,
            pageLimit: PAGE_LIMIT,
            url: this.getURLs,
            subScreen: true,
            queryString: this.props.candidateStatus
              ? `&candidateStatus=${this.props.candidateStatus}`
              : "",
          };
          const { status, data } = await dataSourceUtils(
            obj,
            getFilterParamString
          );
          this.props.dispatch({
            type: UPDATE_CANDIDATE_LIST,
            payload: { key: LINKEDIN_SCRAPER.myLinkedinCandidatesRedux, data },
          });
          if (status === SUCCESS_STATUS_CODE) {
            if (data?.paging?.totalCount === 0) {
              const message = notFoundMessage("records");
              this.props.enqueueSnackbar(message, { variant: WARNING });
            }
            params.successCallback(data.data, data.paging.totalCount);
            this.setState({
              contacts: data.data,
              rowCount: data.paging.totalCount,
            });
            this.state.isAllRowsSelected &&
              this.setSelectAllRows(this.state.isAllRowsSelected);
          } else {
            params.failCallback();
          }
          this.setState({ isLoading: false });
          this.gridApi.hideOverlay();
        } else {
          if (this.props.candidateStatus === undefined) {
            if (this.props.allCandidate?.paging?.totalCount === 0) {
              const message = notFoundMessage("records");
              this.props.enqueueSnackbar(message, { variant: WARNING });
            }
            params.successCallback(
              this.props.allCandidate.data,
              this.props.allCandidate.paging.totalCount
            );
            this.setState({
              contacts: this.props.allCandidate.data,
              rowCount: this.props.allCandidate.paging.totalCount,
            });
            this.state.isAllRowsSelected &&
              this.setSelectAllRows(this.state.isAllRowsSelected);
          } else if (this.props.candidateStatus === "ACTIVE") {
            if (this.props.activeCandidate?.paging?.totalCount === 0) {
              const message = notFoundMessage("records");
              this.props.enqueueSnackbar(message, { variant: WARNING });
            }
            params.successCallback(
              this.props.activeCandidate.data,
              this.props.activeCandidate.paging.totalCount
            );
            this.setState({
              contacts: this.props.activeCandidate.data,
              rowCount: this.props.activeCandidate.paging.totalCount,
            });
            this.state.isAllRowsSelected &&
              this.setSelectAllRows(this.state.isAllRowsSelected);
          } else if (this.props.candidateStatus === LINKEDIN_SCRAPER.MY_LINKEDIN_CANDIDATES) {
            if (this.props.activeCandidate?.paging?.totalCount === 0) {
              const message = notFoundMessage("records");
              this.props.enqueueSnackbar(message, { variant: WARNING });
            }
            params.successCallback(
              this.props[LINKEDIN_SCRAPER.myLinkedinCandidatesRedux].data,
              this.props[LINKEDIN_SCRAPER.myLinkedinCandidatesRedux].paging.totalCount
            );
            this.setState({
              contacts: this.props[LINKEDIN_SCRAPER.myLinkedinCandidatesRedux].data,
              rowCount: this.props[LINKEDIN_SCRAPER.myLinkedinCandidatesRedux].paging.totalCount,
            });
            this.state.isAllRowsSelected &&
              this.setSelectAllRows(this.state.isAllRowsSelected);
          } else {
            if (this.props.clientsuiteandreports?.paging?.totalCount === 0) {
              const message = notFoundMessage("records");
              this.props.enqueueSnackbar(message, { variant: WARNING });
            }
            params.successCallback(
              this.props.clientsuiteandreports.data,
              this.props.clientsuiteandreports.paging.totalCount
            );
            this.setState({
              contacts: this.props.clientsuiteandreports.data,
              rowCount: this.props.clientsuiteandreports.paging.totalCount,
            });
            this.state.isAllRowsSelected &&
              this.setSelectAllRows(this.state.isAllRowsSelected);
          }
        }
      } catch (e) {
        console.log("Error found in downloadAttachment::", e);
      }
    },
    rowCount: null,
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    const columnApi = params.columnApi
    const gridApi = params.api
    loadColumnStateFromLocalStorage(
      `candidatesColumns-${this.props.candidateStatus || ""}`,
      { columnApi, gridApi }
    );
    this.setState({ isGridReady: true });
    if (
      this.props.allCandidate ||
      this.props.activeCandidate ||
      this.props.clientsuiteandreports
    )
      this.setState({ load: false });
  };

  handleCopySearchesToWorkbench = () => {
    this.setState({ isCopyToWorkbenchOpen: true });
  };

  handleCopySearchesToWorkbenchClose = () => {
    this.setState({ isCopyToWorkbenchOpen: false });
  };

  setSelectAllRows = (isAllRowsSelected) => {
    this.setState({ isAllRowsSelected });
    this.gridApi.forEachNode((node) => {
      node.setSelected(isAllRowsSelected);
    });
  };

  handleChange = () => {
    this.setSelectAllRows(!this.state.isAllRowsSelected);
  };

  linkedInPopupHandler = (props) => {
    this.setState({ linkedPopup: true, linkedInValue: props.value });
  };

  LinkedInRenderer = (params) => {
    return (
      <LinkedInRenderer
        params={params}
        linkedInPopup={this.linkedInPopupHandler}
      />
    );
  };

  LinkedInCloseHandler = () => {
    this.setState({ linkedPopup: false });
  };



  handelCloningSearch = async () => {
    this.setState({ showCloningSearchPopup: true })
  }
  handelCloseCloningPopup = () => {
    this.setState({ showCloningSearchPopup: false })
  }
  handelCloseDecisionPopup = () => {
    this.setState({ isDecisionSelection: false })

    this.props.history.push(`/searches/${this.props.id}/active-candidates`)

  }
  HeaderCheckbox = () => {
    return (
      <Checkbox
        style={{ padding: 0, width: 16, height: 16, color: "white" }}
        size="small"
        color="primary"
        onChange={this.handleChange}
      />
    );
  };

  checkUserRole = () => {
    let isAdminUser = isLoggedInUserAdmin();
    isAdminUser = isAdminUser === "true" ? true : false;
    this.setState({ isAdmin: isAdminUser });
  };



  componentDidMount = async () => {
    if (this.props.id) {
      const { status, data } = await searchDataApi(
        GET,
        this.props.id,
      );
      if (status === 200) {
        this.setState({ searchData: data })
      }
    }
    if (!this.props.timezone) {
      const data = await localTimeZoneHandler(this.state.timeZoneOptions);
      this.props.dispatch({
        type: UPDATE_TIMEZONE_DATA,
        payload: { key: "timezone", data },
      });
      this.setState({
        timeZoneOptions: data.timeZoneOptions,
        defaultLocalTime: data.timeZoneOptions[0],
      });
    } else {
      this.setState({
        timeZoneOptions: this.props.timezone.timeZoneOptions,
        defaultLocalTime: this.props.timezone.timeZoneOptions[0],
      });
    }
    this.checkUserRole();
    if (!this.props.colors) {
      this.props.dispatch(fetchPickList("colors"));
    }
  };

  ColorRenderer = (params) => {
    const handleChange = async (event) => {
      this.setState({ isLoading: true });
      const color = event.target.name;
      const { status } = await candidateDataApi(PUT, params.data?.id, { color });
      this.setState({ isLoading: false });
      if (status === 200) {
        params.node.setDataValue("color", color);
        this.props.dispatch({ type: RELOAD_ALL_CANDIDATE_GRID_WITHOUT_PRODUCTONE })
      } else {
        const message = unableMessage("candidate", "update");
        this.props.enqueueSnackbar(message, { variant: ERROR });
      }
    };
    return <ColorRenderer params={params} handleChange={handleChange} />
  };

  removeCandidate = async (id) => {
    const { status } = await candidateDataApi(DELETE, id);
    if (status === 200) {
      const message = successMessage("Candidate", "removed");
      this.props.enqueueSnackbar(message, { variant: SUCCESS });
      this.gridApi.onFilterChanged();
      this.props.dispatch(searchActions.resetSearchState())
      this.setState({ isRemove: false });
    } else {
      const message = unableMessage("candidate", "remove");
      this.props.enqueueSnackbar(message, { variant: ERROR });
    }
  };

  ActionsRenderer = (params) => {
    const status = checkContactFetchingStatus(params)
    let canDelete = checkRecordCanDelete(params, status);
    if (status === LINKEDIN_SCRAPER.PROCESSING_LABEL && !canDelete) return status;

    let list;
    if (status === LINKEDIN_SCRAPER.FAILED_LABEL || canDelete) {
      list = [
        {
          label: "Delete Record",
          onClick: () => {
            this.setState({ isDeleteContactOpen: true, contactId: params.data?.contact?.id });
          },
        },
      ];
    } else {
      //Note: this should only be turned on for admin until the integration is ready.
      list = (HIRELOGIC_STAGES.includes(params?.data?.stage)) ? [
        {
          label: "Log An Activity",
          onClick: async () => {
            this.acquire(params.data?.contact.id, params.data?.id);
          },
        },
        {
          label: "HireLogic Interview",
          onClick: () =>
            this.handleWorkspacePopup(params.data.id, params.data?.contact),
        },
        {
          label: "Add To Workbench",
          onClick: () => this.handleAddToWorkbench(params.data?.contact.id),
        },
        {
          label: "Remove From Workbench",
          onClick: () =>
            this.setState({
              isRemoveFromWorkbenchOpen: true,
              contactId: params.data?.contact.id,
            }),
        },
        {
          label: "Show History",
          onClick: () => this.showActivityHistory(params?.data?.contact),
        },
        {
          label: "Remove",
          onClick: async () => {
            this.setState({ isRemove: true, candidateId: params.data?.id });
            // this.acquire(params?.data?.contact.id, params.data?.id)
          },
        },
      ] : [
        {
          label: "Log An Activity",
          onClick: async () => {
            this.acquire(params.data?.contact.id, params.data?.id)
          }
        },
        {
          label: 'Add To Workbench',
          onClick: () => this.handleAddToWorkbench(params.data?.contact.id)
        },
        {
          label: "Remove From Workbench",
          onClick: () => this.setState({ isRemoveFromWorkbenchOpen: true, contactId: params.data?.contact.id })
        },
        {
          label: "Show History",
          onClick: () => this.showActivityHistory(params?.data?.contact),
        },
        {
          label: "Remove",
          onClick: async () => {
            this.setState({ isRemove: true, candidateId: params.data?.id })
            // this.acquire(params?.data?.contact.id, params.data?.id)
          }
        },
      ];
    }
    return params.data ? <ActionsPopover list={list} /> : null;
  };

  acquire = async (contactId, candidateId) => {
    this.setState({ isLoading: true })
    let { status, data } = await acquireLockApi(contactId);
    if (status && status === 200 && data.message === undefined) {
      // if (this.state.isRemove) {

      //   this.removeCandidate(candidateId)
      // }
      // else {
      await releaseLockApi(contactId);
      let url = this.props.history.location.pathname;
      if (
        url.includes("all-candidates") ||
        url.includes("client-suite-report") ||
        url.includes("my-linkedin-candidates")
      ) {
        url = url.concat("");
      } else if (url.includes("active-candidates")) {
        url = url.replace("/active-candidates", "")
        url = url.concat("/candidates");
      } else {
        url = url.concat("/candidates");
      }
      this.props.history.replace(

        // `/searches/${this.props.id}/candidates/${candidateId}/log-an-activity`,
        `${url}/${candidateId}/log-an-activity/general-info`,
        {
          sortModel: this.state.sortModel,
          filterModel: this.state.filterModel,
          contactId: contactId,
        }
      );
      this.setState({ isLoading: false })
      // }
    } else {
      //if (data.isAdmin) {
      this.setState({ isLoading: false })
      this.props.enqueueSnackbar(data.message, {
        variant: WARNING,
        // eslint-disable-next-line react/display-name
        action: (key) => (
          <>
            <Button
              size="small"
              variant="text"
              color="inherit"
              onClick={() => {
                this.props.closeSnackbar(key);
                this.releaseLock(contactId, candidateId);
              }}
            >
              Force Unlock
            </Button>
            <Button
              size="small"
              variant="text"
              color="inherit"
              onClick={() => {
                let url = this.props.history.location.pathname;
                if (
                  url.includes("all-candidates") ||
                  url.includes("client-suite-report") ||
                  url.includes("my-linkedin-candidates")
                ) {
                  url = url.concat("");
                } else if (url.includes("active-candidates")) {
                  url = url.replace("/active-candidates", "")
                  url = url.concat("/candidates");
                } else {
                  url = url.concat("/candidates");
                }
                this.props.closeSnackbar(key);
                this.props.history.replace(
                  `${url}/${candidateId}/log-an-activity/read-only/general-info`,
                  {
                    sortModel: this.state.sortModel,
                    filterModel: this.state.filterModel,
                    contactId: contactId,
                  }
                );
              }}
            >
              Open Read Only View
            </Button>
            <Button
              size='small'
              variant='text'
              color='inherit'
              onClick={async () => {
                this.props.closeSnackbar(key)
              }}
            >
              CLOSE
            </Button>

          </>
        ),
      });
      /* }
      else {
        this.props.enqueueSnackbar(data.message, {
          variant: WARNING,
          action: key => (
            <Button
              size='small'
              variant='text'
              color='inherit'
              onClick={() => {
                this.props.closeSnackbar(key)
                this.props.history.replace(
                  `/searches/${this.props.id}/candidates/${candidateId}/log-an-activity/read-only`,
                  { sortModel: this.state.sortModel, filterModel: this.state.filterModel, contactId: contactId }
                )
              }}
            >
              Open Read Only View
            </Button>
          )
        });
      } */
      this.setState({ isRemove: false });
    }
  };
  releaseLock = async (contactId, candidateId) => {
    await releaseLockApi(contactId);
    this.acquire(contactId, candidateId);
  };

  DocsRenderer = (params) => {
    const status = checkContactFetchingStatus(params)
    if (status) return status;
    const list = [
      {
        label: "Docs List",
        onClick: () => this.showDocsList(params?.data?.contact),
      },
      {
        label: "Individual Client Athena Report (49 Statement)",
        onClick: () =>
          this.setState({
            isReportsOpen: true,
            reportsParams: {
              params: {
                contactId: params?.data?.contact?.id,
                timeZone: true,
              },
              url: `${API.reports}/athena-individual-consulting`,
            },
          }),
      },
      this.props.search?.helia_version !== 'Athena Questions V2' && {
        label: "Candidate Fit Profile 3.0",
        onClick: () => {
          this.setState({
            isReportsOpen: true,
            reportsParams: {
              params: {
                candidateId: params?.data?.id,
                searchId: this.props.id,
                stage: params?.data?.stage,
                isArenaSearch: this.props.search.type === "Inside Track",
                timeZone: true,
              },
              options: {
                pageSize: true,
                locale: true,
                showLegacy: true,
              },
              url: `${API.reports}/candidate-fit-profile`,
            },
          });
        },
      },
      this.props.search?.helia_version === HELIA.VERSION_2 && {
        label: "Candidate Details Report",
        onClick: () => {
          this.setState({
            isReportsOpen: true,
            reportsParams: {
              params: {
                athenaCompleted: params?.data?.contact?.athena_completion_date,
                contactId: params?.data?.contact.id,
                timeZone: false,
                candidateId: params?.data?.id,
                searchId: this.props.id,
                athenaVersion: this.props.search?.helia_version,
                oneLanguageSupport: false
              },
              options: {
                pageSize: true,
                locale: true,
              },
              url: `${API.reports}/candidate-profile`,
            },
          });
        },
      },
      this.props.search?.helia_version !== 'Athena Questions V2' && {
        label: "Interview Preparation 3.0",
        onClick: () =>
          this.setState({
            isReportsOpen: true,
            reportsParams: {
              params: {
                candidateId: params?.data?.id,
                isArenaSearch: this.props.search.type === "Inside Track",
                timeZone: true,
              },
              options: {
                pageSize: true,
                locale: false,
                showLegacy: true,
              },
              url: `${API.reports}/interview-preparation`,
            },
          }),
      },
      this.props.search?.helia_version === HELIA.VERSION_2 && {
        label: "Interview Preparation Report",
        onClick: () =>
          this.setState({
            isReportsOpen: true,
            reportsParams: {
              params: {
                candidateId: params?.data?.id,
                isArenaSearch: this.props.search.type === "Inside Track",
                timeZone: true,
                searchId: this.props.id,
                athenaVersion: this.props.search?.helia_version,
                oneLanguageSupport: false
              },
              options: {
                pageSize: true,
                locale: true,
                showLegacy: true,
              },
              url: `${API.reports}/interview-prep-report`,
            },
          }),
      },
      {
        label: "Board Candidate Profile",
        onClick: () =>
          this.setState({
            isReportsOpen: true,
            reportsParams: {
              params: {
                candidateId: params?.data?.id,
                searchId: this.props.id,
                timeZone: true,
              },
              options: {
                pageSize: true,
                locale: false,
              },
              url: `${API.reports}/board-candidate-profile`,
            },
          }),
      },
      {
        label: "Proposed Candidate Profile",
        onClick: () =>
          this.setState({
            isReportsOpen: true,
            reportsParams: {
              params: {
                candidateId: params?.data?.id,
                contactId: params?.data?.contact.id,
                timeZone: true,
              },
              options: {
                pageSize: true,
              },
              url: `${API.reports}/contact-bio-profile`,
            },
          }),
      },
      this.props.search?.helia_version !== 'Athena Questions V2' && {
        label: "Athena Results Report",
        onClick: () =>
          this.setState({
            isReportsOpen: true,
            reportsParams: {
              params: {
                contactId: params?.data?.contact.id,
                timeZone: false,
              },
              options: {
                pageSize: true,
                locale: true,
              },
              url: `${API.reports}/athena-candidate-results`,
            },
          }),
      },
      this.props.search?.helia_version === HELIA.VERSION_2 && {
        label: "Athena Results Report",
        onClick: () =>
          this.setState({
            isReportsOpen: true,
            reportsParams: {
              params: {
                candidateId: params?.data?.id,
                timeZone: false,
                athenaVersion: this.props.search?.helia_version,
              },
              options: {
                pageSize: true,
                locale: true,
              },
              url: `${API.reports}/athena-candidate`,
              hideFormat: true
            },
          }),
      }
    ];
    return params.data ? <ActionsPopover list={list} /> : null;
  };
  HireLogicRenderer = (params) => {
    const list = [
      {
        label: "Add to HireLogic",
        // onClick: () => this.showDocsList(params?.data?.contact),
      },
      {
        label: "Sync Details",
        // onClick: () => this.showDocsList(params?.data?.contact),
      }
    ];
    return params.data ? <ActionsPopover list={list} label={"Actions"} /> : null;
  };
  showDocsList = (activeContactDocs) => {
    this.setState({
      activeContactDocs,
    });
  };

  handleChangeAddCandidate = () => {
    this.setState({ isAddCandidateOpen: true });
  };

  handleChangeAddCandidateFromLinkedin = () => {
    this.setState({ isLinkedinPopup: true })
  }

  handleAddCandidateClose = (isRefresh) => {
    if (isRefresh) {
      this.gridApi.onFilterChanged();
      this.props.dispatch(searchActions.resetSearchState())
    }
    this.setState({ isAddCandidateOpen: false, addContact: null });
  };

  handleAddLinkedinDataClose = (isClose) => {
    this.setState({ isLinkedinPopup: isClose })
    this.props.dispatch(searchActions.resetSearchState())
  }

  handleQuickAddContactClose = (contact, isAddToSearch) => {
    this.setState({ isQuickAddOpen: false });
    this.setState({ linkedinContactData: null })
    if (isAddToSearch && contact) {
      this.setState({ addContact: contact, isAddCandidateOpen: true });
    }
  };

  handleCopyCandidates = () => {
    const contactIds = [];
    this.gridApi.forEachNode((node) => {
      if (node.selected) {
        contactIds.push(node.data.contact.id);
      }
    });
    this.setState({ isCopyCandidatesOpen: true, contactIds });
  };
  handleCopyCandidatesClose = () => {
    this.setState({ isCopyCandidatesOpen: false, contactIds: [] });
  };

  showActivityHistory = (activeContact) => {
    this.setState({
      activeContact,
    });
  };

  CompanyNameRenderer = (params) => {
    const status = checkContactFetchingStatus(params)
    if (status) return status;
    return <CompanyNameRenderer company={params?.data?.contact?.company} />;
  };

  /* HireLogicLinkRenderer = (params) => {
    let position_id = this.props.search.position_id
    return <HireLogicLinkRenderer item={params?.data?.interview[0]?.interviewStatus} position_id={position_id} candidateID={params?.data?.hl_candidate_id} />
  } */

  NameRenderer = (params) => {
    return (
      <ContactNameRenderer
        params={params}
        handleAddToSearch={this.handleAddToSearch}
      />
    );
  };

  handleAddToSearch = (contactId) => {
    this.setState({ isAddToSearchOpen: true, contactIds: [contactId] });
  };

  handleAddToSearchClose = () => {
    this.setState({ isAddToSearchOpen: false, contactIds: [] });
  };

  AthenaRenderer = (params) => {
    const status = checkContactFetchingStatus(params)
    if (status) return status;
    const handleInvite = () => {
      if (
        !params.data.contact.private_email &&
        !params.data.contact.work_email
      ) {
        this.setState({
          isDisableSendButton: true,
        });
      } else {
        this.setState({
          isDisableSendButton: false,
        });
      }
      this.setState({
        isAthenaPopupOpen: true,
        athenaData: {
          candidate: { id: params.data?.id },
          contact: params.data?.contact,
        },
      });
    };

    if (params.data?.contact) {
      const contact = params.data.contact;
      if (contact.athena_completion_date || contact.athena_invitation_sent_on) {
        return (
          <AthenaPopover
            contactDetails={params.data.contact}
            handleInvite={handleInvite}
          >
            <Typography
              style={{ fontSize: "inherit", cursor: "pointer" }}
              color="primary"
            >
              {contact.athena_completion_date
                ? `Completed on: ${formatDate(contact.athena_completion_date)}`
                : `Invited on: ${formatDate(
                  contact.athena_invitation_sent_on
                )}`}
            </Typography>
          </AthenaPopover>
        );
      } else {
        return (
          <Button
            className="invite-button athena-button"
            variant="contained"
            color="primary"
            onClick={handleInvite}
          >
            Invite to Athena
          </Button>
        );
      }
    }
    return null;
  };

  CandidateSuiteRenderer = (params) => {
    const status = checkContactFetchingStatus(params)
    if (status) return status;
    const handleInvite = () => {
      this.setState({
        isCandidateSuitePopupOpen: true,
        candidatesuiteData: {
          candidate: { id: params.data?.id },
          contact: params.data?.contact,
        },
      });
    };

    if (params.data?.contact) {
      const contact = params.data.contact;
      if (
        contact.candidatesuite_completion_date ||
        contact.candidatesuite_invitation_sent_on
      ) {
        return (
          <CandidateSuitePopover
            contactDetails={params.data.contact}
            handleInvite={handleInvite}
          >
            <Typography
              style={{ fontSize: "inherit", cursor: "pointer" }}
              color="primary"
            >
              {contact.candidatesuite_completion_date
                ? `Completed on: ${formatDate(
                  contact.candidatesuite_completion_date
                )}`
                : `Invited on: ${formatDate(
                  contact.candidatesuite_invitation_sent_on
                )}`}
            </Typography>
          </CandidateSuitePopover>
        );
      } else {
        return (
          <Button
            className="invite-button athena-button"
            variant="contained"
            color="primary"
            onClick={handleInvite}
          >
            Invite to CandidateSuite
          </Button>
        );
      }
    }
    return null;
  };

  CandidateSuiteRenderer = (params) => {
    const status = checkContactFetchingStatus(params)
    if (status) return status;
    const handleInvite = () => {
      if (
        !params.data.contact.private_email &&
        !params.data.contact.work_email
      ) {
        this.setState({
          isDisableSendButton: true,
        });
      } else {
        this.setState({
          isDisableSendButton: false,
        });
      }
      this.setState({
        isCandidateSuitePopupOpen: true,
        candidatesuiteData: {
          candidate: { id: params.data?.id },
          contact: params.data?.contact,
        },
      });
    };

    if (params.data?.contact) {
      const contact = params.data.contact;
      if (
        contact.candidatesuite_completion_date ||
        contact.candidatesuite_invitation_sent_on
      ) {
        return (
          <CandidateSuitePopover
            contactDetails={params.data.contact}
            handleInvite={handleInvite}
          >
            <Typography
              style={{ fontSize: "inherit", cursor: "pointer" }}
              color="primary"
            >
              {contact.candidatesuite_completion_date
                ? `Completed on: ${formatDate(
                  contact.candidatesuite_completion_date
                )}`
                : `Invited on: ${formatDate(
                  contact.candidatesuite_invitation_sent_on
                )}`}
            </Typography>
          </CandidateSuitePopover>
        );
      } else {
        return (
          <Button
            className="invite-button athena-button"
            variant="contained"
            color="primary"
            onClick={handleInvite}
          >
            Send Invite for CandidateSuite
          </Button>
        );
      }
    }
    return null;
  };

  handleAthenaPopupClose = () => {
    this.setState({ isAthenaPopupOpen: false });
    this.gridApi.onFilterChanged();
  };

  handleIsCandidateSuitePopupClose = () => {
    this.setState({ isCandidateSuitePopupOpen: false });
    this.gridApi.onFilterChanged();
  };

  getParams = (selectedRows) => {
    if (this.state.isAllRowsSelected) {
      if (this.props.candidateStatus) {
        return `candidateStatus=${this.props.candidateStatus}`;
      }
      return "";
    } else {
      return selectedRows.map((row) => `id=${row.id}`).join("&");
    }
  };

  exportCallback = async () => {
    const selectedRows = this.gridApi.getSelectedRows();
    if (selectedRows && selectedRows.length) {
      let sub_route = `candidates/export-as-excel?`.concat(
        this.getParams(selectedRows)
      );
      if (
        this.state.filterModel &&
        Object.keys(this.state.filterModel).length
      ) {
        sub_route = sub_route.concat(
          getFilterParamString(this.state.filterModel)
        );
      }
      const columnHeaders = this.columnApi
        .getAllDisplayedColumns()
        .map((column) => column.getColDef().headerName);
      this.gridApi.showLoadingOverlay();
      const payload = { headers: columnHeaders };
      const { status, data, headers } = await searchDataApi(
        POST,
        this.props.id,
        payload,
        sub_route,
        BLOB
      );
      if (status === 200) {
        let fileName = "candidates.xlsx";
        let fileNameHeader = headers["content-disposition"].split('"');
        if (fileNameHeader && fileNameHeader.length > 2) {
          fileName = fileNameHeader[1];
        }
        saveAs(new File([data], fileName));
      }
      this.gridApi.hideOverlay();
    }
  };

  onFilterChanged = (params) => {
    const filterModel = params.api.getFilterModel();
    this.setState({ filterModel });
    this.setState({ resetGrid: Object.keys(filterModel).length });
    this.saveColumnStateForFilter()
  };

  onSortChanged = (params) => {
    const sortModel = params.api.getSortModel();
    this.setState({ sortModel });
    this.saveColumnStateForSort()
  };

  onFirstDataRendered = () => {
    const locationState = this.props.location.state;
    if (locationState) {
      const sortModel = locationState.sortModel;
      if (sortModel) {
        this.gridApi.setSortModel(sortModel);
      }

      const filterModel = locationState.filterModel;
      if (filterModel) {
        this.gridApi.setFilterModel(filterModel);
      }
    }
  };

  saveColumnState = async () => {
    try {
      this.gridApi && this.gridApi.showLoadingOverlay()
      const columnApi = this.columnApi;
      const gridApi = this.gridApi;
      await saveColumnStateToLocalStorage(
        `candidatesColumns-${this.props.candidateStatus || ""}`,
        { columnApi, gridApi }
      );
      this.gridApi && this.gridApi.hideOverlay()
    } catch (e) {
      console.log("Error found in saveColumnState::", e);
    }
  };

  saveColumnStateForFilter = async () => {
    try {
      this.gridApi && this.gridApi.showLoadingOverlay()
      const columnApi = this.columnApi;
      const gridApi = this.gridApi
      await saveColumnStateToLocalStorage(
        `candidatesColumns-${this.props.candidateStatus || ""}`,
        { columnApi, gridApi },
        true,
        false
      );
      this.gridApi && this.gridApi.hideOverlay()
    } catch (e) {
      console.log("Error found in saveColumnState::", e);
    }
  };

  saveColumnStateForSort = async () => {
    try {
      this.gridApi && this.gridApi.showLoadingOverlay()
      const columnApi = this.columnApi;
      const gridApi = this.gridApi
      await saveColumnStateToLocalStorage(
        `candidatesColumns-${this.props.candidateStatus || ""}`,
        { columnApi, gridApi },
        false,
        true
      );
      this.gridApi && this.gridApi.hideOverlay()
    } catch (e) {
      console.log("Error found in saveColumnState::", e);
    }
  };

  handleAddToWorkbench = (contactId) => {
    this.setState({ isAddToWorkbenchOpen: true, contactIds: [contactId] });
  };

  handleAddToWorkbenchClose = () => {
    this.setState({ isAddToWorkbenchOpen: false });
  };

  handleRemoveFromWorkbenchClose = () => {
    this.setState({ isRemoveFromWorkbenchOpen: false });
  };

  handleValidateEmail = (contact) => {
    if (!contact.private_email) {
      this.props.enqueueSnackbar(VALIDATION_MESSAGE.candidate_personal_email, {
        variant: ERROR,
      });
      return false;
    }
    const email = EMAIL_REGEX.test(contact.private_email)
      ? contact.private_email
      : null;
    if (!email) {
      const message = validationMessage("personal email");
      this.props.enqueueSnackbar(message, { variant: ERROR });
      return false;
    }
    return true;
  }

  handleWorkspacePopup = async (candidateId, contact) => {
    if (candidateId && contact) {
      // const isValidEmail = this.handleValidateEmail(contact)
      // if (!isValidEmail) {
      //   return;
      // }
      this.setState({ contactId: contact.id });
      this.setState({ candidateId: candidateId });
    }
    this.setState({ isLoading: true });
    const sub_route = `user-workspaces?userId=${this.props.userId}&searchId=${this.props.search.id}`;
    let { status, data } = await hlDataApi(GET, sub_route);
    if (status && status === 200) {
      this.setState({ isLoading: false });
      if (data.workspaces.length > 0) {
        this.handleWorkspaceChange(data.workspaces[0]);
      } else {
        this.props.enqueueSnackbar(VALIDATION_MESSAGE.hireLogic_workspace, {
          variant: ERROR,
        });
      }
      if (data.workspaces && data.workspaces.length === 1) {
        this.handleWorkspaceSubmit();
      } else {
        this.setState({ options: data.workspaces });
        this.setState({ hireLogic: true });
      }
    } else {
      const message = unableMessage("Workspaces", "fetch");
      this.props.enqueueSnackbar(message, { variant: ERROR });
      this.setState({ isLoading: false });
    }
  };

  handleWorkspaceChange = (value) => {
    this.setState({ workspace: [value] });
  };

  handleWorkspaceSubmit = async () => {
    try {
      if (this.state.IsSyncAllCandidate) {
        return this.handleSyncValidCandidatesToHL()
      }
      this.setState({ hireLogic: false });
      this.setState({ isLoading: true });
      const payload = {
        userId: this.props.userId,
        candidateId: this.state.candidateId,
        searchId: this.props.search.id,
        workspaceId: this.state.workspace[0].id,
        contactId: this.state.contactId,
      };
      const { status, data } = await initializeSSO(payload);
      if (status && data && status === 200) {
        let newTab = window.open("", "_blank");
        if (newTab !== null) {
          newTab.location.href = data.data.redirectLink;
        }
        const message = successMessage("Workspace", "saved");
        this.props.enqueueSnackbar(message, { variant: SUCCESS });
      } else {
        let str = data.errorDetails.errors[0].title;
        if (data.status === 422) str = str.validationMessages.details[0].message;
        this.props.enqueueSnackbar(str, { variant: ERROR });
      }
      this.setState({ isLoading: false });
    } catch (e) {
      console.log("Error found in handleWorkspaceSubmit::", e);
    }
  };

  getSelectedContactIds = () => {
    const contactIds = [];
    this.gridApi.forEachNode((node) => {
      if (node.selected) {
        contactIds.push(node.data.contact.id);
      }
    });
    return contactIds;
  };

  handleAddContactsToWorkbench = () => {
    const contactIds = this.getSelectedContactIds();
    this.setState({ isAddToWorkbenchOpen: true, contactIds });
  };

  handleCreateWorkbench = () => {
    this.setState({ isCreateWorkbenchOpen: true });
  };

  loaderChange = async (value) => {
    this.setState({ isLoading: value });
  };

  handleCreateWorkbenchClose = async (workbenchId) => {
    try {
      this.setState({ isCreateWorkbenchOpen: false });
      if (workbenchId) {
        const { enqueueSnackbar } = this.props;
        this.setState({ isLoading: true });
        const contactIds = this.getSelectedContactIds(this.gridApi);
        await addContactsToWorkbench(workbenchId, contactIds, enqueueSnackbar);
      }
      this.setState({ isLoading: false });
    } catch (e) {
      console.log("Error found in handleCreateWorkbenchClose::", e);
    }
  };

  handleDeleteClose = (deleted) => {
    this.setState({ isRemove: false, candidateId: null });
    if (deleted) {
      this.gridApi.onFilterChanged();
      this.props.dispatch(searchActions.resetSearchState())
    }
  };

  handleDeleteContactClose = (deleted) => {
    this.setState({ isDeleteContactOpen: false, contactId: null })
    if (deleted) {
      this.gridApi.onFilterChanged()
    }
  }

  handleSyncAllCandidateToHl = async () => {
    this.setState({ IsSyncAllCandidate: true, isLoading: true })
    const { status, data } = await allCandidateListApi(GET, this.getURLs.listURl, 'limit=1000')
    if (status === SUCCESS_STATUS_CODE) {
      if (data?.paging?.totalCount === 0) {
        const message = notFoundMessage("records");
        this.props.enqueueSnackbar(message, { variant: WARNING });
        this.setState({ IsSyncAllCandidate: false, isLoading: false })
        return
      }
      let validCandidates = [];
      // let invalidCandidates = [];

      data.data.forEach(item => {
        // if (HIRELOGIC_STAGES.includes(item.stage)) {
        // if (!item.contact.private_email) {
        //   invalidCandidates = [...invalidCandidates, { ...item, contactId: item.contact.id, candidateId: item.id }]
        // } else {
        //   const email = EMAIL_REGEX.test(item.contact.private_email)
        //     ? item.contact.private_email
        //     : null;
        //   if (!email) {
        //     invalidCandidates = [...invalidCandidates, { ...item, contactId: item.contact.id, candidateId: item.id }]
        //   } else {
        //     validCandidates = [...validCandidates, { ...item, contactId: item.contact.id, candidateId: item.id }]
        //   }
        // }
        validCandidates = [...validCandidates, { ...item, contactId: item.contact.id, candidateId: item.id }]
        //  }
      })

      this.setState({ isLoading: false, showReason: false });
      if (validCandidates.length) {
        this.setState({ validCandidatesForHl: validCandidates });
      }
      // if (invalidCandidates.length > 0) {
      //   this.setState({ invalidCandidatesForHl: invalidCandidates, headerMessage: HL_SYNC_CANDIDATES.VALIDATE_EMAIL_MESSAGE })
      //   this.setState({ isCandidatesSyncPopup: true })
      // } else {
      //   this.handleWorkspacePopup()
      // }
      this.handleWorkspacePopup()
    } else {
      this.props.enqueueSnackbar(data.message, { variant: ERROR });
    }
  }
  resetFilter = async () => {
    this.gridApi.setFilterModel(null);
    const customFilterField = this.columnApi.columnController.columnDefs;
    customFilterField.map(item => {
      if (item.filter === 'CustomFilter') {
        this.gridApi.destroyFilter(item.field);
      }
    })
  }

  resetSort = async () => {
    this.gridApi.setSortModel(null);
  }

  handleSyncValidCandidatesToHL = async () => {
    try {
      this.setState({ hireLogic: false });
      if (this.state.validCandidatesForHl.length === 0) {
        this.setState({ IsSyncAllCandidate: false })
        this.props.enqueueSnackbar(HL_SYNC_CANDIDATES.NO_FOUND_VALID_CANDIDATES, { variant: WARNING })
        return;
      }
      let snackbarKey;
      let isStopSyncing = false
      this.props.enqueueSnackbar(HL_SYNC_CANDIDATES.STOP_SYNCING_MESSAGE_LABEL,
        {
          persist: true,
          variant: WARNING,
          // eslint-disable-next-line react/display-name
          action: key => {
            snackbarKey = key;
            return (
              <Button
                size='small'
                variant='text'
                color='inherit'
                onClick={() => {
                  this.setState({ loadingMessage: HL_SYNC_CANDIDATES.STOPPING_SYNCING_MESSAGE });
                  isStopSyncing = true
                  this.props.closeSnackbar(key)
                }}
              >
                Stop Sync
              </Button>
            )
          }
        }
      )
      this.setState({ loadingMessage: HL_SYNC_CANDIDATES.STOP_SYNCING_CANDIDATES, isLoading: true });
      let failedToSyncCandidates = [];
      let resultCount = 0
      for await (const [, item] of this.state.validCandidatesForHl.entries()) {
        if (isStopSyncing) {
          break;
        }
        const payload = {
          userId: this.props.userId,
          candidateId: item.candidateId,
          searchId: this.props.search.id,
          workspaceId: this.state.workspace[0].id,
          contactId: item.contactId,
        };
        const { status, data } = await initializeSSO(payload);
        if (status && data && status !== 200) {
          let str = data.errorDetails.errors[0].title;
          if (data.status === 422) str = str.validationMessages.details[0].message;
          failedToSyncCandidates = [...failedToSyncCandidates, { ...item, hl_reason: str },]
        }
        resultCount++
      }
      if (resultCount === this.state.validCandidatesForHl.length || isStopSyncing) {
        this.setState({ isLoading: false });
      }
      if (failedToSyncCandidates.length > 0) {
        this.setState({ invalidCandidatesForHl: failedToSyncCandidates, showReason: true, headerMessage: HL_SYNC_CANDIDATES.VALIDATE_REASON_MESSAGE })
        this.setState({ isCandidatesSyncPopup: true })
      }
      else {
        const message = successMessage('Candidates', 'sync with HL')
        this.props.enqueueSnackbar(message, { variant: SUCCESS });
      }
      if (snackbarKey) {
        this.props.closeSnackbar(snackbarKey)
      }
      this.setState({ IsSyncAllCandidate: false, loadingMessage: null })
    } catch (e) {
      console.log("Error found in handleSyncValidCandidatesToHL::", e);
    }

  }

  handleCloseCandidatesSyncPopup = () => {
    this.setState({ isCandidatesSyncPopup: false, IsSyncAllCandidate: false })
  }

  handleRedirectToReportBuilder = async () => {
    this.setState({ isLoading: true })
    const sub_route = `report-builder/login_url`
    let { status, data } = await reportsDataApi(GET, sub_route);
    if (status && status === 200) {
      let newTab = window.open('', '_blank');
      if (newTab !== null) {
        newTab.location.href = data.redirectLink;
      }
      this.setState({ isLoading: false })
    } else {
      this.props.enqueueSnackbar(data.message, { variant: ERROR });
      this.setState({ isLoading: false })
    }
  }
  setEmploymentHistoryEndYearDate = (endDate) => {
    this.setState({ employmentHistoryMessageEndYearDate: endDate });
  }

  handleConfirmJobTitlePopup = async () => {
    this.setState({ isUpdateEmploymentHistory: true, showJobTitleChangedPopup: false, skipCheckJobTitle: true })
  }
  handleCancelJobTitlePopup = async () => {
    this.setState({ isUpdateEmploymentHistory: false, showJobTitleChangedPopup: false, skipCheckJobTitle: true })
  }

  setJobHistoryPopupStates = (data) => {
    this.setState({ ...data })
  }

  handleUpdateJobHistory = async () => {
    this.setState({ skipCheckJobTitle: false })
    const params = this.state.newContactData;
    if (this.state.isUpdateEmploymentHistory) {
      this.setState({ isLoading: true })
      const contact = this.state.currentContact
      let newJobHistory = [{
        company: contact.company,
        title: contact.current_job_title,
        start_year: contact.current_job_start_year,
        end_year: this.state.employmentHistoryMessageEndYearDate || new Date().getFullYear()
      }];
      newJobHistory = [...newJobHistory, ...contact.job_history].filter(item => item?.company)
      params.job_history = sortByChronologicalOrder(cleanupCompanyDetails(newJobHistory, contact.id))
    }
    await acquireUtil(params, 'current_job_title', this.props.enqueueSnackbar, this.props.closeSnackbar, true, '', this.loaderChange, this.props.dispatch, true)
    this.setJobHistoryPopupStates({
      employmentHistoryMessageEndYearDate: null,
      isUpdateEmploymentHistory: false,
      currentContact: null,
      newContactData: null
    })
  }

  render() {
    const {
      linkedInValue,
      linkedPopup,
      isAddCandidateOpen,
      isQuickAddOpen,
      linkedinContactData,
      isRowSelected,
      isCopyCandidatesOpen,
      addContact,
      contactIds,
      isMetricsOpen,
      isReportsOpen,
      hireLogic,
      reportsParams,
      isStatusWithOptionOpen,
      isAthenaPopupOpen,
      isCandidateSuitePopupOpen,
      isAddToWorkbenchOpen,
      isLinkedinPopup,
      isRemoveFromWorkbenchOpen,
      contactId,
      isLoading,
      isCreateWorkbenchOpen,
      rowCount,
      isAllRowsSelected,
      isMatrixWithOptionOpen,
      isCopyToWorkbenchOpen,
      isRemove,
      candidateId,
      isDisableSendButton,
      isCandidatesSyncPopup,
      isDeleteContactOpen,
      showCloningSearchPopup,
      searchData,
      isDecisionSelection
    } = this.state;
    const {
      id,
      search = {},
      jobTitle,
      confidential,
      candidateStatus,
      colorList,
      setColorDesc,
      users,
      // eslint-disable-next-line react/prop-types
      initialLoader,
    } = this.props;
    const queryData = isAllRowsSelected
      ? Object.fromEntries(
        new URLSearchParams(
          getFilterParamString(this.gridApi.getFilterModel())
        )
      )
      : null;
    if (queryData && candidateStatus) {
      queryData.candidateStatus = candidateStatus;
    }
    const isLinkedinCandidate = candidateStatus === LINKEDIN_SCRAPER.MY_LINKEDIN_CANDIDATES;
    return (
      <div className="all-candidates d-flex flex-column h-100">
        <Header
          companyDetails={search.company}
          company={search.company && search.company.name}
          jobNumber={search.job_number}
          jobTitle={jobTitle}
          confidential={confidential}
        >
          <LinkedInDialog
            open={linkedPopup}
            linkedInValue={linkedInValue}
            handleClose={this.LinkedInCloseHandler}
          ></LinkedInDialog>
          <div className="d-flex">
            <div className="action-container d-flex align-items-center" onClick={this.handelCloningSearch}>
              <span className="action-icon">
                <ControlPointDuplicateIcon fontSize="inherit" />
              </span>
              <span className="action-text">Clone Search</span>
            </div>
            <div
              onClick={() => {
                this.setState({ isMetricsOpen: true });
              }}
              className="action-container d-flex align-items-center"
            >
              <span className="action-icon">
                <TimelineIcon fontSize="inherit" />
              </span>
              <span className="action-text">Live Search Metrics</span>
            </div>
            <ActionsPopover
              className="action-container d-flex align-items-center"
              label={
                <div className="action-container d-flex align-items-center">
                  <span className="action-icon">
                    <TrendingUpIcon fontSize="inherit" />
                  </span>
                  <span className="action-text">Reports</span>
                </div>
              }
              list={[
                {
                  label: "Board Status Report 3.0",
                  onClick: () =>
                    this.setState({
                      isStatusWithOptionOpen: true,
                      reportsParams: {
                        params: {
                          searchId: this.props.id,
                          reportType: "BoardStatusReport",
                          hideIsShort: true,
                          hideCompensation: true,
                          hideFQDetails: true,
                          hideSummary: true,
                          pipeline: false,
                          timeZone: true,
                        },
                        url: `${API.reports}/weekly-client-update`,
                      },
                    }),
                },
                this.props?.search?.helia_version !== 'Athena Questions V2' && {
                  label: "Search Status Report 3.0",
                  onClick: () => {
                    this.setState({
                      isStatusWithOptionOpen: true,
                      reportsParams: {
                        params: {
                          searchId: this.props.id,
                          reportType: "StatusReport",
                          isArenaSearch: this.props.search.type === "Inside Track",
                          timeZone: true,
                          athenaVersion: this.props?.search?.helia_version
                        },
                        url: `${API.reports}/weekly-client-update`
                      }
                    });
                  }
                },
                this.props?.search?.helia_version === HELIA.VERSION_2 && {
                  label: "Search Status Report",
                  onClick: () => {
                    this.setState({
                      isStatusWithOptionOpen: true,
                      reportsParams: {
                        params: {
                          searchId: this.props.id,
                          athenaVersion: this.props?.search?.helia_version,
                          oneLanguageSupport: false,
                          
                          /* Hide this two unused checkbox for helia report */
                          hideIsShort: true,
                          hideDisqualified: true,
                        },
                        options: {
                          pageSize: true,
                          locale: true,
                        },
                        url: `${API.reports}/search-status`,
                      },
                    });
                  },
                },
                {
                  label: "LatAm Search Status Report",
                  onClick: () =>
                    this.setState({
                      isStatusWithOptionOpen: true,
                      reportsParams: {
                        params: {
                          searchId: this.props.id,
                          reportType: "StatusReport",
                          hideIsShort: true,
                          timeZone: true,
                        },
                        url: `${API.reports}/latam-search-status`,
                      },
                    }),
                },
                {
                  label: "Candidate Matrix Report",
                  onClick: () =>
                    this.setState({
                      isMatrixWithOptionOpen: true,
                      isReportsOpen: true,
                      reportsParams: {
                        params: {
                          timeZone: true,
                        },
                        url: `${API.reports}/candidate-interview-report`,
                      },
                    }),
                },
                {
                  label: "Report Builder",
                  onClick: this.handleRedirectToReportBuilder,
                }
              ]}
            />
            <Button
              onClick={this.handleCopySearchesToWorkbench}
              className="action-button mr-3"
              variant="outlined"
              color="primary"
            >
              Add to Search Workbench
            </Button>
            {!isLinkedinCandidate && <Button
              disabled={!isRowSelected}
              color="primary"
              className="button"
              variant="contained"
              onClick={this.exportCallback}
            >
              Export
            </Button>}
          </div>
        </Header>

        <LiveSearchMetrics
          id={id}
          jobTitle={jobTitle}
          jobNumber={search.job_number}
          open={isMetricsOpen}
          handleClose={() => {
            this.setState({ isMetricsOpen: false });
          }}
        />
        <div className="d-flex flex-column w-100 h-100">
          {
            isDecisionSelection && search.id &&
            <DecisionSelection
              key={search.id}
              open={isDecisionSelection}
              handelClose={this.handelCloseDecisionPopup}
              searchId={id}
              heliaVersion={search.helia_version}
              initialCoords={search?.search_sfpa_client_decisions}
            />
          }
          {
            showCloningSearchPopup &&
            <SearchCloning
              open={showCloningSearchPopup}
              searchId={id}
              handelClose={this.handelCloseCloningPopup}
              searchData={searchData}
            />
          }
          {this.state.showJobTitleChangedPopup &&
            <EmploymentHistoryConfirmationDialog
              header='Update'
              employmentHistoryMessageEndYearDate={this.state.employmentHistoryMessageEndYearDate}
              setEmploymentHistoryEndYearDate={this.setEmploymentHistoryEndYearDate}
              open={this.state.showJobTitleChangedPopup}
              contact={this.state.currentContact}
              handleSubmit={this.handleConfirmJobTitlePopup}
              handleClose={this.handleCancelJobTitlePopup}
              cancelText='No'
              confirmText='Yes'
              extraClassName="jobhistory-popup"
            />
          }
          {isAddToWorkbenchOpen && (
            <AddContactToWorkbench
              open={isAddToWorkbenchOpen}
              contactIds={contactIds}
              onClose={this.handleAddToWorkbenchClose}
              searchId={isAllRowsSelected ? id : null}
              candidateStatus={candidateStatus}
              type="Contacts"
            />
          )}
          {isRemoveFromWorkbenchOpen && (
            <RemoveContactFromWorkbench
              contactId={contactId}
              onClose={this.handleRemoveFromWorkbenchClose}
              open={isRemoveFromWorkbenchOpen}
            />
          )}
          {isCopyToWorkbenchOpen && (
            <AddContactsToWorkbench
              open={isCopyToWorkbenchOpen}
              searchIds={[this.props.id]}
              onClose={this.handleCopySearchesToWorkbenchClose}
              sourceWorkbenchId={null}
              type="Searches"
            />
          )}
          {isCandidatesSyncPopup && (
            <SyncAllCandidateToHlPopup
              open={isCandidatesSyncPopup}
              onClose={this.handleCloseCandidatesSyncPopup}
              candidateData={this.state.invalidCandidatesForHl}
              showReason={this.state.showReason}
              onSubmit={() => {
                this.setState({ isCandidatesSyncPopup: false });
                this.handleWorkspacePopup()
              }}
              headerMessage={this.state.headerMessage}
              validCandidateLength={this.state.validCandidatesForHl.length}
            />
          )}
          {isRemove ? (
            <DeletePopup
              onClose={this.handleDeleteClose}
              id={candidateId}
              module={"candidates"}
              popupText={DELETE_CONFIRMATION_POPUP_MESSAGE.DELETE_CANDIDATE_FROM_SEARCH}
            />
          ) : null}
          {isDeleteContactOpen ? <DeletePopup
            onClose={this.handleDeleteContactClose}
            payload={{ failed: true }} id={contactId}
            module={'contacts'}
            popupText={DELETE_CONFIRMATION_POPUP_MESSAGE.DELETE_FAILED_CONTACT}
          /> : null}
          {!initialLoader && <Loader className={'sync-message'} loadingMessage={this.state.loadingMessage} show={isLoading} />}
          {isCreateWorkbenchOpen && (
            <AddWorkbench
              onClose={this.handleCreateWorkbenchClose}
              buttonText={VALIDATION_MESSAGE.create_workbench_add_contacts}
              DefaultValue={"Contacts"}
              Disabled={true}
            />
          )}
          {isAthenaPopupOpen ? (
            <AthenaInvitePopUp
              inviteFor={"candidate"}
              selectedDropDown={"Athena"}
              client={this.state.athenaData}
              onClose={this.handleAthenaPopupClose}
              isDisableSendButton={isDisableSendButton}
            />
          ) : null}
          {isCandidateSuitePopupOpen ? (
            <CandidateSuiteInvitePopUp
              inviteFor={"candidate"}
              selectedDropDown={"CandidateSuite"}
              client={this.state.candidatesuiteData}
              onClose={this.handleIsCandidateSuitePopupClose}
              isDisableSendButton={isDisableSendButton}
            />
          ) : null}
          {this.state.activeContact && (
            <ActivityHistories
              contact={this.state.activeContact}
              onPopupClose={() => this.showActivityHistory(false)}
            />
          )}
          {this.state.activeContactDocs && (
            <DocsPopup
              contact={this.state.activeContactDocs}
              onPopupClose={() => this.showDocsList(false)}
              isResetDocs={true}
            />
          )}
          {this.state.isAddToSearchOpen && (
            <CopyCandidatesToSearch
              contacts={this.state.contacts}
              header={VALIDATION_MESSAGE.add_contacts_to_search}
              buttonText={VALIDATION_MESSAGE.add_contacts_to_search}
              uiLabel="contact(s)"
              open={true}
              searchId={null}
              contactIds={this.state.contactIds}
              isAllRowsSelected={this.state.isAllRowsSelected}
              handleClose={this.handleAddToSearchClose}
            />
          )}
          {isAddCandidateOpen && (
            <AddCandidateByContact
              searchId={id}
              searchName={search.job_title}
              contact={addContact}
              open={isAddCandidateOpen}
              handleClose={this.handleAddCandidateClose}
              header="Add Candidate By Contact"
            />
          )}
          {isLinkedinPopup && (
            <AddLinkedinPopup
              open={isLinkedinPopup}
              handleClose={this.handleAddLinkedinDataClose}
              searchId={this.props.id}
              isCandidate={true}
              title='Candidates'
            />
          )}
          {isCopyCandidatesOpen && (
            <CopyCandidatesToSearch
              contacts={this.state.contacts}
              open={isCopyCandidatesOpen}
              searchId={isAllRowsSelected ? id : null}
              contactIds={contactIds}
              queryData={queryData}
              handleClose={this.handleCopyCandidatesClose}
            />
          )}
          {isQuickAddOpen && (
            <QuickAddContact
              searchId={id}
              open={isQuickAddOpen}
              contact={linkedinContactData}
              handleClose={this.handleQuickAddContactClose}
              enqueueSnackbar={this.props.enqueueSnackbar}
              isAddToSearchEnabled={true}
            />
          )}
          {isReportsOpen && (
            <ReportOptions
              timeZoneOptions={this.state.timeZoneOptions}
              defaultLocalTime={this.state.defaultLocalTime}
              openOnMatrix={isMatrixWithOptionOpen}
              onClose={() => {
                this.setState({
                  isReportsOpen: false,
                  isMatrixWithOptionOpen: false,
                  reportsParams: {},
                });
              }}
              {...reportsParams}
            />
          )}
          {hireLogic && this.state.options.length > 0 && (
            <HireLogic
              handleWorkspaceSubmit={this.handleWorkspaceSubmit}
              handleWorkspaceChange={this.handleWorkspaceChange}
              workspace={this.state.workspace}
              company={search.company && search.company.name}
              options={this.state.options}
              jobTitle={jobTitle}
              onClose={() => {
                this.setState({ hireLogic: false });
              }}
            />
          )}
          {isStatusWithOptionOpen && (
            <StatusReportWithOptions
              onClose={() =>
                this.setState({
                  isStatusWithOptionOpen: false,
                })
              }
              options={{ pipeline: true, ...this.state.reportsParams.params }}
              onContinue={(params) => {
                this.setState((prev) => {
                  return {
                    isStatusWithOptionOpen: false,
                    isReportsOpen: true,
                    reportsParams: {
                      ...prev.reportsParams,
                      params: {
                        ...prev.reportsParams.params,
                        ...params,
                      },
                    },
                  };
                });
              }}
            />
          )}
          <div className="all-candidates-actions d-flex">
            {!isLinkedinCandidate && <Button
              onClick={() => {
                this.setState({ isQuickAddOpen: true });
              }}
              variant="outlined"
              color="primary"
              className="mr-2"
            >
              Quick Add Contact
            </Button>}
            {!isLinkedinCandidate && <Button
              onClick={this.handleChangeAddCandidate}
              variant="outlined"
              color="primary"
              className="mr-2"
            >
              Add Candidate By Contact
            </Button>}
            <Button
              onClick={this.handleChangeAddCandidateFromLinkedin}
              variant="outlined"
              color="primary"
              className="mr-2"
            >
              Add Candidate From LinkedIn
            </Button>
            {!isLinkedinCandidate && <Button
              disabled={!isRowSelected}
              onClick={this.handleCopyCandidates}
              variant="outlined"
              color="primary"
              className="mr-2"
            >
              Copy Candidates To Search
            </Button>}
            {!isLinkedinCandidate && <Button
              variant="outlined"
              color="primary"
              className="mr-2"
              disabled={!isRowSelected}
              onClick={this.handleAddContactsToWorkbench}
            >
              Add Contacts To Workbench
            </Button>}
            {!isLinkedinCandidate && <Button
              variant="outlined"
              color="primary"
              disabled={!isRowSelected}
              onClick={this.handleCreateWorkbench}
            >
              Create New Workbench
            </Button>}
          </div>
          <div className="count-container pt-3">
            {
              this.props.candidateStatus === "ACTIVE" && this.props.search.helia_version !== HELIA.VERSION_1 &&
              <div
                // onClick={() => {
                //   this.setState({ isDecisionSelection: true });
                // }}
                className="action-container"
              >
                <Link to={`/searches/${id}/active-candidates/decision-matrix`}>
                  <span className="action-text">Decision Matrix</span>
                </Link>
              </div>
            }
            <div
              className="action-container"
              onClick={() => this.resetFilter()}
            >
              <span className="action-text" >
                Reset Filter
              </span>
            </div>
            <div
              className="action-container"
              onClick={() => this.resetSort()}
            >
              <span className="action-text" >
                Reset Sort
              </span>
            </div>
            {!isLinkedinCandidate && <div className="action-container"
              onClick={() => this.handleSyncAllCandidateToHl()}
            >
              <span className="action-text" >
                Sync All Candidates to HL
              </span>
            </div>}
            <ColorLegend colorList={colorList} setColorDesc={setColorDesc} />
            <Typography>Total count: {rowCount}</Typography>
          </div>
          <div className="list-view">
            {this.state.isGridReady && (
              <ColumnFilter
                columnApi={this.columnApi}
                defaultColumns={defaultColumns}
                showFilterCount={this.state.showFilterCount}
                filterModel={this.state.filterValue}
              />
            )}
            <div id="myGrid" className="ag-theme-alpine">
              <AgGridReact
                onGridReady={this.onGridReady}
                enableBrowserTooltips={true}
                defaultColDef={{
                  resizable: true,
                  sortable: true,
                  minWidth: 150,
                  sortingOrder: ['asc', 'desc', null]
                }}
                isRowSelectable={(params) => {
                  const status = checkContactFetchingStatus(params)
                  if (status) return false;
                  return true;
                }}
                cacheBlockSize={PAGE_LIMIT}
                loadingOverlayComponent={"CustomLoadingOverlayComponent"}
                frameworkComponents={{
                  CustomLoadingOverlayComponent,
                  LinkedInRenderer: this.LinkedInRenderer,
                  DateTimeRenderer,
                  StageEditor,
                  StageRenderer,
                  FQRenderer,
                  EmailRenderer,
                  DateRenderer,
                  CustomFilter,
                  ActionsRenderer: this.ActionsRenderer,
                  ColorRenderer: this.ColorRenderer,
                  HeaderCheckbox: this.HeaderCheckbox,
                  NameRenderer: this.NameRenderer,
                  DocsRenderer: this.DocsRenderer,
                  HireLogicRenderer: this.HireLogicRenderer,
                  CompanyNameRenderer: this.CompanyNameRenderer,
                  //HireLogicLinkRenderer: this.HireLogicLinkRenderer,
                  GenericCellEditor,
                  RichTextBoxEditor,
                  RichTextPopupRendered,
                  CompensationRenderer,
                  ResumeRenderer,
                  ReasonEditor,
                  AthenaRenderer: this.AthenaRenderer,
                  CandidateSuiteRenderer: this.CandidateSuiteRenderer,
                }}
                suppressMenuHide={true}
                getRowNodeId={(data) => data.id}
                scrollbarWidth={12}
                suppressHorizontalScroll={false}
                rowModelType={"infinite"}
                datasource={this.dataSource}
                columnDefs={columnDefs(
                  this.showActivityHistory,
                  this.props.enqueueSnackbar,
                  this.props.closeSnackbar,
                  this.props.candidateStatus !== "CLIENTSUITEANDREPORTS",
                  this.loaderChange,
                  users,
                  search.type,
                  this.props.dispatch,
                  this.setJobHistoryPopupStates,
                  this.props.candidateStatus
                )}
                paginationPageSize={PAGE_LIMIT}
                rowSelection={"multiple"}
                onRowSelected={() => {
                  /* if (!e.node.selected) {
                    this.setState({ isAllRowsSelected: false })
                  } */
                  this.setState({
                    isRowSelected:
                      this.gridApi.getSelectedRows().length > 0 ? true : false,
                  });
                }}
                suppressRowClickSelection={true}
                suppressDragLeaveHidesColumns={true}
                onFilterChanged={this.onFilterChanged}
                onSortChanged={this.onSortChanged}
                onFirstDataRendered={this.onFirstDataRendered}
                onDisplayedColumnsChanged={this.saveColumnState}
                onDragStopped={this.saveColumnState}
              ></AgGridReact>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

CandidatesList.propTypes = {
  id: PropTypes.string,
  search: PropTypes.object,
  jobTitle: PropTypes.string,
  confidential: PropTypes.bool,
  enqueueSnackbar: PropTypes.func,
  closeSnackbar: PropTypes.func,
  candidateStatus: PropTypes.string,
  location: PropTypes.object,
  history: PropTypes.object,
  colors: PropTypes.array,
  fetchPickList: PropTypes.func,
  colorList: PropTypes.array,
  setColorDesc: PropTypes.func,
  userId: PropTypes.string,
  users: PropTypes.array,
  fetchUserList: PropTypes.func,
  timezone: PropTypes.object,
  dispatch: PropTypes.func,
  allCandidate: PropTypes.object,
  activeCandidate: PropTypes.object,
  clientsuiteandreports: PropTypes.object,
  initialLoader: PropTypes.bool,
  socket: PropTypes.object,
  refreshContact: PropTypes.bool,
  [LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_NOTIFY_TO_CANDIDATES]: PropTypes.object,
  [LINKEDIN_SCRAPER.myLinkedinCandidatesRedux]: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    colors: state.commonReducer.colors,
    userId: state.commonReducer.userData.id,
    users: state.rootReducer.users,
    timezone: state.rootReducer.timezone,
    allCandidate: state.rootReducer.allCandidate,
    activeCandidate: state.rootReducer.activeCandidate,
    clientsuiteandreports: state.rootReducer.clientsuiteandreports,
    socket: state.rootReducer.socket,
    refreshContact: state.rootReducer.refreshContact,
    [LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_NOTIFY_TO_CANDIDATES]: state.rootReducer[LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_NOTIFY_TO_CANDIDATES],
    [LINKEDIN_SCRAPER.myLinkedinCandidatesRedux]: state.rootReducer[LINKEDIN_SCRAPER.myLinkedinCandidatesRedux]
  };
};

const mapDispatchToProps = (dispatch) => ({
  fetchPickList,
  fetchUserList,
  dispatch,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(CandidatesList));
