//----------------------------------------------// In-built Imports // -------------------------------------------------
import React from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";

//----------------------------------------------// Internal Imports // -------------------------------------------------
import ContactReferences from "../../ViewContact/References";
import { forwardRef } from "react";
import { useImperativeHandle } from "react";
import TabLayoutFooter from "../../TabLayoutFooter";

// eslint-disable-next-line react/display-name
const References = forwardRef((props, ref) => {
  const { contactId, contact, readOnly, handleBack } = props;
  const actions = [
    { label: 'Close', className: 'primary-btn mr-3', onClick: () => { handleBack(true) } },
  ]

  useImperativeHandle(ref, () => ({
    saveTabData: () => {
      return true;
    },
    isDirty: () => {
      return false
    },
  }));

  return (
    <>
      <div className='input-form references-activity'>
        <Typography className="section-label">References</Typography>
        <ContactReferences contactId={contactId} contact={contact} disabled={readOnly} editable={!readOnly} />
      </div>
      {!readOnly && <TabLayoutFooter actions={actions} />}
    </>
  )
})

References.propTypes = {
  contactId: PropTypes.string,
  contact: PropTypes.object,
  readOnly: PropTypes.bool,
  handleBack: PropTypes.bool,
};

export default References;