//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useState, useEffect } from 'react'

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from "prop-types";
import { Typography, Grid } from '@material-ui/core';
import { useSnackbar } from "notistack";

//----------------------------------------------// Internal Imports // -------------------------------------------------
import RichTextPopup from "../../../../../RichTextPopup";
import { searchDataApi } from '../../../../../../services/ApiService';
import { SUCCESS, ERROR, PATCH } from '../../../../../../services/constantService';
import { VALIDATION_MESSAGE, unableMessage, successMessage } from '../../../../../../services/MessageService';
function ProductsServicesOverview(props) {
  const { register, setValue, currentValues, removeHtmlTags, searchInfo, sfpa, setSfpaData } = props;
  if (currentValues.products_services_overview === undefined) { currentValues.products_services_overview = sfpa.products_services_overview }
  const { enqueueSnackbar } = useSnackbar()
  const [editorHtml, setEditorHtml] = useState(currentValues.products_services_overview)
  const [isLastSaveSuccess, setLastSaveSuccess] = useState(true)

  useEffect(() => {
    register("products_services_overview");
    setEditorHtml(currentValues.products_services_overview);
  }, [currentValues.products_services_overview, register])

  useEffect(() => {
    setValue('products_services_overview', editorHtml)
  }, [editorHtml, setValue])

  const saveProductServicesOverview = async (payload, skipMessage, setAutoSaveLoading) => {
    const plainText = removeHtmlTags(payload.products_services_overview)
    delete payload.old
    let returnResult;
    if (plainText.length === 0) { payload.products_services_overview = plainText }
    if (setAutoSaveLoading) {
      setAutoSaveLoading(true)
    }
    const { status, data } = await searchDataApi(PATCH, searchInfo.id, payload, 'search-autosave-field');
    if (status && status === 200) {
      // setSFPAUpdated(false);
      payload.updated_at = data.result.updated_at
      setSfpaData(payload);
      const message = successMessage("Product/Services Overview" , VALIDATION_MESSAGE.updated_message)
      !skipMessage && enqueueSnackbar(message, { variant: SUCCESS });
      setLastSaveSuccess(true)
      returnResult = true
    } else {
      const message = unableMessage("Product/Services Overview", "update")
      enqueueSnackbar(data.message || message, { variant: ERROR });
      setLastSaveSuccess(false)
      returnResult = false
    }
    if (setAutoSaveLoading) {
      setTimeout(() => {
        setAutoSaveLoading(false)
      }, 500);
    }
    return returnResult
  }

  return (
    <div
      style={{
        marginTop: '20px',
        marginLeft: '20px',
        marginBottom: '0px',
        padding: '0px'
      }}>
      <div>
        <Grid container>
          <Grid item xs={12}>
            <Typography
              style={{ float: 'left', fontWeight: 'bold' }}
            >
              Product/Services Overview
            </Typography>
          </Grid>
        </Grid>
      </div>
      <Grid container spacing={0}>
        <Grid xs={12}>
          <div className='text-view-value p-0 w-100'>
            <div className='outlined-border transform p-0'>
              <RichTextPopup
                onChange={setEditorHtml}
                value={editorHtml}
                onSave={saveProductServicesOverview}
                title="Product/Services Overview"
                name='products_services_overview'
                autoSave={saveProductServicesOverview}
                saveStatus={isLastSaveSuccess}
                updatedDate={currentValues.updated_at}
              />
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

ProductsServicesOverview.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  sfpa: PropTypes.func,
  currentValues: PropTypes.object,
  searchInfo: PropTypes.object,
  removeHtmlTags: PropTypes.func,
  setSfpaData: PropTypes.func,
}
export default ProductsServicesOverview;