//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react'

//----------------------------------------------// External Imports // -------------------------------------------------
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';
import Menu from '@material-ui/core/Menu';
import Badge from '@material-ui/core/Badge';
import { makeStyles, withStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import './index.scss';
import ConfirmationPopup from '../ConfirmationPopup';
import { ERROR, GET, NOTIFICATION, PATCH, SUCCESS } from '../../services/constantService';
import { getUserSelector } from '../../selectors';
import { productOneApi } from '../../services/ApiService';
import { DEFAULT_DATE_FORMAT, DEFAULT_TIME_FORMAT, formatDate } from '../../utils/date';

const useStyles = makeStyles(() => ({
  notificationIconWrapper: {
    cursor: 'pointer',
    position: 'relative',
    padding: '5px',
  },
  notificationBadge: {
    fontSize: '5px'
  },
  notificationIcon: {
    color: 'rgba(255, 255, 255, 0.5)',
    fontSize: '1.9rem'
  },
  menuContainer: {
    cursor: 'pointer',
    position: 'relative',
    marginTop: 5,
    paddingBottom: 5,
    '&:hover': {
      background: 'rgba(0,0,0,0.04)'
    }
  },
  menuComponent: {
    maxHeight: '45vh',
    marginTop: 6,
    maxWidth: '25vw'
  },
  menuBackground: {
    background: '#FAFAFA'
  },
}));

const StyledBadge = withStyles(() => ({
  badge: {
    top: 8,
    fontSize: 10
  },
}))(Badge);

const UserNotification = () => {
  const classes = useStyles();
  const [notificationsItems, setNotificationItems] = useState([])
  const [anchorEl, setAnchorEl] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const userData = useSelector(getUserSelector);
  const { enqueueSnackbar } = useSnackbar();
  const [isAnimating, setAnimating] = useState(false);
  const socket = useSelector(state=> state.rootReducer?.socket)

  const getNotifications = async (shake = false) => {
    try {
      const subRoute = `notifications`
      const { status, data } = await productOneApi(GET, null, subRoute);
      if (status === 200) {
        shake && setAnimating(true)
        setConvertedNotification(data.notifications)
        shake && setTimeout(() => {
          setAnimating(false)
        }, NOTIFICATION.SHAKE_TIME)
        return data.notifications
      } else {
        enqueueSnackbar(data.message, { variant: ERROR });
        return false;
      }
    } catch (err) {
      console.log(err);
    }
  }
  const setConvertedNotification = async (data) => {
    const notifications = data.map(ele => {
      return {
        ...ele,
        visited: ele.status === NOTIFICATION.READ,
        userName: userData.name,
      }
    });
    setNotificationItems(notifications);
  }

  useEffect(() => {
    setConvertedNotification(userData.notifications)
  }, [])

  useEffect(() => {
    if (socket) {
      socket.on(NOTIFICATION.EXTENDED_SEARCH_COMPLETED, (msg) => {
        enqueueSnackbar(msg, { variant: SUCCESS })
        setTimeout(() => {
          getNotifications(true)
        }, 500)
      })
    }
  }, [socket])

  const handleClickItem = async (index) => {
    setSelectedNotification(notificationsItems[index]);
    handleShowPopup(true)
    if (!notificationsItems[index].visited) {
      readNotification({ id: notificationsItems[index].id, status: NOTIFICATION.READ })
    }
  }

  const getMenuItems = () => {
    return notificationsItems.length ? notificationsItems.map((item, index) => {
      return (
        <div className={classes.menuContainer} key={index} onClick={handleClose} >
          {!item.visited && <span className='view-status'></span>}
          <div
            onClick={() => { handleClickItem(index); }}
            className={`notification-container pl-5 pr-4`}
          >
            <span className={`notification-label ${item.visited ? 'viewed-notification-label' : ''} `}>
              {item.notification_content.notification_content}
            </span>
            <div className='notification-sub-label'>
              <span>
                <strong>{item.userName}</strong> | {formatDate(item.created_at, (DEFAULT_DATE_FORMAT + " " + DEFAULT_TIME_FORMAT))}
              </span>
            </div>
          </div>
        </div >
      )
    }) : <div className='empty-notification' >{NOTIFICATION.EMPTY_LABEL}</div>
  }
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleShowPopup = () => {
    setShowPopup(true);
  }

  const handleConfirmPopup = () => {
    setShowPopup(false);
    if(selectedNotification.notification_content.status === 'COMPLETED') {
      window.open(selectedNotification.notification_content.redirect_url)
    }
    setSelectedNotification(null);
  }

  const handleClosePopup = () => {
    setShowPopup(false);
    setSelectedNotification(null);
  }

  const readNotification = async (payload) => {
    try {
      const subRoute = 'notification-status';
      await productOneApi(PATCH, payload, subRoute);
      return getNotifications();
    } catch (err) {
      console.log('error in readNotification::', err)
    }
  }

  const getUnreadNotification = () => {
    return notificationsItems?.filter(ele => !ele.visited).length
  }
  return (
    <div className='user-notification'>
      {showPopup &&
        <ConfirmationPopup
          header={selectedNotification.notification_content.content}
          message={selectedNotification.notification_content.status === 'COMPLETED' ? NOTIFICATION.CONFIRM_REDIRECT : ''}
          onClose={handleClosePopup}
          onConfirm={handleConfirmPopup}
          handleCancel={handleClosePopup}
          disabledCancel={selectedNotification.notification_content.status === 'COMPLETED' ? false : true}
          confirmText={selectedNotification.notification_content.status === 'COMPLETED' ? 'Yes' : 'Ok'}
        />
      }
      <div className='mx-2'>
        <div className={classes.notificationIconWrapper}>
          <StyledBadge color='error' onClick={handleClick} className={`${classes.notificationBadge} ${isAnimating ? 'badge-animation' : ''}`} badgeContent={getUnreadNotification()} max={99}>
            <NotificationsNoneOutlinedIcon className={classes.notificationIcon} />
          </StyledBadge >
        </div>
        <Menu
          className={`${classes.menuComponent} custom-scrollbar`}
          classes={{ paper: classes.menuBackground }}
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {getMenuItems()}
        </Menu>
      </div>
    </div>
  );
};

export default UserNotification;