//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect } from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from "prop-types";

//----------------------------------------------// Internal Imports // -------------------------------------------------
//import RightSideNav from "../../../RightSideNav";
import BillingInformationView from "./Components/BillingInformationView";
import FeeInformationView from "./Components/FeeInformationView";
import './index.scss'
// import AdminChargesInformationView from "./Components/AdminChargesInformationView";
// import RetainersInformationView from "./Components/RetainersInformationView";
// import { rightNav } from "../../../../Containers/Commons/styleUtils";
export default function BillingInfoView(props) {

  const { register, setValue, watch, getValues, handelScroll, control, currentValues, isEditing, renderActions, renderFieldLabel, editingField, /* reloadData, */ currencyDetails, /* setUpdatelLoader */ renderSectionLabel } = props

  useEffect(() => {
    setTimeout(() => {
      handelScroll()
    }, 3000)

  }, []);
  return (
    <div className='input-form'>
      <div id='client-info'>
        <BillingInformationView
          register={register}
          setValue={setValue}
          watch={watch}
          control={control}
          isEditing={isEditing}
          currentValues={currentValues}
          renderFieldLabel={renderFieldLabel}
          renderActions={renderActions}
          editingField={editingField}
          renderSectionLabel={renderSectionLabel}
        />
      
       
        <FeeInformationView
          register={register}
          setValue={setValue}
          watch={watch}
          control={control}
          getValues={getValues}
          isEditing={isEditing}
          currencyDetails={currencyDetails}
          currentValues={currentValues}
          renderFieldLabel={renderFieldLabel}
          renderActions={renderActions}
          editingField={editingField}
          renderSectionLabel={renderSectionLabel}
        />
      </div>
      <div>&nbsp;</div>
        
      {/* <div id='adminChargesInformation'>
          <AdminChargesInformationView
            register={register}
            setValue={setValue}
            watch={watch}
            isEditing={isEditing}
            renderLabel={renderLabel}
            currencyDetails={currencyDetails}
            renderActions={renderActions}
            editingField={editingField}
            currentValues={currentValues}
          />
        </div>
        <div id='retainersInformation'>
          <RetainersInformationView
            register={register}
            setValue={setValue}
            watch={watch}
            control={control}
            currencyDetails={currencyDetails}
            currentValues={currentValues}
            isEditing={isEditing}
            reloadData={reloadData}
            handelScroll={handelScroll}
            setUpdatelLoader={setUpdatelLoader}
          />
        </div> */}
      
      {/* <div className="tab-view-right-nav col-sm-3 mt-2" style={{ position: "absolute", right: 0 }}>
        <div className="right-nav-section right-nav-recent-activity-section d-flex flex-column" style={{ position: "relative" }}>
          <a style={rightNav} href='/' className="right-nav-header" onClick={(e) => { e.preventDefault(); handelScroll('billingInformation') }}>
            Billing Information
          </a>
          <a style={rightNav} href='/' className="right-nav-header" onClick={(e) => { e.preventDefault(); handelScroll('feeInformation') }}>
            Fee Information
          </a>
          <a style={rightNav} href='/' className="right-nav-header" onClick={(e) => { e.preventDefault(); handelScroll('adminChargesInformation') }}>
            Admin Charges Information
          </a>
          <a style={rightNav} href='/' className="right-nav-header" onClick={(e) => { e.preventDefault(); handelScroll('retainersInformation') }}>
            Retainers Information
          </a>
        </div>
      </div> */}
    </div>
  )
}

BillingInfoView.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  watch: PropTypes.func,
  control: PropTypes.object,
  currentValues: PropTypes.object,
  isEditing: PropTypes.bool,
  renderFieldLabel: PropTypes.func,
  renderActions: PropTypes.func,
  editingField: PropTypes.string,
  getValues: PropTypes.func,
  reloadData: PropTypes.func,
  currencyDetails: PropTypes.object,
  handelScroll: PropTypes.func,
  setUpdatelLoader: PropTypes.func,
  renderSectionLabel: PropTypes.func
}
