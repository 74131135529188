
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useState, useEffect } from "react";

//-----------------------------------------------------------// External Imports // ------------------------------

import { Switch, Route, Redirect } from "react-router-dom";
import { ErrorBoundary } from "@sentry/react";

//-----------------------------------------------------------// Internal Imports // ------------------------------

import AddCompany from "./components/AddCompany";
import CompaniesList from "./Containers/Companies/List";
import AddContact from "./components/AddContact";
import ViewContact from "./components/ViewContact";
import ContactsList from "./Containers/Contacts/List";
import MyClientsList from "./Containers/Contacts/MyClientsList";
import MyBDTargetsList from "./Containers/Contacts/MyBDTargetsList";
import MyMergeContactList from "./Containers/Contacts/MyMergeContactList"
import AddUser from "./components/AddUser";
import UsersList from "./Containers/Users";
import CompaniesMyClients from "./Containers/Companies/MyClients/List";
import CompaniesMyTargets from "./Containers/Companies/MyBDTargets/List";
import { isLoggedInUserAdmin } from "./services/cognitoService";
import MySearchPage from "./Containers/Searches/MySearchPage";
import AddBD from "./components/AddBD";
import CreateSearch from "./components/CreateSearch";
import ViewSearch from "./components/ViewSearch";
import KGPSearchesList from "./Containers/Searches/KGPSearchesList";
import PipeLineSearches from "./Containers/Searches/PipeLineSearches";
import ActivityLog from "./components/ActivityLog";
import Fallback from "./Fallback";
import ViewCompany from "./components/ViewCompany";
import MyPlacements from "./Containers/Searches/MyPlacements";
import KGPPlacements from "./Containers/Searches/KGPPlacements";
import AddWorkbench from "./components/AddWorkbench";
import Workbenches from "./Containers/Workbenches/List";
import ViewWorkBench from "./components/ViewWorkbench";
import TokenValidator from "./Containers/Commons/TokenValidator";
import MasterSearch from "./components/MasterSearch";
import { ROUTE_KEY, ROUTES, ROUTE_TYPE, DASHBOARD } from "./services/constantService";
import Dashboard from "./Containers/Dashboard";
import MyLinkedinContacts from "./Containers/Contacts/MyLinkedinContacts";
import AddDocumentType from "./components/AddDocumentType";
import SearchApprovalPending from "./Containers/SearchApprovals/SearchApprovalPending";
import SearchApprovalRejected from "./Containers/SearchApprovals/SearchApprovalRejected";
import InvoiceSummary from "./Containers/InvoiceSummary/List";
import DocumentTypes from "./Containers/DocumentTypes/List";
import TranslateValues from "./Containers/TranslateValues/List";
import AddTranslateValue from "./components/AddTranslateValue";
import { checkSearchApprovalRoles } from "./Containers/Commons/Utils";
import { useSelector } from "react-redux";
import { getUserSelector } from "./selectors";
import BilledInvoice from "./Containers/InvoiceSummary/BilledInvoice";
import YetToBeBilled from "./Containers/InvoiceSummary/YetToBeBilled";
import NextTenDays from "./Containers/InvoiceSummary/NextTenDays";
import Overdue from "./Containers/InvoiceSummary/Overdue";
import ReadyToBill from "./Containers/InvoiceSummary/ReadyToBill";
/* import SfpaSearch from "./Containers/SfpaBuilder/List";
import AddSfpaBuilder from "./components/AddSfpaBuilder"; */
import ReportTemplate from "./Containers/ReportTemplate";


function Routes() {
  const [isAdmin, setIsAdmin] = useState(isLoggedInUserAdmin());
  const [billingAccess, setBillingAccess] = useState(true)
  const userData = useSelector(getUserSelector);
  useEffect(() => {
    const checkUserRole = async () => {
      const isAdmin = isLoggedInUserAdmin();
      setIsAdmin(isAdmin);
    };
    checkUserRole().then(null);
  }, [isAdmin]);

  useEffect(() => {
    if (userData) {
      const isAccess = checkSearchApprovalRoles(userData);
      setBillingAccess(isAccess)
    }
  }, [userData])

  const withErrorBoundary = (props, key = String(Date.now())) => {
    return (
      <ErrorBoundary key={key} fallback={Fallback}>
        {props}
      </ErrorBoundary>
    );
  };

  return (
    <Switch>
      <Route path={ROUTES.masterSearch}>
        {withErrorBoundary(<MasterSearch />, ROUTE_KEY.masterSearch)}
      </Route>
      <Route path={ROUTES.createSearch}>
        {withErrorBoundary(<CreateSearch />, ROUTE_KEY.createSearch)}
      </Route>
      <Route path={ROUTES.createSearchWithoutCurrentTab}>
        {withErrorBoundary(<CreateSearch />, ROUTE_KEY.createSearch)}
      </Route>
      <Route path={ROUTES.pSearches}>
        {withErrorBoundary(<PipeLineSearches />, ROUTE_KEY.pSearches)}
      </Route>
      <Route path={ROUTES.mySearchesKey}>
        {withErrorBoundary(<MySearchPage />, ROUTE_KEY.mySearches)}
      </Route>
      <Route path={ROUTES.mySearches}>
        {withErrorBoundary(<MySearchPage />, ROUTE_KEY.mySearches)}
      </Route>
      <Route path={ROUTES.validateToken}>{<TokenValidator />}</Route>
      <Route path={ROUTES.kgSearches}>
        {withErrorBoundary(<KGPSearchesList />, ROUTE_KEY.mySearches)}
      </Route>
      <Route path={ROUTES.myPlacements}>
        {withErrorBoundary(<MyPlacements />, ROUTE_KEY.myPlacements)}
      </Route>
      <Route path={ROUTES.kgPlacements}>
        {withErrorBoundary(<KGPPlacements />, ROUTE_KEY.kgPlacements)}
      </Route>
      <Route path={ROUTES.billedInvoice}>
        {withErrorBoundary(<BilledInvoice />, ROUTE_KEY.billedInvoice)}
      </Route>
      <Route path={ROUTES.yetToBeBilled}>
        {withErrorBoundary(<YetToBeBilled />, ROUTE_KEY.yetToBeBilled)}
      </Route>
      <Route path={ROUTES.nextTenDays}>
        {withErrorBoundary(<NextTenDays />, ROUTE_KEY.nextTenDays)}
      </Route>
      <Route path={ROUTES.overdue}>
        {withErrorBoundary(<Overdue />, ROUTE_KEY.overdue)}
      </Route>
      <Route path={ROUTES.readyToBill}>
        {withErrorBoundary(<ReadyToBill />, ROUTE_KEY.readyToBill)}
      </Route>
      <Route path={ROUTES.addBd}>
        {withErrorBoundary(<AddBD />, ROUTE_KEY.addBd)}
      </Route>
      <Route path={ROUTES.addTranslateValue}>
        {withErrorBoundary(<AddTranslateValue />, ROUTE_KEY.addTranslateValue)}
      </Route>
      <Route path={ROUTES.addDocumentType}>
        {withErrorBoundary(<AddDocumentType />, ROUTE_KEY.addDocumentType)}
      </Route>

      <Route path={ROUTES.readOnlyActivityLog}>
        {withErrorBoundary(<ActivityLog />, ROUTE_KEY.readOnlyActivityLog)}
      </Route>
      <Route path={ROUTES.readOnlyActivityLogWithoutCurrentTab}>
        {withErrorBoundary(<ActivityLog />, ROUTE_KEY.readOnlyActivityLog)}
      </Route>
      <Route path={ROUTES.activityLog}>
        {withErrorBoundary(<ActivityLog />, ROUTE_KEY.activityLog)}
      </Route>
      <Route path={ROUTES.activityLogWithoutCurrentTab}>
        {withErrorBoundary(<ActivityLog />, ROUTE_KEY.activityLog)}
      </Route>
      <Route path={ROUTES.viewSearchWithActivetab}>
        {withErrorBoundary(<ViewSearch />, ROUTE_KEY.viewSearch)}
      </Route>
      <Route path={ROUTES.viewSearch}>
        {withErrorBoundary(<ViewSearch />, ROUTE_KEY.viewSearch)}
      </Route>
      <Route path={ROUTES.viewSearch}>
        {withErrorBoundary(<ViewSearch />, ROUTE_KEY.viewSearch)}
      </Route>
      <Route path={ROUTES.viewSearchWithoutCurrentTab}>
        {withErrorBoundary(<ViewSearch />, ROUTE_KEY.viewSearch)}
      </Route>
      <Route path={ROUTES.searches}>
        <Redirect to={ROUTES.mySearches} />
      </Route>
      <Route path={ROUTES.addCompany}>
        {withErrorBoundary(<AddCompany />, ROUTE_KEY.addCompany)}
      </Route>
      <Route path={ROUTES.comMyClients}>
        {withErrorBoundary(<CompaniesMyClients />, ROUTE_KEY.comMyClients)}
      </Route>
      <Route path={ROUTES.comMyTargets}>
        {withErrorBoundary(<CompaniesMyTargets />, ROUTE_KEY.comMyTargets)}
      </Route>
      <Route path={ROUTES.viewCompany}>
        {withErrorBoundary(<ViewCompany />, ROUTE_KEY.viewCompany)}
      </Route>
      <Route path={ROUTES.viewCompanyWithoutCurrentTab}>
        {withErrorBoundary(<ViewCompany />, ROUTE_KEY.viewCompany)}
      </Route>
      <Route path={ROUTES.companies}>
        {withErrorBoundary(<CompaniesList />, ROUTE_KEY.companies)}
      </Route>
      <Route path={ROUTES.addContact}>
        {withErrorBoundary(<AddContact />, ROUTE_KEY.addContact)}
      </Route>
      <Route path={ROUTES.addContactWithoutCurrentTab}>
        {withErrorBoundary(<AddContact />, ROUTE_KEY.addContact)}
      </Route>
      <Route path={ROUTES.conClients}>
        {withErrorBoundary(<MyClientsList />, ROUTE_KEY.conClients)}
      </Route>
      <Route path={ROUTES.conMyTarget}>
        {withErrorBoundary(<MyBDTargetsList />, ROUTE_KEY.conMyTarget)}
      </Route>
      <Route path={ROUTES.conMergeContacts}>
        {withErrorBoundary(<MyMergeContactList />, ROUTE_KEY.conMergeContacts)}
      </Route>
      <Route path={ROUTES.linkedinContacts}>
        {withErrorBoundary(<MyLinkedinContacts />, ROUTE_KEY.linkedinContacts)}
      </Route>
      <Route path={ROUTES.readOnlyContactActivityLog}>
        {withErrorBoundary(<ActivityLog />, ROUTE_KEY.readOnlyContactActivityLog)}
      </Route>
      <Route path={ROUTES.contactActivityLog}>
        {withErrorBoundary(<ActivityLog />, ROUTE_KEY.contactActivityLog)}
      </Route>
      <Route path={ROUTES.readOnlyContactActivityLogWithoutCurrentTab}>
        {withErrorBoundary(<ActivityLog />, ROUTE_KEY.readOnlyContactActivityLog)}
      </Route>
      <Route path={ROUTES.contactActivityLogWithoutCurrentTab}>
        {withErrorBoundary(<ActivityLog />, ROUTE_KEY.contactActivityLog)}
      </Route>
      <Route path={ROUTES.viewContact}>
        {withErrorBoundary(<ViewContact />, ROUTE_KEY.viewContact)}
      </Route>
      <Route path={ROUTES.viewContactWithoutCurrentTab}>
        {withErrorBoundary(<ViewContact />, ROUTE_KEY.viewContact)}
      </Route>
      <Route path={ROUTES.contacts}>
        {withErrorBoundary(<ContactsList />, ROUTE_KEY.contacts)}
      </Route>
      {isAdmin && (
        <Route path={ROUTES.userAdd}>
          {withErrorBoundary(<AddUser />, ROUTE_KEY.userAdd)}
        </Route>
      )}
      <Route path={ROUTES.users}>
        {withErrorBoundary(<UsersList />, ROUTE_KEY.users)}
      </Route>
      <Route exact path={ROUTES.usersWithId}>
        {withErrorBoundary(<UsersList />, ROUTE_KEY.users)}
      </Route>
      <Route exact path={ROUTES.legacyArByPartnerDashboard}>
        {withErrorBoundary(<Dashboard viewName={DASHBOARD.LEGACY_AR_BY_PARTNER} dashboardName={'legacy_ar_by_partner'}/>, ROUTE_KEY.legacyArByPartnerDashboard)}
      </Route>
      <Route exact path={ROUTES.legacySalesByPartnerDashboard}>
        {withErrorBoundary(<Dashboard viewName={DASHBOARD.LEGACY_SALES_BY_PARTNER} dashboardName={'legacy_sales_by_partner'}/>, ROUTE_KEY.legacySalesByPartnerDashboard)}
      </Route>
      <Route exact path={ROUTES.legacyCashReportDashboard}>
        {withErrorBoundary(<Dashboard viewName={DASHBOARD.LEGACY_CASH_REPORT} dashboardName={'legacy_cash_report'} />, ROUTE_KEY.legacyCashReportDashboard)}
      </Route>
      <Route exact path={ROUTES.legacyRegionalSalesDashboard}>
        {withErrorBoundary(<Dashboard viewName={DASHBOARD.LEGACY_REGIONAL_SALES} dashboardName={'legacy_regional_sales'} />, ROUTE_KEY.legacyRegionalSalesDashboard)}
      </Route>
      {/* <Route exact path={ROUTES.arByPartnerDashboard}>
        {withErrorBoundary(<Dashboard viewName={DASHBOARD.AR_BY_PARTNER} showReport={true} />, ROUTE_KEY.arByPartnerDashboard)}
      </Route>
      <Route exact path={ROUTES.cashReportDashboard}>
        {withErrorBoundary(<Dashboard viewName={DASHBOARD.CASH_REPORT} showReport={true} />, ROUTE_KEY.cashReportDashboard)}
      </Route>
      <Route exact path={ROUTES.salesByPartnerDashboard}>
        {withErrorBoundary(<Dashboard viewName={DASHBOARD.SALES_BY_PARTNER} showReport={true} />, ROUTE_KEY.salesByPartnerDashboard)}
      </Route>
      <Route exact path={ROUTES.bDReportDashboard}>
        {withErrorBoundary(<Dashboard viewName={DASHBOARD.BD_REPORT} showReport={true} />, ROUTE_KEY.bdReportDashboard)}
      </Route> */}
      <Route path={ROUTES.defaultRoute}>
        <Redirect to={ROUTES.arByPartnerDashboard} />
      </Route>
      <Route exact path={ROUTES.documentTypeProductSetup}>
        {withErrorBoundary(<DocumentTypes />, ROUTE_KEY.documentTypeProductSetup)}
      </Route>
      {billingAccess && <Route path={ROUTES.searchApprovalPending}>
        {withErrorBoundary(<SearchApprovalPending />, ROUTE_KEY.searchApprovalPendidng)}
      </Route>}
      {billingAccess && <Route path={ROUTES.searchApprovalRejected}>
        {withErrorBoundary(<SearchApprovalRejected />, ROUTE_KEY.searchApprovalRejected)}
      </Route>}
      <Route path={ROUTES.invoiceSummary}>
        {withErrorBoundary(<InvoiceSummary />, ROUTE_KEY.invoiceSummary)}
      </Route>
      {/* {isAdmin && <Route exact path={ROUTES.addSfpaBuilder}>
        {withErrorBoundary(<AddSfpaBuilder />, ROUTE_KEY.addSfpaBuilder)}
      </Route>}
      {isAdmin && <Route exact path={ROUTES.sfpaBuilder}>
        {withErrorBoundary(<SfpaSearch />, ROUTE_KEY.SearchSfpaReport)}
      </Route>} */}
      {isAdmin && <Route exact path={ROUTES.reportTemplates}>
        {withErrorBoundary(<ReportTemplate />, ROUTE_KEY.reportTemplates)}
      </Route>}

      <Route path={ROUTES.translateValueProductSetup}>
        {withErrorBoundary(<TranslateValues />, ROUTE_KEY.translateValueProductSetup)}
      </Route>
      <Route path={ROUTES.workbenchAdd}>
        {withErrorBoundary(<AddWorkbench />, ROUTE_KEY.workbenchAdd)}
      </Route>
      <Route exact path={ROUTES.myWorkbenchesContacts}>
        {withErrorBoundary(
          <Workbenches type={ROUTE_TYPE.userContacts} />,
          ROUTE_KEY.myWorkbenchesContacts
        )}
      </Route>
      <Route exact path={ROUTES.myWorkbenchesSearches}>
        {withErrorBoundary(
          <Workbenches type={ROUTE_TYPE.userSearches} />,
          ROUTE_KEY.myWorkbenchesSearches
        )}
      </Route>
      <Route path={ROUTES.myWorkbenches}>
        {withErrorBoundary(
          <Workbenches type={ROUTE_TYPE.user} />,
          ROUTE_KEY.myWorkbenches
        )}
      </Route>
      <Route path={ROUTES.kgWorkbenchesContacts}>
        {withErrorBoundary(
          <Workbenches type={ROUTE_TYPE.kgContacts} />,
          ROUTE_KEY.kgWorkbenchesContacts
        )}
      </Route>
      <Route path={ROUTES.kgWorkbenchesSearches}>
        {withErrorBoundary(
          <Workbenches type={ROUTE_TYPE.kgSearches} />,
          ROUTE_KEY.kgWorkbenchesSearches
        )}
      </Route>
      
      <Route path={ROUTES.kgWorkbenches}>
        {withErrorBoundary(
          <Workbenches type={ROUTE_TYPE.kg} />,
          ROUTE_KEY.kgWorkbenches
        )}
      </Route>
      <Route path={ROUTES.archivedWorkbenchesContacts}>
        {withErrorBoundary(
          <Workbenches type={ROUTE_TYPE.archivedContacts} />,
          ROUTE_KEY.archivedWorkbenchesContacts
        )}
      </Route>
      <Route path={ROUTES.archivedWorkbenchesSearches}>
        {withErrorBoundary(
          <Workbenches type={ROUTE_TYPE.archivedSearches} />,
          ROUTE_KEY.archivedWorkbenchesSearches
        )}
      </Route>
      <Route path={ROUTES.archivedWorkbenches}>
        {withErrorBoundary(
          <Workbenches type={ROUTE_TYPE.archived} />,
          ROUTE_KEY.archivedWorkbenches
        )}
      </Route>
      <Route path={ROUTES.viewWorkbench}>
        {withErrorBoundary(<ViewWorkBench />, ROUTE_KEY.viewWorkbench)}
      </Route>
      <Route path={ROUTES.viewWorkbenchWithoutCurrentTab}>
        {withErrorBoundary(<ViewWorkBench />, ROUTE_KEY.viewWorkbench)}
      </Route>
      <Route path={ROUTES.workbenches}>
        <Redirect to={ROUTES.myWorkbenches} />
      </Route>
      <Route path={ROUTES.allCandidates}>
        {withErrorBoundary(<ViewSearch />, ROUTE_KEY.allCandidates)}
      </Route>
      <Route path={ROUTES.clientSuiteReport}>
        {withErrorBoundary(<ViewSearch />, ROUTE_KEY.clientSuiteReport)}
      </Route>
      <Route path={ROUTES.myLinkedinCandidates}>
        {withErrorBoundary(<ViewSearch />, ROUTE_KEY.myLinkedinCandidates)}
      </Route>
      <Route path={ROUTES.remaining}>
        <Redirect to={ROUTES.searches} />
      </Route>
    </Switch>
  );
}

export default Routes;
