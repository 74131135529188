export const pdlContactFieldTitles = {
  first_name: 'First Name',
  last_name: 'Last Name',
  current_job_title: 'Current Job Title',
  current_company_id: 'Current Company',
  industries: 'Industry',
  job_functions: "Job Functions",
  current_job_start_year: "Job Start Year",
  current_job_end_year: "Job End Year",
  gender: 'Gender',
  country: "Country",
  linkedin_url: "LinkedIn Link",
  pdl_linkedin_username: "LinkedIn Username",
  pdl_linkedin_id: "LinkedIn Id",
  pdl_facebook_url: "PDL Facebook URL",
  pdl_facebook_username: "PDL Facebook Username",
  pdl_facebook_id: "PDL FacebookId",
  pdl_twitter_url: "PDL Twitter URL",
  pdl_twitter_username: "PDL Twitter Username",
  pdl_github_url: "PDL Github URL",
  pdl_github_username: "PDL Github Username",
  job_history: "Job History <br /> (Selected items will overwrite existing job history in the system. Unselected items will be lost.)",
  education_details: "Education Details (Selected items will overwrite existing education details in the system. Unselected items will be lost.)",
  pdl_phone_numbers: "PDL Phone Numbers",
  pdl_interests: "PDL Interests",
  pdl_skills: "PDL Skills",
  pdl_emails: "PDL Emails",
  pdl_certifications: "PDL Certification",
  pdl_id: "PDL Id",
  pdl_summary: "PDL Summary",
  pdl_inferred_experience : "PDL Inferred Experience",
  pdl_job_last_updated: "PDL Job Last Updated",
  mobile_phone: 'Mobile Phone',
  work_email: 'Work Email',
  private_email: 'Private Email'
}

export const contactFieldTitles = {
  first_name: 'First Name',
  last_name: 'Last Name',
  current_company_id: 'Current Company',
  current_job_title: 'Current Job Title',
  industries: 'Industry',
  job_functions: "Job Functions",
  current_job_start_year: "Job Start Year",
  current_job_end_year: "Job End Year",
  age: 'Age',
  gender: 'Gender',
  country: "Country",
  state: 'State',
  city: 'City',
  linkedin_url: "LinkedIn Link",
  mailing_list: 'Mailing List',
  direct_line: 'Direct Line',
  mobile_phone: 'Mobile Phone',
  home_phone: 'Home Phone',
  work_email: 'Work Email',
  private_email: 'Private Email',
  bd_target_id: 'BD Target',
  relationship: 'Relationship',
  published_bio: 'Published Bio'
}

export const getContactValue = (contact, field, pdlContact = false, secondContact) => {
  if (field === 'current_company_id') {
    if (pdlContact) {
      if(secondContact) {
        return contact && contact.current_company_name ? contact.current_company_name : '';
      } else {
        return contact.company && contact.company?.name ? contact.company?.name : ''
      }
    } else {
      return contact.company && contact.company?.name ? contact.company?.name : '';
    }
  } else if (field === 'job_functions' || field === 'industries' || field === 'pdl_skills' || field === 'pdl_phone_numbers' || field === 'pdl_interests') {
    return contact[field]?.join(', ')
  } else if (field === 'job_history' || field === 'education_details' || field === 'pdl_emails') {
    return contact[field]?.length ? contact[field]?.join('') : null;
  } else if (field === 'mailing_list') {
    return contact.mailing_list_user?.name
  } else if (field === 'bd_target_id') {
    return contact.bd_target?.name
  } else if (field === 'age') {
    return contact.age ? String(contact.age) : contact.age
  }
  return contact[field]
}

export const getContactName = (contact) => {
  return `${contact.first_name || ''} ${contact.last_name || ''}`
}

export const getOldValueOfContact = (contact, field, index=null, pdlContact, secondContact, conflictCheck = false) => {
  const oldValue = {[field]: contact[field]};
  if (field === 'current_company_id') {
    if (pdlContact) {
      if (secondContact) {
        oldValue[field] = contact.current_company_name
      } else {
        if (conflictCheck) {
          oldValue[field] = contact.company?.name
        } else {
          oldValue[field] = contact.company?.id
        }
      }
    } else {
      oldValue[field] = contact.company && contact.company?.id ? contact.company?.id : null;
    }
  } else if (field === 'bd_target_id') {
    oldValue[field] = contact.bd_target?.id
  } else if (field === 'job_history') {
    if(index !== null && index !== undefined && index >= 0) {
      oldValue[field] = contact.job_histories[index]  
    } else {
      oldValue[field] = contact.job_histories ? JSON.parse(JSON.stringify(contact.job_histories)) : null
    }
  } else if (field === 'education_details') {
    if(index !== null && index !== undefined && index >= 0) {
      oldValue[field] = contact.contact_educations[index]  
    } else {
      oldValue[field] = contact.contact_educations ? JSON.parse(JSON.stringify(contact.contact_educations)) : null
    }
  } else if (field === 'pdl_emails') {
    oldValue[field] = contact.pdl_existEmail
  }
  return oldValue
}

export const capitalizeCaseKeys = [
  'first_name', 'last_name', 'industries', 'job_functions', 'current_company_id', 'country', 'pdl_skills', 'pdl_summary', 'current_job_title'
]

export const pdlContactKeys = [
  'first_name', 'last_name', 'current_company_id', 'current_job_start_year', 'current_job_title',
  'current_job_end_year', 'industries', 'job_functions', "gender", "country", 'linkedin_url',
  'mobile_phone', 'work_email', 'private_email', 'pdl_github_username', 'pdl_github_url', 'pdl_skills', 'pdl_phone_numbers', 'pdl_emails',
  'pdl_twitter_username', 'pdl_certifications', 'pdl_id', 'pdl_summary', 'pdl_inferred_experience', 
  'pdl_job_last_updated', 'pdl_twitter_url', 'pdl_facebook_id', 'pdl_facebook_username', 'pdl_facebook_url', 
  'pdl_linkedin_id', 'pdl_linkedin_username', 'job_history', 'education_details', 'data_fetching_status'
]

export const contactKeys = [
  'first_name', 'last_name', 'current_job_title', 'current_company_id', 'current_job_start_year',
  'current_job_end_year', 'industries', 'job_functions', "age", "gender", "country", 'state', 'city', 'linkedin_url',
  'mailing_list', 'direct_line', 'mobile_phone', 'home_phone', 'work_email', 'private_email', 'bd_target_id',
  'relationship', 'published_bio'
]