
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";

//-----------------------------------------------------------// External Imports // ------------------------------

import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import {
  Button,
  InputAdornment,
  FormControl,
  Input,
  InputLabel,
  Typography,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { KeyboardDatePicker } from "@material-ui/pickers";

//-----------------------------------------------------------// Internal Imports // ------------------------------

import IndustrySelection from "../../../components/IndustrySelection";
import CompanySelection from "../../../components/CompanySelection";
import CountrySelection from "../../../components/CountrySelection";
import JobFunctionSelection from "../../../components/jobFunctionSelection";
import UserSelection from "../../../components/UserSelection";
import SaveQueryDialog from "./SaveQueryDialog";
import SavedQuerySelection from "./SavedQueries";
import { getUserSelector } from "../../../selectors";
import OffLimitsSelection from "../../../components/OffLimitsSelection";
import JobTitleSelection from '../../../components/JobTitleSelection'
import GenderSelection from "../../../components/GenderSelection";

const defaultValues = {
  currentCompany: [],
  industry: [],
  previousCompany: [],
  country: [],
  bdTarget: [],
  createdBy: [],
  internationalExperience: [],
  title: [],
  current_job_title: null,
  city: null,
  state: null,
  name: null,
  saved_query: [],
};
const Query = forwardRef((props, ref) => {
  const { option } = props;
  const { handleSubmit, setValue, register, reset, watch } = useForm({
    defaultValues,
  });
  const userData = useSelector(getUserSelector);
  const [open, setOpen] = React.useState(false);
  const [queryData, setQueryData] = useState({});
  const [savedQueryData, setSavedQueryData] = useState(null)
  useEffect(() => {
    register({ name: "currentCompany" });
    register({ name: "jobFunction" });
    register({ name: "industry" });
    register({ name: "title" })
    register("country");
    register({ name: "previousCompany" });
    register({ name: "fromCreate" });
    register({ name: "toCreate" });
    register({ name: "bdTarget" });
    register({ name: "createdBy" });
    register("internationalExperience");
    register({ name: "diversity_candidate" });
    register({ name: "mailing_list" });
    register({ name: "current_job_title" })
    register({ name: "name" });
    register({ name: "city" })
    register({ name: "state" });
    register({ name: "gender" });
    // register({ name: "kg" });
  }, [register]);

  useImperativeHandle(ref, () => ({
    resetForm: () => {
      reset(defaultValues)
    },
    resetSavedValue: () => {
      setSavedQueryData(null)
      setQueryData({})
    }
  }));

  const handleClose = () => {
    setOpen(false);
    setQueryData({});
  };

  const handleSaveQuery = (data) => {
    setQueryData(data);
    setOpen(true);
  };

  const handleSaveQueryChange = (e, data) => {
    setSavedQueryData(data)
    if (data) {
      reset({ ...data.query });
      props.handleQuery({ ...data.query });
    }
  };

  const rowOne = () => {
    return (
      <div className="d-flex align-items-center">
        <FormControl className="query-input">
          <InputLabel focused htmlFor="contact">
            CONTACT NAME
          </InputLabel>
          <Input
            inputRef={register}
            name="name"
            id="contactName"
            placeholder={"Search"}
            startAdornment={
              <InputAdornment position="start" style={{ color: "#C4C4C4" }}>
                <SearchIcon />
              </InputAdornment>
            }
          />
        </FormControl>
        {props.type === "my-merge-contacts" && <JobTitleSelection
          multiple
          className="query-input"
          value={watch('title') || []}
          InputLabelProps={{ focused: true, shrink: true }}
          filterSelectedOptions={true}
          placeholder={"Select"}
          label={"CANDIDATES ON SEARCH #"}
          onChange={(e, data) => { setValue("title", Array.isArray(data) ? data : []); }}
        />}
        <CompanySelection
          multiple
          className="query-input"
          value={watch("currentCompany") || []}
          InputLabelProps={{ focused: true, shrink: true }}
          filterSelectedOptions={true}
          label={"CURRENT COMPANY"}
          placeholder={"Select"}
          onChange={(e, data) => {
            setValue("currentCompany", Array.isArray(data) ? data : []);
          }}
        />
        <FormControl className="query-input">
          <InputLabel focused htmlFor="currentJobTitle">
            {props.type === "my-merge-contacts" ?  'CURRENT JOB TITLE' : 'JOB TITLE' } 
          </InputLabel>
          <Input
            inputRef={register}
            name="current_job_title"
            id="currentJobTitle"
            placeholder={"Search"}
            startAdornment={
              <InputAdornment position="start" style={{ color: "#C4C4C4" }}>
                <SearchIcon />
              </InputAdornment>
            }
          />
        </FormControl>
        {props.type != "my-merge-contacts" && <JobFunctionSelection
          multiple
          value={watch("jobFunction") || []}
          className="query-input"
          InputLabelProps={{ focused: true, shrink: true }}
          filterSelectedOptions={true}
          placeholder={"Select"}
          label={"JOB FUNCTION"}
          onChange={(e, data) => {
            setValue(
              "jobFunction",
              Array.isArray(data) ? data.map((item) => item) : []
            );
          }}
        />}
      </div>
    );
  };

  const rowTwo = () => {
    return (
      <div className="d-flex align-items-center">
        {props.type === "my-merge-contacts" && <JobFunctionSelection
          multiple
          value={watch("jobFunction") || []}
          className="query-input"
          InputLabelProps={{ focused: true, shrink: true }}
          filterSelectedOptions={true}
          placeholder={"Select"}
          label={"JOB FUNCTION"}
          onChange={(e, data) => {
            setValue(
              "jobFunction",
              Array.isArray(data) ? data.map((item) => item) : []
            );
          }}
        />}
        <IndustrySelection
          multiple
          className="query-input"
          value={watch("industry") || []}
          InputLabelProps={{ focused: true, shrink: true }}
          filterSelectedOptions={true}
          placeholder={"Select"}
          label={"INDUSTRY"}
          onChange={(e, data) => {
            setValue("industry", Array.isArray(data) ? data : []);
          }}
        />
        <FormControl className="query-input">
          <InputLabel focused htmlFor="city">
            CITY
          </InputLabel>
          <Input
            inputRef={register}
            name="city"
            id="city"
            placeholder={"Search"}
            startAdornment={
              <InputAdornment position="start" style={{ color: "#C4C4C4" }}>
                <SearchIcon />
              </InputAdornment>
            }
          />
        </FormControl>
        <CountrySelection
          multiple
          value={watch("country") || []}
          className="query-input"
          InputLabelProps={{ focused: true, shrink: true }}
          filterSelectedOptions={true}
          label={"LOCATION"}
          placeholder={"Select"}
          onChange={(e, data) => {
            setValue("country", Array.isArray(data) ? data : []);
          }}
        />
        {props.type != "my-merge-contacts" && <FormControl className="query-input">
          <InputLabel focused htmlFor="state">
            STATE
          </InputLabel>
          <Input
            inputRef={register}
            name="state"
            id="state"
            placeholder={"Search"}
            startAdornment={
              <InputAdornment position="start" style={{ color: "#C4C4C4" }}>
                <SearchIcon />
              </InputAdornment>
            }
          />
        </FormControl>}
      </div>
    );
  };

  const rowEight = () => {
    return (
      <div className="d-flex align-items-center">
        <FormControl className="query-input">
          <InputLabel focused htmlFor="state">
            STATE
          </InputLabel>
          <Input
            inputRef={register}
            name="state"
            id="state"
            placeholder={"Search"}
            startAdornment={
              <InputAdornment position="start" style={{ color: "#C4C4C4" }}>
                <SearchIcon />
              </InputAdornment>
            }
          />
        </FormControl>
      </div>
    );
  };

  const rowThree = () => {
    return (
      <div className="d-flex align-items-center">
        <FormControl className="query-input">
          <InputLabel focused htmlFor="schoolName">
            SCHOOL NAME
          </InputLabel>
          <Input
            inputRef={register}
            name="schoolName"
            id="schoolName"
            placeholder={"Search"}
            startAdornment={
              <InputAdornment position="start" style={{ color: "#C4C4C4" }}>
                <SearchIcon />
              </InputAdornment>
            }
          />
        </FormControl>
        <CompanySelection
          multiple
          value={watch("previousCompany") || []}
          className="query-input"
          InputLabelProps={{ focused: true, shrink: true }}
          filterSelectedOptions={true}
          label={"PREVIOUS COMPANY"}
          placeholder={"Select"}
          onChange={(e, data) => {
            setValue("previousCompany", Array.isArray(data) ? data : []);
          }}
        />
        <FormControl className="query-input">
          <InputLabel focused htmlFor="previousJobTitle">
            PREVIOUS JOB TITLE
          </InputLabel>
          <Input
            inputRef={register}
            name="previousJobTitle"
            id="previousJobTitle"
            placeholder={"Search"}
            startAdornment={
              <InputAdornment position="start" style={{ color: "#C4C4C4" }}>
                <SearchIcon />
              </InputAdornment>
            }
          />
        </FormControl>
        <UserSelection
          option={option}
          multiple
          value={watch("bdTarget")}
          className="query-input"
          InputLabelProps={{ focused: true, shrink: true }}
          filterSelectedOptions={true}
          label={"BD TARGET"}
          placeholder={"Select"}
          onChange={(e, data) => {
            setValue("bdTarget", Array.isArray(data) ? data : []);
          }}
        />
      </div>
    );
  };

  const rowFour = () => {
    return (
      <div className="d-flex align-items-center second-row">
        <UserSelection
          option={option}
          multiple
          value={watch("createdBy")}
          className="query-input"
          InputLabelProps={{ focused: true, shrink: true }}
          filterSelectedOptions={true}
          label={"CREATED BY"}
          placeholder={"Select"}
          onChange={(e, data) => {
            setValue("createdBy", Array.isArray(data) ? data : []);
          }}
        />
        <div className="d-flex flex-column date-range query-input">
          <InputLabel
            className="range-label"
            focused
            shrink={true}
            htmlFor="date-picker-inline"
          >
            CREATED DATE RANGE
          </InputLabel>
          <div className="d-flex align-items-center ">
            <KeyboardDatePicker
              //disableToolbar
              autoOk
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              placeholder="From date"
              value={watch("fromCreate") || null}
              maxDate={watch("toCreate")}
              onChange={(date) => {
                setValue("fromCreate", date);
              }}
            />
            <Typography className="to-label">to</Typography>
            <KeyboardDatePicker
              //disableToolbar
              autoOk
              placeholder="End date"
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              minDate={watch("fromCreate")}
              value={watch("toCreate") || null}
              onChange={(date) => {
                setValue("toCreate", date);
              }}
            />
          </div>
        </div>
        <CountrySelection
          multiple
          value={watch("internationalExperience") || []}
          className="query-input"
          filterSelectedOptions={true}
          InputLabelProps={{ focused: true, shrink: true }}
          label="INTERNATIONAL EXPERIENCE"
          placeholder="Select"
          onChange={(e, data) => {
            setValue(
              "internationalExperience",
              Array.isArray(data) ? data : []
            );
          }}
        />
        <OffLimitsSelection
          className="query-input"
          value={watch("diversity_candidate")}
          InputLabelProps={{ focused: true, shrink: true }}
          filterSelectedOptions={true}
          label={"DIVERSITY CANDIDATE"}
          placeholder={"Select"}
          onChange={(e, data) => {
            setValue("diversity_candidate", data?.id ? data.id : "");
          }}
        />
      </div>
    );
  };

  const rowFive = () => {
    return (
      <div className="d-flex align-items-center">
        <UserSelection
          option={option}
          multiple
          value={watch("mailing_list") || []}
          className="query-input"
          InputLabelProps={{ focused: true, shrink: true }}
          filterSelectedOptions={true}
          label={"MAILING LIST"}
          placeholder={"Select"}
          onChange={(e, data) => {
            setValue("mailing_list", Array.isArray(data) ? data : []);
          }}
        />
        <GenderSelection 
          value={watch('gender') || null}
          className="query-input"
          InputLabelProps={{ focused: true, shrink: true }}
          filterSelectedOptions={true}
          label={"Gender"}
          placeholder={"Select"}
          onChange={(e, data) => {
            setValue("gender", data);
          }}
        />
        {/* <UserSelection
          option={option}
          multiple
          value={watch("kg")}
          className="query-input"
          InputLabelProps={{ focused: true, shrink: true }}
          filterSelectedOptions={true}
          label={"KG 25"}
          placeholder={"Select"}
          onChange={(e, data) => {
            setValue("kg", Array.isArray(data) ? data : []);
          }}
        /> */}
      </div>
    );
  };

  const rowSix = () => {
    return (
      <div className="d-flex align-items-center second-row mt-3">
        {props.type != "my-merge-contacts" && <Button
          className='mr-3'
          variant='outlined'
          color='primary'
          onClick={advanceHandler}>
          Switch to Advanced Query
        </Button>}
        <div style={{ position: "absolute", right: "80px" }}>
          <Button
            className={"query-button"}
            variant={"outlined"}
            color={"primary"}
            onClick={handleSubmit(props.handleQuery)}
          >
            Search
          </Button>
          <Button
            className={"save-query"}
            variant={"outlined"}
            color={"primary"}
            onClick={handleSubmit(handleSaveQuery)}
          >
            Save Query
          </Button>
        </div>
      </div>
    );
  };
  const rowSeven = () => {
    return (
      <div className="d-flex align-items-center second-row justify-content-between mt-4">
        <SavedQuerySelection
          type="contacts"
          onChange={handleSaveQueryChange}
          value={savedQueryData}
          refreshQueries={open}
          InputLabelProps={{ focused: true }}
          className="saved-queries"
          userId={userData?.id}
          disableClearable
          style={{ marginLeft: "auto", justifySelf: "end" }}
        />
      </div>
    );
  };

  const advanceHandler = () => {
    props.pullData()
  }

  return (
    <div className="query">
      <SaveQueryDialog
        type="contacts"
        open={open}
        queryData={queryData}
        userId={userData?.id}
        handleClose={handleClose}
      />
      {rowOne()}
      {rowTwo()}
      {props.type === "my-merge-contacts" && rowEight()}
      {props.type != "my-merge-contacts" && rowThree()}
      {props.type != "my-merge-contacts" && rowFour()}
      {props.type != "my-merge-contacts" && rowFive()}
      {rowSix()}
      {rowSeven()}
    </div>
  );
});

Query.propTypes = {
  handleQuery: PropTypes.func,
  export: PropTypes.func,
  isRowSelected: PropTypes.bool,
  pullData: PropTypes.func,
  option: PropTypes.array,
  type: PropTypes.string
};

export default Query;
