
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useEffect, useState } from "react";

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from "prop-types";
import Popup from "reactjs-popup";
import CloseIcon from "@material-ui/icons/Close";
import { Tooltip, Typography } from '@material-ui/core'
import { ERROR, POST, PUT, SUCCESS, WARNING } from "../../../../../../services/constantService";
import BusinessIcon from "@material-ui/icons/Business";
import PolicyOutlinedIcon from "@material-ui/icons/PolicyOutlined";
import DescriptionOutlinedIcon from "@material-ui/icons/DescriptionOutlined";
import FindInPageOutlinedIcon from "@material-ui/icons/FindInPageOutlined";
import WbIncandescentOutlinedIcon from "@material-ui/icons/WbIncandescentOutlined";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import { Button } from "@material-ui/core";

import './index.scss'
import MessageLoader from "../../../../../common/MessageLoader/MessageLoader";
import ConfirmationPopup from "../../../../../ConfirmationPopup";
import { successMessage, unableMessage, VALIDATION_MESSAGE } from "../../../../../../services/MessageService";
import { searchDataApi } from "../../../../../../services/ApiService";
import IndustrySelection from "../../../../../IndustrySelection";
import { AddRecordButton } from "../../../../../../utils/common";
import { useDispatch, useSelector } from "react-redux";
import { fetchPickList } from "../../../../../../actions";
import { isNull } from "lodash";
import { searchActions } from "../../../../../../store/searchSlice";
import { useCustomScrollToPosition } from "../../../../../../utils/Hooks/UseCustomScrollToPosition";

//-----------------------------------------------------------// Internal Imports // ------------------------------
let resultData;

export default function SfpaAutomationPopup(props) {
  const [highLighted, setHighLighted] = useState(0)
  const [showTable, setShowTable] = useState(false)
  const [loading, setLoading] = useState(false);
  const [statusMessage, setStatusMessage] = useState("");
  const [tableData, setTableData] = useState({});
  const [tabEdited, setTabEdited] = useState(false);
  const [tabChangeName, setTabChangeName] = useState([]);
  const [currentValues, setCurrentValues] = useState([]);
  const { onClose, search, sfpa, enqueueSnackbar, setSfpaAutoDataGenerated } = props;
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [isMultipleSelection, setIsMultipleSelection] = useState({});
  const [isClosePopup, setIsClosePopup] = useState(false);
  const [isSavePopup, setIsSavePopup] = useState(false);
  const [isRefreshTargetCompanies, setIsRefreshTargetCompanies] = useState(false)
  const [selectedItems, setSelectedItems] = useState([]);
  const [tabName, setTabName] = useState('target_industries');
  const [sfpaData, setSfpaData] = useState();
  const [initialSfpaDataSet, setInitialSfpaDataSet] = useState(false);
  const [availableCategory, setAvailableCategory] = useState([])
  const industries = useSelector((state) => state.commonReducer.industries);
  const dispatch = useDispatch()
  const [keys, setKeys] = useState();

  const { myEleRef, setScroll } = useCustomScrollToPosition('bottom');

  let count = 0;
  if (Array.isArray(tableData) && tableData.length > 0) {
    count = tableData.filter(item => item.isSelected === true).length;
  }
  const fetchSFPAData = async () => {
    const payload = {
      template_name: "SFPA-Search",
      search_id: search.id,
      mode: "initial"
    }
    setLoading(true);
    setStatusMessage('HELIA in Action. Hold tight while it fetches the data for you to create an epic Search Roadmap...');
    const { status, data } = await searchDataApi(POST, search.id, payload, 'sfpa-by-ai');
    if (status && status === 200) {
      if (data) {
        setSfpaData(data);
        setInitialSfpaDataSet(true);
        resultData = data;
      }
      //   setSfpaData(true);
    } else {
      //enqueueSnackbar(VALIDATION_MESSAGE.review_contact_fail, { variant: ERROR });
    }
    setLoading(false);
    setStatusMessage("");

  }

  useEffect(() => {
    if (!industries) {
      dispatch(fetchPickList(`industries`))
    } else {
      if (tabName === 'target_industries' && tableData && Array.isArray(tableData) && tableData.length > 0) {
        let existingIndustry = tableData.filter(item => item.name)
        let availableGroup = industries
          .filter(item => !existingIndustry.map(item => item.name).includes(item.name))
          .map(item => { return item })
        setAvailableCategory(availableGroup)
      }
    }
  }, [tableData])

  useEffect(() => {
    fetchSFPAData();
  }, [])


  useEffect(() => {
    if (initialSfpaDataSet) {
      getTargetRecords('target_industries', 0);
    }

  }, [initialSfpaDataSet]);



  const closePopup = () => {
    setIsAllSelected(false)
    setTableData(null)
    resultData = { ...resultData, [tabName]: tableData }
    delete resultData['target_compensation'];
    delete resultData['persistVariable'];
    if (sfpaData) {
      Object.keys(resultData).forEach((key) => {
        let newData = resultData[key]
        newData.map((item) => { if (item.isSelected) item.isSelected = false; })
      });
    }

    onClose && onClose();
  }


  const confirmClosePopup = () => {
    if (sfpaData) {
      let flag = false;
      tableData && tableData.map((item) => {
        if (item && item.isSelected === true) {
          flag = true;
        }
      })
      if (tabEdited || flag) {
        setIsClosePopup(true);
      }
      else {
        onClose && onClose();
      }
    }
    else {
      onClose && onClose();
    }
  }

  const handleDiscard = () => {
    // onClose && onClose();

    setIsClosePopup(false);
  }
  const onSave = async () => {
    resultData = { ...resultData, [tabName]: tableData }
    // const selectedData = {};
    delete resultData['target_compensation'];
    delete resultData['persistVariable'];
    const selectedNames = {};
    for (const category in resultData) {
      const selectedItems = resultData[category].filter((item) => item.isSelected);
      if (selectedItems.length > 0) {
        const names = selectedItems.map((item) => {
          if (category === 'skills') {
            const { name: title, ideal, minimum, question } = item;
            return { title, elements: [{ ideal, minimum, question }] };
          } else {
            return item.name;
          }
        });
        selectedNames[category] = names;
      }
    }
    const mergedObj = { ...sfpa };
    for (const key in selectedNames) {
      if (key === 'target_industries' && selectedNames.target_industries && selectedNames.target_industries.length > 0 && mergedObj.search_industry_rankings) {
        const filterIndustry = selectedNames.target_industries.filter(isNull)
        if (filterIndustry.length > 0) {
          enqueueSnackbar('pls enter the value in selected target industry', { variant: WARNING })
          setLoading(false);
          setIsSavePopup(false)
          return
        }
        const existingIndustryObj = mergedObj.search_industry_rankings.findIndex(obj => obj.industry === 'AI Managed');
        if (existingIndustryObj < 0) {
          const newIndustryObj = {
            industry: 'AI Managed',
            sub_industries: selectedNames.target_industries,
            ranking: 0
          };
          mergedObj.search_industry_rankings.push(newIndustryObj);
        } else if (existingIndustryObj >= 0) {
          selectedNames.target_industries.forEach((industry) => {
            const subIndustry = mergedObj.search_industry_rankings[existingIndustryObj].sub_industries.find(ele => ele === industry)
            if (!subIndustry) {
              mergedObj.search_industry_rankings[existingIndustryObj].sub_industries.push(industry)
            }
          })
        }
      } else if (key === 'target_titles' && selectedNames.target_titles && selectedNames.target_titles.length > 0 && mergedObj.contact_targets) {
        selectedNames.target_titles.forEach((title) => {
          const existingTitleObj = mergedObj.contact_targets.find(obj => obj.title === title);
          if (!existingTitleObj) {
            const newTitleObj = {
              title,
              employee_max: 0,
              employee_min: 0,
              revenue_max: "0",
              revenue_min: "0",
              ranking: null
            };
            mergedObj.contact_targets.push(newTitleObj);
          }
        });

      }

      else if (key === 'skills' && mergedObj.skills && mergedObj.skills.length > 0) {
        mergedObj.skills = mergedObj.skills.filter((skill) => {
          return skill.title !== '' || skill.elements.length > 0;
        });
        const mergedSet = new Set([...mergedObj[key], ...selectedNames[key]]);
        mergedObj[key] = Array.from(mergedSet);
      }
      // eslint-disable-next-line no-prototype-builtins
      else if (mergedObj.hasOwnProperty(key) && mergedObj.hasOwnProperty(key) !== 'target_industries' && mergedObj.hasOwnProperty(key) !== 'target_titles') {
        if (Array.isArray(mergedObj[key]) && Array.isArray(selectedNames[key])) {
          const mergedSet = new Set([...mergedObj[key], ...selectedNames[key]]);
          mergedObj[key] = Array.from(mergedSet);
          // eslint-disable-next-line no-prototype-builtins
        } else if (!mergedObj.hasOwnProperty(key) || !Array.isArray(mergedObj[key])) {
          mergedObj[key] = [];
        }
        if (Array.isArray(selectedNames[key])) {
          const mergedSet = new Set([...mergedObj[key], ...selectedNames[key]]);
          mergedObj[key] = Array.from(mergedSet);
        }
      } else {
        mergedObj[key] = selectedNames[key];
      }
    }
    mergedObj.aiUpdate = true;
    setLoading(true);
    const { status, data } = await searchDataApi(PUT, search.id, mergedObj, 'sfpa');

    if (status === 200) {
      const message = successMessage("Search Roadmap", VALIDATION_MESSAGE.updated_message)
      enqueueSnackbar(data?.message || message, { variant: SUCCESS });
      // It impacts on update latest search info tab data 
      dispatch(searchActions.resetSearchState());
    }
    else {
      const message = unableMessage("Search Roadmap", "update")
      enqueueSnackbar(data?.message || message, { variant: ERROR });
    }
    setLoading(false);
    setSfpaAutoDataGenerated(true);
    onClose && onClose();

  }


  useEffect(() => {
    let x = tableData && Object.values(tableData).every((v) => v.isSelected && v.isSelected === true);
    if (x) setIsAllSelected(true);
    else setIsAllSelected(false);
  }, [tabName, tableData])

  useEffect(() => {
    if (tabName === 'target_titles') {
      const searchTitle = [];
      searchTitle.push(search?.helia_title);
      setCurrentValues(searchTitle);

    } else if (tabName === 'skills') {
      const title = []
      if (search.helia_title)
        title.push(search.helia_title)
      setCurrentValues(title)
    } else if (tabName === 'target_industries') {
      const title = []
      if (search.industries)
        search.industries.map(item => {
          title.push(item.name)
        })
      setCurrentValues(title)

    } else if (tabName === 'target_companies') {
      const searchCompany = [];
      if (search.company && search.company.name)
        searchCompany.push(search.company.name)
      const company = searchCompany
      setCurrentValues(company)
    }
    else if (tabName === 'target_job_functions') {
      const title = []
      if (search.helia_title)
        title.push(search.helia_title)
      setCurrentValues(title)
    }
  }, [tabName])


  const getTargetRecords = async (tab) => {
    let keysArray = [];
    setShowTable(false)
    if (sfpaData) {
      const keysToStore = Object.keys(sfpaData);
      for (let i = 0; i < keysToStore.length; i++) {
        const key = keysToStore[i];
        if (key !== "target_compensation" && key !== "persistVariable") {
          keysArray.push(key);
        }
      }
      setKeys(keysArray)
      setShowTable(true)
      setTabName(tab);
      setTableData(sfpaData[tab]);
      setIsMultipleSelection(sfpaData[tab])
    }

  }


  const handleSaveClose = () => {
    setIsSavePopup(false);
  }

  const onSavePopup = () => {
    setIsSavePopup(true);
  }

  const onRefreshTargetCompany = () => {
    setIsRefreshTargetCompanies(true)
  }

  const handleRefreshTargetCompanyClose = () => {
    setIsRefreshTargetCompanies(false)
  }

  const handleRadioChange = async (tab, index) => {
    setHighLighted(index)
    await getTargetRecords(tab[index], index)
    let flag = false;
    tableData && tableData.map((item) => {
      if (item && item.isSelected === true) {
        flag = true;
        setTabEdited(true);
      }
    })
    if (flag) {
      setTabChangeName(prevArray => [...prevArray, tabName]);
    } else if (checkEdit(tabName)) {
      const newArray = tabChangeName.filter(value => value !== tabName)
      setTabChangeName(newArray);
    }
    resultData = { ...resultData, [tabName]: tableData }
  }

  const handleSelectAllChange = () => {

    const newData = [...tableData]
    if (isMultipleSelection) {

      newData.map((item) => {
        item.isSelected = !isAllSelected;
      });

      setTableData(newData);
      setIsAllSelected(!isAllSelected);
    }
  };

  const handleCheckboxChange = (itemId) => {
    const newData = [...tableData]
    if (isMultipleSelection) {
      const updatedItems = newData.map((item) => {
        if (item === newData[itemId]) {
          item.isSelected = !item.isSelected;
        } return item;
      });
      setSelectedItems(updatedItems.filter((item) => item.isSelected));
      setIsAllSelected(updatedItems.filter((item) => item.isSelected).length === newData.length);
    }
    else {
      const clickedItem = newData.find((item) => item === newData[itemId]);
      const updatedItems = newData.map((item) => {
        console.log(updatedItems);
        if (item === newData[itemId]) { item.isSelected = true; }
        else { item.isSelected = false; } return item;
      });
      setSelectedItems([clickedItem]);
    }
    console.log("dsdffdf", selectedItems);
  };

  const updateIndustriesList = (data, index) => {
    const newData = [...tableData]
    newData.forEach((item) => {
      if (item === newData[index]) {
        if (data === null || typeof (data) === 'string') {
          item.name = data
        } else {
          item.name = data.inputValue ? data.inputValue : data.name
        }
      }
    });
    setTableData(newData);
  }

  const deleteTargateIndustries = (index) => {
    const newData = [...tableData]
    newData.splice(index, 1);
    setTableData(newData);
    setSfpaData(prevSfpaData => ({
      ...prevSfpaData,
      ['target_industries']: newData
    }));
  }

  const getNameFormatted = (value) => {
    // if (value === 'helia_titles') {
    //   return 'Helia Titles';
    // }
    return value
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  }

  const onGetMore = async (initialCompanies = false) => {
    let templateName;
    let keyName;
    let dependingKeyName;
    let payload;
    let selectedTargetIndustryList;
    if (!initialCompanies) {
      if (tableData) {
        tableData.forEach((item) => {
          item.isNew = false
        })
      }
      if (tabName === 'target_companies') {
        selectedTargetIndustryList = sfpaData['target_industries'].filter((item) => item.isSelected && item.name != null)
        if (selectedTargetIndustryList.length === 0) {
          selectedTargetIndustryList = sfpaData['target_industries'].filter((item) => item.name != null)
        }
        templateName = 'SFPA-Company-Search-more'
        dependingKeyName = 'selectedTargetIndustryList'
        keyName = 'fetchedCompanyList'
      }
      else if (tabName === 'target_industries') {
        templateName = 'SFPA-Industry-Search'
        keyName = 'fetchedIndustryList'
      }
      else if (tabName === 'target_job_functions') {
        templateName = 'SFPA-Jobfunction-Search'
        keyName = 'fetchedJobFunctionList'
      }
      else if (tabName === 'skills') {
        templateName = 'SFPA-Skill-Search'
        keyName = 'fetchedSkillList'
      }
      else if (tabName === 'target_titles') {
        templateName = 'SFPA-Jobtitle-Search'
        keyName = 'fetchedJobTitleList'
      }
      payload = {
        template_name: templateName,
        mode: "more",
        variables: {
          searchCompany: search.company.name,
          searchIndustry: search.industries,
          [keyName]: tableData,
          [dependingKeyName]: selectedTargetIndustryList,
          key: keyName
        }
      }
    } else {
      let selectedIndustry;
      if (sfpaData['target_industries']) {
        selectedIndustry = sfpaData['target_industries'].filter((item) => item.isSelected && item.name != null)
        if (selectedIndustry.length === 0) {
          selectedIndustry = sfpaData['target_industries'].filter((item) => item.name != null)
        }
      }
      if (tabName === 'target_companies') {
        templateName = 'SFPA-Company-Search'
        keyName = 'selectedTargetIndustryList'
      }
      payload = {
        template_name: templateName,
        mode: "more",
        variables: {
          searchCompany: search.company.name,
          [keyName]: selectedIndustry,
          key: keyName
        }
      }
    }

    if (tabName === 'target_job_functions' || tabName === 'skills' || tabName === 'target_titles') {
      payload.variables = { ...payload.variables, searchJobTitle: search.helia_title };
    }
    setLoading(true);
    setStatusMessage('Loading more results for you.');
    const { status, data } = await searchDataApi(POST, search.id, payload, 'sfpa-by-ai');
    if (status && status === 200) {
      if (data) {
        setTableData(data[tabName]);
        setSfpaData(prevSfpaData => ({
          ...prevSfpaData,
          [tabName]: data[tabName]
        }));
      }
    }
    setLoading(false);
    setIsRefreshTargetCompanies(false);
    setStatusMessage('');
    setScroll(true);
  }

  const addNewRow = () => {
    const newData = [...tableData]
    if (Array.isArray(newData)) {
      newData.push({
        name: null,
        isNew: true,
        isSelected: true
      })
      setTableData(newData);
      setSfpaData(prevSfpaData => ({
        ...prevSfpaData,
        ['target_industries']: newData
      }));
    }
    setScroll(true);
  }

  const checkEdit = (e) => tabChangeName.includes(e);

  return (
    <Popup overlayStyle={{ zIndex: '1501 !important' }} className={"add-sfpa-value"} open={true} modal closeOnDocumentClick={false} closeOnEscape={false} >
      <>
        <div className='sfpa-assistant'>
          <MessageLoader show={loading} message={statusMessage}></MessageLoader>
          {isClosePopup ? <ConfirmationPopup
            header={VALIDATION_MESSAGE.sfpa_close}
            onConfirm={closePopup}
            onCancel={handleDiscard}
            onClose={handleDiscard}
            cancelText='No'
            confirmText='Yes'
          /> : null}
          {isSavePopup ? <div className='confirmation-popup-bd-search'> <ConfirmationPopup
            header={VALIDATION_MESSAGE.sfpa_save_confirm}
            onConfirm={onSave}
            onCancel={handleSaveClose}
            onClose={handleSaveClose}
            cancelText='Cancel'
            confirmText='Continue'
          />  </div> : null}
          {isRefreshTargetCompanies ? <div className='confirmation-popup-bd-search'> <ConfirmationPopup
            header={VALIDATION_MESSAGE.refresh_target_companies}
            onConfirm={function () { onGetMore(true) }}
            onCancel={handleRefreshTargetCompanyClose}
            onClose={handleRefreshTargetCompanyClose}
            cancelText='Cancel'
            confirmText='Continue'
          />  </div> : null}

          <div className="" style={{ background: '#2a364c', color: '#fff' }}>
            <div className="quick-add-header">
              <Typography >Search Roadmap Builder <Tooltip placement='top' title="Search Roadmap Builder uses the GPT API to fetch live data"><InfoOutlinedIcon className="cursor-pointer tooltip-icon" style={{ fontSize: '18px' }} /></Tooltip></Typography>
              <CloseIcon className="cursor-pointer" onClick={confirmClosePopup} />
            </div>
          </div>

          {!loading && !showTable && <div className='view-container d-flex align-items-center justify-content-center'>
            <div className='center'>
              Record Not Found
            </div>
          </div>}
          {showTable && <div className='view-container'>
            <div className='table-header sidebar-width'>
              <div>
                {keys.map((item, index) => (
                  <div className='table-header' key={index}>
                    <div onClick={function () {
                      handleRadioChange(keys, index)
                    }} className={"table-cell click " + ((index === highLighted) ? ' active ' : '')} >
                      <div className='product-one-score-content'>
                        {!checkEdit(item) && <div className="product-one-color tabs-icon"></div>}
                        {tabEdited && checkEdit(item) && <div className="product-one-color" style={{ backgroundColor: '#26C137' }}></div>}
                        <span className="action-icon">
                          {item === 'target_industries' && <PolicyOutlinedIcon fontSize="inherit" />}
                          {item === 'target_companies' && <BusinessIcon fontSize="inherit" />}
                          {item === 'target_job_functions' && <DescriptionOutlinedIcon fontSize="inherit" />}
                          {item === 'target_titles' && <FindInPageOutlinedIcon fontSize="inherit" />}
                          {item === 'skills' && <WbIncandescentOutlinedIcon style={{ transform: 'rotate(180deg)' }} fontSize="inherit" />}
                          {(item !== 'target_industries' && item !== 'target_companies' && item !== 'target_job_functions' && item !== 'target_titles' && item !== 'skills') && <AcUnitIcon fontSize="inherit" />}
                        </span>
                        <span>{item !== 'skills' ? getNameFormatted(item) : 'Experience'}</span></div>
                    </div>
                  </div>
                )

                )}

              </div>
            </div>


            <div className={(tabName === "target_industries" ? 'col-9 ' : "") + " px-2 table-width"}>
              <div className='py-4 d-flex'>
                <div className="col-10 d-inline-flex align-items-center pl-0">
                  {tabName === "target_companies" && tabEdited && checkEdit('target_industries') &&
                    <Button className="refreshTargetCompanies" onClick={onRefreshTargetCompany}>REFRESH TARGET COMPANIES</Button>
                  }
                  <div className={tabName === "target_companies" && tabEdited && checkEdit('target_industries') ? "col-9 d-inline-flex align-items-center" : "col-12 pl-0"}>
                    {currentValues && currentValues.length > 0 &&
                      (tabName === 'target_industries' ? <Tooltip placement='top' title={currentValues.map((item, index) => (
                        <span key={index}> {item}
                          {index !== currentValues.length - 1 && ','}
                        </span>
                      ))}>
                        <span className="text-black ellipsis-text"> {tabName !== 'skills' ? getNameFormatted(tabName) : 'Experience'} {tabName === 'target_companies' && 'in Industries'} similar to {tabName === 'target_companies' && 'selected on Target Industries page'} {(tabName === 'skills' || tabName === 'target_job_functions') && 'helia title'} {currentValues.map((item, index) => (
                          <span key={index} style={{ color: '#5c52c6' }}> {item}
                            {index !== currentValues.length - 1 && ','}
                          </span>
                        ))} {tabName !== 'target_companies' && tabName !== 'target_industries' && <span> for Company <span style={{ color: '#5c52c6' }}>{search?.company && search?.company?.name}</span></span>} </span></Tooltip>
                        : <Tooltip placement='top' title={<span className="text-black"> {tabName !== 'skills' ? getNameFormatted(tabName) : 'Experience'} {tabName === 'target_companies' && 'in Industries'} similar to {tabName === 'target_companies' && 'selected on Target Industries page'} {(tabName === 'skills' || tabName === 'target_job_functions') && 'helia title'} {tabName != 'target_companies' && currentValues.map((item, index) => (
                          <span key={index}> {item}
                            {index !== currentValues.length - 1 && ','}
                          </span>
                        ))} {tabName !== 'target_companies' && tabName !== 'target_industries' && <span> for Company <span>{search?.company && search?.company?.name}</span></span>} </span>
                        }>
                          <span className="text-black ellipsis-text"> {tabName !== 'skills' ? getNameFormatted(tabName) : 'Experience'} {tabName === 'target_companies' && 'in Industries'} similar to {tabName === 'target_companies' && 'selected on Target Industries page'} {(tabName === 'skills' || tabName === 'target_job_functions') && 'helia title'} {tabName != 'target_companies' && currentValues.map((item, index) => (
                            <span key={index} style={{ color: '#5c52c6' }}> {item}
                              {index !== currentValues.length - 1 && ','}
                            </span>
                          ))} {tabName !== 'target_companies' && tabName !== 'target_industries' && <span> for Company <span style={{ color: '#5c52c6' }}>{search?.company && search?.company?.name}</span></span>} </span></Tooltip>)}
                  </div>
                </div>
                <div className={((tabName === "target_companies" && tabEdited && checkEdit('target_industries')) ? 'd-inline-flex align-items-center justify-content-end ' : ' col-2 ') + "col-2 pr-0 text-end "}><span>Selected Count: {count}</span></div>
              </div>
              <div className="list-values">
                <table className="table table-bordered sfpa-input-checkbox table-style">
                  <thead style={{ background: '#2a364c', color: '#fff', position: 'sticky', top: 0 }}>
                    <tr>
                      <td>
                        <div className="d-flex gap-6">
                          <div>
                            <input
                              type="checkbox"
                              className="custom-checkbox"
                              checked={isAllSelected}
                              onChange={handleSelectAllChange}
                            />
                          </div>
                          <div className="border-left border-white border-left-width-2 border-content">{tabName !== 'skills' ? getNameFormatted(tabName) : 'Experience'}
                          </div>
                        </div>
                      </td>
                      {tabName === 'skills' && <td>
                        {"Minimal"}

                      </td>}{tabName === 'skills' && <td>
                        {"Ideal"}

                      </td>}
                      {tabName === 'skills' && <td style={{ width: "26.5%" }}>
                        {"Question"}

                      </td>}
                    </tr>
                  </thead>
                  <tbody ref={myEleRef}>
                    {tableData && tableData.map((item, index) => {
                      let modifiedName = item.name;

                      if (modifiedName && typeof modifiedName === 'string' && modifiedName.startsWith('AI Managed -')) {
                        modifiedName = modifiedName.replace('AI Managed -', '');
                      }
                      return (
                        <tr key={index}>
                          <td>
                            <div className="d-flex gap-6">
                              <input
                                type="checkbox"
                                className="custom-checkbox"
                                checked={item.isSelected}
                                onChange={() => handleCheckboxChange(index)}
                              />
                              <div className=""></div>
                              {tabName !== 'target_industries' && <div className="ml-4">
                                {modifiedName && typeof modifiedName === 'string' && modifiedName}
                              </div>}
                              {tabName === 'target_industries' &&
                                <div className="d-flex ml-4 col-5">
                                  <IndustrySelection
                                    value={modifiedName}
                                    // disabled={!isEditing}
                                    className='input-form-field input-field-old'
                                    label=''
                                    size='small'
                                    filterSelectedOptions={true}
                                    isSfpaAutomationIndustry={true}
                                    InputLabelProps={{ focused: true }}
                                    onChange={(e, data) => {
                                      updateIndustriesList(data, index)
                                    }}
                                    isGroupIndustry={true}
                                    availableCategory={availableCategory}
                                    variant="outlined"
                                  />
                                  <CloseIcon variant="large" style={{ color: "red" }} cursor='pointer' fontSize='medium' onClick={() => { deleteTargateIndustries(index) }} />
                                </div>
                              }
                            </div>
                          </td>

                          {tabName === 'skills' && (
                            <>
                              <td>
                                <div>{item.minimum}</div>
                              </td>
                              <td>
                                <div>{item.ideal}</div>
                              </td>
                              <td>
                                <div>{item.question}</div>
                              </td>
                            </>
                          )}
                        </tr>
                      );
                    })}


                  </tbody>
                </table>

              </div>
              {tabName === "target_industries" &&
                <div className="left-btn">
                  <AddRecordButton
                    onClick={addNewRow}
                    color={"red"}
                  >
                    Add
                  </AddRecordButton>
                </div>}
              <div className="right-btn">
                <Button className="getMore" onClick={function () {
                  onGetMore()
                }}>Get More</Button>
              </div>

            </div>
          </div>}

          {!loading && showTable && <div className="page-content-footer">
            <Button variant="contained" type="submit" className="page-btn-save" disabled={!showTable} onClick={onSavePopup}>Save to Search Roadmap</Button>

          </div>}
        </div>
      </>
    </Popup>
  )
}

SfpaAutomationPopup.propTypes = {
  onClose: PropTypes.func,
  search: PropTypes.object,
  sfpa: PropTypes.object,
  setSfpaAutoDataGenerated: PropTypes.bool,
  enqueueSnackbar: PropTypes.func,
}