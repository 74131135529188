//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from "prop-types";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { withSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { fetchKGTeam } from "../../../../../actions";

function KgpTeamSelection(props) {
  const {
    label = "KgTeam",
    placeholder = "",
    InputLabelProps,
    searchId,
    ...rest
  } = props

  const [options, setOptions] = useState([]);
  const kgTeam = useSelector(state => state.rootReducer.kgTeam);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!kgTeam) {
      dispatch(fetchKGTeam('kgTeam', searchId))
    }
    else {
      setOptions(kgTeam.filter(item => { if (item.user) return (item.user)}).map(item => ({...item.user})));
    }
  }, [searchId, kgTeam])

  return (
    <Autocomplete
      {...rest}
      options={options}
      getOptionLabel={(option) => (option.first_name || '') + ' ' + (option.last_name || '')}
      getOptionSelected={(option, value) => option.id === value.id}
      renderInput={(params) => (
        <TextField
          {...params}
          InputLabelProps={InputLabelProps}
          label={label}
          placeholder={placeholder}
        />
      )}
    />
  )
}

KgpTeamSelection.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  InputLabelProps: PropTypes.object,
  searchId: PropTypes.string,
  enqueueSnackbar: PropTypes.func
}

export default withSnackbar(KgpTeamSelection)
