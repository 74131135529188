
//-----------------------------------------------------------// Internal Imports // ------------------------------

import { hlDataApi } from "./ApiService";
import { PUT } from "./constantService";

export const initializeSSO = async (payload) => {
  const sub_route = `create-position`;
  let { status, data } = await hlDataApi(PUT, sub_route, { payload });
  if (status && status === 200) {
    const sub_route = `copy-candidate-documents`;
    let res = await hlDataApi(PUT, sub_route, { payload });
    if (res.status === 200) {
      const interviewerPayload = {
        userId: data.data.userId,
        existingSearch: data.data.existingSearch,
        positionId: data.data.positionId,
        workspaceId: data.data.workspaceId,
        candidateId: data.data.candidateId,
      };
      const sub_route = `add-interviewers-into-position`;
      return ({ status, data } = await hlDataApi(PUT, sub_route, {
        interviewerPayload,
      }));
    }
  }
  return { status, data };
};
