//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { Component } from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from "prop-types";
import { Button, Checkbox, Typography, FormControlLabel } from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import { withSnackbar } from "notistack";
import { saveAs } from "file-saver";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import NumberFormat from "react-number-format";

//----------------------------------------------// Internal Imports // -------------------------------------------------
import {
  addAsMyBDTarget,
  CustomLoadingOverlayComponent,
  PAGE_LIMIT,
  SUCCESS_STATUS_CODE
} from "../../../../Containers/Contacts/utils";
import {
  getFilterParamString, defaultColumns, TitleRenderer
} from "../../../../Containers/Searches/Utils"
import ActionsPopover from "../../../../Containers/Contacts/ActionsPopover";
import {
  addSearchesToWorkbench,
  currencyValueSetter,
  DateTimeRenderer,
  getCurrencyPrefix,
  loadColumnStateFromLocalStorage, removeSearchesFromWorkbench,
  saveColumnStateToLocalStorage
} from "../../../../Containers/Commons/Utils";
import CopyCandidatesToSearch from "../../../../Containers/Searches/AllCandidates/CopyCandidatesToSearch";
import ColumnFilter from "../../../../Containers/Commons/ColumnFilter";
import GenericCellEditor from "../../../../Containers/Commons/CellEditors/GenericCellEditor";
import Header from "../Header";
import AddContactToWorkbench from "../AddContactToWorkbench";
import ConfirmationPopup from "../../../ConfirmationPopup";
import Loader from "../../../common/Loader";
import AddContactsToWorkbench from '../../../../Containers/Commons/AddContactToWorkbench'
import CustomFilter from "../../../../Containers/Commons/CustomFilter";
import ColorLegend from "../../../../Containers/Commons/ColorLegend"
import PopoverStickOnHover from "../../../common/PopoverStickOnHover"
import AthenaInvitePopUpContact from "../../../ViewSearch/Components/SFPA/Components/ClientAppAccess/AthenaInvitePopUpContact";
import { CompanyNameRenderer } from '../../../../Containers/Companies/utils';
import {
  IdRenderer,
  columnDefs,
  renderActiveCount,
  renderTargetCount
} from '../../../../Containers/Searches/Utils';
import {
  DateRenderer
} from '../../../../Containers/Commons/Utils';
import { SCROLL_TIMEOUT } from "../../../../utils/common";
import { API, BLOB, EXPORT_LIMIT, GET, POST, PUT, ERROR, WARNING } from "../../../../services/constantService"
import PopupEditor from "../../../../Containers/Commons/CellEditors/PopupEditor";
import { dataSourceUtils } from "../../../../utils/dataSource";
import { picklistsColorsDataApi, workbenchDataApi } from "../../../../services/ApiService";
import { unableMessage, exportMessage, notFoundMessage, VALIDATION_MESSAGE } from "../../../../services/MessageService";
// import { workbenchActions } from "../../../../store/workbenchSlice";
import { pickListActions } from "../../../../store/pickListSlice";
// import QuickAddSearch from "../../../../Containers/Searches/AllCandidates/Components/QuickAddSearch";
class Searches extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowCount: 0,
      isRowSelected: false,
      // isQuickAddOpen: false,
      isLoading: false,
      isAllRowsSelected: false,
      reload: false,
      colorData: null,
      workBenchId: '',
      isAthenaPopupOpen: false,
      filterModel: {},
      showFilterCount: 0
    }
    this.getTotalCount = this.props.getTotalCount
  }

  dataSource = {
    getRows: async params => {
      try {
        this.setState({ showFilterCount: Object.keys(this.state.filterModel).length })
        const getURLs = {
          listURl: `${API.workbench}/${this.props.id}/searches`
        };
        const thisValue = { ...this };
        this.gridApi.showLoadingOverlay();
        const obj = {
          params: params,
          context: thisValue,
          pageLimit: PAGE_LIMIT,
          url: getURLs,
          subScreen: true
        }

        const { status, data } = await dataSourceUtils(obj, getFilterParamString);
        if (status === SUCCESS_STATUS_CODE) {
          if (data?.paging) {
            if (data?.paging?.totalCount === 0) {
              const message = notFoundMessage("records")
              this.props.enqueueSnackbar(message, { variant: WARNING })
            }
            this.setState({ rowCount: data.paging.totalCount })
            this.getTotalCount(data.paging.totalCount)
            params.successCallback(data.data, data.paging.totalCount);
          } else if (Object.keys(data).length) {
            if (data.length === 0) {
              const message = notFoundMessage("records")
              this.props.enqueueSnackbar(message, { variant: WARNING })
            }
            params.successCallback([data], 1)
          }
          this.state.isAllRowsSelected && this.setSelectAllRows(this.state.isAllRowsSelected);

        } else {
          params.failCallback();
        }
        this.gridApi.hideOverlay();
      } catch (e) {
        console.log("Error found in getRows::", e);
      }
    },
    rowCount: null
  };

  setSelectAllRows = (isAllRowsSelected) => {
    this.setState({ isAllRowsSelected })
    this.gridApi.forEachNode(node => {
      node.setSelected(isAllRowsSelected);
    });
  }

  handleAthenaPopupClose = () => {
    this.setState({ isAthenaPopupOpen: false });
    this.gridApi.onFilterChanged()
  }

  onGridReady = params => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this.setState({ isGridReady: true });
  };

  NameRenderer = (params) => {
    return <CompanyNameRenderer company={params?.data?.company} />
  }

  AddContactToRenderer = (params) => {
    const list = [
      {
        label: 'To Search',
        onClick: () => this.handleAddToSearch(params?.data?.id)
      },
      {
        label: 'As BD Target',
        onClick: () => addAsMyBDTarget(params?.data, this.props.userData, this.gridApi, this.props.enqueueSnackbar)
      }
    ]
    return params.data ? <ActionsPopover list={list} /> : null
  }

  handleAddToSearch = searchId => {
    this.setState({ isAddToSearchOpen: true, searchIds: [searchId] });
  };

  handleAddToSearchClose = () => {
    this.setState({ isAddToSearchOpen: false, searchIds: [] });
    this.gridApi.onFilterChanged()

  };

  addSearchesToWorkbench = async (searches) => {
    try {
      const { id, enqueueSnackbar } = this.props
      this.setState({ loading: true })
      const status = await addSearchesToWorkbench(id, searches, enqueueSnackbar)
      this.setState({ loading: false })
      return status
    } catch (e) {
      console.log("Error found in addSearchesToWorkbench::", e);
    }
  }

  // handleQuickAddSearchClose = async (search, addToWorkbench) => {
  //   this.setState({ isQuickAddOpen: false });
  //   if (addToWorkbench && search) {
  //     const success = await this.addSearchesToWorkbench([search.id])
  //     if (success) {
  //       this.gridApi.onFilterChanged()
  //     }
  //   }
  // };

  onFirstDataRendered = (params) => {
    const columnApi = params.columnApi
    const gridApi = params.api
    loadColumnStateFromLocalStorage('workbenchSearchesColumns', { columnApi, gridApi })
    const locationState = this.props.location.state
    if (locationState) {
      const sortModel = locationState.sortModel
      if (sortModel) {
        this.gridApi.setSortModel(sortModel);
      }

      const filterModel = locationState.filterModel
      if (filterModel) {
        this.gridApi.setFilterModel(filterModel);
      }
    }
  }

  saveColumnState = async () => {
    try {
      this.gridApi && this.gridApi.showLoadingOverlay()
      const columnApi = this.columnApi;
      const gridApi = this.gridApi
      await saveColumnStateToLocalStorage('workbenchSearchesColumns', { columnApi, gridApi })
      this.gridApi && this.gridApi.hideOverlay()
    } catch (e) {
      console.log("Error found in saveColumnState::", e);
    }
  }

  saveColumnStateForFilter = async () => {
    try {
      this.gridApi && this.gridApi.showLoadingOverlay()
      const columnApi = this.columnApi;
      const gridApi = this.gridApi
      await saveColumnStateToLocalStorage('workbenchSearchesColumns', { columnApi, gridApi }, true, false)
      this.gridApi && this.gridApi.hideOverlay()
    } catch (e) {
      console.log("Error found in saveColumnState::", e);
    }
  }

  saveColumnStateForSort = async () => {
    try {
      this.gridApi && this.gridApi.showLoadingOverlay()
      const columnApi = this.columnApi;
      const gridApi = this.gridApi
      await saveColumnStateToLocalStorage('workbenchSearchesColumns', { columnApi, gridApi }, false, true)
      this.gridApi && this.gridApi.hideOverlay()
    } catch (e) {
      console.log("Error found in saveColumnState::", e);
    }
  }

  handleAddToWorkbench = () => {
    this.setState({ isAddToWorkbenchOpen: true })
  }

  handleAddToWorkbenchClose = (added) => {
    if (added) {
      this.gridApi.onFilterChanged()
    }
    this.setState({ isAddToWorkbenchOpen: false })
  }

  getSelectedSearchIds = () => {
    // const contactIds = [];
    // this.gridApi.forEachNode((node) => {
    //   if (node.selected) {
    //     contactIds.push(node.data.id)
    //   }
    // })
    // return contactIds
    const SearchIds = [];
    this.gridApi.forEachNode((node) => {
      if (node.selected) {
        SearchIds.push(node.data.id)
      }
    })
    return SearchIds
  }

  dispatchColorPickList = (data) => {
    this.props.dispatch(pickListActions.updateColorPickList(data))
  }

  componentDidMount = async () => {
    const sub_route = '?type=workbench'
    if (this.props && !this.props.color_pick_list) {
      const { status, data } = await picklistsColorsDataApi(GET, this.props.id, '', sub_route, this.dispatchColorPickList);
      if (status === 200 && data !== null) {
        this.setState({ colorData: data });
      } else if (status === 200) {
        const message = notFoundMessage("color details")
        this.props.enqueueSnackbar(message, { variant: ERROR });
      } else {
        const message = unableMessage("color details", "fetch")
        this.props.enqueueSnackbar(data?.message || message, { variant: ERROR });
      }
    } else {
      this.setState({ colorData: this.props.color_pick_list });
    }

  }

  componentWillUnmount = () => {
    this.props.dispatch(pickListActions.updateColorPickList(this.state.colorData))
  }

  setColorDesc = (event, color, index) => {
    if (event.target.value.length <= 20) {
      let newArr = [...this.state.colorData];
      newArr = newArr.map((item, i) => i === index ? { ...item, desc: event.target.value } : { ...item });
      this.setState({ colorData: newArr });
      const data = { data: { workbench_id: this.props.id, colorId: color.id, desc: event.target.value } };

      setTimeout(async () => {
        const sub_route = '?type=workbench'
        await picklistsColorsDataApi(PUT, this.props.id, data, sub_route)
      }, 500);
    }
  }

  getColorByValue = (value) => {
    return this.state.colorData?.find(item => item.value === Number(value)) || {}
  }

  RenderColorsList = (params) => {
    const handleChange = async (event) => {
      this.setState({ isLoading: true });
      const color = event.target.name;
      const data = { search_color: color, searchId: params.data?.id }
      const sub_route = 'workbench-search'
      const { status } = await workbenchDataApi(PUT, this.props.id, data, sub_route);
      this.setState({ isLoading: false });
      if (status === 200) {
        params.node.setDataValue('search_color', color);
      } else {
        const message = unableMessage("Search", "update")
        this.props.enqueueSnackbar(message, { variant: ERROR });
      }
    };

    return <div className="d-flex colors-container justify-content-between">
      {this.state.colorData?.map(item => {
        return <div key={item.hex_code} className="color-code-container d-flex" style={{ background: `#${item.hex_code}`, color: `#${item.hex_code}` }}>
          <FormControlLabel
            style={{ margin: 0 }}
            control={<Checkbox style={{ padding: 0, width: 16, height: 16 }} color="default" checked={params.value === item.value} onChange={handleChange} name={item.value} />}
            label=""
          />
        </div>
      })}
    </div>
  }

  ColorRenderer = (params) => {
    let color
    if (params.data && params.data?.search_color) {
      const isArray = Array.isArray(params.data?.search_color)
      if (isArray) {
        color = this.getColorByValue(params.data?.search_color[0]?.search_color);
      } else {
        color = this.getColorByValue(params.data?.search_color);
      }
    }
    if (params?.data?.color) {
      color = this.getColorByValue(params?.data?.color);
    }
    return <div className="d-flex align-items-center">
      <div className="color-code-box" style={{ background: `#${color?.hex_code}` }} />
      <PopoverStickOnHover
        component={this.RenderColorsList(params)}
        placement="bottom"
        onMouseEnter={() => { }}
        delay={200}>
        <ExpandMoreIcon color="primary" className="cursor-pointer" />
      </PopoverStickOnHover>
    </div>
  }

  // handleCopyCandidates = () => {
  //   const contactIds = this.getSelectedSearchIds()
  //   this.setState({ isAddToSearchOpen: true, contactIds })
  // }

  handleChange = (event) => {
    const checked = event.currentTarget.checked
    this.setState({ isAllRowsSelected: checked })
    this.gridApi.forEachNode(node => {
      node.setSelected(checked);
    });
  };
  HeaderCheckbox = () => {
    return (
      <Checkbox
        style={{ padding: 0, width: 16, height: 16, color: "white" }}
        size="small"
        color="primary"
        onChange={this.handleChange}
      />
    )
  }
  // handleQuickAddSearch = () => {
  //   this.setState({
  //     isQuickAddOpen: true
  //   });
  // };

  handleRemoveSearches = () => {
    this.setState({ isRemoveConfirmationOpen: true })
  }

  handleConfirmPopupClose = () => {
    this.setState({ isRemoveConfirmationOpen: false })
    this.setState({ removeSearchId: false })
  }

  removeSearches = async () => {
    try {
      const { id, enqueueSnackbar } = this.props
      const searcheIds = this.getSelectedSearchIds()

      this.setState({ isLoading: true })
      const removed = await removeSearchesFromWorkbench(id, searcheIds, enqueueSnackbar, { ...this.state.filterModel })
      if (removed) {
        this.setState({ isRowSelected: false })
        this.gridApi.onFilterChanged()
      }

      this.setState({ isLoading: false })
      this.handleConfirmPopupClose()
    } catch (e) {
      console.log("Error found in removeSearches::", e);
    }
  }

  removeSearch = async () => {
    try {
      const { id, enqueueSnackbar } = this.props
      const searcheId = [this.state.searchId];
      this.setState({ isLoading: true })
      const removed = await removeSearchesFromWorkbench(id, searcheId, enqueueSnackbar, { ...this.state.filterModel })
      if (removed) {
        this.gridApi.onFilterChanged()
      }
      this.setState({ isLoading: false })
      this.handleConfirmPopupClose()
    } catch (e) {
      console.log("Error found in removeSearch::", e);
    }

  }

  exportSearches = async () => {
    let selectedSearchIds = []
    selectedSearchIds = this.getSelectedSearchIds()
    if (this.state.isAllRowsSelected && this.state.rowCount > EXPORT_LIMIT) {
      const message = exportMessage(`${EXPORT_LIMIT}`, "Workbench-Search")
      this.props.enqueueSnackbar(message, { variant: ERROR })
    }
    else if (!this.state.isAllRowsSelected && selectedSearchIds.length > EXPORT_LIMIT) {
      const message = exportMessage(`${EXPORT_LIMIT}`, "Workbench-Search")
      this.props.enqueueSnackbar(message, { variant: ERROR })
    }
    else {
      let sub_route = `searches/export?`;
      if (Object.keys(this.state.filterModel).length) {
        sub_route = sub_route.concat(getFilterParamString(this.state.filterModel));
      }
      if (this.state.isAllRowsSelected) selectedSearchIds = [];
      const columnHeaders = this.columnApi.getAllDisplayedColumns().map(column => column.getColDef().headerName);
      this.setState({ isLoading: true })
      const payload = { headers: { columnHeaders: columnHeaders, selectedRows: { selectedSearchIds }, allRowSelected: this.state.isAllRowsSelected, limit: 10000 } }
      const { status, data } = await workbenchDataApi(POST, this.props.id, payload, sub_route, BLOB);
      if (status === 200) {
        saveAs(new File([data], 'workbench_searches.xlsx'));
      } else if (status === 400) {
        const message = exportMessage(`${EXPORT_LIMIT}`, "Workbench-Searches")
        this.props.enqueueSnackbar(message, { variant: ERROR })
      }
      else {
        const message = unableMessage("Workbench-Searches", "Export")
        this.props.enqueueSnackbar(data?.message || message, { variant: ERROR })
      }
      this.setState({ isLoading: false })
    }
  };

  handleCopySearchesToWorkbench = () => {
    const searchIds = this.getSelectedSearchIds()
    this.setState({ isCopyToWorkbenchOpen: true, searchIds })
  }

  handleCopySearchesToWorkbenchClose = () => {
    this.setState({ isCopyToWorkbenchOpen: false })
  }

  FeeAmountRenderer = (params) => {
    const currencyCode = params.data && params.data.currency ? params.data.currency : ''
    const baseSalary = params.data && params.data.fee_amount ? params.data.fee_amount : ''
    return <NumberFormat
      style={{ border: 'none', fontSize: '15px' }}
      color="primary"
      value={baseSalary}
      thousandSeparator={true}
      decimalSeparator='.'
      displayType='text'
      prefix={getCurrencyPrefix(currencyCode !== 'RUR' ? currencyCode : '')}
      suffix={getCurrencyPrefix(currencyCode === 'RUR' ? 'RUR' : '')}
      renderText={(data) => {
        const value = currencyValueSetter(data, currencyCode)
        return value ? value : '--'
      }}
    />
  }
  resetFilter = async () => {
    this.gridApi.setFilterModel(null);
    const customFilterField = this.columnApi.columnController.columnDefs;
    customFilterField.map(item => {
      if (item.filter === 'CustomFilter') {
        this.gridApi.destroyFilter(item.field);
      }
    })
  }

  resetSort = async () => {
    this.gridApi.setSortModel(null);
  }

  ActionsRenderer = (params) => {
    const extraClass = this.getExtraClassName()
    if (params.data) {
      const list = [
        {
          label: 'Remove from Workbench',
          onClick: () => {
            this.setState({ removeSearchId: true })
            this.setState({ searchId: params.data?.id })
          }
        }
      ]
      return <ActionsPopover list={list} className={extraClass} />
    }
    return null
  }

  getExtraClassName = () => {
    return this.props.isLockedWorkbench ? 'locked' : ''
  }

  onSortChanged = (params) => {
    const sortModel = params.api.getSortModel();
    this.setState({ sortModel })
  }

  onFilterChanged = (params) => {
    const filterModel = params.api.getFilterModel();
    this.setState({ filterModel })
    this.saveColumnStateForFilter()
  }

  loaderChange = async (value) => {
    this.setState({ isLoading: value })
  }

  render() {
    const { workbench, id, reloadData, option, userData, isLockedWorkbench } = this.props
    // const { filter } = this.state.filterModel
    const {
      isRowSelected, isAddToSearchOpen, searchIds, removeSearchId, isAddToWorkbenchOpen, /*isQuickAddOpen,*/
      isRemoveConfirmationOpen, isLoading, isCopyToWorkbenchOpen, isAllRowsSelected, isAthenaPopupOpen
    } = this.state
    const queryData = isAllRowsSelected ? Object.fromEntries(new URLSearchParams(getFilterParamString(this.gridApi.getFilterModel()))) : null
    const extraClassName = this.getExtraClassName()

    return (
      <div className="list-work-benches d-flex flex-column">
        <Loader show={isLoading} />
        {isAddToSearchOpen && (
          <CopyCandidatesToSearch
            header={VALIDATION_MESSAGE.add_contact_to_search}
            buttonText={VALIDATION_MESSAGE.add_contact_to_search}
            uiLabel="contact"
            open={isAddToSearchOpen}
            searchIds={searchIds}
            workbenchId={isRowSelected ? id : null}
            handleClose={this.handleAddToSearchClose}
            queryData={queryData}
            hide={true}
          />
        )}
        {isRemoveConfirmationOpen &&
          <ConfirmationPopup
            message="Are you sure you want to remove the searches from this workbench?"
            onClose={this.handleConfirmPopupClose}
            onConfirm={this.removeSearches}
          />
        }
        {
          removeSearchId &&
          <ConfirmationPopup
            message="Are you sure you want to remove the searches from this workbench?"
            onClose={this.handleConfirmPopupClose}
            onConfirm={this.removeSearch}
          />
        }
        {
          isCopyToWorkbenchOpen &&
          <AddContactsToWorkbench
            open={isCopyToWorkbenchOpen}
            searchIds={searchIds}
            onClose={this.handleCopySearchesToWorkbenchClose}
            sourceWorkbenchId={isAllRowsSelected ? id : null}
            type="Searches"
          />
        }
        {isAthenaPopupOpen ? <AthenaInvitePopUpContact inviteFor={'candidate'} selectedDropDown={'Athena'} client={this.state.athenaData} onClose={this.handleAthenaPopupClose} /> : null}
        <AddContactToWorkbench
          open={isAddToWorkbenchOpen}
          handleClose={this.handleAddToWorkbenchClose}
          addToWorkbench={this.addSearchesToWorkbench}
          type="Searches"
          title="Search"
        />
        {/* <QuickAddSearch
          open={isQuickAddOpen}
          handleClose={this.handleQuickAddSearchClose}
          isAddToSearchEnabled={true}
          addToText='Create Search & Add To Workbench'
        /> */}
        <Header
          name={workbench.name}
          users={workbench.tagged_users}
          workbench={workbench} showEdit={false}
          reloadData={reloadData}
          isLockedWorkbench={isLockedWorkbench}
        >
          <Button
            variant='contained'
            color='primary'
            onClick={this.exportSearches}
            disabled={!isRowSelected}
          >
            Export
          </Button>
        </Header>
        <div className='d-flex align-items-center p-3'>
          {/* <Button
            color="primary"
            variant="outlined"
            className='action-button'
            onClick={this.handleQuickAddSearch}
          >
            Quick Add Search
          </Button> */}
          <Button
            color='primary'
            variant='outlined'
            className='action-button'
            onClick={this.handleAddToWorkbench}
            disabled={isLockedWorkbench}
          >
            Add Searches To Workbench
          </Button>
          <Button
            onClick={this.handleCopySearchesToWorkbench}
            className='action-button'
            disabled={!isRowSelected}
            variant='outlined'
            color='primary'
          >
            Copy Searches to Workbench
          </Button>
          {/* <Button
            disabled={!isRowSelected}
            onClick={this.handleCopyCandidates}
            className='action-button'
            variant='outlined'
            color='primary'
          >
            Copy Contacts to Search
          </Button> */}
          <Button
            color='primary'
            variant='outlined'
            className='action-button'
            disabled={!isRowSelected || isLockedWorkbench}
            onClick={this.handleRemoveSearches}
          >
            Remove Searches From Workbench
          </Button>
        </div>
        <div className="count-container">
          <div
            className="action-container"
            style={{ minWidth: '0' }}
            onClick={() => this.resetFilter()}
          >
            <span className="action-text" >
              Reset Filter
            </span>
          </div>
          <div
            className="action-container"
            style={{ minWidth: '0' }}
            onClick={() => this.resetSort()}
          >
            <span className="action-text" >
              Reset Sort
            </span>
          </div>
          <ColorLegend colorList={this.state.colorData} setColorDesc={this.setColorDesc} extraClassName={extraClassName} />
          <Typography>Total count: {this.state.rowCount}</Typography>
        </div>
        <div className="list-view flex-grow-1">
          {this.state.isGridReady && <ColumnFilter columnApi={this.columnApi} defaultColumns={defaultColumns} showFilterCount={this.state.showFilterCount} filterModel={this.state.filterModel} />}
          <div id="myGrid" className="ag-theme-alpine">
            <AgGridReact
              onGridReady={this.onGridReady}
              enableBrowserTooltips={true}
              defaultColDef={{
                minWidth: 100,
                resizable: true,
                sortable: true,
                sortingOrder: ['asc', 'desc', null]
              }}
              cacheBlockSize={PAGE_LIMIT}
              loadingOverlayComponent={'CustomLoadingOverlayComponent'}
              frameworkComponents={{
                CustomLoadingOverlayComponent,
                IdRenderer,
                DateTimeRenderer,
                ColorRenderer: this.ColorRenderer,
                NameRenderer: this.NameRenderer,
                FeeAmountRenderer: this.FeeAmountRenderer,
                DateRenderer,
                TitleRenderer,
                renderActiveCount,
                renderTargetCount,
                CustomFilter,
                HeaderCheckbox: this.HeaderCheckbox,
                ActionsRenderer: this.ActionsRenderer,
                GenericCellEditor,
                PopupEditor
              }}
              blockLoadDebounceMillis={SCROLL_TIMEOUT}
              scrollbarWidth={12}
              suppressHorizontalScroll={false}
              rowModelType={'infinite'}
              datasource={this.dataSource}
              columnDefs={columnDefs(true, true, true, this.props.enqueueSnackbar, this.loaderChange, option, true, userData.roles[0])}
              paginationPageSize={PAGE_LIMIT}
              rowSelection={'multiple'}
              suppressRowClickSelection={true}
              suppressMenuHide={true}
              suppressDragLeaveHidesColumns={true}
              onDisplayedColumnsChanged={this.saveColumnState}
              onDragStopped={this.saveColumnState}
              onFirstDataRendered={this.onFirstDataRendered}
              onFilterChanged={this.onFilterChanged}
              onSortChanged={this.saveColumnStateForSort}
              onRowSelected={(e) => {
                if (!e.node.selected) {
                  this.setState({ isAllRowsSelected: false })
                }
                this.setState({ isRowSelected: this.gridApi.getSelectedRows().length > 0 ? true : false })
              }}
            >
            </AgGridReact>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    userData: state.commonReducer.userData,
    color_pick_list: state.pickListReducer.color_pick_list
  }
};

const mapDispatchToProps = (dispatch) => ({ dispatch });


export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(withRouter(Searches)));

Searches.propTypes = {
  workbench: PropTypes.object,
  enqueueSnackbar: PropTypes.func,
  closeSnackbar: PropTypes.func,
  color_pick_list: PropTypes.func,
  dispatch: PropTypes.func,
  id: PropTypes.string,
  location: PropTypes.object,
  history: PropTypes.object,
  reloadData: PropTypes.func,
  userData: PropTypes.object,
  getTotalCount: PropTypes.func,
  option: PropTypes.array,
  isLockedWorkbench: PropTypes.bool
}
