
//----------------------------------------------// In-built Imports // -------------------------------------------------
import React from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import TextField from '@material-ui/core/TextField';


/* eslint-disable-next-line react/display-name */
const TextArea = React.forwardRef(({ ...rest }, ref) => {
  return (
    <TextField
      {...rest}
      inputRef={ref}
      multiline
    />
  )
});

export default TextArea;
