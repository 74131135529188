import moment from "moment";
import momentTimeZone from "moment-timezone"
export const DATE_FORMAT_WITHOUT_YEAR = "DD MMM";
export const DEFAULT_DATE_FORMAT = "DD MMM YYYY";
export const DEFAULT_TIME_FORMAT = "HH:mm:ss";
export const DECISION_PRIOR_DATE = new Date("2023-01-01");

export const formatDate = (date, format = DEFAULT_DATE_FORMAT) => {
  if (!date) {
    return null;
  }
  return moment(date).format(format);
};
export const formatDateWithMomentTimeZone = (date, format = DEFAULT_DATE_FORMAT) => {
  if (!date) {
    return null;
  }
  return momentTimeZone(date).format(format);
};
export const formatTime = (date, format = DEFAULT_TIME_FORMAT) => {
  if (!date) {
    return null;
  }
  return moment(date).format(format);
};

export const getAge = (date) => {
  return date ? moment().diff(moment(date), "days") : null;
};

export const getDifferenceTime = (date, type='minute') => {
  return date ? moment().diff(moment(date), type) : null;
}

export const getDifferenceInDays = (date1, date2) => {
  if (!date1 || !date2) {
    return null;
  }
  return moment(date1).diff(moment(date2), "days");
};

export const validateStartYearEndYear = (value) => {

  if (value && Array.isArray(value)) {
    for (let x of value) {
      if (x.start_year && x.end_year) {
        return (value).find(ele => (ele?.start_year && ele?.end_year && (ele?.end_year !== "Present") && (ele?.start_year > ele?.end_year))) || {};

      }
      else if (x.start_date && x.end_date) {
        return (value).find(ele => (ele?.start_date && ele?.end_date && (ele?.end_date !== "Present") && (ele?.start_date > ele?.end_date))) || {};
      }
      else {
        return null
      }
    }
  }
  else if (value && (value.start_year || value.start_date) && (value.end_year || value.end_date)) {
    if (((value.start_year || value.start_date) > (value.end_year || value.end_date)) && (value.end_year !== "Present" || value.end_date !== "Present")) {
      return value;
    }
    else {
      return null;
    }
  }
  else {
    return null;
  }
}

export const getTheDaysDifference = (data) => {
  let days;
  if (!data.off_hold_date && data.on_hold_date) {
    days = getDifferenceInDays(new Date(), data.on_hold_date);
  }
  if (data.on_hold_date && data.off_hold_date) {
    days = getDifferenceInDays(data.off_hold_date, data.on_hold_date);
  }
  if (!days && days !== 0) {
    return "--";
  }
  return days;
};

export const getTheDaysDifferences = (data) => {
  let days;
  if (data.start_date && data.close_date && !data.on_hold_date) {
    days = getDifferenceInDays(data.close_date, data.start_date);
  }
  if (data.start_date && !data.close_date && !data.on_hold_date) {
    days = getDifferenceInDays(new Date(), data.start_date);
  }
  if (data.on_hold_date && !data.off_hold_date) {
    days = getDifferenceInDays(data.on_hold_date, data.start_date);
  }
  if (data.start_date && !data.close_date && data.on_hold_date && data.off_hold_date) {
    days = getDifferenceInDays(new Date(), data.start_date) - getTheDaysDifference(data);
  }
  if (data.start_date && data.close_date && data.on_hold_date && data.off_hold_date) {
    days = getDifferenceInDays(data.close_date, data.start_date) - getTheDaysDifference(data);
  }
  if (!days && days !== 0) {
    return "--";
  }
  return days;
};

export const checkForPriorDecision = (date) => {
  const convertedDate = new Date(date);
  return convertedDate >= DECISION_PRIOR_DATE;
};

export const validateDate = (date) => {
  if (!date) return true
  const validDate = new Date(date)
  return (!isNaN(validDate.getTime()) && (validDate.getFullYear() < 2100) && (validDate.getFullYear() > 1900))
}

// export const compareDateWithCurrentDate =(date) => {
//   if(date){
//     if (new Date(date) < new Date())
//       return date ;
//   }
//   return new Date();
// }
