
//-----------------------------------------------------------// In-built Imports // ------------------------------
import React from "react";

//-----------------------------------------------------------// External Imports // ------------------------------
import { Link } from "react-router-dom";
import { CircularProgress, Typography } from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";

//-----------------------------------------------------------// Internal Imports // ------------------------------
import { getTheDaysDifferences, validateDate } from "../../utils/date";
import {
  getDateFilterOptions,
  getFilterQuery,
  getList,
  getMultiValueParam,
  getSingleValueParam,
  updateSearch,
  DateRenderer, DateAndTimeRenderer,
  // currencyValueSetter,
  getCurrencyPrefix,
  currencyWithCommas,
  getMatchFilterOptions
} from "../Commons/Utils";
import CompanySelection from "../../components/CompanySelection";
import CountrySelection from "../../components/CountrySelection";
import StateSelection from "../../components/StateSelection";
import JobFunctionSelection from "../../components/jobFunctionSelection";
import IndustrySelection from "../../components/IndustrySelection";
import UserSelection from "../../components/UserSelection";
import SearchStageSelection from "../../components/CreateSearch/Components/SearchInformation/Components/SearchStageSelection";
import WorkgroupSelection from "../../components/AddUser/components/WorkgroupSelection";
import SearchResultSelection from "../../components/CreateSearch/Components/SearchInformation/Components/SearchResultSelection";
import InputField from "../../components/common/InputField";
import SearchTypeSelection from "../../components/CreateSearch/Components/SearchInformation/Components/SearchTypeSelection";
import OffLimitsSelection from "../../components/OffLimitsSelection";
import ColorSelection from "../../components/ColorSelection";
import { GET, POST, SUCCESS, ERROR } from '../../services/constantService';
import { searchDataApi, workbenchDataApi } from '../../services/ApiService';
import PracticeSelection from '../../components/AddUser/components/PracticeSelection'
import RegionSelection from '../../components/AddUser/components/RegionSelection'
import { unableMessage, successMessage } from '../../services/MessageService';
import BillableSelection from '../../components/BillableSelection';
import CapitalStructure from "../../components/ViewSearch/Components/SFPA/Components/Targets/CapitalStructure";
import BrandSelection from '../../components/CreateSearch/Components/SearchInformation/Components/BrandSelection'
import ProbabilitySelection from "../../components/CreateSearch/Components/SearchInformation/Components/ProbabilitySelection";
// import BDStatusSelection from "../../components/CreateSearch/Components/SearchInformation/Components/BDStatusSelection";
export const PAGE_LIMIT = 200;
export const SUCCESS_STATUS_CODE = 200;

//const getCompany = (params) => (params.value ? params.value.name : "");
const getVerticalPractice = (params) => (params.value ? params.value : "");
const getHorizontalPractice = (params) => (params.value ? params.value : "");
const getRegion = (params) => (params.value ? params.value : "");
const getBrand = (params) => (params.value ? params.value : "");
const getOnHoldDate = (params) => params.data && params.data.on_hold_date;

const getStage = (params) => params.value ? (params.value === 'BD' ? "Business Development" : params.value) : ""

const getStages = (params) => {
  return params.data && params.data.stage ? params.data.stage === "BD" ? "Business Development" : params.data.stage : "";
}
const getAdmins = (params) => {
  if (params.data &&
    params.data?.eas &&
    params.data?.eas[0]) {
    const admins = [];
    params.data.eas.forEach(admin => {
      if (admin && admin.user) {
        admins.push(`${admin.user.first_name} ${admin.user.last_name}`);
      }
    });
    return admins.join(", ")
  }
}

const getIndustries = (params) => {
  if (params.value) {
    let names = [];
    params.value.map((val) => names.push(val));
    return names.join(", ");
  }
  return "";
};
/* const getProductOne = (params) => (params.value === true ? "Yes" : "No"); */
const getJobFunctions = (params) => {
  if (params.value) {
    let names = [];
    params.value.map((val) => names.push(val));
    return names.join(", ");
  }
  return "";
};

const getVcPe = (params) => {
  if (params.value) {
    let names = [];
    params.value.map((val) => names.push(val));
    return names.join(", ");
  }
  return "";
}
const stateSelection = (params) => {
  const country = params.data.country ? params.data.country : null
  return StateSelection({ ...params, country: country })
}
const getEditable = (params)=>{
  return (params?.data?.country) ?  true : false
}

const getPartner = (params) => {
  if (params.value) {
    let names = [];
    params.value.map(
      (val) =>
        val.user && names.push(val.user.first_name + " " + val.user.last_name)
    );
    return names.join(", ");
  }
  return "";
};

const getBdStatus = (params) => {
  return params.value ? params.value : 'Target Identified'
};

const getCapitalStructure = (params) => {
  if (params.value) {
    let names = [];
    params.value.map((val) => names.push(val));
    return names.join(", ");
  }
  return "";
};

const getRecruiter = (params) => {
  if (params.value) {
    let names = [];
    params.value.forEach(
      (val) =>
        val.user && names.push(val.user.first_name + " " + val.user.last_name)
    );
    return names.join(", ");
  }
  return "";
};
const getResearcher = (params) => {
  if (params.value) {
    let names = [];
    params.value.forEach(
      (val) =>
        val.user && names.push(val.user.first_name + " " + val.user.last_name)
    );
    return names.join(", ");
  }
  return "";
};

const getDays = (params) => {
  if (params && params.data) return getTheDaysDifferences(params?.data);
};
const renderName = (params) => {
  const first_name = params.first_name ? params.first_name : "";
  const last_name = params.last_name ? params.last_name : "";
  return (
    <Link
      to={`/contacts/${params?.id}/details`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Typography className="text-link" color="primary">
        {first_name + " " + last_name}
      </Typography>
    </Link>
  );
};

const placementName = (params) => {
  const first_name =
    params.data &&
      params.data.candidates &&
      params.data.candidates[0] &&
      params.data.candidates[0].contact.first_name
      ? params.data.candidates[0].contact.first_name
      : "";
  const last_name =
    params.data &&
      params.data.candidates &&
      params.data.candidates[0] &&
      params.data.candidates[0].contact.last_name
      ? params.data.candidates[0].contact.last_name
      : "";
  return first_name + " " + last_name;
};

export const clientContactsRenderer = (params) => {
  if (params.value) {
    return params.value.map((val) => {
      if (val.contact) {
        return renderName(val.contact);
      }
      return "";
    });
  }
  return "";
};
const getFeeAmount = (params) => {

  const currencyCode =
    params.data && params.data.currency ? params.data.currency : "";
  const feeAmount =
    params.data && params.data.fee_amount ? params.data.fee_amount : "";

  return getCurrencyPrefix(currencyCode) + currencyWithCommas(feeAmount);
}

const verticalPracticeData = (params) => {
  const type = "vertical"
  return PracticeSelection({ ...params, type })
}

const getClientContacts = (params) => {
  if (params.value) {
    return params.value.map((val) => {
      if (val.contact) {
        const first_name = val.contact.first_name ? val.contact.first_name : "";
        const last_name = val.contact.last_name ? val.contact.last_name : "";
        return first_name + " " + last_name;
      }
      return "";
    });
  }
  return "";
};

export const renderTargetCount = (params = {}) => {
  const getCount = async () => {
    if (!params?.data?.id) {
      return;
    }
    // params.SetLoader(true)
    const { status, data } = await searchDataApi(
      GET,
      params.data.id,
      {},
      "target-candidates-count"
    );
    if (status === 200) {
      // params.SetLoader(false)
      params.setValue(data.target_candidates_count);
    }
  };

  return params.value || params.value === 0 ? (
    params.value
  ) : (
    <Typography className="text-link" color="primary" onClick={getCount}>
      Show Count
    </Typography>
  );
};

export const renderActiveCount = (params = {}) => {
  const getCount = async () => {
    if (!params?.data?.id) {
      return;
    }
    // params.SetLoader(true)
    const { status, data } = await searchDataApi(
      GET,
      params.data.id,
      {},
      "active-candidates-count"
    );
    if (status === 200) {
      // params.SetLoader(false)
      params.setValue(data.active_candidates_count);
    }
  };

  return params.value || params.value === 0 ? (
    params.value
  ) : (
    <Typography className="text-link" color="primary" onClick={getCount}>
      Show Count
    </Typography>
  );
};

export const columnDefs = (
  isAdmin,
  placedSearches,
  activeSearches,
  enqueueSnackbar,
  SetLoader,
  option,
  isWorkbenchSearches,
  roles,
  dispatch = () => { }
) => {
  const columns = [
    {
      headerName: "",
      field: "check_box",
      colId: "check_box",
      maxWidth: 50,
      minWidth: 50,
      headerComponent: "HeaderCheckbox",
      headerCheckboxSelectionFilteredOnly: false,
      checkboxSelection: true,
      sortable: false,
      pinned: "left",
      lockPinned: true,
      lockPosition: true,
    },
    {
      field: "job_number",
      colId: "job_number",
      headerName: "Search#",
      minWidth: 50,
      maxWidth: 120,
      width: 90,
      suppressSizeToFit: true,
      cellRenderer: "IdRenderer",
      tooltipField: "job_number",
      pinned: "left",
      lockPinned: true,
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ['equal to'],
        buttons: ["reset", "apply"],
        suppressAndOrCondition: true,
        closeOnApply: true,
      },
    },
    {
      field: "job_title",
      colId: "job_title",
      headerName: "Title",
      minWidth: 50,
      maxWidth: 200,
      tooltipField: "job_title",
      cellRenderer: "TitleRenderer",
      pinned: "left",
      lockPinned: true,
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains"],
        buttons: ["reset", "apply"],
        suppressAndOrCondition: true,
        closeOnApply: true,
      },
    },
    {
      field: "eas",
      colId: "eas",
      headerName: "Admin",
      minWidth: 50,
      maxWidth: 200,
      tooltipValueGetter: getAdmins,
      cellRenderer: getAdmins,
      sortable: false,
      filter: "CustomFilter",
      filterParams: {
        Component: UserSelection,
        multiple: true,
        option: option ? option : [],
      },
    },
    {
      field: "company",
      colId: "company",
      headerName: "Company",
      minWidth: 50,
      maxWidth: 200,
      cellRenderer: "NameRenderer",
      filter: "CustomFilter",
      filterParams: {
        Component: CompanySelection,
        // field: "id",
        multiple: true,
      },
    },
    {
      field: "close_date",
      colId: "close_date",
      headerName: "Close Date",
      minWidth: 50,
      maxWidth: 120,
      tooltipValueGetter: DateRenderer,
      cellRenderer: "DateRenderer",
      filter: "agDateColumnFilter",
      filterParams: {
        filterOptions: getDateFilterOptions(),
        buttons: ["reset", "apply"],
        suppressAndOrCondition: true,
        closeOnApply: true,
      },
    },
    {
      field: "start_date",
      colId: "start_date",
      headerName: "Start Date",
      minWidth: 50,
      maxWidth: 120,
      tooltipValueGetter: DateRenderer,
      cellRenderer: "DateRenderer",
      filter: "agDateColumnFilter",
      filterParams: {
        filterOptions: getDateFilterOptions(),
        buttons: ["reset", "apply"],
        suppressAndOrCondition: true,
        closeOnApply: true,
      },
    },
    {
      field: "start_date",
      colId: "days_open",
      headerName: "Days Open",
      minWidth: 50,
      maxWidth: 120,
      tooltipValueGetter: getDays,
      cellRenderer: getDays,
      filter: false,
      sortable: false,
      // We disable this intentionally
      // filter: 'agDateColumnFilter',
      // filterParams: {
      //   filterOptions: getDateFilterOptions(),
      //   buttons: ['reset', 'apply'],
      //   suppressAndOrCondition: true,
      //   closeOnApply: true
      // }
    },
    {
      field: "target",
      colId: "target",
      width: 90,
      minWidth: 50,
      maxWidth: 120,
      headerName: "Target#",
      tooltipField: "target",
      cellRenderer: "renderTargetCount",
      sortable: false,
      filter: false,
      cellRendererParams: {
        SetLoader
      },
    },
    {
      field: "",
      colId: "actions",
      headerName: "Actions",
      minWidth: 135,
      maxWidth: 135,
      cellRenderer: "ActionsRenderer",
      sortable: false,
      filter: false,
      hide: !isAdmin,
      suppressColumnsToolPanel: !isAdmin,
    },
    {
      field: "billable",
      colId: "billable",
      minWidth: 50,
      maxWidth: 100,
      headerName: "Billable",
      cellRenderer: (params) =>
        params.value === true ? "Yes" : params.value === false ? "No" : "",
      filter: "CustomFilter",
      filterParams: {
        Component: BillableSelection,
        field: "id",
      },
    },
    /* {
      field: "product_one",
      colId: "product_one",
      headerName: "Product One",
      minWidth: 50,
      maxWidth: 200,
      cellRenderer: getProductOne,
      filter: "CustomFilter",
      filterParams: {
        Component: BillableSelection,
        field: "id",
        name: "Product One",
      },
    }, */
    {
      field: "active",
      colId: "active",
      width: 90,
      headerName: "Active#",
      minWidth: 50,
      maxWidth: 120,
      tooltipField: "active",
      cellRenderer: "renderActiveCount",
      sortable: false,
      filter: false,
      cellRendererParams: {
        SetLoader
      }
    },
    {
      field: "industries",
      colId: "industries",
      headerName: "Industries",
      tooltipValueGetter: getIndustries,
      cellRenderer: getIndustries,
      minWidth: 50,
      maxWidth: 200,
      editable: true,
      cellEditor: "PopupEditor",
      cellEditorParams: {
        InputComponent: IndustrySelection,
        isSearchIndustry: true
      },
      valueSetter: (params) => {
        params.newValue = params.newValue?.map((item) =>
          typeof item === "string" ? item : item.name
        );
        return updateSearch(
          params,
          "industries",
          true,
          enqueueSnackbar,
          SetLoader
        );
      },
      filter: "CustomFilter",
      filterParams: {
        Component: IndustrySelection,
        multiple: true,
        isSearchIndustry: true
      },
    },
    {
      field: "job_functions",
      colId: "job_functions",
      headerName: "Functions",
      minWidth: 50,
      maxWidth: 200,
      tooltipValueGetter: getJobFunctions,
      cellRenderer: getJobFunctions,
      filter: "CustomFilter",
      editable: true,
      cellEditor: "PopupEditor",
      cellEditorParams: {
        InputComponent: JobFunctionSelection,
        title: "Job Functions",
      },
      valueSetter: (params) => {
        params.newValue = params.newValue?.map((item) =>
          typeof item === "string" ? item : item.name
        );
        return updateSearch(
          params,
          "job_functions",
          true,
          enqueueSnackbar,
          SetLoader
        );
      },
      filterParams: {
        Component: JobFunctionSelection,
        multiple: true,
      },
    },
    {
      field: "city",
      colId: "city",
      headerName: "City",
      tooltipField: 'city',
      minWidth: 50,
      maxWidth: 200,
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains"],
        buttons: ["reset", "apply"],
        suppressAndOrCondition: true,
        closeOnApply: true,
      },
    },
    {
      field: "state",
      colId: "state",
      headerName: "State",
      tooltipField: 'state',
      minWidth: 50,
      maxWidth: 200,
      editable: getEditable,
      cellEditor: "GenericCellEditor",
      filter: false, // "CustomFilter",
      cellEditorParams: {
        InputComponent: stateSelection,
        label: "",
        placeholder: "",
        classes: { option: "ag-custom-component-popup" },
      },
      valueSetter: (params) => {
        //params.newValue = params.newValue.name;
        params.newValue = params.newValue.inputValue ? params.newValue.inputValue : params.newValue.title ? params.newValue.title : params.newValue;
        if (params.oldValue === params.newValue) {
          return false;
        }
        return updateSearch(
          params,
          "state",
          false,
          enqueueSnackbar,
          SetLoader
        );
      },
      filterParams: {
        Component: StateSelection,
        filterOptions: ["contains"],
        buttons: ["reset", "apply"],
        suppressAndOrCondition: true,
        closeOnApply: true,
      },
    },
    {
      field: "country",
      colId: "country",
      headerName: "Location",
      tooltipField: 'country',
      minWidth: 50,
      maxWidth: 230,
      editable: true,
      cellEditor: "GenericCellEditor",
      cellEditorParams: {
        InputComponent: CountrySelection,
        label: "",
        placeholder: "",
        classes: { option: "ag-custom-component-popup" },
      },
      valueSetter: (params) => {
        params.newValue = params.newValue.name;
        return updateSearch(
          params,
          "country",
          true,
          enqueueSnackbar,
          SetLoader
        );
      },
      filter: "CustomFilter",
      filterParams: {
        Component: CountrySelection,
        multiple: true,
      },
    },
    {
      field: "partners",
      colId: "partners",
      headerName: "Partner(s)",
      tooltipValueGetter: getPartner,
      cellRenderer: getPartner,
      minWidth: 50,
      maxWidth: 200,
      sortable: false,
      hide: true,
      filter: "CustomFilter",
      filterParams: {
        Component: UserSelection,
        multiple: true,
        option: option ? option : [],
      },
    },
    {
      field: "capital_structure",
      colId: "capital_structure",
      headerName: "Capital Structure",
      tooltipValueGetter: getCapitalStructure,
      cellRenderer: getCapitalStructure,
      minWidth: 150,
      maxWidth: 200,
      sortable: true,
      hide: true,
      filter: "CustomFilter",
      filterParams: {
        Component: CapitalStructure,
        multiple: true,
        option: option ? option : [],
      },
    },
    {
      field: "recruiters",
      colId: "recruiters",
      headerName: "Recruiter(s)",
      tooltipValueGetter: getRecruiter,
      cellRenderer: getRecruiter,
      sortable: false,
      hide: true,
      minWidth: 50,
      maxWidth: 200,
      filter: "CustomFilter",
      filterParams: {
        Component: UserSelection,
        multiple: true,
        option: option ? option : [],
      },
    },
    {
      field: "researchers",
      colId: "researchers",
      headerName: "Researcher(s)",
      tooltipValueGetter: getResearcher,
      cellRenderer: getResearcher,
      sortable: false,
      minWidth: 50,
      maxWidth: 200,
      hide: true,
      filter: "CustomFilter",
      filterParams: {
        Component: UserSelection,
        multiple: true,
        option: option ? option : [],
      },
    },
    {
      field: "freeze_dropdown",
      colId: "freeze_dropdown",
      headerName: "",
      sortable: false,
      filter: false,
      minWidth: 44,
      maxWidth: 44,
      width: 44,
      pinned: "right",
      lockPinned: true,
    },
    {
      field: "vertical_practice",
      colId: "vertical_practice",
      headerName: "Vertical Practice",
      tooltipValueGetter: getVerticalPractice,
      cellRenderer: getVerticalPractice,
      editable: true,
      minWidth: 50,
      maxWidth: 160,
      cellEditor: "GenericCellEditor",
      cellEditorParams: {
        InputComponent: verticalPracticeData,
        label: "",
        placeholder: "Vertical Practice",
        classes: { option: "ag-custom-component-popup" },
        type: "vertical"
      },

      valueSetter: (params) => {
        params.newValue = params.newValue.name || null;
        if (params.newValue !== params.oldValue)
          return updateSearch(
            params,
            "vertical_practice",
            false,
            enqueueSnackbar,
            SetLoader
          );
      },

      filter: "CustomFilter",
      filterParams: {
        Component: (params) =>
          PracticeSelection({ ...params, type: 'vertical' }),
        field: "name",
        placeholder: "Vertical Practice",
      },
    },
    {
      field: "horizontal_practice",
      colId: "horizontal_practice",
      headerName: "Horizontal Practice",
      tooltipValueGetter: getHorizontalPractice,
      cellRenderer: getHorizontalPractice,
      editable: true,
      minWidth: 50,
      maxWidth: 160,
      cellEditor: "GenericCellEditor",
      cellEditorParams: {
        InputComponent: PracticeSelection,
        label: "",
        placeholder: "Horizontal Practice",
        classes: { option: "ag-custom-component-popup" },
        type: 'horizontal'
      },

      valueSetter: (params) => {
        params.newValue = params.newValue.name || null;
        if (params.newValue !== params.oldValue)
          return updateSearch(
            params,
            "horizontal_practice",
            false,
            enqueueSnackbar,
            SetLoader
          );
      },

      filter: "CustomFilter",
      filterParams: {
        Component: (params) =>
          PracticeSelection({ ...params, type: 'horizontal' }),
        field: "name",
        placeholder: "Horizontal Practice",
      },
    },
    {
      field: "region",
      colId: "region",
      headerName: "Region",
      tooltipValueGetter: getRegion,
      cellRenderer: getRegion,
      editable: true,
      minWidth: 50,
      maxWidth: 120,
      cellEditor: "GenericCellEditor",
      cellEditorParams: {
        InputComponent: RegionSelection,
        label: "",
        placeholder: "",
        classes: { option: "ag-custom-component-popup" },
      },
      valueSetter: (params) => {
        params.newValue = params.newValue.name || null;
        return updateSearch(
          params,
          "region",
          false,
          enqueueSnackbar,
          SetLoader
        );
      },

      filter: "CustomFilter",
      filterParams: {
        Component: RegionSelection,
        field: "name",
      },
    },
    {
      field: "vc_pe",
      colId: "vc_pe",
      headerName: "Search VC/PE",
      minWidth: 50,
      maxWidth: 200,
      tooltipValueGetter: getVcPe,
      cellRenderer: getVcPe,
      filter: "CustomFilter",
      editable: true,
      cellEditor: "PopupEditor",
      cellEditorParams: {
        InputComponent: CompanySelection,
        title: "Search VC/PE",
      },
      valueSetter: (params) => {
        params.newValue = params.newValue?.map((item) =>
          typeof item === "string" ? item : item.name
        );
        return updateSearch(
          params,
          "vc_pe",
          false,
          enqueueSnackbar,
          SetLoader
        );
      },
      filterParams: {
        Component: CompanySelection,
        multiple: true,
      },
    },
    {
      field: "brand",
      colId: "brand",
      headerName: "Service Offering",
      tooltipValueGetter: getBrand,
      cellRenderer: getBrand,
      editable: true,
      minWidth: 50,
      maxWidth: 200,
      cellEditor: "GenericCellEditor",
      cellEditorParams: {
        InputComponent: BrandSelection,
        label: "",
        placeholder: "Service Offering",
        classes: { option: "ag-custom-component-popup" },
      },

      valueSetter: (params) => {
        params.newValue = params.newValue.field_value ? params.newValue.field_value : null;
        if (params.newValue !== params.oldValue)
          return updateSearch(
            params,
            "brand",
            true,
            enqueueSnackbar,
            SetLoader
          );
      },

      filter: "CustomFilter",
      filterParams: {
        Component: BrandSelection,
        placeholder: "Service Offering",
        multiple: true,
      },
    },
    {
      field: "created_at",
      colId: "created_at",
      headerName: "Created On",
      cellRenderer: "DateTimeRenderer",
      tooltipValueGetter: DateAndTimeRenderer,
      minWidth: 50,
      maxWidth: 200,
      filter: "agDateColumnFilter",
      filterParams: {
        filterOptions: getDateFilterOptions(),
        buttons: ["reset", "apply"],
        suppressAndOrCondition: true,
        closeOnApply: true,
      },
    },
    {
      field: "competition",
      colId: "competition",
      minWidth: 50,
      maxWidth: 200,
      headerName: "Competition",
      tooltipField: "competition",
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains"],
        buttons: ["reset", "apply"],
        suppressAndOrCondition: true,
        closeOnApply: true,
      },
    },
    {
      field: "projected_start_date",
      colId: "projected_start_date",
      headerName: "Projected Start Date",
      cellRenderer: "DateRenderer",
      minWidth: 50,
      maxWidth: 200,
      tooltipValueGetter: DateRenderer,
      hide: true,
      filter: "agDateColumnFilter",
      filterParams: {
        filterOptions: getDateFilterOptions(),
        buttons: ["reset", "apply"],
        suppressAndOrCondition: true,
        closeOnApply: true,
      },
    },
    // {
    //   field: "projected_fee",
    //   colId: "projected_fee",
    //   headerName: "Projected Fee",
    //   tooltipField: "projected_fee",
    //   minWidth: 50,
    //   maxWidth: 200,
    //   filter: "agNumberColumnFilter",
    //   filterParams: {
    //     filterOptions: ["lessThan", "greaterThan"],
    //     buttons: ["reset", "apply"],
    //     suppressAndOrCondition: true,
    //     closeOnApply: true,
    //   },
    // },
    {
      field: "probability",
      colId: "probability",
      headerName: "Probability",
      tooltipField: "probability",
      minWidth: 150,
      maxWidth: 200,
      hide: true,
      filter: "CustomFilter",
      filterParams: {
        Component: ProbabilitySelection,
        placeholder: "Probability",
        multiple: true,
      },
    },
    // {
    //   field: "bd_status",
    //   colId: "bd_status",
    //   headerName: "BD Status",
    //   tooltipField: "bd_status",
    //   editable: true,
    //   minWidth: 150,
    //   maxWidth: 200,
    //    cellEditor: "GenericCellEditor",
    //   cellEditorParams: {
    //     InputComponent: BDStatusSelection,
    //     label: "",
    //     placeholder: "",
    //     classes: { option: "ag-custom-component-popup" }
    //   },
    //   valueSetter: (params) => {
    //     params.newValue = params.newValue.field_value ? params.newValue.field_value : null;
    //     return updateSearch(
    //       params,
    //       "bd_status",
    //       true,
    //       enqueueSnackbar,
    //       SetLoader,
    //       dispatch
    //     );
    //   },
    //   filter: "CustomFilter",
    //   filterParams: {
    //     Component: BDStatusSelection,
    //     placeholder: "BD Status",
    //     multiple: true,
    //   },
    // },
    {
      field: "decision",
      colId: "decision",
      headerName: "Decision",
      minWidth: 50,
      maxWidth: 200,
      tooltipField: "decision",
      hide: true,
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains"],
        buttons: ["reset", "apply"],
        suppressAndOrCondition: true,
        closeOnApply: true,
      },
    },
    {
      field: "work_group",
      colId: "work_group",
      headerName: "Workgroup",
      minWidth: 50,
      maxWidth: 150,
      tooltipField: "work_group",
      hide: true,
      filter: "CustomFilter",
      filterParams: {
        Component: WorkgroupSelection,
        multiple: true,
      },
    },
    {
      field: "bd_status",
      colId: "Bd Status",
      headerName: "Bd Status",
      tooltipField: 'Bd Status',
      cellRenderer: getBdStatus,
      sortable: false,
      minWidth: 50,
      maxWidth: 200
    },
    {
      field: "estimated_percentage_base",
      colId: "Estimated Base Fee",
      headerName: "Estimated Base Fee",
      tooltipField: 'Estimated Base Fee',
      cellRenderer: 'EstimatedBaseFeeRender',
      sortable: false,
      minWidth: 50,
      maxWidth: 200
    },
    {
      field: "estimated_revenue",
      colId: "Estimated Revenue",
      headerName: "Estimated Revenue",
      tooltipField: 'Estimated Revenue',
      cellRenderer: 'EstimatedAmountRender',
      sortable: false,
      minWidth: 50,
      maxWidth: 200
    },
  ];

  if (placedSearches) {
    columns.splice(
      15,
      0,
      {
        field: "placement",
        colId: "placement",
        minWidth: 50,
        maxWidth: 200,
        headerName: "Placement",
        tooltipValueGetter: placementName,
        cellRenderer: placementName,
        sortable: false,
        filter: false,
      }
      // Duplicate close_date Column
      // {
      //   field: 'close_date',
      //   colId: 'close_date',
      //   headerName: 'Close Date',
      //   tooltipField: 'close_date',
      //   hide: true,
      //   cellRenderer: 'DateRenderer',
      //   filter: 'agDateColumnFilter',
      //   filterParams: {
      //     filterOptions: getDateFilterOptions(),
      //     buttons: ['reset', 'apply'],
      //     suppressAndOrCondition: true,
      //     closeOnApply: true
      //   }
      // }
    );
  }
  if (activeSearches) {
    columns.splice(
      3,
      0,
      {
        field: "on_hold_date",
        colId: "on_hold_date",
        headerName: "On Hold",
        minWidth: 50,
        maxWidth: 120,
        tooltipValueGetter: DateRenderer,
        valueGetter: getOnHoldDate,
        cellRenderer: DateRenderer,
        editable: roles !== "user" ? true : false,
        cellEditor: "GenericCellEditor",
        hide:true,
        cellEditorParams: {
          InputComponent: KeyboardDatePicker,
          label: "",
          placeholder: "",
          autoOk: true,
          variant: "inline",
          type: "Date",
          format: "MM-dd-yyyy",
        },
        valueSetter: (params) => {
          if (params && !params.newValue){
            params.newValue = null;
          }
          
          if(!validateDate(params.newValue)){
            return false
          }
          return updateSearch(
            params,
            "on_hold_date",
            false,
            enqueueSnackbar,
            SetLoader
          );
        },
        filter: "agDateColumnFilter",
        filterParams: {
          filterOptions: getDateFilterOptions(),
          buttons: ["reset", "apply"],
          suppressAndOrCondition: true,
          closeOnApply: true,
        },
      },
      {
        field: "fee_amount",
        colId: "fee_amount",
        headerName: "Fee Amount",
        minWidth: 150,
        maxWidth: 200,
        //tooltipField: "fee_amount",
        tooltipValueGetter: getFeeAmount,
        filter: "agNumberColumnFilter",
        cellRenderer: "FeeAmountRenderer",
        filterParams: {
          filterOptions: getMatchFilterOptions(),
          buttons: ["reset", "apply"],
          suppressAndOrCondition: true,
          closeOnApply: true,
        },
      }
    );
    columns.splice(
      16,
      0,
      {
        field: "stage",
        colId: "stage",
        editable: (roles !== "user") ? true : false,
        headerName: "Stage",
        minWidth: 50,
        maxWidth: 200,
        cellEditor: "GenericCellEditor",
        tooltipValueGetter: getStage,
        //cellRenderer: getStage,
        valueGetter: (params) => getStages(params),
        cellEditorParams: {
          InputComponent: SearchStageSelection,
          label: "",
          placeholder: "",
          classes: { option: "ag-custom-component-popup" },
        },
        valueSetter: (params) => {
          params.newValue = params.newValue.field_value ? params.newValue.field_value : null;
          return updateSearch(
            params,
            "stage",
            true,
            enqueueSnackbar,
            SetLoader,
            dispatch
          );
        },
        filter: "CustomFilter",
        filterParams: {
          Component: SearchStageSelection,
          multiple: true,
        },
      },
      {
        field: "result",
        colId: "result",
        minWidth: 50,
        maxWidth: 170,
        editable: roles !== "user" ? true : false,
        headerName: "Results",
        tooltipField: "result",
        cellEditor: "GenericCellEditor",
        cellEditorParams: {
          InputComponent: SearchResultSelection,
          label: "",
          placeholder: "",
          classes: { option: "ag-custom-component-popup" },
        },
        valueSetter: (params) => {
          params.newValue = params.newValue.name || null;
          return updateSearch(
            params,
            "result",
            false,
            enqueueSnackbar,
            SetLoader
          );
        },
        filter: "CustomFilter",
        filterParams: {
          Component: SearchResultSelection,
          multiple: true,
        },
      }
    );
  }
  if (isWorkbenchSearches) {
    columns.splice(1, 0, {
      field: "search_color",
      colId: "search_color",
      headerName: "Color",
      cellRenderer: "ColorRenderer",
      maxWidth: 100,
      minWidth: 100,
      pinned: "left",
      lockPinned: true,
      filter: "CustomFilter",
      filterParams: {
        Component: ColorSelection,
        field: "value",
      },
    });
  }
  return columns;
};

export const getBdColumnDefs = (isFromCreateSearch) => {
  const columns = [
    {
      field: "job_number",
      colId: "job_number",
      headerName: "Job#",
      minWidth: 50,
      maxWidth: 120,
      width: 90,
      suppressSizeToFit: true,
      cellRenderer: "IdRenderer",
      tooltipField: "job_number",
      pinned: "left",
      lockPinned: true,
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ['equal to'],
        buttons: ["reset", "apply"],
        suppressAndOrCondition: true,
        closeOnApply: true,
      },
    },
    {
      field: "job_title",
      colId: "job_title",
      headerName: "Title",
      minWidth: 50,
      maxWidth: 200,
      tooltipField: "job_title",
      cellRenderer: isFromCreateSearch
        ? "JobNumberRendererForPipeline"
        : "JobNumberRenderer",
      pinned: "left",
      lockPinned: true,
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains"],
        buttons: ["reset", "apply"],
        suppressAndOrCondition: true,
        closeOnApply: true,
      },
    },
    {
      field: "company",
      colId: "company",
      headerName: "Company",
      minWidth: 50,
      maxWidth: 200,
      cellRenderer: "NameRenderer",
      //sortable: false,
      filter: "CustomFilter",
      filterParams: {
        Component: CompanySelection,
        //field: "id",
        multiple: true,
      },
    },
    {
      field: "city",
      colId: "city",
      minWidth: 50,
      maxWidth: 200,
      headerName: "City",
      tooltipField: 'city',
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains"],
        buttons: ["reset", "apply"],
        suppressAndOrCondition: true,
        closeOnApply: true,
      },
    },
    {
      field: "country",
      colId: "country",
      headerName: "Location",
      tooltipField: 'country',
      minWidth: 50,
      maxWidth: 230,
      filter: "CustomFilter",
      filterParams: {
        Component: CountrySelection,
        multiple: true,
      },
    },
    {
      field: "state",
      colId: "state",
      headerName: "State",
      tooltipField: 'state',
      minWidth: 50,
      maxWidth: 200,
      filter: "agTextColumnFilter",
      filterParams: {
        filterOptions: ["contains"],
        buttons: ["reset", "apply"],
        suppressAndOrCondition: true,
        closeOnApply: true,
      },
    },
    {
      field: "partners",
      colId: "partners",
      minWidth: 50,
      maxWidth: 200,
      headerName: "Partner(s)",
      tooltipValueGetter: getPartner,
      cellRenderer: getPartner,
      sortable: false,
      // filter: 'CustomFilter',
      // filterParams: {
      //   Component: UserSelection,
      //   multiple: true
      // }
    },
    {
      field: "bd_status",
      colId: "BD Status",
      headerName: "Bd Status",
      tooltipField: 'Bd Status',
      cellRenderer: getBdStatus,
      sortable: false,
      minWidth: 50,
      maxWidth: 200
    },
    {
      field: "estimated_percentage_base",
      colId: "Estimated Base Fee",
      headerName: "Estimated Base Fee",
      tooltipField: 'Estimated Base Fee',
      cellRenderer: 'EstimatedBaseFeeRender',
      sortable: false,
      minWidth: 50,
      maxWidth: 200
    },
    {
      field: "estimated_revenue",
      colId: "Estimated Revenue",
      headerName: "Estimated Revenue",
      tooltipField: 'Estimated Revenue',
      cellRenderer: 'EstimatedAmountRender',
      sortable: false,
      minWidth: 50,
      maxWidth: 200
    }
  ];
  if (isFromCreateSearch) {
    columns.push(
      {
        field: "stage",
        colId: "stage",
        headerName: "Stage",
        tooltipField: "stage",
        minWidth: 50,
        maxWidth: 200,
        tooltipValueGetter: getStage,
        cellRenderer: getStage,
        //filter: "CustomFilter",
        // filterParams: {
        //   Component: SearchStageSelection,
        //   multiple: true,
        // },
      },
      {
        field: "start_date",
        colId: "start_date",
        headerName: "Start Date",
        tooltipValueGetter: DateRenderer,
        minWidth: 50,
        maxWidth: 120,
        cellRenderer: "DateRenderer",
        filter: "agDateColumnFilter",
        filterParams: {
          filterOptions: getDateFilterOptions(),
          buttons: ["reset", "apply"],
          suppressAndOrCondition: true,
          closeOnApply: true,
        },
      },
      {
        headerName: "Edit",
        colId: "edit",
        cellRenderer: "EditRenderer",
      }
    );
  } else {
    columns.push(
      {
        field: "created_at",
        colId: "created_at",
        headerName: "Created On",
        cellRenderer: "DateTimeRenderer",
        tooltipValueGetter: DateAndTimeRenderer,
        minWidth: 50,
        maxWidth: 200,
        filter: "agDateColumnFilter",
        filterParams: {
          filterOptions: getDateFilterOptions(),
          buttons: ["reset", "apply"],
          suppressAndOrCondition: true,
          closeOnApply: true,
        },
      },
      {
        field: "competition",
        colId: "competition",
        minWidth: 50,
        maxWidth: 200,
        headerName: "Competition",
        tooltipField: "competition",
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          buttons: ["reset", "apply"],
          suppressAndOrCondition: true,
          closeOnApply: true,
        },
      },
      /* {
        field: "",
        colId: "loe",
        headerName: "LOE",
        minWidth: 100,
        maxWidth: 100,
        tooltipField: "",
        cellRenderer: "LOERenderer",
        sortable: false,
      }, */
      {
        field: "",
        colId: "actions",
        headerName: "Actions",
        minWidth: 150,
        maxWidth: 150,
        tooltipField: "",
        cellRenderer: "ActionsRenderer",
        sortable: false,
      },
      {
        field: "created_at",
        colId: "age",
        headerName: "Age",
        minWidth: 50,
        maxWidth: 100,
        tooltipValueGetter: getDays,
        cellRenderer: getDays,
      },
      {
        field: "projected_start_date",
        colId: "projected_start_date",
        headerName: "Projected Start Date",
        cellRenderer: DateRenderer,
        minWidth: 50,
        maxWidth: 200,
        tooltipValueGetter: DateRenderer,
        hide: true,
        filter: "agDateColumnFilter",
        filterParams: {
          filterOptions: getDateFilterOptions(),
          buttons: ["reset", "apply"],
          suppressAndOrCondition: true,
          closeOnApply: true,
        },
      },
      // {
      //   field: "projected_fee",
      //   colId: "projected_fee",
      //   headerName: "Projected Fee",
      //   tooltipField: "projected_fee",
      //   minWidth: 50,
      //   maxWidth: 200,
      //   filter: "agNumberColumnFilter",
      //   filterParams: {
      //     filterOptions: ["lessThan", "greaterThan"],
      //     buttons: ["reset", "apply"],
      //     suppressAndOrCondition: true,
      //     closeOnApply: true,
      //   },
      // },
      {
        field: "probability",
        colId: "probability",
        headerName: "Probability",
        tooltipField: "probability",
        minWidth: 150,
        maxWidth: 200,
        hide: true,
        filter: "CustomFilter",
        filterParams: {
          Component: ProbabilitySelection,
          multiple: true,
        },
      }, 
      // {
      //   field: "bd_status",
      //   colId: "bd_status",
      //   headerName: "BD Status",
      //   tooltipField: "bd_status",
      //   minWidth: 150,
      //   maxWidth: 200,
      //   hide: true,
      //   filter: "CustomFilter",
      //   filterParams: {
      //     Component: BDStatusSelection,
      //     multiple: true,
      //   },
      // },
      {
        field: "decision",
        colId: "decision",
        headerName: "Decision",
        minWidth: 50,
        maxWidth: 200,
        tooltipField: "decision",
        hide: true,
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          buttons: ["reset", "apply"],
          suppressAndOrCondition: true,
          closeOnApply: true,
        },
      },
      {
        field: "work_group",
        colId: "work_group",
        headerName: "Workgroup",
        minWidth: 50,
        maxWidth: 150,
        tooltipField: "work_group",
        hide: true,
        filter: "CustomFilter",
        filterParams: {
          Component: WorkgroupSelection,
          multiple: true,
        },
      },
      {
        field: "client_team",
        colId: "client_team",
        headerName: "Client Contacts",
        minWidth: 50,
        maxWidth: 200,
        tooltipValueGetter: getClientContacts,
        cellRenderer: "clientContactsRenderer",
        hide: true,
        sortable: false,
      }
    );
  }
  columns.push({
    field: "freeze_dropdown",
    colId: "freeze_dropdown",
    headerName: "",
    sortable: false,
    filter: false,
    minWidth: 44,
    maxWidth: 44,
    width: 44,
    pinned: "right",
    lockPinned: true,
  });
  return columns;
};

export const CustomLoadingOverlayComponent = () => {
  return <CircularProgress />;
};

export const IdRenderer = (params) => {
  return (
    <Link
      to={`/searches/${params?.data?.id}/active-candidates`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Typography className="text-link" color="primary">
        {params.value}
      </Typography>
    </Link>
  );
};

export const TitleRenderer = (params) => {
  return (
    <Link
      to={`/searches/${params?.data?.id}/active-candidates`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Typography className="text-link" color="primary">
        {params.value}
        {params?.data?.is_confidential === true ? " (Confidential)" : ""}
      </Typography>
    </Link>
  );
};
export const defaultColumns = [
  "job_title",
  "company",
  "freeze_dropdown"
];

export const getQueryParamsString = (data) => {
  //const jobTitle = getMultiValueParam(getList(data['title'], 'job_title'), 'title');
  const jobTitle = getSingleValueParam(
    /* getList(data['title'], 'job_title'), 'title' */ data,
    "title"
  );
  const revenueFrom = getSingleValueParam(data, "revenue_from");
  const toRevenue = getSingleValueParam(data, "revenue_to");
  const estimatedTotalCompensation = getSingleValueParam(
    data,
    "estimatedTotalCompensation"
  );
  const activePartners = getSingleValueParam(data, "activePartners");
  const fromStart = getSingleValueParam(data, "start_date_from");
  const toStart = getSingleValueParam(data, "start_date_to");
  const fromClose = getSingleValueParam(data, "close_date_from");
  const toClose = getSingleValueParam(data, "close_date_to");
  const vertical_practice = getSingleValueParam(data, "vertical_practice");
  const horizontal_practice = getSingleValueParam(data, "horizontal_practice");
  const region = getSingleValueParam(data, "region");
  const company = getMultiValueParam(getList(data["company"], "id"), "company");
  const industries = getMultiValueParam(
    getList(data["industries"], "name"),
    "industries"
  );
  const jobFunctions = getMultiValueParam(
    getList(data["job_functions"], "name"),
    "job_functions"
  );
  const kgpTeamMember = getMultiValueParam(
    getList(data["kgpTeamMember"], "id"),
    "kgpTeamMember"
  );
  const location = getMultiValueParam(
    getList(data["location"], "name"),
    "country"
  );
  const stage = getMultiValueParam(getList(data["stage"], "field_value"), "stage");
  const type = getMultiValueParam(getList(data["type"], "name"), "type");
  const vc_pe = getMultiValueParam(getList(data["vc_pe"], "name"), "vc_pe");
  const result = getMultiValueParam(getList(data["result"], "name"), "result");
  const capital_structure = getMultiValueParam(getList(data["capital_structure"], "name"), "capital_structure");
  const billable = getSingleValueParam(data, "billable");
  return (
    jobTitle +
    revenueFrom +
    toRevenue +
    estimatedTotalCompensation +
    activePartners +
    fromStart +
    toStart +
    fromClose +
    toClose +
    company +
    industries +
    jobFunctions +
    kgpTeamMember +
    location +
    stage +
    type +
    vc_pe +
    result +
    vertical_practice +
    region +
    horizontal_practice +
    capital_structure +
    billable
  );
};

export const getFilterParamString = (data, prefix, AdvancedQuery = false) => {
  const queryAliases = {
    job_title: "title" /* 'job_title' */,
    job_number: /* 'job_number' */ "jobNumber",
  };
  const queryValueTypes = {
    job_functions: { field: "name" },
    capital_structure: { field: "name" },
    country: { field: "name" },
    vertical_practice: { field: "name" },
    horizontal_practice: { field: "name" },
    industries: { field: "name" },
    stage: { field: "field_value" },
    brand: { field: "field_value" },
    probability: { field: "field_value" },
    // bd_status:{ field: "field_value" },
    work_group: { field: "name" },
    result: { field: "name" },
    region: { field: "name" },
    vc_pe: { field: "name" }
  };
  return getFilterQuery(
    data,
    queryAliases,
    queryValueTypes,
    prefix,
    AdvancedQuery
  );
};

const dateProps = {
  variant: "inline",
  format: "MM/dd/yyyy",
  placeholder: "Select",
  disableToolbar: false,
  autoOk: true,
};

export const fieldDefinitions = {
  "Job Title": {
    component: InputField,
    field: "job_title",
    props: { placeholder: "Enter" },
  },
  Company: {
    component: InputField,
    field: "company.name",
    props: { placeholder: "Enter" },
  },
  "KG Member": {
    component: UserSelection,
    field: "kgpTeamMember",
    colId: "kgpTeamMember",
    type: "selection",
    props: {
      placeholder: "Search",
      //multiple: true,
      filterSelectedOptions: true,
      size: "small",
    },
  },
  "Capital Structure": {
    component: CapitalStructure,
    field: "capital_structure",
    colId: "capital_structure",
    dataField: "name",
    type: "selection",
    props: {
      placeholder: "Search",
      multiple: true,
      filterSelectedOptions: true,
      size: "small",
    },
  },
  Stage: {
    component: SearchStageSelection,
    field: "stage",
    colId: "stage",
    type: "selection",
    dataField: "name",
    props: {
      placeholder: "Search",
      //filterSelectedOptions: true,
      size: "small",
    },
  },
  Industry: {
    component: IndustrySelection,
    field: "industries",
    colId: "industries",
    type: "selection",
    dataField: "name",
    props: {
      placeholder: "Select",
      multiple: true,
      filterSelectedOptions: true,
      size: "small",
    },
  },
  "Job Function": {
    component: JobFunctionSelection,
    field: "job_functions",
    colId: "job_functions",
    type: "selection",
    dataField: "name",
    props: {
      placeholder: "Select",
      multiple: true,
      filterSelectedOptions: true,
      size: "small",
    },
  },
  Location: {
    component: CountrySelection,
    type: "selection",
    colId: "country",
    dataField: "name",
    field: "country",
    props: {
      placeholder: "Select",
      multiple: true,
      filterSelectedOptions: true,
      size: "small",
    },
  },
  "VC/PE": {
    component: InputField,
    field: "vc_pe",
    colId: "vc_pe",
    props: { placeholder: "Enter" },
  },
  Type: {
    component: SearchTypeSelection,
    field: "type",
    colId: "type",
    type: "selection",
    dataField: "name",
    props: {
      placeholder: "Select",
      filterSelectedOptions: true,
      size: "small",
    },
  },
  Result: {
    component: SearchResultSelection,
    field: "result",
    colId: "result",
    dataField: "name",
    type: "selection",
    props: {
      placeholder: "Select",
      filterSelectedOptions: true,
      size: "small",
    },
  },
  Revenue: {
    component: InputField,
    field: "company.revenue",
    colId: "company.revenue",
    type: "numberRange",
    props: { placeholder: "Enter" },
  },
  "Estimated Total Comp": {
    component: InputField,
    type: "numberRange",
    field: "estimated_total_comp",
    colId: "estimated_total_comp",
    props: { placeholder: "Enter" },
  },
  "Start Date": {
    component: KeyboardDatePicker,
    field: "start_date",
    colId: "start_date",
    type: "dateRange",
    props: { ...dateProps },
  },
  "Close Date": {
    component: KeyboardDatePicker,
    field: "close_date",
    colId: "close_date",
    type: "dateRange",
    props: { ...dateProps },
  },
  "Active Partners": {
    component: OffLimitsSelection,
    field: "is_active",
    colId: "is_active",
    type: "selection",
    props: {
      placeholder: "Select",
      filterSelectedOptions: true,
      size: "small",
    },
  },
  "Region": {
    component: RegionSelection,
    field: "region",
    colId: "region",
    type: "selection",
    dataField: "name",
    props: {
      placeholder: "Select",
      filterSelectedOptions: true,
      size: "small",
    },
  },
  "Service Offering": {
    component: BrandSelection,
    field: "brand",
    colId: "brand",
    type: "selection",
    dataField: "name",
    props: {
      placeholder: "Select",
      //filterSelectedOptions: true,
      size: "small",
    },
  },
  "Billable": {
    component: BillableSelection,
    field: "billable",
    colId: "billable",
    type: "selection",
    props: {
      placeholder: "Select",
      filterSelectedOptions: true,
      size: "small",
    },
  },
  "Product One": {
    component: BillableSelection,
    field: "product_one",
    colId: "product_one",
    type: "selection",
    props: {
      placeholder: "Select",
      filterSelectedOptions: true,
      size: "small",
    },
  },
};

export const getSelectedSearchIds = (gridApi) => {
  const searchIds = [];
  gridApi.forEachNode((node) => {
    if (node.selected) {
      searchIds.push(node.data.id); // Only for contact grids
    }
  });
  return searchIds;
};

export const addSearchesToWorkbench = async (
  workbenchId,
  searches,
  enqueueSnackbar
) => {
  const sub_route = "searches";
  const { status, data } = await workbenchDataApi(
    POST,
    workbenchId,
    searches,
    sub_route
  );
  return processAddSearchesResponse(status, data, enqueueSnackbar);
};

export const processAddSearchesResponse = (status, data, enqueueSnackbar) => {
  if (status === 200) {
    const message = successMessage("Searches added to", "workbench");
    enqueueSnackbar(message, { variant: SUCCESS });
    return true;
  } else {
    const message = unableMessage("workbench", "add search(s) to");
    enqueueSnackbar(data?.message || message, { variant: ERROR });
  }
  return false;
};
