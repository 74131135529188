//----------------------------------------------// In-built Imports // -------------------------------------------------
import React from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from "prop-types";
import Autocomplete from "@material-ui/lab/Autocomplete";

//----------------------------------------------// Internal Imports // -------------------------------------------------
import InputField from "../common/InputField";

function WorkspaceSelection(props) {
  const {
    label = "User",
    placeholder = "User",
    required = false,
    InputProps,
    InputLabelProps,
    inputRef,
    workspace,
    handleWorkspaceChange,
    options,
    ...rest
  } = props;

  return (
    options && options.length > 0 && (
      <Autocomplete
        {...rest}
        options={options}
        getOptionLabel={(option) => (option && option !== "" ? option.name : "")}
        value={workspace[0] ? workspace[0] : ""}
        onChange={(event, newValue) => {
          if (newValue) {
            handleWorkspaceChange(newValue);
          }
        }}
        renderInput={(params) => (
          <InputField
            {...params}
            ref={inputRef}
            InputProps={{ ...params.InputProps, ...InputProps }}
            variant="standard"
            InputLabelProps={InputLabelProps}
            required={required}
            label={label}
            placeholder={placeholder}
          />
        )}
      />
    )
  );
}

WorkspaceSelection.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  InputLabelProps: PropTypes.object,
  InputProps: PropTypes.object,
  inputRef: PropTypes.object,
  workspace: PropTypes.array,
  handleWorkspacesChange: PropTypes.func,
  options: PropTypes.array,
  handleWorkspaceChange: PropTypes.func
};

export default WorkspaceSelection;
