//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from "prop-types";
import { TextField } from "@material-ui/core";
import Autocomplete, {
  createFilterOptions
} from "@material-ui/lab/Autocomplete";

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { AVAILABLE_COUNTRY_WITH_STATES, GET } from "../../services/constantService";
import { picklistsCountryStateDataApi } from "../../services/ApiService";
import { useDispatch, useSelector } from "react-redux";
import { UPDATE_STATE_LIST } from "../../types";

export default function CustomStateSelection(props) {
  const { country, className, InputLabelProps = { focused: true }, placeholder = 'State', label = 'State', disabled = false, onKeyDown, ...rest } = props
  const [options, setOptions] = useState([]);
  const usState=useSelector(state=>state.usState)
  const dispatch=useDispatch()
  const filter = createFilterOptions();
  useEffect(() => {
    const fetchStates = async () => {
      if (country && AVAILABLE_COUNTRY_WITH_STATES.includes(country)) {
        if(!usState){
          const sub_route = `?countryName=${country}`
          const { status, data } = await picklistsCountryStateDataApi(GET, sub_route);
          if (status === 200) {
            dispatch({type:UPDATE_STATE_LIST,payload:{ key:'usState',data }})
            let array = []
            data.map(data => array.push({ title: data.name }))
            setOptions(array)
          }
        }
        else
        {
          let array = []
          usState.map(data => array.push({ title: data.name }))
          setOptions(array)
        }
      }
      else {
        setOptions([])
      }
    }
    fetchStates().then(null)
  }, [country])

  return (
    <Autocomplete
      {...rest}
      autoSelect
      className={className}
      options={options}
      key={country}
      disabled={disabled}
      freesolo
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        const { inputValue } = params;
        
        // Suggest the creation of a new value
        const isExisting = options.some((option) => option.title === inputValue);
        if (inputValue !== '' && !isExisting && country) {
          filtered.push({
            inputValue: params.inputValue,
            title: `Add item "${inputValue}"`,
          });
        }
        return filtered;
      }}
      getOptionLabel={(option) => {
        // Value selected with enter, right from the input
        if (typeof option === "string") {
          return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
          return option.inputValue;
        }
        // Regular option
        return option.title;
      }}
      getOptionSelected={(option, value) => {
        if (!value) {
          return false;
        }
        if (typeof value === "string") {
          return option.title === value;
        }
        return option.title === value.title;
      }}
      renderOption={(option) => option.title}
      renderInput={(params) => {
        params.inputProps.onKeyDown = onKeyDown;
        return (
          <TextField
            {...params}
            name='state'
            InputLabelProps={InputLabelProps}
            label={label}
            placeholder={placeholder}
          />
        )}}
    />
  )
}

CustomStateSelection.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.string,
  country: PropTypes.string,
  className: PropTypes.string,
  register: PropTypes.func,
  InputLabelProps: PropTypes.object,
  disabled: PropTypes.bool,
  onKeyDown: PropTypes.func, 
}
