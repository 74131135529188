//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useEffect, useRef, useState } from "react";

//-----------------------------------------------------------// External Imports // ------------------------------

import {
  Button,
  Checkbox,
  CircularProgress,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
} from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PropTypes from "prop-types";
import CloseIcon from "@material-ui/icons/Close";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { saveAs } from "file-saver";

//-----------------------------------------------------------// Internal Imports // ------------------------------

import ColumnFilter from "../Commons/ColumnFilter";
import InvoiceQuery from "./invoiceQuery";
import { dashboardDataApi, invoiceSummaryApi } from "../../services/ApiService";
import { SUCCESS_STATUS_CODE } from "../Commons/Utils";
import {
  VALIDATION_MESSAGE,
  notFoundMessage,
} from "../../services/MessageService";
import Loader from "../../components/common/Loader";
import {
  DateRenderer,
  loadColumnStateFromLocalStorage,
  saveColumnStateToLocalStorage,
} from "../Commons/Utils";
import { PAGE_LIMIT } from "../Contacts/utils";
import { columnDefs, getFilterParamStringForInvoiceSummery, getQueryParamsStringForInvoice } from "./utils";
import { API, BLOB, ERROR, GET, POST, WARNING } from "../../services/constantService";
import { useSnackbar } from 'notistack';
import './index.scss';
import CustomFilter from "../Commons/CustomFilter";
import ConfirmProjectedDatePopup from "./ConfirmProjectedDatePopup";

let gridApi, columnApi;
export default function InvoiceSummary(props) {
  const { type, title } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const [showQuery, setShowQuery] = useState(false);
  const [minimize, setMinimize] = useState(false);
  const [rowCount, setRowCount] = useState(null);
  const [selectedRowsCount, setSelectedRowsCount] = useState(0);
  const [showFilterCount, setShowFilterCount] = useState(0);
  const [active, setActive] = useState(false);
  const [uiSelector, setUiSelector] = useState(false);
  const [isAllRowsSelected, setIsAllRowsSelected] = useState(false);
  const [filterModel, setFilterModel] = useState({});
  const [isQuerySelector, setIsQuerySelector] = useState(false);
  const [isProjectedDateSelected, setIsProjectedDateSelected] = useState(false);
  const [projectedData, setProjectedData] = useState(null);

  let queryData = useRef();
  let isClassicQuery = useRef();

  useEffect(() => {
    return () => {
      const columnApis = columnApi;
      saveColumnStateToLocalStorage(
        type ? `${type}invoiceSearch` : "invoiceSearch",
        { columnApis }
      );
    };
  }, []);

  const handleVisibilityChange = () => {
    localStorage.setItem("invoiceSearch", JSON.stringify(showQuery));
  };

  useEffect(() => {
    const savedQueryState = localStorage.getItem("invoiceSearch");
    if (savedQueryState) {
      setShowQuery(JSON.parse(savedQueryState));
    }
  }, []);

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [showQuery]);

  // eslint-disable-next-line no-unused-vars
  let isSelectedFromList = false;

  const defaultColumns = ["name", "freeze_dropdown"];

  const onGridReady = (params) => {
    gridApi = params.api;
    columnApi = params.columnApi;
    loadColumnStateFromLocalStorage(
      type ? `${type}invoiceSearch` : "invoiceSearch",
      { columnApi, gridApi }
    );
  };

  const resetFilter = async () => {
    gridApi.setFilterModel(null);
    const customFilterField = columnApi.columnController.columnDefs;
    customFilterField.map((item) => {
      if (item.filter === "CustomFilter") {
        gridApi.destroyFilter(item.field);
      }
    });
  };

  const resetSort = async () => {
    gridApi.setSortModel(null);
  };

  const getUrl = () => {
    return `${API.invoice_summary}`;
  };

  const saveColumnsState = async () => {
    try {
      gridApi && gridApi.showLoadingOverlay();
      const columnApis = columnApi;
      const gridApis = gridApi;
      await saveColumnStateToLocalStorage(
        type ? `${type}invoiceSearch` : "invoiceSearch",
        { columnApi: columnApis, gridApi: gridApis }
      );
      gridApi && gridApi.hideOverlay();
    } catch (e) {
      console.log("Error found in saveColumnsState::", e);
    }
  };

  const saveColumnsStateForFilter = async () => {
    try {
      gridApi && gridApi.showLoadingOverlay();
      const columnApis = columnApi;
      const gridApis = gridApi;
      await saveColumnStateToLocalStorage(
        type ? `${type}invoiceSearch` : "invoiceSearch",
        { columnApi: columnApis, gridApi: gridApis },
        true,
        false
      );
      gridApi && gridApi.hideOverlay();
    } catch (e) {
      console.log("Error found in saveColumnsStateForFilter::", e);
    }
  };

  const saveColumnsStateForSort = async () => {
    try {
      gridApi && gridApi.showLoadingOverlay();
      const columnApis = columnApi;
      const gridApis = gridApi;
      await saveColumnStateToLocalStorage(
        type ? `${type}invoiceSearch` : "invoiceSearch",
        { columnApi: columnApis, gridApi: gridApis },
        false,
        true
      );
      gridApi && gridApi.hideOverlay();
    } catch (e) {
      console.log("Error found in saveColumnsState::", e);
    }
  };

  const dataSource = {
    getRows: async (params) => {
      if (params.filterModel) {
        setFilterModel(params.filterModel);
      }
      if (params.filterModel && Object.keys(params.filterModel).length > 0) {
        gridApi.deselectAll();
      }
      const { filterModel, sortModel } = params;
      setShowFilterCount(Object.keys(filterModel).length);
      let paginationString;
      paginationString = `limit=${PAGE_LIMIT}&page=${params.endRow / PAGE_LIMIT
        }`;
      let url = "";
      url = getUrl();
      gridApi.showLoadingOverlay();
      if (sortModel.length > 0) {
        paginationString = paginationString.concat(
          `&sortOn=${sortModel[0].colId
          }&sortType=${sortModel[0].sort.toUpperCase()}`
        );
      }
      if (isClassicQuery.current) {
        const paramsStringData = getQueryParamsStringForInvoice(
          queryData.current
        );
        paginationString = paginationString.concat(paramsStringData);
      }

      if (Object.keys(filterModel).length) {
        paginationString = paginationString.concat(
          getFilterParamStringForInvoiceSummery(filterModel)
        );
      }
      if (type)
        paginationString = paginationString.concat(`&invoiceStatus=${type}`);
      const { status, data } = await dashboardDataApi(
        GET,
        url,
        paginationString
      );
      if (status === SUCCESS_STATUS_CODE) {
        if (data?.paging) {
          if (data?.paging?.totalCount === 0) {
            const message = notFoundMessage("records");
            enqueueSnackbar(message, { variant: WARNING });
          }
          params.successCallback(data?.data, data?.paging?.totalCount);
          setRowCount(data?.paging?.totalCount);
        }
        else if (Object.keys(data).length) {
          params.successCallback([data], 1);
        }
        isAllRowsSelected &&
          setSelectAllRows(isAllRowsSelected);
      } else {
        params.failCallback();
      }

      gridApi.hideOverlay();
      setIsLoading(false);
    },
  };

  const CustomLoadingOverlayComponent = () => {
    return <CircularProgress />;
  };

  const HeaderCheckbox = () => {
    return (
      <Checkbox
        style={{ padding: 0, width: 16, height: 16, color: "white" }}
        size="small"
        color="primary"
        onChange={(e) => handleChange(e)}
      />
    );
  };

  const setSelectAllRows = (isRowsSelected) => {
    setIsAllRowsSelected(isRowsSelected);
    gridApi.forEachNode((node) => {
      node.setSelected(isRowsSelected);
    });
  };

  const handleChange = (event) => {
    const checked = event.currentTarget.checked;
    setSelectAllRows(checked);
  };

  const exportCallback = async () => {
    const selectedRows = gridApi.getSelectedRows();
    if (selectedRows.length > 0) {
      let sub_route = `export-as-excel?`;
      if (type && type !== "")
        sub_route = `${sub_route}&invoiceStatus=${type ? type : ""}`;
      if (isClassicQuery.current) {
        const paramsString = getQueryParamsStringForInvoice(queryData.current);
        sub_route = `${sub_route}${paramsString}&isClassicQuery=${isClassicQuery}`;
      }
      if (filterModel && Object.keys(filterModel).length) {
        sub_route = sub_route.concat(
          getFilterParamStringForInvoiceSummery(filterModel, showQuery)
        );
        sub_route = `${sub_route}&filter=true`;
      }
      let id = [];
      if (!isAllRowsSelected) {
        selectedRows.map((data) => id.push(data.id));
      }
      const columnHeaders = columnApi
        .getAllDisplayedColumns()
        .map((column) => column.getColDef().headerName);
      gridApi.showLoadingOverlay();
      const payload = {
        headers: {
          columnHeaders: columnHeaders,
          selectedRows: { id: id },
          type: type ? type : "Invoice Summary",
          allRowSelected: isAllRowsSelected,
          limit: 1000,
        },
      };
      let { status, data, headers } = await invoiceSummaryApi(
        POST,
        "",
        payload,
        sub_route,
        BLOB
      );
      if (status === 200) {
        let fileName = "InvoiceSummary.xlsx";
        let fileNameHeader = headers["content-disposition"].split('"');
        if (fileNameHeader && fileNameHeader.length > 2) {
          fileName = fileNameHeader[1];
        }
        saveAs(new File([data], fileName));
      } else {
        enqueueSnackbar(VALIDATION_MESSAGE.export_fail, { variant: ERROR });
      }
      gridApi.hideOverlay();
    } else {
      enqueueSnackbar(VALIDATION_MESSAGE.no_record_selected, {
        variant: ERROR,
      });
    }
  };

  const IdRenderer = (params) => {
    return (
      <Link
        to={{
          pathname: `/searches/${params?.data?.searchInvoiceInfo?.id}/invoice-info`,
        }}
        target="_blank"
        rel="noopener noreferrer"
      >
        <Typography className="text-link" color="primary">
          {params?.data?.searchInvoiceInfo?.job_number}
        </Typography>
      </Link>
    );
  };

  const TitleRenderer = (params) => {
    return (
      <Link
        to={{
          pathname: `/searches/${params?.data?.search_id}/invoice-info`,
        }}
        rel="noopener noreferrer"
        target="_blank"
      >
        <Typography className="text-link" color="primary">
          {params?.data?.searchInvoiceInfo?.job_title}
        </Typography>
      </Link>
    );
  };

  const ProjectedDateRenderer = (params) => {
    const formattedDate = DateRenderer(params);
    return (
      <>
        {params && params.data  && !params.data.expenses ? (
          <Typography
            className="text-link"
            color="primary"
            onClick={() => {
              setIsProjectedDateSelected(true);
              setProjectedData(params);
            }}
          >
            {formattedDate}
          </Typography>
        ) : (
          <span>{formattedDate}</span>
        )}
      </>
    );
  };

  const showQueryState = async (value) => {
    await resetSort();
    await resetFilter();
    gridApi.onFilterChanged();
    isSelectedFromList = false;
    setShowQuery(value);
    setMinimize(!value);
    localStorage.setItem("invoiceSearch", value);
  };

  const handleQuery = (data) => {
    if (isAllRowsSelected === true) {
      setSelectAllRows(false);
      setIsAllRowsSelected(true);
    } else {
      gridApi.forEachNode((node) => {
        node.setSelected(false);
      });
    }
    localStorage.removeItem(`invoiceSummary`);
    queryData.current = data;
    gridApi.onFilterChanged();
  };

  const classicQuery = (queryData) => {
    isClassicQuery.current = true;
    handleQuery(queryData);
  };

  const pullData = () => {
    if (uiSelector === false && isQuerySelector === false) {
      setUiSelector(true);
      setIsQuerySelector(true);
    } else if (uiSelector === true && isQuerySelector === true) {
      setUiSelector(false);
      setIsQuerySelector(false);
    }
  };

  const handleProjectedDateClose = () => {
    setIsProjectedDateSelected(false);
  }
  const handleQueryClose = async () => {
    await resetSort();
    await resetFilter();
    isClassicQuery.current = false;
    setShowQuery(false);
    queryData.current = {};
    gridApi.onFilterChanged();
    if (uiSelector === true) {
      setIsQuerySelector(true);
    } else {
      setIsQuerySelector(false);
    }
    localStorage.removeItem(type ? `${type}invoiceSearch` : "invoiceSearch");
    localStorage.removeItem("invoiceSearch");
    localStorage.removeItem("invoiceQueryForm");
  };

  useEffect(() => {
    if (
      type === "billedInvoice" ||
      type === "nextTenDays" ||
      type === "yetToBeBilled" ||
      type === "overdue" ||
      type === "readyToBill"
    )
      setActive(false);
    else setActive(true);
  }, [type]);

  return (
    <div className="d-flex flex-column w-100 h-100">
      <Loader show={isLoading} />
      <Helmet>
        <title>{title ? title : "Invoice Summary"}</title>
      </Helmet>
      <div className="d-flex justify-content-between align-items-end position-sticky search-header p-3 pl-4 border-bottom-0">
        <Typography className="job-title header-style">
          Invoice Summary
        </Typography>
      </div>

      <div className="d-flex pl-4 pb-3 align-items-center">
        <Link to="/invoice-summary" className="action-button pr-2">
          <Button
            className={active ? "active-button" : ""}
            variant="outlined"
            color="primary"
          >
            All Invoices
          </Button>
        </Link>

        <Link to="/invoice-summary/billed-invoice" className="action-button pr-2">
          <Button
            variant="outlined"
            className={type === "billedInvoice" ? "active-button" : ""}
            color="primary"
          >
            Billed Invoices
          </Button>
        </Link>

        <Link to="/invoice-summary/yet-to-be-billed" className="action-button pr-2">
          <Button
            className={type === "yetToBeBilled" ? "active-button" : ""}
            variant="outlined"
            color="primary"
          >
            Yet to be Billed
          </Button>
        </Link>

        <Link to="/invoice-summary/next-ten-days" className="action-button pr-2">
          <Button
            className={type === "nextTenDays" ? "active-button" : ""}
            variant="outlined"
            color="primary"
          >
            Approaching in 10 days
          </Button>
        </Link>

        <Link to="/invoice-summary/overdue" className="action-button pr-2">
          <Button
            className={type === "overdue" ? "active-button" : ""}
            variant="outlined"
            color="primary"
          >
            OverDue
          </Button>
        </Link>

        <Link to="/invoice-summary/ready-to-bill" className="action-button">
          <Button
            className={type === "readyToBill" ? "active-button" : ""}
            variant="outlined"
            color="primary"
          >
            Ready To Bill
          </Button>
        </Link>
      </div>

      {showQuery ? (
        <div className="d-flex query-toolbar d-flex p-0 m-4">
          <ExpansionPanel
            className="flex-grow-1 w-100 m-0"
            expanded={showQuery && !minimize}
          >
            <ExpansionPanelSummary
              expandIcon={<ExpandMoreIcon onClick={() => setMinimize(true)} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              onClick={() => setMinimize(!minimize)}
              style={{ lineHeight: 1 }}
              className="pb-3 pl-3 mt-3"
            >
              Filter By
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className="w-100 p-0 pr-3 pl-3">
              <InvoiceQuery
                type={type}
                handleQuery={classicQuery}
                pullData={pullData}
              />
            </ExpansionPanelDetails>
          </ExpansionPanel>

          <div className="expansion-panel-close-container">
            <span
              style={{
                display: minimize ? "inline" : "none",
                padding: 12,
              }}
              className="cursor-pointer d-flex"
              onClick={handleQueryClose}
            >
              <CloseIcon />
            </span>
          </div>
        </div>
      ) : (
        <div className={"toolbar p-4"}>
          <Button
            className={"start-query ml-0"}
            variant={"outlined"}
            color={"primary"}
            onClick={() => showQueryState(true)}
          >
            Start Query
          </Button>
        </div>
      )}

      <div className="d-flex align-items-center justify-content-end">
        <div
          className="action-container"
          style={{ minWidth: "0px" }}
          onClick={() => resetFilter()}
        >
          <span className="action-text">Reset Filter</span>
        </div>

        <div
          className="action-container"
          style={{ minWidth: "0px" }}
          onClick={() => resetSort()}
        >
          <span className="action-text">Reset Sort</span>
        </div>
        <Button
          disabled={!selectedRowsCount}
          className="mr-3"
          variant="outlined"
          color="primary"
          onClick={exportCallback}
        >
          Export List
        </Button>
        <Typography className="mr-3">Total count: {rowCount}</Typography>
      </div>
      <div className="list-view flex-grow-1">
        {columnApi && (
          <ColumnFilter
            columnApi={columnApi}
            defaultColumns={defaultColumns}
            filterModel={filterModel}
            showFilterCount={showFilterCount}
          />
        )}
        {isProjectedDateSelected && <ConfirmProjectedDatePopup
          onClose={handleProjectedDateClose}
          projectedData={projectedData}
          enqueueSnackbar={enqueueSnackbar}
          gridApi={gridApi}
        />
        }
        <div id="myGrid" className="ag-theme-alpine pl-4">
          <AgGridReact
            enableBrowserTooltips={true}
            tooltipShowDelay={0}
            suppressHorizontalScroll={false}
            frameworkComponents={{
              HeaderCheckbox: HeaderCheckbox,
              IdRenderer: IdRenderer,
              TitleRenderer: TitleRenderer,
              ProjectedDateRenderer: ProjectedDateRenderer,
              CustomLoadingOverlayComponent,
              DateRenderer,
              CustomFilter,
            }}
            datasource={dataSource}
            rowModelType={"infinite"}
            onGridReady={onGridReady}
            suppressRowClickSelection={true}
            loadingOverlayComponent={"CustomLoadingOverlayComponent"}
            scrollbarWidth={12}
            getRowNodeId={(data) => data.id}
            columnDefs={columnDefs(enqueueSnackbar, setIsLoading)}
            defaultColDef={{
              minWidth: 100,
              resizable: true,
              sortable: true,
              sortingOrder: ["asc", "desc", null],
            }}
            onDisplayedColumnsChanged={saveColumnsState}
            onDragStopped={saveColumnsState}
            onSortChanged={saveColumnsStateForSort}
            onFilterChanged={saveColumnsStateForFilter}
            paginationPageSize={PAGE_LIMIT}
            cacheBlockSize={PAGE_LIMIT}
            rowSelection={"multiple"}
            onRowSelected={() => {
              setSelectedRowsCount(gridApi.getSelectedRows().length);
            }}
          ></AgGridReact>
        </div>
      </div>
      <Loader show={isLoading} />
    </div>
  );
}

InvoiceSummary.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
};
