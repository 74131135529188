
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useState, useEffect } from "react";

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from "prop-types";
import { withSnackbar } from "notistack";
import Autocomplete from "@material-ui/lab/Autocomplete";

//-----------------------------------------------------------// Internal Imports // ------------------------------

import InputField from "../../../components/common/InputField";
import { GET, ERROR } from "../../../services/constantService";
import { userDataApi } from "../../../services/ApiService";
import { unableMessage } from "../../../services/MessageService";

function SavedQuerySelection(props) {
  const {
    label = "Saved Queries",
    placeholder = "Search",
    enqueueSnackbar,
    InputLabelProps,
    userId,
    refreshQueries,
    type,
    ...rest
  } = props;

  const [options, setOptions] = useState([]);

  useEffect(() => {
    const getCountries = async () => {
      let { status, data } = await userDataApi(
        GET,
        userId,
        {},
        `${type}/queries`
      );
      if (status && status === 200) {
        setOptions(data);
      } else {
        const message = unableMessage("saved queries", "fetch");
        enqueueSnackbar(message, { variant: ERROR });
      }
    };
    if (userId) {
      getCountries().then(null);
    }
  }, [userId, enqueueSnackbar, refreshQueries]);

  return (
    <Autocomplete
      {...rest}
      options={options}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => (
        <InputField
          {...params}
          InputLabelProps={InputLabelProps}
          label={label}
          placeholder={placeholder}
        />
      )}
    />
  );
}

SavedQuerySelection.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  enqueueSnackbar: PropTypes.func,
  InputLabelProps: PropTypes.object,
  userId: PropTypes.string,
  refreshQueries: PropTypes.bool,
};

export default withSnackbar(SavedQuerySelection);
