//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react'

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types'
import { useForm } from "react-hook-form";
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import Details from './Components/Details'
import EducationDetails from '../../../AddContact/Components/EducationDetails'
import './index.scss';
import IndustryAndJobFunction from '../../../AddContact/Components/IndustryAndJobFunction'
import { NextStepsAndRationale } from "./Components/NextStepsAndRationale";
import { ExpandableSection } from "../ExpandableSection";
import TabLayoutFooter from '../../../TabLayoutFooter';
import { formSchema, getActivityGenralInfoPayload, getGeneralNotesDefaultValues, removeSkipFields } from '../../utils';
import { API, EMAIL_REGEX, ERROR, SUCCESS } from '../../../../services/constantService';
import { successMessage, unableMessage, validateMessage } from '../../../../services/MessageService';
import { cleanupFormValues, customFormValidator, requireValidMessage } from '../../../../utils/common';
import httpService from '../../../../services/httpService';

// eslint-disable-next-line react/display-name
const GeneralInfo = forwardRef((props, ref) => {
  const { isContactActivityLog, saveStatus, candidateId, handleSaveTabData, checkJobDataUpdated, getActivityLogInfoTabData, readOnly, isRichTextSaved, setRichTextSaved, getActivityLogInfo: reloadPerent, saveStatusSetter, setParentUpdatedAt } = props
  const [tabInfo, setTabInfo] = useState(null)
  const [currentValues, setCurrentValues] = useState(null)
  const [formDirtyFields, setFormDirtyFields] = useState([])
  const { id, path, contactId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { register, reset, handleSubmit, getValues, watch, setValue, formState } = useForm({});
  const { dirtyFields } = formState

  const actions = [
    { label: 'Save & Close', className: 'primary-btn mr-3', onClick: () => { handleSave(true) } },
    { label: 'Save', className: 'primary-btn', onClick: () => { handleSave(false, false, true) } }
  ]

  const getActivityLogInfo = useCallback(async (hideLoader) => {
    const data = await getActivityLogInfoTabData(hideLoader, 'GENERAL_INFO_TAB')
    if (data) {
      setTabInfo(data)
    }
  }, [contactId, id, path, enqueueSnackbar])

  useEffect(() => {
    getActivityLogInfo();
  }, [getActivityLogInfo])

  const resetGeneralInfoTab = (data) => {
    let defaultValues = getGeneralNotesDefaultValues(data);
    if (defaultValues && isRichTextSaved) {
      defaultValues = { ...defaultValues, ...cleanupFormValues(getValues({ nest: true })) }
      const forcedDirtyFields = removeSkipFields([...dirtyFields, ...formDirtyFields])
      setFormDirtyFields(forcedDirtyFields);
    }
    if (defaultValues) {
      setCurrentValues(defaultValues)
      reset(defaultValues)
      setParentUpdatedAt(defaultValues.candidateUpdatedAt)
    }
  }

  const autoSave = async (value, skipMessage, setAutoSaveLoading) => {
    setAutoSaveLoading && setAutoSaveLoading(true)
    const { status, data } = await httpService.put(`${API.candidate}/${candidateId}`, value);
    if (status === 200) {
      const message = successMessage("Details", "updated")
      !skipMessage && enqueueSnackbar(message, { variant: SUCCESS });
      setRichTextSaved(true);
      delete value.old
      const key = Object.keys(value)[0]
      const newValue = Object.values(value)[0]
      setValue(key, newValue)
      saveStatusSetter(true)
      await getActivityLogInfo(true)
    } else {
      const message = unableMessage("details", "update")
      enqueueSnackbar(data?.message || message, { variant: ERROR })
      saveStatusSetter(true)(false)
    }
    setAutoSaveLoading && setTimeout(() => {
      setAutoSaveLoading(false)
    }, 500)
  };

  useEffect(() => {
    resetGeneralInfoTab(tabInfo)
  }, [tabInfo, reset, getValues])

  const validateEmail = (email) => {
    return email && !email.match(EMAIL_REGEX) ? false : true;
  }

  const validateEmailFields = (formValues) => {
    let validateWorkEmail = true;
    let validatePrivateEmail = true;
    validateWorkEmail = validateEmail(formValues.work_email)
    validatePrivateEmail = validateEmail(formValues.private_email)
    if (!validateWorkEmail || !validatePrivateEmail) {
      const message = validateMessage(`valid email`, "enter")
      enqueueSnackbar(message, { variant: ERROR });
      // handleSubmit(null);
      return false;
    }
    return true
  }

  const handleSave = async (saveAndClose, skipMessage = false, reload) => {
    const formValues = getValues({ nest: true });
    let result
    const isValid = await formSchema.isValid(formValues)
    if (!isValid) {
      let requiredField = [
        { fieldName: "current_company", label: "Company Name", type: {} },
        { fieldName: "current_job_title", label: "Title", type: String },
        { fieldName: "country", label: "Location", type: String },
        { fieldName: "industries", label: "Industry", type: [] },
        { fieldName: "job_functions", label: "Job Functions", type: [] }
      ]
      let dirtyField = customFormValidator(formValues, requiredField)
      if (dirtyField) {
        const message = requireValidMessage(dirtyField)
        enqueueSnackbar(message, { variant: ERROR })
        return
      }
    }
    const validEmail = validateEmailFields(formValues)
    if (!validEmail) return false;
    const newData = checkJobDataUpdated(tabInfo.contact, formValues, { saveAndClose, skipMessage, reload: true })
    if (!newData.continue) return false;
    const newDirtyFields = [...dirtyFields, ...formDirtyFields];
    const payload = await getActivityGenralInfoPayload(newData.formValues, newDirtyFields);
    if (payload && payload.contact && payload.contact.state) {
      payload.contact.country = formValues.country
    }
    if (payload && tabInfo.contact) { payload.contact.updated_at = tabInfo.contact.updated_at }
    result = await handleSaveTabData(payload, saveAndClose, skipMessage, false, 0,)
    if (result && reload) {
      setRichTextSaved(false)
      setFormDirtyFields([]);
      await getActivityLogInfo();
      await reloadPerent();
    }
    if (!result) {
      handleSubmit(null)
    }
    return result
  }

  useImperativeHandle(ref, () => ({
    saveTabData: (saveAndClose, skipMessage = false, reload) => {
      return handleSave(saveAndClose, skipMessage, reload)
    },
    isDirty: async (saveAndClose, skipMessage = false) => {
      const formValues = getValues({ nest: true });
      const isValid = await formSchema.isValid(formValues)
      if (!isValid) {
        return false;
      }
      const validEmail = validateEmailFields(formValues)
      if (!validEmail) return false;
      const newData = checkJobDataUpdated(tabInfo.contact, formValues, { saveAndClose, skipMessage, reload: true })
      if (!newData.continue) return false;
      let fields = removeSkipFields([...dirtyFields, ...formDirtyFields]);
      return fields.length > 0
    },
    formData: () => {
      return getValues({ nest: true })
    },
    reload: (hideLoader) => {
      getActivityLogInfo(hideLoader);
    },
    getContact: () => { return tabInfo?.contact }
  }));

  useEffect(() => {
    register('current_job_start_year')
  }, [register])

  useEffect(() => {
    if (currentValues) {
      reset(currentValues)
    }
  }, [currentValues])


  return currentValues && (
    <div className='overflow-auto'>
      <div className="d-flex contact-view input-form general-info">
        <div className="general-info-left">
          <Details
            currentValues={currentValues}
            register={register}
            watch={watch}
            isContactActivityLog={isContactActivityLog}
            setValue={setValue}
            autoSave={autoSave}
            saveStatus={saveStatus}
          />
        </div>
        <div className="general-info-middle">
          <NextStepsAndRationale
            saveStatus={saveStatus}
            register={register}
            currentValues={currentValues}
            isContactActivityLog={isContactActivityLog}
            autoSave={autoSave}
          />
          <ExpandableSection title='Industry and Job Function'>
            <IndustryAndJobFunction
              register={register}
              watch={watch}
              setValue={setValue}
              showTitle={false}
            />
          </ExpandableSection>
          <ExpandableSection title='Education Details'>
            <EducationDetails
              currentValues={currentValues}
              register={register}
              setValue={setValue}
              showTitle={false}
            />
          </ExpandableSection>
        </div>
        <div className="general-info-right" />
      </div>
      {!readOnly && <TabLayoutFooter actions={actions} />}
    </div>
  )
});

GeneralInfo.propTypes = {
  candidateId: PropTypes.string,
  saveStatus: PropTypes.string,
  isContactActivityLog: PropTypes.string,
  checkJobDataUpdated: PropTypes.func,
  getActivityLogInfoTabData: PropTypes.func,
  handleSaveTabData: PropTypes.func,
  readOnly: PropTypes.bool,
  isRichTextSaved: PropTypes.bool,
  setRichTextSaved: PropTypes.func,
  getActivityLogInfo: PropTypes.func,
  saveStatusSetter: PropTypes.func,
  setParentUpdatedAt: PropTypes.func
}

export default GeneralInfo;