//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useState, useEffect } from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import Popup from "reactjs-popup";
import PropTypes from "prop-types";
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { withSnackbar } from "notistack";
import CloseIcon from "@material-ui/icons/Close";
import { Button, Typography } from "@material-ui/core";
import { RadioGroup, FormControlLabel, Radio } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import { useForm } from 'react-hook-form';
import { useDispatch } from "react-redux";

//----------------------------------------------// Internal Imports // -------------------------------------------------
import Loader from '../../../../../../components/common/Loader';
import { formatDate } from "../../../../../../utils/date";
import { athenaInviteApi } from "../../../../../../services/ApiService";
import { SUCCESS, ERROR } from "../../../../../../services/constantService";
import { unableMessage, successMessage, inviteEmailValidateMessage } from "../../../../../../services/MessageService";
import { searchActions } from "../../../../../../store/searchSlice";

function AthenaInvitePopUp(props) {
  const { client, inviteFor, onClose, selectedDropDown, enqueueSnackbar, isdisableSendButton = false, resetState = ()=>{} } = props;
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const defaultValues = {
    emailType: inviteFor === 'client' ?
      client.contact.work_email ? 'Work' : 'Private' :
      client.contact.private_email ? 'Private' : 'Work',
    locale: 'en_US',
    bcc: '',
    reset: false
  }
  const { register, handleSubmit, setValue } = useForm({ defaultValues });
  const [popUpOpen, setPopUpOpen] = useState(true)
  const [resetInvite, setResetInvite] = useState(false)

  useEffect(() => {
    if (isdisableSendButton) {
      const message = inviteEmailValidateMessage('athena');
      enqueueSnackbar(message, { variant: ERROR });
    }
    if (client.contact.athena_invitation_sent_on) {
      setResetInvite(true)
    }
  }, [client])

  const sendAthenaInvite = async (formData) => {
    setIsLoading(true)
    const inviteId = (inviteFor === 'client') ? client.client_id : client.candidate.id;
    const email = (formData.emailType === 'Work') ? client.contact.work_email : client.contact.private_email
    const sub_route = `${inviteFor}/${inviteId}/invite`
    const payload = { locale: formData.locale, athena_invite_type: selectedDropDown, email: email, bcc: formData.bcc, reset: formData.reset }
    const { status, data } = await athenaInviteApi(sub_route, payload);
    if (status && status === 200 && data.success === true) {
      const message = successMessage("Invite", "sent")
      enqueueSnackbar(message, { variant: SUCCESS });
      setPopUpOpen(false)
      onClose(true)
    } else if (status === 200 && data?.message.includes('An invite has already been sent')) {
      setResetInvite(true)
    } else {
      const message = unableMessage("athena invite", "send")
      enqueueSnackbar(data?.message || message, { variant: ERROR });
      setPopUpOpen(false)
      onClose(false)
    }
    await resetSearchData()

    setIsLoading(false)
  }

  const resetSearchData = async () => {
    dispatch(searchActions.resetSearchState())
    await resetState(true)
  }

  const getName = (client) => {
    const firstName = client?.contact.first_name ? client.contact.first_name : ''
    const lastName = client?.contact.last_name ? client.contact.last_name : ''
    return firstName + ' ' + lastName
  }

  useEffect(() => {
    register('locale');
    register('bcc');
    register('reset');
  }, [register])

  const getDate = (date) => {
    if (date) {
      return formatDate(date)
    }
    return 'N.A'
  }

  const handleResentInvite = () => {
    setResetInvite(false);
    setValue('reset', true)
  }

  return (
    <Popup open={popUpOpen} className="athena-invite client-suite-invite" closeOnDocumentClick={false} closeOnEscape={false}>
      <>
        <Loader show={isLoading} />
        <div className="d-flex flex-column h-100">
          <div className="d-flex align-items-center justify-content-between popup-header" style={{ background: '#c3c3c35c' }}>
            <div className="d-flex align-items-center">
              <h4 className="mr-5 color-white">Athena</h4>
            </div>
            <span className="action-icon cursor-pointer color-white" onClick={onClose}>
              <CloseIcon />
            </span>
          </div>
          {resetInvite ?
            <div className="client-suite-invite-options text-center">
              <Typography style={{ fontSize: 18, paddingBottom: 10 }}>An invitation has already been sent to <span style={{ fontWeight: 600 }}>{getName(client)}</span></Typography>
              <Typography style={{ paddingBottom: 20 }}>Are you sure you want to send an invite again?</Typography>
              <Typography>Athena invitation sent on: {getDate(client.contact.athena_invitation_sent_on)}</Typography>
              <Typography>Athena first logged in on: {getDate(client.contact.first_athena_login)}</Typography>
              <Typography>Athena completed on: {getDate(client.contact.athena_completion_date)}</Typography>
            </div>
            :
            <div className="client-suite-invite-options">
              <div className="field-details">
                <span className="field-title">Contact Name</span>
                <span className="field-value">{getName(client)}</span>
              </div>
              <div className="field-details">
                <span className="field-title">Select Language</span>
                <div className="field-value">
                  <Select defaultValue={defaultValues.locale} onChange={(e) => setValue('locale', e.target.value)}>
                    <MenuItem value='en_US'>English</MenuItem>
                    <MenuItem value='es_ES'>Spanish</MenuItem>
                    <MenuItem value='pt_BR'>Portuguese</MenuItem>
                  </Select>
                </div>
              </div>
              <div className="field-details align-items-start">
                <span className="field-title mt-2">Send Email To</span>
                <RadioGroup aria-label='email' name='emailType' defaultValue={defaultValues.emailType} className="field-value">
                  <FormControlLabel
                    control={<Radio size="small" value={'Work'} inputRef={register} />}
                    label={`Work Email(${client.contact.work_email || 'N.A'})`}
                    disabled={!client.contact.work_email}
                  />
                  <FormControlLabel
                    control={<Radio size="small" value={'Private'} inputRef={register} />}
                    label={`Private Email(${client.contact.private_email || 'N.A'})`}
                    disabled={!client.contact.private_email}
                  />
                </RadioGroup>
              </div>
              <div className="field-details">
                <span className="field-title">BCC</span>
                <TextField required={true} InputLabelProps={{ focused: true }} className='input-form-field' inputRef={register} name={'bcc'} />
              </div>
            </div>
          }
          {resetInvite ?
            <div className='action-buttons text-center' style={{ background: '#c3c3c35c' }}>
              <Button onClick={onClose} style={{ marginRight: '20%' }} color='primary' variant='outlined'>No</Button>
              <Button onClick={handleResentInvite} color='primary' variant='contained' >Yes</Button>
            </div> :
            <div className="action-buttons" style={{ background: '#c3c3c35c' }}>
              <Button onClick={onClose} color="primary" className="mr-2">Cancel</Button>
              <Button variant="contained" className="button" color="primary" disabled={ isdisableSendButton } onClick={handleSubmit(sendAthenaInvite)} autoFocus>Send</Button>
            </div>
          }
        </div>
      </>
    </Popup>
  )
}

AthenaInvitePopUp.propTypes = {
  client: PropTypes.object,
  inviteFor: PropTypes.string,
  onClose: PropTypes.func,
  enqueueSnackbar: PropTypes.func,
  selectedDropDown: PropTypes.string,
  isdisableSendButton: PropTypes.bool,
  resetState: PropTypes.func
}

export default withSnackbar(AthenaInvitePopUp);
