//----------------------------------------------// In-built Imports // -------------------------------------------------
import React from 'react'

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from "prop-types";
//----------------------------------------------// Internal Imports // -------------------------------------------------
import './index.scss'
import { SCORES_DESCRIPTION as scoreDesc } from '../../services/constantService';
import CustomInfoPopup from '../CustomInfoPopup/Popup';


export default function ScoresDefinitionPopup(props) {
  const { onClose, columnHeaders={} } = props;
  const SCORES_DESCRIPTION = scoreDesc(columnHeaders);
  return (
    <CustomInfoPopup onClose={onClose} header='Scores Definition'>

      <div className='d-flex py-3 pr-5 score-definition-content'>
        <div className='col-10'>
          {SCORES_DESCRIPTION.map((item, index) => {
            return (
              <div key={index} className='d-flex contact-details-row  padding-score-definition'>
                <div className='col-3 font-weight-bold score-label'>{item.name}</div>
                <div className='col-7'>{item.description}</div>
              </div>
            )
          })}
        </div>
        <div className='col-2'>
          <span className='font-weight-bold score-label'>Score Range</span>
          <div className='border score-height contact-details-row'>
            <div className="product-one-score-content">
              <div className="product-one-color color-green"></div><span className="score-legend-text">85 - 100</span>
            </div>
            <div className="product-one-score-content">
              <div className="product-one-color color-orange"></div><span className="score-legend-text">65 - 84</span>
            </div>
            <div className="product-one-score-content">
              <div className="product-one-color color-gray"></div><span className="score-legend-text">0 - 64</span>
            </div>
          </div>
        </div>
      </div>
    </CustomInfoPopup>
  )
}
ScoresDefinitionPopup.propTypes = {
  onClose: PropTypes.func,
  columnHeaders: PropTypes.object
}
