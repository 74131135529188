//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useState, useEffect, useRef } from 'react'
import * as yup from "yup";


//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { Button, CircularProgress, Typography } from "@material-ui/core";
import Popup from "reactjs-popup";
import axios from "axios";

//----------------------------------------------// Internal Imports // -------------------------------------------------
import QuickAddContact from './QuickAddContact';
import { customFormValidator, requireValidMessage, SEARCH_TIMEOUT } from "../../utils/common";
import './index.scss';
import { API, POST, ERROR, SUCCESS } from '../../services/constantService';
import { contactDataApi, getMethodWithCancelTokenApi } from '../../services/ApiService';
import InputField from '../common/InputField';
import { unableMessage } from '../../services/MessageService';
import { useSnackbar } from 'notistack';

const filter = createFilterOptions();
const CancelToken = axios.CancelToken;

export default function ContactSelection(props) {
  const {
    label = "Contact",
    placeholder = "Search",
    InputLabelProps,
    required,
    defaultValue = null,
    onChange,
    inputRef,
    multiple = false,
    // enqueueSnackbar,
    autoFocus = false,
    variant = 'standard',
    client_Contact = false,
    list,
    // eslint-disable-next-line no-unused-vars
    ...rest
  } = props;

  const [options, setOptions] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [open, toggleOpen] = React.useState(false);
  const [name, setName] = useState('');
  const [value, setValue] = useState(multiple ? [] : null);
  let call = useRef();

  const filterSelectedContact = (data, flag) => {
    let res = [];
    res = data.filter(el => {
      const checkArray = flag ? list : defaultValue;
      return  !checkArray.find(element => {
        return element.id === el.id;
      });
    });
    return res;
  }
  useEffect(() => {
    if (defaultValue !== undefined) {
      if (multiple && !defaultValue) {
        setValue([])
      } else {
        setValue(defaultValue);
      }
    }
  }, [defaultValue, setValue, multiple])

  useEffect(() => {
    if (call.current) {
      call.current.cancel();
    }
    if (name.length < 3) {
      setOptions([])
      setLoading(false)
      return;
    }
    call.current = CancelToken.source();
    setLoading(true)
    const timer = setTimeout(() => {
      getMethodWithCancelTokenApi(API.picklists_contacts, { name }, {}, {}, call.current.token)
        .then(response => {
          const { status, data } = response;
          if (status === 200 && data && data.data) {
            if (list) {
              if (list.length) {
                const filterData = filterSelectedContact(data.data, true)
                setOptions(filterData)
              } else{ 
                setOptions(data.data);
              }
            } else if (defaultValue && defaultValue.length) {
              const filterData = filterSelectedContact(data.data)
              setOptions(filterData)
            } else {
              setOptions(data.data);
            }
          }
          setLoading(false)
        })
    }, SEARCH_TIMEOUT)
    return () => clearTimeout(timer);
  }, [name, (client_Contact === false && defaultValue), (client_Contact===false && list)])

  const CreateSchema = yup.object().shape({
    first_name: yup.string().required(),
    last_name: yup.string().required(),
    current_company: yup.object().required(),
    current_job_title: yup.string().required(),
    current_job_start_year: yup.string().required(),
    country: yup.string().required(),
    industries: yup.array().of(yup.string()).required(),
    job_functions: yup.array().of(yup.string()).required(),
  });

  const addContact = async (contact, allowDuplicate) => {
    const isValid = await CreateSchema.isValid(contact)
    if (!isValid) {
      let requiredField = [
        { fieldName: "first_name", label : "First Name",  type: String },
        { fieldName: "last_name", label : "Last Name",  type: String },
        { fieldName: "current_company", label : "Company Name",  type: Object },
        { fieldName: "current_job_title", label : "Job Title",  type: String },
        { fieldName: "current_job_start_year", label : "Job Start Year",  type: String },
        { fieldName: "country", label : "Location",  type: String },
        { fieldName: "industries", label : "Industry",  type: [] },
        { fieldName: "job_functions", label : "Job Functions",  type: [] },

      ]
      let dirtyField = customFormValidator(contact, requiredField)
      if(dirtyField){
        const message = requireValidMessage(dirtyField)
        enqueueSnackbar(message, { variant: ERROR })
        return
      }
    }
    contact.current_company_id = contact.current_company.id;
    const sub_route = allowDuplicate ? '?allowDuplicate=true' : ''
    const { status, data } = await contactDataApi(POST, '', contact, sub_route);
    if (status === 201) {
      const newContact = { first_name: contact.first_name, last_name: contact.last_name, id: data.id }
      setOptions(prevState => ([newContact, ...prevState]))
      let updatedValue;
      if (multiple) {
        updatedValue = [...value, newContact]
      } else {
        updatedValue = newContact;
      }
      setValue(updatedValue)
      onChange(new Event('onChange'), updatedValue);
      enqueueSnackbar("Contact Added Successfully", { variant: SUCCESS })

    } else if (status === 200) {
      return data.contacts;
    }
    else {
      const message = unableMessage("contact", "add")
      enqueueSnackbar(data?.message || message, { variant: ERROR });
    }
    toggleOpen(false)
  }

  const handleClose = () => {
    toggleOpen(false)
  }

  return (
    <React.Fragment>
      <Popup open={open} className={"quick-add-contact"} onClose={handleClose} closeOnDocumentClick={false} closeOnEscape={false} >
        <QuickAddContact handleClose={handleClose} addContact={addContact} first_name={name} />
      </Popup>
      <Autocomplete
        {...rest}
        value={value}
        multiple={multiple}
        options={options}
        getOptionLabel={(option) => { return option.first_name + ' ' + option.last_name }}
        onInputChange={(e, val, reason) => {
          if (reason === 'input') {
            setName(val);
          }
        }}
        getOptionSelected={(option, value) => (option.id === value.id)}
        onChange={(event, newValue) => {
          if (newValue && (newValue.inputValue || (Array.isArray(newValue) && newValue.length && newValue[newValue.length - 1].inputValue))) {
            toggleOpen(true);
            setName(newValue.inputValue || newValue[newValue.length - 1].inputValue)
            return;
          }
          setValue(newValue);
          onChange(event, newValue)
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params)
          if (params.inputValue) {
            filtered.push({
              inputValue: params.inputValue,
            })
          }
          return filtered
        }}
        renderOption={(option => {
          if (option.inputValue) {
            return (
              <div style={{ width: '100%', borderTop: '1px solid #dfdfdf' }}>
                <Button
                  variant={"text"}
                  startIcon={<AddCircleIcon />}
                  color='primary'>
                  ADD NEW CONTACT
                </Button>
              </div>
            )
          }
          const jobTitle = option.current_job_title || '';
          const companyName = option.company?.name || '';
          return (
            <div>
              <Typography>{option.first_name + ' ' + option.last_name}</Typography>
              <Typography style={{ fontSize: 14, fontStyle: 'italic' }}>{'(' + jobTitle + ' - ' + companyName + ')'}</Typography>
            </div>
          )
        })}
        renderInput={(params) => (
          <InputField
            {...params}
            ref={inputRef}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="primary" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            autoFocus={autoFocus}
            InputLabelProps={InputLabelProps}
            required={required}
            label={label}
            placeholder={placeholder}
            variant={variant}
          />
        )}
      />
    </React.Fragment>
  )
}

ContactSelection.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  InputLabelProps: PropTypes.object,
  defaultValue: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  required: PropTypes.bool,
  onChange: PropTypes.func,
  enqueueSnackbar: PropTypes.func,
  closeSnackbar: PropTypes.func,
  inputRef: PropTypes.object,
  multiple: PropTypes.bool,
  autoFocus: PropTypes.bool,
  variant: PropTypes.string,
  client_Contact: PropTypes.bool,
  list:PropTypes.array
}
