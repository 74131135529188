//----------------------------------------------// In-built Imports // -------------------------------------------------
import React from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from "prop-types";

//----------------------------------------------// Internal Imports // -------------------------------------------------
import KGPTeamInfoView from "./View";
import KGPTeamInfo from "../../../CreateSearch/Components/KGPTeamInfo";

export default function KGPTeamInfoContainer(props) {
  const { register, control, setValue, watch, currentValues, isEditing = true, editingField, renderActions, renderLabel, option } = props

  return (
    isEditing ?
      <KGPTeamInfo
        option={option}
        register={register}
        control={control}
        setValue={setValue}
        watch={watch}
        isEditing={isEditing}
        currentValues={currentValues}
        types="ViewSearch"
      />
      :
      <KGPTeamInfoView
        option={option}
        register={register}
        control={control}
        setValue={setValue}
        watch={watch}
        isEditing={isEditing}
        currentValues={currentValues}
        renderLabel={renderLabel}
        editingField={editingField}
        renderActions={renderActions}
      />
  )
}

KGPTeamInfoContainer.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  watch: PropTypes.func,
  control: PropTypes.object,
  currentValues: PropTypes.object,
  isEditing: PropTypes.bool,
  renderLabel: PropTypes.func,
  renderActions: PropTypes.func,
  editingField: PropTypes.string,
  option:PropTypes.array
}
