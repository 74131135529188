//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect } from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from "prop-types";
import ReactPhoneInput from 'react-phone-input-material-ui';
import { TextField, Typography } from "@material-ui/core";
import 'react-phone-input-mui/dist/style.css';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import InputField from "../../../components/common/InputField";
import UserSelection from "../../UserSelection";


export default function CommunicationDetails(props) {
  const { register, dynamicKey, watch, setValue, option } = props;

  useEffect(() => {
    register('bd_target')
    register('mobile_phone')
    register('kg')
  }, [register])

  return (
    <div className='w-100 section-content'>
      <Typography className='section-label'>Communication</Typography>
      <div className='d-flex flex-grow-1'>
        <div className='input-form-field input-field-old'>
          <ReactPhoneInput
            ref={register('direct_line')}
            placeholder='Direct Line'
            value={watch('direct_line') || ''}
            onChange={(data, c, e, value) => {
              if (data.length > 1) {
                setValue('direct_line', value)
              } else {
                setValue('direct_line', '')
              }
            }}
            name='direct_line'
            enableSearch={true}
            key={dynamicKey + 'direct'}
            inputProps={{
              name: 'direct_line',
              label: "Direct line"
            }}
            component={TextField}
          />
        </div>
        <div className='input-form-field input-field-old'>
          <ReactPhoneInput
            placeholder='Mobile Phone'
            value={watch('mobile_phone') || ''}
            onChange={(data, c, e, value) => {
              if (data.length > 1) {
                setValue('mobile_phone', value)
              } else {
                setValue('mobile_phone', '')
              }
            }}
            enableSearch={true}
            key={dynamicKey + 'mobile'}
            inputProps={{
              label: 'Mobile Phone'
            }}
            component={TextField}
          />
        </div>
      </div>
      <div className='d-flex flex-grow-1'>
        <div className='input-form-field input-field-old'>
          <ReactPhoneInput
            ref={register('home_phone')}
            placeholder='Home Phone'
            value={watch('home_phone') || ''}
            onChange={(data, c, e, value) => {
              if (data.length > 1) {
                setValue('home_phone', value)
              } else {
                setValue('home_phone', '')
              }
            }}
            enableSearch={true}
            key={dynamicKey + 'home'}
            inputProps={{
              label: 'Home Phone'
            }}
            component={TextField}
          />
        </div>
        <InputField
          ref={register()}
          className='input-form-field input-field-old'
          type='email'
          label='Work Email'
          placeholder='Work Email'
          InputLabelProps={{ focused: true }}
          name='work_email'
        />
      </div>
      <div className='d-flex w-50'>
        <InputField
          ref={register()}
          className='input-form-field input-field-old'
          type='email'
          label='Private Email'
          placeholder='Private Email'
          InputLabelProps={{ focused: true }}
          name='private_email'
        />
      </div>
      <div className='d-flex'>
        <UserSelection
          option={option}
          value={watch('bd_target') || ''}
          className='input-form-field input-field-old'
          label='BD Target'
          InputLabelProps={{ focused: true }}
          onChange={(e, data) => { setValue('bd_target', data) }}
        />
        <InputField
          ref={register}
          className='input-form-field input-field-old'
          label='Relationship'
          placeholder='Relationship'
          InputLabelProps={{ focused: true }}
          name='relationship'
        />
      </div>
      <div className='d-flex w-50'>
        <UserSelection
          option={option}
          multiple
          InputLabelProps={{ focused: true }}
          // InputLabelProps={{ focused: true}}
          filterSelectedOptions={true}
          value={watch('kg') || []}
          className='input-form-field input-field-old'
          label='KG 25'
          name='kg'
          onChange={(e, data) => {
            setValue('kg', Array.isArray(data) ? data : []);
          }}
        />
      </div>
    </div>
  );
}

CommunicationDetails.propTypes = {
  register: PropTypes.func,
  control: PropTypes.object,
  dynamicKey: PropTypes.string,
  watch: PropTypes.func,
  setValue: PropTypes.func,
  option: PropTypes.array
};
