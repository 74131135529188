//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useCallback, useEffect, useState } from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { CLIENTSUITE, GET, POST, PUT, REPLY, UPDATED_AT, ERROR, SUCCESS } from "../../../../services/constantService";
import { getUserSelector } from "../../../../selectors";
import TextPopup from "../../../TextPopup";
import { formatDate } from "../../../../utils/date";
import { clientFeedbackDataApi } from "../../../../services/ApiService";
import { unableMessage, successMessage } from "../../../../services/MessageService";
import { UPDATE_CLIENTSUITE_FEEDBACK } from "../../../../types";
import { useImperativeHandle } from "react";
import { forwardRef } from "react";
import { useForm } from "react-hook-form";
import TabLayoutFooter from "../../../TabLayoutFooter";

// eslint-disable-next-line react/display-name
const ClientSuiteFeedback = forwardRef((props, ref) => {
  const { candidateId, setLoading, readOnly, handleBack = { handleBack } } = props
  const [clientSuiteFeedback, setClientSuiteFeedback] = useState([])
  const { enqueueSnackbar } = useSnackbar()
  const user = useSelector(getUserSelector)
  const { getValues } = useForm({});
  const actions = [
    { label: 'Close', className: 'primary-btn mr-3', onClick: () => { handleBack(true) } },
  ]

  useImperativeHandle(ref, () => ({
    saveTabData: () => {
      return true
    },
    isDirty: () => {
      return false
    },
    formData: () => {
      return getValues({ nest: true })
    },
    reload: () => {
      fetchData();
    }
  }));

  const dispatch = useDispatch()
  const reduxClientSuiteFeedback = useSelector(state => state.rootReducer.reduxClientSuiteFeedback)
  const fetchData = useCallback(async () => {
    setLoading(true)
    if (!reduxClientSuiteFeedback) {
      const sub_route = `client-feedback?type=${CLIENTSUITE}&sortBy=${UPDATED_AT}`
      const { status, data } = await clientFeedbackDataApi(GET, candidateId, {}, sub_route)
      if (status === 200 && Array.isArray(data)) {
        dispatch({ type: UPDATE_CLIENTSUITE_FEEDBACK, payload: { key: "reduxClientSuiteFeedback", data } })
        setClientSuiteFeedback(data)
      } else {
        const message = unableMessage('client feedback', 'fetch')
        enqueueSnackbar(data.message || message, { variant: ERROR })
      }
    }
    else {
      setClientSuiteFeedback(reduxClientSuiteFeedback)
    }
    setLoading(false)
  }, [candidateId, setLoading, enqueueSnackbar])
  useEffect(() => {
    fetchData()
  }, [fetchData])
  const renderField = (label, value) => {
    return (
      <div className="d-flex align-items-center mb-1">
        <Typography color="primary">{label}</Typography>
        <span className='ml-2 mr-4'>{value}</span>
      </div>
    )
  }
  const renderTextField = (label, value, onSave, userName) => {
    return (
      <div className="mb-2">
        <div className="d-flex">
          <Typography color="primary" style={{ fontSize: 14 }}>{label}</Typography>
          {label === "KG Response" && <Typography color="primary" style={{ fontSize: 14 }} className="ml-auto">{userName}</Typography>}
        </div>
        <TextPopup expandToContent={true} title={label} onSave={onSave} className='w-100' value={value} />
      </div>
    )
  }
  const updateClientFeedback = async (index, comments) => {
    try {
      if (clientSuiteFeedback[index].comments !== comments) {
        setLoading(true)
        const { status, data } = await clientFeedbackDataApi(PUT, clientSuiteFeedback[index].id, { comments })
        if (status === 200) {
          const message = successMessage('Comments', 'updated')
          enqueueSnackbar(message, { variant: SUCCESS })
          await fetchData()
        } else {
          const message = unableMessage('comments', 'update')
          enqueueSnackbar(data.message || message, { variant: ERROR })
        }
        setLoading(false)
      }
    }
    catch (e) {
      console.error("Error found in updateClientFeedback::", e);
    }
  }
  const updateReply = async (feedback, replyText) => {
    try {
      const reply = feedback.replies[0]
      setLoading(true)
      if (reply) {
        if (reply.reply !== replyText) {
          const { status, data } = await clientFeedbackDataApi(PUT, reply.id, { reply: replyText }, REPLY)
          if (status === 200) {
            const message = successMessage('Reply', 'updated')
            enqueueSnackbar(message, { variant: SUCCESS })
            await fetchData()
          } else {
            const message = unableMessage('reply', 'update')
            enqueueSnackbar(data.message || message, { variant: ERROR })
          }
        }
      } else if (replyText) {
        const payload = { reply: replyText, client_type: 'user', client_id: user.id }
        const { status, data } = await clientFeedbackDataApi(POST, feedback.id, payload, REPLY)
        if (status === 200) {
          const message = successMessage('Reply', 'added')
          enqueueSnackbar(message, { variant: SUCCESS })
          await fetchData()
        } else {
          const message = unableMessage('reply', 'add')
          enqueueSnackbar(data.message || message, { variant: ERROR })
        }
      }
      setLoading(false)
    }
    catch (e) {
      console.error("Error found in updateReply::", e);
    }
  }
  const renderClientFeedback = (feedback, index) => {
    return (
      <div key={index} className='feedback card'>
        <h6 className="mb-3">Client Feedback</h6>
        <div>
          <div className='mb-2 d-flex justify-content-between'>
            {renderField('Client Name:', feedback.client_name)}
            {renderField('Date:', formatDate(feedback.updated_at || feedback.created_at, 'DD/MM/YYYY'))}
          </div>
          {
            renderTextField('Comments', feedback.comments, (data) => { updateClientFeedback(index, data) }, feedback?.replies[0]?.client_name)
          }
          {
            renderTextField('KG Response', feedback.replies[0]?.['reply'], (data) => { updateReply(feedback, data) }, feedback?.replies[0]?.client_name)
          }
        </div>
      </div>
    )
  }
  return (
    <div>
      <div className='client-suite-feedback'>
        <Typography className='heading'>ClientSuite Feedback Log</Typography>
        {
          clientSuiteFeedback.map((item, index) => renderClientFeedback(item, index))
        }
      </div>
      {!readOnly && <TabLayoutFooter actions={actions} />}
    </div>
  )
})
ClientSuiteFeedback.propTypes = {
  candidateId: PropTypes.string,
  setLoading: PropTypes.func,
  readOnly: PropTypes.bool,
  handleBack: PropTypes.func,
}
export default ClientSuiteFeedback;