//----------------------------------------------// In-built Imports // -------------------------------------------------
import React from 'react'

//----------------------------------------------// External Imports // -------------------------------------------------
import { Button, Typography } from "@material-ui/core";
import Popup from "reactjs-popup";
import CloseIcon from "@material-ui/icons/Close";
import { AgGridReact } from 'ag-grid-react';
import PropTypes from "prop-types";

//----------------------------------------------// Internal Imports // -------------------------------------------------
import "../../Commons/index.scss";
import "./index.scss";
import {
  CustomLoadingOverlayComponent,
  PAGE_LIMIT,
} from "../../Commons/Utils";
import { EmailRenderer } from "../../Contacts/utils";
import { syncCandidatesToHlColumndefs } from './utils';
let columnApi,gridApi;
const SyncAllCandidateToHlPopup = (props) => {
  const { open, onClose, candidateData, showReason, onSubmit, headerMessage, validCandidateLength } = props;
  const onGridReady = (params) => {
    columnApi = params.columnApi;
    gridApi = params.api;
    params.api.sizeColumnsToFit();
  };
  const getName = (params) => {
    if (params.data) {
      if (params.data.contact) {
        const firstName = params.data.contact.first_name || "";
        const lastName = params.data.contact.last_name || "";
        return `${firstName} ${lastName}`;
      }
      const firstName = params.data.first_name || "";
      const lastName = params.data.last_name || "";
      return `${firstName} ${lastName}`;
    }
    return "";
  };
  const NameRenderer = (params) => {
    return (
      <Typography color="primary">{getName(params)}</Typography>
    );
  };
  const companyNameRenderer = (params) => {
    return (
      <Typography color="primary">{params?.data?.contact?.company?.name || ''}</Typography>
    );
  };
  const ReasonRenderer = (params) => {
    return (
      <Typography color="error" className='text-link'>{params?.data?.hl_reason || ''}</Typography>
    );
  }
  const resetFilter = async () => {
    gridApi.setFilterModel(null);
    const customFilterField = columnApi.columnController.columnDefs;
    customFilterField.map(item => {
      if(item.filter === 'CustomFilter'){
        gridApi.destroyFilter(item.field);
      }
    })
  }

  const resetSort = async () => {
    gridApi.setSortModel(null);
  }

  return (
    <Popup
      className="sync-candidates-to-hl"
      open={open}
      onClose={onClose}
      closeOnDocumentClick={false}
      closeOnEscape={false}
    >
      <React.Fragment>
        <div className="popup-container">
          <div className="d-flex align-items-center justify-content-between top-bar">
            <div className="d-flex align-items-end">
              <Typography className="title heading-title">{headerMessage}</Typography>

            </div>
            <CloseIcon style={{ cursor: 'pointer' }} onClick={onClose} />
          </div>
          <div className="inside-container">
            <div className="count-container">
              <div className="action-container" style={{ minWidth: 0 }}
                onClick={() => resetFilter()}
              >
                <span className="action-text" >
                  Reset Filter
                </span>
              </div>
              <div className="action-container" style={{ minWidth: 0 }}
                onClick={() => resetSort()}
              >
                <span className="action-text" >
                  Reset Sort
                </span>
              </div>
              <Typography>Total count: {candidateData.length}</Typography>
            </div>
            <div className="ag-theme-alpine">
              <AgGridReact
                onGridReady={onGridReady}
                enableBrowserTooltips={true}
                defaultColDef={{
                  resizable: true,
                  sortable: false,
                  sortingOrder: ['asc', 'desc', null]
                }}
                cacheBlockSize={PAGE_LIMIT}
                loadingOverlayComponent={"CustomLoadingOverlayComponent"}
                frameworkComponents={{
                  CustomLoadingOverlayComponent,
                  CompanyNameRenderer: companyNameRenderer,
                  EmailRenderer,
                  NameRenderer,
                  ReasonRenderer
                }}
                getRowNodeId={(data) => data.id}
                scrollbarWidth={12}
                suppressHorizontalScroll={false}
                rowData={candidateData}
                columnDefs={syncCandidatesToHlColumndefs(showReason)}
                rowSelection={"single"}
                suppressRowClickSelection={true}
              ></AgGridReact>
            </div>
          </div>
        </div>
        <div className='d-flex align-items-center justify-content-end bottom-actions'>
          {!showReason ? (
            <>
              <Button onClick={onSubmit} className='mr-3' variant='contained' color='primary'>{`Continue syncing (${validCandidateLength})`}</Button>
              <Button onClick={onClose} variant='contained' color='primary'>cancel</Button>
            </>
          ) :
            <Button onClick={onClose} variant='contained' color='primary'>Ok</Button>}
        </div>
      </React.Fragment>
    </Popup>
  )
}

SyncAllCandidateToHlPopup.propTypes = {
  contact: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  candidateData: PropTypes.array,
  showReason: PropTypes.bool,
  onSubmit: PropTypes.func,
  headerMessage: PropTypes.string,
  validCandidateLength: PropTypes.number
};

export default SyncAllCandidateToHlPopup;