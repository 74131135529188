//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useEffect } from "react";

//-----------------------------------------------------------// External Imports // ------------------------------

import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import {
  Button,
  FormControl,
  InputLabel,
  Typography,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";

//-----------------------------------------------------------// Internal Imports // ------------------------------

import FeetypeSelection from "../../components/FeetypeSelection";
import CompanySelection from "../../components/CompanySelection";
import RegionSelection from "../../components/AddUser/components/RegionSelection";
import UserSelection from "../../components/UserSelection";
import InputField from "../../components/common/InputField";

const defaultValues = {
  projected_bill_date_to: null,
  projected_bill_date_from:null,
  term_type: null,
  intervals:null,
  intervals_desc: null,
  intervals_other:null,
  term_desc:null,
  company: [],
  region: null,
  partners: []
};

export default function InvoiceQuery(props) {
  const { option } = props;
  const { handleQuery,  /* option */ } = props;
  const { handleSubmit, setValue, register,unregister, watch } = useForm({
    defaultValues,
  });

  const onSubmit = (data) => {
    localStorage.setItem("invoiceQueryForm", JSON.stringify(data));
    handleQuery(data);
  };

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem("invoiceQueryForm");
    };

    const handleUnload = () => {
      localStorage.removeItem("invoiceQueryForm");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("unload", handleUnload);
    const savedFormValues = JSON.parse(localStorage.getItem("invoiceQueryForm"));
    if (savedFormValues) {
      const fieldsToRegister = ["projected_bill_date_to","projected_bill_date_from","term_type","term_desc","intervals","intervals_desc","intervals_other","company","region","partners","term_desc"];
      fieldsToRegister.forEach((fieldName) => {
        register({ name: fieldName });
        setValue(fieldName, savedFormValues[fieldName]);
      });
 
      onSubmit(savedFormValues)
    }

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("unload", handleUnload);
    };
  }, [setValue]);

  useEffect(() => {
    register({ name: "projected_bill_date_to" });
    register({ name: "projected_bill_date_from" });
    register({ name: "term_type" });
    register({ name: "intervals" });
    register({ name: "intervals_desc" });
    register({ name: "intervals_other" });
    register({ name: "company" });
    register({ name: "region" });
    register({ name: "partners" });
    register({ name: "term_desc" });
    return () => {
      unregister({ name: "projected_bill_date_to" });
      unregister({ name: "projected_bill_date_from" });
      unregister({ name: "term_type" });
      unregister({ name: "intervals" });
      unregister({ name: "intervals_desc" });
      unregister({ name: "intervals_other" });
      unregister({ name: "company" });
      unregister({ name: "region" });
      unregister({ name: "partners" });
      unregister({ name: "term_desc" });
    };
  }, [register]);

  return (
    <div className="query">
      <div className="d-flex align-items-center row-height">
        <FormControl className="query-input query-input-invoice">
          <InputLabel focused htmlFor="contact" className="query-label">
            Projected Bill Date Range
          </InputLabel>
          <div className="d-flex align-items-center ">
            <KeyboardDatePicker
              autoOk
              label=''
              placeholder="From Date"
              variant="inline"
              format="MM/dd/yyyy"
              value={(watch("projected_bill_date_from")) || null}
              maxDate={watch("projected_bill_date_to")}
              onChange={(e, data) => {
                setValue("projected_bill_date_from", data ? data : null);
              }}
            />
            <Typography className="to-label pl-2 pr-2">to</Typography>
            <KeyboardDatePicker
              autoOk
              label=''
              placeholder="End Date"
              format="MM/dd/yyyy"
              variant="inline"
              minDate={watch("projected_bill_date_from")}
              value={(watch("projected_bill_date_to")) || null}
              onChange={(e, data) => {
                setValue("projected_bill_date_to", data ? data : null);
              }}
            />
          </div>

        </FormControl>
        <FormControl className="query-input query-input-invoice">
          <InputLabel focused htmlFor="contact" className="query-label">
            TERM TYPE
          </InputLabel>
          <FeetypeSelection
            value={watch('term_type') || null}
            // disabled={disable}
            InputLabelProps={{ focused: true, shrink: true }}
            label=""
            type='TERM_TYPE'
            name="term_type"
            placeholder="Select Term Type"
            onChange={(e, data) => {
              setValue("intervals_desc", null) ; 
              setValue("intervals", null)
              setValue("term_type", data ? data : null);
              setValue("term_desc", data ? data?.short_desc : null)
            }}
          />
        </FormControl>
        <FormControl className="query-input">
          <InputLabel focused htmlFor="contact" className="query-label">
            Intervals
          </InputLabel>
          <FeetypeSelection
            disabled={!watch('term_type')}
            value={watch('intervals_desc') || null}
            //ref={register(`intervals_desc`)}
            InputLabelProps={{ focused: true }}
            type={watch('term_desc') === 'Time based' ? 'INTERVAL_TIME' : 'INTERVAL_MILE'}
            placeholder="Select Intervals"
            label=''
            onChange={(e, data) => {
              setValue('intervals', data ? data.field_value : null)
              setValue('intervals_desc', data ? data.short_desc : null)
              if (data !== null && data.field_value === 'Other Interval') {
                setValue('intervals_other', '')
              }
            }
            }
          />
        </FormControl>
        {watch("intervals_desc") === "Other Interval" ?
          <FormControl className="mb-4" style={{ width: "20%" }}>
            <InputLabel focused htmlFor="contact" className="query-label">
              Interval Other
            </InputLabel>
            <InputField
              value={watch('intervals_other') || null}
              label=''
              placeholder='Interval Other'
              name='intervals_other'
              onChange={(e) => setValue('intervals_other', e?.target?.value ? e?.target?.value : "")}
              InputLabelProps={{ focused: true }}
            />
          </FormControl> : null}
      </div>
      <div className="d-flex align-items-center" style={{ height: "auto" }}>
        <FormControl className="query-input">
          <InputLabel focused htmlFor="contact" className="query-label">
            Company
          </InputLabel>
          <CompanySelection
            multiple
            value={watch("company") || []}
            InputLabelProps={{ focused: true, shrink: true }}
            defaultValue={watch('company') || null}
            filterSelectedOptions={true}
            label=''
            onChange={(e, data) => {
              setValue("company", Array.isArray(data) ? data : []);
            }}
            placeholder="Select Company Name" />
        </FormControl>

        <FormControl className="query-input">
          <InputLabel focused htmlFor="contact" className="query-label">
            Region
          </InputLabel>
          <RegionSelection
            value={watch('region') || null}
            InputLabelProps={{ focused: true }}
            filterSelectedOptions={true}
            label=''
            onChange={(e, data) => {
              setValue('region', data ? data : null);
            }}
            placeholder="Select Region Name" />
        </FormControl>

        <FormControl className="query-input">
          <InputLabel focused htmlFor="contact" className="query-label">
            partners
          </InputLabel>
          <UserSelection
            value={watch('partners') || []}
            multiple
            option={option}
            filterSelectedOptions={true}
            label=''
            onChange={(e, data) => {
              setValue('partners', data ? data : []);
            }}
            placeholder={"Select Partners Name"}
          />
        </FormControl>


      </div>

      <div className="d-flex row-height">

        <div style={{ position: "absolute", right: "80px" }}>
          <Button
            className="query-button mt-0"
            variant="contained"
            color="primary"
            /* style={{ width: 100, marginLeft: 'auto' }} */
            onClick={handleSubmit(onSubmit)}
          >
            Search
          </Button>
        </div>
      </div>
    </div>
  );
}

InvoiceQuery.propTypes = {
  handleQuery: PropTypes.func,
  type: PropTypes.string,
  pullData: PropTypes.func,
  option: PropTypes.array,
};
