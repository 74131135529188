//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from "prop-types";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { fetchRevenueMarket } from "../../actions";

function RevenueMarketSelection(props) {
  const {
    register,
    className = 'w-100',
    label = "Geography",
    name = 'market',
    placeholder = "Geography",
    InputLabelProps,
    required,
    variant = 'standard',
    ...rest
  } = props

  const [options, setOptions] = useState([])
  const market = useSelector(state => state.commonReducer.market)
  const dispatch = useDispatch()
  useEffect(() => {
    if (!market) {
      dispatch(fetchRevenueMarket('REVENUE_MARKET', 'market'))
    } else {
      setOptions(market);
    }
  }, [market, dispatch])

  return (
    <Autocomplete
      {...rest}
      className={className}
      options={options}
      getOptionLabel={option => {
        if (typeof option === 'string') {
          return option
        }
        return option.market
      }}
      getOptionSelected={(option, value) => {
        if (!value) {
          return false;
        }
        if (typeof value === 'string') {
          return option.market === value;
        }
        return option.market === value.market
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          inputRef={register}
          name={name}
          InputLabelProps={InputLabelProps}
          label={label}
          required={required}
          placeholder={placeholder}
          variant={variant}
        />
      )}
    />
  )
}

RevenueMarketSelection.propTypes = {
  register: PropTypes.func,
  className: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  InputLabelProps: PropTypes.object,
  placeholder: PropTypes.string,
  variant: PropTypes.string,
  required:PropTypes.bool
}

export default RevenueMarketSelection;
