
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
  useRef,
} from "react";

//-----------------------------------------------------------// External Imports // ------------------------------

import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";

//-----------------------------------------------------------// Internal Imports // ------------------------------

import { fetchPickList } from "../../../actions";

// eslint-disable-next-line react/display-name
const ReasonEditor = forwardRef((props, ref) => {
  const { value, data: rowData } = props;
  const [reason, setReason] = useState(value);
  const [options, setOptions] = useState([]);
  const [stageName] = useState(rowData.stage);
  const inputRef = useRef();
  const candidateStages = useSelector((state) => state.commonReducer.candidateStages);
  const dispatch = useDispatch();

  const handleChange = (e, data) => {
    setReason(data);
  };

  useImperativeHandle(ref, () => {
    return {
      getValue: () => reason,
      afterGuiAttached: () => {
        inputRef.current.focus();
      },
    };
  });

  useEffect(() => {
    if (!candidateStages) {
      dispatch(fetchPickList("candidatestages", "candidateStages"));
    } else {
      const stages = candidateStages.filter(
        (stageObj) => stageObj.name === stageName
      );
      if (stages.length === 1) {
        const reasons = stages[0].reason;
        setOptions(reasons);
      }
    }
  }, [stageName, candidateStages, dispatch]);

  return (
    <Autocomplete
      value={reason}
      onChange={handleChange}
      options={options || []}
      renderInput={(params) => (
        <TextField
          {...params}
          ref={inputRef}
          label=""
          InputLabelProps={{ required: true }}
          placeholder="Reason"
        />
      )}
    />
  );
});

export default ReasonEditor;

ReasonEditor.propTypes = {
  value: PropTypes.string,
  data: PropTypes.object,
  api: PropTypes.object,
  enqueueSnackbar: PropTypes.func,
  stopEditing: PropTypes.func,
};
