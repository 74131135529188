import React from 'react'

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { FormControlLabel, Switch, TextField, InputLabel } from '@material-ui/core';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import CurrencySelection from '../../../../CurrencySelection';
import RegionSelection from '../../../../AddUser/components/RegionSelection';
import InputField from '../../../../common/InputField';
import RichTextPopup from '../../../../RichTextPopup';
// import IntervalSelection from '../../../../CreateSearch/Components/BillingInfo/Components/IntervalSelection';
// import TermTypeSelection from '../../../../CreateSearch/Components/BillingInfo/Components/TermTypeSelection';
import FeetypeSelection from '../../../../FeetypeSelection';
import NumberFormat from 'react-number-format';
import { separator } from '../../../../../Containers/Commons/Utils';
import { DECIMAL_SEPARATOR, FEE_TYPES, THOUSAND_SEPARATOR } from '../../../../../services/constantService';



const BillingForm = (props) => {
  const { register, watch, setValue, currentValues, currencyDetails, isHide } = props;
  return (
    <div className='d-flex flex-column'>
      <div className='d-flex contact-details-row'>
        <div className='d-flex intersection-page-view'>
          <InputLabel className="page-label">Opportunity #</InputLabel>
          <div className='page-label-value'>

            <span>{watch('job_number') || '--'}</span>

          </div>
        </div>
        <div className='d-flex intersection-page-view'>
          <InputLabel className="page-label">Service Offering</InputLabel>
          <div className='page-label-value'>
            <span>{watch('brand_desc') ? watch('brand_desc') : watch('brand') || '--'}</span>
          </div>
        </div>
      </div>
      <div className="d-flex contact-details-row">
        <div className='d-flex intersection-page-view'>
          <InputLabel required className="page-label">Currency</InputLabel>
          <div className='page-label-value'>
            <CurrencySelection
              label=''
              customInput={TextField}
              required={true}
              value={watch('currency') || null}
              className="input-form-field"
              InputLabelProps={{ focused: true }}
              onChange={(e, data) => {
                setValue("currency", data ? data.code : null);
              }}
              disabled={isHide}
              variant="outlined"
            />
          </div>
        </div>
        <div className='d-flex intersection-page-view'>
          <InputLabel className="page-label">Geography</InputLabel>
          <div className='page-label-value'>
            <span>{watch('markets.market') || '--'} </span>
          </div>
        </div>
      </div>
      <div className="d-flex contact-details-row">
        <div className='d-flex intersection-page-view'>
          <InputLabel className="page-label">Fee Cap</InputLabel>
          <div className='page-label-value'>
            <FormControlLabel
              control={<Switch checked={watch('is_fee_cap') || false} onChange={(e) => { setValue('fee_cap_amount', null); setValue('is_fee_cap', e.target.checked); }} color='primary' inputRef={register} name={'is_fee_cap'} />}
              label=""
              className='input-form-field checkbox-width'
            />
          </div>
        </div>
        {watch('is_fee_cap') ?
          <div className='d-flex intersection-page-view'>
            <InputLabel className="page-label">Fee Cap Amount</InputLabel>
            <div className='page-label-value'>
              {/* <InputField value={watch('fee_cap_amount') || "--"} variant="outlined" ref={register} className="input-form-field" label="" type="number" placeholder="Fee Cap Amount" InputLabelProps={{ focused: true }} name="fee_cap_amount" /> */}
              <NumberFormat
                variant="outlined"
                customInput={TextField}
                fullWidth='true'
                name='fee_cap_amount'
                ref={register}
                defaultValue={currentValues.fee_cap_amount}
                thousandSeparator={separator(watch('currency'), THOUSAND_SEPARATOR)}
                decimalSeparator={separator(watch('currency'), DECIMAL_SEPARATOR)}
                decimalScale={2}
                isNumericString={true}
                prefix={currencyDetails ? (currencyDetails.prefix ? `${currencyDetails.currency_icon} ` : '') : ''}
                suffix={currencyDetails ? (currencyDetails.prefix === false ? ` ${currencyDetails.currency_icon}` : '') : ''}
                onValueChange={(values) => {
                  const { value } = values;
                  setValue('fee_cap_amount', value);
                }}
                className="input-form-field"
              />
            </div>
          </div>
          :
          <div className='search-details'>&nbsp;</div>
        }
      </div>
      <div className="d-flex contact-details-row">
        <div className='d-flex intersection-page-view'>
          <InputLabel className="page-label">Term Type</InputLabel>
          {/* <ContactSelection
            className='add-bd-input'
            label={``}
            ref={register(`contact_name`)}
            defaultValue={watch('contact_name') || null}
            InputLabelProps={{ focused: true }}
            onChange={(e, data) => {
              let contact = data || {};
              setValue('contact_name', data)
              setValue("mobile_phone", contact.mobile_phone || null)
              setValue("work_email", contact.work_email || null)
            }}
            variant="outlined"
          /> */}
          <div className='page-label-value'>
            <div className='input-form-field'>
              <FeetypeSelection
                variant="outlined"
                className="team-info-field sum-value"
                value={watch('term_desc') || null}
                ref={register(`term_desc`)}
                InputLabelProps={{ focused: true }}
                placeholder="Term Type"
                type='TERM_TYPE'
                label=''
                onChange={(e, data) => {
                  setValue('intervals_desc', null)
                  setValue('intervals', null)
                  setValue('intervals_other', null)
                  setValue('term_type', data ? data.field_value : null)
                  setValue('term_desc', data ? data.short_desc : null)
                }}
              />
            </div>
          </div>
        </div>
        <div className='d-flex intersection-page-view'>
          <InputLabel className="page-label">Intervals</InputLabel>
          <div className='page-label-value'>
            <div className='input-form-field'>
              <FeetypeSelection
                variant="outlined"
                disabled={!watch('term_desc')}
                className="team-info-field sum-value"
                value={watch('intervals_desc') || null}
                ref={register(`intervals_desc`)}
                InputLabelProps={{ focused: true }}
                type={watch('term_desc') === 'Milestone' ? 'INTERVAL_MILE' : 'INTERVAL_TIME'}
                placeholder="Intervals"
                label=''
                onChange={(e, data) => {
                  setValue('intervals', data ? data.field_value : null)
                  setValue('intervals_desc', data ? data.short_desc : null)
                  if (data !== null && data.field_value !== 'Other Interval') {
                    setValue('intervals_other', '')
                  }
                }
                }
              />
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex contact-details-row">
        <div className='d-flex intersection-page-view' style={{ flex: '0 0 50%' }}>
          <InputLabel required className="page-label">Billing Region</InputLabel>
          <div className='page-label-value'>
            <div className='input-form-field'>
              <RegionSelection
                className="team-info-field sum-value"
                value={watch('billing_region') || null}
                ref={register(`billing_region`)}
                label={''}
                InputLabelProps={{ focused: true }}
                onChange={(e, data) => {
                  setValue('billing_region', data ? data.name : '');
                }}
                variant="outlined"
              // required={true}
              />
            </div>
          </div>
        </div>
        {watch('intervals') === 'Other Interval' && <div className='d-flex intersection-page-view'>
          <InputLabel className="page-label">Interval Other</InputLabel>
          <div className='page-label-value'>
            <InputField
              variant="outlined"
              defaultValue={watch('intervals_other') || ''}
              // value={watch('intervals_other') || ''}
              className='input-form-field'
              label=''
              placeholder='Interval Other'
              name='intervals_other'
              onChange={(e) => setValue('intervals_other', e.target.value)}
              InputLabelProps={{ focused: true }}
            />
          </div>
        </div>}
      </div>
      <div className="d-flex contact-details-row">
        <div className='d-flex intersection-page-view' style={{ flex: '0 0 50%' }}>
          <InputLabel className="page-label">Fee Type</InputLabel>
          <div className='page-label-value'>
            <div className='input-form-field'>
              <FeetypeSelection
                variant="outlined"
                className="team-info-field sum-value"
                defaultValue={watch('fee_type_desc') || currentValues.fee_type_desc || watch('fee_type') || FEE_TYPES.ONE_THIRD}
                ref={register(`fee_type_desc`)}
                InputLabelProps={{ focused: true }}
                placeholder="Fee Type"
                type='FEE_TYPE'
                onChange={(e, data) => {
                  setValue("fee_type", data ? data.field_value : null);
                  setValue("fee_type_desc", data ? data.short_desc : null)
                  setValue('fee_percentage', (!data || data.short_desc === FEE_TYPES.ONE_THIRD) ? 33.33 : (!data || data.short_desc === FEE_TYPES.FIXED_FEE) ? 0 : null)
                }}
                label=""
              />
            </div>
          </div>
        </div>
        <div className='d-flex intersection-page-view' style={{ flex: '0 0 50%' }}>
          <InputLabel className="page-label">Fee Percentage</InputLabel>
          <div className='page-label-value'>
            <InputField
              variant="outlined"
              value={(watch('fee_percentage') || '')}
              className="input-form-field"
              label=""
              disabled={(watch('fee_type_desc') !== FEE_TYPES.OTHER)}
              flag="true"
              inputProps={{ min: 0, max: 100 }}
              error={watch('fee_percentage') > 100}
              type="number"
              InputLabelProps={{ focused: true }}
              onChange={(e) => setValue('fee_percentage', e.target.value)}
              name="fee_percentage" />
          </div>
        </div>
      </div>
      <div className="d-flex">
        <div className="search-details-billing d-flex">
          <div className='pt-4' >
            <InputLabel style={{ color: 'black' }} className="billing-note-size">Billing Notes</InputLabel></div>
          <div className='d-flex contact-view-value pt-2'>
            <div className='outlined-box rich-text-field transform ' style={{ width: '100%' }}>
              <RichTextPopup
                className=""
                label="Billing Notes"
                placeholder='Billing Notes'
                title="Billing Notes"
                InputLabelProps={{ focused: true }}
                name="billing_notes"
                variant="outlined"
                value={currentValues.billing_notes}
                onSave={(data) => setValue('billing_notes', data.billing_notes)}
              />
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

BillingForm.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  watch: PropTypes.func,
  currentValues: PropTypes.object,
  currencyDetails: PropTypes.object,
  isHide: PropTypes.bool
}

export default BillingForm;
