//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useState, useEffect, useCallback } from 'react'

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from "prop-types";
import { Button, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close'
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import AddIcon from '@material-ui/icons//Add';
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
//----------------------------------------------// Internal Imports // -------------------------------------------------
import { getAccessToken } from '../../../../../services/cognitoService';
import UserSelection from '../../../../UserSelection';
import InputField from '../../../../common/InputField';
import { API } from '../../../../../services/constantService';
// import { ColumnController } from 'ag-grid-community';

const getCredits = (list) => {
  let total_origination_credit = 0;
  let total_selling_credit = 0;
  let total_execution_credit = 0;
  for (let i = 0; i < list.length; i++) {
    total_origination_credit += parseFloat(list[i].origination_credit || 0)
    total_selling_credit += parseFloat(list[i].selling_credit || 0)
    total_execution_credit += parseFloat(list[i].execution_credit || 0)
  }
  return { total_origination_credit, total_selling_credit, total_execution_credit }
}

export default function TeamInfoTable(props) {
  const { header = "Team Information", label = "", inputConfig = {}, isEditing = true, option, readOnlyMessage = '', currentValues, bd = "" } = props;
  const { list = [], fields = {}, setter } = inputConfig;
  const [isError, setIsError] = useState(false);
  const [credits, setCredits] = useState({});
  const [isRowDeleted, setIsRowDeleted] = useState(false);
  const [token, setToken] = useState('');
  useEffect(() => {
    const accessToken = async () => {
      setToken(await getAccessToken());
    }
    accessToken();
  }, [])
  const handleUserChange = (index, name, data) => {
    let newList = [...list]
    newList[index] = { ...newList[index], [name]: data }
    const updatedList = [...newList];

    setter(updatedList);
  }
  const updateCredits = useCallback((list) => {
    setCredits(getCredits(list))
  }, [])

  const checkForError = useCallback((list) => {

    const { total_origination_credit, total_selling_credit, total_execution_credit } = getCredits(list)
    let isError = false;
    if (fields.origination_credit) {
      if (total_origination_credit > fields.origination_credit) {
        isError = true;
      }
    }
    if (fields.selling_credit) {
      if (total_selling_credit > fields.selling_credit) {
        isError = true;
      }
    }
    if (fields.execution_credit) {
      if (total_execution_credit > fields.execution_credit) {
        isError = true;
      }
    }
    return isError;
  }, [fields])

  useEffect(() => {
    updateCredits(list);
    let isError = checkForError(list);
    setIsError(isError);
  }, [updateCredits, checkForError, list])

  const getTotalCredit = (item) => {
    return parseFloat(item.origination_credit || 0) + parseFloat(item.selling_credit || 0) + parseFloat(item.execution_credit || 0);
  }

  const checkIsErrorRelatedToSameField = (fieldName, errorField) => {
    return errorField.includes(fieldName)
  }

  const handleChange = (index, name, data) => {
    const value = data && Math.abs(data)
    let newList = [...list]

    if (parseFloat(value) < 0) {
      setIsError(true)
    } else {
      newList[index] = { ...newList[index], [name]: parseFloat(value) }
    }

    let isError = checkForError(newList);
    const { total_origination_credit, total_selling_credit, total_execution_credit } = getCredits(newList)
    let isErrorRelatedToSameField = false;
    if (isError) {
      if (fields.origination_credit) {
        if (total_origination_credit > fields.origination_credit) {
          isErrorRelatedToSameField = isErrorRelatedToSameField || checkIsErrorRelatedToSameField(name, "origination_credit_error")
        }
      }
      if (fields.selling_credit) {
        if (total_selling_credit > fields.selling_credit) {
          isErrorRelatedToSameField = isErrorRelatedToSameField || checkIsErrorRelatedToSameField(name, "selling_credit_error")
        }
      }
      if (fields.execution_credit) {
        if (total_execution_credit > fields.execution_credit) {
          isErrorRelatedToSameField = isErrorRelatedToSameField || checkIsErrorRelatedToSameField(name, "execution_credit_error")
        }
      }
    } else {
      newList = newList.map((item) => ({ ...item, [`${name}_error`]: false }));
    }

    if (isError && isErrorRelatedToSameField) {
      newList[index][`${name}_error`] = true
    } else {
      newList[index][`${name}_error`] = false
    }
    newList.forEach((item) => {
      if (item[name] && isNaN(item[name])) {
        item[`${name}_error`] = false
      }
    })
    setIsError(isError);
    setter([...newList]);
  }

  const addElement = () => {
    const updatedList = [...list, {}];
    updateCredits(updatedList);
    setter(updatedList);
  }

  const removeElement = (index) => {
    let updatedList = [...list];
    updatedList.splice(index, 1)
    updateCredits(updatedList);
    if (bd === "AddBD") {
      currentValues != undefined && currentValues.basic_partners != undefined && currentValues.basic_partners.splice(index, 1);
    }
    setter(updatedList);
    setIsRowDeleted(!isRowDeleted)
  }

  const getGrandTotalCredit = () => {
    return (parseFloat(credits.total_origination_credit || 0) + parseFloat(credits.total_selling_credit || 0) + parseFloat(credits.total_execution_credit || 0))
  }

  return (
    <div>
      <div className={`d-flex flex-column  my-2`}>
        <ExpansionPanel defaultExpanded={true} className="w-100" style={{ background: '#fff', color: '#fff' }}>
          <ExpansionPanelSummary
            expandIcon={
              <ExpandMoreIcon style={{ color: "#fff" }} />
            }
            aria-controls="panel1a-content"
            style={{ background: '#2a364c' }}
          >
            <div className={`d-flex table-header ${readOnlyMessage ? 'layout-content read-only' : ''}`}>
              <span className={`${readOnlyMessage ? 'read-only' : ''}`}>{header}</span>
            </div>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={`d-flex flex-column ${readOnlyMessage ? 'layout-content read-only' : ''} p-0`}>
            <div className='section-content team-information-table teamInfo p-0 flex-grow-1 mb-2'>
              <div className=" d-flex flex-column">
                <div className="d-flex align-items-center table-main-header">
                  <div className="team-info-field name-field">Name</div>
                  <div className="team-info-field">Origination Credit</div>
                  <div className="team-info-field">Selling Credit</div>
                  <div className="team-info-field">Execution Credit</div>
                  <div className="team-info-field">Total Credit</div>
                  <div className="close-action"></div>
                </div>
              </div>
              <div className={`table-content ${isError ? 'validation-error' : ''}`}>
                {list.length > 0 ? list.map((item, index) => {
                  return <div className="d-flex team-info-row" key={index}>
                    <div className="team-info-field name-field d-flex align-items-center align-self-baseline ">
                      <UserSelection
                        option={option}
                        label=""
                        placeholder={label}
                        value={item.user || null}
                        disabled={!isEditing}
                        header="TeamInfo"
                        list={list}
                        InputProps={{ startAdornment: <Typography style={{ padding: '0 5px' }} color='primary'>{index + 1}.</Typography> }}
                        onChange={(e, data) => handleUserChange(index, 'user', data)}
                        className="flex-grow-1" />
                      <span className="profile-image-container team-profile-image d-flex">
                        <span className="image-container">{(item.user && item.user.profile_image_id) ? <img src={`${API.users}/${item.user.id}/profileimage?token=${token}`} alt="Profile" /> : <span className="default-profile-icon"><AccountCircleIcon fontSize="inherit" /></span>}</span>
                      </span>
                    </div>
                    {fields.origination_credit ?
                      <InputField
                        type="number"
                        flag="true"
                        value={item.origination_credit || ''}
                        disabled={!isEditing}
                        inputProps={{ min: 0 }}
                        onChange={(e) => handleChange(index, 'origination_credit', e.target.value)}
                        error={item.origination_credit_error ? true : false}
                        helperText={item.origination_credit_error && `${label}s Origination Credit total cannot be more than ${fields.origination_credit}%.`}
                        className="team-info-field inputfieldinfo text-wrap" />
                      :
                      <div className="team-info-field"></div>
                    }
                    {fields.selling_credit ?
                      <InputField
                        type="number"
                        flag="true"
                        value={item.selling_credit || ''}
                        disabled={!isEditing}
                        inputProps={{ min: 0 }}
                        onChange={(e) => handleChange(index, 'selling_credit', e.target.value)}
                        error={item.selling_credit_error ? true : false}
                        helperText={item.selling_credit_error && `${label}s Selling Credit total cannot be more than ${fields.selling_credit}%.`}
                        className="team-info-field inputfieldinfo text-wrap" />
                      :
                      <div className="team-info-field"></div>
                    }
                    {fields.execution_credit ?
                      <InputField
                        type="number"
                        flag="true"
                        value={item.execution_credit || ''}
                        disabled={!isEditing}
                        inputProps={{ min: 0 }}
                        onChange={(e) => handleChange(index, 'execution_credit', e.target.value)}
                        error={item.execution_credit_error ? true : false}
                        helperText={item.execution_credit_error ? `${label}s Execution Credit total cannot be more than ${fields.execution_credit}%.` : ''}
                        className={`team-info-field ${readOnlyMessage ? "disabled-input-field-info" : "inputfieldinfo"} text-wrap `} />
                      :
                      <div className="team-info-field"></div>
                    }

                    <div className={`team-info-field sum-value ${getGrandTotalCredit() === 100 && !isError && 'text-success'}`}  >
                      {getTotalCredit(item)}
                    </div>
                    <div className="close-action">
                      {((label === 'Partner' && list.length > 1) || (label !== 'Partner')) &&
                        <span className="close-icon" onClick={() => removeElement(index)}>
                          <CloseIcon color="error" fontSize="inherit" />
                        </span>}
                    </div>
                  </div>
                }) : <div className="d-flex">--</div>}
                {list.length > 0 ? <div className="d-flex">
                  <div className="team-info-field name-field"></div>
                  <div style={{ minWidth: '18px' }}></div>
                  {fields.origination_credit ?
                    <div className={`team-info-field sum-value ${!isError ? "content" : "text-red"}`}>
                      {credits['total_origination_credit'] || 0}
                    </div>
                    // <InputField
                    //   type="number"
                    //   value={credits['total_origination_credit'] || 0}
                    //   InputProps={{ disableUnderline: true }}
                    //   disabled
                    //   className="team-info-field sum-value " />
                    :
                    <div className="team-info-field"></div>
                  }
                  {fields.selling_credit ?
                    <div className={`team-info-field sum-value ${!isError ? "content" : "text-red"}`}>
                      {credits['total_selling_credit'] || 0}
                    </div>
                    // <InputField
                    //   type="number"
                    //   value={credits['total_selling_credit'] || 0}
                    //   InputProps={{ disableUnderline: true }}
                    //   disabled
                    //   className="team-info-field sum-value" />
                    :
                    <div className="team-info-field"></div>
                  }
                  {fields.execution_credit ?
                    <div className={`team-info-field sum-value ${!isError ? "content" : "text-red"}`}>
                      {credits['total_execution_credit'] || 0}
                    </div>
                    // <InputField
                    //   type="number"
                    //   value={credits['total_execution_credit'] || 0}
                    //   InputProps={{ disableUnderline: true }}
                    //   disabled
                    //   className="team-info-field sum-value" />
                    :
                    <div className="team-info-field"></div>
                  }
                  <div className="team-info-field sum-value content">
                    {getGrandTotalCredit()}
                  </div>
                  {/* <InputField
              type="number"
              value={parseFloat(credits.total_origination_credit || 0) + parseFloat(credits.total_selling_credit || 0) + parseFloat(credits.total_execution_credit || 0)}
              InputProps={{ disableUnderline: true }}
              disabled
              className="team-info-field sum-value" /> */}
                  <div className="close-action"></div>
                </div> : ''}
                {isEditing && <div className='text-start'><Button
                  variant={"text"}
                  startIcon={<AddIcon style={{ color: "red" }} />}
                  onClick={addElement}
                  className="button-text-capitalized"
                  style={{
                    position: "relative",
                    bottom: "-6px"
                  }}
                >
                  <span style={{
                    fontWeight: "600",
                    fontSize: "13px",
                    fontStyle: "italic",
                  }}>Add new {label}</span>
                </Button></div>}
              </div>
            </div>
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
      {readOnlyMessage ? <div className={readOnlyMessage ? 'layout-content read-only' : ''}><div className='mb-2' dangerouslySetInnerHTML={{ __html: readOnlyMessage }}></div> </div> : null}
    </div>
  )
}

TeamInfoTable.propTypes = {
  header: PropTypes.string,
  label: PropTypes.string,
  inputConfig: PropTypes.object.isRequired,
  isEditing: PropTypes.bool,
  option: PropTypes.array,
  currentValues: PropTypes.object,
  readOnlyMessage: PropTypes.string,
  bd: PropTypes.string,
}
