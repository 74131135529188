//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useState, useEffect, useRef } from 'react'

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from 'prop-types'
import EditIcon from '@material-ui/icons/Edit';
import DoneIcon from '@material-ui/icons/Done';
import { withSnackbar } from 'notistack';
import { Controller } from 'react-hook-form';
import Autocomplete from '@material-ui/lab/Autocomplete'
import ReactPhoneInput from 'react-phone-input-material-ui';
import { Button, InputLabel, TextField } from "@material-ui/core";
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Delete } from "@material-ui/icons";
import { useHistory } from 'react-router-dom'
import CloseIcon from "@material-ui/icons/Close";
import NumberFormat from 'react-number-format';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import { formatDate } from '../../../utils/date'
import { addHttps } from "../../../utils/string";
import './index.scss'
import QuickAddCompanySelection from "../../AddContact/Components/CompanySelection";
import CompanySelection from "../../CompanySelection";
import IndustrySelection from '../../IndustrySelection'
import CountrySelection from '../../CountrySelection'
import InputField from '../../common/InputField'
import CurrencySelection from '../../CurrencySelection';
import StateSelection from '../../StateSelection';
import { determineInitialCountryGuess } from "../../../utils/phone";
import DeletePopup from "../../../Containers/Commons/DeletePopup";
import { cleanupCompany, getOldValue, getDefaultValues } from "./utils";
import RichTextPopup from '../../RichTextPopup';
import { DECIMAL_SEPARATOR, ERROR, IMAGE_UNSUPPORTED_FILE_FORMAT, INLINE_EDIT_RECORD_VALIDATION_MESSAGE, THOUSAND_SEPARATOR, WARNING, DELETE_CONFIRMATION_POPUP_MESSAGE } from '../../../services/constantService';
import { requireMessage } from '../../../services/MessageService';
import { currencyValueSetter, separator } from '../../../Containers/Commons/Utils';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCurrencyDetails } from '../../../actions';
import { checkIsImage, customFormValidatorInline, filterTags, RedirectToCompanies, tooltipValue } from '../../../utils/common';
import StarIcon from '@material-ui/icons/Star';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CapitalStructure from '../../ViewSearch/Components/SFPA/Components/Targets/CapitalStructure';

function CompanyDetails(props) {
  const { company, enqueueSnackbar, closeSnackbar, isEditing, setCheckPic, uploadProfilePic, saveCompanyDetails, cancelProfileUrl, handleEdit, handleCancel, updateCompany, useForm, isLastSaveSuccess, profileUrl, setNewProfilePic, setProfileUrl } = props
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [editingField, setEditingField] = useState('');
  const [hoveredField, setHoveredField] = useState('')
  const history = useHistory();
  const industryRef = useRef()
  const capitalStructureRef = useRef()
  const { register, setValue, handleSubmit, errors, watch, reset, control, getValues } = useForm;
  const off_limits_options = [{ id: true, name: "Yes" }, { id: false, name: "No" }]
  const country = watch('country');
  const currencyDetails = useSelector(state => state.commonReducer[watch('currency_iso_code')] ? state.commonReducer[watch('currency_iso_code')] : {})
  const dispatch = useDispatch()
  const [didLoad, setDidLoad] = useState(false);

  useEffect(() => {
    register({ name: 'parent_id' });
    register({ name: "country" });
    register({ name: "off_limits" });
    register({ name: "vc_pe" });
    register({ name: 'state' })
    register('description')
    register('off_limits_reason')
    register('currency_iso_code')
    register('revenue')
    register('capital_structure')
    return () => {
      register('parent_id');
      register("country");
      register("off_limits");
      register("vc_pe");
    };
  }, [register])

  const onFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (checkIsImage(file.name)) {
        setNewProfilePic(file);
        var fr = new FileReader();
        fr.onload = function () {
          setProfileUrl(fr.result);
        }
        fr.readAsDataURL(file);
      } else {
        enqueueSnackbar(IMAGE_UNSUPPORTED_FILE_FORMAT, { variant: ERROR });
      }

    }
  }


  useEffect(() => {
    let currentValues = { ...company }
    if (isEditing) {
      const values = getValues({ nest: true })
      values.vc_pe = values.vc_pe.map(item => ({ company: item }))
      currentValues = { ...currentValues, ...values }
    }
    reset(getDefaultValues(currentValues));
  }, [company, reset, isEditing, getValues])

  useEffect(() => {
    const currencyHandler = async () => {
      try {
        await fetchCurrencyDetails(watch('currency_iso_code'), dispatch)
      } catch (e) {
        console.log("Error found in currencyHandler::", e);
      }
    }
    if (!didLoad) {
      if (watch('currency_iso_code') && Object.keys(currencyDetails).length === 0) {
        currencyHandler()
      }
      setDidLoad(true);
    }

  }, [didLoad, watch('currency_iso_code')])

  const renderStateInput = () => {
    return <StateSelection
      label=''
      value={watch('state') || null}
      country={country?.name}
      register={register}
      defaultValue={company.state}
      InputLabelProps={{ focused: false }}
      className='company-details-value'
      onChange={(e, state) => {
        stateChange(state);
      }}
    />;
  }

  const handleChangeEditMode = () => {
    if (editingField !== '') {
      enqueueSnackbar(INLINE_EDIT_RECORD_VALIDATION_MESSAGE, {
        variant: WARNING,
        // eslint-disable-next-line react/display-name
        action: key => (
          <>
            <Button
              size='small'
              variant='text'
              color='inherit'
              onClick={async () => {
                handleCancelField()
                closeSnackbar(key)
                handleEdit()
              }}
            >
              Force Edit
            </Button>
            <Button
              size='small'
              variant='text'
              color='inherit'
              onClick={async () => {
                closeSnackbar(key)
              }}
            >
              CLOSE
            </Button>
          </>
        )
      });

    } else {
      handleEdit()
    }

  }

  const handleCancelField = () => {
    setEditingField('');
    cancelProfileUrl();
    reset(getDefaultValues(company))
  }

  const handleChange = (name, value) => {
    const date = formatDate(value)
    setValue(name, date)
  }
  const handleDeleteRecord = () => {
    setPopupOpen(true);
  }

  const handleClose = (deleted) => {
    setPopupOpen(false);
    if (deleted) {
      history.replace('/companies')
    }
  }

  const updateField = async (field, required) => {
    try {
      if (field === 'profileUrl') {
        await uploadProfilePic()
        setCheckPic(true);
      }
      const formData = { [field]: getValues({ nest: true })[field] };
      let formDataForCountry = getValues({ nest: true });
      if (formData[field] !== company[field]) {
        const data = cleanupCompany(formData)
        data.old = getOldValue(company, field)
        if (field === 'state') {
          data.country = formDataForCountry.country.name;
        }
        const isEmpty = !(Array.isArray(data[field]) ? data[field].length : data[field])
        if (required && isEmpty) {
          let requiredField = [
            { fieldName: "name", label: "Name", type: String },
            { fieldName: "website", label: "Website", type: String },
            { fieldName: "country", label: "Country", type: String },
            { fieldName: "industries", label: "Industry", type: [] },
          ]
          const dirtyField = customFormValidatorInline(field, requiredField);
          if (dirtyField) {
            const message = requireMessage(dirtyField, "is")
            enqueueSnackbar(message, { variant: ERROR });
            return
          }
          // const validField = field.charAt(0).toUpperCase() + field.substr(1);
          // const message = requireMessage(validField.replace("id", "Name").replace(/_/g, " "), "is")
          // enqueueSnackbar(message, { variant: ERROR });
          // handleSubmit(function () { })(); // To auto focus the field
          // return
        }
        let inlineEdit = true
        await saveCompanyDetails(data, inlineEdit)
      }
      setEditingField('')
    } catch (e) {
      console.log("Error found in updateField::", e);
    }
  }

  const handleTextSave = async (data, skipMessage, setAutoSaveLoading) => {
    try {
      if (setAutoSaveLoading) {
        setAutoSaveLoading(true)
      }
      const formData = cleanupCompany(data, true)
      await saveCompanyDetails(formData, true, true)
      if (setAutoSaveLoading) {
        setTimeout(() => {
          setAutoSaveLoading(false)
        }, 1000);
      }
    } catch (e) {
      console.log("Error found in handleTextSave::", e);
    }
  }

  const renderEditIcon = (field) => {
    return !(editingField === field || isEditing || hoveredField !== field) &&
      <EditIcon className='ml-2' onClick={() => { setEditingField(field); setHoveredField('') }} fontSize='inherit' color='secondary' />
  }

  const renderActions = (field, required = false) => {
    if (editingField === field && !isEditing) {
      return (
        <>
          <span className="action-icon" onClick={() => updateField(field, required)}>
            <DoneIcon cursor='pointer' fontSize="inherit" color='primary' />
          </span>
          <span className="action-icon" onClick={handleCancelField}>
            <CloseIcon cursor='pointer' fontSize="inherit" />
          </span>
        </>
      )
    }
    return null
  }

  const handleMouseEnter = (field) => {
    setHoveredField(field)
  }
  const handleMouseLeave = () => {
    setHoveredField('')
  }

  const renderLabel = (label, field, required = false) => {
    return <InputLabel required={required} className="company-details-label company-details" onMouseEnter={() => handleMouseEnter(field)} onMouseLeave={handleMouseLeave}>{label}{renderEditIcon(field)}</InputLabel>
  }

  const stateChange = (state) => {
    setValue('state', state);
  }
  const update = (value) => {
    setPopupOpen({ isPopupOpen: value })
  }

  return (
    <form className="d-flex flex-column h-100" onSubmit={handleSubmit((data) => { updateCompany(data, false) })}>
      {isPopupOpen ? <DeletePopup onClose={handleClose} id={company?.id} updateParent={update} popupText={DELETE_CONFIRMATION_POPUP_MESSAGE.DELETE_COMPANY} /> : null}
      <div className={`company-view-content company-details-section  ${isEditing ? 'editing' : ''}`}>
        <div className={`d-flex flex-column justify-content-start company-details`}>
          {renderLabel('COMPANY LOGO', 'profileUrl')}
          {
            editingField === 'profileUrl' || isEditing ?
              <div className='d-flex' style={{ marginLeft: '-10px', marginTop: '10px' }}>

                <label
                  className={`profile-image-container cursor-pointer contact-profile-image d-flex align-items-center justify-content-center ${isEditing ? 'edit' : ''}`}
                  htmlFor="profile-pic-input">
                  <span className="image-container">{profileUrl ? <img src={profileUrl} alt="Profile" /> :
                    <span className="default-profile-icon"><AccountCircleIcon fontSize="inherit" /></span>}</span>
                  <input
                    id="profile-pic-input" type="file" accept="image/*" hidden
                    onClick={(e) => {
                      e.target.value = null;
                      setNewProfilePic(null)
                    }}
                    onChange={onFileChange}
                  />

                  {watch('is_rock_star') && <div className="contact-rock-star">
                    <StarIcon fontSize="inherit" />
                  </div>}
                  <div className="profile-pic-edit">
                    <EditIcon fontSize="inherit" />
                  </div>
                </label>

                {renderActions('profileUrl', true)}
              </div>
              :
              <label
                className={`profile-image-container cursor-pointer contact-profile-image d-flex align-items-center justify-content-center ${isEditing ? 'edit' : ''}`}
                htmlFor="profile-pic-input">
                <span className="image-container">{profileUrl ? <img src={profileUrl} alt="Profile" /> :
                  <span className="default-profile-icon"><AccountCircleIcon fontSize="inherit" /></span>}</span>
                {watch('is_rock_star') && <div className="contact-rock-star">
                  <StarIcon fontSize="inherit" />
                </div>}
              </label>
          }

        </div>
        <div className='d-flex company-details-section-wrapper'>
          <div className="d-flex flex-column left-section">
            <div className={`company-details d-flex`}>
              {editingField === 'name' || isEditing ?
                <>
                  <InputLabel required={true} className="company-details-label">NAME</InputLabel>
                  <InputField className="company-details-value update-company-input" error={errors.name} name="name" ref={register()} />
                  {renderActions('name', true)}
                </>
                :
                <>
                  {renderLabel('NAME', 'name')}
                  <span className="company-details-value">{watch('name') || '--'}</span>
                </>
              }
            </div>
            <div className={`company-details d-flex`}>
              {renderLabel('PARENT COMPANY', 'parent_id')}
              {
                editingField === 'parent_id' || isEditing ?
                  <>
                    <QuickAddCompanySelection
                      className="company-details-value update-company-input"
                      label=""
                      placeholder=""
                      defaultValue={company.parent}
                      onChange={(e, data) => {
                        setValue("parent_id", data ? data.id : null);
                      }}
                    />
                    {renderActions('parent_id')}
                  </>
                  :
                  <>
                    <span className="company-details-value">
                      {company && company.parent && company.parent.name ?
                        <RedirectToCompanies id={company.parent.id} name={company.parent.name} showInLink={true} />
                        : '--'}
                    </span>
                  </>
              }
            </div>
            <div className={`company-details d-flex multivalue-input `}>
              {renderLabel('VC/PE', 'vc_pe')}
              {
                isEditing || editingField === 'vc_pe' ?
                  <React.Fragment>
                    <CompanySelection
                      multiple
                      label=""
                      placeholder=""
                      value={watch('vc_pe')}
                      filterSelectedOptions={true}
                      className="company-details-value update-company-input"
                      onChange={(e, data) => {
                        setValue("vc_pe", data);
                      }}
                    />
                    {renderActions('vc_pe')}
                  </React.Fragment>
                  :
                  <span className="company-details-value">
                    {company.vc_pe.length > 0 ? company.vc_pe.map((vcpe, index) => (<p key={index}>{vcpe.company?.name}</p>)) : '--'}
                  </span>
              }
            </div>
            <div className={`company-details d-flex`}>
              {renderLabel('PHONE', 'phone')}
              {
                isEditing || editingField === 'phone' ?
                  <React.Fragment>
                    <div className='company-details-value update-company-input'>
                      <ReactPhoneInput
                        ref={register('phone')}
                        value={watch('phone') || ''}
                        onChange={(data, c, e, value) => {
                          if (data.length > 1) {
                            setValue('phone', value)
                          }
                          else {
                            setValue('phone', '')
                          }
                        }}
                        enableSearch={true}
                        disableInitialCountryGuess={determineInitialCountryGuess(company.phone)}
                        inputProps={{
                          label: ""
                        }}
                        component={TextField}
                      />
                    </div>
                    {renderActions('phone')}
                  </React.Fragment>
                  :
                  <span className="company-details-value">
                    {company.phone ? <a href={`tel:${company.phone}`}> {company.phone}</a> : '--'}
                  </span>
              }
            </div>
            <div className={`company-details d-flex`}>
              {renderLabel('SWITCHBOARD', 'switchboard')}
              {
                isEditing || editingField === 'switchboard' ?
                  <React.Fragment>
                    <div className='company-details-value update-company-input'>
                      <ReactPhoneInput
                        ref={register('switchboard')}
                        value={watch('switchboard') || ''}
                        onChange={(data, c, e, value) => {
                          if (data.length > 1) {
                            setValue('switchboard', value)
                          }
                          else {
                            setValue('switchboard', '')
                          }
                        }}
                        enableSearch={true}
                        disableInitialCountryGuess={determineInitialCountryGuess(company.switchboard)}
                        inputProps={{
                          label: ""
                        }}
                        component={TextField}
                      />
                    </div>
                    {renderActions('switchboard')}
                  </React.Fragment>
                  :
                  <span className="company-details-value">
                    {company.switchboard ? <a href={`tel:${company.switchboard}`}> {company.switchboard}</a> : '--'}
                  </span>
              }
            </div>
            <div className={`company-details d-flex`}>
              {renderLabel('WEBSITE', 'website', isEditing || editingField === 'website')}
              {
                isEditing || editingField === 'website' ?
                  <>
                    <InputField
                      className="company-details-value update-company-input"
                      error={errors.website}
                      name="website"
                      ref={register()}
                    />
                    {renderActions('website', true)}
                  </>
                  :
                  <span className="company-details-value">
                    {company.website ?
                      <a href={addHttps(company.website)} rel='noopener noreferrer' target="_blank">
                        {company.website}
                      </a>
                      : '--'
                    }
                  </span>
              }
            </div>
            <div className={`company-details d-flex`}>
              {renderLabel('LINKEDIN URL', 'linkedin_url')}
              {
                isEditing || editingField === 'linkedin_url' ?
                  <>
                    <InputField className="company-details-value update-company-input" name="linkedin_url" ref={register} />
                    {renderActions('linkedin_url')}
                  </>
                  :
                  <span className="company-details-value">
                    {company.linkedin_url ?
                      <a href={addHttps(company.linkedin_url)} rel='noopener noreferrer' target="_blank">
                        {tooltipValue(company.linkedin_url)}
                      </a>
                      : '--'
                    }
                  </span>
              }
            </div>
            <div className={`company-details d-flex`}>
              <div style={{ width: "40%" }}>
                {renderLabel('COMPANY NEWS', 'news')}
              </div>
              {
                isEditing || editingField === 'news' ?
                  <>
                    <div style={{ width: "63%", }}>
                      <div className='text-view-value p-0 w-100'>
                        <div className='outlined-border transform p-0 '>
                          <RichTextPopup
                            className="company-details-value update-company-input"
                            label=''
                            value={company.news}
                            ref={register}
                            name='news'
                            onChange={(data) => {
                              setValue('news', data);
                            }}
                            title='Company News'
                            onSave={handleTextSave}
                            autoSave={handleTextSave}
                            saveStatus={isLastSaveSuccess}
                            updatedDate={company.updated_at}
                          />
                        </div>
                      </div>
                    </div>
                    {renderActions('news')}
                  </>
                  :
                  <div style={{ width: "64%" }}>
                    <RichTextPopup
                      className="company-details-value"
                      value={filterTags(company.news)}
                      setOpen={false}
                    />
                  </div>
              }
            </div>
            <div className={`company-details d-flex`}>
              {renderLabel('STREET ADDRESS', 'street')}
              {
                isEditing || editingField === 'street' ?
                  <>
                    <InputField className="company-details-value update-company-input" name="street" ref={register} />
                    {renderActions('street')}
                  </>
                  :
                  <span className="company-details-value">{company.street || '--'}</span>
              }
            </div>
            <div className={`company-details d-flex`}>
              {renderLabel('CITY', 'city')}
              {
                isEditing || editingField === 'city' ?
                  <>
                    <InputField className="company-details-value update-company-input" name="city" ref={register} />
                    {renderActions('city')}
                  </>
                  :
                  <span className="company-details-value">{company.city || '--'}</span>
              }
            </div>
            <div className={`company-details d-flex`}>
              {renderLabel('STATE', 'state')}
              {
                isEditing || editingField === 'state' ?
                  <>
                    {renderStateInput()}
                    {renderActions('state')}
                  </>
                  :
                  <span className="company-details-value">{company.state || '--'}</span>
              }
            </div>
            <div className={`company-details d-flex`}>
              {renderLabel('Zip Code/Postal Code', 'zip_code')}
              {
                isEditing || editingField === 'zip_code' ?
                  <>
                    <InputField className="company-details-value update-company-input" name="zip_code" ref={register} />
                    {renderActions('zip_code')}
                  </>
                  :
                  <span className="company-details-value">{company.zip_code || '--'}</span>
              }
            </div>
            <div className={`company-details d-flex`}>
              {renderLabel('COUNTRY', 'country', isEditing || editingField === 'country')}
              {
                isEditing || editingField === 'country' ?
                  <>
                    <CountrySelection
                      label=""
                      placeholder=""
                      defaultValue={company.country}
                      className="company-details-value update-company-input"
                      // required={true}
                      onChange={(e, data) => {
                        stateChange('');
                        setValue("country", data);
                      }}
                    />
                    {renderActions('country', true)}
                  </>
                  :
                  <span className="company-details-value">{company.country ? company.country : '--'}</span>
              }
            </div>

            <div className={`company-details d-flex`}>
              {renderLabel('OFF LIMITS Y/N', 'off_limits')}
              {
                isEditing || editingField === 'off_limits' ?
                  <>
                    <Autocomplete
                      className="company-details-value update-company-input"
                      options={off_limits_options}
                      getOptionSelected={(option, value) => {
                        if (typeof value === 'boolean') {
                          return option.id === value;
                        }
                        return option.id === value.id
                      }}
                      getOptionLabel={(option) => option.name}
                      defaultValue={company.off_limits != null ? company.off_limits ? off_limits_options[0] : off_limits_options[1] : ''}
                      onChange={async (e, data) => {
                        setValue("off_limits", data ? data.id : null);
                      }}
                      renderInput={(params) => (
                        <InputField
                          {...params}
                          variant="standard"
                          label=""
                          placeholder=""
                        />
                      )}
                    />
                    {renderActions('off_limits')}
                  </>
                  :
                  <span
                    className="company-details-value">{company.off_limits != null ? company.off_limits ? 'Yes' : 'No' : '--'}</span>
              }
            </div>
            <div className={`company-details d-flex`}>
              <div style={{ width: "40%" }}>
                {renderLabel('OFF LIMITS REASON', 'off_limits_reason')}
              </div>
              {
                isEditing || editingField === 'off_limits_reason' ?
                  <>
                    <div style={{ width: "63%" }}>
                      <div className='text-view-value p-0 w-100'>
                        <div className='outlined-border transform p-0 '>
                          <RichTextPopup
                            className="company-details-value update-company-input"
                            label=''
                            value={company.off_limits_reason}
                            name='off_limits_reason'
                            onChange={(data) => {
                              setValue('off_limits_reason', data);
                            }}
                            onSave={handleTextSave}
                            autoSave={handleTextSave}
                            title='OFF LIMITS REASON'
                            saveStatus={isLastSaveSuccess}
                            updatedDate={company.updated_at}
                          />
                        </div>
                      </div>
                    </div>
                    {renderActions('off_limits_reason')}
                  </>
                  :
                  <div style={{ width: "64%" }}>
                    <RichTextPopup
                      className="company-details-value"
                      value={filterTags(company.off_limits_reason)}
                      setOpen={false}
                    />
                  </div>
              }
            </div>
            <div className={`company-details d-flex`}>
              {renderLabel('OFF LIMITS EXPIRATION DATE', 'off_limits_expiration')}
              {
                isEditing || editingField === 'off_limits_expiration' ?
                  <>
                    <KeyboardDatePicker
                      className="company-details-value"
                      //disableToolbar
                      ref={register({ name: `off_limits_expiration` })}
                      autoOk
                      // label="Off Limits Expiration Date"
                      variant="inline"
                      format="dd/MM/yyyy"
                      value={formatDate(watch('off_limits_expiration')) || null}
                      placeholder="Off Limits Expiration Date"
                      onChange={(date) => { handleChange('off_limits_expiration', date); }}
                    />
                    {renderActions('off_limits_expiration')}
                  </>
                  :
                  <span className="company-details-value">{formatDate(company.off_limits_expiration) || '--'}</span>
              }
            </div>
            <div className={`company-details d-flex`}>
              {renderLabel('EMAIL SYNTAX', 'email_syntax')}
              {
                isEditing || editingField === 'email_syntax' ?
                  <>
                    <InputField className="company-details-value update-company-input" name="email_syntax" ref={register} />
                    {renderActions('email_syntax')}
                  </>
                  :
                  <span className="company-details-value">{company.email_syntax || '--'}</span>
              }
            </div>
          </div>
          <div className="d-flex flex-column right-section">
            <div className={`company-details d-flex multivalue-input `}>
              {renderLabel('INDUSTRY', 'industries', isEditing || editingField === 'industries')}
              {
                isEditing || editingField === 'industries' ?
                  <>
                    <Controller
                      name='industries'
                      as={
                        <IndustrySelection
                          multiple
                          label=''
                          isSearchIndustry={true}
                          placeholder=""
                          inputRef={industryRef}
                          filterSelectedOptions={true}
                          className="company-details-value update-company-input"
                        />}
                      control={control}
                      defaultValue={Array.isArray(company.industries) ? company.industries : []}
                      onFocus={() => { industryRef.current.focus() }}
                      onChange={([, data]) => data}
                    // rules={{ required: true, validate: d => d.length > 0 }}
                    />
                    {renderActions('industries', true)}
                  </>
                  :
                  <span className="company-details-value">
                    {company.industries && company.industries.length > 0 ? company.industries.map((industry, index) => (<p key={index}>{industry}</p>)) : '--'}
                  </span>
              }
            </div>

            <div className={`company-details d-flex`}>
              {renderLabel('TICKER SYMBOL', 'ticker_sym')}
              {
                isEditing || editingField === 'ticker_sym' ?
                  <>
                    <InputField className="company-details-value update-company-input" name="ticker_sym" ref={register} />
                    {renderActions('ticker_sym')}
                  </>
                  :
                  <span className="company-details-value">{company.ticker_sym || '--'}</span>
              }
            </div>

            <div className={`company-details d-flex`}>
              {renderLabel('REVENUE CURRENCY', 'currency_iso_code')}
              {
                isEditing || editingField === 'currency_iso_code' ?
                  <>
                    <CurrencySelection
                      value={watch('currency_iso_code') || null}
                      label={''}
                      className="company-details-value"
                      onChange={(e, data) => {
                        setDidLoad(false)
                        setValue("currency_iso_code", data ? data.code : null);
                      }}
                    />
                    {renderActions('currency_iso_code')}
                  </>
                  :
                  <span className="company-details-value">{company.currency_iso_code || '--'}</span>
              }
            </div>
            <div className={`company-details d-flex multivalue-input `}>
              {renderLabel('CAPITAL STRUCTURE', 'capital_structure', false)}
              {
                isEditing || editingField === 'capital_structure' ?
                  <>
                    <CapitalStructure
                      name='capital_structure'
                      // multiple
                      label=''
                      placeholder=""
                      inputRef={capitalStructureRef}
                      filterSelectedOptions={true}
                      className="company-details-value update-company-input"
                      onChange={(e, data) => {
                        setValue("capital_structure", data?.name ? data.name : null);
                      }}
                      defaultValue={company.capital_structure ? company.capital_structure : null}
                    />
                    {renderActions('capital_structure', true)}
                  </>
                  :
                  <span className="company-details-value">
                    {company.capital_structure ? company.capital_structure : '--'}
                  </span>
              }
            </div>
            <div className={`company-details d-flex`}>
              {renderLabel('REVENUE', 'revenue')}
              {
                isEditing || editingField === 'revenue' ?
                  <>
                    <NumberFormat
                      className="company-details-value"
                      customInput={TextField}
                      // style={{
                      //   border: 'none',
                      //   borderBottom: '2px solid #a3a3a3',
                      //   width: '75px'
                      // }}
                      value={watch('revenue')}
                      thousandSeparator={separator(watch('currency_iso_code'), THOUSAND_SEPARATOR)}
                      decimalSeparator={separator(watch('currency_iso_code'), DECIMAL_SEPARATOR)}
                      decimalScale={0}
                      isNumericString={true}
                      prefix={currencyDetails ? (currencyDetails.prefix ? currencyDetails.currency_icon : '') : ''}
                      suffix={currencyDetails ? (currencyDetails.prefix === false ? currencyDetails.currency_icon : '') : ''}
                      onValueChange={(values) => {
                        const { value } = values;
                        setValue('revenue', value);
                      }}
                    />
                    {renderActions('revenue')}
                  </>
                  :
                  <div className="company-details-value">
                    <NumberFormat
                      style={{ border: 'none' }}
                      value={company.revenue || '--'}
                      renderText={(data) => {
                        const value = currencyValueSetter(data, watch('currency_iso_code'))
                        return value ? value : '--'
                      }}
                      displayType='text'
                      thousandSeparator={true}
                      decimalSeparator='.'
                      prefix={currencyDetails ? (currencyDetails.prefix ? currencyDetails.currency_icon : '') : ''}
                      suffix={currencyDetails ? (currencyDetails.prefix === false ? currencyDetails.currency_icon : '') : ''}
                    />
                  </div>
              }
            </div>
            <div className={`company-details d-flex`}>
              {renderLabel('EMPLOYEE SIZE', 'employee_size')}
              {
                isEditing || editingField === 'employee_size' ?
                  <>
                    <InputField className="company-details-value update-company-input" name="employee_size" type="number" ref={register} />
                    {renderActions('employee_size')}
                  </>
                  :
                  <span className="company-details-value">{company.employee_size || '--'}</span>
              }
            </div>

            <div className={`company-details d-flex `} >
              <div style={{ width: "40%" }}>
                {renderLabel('Description', 'description')}
              </div>
              {
                isEditing || editingField === 'description' ?
                  <>
                    <div style={{ width: "63%" }}>
                      <div className='text-view-value p-0 w-100'>
                        <div className='outlined-border transform p-0 '>
                          <RichTextPopup
                            className="company-details-value update-company-input"
                            label=''
                            value={company.description}
                            name='description'
                            onChange={(data) => {
                              setValue('description', data);
                            }}
                            title='Description'
                            onSave={handleTextSave}
                            autoSave={handleTextSave}
                            saveStatus={isLastSaveSuccess}
                            updatedDate={company.updated_at}
                          />
                        </div>
                      </div>
                    </div>
                    {renderActions('description')}
                  </>
                  :
                  <div style={{ width: "64%" }}>
                    <RichTextPopup
                      className="company-details-value"
                      value={filterTags(company.description)}
                      setOpen={false}
                    />

                  </div>
              }
            </div>
            <div className={`company-details d-flex`}>
              {renderLabel('STAR', 'is_rock_star')}
              {
                isEditing || editingField === 'is_rock_star' ?
                  <>
                    <Autocomplete
                      className="company-details-value update-company-input"
                      options={off_limits_options}
                      getOptionSelected={(option, value) => {
                        if (typeof value === 'boolean') {
                          return option.id === value;
                        }
                        return option.id === value.id
                      }}
                      getOptionLabel={(option) => option.name}
                      defaultValue={company.is_rock_star != null ? company.is_rock_star ? off_limits_options[0] : off_limits_options[1] : ''}
                      onChange={(e, data) => {
                        setValue("is_rock_star", data ? data.id : null);
                      }}
                      renderInput={(params) => (
                        <InputField
                          {...params}
                          variant="standard"
                          label=""
                          placeholder=""
                        />
                      )}
                    />
                    {renderActions('is_rock_star')}
                  </>
                  :
                  <span
                    className="company-details-value">{company.is_rock_star != null ? company.is_rock_star ? 'Yes' : 'No' : '--'}</span>
              }
            </div>

            {
              !isEditing
              &&
              <div className={`company-details d-flex`}>
                <span className="company-details-label">ADDED BY</span>
                <span className="company-details-value">{company.created_user ? company.created_user.name : '--'}</span>
              </div>
            }
            {
              !isEditing &&
              <div className={`company-details d-flex`}>
                <span className="company-details-label">DATE ADDED</span>
                <span className="company-details-value">{formatDate(company.created_at) || '--'}</span>
              </div>
            }

            {
              !isEditing &&
              <div className={`company-details d-flex`}>
                <span className="company-details-label">DATE LAST MODIFIED</span>
                <span className="company-details-value">{formatDate(company.updated_at) || '--'}</span>
              </div>
            }

            {
              !isEditing &&
              <div className={`company-details d-flex`}>
                <span className="company-details-label">MODIFIED BY</span>
                <span className="company-details-value">{company.updated_user ? company.updated_user.name : '--'}</span>
              </div>
            }

          </div>
        </div>
      </div>
      <div className="actions">
        {
          isEditing ? (
            <div className="d-flex">
              <label htmlFor="submit" className="m-0" >
                <div className="d-flex align-items-center action-edit">
                  <span className="section-icon" style={{ color: "#DE9F6D" }}>
                    <DoneIcon cursor='pointer' fontSize="inherit" />
                  </span>
                  <span>Save</span>
                </div>
              </label>
              <input id="submit" hidden className="primary-btn" type="submit" value="Update" />

              <div className="d-flex align-items-center action-edit" onClick={handleCancel}>
                <span>Cancel</span>
              </div>
            </div>
          ) : (
            <div className='d-flex justify-content-between'>
              <div className="d-flex align-items-center action-edit" onClick={handleChangeEditMode}>
                <span className="section-icon">
                  <EditIcon cursor='pointer' fontSize="inherit" />
                </span>
                <span>Edit Details</span>
              </div>
              <div className='d-flex align-items-center action-edit' style={{ paddingRight: 200 }} onClick={handleDeleteRecord}>
                <span className="section-icon">
                  <Delete cursor='pointer' fontSize="inherit" />
                </span>
                <span>Delete Record</span>
              </div>
            </div>
          )
        }
      </div>
    </form>
  )
}

CompanyDetails.propTypes = {
  company: PropTypes.object,
  enqueueSnackbar: PropTypes.func,
  profileUrl: PropTypes.string,
  setNewProfilePic: PropTypes.func,
  setCheckPic: PropTypes.func,
  setProfileUrl: PropTypes.func,
  closeSnackbar: PropTypes.func,
  updateCompany: PropTypes.func,
  reloadData: PropTypes.func,
  saveCompanyDetails: PropTypes.func,
  isEditing: PropTypes.bool,
  handleEdit: PropTypes.func,
  handleCancel: PropTypes.func,
  useForm: PropTypes.object,
  isLastSaveSuccess: PropTypes.bool,
  uploadProfilePic: PropTypes.func,
  cancelProfileUrl: PropTypes.func
}

export default withSnackbar(CompanyDetails)