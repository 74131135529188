
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { Component } from "react";

//-----------------------------------------------------------// External Imports // ------------------------------

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import { withSnackbar } from "notistack";
import { saveAs } from "file-saver";
import { AgGridReact } from "ag-grid-react";
import { Button, Checkbox, ExpansionPanel, ExpansionPanelDetails, Typography } from "@material-ui/core";

//-----------------------------------------------------------// Internal Imports // ------------------------------

import {
  addContactsToWorkbench,
  DateRenderer,
  DateTimeRenderer,
  LinkedInRenderer,
  loadColumnStateFromLocalStorage,
  saveColumnStateToLocalStorage,
} from "../Commons/Utils";
import {
  addAsMyBDTarget,
  columnDefs,
  columnMergeContactDefs,
  ContactNameRenderer,
  CustomLoadingOverlayComponent,
  defaultColumns,
  EmailRenderer,
  getSelectedContactIds,
  PAGE_LIMIT,
  PhoneRenderer,
  ResumeRenderer,
  SUCCESS_STATUS_CODE,
  getFilterParamString,
  acquire as acquireUtil,
  cleanupQuery,
  getQueryParamsString,
  checkContactFetchingStatus,
  checkRecordCanDelete
} from "./utils";
import ActionsPopover from "./ActionsPopover";
import DeletePopup from "../Commons/DeletePopup";
import { CompanyNameRenderer } from "../../Containers/Companies/utils";
import ActivityHistories from "../../components/ActivityLog/Components/GeneralInfo/Components/ActivityHistory/ActivityHistories";
import CopyCandidatesToSearch from "../Searches/AllCandidates/CopyCandidatesToSearch";
import ColumnFilter from "../Commons/ColumnFilter";
import { CompensationRenderer, SCROLL_TIMEOUT, sortByChronologicalOrder } from "../../utils/common";
import GenericCellEditor from "../Commons/CellEditors/GenericCellEditor";
import AddContactAsClient from "./components/AddContactAsClient";
import AddContactToWorkbench from "../Commons/AddContactToWorkbench";
import RemoveContactFromWorkbench from "../Commons/RemoveContactFromWorkbench";
import AddWorkbench from "../../components/AddWorkbench";
import CustomFilter from "../Commons/CustomFilter";
import AthenaPopover from "../Searches/AllCandidates/Components/AthenaPopover";
import { formatDate } from "../../utils/date";
import AthenaInvitePopUpContact from "../../../src/components/ViewSearch/Components/SFPA/Components/ClientAppAccess/AthenaInvitePopUpContact";
import {
  API,
  BLOB,
  EXPORT_CONTACT_LIMIT,
  POST,
  ERROR,
  WARNING,
  DELETE_CONFIRMATION_POPUP_MESSAGE,
  LINKEDIN_SCRAPER,
} from "../../services/constantService";
import LinkedInDialog from "../Commons/LinkedInDialog";
import Loader from "../../../src/components/common/Loader";
import { dataSourceUtils } from "../../utils/dataSource";
import { fetchUserList } from "../../actions";
import {
  acquireLockApi,
  contactDataApi,
  contactDataRefreshApi,
  releaseLockApi,
} from "../../services/ApiService";
import {
  exportMessage,
  VALIDATION_MESSAGE,
  notFoundMessage,
} from "../../services/MessageService";
import EmploymentHistoryConfirmationDialog from '../Commons/EmploymentHistoryConfirmationDialog';
import { cleanupCompanyDetails } from '../../components/AddContact/utils';
import Query from "./components/Query";
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseIcon from "@material-ui/icons/Close";
import PdlContactsMergingPopup from "../Commons/PdlContactsMergPopup";

class Contacts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      queryData: {},
      viewColumns: false,
      anchorEl: null,
      isRowSelected: false,
      isAllRowsSelected: false,
      contactIds: [],
      activeContact: null,
      rowCount: 0,
      contacts: [{}],
      isAthenaPopupOpen: false,
      linkedPopup: false,
      linkedInValue: {},
      isLoading: false,
      showJobTitleChangedPopup: false,
      employmentHistoryMessageEndYearDate: null,
      isUpdateEmploymentHistory: false,
      skipCheckJobTitle: false,
      currentContact: null,
      newContactData: null,
      showFilterCount: 0,
      isClassicQuery: false,
      showClassic: true,
      showMergePopup: false,
      pdlData: null,
      isDeleteContactOpen: false,
      savedQuery: false
    };
    this.child = React.createRef();
    this.enqueueSnackbar = props.enqueueSnackbar;
    this.closeSnackbar = props.closeSnackbar;
  }

  componentWillUnmount() {
    // const columnApi = this.columnApi
    // saveColumnStateToLocalStorage('contactColumns', { columnApi });
    this.props.dispatch({ type: 'resetLinkedinScraperEvents' })
  }

  componentDidMount() {
    if (!this.props.users) {
      this.props.fetchUserList("users");
    }
    this.props.dispatch({ type: 'resetLinkedinScraperEvents' })
  }

  componentDidUpdate(/* prevProps */) {
    if (this.state.skipCheckJobTitle) {
      this.handleUpdateJobHistory()
    }
    // if (this.props.socket /* && prevProps.socket !== this.props.socket */) {
    //   console.log('socket changes found:: ', this.props.socket);
    //   this.props.socket.on(LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_COMPLETED, (msg, obj) => {
    //     console.log('socket event found:: ', LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_COMPLETED, msg, obj);
    //     let contactFound
    //     this.gridApi.forEachNode(node => {
    //       console.log('node', node.data?.id)
    //       console.log('socket obj::', obj?.contact?.id)
    //       if (node.data?.id === obj?.contact?.id) {
    //         contactFound = node
    //       }
    //     })
    //     console.log('socket event candidate found:: ', contactFound)
    //     if (contactFound) {
    //       contactFound.setData(obj.contact)
    //       this.gridApi.refreshCells({ force: true });
    //     }
    //   })
    // }
    if (this.props[LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_COMPLETED]) {
      const event = this.props[LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_COMPLETED];
      let contactFound
      this.gridApi.forEachNode(node => {
        if (node.data?.id === event.obj?.contact?.id) {
          contactFound = node
        }
      })
      if (contactFound) {
        contactFound.setData(event.obj.contact)
        this.gridApi.refreshCells({ force: true });
      }
      this.props.dispatch({ type: LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_COMPLETED, payload: undefined })
      // this.props.socket.on(LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_COMPLETED, (msg, obj) => {
      // })
    }
    if (this.props.refreshContact) {
      this.gridApi.onFilterChanged();
    }
  }

  getURLs = {
    listURl: `${API.contact}/${this.props.type}`,
    classicURl: `${API.contact}`,
  };
  resetFilter = async () => {
    this.gridApi.setFilterModel(null);
    const customFilterField = this.columnApi.columnController.columnDefs;
    customFilterField.map(item => {
      if (item.filter === 'CustomFilter') {
        this.gridApi.destroyFilter(item.field);
      }
    })
  }

  resetSort = async () => {
    this.gridApi.setSortModel(null);
  }
  dataSource = {
    getRows: async (params) => {
      try {
        params.filterModel && this.setState({ filterModel: params.filterModel });
        this.setState({ showFilterCount: Object.keys(this.state.filterModel).length })
        //  this.gridApi.deselectAll();
        if (params.filterModel && Object.keys(params.filterModel).length > 0)
          this.gridApi.deselectAll();
        const thisValue = { ...this };
        this.gridApi.showLoadingOverlay();
        const obj = {
          params: params,
          context: thisValue,
          pageLimit: PAGE_LIMIT,
          url: this.getURLs,
          subScreen: true,
        };
        const { status, data } = await dataSourceUtils(obj, getFilterParamString, getQueryParamsString);
        if (status === SUCCESS_STATUS_CODE) {
          if (data.paging) {
            const message = notFoundMessage("records");
            if (data?.paging?.totalCount === 0) {
              this.props.enqueueSnackbar(message, { variant: WARNING });
            }
            params.successCallback(data.data, data.paging.totalCount);
            this.setState({
              rowCount: data.paging.totalCount,
              contacts: data.data,
            });
          } else if (Object.keys(data).length) {
            const message = notFoundMessage("records");
            if (data.length === 0) {
              this.props.enqueueSnackbar(message, { variant: WARNING });
            }
            params.successCallback([data], 1);
          }
          this.state.isAllRowsSelected &&
            this.setSelectAllRows(this.state.isAllRowsSelected);
        } else {
          params.failCallback();
        }
        this.gridApi.hideOverlay();
      }
      catch (e) {
        console.log("Error found in getRows::", e);
      }
    },
    rowCount: null,
  };

  handleAthenaPopupClose = () => {
    this.setState({ isAthenaPopupOpen: false });
    this.gridApi.onFilterChanged();
  };

  AthenaRenderer = (params) => {
    const status = checkContactFetchingStatus(params)
    if (status) return status;
    const handleInvite = () => {
      this.setState({
        isAthenaPopupOpen: true,
        athenaData: {
          candidate: { id: params.data?.candidates },
          contact: params.data,
        },
      });
    };
    if (params.data) {
      if (
        params.data.athena_completion_date ||
        params.data.athena_invitation_sent_on
      ) {
        return (
          <AthenaPopover
            contactDetails={params.data}
            handleInvite={handleInvite}
          >
            <Typography
              style={{ fontSize: "inherit", cursor: "pointer" }}
              color="primary"
            >
              {params.data.athena_completion_date
                ? `Completed on: ${formatDate(
                  params.data.athena_completion_date
                )}`
                : `Invited on: ${formatDate(
                  params.data.athena_invitation_sent_on
                )}`}
            </Typography>
          </AthenaPopover>
        );
      } else if (params.data.candidates?.length > 0) {
        return (
          <Button
            className="invite-button athena-button"
            variant="contained"
            color="primary"
            onClick={handleInvite}
          >
            Invite to Athena
          </Button>
        );
      } else {
        return (
          <Typography
            style={{ fontSize: "inherit", cursor: "pointer" }}
            color="primary"
          >
            Contact Is Not Associated With Any Search
          </Typography>
        );
      }
    }
    return null;
  };

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    const columnApi = params.columnApi
    const gridApi = params.api
    loadColumnStateFromLocalStorage(this.props.storageKey, { columnApi, gridApi });
    this.setState({ isGridReady: true });
  };
  exportCallback = async () => {
    const selectedRows = this.gridApi.getSelectedRows();
    if (
      this.state.isAllRowsSelected &&
      this.state.rowCount > EXPORT_CONTACT_LIMIT
    ) {
      const message = exportMessage(`${EXPORT_CONTACT_LIMIT}`, "Contacts");
      this.props.enqueueSnackbar(message, { variant: ERROR });
    } else if (
      !this.state.isAllRowsSelected &&
      selectedRows.length > EXPORT_CONTACT_LIMIT
    ) {
      const message = exportMessage(`${EXPORT_CONTACT_LIMIT}`, "Contacts");
      this.props.enqueueSnackbar(message, { variant: ERROR });
    } else {
      let sub_route = `export-as-excel?`;
      if (
        this.state.filterModel &&
        Object.keys(this.state.filterModel).length
      ) {
        sub_route = sub_route.concat(
          getFilterParamString(this.state.filterModel, this.state.showQuery)
        );
        sub_route = `${sub_route}&filter=true`;
      }
      let id = [];
      if (!this.state.isAllRowsSelected) {
        selectedRows.map((data) => id.push(data.id));
      }
      const columnHeaders = this.columnApi
        .getAllDisplayedColumns()
        .map((column) => column.getColDef().headerName);
      this.gridApi.showLoadingOverlay();
      const payload = {
        headers: {
          columnHeaders: columnHeaders,
          selectedRows: { id: id },
          type: this.props.title,
          allRowSelected: this.state.isAllRowsSelected,
          limit: EXPORT_CONTACT_LIMIT,
        },
      };
      let { status, data, headers } = await contactDataApi(
        POST,
        "",
        payload,
        sub_route,
        BLOB
      );
      if (status === 200) {
        let fileName = "Contacts.xlsx";
        let fileNameHeader = headers["content-disposition"].split('"');
        if (fileNameHeader && fileNameHeader.length > 2) {
          fileName = fileNameHeader[1];
        }
        saveAs(new File([data], fileName));
      } else {
        this.props.enqueueSnackbar(VALIDATION_MESSAGE.export_fail, {
          variant: ERROR,
        });
      }
      this.gridApi.hideOverlay();
    }
  };

  setSelectAllRows = (isAllRowsSelected) => {
    this.setState({ isAllRowsSelected });
    this.gridApi.forEachNode((node) => {
      node.setSelected(isAllRowsSelected);
    });
  };

  handleChange = () => {
    this.setSelectAllRows(!this.state.isAllRowsSelected);
  };

  handleAddAsClient = (contactId) => {
    this.setState({ isAddAsClientOpen: true, contactIds: [contactId] });
  };

  handleAddAsClientClose = () => {
    this.setState({ isAddAsClientOpen: false, contactIds: [] });
  };

  HeaderCheckbox = () => {
    return (
      <Checkbox
        style={{ padding: 0, width: 16, height: 16 , color: "white" }}
        size="small"
        color="primary"
        onChange={this.handleChange}
      />
    );
  };

  NameRenderer = (params) => {
    return (
      <ContactNameRenderer
        params={params}
        handleAddToSearch={this.handleAddToSearch}
      />
    );
  };

  linkedInPopupHandler = (props) => {
    this.setState({ linkedPopup: true, linkedInValue: props.value });
  };

  LinkedInRenderer = (params) => {
    return (
      <LinkedInRenderer
        params={params}
        linkedInPopup={this.linkedInPopupHandler}
      />
    );
  };

  handleClose = () => {
    this.setState({ linkedPopup: false });
  };

  AddContactToRenderer = (params) => {
    const status = checkContactFetchingStatus(params)
    if (status) return status;

    const list = [
      {
        label: "To Search",
        onClick: () => this.handleAddToSearch(params?.data?.id),
      },
      {
        label: "To Workbench",
        onClick: () => this.handleAddToWorkbench(params.data?.id),
      },
    ];
    if (this.props.type === "my-bd-targets") {
      list.push({
        label: "As Client",
        onClick: () => this.handleAddAsClient(params?.data?.id),
      });
    } else {
      list.push({
        label: "As BD Target",
        onClick: () =>
          addAsMyBDTarget(
            params?.data,
            this.props.userData,
            this.gridApi,
            this.props.enqueueSnackbar
          ),
      });
    }

    return params.data ? <ActionsPopover list={list} /> : null;
  };

  handleAddToSearchBySelection = () => {
    const contactIds = getSelectedContactIds(this.gridApi);
    this.setState({ isAddToSearchOpen: true, contactIds });
  };

  handleAddToSearch = (contactId) => {
    this.setState({ isAddToSearchOpen: true, contactIds: [contactId] });
  };

  CompanyNameRenderer = (params) => {
    const status = checkContactFetchingStatus(params)
    if (status) return status;
    return <CompanyNameRenderer company={params?.data?.company} />;
  };

  handleAddToSearchClose = () => {
    this.setState({ isAddToSearchOpen: false, contactIds: [] });
  };

  showActivityHistory = (activeContact) => {
    this.setState({
      activeContact,
    });
  };

  handleDeleteContactClose = (deleted) => {
    this.setState({ isDeleteContactOpen: false, contactId: null })
    if (deleted) {
      this.gridApi.onFilterChanged()
    }
  }

  ActionsRenderer = (params) => {
    const status = checkContactFetchingStatus(params)
    let canDelete = checkRecordCanDelete(params, status);
    if (status === LINKEDIN_SCRAPER.PROCESSING_LABEL && !canDelete) return status;

    let list;
    if (status === LINKEDIN_SCRAPER.FAILED_LABEL || canDelete) {
      list = [
        {
          label: "Delete Record",
          onClick: async () => {
            this.setState({ isDeleteContactOpen: true, contactId: params.data?.id });
          },
        },
      ];
    } else {
      list = [
        {
          label: "Log An Activity",
          onClick: async () => {
            this.acquire(params?.data?.id);
          },
        },
        {
          label: "Remove From Workbench",
          onClick: () =>
            this.setState({
              isRemoveFromWorkbenchOpen: true,
              contactId: params.data?.id,
            }),
        },
        {
          label: "Show History",
          onClick: params.colDef
            ? () =>
              params.colDef.cellRendererParams.showActivityHistory(params.data)
            : null,
        },
        {
          label: "Delete Record",
          onClick: () => {
            this.setState({ isDeleteOpen: true, contactId: params.data?.id });
          },
        },
      ];
    }
    return params.data ? <ActionsPopover list={list} /> : null;
  };
  acquire = async (contactId) => {
    let { status, data } = await acquireLockApi(contactId);
    if (status && status === 200 && data.message === undefined) {
      if (this.state.isRemove) {
        await releaseLockApi(contactId);
        this.setState({ isDeleteOpen: true, contactId: contactId });
      } else {
        this.props.history.replace(`/contacts/${contactId}/log-an-activity/general-info`, {
          sortModel: this.state.sortModel,
          fromContact: true,
          previousPath: this.props.location.pathname,
        });
      }
    } else {
      if (data.isAdmin) {
        this.enqueueSnackbar(data.message, {
          variant: WARNING,
          // eslint-disable-next-line react/display-name
          action: (key) => (
            <>
              <Button
                size="small"
                variant="text"
                color="inherit"
                onClick={() => {
                  this.closeSnackbar(key);
                  this.releaseLock(contactId);
                }}
              >
                Force Unlock
              </Button>
              <Button
                size="small"
                variant="text"
                color="inherit"
                onClick={() => {
                  this.closeSnackbar(key);
                  this.props.history.replace(
                    `/contacts/${contactId}/log-an-activity/read-only/general-info`,
                    {
                      sortModel: this.state.sortModel,
                      fromContact: true,
                      previousPath: this.props.location.pathname,
                    }
                  );
                }}
              >
                Open Read Only View
              </Button>

              <Button
                size='small'
                variant='text'
                color='inherit'
                onClick={async () => {
                  this.closeSnackbar(key)
                }}
              >
               CLOSE
              </Button>
            </>
          ),
        });
      } else {
        this.enqueueSnackbar(data.message, {
          variant: WARNING,
          action: (key) => (
            <Button
              size="small"
              variant="text"
              color="inherit"
              onClick={() => {
                this.closeSnackbar(key);
                this.props.history.replace(
                  `/contacts/${contactId}/log-an-activity/read-only/general-info`,
                  {
                    sortModel: this.state.sortModel,
                    fromContact: true,
                    previousPath: this.props.location.pathname,
                  }
                );
              }}
            >
              Open Read Only View
            </Button>
          ),
        });
      }
      this.setState({ isRemove: false });
    }
  };
  releaseLock = async (contactId) => {
    await releaseLockApi(contactId);
    this.acquire(contactId);
  };
  handleDeleteClose = (deleted) => {
    this.setState({ isDeleteOpen: false, contactId: null });
    if (deleted) {
      this.gridApi.onFilterChanged();
    }
  };

  onSortChanged = (params) => {
    const sortModel = params.api.getSortModel();
    this.setState({ sortModel });
    this.saveColumnStateForSort()
  };

  onFirstDataRendered = () => {
    const locationState = this.props.location.state;
    if (locationState) {
      const sortModel = locationState.sortModel;
      if (sortModel) {
        this.gridApi.setSortModel(sortModel);
      }
    }
  };

  saveColumnState = async () => {
    try {
      this.gridApi && this.gridApi.showLoadingOverlay()
      const columnApi = this.columnApi;
      const gridApi = this.gridApi
      await saveColumnStateToLocalStorage(this.props.storageKey, { columnApi, gridApi });
      this.gridApi && this.gridApi.hideOverlay()
    } catch (e) {
      console.log("Error found in saveColumnState::", e);
    }
  };

  saveColumnStateForFilter = async () => {
    try {
      this.gridApi && this.gridApi.showLoadingOverlay()
      const columnApi = this.columnApi;
      const gridApi = this.gridApi
      await saveColumnStateToLocalStorage(this.props.storageKey, { columnApi, gridApi }, true, false);
      this.gridApi && this.gridApi.hideOverlay()
    } catch (e) {
      console.log("Error found in saveColumnState::", e);
    }
  };

  saveColumnStateForSort = async () => {
    try {
      this.gridApi && this.gridApi.showLoadingOverlay()
      const columnApi = this.columnApi;
      const gridApi = this.gridApi
      await saveColumnStateToLocalStorage(this.props.storageKey, { columnApi, gridApi }, false, true);
      this.gridApi && this.gridApi.hideOverlay()
    } catch (e) {
      console.log("Error found in saveColumnState::", e);
    }
  };

  handleRowSelection = () => {
    /* if (!e.node.selected) {
      this.setState({ isAllRowsSelected: false })
    } */
    this.setState({
      isRowSelected: this.gridApi.getSelectedRows().length > 0 ? true : false,
    });
  };

  handleActivityHistoryClose = () => this.showActivityHistory(null);

  handleAddToWorkbench = (contactId) => {
    this.setState({ isAddToWorkbenchOpen: true, contactIds: [contactId] });
  };

  handleAddToWorkbenchClose = () => {
    this.setState({ isAddToWorkbenchOpen: false });
  };

  handleRemoveFromWorkbenchClose = () => {
    this.setState({ isRemoveFromWorkbenchOpen: false });
  };

  handleMergeClose = () => {
    this.setState({ showMergePopup: false });
  }

  handleAddContactsToWorkbench = () => {
    const contactIds = getSelectedContactIds(this.gridApi);
    this.setState({ isAddToWorkbenchOpen: true, contactIds });
  };

  handleCreateWorkbench = () => {
    this.setState({ isCreateWorkbenchOpen: true });
  };

  contactDataRefresh = async () => {
    let selectedRows = this.gridApi.getSelectedRows();
    if (selectedRows.length > 10) {
      this.props.enqueueSnackbar(VALIDATION_MESSAGE.review_contact, { variant: WARNING });
    } else {
      this.setState({ isLoading: true });
      let result = await contactDataRefreshApi(selectedRows);
      if (result && result.data) {
        this.setState({ pdlData: selectedRows })
        this.setState({ showMergePopup: true })
      } else {
        this.props.enqueueSnackbar(VALIDATION_MESSAGE.review_contact_fail, { variant: ERROR });
      }
      this.setState({ isLoading: false });
    }
  }

  handleQuery = (queryData) => {
    if (this.state.isAllRowsSelected === true) {
      this.setSelectAllRows(false)
      this.setState({ isAllRowsSelected: true })
    }
    else {
      this.gridApi.forEachNode(node => {
        node.setSelected(false);
      });
    }
    this.setState({ queryData: cleanupQuery(queryData) });
    this.gridApi.onFilterChanged()
  };

  classicQuery = (queryData) => {
    this.setState({ isClassicQuery: true })
    this.handleQuery(queryData)
  }

  handleCreateWorkbenchClose = async (workbenchId) => {
    try {
      this.setState({ isCreateWorkbenchOpen: false });
      if (workbenchId) {
        this.gridApi.showLoadingOverlay();
        const contactIds = getSelectedContactIds(this.gridApi);
        await addContactsToWorkbench(
          workbenchId,
          contactIds,
          this.enqueueSnackbar
        );
        this.gridApi.hideOverlay();
      }
    } catch (e) {
      console.log("Error found in handleCreateWorkbenchClose::", e);
    }
  };

  loaderChange = async (value) => {
    this.setState({ isLoading: value });
  };

  setEmploymentHistoryEndYearDate = (endDate) => {
    this.setState({ employmentHistoryMessageEndYearDate: endDate });
  }

  handleConfirmJobTitlePopup = async () => {
    this.setState({ isUpdateEmploymentHistory: true, showJobTitleChangedPopup: false, skipCheckJobTitle: true })
  }
  handleCancelJobTitlePopup = async () => {
    this.setState({ isUpdateEmploymentHistory: false, showJobTitleChangedPopup: false, skipCheckJobTitle: true })
  }

  setJobHistoryPopupStates = (data) => {
    this.setState({ ...data })
  }

  handleUpdateJobHistory = async () => {
    this.setState({ skipCheckJobTitle: false })
    const params = this.state.newContactData;
    if (this.state.isUpdateEmploymentHistory) {
      this.setState({ isLoading: true })
      const contact = this.state.currentContact
      let newJobHistory = [{
        company: contact.company,
        title: contact.current_job_title,
        start_year: contact.current_job_start_year,
        end_year: this.state.employmentHistoryMessageEndYearDate || new Date().getFullYear()
      }]
      newJobHistory = [...newJobHistory, ...contact.job_history].filter(item => item?.company)
      params.job_history = sortByChronologicalOrder(cleanupCompanyDetails(newJobHistory, contact.id))
    }
    await acquireUtil(params, 'current_job_title', this.props.enqueueSnackbar, this.props.closeSnackbar, true, this.loaderChange, undefined, undefined, true)
    this.setJobHistoryPopupStates({
      employmentHistoryMessageEndYearDate: null,
      isUpdateEmploymentHistory: false,
      currentContact: null,
      newContactData: null
    })
  }

  checkEnrichDisabled = () => {
    if (this.props.type === "my-merge-contacts" || this.props.type === "my-linkedin-contacts") {
      if (this.gridApi) {
        let isDisabled = true;
        const selectedRows = this.gridApi.getSelectedRows()
        for (const row of selectedRows) {
          if (row?.data_fetching_status === LINKEDIN_SCRAPER.FAILED) {
            isDisabled = true;
            break;
          }
          isDisabled = false;
        }
        return isDisabled;
      }
      return true;
    }
    return !this.state.isRowSelected
  }

  render() {
    const {
      linkedInValue,
      linkedPopup,
      isAddToWorkbenchOpen,
      contactIds,
      contactId,
      activeContact,
      isAddToSearchOpen,
      contacts,
      isAddAsClientOpen,
      isAllRowsSelected,
      isRowSelected,
      rowCount,
      isGridReady,
      isDeleteOpen,
      isRemoveFromWorkbenchOpen,
      isCreateWorkbenchOpen,
      isAthenaPopupOpen,
      isLoading,
      showMergePopup,
      isDeleteContactOpen,
      savedQuery
    } = this.state;
    const { users } = this.props;

    return (
      <div className="list-contacts d-flex flex-column">
        <LinkedInDialog
          open={linkedPopup}
          linkedInValue={linkedInValue}
          handleClose={this.handleClose}
        ></LinkedInDialog>
        <Loader show={isLoading} />
        <Helmet>
          <title>{this.props.title} - KG Galaxy</title>
        </Helmet>
        {isDeleteContactOpen ? <DeletePopup
          onClose={this.handleDeleteContactClose}
          payload={{ failed: true }}
          id={contactId} module={'contacts'}
          popupText={DELETE_CONFIRMATION_POPUP_MESSAGE.DELETE_FAILED_CONTACT}
        /> : null}
        {this.state.showJobTitleChangedPopup &&
          <EmploymentHistoryConfirmationDialog
            header='Update'
            employmentHistoryMessageEndYearDate={this.state.employmentHistoryMessageEndYearDate}
            setEmploymentHistoryEndYearDate={this.setEmploymentHistoryEndYearDate}
            open={this.state.showJobTitleChangedPopup}
            contact={this.state.currentContact}
            handleSubmit={this.handleConfirmJobTitlePopup}
            handleClose={this.handleCancelJobTitlePopup}
            cancelText='No'
            confirmText='Yes'
            extraClassName="jobhistory-popup"
          />
        }
        {isCreateWorkbenchOpen && (
          <AddWorkbench
            onClose={this.handleCreateWorkbenchClose}
            buttonText={VALIDATION_MESSAGE.create_workbench_add_contacts}
          />
        )}
        {isAddToWorkbenchOpen && (
          <AddContactToWorkbench
            open={isAddToWorkbenchOpen}
            contactIds={contactIds}
            onClose={this.handleAddToWorkbenchClose}
            type="Contacts"
          />
        )}
        {isRemoveFromWorkbenchOpen && (
          <RemoveContactFromWorkbench
            contactId={contactId}
            onClose={this.handleRemoveFromWorkbenchClose}
            open={isRemoveFromWorkbenchOpen}
          />
        )}
        {
          showMergePopup && (
            <PdlContactsMergingPopup
              onClose={this.handleMergeClose}
              contacts={this.state.pdlData}
            />
          )}
        {activeContact && (
          <ActivityHistories
            contact={activeContact}
            onPopupClose={this.handleActivityHistoryClose}
          />
        )}
        {isDeleteOpen ? (
          <DeletePopup
            onClose={this.handleDeleteClose}
            id={contactId}
            module={"contacts"}
            popupText={DELETE_CONFIRMATION_POPUP_MESSAGE.DELETE_CONTACT}
          />
        ) : null}
        {isAthenaPopupOpen ? (
          <AthenaInvitePopUpContact
            inviteFor={"candidate"}
            selectedDropDown={"Athena"}
            client={this.state.athenaData}
            onClose={this.handleAthenaPopupClose}
          />
        ) : null}
        {this.props.type === "my-merge-contacts" && <div className={'d-flex query-toolbar d-flex'} style={{ padding: '0px' }}>
          <ExpansionPanel className="w-100 m-0" expanded={this.state.showQuery && !this.state.minimizeQuery}>
            <ExpansionPanelSummary
              expandIcon={
                <ExpandMoreIcon onClick={() => this.setState({
                  minimizeQuery: true
                })} />
              }
              aria-controls="panel1a-content"
              id="panel1a-header"
              onClick={() => this.setState({
                minimizeQuery: !this.state.minimizeQuery
              })}
              style={{ padding: '0px 14px', lineHeight: 1 }}
            >
              Contacts Query
            </ExpansionPanelSummary>
            <ExpansionPanelDetails style={{ padding: '0px 15px 3px', width: '100%' }}>
              {this.state.showClassic &&
                <Query gridApi={this.gridApi} isAllRowsSelected={this.state.isAllRowsSelected} columnApi={this.columnApi} handleQuery={this.classicQuery} queryData={this.state.queryData} option={users} isRowSelected={isRowSelected} type={this.props.type} resetValue={null} ref={this.child} savedQuery={savedQuery} />
              }
            </ExpansionPanelDetails >
          </ExpansionPanel >
          <div className="expansion-panel-close-container">
            <span
              style={{ display: this.state.minimizeQuery ? 'inline' : 'none' }}
              className="cursor-pointer expansion-panel-close d-flex"
              onClick={() => {
                this.setState({ showQuery: false, queryData: {}, showClassic: false });
                this.gridApi.onFilterChanged()
                this.setState({ isClassicQuery: false, showClassic: true })
                this.setState({ savedQuery: true })
                this.child.current.resetForm()
                this.child.current.resetSavedValue()
                window.location.reload(false)
              }}
            >
              <CloseIcon />
            </span >
          </div >
        </div >}
        {isAddToSearchOpen && (
          <CopyCandidatesToSearch
            contacts={contacts}
            header={VALIDATION_MESSAGE.add_contacts_to_search}
            buttonText={VALIDATION_MESSAGE.add_contacts_to_search}
            uiLabel="contact(s)"
            open={true}
            searchId={null}
            contactIds={contactIds}
            handleClose={this.handleAddToSearchClose}
          />
        )}
        <AddContactAsClient
          header={VALIDATION_MESSAGE.add_contact_as_client}
          buttonText={VALIDATION_MESSAGE.add_contact_as_client}
          uiLabel="contact"
          open={isAddAsClientOpen}
          contactId={contactIds.length ? contactIds[0] : null}
          isAllRowsSelected={isAllRowsSelected}
          handleClose={this.handleAddAsClientClose}
        />
        <div style={{ padding: "20px 20px 0 20px" }}>
          {this.props.type != "my-merge-contacts" && <Button
            disabled={this.checkEnrichDisabled()}
            onClick={this.handleAddToSearchBySelection}
            variant="outlined"
            color="primary"
            className="mr-2"
          >
            {VALIDATION_MESSAGE.add_contacts_to_search}
          </Button>}
          {this.props.type != "my-merge-contacts" && <Button
            variant="outlined"
            color="primary"
            className="mr-2"
            disabled={this.checkEnrichDisabled()}
            onClick={this.handleAddContactsToWorkbench}
          >
            Add Contacts To Workbench
          </Button>}
          {this.props.type != "my-merge-contacts" && <Button
            variant="outlined"
            color="primary"
            className="mr-2"
            disabled={this.checkEnrichDisabled()}
            onClick={this.handleCreateWorkbench}
          >
            Create New Workbench
          </Button>}
          {(this.props.type === "my-merge-contacts" || this.props.type === "my-linkedin-contacts") && <Button
            variant="outlined"
            color="primary"
            className="mr-2"
            disabled={!isRowSelected}
            onClick={this.contactDataRefresh}
          >
            Review Enrich Data
          </Button>}
        </div>
        <div
          className="d-flex align-items-center justify-content-end"
          style={{ padding: "0 20px" }}
        >

          <div
            className="action-container"
            style={{ minWidth: '0' }}
            onClick={() => this.resetFilter()}
          >
            {this.props.type != "my-merge-contacts" &&
              <span className="action-text" >
                Reset Filter
              </span>}
          </div>
          <div
            className="action-container"
            style={{ minWidth: '0' }}
            onClick={() => this.resetSort()}
          >
            {this.props.type != "my-merge-contacts" &&
              <span className="action-text" >
                Reset Sort
              </span>}
          </div>
          {this.props.type != "my-merge-contacts" && <Button
            className="mr-3"
            variant={"outlined"}
            color={"primary"}
            disabled={!isRowSelected}
            onClick={this.exportCallback}
          >
            Export list
          </Button>}
          <Typography>Total count: {rowCount}</Typography>
        </div>
        <div className="list-view flex-grow-1">
          {isGridReady && (
            <ColumnFilter
              columnApi={this.columnApi}
              defaultColumns={defaultColumns}
              filterModel={this.state.filterModel}
              showFilterCount={this.state.showFilterCount}
            />
          )}
          <div id="myGrid" className="ag-theme-alpine">
            <AgGridReact
              enableBrowserTooltips={true}
              blockLoadDebounceMillis={SCROLL_TIMEOUT}
              cacheBlockSize={PAGE_LIMIT}
              loadingOverlayComponent={"CustomLoadingOverlayComponent"}
              scrollbarWidth={12}
              suppressHorizontalScroll={false}
              suppressDragLeaveHidesColumns={true}
              rowModelType={"infinite"}
              datasource={this.dataSource}
              paginationPageSize={PAGE_LIMIT}
              rowSelection={"multiple"}
              suppressRowClickSelection={true}
              isRowSelectable={(params) => {
                const status = checkContactFetchingStatus(params)
                if (this.props.type === "my-merge-contacts" || this.props.type === "my-linkedin-contacts") {
                  if (status && status === LINKEDIN_SCRAPER.PROCESSING_LABEL) return false;
                } else if (status) return false;
                return true;
              }}
              defaultColDef={{
                minWidth: 100,
                resizable: true,
                sortable: true,
                sortingOrder: ['asc', 'desc', null]
              }}
              frameworkComponents={{
                CustomLoadingOverlayComponent,
                LinkedInRenderer: this.LinkedInRenderer,
                PhoneRenderer,
                EmailRenderer,
                ActionsRenderer: this.ActionsRenderer,
                AddContactToRenderer: this.AddContactToRenderer,
                NameRenderer: this.NameRenderer,
                ResumeRenderer,
                HeaderCheckbox: this.HeaderCheckbox,
                DateRenderer,
                DateTimeRenderer,
                CompanyNameRenderer: this.CompanyNameRenderer,
                GenericCellEditor,
                CompensationRenderer,
                CustomFilter,
                AthenaRenderer: this.AthenaRenderer,
              }}
              getRowNodeId={(data) => data.id}
              onGridReady={this.onGridReady}
              columnDefs={this.props.type != "my-merge-contacts" ? columnDefs(
                this.showActivityHistory,
                this.enqueueSnackbar,
                this.closeSnackbar,
                this.loaderChange,
                users,
                undefined,
                this.setJobHistoryPopupStates
              ) : columnMergeContactDefs(
                this.enqueueSnackbar,
                this.closeSnackbar,
                this.loaderChange,
                this.setJobHistoryPopupStates)}
              onRowSelected={this.handleRowSelection}
              onSortChanged={this.onSortChanged}
              onFirstDataRendered={this.onFirstDataRendered}
              onDisplayedColumnsChanged={this.saveColumnState}
              onDragStopped={this.saveColumnState}
              onFilterChanged={this.saveColumnStateForFilter}
            ></AgGridReact>
          </div>
        </div>
      </div>
    );
  }
}

Contacts.propTypes = {
  enqueueSnackbar: PropTypes.func,
  location: PropTypes.object,
  history: PropTypes.object,
  type: PropTypes.string,
  storageKey: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  userData: PropTypes.object,
  closeSnackbar: PropTypes.func,
  users: PropTypes.array,
  fetchUserList: PropTypes.func,
  socket: PropTypes.object,
  refreshContact: PropTypes.bool,
  [LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_COMPLETED]: PropTypes.object,
  dispatch: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    userData: state.commonReducer.userData,
    users: state.rootReducer.users,
    socket: state.rootReducer.socket,
    refreshContact: state.rootReducer.refreshContact,
    [LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_COMPLETED]: state.rootReducer[LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_COMPLETED]
  };
};
const mapDispatchToProps = (dispatch) => ({
  fetchUserList,
  dispatch,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(withRouter(Contacts)));
