//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from "prop-types";
import { Typography, Button } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import CloseIcon from '@material-ui/icons/Close';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import InputField from "../../../components/common/InputField";
import YearSelection from "../../../components/YearSelection";
import CompanySelection from "./CompanySelection";

const EmploymentHistory = (props) => {
  const { register, setValue, currentValues } = props;
  const [startYear, setStartYear] = useState('');
  const [jobHistory, setJobHistory] = useState([])

  useEffect(() => {
    register('job_history');
    if (currentValues.job_history.length > 0) {
      setJobHistory(currentValues.job_history)
    } else {
      setJobHistory([{}, {}])
    }
  }, [currentValues, register])

  useEffect(() => {
    setValue('job_history', jobHistory);
  }, [jobHistory, setValue])

  const handleChange = (index, field, value) => {
    const currentJobHistory = [...jobHistory]
    currentJobHistory[index][field] = value
    setJobHistory(currentJobHistory)
  }

  const addRecord = () => {
    setJobHistory(prevState => ([...prevState, {}]))
  }

  const removeRecord = (index) => {
    let details = [...jobHistory];
    details.splice(index, 1)
    setJobHistory(details)
  }

  return (
    <div className='w-100 section-content'>
      <Typography className='section-label'>Employment History</Typography>
      {jobHistory.map((item, index) => {
        return (
          <div key={props.dynamicKey + 'emp' + index} className="section-sub-content">
            <div className='d-flex'>
              <Typography className="sub-section-label" component={"legend"}>Company {index + 1}</Typography>
              <CloseIcon cursor='pointer' onClick={() => removeRecord(index)} style={{ width: 18, height: 18, marginRight: 15 }} />
            </div>
            <div className='d-flex flex-grow-1'>
              <CompanySelection
                defaultValue={item.company}
                onChange={(e, company) => {
                  handleChange(index, 'company', company);
                }}
                className='input-form-field input-field-old'
                label='Company Name'
                placeholder={"Search"}
                InputLabelProps={{ focused: true }}
                required={false}
              />
              <InputField
                value={item.title || ''}
                className='input-form-field input-field-old'
                label='Title'
                placeholder='Title'
                InputLabelProps={{ focused: true }}
                onChange={(e) => { handleChange(index, 'title', e.target.value) }}
              />
            </div>
            <div className='d-flex flex-grow-1'>
              <YearSelection
                className='input-form-field input-field-old'
                value={item.start_year || null}
                onChange={(e, data) => {
                  setStartYear(data)
                  handleChange(index, 'start_year', data);
                }}
                label='Job Start Year'
                InputLabelProps={{ focused: true }}
                yearType='start'
              />
              <YearSelection
                className='input-form-field input-field-old'
                minYear={Date(EmploymentHistory.start_year)}
                value={item.end_year || null}
                onChange={(e, data) => {
                  handleChange(index, 'end_year', data);
                }}
                label='Job End Year'
                InputLabelProps={{ focused: true }}
                yearType='end'
                selectedStartYear={startYear}
              />
            </div>
          </div>
        );
      })}
      <Button
        variant={"text"}
        startIcon={<AddCircleIcon />}
        onClick={addRecord}
        color='primary'>
        Add Company
      </Button>
    </div>
  );
};

EmploymentHistory.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  currentValues: PropTypes.object,
  watch: PropTypes.func,
  getValues: PropTypes.func,
  dynamicKey: PropTypes.string
};
export default EmploymentHistory;
