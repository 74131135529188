
export const PAGE_LIMIT = 200;
export const SUCCESS_STATUS_CODE = 200;

const questionCols = (questionCount) => {
  let columnDefs = [];
  for (var i = 1; i <= questionCount; i++) {
    columnDefs.push({
      field: `question_${i}`,
      headerName: `Question ${i}`
    });
  }
  return columnDefs;
}

const getSyncFitValue = (data) => {
  if (data) {
    return data?.data?.helia_desc ? data.data.helia_desc : ""
  }
  else {
    return null
  }
}

export const columnDefs = [
  {
    field: "search",
    colId: 'search',
    headerName: "Search",
    cellRenderer: "SearchIdRenderer",
    lockPinned: true,
    pinned: "left",
    tooltipValueGetter: (params) => params.value?.job_title
  },
  {
    field: "status",
    colId: 'status',
    headerName: "Status",
    pinned: "left",
    lockPinned: true
  },
  {
    field: "quality_of_choices",
    colId: 'quality_of_choices',
    headerName: "QOC"
  },
  {
    field: "time_taken",
    colId: 'time_taken',
    headerName: "Time taken"
  },
  {
    field: "user_type",
    colId: 'user_type',
    headerName: "User Type"
  },
  {
    field: "date_completed",
    colId: 'date_completed',
    headerName: "Date Completed",
    cellRenderer: "DateTimeRenderer"
  },
  {
    field: "helia_version",
    colId: 'helia_version',
    headerName: "Framework Version",
    cellRenderer: getSyncFitValue
  },
  {
    field: "x_coordinate",
    colId: 'x_coordinate',
    headerName: "X Co-ordinate"
  },
  {
    field: "y_coordinate",
    colId: 'y_coordinate',
    headerName: "Y Co-ordinate"
  },
  {
    field: "created_at",
    colId: 'created_at',
    headerName: "Created On",
    cellRenderer: "DateTimeRenderer"
  },
  {
    field: "updated_at",
    colId: 'updated_at',
    headerName: "Updated On",
    cellRenderer: "DateRenderer"
  },
  ...questionCols(49)
];
