
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from "react";

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from "prop-types";

//-----------------------------------------------------------// Internal Imports // ------------------------------

import CompensationView from "./CompensationView";
import Edit from "./Edit";

export default function CompensationContainer(props) {
  const {contact, renderLabel, renderActions, editingField, isEditing} = props;
  const {register, watch, setValue, unregister, reset, control} = props.useForm

  return (
    isEditing ?
      <Edit
        contact={contact}
        register={register}
        watch={watch}
        setValue={setValue}
        control={control}
        unregister={unregister}
        reset={reset}
      /> :
      <CompensationView
        contact={contact}
        renderLabel={renderLabel}
        renderActions={renderActions}
        editingField={editingField}
        register={register}
        watch={watch}
        setValue={setValue}
      />
  )
}

CompensationContainer.propTypes = {
  contact: PropTypes.object,
  useForm: PropTypes.object,
  renderLabel: PropTypes.func,
  renderActions: PropTypes.func,
  editingField: PropTypes.string,
  isEditing: PropTypes.bool
}
