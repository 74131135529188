
//-----------------------------------------------------------// In-built Imports // ------------------------------
import React from 'react'

//-----------------------------------------------------------// External Imports // ------------------------------
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { CircularProgress, Typography } from '@material-ui/core'

//-----------------------------------------------------------// Internal Imports // ------------------------------
import { addHttps } from '../../utils/string'
import { LinksRenderer, getDateFilterOptions, getFilterQuery, currencyWithCommas, getMatchFilterOptions } from '../Commons/Utils';
import NamePopover from './NamePopover';
import { updateCompany, getCurrencyPrefix, DateRenderer } from '../Commons/Utils'
import CurrencySelection from '../../components/CurrencySelection';
import InputField from '../../components/common/InputField';
import CountrySelection from '../../components/CountrySelection';
import UserSelection from '../../components/UserSelection';
import IndustrySelection from "../../components/IndustrySelection";
import CompanySelection from "../../components/CompanySelection";
import OffLimitsSelection from "../../components/OffLimitsSelection";
import RichTextPopup from '../../components/RichTextPopup';
import StateSelection from '../../components/StateSelection';

export const PAGE_LIMIT = 200
export const SUCCESS_STATUS_CODE = 200;
const getIndustries = params => {
  if (Array.isArray(params.value)) {
    return params.value.join(', ')
  }
  return ''
}
const getOffLimits = (params => (params.value ? "Yes" : params.value === null ? "" : "No"))
// eslint-disable-next-line 
// const getOffLimitsReason = (params => (params.value ? params.value.replace(/<br\s*[\/]?>/gi, '\n').replace(/<[^>]*>?/gm, '') : ''))
// eslint-disable-next-line 
// const getDescription = (params => (params.value ? params.value.replace(/<br\s*[\/]?>/gi, '\n').replace(/<[^>]*>?/gm, '') : ''))
//const getParentCompany = (params => (params.value ? params.value.name : ''))
const getCreatedUser = (params => (params.value ? params.value.first_name + ' ' + params.value.last_name : ''))

const getRevenue = (params) => {
  const currencyCode =
    params.data && params.data.currency_iso_code ? params.data.currency_iso_code : "";
  const revenue =
    params.data && params.data.revenue ? params.data.revenue : "";
  return (getCurrencyPrefix(currencyCode) + currencyWithCommas(revenue));
}

const stateSelection = (params) => {
  const country = params?.data?.country ? params.data.country : null
  return StateSelection({ ...params, country: country })
}

const getEditable = (params)=>{
  return (params?.data?.country) ?  true : false
}

export const columnDefs = (enqueueSnackbar, SetLoader) => [
  {
    headerName: '',
    colId: "check_box",
    field: 'check_box',
    maxWidth: 50,
    minWidth: 50,
    headerComponent: 'HeaderCheckbox',
    headerCheckboxSelectionFilteredOnly: false,
    checkboxSelection: true,
    sortable: false,
    pinned: 'left',
    lockPinned: true,
    lockPosition: true
  },
  {
    field: 'name',
    colId: 'name',
    maxWidth: 200,
    minWidth: 50,
    headerName: 'Name',
    cellRenderer: 'NameRenderer',
    pinned: 'left',
    lockPinned: true,
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      buttons: ['reset', 'apply'],
      suppressAndOrCondition: true,
      closeOnApply: true
    }
  },
  {
    field: 'industries',
    colId: 'industries',
    maxWidth: 200,
    minWidth: 50,
    headerName: 'Industries',
    tooltipValueGetter: getIndustries,
    cellRenderer: getIndustries,
    editable: true,
    cellEditor: 'PopupEditor',
    cellEditorParams: {
      InputComponent: IndustrySelection,
      isSearchIndustry:true,
    },
    valueSetter: (params) => {
      let values = params.newValue.map(industry => industry.name || industry)
      params.newValue = values
      return updateCompany(params, 'industries', enqueueSnackbar, true, SetLoader)
    },
    filter: 'CustomFilter',
    filterParams: {
      Component: IndustrySelection,
      isSearchIndustry:true,
      multiple: true
    }
  },
  {
    field: '',
    colId: "actions",
    headerName: 'Actions',
    maxWidth: 121,
    minWidth: 121,
    tooltipField: '',
    cellRenderer: 'ActionsRenderer',
    sortable: false
  },
  {
    field: 'currency_iso_code',
    colId: 'currency_iso_code',
    headerName: 'Revenue Currency',
    tooltipField: "currency_iso_code",
    editable: true,
    maxWidth: 180,
    minWidth: 50,
    cellEditor: 'GenericCellEditor',
    cellEditorParams: {
      InputComponent: CurrencySelection,
      label: ""
    },
    valueSetter: (params) => {
      params.newValue = params.newValue.code
      return updateCompany(params, 'currency_iso_code', enqueueSnackbar, false, SetLoader)
    },
    filter: 'CustomFilter',
    filterParams: {
      Component: CurrencySelection,
      field: 'code'
    }
  },
  {
    field: 'revenue',
    colId: 'revenue',
    maxWidth: 200,
    minWidth: 150,
    headerName: 'Annual Revenue',
    tooltipValueGetter: getRevenue,
    editable: true,
    cellRenderer: 'RevenueRenderer',
    cellEditor: 'NumberEditor',
    valueSetter: (params) => {
      if (params.newValue === false) {
        params.newValue = null
      }
      return updateCompany(params, 'revenue', enqueueSnackbar, false, SetLoader)
    },
    cellEditorParams: {
      field: 'revenue',
      decimalScale: 0
    },
    filter: 'agNumberColumnFilter',
    filterParams: {
      filterOptions: getMatchFilterOptions(),
      buttons: ['reset', 'apply'],
      suppressAndOrCondition: true,
      closeOnApply: true
    }
  },
  {
    field: 'city',
    colId: 'city',
    headerName: 'City',
    maxWidth: 200,
    minWidth: 50,
    tooltipField: 'city',
    hide: true,
    editable: true,
    valueSetter: (params) => {
      return updateCompany(params, 'city', enqueueSnackbar, false, SetLoader)
    },
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      buttons: ['reset', 'apply'],
      suppressAndOrCondition: true,
      closeOnApply: true
    }
  },
  {
    field: 'state',
    colId: 'state',
    headerName: 'State',
    maxWidth: 200,
    minWidth: 50,
    tooltipField: 'state',
    hide: true,
    editable: getEditable,
    cellEditor: 'GenericCellEditor',
    cellEditorParams: {
      InputComponent: stateSelection,
      label: "",
      placeholder: ''
    },
    valueSetter: (params) => {
      params.newValue = params.newValue.inputValue ? params.newValue.inputValue : params.newValue.title ? params.newValue.title : params.newValue;
      if (params.oldValue === params.newValue || params.newValue === undefined) {
        return false;
      }
      return updateCompany(params, 'state', enqueueSnackbar, false, SetLoader)
    },
    filter: 'CustomFilter',
    filterParams: {
      Component: StateSelection,
      filterOptions: ['contains'],
      buttons: ['reset', 'apply'],
      suppressAndOrCondition: true,
      closeOnApply: true
    }
  },
  {
    field: 'country',
    colId: 'country',
    headerName: 'Country',
    maxWidth: 220,
    minWidth: 50,
    tooltipField: "country",
    editable: true,
    cellEditor: 'GenericCellEditor',
    cellEditorParams: {
      InputComponent: CountrySelection,
      label: "",
      placeholder: ''
    },
    valueSetter: (params) => {
      params.newValue = params.newValue.name;
      return updateCompany(params, 'country', enqueueSnackbar, true, SetLoader)
    },
    filter: 'CustomFilter',
    filterParams: {
      Component: CountrySelection,
      multiple: true
    }
  },
  {
    field: 'ticker_sym',
    colId: 'ticker_sym',
    headerName: 'Ticker Symbol',
    tooltipField: 'ticker_sym',
    maxWidth: 200,
    minWidth: 50,
    editable: true,
    valueSetter: (params) => {
      return updateCompany(params, 'ticker_sym', enqueueSnackbar, false, SetLoader)
    },
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      buttons: ['reset', 'apply'],
      suppressAndOrCondition: true,
      closeOnApply: true
    }
  },
  {
    field: 'website',
    colId: 'website',
    headerName: 'Website',
    cellRenderer: 'WebsiteRenderer',
    tooltipField: "website",
    maxWidth: 400,
    minWidth: 50,
    editable: true,
    valueSetter: (params) => {
      return updateCompany(params, 'website', enqueueSnackbar, true, SetLoader)
    },
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      buttons: ['reset', 'apply'],
      suppressAndOrCondition: true,
      closeOnApply: true
    }
  },
  {
    field: 'phone',
    colId: 'phone',
    headerName: 'Contact Info',
    cellRenderer: 'PhoneRenderer',
    tooltipField: "phone",
    maxWidth: 200,
    minWidth: 50,
    sortable: false,
    editable: true,
    cellEditor: 'PhoneEditor',
    valueSetter: (params) => {
      return updateCompany(params, 'phone', enqueueSnackbar, false, SetLoader)
    },
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      buttons: ['reset', 'apply'],
      suppressAndOrCondition: true,
      closeOnApply: true
    }
  },
  {
    field: 'updated_at',
    colId: 'updated_at',
    hide: true,
    maxWidth: 200,
    minWidth: 50,
    headerName: 'Updated On',
    cellRenderer: 'DateRenderer',
    tooltipValueGetter: DateRenderer,
    filter: 'agDateColumnFilter',
    filterParams: {
      filterOptions: getDateFilterOptions(),
      buttons: ['reset', 'apply'],
      suppressAndOrCondition: true,
      closeOnApply: true
    }
  },
  {
    field: 'off_limits',
    colId: 'off_limits',
    hide: true,
    headerName: 'Off Limits',
    maxWidth: 120,
    minWidth: 50,
    tooltipValueGetter: getOffLimits,
    cellRenderer: getOffLimits,
    editable: true,
    cellEditor: 'ToggleSelection',
    valueSetter: (params) => {
      return updateCompany(params, 'off_limits', enqueueSnackbar, false, SetLoader)
    },
    filter: 'CustomFilter',
    filterParams: {
      Component: OffLimitsSelection,
      field: "id"
    }
  },
  {
    field: 'off_limits_reason',
    colId: 'off_limits_reason',
    hide: true,
    maxWidth: 200,
    minWidth: 50,
    headerName: 'Off Limits Reason',
    editable: true,
    cellEditor: 'RichTextBoxEditor',
    cellRenderer: 'RichTextPopupRendered',
    cellEditorParams: {
      title: 'Off Limits Reason',
      type: 'companies'
    },
    valueSetter: (params) => {
      return updateCompany(params, 'off_limits_reason', enqueueSnackbar, false, SetLoader)
    },
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      buttons: ['reset', 'apply'],
      suppressAndOrCondition: true,
      closeOnApply: true
    }
  },
  {
    field: 'off_limits_expiration',
    colId: 'off_limits_expiration',
    hide: true,
    maxWidth: 250,
    minWidth: 50,
    headerName: 'Off Limits Expiration Date',
    cellRenderer: 'DateRenderer',
    editable: true,
    tooltipValueGetter: DateRenderer,
    cellEditor: 'GenericCellEditor',
    cellEditorParams: {
      InputComponent: KeyboardDatePicker,
      label: "",
      placeholder: '',
      autoOk: true,
      variant: 'inline',
      type: "Date",
      format: "yyyy-MM-dd"
    },
    valueSetter: (params) => {
      return updateCompany(params, 'off_limits_expiration', enqueueSnackbar, false, SetLoader)
    },
    filter: 'agDateColumnFilter',
    filterParams: {
      filterOptions: getDateFilterOptions(),
      buttons: ['reset', 'apply'],
      suppressAndOrCondition: true,
      closeOnApply: true
    }
  },
  {
    field: 'description',
    colId: 'description',
    hide: true,
    maxWidth: 200,
    minWidth: 50,
    headerName: 'Description',
    // tooltipField: "description",
    cellEditor: 'RichTextBoxEditor',
    cellRenderer: 'RichTextPopupRendered',
    cellEditorParams: {
      title: 'Description',
      type: 'companies'
    },
    editable: true,
    valueSetter: (params) => {
      return updateCompany(params, 'description', enqueueSnackbar, false, SetLoader)
    },
    filter: 'agTextColumnFilter',
    filterParams: {
      filterOptions: ['contains'],
      buttons: ['reset', 'apply'],
      suppressAndOrCondition: true,
      closeOnApply: true
    }
  },
  {
    field: 'parent',
    colId: 'parent',
    maxWidth: 200,
    minWidth: 50,
    headerName: 'Parent Company',
    hide: true,
    cellRenderer: 'ParentCompanyNameRenderer',
    editable: true,
    cellEditor: 'GenericCellEditor',
    cellEditorParams: {
      InputComponent: CompanySelection,
      label: "",
      placeholder: ''
    },
    valueSetter: (params) => {
      return updateCompany(params, 'parent_id', enqueueSnackbar, false, SetLoader)
    },
    filter: 'CustomFilter',
    filterParams: {
      Component: CompanySelection,
      multiple: true
    }
  },
  {
    field: 'created_at',
    colId: 'created_at',
    headerName: 'Created On',
    hide: true,
    maxWidth: 120,
    minWidth: 50,
    cellRenderer: 'DateRenderer',
    tooltipValueGetter: DateRenderer,
    filter: 'agDateColumnFilter',
    filterParams: {
      filterOptions: getDateFilterOptions(),
      buttons: ['reset', 'apply'],
      suppressAndOrCondition: true,
      closeOnApply: true
    }
  },
  {
    field: 'created_user',
    colId: 'created_by',
    headerName: 'User Added',
    hide: true,
    maxWidth: 200,
    minWidth: 50,
    tooltipValueGetter: getCreatedUser,
    cellRenderer: getCreatedUser,
    filter: 'CustomFilter',
    filterParams: {
      Component: UserSelection,
      multiple: true,
      option: []
    }
  },
  {
    field: 'freeze_dropdown',
    headerName: '',
    colId: "freeze_dropdown",
    sortable: false,
    filter: false,
    minWidth: 44,
    maxWidth: 44,
    width: 44,
    pinned: 'right',
    lockPinned: true,
  },
]
export const WebsiteRenderer = (params) => {
  const color = (params.data?.off_limits || (params.data?.search && params.data?.search.length > 0)) ? 'red' : 'primary'
  return LinksRenderer(params, addHttps(params.value), '_blank', color)
}

export const CustomLoadingOverlayComponent = () => {
  return <CircularProgress />
}
export const getQueryParamsString = (data) => {
  let paramsString = ''
  if (data.currentCompany) {
    data.currentCompany.forEach(data => {
      paramsString = paramsString.concat(`&id=${data.id}`)
    });
  }

  if (data.country) {
    data.country.forEach(name => {
      paramsString = paramsString.concat(`&country=${name}`)
    });
  }
  if (data.fromDate) {
    paramsString = paramsString.concat(`&created_at_from=${data.fromDate}`)
  }
  if (data.toDate) {
    paramsString = paramsString.concat(`&created_at_to=${data.toDate}`)
  }
  if (data.industries) {
    data.industries.map((industryId) => {
      if (industryId.includes('&')) {
        industryId = industryId.replace('&', '%26')
      }
      paramsString = paramsString.concat(`&industry=${industryId}`)
      return paramsString
    })
  }
  if (data.vcPe) {
    data.vcPe.forEach(vcPe => {
      paramsString = paramsString.concat(`&vc_pe=${vcPe.id}`)
    });
  }
  if (data.revenueFrom) {
    paramsString = paramsString.concat(`&revenue_from=${data.revenueFrom}`)
  }
  if (data.revenueTo) {
    paramsString = paramsString.concat(`&revenue_to=${data.revenueTo}`)
  }
  return paramsString
}

export const cleanup = (queryData) => {
  if (queryData.industries) {
    queryData.industries = queryData.industries.map(item => item.name)
  }
  if (queryData.country) {
    queryData.country = queryData.country.map(item => item.name)
  }
  return queryData
}

export const CompanyNameRenderer = (props) => {
  const { company, hidePopover } = props;
  if (company) {
    if (hidePopover === true) {
      return <>{company.name || ''}</>
    }
    return <NamePopover company={company} >
      <Link to={`/companies/${company?.id}/details`} target="_blank" rel='noopener noreferrer'>
        <Typography
          className="text-link text-capitalize"
          color={company.off_limits ? 'error' : 'primary'}
        >
          {company.name || ''}
        </Typography>
      </Link>
    </NamePopover>
  }
  return ''
}

export const getFilterParamString = (data, prefix) => {
  const queryAliases = { industries: 'industry' };
  const queryValueTypes = {
    country: { field: 'name' },
    industry: { field: 'name' },
  };
  return getFilterQuery(data, queryAliases, queryValueTypes, prefix);
}

CompanyNameRenderer.propTypes = {
  params: PropTypes.object,
  company: PropTypes.object,
  hidePopover: PropTypes.bool,

}

export const defaultColumns = ['name', 'freeze_dropdown']

export const titles = {
  "name": "Name",
  "website": "Website",
  "industries": "Industries",
  "country": "Country",
  "description": "Description",
  "state": "State",
  "street": "Street",
  "city": "City",
  "currency_iso_code": "Currency Code",
  "revenue": "Revenue",
  "ticker_sym": "Ticker Symbol",
  "employee_size": "Employee Size",
  "email_syntax": "Email Syntax",
  "phone": "Phone Number",
  "off_limits": "Off Limits",
  "off_limits_reason": "Off Limits Reason",
  "off_limits_expiration": "Off Limits Expiration Date",
  "vc_pe": "VC/PE",
  "parent_id": "Parent",
  "zip_code": "Zip Code/Postal Code"
}

export const getValue = (company, field) => {
  if (field === 'industries') {
    return company.industries?.sort().join(',')
  } else if (field === 'vc_pe') {
    return company.vc_pe?.map(item => item.company?.name).sort().join(',')
  } else if (field === 'off_limits') {
    return company.off_limits ? 'Yes' : company.off_limits !== null ? 'No' : ''
  } else if (field === 'parent_id') {
    return company.parent?.name
  }
  else if (field === 'off_limits_reason' && company.off_limits_reason) {
    return {
      component: <RichTextPopup
        className="company-details-value"
        value={company.off_limits_reason}
        setOpen={false}
      />,
      value: company.off_limits_reason
    }
  }
  else if (field === 'description' && company.description) {
    return {
      component: <RichTextPopup
        className="company-details-value"
        value={company.description}
        setOpen={false}
      />,
      value: company.description
    }
  } else {
    return field !== 'employee_size' ? company[field] : (company[field] && company[field].toString())
  }
}

const dateProps = {
  variant: "inline",
  format: "dd/MM/yyyy",
  placeholder: 'Select',
  disableToolbar: false,
  autoOk: true
}

export const getCompanyFieldValue = (field, type) => typeof field === 'object' && field !== null ? field[type] : field

export const fieldDefinitions = {
  "Company Name": {
    component: InputField,
    field: "name",
    colId: "name",
    props: { placeholder: "Enter" },
  },
  // 'VC/PE': { component: CompanySelection, field: 'vc_pe.id', type: 'search', props: { placeholder: 'Select', multiple: true, filterSelectedOptions: true, size: 'small' } },
  // 'Parent Company': {
  //   component: CompanySelection,
  //   field: 'parent_id',
  //   colId: "parent_id",
  //   type: 'search',
  //   props: { placeholder: 'Select', multiple: true, filterSelectedOptions: true, size: 'small' }
  // },
  'Industry': {
    component: IndustrySelection,
    field: 'industries',
    colId: "industries",
    type: 'selection',
    dataField: 'name',
    props: { placeholder: 'Select', multiple: true, filterSelectedOptions: true, size: 'small' }
  },
  'State': {
    component: InputField,
    field: "state",
    colId: "state",
    props: { placeholder: "Enter" },
  },
  'City': {
    component: InputField,
    field: "city",
    colId: "city",
    props: { placeholder: "Enter" },
  },
  'Ticker Symbol': {
    component: InputField,
    field: "ticker_sym",
    colId: "ticker_sym",
    props: { placeholder: "Enter" },
  },
  'Location': {
    component: CountrySelection,
    type: 'selection',
    dataField: 'name',
    field: 'country',
    colId: "country",
    props: { placeholder: 'Select', multiple: true, filterSelectedOptions: true, size: "small", }
  },
  'Created Date Range': { component: KeyboardDatePicker, field: 'created_at', colId: "created_at", type: 'dateRange', props: { ...dateProps } },
  'Revenue Range': { component: InputField, field: 'revenue', colId: "revenue", type: 'numberRange', props: { placeholder: 'Enter' } },
}
