
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from "prop-types";
import isEqual from "lodash.isequal";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const PopupEditor = forwardRef((props, ref) => {
  const {
    stopEditing,
    value: defaultValue,
    InputComponent,
    title = "Industries",
    validateDqReason = false,
    onSave=()=>{},
    ...rest
  } = props;
  const initialValue = defaultValue || []
  const [open, setOpen] = React.useState(true);
  const [value, setValue] = React.useState(initialValue);
  const [currentValue, setCurrentValue] = React.useState(initialValue);
  const [canClose, setCanClose] = React.useState(false);
  const industryRef = useRef();

  const handleClose = useCallback(() => {
    stopEditing();
    setOpen(false);
  }, [stopEditing]);

  const handleSave = () => {
    setValue(currentValue);
    onSave(currentValue.map(ele=> ele.short_desc))
    setCanClose(true);
  };

  useEffect(() => {
    if (!isEqual(value, initialValue) || canClose) {
      handleClose();
      setCanClose(false);
    }
  }, [value, initialValue, handleClose, canClose]);

  const handleChange = (data) => {
    setCurrentValue(data);
  };

  useImperativeHandle(ref, () => {
    return {
      getValue: () => value,
      afterGuiAttached: () => industryRef.current?.focus(),
      isPopup: () => true,
    };
  });

  return (
    <Dialog
      className="ag-custom-component-popup"
      maxWidth="md"
      onClose={handleClose}
      disableBackdropClick={true}
      open={open}
    >
      <DialogTitle style={{ background: '#2a364c', color: '#fff' }}>{title}</DialogTitle>
      <DialogContent
        dividers
        style={{ minWidth: "400px", maxWidth: "400px", maxHeight: 150 }}
      >
        <InputComponent
          multiple={true}
          filterSelectedOptions={true}
          label=""
          size="small"
          {...rest}
          value={currentValue}
          inputRef={industryRef}
          onChange={(e, data) => handleChange(data)}
        />
      </DialogContent>
      <DialogActions className="page-content-footer">
        <Button onClick={handleClose} className="page-btn-cancel">
          Cancel
        </Button>
        <Button variant="contained" disabled={validateDqReason && !currentValue.length} onClick={handleSave}  className="page-btn-save">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
});

PopupEditor.propTypes = {
  stopEditing: PropTypes.func,
  value: PropTypes.array,
  InputComponent: PropTypes.node,
  title: PropTypes.string,
  validateDqReason: PropTypes.bool,
  onSave: PropTypes.func
};

PopupEditor.displayName = "PopupEditor";

export default PopupEditor;
