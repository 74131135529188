
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { forwardRef, useImperativeHandle } from "react";

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from "prop-types";
import { TextField } from "@material-ui/core";
import ReactPhoneInput from "react-phone-input-material-ui";

//-----------------------------------------------------------// Internal Imports // ------------------------------

import { determineInitialCountryGuess } from "../../../utils/phone";

const PhoneEditor = forwardRef((props, ref) => {
  const { value: initialValue } = props;
  const [value, setValue] = React.useState(initialValue);

  const handleChange = (text) => {
    setValue(text);
  };

  useImperativeHandle(ref, () => {
    return {
      getValue: () => value,
      afterGuiAttached: () => { },
    };
  });

  return (
    <div className="phone-input">
      <ReactPhoneInput
        containerStyle={{
          position: "fixed",
          width: '195px',
        }}
        placeholder="Phone Number"
        value={value || ""}
        onChange={(data, c, e, value) => {
          if (data.length > 1) {
            handleChange(value);
          } else {
            handleChange("");
          }
        }}
        enableSearch={true}
        enableSearchField={true}
        disableInitialCountryGuess={determineInitialCountryGuess(value)}
        inputProps={{
          label: "",
          InputLabelProps: { focused: true },
        }}
        component={TextField}
      />
    </div>
  );
});

PhoneEditor.displayName = "PhoneEditor";

PhoneEditor.propTypes = {
  stopEditing: PropTypes.func,
  value: PropTypes.string,
};

export default PhoneEditor;
