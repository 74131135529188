//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useState } from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from "prop-types";
import { Button, Typography } from "@material-ui/core";
import GroupIcon from "@material-ui/icons/Group";
import ListAltIcon from '@material-ui/icons/ListAlt';
import EditIcon from '@material-ui/icons/Edit';
import ControlPointDuplicateIcon from '@material-ui/icons/ControlPointDuplicate';
import RestoreIcon from '@material-ui/icons/Restore';
import ArchiveIcon from '@material-ui/icons/Archive';
import { useSnackbar } from "notistack";
import LockIcon from '@material-ui/icons/Lock';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { getTodayDate } from "../../../Containers/Workbenches/utils";
import DuplicateWorkbench from "./DuplicateWorkbench";
import ConfirmationPopup from "../../ConfirmationPopup";
import { formatDate, getDifferenceInDays } from "../../../utils/date";
import { PUT, SUCCESS, ERROR } from "../../../services/constantService";
import { getArchivedDate, getWorkbenchUsers } from "../utils";
import { workbenchDataApi } from "../../../services/ApiService";
import { VALIDATION_MESSAGE } from "../../../services/MessageService";

export default function Header(props) {
  const { name, users = [], workbench, handleEdit, isEditing, handleEditCancel, showEdit, handleSave, children, reloadData, isLockedWorkbench } = props
  const [showDuplicatePopup, setShowDuplicatePopup] = useState(false)
  const [showArchiveConfirmPopup, setShowArchiveConfirmPopup] = useState(false)
  const [showARestoreConfirmPopup, setShowRestoreConfirmPopup] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const extraClassName = isLockedWorkbench ? 'locked' : ''

  const handleDuplicate = () => {
    setShowDuplicatePopup(true);
  }

  const handleDuplicateWorkbenchClose = () => {
    setShowDuplicatePopup(false)
  }

  const handleArchive = () => {
    setShowArchiveConfirmPopup(true)
  }

  const handleConfirmPopupClose = () => {
    setShowArchiveConfirmPopup(false)
  }

  const updateWorkbench = async (formData, successMessage, errorMessage) => {
    const { status, data } = await workbenchDataApi(PUT, workbench.id, formData);
    if (status === 200) {
      enqueueSnackbar(successMessage, { variant: SUCCESS })
      reloadData()
    } else {
      enqueueSnackbar(data?.message || errorMessage, { variant: ERROR })
    }
  }

  const archiveWorkbench = async () => {
    try{  
      const formData = { archive_date: formatDate(new Date(), 'YYYY-MM-DD') }
      await updateWorkbench(formData, 'Workbench archived successfully', 'Unable to archive workbench')
      handleConfirmPopupClose()
    }catch(e){
      console.log("Error found in archiveWorkbench::", e);
    }

  }

  const handleRestoreClose = () => {
    setShowRestoreConfirmPopup(false)
  }

  const restoreWorkbench = async () => {
    try{  
      const formData = { archive_date: formatDate(getArchivedDate(), 'YYYY-MM-DD') }
      await updateWorkbench(formData, 'Workbench restored successfully', 'Unable to restore workbench')
      handleRestoreClose()
    }catch(e){
      console.log("Error found in restoreWorkbench::", e);
    }

  }

  const handleRestore = () => {
    setShowRestoreConfirmPopup(true)
  }

  return (
    <div className='d-flex justify-content-between position-sticky search-header'>
      {
        showDuplicatePopup &&
        <DuplicateWorkbench workbench={workbench} onClose={handleDuplicateWorkbenchClose} />
      }
      {
        showArchiveConfirmPopup &&
        <ConfirmationPopup
          message="Are you sure you want to archive this workbench?"
          onClose={handleConfirmPopupClose}
          onConfirm={archiveWorkbench}
        />
      }
      {
        showARestoreConfirmPopup &&
        <ConfirmationPopup
          message={VALIDATION_MESSAGE.restore_workbench}
          onClose={handleRestoreClose}
          onConfirm={restoreWorkbench}
        />
      }
      <div>
        <div className='d-flex align-items-center'>
          <Typography className='master-card'>{name}</Typography>
          {workbench.is_locked && <span className="ml-1 action-icon">
            <LockIcon fontSize="small" />
          </span>}
        </div>
        <div className='d-flex'>
          {workbench.is_replist &&
            <div className='d-flex align-items-center mt-3 mr-5'>
              <ListAltIcon style={{ color: '#818181' }} fontSize='small' />
              <Typography className='job-title'>
                Rep List
              </Typography>
            </div>
          }
          <div className='d-flex align-items-center mt-3'>
            <GroupIcon style={{ color: '#818181' }} fontSize='small' />
            <Typography className='job-title'>
              {getWorkbenchUsers(users)}
            </Typography>
          </div>
        </div>
      </div>
      <div className='d-flex align-items-center'>
        {
          isEditing && showEdit ?
            <React.Fragment>
              <Button color='primary' variant='text' className='mr-3' onClick={handleEditCancel}>Cancel</Button>
              <Button color='primary' variant='contained' className='mr-3' onClick={handleSave}>Save</Button>
            </React.Fragment>
            :
            <React.Fragment>
              {
                showEdit &&
                <div onClick={handleEdit} className={`action-container d-flex align-items-center ${extraClassName}`}>
                  <span className="action-icon">
                    <EditIcon fontSize="inherit" />
                  </span>
                  <span className="action-text">Edit Workbench</span>
                </div>
              }
              <div className="action-container d-flex align-items-center" onClick={handleDuplicate}>
                <span className="action-icon">
                  <ControlPointDuplicateIcon fontSize="inherit" />
                </span>
                <span className="action-text">Duplicate Workbench</span>
              </div>
              {
                getDifferenceInDays(workbench.archive_date, getTodayDate()) > 0 ?
                  <div className={`action-container d-flex align-items-center ${extraClassName}`} onClick={handleArchive}>
                    <span className="action-icon">
                      <ArchiveIcon fontSize="inherit" />
                    </span>
                    <span className="action-text">Archive Workbench</span>
                  </div>
                  :
                  <div className={`action-container d-flex align-items-center ${extraClassName}`} onClick={handleRestore}>
                    <span className="action-icon">
                      <RestoreIcon fontSize="inherit" />
                    </span>
                    <span className="action-text">Restore Workbench</span>
                  </div>
              }
            </React.Fragment>
        }
        {children}
      </div>
    </div>
  )
}

Header.propTypes = {
  name: PropTypes.string,
  users: PropTypes.array,
  handleEdit: PropTypes.func,
  isEditing: PropTypes.bool,
  handleEditCancel: PropTypes.func,
  showEdit: PropTypes.bool,
  handleSave: PropTypes.func,
  children: PropTypes.node,
  workbench: PropTypes.object,
  reloadData: PropTypes.func,
  isLockedWorkbench: PropTypes.bool
}
