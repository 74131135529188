//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from "prop-types";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";

//----------------------------------------------// Internal Imports // -------------------------------------------------
import InputField from "../../../components/common/InputField";
import CompanySelection from "./CompanySelection";
import CountrySelection from "../../../components/CountrySelection";
import YearSelection from "../../../components/YearSelection";
import StateSelection from "../../StateSelection";
import UserSelection from "../../UserSelection";


const ProfileDetails = (props) => {
  const { register, setValue, values, unregister, dynamicKey, watch, option } = props;
  const country = watch('country');
  const [startYear, setStartYear] = useState('');
  useEffect(() => {
    register({ name: 'current_company' });
    register({ name: 'current_job_start_year' });
    register({ name: 'current_job_end_year' })
    register('country')
    register('state')
    register('industries');
    register('mailing_list');
    register('international_experience')
    return (() => {
      unregister('current_company');
      unregister('country');
      unregister('state');
      unregister('current_job_start_year');
      unregister('current_job_end_year');
    })
  }, [register, unregister])

  const stateChange = (state) => {
    setValue('state', state);
  }

  return (
    <div className="w-100 section-content">
      <Typography className="section-label">Profile Details</Typography>
      <div className="d-flex flex-grow-1">
        <InputField
          ref={register()} 
          className="input-form-field input-field-old"label={<label>First Name<span className="MuiInputLabel-asterisk"> *</span></label>} placeholder='First Name' InputLabelProps={{ focused: true }} name="first_name" />
        <InputField
          ref={register()} className="input-form-field input-field-old" label={<label>Last Name<span className="MuiInputLabel-asterisk"> *</span></label>} placeholder='Last Name' InputLabelProps={{ focused: true }} name="last_name" />
      </div>
      <div className='d-flex flex-grow-1'>
        <CompanySelection
          // required={true}
          key={dynamicKey + 'current'}
          defaultValue={values['current_company']}
          onChange={(e, company) => {
            setValue("current_company", company);
            if (company && company.industries?.length) {
              setValue("industries", company.industries);
            }
          }}
          className='input-form-field input-field-old'
          label={<label>Company Name<span className="MuiInputLabel-asterisk"> *</span></label>}
          placeholder={"Search"}
          InputLabelProps={{ focused: true }}
        />
        <InputField
          // required
          className='input-form-field input-field-old'
          label={<label>Job Title<span className="MuiInputLabel-asterisk"> *</span></label>}
          placeholder='Job Title'
          ref={register()}
          name='current_job_title'
          InputLabelProps={{ focused: true }}
        />
      </div>
      <div className='d-flex flex-grow-1'>
        <YearSelection
          autoSelect
          autoHighlight
          key={dynamicKey + 'start_year'}
          className='input-form-field input-field-old'
          label={<label>Job Start Year<span className="MuiInputLabel-asterisk"> *</span></label>}
          defaultValue={values['current_job_start_year']}
          // required={true}
          onChange={(e, data) => {
            setStartYear(data)
            setValue("current_job_start_year", data);
          }}
          InputLabelProps={{ focused: true }}
          yearType='start'
        />
        <YearSelection
          autoSelect
          autoHighlight
          key={dynamicKey + 'end_year'}
          defaultValue={values['current_job_end_year']}
          className='input-form-field input-field-old'
          label='Job End Year'
          onChange={(e, data) => {
            setValue("current_job_end_year", data);
          }}
          InputLabelProps={{ focused: true }}
          yearType='end'
          selectedStartYear={startYear}
        />
      </div>
      <div className='d-flex'>
        <InputField
          ref={register}
          className='input-form-field input-field-old'
          name='age'
          label='Age'
          placeholder='Age'
          type='number'
          InputProps={{
            inputProps: { min: 0 }
          }}
          InputLabelProps={{ focused: true }}
        />
        <FormControl className='w-100' style={{ paddingRight: 20 }}>
          <Typography className='input-label'>Gender</Typography>
          <RadioGroup row key={dynamicKey + 'gender'} aria-label='gender' defaultValue={values['gender']} name='gender' className='radio-group'>
            <FormControlLabel
              className="checkbox-component"
              control={<Radio 
                className="checkbox-container"size="small" value='FEMALE' inputRef={register} />}
              label='Female'
            />
            <FormControlLabel className="checkbox-container" control={<Radio className="checkbox-component" size="small" value='MALE' inputRef={register} />} label='Male' />
            <FormControlLabel className="checkbox-container" control={<Radio className="checkbox-component" size="small" value='OTHER' inputRef={register} />} label='Other' />
          </RadioGroup>
        </FormControl>
      </div>
      <div className='d-flex flex-grow-1'>
        <CountrySelection
          defaultValue={values.country}
          // required={true}
          className="input-form-field input-field-old"
          key={dynamicKey + 'country'}
          onChange={(e, country) => {
            stateChange('');
            setValue("country", country?.name);
          }}
          label={<label>Location<span className="MuiInputLabel-asterisk"> *</span></label>}
          placeholder={"Search"}
          InputLabelProps={{ focused: true, classes: { root: 'input-label' } }}
        />
        <StateSelection
          value={watch('state') || null}
          key={dynamicKey + 'state'}
          className='input-form-field input-field-old'
          country={country}
          register={register}
          onChange={(e, state) => {
            stateChange(state);
          }}
        />
      </div>
      <div className='d-flex flex-grow-1'>
        <InputField
          ref={register}
          className='input-form-field input-field-old'
          name='city'
          label='City'
          placeholder='City'
          InputLabelProps={{ focused: true }}
        />
        <InputField
          className='input-form-field input-field-old'
          label='LinkedIn Link'
          placeholder='LinkedIn Link'
          ref={register}
          name='linkedin_url'
          InputLabelProps={{ focused: true }}
        />
      </div>
      <div className='d-flex flex-grow-1'>
        <UserSelection
          option={option}
          multiple
          value={watch('mailing_list')}
          className='input-form-field input-field-old'
          filterSelectedOptions={true}
          label='Mailing List'
          InputLabelProps={{ focused: true }}
          onChange={(e, data) => setValue('mailing_list', data)}
        />
        <FormControl component='fieldset' className='input-form-field input-field-old'>
          <Typography className={'input-label'}>
            Mark Contact As
          </Typography>
          <RadioGroup row aria-label='gender' key={dynamicKey + 'is_'} className='radio-group'>
            <FormControlLabel
              className="checkbox-container"
              control={<Checkbox className="checkbox-component" size="small" defaultChecked={values['is_client']} name='is_client' inputRef={register} />}
              label='Client'
            />
            <FormControlLabel
              className="checkbox-container"
              control={<Checkbox className="checkbox-component" size="small" defaultChecked={values['is_rock_star']} name='is_rock_star' inputRef={register} />}
              label='Star'
            />
            <FormControlLabel
              className="checkbox-container"
              control={<Checkbox className="checkbox-component" size="small" defaultChecked={values['is_board_candidate']} name='is_board_candidate' inputRef={register} />}
              label='Board Candidate'
            />
          </RadioGroup>
        </FormControl>
      </div>
      <div className='d-flex flex-grow-1'>
        <FormControl component='fieldset' className='input-form-field input-field-old'>
          <Typography className={'input-label'}>
            GDPR Release
          </Typography>
          <FormControlLabel
            className="checkbox-container"
            control={
              <Checkbox
                className="checkbox-component"
                size="small"
                defaultChecked={values['gdpr_release']}
                name='gdpr_release'
                inputRef={register}
              />
            }
            label='Yes'
          />
        </FormControl>
        <FormControl component='fieldset' className='input-form-field input-field-old'>
          <Typography className={'input-label'}>
            Exclude from ClientSuite
          </Typography>
          <FormControlLabel
            className="checkbox-container"
            control={
              <Checkbox
                className="checkbox-component"
                size="small"
                defaultChecked={values['hide_in_client_suite']}
                name='hide_in_client_suite'
                inputRef={register}
              />
            }
            label='Yes'
          />
        </FormControl>
      </div>
      <div className='d-flex flex-grow-1'>
        <CountrySelection
          defaultValue={values.international_experience}
          multiple={true}
          className="input-form-field input-field-old w-50"
          filterSelectedOptions={true}
          onChange={(e, data) => {
            setValue("international_experience", data);
          }}
          label='International Experience'
          placeholder={"Search"}
          InputLabelProps={{ focused: true, classes: { root: 'input-label' } }}
        />
      </div>
      <FormControl component='fieldset' className='input-form-field input-field-old'>
        <Typography className={'input-label'}>
          Diversity Candidate
        </Typography>
        <FormControlLabel
          className="checkbox-container"
          control={
            <Checkbox
              className="checkbox-component"
              size="small"
              defaultChecked={values['diversity_candidate']}
              name='diversity_candidate'
              inputRef={register}
            />
          }
          label='Yes'
        />
      </FormControl>
    </div>
  );
};

ProfileDetails.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  unregister: PropTypes.func,
  values: PropTypes.object,
  dynamicKey: PropTypes.string,
  watch: PropTypes.func,
  option: PropTypes.array
};

export default ProfileDetails;
