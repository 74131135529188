
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from "react";

//-----------------------------------------------------------// Internal Imports // ------------------------------

import BDList from "./BDList";

export default function PipeLineSearches() {
  return (
    <div className="my-search d-flex flex-column">
      <BDList isFromCreateSearch={true} />
    </div>
  );
}
