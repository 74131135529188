export const AWS_DOMAIN_DEV = 'https://galaxy-dev.auth.us-east-2.amazoncognito.com';
export const AWS_DOMAIN_PROD = 'https://auth.galaxy-2020.com';
export const AWS_APP_CLIENT_ID_DEV = '1gaf4glg0gafaf9gojc5738u2m';
export const AWS_APP_CLIENT_ID_PROD = '3n6sd3d4sdupn0ivnvsr138f00';
export const AWS_TOKEN_ENDPOINT = '/oauth2/token';
export const AWS_TOKEN_GRANT_TYPE_AUTH_CODE = 'authorization_code';
export const AWS_TOKEN_GRANT_TYPE_REFRESH_TOKEN = 'refresh_token';
export const SOCKET_PORT = ':5000';
export const TEST_URL = 'https://d2dj9nz75kvbxi.cloudfront.net';
export const TEST_SOCKET_URL = TEST_URL + SOCKET_PORT;
export const LAB_URL = 'https://lab.galaxy-2020.com';
export const LAB_SOCKET_URL = LAB_URL + SOCKET_PORT;
export const LOCAL_URL = 'http://localhost:3000';
export const LOCAL_SOCKET_URL = 'http://localhost:5000';
export const STAGE_URL = 'https://staging.galaxy-2020.com';
export const STAGE_SOCKET_URL = STAGE_URL + SOCKET_PORT;
export const PROD_URL1 = 'https://www.galaxy-2020.com';
export const PROD_SOCKET_URL1 = PROD_URL1 + SOCKET_PORT;
export const PROD_URL2 = 'https://app.galaxy-2020.com';
export const PROD_SOCKET_URL2 = PROD_URL2 + SOCKET_PORT;
export const PROD_URL3 = 'https://asia.galaxy-2020.com';
export const PROD_SOCKET_URL3 = PROD_URL3 + SOCKET_PORT;
export const AWS_APP_REDIRECT_PATH = '/searches/my-searches';
export const SYNCLINK_URL = 'https://galaxy-dev.auth.us-east-2.amazoncognito.com/oauth2/authorize?client_id=4udpmvte98tadol3ren9464gp5&response_type=code&scope=email+openid+profile&redirect_uri=https://synclink-test.kingsleygate.com/'
export const GALAXY_HL_SSO_URL_DEV = 'https://galaxy-dev.auth.us-east-2.amazoncognito.com/login?client_id=' + AWS_APP_CLIENT_ID_DEV + '&response_type=code&scope=email+openid+profile&redirect_uri=';
export const GALAXY_HL_SSO_URL_PROD = 'https://auth.galaxy-2020.com/login?client_id=' + AWS_APP_CLIENT_ID_PROD + '&response_type=code&scope=email+openid&redirect_uri=';
export const GALAXY_GUIDE_URL = 'https://galaxy-web-app.s3.us-east-2.amazonaws.com/galaxy-assets/The_Ultimate_Galaxy_Guide_July_2022.pdf'
export const HTML_TAGS_FILTER_REGEX = /(<([^>]+)>)/ig;
export const KGU_DASHBOARD = 'https://kgpuniversity.com';
export const CASH_COLLECTED_DASHBOARD = '/dashboard';
export const DISALLOW_EXTENSION = /(\.|\/)(bat|exe|cmd|sh|php([0-9])?|pl|cgi|386|dll|com|torrent|js|app|jar|pif|vb|vbscript|wsf|asp|cer|csr|jsp|drv|sys|ade|adp|bas|chm|cpl|crt|csh|fxp|hlp|hta|inf|ins|isp|jse|htaccess|htpasswd|ksh|lnk|mdb|mde|mdt|mdw|msc|msi|msp|mst|ops|pcd|prg|reg|scr|sct|shb|shs|url|vbe|vbs|wsc|wsf|wsh)$/i;
// export const AR_BY_PARTNER = 'https://prod-useast-a.online.tableau.com/#/site/kingsleygatepartners/views/ARReport08172022/ARByPartner_1?:iid=4';
// export const SALES_BY_COMPENSATION_DASHBOARD = 'https://prod-useast-a.online.tableau.com/#/site/kingsleygatepartners/views/SalesByPartnerReportAugust2022/SalesByPartnerReport?:iid=1';
// export const CASH_COLLECTED_DASHBOARD = 'https://prod-useast-a.online.tableau.com/#/site/kingsleygatepartners/views/CashCollectedReport08172022/CashCollectedByPartner_1?:iid=2';
export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const POSITIVE_INTEGER_WITH_ZERO_REGEX = /^[1-9]+[0-9]*$/;
//eslint-disable-next-line
export const LINKEDIN_URL_REGEX = /(https?)?:?(\/\/)?(([w]{3}||\w\w)\.)?linkedin.com(\w+:{0,1}\w*@)?(\S+)(:([0-9])+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
// export const URL_REGEX = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi;
export const EXPORT_CONTACT_LIMIT = 1500;
export const SPECIAL_CHARACTERS_REPLACE_REGEX = /[&/\\#,+()$~%.'":*?<>{}]/g;
export const REPLACE_SPACE_REGEX = /\s/g;
export const CONSTANT_MESSAGE = 'Notes Description and Title cannot be empty together'
export const EXPORT_LIMIT = 10000;
export const GET = "get";
export const CANDIDATE = "candidate";
export const START_YEAR = '1970'
export const ACTIVITY = "activity";
export const ACTIVITIES = "activities";
export const ACTIVITIES_ATTRIBUTE = "activities-attribute";
export const CONTACT = "contact";
export const POST = "post";
export const PUT = "put";
export const PATCH = "patch";
export const DELETE = "delete";
export const BLOB = "blob";
export const CLIENTS = "clients";
export const KG_TEAM = "kg-team";
export const CLIENTSUITE = "ClientSuite";
export const UPDATED_AT = "updated_at";
export const ARENA = "Arena";
export const REPLY = "reply";
export const ERROR = "error";
export const SUCCESS = "success";
export const WARNING = "warning";
export const THOUSAND_SEPARATOR = "ThousandSeparator";
export const DECIMAL_SEPARATOR = "DecimalSeparator";
export const OLD_SEARCH_DATE = "14/03/2022";
export const REDO = `<svg viewbox="0 0 18 18">
                        <polygon class="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10"></polygon>
                        <path class="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"></path>
                    </svg>`;
export const UNDO = `<svg viewbox="0 0 18 18">
                        <polygon class="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10"></polygon>
                        <path class="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"></path>
                    </svg>`;

export const HL_SYNC_CANDIDATES = {
  VALIDATE_EMAIL_MESSAGE: 'Following candidates will not sync because of having invalid/empty personal email',
  VALIDATE_REASON_MESSAGE: `Following candidates couldn't be synced.`,
  NO_FOUND_VALID_CANDIDATES: 'No found valid candidates for HL Sync.',
  STOP_SYNCING_CANDIDATES: 'Please wait... we are syncing all candidates with Hirelogic.',
  STOP_SYNCING_MESSAGE_LABEL: 'You can Stop Syncing',
  STOPPING_SYNCING_MESSAGE: 'Stopping process...'
}

export const DASHBOARD = {
  LEGACY_AR_BY_PARTNER: 'ARReport10162022/ARSummary',
  LEGACY_SALES_BY_PARTNER: 'SalesByPartnerReport10162022/SalesByPartner',
  LEGACY_CASH_REPORT: 'CashReport10162022/CollectedYTD',
  // LEGACY_REGIONAL_SALES: 'RegionalSalesDashboard10162022/Dashboard', // deprecated url
  LEGACY_REGIONAL_SALES: 'RegionalSalesDashboard10162022_16891800622390/Dashboard',
  AR_BY_PARTNER: 'AR_REPORT',
  CASH_REPORT: 'CASH_REPORT',
  SALES_BY_PARTNER: 'SALES_REPORT',
  BD_REPORT: 'BD_REPORT',
  HOST_URL: 'https://prod-useast-a.online.tableau.com/',
  SCRIPT_URL: 'https://prod-useast-a.online.tableau.com/javascripts/api/viz_v1.js',
  SITE_ROOT: '/t/kingsleygatepartners',
  COOKIE_INFORMATION: 'Please enable 3rd party cookies in your browser to see the tableau views. For more information, ',
  INFORMATION_LINK: 'https://akohubteam.medium.com/how-to-enable-third-party-cookies-on-your-browsers-f9a8143b8cc5',
  INSIGHT_URL: 'insight-url'
}

export const EXTENDED_SEARCH_LOAD_CANDIDATES = 'extended-search/candidates'
export const PRODUCTONE_LOAD_CANDIDATES = 'candidates-galaxy'
export const PRODUCTONE_TERMINATE_EXTENDED = 'terminate-extended-search'
export const ACTIVITIES_TABDATA = 'activity-tabdata'
export const SFPA_BUILDER_VALIDATE = 'There is a problem identifying Search Roadmap builder due to an incomplete SEARCH.'

export const NOTIFICATION = {
  EMPTY_LABEL: 'No notifications',
  READ: 'READ',
  UNREAD: 'UNREAD',
  EXTENDED_SEARCH_COMPLETED: 'extendedSearchCompleted',
  SHAKE_TIME: 1000,
  CONFIRM_REDIRECT: 'Do you want to go to the Product One candidate list?'
}

export const PRODUCT_ONE_MESSAGE = {
  PROCESS_INPROGRESS: 'Process is in-progress',
  STARTED: 'The Extended Search in Galaxy has started and is running in the background.  You will receive an email and a notification in Galaxy when it is complete.',
  VALIDATE_SFPA: 'Cannot process with incomplete Search Roadmap data. Make sure target_industries, contact_targets, target_job_functions, search_industry_rankings are available',
  CONFIRM_TERMINATE: 'Do you want to stop the search?',
  VALIDATE_POPUP_HEADER: 'There is a problem identifying candidates due to an incomplete Search Roadmap',
  UNABLE_TO_FETCH_MESSAGE: `has encountered an error. Please contact <a class='support-mail-link' href='mailto:support@kingsleygate.com'>support@kingsleygate.com</a>`,
  QUICK_SEARCH_PROCESS_STARTED: 'search is running...',
  VALIDATE_DISAGREE_SELECTION: 'Reject reason is specified without selecting the disagree checkbox. Please remove the reason or select the checkbox to proceed',
  DISAGREE_TOOLTIP_LABEL: 'Add reason to enable the checkbox',
  quick_search_timeout: 'Quick Search is taking longer than expected. Please refresh the screen after some time to view the results.'
};

export const DELETE_CONFIRMATION_POPUP_MESSAGE = {
  DELETE_COMPANY: 'Are you sure you want to delete this company?',
  DELETE_CONTACT: 'Are you sure you want to delete this contact?',
  DELETE_FAILED_CONTACT: 'Are you sure you want to delete this record? This will remove all associated records',
  DELETE_CANDIDATE_FROM_SEARCH: 'Are you sure you want to delete this candidate from search?',
  DELETE_RECORD: 'Are you sure you want to delete this record?',
  DELETE_SEARCH: 'Are you sure you want to delete this search?',
  DELETE_BD_SEARCH: 'Are you sure you want to delete this BD?',
  DELETE_TRANSLATE_PRODUCT: 'Are you sure you want to delete this product?',
  DELETE_DOCUMENT: 'Are you sure you want to delete this Document Type?',
  DELETE_AI_TEMPLATE: 'Are you sure you want to delete this AI Template?'
}

export const PRODUCT_ONE = {
  QUICK: 'quick',
  QUICK_LABEL: 'Quick',
  EXTENDED: 'extended',
  EXTENDED_LABEL: 'Extended',
  ZOOM: 'Zoom',
  TATRAS: 'Galaxy P1',
  EXTENDED_RECORD_LIMIT: 25,
  ZOOM_QUICK_LIMIT: 50,
  TATRAS_QUICK_LIMIT: 80,
  REJECTED_STAGE: 'P1 Rejected',
  CANDIDATE_SOURCE: 'Galaxy P1',
}

export const LINKEDIN_SCRAPER = {
  GET_LINKEDIN_TASK: 'linkedin-scraper',
  CHECK_DUPLICATE_CONTACTS: 'check-duplicate-contacts',
  LINKEDIN_SCRAPER_COMPLETED: 'linkedinScraperCompleted',
  LINKEDIN_SCRAPER_NOTIFY_TO_CANDIDATES: 'linkedinScraperCompletedToCandidates',
  LINKEDIN_SCRAPER_NOTIFY_TO_PRODUCT_ONE: 'linkedinScraperCompletedToProductOne',
  PROCESSING_LABEL: 'PROCESSING...',
  FAILED_LABEL: 'FAILED',
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
  PROCESSING: 'PROCESSING',
  MY_LINKEDIN_CANDIDATES: 'MY_LINKEDIN_CANDIDATES',
  myLinkedinCandidatesRedux: 'myLinkedinCandidates'
}

export const LOE_GENERATION = {
  PROCESSING: 'PROCESSING',
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
  LOE_DOCUMENT_UPLOADED: 'LOE_DOCUMENT_UPLOADED'
}

export const WEBSITE = {
  LABLE: 'Website QR Code',
  TOOLTIP_MESSAGE: 'Click here to get redirect to website'
}
export const LINKEDIN = {
  LABLE: 'LinkedIn QR Code',
  TOOLTIP_MESSAGE: 'Click here to get redirect to linkedin profile'
}
export const COMMENTS_VALIDATION = {
  SINGLE_COMMENT_SAME: 'Selected partner and recruiter comments are identical',
  ALL_COMMENTS_SAME: 'All partners and recruiters comments are identical',
}
export const TARGET_INDUSTRY_RANKING = {
  RANKING_VALIDATION_MESSAGE: "Weight must between 0 to 100"
}
export const SCORE_RANKING = {
  SCORE_VALIDATION_MESSAGE: "Score must be between 0 to 100"
}
export const TARGET_COUNTRY_RANKING = {
  RANKING_VALIDATION_MESSAGE: "Weight must between 0 to 100"
}
export const INLINE_EDIT_RECORD_VALIDATION_MESSAGE = "Inline changes will be lost. Are you sure you want to continue?"
export const SYNCLINK_CREATED_SUCCESSFULLY = "Synclink record created. Use 'View Synclink' option to access"
export const SYNCLINK_FAILED = "Synclink creation failed."
export const UPDATE_EMPLOYMENT_HISTORY_CONFIRMATION_MESSAGE = 'This change will update employment history, do you want to proceed?'
export const IMAGE_UNSUPPORTED_FILE_FORMAT = 'This is an unsupported file format for image'
export const WORKBENCHES_MESSAGE = {
  TOOLTIP_MESSAGE: 'This workbench is locked',
  VALIDATION_MESSAGE: 'Selected Workbench is Locked.'
}

export const ACTIVITY_LOG_MESSAGE = {
  GENERAL_INFO_REQUIRED_FIELDS: "Please fill all required fields in General Info tab",
  JOB_HISTORY_REQUIRED_FIELDS: "Company is required for job history",
  BOARD_DETAILS_HISTORY_REQUIRED_FIELDS: "Company is required for board details"
}

export const AVAILABLE_COUNTRY_WITH_STATES = ['United States of America (USA)', 'India', 'Brazil', 'Mexico']


export const BILLING_VALIDATIONS = {
  FEE_PERCENTAGE: "Fee percentage is a required field",
  FEE_CAP_AMOUNT: "Estimated Revenue exceeds the specified Fee Cap Amount",
  MARKET_VALUE: "Geography Value is required when Estimated Revenue is greater than 0",
  NOTIFY_BILLING: "Actual revenue should be greater than 0",
  FEE_TYPE: "Fee Type required if value is selected",
  INDIRECT_FEE: "Fee Type is required if calculation type is selected in Indirect Fee",
  LOE_DATE: "Loe Date is required for acknowledging the estimated revenue",
  FEE_PERCENTAGE_MAX: 'Percentage must be less than or equal to 100',
  BY_PRODUCT_FEE_MAX: 'By product fee must be less than or equal to 100',
  CALCULATION_AMOUNT_MAX: 'Please enter valid calculated amount'
}

export const INVOICE_VALIDATION = {
  COLLECTED_PERCENTAGE: "Invoice collected percentage must be 100% for closing the opportunity",
  UNSAVED_DATA: 'Unsaved data will be lost. Do you wish to continue?',
  CONFIRM_TO_DELETE: 'Are you sure you want to permanently delete this record?'
}

export const BUSINESS_DEVELOPMENT = {
  REQUIRED_FIELDS: 'Please add all mandatory fields to generate LOE Document.',
  GENERATE_LOE: 'generate-loe'
}

export const API = {
  acquire_lock: "/api/lock/acquire",
  pdl_contact_data_merge: "/api/contacts/pdl-contact-update",
  contact_data_refresh: "/api/contacts/add-contact-to-pdl-staging",
  galaxy_pdl_data: "/api/contacts/pdl-galaxy-contact/details",
  release_lock: "/api/lock/release",
  renew_lock: "/api/lock/renew",
  users: "/api/users",
  get_zendesk_token: "/api/users/get-zendesk-token",
  search: "/api/searches",
  sfpa_decision: "/api/sfpa-decision-questionnaires",
  candidate: "/api/candidates",
  workbench: "/api/work-benches",
  contact: "/api/contacts",
  companies: "/api/companies",
  user_profile: "/api/users/profile",
  user_export: "/api/users/export-as-excel",
  user_search: "/api/users/search",
  tanslate_search: "/api/tanslate/search",
  picklists: "/api/picklists",
  picklists_companies: "/api/picklists/companies",
  picklists_countries: "/api/picklists/countries",
  picklists_country_states: "/api/picklists/countries/states",
  type_list: "/api/product-settings",
  picklists_timezones: "/api/picklists/timezones",
  picklists_contacts: "/api/picklists/contacts",
  picklists_colors: "/api/picklists/colors",
  picklists_search: "/api/picklists/searches",
  master_query: `/api/master-search/query`,
  client_feedback: "/api/client-feedback",
  hl: "/api/hl",
  activities: "/api/activities",
  searches: "/api/search",
  reportTemplate: "/api/carbon",
  master_search: "/api/master-search",
  business_developments: "/api/business-developments",
  reports: "/api/reports",
  contacts: "/api/contact",
  clientsuite: "/api/clientsuite",
  synclink: "/api/synclink",
  athena: "/api/athena",
  offlimits: "/api/offlimits/contacts",
  user_grid: "/api/user-grid-settings",
  candidatesuite: "/api/candidate-suite",
  dashboard: "/api/dashboard",
  product_one: "/api/product-one",
  product_setting: "/api/product-settings",
  chart_api: "/api/chart-api",
  invoice_summary: "/api/invoice-summary/getAllInvoiceSummary",
  invoice_summary_export: "/api/invoice-summary",
  sfpa_setting: "/api/template",
};

export const ROUTES = {
  masterSearch: "/master-search",
  searches: "/searches",
  reportTemplate: "/report-template",
  createSearch: "/searches/create/:currentTabs",
  createSearchWithoutCurrentTab: "/searches/create",
  pSearches: "/searches/pipeline-searches",
  mySearchesKey: "/searches/my-searches/:searchKey",
  mySearches: "/searches/my-searches",
  validateToken: "/validate-token",
  kgSearches: "/searches/kg-searches",
  myPlacements: "/searches/my-placements",
  kgPlacements: "/searches/kg-placements",
  billedInvoice: "/invoice-summary/billed-invoice",
  yetToBeBilled: "/invoice-summary/yet-to-be-billed",
  nextTenDays: "/invoice-summary/next-ten-days",
  overdue: "/invoice-summary/overdue",
  readyToBill: "/invoice-summary/ready-to-bill",
  addBd: "/searches/business-development/add",
  addTranslateValue: '/product-setup/translate-value/add',
  addDocumentType: '/product-setup/document-type/add',
  addSfpaBuilder: '/product-setup/sfpa-builder/add',
  readOnlyActivityLog: "/searches/:searchId/:path/:id/log-an-activity/read-only/:currentTabs",
  readOnlyActivityLogWithoutCurrentTab: "/searches/:searchId/:path/:id/log-an-activity/read-only",
  activityLog: "/searches/:searchId/:path/:id/log-an-activity/:currentTabs",
  activityLogWithoutCurrentTab: "/searches/:searchId/:path/:id/log-an-activity",
  viewSearch:
    "/searches/:id([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})/:currentTabs",
  viewSearchWithActivetab:
    "/searches/:id([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})/:currentTabs/:currentSfpaTab",

  viewSearchWithoutCurrentTab: "/searches/:id([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})",
  clientSuiteReport:
    "/searches/:id([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})/client-suite-report",
  allCandidates:
    "/searches/:id([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})/all-candidates",
  myLinkedinCandidates:
    "/searches/:id([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})/my-linkedin-candidates",
  addCompany: "/companies/add",
  comMyClients: "/companies/clients",
  comMyTargets: "/companies/targets",
  viewCompany: "/companies/:id/:currentTabs",
  viewCompanyWithoutCurrentTab: "/companies/:id",
  companies: "/companies",
  addContact: "/contacts/add/:currentTabs",
  addContactWithoutCurrentTab: "/contacts/add",
  conClients: "/contacts/my-clients",
  conMyTarget: "/contacts/my-targets",
  conMergeContacts: "/contacts/my-mergecontacts",
  linkedinContacts: "/contacts/my-linkedin-contacts",
  readOnlyContactActivityLog: "/contacts/:contactId/log-an-activity/read-only/:currentTabs",
  readOnlyContactActivityLogWithoutCurrentTab: "/contacts/:contactId/log-an-activity/read-only",
  contactActivityLog: "/contacts/:contactId/log-an-activity/:currentTabs",
  contactActivityLogWithoutCurrentTab: "/contacts/:contactId/log-an-activity",
  viewContactWithoutCurrentTab: "/contacts/:id",
  viewContact: "/contacts/:id/:currentTab",
  contacts: "/contacts",
  userAdd: "/users/add",
  users: "/users",
  usersWithId: "/users/:id",
  workbenchAdd: "/workbenches/add",
  myWorkbenchesContacts: "/workbenches/my-workbenches/contacts",
  myWorkbenchesSearches: "/workbenches/my-workbenches/searches",
  myWorkbenches: "/workbenches/my-workbenches",
  kgWorkbenchesContacts: "/workbenches/kg-workbenches/contacts",
  kgWorkbenchesSearches: "/workbenches/kg-workbenches/searches",
  kgWorkbenches: "/workbenches/kg-workbenches",
  archivedWorkbenchesContacts: "/workbenches/archived-workbenches/contacts",
  archivedWorkbenchesSearches: "/workbenches/archived-workbenches/searches",
  archivedWorkbenches: "/workbenches/archived-workbenches",
  viewWorkbench: "/workbenches/:id/:currentTab",
  viewWorkbenchWithoutCurrentTab: "/workbenches/:id",
  workbenches: "/workbenches",
  languageExpertise: "language-expertise",
  remaining: "*",
  dashboard: '/dashboard',
  legacyArByPartnerDashboard: "/insights/ar-by-partner",
  legacySalesByPartnerDashboard: "/insights/sales-by-partner",
  legacyCashReportDashboard: "/insights/cash-report",
  legacyRegionalSalesDashboard: "/insights/regional-sales",
  arByPartnerDashboard: "/insights/ar-by-partner",
  salesByPartnerDashboard: "/insights/sales-by-partner",
  cashReportDashboard: "/insights/cash-report",
  bDReportDashboard: "/insights/bd-report",
  defaultRoute: "/insights/*",
  translateValueProductSetup: "/product-setup/translate-value",
  documentTypeProductSetup: "/product-setup/document-type",
  sfpaBuilder: "/product-setup/sfpa-builder",
  reportTemplates: "/product-setup/report-templates",
  productSetup: "/product-setup/*",
  searchApprovalPending: "/search-approval/pending",
  searchApprovalRejected: "/search-approval/rejected",
  invoiceSummary: '/invoice-summary'
};

export const WORKBENCH_TYPES = {
  archived: "archived",
  archivedContacts: "archived-contacts",
  archivedSearches: "archived-searches",
  kg: "kg",
  kgSearches: "kg-searches",
  kgContacts: "kg-contacts",
  user: "user",
  userContacts: "user-contacts",
  userSearches: "user-searches"
}

export const ROUTE_KEY = {
  masterSearch: "masterSearch",
  createSearch: "createSearch",
  pSearches: "pSearches",
  mySearches: "mySearches",
  myPlacements: "myPlacements",
  kgPlacements: "kgPlacements",
  billedInvoice: "billedInvoice",
  yetToBeBilled: "yetToBeBilled",
  nextTenDays: "nextTenDays",
  overdue: "overdue",
  readyToBill: "readyToBill",
  addBd: "addBd",
  addTranslateValue: "addTranslateValue",
  addDocumentType: "addDocumentType",
  addSfpaBuilder: "addSfpaBuilder",
  readOnlyActivityLog: "readOnlyActivityLog",
  activityLog: "activityLog",
  viewSearch: "viewSearch",
  clientSuiteReport: "clientSuiteReport",
  allCandidates: "allCandidates",
  myLinkedinCandidates: "myLinkedinCandidates",
  addCompany: "addCompany",
  comMyClients: "comMyClients",
  comMyTargets: "comMyTargets",
  viewCompany: "viewCompany",
  companies: "companies",
  addContact: "addContact",
  conClients: "conClients",
  conMyTarget: "conMyTarget",
  conMergeContacts: "conMergeContacts",
  linkedinContacts: "linkedinContacts",
  readOnlyContactActivityLog: "readOnlyContactActivityLog",
  contactActivityLog: "contactActivityLog",
  viewContact: "viewContact",
  contacts: "contacts",
  userAdd: "userAdd",
  users: "users",
  workbenchAdd: "workbenchAdd",
  myWorkbenchesContacts: "myWorkbenchesContacts",
  myWorkbenchesSearches: "myWorkbenchesSearches",
  myWorkbenches: "myWorkbenches",
  kgWorkbenchesContacts: "kgWorkbenchesContacts",
  kgWorkbenchesSearches: "kgWorkbenchesSearches",
  kgWorkbenches: "kgWorkbenches",
  archivedWorkbenchesContacts: "archivedWorkbenchesContacts",
  archivedWorkbenchesSearches: "archivedWorkbenchesSearches",
  archivedWorkbenches: "archivedWorkbenches",
  viewWorkbench: "viewWorkbench",
  languageExpertise: "languageExpertise",
  languages: "languages",
  dashboard: 'dashboard',
  legacyArByPartnerDashboard: "legacyArByPartnerDashboard",
  legacySalesByPartnerDashboard: "legacySalesByPartnerDashboard",
  legacyCashReportDashboard: "legacyCashReportDashboard",
  legacyRegionalSalesDashboard: "legacyRegionalSalesDashboard",
  arByPartnerDashboard: "arByPartnerDashboard",
  salesByPartnerDashboard: "salesByPartnerDashboard",
  cashReportDashboard: "cashReportDashboard",
  bdReportDashboard: "bdReportDashboard",
  defaultRoute: 'defaultRoute',
  translateValueProductSetup: "translateValueProductSetup",
  documentTypeProductSetup: "documentTypeProductSetup",
  productSetup: "productSetup",
  searchApprovalPending: "searchApprovalPending",
  searchApprovalRejected: "searchApprovalRejected",
  invoiceSummary: "invoiceSummary",
  SearchSfpaReport: "SearchSfpaReport",
  reportTemplates: "reportTemplates"

};

export const ROUTE_TYPE = {
  userContacts: "user-contacts",
  userSearches: "user-searches",
  user: "user",
  kgContacts: "kg-contacts",
  kgSearches: "kg-searches",
  kg: "kg",
  archivedContacts: "archived-contacts",
  archivedSearches: "archived-searches",
  archived: "archived",
};

export const LABELS_AND_PLACEHOLDERS = {
  expertiseLevel: "Level of expertise",
  language: "Language",
  languages: "Languages",
};

export const REGIONAL_ROLE_WITH_MARKET = {
  REGHEAD_USA: ['USA'],
  REGHEAD_APAC: ['Singapore', 'India'],
  REGHEAD_EUROPE: ['UK'],
  REGHEAD_LATAM: ['LatAm-Other'],
}



export const FINANCE_ROLE_EDIT_FIELDS_RESTRICTIONS = {
  search: [], //['stage', 'type', 'result', 'on_hold_date', 'off_hold_date', 'start_date', 'loe_date', 'is_sfpa_completed', 'contact_name', 'billing_contact', 'mobile_phone', 'work_email', 'billing_address', 'zip_code', 'invoice_delivery_type', 'is_po_direct', 'po', 'billing_notes', 'currency', 'estimated_total_comp', 'fee_type', 'fee', 'fee_percentage', 'fee_amount', 'is_fee_cap', 'admin_fee', 'frequency', 'duration', 'by_product_fee', 'retainer_1', 'retainer_2', 'retainer_3', 'billed_date', 'collected_date', 'amount']
  user: [],
};

export const USER_ROLE_EDIT_FIELDS_RESTRICTIONS = {
  search: [
    "stage",
    "type",
    "result",
    "on_hold_date",
    "off_hold_date",
    "start_date",
    "loe_date",
    "is_sfpa_completed",
    "contact_name",
    "billing_contact",
    "mobile_phone",
    "work_email",
    "billing_address",
    "zip_code",
    "invoice_delivery_type",
    "is_po_direct",
    "po",
    "billing_notes",
    "currency",
    "estimated_total_comp",
    "fee_type",
    "fee",
    "fee_percentage",
    "fee_amount",
    "is_fee_cap",
    "admin_fee",
    "frequency",
    "duration",
    "by_product_fee",
    "retainer_1",
    "retainer_2",
    "retainer_3",
    "billed_date",
    "collected_date",
    "amount",
  ],
  user: [],
};

export const ADMIN_ROLE_EDIT_FIELDS_RESTRICTIONS = {
  search: [],
  user: []
};

export const HIRELOGIC_STAGES = ["Placement", "Offer Presented", "Client Interview", "KG Interview", "Candidate", "Screened", "Initial Contact & Scheduled Calls", "Target"]

export const PENDING_ACKNOWLEDGMENT = 'Pending Acknowledgement'
export const OPEN = 'Open'
export const PENDING_REJECT_BILLING_STATUS = ['PND_RHEAD', 'PND_ACK_EST', 'PND_ACK']
export const PENDING_REGIONAL_BILLING_STATUS = ['PND_RHEAD']
export const NOTIFY_BILLING_ACKNOWLEDGEMENT = ['ACK_DONE_EST']
export const PENDING_BILLING_ACKNOWLEDGEMENT = ['PND_ACK_EST', 'PND_ACK']
export const ALL_BILLING_STATUS = ['PND_RHEAD', 'REJ_RHEAD', 'PND_ACK_EST', 'ACK_DONE_EST', 'PND_ACK', 'ACK_DONE']
export const SUBMIT_APPROVAL_STATUS = ['APR_RHEAD', 'NONE']
export const DEFAULT_ALLOWED_SANITIZE_TAGS = ['p', 'h1', 'h2', 'h3', 'em', 'strong', 'u', 'a', 'ol', 'ul', 'li', 'br'];
export const DEFAULT_ALLOWED_SANITIZE_ATTRIBUTES = { a: ['href'] };
export const DISCARD = 'discard';
/* as per requirement change dashboard department 'IT' to 'Office of the Chairman' */
export const VISIBLE_DEPARTMENTS_LIST = ['SLT', 'Finance', 'Executive Team', 'Office of the Chairman']
export const AR_BY_PARTNER_DEPARTMENTS = ['Admin']
export const HIDE_REGIONAL_SALES_FOR_DEPARTMENTS = ['Partner', 'Vice President']
export const REGIONAL_ROLE = 'RegionHead'
export const BILLING_DESK_ROLE = 'BILLDESK'
export const ADMIN_ROLE = 'admin'
export const ALL_REGIONAL_HEAD = ['PND_RHEAD', 'REJ_RHEAD', 'APR_RHEAD', 'ACK_DONE', 'NONE']
export const ALL_BILLING_DESK = ['PND_ACK_EST', 'ACK_DONE_EST', 'PND_ACK', 'ACK_DONE', 'NONE']
export const INVOICE_INFO_ROLE = ['PM', 'Partner']
export const SFPA_DATA = {
  sfpa_invalid_data: 'No candidates found for the given Search Roadmap details. Please provide more information in Search Roadmap to find matching candidates'
}
export const STATUS_CHECK = ["REJ_RHEAD", "PND_ACK_EST"]
export const BD_STAGES = ['On Hold', 'BD']
export const OPEN_STAGES = ['Open', 'On Hold', 'Closed', 'None']
export const BD_PENDING_STATUS_MESSAGE = "Pending Threshold Approval";

export const SCORES_DESCRIPTION = (transKeys = {}) => [{
  name: "Title",
  description: "Title Score indicates the Candidate title similarity with all titles provided in the Search Roadmap"

}, {
  name: "Job Function",
  description: "Job Function Score indicates the degree of candidate’s job function similarity with respect to Search Roadmap Job functions"

}, {
  name: "Industry",
  description: "Industry Score indicates the degree of candidate’s industry similarity with respect to Search Roadmap industries"

}, {
  name: "Company",
  description: "Company Score indicates the degree of candidate’s company profile match with the target companies provided in Search Roadmap. This takes into account company information like company industry, revenue range, employee range."

}, {
  name: "Company Employee Range",
  description: "Company Employee Range Score signifies how closely the candidate's company employee size aligns with the specified employee range in the target title in Roadmap. A higher score indicates a stronger match."
}, {
  name: "Company Industry",
  description: "Company Industry Score indicates the similarity between the candidate's company industry and the industries specified in the target companies of the Search Roadmap. The higher the score, the closer the alignment."
}, {
  name: "Company Revenue Range",
  description: "Company Revenue Range Score reflects the degree of similarity between the candidate's company's revenue range and the revenue range outlined in the target title in Roadmap. A higher score denotes a closer match."
}, {
  name: transKeys['EXACT_COMPANY_MATCH'] || '',
  description: "The Exact Company Match Score indicates how closely the candidate's current company matches to the target companies specified in the Search Roadmap. A higher score signifies a strong match."
}, {
  name: 'Proximity Location',
  description: "--",
  skipFilter: true
}, {
  name: "Career Experience",
  description: "Career Experience indicating the nearness of candidate’s years of experience with respect to experience provided in Search Roadmap."

}, {
  name: "Job Movement",
  description: "Job Movement indicates the frequency of change at the workplace. Lower change frequency results in higher stint score indicating stability."

}, {
  name: "Current Title Tenure",
  description: "Current Title Tenure indicates the recent switch of the candidate weighted by years in the current title."

}, {
  name: "Relevancy",
  description: "Relevancy Score indicates the degree of title match with the titles provided in Search Roadmap weighted by years of experience in the recent matching title."

}, {
  name: "Lifer",
  description: "Candidate has been in their current position for more than the maximum listed in the scope."

}]

export const FEE_TYPES = {
  ONE_THIRD: 'Standard - 1/3',
  OTHER: 'Other Percentage',
  FIXED_FEE: 'Fixed Fee'
}

export const HELIA = {
  DEFAULT_VERSION: 'Athena Questions V2',
  DEFAULT_VERSION_DESC: 'Helia',
  VERSION_1: 'Athena Questions Motive Pairs',
  VERSION_1_DESC: 'Legacy',
  VERSION_2: 'Athena Questions V2',
  VERSION_2_DESC: 'Helia',
}

export const ATTRIBUTE_SELECTOR = {
  VERSION_1: 'Leadership_And_Management_Attributes_1',
  VERSION_2: 'Attribute Selector V2',
}