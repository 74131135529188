
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from 'react'

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import InputField from '../common/InputField';

function GenderSelection(props) {
  const { label = "gender", placeholder = "Gender", required = false, InputLabelProps, inputRef, ...rest } = props;
  const options = ['MALE', 'FEMALE', 'OTHER'];

  return (
    <Autocomplete
      {...rest}
      options={options}
      getOptionLabel={(option) => option}
      renderInput={(params) => (
        <InputField
          {...params}
          required={required}
          variant="standard"
          ref={inputRef}
          InputLabelProps={InputLabelProps}
          label={label}
          placeholder={placeholder}
        />
      )}
    />
  );
}


GenderSelection.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  InputLabelProps: PropTypes.object,
  InputProps: PropTypes.object,
  inputRef: PropTypes.object,
  disabled: PropTypes.bool
};

export default GenderSelection