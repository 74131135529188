//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useState, useEffect } from 'react'

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from "prop-types";

//----------------------------------------------// Internal Imports // -------------------------------------------------
import TeamInfoTable from './TeamInfoTable'
import { useSelector } from "react-redux";
import { isEmpty } from '../../../../../utils/common';
export default function PartnerInformation(props) {
  const { register, setValue, currentValues = {}, isEditing = true, option, bd, types, currentTabIndex } = props
  const basicPartner = useSelector(state => state.searchReducer.basicPartner)
  const fields = {
    origination_credit: 30,
    selling_credit: 20,
    execution_credit: 50
  }
  const [partners, setPartners] = useState([])
  const [selectedPartners, setSelectedPartners] = useState([])
  useEffect(() => {
    register('partners')
    setValue('partners', partners)
  }, [register, partners, setValue])

  useEffect(() => {
    if (bd !== "AddBD" && types === "AddSearch") {
      if (currentValues.basic_partners && currentValues.basic_partners.length) {
        let filterPartnerData = []
        const result = currentValues.basic_partners.filter(
          obj => !(obj && Object.keys(obj).length === 0)
        );
        if (result && result.length) {
          if (partners && partners.length) {
            partners.map(elem => {
              const isPartnerMatch = result.find(item => item?.id === elem?.user?.id)
              if (isPartnerMatch) {
                filterPartnerData.push(isPartnerMatch)
              } else {
                const isUser = elem?.hasOwnProperty('user')
                if (isUser) {
                  if (elem && elem.user) {
                    filterPartnerData.push(elem.user)
                  }
                }
              }
            }
            )
            currentValues.basic_partners = filterPartnerData
            setValue("basic_partners", filterPartnerData)
          }
        }
      }
    }
  }, [partners])
  useEffect(() => {
    if (bd !== "AddBD" && types === "AddSearch" && currentTabIndex === 2) {
      if (basicPartner !== undefined) {
        if (basicPartner.length) {
          const isBasicPartner = basicPartner.some(item => isEmpty(item))
          if (!isBasicPartner) {
            //const isPartner = currentValues.partners.some(item => isEmpty(item.user && item.user))
            // let isValid
            // if (!isPartner) {
            //   isValid = currentValues.partners.findIndex(item => basicPartner.some(elem => elem.id === item.user.id))
            // }
            // if (isValid !== -1) {
            if (basicPartner.length) {
              currentValues.partners = basicPartner.map(item => item)
            }
            //}
          } else {
            currentValues.partners = basicPartner
          }
        }
      }
    }
  }, [basicPartner])
  useEffect(() => {
    if (partners && partners.length > 0) {
      const options = option.filter((array_el) => {
        return partners.every(anotherOne_el => anotherOne_el?.user?.id !== array_el?.id)
      });
      setSelectedPartners(options)
    } else {
      setSelectedPartners(option)
    }
  }, [register, partners, setValue])

  useEffect(() => {
    setPartners(currentValues.partners)
  }, [currentValues.partners])
  return (
    <div>
      <TeamInfoTable header={"Partner Information"} label="Partner" inputConfig={{ list: partners, fields, setter: setPartners }} isEditing={isEditing} option={selectedPartners} currentValues={currentValues} bd={bd} />
    </div>
  )
}

PartnerInformation.propTypes = {
  register: PropTypes.func,
  control: PropTypes.object,
  setValue: PropTypes.func,
  watch: PropTypes.func,
  currentValues: PropTypes.object,
  isEditing: PropTypes.bool,
  option: PropTypes.array,
  bd: PropTypes.string,
  types: PropTypes.string,
  currentTabIndex: PropTypes.number
}
