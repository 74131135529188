//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect } from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";

//----------------------------------------------// Internal Imports // -------------------------------------------------
import IndustrySelection from "../../../components/IndustrySelection";
import JobFunctionSelection from "../../jobFunctionSelection";

function IndustryAndJobFunction(props) {
  const { register, setValue, watch, showTitle = true, disabled=false,required=true } = props;


  useEffect(() => {
    register({ name: 'industries' }, { required: true, validate: value => value.length > 0 });
    register({ name: 'job_functions' }, { required: true, validate: value => value.length > 0 });
  }, [register]);
  return (
    <div className='w-100 section-content industry-job-functions'>
      {showTitle && <Typography className='section-label'>Industry and Job Function</Typography>}
      <div className='d-flex flex-grow-1 align-items-end'>
        <IndustrySelection
          multiple
          required={required}
          filterSelectedOptions
          isSearchIndustry={true}
          size='small'
          label='Industry'
          className='input-form-field input-field-old'
          value={watch('industries') || []}
          onChange={(e, data) => {
            setValue(`industries`, data ? data.map((item) => typeof item === 'string' ? item : item.name) : [])
          }}
          InputLabelProps={{ focused: true }}
          disabled={disabled}
        />
        <JobFunctionSelection
          multiple
          required={required}
          filterSelectedOptions
          size='small'
          className='input-form-field input-field-old'
          label='Job Function'
          value={watch('job_functions') || []}
          InputLabelProps={{ focused: true }}
          onChange={(e, data) => {
            setValue('job_functions', data ? data.map((item) => typeof item === 'string' ? item : item.name) : [])
          }}
          disabled={disabled}
        />
      </div>
    </div>
  );
}

IndustryAndJobFunction.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  control: PropTypes.object,
  watch: PropTypes.func,
  showTitle: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool
};

export default IndustryAndJobFunction
