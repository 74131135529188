
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useState, useEffect, useRef } from "react";

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from "prop-types";
import axios from "axios";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { CircularProgress, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";

//-----------------------------------------------------------// Internal Imports // ------------------------------

import { SEARCH_TIMEOUT } from "../../utils/common";
import { API } from "../../services/constantService";
import { getMethodWithCancelTokenApi } from "../../services/ApiService";

const CancelToken = axios.CancelToken;
const useStyles = makeStyles(() => ({
  root: {
    top: 10,
    width: 320,
    height: 40,
    fontSize: 13,
    border: "1px solid #DDDDE2",
  },
  inputRoot: {
    height: 37,
    paddingLeft: 10,
    fontSize: 19,
  },
  input: {
    fontSize: 14,
    padding: 10,
  },
  endAdornment: {
    paddingRight: 10,
  },
}));

export default function Search(props) {
  const { placeholder = "Search", ...rest } = props;
  const [options, setOptions] = useState([]);
  const [value, setValue] = useState("");
  const [isLoading, setLoading] = useState(false);
  const classes = useStyles();
  let call = useRef();

  useEffect(() => {
    if (call.current) {
      call.current.cancel();
    }
    if (value.length < 3) {
      setOptions([]);
      setLoading(false);
      return;
    }
    call.current = CancelToken.source();
    setLoading(true);
    const timer = setTimeout(() => {
      getMethodWithCancelTokenApi(
        `${API.picklists_contacts}`,
        { name: value },
        {},
        {},
        call.current.token
      ).then((response) => {
        const { status, data } = response;
        if (status === 200) {
          setOptions(data.data);
        }
        setLoading(false);
      });
    }, SEARCH_TIMEOUT);
    return () => clearTimeout(timer);
  }, [value]);

  return (
    <div style={{ display: "flex" }}>
      <Autocomplete
        {...rest}
        classes={classes}
        freeSolo
        filterOptions={(options) => options}
        loading={isLoading}
        options={options}
        getOptionLabel={(option) => {
          if (typeof option === "string") {
            return option;
          }
          return option.first_name + " " + option.last_name;
        }}
        renderOption={(option) => {
          const jobTitle = option.current_job_title || "";
          const companyName = option.company?.name || "";
          return (
            <div>
              <Typography>
                {option.first_name + " " + option.last_name}
              </Typography>
              <Typography style={{ fontSize: 14, fontStyle: "italic" }}>
                {"(" + jobTitle + " - " + companyName + ")"}
              </Typography>
            </div>
          );
        }}
        onInputChange={(e, val) => setValue(val)}
        value={props.value ? props.value : ""}
        renderInput={(params) => (
          <TextField
            {...params}
            className="search"
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              endAdornment: isLoading ? (
                <CircularProgress color="primary" size={20} />
              ) : (
                params.InputProps.endAdornment
              ),
            }}
          />
        )}
      />
      <span
        onClick={(event) => {
          props.onChange(event, value);
        }}
        className={"search-icon"}
      >
        <SearchIcon />
      </span>
    </div>
  );
}

Search.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
};
