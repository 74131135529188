
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useEffect, useState, useRef } from "react";

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from "prop-types";
import Popup from "reactjs-popup";
import { AgGridReact } from "ag-grid-react";
import { withSnackbar } from "notistack";
import axios from "axios";
import { Typography, Button, InputLabel } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

//-----------------------------------------------------------// Internal Imports // ------------------------------

import Loader from "../../../../components/common/Loader";
import {
  CustomLoadingOverlayComponent,
  PAGE_LIMIT,
  SUCCESS_STATUS_CODE,
} from "../../../Commons/Utils";
import { columnsDef, IdRenderer } from "./utils";
import InputField from "../../../../components/common/InputField";
import { SEARCH_TIMEOUT } from "../../../../utils/common";
import SearchStageSelection from "../../../../components/CreateSearch/Components/SearchInformation/Components/SearchStageSelection";
import {
  API,
  POST,
  WARNING,
  SUCCESS,
  ERROR,
} from "../../../../services/constantService";
import {
  contactDataApi,
  getMethodWithCancelTokenApi,
} from "../../../../services/ApiService";
import {
  unableMessage,
  validateMessage,
  successMessage,
  VALIDATION_MESSAGE,
} from "../../../../services/MessageService";

let gridApi;
const CancelToken = axios.CancelToken;
function AddContactAsClient(props) {
  const {
    header = VALIDATION_MESSAGE.add_contact_as_client,
    buttonText = VALIDATION_MESSAGE.add_contact_as_client,
    uiLabel = "contacts(s)",
    open,
    handleClose,
    contactId,
    enqueueSnackbar,
  } = props;
  const [isLoading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [jobNumber, setJobNumber] = useState(null);
  const [stage, setStage] = useState("Open");
  const [stages, setStages] = useState("Open");
  const call = useRef();

  const dataSource = {
    getRows: async (params) => {
      gridApi.showLoadingOverlay();
      let url = `${API.picklists_search}?limit=${PAGE_LIMIT}&page=${params.endRow / PAGE_LIMIT
        }`;
      const { status, data } = await getMethodWithCancelTokenApi(
        url,
        { stage, searchValue, jobNumber },
        {},
        {},
        call.current.token
      );
      if (status === SUCCESS_STATUS_CODE) {
        params.successCallback(data.data, data.paging.totalCount);
      } else {
        params.failCallback();
      }
      gridApi.hideOverlay();
    },
    rowCount: null,
  };

  useEffect(() => {
    if (call.current) {
      call.current.cancel();
    }
    call.current = CancelToken.source();
    const timer = setTimeout(() => {
      gridApi && gridApi.setDatasource(dataSource);
    }, SEARCH_TIMEOUT);
    return () => clearTimeout(timer);
    //eslint-disable-next-line
  }, [stage, searchValue, jobNumber]);

  const onGridReady = (params) => {
    gridApi = params.api;
    params.api.sizeColumnsToFit();
  };

  const handleAddAsClient = async () => {
    if (gridApi.getSelectedRows().length === 0) {
      const message = validateMessage("search", "select");
      enqueueSnackbar(message, { variant: WARNING });
      return;
    }
    const selectedSearch = gridApi.getSelectedRows()[0];
    setLoading(true);
    let payload;
    if (contactId) {
      payload = {
        searchId: selectedSearch.id,
        contactId,
      };
      const sub_route = "my-clients";
      const { status, data } = await contactDataApi(POST, "", payload, sub_route);
      if (status === 201) {
        const message = successMessage(
          `${uiLabel} added as client associated with`,
          `Search(${selectedSearch.job_title})`
        );
        enqueueSnackbar(message, {
          variant: SUCCESS,
        });
        handleClose();
      } else {
        const message = unableMessage("as client", `add ${uiLabel}`);
        enqueueSnackbar(data?.message || message, {
          variant: ERROR,
        });
      }
    }
    setLoading(false);
  };

  const handleStageChange = (e, data) => {
    setStage(data ? data.field_value : "");
    setStages(data ? data.short_desc : "");
  };

  return (
    <Popup
      className="copy-candidates"
      open={open}
      onClose={handleClose}
      closeOnDocumentClick={false}
      closeOnEscape={false}
    >
      <React.Fragment>
        <Loader show={isLoading} />
        <div className="popup-container">
          <div className="d-flex align-items-center justify-content-between top-bar">
            <Typography className="title">{header}</Typography>
            <CloseIcon className="cursor-pointer" onClick={handleClose} />
          </div>
          <div className="inside-container">
            <div className="d-flex">
              <div
                className="d-flex align-items-center mr-3"
                style={{ flex: "1 0" }}
              >
                <InputLabel
                  className="m-0"
                  focused={true}
                  style={{
                    paddingRight: 5,
                    letterSpacing: "0.09em",
                    fontSize: 14,
                    flex: "0.7 0",
                  }}
                >
                  SEARCH STAGE
                </InputLabel>
                <SearchStageSelection
                  removeClosed={true}
                  value={stages}
                  label=""
                  className="w-50"
                  placeholder="Select Search Stage"
                  onChange={handleStageChange}
                />
              </div>
              <div
                className="d-flex align-items-center ml-3"
                style={{ flex: "1 0" }}
              >
                <InputLabel
                  className="m-0"
                  focused={true}
                  style={{
                    paddingRight: 5,
                    letterSpacing: "0.09em",
                    fontSize: 14,
                    flex: "0.7 0",
                  }}
                >
                  Job Title / Company
                </InputLabel>
                <InputField
                  placeholder="Search"
                  className="w-50"
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="d-flex mt-3">
              <div
                className="d-flex align-items-center mr-3"
                style={{ flex: "1 0" }}
              >
                <InputLabel
                  className="m-0"
                  focused={true}
                  style={{
                    paddingRight: 5,
                    letterSpacing: "0.09em",
                    fontSize: 14,
                    flex: "0.7 0",
                  }}
                >
                  Job Number
                </InputLabel>
                <InputField
                  placeholder="Search"
                  className="w-50"
                  onChange={(e) => {
                    setJobNumber(e.target.value);
                  }}
                />
              </div>
              <div
                className="d-flex align-items-center ml-3"
                style={{ flex: "1 0" }}
              ></div>
            </div>
            <div className="ag-theme-alpine add-contact-section">
              <AgGridReact
                onGridReady={onGridReady}
                enableBrowserTooltips={true}
                defaultColDef={{
                  resizable: true,
                  sortable: false,
                  sortingOrder: ['asc', 'desc', null]
                }}
                cacheBlockSize={PAGE_LIMIT}
                loadingOverlayComponent={"CustomLoadingOverlayComponent"}
                frameworkComponents={{
                  CustomLoadingOverlayComponent,
                  IdRenderer,
                }}
                getRowNodeId={(data) => data.id}
                scrollbarWidth={12}
                suppressHorizontalScroll={false}
                rowModelType={"infinite"}
                datasource={dataSource}
                columnDefs={columnsDef}
                rowSelection={"single"}
              ></AgGridReact>
            </div>
          </div>
        </div>
        <div className="d-flex align-items-center justify-content-end bottom-actions">
          <Button
            variant="contained"
            color="primary"
            onClick={handleAddAsClient}
          >
            {buttonText}
          </Button>
        </div>
      </React.Fragment>
    </Popup>
  );
}

AddContactAsClient.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  contactId: PropTypes.string,
  enqueueSnackbar: PropTypes.func,
  header: PropTypes.string,
  buttonText: PropTypes.string,
  uiLabel: PropTypes.string,
};

export default withSnackbar(AddContactAsClient);
