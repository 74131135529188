
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, {useEffect, useState} from "react";

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import Autocomplete from "@material-ui/lab/Autocomplete";

//-----------------------------------------------------------// Internal Imports // ------------------------------

import {fetchPickList} from "../../../actions";
import InputField from "../../common/InputField";

export default function ReferenceTypeSelection(props) {
  const {label = "", placeholder = "", InputLabelProps, required = false, inputRef, ...rest} = props;

  const [options, setOptions] = useState([]);
  const referenceTypes = useSelector(state => state.commonReducer.referenceTypes)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!referenceTypes) {
      dispatch(fetchPickList('reference-types', 'referenceTypes'))
    } else {
      setOptions(referenceTypes);
    }
  }, [referenceTypes, dispatch])

  return (
    <Autocomplete
      {...rest}
      options={options}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option
        }
        return option.name
      }}
      renderInput={(params) => (
        <InputField
          {...params}
          required={required}
          variant="standard"
          ref={inputRef}
          InputLabelProps={InputLabelProps}
          label={label}
          placeholder={placeholder}
        />
      )}
    />
  )
}

ReferenceTypeSelection.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  InputLabelProps: PropTypes.object,
  required: PropTypes.bool,
  inputRef: PropTypes.func
};
