//----------------------------------------------// In-built Imports // -------------------------------------------------
import React from 'react'

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';

// -------------------------------------------------// Internal Imports// -------------------------------------------------
import BillingInformation from './Components/BillingInformation';
import FeeInformation from './Components/FeeInformation';
import './index.scss';

export default function BillingInfo(props) {



  const { register, setValue, watch, control, getValues, currentValues, isEditing, currencyDetails } = props
  // useEffect(() => {
  //   setTimeout(() => {
  //     handelScroll && handelScroll()
  //   }, 3000)

  // }, []);
  return (
    <div className='input-form'>
      <div id='client-info'>
     
        <BillingInformation
          register={register}
          setValue={setValue}
          watch={watch}
          control={control}
          isEditing={isEditing}
          currentValues={currentValues}
        />
    
        <FeeInformation
          register={register}
          setValue={setValue}
          watch={watch}
          control={control}
          currencyDetails={currencyDetails}
          getValues={getValues}
          isEditing={isEditing}
          currentValues={currentValues}
        />
     
        {/* <div id='adminChargesInformation'>
          <AdminChargesInformation
            register={register}
            setValue={setValue}
            watch={watch}
            currencyDetails={currencyDetails}
            control={control}
            isEditing={isEditing}
          />
        </div>
        <div id='retainersInformation'>
          <RetainersInformation
            register={register}
            setValue={setValue}
            watch={watch}
            currencyDetails={currencyDetails}
            control={control}
            currentValues={currentValues}
            isEditing={isEditing}
          />
        </div> */}
      </div>

    </div>
  )
}

BillingInfo.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  watch: PropTypes.func,
  control: PropTypes.object,
  getValues: PropTypes.func,
  currentValues: PropTypes.object,
  isEditing: PropTypes.bool,
  currencyDetails: PropTypes.object,
  handelScroll: PropTypes.func
}
