import React, { useState } from 'react'
import Popup from "reactjs-popup";
import CloseIcon from '@material-ui/icons/Close'
import PropTypes from "prop-types";
import { GALAXY_GUIDE_URL } from '../../services/constantService';

const GalaxyGuidePopUp = (props) => {

  const header = "Galaxy Guide"
  const [open, setOpen] = useState(true);
  const { onClose } = props

  const handleClose = () => {
    setOpen(false)
    onClose && onClose();
  }

  return (
    <Popup
      overlayStyle={{ zIndex: '2000 !important' }}
      open={open}
      modal
      closeOnDocumentClick={false}
      closeOnEscape={false}
      className="confirmation-popup"
    >
      <>
        <div className="confirmation-content d-flex" style={{ padding: '10px' }}>
          <div className="flex-grow-1 d-flex flex-column pr-5">
            <div className="confirmation-header" style={{ fontSize: 18, color: '#5c52c6' }}>{header}</div>
          </div>
          <span className="close-icon d-flex align-items-center" onClick={handleClose}>
            <CloseIcon fontSize="bold" />
          </span>
        </div>
        <embed src={GALAXY_GUIDE_URL}
          style={{
            position: 'relative',
            display: 'inline-block',
            height: '80vh',
            width: "1000px"
          }}
        />
      </>
    </Popup>
  )
}

GalaxyGuidePopUp.propTypes = {
  onClose: PropTypes.func,
}

export default GalaxyGuidePopUp;


