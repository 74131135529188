
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useState } from "react";

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from "prop-types";
import { useSnackbar, withSnackbar } from "notistack";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import {Typography, Button } from "@material-ui/core";
import Popup from "reactjs-popup";
import CloseIcon from '@material-ui/icons/Close';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import {
  columnDefs,
  PAGE_LIMIT,
  SUCCESS_STATUS_CODE
} from "./utils";
import { CustomLoadingOverlayComponent } from '../../../Containers/Commons/Utils';
import { CompanyNameRenderer } from "../../../Containers/Companies/utils";
import { DateTimeRenderer, DateRenderer } from "../../../Containers/Commons/Utils";
import AthenaPopUp from '../../ActivityLog/Components/Athena'
import { GET, WARNING } from "../../../services/constantService";
import { contactDataApi } from "../../../services/ApiService";
import { notFoundMessage } from "../../../services/MessageService";
import { useDispatch, useSelector } from "react-redux";
import { contactActions } from "../../../store/contactSlice";
import { Link } from "react-router-dom";


export const SearchIdRenderer = params => {
  return (
    <Link
      to={`/searches/${params?.value?.id}/active-candidates`}
      target="_blank"
      rel="noopener noreferrer">
      <Typography className="text-link" color="primary">
        {params.value?.job_title}
      </Typography>
    </Link>
  );
};

let gridApi;
function Athena(props) {
  const { contact } = props;
  const [open, setOpen] = useState(false)
  const [rowCount, setRowCount] = useState(0)
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const contact_athena = useSelector(state => state.contactReducer.contact_athena)
  const dataSource = {
    getRows: async params => {
      if (!contact_athena || params.sortModel.length) {
        gridApi.showLoadingOverlay();
        let sub_route = `assessments?limit=${PAGE_LIMIT}&page=${params.endRow / PAGE_LIMIT}`
        if (params.sortModel.length) {
          sub_route = sub_route.concat(`&sortOn=${params.sortModel[0].colId}&sortType=${params.sortModel[0].sort.toUpperCase()}`)
        }
        const { status, data } = await contactDataApi(GET, contact.id, '', sub_route);
        if (status === SUCCESS_STATUS_CODE) {
          dispatch(contactActions.updateContactAthena(data));
          if (data?.paging?.totalCount === 0) {
            const message = notFoundMessage("records")
            enqueueSnackbar(message, { variant: WARNING })
          }
          params.successCallback(data.data, data.paging.totalCount);
          setRowCount(data.paging.totalCount);
        } else {
          params.failCallback();
        }
        gridApi.hideOverlay();
      }
      else {
        if (contact_athena?.paging?.totalCount === 0) {
          const message = notFoundMessage("records")
          enqueueSnackbar(message, { variant: WARNING })
        }
        params.successCallback(contact_athena.data, contact_athena.paging.totalCount);
        setRowCount(contact_athena.paging.totalCount);
      }
    },
    rowCount: null
  };

  const onGridReady = params => {
    gridApi = params.api;
  };

  const NameRenderer = params => {
    return <CompanyNameRenderer company={params.data?.company} />;
  };

  const resetSort = async () => {
    gridApi.setSortModel(null);
  }

  return (
    <React.Fragment>
      <div className='d-flex justify-content-between'>
        <div style={{ padding: "20px 20px 0 20px" }}>



          <Button color="primary" onClick={() => { setOpen(true) }} style={{ textDecoration: 'underline' }}>Athena Invite Details</Button>
        </div>
        <div className='d-flex  align-items-center' style={{ padding: "20px 20px 0 20px" }}>
          <div
            className="action-container"
            style={{ minWidth: 0 }}
            onClick={() => resetSort()}
          >
            <span className="action-text" >
              Reset Sort
            </span>
          </div>
          <Typography>Total count: {rowCount}</Typography>
        </div>
      </div>
      <Popup open={open} className="athena-invite athena-invite-popup" modal onClose={() => { setOpen(false) }} closeOnDocumentClick={false} closeOnEscape={false}>
        <>
          <div className="d-flex align-items-center justify-content-between popup-header quick-add-header" style={{ background: '#c3c3c35c' }}>
            <h4 className="mr-5">Athena Invite Details</h4>
            <span className="action-icon cursor-pointer" onClick={() => { setOpen(false) }}>
              <CloseIcon />
            </span>
          </div>
          <AthenaPopUp contactDetails={contact} />
        </>
      </Popup>
      <div id="myGrid" className="ag-theme-alpine">
        <AgGridReact
          onGridReady={onGridReady}
          enableBrowserTooltips={true}
          defaultColDef={{
            minWidth: 20,
            resizable: true,
            sortable: true,
            sortingOrder: ['asc', 'desc', null]
          }}
          tooltipShowDelay={0}
          scrollbarWidth={12}
          suppressHorizontalScroll={false}
          cacheBlockSize={PAGE_LIMIT}
          loadingOverlayComponent={"CustomLoadingOverlayComponent"}
          frameworkComponents={{
            SearchIdRenderer,
            CustomLoadingOverlayComponent,
            NameRenderer: NameRenderer,
            DateTimeRenderer,
            DateRenderer
          }}
          rowModelType={"infinite"}
          datasource={dataSource}
          columnDefs={columnDefs}
          paginationPageSize={20}
          colResizeDefault={"shift"}
        />
      </div>
    </React.Fragment>
  );
}

Athena.propTypes = {
  contact: PropTypes.object
};

export default withSnackbar(Athena);
