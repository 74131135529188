//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useRef } from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from "prop-types";

// -------------------------------------------------// External Imports// -------------------------------------------------

import Autocomplete from "@material-ui/lab/Autocomplete";
import { TextField } from "@material-ui/core";
import { Controller } from "react-hook-form";


export default function TypeSelection(props) {
  const { control, Disabled, getType } = props
  const typeRef = useRef()
  const setType = (data) => {
    getType(data)
  }
  return (
    <Controller
      name={"type"}
      disabled={Disabled}
      as={
        <Autocomplete
          className='input'
          renderInput={(params) => (
            <TextField
              {...params}
              inputRef={typeRef}
              variant="standard"
              InputLabelProps={{ focused: true }}
              // required={true}
              label={<label>Type<span className="MuiInputLabel-asterisk"> *</span></label>}
              name='type'
              placeholder="Type"
            />
          )}
          options={['Contacts', 'Searches']}
        />
      }
      control={control}
      onFocus={() => {
        typeRef.current.focus()
      }}
      onChange={([, data]) => {
        setType(data);
        return data
      }}
      // rules={{ required: true }}
    />
  )
}

TypeSelection.propTypes = {
  control: PropTypes.object,
  Disabled: PropTypes.bool,
  DefaultValue: PropTypes.string,
  getType: PropTypes.func
};
