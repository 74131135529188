//----------------------------------------------// In-built Imports // -------------------------------------------------
import React from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from "prop-types";
import { Button, Typography } from "@material-ui/core";
import { useFieldArray } from "react-hook-form";
import CloseIcon from "@material-ui/icons/Close";
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AddIcon from '@material-ui/icons/Add';
//----------------------------------------------// Internal Imports // -------------------------------------------------
import InputField from "../../../../common/InputField";

export default function Notes(props) {
  const { register, control } = props
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'notes'
  })

  return (

    <div className='section-content flex-grow-1' id='clientInformation'>
      <ExpansionPanel defaultExpanded={true} className="w-100 mb-5" style={{ background: '#fff', color: '#fff' }}>
        <ExpansionPanelSummary
          expandIcon={
            <ExpandMoreIcon style={{ color: "#fff" }} />
          }
          aria-controls="panel1a-content"
          style={{ background: '#2a364c' }}
        >
          <Typography >Notes</Typography>
        </ExpansionPanelSummary>

        <ExpansionPanelDetails className='d-flex flex-column p-0 flex-grow-1 pt-2' >
          {fields.map((item, index) =>

            <div key={item.id} className="mx-2">

              <div className='section-content team-information-table flex-grow-1'>
                <div className="d-flex align-items-center table-header p-0" style={{ color: "#fff" }} >
                  <Typography className="m-0" style={{ background: '#2a364c', color: '#fff', border: "6px solid #2a364c" }} >
                    Note {index + 1}
                  </Typography>
                  {fields.length > 1 && <CloseIcon cursor="pointer" onClick={() => remove(index)} style={{ width: 18, height: 18, marginRight: 15, color: "#fff" }} />}
                </div>
                <div className="d-flex contact-details-row ">
                  <div className="d-flex intersection-page-view w-100">
                    <InputField
                      InputProps={{
                        style: {
                          fontWeight: 500,
                          lineHeight: 19
                        }
                      }}
                      ref={register()}
                      className='input-form-field'
                      placeholder="Heading Text Here"
                      InputLabelProps={{ focused: true }}
                      name={`notes[${index}].title`}
                      variant="outlined"
                    />
                  </div>
                </div>
                <div className="d-flex contact-details-row ">
                  <div className="d-flex intersection-page-view w-100">
                    <InputField
                      InputProps={{
                        style: {
                          fontSize: 14
                        }
                      }}
                      ref={register()}
                      className='input-form-field'
                      placeholder='Text Here'
                      InputLabelProps={{ focused: true }}
                      name={`notes[${index}].description`}
                      variant="outlined"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className='text-start'> <Button
            variant={"text"}
            startIcon={<AddIcon style={{ color: "red" }} />}
            onClick={() => {
              append({})
            }}
            className="button-text-capitalized"
            style={{ width: "175px" }}
          >
            <span style={{
              fontWeight: "600",
              fontSize: "13px",
              fontStyle: "italic",
            }}>Add Note</span>
          </Button>
          </div>

        </ExpansionPanelDetails>
      </ExpansionPanel>

    </div>
  )
}

Notes.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  watch: PropTypes.func,
  control: PropTypes.object,
  isEditing: PropTypes.bool
}
