
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { Component } from 'react'

//-----------------------------------------------------------// External Imports // ------------------------------

import { connect } from "react-redux";
import { withRouter } from 'react-router-dom'
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { withSnackbar } from "notistack";
import { saveAs } from 'file-saver';
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/dist/styles/ag-grid.css'
import { Button, Checkbox, Typography } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import CloseIcon from "@material-ui/icons/Close";

//-----------------------------------------------------------// Internal Imports // ------------------------------

import GenericCellEditor from '../Commons/CellEditors/GenericCellEditor'
import PhoneEditor from '../Commons/CellEditors/PhoneEditor'
import Search from "./Search";
import { CompensationRenderer, sortByChronologicalOrder } from '../../utils/common'

import Loader from '../../../src/components/common/Loader';
import {
  columnDefs,
  SUCCESS_STATUS_CODE,
  PAGE_LIMIT,
  CustomLoadingOverlayComponent,
  EmailRenderer,
  PhoneRenderer,
  ContactNameRenderer,
  getQueryParamsString,
  ResumeRenderer,
  defaultColumns,
  cleanupQuery,
  addAsMyBDTarget,
  getFilterParamString,
  getSelectedContactIds,
  getQueryPayload,
  fieldDefinitions,
  acquire as acquireUtil,
  checkContactFetchingStatus,
  checkRecordCanDelete
} from './utils';
import {
  DateRenderer,
  DateTimeRenderer,
  saveColumnStateToLocalStorage,
  loadColumnStateFromLocalStorage,
  LinkedInRenderer, addContactsToWorkbench/*, processAddContactsResponse*/
} from '../Commons/Utils';

import '../Commons/index.scss'
import './index.scss'
import Query from "./components/Query";
import AdvancedQuery from "../Commons/AdvancedQuery";
import CopyCandidatesToSearch from '../Searches/AllCandidates/CopyCandidatesToSearch'
import ActivityHistories from '../../components/ActivityLog/Components/GeneralInfo/Components/ActivityHistory/ActivityHistories';
import ActionsPopover from './ActionsPopover'
import { SCROLL_TIMEOUT } from "../../utils/common";
import { CompanyNameRenderer } from '../Companies/utils'
import QuickAddContact from '../Searches/AllCandidates/Components/QuickAddContact'
import AddContactAsClient from './components/AddContactAsClient';
import ColumnFilter from "../Commons/ColumnFilter";
import DeletePopup from "../Commons/DeletePopup";
import CustomFilter from "../Commons/CustomFilter";
import { dataSourceUtils } from "../../utils/dataSource"
import AddContactToWorkbench from "../Commons/AddContactToWorkbench";
import RemoveContactFromWorkbench from "../Commons/RemoveContactFromWorkbench";
import AddWorkbench from "../../components/AddWorkbench";
import AthenaPopover from "../Searches/AllCandidates/Components/AthenaPopover";
import { formatDate } from "../../utils/date";
import AthenaInvitePopUpContact
  from "../../../src/components/ViewSearch/Components/SFPA/Components/ClientAppAccess/AthenaInvitePopUpContact";
import { API, BLOB, EXPORT_CONTACT_LIMIT, POST, ERROR, WARNING, DELETE_CONFIRMATION_POPUP_MESSAGE, LINKEDIN_SCRAPER } from '../../services/constantService';
import LinkedInDialog from "../Commons/LinkedInDialog";
import { fetchUserList } from '../../actions';
import { acquireLockApi, contactDataApi, releaseLockApi/*, workbench_data*/, /* contactDataRefreshApi */ } from '../../services/ApiService';
import { VALIDATION_MESSAGE, notFoundMessage } from '../../services/MessageService';
import EmploymentHistoryConfirmationDialog from '../Commons/EmploymentHistoryConfirmationDialog';
import { cleanupCompanyDetails } from '../../components/AddContact/utils';
// import PdlContactsMergPopup from "../Commons/PdlContactsMergPopup";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      queryData: {},
      isRemove: false,
      showQuery: false,
      isRowSelected: false,
      isAllRowsSelected: false,
      contactIds: [],
      activeContact: false,
      minimizeQuery: true,
      rowCount: 0,
      contacts: [{}],
      isQuickAddOpen: false,
      isAddToWorkbenchOpen: false,
      uiSelector: false,
      masterQuery: '',
      isAdvanceQuery: false,
      isClassicQuery: false,
      quickQuery: '',
      searchValue: '',
      isAthenaPopupOpen: false,
      filterModel: {},
      linkedPopup: false,
      linkedInValue: {},
      isLoading: false,
      exportSingleValue: false,
      advanceSelector: false,
      showJobTitleChangedPopup: false,
      employmentHistoryMessageEndYearDate: null,
      isUpdateEmploymentHistory: false,
      skipCheckJobTitle: false,
      currentContact: null,
      newContactData: null,
      showFilterCount: 0,
      pdlData: [],
      isDeleteContactOpen: false,
      isQuerySelector: false
    }
    this.enqueueSnackbar = props.enqueueSnackbar;
    this.closeSnackbar = props.closeSnackbar;
    this.update = this.update.bind(this);
  }

  isSelectedFromList = false;

  componentWillUnmount() {
    const columnApi = this.columnApi
    saveColumnStateToLocalStorage('contactColumns', { columnApi })
    this.props.dispatch({ type: 'resetLinkedinScraperEvents' })
  }

  linkedInPopupHandler = (props) => {
    this.setState({ linkedPopup: true, linkedInValue: props.value })
  }

  LinkedInRenderer = (params) => {
    return (
      <LinkedInRenderer params={params} linkedInPopup={this.linkedInPopupHandler} />
    )
  }

  LinkedInCloseHandler = () => {
    this.setState({ linkedPopup: false });
  }

  componentDidMount() {
    this.setState({ uiSelector: false, quickQuery: '', searchValue: '' })
    if (this.props && this.props.location && this.props.location.state && typeof (this.props.location.state) === 'string') {
      this.setState({ masterQuery: this.props.location.state })
    }
    if (!this.props.users) {
      this.props.fetchUserList('users')
    }
    this.props.dispatch({ type: 'resetLinkedinScraperEvents' })
  }

  componentDidUpdate() {
    if (this.state.skipCheckJobTitle) {
      this.handleUpdateJobHistory()
    }
    // if (this.props.socket) {
    //   console.log('socket changes found:: ', this.props.socket)
    //   this.props.socket.on(LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_COMPLETED, (msg, obj) => {
    //     console.log('socket event found:: ', LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_COMPLETED, msg, obj)
    //     let contactFound
    //     this.gridApi.forEachNode(node => {
    //       console.log('node', node.data?.id)
    //       console.log('socket obj::', obj?.contact?.id)
    //       if (node.data?.id === obj?.contact?.id) {
    //         contactFound = node
    //       }
    //     })
    //     console.log('socket event contact found:: ', contactFound)
    //     if (contactFound) {
    //       contactFound.setData(obj.contact)
    //       this.gridApi.refreshCells({ force: true });
    //     }
    //   })
    // }
    if (this.props[LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_COMPLETED]) {
      const event = this.props[LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_COMPLETED];
      let contactFound
      this.gridApi.forEachNode(node => {
        if (node.data?.id === event.obj?.contact?.id) {
          contactFound = node
        }
      })
      if (contactFound) {
        contactFound.setData(event.obj.contact)
        this.gridApi.refreshCells({ force: true });
      }
      this.props.dispatch({ type: LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_COMPLETED, payload: undefined })
    }
    if (this.props.refreshContact) {
      this.gridApi.onFilterChanged();
    }
  }

  getURLs = {
    listURl: `${API.contact}`,
    classicURl: `${API.contact}`,
    quickQuery: `${API.contact}`,
    advanceURL: API.master_query
  };

  dataSource = {
    getRows: async (params) => {
      try {
        let value = localStorage.getItem('contactColumns')
        if (value === 'true') {
          this.setState({ showQuery: true, minimizeQuery: false })
          // this.resetFilter();
          // this.resetSort();
          params.successCallback([], 0);
          this.setState({ rowCount: 0 });
          params.filterModel && this.setState({ filterModel: params.filterModel });
          this.setState({ showFilterCount: Object.keys(this.state.filterModel).length })
          return;
        }
        params.filterModel && this.setState({ filterModel: params.filterModel });
        this.setState({ showFilterCount: Object.keys(this.state.filterModel).length })
        //  this.gridApi.deselectAll();
        if (params.filterModel && Object.keys(params.filterModel).length > 0)
          this.gridApi.deselectAll();
        const thisValue = { ...this };
        this.setState({ isLoading: true })
        if (this.state.masterQuery) {
          this.handleSearchChange('', this.state.masterQuery)
        }
        else {
          /* this.gridApi.showLoadingOverlay(); */
          const obj = {
            params: params,
            context: thisValue,
            pageLimit: PAGE_LIMIT,
            url: this.getURLs,
            contactsScreen: true,
          }
          const getData = await dataSourceUtils(obj, getFilterParamString, getQueryParamsString);
          const { status, data } = getData;
          if (status === SUCCESS_STATUS_CODE) {
            if (data?.paging) {
              if (data?.paging?.totalCount === 0) {
                const message = notFoundMessage("records")
                this.props.enqueueSnackbar(message, { variant: WARNING })
              }
              params.successCallback(data.data, data.paging.totalCount)
              this.setState({ rowCount: data.paging.totalCount, contacts: data.data })
            } else if (Array.isArray(data)) {
              const message = notFoundMessage("records")
              if (data.length === 0) { this.props.enqueueSnackbar(message, { variant: WARNING }) }
              params.successCallback(data, data.length)
              this.setState({ rowCount: data.length, contacts: data })
            } else if (Object.keys(data) && Object.keys(data)?.length) {
              const message = notFoundMessage("records")
              if (data.length === 0) { this.props.enqueueSnackbar(message, { variant: WARNING }) }
              params.successCallback([data], 1)
              this.setState({ rowCount: 1, contacts: [data] })
            }
            this.state.isAllRowsSelected && this.setSelectAllRows(this.state.isAllRowsSelected);
          } else {
            params.failCallback();
          }
          this.setState({ isLoading: false })
          this.gridApi.hideOverlay()
        }
      } catch (e) {
        console.log("Error found in getRows::", e);
      }
      // localStorage.removeItem('contactColumns')
    },
    rowCount: null
  };

  classicQuery = (queryData) => {
    this.setState({ isAdvanceQuery: false, isClassicQuery: true, quickQuery: queryData })
    this.handleQuery(queryData)
    localStorage.removeItem('contactColumns');
  }

  resetFilter = async () => {
    this.gridApi.setFilterModel(null);
    const customFilterField = this.columnApi.columnController.columnDefs;
    customFilterField.map(item => {
      if (item.filter === 'CustomFilter') {
        this.gridApi.destroyFilter(item.field);
      }
    })
  }

  resetSort = async () => {
    this.gridApi.setSortModel(null);
  }

  AdvancedQuery = (queryData) => {
    this.setState({ isAdvanceQuery: true, isClassicQuery: false, quickQuery: '', advanceSelector: true })
    this.handleQuery(queryData)
    localStorage.removeItem('contactColumns');
  }

  handleAthenaPopupClose = () => {
    this.setState({ isAthenaPopupOpen: false });
    this.gridApi.onFilterChanged()
  }

  AthenaRenderer = (params) => {
    const status = checkContactFetchingStatus(params)
    if (status) return status;
    const handleInvite = () => {
      this.setState({
        isAthenaPopupOpen: true,
        athenaData: {
          candidate: { id: params.data?.candidates },
          contact: params.data
        }
      })
    }
    if (params.data) {
      if (params.data.athena_completion_date || params.data.athena_invitation_sent_on) {
        return (
          <AthenaPopover contactDetails={params.data} handleInvite={handleInvite}>
            <Typography style={{ fontSize: 'inherit', cursor: 'pointer' }} color="primary">
              {params.data.athena_completion_date ?
                `Completed on: ${formatDate(params.data.athena_completion_date)}`
                :
                `Invited on: ${formatDate(params.data.athena_invitation_sent_on)}`
              }
            </Typography>
          </AthenaPopover>
        )
      } else if (params.data && params.data.candidates && params.data.candidates.length > 0) {
        return (
          <Button
            className='invite-button athena-button'
            variant='contained'
            color='primary'
            onClick={handleInvite}
          >
            Invite to Athena
          </Button>
        )
      } else {
        return (
          <Typography style={{ fontSize: 'inherit', cursor: 'pointer' }} color="primary">Contact Is Not Associated With Any Search</Typography>
        )
      }

    }
    return null
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    const columnApi = params.columnApi
    const gridApi = params.api
    loadColumnStateFromLocalStorage('contactColumns', { columnApi, gridApi })
    this.setState({ isGridReady: true });
  };

  handleSearchChange = (event, value) => {
    this.setState({ isAdvanceQuery: false, isClassicQuery: false, })
    this.isSelectedFromList = false
    if (this.state.masterQuery) {
      this.setState({ masterQuery: '' })
    }
    if (typeof value === 'string') {
      this.setState({ quickQuery: value, searchValue: value, exportSingleValue: false })
    } else if (value && value.id) {
      this.isSelectedFromList = true;
      this.setState({ quickQuery: value.id, searchValue: value.name, exportSingleValue: true })
    }

    this.setState({ quickQuery: value?.id ? value?.id : (value ? value : '') }, () => {
      this.gridApi.onFilterChanged();

    })
    this.resetFilter();
    this.resetSort();
  };

  handleQuery = (queryData) => {
    if (this.state.isAllRowsSelected === true) {
      this.setSelectAllRows(false)
      this.setState({ isAllRowsSelected: true })
    }
    else {
      this.gridApi.forEachNode(node => {
        node.setSelected(false);
      });
    }
    this.setState({ queryData: cleanupQuery(queryData) });
    this.gridApi.onFilterChanged()
  };

  handleChangeQuery = async () => {
    await this.resetSort();
    await this.resetFilter();
    this.gridApi.onFilterChanged();
    this.isSelectedFromList = false
    this.setState({ showQuery: true, minimizeQuery: false })
    localStorage.setItem('contactColumns', true)
    this.setState({ masterQuery: '', quickQuery: '', searchValue: '' })

    // setTimeout(() => {
    //   window.location.reload(false);
    // }, 3000);
  };

  getParams = (selectedRows) => {
    // const selectedRows = this.gridApi.getSelectedRows();
    // if (this.state.isAllRowsSelected || selectedRows.length === 0) {
    //   return selectedRows.map(row => `id=${row.id}`).join('&')
    //   // return getQueryParamsString(this.state.queryData).substring(1)
    // } else {
    //   return selectedRows.map(row => `id=${row.id}`).join('&');
    // }
    return selectedRows.map(row => `id=${row.id}`).join('&');
  }

  exportCallback = async () => {
    const selectedRows = this.gridApi.getSelectedRows();
    // if ((this.state.isAllRowsSelected && this.state.rowCount > EXPORT_CONTACT_LIMIT) || (selectedRows.length > EXPORT_CONTACT_LIMIT)) {
    //   const message = exportMessage(`${EXPORT_CONTACT_LIMIT}`, "Contacts")
    //   this.props.enqueueSnackbar(message, { variant: ERROR })
    // }
    // else {
    if (selectedRows.length > 0) {
      let sub_route = `export-as-excel?`;
      if (this.state.quickQuery && !this.state.isClassicQuery) {
        if (this.state.exportSingleValue) {
          sub_route = `${sub_route}&id=${this.state.quickQuery}&quickSearch=true`;
        } else {
          sub_route = `${sub_route}&searchValue=${this.state.quickQuery}&quickSearch=true`;
        }
      }
      else if (this.state.isClassicQuery) {
        const paramsString = getQueryParamsString(this.state.queryData)
        sub_route = `${sub_route}${paramsString}&isClassicQuery=${this.state.isClassicQuery}`;
      }
      if (Object.keys(this.state.filterModel).length) {
        sub_route = sub_route.concat(getFilterParamString(this.state.filterModel, this.state.showQuery || this.state.quickQuery));
        sub_route = `${sub_route}&filter=true`
      }
      let id = []
      if (!this.state.isAllRowsSelected) {
        selectedRows.map((data) => id.push(data.id));
      }
      const columnHeaders = this.columnApi.getAllDisplayedColumns().map(column => column.getColDef().headerName);
      this.gridApi.showLoadingOverlay()
      const payload = { headers: { columnHeaders: columnHeaders, selectedRows: { id: id }, type: 'Contacts', allRowSelected: this.state.isAllRowsSelected, limit: !this.state.quickQuery && !Object.keys(this.state.filterModel).length && !this.state.isClassicQuery && !this.state.advanceSelector ? PAGE_LIMIT : EXPORT_CONTACT_LIMIT } };
      if (this.state.advanceSelector) {
        payload.headers.queryData = this.state.queryData
      }
      let { status, data, headers } = await contactDataApi(POST, '', payload, sub_route, BLOB);
      if (status === 200) {
        let fileName = 'Contacts.xlsx';
        let fileNameHeader = headers['content-disposition'].split('"');
        if (fileNameHeader && fileNameHeader.length > 2) {
          fileName = fileNameHeader[1];
        }
        saveAs(new File([data], fileName));
      }
      else {
        this.props.enqueueSnackbar(VALIDATION_MESSAGE.export_fail, { variant: ERROR })
      }
      this.gridApi.hideOverlay()
    } else {
      this.props.enqueueSnackbar(VALIDATION_MESSAGE.no_record_selected, { variant: ERROR })
    }
    // }
  };

  setSelectAllRows = (isAllRowsSelected) => {
    this.setState({ isAllRowsSelected })
    this.gridApi.forEachNode(node => {
      node.setSelected(isAllRowsSelected);
    });
  }

  handleChange = () => {
    this.setSelectAllRows(!(this.state.isAllRowsSelected))
  };

  HeaderCheckbox = () => {
    return (
      <Checkbox
        style={{ padding: 0, width: 16, height: 16, color: "white" }}
        size="small"
        color="primary"
        onChange={this.handleChange}
      />
    )
  }

  NameRenderer = (params) => {
    return <ContactNameRenderer params={params} handleAddToSearch={this.handleAddToSearch} />
  }

  CompanyNameRenderer = (params) => {
    const status = checkContactFetchingStatus(params)
    if (status) return status;
    return <CompanyNameRenderer company={params?.data?.company} />
  }

  AddContactToRenderer = (params) => {
    const status = checkContactFetchingStatus(params)
    if (status) return status;
    const list = [
      {
        label: 'To Search',
        onClick: () => this.handleAddToSearch(params?.data?.id)
      },
      {
        label: 'To Workbench',
        onClick: () => this.handleAddToWorkbench(params.data?.id)
      },
      {
        label: 'As BD Target',
        onClick: () => addAsMyBDTarget(params?.data, this.props.userData, this.gridApi, this.props.enqueueSnackbar)
      },
      {
        label: 'As Client',
        onClick: () => this.handleAddAsClient(params?.data?.id)
      }
    ]
    return params.data ? <ActionsPopover list={list} /> : null
  }

  handleAddToSearchBySelection = () => {
    const contactIds = getSelectedContactIds(this.gridApi)
    this.setState({ isAddToSearchOpen: true, contactIds })
  }

  handleAddToSearch = (contactId) => {
    this.setState({ isAddToSearchOpen: true, contactIds: [contactId] })
  }

  handleAddAsClient = (contactId) => {
    this.setState({ isAddAsClientOpen: true, contactIds: [contactId] })
  }

  handleAddAsClientClose = () => {
    this.setState({ isAddAsClientOpen: false, contactIds: [] })
  }

  handleAddToSearchClose = () => {
    this.setState({ isAddToSearchOpen: false, contactIds: [] })
  }

  handleQuickAddContactClose = (contact) => {
    this.setState({ isQuickAddOpen: false })
    if (contact) {
      this.gridApi.onFilterChanged();
    }
  }

  showContactActivityHistory = (contact) => {
    this.setState({
      activeContact: contact
    })
  }

  ActionsRenderer = (params) => {
    const status = checkContactFetchingStatus(params)
    let canDelete = checkRecordCanDelete(params, status);
    if (status === LINKEDIN_SCRAPER.PROCESSING_LABEL && !canDelete) return status;

    let list;
    if (status === LINKEDIN_SCRAPER.FAILED_LABEL || canDelete) {
      list = [
        {
          label: "Delete Record",
          onClick: () => {
            this.setState({ isDeleteContactOpen: true, contactId: params.data?.id })
          }
        }
      ];
    } else {
      list = [
        {
          label: "Log An Activity",
          onClick: async () => {
            this.setState({ isRemove: false })
            this.acquire(params?.data?.id)
          }
        },
        {
          label: "Remove From Workbench",
          onClick: () => this.setState({ isRemoveFromWorkbenchOpen: true, contactId: params.data?.id })
        },
        {
          label: "Show History",
          onClick: params.colDef ? () => params.colDef.cellRendererParams.showActivityHistory(params.data) : null,
        },
        {
          label: "Delete Record",
          onClick: () => {
            this.setState({ isRemove: true })
            this.acquire(params.data?.id)
          }
        }
      ]
    }
    return <ActionsPopover list={list} />;
  };

  acquire = async (contactId) => {
    let { status, data } = await acquireLockApi(contactId);
    if (status && status === 200 && data.message === undefined) {
      if (this.state.isRemove) {
        this.setState({ isDeleteOpen: true, contactId: contactId })
        this.setState({ isRemove: false })
      }
      else {
        await releaseLockApi(contactId)
        this.props.history.replace(
          `/contacts/${contactId}/log-an-activity/general-info`,
          {
            sortModel: this.state.sortModel,
            fromContact: true,
            previousPath: this.props.location.pathname
          }
        )
      }
    } else {
      //if (data.isAdmin) {
      this.enqueueSnackbar(data.message, {
        variant: WARNING,
        // eslint-disable-next-line react/display-name
        action: key => (
          <>
            <Button
              size='small'
              variant='text'
              color='inherit'
              onClick={async () => {
                this.closeSnackbar(key)
                this.releaseLock(contactId)
              }}
            >
              Force Unlock
            </Button>
            <Button
              size='small'
              variant='text'
              color='inherit'
              onClick={() => {
                this.closeSnackbar(key)
                this.props.history.replace(
                  `/contacts/${contactId}/log-an-activity/read-only/general-info`,
                  {
                    sortModel: this.state.sortModel,
                    fromContact: true,
                    previousPath: this.props.location.pathname
                  }
                )
              }}
            >
              Open Read Only View
            </Button>
            <Button
              size='small'
              variant='text'
              color='inherit'
              onClick={async () => {
                this.closeSnackbar(key)
              }}
            >
               CLOSE
            </Button>
          </>
        )
      });
      /* }
      else {
        this.enqueueSnackbar(data.message, {
          variant: WARNING,
          action: key => (
            <Button
              size='small'
              variant='text'
              color='inherit'
              onClick={() => {
                this.closeSnackbar(key)
                this.props.history.replace(
                  `/contacts/${contactId}/log-an-activity/read-only`,
                  {
                    sortModel: this.state.sortModel,
                    fromContact: true,
                    previousPath: this.props.location.pathname
                  }
                )
              }}
            >
              Open Read Only View
            </Button>
          )
        });
      } */
    }
  }
  releaseLock = async (contactId) => {
    await releaseLockApi(contactId)
    this.acquire(contactId)
  }
  handleDeleteClose = (deleted) => {
    this.setState({ isDeleteOpen: false, contactId: null })
    if (deleted) {
      this.gridApi.onFilterChanged()
    }
  }
  handleDeleteContactClose = (deleted) => {
    this.setState({ isDeleteContactOpen: false, contactId: null })
    if (deleted) {
      this.gridApi.onFilterChanged()
    }
  }
  update(nextState) {
    this.setState({ isRemove: nextState });
  }
  onSortChanged = (params) => {
    const sortModel = params.api.getSortModel();
    this.setState({ sortModel })
    this.saveColumnStateForSort()
  }

  onFirstDataRendered = () => {
    const locationState = this.props.location.state
    if (locationState) {
      const sortModel = locationState.sortModel
      if (sortModel) {
        this.gridApi.setSortModel(sortModel);
      }
    }
  }

  saveColumnState = async () => {
    try {
      this.gridApi && this.gridApi.showLoadingOverlay()
      const columnApi = this.columnApi;
      const gridApi = this.gridApi
      await saveColumnStateToLocalStorage('contactColumns', { columnApi, gridApi })
      this.gridApi && this.gridApi.hideOverlay()
    } catch (e) {
      console.log("Error found in saveColumnState::", e);
    }
  }

  saveColumnStateForFilter = async () => {
    try {
      this.gridApi && this.gridApi.showLoadingOverlay()
      const columnApi = this.columnApi;
      const gridApi = this.gridApi
      await saveColumnStateToLocalStorage('contactColumns', { columnApi, gridApi }, true, false)
      this.gridApi && this.gridApi.hideOverlay()
    } catch (e) {
      console.log("Error found in saveColumnState::", e);
    }
  }

  saveColumnStateForSort = async () => {
    try {
      this.gridApi && this.gridApi.showLoadingOverlay()
      const columnApi = this.columnApi;
      const gridApi = this.gridApi
      await saveColumnStateToLocalStorage('contactColumns', { columnApi, gridApi }, false, true)
      this.gridApi && this.gridApi.hideOverlay()
    } catch (e) {
      console.log("Error found in saveColumnState::", e);
    }
  }

  handleAddToWorkbench = (contactId) => {
    this.setState({ isAddToWorkbenchOpen: true, contactIds: [contactId] })
  }

  handleAddToWorkbenchClose = () => {
    this.setState({ isAddToWorkbenchOpen: false })
  }

  handleRemoveFromWorkbenchClose = () => {
    this.setState({ isRemoveFromWorkbenchOpen: false })
  }

  handleAddContactsToWorkbench = () => {
    const contactIds = getSelectedContactIds(this.gridApi)
    this.setState({ isAddToWorkbenchOpen: true, contactIds })
  }

  handleCreateWorkbench = () => {
    this.setState({ isCreateWorkbenchOpen: true })
  }

  handleCreateWorkbenchClose = async (workbenchId) => {
    this.setState({ isCreateWorkbenchOpen: false })
    if (workbenchId) {
      this.gridApi.showLoadingOverlay()
      // if (this.state.showQuery && this.state.isAllRowsSelected) {
      //   const payload = { query: this.getQueryData() }
      //   const sub_route = 'contacts/copy'
      //   const { status, data } = await workbenchDataApi(POST, workbenchId, payload, sub_route);
      //   processAddContactsResponse(status, data, this.enqueueSnackbar)
      // } else {
      const contactIds = getSelectedContactIds(this.gridApi)
      await addContactsToWorkbench(workbenchId, contactIds, this.enqueueSnackbar)
      // }
      this.gridApi.hideOverlay()
    }
  }

  getQueryData = () => {
    if (this.state.showQuery && this.state.isAllRowsSelected) {
      return getQueryPayload(this.state.queryData, this.gridApi.getFilterModel())
    }
    return {}
  }
  loaderChange = async (value) => {
    this.setState({ isLoading: value })
  }
  pullData = () => {
    if (this.state.uiSelector === false && this.state.isQuerySelector === false) {
      this.setState({
        uiSelector: true,
        advanceSelector: true,
        isQuerySelector: true
      });
    } else if (this.state.uiSelector === true && this.state.isQuerySelector === true) {
      this.setState({
        uiSelector: false,
        advanceSelector: false,
        isQuerySelector: false
      })
    }
  }

  setEmploymentHistoryEndYearDate = (endDate) => {
    this.setState({ employmentHistoryMessageEndYearDate: endDate });
  }

  handleConfirmJobTitlePopup = async () => {
    this.setState({ isUpdateEmploymentHistory: true, showJobTitleChangedPopup: false, skipCheckJobTitle: true })
  }
  handleCancelJobTitlePopup = async () => {
    this.setState({ isUpdateEmploymentHistory: false, showJobTitleChangedPopup: false, skipCheckJobTitle: true })
  }

  setJobHistoryPopupStates = (data) => {
    this.setState({ ...data })
  }

  handleUpdateJobHistory = async () => {
    this.setState({ skipCheckJobTitle: false })
    const params = this.state.newContactData;
    if (this.state.isUpdateEmploymentHistory) {
      this.setState({ isLoading: true })
      const contact = this.state.currentContact
      let newJobHistory = [{
        company: contact.company,
        title: contact.current_job_title,
        start_year: contact.current_job_start_year,
        end_year: this.state.employmentHistoryMessageEndYearDate || new Date().getFullYear()
      }]
      newJobHistory = [...newJobHistory, ...contact.job_history].filter(item => item?.company)
      params.job_history = sortByChronologicalOrder(cleanupCompanyDetails(newJobHistory, contact.id))
    }
    await acquireUtil(params, 'current_job_title', this.props.enqueueSnackbar, this.props.closeSnackbar, true, this.loaderChange, undefined, undefined, true)
    this.setJobHistoryPopupStates({
      employmentHistoryMessageEndYearDate: null,
      isUpdateEmploymentHistory: false,
      currentContact: null,
      newContactData: null
    })
  }

  render() {
    const {
      linkedInValue, linkedPopup, isAddToWorkbenchOpen, contactIds, isRemoveFromWorkbenchOpen, contactId, isDeleteOpen, isRowSelected,
      isCreateWorkbenchOpen, showQuery, isAllRowsSelected, isAddToSearchOpen, contacts, isAthenaPopupOpen, isLoading, isDeleteContactOpen
    } = this.state
    const { users } = this.props

    return (
      <div className="list-contacts d-flex flex-column">
        <LinkedInDialog open={linkedPopup} linkedInValue={linkedInValue} handleClose={this.LinkedInCloseHandler} ></LinkedInDialog>
        <Loader show={isLoading} />
        <Helmet>
          <title>Contacts - KG Galaxy</title>
        </Helmet>
        {isCreateWorkbenchOpen &&
          <AddWorkbench onClose={this.handleCreateWorkbenchClose} buttonText={VALIDATION_MESSAGE.create_workbench_add_contacts} DefaultValue={"Contacts"} Disabled={true} />}
        {isDeleteOpen ? <DeletePopup onClose={this.handleDeleteClose} id={contactId} module={'contacts'} updateParent={this.update} popupText={DELETE_CONFIRMATION_POPUP_MESSAGE.DELETE_CONTACT} /> : null}
        {isDeleteContactOpen ? <DeletePopup onClose={this.handleDeleteContactClose} payload={{ failed: true }} id={contactId} module={'contacts'} popupText={DELETE_CONFIRMATION_POPUP_MESSAGE.DELETE_FAILED_CONTACT} /> : null}
        {this.state.activeContact && <ActivityHistories contact={this.state.activeContact} onPopupClose={() => this.showContactActivityHistory(false)} />}
        {isAddToSearchOpen &&
          <CopyCandidatesToSearch
            contacts={contacts}
            header={VALIDATION_MESSAGE.add_contacts_to_search}
            buttonText={VALIDATION_MESSAGE.add_contacts_to_search}
            uiLabel="contact(s)"
            open={isAddToSearchOpen}
            contactIds={contactIds}
            isAllRowsSelected={showQuery && isAllRowsSelected}
            queryData={this.getQueryData()}
            handleClose={this.handleAddToSearchClose} />
        }
        {this.state.showJobTitleChangedPopup &&
          <EmploymentHistoryConfirmationDialog
            header='Update'
            employmentHistoryMessageEndYearDate={this.state.employmentHistoryMessageEndYearDate}
            setEmploymentHistoryEndYearDate={this.setEmploymentHistoryEndYearDate}
            open={this.state.showJobTitleChangedPopup}
            contact={this.state.currentContact}
            handleSubmit={this.handleConfirmJobTitlePopup}
            handleClose={this.handleCancelJobTitlePopup}
            cancelText='No'
            confirmText='Yes'
            extraClassName="jobhistory-popup"
          />
        }
        {this.state.isQuickAddOpen && <QuickAddContact open={this.state.isQuickAddOpen} handleClose={this.handleQuickAddContactClose} />}
        <AddContactAsClient header={VALIDATION_MESSAGE.add_contact_as_client} buttonText={VALIDATION_MESSAGE.add_contact_as_client} uiLabel="contact" open={this.state.isAddAsClientOpen} contactId={this.state.contactIds.length ? this.state.contactIds[0] : null} isAllRowsSelected={this.state.isAllRowsSelected} handleClose={this.handleAddAsClientClose} />
        {
          isAddToWorkbenchOpen &&
          <AddContactToWorkbench
            open={isAddToWorkbenchOpen}
            contactIds={contactIds}
            onClose={this.handleAddToWorkbenchClose}
            contactsQuery={this.getQueryData()}
            type="Contacts"
          />
        }
        {
          isRemoveFromWorkbenchOpen &&
          <RemoveContactFromWorkbench
            contactId={contactId}
            onClose={this.handleRemoveFromWorkbenchClose}
            open={isRemoveFromWorkbenchOpen}
          />
        }
        {isAthenaPopupOpen ? <AthenaInvitePopUpContact inviteFor={'candidate'} selectedDropDown={'Athena'} client={this.state.athenaData} onClose={this.handleAthenaPopupClose} /> : null}
        {this.state.showQuery ?
          <div className={'d-flex query-toolbar d-flex'} style={{ padding: '0px' }}>
            <ExpansionPanel className="w-100 m-0" expanded={this.state.showQuery && !this.state.minimizeQuery}>
              <ExpansionPanelSummary
                expandIcon={
                  <ExpandMoreIcon onClick={() => this.setState({
                    minimizeQuery: true
                  })} />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
                onClick={() => this.setState({
                  minimizeQuery: !this.state.minimizeQuery
                })}
                style={{ padding: '0px 14px', lineHeight: 1 }}
              >
                Contacts Query
              </ExpansionPanelSummary>
              <ExpansionPanelDetails style={{ padding: '0px 15px 3px', width: '100%' }}>
                {this.state.uiSelector ?
                  <AdvancedQuery fieldDefinitions={fieldDefinitions} type={'contact'} pullData={this.pullData} handleQuery={this.AdvancedQuery} isRowSelected={isRowSelected} />
                  :
                  <Query gridApi={this.gridApi} isAllRowsSelected={this.state.isAllRowsSelected} pullData={this.pullData} columnApi={this.columnApi} handleQuery={this.classicQuery} queryData={this.state.queryData} option={users} isRowSelected={isRowSelected} />}
              </ExpansionPanelDetails >
            </ExpansionPanel >
            <div className="expansion-panel-close-container">
              <span
                style={{ display: this.state.minimizeQuery ? 'inline' : 'none' }}
                className="cursor-pointer expansion-panel-close d-flex"
                onClick={async () => {
                  await this.resetSort();
                  await this.resetFilter();
                  this.setState({ showQuery: false, queryData: {} });
                  this.gridApi.onFilterChanged()
                  this.setState({ isAdvanceQuery: false, isClassicQuery: false, advanceSelector: false })
                  if (this.state.uiSelector === true) {
                    this.setState({ isQuerySelector: true })
                  } else {
                    this.setState({ isQuerySelector: false })
                  }
                  localStorage.removeItem('contactColumns')
                  // setTimeout(() => {
                  //   window.location.reload(false);
                  // }, 3000);
                }}
              >
                <CloseIcon />
              </span >
            </div >
          </div > :
          <div className={'toolbar'}>
            <Search value={this.state.searchValue} onChange={this.handleSearchChange} />
            <Button className={'start-query'} variant={'outlined'} color={'primary'} onClick={this.handleChangeQuery}>
              Start Query
            </Button>
          </div>
        }
        <div className="d-flex w-100 align-items-center justify-content-between" style={{ padding: '20px 20px 0 20px', minHeight: 56 }}>
          <div className="d-flex">
            <Button disabled={!isRowSelected} onClick={this.handleAddToSearchBySelection} variant='outlined' color='primary' className="mr-2">{VALIDATION_MESSAGE.add_contacts_to_search}</Button>
            <Button
              variant='outlined'
              color='primary'
              className='mr-2'
              disabled={!isRowSelected}
              onClick={this.handleAddContactsToWorkbench}
            >Add Contacts To Workbench</Button>
            <Button
              variant='outlined'
              color='primary'
              className='mr-2'
              disabled={!isRowSelected}
              onClick={this.handleCreateWorkbench}
            >Create New Workbench</Button>
            <Button variant='outlined' color='primary' className='mr-2' onClick={() => { this.setState({ isQuickAddOpen: true }) }}>Quick Add Contact</Button>
          </div>
          <div className="d-flex align-items-center justify-content-end">
            <div
              className="action-container"
              style={{ minWidth: '0' }}
              onClick={() => this.resetFilter()}
            >
              <span className="action-text" >
                Reset Filter
              </span>
            </div>
            <div
              className="action-container"
              style={{ minWidth: '0' }}
              onClick={() => this.resetSort()}
            >
              <span className="action-text" >
                Reset Sort
              </span>
            </div>
            <Button className="mr-3" variant={"outlined"} color={"primary"} disabled={!isRowSelected} onClick={this.exportCallback}>Export list</Button>
            <Typography className='="mr-3'>Total count: {this.state.rowCount}</Typography>
          </div>
        </div>
        <div className="list-view">
          {this.state.isGridReady && <ColumnFilter columnApi={this.columnApi} defaultColumns={defaultColumns} showFilterCount={this.state.showFilterCount} filterModel={this.state.filterModel} />}
          <div id="myGrid" className="ag-theme-alpine">
            <AgGridReact
              onGridReady={this.onGridReady}
              enableBrowserTooltips={true}
              defaultColDef={{
                minWidth: 100,
                resizable: true,
                sortable: true,
                sortingOrder: ['asc', 'desc', null]
              }}
              isRowSelectable={(params) => {
                const status = checkContactFetchingStatus(params)
                if (status) return false;
                return true;
              }}
              cacheBlockSize={PAGE_LIMIT}
              loadingOverlayComponent={'CustomLoadingOverlayComponent'}
              frameworkComponents={{
                CustomLoadingOverlayComponent,
                LinkedInRenderer: this.LinkedInRenderer,
                PhoneRenderer,
                EmailRenderer,
                ActionsRenderer: this.ActionsRenderer,
                AddContactToRenderer: this.AddContactToRenderer,
                NameRenderer: this.NameRenderer,
                ResumeRenderer,
                HeaderCheckbox: this.HeaderCheckbox,
                DateTimeRenderer,
                DateRenderer,
                CompanyNameRenderer: this.CompanyNameRenderer,
                GenericCellEditor,
                PhoneEditor,
                CompensationRenderer,
                CustomFilter,
                AthenaRenderer: this.AthenaRenderer
              }}
              blockLoadDebounceMillis={SCROLL_TIMEOUT}
              scrollbarWidth={12}
              suppressHorizontalScroll={false}
              suppressDragLeaveHidesColumns={true}
              rowModelType={'infinite'}
              datasource={this.dataSource}
              columnDefs={columnDefs(this.showContactActivityHistory, this.enqueueSnackbar, this.closeSnackbar, this.loaderChange, users, undefined, this.setJobHistoryPopupStates)}
              suppressMenuHide={true}
              paginationPageSize={PAGE_LIMIT}
              rowSelection={'multiple'}
              getRowNodeId={data => data.id}
              onRowSelected={() => {
                // if (!e.node.selected) {
                //   this.setState({ isAllRowsSelected: false })
                // }
                this.setState({ isRowSelected: this.gridApi.getSelectedRows().length > 0 })
              }}
              suppressRowClickSelection={true}
              onSortChanged={this.onSortChanged}
              onFirstDataRendered={this.onFirstDataRendered}
              onDisplayedColumnsChanged={this.saveColumnState}
              onDragStopped={this.saveColumnState}
              onFilterChanged={this.saveColumnStateForFilter}
            >
            </AgGridReact>
          </div>
        </div>
      </div >
    )
  }
}

App.propTypes = {
  enqueueSnackbar: PropTypes.func,
  closeSnackbar: PropTypes.func,
  location: PropTypes.object,
  history: PropTypes.object,
  userData: PropTypes.object,
  users: PropTypes.array,
  fetchUserList: PropTypes.func,
  socket: PropTypes.object,
  refreshContact: PropTypes.bool,
  [LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_COMPLETED]: PropTypes.object,
  dispatch: PropTypes.func,
};

const mapStateToProps = state => {
  return {
    userData: state.commonReducer.userData,
    users: state.rootReducer.users,
    // socket: state.rootReducer.socket,
    refreshContact: state.rootReducer.refreshContact,
    [LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_COMPLETED]: state.rootReducer[LINKEDIN_SCRAPER.LINKEDIN_SCRAPER_COMPLETED]
  }
};
const mapDispatchToProps = (dispatch) => ({
  fetchUserList,
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(withRouter(App)))
