
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useState, useEffect } from 'react'

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from 'prop-types'
import { useSnackbar } from "notistack";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import EditIcon from "@material-ui/icons/Edit";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import DoneIcon from "@material-ui/icons/Done";


//-----------------------------------------------------------// Internal Imports // ------------------------------

import RecentSearchesActivities from '../RecentSearchesActivities';
import { rightNav } from '../../../Containers/Commons/styleUtils';
import { acquireLockApi, releaseLockApi } from '../../../services/ApiService';
import { WARNING, SUCCESS } from '../../../services/constantService';
import { validateMessage, successMessage, VALIDATION_MESSAGE } from '../../../services/MessageService';
import RichTextPopup from '../../RichTextPopup';
import { showSnackbar } from '../../../Containers/Commons/Utils';
// import { useParams } from 'react-router-dom';
// import { SUCCESS_STATUS_CODE } from '../../../Containers/Contacts/utils';

export default function StrengthsAndPotentialIssuesView(props) {
  const { contact, updateField, strengths = [], issues = [], removeIssue, handelScroll, removeStrength, addIssue, addStrength, handleChange, setIsLoading, individualFieldSave, saveStatus, setSaveStatus } = props;

  const [editIndex, setEditIndex] = useState(-1)
  const [hoverIndex, setHoverIndex] = useState(-1)
  const [isAdding, setIsAdding] = useState(false)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  useEffect(() => {
    setTimeout(() => {
      handelScroll()
    }, 3000)

  }, []);

  const handleAdd = (type) => {
    if (isAdding) {
      const message = validateMessage("add", "complete")
      enqueueSnackbar(message, { variant: WARNING })
      return
    }
    const listData = type === 'issues' ? issues : strengths;
    setEditIndex(type + listData.length);
    if (type === 'issues') {
      addIssue();
    } else {
      addStrength();
    }
    setIsAdding(false)
  }

  const handleUpdate = async (type, autosave = false) => {
    try {
      if (!isAdding) {
        setIsLoading(true)
        const saveStatus = await updateField(type, false, null, true);
        setIsLoading(false)
        setSaveStatus(saveStatus);
        const message = successMessage("Contact", VALIDATION_MESSAGE.updated_message)
        enqueueSnackbar(message, { variant: SUCCESS });
        if (!autosave) {
          setEditIndex(-1);
        }
      }
      if (isAdding) {
        setIsAdding(false);
      }
      releaseLock()
    } catch (e) {
      console.log("Error found in handleUpdate::", e);
    }
  }
  const releaseLock = async () => {
    await releaseLockApi(contact.id);
  }
  const handleCancel = (type, index) => {
    if (isAdding) {
      if (type === 'issues') {
        removeIssue(index);
      } else {
        removeStrength(index);
      }
      setIsAdding(false)
    }
    setEditIndex(-1)
    releaseLock()
  }

  const handleDelete = async (type, index) => {
    try {
      if (type === 'issues') {
        removeIssue(index);
      } else {
        removeStrength(index);
      }
      const data = type === 'issues' ? issues : strengths;
      await updateField(type, false, data.filter((ele, eleIndex) => index !== eleIndex));
      setEditIndex(-1)
      releaseLock()
    } catch (e) {
      console.log("Error found in handleDelete::", e);
    }

  }
  const saveStrengthPotential = async (Data, Index, Type, item, setAutoSaveLoading) => {
    try {
      const updatedContact = {}
      if (Type === "strengths") {
        const updatedStrengths = [...strengths]
        updatedStrengths[Index] = Data[`Strength ${Index + 1}`]
        updatedContact.old = [Data.old]
        updatedContact.strengths = updatedStrengths
      }
      if (Type === "issues") {
        const updatedIssues = [...issues]
        updatedIssues[Index] = Data[`Potential Issue ${Index + 1}`]
        updatedContact.old = [Data.old]
        updatedContact.issues = updatedIssues
      }
      const saveStatus = await individualFieldSave(updatedContact, true, setAutoSaveLoading, false)
      setSaveStatus(saveStatus)
    } catch (e) {
      console.log("Error found in saveStrengthPotential::", e);
    }
  }

  const renderListElements = (items, type) => {
    if (items.length === 0) {
      return <div className="d-flex contact-details-row no-data-available">--</div>
    }
    return items.map((item, index) => {
      return <div key={index}>
        <div className="d-flex contact-details-row">
          <div className="contact-details">
            <div className="contact-view-label" onMouseEnter={() => { setHoverIndex(type + index) }} onMouseLeave={() => { setHoverIndex(-1) }}>
              <span>{`${type === 'strengths' ? 'Strength' : 'Issue'} ${index + 1}`}</span>
              {(hoverIndex === type + index) && editIndex !== type + index
                && <EditIcon className='ml-2 cursor-pointer' onClick={async () => {
                  let { status, data } = await acquireLockApi(contact.id);
                  if (status && status === 200 && data.message && data.user === false) {
                    //if (data.isAdmin) {
                    const object = {
                      enqueueSnackbar: enqueueSnackbar,
                      closeSnackbar: closeSnackbar,
                      message: data.message,
                      id: contact.id
                    }
                    showSnackbar(object)

                    /* }
                    else {
                      enqueueSnackbar(data.message, { variant: WARNING });
                    } */
                  }
                  else {
                    setEditIndex(type + index)
                  }
                }} fontSize='inherit' color='secondary' />}
            </div>
            {
              (editIndex === type + index) ?
                <div className="contact-view-value d-flex">
                  <div className='w-100'>
                    <RichTextPopup
                      className='strength-potential'
                      InputLabelProps={{ focused: true }}
                      onSave={async (data) => {
                        await handleChange(type, index, data);
                      }}
                      // saveText={async (data) => {
                      //   if (data[`${type}`] && data[`${type === 'strengths' ? 'Strength' : 'Potential Issue'} ${index + 1}`] !== contact[`${type}`][`${index}`]) {
                      //     await handleUpdate(type)
                      //   }
                      // }}
                      // onSave={(data) => { saveStrengthPotential(data, undefined, type, undefined, index) }}
                      autoSave={saveStrengthPotential}
                      item={item}
                      index={index}
                      displayToolbar={false}
                      expandToContent={true}
                      commentType={type}
                      value={(contact[`${type}`] && contact[`${type}`][`${index}`]) || ""}
                      title={`${type === 'strengths' ? 'Strength' : 'Potential Issue'} ${index + 1}`}
                      name={`${type === 'strengths' ? 'Strength' : 'Potential Issue'} ${index + 1}`}
                      updatedDate={contact.updated_at}
                      saveStatus={saveStatus || true}
                      payloadType={'default'}
                    />
                  </div>
                  <div className='ml-2' style={{ fontSize: 16, width: 55 }}>
                    {!isAdding && <DeleteIcon fontSize='inherit' cursor='pointer' onClick={async () => await handleDelete(type, index)} />}
                    <DoneIcon fontSize="inherit" cursor='pointer' color='primary' onClick={async () => await handleUpdate(type)} />
                    <CloseIcon fontSize="inherit" cursor='pointer' onClick={() => handleCancel(type, index)} />
                  </div>
                </div>
                : <div className="contact-view-value">
                  <RichTextPopup
                    setOpen={false}
                    value={item || '--'}
                    strengthsAndPotentialIssues={true}
                    defaultValue={item || '--'}
                  />
                </div>
            }
          </div>
        </div>
      </div>
    })
  }

  return (
    <div className="d-flex contact-view">
      <div className="d-flex tab-view-content flex-column">
        <div className="section-container" id='strengths'>
          <div className="section-header">
            <span>Strengths</span>
            {strengths.length < 8 && <AddCircleIcon className='ml-2 cursor-pointer' fontSize='inherit' color='primary' onClick={() => handleAdd('strengths')} />}
          </div>
          {renderListElements(strengths, "strengths")}
        </div>
        <div className="section-container" id='potential'>
          <div className="section-header">
            <span>Potential Issues</span>
            {issues.length < 8 && <AddCircleIcon className='ml-2 cursor-pointer' fontSize='inherit' color='primary' onClick={() => handleAdd('issues')} />}
          </div>
          {renderListElements(issues, "issues")}
        </div>
      </div>
      <div className="tab-view-right-nav">
        <div className="right-nav-section right-nav-recent-activity-section d-flex flex-column">
          <a style={rightNav} href='/' className="right-nav-header" onClick={(e) => { e.preventDefault(); handelScroll('strengths') }}>
            Strengths
          </a>
          <a style={rightNav} href='/' className="right-nav-header" onClick={(e) => { e.preventDefault(); handelScroll('potential') }}>
            Potential Issues
          </a>
        </div>
        <RecentSearchesActivities contact={contact} />
      </div>
    </div>
  )
}

StrengthsAndPotentialIssuesView.propTypes = {
  contact: PropTypes.object,
  updateField: PropTypes.func,
  strengths: PropTypes.array,
  issues: PropTypes.array,
  removeIssue: PropTypes.func,
  removeStrength: PropTypes.func,
  addIssue: PropTypes.func,
  addStrength: PropTypes.func,
  handleChange: PropTypes.func,
  setIsLoading: PropTypes.func,
  individualFieldSave: PropTypes.func,
  saveStatus: PropTypes.bool,
  setSaveStatus: PropTypes.func,
  handelScroll: PropTypes.func
}
