//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, {useEffect, useState} from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from "prop-types";
import {TextField} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import {useDispatch, useSelector} from "react-redux";

//----------------------------------------------// Internal Imports // -------------------------------------------------
import {fetchPickList} from "../../../actions";

function TimezoneSelection(props){
  const {
    register,
    className='w-100',
    label="Timezone",
    name='time_zone',
    placeholder='Timezone',
    InputLabelProps,
    ...rest
  } =props
  const [options, setOptions] = useState([])
  const timezones = useSelector(state => state.commonReducer.timezones)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!timezones) {
      dispatch(fetchPickList('timezones'))
    } else {
      setOptions(timezones);
    }
  }, [timezones, dispatch])

  return(
    <Autocomplete
      {...rest}
      className={className}
      options={options}
      getOptionLabel={option => option.name}
      getOptionSelected={(option, value) => option.name===value.name}
      renderInput={(params) => (
        <TextField
          {...params}
          inputRef={register}
          name={name}
          InputLabelProps={InputLabelProps}
          label={label}
          placeholder={placeholder}
        />
      )}
    />
  )
}

TimezoneSelection.propTypes = {
  register:PropTypes.func,
  className:PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  InputLabelProps:PropTypes.object
}

export default TimezoneSelection;
