
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useEffect } from 'react';

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from 'prop-types'
import { useSnackbar } from "notistack";
import { useDispatch } from 'react-redux';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import './index.scss'
// import RightSideNav from '../../RightSideNav';
import RecentSearchesActivities from '../RecentSearchesActivities';
import ContactNotes from './ContactNotes';
import ContactAttachments from './ContactAttachments';
import ContactResume from './ContactResume';
import ContactOfferLetter from './ContactOfferLetter';
import VideoDisplay from './Video';
import ContactNda from './ContactNda';
import { contactActions } from '../../../store/contactSlice';

function NotesAttachments(props) {


  const { enqueueSnackbar } = useSnackbar();
  const { contact, video, handelScroll } = props
  const style = {
    fontSize: '14px',
    color: '#939393',
    letterSpacing: '.003em',
    paddingLeft: '3px',
    cursor: 'pointer',
    marginBottom: '14px'
  }

  const dispatch = useDispatch()
  useEffect(() => {
    return () => {
      dispatch(contactActions.resetContactState())
    };
  }, [contact.id]);

  useEffect(() => {
    setTimeout(() => {
      handelScroll()
    }, 3000)

  }, []);

  return (
    <React.Fragment>
      <div className="d-flex contact-view">
        <div className="d-flex tab-view-content d-flex flex-row">
          <div style={{ width: '90%' }}>
            <div id='Notes'><ContactNotes contactId={contact.id} /></div>
            <div id='Attachments'>   <ContactAttachments contactId={contact.id} /></div>
            <div id='Resume'><ContactResume contactId={contact.id} /></div>
            <div id='NDA'><ContactNda contactId={contact.id} enqueueSnackbar={enqueueSnackbar} /></div>
            <div id='OfferLetters'>  <ContactOfferLetter contactId={contact.id} /></div>
            <VideoDisplay video={video} />
          </div>

        </div>
        <div className="tab-view-right-nav">
          {/* <RightSideNav navItems={['Notes', 'Attachments', 'Resume', 'OfferLetters']} /> */}
          <div className="right-nav-section right-nav-recent-activity-section d-flex flex-column">
            <a style={style} href='/' className="right-nav-header" onClick={(e) => { e.preventDefault(); handelScroll('Notes') }}>
              Notes
            </a>
            <a style={style} href='/' className="right-nav-header" onClick={(e) => { e.preventDefault(); handelScroll('Attachments') }}>
              Attachments
            </a>
            <a style={style} href='/' className="right-nav-header" onClick={(e) => { e.preventDefault(); handelScroll('Resume') }}>
              Resume
            </a>
            <a style={style} href='/' className="right-nav-header" onClick={(e) => { e.preventDefault(); handelScroll('NDA') }}>
              NDA
            </a>
            <a style={style} href='/' className="right-nav-header" onClick={(e) => { e.preventDefault(); handelScroll('OfferLetters') }}>
              OfferLetters
            </a>
          </div>
          <RecentSearchesActivities contact={contact} />
        </div>
      </div>
    </React.Fragment>
  )
}

NotesAttachments.propTypes = {
  contact: PropTypes.object,
  video: PropTypes.object,
  handelScroll: PropTypes.func
}

export default NotesAttachments;
