
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useState } from "react";

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from "prop-types";
import { AgGridReact } from "ag-grid-react";
import EditIcon from '@material-ui/icons/Edit';
import { useSnackbar } from "notistack";
import { Checkbox } from "@material-ui/core";

//-----------------------------------------------------------// Internal Imports // ------------------------------

import {
  CustomLoadingOverlayComponent,
  PAGE_LIMIT,
  LinkedInRenderer,
  EmailRenderer,
  DateRenderer,
  saveColumnStateToLocalStorage
} from "../../../Containers/Commons/Utils";
import {
  columnDefs, SearchIdRenderer, SearchTitleRenderer, SearchCompanyRenderer, ReferenceCompanyRenderer, defaultColumns,
} from './utils'
import { ContactNameRenderer } from "../../../Containers/Contacts/utils";
import ColumnFilter from "../../../Containers/Commons/ColumnFilter";
import GenericCellEditor from "../../../Containers/Commons/CellEditors/GenericCellEditor";
import CustomFilter from "../../../Containers/Commons/CustomFilter";
import LinkedInDialog from "../../../Containers/Commons/LinkedInDialog";
import Loader from "../../common/Loader";
export default function ReferencesGrid(props) {
  const { onGridReady, dataSource, columnApi, gridApi, handleEdit, handleCheckboxChange, onRowSelected, disabled = false, editable = true, filterValue, showFilterCount } = props
  const { enqueueSnackbar } = useSnackbar()
  const [linkedPopup, setLinkedPopup] = useState(false);
  const [linkedInValue, setLinkedInValue] = useState({});
  const [isLoading, setLoading] = useState(false);

  const linkedInPopupHandler = (params) => {
    setLinkedInValue(params.value);
    setLinkedPopup(true);
  }

  const LinkedInRenderHandler = (params) => {
    return (
      <LinkedInRenderer params={params} linkedInPopup={linkedInPopupHandler} />
    )
  }

  const LinkedInCloseHandler = () => {
    setLinkedPopup(false);
  }

  const NameRenderer = (params) => {
    return <ContactNameRenderer params={params} />
  }

  const saveColumnState = async () => {
    try {
      if (columnApi) {
        setLoading(true)
        await saveColumnStateToLocalStorage('contactReferences', { columnApi, gridApi });
        setLoading(false)
      }
    } catch (e) {
      console.log("Error found in saveColumnState::", e);
    }
  }

  const saveColumnStateForFilter = async () => {
    try {
      if (columnApi) {
        setLoading(true)
        await saveColumnStateToLocalStorage('contactReferences', { columnApi, gridApi }, true, false);
        setLoading(false)
      }
    } catch (e) {
      console.log("Error found in saveColumnState::", e);
    }
  }

  const saveColumnStateForSort = async () => {
    try {
      if (columnApi) {
        setLoading(true)
        await saveColumnStateToLocalStorage('contactReferences', { columnApi, gridApi }, false, true);
        setLoading(false)
      }
    } catch (e) {
      console.log("Error found in saveColumnState::", e);
    }
  }

  const DetailsRenderer = (params) => {
    if (disabled === true) {
      return null
      // return <EditIcon color='primary' fontSize='small' />
    }
    return <EditIcon color='primary' className='cursor-pointer' fontSize='small' onClick={() => { handleEdit(params.data) }} />
  }

  const HeaderCheckbox = () => {
    return (
      <Checkbox
        style={{ padding: 0, width: 16, height: 16 , color: "white" }}
        size="small"
        color="primary"
        onChange={handleCheckboxChange}
      // disabled={disabled}
      />
    )
  }

  return (
    <div className="list-view">
      <div id="myGrid" className="ag-theme-alpine">
        <Loader show={isLoading} />
        <LinkedInDialog open={linkedPopup} linkedInValue={linkedInValue} handleClose={LinkedInCloseHandler} />
        {columnApi && <ColumnFilter columnApi={columnApi} defaultColumns={defaultColumns} filterModel={filterValue} showFilterCount={showFilterCount} />}
        <AgGridReact
          enableBrowserTooltips={true}
          suppressMenuHide={true}
          scrollbarWidth={12}
          suppressHorizontalScroll={false}
          loadingOverlayComponent={"CustomLoadingOverlayComponent"}
          rowModelType={"infinite"}
          rowSelection="multiple"
          suppressRowClickSelection={true}
          paginationPageSize={20}
          cacheBlockSize={PAGE_LIMIT}
          datasource={dataSource}
          onGridReady={onGridReady}
          columnDefs={columnDefs(enqueueSnackbar, editable)}
          defaultColDef={{
            minWidth: 20,
            resizable: true,
            sortable: true,
            sortingOrder: ['asc', 'desc', null]
          }}
          frameworkComponents={{
            CustomLoadingOverlayComponent,
            SearchIdRenderer,
            SearchTitleRenderer,
            SearchCompanyRenderer,
            NameRenderer,
            ReferenceCompanyRenderer,
            EmailRenderer,
            LinkedInRenderer: (params) => LinkedInRenderHandler(params),
            DateRenderer,
            DetailsRenderer,
            GenericCellEditor,
            CustomFilter,
            HeaderCheckbox
          }}
          onDisplayedColumnsChanged={saveColumnState}
          onDragStopped={saveColumnState}
          onRowSelected={onRowSelected}
          getRowNodeId={data => data.id}
          //onCellValueChanged={onCellValueChanged}
          onSortChanged={saveColumnStateForSort}
          onFilterChanged={saveColumnStateForFilter}
        />
      </div>
    </div>
  )
}

ReferencesGrid.propTypes = {
  onGridReady: PropTypes.func,
  dataSource: PropTypes.object,
  columnApi: PropTypes.object,
  gridApi: PropTypes.object,
  handleEdit: PropTypes.func,
  handleCheckboxChange: PropTypes.func,
  onRowSelected: PropTypes.func,
  // onCellValueChanged: PropTypes.func,
  disabled: PropTypes.bool,
  editable: PropTypes.bool,
  filterValue: PropTypes.object,
  showFilterCount: PropTypes.number
};
