//----------------------------------------------// In-built Imports // -------------------------------------------------

import React from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types'
import Autocomplete from "@material-ui/lab/Autocomplete";

//----------------------------------------------// Internal Imports // -------------------------------------------------
import InputField from "./common/InputField";

export default function GdprReleaseSelection(props) {
  const name = "GDPR Release"
  const { label = name, placeholder = name, ...rest } = props
  return (
    <Autocomplete
      {...rest}
      options={[{ id: "true", name: "Yes" }, { id: "false", name: "No" }]}
      getOptionLabel={(option) => option.name}
      renderInput={(params) => (
        <InputField
          {...params}
          variant="standard"
          label={label}
          placeholder={placeholder}
        />
      )}
    />
  )
}

GdprReleaseSelection.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string
}
