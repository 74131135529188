import React, { useRef } from "react";
import { useState } from 'react';
import { Helmet } from "react-helmet";
import { AgGridReact } from "ag-grid-react";
import { Button, Checkbox, Typography } from "@material-ui/core";
import { columnDefs } from "./utils";
import ActionsPopover from "../Contacts/ActionsPopover";
import DeletePopup from "../Commons/DeletePopup";
import { DELETE_CONFIRMATION_POPUP_MESSAGE } from '../../services/constantService';
import { API, ERROR, GET, DELETE, SUCCESS, WARNING } from '../../services/constantService'
import { dashboardDataApi } from '../../services/ApiService';
import { useSnackbar } from 'notistack';
import { unableMessage, successMessage, notFoundMessage } from '../../services/MessageService';
import ConfirmationPopup from "../../components/ConfirmationPopup";
import Loader from '../../components/common/Loader';
import {
  ProductSettingsApi
} from "../../services/ApiService";
import { Link, withRouter } from "react-router-dom";
import {
  PAGE_LIMIT,
} from "./utils";
import {
  CustomLoadingOverlayComponent,
  SUCCESS_STATUS_CODE
} from "../Commons/Utils";
import { getFilterParamStringForTranslateValue } from './utils'
import TranslateView from "../../components/ViewTranslateValues/TranslateView";
import Search from "../Commons/Search";

//-----------------------------------------------------------// Internal Imports // ------------------------------
let gridApi;

const TranslateValues = () => {
  const [isDeleteTranslateOpen, setDeleteTranslatePopup] = useState(false);
  const [orgId, setOrgId] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [isRemoveSelectedAll, setRemoveSelectedAll] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRowsCount, setSelectedRowsCount] = useState(0);
  const [isSingleDelete, setSingleDelete] = useState(false);
  const [rowCount, setRowCount] = useState(null);
  const [translateView, setTranslateView] = useState(false);
  const [translateData, setTranslateData] = useState(null);
  const [searchValue, setSearchValue] = useState("")
  const [quickQuery, setQuickQuery] = useState("")
  const [isSelectedFromList, setIsSelectedFromList] = useState(false)
  const searchQueryRef = useRef();
  const isSelectedFromListRef = useRef()
  searchQueryRef.current = quickQuery
  isSelectedFromListRef.current = isSelectedFromList

  const onGridReady = (params) => {
    params.columnApi.autoSizeColumns();
    gridApi = params.api;
  };

  const handleSearchChange = async (event, value) => {
    setIsSelectedFromList(false)
    if (typeof value === "string") {
      setQuickQuery(value)
      setSearchValue(value)
    } else if (value && value.id) {
      setIsSelectedFromList(true)
      setQuickQuery(value.id)
      setSearchValue(value.field_name)
    } else {
      setQuickQuery(value?.id ? value?.id : value ? value : "")
    }
    resetFilter()
    resetSort()
  }

  const resetFilter = async () => {
    gridApi.setFilterModel(null);
  }
  const resetSort = async () => {
    gridApi.setSortModel(null);
  }

  const HeaderCheckbox = () => {
    return (
      <Checkbox
        style={{ padding: 0, width: 16, height: 16, color: 'white' }}
        size="small"
        onChange={(e) => handleChange(e)}
      />
    );
  };

  const setSelectAllRows = (isAllRows) => {
    gridApi.forEachNode(node => {
      node.setSelected(isAllRows);
    });

  }
  const handleChange = (event) => {
    const checked = event.currentTarget.checked
    setSelectAllRows(checked);
  };

  const dataSource = {
    getRows: async params => {
      const { filterModel, sortModel } = params;
      let paginationString = `limit=${PAGE_LIMIT}&page=${params.endRow / PAGE_LIMIT}`;
      let url = "";
      url = `${API.product_setting}/translate_value`
      gridApi.showLoadingOverlay();

      if (searchQueryRef.current !== "") {
        paginationString = paginationString.concat(`&${isSelectedFromListRef.current ? "id" : "searchValue"}=${searchQueryRef.current}`)
      }

      if (sortModel.length > 0) {
        paginationString = paginationString.concat(
          `&sortOn=${sortModel[0].colId
          }&sortType=${sortModel[0].sort.toUpperCase()}`
        );
      }
      if (Object.keys(filterModel).length) {
        paginationString = paginationString.concat(
          getFilterParamStringForTranslateValue(
            filterModel
          )
        );
      }
      const { status, data } = await dashboardDataApi(GET, url, paginationString);

      if (status === SUCCESS_STATUS_CODE && data?.data) {
        if (data?.data?.count === 0) {
          const message = notFoundMessage("records");
          enqueueSnackbar(message, { variant: WARNING });
        }
        params.successCallback(data?.data?.rows, data?.data?.count);
        setRowCount(data?.data?.count);
      }
      gridApi.hideOverlay();
      setIsLoading(false);
    }

  }

  const ActionsRenderer = (params) => {
    const list = [
      {
        label: "Delete",
        onClick: () => {
          setSingleDelete(true);
          setOrgId(params.data.id);
        },
      },
    ];
    return <ActionsPopover list={list} />;
  }

  const getSelectedIds = () => {
    const translateIds = [];
    gridApi.forEachNode((node) => {
      if (node.selected) {
        translateIds.push(node.data.id)
      }
    })
    return translateIds
  }

  const handleDeleteTranslateClose = () => {
    setDeleteTranslatePopup(false);
  }

  const handleDeleteSelectedItem = () => {
    setRemoveSelectedAll(true);
  }

  const handleConfirmPopupClose = () => {
    setRemoveSelectedAll(false);
    setSingleDelete(false)
  }

  const translateViewRenderer = (params) => {
    return (
      <Typography
        className="text-link"
        color="primary"
        onClick={() => {
          setTranslateView(true);
          setTranslateData(params.data);
          setOrgId(params.data.id);
        }}
      >
        {params.value}
      </Typography>
    );
  };

  const onViewPopupClose = (refreshData) => {
    if (refreshData) {
      gridApi.onFilterChanged();
    }
    setTranslateView(false);
  };

  const deleteSelectedItem = async () => {
    let sub_route = 'translate_value_delete'
    const selectedRows = isSingleDelete ? [orgId] : getSelectedIds();
    setIsLoading(true);
    const { status, data } = await ProductSettingsApi(
      DELETE,
      selectedRows,
      sub_route
    );

    if (status === 200) {
      const message = successMessage("Translate Value", "removed");
      enqueueSnackbar(message, { variant: SUCCESS });
      setIsLoading(false);
      setRemoveSelectedAll(false);
      setSingleDelete(false);
      setSelectAllRows(0);
      gridApi.onFilterChanged();
      return true;
    } else {
      const message = unableMessage("Translate Values", "remove");
      enqueueSnackbar(data?.message || message, { variant: ERROR });
      setRemoveSelectedAll(false);
      setSingleDelete(false);
      setIsLoading(false);
    }

  }
  return (
    <div className="d-flex flex-column w-100 h-100">
      <Loader show={isLoading} />
      <Helmet>
        <title>Translate Product Setup</title>
      </Helmet>
      {isDeleteTranslateOpen ? <DeletePopup onClose={handleDeleteTranslateClose} payload={{ failed: true }} id={orgId} module={'product-settings/translate_value_delete'} popupText={DELETE_CONFIRMATION_POPUP_MESSAGE.DELETE_TRANSLATE_PRODUCT} /> : null}
      {(isRemoveSelectedAll || isSingleDelete) &&
        <ConfirmationPopup
          message="Are you sure you want to remove the translate values?"
          onClose={handleConfirmPopupClose}
          onConfirm={() => deleteSelectedItem()}
        />
      }
      <div className={"toolbar"}>
        <Search
          value={searchValue}
          onChange={handleSearchChange}
          type="translate_value"
        />
      </div>
      <div className=" d-flex " style={{ padding: '20px 20px 0 20px' }}>
        <div className="d-flex w-100 justify-content-start">
          <Link to="/product-setup/translate-value/add">
            <Button
              className="mr-3"
              variant={"outlined"}
              color={"primary"}
            >
              Add New
            </Button>
          </Link>
          <Button
            className="mr-3"
            variant={"outlined"}
            color={"primary"}
            onClick={handleDeleteSelectedItem}
            disabled={selectedRowsCount === 0}
          >
            Delete Selected
          </Button>
        </div>
        <div className="d-flex align-items-center justify-content-end mr-4 w-100">
          <div
            className="action-container"

            onClick={() => resetFilter()}
          >
            <span className="action-text" >
              Reset Filter
            </span>
          </div>
          <div
            className="action-container"

            onClick={() => resetSort()}
          >
            <span className="action-text" >
              Reset Sort
            </span>
          </div>

          <Typography>Total count: {rowCount}</Typography>
        </div>
      </div>
      <div className="list-view flex-grow-1 w-100">
        <div id="myGrid" className="ag-theme-alpine">
          <AgGridReact
            onGridReady={onGridReady}
            enableBrowserTooltips={true}
            defaultColDef={{
              minWidth: 20,
              resizable: true,
              sortable: true,
              sortingOrder: ['asc', 'desc', null]
            }}
            tooltipShowDelay={0}
            scrollbarWidth={12}
            suppressHorizontalScroll={false}
            cacheBlockSize={PAGE_LIMIT}
            loadingOverlayComponent={"CustomLoadingOverlayComponent"}
            frameworkComponents={{
              HeaderCheckbox: HeaderCheckbox,
              ActionsRenderer: ActionsRenderer,
              CustomLoadingOverlayComponent,
              translateViewRenderer: translateViewRenderer
            }}
            suppressRowClickSelection={true}
            columnDefs={columnDefs(enqueueSnackbar, setIsLoading)}
            datasource={dataSource}
            rowSelection={'multiple'}
            rowModelType={"infinite"}
            getRowNodeId={data => data.id}
            onRowSelected={() => {

              setSelectedRowsCount(gridApi.getSelectedRows().length);
            }}
          ></AgGridReact>
        </div>
        {translateView && (
          <TranslateView
            visible={translateView}
            onClose={onViewPopupClose}
            translateData={translateData}
            setTranslateData={setTranslateData}
          />
        )}
      </div>
    </div>
  )
}

export default withRouter(TranslateValues);