//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect } from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import CloseIcon from '@material-ui/icons/Close'
import { useForm } from "react-hook-form";
import { Button, InputLabel, Typography } from "@material-ui/core";
import PropTypes from 'prop-types';
import * as yup from "yup";
import Loader from "../common/Loader";
import { useHistory } from 'react-router-dom';
import InputField from "../common/InputField";
import './index.scss'
import { Helmet } from "react-helmet";
//----------------------------------------------// Internal Imports // -------------------------------------------------
import { POST, SUCCESS, ERROR } from "../../services/constantService";
import { ProductSettingsApi } from "../../services/ApiService";
import { successMessage, unableMessage } from "../../services/MessageService";
import RoleSelection from "../AddUser/components/Roles";
import { customFormValidator, requireValidMessage } from "../../utils/common";
import { useSnackbar } from "notistack";
import Popup from "reactjs-popup";

export default function AddDocumentType() {

  const { register, unregister, handleSubmit, setValue } = useForm({})
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);
  const history = useHistory();

  useEffect(() => {
    register('upload_role');
    register('delete_role');
    register('view_role');
    register('download_role');
    return () => {
      unregister('upload_role');
      unregister('delete_role');
      unregister('view_role');
      unregister('download_role');
    }
  }, [register, unregister])

  const onClose = () => {
    history.push('/product-setup/document-type');
  }

  const addSchema = yup.object().shape({
    org_id: yup.string().required(),
    doctype_code: yup.string().required(),
    doctype_desc: yup.string().required(),
    allow_extensions: yup.string().required(),
    upload_role: yup.string(),
    delete_role: yup.string(),
    view_role: yup.string(),
    download_role: yup.string(),

  });


  const addDocType = async (userData) => {
    const isValid = await addSchema.isValid(userData)
    if (!isValid) {
      let requiredField = [
        { fieldName: "org_id", label: "Organization Id", type: String },
        { fieldName: "doctype_code", label: "Document Code", type: String },
        { fieldName: "doctype_desc", label: "Description", type: String },
        { fieldName: "allow_extensions", label: "Allow Extensions", type: String },
      ]
      let dirtyField = customFormValidator(userData, requiredField)
      if (dirtyField) {
        const message = requireValidMessage(dirtyField)
        enqueueSnackbar(message, { variant: ERROR })
        return
      }
    }

    setLoading(true);
    const { status, data } = await ProductSettingsApi(POST, userData, 'document_type')
    if (status === 201 || status === 200) {
      const message = successMessage("Document Type", "created")
      enqueueSnackbar(message, { variant: SUCCESS });
      setLoading(false);
      history.push('/product-setup/document-type')
    }
    else {
      const message = unableMessage("Document Type", "create")
      enqueueSnackbar(data?.message || message, { variant: ERROR });
      setLoading(false);
    }

  }

  return (
    <Popup open={true} modal closeOnDocumentClick={false} closeOnEscape={false} className={"add-document-value"} >
      <Helmet>
        <title>Edit Document Type - KG Galaxy</title>
      </Helmet>
      <div>
        <Loader show={loading} />
        <form autoComplete="off">
          <div className="" style={{ background: '#2a364c', color: '#fff' }}>
            <div className="quick-add-header">
              <Typography >Add Document Type</Typography>
              <CloseIcon className="cursor-pointer" onClick={onClose} />
            </div>
          </div>

          <div className='d-flex flex-column py-3'>
            <div className='d-flex contact-details-row'>

              <div className='d-flex intersection-page-view'>
                <InputLabel required={true} className="page-label">Organization Id</InputLabel>
                <div className='page-label-value'>
                  <InputField
                    variant="outlined"
                    placeholder="Organization Id"
                    defaultValue="KG"
                    inputRef={register()}
                    className='input-form-field'
                    name={"org_id"}
                    ref={register}
                    required={true}
                    disabled
                  />
                </div>
              </div>

            </div>

            <div className='d-flex contact-details-row'>
              <div className='d-flex intersection-page-view'>
                <InputLabel required={true} className="page-label">Document Code</InputLabel>
                <div className='page-label-value'>
                  <InputField
                    variant="outlined"
                    required={true}
                    className='input-form-field'
                    label=''
                    placeholder='Document Code'
                    ref={register}
                    name='doctype_code'
                    InputLabelProps={{ focused: true }}
                  />
                </div>
              </div>
              <div className='d-flex intersection-page-view'>
                <InputLabel required={true} className="page-label">Description</InputLabel>
                <div className='page-label-value'>
                  <InputField
                    variant="outlined"
                    required={true}
                    className='input-form-field'
                    label=''
                    placeholder='Description'
                    ref={register}
                    name='doctype_desc'
                    InputLabelProps={{ focused: true }}

                  />
                </div>
              </div>
            </div>
            <div className='d-flex contact-details-row'>
              <div className='d-flex intersection-page-view'>
                <InputLabel required={true} className="page-label">Allowed Extensions</InputLabel>
                <div className='page-label-value'>
                  <InputField
                    variant="outlined"
                    required={true}
                    className='input-form-field'
                    label=''
                    placeholder='Allowed Extensions'
                    ref={register}
                    name='allow_extensions'
                    InputLabelProps={{ focused: true }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div id='clientDetails'>
            <div className='team-information-table flex-grow-1 document-table-bg-hide'>
              <Typography className="" style={{ background: '#2a364c', color: '#fff', border: "6px solid #2a364c" }}>
                <div className="d-flex align-items-center table-header p-0" style={{ color: "#fff" }}>
                  Security
                </div>
              </Typography>
              <div className="client-info-table d-flex flex-column py-3 document-table-background">



                <div className="d-flex contact-details-row">
                  <div className="d-flex intersection-page-view">
                    <InputLabel
                      className="page-label"

                    >
                      Upload Role
                    </InputLabel>
                    <div className='page-label-value'>
                      <RoleSelection
                        variant="outlined"
                        className='input-form-field'
                        InputLabelProps={{ focused: true }}
                        onChange={(e, data) => setValue('upload_role', data)}

                      />
                    </div>
                  </div>
                  <div className="d-flex intersection-page-view">
                    <InputLabel
                      className="page-label"
                    >
                      View Role
                    </InputLabel>
                    <div className='page-label-value'>
                      <RoleSelection
                        variant="outlined"
                        className='input-form-field'
                        InputLabelProps={{ focused: true }}
                        onChange={(e, data) => setValue('view_role', data)}

                      /></div>

                  </div>
                </div>
                <div className="d-flex contact-details-row">
                  <div className="d-flex intersection-page-view">
                    <InputLabel
                      className="page-label"
                    >
                      Delete Role
                    </InputLabel>
                    <div className='page-label-value'>
                      <RoleSelection
                        variant="outlined"
                        className='input-form-field'
                        InputLabelProps={{ focused: true }}
                        onChange={(e, data) => setValue('delete_role', data)}

                      />
                    </div>
                  </div>
                  <div className="d-flex intersection-page-view">
                    <InputLabel
                      className="page-label"
                    >
                      Download Role
                    </InputLabel>
                    <div className='page-label-value'>
                      <RoleSelection
                        variant="outlined"
                        className='input-form-field'
                        InputLabelProps={{ focused: true }}

                        onChange={(e, data) => setValue('download_role', data)}
                      /></div>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div className="page-content-footer">
            <Button className="page-btn-cancel" onClick={onClose} >Cancel</Button>
            <Button variant="contained" type="submit" className="page-btn-save" onClick={handleSubmit(addDocType)}>Submit</Button>
          </div>
        </form>
      </div>
    </Popup>

  )
}

AddDocumentType.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  enqueueSnackbar: PropTypes.func,
}
