//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, {useEffect, useState} from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from "prop-types";
import {TextField,Typography} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import GroupIcon from '@material-ui/icons/Group';
import PhoneIcon from '@material-ui/icons/Phone';
import {useDispatch, useSelector} from "react-redux";

//----------------------------------------------// Internal Imports // -------------------------------------------------
import {fetchPickList} from "../../../../../actions";

const methods = {
  'Face to Face':<GroupIcon style={{margin: '3px 0'}} color='primary'/>,
  'Video':<PlayCircleOutlineIcon style={{margin: '3px 0'}} color='primary'/>,
  'Phone':<PhoneIcon style={{margin: '3px 0'}} color='primary'/>
}

function MethodSelection(props){
  const {
    label="Type",
    placeholder="",
    InputLabelProps,
    ...rest
  } =props

  const [options, setOptions] = useState([])
  const clientInterviewTypes = useSelector(state => state.commonReducer.clientInterviewTypes)
  const dispatch = useDispatch()

  useEffect(() => {
    if(!clientInterviewTypes){
      dispatch(fetchPickList('client-interview-types','clientInterviewTypes'))
    } else {
      setOptions(clientInterviewTypes);
    }
  }, [clientInterviewTypes, dispatch])

  return(
    <Autocomplete
      {...rest}
      options={options}
      getOptionSelected={(option,value) =>{
        if(typeof value==='string'){
          return option.name===value;
        }
        return option.name===value.name
      }}
      getOptionLabel={(option) => {
        if(typeof option==='string'){
          return option
        }
        return option.name
      }}
      renderOption={option =>
        <div className='d-flex align-items-center'>
          {methods[option.name]}
          <Typography style={{marginLeft: 10}}>{option.name}</Typography>
        </div>
      }
      renderInput={(params) => (
        <TextField
          {...params}
          InputProps={{
            ...params.InputProps,
            startAdornment: methods[params.inputProps.value]
          }}
          InputLabelProps={InputLabelProps}
          label={label}
          placeholder={placeholder}
        />
      )}
    />
  )
}

MethodSelection.propTypes = {
  className:PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  InputLabelProps:PropTypes.object
}

export default MethodSelection;
