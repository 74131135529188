/* eslint-disable react/display-name */
//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from "prop-types";
import {
  Checkbox, FormControlLabel, Typography, InputLabel, Radio, RadioGroup, Tooltip,
} from "@material-ui/core";
import { useFieldArray, useForm } from "react-hook-form";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import InfoIcon from '@material-ui/icons/Info';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { getElementsRating, getRating } from "./utils";
import './index.scss'
import RichTextPopup from "../../../../../src/components/RichTextPopup";
import { unableMessage, successMessage } from "../../../../services/MessageService";
import RatingSelection from "./Components/RatingSelection";
import { COMMENTS_VALIDATION, ERROR, SUCCESS, WARNING } from '../../../../services/constantService';
import TabLayoutFooter from "../../../TabLayoutFooter"
import { useParams } from "react-router-dom";
import { skillsTabDefaultValues } from "../../utils";
const useStyles = makeStyles(() => ({
  label: {
    fontSize: 14,
    color: '#686A7E'
  },
  root: {
    paddingTop: 14,
    margin: 0
  }
}))

const useStylesForRadio = makeStyles(() => ({
  label: {
    fontSize: 12,
    color: '#686A7E'
  },
  root: {
    margin: 0
  }
}))


const Skills = forwardRef((props, ref) => {
  const { saveStatus, updateCandidateInfo, setLoading, acquire, releaseLock, goBack, readOnly, getActivityLogInfoTabData, setParentUpdatedAt } = props
  const classes = useStyles()
  const radioClasses = useStylesForRadio()
  const [data, setData] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [currentValues, setCurrentValues] = useState(null)

  const [tabInfo, setTabInfo] = useState(null)
  const { id, path, contactId } = useParams();
  const { register, reset, getValues, setValue, watch, control } = useForm({});
  // using this fields directly nor triggering re render when using setValue
  useFieldArray({
    control,
    name: "skill_ratings",
  });

  const getActivityLogInfo = useCallback(async (hideLoader) => {
    const data = await getActivityLogInfoTabData(hideLoader, 'EXPERIENCE_TAB')
    if (data) {
      setTabInfo(data)
    }
  }, [contactId, id, path, enqueueSnackbar])

  useEffect(() => {
    getActivityLogInfo();
  }, [getActivityLogInfo])

  useEffect(() => {
    let defaultValues = skillsTabDefaultValues(tabInfo);
    // if (defaultValues && isRichTextSaved) {
    //   defaultValues = { ...defaultValues, ...cleanupFormValues(getValues({ nest: true })) }
    // }
    if (defaultValues) {
      setCurrentValues(defaultValues)
      setData(defaultValues);
      reset(defaultValues)
    }
  }, [tabInfo, reset, getValues])

  useImperativeHandle(ref, () => ({
    saveTabData: () => {
      return true
    },
    isDirty: () => {
      return false
    },
    formData: () => {
      return getValues({ nest: true })
    },
    reload: (hideLoader) => {
      getActivityLogInfo(hideLoader);
    }
  }));

  /* 
  This code is commented due to issue of copy recruiter comment
  then watch('skill_ratings') is getting undefined
  useEffect(() => {
    if (currentValues) {
      reset(currentValues)
    }
  }, [currentValues])
  */

  const skills = watch('skill_ratings') // triggers re render when setting value with setValue
  const { fields: skillFields } = useFieldArray({
    control,
    name: 'skills'
  })

  useEffect(() => {
    if (data) {
      data.skill_ratings.map((item, skillIndex) => {
        setValue(`skill_ratings[${skillIndex}].recruiter_rating`, item.recruiter_rating)
        setValue(`skill_ratings[${skillIndex}].partner_rating`, item.partner_rating)
        setValue(`skill_ratings[${skillIndex}].partner_comments`, item.partner_comments)
        setValue(`skill_ratings[${skillIndex}].recruiter_comments`, item.recruiter_comments)
        setValue(`skill_ratings[${skillIndex}].hl_partner_comments`, item.hl_partner_comments)
        setValue(`skill_ratings[${skillIndex}].hl_recruiter_comments`, item.hl_recruiter_comments)
      })
      const newData = { ...data };
      setCurrentValues(newData);
    }
  }, [data])

  const getRecruiterRating = () => {
    return getRating(skills, 'recruiter_rating')
  }
  const getPartnerRating = () => {
    return getRating(skills, 'partner_rating')
  }

  const saveCommentsOnSave = async (comments, index, type, loaderFlag = false, waitForAllResults = false, setAutoSaveLoading) => {
    try {
      const elements = []
      let returnResult;
      if (data.skill_ratings[index].elements) {
        data.skill_ratings[index].elements.forEach((ele, i) => {
          elements.push({
            ...ele,
            position: data.skills[index].elements[i].position || null,
            minimum: data.skills[index].elements[i].minimum || null,
            ideal: data.skills[index].elements[i].ideal || null
          })
        })
        comments.id = data.skills[index].id;
        // data.skills[index].elements = elements;
      }
      let skillData = { ...comments, skills: data.skill_ratings[index] };
      if (setAutoSaveLoading) {
        setAutoSaveLoading(true)
      }
      const updatedInfo = await updateCandidateInfo(skillData, true, type, data.skills[index].id)
      if (updatedInfo && updatedInfo.result) {
        if (waitForAllResults) {
          returnResult = updatedInfo.result;
        } else {
          setSkillInfo(updatedInfo.result);
        }
      } else {
        if (!waitForAllResults) {
          const message = unableMessage("Experience details", "update")
          enqueueSnackbar(message || message, { variant: ERROR })
        }
        returnResult = null;
      }
      if (setAutoSaveLoading) {
        setTimeout(() => {
          setAutoSaveLoading(false)
        }, 1000);
      }
      // loader flag used for richtextbox common autosave function
      if (loaderFlag) {
        return returnResult;
      }
      return returnResult;
    } catch (e) {
      console.log("Error found in saveCommentsOnSave::", e);
    }
  }

  const setSkillInfo = (skillData) => {
    delete skillData.created_at;
    delete skillData.candidate_id;

    let activityInfoWithSkills = { ...data }
    activityInfoWithSkills.skill_ratings = activityInfoWithSkills.skill_ratings.map(skill => {
      if (skill.skill_id === skillData.skill_id) {
        return { ...skill, ...skillData }
      } else return { ...skill }
    })
    setData(activityInfoWithSkills);
  }

  const setCandidateActivity = (activityInfoWithSkills, skill_result) => {
    activityInfoWithSkills.skill_ratings = activityInfoWithSkills.skill_ratings.map(skill => {
      const skillData = skill_result.find(item => item.skill_id === skill.skill_id);
      if (skillData) {
        return { ...skill, ...skillData };
      } else {
        return { ...skill };
      }
    })
    setData(activityInfoWithSkills);
  }

  const handleCopyRecruiterComments = async (index) => {
    // If partner and recruiter comments are same, don't copy
    if (data.skill_ratings[index].recruiter_comments !== data.skill_ratings[index].partner_comments) {
      setLoading(true)
      const data = { [`skill_ratings[${index}].partner_comments`]: skills[index].recruiter_comments }
      await saveCommentsOnSave(data, index, 'partner').then(() => setLoading(false))
    } else {
      enqueueSnackbar(COMMENTS_VALIDATION.SINGLE_COMMENT_SAME, { variant: WARNING })
    }
  }

  const handleSetSkillApproval = async (e, checked) => {
    // saveCommentsOnSave(checked, 0, 'rating' )
    try {
      // saveCommentsOnSave(checked, 0, 'rating' )
      setLoading(true)
      const updatedCandidate = await updateCandidateInfo(checked, true, 'skill_approval', null)
      if (updatedCandidate.result) {
        let newData = { ...data, is_skills_approved_by_partner: updatedCandidate.result.is_skills_approved_by_partner };
        setData(newData);
        setParentUpdatedAt(updatedCandidate.result.updated_at)
      }
      setLoading(false)
    }
    catch (e) {
      console.log("Error found in handleSetSkillApproval::", e);
    }
  }

  const handleCopyAllRecruiterComments = async (e, checked) => {
    setLoading(true);
    if (checked) {
      let skill_result = [];
      let api_count = 0
      // wait till all the recruiter comments are copied to partner comments
      for await (const [index, item] of skills.entries()) {
        // If partner and recruiter comments are same, don't copy
        if (item.recruiter_comments !== item.partner_comments) {
          const data = { [`skill_ratings[${index}].partner_comments`]: item.recruiter_comments }
          const result = await saveCommentsOnSave(data, index, 'partner', false, true)
          api_count++
          if (result) {
            delete result.created_at;
            delete result.candidate_id;
            skill_result = [...skill_result, result];
          }
        }
      }

      if (skill_result.length) {
        let activityInfoWithSkills = { ...data }
        setCandidateActivity(activityInfoWithSkills, skill_result)
      } else {
        enqueueSnackbar(COMMENTS_VALIDATION.ALL_COMMENTS_SAME, { variant: WARNING })
      }

      if (skill_result.length !== api_count) {
        const message = unableMessage("Experience details", "update")
        enqueueSnackbar(message || message, { variant: ERROR })
      }
    }
    setLoading(false)
  }

  const setRatings = (field, value, index) => {
    setLoading(true);
    const ratingValue = { ...data.skill_ratings[index], [`${field}`]: value }
    ratingValue.elements && ratingValue.elements.forEach(ele => {
      if (!ele.candidate_skill_id) {
        ele.candidate_skill_id = ratingValue.id
      }
    })
    saveCommentsOnSave(ratingValue, index, 'rating').then(() => setLoading(false))
  }

  const getDefaultRatingValue = (field, skillIndex, index) => {
    return data.skill_ratings[skillIndex] && data.skill_ratings[skillIndex].elements && data.skill_ratings[skillIndex].elements[index] ? data.skill_ratings[skillIndex].elements[index][field] : null;
  }

  const handleElementRatingChange = async (skillIndex, eleIndex, value, field) => {
    const elements = [...skills[skillIndex].elements];
    elements[eleIndex][field] = value;
    const rating = getElementsRating(elements);
    data.skill_ratings[skillIndex].elements[eleIndex][field] = value;
    setValue(`skill_ratings[${skillIndex}].recruiter_rating`, rating);
    setValue(`skill_ratings[${skillIndex}].partner_rating`, rating);
    const ratingValue = { ...data.skill_ratings[skillIndex], 'recruiter_rating': rating, 'partner_rating': rating }
    setLoading(true);
    ratingValue.elements.forEach(ele => {
      if (!ele.candidate_skill_id) {
        ele.candidate_skill_id = ratingValue.id
      }
    })
    await saveCommentsOnSave(ratingValue, skillIndex, 'rating').then(() => setLoading(false))
    await getActivityLogInfo()
  }

  const renderElement = (item, field, index, skillIndex) => {
    return <div key={index} className='element'>
      <div className='outlined-border transform p-0'>
        <RichTextPopup
          className='input-form-field input-field-old'
          placeholder={""}
          value={item[field] || ''}
          InputLabelProps={{ focused: true }}
          expandToContent={true}
          setOpen={false}
        />
      </div>
      <RadioGroup row onChange={(e, value) => handleElementRatingChange(skillIndex, index, value, field)} name={`skill_ratings[${skillIndex}].elements[${index}][${field}]`} defaultValue={getDefaultRatingValue(field, skillIndex, index)} className='radio-group'>
        <FormControlLabel
          classes={radioClasses}
          control={<Radio size="small" value='DEFICIENT' inputRef={register()} />}
          label='Deficient'
          disabled={!item[field]}
        />
        <FormControlLabel
          classes={radioClasses}
          control={<Radio size="small" value='MEETS' inputRef={register()} />}
          label='Meets'
          disabled={!item[field]}
        />
        {field === 'minimum' &&
          <FormControlLabel
            classes={radioClasses}
            control={<Radio size="small" value='EXCEEDS' inputRef={register()} />}
            label='Exceeds'
            disabled={!item[field]}
          />}
      </RadioGroup>
    </div>
  }

  const renderElements = (elements, skillIndex) => {
    return Array.isArray(elements) ? elements.map((item, index) => (
      <div className="d-flex" key={index}>
        <div className='skill-field'>
          <InputLabel shrink focused>Minimum</InputLabel>
          {renderElement(item, 'minimum', index, skillIndex)}
        </div>
        <div className='skill-field'>
          <InputLabel shrink focused>Ideal</InputLabel>
          {renderElement(item, 'ideal', index, skillIndex)}
        </div>
      </div>
    )) : null
  }

  const updateSkillsForm = async (closeTab) => {
    try {
      const aquireData = await acquire()
      setLoading(true);
      if (aquireData) {
        let skill_result = [];
        let api_count = 0;
        for await (const [index, item] of data.skill_ratings.entries()) {
          const ratings = {
            ...item,
            'recruiter_rating': (item.recruiter_rating === null || item.recruiter_rating === undefined) ? null : item.recruiter_rating,
            'partner_rating': (item.partner_rating === null || item.partner_rating === undefined) ? null : item.partner_rating
          }
          api_count++;
          const result = await saveCommentsOnSave(ratings, index, 'rating', false, true)
          if (result) {
            delete result.created_at;
            delete result.candidate_id;
            skill_result = [...skill_result, result];
          }
        }
        const updatedCandidate = await updateCandidateInfo(data.is_skills_approved_by_partner, true, 'skill_approval', null)
        if (skill_result.length === data.skill_ratings.length && updatedCandidate.result) {
          let activityInfoWithSkills = { ...data }
          activityInfoWithSkills.is_skills_approved_by_partner = updatedCandidate.result.is_skills_approved_by_partner
          setCandidateActivity(activityInfoWithSkills, skill_result)
          setParentUpdatedAt(updatedCandidate.result.updated_at)
          const message = successMessage("Experience details", "updated")
          enqueueSnackbar(message, { variant: SUCCESS })
          if (closeTab) {
            releaseLock()
            goBack()
          }
        }
        if (skill_result.length !== api_count || !updatedCandidate.result) {

          const message = unableMessage("Experience details", "update")
          enqueueSnackbar(message || message, { variant: ERROR })
          setLoading(false)
          return false
        }
        setLoading(false)
        return true
      }
    } catch (e) {
      console.log("Error found in updateSkillsForm::", e);
    }
  }

  const actions = [
    { label: 'Save & Close', className: 'primary-btn mr-3', onClick: () => updateSkillsForm(true) },
    { label: 'Save', className: 'primary-btn', onClick: () => updateSkillsForm(false) }
  ]

  return currentValues && (
    <div>
      <div className='skills'>
        <div className='d-flex'>
          <div className='skill-fields'>
            <FormControlLabel
              className='checkbox-container'
              classes={classes}
              control={
                <Checkbox
                  className='checkbox-component'
                  defaultChecked={data.is_skills_approved_by_partner}
                  size='small'
                  style={{ padding: '0 2px' }}
                  name="is_skills_approved_by_partner"
                  inputRef={register}
                  onChange={handleSetSkillApproval}
                />
              }
              label={
                <Typography style={{ color: watch('is_skills_approved_by_partner') ? '#686A7E' : 'red', fontSize: '14px' }}>
                  Approved By Partner to Publish
                </Typography>
              }
            />
            <Typography className='section-label'>Experience</Typography>
          </div>
          <div className='d-flex flex-column' style={{ flex: '2 0' }}>
            <FormControlLabel onChange={handleCopyAllRecruiterComments} style={{ paddingRight: 35 }} className='justify-content-end' classes={classes} control={<Checkbox size='small' style={{ padding: '0 2px' }} />} label="Copy All Recruiters Comments" />
            <div className='d-flex'>
              <div className='rating-field'>
                <Typography className='rating-field section-label'>Recruiter Ratings</Typography>
                <div className='d-flex'>
                  <Typography className='rating-name'>Average Rating</Typography>
                  <Typography className='rating-value'>{getRecruiterRating()}</Typography>
                </div>
              </div>
              <div className='rating-field'>
                <Typography className='section-label'>Partner Ratings</Typography>
                <div className='d-flex'>
                  <Typography className='rating-name'>Average Rating</Typography>
                  <Typography className='rating-value'>{getPartnerRating()}</Typography>
                </div>
              </div>
            </div>
          </div>
        </div>
        {skillFields.map((item, index) => (
          <div key={item.id}>
            <div className="d-flex pb-2 " style={{ width: "59%" }}>
              <div className='outlined-border transform p-0 w-100'>
                <Typography className='d-flex title' component={"div"} style={{ flex: '3 0', marginRight: 20 }}>
                  <Typography style={{ fontSize: 'inherit', fontWeight: 'inherit', lineHeight: 'inherit', paddingRight: 5, paddingTop: 12, minWidth: 'fit-content' }} color='primary'>{`${index + 1}.`}</Typography>
                  <RichTextPopup
                    setOpen={false}
                    restrictWidth={true}
                    restrictHeight={false}
                    value={data.skills[index].title}
                  />
                </Typography>
              </div>
              <div style={{ flex: '2 0' }}></div>
            </div>
            <div className='d-flex'>
              <div className='d-flex skill-fields flex-column' style={{ flex: '3 0' }}>
                {renderElements(item.elements, index)}
              </div>
              <div className='d-flex rating-fields' style={{ flex: '2 0' }}>
                <div className='rating-field'>
                  <RatingSelection
                    value={watch(`skill_ratings[${index}].recruiter_rating`) || watch(`skill_ratings[${index}].recruiter_rating`) === 0 ? watch(`skill_ratings[${index}].recruiter_rating`) : ''}
                    autoSelect={true}
                    ref={register(`skill_ratings[${index}].recruiter_rating`)}
                    label='Rating'
                    InputLabelProps={{ focused: true, shrink: true }}
                    onChange={(e, value) => { setRatings('recruiter_rating', value, index, 'recruiter') }}
                  />
                  <div className='outlined-border transform p-0'>
                    <RichTextPopup
                      name={`skill_ratings[${index}].recruiter_comments`}
                      ref={register(`skill_ratings[${index}].recruiter_comments`)}
                      onChange={(data) => {
                        setValue(`skill_ratings[${index}].recruiter_comments`, data);
                      }}
                      commentType='recruiter'
                      item={item?.id}
                      placeholder={"Enter Comments Here"}
                      autoSave={saveCommentsOnSave}
                      onSave={(data) => saveCommentsOnSave(data, index, 'recruiter')}
                      value={data.skill_ratings[index].recruiter_comments || ''}
                      saveStatus={saveStatus}
                      title="Recruiter Comments"
                      updatedDate={data.skill_ratings[index]?.updated_at}
                      payloadType="skills"
                      field="recruiter_comment"
                      index={index}
                    />
                  </div>
                  <div className='hl-comments'>
                    <InputLabel focused={true} className="field-title" color="primary" style={{ fontSize: 14 }}><span className='mr-1'>HL Recruiter Comments</span>
                      <Tooltip title="This comment will not be available in any reports"><InfoIcon style={{ fontSize: '1rem' }} /></Tooltip>
                    </InputLabel>
                    <div className='outlined-border transform p-0'>
                      <RichTextPopup
                        value={data.skill_ratings[index].hl_recruiter_comments || ''}
                        ref={register(`skill_ratings[${index}].hl_recruiter_comments`)}
                        setOpen={false}
                      />
                    </div>
                  </div>
                </div>
                {/* watch(`skill_ratings[${index}].recruiter_comments`) */}
                <div className='rating-field'>
                  <RatingSelection
                    value={watch(`skill_ratings[${index}].partner_rating`) || watch(`skill_ratings[${index}].partner_rating`) === 0 ? watch(`skill_ratings[${index}].partner_rating`) : ''}
                    ref={register(`skill_ratings[${index}].partner_rating`)}
                    autoSelect={true}
                    label='Rating'
                    InputLabelProps={{ focused: true, shrink: true }}
                    onChange={(e, value) => { setRatings('partner_rating', value, index, 'partner') }}
                  />
                  <div className='outlined-border transform p-0'>
                    <RichTextPopup
                      name={`skill_ratings[${index}].partner_comments`}
                      ref={register(`skill_ratings[${index}].partner_comments`)}
                      onChange={(data) => {
                        setValue(`skill_ratings[${index}].partner_comments`, data);
                      }}
                      commentType='partner'
                      item={item?.id}
                      placeholder={"Enter Comments Here"}
                      autoSave={saveCommentsOnSave}
                      CopyRecruiterFlag={true}
                      onSave={(data) => saveCommentsOnSave(data, index, 'partner')}
                      value={data.skill_ratings[index].partner_comments || ''}
                      saveStatus={saveStatus}
                      title="Partner Comments"
                      updatedDate={data.skill_ratings[index]?.updated_at}
                      payloadType="skills"
                      field="partner_comment"
                      index={index}
                    // defaultValue={watch(`skill_ratings[${index}].partner_comments`) || ''}
                    />
                  </div>
                  {/* watch(`skill_ratings[${index}].partner_comments`) */}
                  <div>
                    <FormControlLabel onChange={(event, checked) => { checked && handleCopyRecruiterComments(index) }} classes={classes} control={<Checkbox size='small' style={{ padding: '0 2px' }} name="copy_recruiters" inputRef={register} />} label="Copy Recruiter’s comments" />
                  </div>
                  <div className='mt-5'>
                    <InputLabel focused={true} className="field-title" color="primary" style={{ fontSize: 14 }}><span className='mr-1'>HL Partner Comments</span>
                      <Tooltip title="This comment will not be available in any reports"><InfoIcon style={{ fontSize: '1rem' }} /></Tooltip>
                    </InputLabel>
                    <div className='outlined-border transform p-0'>
                      <RichTextPopup
                        value={data.skill_ratings[index].hl_partner_comments || ''}
                        ref={register(`skill_ratings[${index}].hl_partner_comments`)}
                        setOpen={false}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {!readOnly && <TabLayoutFooter actions={actions} />}
    </div>
  )
});

Skills.propTypes = {
  saveStatus: PropTypes.bool,
  updateCandidateInfo: PropTypes.func,
  setLoading: PropTypes.func,
  acquire: PropTypes.func,
  releaseLock: PropTypes.func,
  goBack: PropTypes.func,
  readOnly: PropTypes.bool,
  getActivityLogInfoTabData: PropTypes.func,
  setParentUpdatedAt: PropTypes.func
}

export default Skills;