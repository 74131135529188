
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from "react";

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
import { withSnackbar } from "notistack";
import Typography from "@material-ui/core/Typography";

//-----------------------------------------------------------// Internal Imports // ------------------------------

import PopoverStickOnHover from "../../../components/common/PopoverStickOnHover";
import { currencyValueSetter, getCurrencyPrefix } from "../../Commons/Utils";

const CompensationPopover = (props) => {
  const {
    contactDetails,
    children,
    baseSalary,
    currencyCode,
    targetBonus
  } = props;
  const renderPopoverContent = () => {
    return (
      <Typography
        className="pop-over-name"
        component={"div"}
        style={{ width: "auto", minWidth: 400, maxWidth: 600 }}
      >
        <div className="d-flex flex-grow-1">
          <div
            className="flex-column"
            style={{ fontSize: "20px", padding: "10px" }}
          >
            Compensation Details
          </div>
        </div>
        <div className="name-popover-content d-flex flex-grow-1">
          <div className="tab-view-content d-flex flex-column">
            <div className="section-container flex-grow-1 m-0">
              <div className="field-details">
                <span className="field-title">Base Salary</span>
                <div className="field-value">
                  <NumberFormat
                    style={{ border: "none", fontSize: "15px" }}
                    color="primary"
                    value={baseSalary}
                    thousandSeparator={true}
                    decimalSeparator="."
                    displayType="text"
                    prefix={getCurrencyPrefix(
                      currencyCode !== "RUR" ? currencyCode : ""
                    )}
                    suffix={getCurrencyPrefix(
                      currencyCode === "RUR" ? "RUR" : ""
                    )}
                    renderText={(data) => {
                      const value = currencyValueSetter(data, currencyCode);
                      return value ? value : "--";
                    }}
                  />
                </div>
              </div>
              <div className="field-details">
                <span className="field-title">Bonus</span>
                <div className="field-value">
                  <NumberFormat
                    style={{ border: "none", fontSize: "15px" }}
                    color="primary"
                    value={targetBonus}
                    thousandSeparator={true}
                    decimalSeparator="."
                    displayType="text"
                    prefix={getCurrencyPrefix(
                      currencyCode !== "RUR" ? currencyCode : ""
                    )}
                    suffix={getCurrencyPrefix(
                      currencyCode === "RUR" ? "RUR" : ""
                    )}
                    renderText={(data) => {
                      const value = currencyValueSetter(data, currencyCode);
                      return value ? value : "--";
                    }}
                  />
                </div>
              </div>
              <div className="field-details">
                <span className="field-title">Equity</span>
                <span className='field-value'>{contactDetails.equity || '--'}</span>
              </div>
              <div className="field-details">
                <span className="field-title">Compensation Notes</span>
                <span className="field-value">
                  {contactDetails.compensation_notes || "--"}
                </span>
              </div>
            </div>
          </div>
        </div>
      </Typography>
    );
  };

  return (
    <PopoverStickOnHover
      component={renderPopoverContent()}
      placement="bottom-start"
      onMouseEnter={() => { }}
      delay={100}
    >
      {children}
    </PopoverStickOnHover>
  );
};

CompensationPopover.propTypes = {
  contactDetails: PropTypes.object,
  baseSalary: PropTypes.string,
  currencyCode: PropTypes.string,
  targetBonus: PropTypes.string,
  equity: PropTypes.string,
  children: PropTypes.node,
};

export default withSnackbar(CompensationPopover);
