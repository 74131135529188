//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useCallback, useState } from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types'
import { Button, Typography } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import update from 'immutability-helper';
import DragIndicatorOutlinedIcon from '@material-ui/icons/DragIndicatorOutlined';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'

//----------------------------------------------// Internal Imports // -------------------------------------------------
import DraggableComponent from '../Draggable/DraggableComponent';
import InputField from "../../../../../common/InputField";
import ItemTypes from '../Draggable/ItemTypes';
export default function RealEstate(props) {
  const { register, setValue, currentValues } = props
  if (currentValues.cs_location_realestate.length === 0) { currentValues.cs_location_realestate = [{}] }
  const [realEstate, setRealEstate] = useState([{}])
  const skillIndex = 0;
  useEffect(() => {
    setRealEstate(currentValues.cs_location_realestate || []);
    register('cs_location_realestate')
  }, [currentValues, register])

  useEffect(() => {
    setValue('cs_location_realestate', realEstate);
  }, [realEstate, setValue])

  const updaterealestate = (index, name, data) => {
    const currentrealestate = [...realEstate];
    currentrealestate[index][name] = data;
    setRealEstate(currentrealestate)
  }

  const deleterealestate = (index) => {
    const currentrealestate = [...realEstate];
    currentrealestate.splice(index, 1);
    setRealEstate(currentrealestate)
  }

  const addrealestate = () => {
    const currentrealestate = [...realEstate, {}];
    setRealEstate(currentrealestate)
  }

  const onSkillElementsChange = useCallback((skillIndex, elements) => {
    setRealEstate(elements)
  },[])
  
  const moveElement = useCallback(
    (dragIndex, hoverIndex) => {
      const dragSkillElement = realEstate[dragIndex]
      let currentrealestate = update(realEstate, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragSkillElement],
        ],
      });
      onSkillElementsChange(skillIndex, currentrealestate)
    },
    [realEstate, skillIndex, onSkillElementsChange],
  )

  return (
    <div className="section-content">
      <Typography className="section-label">Real Estate</Typography>
      <DndProvider backend={HTML5Backend}>
        {realEstate.map((item, index) => <DraggableComponent key={index}
          index={index}
          id={item.id || index}
          moveNode={moveElement}
          componentType={ItemTypes.RE}
          content={(dragHandle, preview) => (
            <div key={index} ref={preview} style={{ marginBottom: 10 }}>
              <Typography className='sub-section-label'>Real Estate Details {index + 1}</Typography>
              <div className='d-flex align-items-center'>
                <span ref={dragHandle} >
                  <DragIndicatorOutlinedIcon style={{ padding: '0px', color: 'gray' }} />
                </span>
                <InputField
                  label='Title'
                  placeholder='Title'
                  className='input-form-field'
                  value={item.title || ''}
                  InputLabelProps={{ focused: true }}
                  onChange={(e) => {
                    updaterealestate(index, 'title', e.target.value)
                  }}
                />
                <InputField
                  label='Description'
                  placeholder='Description'
                  multiline
                  rowsMax="10"
                  className='input-form-field'
                  value={item.description || ''}
                  InputLabelProps={{ focused: true }}
                  onChange={(e) => {
                    updaterealestate(index, 'description', e.target.value)
                  }}
                />
                <InputField
                  label='Website url'
                  placeholder='Website url'
                  className='input-form-field'
                  value={item.website_url || ''}
                  InputLabelProps={{ focused: true }}
                  onChange={(e) => {
                    updaterealestate(index, 'website_url', e.target.value)
                  }}
                />
                <DeleteIcon
                  fontSize='small'
                  className="cursor-pointer"
                  onClick={() => deleterealestate(index)}
                />
              </div>
            </div>
          )}/>)
        }
      </DndProvider>
      {realEstate.length < 10 ?
        <Button
          variant={"text"}
          startIcon={<AddCircleIcon />}
          onClick={addrealestate}
          color="primary"
        >
            Add Real Estate
        </Button>
        : null
      }
    </div>
  )
}

RealEstate.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  currentValues: PropTypes.object
}
