//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useState } from "react";
import { useHistory } from 'react-router-dom';
import * as yup from "yup";
import InputField from "../common/InputField";
//----------------------------------------------// External Imports // -------------------------------------------------
import CloseIcon from '@material-ui/icons/Close'
import { useForm } from "react-hook-form";
import { Button, InputLabel, Typography } from "@material-ui/core";
import Popup from "reactjs-popup";
import PropTypes from 'prop-types';
import { useSnackbar } from "notistack";
import { customFormValidator, requireValidMessage } from '../../utils/common';
import './index.scss'

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { POST, SUCCESS, ERROR } from "../../services/constantService";
import { ProductSettingsApi } from "../../services/ApiService";
import { successMessage, unableMessage } from "../../services/MessageService";
import TextArea from "../common/TextArea";
import Loader from "../common/Loader";

const translateValueSchema = yup.object().shape({
  org_id: yup.string().required().min(1),
});


export default function AddTranslateValue() {
  const { register, handleSubmit } = useForm({})
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const addTranslateValue = async (translateData) => {
    const isValid = await translateValueSchema.isValid(translateData)
    if (!isValid) {
      let requiredField = [
        { fieldName: "org_id", label: "Organization Id", type: String }
      ]
      let dirtyField = customFormValidator(translateData, requiredField)
      if (dirtyField) {
        const message = requireValidMessage(dirtyField)
        enqueueSnackbar(message, { variant: ERROR })
        return
      }
    }
    setLoading(true)
    const { status, data } = await ProductSettingsApi(POST, translateData, 'translate_value')
    if (status === 200) {
      const message = successMessage("Translate Value", "created")
      enqueueSnackbar(message, { variant: SUCCESS });
      setLoading(false);
      history.push('/product-setup/translate-value')
    }
    else {
      const message = unableMessage("Translate Value", "create")
      enqueueSnackbar(data?.message || message, { variant: ERROR });
      setLoading(false)
    }
  }

  const onClose = () => {
    history.push('/product-setup/translate-value');
  }

  return (
    <Popup className={'add-translate-value'} open={true} modal closeOnDocumentClick={false} closeOnEscape={false}
    >
      <React.Fragment>
        <Loader show={loading} />
        <form autoComplete="off" className="position-relative">
          <div>
            <div style={{ background: '#2a364c', color: '#fff' }}>
              <div className="quick-add-header">
                <Typography >Add Translate Value</Typography>
                <CloseIcon className="cursor-pointer" onClick={onClose} />
              </div>
            </div>

            <div className="d-flex flex-column py-3">
              <div className='d-flex contact-details-row'>
                <div className='d-flex intersection-page-view'>
                  <InputLabel required={true} className="page-label">Organization Id</InputLabel>
                  <div className='page-label-value'>
                    <InputField
                      variant="outlined"
                      className='input-form-field'
                      label=''
                      placeholder='Organization Id'
                      ref={register}
                      name='org_id'
                      InputLabelProps={{ focused: true }}
                      required={true}
                      disabled
                      defaultValue='KG'

                    />
                  </div>
                </div>
                <div className='d-flex intersection-page-view'>
                  <InputLabel className="page-label">Field Name</InputLabel>
                  <div className='page-label-value'>
                    <InputField
                      variant="outlined"
                      className='input-form-field'
                      label=''
                      placeholder='Field Name'
                      ref={register}
                      name='field_name'
                      InputLabelProps={{ focused: true }}
                    />
                  </div>
                </div>
              </div>

              <div className='d-flex contact-details-row '>
                <div className='d-flex intersection-page-view'>
                  <InputLabel className="page-label">Field Value</InputLabel>
                  <div className='page-label-value'>
                    <InputField
                      variant="outlined"
                      className='input-form-field'
                      label=''
                      placeholder='Field Value'
                      ref={register}
                      name='field_value'
                      InputLabelProps={{ focused: true }}


                    />
                  </div>
                </div>
                <div className='d-flex intersection-page-view'>
                  <InputLabel className="page-label">Language CD</InputLabel>
                  <div className='page-label-value'>
                    <InputField
                      variant="outlined"
                      className='input-form-field'
                      label=''
                      placeholder='Language CD'
                      ref={register}
                      name='lang_cd'
                      InputLabelProps={{ focused: true }}
                    />
                  </div>
                </div>
              </div>

              <div className='d-flex contact-details-row '>
                <div className='d-flex intersection-page-view'>
                  <InputLabel className="page-label">Short Description</InputLabel>
                  <div className='page-label-value'>
                    <InputField
                      variant="outlined"
                      className='input-form-field'
                      label=''
                      placeholder='Short Description'
                      ref={register}
                      name='short_desc'
                      InputLabelProps={{ focused: true }}

                    />
                  </div>
                </div>
                <div className='d-flex intersection-page-view'>
                  <InputLabel className="page-label">Long Description</InputLabel>
                  <div className='page-label-value h-overflow' >
                    <TextArea
                      ref={register}
                      className="input-form-field text-area-value"
                      variant="outlined"
                      name={'long_desc'}
                      placeholder="Long Description"
                      InputLabelProps={{ focused: true }}
                    />
                  </div>
                </div>
              </div>


            </div>
            <div className="page-content-footer">
              <Button className="page-btn-cancel" onClick={onClose}>Cancel</Button>
              <Button variant="contained" type="submit" className="page-btn-save" onClick={handleSubmit(addTranslateValue)} >Submit</Button>
            </div>
          </div>
        </form>
      </React.Fragment>
    </Popup>
  )
}

AddTranslateValue.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  enqueueSnackbar: PropTypes.func,
}

