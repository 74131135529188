//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useState, useEffect, useCallback } from 'react'

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types'
import { Tabs, Tab, Button } from '@material-ui/core'
import PrintIcon from '@material-ui/icons/Print';
import { useDispatch, useSelector } from 'react-redux';

import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { useParams, useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { withSnackbar } from 'notistack';
//----------------------------------------------// Internal Imports // -------------------------------------------------
import Header from '../Header'
import Loader from '../../../common/Loader';
import Attributes from './Components/Attributes';
import { getDefaultValues, getUpdateSFPAPayload, initialSFPA } from './utils';
import Targets from './Components/Targets'
import './index.scss';
import PartnerBrief from './Components/PartnerBrief';
import Skills from './Components/Skills';
import Footer from './Components/Footer';
import ClientAppAccess from './Components/ClientAppAccess';
// import LegacySkills from './Components/LegacySkills';
// import LegacyAttributes from './Components/LegacyAttributes'
import ActionsPopover from '../../../../Containers/Contacts/ActionsPopover';
import ReportOptions from '../../../common/ReportOptions';
import { checkForPriorDecision } from '../../../../utils/date';
import { searchDataApi } from '../../../../services/ApiService';
import { PUT, GET, API, ERROR, SUCCESS, WARNING, SFPA_BUILDER_VALIDATE, HELIA } from "../../../../services/constantService";
import { VALIDATION_MESSAGE, unableMessage, successMessage, notFoundMessage, validateMessage } from '../../../../services/MessageService';
import { localTimeZoneHandler } from '../../../../Containers/Commons/Utils';
import { UPDATE_SFPADATA, UPDATE_TIMEZONE_DATA } from '../../../../types';
import { fetchCurrencyDetails } from '../../../../actions';
import { searchActions } from '../../../../store/searchSlice';
import SfpaAutomationPopup from './Components/SfpaAutomation';
import ConfirmationPopup from '../../../ConfirmationPopup';
import Decisions from './Components/Decisions';

function TabPanel(props) {
  const { children, value, index } = props;
  return (
    value === index && <React.Fragment>
      {children}
    </React.Fragment>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

const TabLayout = (props) => {
  const { children, value } = props;
  console.log(children.filter(item => item.key))
  return children.filter(item => item).map((item, index) => {
    return <TabPanel key={index} index={index} value={value}>
      {item}
    </TabPanel>
  })
}

const RICH_TEXT_FIELDS = ['benchmark_feedback', 'summary_feedback', 'executive_feedback', 'partner_brief', 'attributes']
const SFPA = (props) => {
  const { search = {}, jobTitle, currentSfpaIndex, confidential, onValueChange, enqueueSnackbar, closeSnackbar, setSFPADirty, setUpdateSFPA, onClose, readOnly, acquire, sfpaNavItems = [] } = props;
  const { id } = useParams();
  const [currentValues, setCurrentValues] = useState({})
  const { register, setValue, unregister, getValues, reset, watch, formState } = useForm()
  const { dirty } = formState;
  const [value, setTabValue] = useState();
  const [sfpa, setSFPA] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [isReportsOpen, setIsReportsOpen] = useState(false);
  const [reportsParams, setReportsParams] = useState({});
  const [startDate, setStartDate] = useState(false);
  const [timeZoneOptions, setTimeZoneOptions] = useState([]);
  const [defaultLocalTime, setDefaultLocalTime] = useState({})
  const [isSfpaAutomation, setSfpaAutomation] = useState(false);
  const [isSfpaAutoDataGenerated, setSfpaAutoDataGenerated] = useState(false);
  const [searchValidatePopup, setSearchValidatePopup] = useState(false);
  const [invalidSRBValidateMessage, setInvalidSRBValidateMessage] = useState(null);
  const [invalidSRBValidateHeader, serInvalidSRBValidateHeader] = useState(null)
  const [goToUrl, setGoToUrl] = useState(null);
  const [validateAction, setValidateAction] = useState(null);
  const timezone = useSelector(state => state.rootReducer.timezone);
  const sfpaData = useSelector(state => state.rootReducer.sfpaData);
  const dispatch = useDispatch()
  const currencyDetails = useSelector(state => state.commonReducer[watch('currency')] ? state.commonReducer[watch('currency')] : {})

  const history = useHistory();

  useEffect(() => {
    if (value) {
      setTabValue(currentSfpaIndex)
    }
    else {
      setTabValue(currentSfpaIndex || 0)
    }
  })

  useEffect(() => {
    // Initially Render tab components after load sfpa and set currentValues
    if (sfpa) {
      const defaultValues = getDefaultValues(sfpa);
      setCurrentValues(defaultValues)
      reset(defaultValues)
    }
  }, [sfpa, setCurrentValues, reset])

  const getStatus = useCallback(() => {
    const status = checkForPriorDecision(search?.start_date);
    if (status) {
      setStartDate(status);
    }
  }, [search])
  useEffect(() => {
    const currencyHandler = async () => {
      try {
        await fetchCurrencyDetails(watch('currency'), dispatch)
      } catch (e) {
        console.log("Error found in currencyHandler::", e);
      }
    }
    if (watch('currency') && Object.keys(currencyDetails).length === 0) {
      currencyHandler()
    }

  }, [watch('currency')])

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!timezone) {
          const data = await localTimeZoneHandler(timeZoneOptions)
          dispatch({ type: UPDATE_TIMEZONE_DATA, payload: { key: 'timezone', data } })
          setTimeZoneOptions(data.timeZoneOptions);
          setDefaultLocalTime(data.defaultLocalTime);
        } else {
          setTimeZoneOptions(timezone.timeZoneOptions);
          setDefaultLocalTime(timezone.defaultLocalTime);
        }
      } catch (e) {
        console.log("Error found in fetchData::", e);
      }
    }
    if (!timeZoneOptions?.length) {
      fetchData()
    }
  }, [timeZoneOptions, timezone, dispatch])

  const getData = useCallback(async (loading = true, returnData) => {
    loading && setLoading(loading);
    const { status, data } = await searchDataApi(GET, id, {}, `sfpa?locale=${navigator.language}`);
    if (status && status === 200) {
      if (data) {
        if (data.skills && data.skills.length === 0) {
          data.skills = [{}]
        }
        if (data.off_limits === null) {
          data.off_limits = []
        }
        if (data.target_industries === null) {
          data.target_industries = []
        }
        if (data.target_locations === null) {
          data.target_locations = []
        }
        if (data.target_companies === null) {
          data.target_companies = []
        }
        if (returnData) return data;
        dispatch({ type: UPDATE_SFPADATA, payload: { key: 'sfpaData', data } })
        setSFPA(data);
      }
      else {
        if (returnData) return initialSFPA;
        setSFPA(initialSFPA)
      }
    } else {
      const message = unableMessage("Search Roadmap", "fetch")
      enqueueSnackbar(data?.message || message, { variant: ERROR });
    }
    setLoading(false);
  }, [id, enqueueSnackbar])

  useEffect(() => {
    if (!sfpaData) {
      getData()
    }
    else {
      if (sfpaData.skills && sfpaData.skills.length === 0) {
        sfpaData.skills = [{}]
      }
      if (sfpaData.off_limits === null) {
        sfpaData.off_limits = []
      }
      if (sfpaData.target_industries === null) {
        sfpaData.target_industries = []
      }
      if (sfpaData.target_locations === null) {
        sfpaData.target_locations = []
      }
      if (sfpaData.target_companies === null) {
        sfpaData.target_companies = []
      }
      setSFPA(sfpaData);
    }
    if (search && search.start_date === null) {
      setStartDate(true);
    }
    if (search?.start_date) {
      getStatus();
    }
  }, [search, getData, getStatus])

  const reportURL = () => {
    if (value === 1) {
      const updateURL = `${API.reports}/skill-report/?searchId=${id}`
      window.open(updateURL);
    }
    else if (value === 2) {
      const updateURL = `${API.reports}/attribute-report/?searchId=${id}`
      window.open(updateURL);
    }
    onClose && onClose();
  }

  const validateSearch = () => {
    let fields = [];
    if (!search?.company_id) {
      fields = [...fields, 'Company']
    }
    if (!search?.country) {
      fields = [...fields, 'Location']
    }
    if (!fields.length) {
      return true
    }

    const message = `The ${fields.join(', ')} field has missing or incomplete data`
    serInvalidSRBValidateHeader(SFPA_BUILDER_VALIDATE)
    setInvalidSRBValidateMessage(message);
    setSearchValidatePopup(true);
    setGoToUrl(`/searches/${search.id}/client-info`)
    setValidateAction(`Go to Search`)
    return false
  }

  const validateSfpa = () => {
    const sfpaFormData = getValues()
    if (sfpaNavItems[value] === 'Scope') {
      if (sfpaFormData?.helia_title) return true;
    } else if (sfpaData?.helia_title) return true;

    const message = `Helia Title is required to use the Search Roadmap Builder.`;
    setGoToUrl(`/searches/${search.id}/search-roadmap/scope`)
    setValidateAction(`Go to Scope`)
    serInvalidSRBValidateHeader(message);
    setInvalidSRBValidateMessage('Please ensure changes have been saved before proceeding.');
    setSearchValidatePopup(true);
    return false
  };

  const sfpaAutomation = async () => {
    if (sfpaNavItems[value] === 'Scope' && dirty) {
      await updateSFPA(value, dirty)
    }
    let isValid = validateSfpa()
    if (!isValid) return false;

    isValid = validateSearch()
    if (!isValid) return false;

    setSfpaAutomation(true);
  }

  const handleChange = async (event, newValue, oldValue) => {
    try {
      reset({ ...currentValues, ...getValues({ nest: true }) })
      setCurrentValues(prevState => ({ ...prevState, ...getValues({ nest: true }) }))
      let isDirtyFields = [...formState.dirtyFields]
        .filter(item => !RICH_TEXT_FIELDS.includes(item))
      // if want to display button keep this code and remove code from footer file
      // if(newValue<5|| (startDate && newValue>=5))
      if (!readOnly && dirty && isDirtyFields.length > 0) {
        await updateSFPA(oldValue, dirty)
      }
      onValueChange(newValue);
      setTabValue(newValue);
    } catch (e) {
      console.log("Error found in handleChange::", e);
    }
  };

  // useEffect(() => {
  //   getData()
  // }, [search, getData])

  useEffect(() => {
    getData()
    setSfpaAutoDataGenerated(false);
  }, [isSfpaAutoDataGenerated])

  const setSfpaData = (field) => {
    let newSfpaData = { ...sfpaData, ...field }
    if (Object.prototype.hasOwnProperty.call(field, 'attributes')) {
      newSfpaData.attributes = newSfpaData.attributes?.map(item => {
        return {
          ...item,
          attribute: {
            id: item.id,
            definition: item.definition,
            name: item.name
          },
        }
      })
    }
    dispatch({ type: UPDATE_SFPADATA, payload: { key: 'sfpaData', data: newSfpaData } })
    // It impacts on update latest search info tab data 
    dispatch(searchActions.resetSearchState());
  }
  // useEffect(() => {
  //   getData().then(null)
  // }, [getData])

  const goToTab = (oldTabNUmber, tabNumber) => {
    if (tabNumber < 0 || tabNumber > 8) {
      return;
    }
    handleChange(null, tabNumber, oldTabNUmber)
  }

  const checkValidWeight = (field, rankingArray) => {
    if (rankingArray && rankingArray.length > 0) {
      const myarray = rankingArray.map(item => item.ranking).filter(item => item > 101 || item < 0)
      if (myarray.length > 0) {
        const message = validateMessage(`valid ${field} weight`, 'enter');
        enqueueSnackbar(message, { variant: WARNING });
        return false
      }
    }
    return true
  }

  const handleCloseSfpaAutomation = () => {
    setSfpaAutomation(false)
  }
  const handleConfirmSearchPopup = () => {
    handleCloseSearchPopup()
    history.push(goToUrl);
  }


  const handleCloseSearchPopup = () => {
    setSearchValidatePopup(false);
    serInvalidSRBValidateHeader('')
    setInvalidSRBValidateMessage('');
    setGoToUrl(null)
    setValidateAction(null)
  }

  const updateSFPA = useCallback(async (oldValue, dirty) => {
    try {
      console.log('oldValue', oldValue)
      const data = await acquire(id)
      if (data) {
        let isDirtyFields = [...formState.dirtyFields]
          .filter(item => !RICH_TEXT_FIELDS.includes(item))
        if (dirty && isDirtyFields.length > 0) {
          const formValues = Object.assign(currentValues, getValues({ nest: true }))
          if (formValues.helia_title) {
            if (sfpa.helia_title !== formValues.helia_title) {
              formValues.contact_targets = [...formValues.contact_targets, { title: formValues.helia_title },];
            }
          }
          let payload = getUpdateSFPAPayload(formValues, id);
          if (typeof payload.max_years_of_experience_current_company === "string") {
            payload.max_years_of_experience_current_company = parseInt(payload.max_years_of_experience_current_company)
            if (isNaN(payload.max_years_of_experience_current_company)) {
              payload.max_years_of_experience_current_company = null
            }
          }
          if (payload.max_years_of_experience_current_company !== "") {
            const yearsValue = payload.max_years_of_experience_current_company;

            // const yearsValue = (payload.max_years_of_experience_current_company)
            if (yearsValue < 0 || yearsValue > 99) {
              const message = validateMessage(`valid max year of experience`, "enter")
              enqueueSnackbar(message, { variant: ERROR });
              return
            }
          }
          if (payload.years_of_experience !== "") {
            const yearsValue = payload.years_of_experience;
            if (yearsValue < 0 || yearsValue > 99) {
              const message = validateMessage(`valid min year of experience`, "enter")
              enqueueSnackbar(message, { variant: ERROR });
              return
            }
          }
          // Validate Weight
          const validIndustryRanking = checkValidWeight('industry', payload.search_industry_rankings)
          if (!validIndustryRanking) return;
          const validCountryRanking = checkValidWeight('country', payload.search_target_country_rankings)
          if (!validCountryRanking) return;

          payload.updated_at = sfpa.updated_at
          setLoading(true);
          delete payload.cs_location_placeofinterest
          delete payload.cs_location_realestate
          delete payload.cs_location_restaurantsandshopping
          delete payload.cs_location_schoolsandcolleges
          delete payload.cs_location_weather
          delete payload.target_industries_group
          delete payload.target_country_group

          // if (oldValue === 3 || value === 3) {
          //   delete payload.client_team
          //   delete payload.attributes
          //   delete payload.company
          //   delete payload.cs_location_placeofinterest
          //   delete payload.cs_location_realestate
          //   delete payload.cs_location_restaurantsandshopping
          //   delete payload.cs_location_schoolsandcolleges
          //   delete payload.cs_location_weather
          //   delete payload.skills
          // } else if (oldValue === 2 || value === 2) {
          //   payload = { skills: payload.skills }
          // } else if (oldValue === 1 || value === 1) {
          //   payload = { attributes: payload.attributes }
          // } else if (oldValue === 4 || value === 4) {
          //   payload = { client_team: payload.client_team }
          // }
          const { status, data } = await searchDataApi(PUT, id, payload, 'sfpa');
          if (status && status === 200) {
            const message = successMessage("Search Roadmap", VALIDATION_MESSAGE.updated_message)
            enqueueSnackbar(data?.message || message, { variant: SUCCESS });
            if (data.errorDetails) {
              const message = unableMessage("in HL", "update data")
              enqueueSnackbar(message, { variant: ERROR });
            }
            await getData();
            // It impacts on update latest search info tab data 
            dispatch(searchActions.resetSearchState());
          } else if (status === 409) {
            const message = unableMessage("activity info", "save")
            enqueueSnackbar(data?.message || message,
              {
                persist: true,
                variant: ERROR,
                // eslint-disable-next-line react/display-name
                action: key => (
                  <Button
                    size='small'
                    variant='text'
                    color='inherit'
                    onClick={() => { closeSnackbar(key) }}
                  >
                    Dismiss
                  </Button>
                )
              }
            );
          } else {
            const message = unableMessage("Search Roadmap", "update")
            enqueueSnackbar(data?.message || message, { variant: ERROR });
          }
          setLoading(false);

        }
        else {
          const message = notFoundMessage('Changes')
          enqueueSnackbar(message, { variant: WARNING });
        }
      }
    } catch (e) {
      console.log("Error found in updateSFPA::", e);
    }
  }, [currentValues, sfpa, id, getValues, enqueueSnackbar, closeSnackbar, getData])

  useEffect(() => {
    setSFPADirty(dirty)
  }, [dirty, setSFPADirty])

  useEffect(() => {
    setUpdateSFPA(updateSFPA)
  }, [setUpdateSFPA, updateSFPA])

  const buildNavItems = (navItems) => {
    return navItems.map((item, index) => {
      // eslint-disable-next-line react/display-name
      return <Tab key={index} component={React.forwardRef((params, ref) => {
        return (
          <div ref={ref} className="details-section align-items-center" style={{ color: value === index ? '#DE9F6D' : '' }} onClick={params.onClick}>
            {item}
          </div>
        )
      })} />
    });
  }

  return (
    <div className="position-relative h-100" style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Loader show={isLoading} />
      {isReportsOpen && <ReportOptions timeZoneOptions={timeZoneOptions} defaultLocalTime={defaultLocalTime} onClose={() => { setIsReportsOpen(false); setReportsParams({}) }} {...reportsParams} />}
      {(sfpa && Object.keys(currentValues).length) && <>
        <Header
          companyDetails={search.company}
          company={search.company && search.company.name}
          jobNumber={search.job_number}
          jobTitle={jobTitle}
          confidential={confidential}
        >
          <div className="d-flex">
            {!readOnly && <div className="action-container d-flex align-items-center" onClick={sfpaAutomation}>  <span className="material-icons psychology-icon action-icon">psychology</span><span className="action-text">Search Roadmap Builder</span>
            </div>}
            {isSfpaAutomation
              &&
              <SfpaAutomationPopup
                onClose={handleCloseSfpaAutomation}
                search={search}
                sfpa={sfpa}
                enqueueSnackbar={enqueueSnackbar}
                setSfpaAutoDataGenerated={setSfpaAutoDataGenerated}
              />
            }
            {
              [1, 2].includes(value) && (<div className="action-container d-flex align-items-center" onClick={reportURL}>
                <span className="action-icon">
                  <PrintIcon fontSize="inherit" />
                </span>
                <span className="action-text">Print</span>
              </div>)
            }

            <ActionsPopover
              className="action-container d-flex align-items-center"
              label={
                <div className="action-container d-flex align-items-center">
                  <span className="action-icon">
                    <InsertDriveFileIcon fontSize="inherit" />
                  </span>
                  <span className="action-text">Search Roadmap Reports</span>
                </div>
              }
              list={[{
                label: 'Athena Comparison Report Candidates',
                onClick: () => {
                  setIsReportsOpen(true);
                  setReportsParams({
                    params: {
                      "searchId": id,
                      timeZone: false
                    },
                    url: `${API.reports}/athena-benchmark-comparison-multiple-candidates`
                  })
                }
              }, {
                label: 'Attribute Selector Comparison Report',
                onClick: () => {
                  setIsReportsOpen(true);
                  setReportsParams({
                    params: {
                      "searchId": id,
                      timeZone: false
                    },
                    options: {
                      pageSize: true,
                      locale: false,
                    },
                    url: `${API.reports}/attribute-selector-results-comparison`
                  })
                }
              }, {
                label: 'Athena Executive Summary Report',
                onClick: () => {
                  setIsReportsOpen(true);
                  setReportsParams({
                    params: {
                      "searchId": id,
                      timeZone: false
                    },
                    url: `${API.reports}/athena-executive-summary`
                  })
                }
              }, {
                label: 'Athena Summary Report Package',
                onClick: () => {
                  setIsReportsOpen(true);
                  setReportsParams({
                    params: {
                      "searchId": id,
                      timeZone: false
                    },
                    url: `${API.reports}/athena-summary-package`
                  })
                }
              },
              search?.helia_version !== HELIA.VERSION_2 && {
                label: 'Search Roadmap Report (Legacy) ',
                onClick: () => {
                  setIsReportsOpen(true);
                  setReportsParams({
                    params: {
                      "searchId": id,
                      timeZone: true
                    },
                    url: `${API.reports}/sfpa-report`
                  })
                }
              },
              search?.helia_version === HELIA.VERSION_2 && {
                label: 'Search Roadmap',
                onClick: () => {
                  setIsReportsOpen(true);
                  setReportsParams({
                    params: {
                      "searchId": id,
                      "athenaVersion": search?.helia_version,
                      "oneLanguageSupport": false
                    },
                    url: `${API.reports}/success-profile-agreement-report`
                  })
                }
              },
              {
                label: 'Position Profile',
                onClick: () => {
                  setIsReportsOpen(true);
                  setReportsParams({
                    params: {
                      "searchId": id,
                      timeZone: false
                    },
                    url: `${API.reports}/position-profile-report`
                  })
                }
              }
              ]}
            />
            {value !== 0 && !readOnly && <Button color='primary' className='button' variant='contained' onClick={() => updateSFPA(undefined, dirty)}>Save</Button>}
          </div>
          {searchValidatePopup &&
            <ConfirmationPopup
              header={invalidSRBValidateHeader}
              message={invalidSRBValidateMessage}
              onClose={handleCloseSearchPopup}
              onConfirm={handleConfirmSearchPopup}
              onCancel={handleCloseSearchPopup}
              confirmText={validateAction}
              cancelText='Close'
            />
          }
        </Header>
        <Tabs
          value={value}
          indicatorColor="secondary"
          textColor="secondary"
          style={{ background: '#f4f4f5', flex: 1, overflow: 'auto' }}
          onChange={(e, newValue) => handleChange(e, newValue, value)}
          aria-label="disabled tabs example"
        >


          {buildNavItems(sfpaNavItems)}
          {/* {startDate && (
            // eslint-disable-next-line react/display-name 
            <Tab component={React.forwardRef((params, ref) => {
              return (
                <div ref={ref} className="details-section align-items-center" style={{ color: value === 5 ? '#DE9F6D' : '' }} onClick={params.onClick}>
                  <span className="section-icon">
                  </span>
                  Legacy Skills
                </div>
              )
            })} />
          )} */}
          {/* {startDate && (
            // eslint-disable-next-line react/display-name
            <Tab component={React.forwardRef((params, ref) => {
              return (
                <div ref={ref} className="details-section align-items-center" style={{ color: value === 6 ? '#DE9F6D' : '' }} onClick={params.onClick}>
                  <span className="section-icon">
                  </span>
                  Legacy Attributes
                </div>
              )
            })} />
          )}
          
          )} */}
        </Tabs>
        <div className="company-view-container">
          <div style={{ padding: 0, flex: 1, overflow: 'auto', height: 'calc(100% - 70px)' }}>
            <TabLayout value={value} >
              {(search?.helia_version !== HELIA.VERSION_1) && (
                <Decisions readOnly={readOnly} register={register} setValue={setValue} currentValues={currentValues} searchInfo={search} />
              )}
              <Attributes key={1} readOnly={readOnly} currentValues={currentValues} register={register} setValue={setValue} getData={getData} searchCreatedAt={search.created_at} setSfpaData={setSfpaData} setCurrentValues={setCurrentValues} setLoading={setLoading} enqueueSnackbar={enqueueSnackbar} heliaVersion={search.helia_version} />
              <Skills key={2} readOnly={readOnly} currentValues={currentValues} setValue={setValue} register={register} getData={getData} setLoading={setLoading} setCurrentValues={setCurrentValues} setSfpaData={setSfpaData} enqueueSnackbar={enqueueSnackbar} />
              <Targets key={3} readOnly={readOnly} currencyDetails={currencyDetails} register={register} unregister={unregister} watch={watch} setValue={setValue} currentValues={currentValues} />
              <PartnerBrief key={4} readOnly={readOnly} searchInfo={search} register={register} setValue={setValue} currentValues={currentValues} setCurrentValues={setCurrentValues} setSfpaData={setSfpaData} />
              <ClientAppAccess key={5} readOnly={readOnly} setCurrentValues={setCurrentValues} setSfpaData={setSfpaData} searchInfo={search} register={register} setValue={setValue} currentValues={currentValues} watch={watch} />
              {/* <TabPanel index={5} value={value}>
              <LegacySkills readOnly={readOnly} currentValues={currentValues} />
            </TabPanel>
            <TabPanel index={6} value={value}>
              <LegacyAttributes readOnly={readOnly} currentValues={currentValues} />
            </TabPanel>
            
            </TabPanel> */}
            </TabLayout>
          </div >
          <Footer prev={() => goToTab(value, value - 1)} next={() => goToTab(value, value + 1)} value={value} startDate={startDate} />
        </div >
      </>}
    </div >
  )
}

SFPA.propTypes = {
  id: PropTypes.string,
  search: PropTypes.object,
  jobTitle: PropTypes.string,
  confidential: PropTypes.bool,
  readOnly: PropTypes.bool,
  enqueueSnackbar: PropTypes.func,
  acquire: PropTypes.func,
  closeSnackbar: PropTypes.func,
  setSFPADirty: PropTypes.func,
  setUpdateSFPA: PropTypes.func,
  onClose: PropTypes.func,
  currentSfpaIndex: PropTypes.number,
  onValueChange: PropTypes.func,
  sfpaNavItems: PropTypes.array
}

export default withSnackbar(SFPA);