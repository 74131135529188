//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { useSelector } from "react-redux";
import MenuIcon from '@material-ui/icons/Menu';
import { withRouter, Link } from 'react-router-dom';
import HelpOutlineRoundedIcon from '@material-ui/icons/HelpOutlineRounded';
import { Button } from "@material-ui/core";
//----------------------------------------------// Internal Imports // -------------------------------------------------
import './index.css';
import SideNavItem from './SideNavItem';
import { getNavItemsFromPath } from './sideNavData';
import { getUserSelector } from '../../selectors';
//import AddContactsToWorkbench from '../../components/ViewWorkbench/Components/AddContactToWorkbench'
import AddLinkedinPopup from '../../Containers/Commons/AddLinkedinPopup';
import { checkRegionRole } from '../../Containers/Commons/Utils';

function SideNav(props) {
  const { location } = props;
  const { addAction, addLinkedinAction, navItems = [], isShow = true } = getNavItemsFromPath(location.pathname);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isRegional, setIsRegional] = useState(false);
  const [isLinkedinPopup, setIsLinkedinPopup] = useState(false)
  const userData = useSelector(getUserSelector)

  useEffect(() => {
    const isRegional = checkRegionRole(userData)
    if (isRegional) {
      setIsRegional(true)
    }
  }, [userData]);

  const getNavItems = navItems => {
    return navItems.map((item, index) => {
      if (Object.prototype.hasOwnProperty.call(item, "header")) {
        return <div className="side-nav-group" key={item.header}>
          <span className="side-nav-header">{item.header}</span>
          {getNavItems(item.list)}
        </div>
      } else {
        return <SideNavItem
          info={item}
          key={index}
          isActive={true}
          isRegional={isRegional ? true : item.isHide ? false : true}
        />
      }
    });
  };

  const toggleMenu = () => {
    setIsMenuOpen(prev => !prev);
  };

  const linkedinPopup = () => {
    setIsLinkedinPopup(true)
  }

  const handleClose = () => {
    setIsLinkedinPopup(false)
  }

  const openWidget = () => {
    window.zE("webWidget", "show")
    window.zE("webWidget", "open")
    document.querySelector("#zdLauncher").style.opacity = 0
  }

  return (

    (location.pathname.includes('/users') && userData.roles[0] === 'admin' && isShow) || (!location.pathname.includes('/users') && isShow) ?
      <>
        <div className={`side-nav-container ${isMenuOpen ? "open" : 'closed'}`}>
          <div className="side-nav-bar d-flex flex-column">
            <div className="d-flex">
              <div className="d-flex side-nav-top-actions">
                <span className="hamburger-icon d-inline-block" onClick={toggleMenu}>
                  <MenuIcon fontSize="inherit" color="primary" />
                </span>
                <div className="d-flex flex-column">
                  {addAction && <Link to={addAction.route}><button className="primary-btn add-new">{addAction.label}</button></Link>}
                  {addLinkedinAction && <button onClick={linkedinPopup} className="linkedin-btn add-new">{addLinkedinAction.label}</button>}
                  {
                    isLinkedinPopup &&
                    <AddLinkedinPopup
                      open={isLinkedinPopup}
                      handleClose={handleClose}
                      title='Contacts'
                    />
                  }
                </div>
              </div>
            </div>
            <div className="side-nav-items flex-column flex-grow-1">{getNavItems(navItems)}</div>
            <Button
              startIcon={<HelpOutlineRoundedIcon />}
              className={isMenuOpen ? '' : 'collapsedBtn'}
              color="primary"
              onClick={openWidget}
              id="zdLauncher"
            >
              {isMenuOpen ? 'Help' : ''}
            </Button>
          </div>
        </div>
      </>
      : null
  );
}

SideNav.propTypes = {
  location: PropTypes.object,
}

export default withRouter(SideNav);
