
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from "react";

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from "prop-types";
import Autocomplete from "@material-ui/lab/Autocomplete";

//-----------------------------------------------------------// Internal Imports // ------------------------------

import InputField from "../../common/InputField";

export default function ReferenceStatusSelection(props) {
  const {label = "", placeholder = "", InputLabelProps, required = false, inputRef, ...rest} = props;

  const options = ['Identified', 'Scheduled', 'Completed']

  return (
    <Autocomplete
      {...rest}
      options={options}
      getOptionLabel={(option) => option}
      renderInput={(params) => (
        <InputField
          {...params}
          required={required}
          variant="standard"
          ref={inputRef}
          InputLabelProps={InputLabelProps}
          label={label}
          placeholder={placeholder}
        />
      )}
    />
  )
}

ReferenceStatusSelection.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  InputLabelProps: PropTypes.object,
  required: PropTypes.bool,
  inputRef: PropTypes.func
};
