//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useState, useEffect, useCallback, useRef } from 'react'

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types'
import { Button } from '@material-ui/core'
import PrintIcon from '@material-ui/icons/Print';
import { useParams } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import Grid from "@material-ui/core/Grid";
import { useForm } from 'react-hook-form';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { useDispatch, useSelector } from 'react-redux';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import Header from '../Header'
import Loader from '../../../common/Loader';
import Weather from "./Components/Weather";
import PlacesOfInterest from "./Components/PlacesOfInterest";
import SchoolsAndColleges from "./Components/SchoolsAndColleges";
import RestaurantsAndShopping from "./Components/RestaurantsAndShopping";
import RealEstate from "./Components/RealEstate";
import { getDefaultValues, getUpdateSFPAPayload, initialSFPA } from './utils';
import './index.scss';
import ReportOptions from '../../../common/ReportOptions';
import ActionsPopover from '../../../../Containers/Contacts/ActionsPopover';
import { searchDataApi } from '../../../../services/ApiService';
import { PUT, GET, API, ERROR, WARNING, SUCCESS, HELIA } from "../../../../services/constantService";
import { unableMessage, validateMessage, successMessage, notFoundMessage, VALIDATION_MESSAGE } from '../../../../services/MessageService';
import { localTimeZoneHandler } from '../../../../Containers/Commons/Utils';
import { UPDATE_SFPADATA, UPDATE_TIMEZONE_DATA } from '../../../../types';
import { isValidURL } from '../../../../utils/common';
const CSLocInfo = (props) => {
  const { search = {}, jobTitle, confidential, enqueueSnackbar, closeSnackbar, setSFPADirty, setUpdateSFPA } = props;
  const { id } = useParams();
  const [currentValues, setCurrentValues] = useState({})
  const { register, setValue, getValues, reset, formState } = useForm()
  const { dirty } = formState;
  const [sfpa, setSFPA] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [isReportsOpen, setIsReportsOpen] = useState(false);
  const [reportsParams, setReportsParams] = useState({});
  const error = useRef(false);
  const [timeZoneOptions, setTimeZoneOptions] = useState([]);
  const [defaultLocalTime, setDefaultLocalTime] = useState({})
  const dispatch = useDispatch()
  const timezone = useSelector(state => state.rootReducer.timezone)
  const sfpaData = useSelector(state => state.rootReducer.sfpaData)
  useEffect(() => {
    const defaultValues = getDefaultValues(sfpa);
    setCurrentValues(defaultValues)
    reset(defaultValues)
  }, [sfpa, setCurrentValues, reset])

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!timezone) {
          const data = await localTimeZoneHandler(timeZoneOptions)
          dispatch({ type: UPDATE_TIMEZONE_DATA, payload: { key: 'timezone', data } })
          setTimeZoneOptions(data.timeZoneOptions);
          setDefaultLocalTime(data.defaultLocalTime);
        }
        else {
          setTimeZoneOptions(timezone.timeZoneOptions);
          setDefaultLocalTime(timezone.defaultLocalTime);
        }
      } catch (e) {
        console.log("Error found in fetchData::", e);
      }
    }
    if (!timeZoneOptions.length) {
      fetchData()
    }
  }, [timeZoneOptions])
  const getData = useCallback(async () => {
    setLoading(true);
    const { status, data } = await searchDataApi(GET, id, {}, 'sfpa');
    if (status && status === 200) {
      if (data) {
        if (data.skills && data.skills.length === 0) {
          data.skills = [{}]
        }
        if (data.off_limits === null) {
          data.off_limits = []
        }
        if (data.target_industries === null) {
          data.target_industries = []
        }
        if (data.target_locations === null) {
          data.target_locations = []
        }
        if (data.target_companies === null) {
          data.target_companies = []
        }
        dispatch({ type: UPDATE_SFPADATA, payload: { key: 'sfpaData', data } })
        setSFPA(data);
      }
      else {
        setSFPA(initialSFPA)
      }
    } else {
      const message = unableMessage("Search Roadmap", "fetch")
      enqueueSnackbar(data?.message || message, { variant: ERROR });
    }
    setLoading(false);
  }, [id, enqueueSnackbar])

  useEffect(() => {
    if (!sfpaData) {
      getData()
    }
    else {
      if (sfpaData.skills && sfpaData.skills.length === 0) {
        sfpaData.skills = [{}]
      }
      if (sfpaData.off_limits === null) {
        sfpaData.off_limits = []
      }
      if (sfpaData.target_industries === null) {
        sfpaData.target_industries = []
      }
      if (sfpaData.target_locations === null) {
        sfpaData.target_locations = []
      }
      if (sfpaData.target_companies === null) {
        sfpaData.target_companies = []
      }
      setSFPA(sfpaData);
    }
  }, [search, getData])

  // useEffect(() => {
  //   getData().then(null)
  // }, [getData])

  const printSFPA = () => {
    setIsReportsOpen(true);
    setReportsParams({
      params: {
        "searchId": id,
      },
      options: {
        pageSize: true,
        locale: false,
      },
      url: `${API.reports}/sfpa-report`
    })
  }
  const checkError = (array) => {
    array.forEach(item => {
      if (item.title) {
        if (item.website_url === undefined || item.website_url === null || item.website_url === "") {
          error.current = true
        }
      }
    })
    return error.current
  }

  const ChecksUrlValid = (dataUrl) => {
    for (var i = 0; i < dataUrl.length; i++) {
      var url = dataUrl[i];
      if (url.website_url !== undefined && url.website_url !== null && url.website_url !== "") {
        let isWebsiteUrl = isValidURL(url.website_url)
        if (!isWebsiteUrl) {
          return false;
        }
      }
    }
    return true;
  }

  const updateSFPA = useCallback(async (dirty) => {
    try {
      if (dirty) {
        error.current = false
        const formValues = Object.assign(currentValues, getValues({ nest: true }))
        if (formValues.cs_location_weather.length !== 0) {
          checkError(formValues.cs_location_weather)
          const isValidUrl = ChecksUrlValid(formValues.cs_location_weather)
          if (!isValidUrl) {
            const message = validateMessage("valid Website URL", "enter")
            enqueueSnackbar(message, { variant: ERROR });
            return false
          }
        }
        if (formValues.cs_location_placeofinterest.length !== 0) {
          checkError(formValues.cs_location_placeofinterest)
          const isValidUrl = ChecksUrlValid(formValues.cs_location_placeofinterest)
          if (!isValidUrl) {
            const message = validateMessage("valid Website URL", "enter")
            enqueueSnackbar(message, { variant: ERROR })
            return
          }
        }
        if (formValues.cs_location_schoolsandcolleges.length !== 0) {
          checkError(formValues.cs_location_schoolsandcolleges)
          const isValidUrl = ChecksUrlValid(formValues.cs_location_schoolsandcolleges)
          if (!isValidUrl) {
            const message = validateMessage("valid Website URL", "enter")
            enqueueSnackbar(message, { variant: ERROR })
            return
          }
        }
        if (formValues.cs_location_restaurantsandshopping.length !== 0) {
          checkError(formValues.cs_location_restaurantsandshopping)
          const isValidUrl = ChecksUrlValid(formValues.cs_location_restaurantsandshopping)
          if (!isValidUrl) {
            const message = validateMessage("valid Website URL", "enter")
            enqueueSnackbar(message, { variant: ERROR })
            return
          }
        }
        if (formValues.cs_location_realestate.length !== 0) {
          checkError(formValues.cs_location_realestate)
          const isValidUrl = ChecksUrlValid(formValues.cs_location_realestate)
          if (!isValidUrl) {
            const message = validateMessage("valid Website URL", "enter")
            enqueueSnackbar(message, { variant: ERROR })
            return
          }
        }
        const message = validateMessage("Website URL", "Fill")
        if (error.current) enqueueSnackbar(message, { variant: WARNING })
        else {
          let payload = getUpdateSFPAPayload(formValues);
          payload.updated_at = sfpa.updated_at
          payload = {
            cs_location_placeofinterest: payload.cs_location_placeofinterest,
            cs_location_realestate: payload.cs_location_realestate,
            cs_location_restaurantsandshopping: payload.cs_location_restaurantsandshopping,
            cs_location_schoolsandcolleges: payload.cs_location_schoolsandcolleges,
            cs_location_weather: payload.cs_location_weather
          }
          setLoading(true);
          const { status, data } = await searchDataApi(PUT, id, payload, 'sfpa');
          if (status && status === 200) {
            const message = successMessage("Search Roadmap", VALIDATION_MESSAGE.updated_message)
            enqueueSnackbar(data?.message || message, { variant: SUCCESS });
            await getData();
          } else if (status === 409) {
            const message = unableMessage("activity info", "save")
            enqueueSnackbar(data?.message || message,
              {
                persist: true,
                variant: ERROR,
                // eslint-disable-next-line react/display-name
                action: key => (
                  <Button
                    size='small'
                    variant='text'
                    color='inherit'
                    onClick={() => { closeSnackbar(key) }}
                  >
                    Dismiss
                  </Button>
                )
              }
            );
          } else {
            const message = unableMessage("Search Roadmap", "update")
            enqueueSnackbar(data?.message || message, { variant: ERROR });
          }
          setLoading(false);
        }
      }
      else {
        const message = notFoundMessage('Changes')
        enqueueSnackbar(message, { variant: WARNING });
      }
    } catch (e) {
      console.log("Error found in updateSFPA::", e);
    }
  }, [currentValues, sfpa, id, getValues, enqueueSnackbar, closeSnackbar, getData])

  if (currentValues.cs_location_weather === undefined) { currentValues.cs_location_weather = [{}] }
  if (currentValues.cs_location_placeofinterest === undefined) { currentValues.cs_location_placeofinterest = [{}] }
  if (currentValues.cs_location_schoolsandcolleges === undefined) { currentValues.cs_location_schoolsandcolleges = [{}] }
  if (currentValues.cs_location_restaurantsandshopping === undefined) { currentValues.cs_location_restaurantsandshopping = [{}] }
  if (currentValues.cs_location_realestate === undefined) { currentValues.cs_location_realestate = [{}] }

  useEffect(() => {
    setSFPADirty(dirty)
  }, [dirty, setSFPADirty])

  useEffect(() => {
    setUpdateSFPA(updateSFPA)
  }, [setUpdateSFPA, updateSFPA])

  return (
    <div className="position-relative h-100" style={{ display: 'flex', width: '98%', flexDirection: 'column', height: '100%' }}>
      <Loader show={isLoading} />
      {isReportsOpen && <ReportOptions timeZoneOptions={timeZoneOptions} defaultLocalTime={defaultLocalTime} onClose={() => { setIsReportsOpen(false); setReportsParams({}) }} {...reportsParams} />}
      {sfpa && <>
        <Header
          companyDetails={search.company}
          company={search.company && search.company.name}
          jobNumber={search.job_number}
          jobTitle={jobTitle}
          confidential={confidential}
        >
          <div className="d-flex">
            <div className="action-container d-flex align-items-center" onClick={printSFPA}>
              <span className="action-icon">
                <PrintIcon fontSize="inherit" />
              </span>
              <span className="action-text">Print</span>
            </div>
            <ActionsPopover
              className="action-container d-flex align-items-center"
              label={
                <div className="action-container d-flex align-items-center">
                  <span className="action-icon">
                    <InsertDriveFileIcon fontSize="inherit" />
                  </span>
                  <span className="action-text">Search Roadmap Reports</span>
                </div>
              }
              list={[{
                label: 'Athena Comparison Report Candidates',
                onClick: () => {
                  setIsReportsOpen(true);
                  setReportsParams({
                    params: {
                      "searchId": id,
                    },
                    url: `${API.reports}/athena-benchmark-comparison-multiple-candidates`
                  })
                }
              }, {
                label: 'Attribute Selector Comparison Report',
                onClick: () => {
                  setIsReportsOpen(true);
                  setReportsParams({
                    params: {
                      "searchId": id,
                    },
                    options: {
                      pageSize: true,
                      locale: false,
                    },
                    url: `${API.reports}/attribute-selector-results-comparison`
                  })
                }
              }, {
                label: 'Athena Executive Summary Report',
                onClick: () => {
                  setIsReportsOpen(true);
                  setReportsParams({
                    params: {
                      "searchId": id,
                    },
                    url: `${API.reports}/athena-executive-summary`
                  })
                }
              }, {
                label: 'Athena Summary Report Package',
                onClick: () => {
                  setIsReportsOpen(true);
                  setReportsParams({
                    params: {
                      "searchId": id,
                    },
                    url: `${API.reports}/athena-summary-package`
                  })
                }
              },
              search?.helia_version !== HELIA.VERSION_2 && {
                label: 'Search Roadmap Report (Legacy) ',
                onClick: () => {
                  setIsReportsOpen(true);
                  setReportsParams({
                    params: {
                      "searchId": id,
                      timeZone: true
                    },
                    url: `${API.reports}/sfpa-report`
                  })
                }
              },
              search?.helia_version === HELIA.VERSION_2 && {
                label: 'Search Roadmap',
                onClick: () => {
                  setIsReportsOpen(true);
                  setReportsParams({
                    params: {
                      "searchId": id,
                      "athenaVersion": search?.helia_version,
                      "oneLanguageSupport": false
                    },
                    url: `${API.reports}/success-profile-agreement-report`
                  })
                }
              },
              {
                label: 'Position Profile',
                onClick: () => {
                  setIsReportsOpen(true);
                  setReportsParams({
                    params: {
                      "searchId": id,
                    },
                    url: `${API.reports}/position-profile-report`
                  })
                }
              }
              ]}
            />
            <Button color='primary' className='button' variant='contained' onClick={() => updateSFPA(dirty)}>Save</Button>
          </div>
        </Header>
        <div className="input-form target-section">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Weather register={register} setValue={setValue} currentValues={currentValues} />
              <PlacesOfInterest register={register} setValue={setValue} currentValues={currentValues} />
              <SchoolsAndColleges register={register} setValue={setValue} currentValues={currentValues} />
              <RestaurantsAndShopping register={register} setValue={setValue} currentValues={currentValues} />
              <RealEstate register={register} setValue={setValue} currentValues={currentValues} />
            </Grid>
          </Grid>
        </div>
      </>}
    </div>
  )
}

CSLocInfo.propTypes = {
  id: PropTypes.string,
  search: PropTypes.array,
  jobTitle: PropTypes.string,
  confidential: PropTypes.bool,
  enqueueSnackbar: PropTypes.func,
  closeSnackbar: PropTypes.func,
  setSFPADirty: PropTypes.func,
  setUpdateSFPA: PropTypes.func
}

export default withSnackbar(CSLocInfo);