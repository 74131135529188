//----------------------------------------------// In-built Imports // -------------------------------------------------
import React from 'react'

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types'

//----------------------------------------------// Internal Imports // -------------------------------------------------
import './index.scss'

const TabLayoutFooter = (props) => {
  const { actions } = props
  const buildActionButtons = () => {
    return actions.map((item, index) => {
      const { label, type = "submit", ...rest } = item;
      return <button key={index} type={type} {...rest}>{label}</button>
    })
  }
  return (
    <div className="custom-layput-actions">
      {buildActionButtons()}
    </div>
  )
}

TabLayoutFooter.propTypes = {
  actions: PropTypes.array,
}
export default TabLayoutFooter;