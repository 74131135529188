//----------------------------------------------// In-built Imports // -------------------------------------------------
import React from 'react'

//----------------------------------------------// External Imports // -------------------------------------------------
import CallIcon from '@material-ui/icons/Call';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import EmailIcon from '@material-ui/icons/Email';
import FeedbackIcon from '@material-ui/icons/Feedback';
import DescriptionIcon from '@material-ui/icons/Description';
import PeopleIcon from '@material-ui/icons/People';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import InformationUpdate from '../../../../../../assets/images/InformationUpdate.png';
import OtherInformation from '../../../../../../assets/images/OtherInformation.png';

export const getActivityIcon = (activity) => {
  switch(activity){
  case 'Call':
    return <CallIcon fontSize="inherit" />
  case 'Email':
    return <EmailIcon fontSize="inherit" />
  case 'Text':
    return <DescriptionIcon fontSize="inherit" />
  case 'LinkedIn':
    return <LinkedInIcon fontSize="inherit" style={{color: 'rgb(40, 103, 178)'}}/>
  case 'Meetings':
    return <PeopleIcon fontSize="inherit" />
  case 'Feedback':
    return <FeedbackIcon fontSize="inherit" />
  case 'Other':
    return <img src={OtherInformation} alt='Other Information' style={{width: 14, height: 14}} />
  case 'Information Update':
    return <img src={InformationUpdate} alt='Information Update' style={{width: 14, height: 14}} />
  default:
    return ''
  }
}
