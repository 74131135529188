
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, {
  forwardRef,
  useCallback,
} from "react";

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { TextField } from "@material-ui/core";
import { ERROR } from "../../services/constantService";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const RejectReason = forwardRef((props) => {
  const {
    title = "Industries",
    reject,
    onClose,
    enqueueSnackbar
  } = props;
  const [open, setOpen] = React.useState(true);
  const [currentValue, setCurrentValue] = React.useState(null);

  const handleClose = useCallback(() => {
    setOpen(false);
    onClose(false);
  }, []);

  const handleSave = () => {
    if (!currentValue) {
      enqueueSnackbar('Please add reason for reject', { variant: ERROR });
    } else {
      reject(currentValue)
      setOpen(false);
      onClose(false);
    }
  };

  return (
    <Dialog
      className="ag-custom-component-popup"
      maxWidth="md"
      onClose={handleClose}
      disableBackdropClick={true}
      open={open}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent
        dividers
        style={{ minWidth: "400px", maxWidth: "400px", maxHeight: 150 }}
      >
        <TextField
          //ref={register}
          className="input-form-field input-field-old"
          name={'reason'}
          label="Reason"
          placeholder="Reason"
          multiline
          onChange={(e) => {
            setCurrentValue(e.target.value)
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
                    Cancel
        </Button>
        <Button variant="contained" onClick={handleSave} color="primary">
                    Save
        </Button>
      </DialogActions>
    </Dialog>
  );
});

RejectReason.propTypes = {
  title: PropTypes.string,
  enqueueSnackbar: PropTypes.func,
  reject: PropTypes.func,
  onClose: PropTypes.func
};

RejectReason.displayName = "RejectReason";

export default RejectReason;
