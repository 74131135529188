//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useCallback, useEffect, useState } from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import ReactQuill from "react-quill";
import moment from "moment-timezone"
import isEqual from 'lodash.isequal';
import { Button, Typography } from "@material-ui/core";
import debounce from "lodash.debounce";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import Popup from "reactjs-popup";
import CloseIcon from "@material-ui/icons/Close";

//----------------------------------------------// Internal Imports // -------------------------------------------------
import Loader from "../common/Loader";
import ConfirmationPopup from "../ConfirmationPopup";
import { formatDate } from "../../utils/date";
import './index.scss';
import { REDO, UNDO } from "../../services/constantService";
import { sanitize } from "../../utils/htmlSanitizer";
import { useLocation, useParams } from "react-router-dom";
import { VALIDATION_MESSAGE } from "../../services/MessageService";

const icons = ReactQuill.Quill.import("ui/icons");
const AUTOSAVE_PAYLOAD_TYPE = ['skills', 'attribute']

let userTimestampInitial = ''
let initialHtmlArrayLength;
export default function RichTextPopup(props) {
  const {
    value: defaultValue, placeholder = "Enter text here", watch, onChange, title = "", name = "", onSave, setOpen = true, displayToolbar = true, onClose, item,
    saveButtonText = 'Save', saveStatus, userdata, form = false, updatedDate, autoSave = function () { }, restrictHeight = true, restrictWidth = false, CopyRecruiterFlag,
    commentType, index, strengthsAndPotentialIssues, spfaSkillFlag, payloadType, eventBinding = false, renderGrid = false, grid = false, screeningNotesFlag = false, setTimestamp, setInitialLength, screeningNotesToggleUpdatedFlag, ...rest
  } = props
  const { path } = useParams();
  const location = useLocation();
  const [readOnly, setReadOnly] = useState(false);
  const [value, setValue] = useState(defaultValue)
  const [popupOpen, setPopupOpen] = useState(grid ? true : false);
  const [isLoading, setIsLoading] = useState(false);
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [copyRecruiterFlag, setCopyRecruiterFlag] = useState(CopyRecruiterFlag);
  const [skillFlag, setSkillFlag] = useState(spfaSkillFlag)
  const [strengthsAndPotentialIssuesFlag, setStrengthsAndPotentialIssuesFlag] = useState(strengthsAndPotentialIssues);
  const [isAutoSaveLoading, setAutoSaveLoading] = useState(false);
  const [isScreeningNotesLoading] = useState(false);
  const [failureCount, setFailureCount] = useState(0)
  const quill = React.useRef(ReactQuill);

  icons["undo"] = UNDO;
  icons["redo"] = REDO;
  var bindings = {
    custom: {
      key: 'Y',
      ctrlKey: true,
      handler: React.useCallback(() => {
        quill.current.editor.history.redo();
      }, [])
    }
  };

  const handler = {
    'undo': React.useCallback(() => {
      quill.current.editor.history.undo();
    }, []),
    'redo': React.useCallback(() => {
      quill.current.editor.history.redo();
    }, [])
  }
  let toolbar;
  if (displayToolbar) {
    toolbar = {
      container: [
        [
          { 'header': [1, 2, 3, false] }
        ],
        ['bold', 'italic', 'underline', 'link'],
        [
          { 'list': 'ordered' },
          { 'list': 'bullet' }
        ],
        ['undo', 'redo']
      ],
      handlers: handler
    }
  }
  else {
    toolbar = false
  }

  // const firstrender = () => {
  //   quill.current.editor.on('text-change', function() {
  //     quill.current.editor.insertText('a', (userdata.first_name.substr(0, 1) + userdata.last_name.substr(0, 1)), 'bold', false);
  //     //quill.current.editor.formatText((range.index, range.index), 'bold', true);
  //   });
  // }


  const modules = {
    toolbar: toolbar,
    history: {
      delay: 200,
      maxStack: 500,
      userOnly: true
    },
    keyboard: {
      bindings: bindings
    }
  };


  const debouncedSave = useCallback(
    debounce(async (nextValue, prevValue) => {
      try {
        let validScreeningNotesContent = true;
        if (screeningNotesFlag) {
          let data = await screeningNotesValidation(nextValue)
          let stringCompare = data.substring(0, userTimestampInitial.length)
          validScreeningNotesContent = data.trim().length > userTimestampInitial.length && stringCompare === userTimestampInitial
        }
        const sanitizedNextValue = sanitize(nextValue)
        /* prevValue?.replaceAll('<br />', '<br>') */
        const valueCheckerPrevValue = prevValue?.replaceAll('<p><br /></p>','');
        const valueCheckerNextValue = sanitizedNextValue?.replaceAll('<p><br /></p>','')
        if (isEqual(valueCheckerPrevValue, valueCheckerNextValue )) {
          validScreeningNotesContent = false
        }
        if (!isEqual(sanitizedNextValue, prevValue) && validScreeningNotesContent) {
          if (popupOpen) {
            if (AUTOSAVE_PAYLOAD_TYPE.includes(payloadType)) {
              const waitForAllResults = false;
              const loaderFlag = false
              await autoSave({ [name]: sanitizedNextValue }, index, commentType, loaderFlag, waitForAllResults, setAutoSaveLoading)
            }
            else if (payloadType === 'default') {
              await autoSave({ [name]: sanitizedNextValue, old: { [name]: prevValue } }, index, commentType, item, setAutoSaveLoading)
            }
            else if (screeningNotesFlag) {
              setIsLoading(true);
              await autoSave({ [name]: sanitizedNextValue, old: { [name]: prevValue } }, true, setAutoSaveLoading)
              setIsLoading(false);
            } else {
              await autoSave({ [name]: sanitizedNextValue, old: { [name]: prevValue } }, true, setAutoSaveLoading)
              // autoSave({ [name]: nextValue, old: { [name]: prevValue } }, true, commentType, item, index, false, setAutoSaveLoading)
            }
          }
        }
      } catch (e) {
        console.log("Error found in debounce::", e);
      }
    }, 5000),
    [popupOpen]
  );
  useEffect(() => {
    if (saveStatus === false) {
      setFailureCount(prev => prev + 1)
    } else {
      setFailureCount(0)
    }
  }, [saveStatus, isAutoSaveLoading])
  useEffect(() => {
    setFailureCount(0);
  }, [value])

  // When submit activity form then reset comment field
  useEffect(() => {
    // Check if field from activity log Comment and make changes from perent component
    if (watch && form) {
      const activityComment = watch('comments') || null;
      setValue(activityComment);
    }
  }, [watch])

  useEffect(() => {
    const isReadOnly = location.pathname.includes('read-only');
    setReadOnly(isReadOnly);
  }, [path])

  const screeningNotesValidation = (nextValue) => {
    const doc = new DOMParser().parseFromString(nextValue, "text/html");
    let HTMLArray;
    HTMLArray = [...doc.body.children].map(el => el.outerHTML);
    // let data = HTMLArray[initialHtmlArrayLength]
    let data = HTMLArray.slice(initialHtmlArrayLength, HTMLArray.length).join('')
    const span = document.createElement('span');
    span.innerHTML = data;
    data = span.textContent
    return data;
  }
  useEffect(() => {
    if (!isEqual(value, defaultValue) && !isAutoSaveLoading && failureCount === 0) {
      if (copyRecruiterFlag) {
        debouncedSave(defaultValue, value)
      } else {
        debouncedSave(value, defaultValue)
      }
      // firstrender()
    }
  }, [value, defaultValue, debouncedSave, isAutoSaveLoading])

  useEffect(() => {
    if (popupOpen && eventBinding) {
      let HTMLArray;
      const userTimestamp = `${userdata.first_name.substr(0, 1)}${userdata.last_name.substr(0, 1)} ${moment.utc().local().format('DD/MM/YYYY hh:mm a')} ${moment.tz(moment.tz.guess()).zoneAbbr()}  `
      userTimestampInitial = userTimestamp.trim();
      setTimestamp && setTimestamp(userTimestampInitial)
      const range = quill.current.editor.getLength()
      if (value === null || value === "") {
        quill.current.editor.insertText((range - 1), userTimestamp, 'bold', true);
        quill.current.editor.removeFormat(((range - 1) + (userTimestamp.length - 1)), (range + (userTimestamp.length - 1)));
      } else {
        const doc = new DOMParser().parseFromString(value, "text/html");
        HTMLArray = [...doc.body.children].map(el => el.outerHTML);
        quill.current.editor.insertText(range, userTimestamp, 'bold', true);
        quill.current.editor.removeFormat((range + (userTimestamp.length - 1)), (range + (userTimestamp.length - 1)));
      }
      const doc = new DOMParser().parseFromString(value, "text/html");
      HTMLArray = [...doc.body.children].map(el => el.outerHTML);
      initialHtmlArrayLength = HTMLArray.length
      setInitialLength && setInitialLength(HTMLArray.length)
    }
  }, [popupOpen])
  // need to enable if any bug found due to this code
  /* useEffect(() => {
    if (typeof defaultValue === 'string' && CopyRecruiterFlag) {
      setValue(defaultValue)
      debouncedSave(value, defaultValue)
    }
  }, [defaultValue]) */


  useEffect(() => {
    if (CopyRecruiterFlag || skillFlag || strengthsAndPotentialIssuesFlag) {
      setValue(defaultValue)
    }
  }, [defaultValue])

  useEffect(() => {
    if (screeningNotesFlag) {
      setValue(defaultValue)
    }
  }, [defaultValue, screeningNotesToggleUpdatedFlag])

  const handleSave = async () => {
    try {
      let isSaveSuccess = true;
      debouncedSave.cancel();
      if (onSave) {
        setIsLoading(true);
        isSaveSuccess = await onSave({ [name]: sanitize(value) });
        setIsLoading(false);
        setPopupOpen(false);
        grid && onClose()
      }
      if (isSaveSuccess) {
        setPopupOpen(false);
        onChange && onChange(sanitize(value))
        grid && onClose()
      }
      debouncedSave.cancel();
    } catch (e) {
      console.log("Error found in handleSave::", e);
    }
  }
  const handleCancel = () => {
    setIsLoading(false);
    setAlertOpen(true)
  }

  const handleNo = () => {
    setAlertOpen(false)
  }

  const handleClose = () => {
    grid && onClose()
    setAlertOpen(false);
    setPopupOpen(false);
    setValue(defaultValue);
    debouncedSave.cancel();
  }

  const onUpdate = (updatedValue) => {
    /*  sequence of setValue and setCopyRecruiterFlag must not change
        it's impact on typing to cursor focus issue 
    */
    setValue(updatedValue);
    setCopyRecruiterFlag(false);
    setSkillFlag(false)
    setStrengthsAndPotentialIssuesFlag(false);
  }

  return (
    <>
      {isAlertOpen ?
        <ConfirmationPopup
          header={VALIDATION_MESSAGE.close_textbox}
          onConfirm={handleClose}
          onClose={handleNo}
        /> : null
      }
      {popupOpen ?
        <Popup open={popupOpen} onClose={handleCancel} className='rich-text-popup' closeOnDocumentClick={false} closeOnEscape={false}>
          <>
            <Loader show={isLoading} />
            <div className="rich-text-popup-header">
              <Typography className='title'>{title}</Typography>
              <CloseIcon cursor='pointer' onClick={handleCancel} />
            </div>
            <div className={'rich-text-popup-container react-quill-container'}  >
              <ReactQuill
                bounds={'.react-quill-container'}
                placeholder={placeholder}
                value={value}
                onChange={onUpdate}
                ref={quill}
                modules={modules}
              />
            </div>
            <div className={'rich-text-popup-actions ' + (typeof saveStatus === 'boolean' && 'justify-content-between')}>
              {
                typeof saveStatus === 'boolean' &&
                <div className='d-flex align-items-center'>
                  {saveStatus ? <CheckCircleIcon style={{ color: '#00af00', fontSize: '2rem' }} /> :
                    <CancelIcon style={{ color: '#ff0000', fontSize: '2rem' }} />}

                  {isAutoSaveLoading || isScreeningNotesLoading ? <Typography className='ml-2'>
                    Saving...
                  </Typography> : <Typography className='ml-2'>
                    {saveStatus ?
                      `Changes saved: ${updatedDate ? formatDate(updatedDate, 'h:mm:ss a DD/MM/YYYY') : '--'}`
                      : `Offline: changes last saved: ${updatedDate ? formatDate(updatedDate, 'h:mm:ss a DD/MM/YYYY') : '--'}`
                    }
                  </Typography>
                  }
                </div>
              }
              <div>
                <Button variant='text' color='primary' disabled={isAutoSaveLoading} onClick={handleCancel}>Cancel</Button>
                <Button className='primary-btn' variant='contained' color='primary' disabled={isAutoSaveLoading} onClick={handleSave}>{saveButtonText}</Button>
              </div>
            </div>
          </>
        </Popup> : ''
      }
      <div onClick={() => { !readOnly && !renderGrid && setPopupOpen(setOpen) }} className={`rich-text-popup-container react-quill-container ${renderGrid && 'grid'} `} style={!renderGrid ? restrictWidth ? { width: '100%' } : {} : { position: 'relative' }} >
        <ReactQuill
          placeholder={!renderGrid ? placeholder : ''}
          readOnly={true}
          value={form ? (watch('comments') || null) : value}
          ref={quill}
          modules={modules}
          style={!renderGrid ? (restrictHeight ? { maxHeight: '150px', overflowY: 'auto', pointerEvents: 'auto' } : {}) : { position: 'absolute', top: '-28px' }}
          {...rest}
        />
      </div>
    </>
  )
}

RichTextPopup.propTypes = {
  value: PropTypes.string,
  watch: PropTypes.func,
  form: PropTypes.bool,
  title: PropTypes.string,
  commentType: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  item: PropTypes.object,
  onSave: PropTypes.func,
  saveButtonText: PropTypes.string,
  CopyRecruiterFlag: PropTypes.bool,
  spfaSkillFlag: PropTypes.bool,
  strengthsAndPotentialIssues: PropTypes.bool,
  saveStatus: PropTypes.bool,
  updatedDate: PropTypes.any,
  autoSave: PropTypes.func,
  restrictHeight: PropTypes.bool,
  restrictWidth: PropTypes.bool,
  setOpen: PropTypes.bool,
  index: PropTypes.number,
  displayToolbar: PropTypes.bool,
  payloadType: PropTypes.string,
  onClose: PropTypes.func,
  grid: PropTypes.bool,
  eventBinding: PropTypes.bool,
  renderGrid: PropTypes.bool,
  userdata: PropTypes.object,
  screeningNotesFlag: PropTypes.bool,
  setTimestamp: PropTypes.func,
  setInitialLength: PropTypes.func,
  screeningNotesToggleUpdatedFlag: PropTypes.bool
}
