//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, {useState} from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from "prop-types";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {ExpansionPanelDetails, Typography} from "@material-ui/core";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";

export function ExpandableSection(props) {
  const [expanded, setExpanded] = useState(true);

  const handleExpand = () => {
    setExpanded(true)
  }

  const handleMinimize = () => {
    setExpanded(!expanded)
  }

  return (
    <ExpansionPanel className="expandable-section" expanded={expanded}>
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon onClick={handleExpand} color='inherit'/>}
        onClick={handleMinimize}
      >
        <Typography className='section-label m-0 p-0 expandable-section-title' color='inherit'>{props.title}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails className={props.className}>
        {props.children}
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}

ExpandableSection.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  defaultExpanded: PropTypes.bool,
  className: PropTypes.string
}
