//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useCallback, useEffect, useState } from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import isEqual from "lodash.isequal";
import { Button, TextareaAutosize, TextField, Typography } from "@material-ui/core";
import Popup from "reactjs-popup";
import CloseIcon from "@material-ui/icons/Close";
import debounce from 'lodash.debounce'
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import ConfirmationPopup from "../ConfirmationPopup";
import './index.scss';
import { formatDate } from "../../utils/date";
import { VALIDATION_MESSAGE } from "../../services/MessageService";

/* eslint-disable-next-line react/display-name */
const TextPopup = React.forwardRef(({ ...props }, ref) => {
  const {
    value: defaultValue, placeholder = "", onChange, title = "", saveText = () => {
    }, name, updatedDate, saveStatus, expandToContent = false, autoSaveTime = 1000, onSave, disabled = false, setOpen = true, ...rest
  } = props
  const [value, setValue] = useState('')
  const [debouncedValue, setDebouncedValue] = useState(null)
  const [popupOpen, setPopupOpen] = useState(false);
  const [isAlertOpen, setAlertOpen] = useState(false)

  useEffect(() => {
    if (typeof defaultValue === 'string') {
      setValue(defaultValue)
      setDebouncedValue(defaultValue)
    }
  }, [defaultValue])

  const debouncedSave = useCallback(
    debounce((nextValue) => {
      setDebouncedValue(nextValue)
    }, autoSaveTime),
    []
  );

  useEffect(() => {
    if (typeof debouncedValue === "string" && !isEqual(debouncedValue, defaultValue)) {
      saveText({ [name]: debouncedValue, old: { [name]: defaultValue } })
    }
  }, [debouncedValue])

  const handleSave = () => {
    setPopupOpen(false);
    onChange && onChange(value)
    onSave && onSave(value)
  }

  const handleCancel = () => {
    setAlertOpen(true)
  }

  const handleNo = () => {
    setAlertOpen(false)
  }

  const handleClose = () => {
    setAlertOpen(false);
    setPopupOpen(false);
    setValue(defaultValue);
  }
  return (
    <>
      {isAlertOpen ?
        <ConfirmationPopup
          header={VALIDATION_MESSAGE.close_textbox}
          onConfirm={handleClose}
          onClose={handleNo}
        /> : null
      }
      {popupOpen ?
        <Popup open={popupOpen} onClose={handleCancel} className='text-popup' closeOnDocumentClick={false}
          closeOnEscape={false}>
          <>
            <div className="text-popup-header">
              <Typography className='title'>{title}</Typography>
              <CloseIcon cursor='pointer' onClick={handleCancel} />
            </div>
            <div className="text-popup-container">
              <TextareaAutosize
                placeholder={placeholder}
                autoFocus
                rowsMin={10}
                rowsMax={10}
                style={{ width: '100%' }}
                value={value}
                name={name}
                onChange={(e) => {
                  setValue(e.target.value);
                  debouncedSave(e.target.value);
                  onChange && onChange(e.target.value);
                }}
              />
            </div>
            <div className={'text-popup-actions ' + (typeof saveStatus === 'boolean' && 'justify-content-between')}>
              {
                typeof saveStatus === 'boolean' &&
                <div className='d-flex align-items-center'>
                  {saveStatus ? <CheckCircleIcon style={{ color: '#00af00', fontSize: '2rem' }} /> :
                    <CancelIcon style={{ color: '#ff0000', fontSize: '2rem' }} />}
                  <Typography className='ml-2'>
                    {saveStatus ?
                      `Changes saved: ${formatDate(updatedDate, 'h:mm:ss a DD/MM/YYYY')}`
                      : `Offline: changes last saved: ${formatDate(updatedDate, 'h:mm:ss a DD/MM/YYYY')}`
                    }
                  </Typography>
                </div>
              }
              <div>
                <Button variant='text' color='primary' className="mr-3" onClick={handleCancel}>Cancel</Button>
                <Button className='primary-btn' variant='contained' color='primary' onClick={handleSave}>Ok</Button>
              </div>
            </div>
          </>
        </Popup> : ''}
      <TextField
        {...rest}
        onClick={() => {
          setPopupOpen(setOpen);
        }}
        onKeyUpCapture={(e) => {
          if (e.key === 'Enter') {
            setPopupOpen(setOpen);
          }
        }}
        placeholder={placeholder}
        inputRef={ref}
        multiline
        name={name}
        rowsMax={expandToContent ? Infinity : 1}
        value={value}
        disabled={disabled}
      />
    </>
  )
})

TextPopup.propTypes = {
  value: PropTypes.string,
  title: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string,
  saveText: PropTypes.func,
  saveStatus: PropTypes.bool,
  updatedDate: PropTypes.any,
  autoSaveTime: PropTypes.number,
  expandToContent: PropTypes.bool,
  onSave: PropTypes.func,
  state: PropTypes.object,
  setOpen: PropTypes.bool,
  disabled: PropTypes.bool
}

export default TextPopup
