
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useEffect, useState } from "react";

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from "prop-types";
import Popup from "reactjs-popup";
import CloseIcon from "@material-ui/icons/Close";
import { Button, Paper, Radio, RadioGroup, TableContainer } from "@material-ui/core";
import { useSnackbar } from "notistack";
import isEqual from "lodash.isequal";
import ReactQuill from "react-quill";

//-----------------------------------------------------------// Internal Imports // ------------------------------

import Loader from "../../common/Loader";
import ConfirmationPopup from "../../ConfirmationPopup";
import { isEmpty } from "../../../utils/common";
import { contactFieldTitles, getContactValue, contactKeys, getContactName, getOldValueOfContact } from "./utils";
import { GET, PUT, ERROR, SUCCESS } from "../../../services/constantService";
import { contactDataApi } from "../../../services/ApiService";
import { unableMessage, validateMessage, successMessage } from "../../../services/MessageService";


export default function ContactsMergingPopup(props) {
  const { onClose, contacts } = props
  const [firstRecord, secondRecord] = contacts
  const [firstContact, setFirstContact] = useState({ ...firstRecord })
  const [secondContact, setSecondContact] = useState({ ...secondRecord })

  useEffect(() => {
    const fetchContact = async (id) => {
      setLoading(true)
      const { status, data } = await contactDataApi(GET, id);
      setLoading(false)
      if (status === 200) {
        return data
      }
    }
    const [firstRecord, secondRecord] = contacts
    fetchContact(firstRecord.id).then((data) => {
      setFirstContact(prevState => ({ ...prevState, ...data ? data : {} }))
    })
    fetchContact(secondRecord.id).then((data) => {
      setSecondContact(prevState => ({ ...prevState, ...data ? data : {} }))
    })
  }, [contacts])

  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(true)
  const [conflictingFields, setConflictingFields] = useState([]);
  const [contact, setContact] = useState({})
  const [loading, setLoading] = useState(false);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false)

  useEffect(() => {
    const conflictingKeys = []
    const mergedContact = {}
    contactKeys.forEach(key => {
      const firstContactValue = getOldValueOfContact(firstContact, key)[key]
      const secondContactValue = getOldValueOfContact(secondContact, key)[key]

      if (isEmpty(firstContactValue)) {
        mergedContact[key] = secondContactValue
      } else if (isEmpty(secondContactValue)) {
        mergedContact[key] = firstContactValue
      } else if (!isEqual(firstContactValue, secondContactValue)) {
        conflictingKeys.push(key)
      } else {
        mergedContact[key] = firstContactValue
      }
    })

    setConflictingFields(conflictingKeys)
    setContact(mergedContact)
  }, [firstContact, secondContact])

  const handleClose = (merged) => {
    setOpen(false);
    onClose && onClose(merged)

  }

  const handleCancel = () => {
    handleClose(false)
  }

  const handleConfirmPopupClose = () => {
    setShowConfirmPopup(false)

  }

  const changeContactField = (field, value) => {
    setContact(prevState => {
      prevState[field] = value
      return prevState
    })
  }

  const handleMerge = () => {
    if (Object.keys(contact).length !== contactKeys.length + 1) {
      const message = validateMessage("fields", "select all")
      enqueueSnackbar(message, { variant: ERROR })
      return
    }
    setShowConfirmPopup(true)
  }

  const mergeContacts = async () => {
    setLoading(true)
    const payload = { data: contact }
    payload.mergeId = contact.id === firstContact.id ? secondContact.id : firstContact.id
    const sub_route = 'merge'
    const { status, data } = await contactDataApi(PUT, contact.id, payload, sub_route);
    if (status === 200) {
      const message = successMessage("Contacts", "merged")
      enqueueSnackbar(message, { variant: SUCCESS })
      setLoading(false)
      handleClose(true)
    } else {
      const message = unableMessage("contacts", "merge")
      enqueueSnackbar(data?.message || message, { variant: ERROR })
      setLoading(false)
    }
  }

  const handleConfirmPopup = async () => {
    try {
      handleConfirmPopupClose()
      await mergeContacts()
    } catch (e) {
      console.log("Error found in handleConfirmPopup::", e);
    }

  }

  const renderCell = (field, value, showRadio) => {
    return (
      <div className="table-cell">
        {showRadio && <Radio value={value} size='small' />}
        {
          field === 'published_bio' ?
            <ReactQuill
              value={value}
              readOnly={true}
              theme='bubble'
            />
            : (field !== 'id' && value)
        }
      </div>
    )
  }

  const renderRow = (name, firstValue, secondValue, field, key, showRadio) => {
    if (field === 'id') {
      showRadio = true;
      firstValue = 'id1'
      secondValue = 'id2'
    }
    return (
      <RadioGroup
        row
        onChange={(e) => {
          const { value } = e.target;
          changeContactField(field,
            isEqual(value, firstValue) ?
              getOldValueOfContact(firstContact, field)[field] :
              getOldValueOfContact(secondContact, field)[field]
          )
        }}
        name={field}
        key={key}
      >
        <div className={"text-right table-cell table-cell-header " + (showRadio && 'highlight')}>{name}</div>
        {renderCell(field, firstValue, showRadio)}
        {renderCell(field, secondValue, showRadio)}
      </RadioGroup>
    )
  }

  return (
    <Popup open={open} modal closeOnEscape={false} closeOnDocumentClick={false}>
      <div className='merge-companies'>
        <Loader show={loading} />
        {showConfirmPopup && <ConfirmationPopup
          header='Are you sure you want to merge these contacts? This process cannot be undone.'
          onConfirm={handleConfirmPopup}
          onClose={handleConfirmPopupClose}
          cancelText={'Cancel'}
          confirmText={'Merge'}
        />}
        <div className='header-section '>
          <span className='view-company-header'>Merge Contacts</span>
          <CloseIcon onClick={handleCancel} cursor='pointer' />
        </div>
        <div className='view-container'>
          <TableContainer component={Paper} className='table-container'>
            <div className='d-flex flex-grow-1 table-header'>
              <div className='table-cell'></div>
              <div className='table-cell'>{getContactName(firstContact)}</div>
              <div className='table-cell'>{getContactName(secondContact)}</div>
            </div>
            <div>
              {renderRow("Master Record", '', '', 'id')}
              {
                conflictingFields.map((field, index) => (
                  renderRow(
                    contactFieldTitles[field],
                    getContactValue(firstContact, field),
                    getContactValue(secondContact, field),
                    field, index, true
                  )
                ))
              }
              {
                contactKeys.filter((key) => !conflictingFields.includes(key)).map((field, index) => (
                  renderRow(contactFieldTitles[field], getContactValue(firstContact, field), getContactValue(secondContact, field), field, index)
                ))
              }
            </div>
          </TableContainer>
        </div>
        <div className='actions-container'>
          <Button color='primary' onClick={handleCancel}>Cancel</Button>
          <Button className='merge-button' variant='contained' color='primary' onClick={handleMerge}>Merge</Button>
        </div>
      </div>
    </Popup>
  )
}

ContactsMergingPopup.propTypes = {
  onClose: PropTypes.func,
  contacts: PropTypes.array
}
