//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { fetchTypeList } from "../../../../../actions";
import { BD_STAGES, OPEN_STAGES } from "../../../../../services/constantService";

function SearchStageSelection(props) {
  const {
    className = 'w-100',
    label = "Stage",
    InputLabelProps,
    required = false,
    placeholder = 'Stage',
    removeClosed,
    variant = 'standard',
    fullSearch=false,
    ...rest
  } = props
  const [options, setOptions] = useState([])
  const searchStages = useSelector(state => state.commonReducer.searchStages)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!searchStages) {
      dispatch(fetchTypeList('SEARCH_STAGE', 'searchStages'))
    } else {
      let data = [...searchStages]
      if (removeClosed) {
        data.splice(data.findIndex(item => item.name === 'Closed'), 1)
      }
      if(fullSearch){
        if (props.value) {
          if (props.value === 'Business Development' || props.value === 'Open') {
            data = data.filter(item => {
              if (props.value === 'Business Development') {
                return BD_STAGES.includes(item.field_value)
              } else if (props.value === 'Open') {
                return OPEN_STAGES.includes(item.field_value)
              }
            })
          }
        }
      }
      setOptions(data);
    }
  }, [searchStages, dispatch, removeClosed])

  return (
    <Autocomplete
      {...rest}
      className={className}
      options={options}
      getOptionSelected={(option, value) => {
        if (typeof value === 'string') {
          return option.short_desc === value;
        }
        return option.short_desc === value.short_desc
      }}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option
        }
        return option.short_desc
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholder}
          required={required}
          InputLabelProps={InputLabelProps}
          label={label}
          variant={variant}
        />
      )}
    />
  )
}

SearchStageSelection.propTypes = {
  register: PropTypes.func,
  required: PropTypes.bool,
  className: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  InputLabelProps: PropTypes.object,
  removeClosed: PropTypes.bool,
  variant: PropTypes.string,
  value: PropTypes.string,
  fullSearch:PropTypes.bool
}

export default SearchStageSelection;
