//----------------------------------------------// In-built Imports // -------------------------------------------------
import React from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types'

// -------------------------------------------------// External Imports// -------------------------------------------------

import { KeyboardDatePicker } from "@material-ui/pickers";

export default function CustomDatePicker(props) {
  const { variant = 'inline', format = "dd/MM/yyyy", InputLabelProps, ...rest } = props
  return (
    <KeyboardDatePicker
      {...rest}
      disableToolbar
      autoOk
      variant={variant}
      format={format}
      InputLabelProps={InputLabelProps}
    />
  )
}

CustomDatePicker.propTypes = {
  variant: PropTypes.string,
  format: PropTypes.string,
  InputLabelProps: PropTypes.object
}
