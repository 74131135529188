//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from "prop-types";

//----------------------------------------------// Internal Imports // -------------------------------------------------
import BillingInfoView from "./View";
import BillingInfo from "../../../CreateSearch/Components/BillingInfo";
import { useSelector } from "react-redux";
import { getUserSelector } from "../../../../selectors";
import { checkBillingRole, checkSearchApprovalRoles } from "../../../../Containers/Commons/Utils";
import { PENDING_REJECT_BILLING_STATUS } from "../../../../services/constantService";

export default function BillingInfoContainer(props) {
  const { register, setValue, handelScroll, watch, getValues, control, currentValues, isEditing, renderActions, renderFieldLabel, editingField, reloadData, currencyDetails, setUpdatelLoader, renderSectionLabel, location } = props
  const userData = useSelector(getUserSelector)
  const [isHide, setIsHide] = useState(false)
  
  useEffect(() => {
    setTimeout(() => {
      handelScroll()
    }, 3000)

  }, []);

  useEffect(() => {
    const disableEdit = checkSearchApprovalRoles(userData)
    if (!disableEdit && PENDING_REJECT_BILLING_STATUS.includes(currentValues.billing_status)) {
      setIsHide(true)
    } else if (currentValues.stage === 'Closed') {
      const editable = checkBillingRole(userData)
      if (!editable) {
        setIsHide(true)
      }
    }
  }, [currentValues])

  return (
    isEditing && !isHide ?
      <BillingInfo
        register={register}
        setValue={setValue}
        currencyDetails={currencyDetails}
        watch={watch}
        control={control}
        getValues={getValues}
        isEditing={isEditing}
        currentValues={currentValues}
        handelScroll={handelScroll}
        location={location}
      />
      :
      <BillingInfoView
        register={register}
        setValue={setValue}
        currencyDetails={currencyDetails}
        watch={watch}
        getValues={getValues}
        currentValues={currentValues}
        renderActions={renderActions}
        renderFieldLabel={renderFieldLabel}
        editingField={editingField}
        reloadData={reloadData}
        handelScroll={handelScroll}
        setUpdatelLoader={setUpdatelLoader}
        renderSectionLabel={renderSectionLabel}
        location={location}
      />

  )
}

BillingInfoContainer.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  watch: PropTypes.func,
  control: PropTypes.object,
  currentValues: PropTypes.object,
  isEditing: PropTypes.bool,
  renderFieldLabel: PropTypes.func,
  renderActions: PropTypes.func,
  editingField: PropTypes.string,
  getValues: PropTypes.func,
  reloadData: PropTypes.func,
  currencyDetails: PropTypes.object,
  handelScroll: PropTypes.func,
  setUpdatelLoader: PropTypes.func,
  renderSectionLabel: PropTypes.func,
  location: PropTypes.object
}
