import React from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';

//----------------------------------------------// Internal Imports // -------------------------------------------------
// import IndirectFee from './component/IndirectFee';
// import PercentageOfBase from './component/PercentageForm';
import BillingForm from './component/BillingForm';
import { currencyWithCommas, getCurrencyPrefix } from '../../../Containers/Commons/Utils';
import TotalRevenue from './component/TotalRevenue';
import IndirectFee from '../../AddBD/BillingDetails/Components/IndirectFee';
import PercentageOfBase from '../../AddBD/BillingDetails/Components/PercentageOfBase';
import { BILLING_VALIDATIONS } from '../../../services/constantService';
import { getFixedDecimal } from '../../../utils/common';


const BillingDetails = (props) => {
  const { register, watch, setValue, /* currentValues, */ renderLabel, renderActions, editingField, defaultValues, currencyDetails } = props;
  const fe_Cap_amount = defaultValues.fee_cap_amount
  const is_fee_cap = defaultValues.is_fee_cap
  const totalEstimate = defaultValues.estimated_revenue

  const getTotalPercentageOfBase = (data, key, formattedValue) => {
    const currency = getCurrencyPrefix((defaultValues?.currency) || '')
    const percentage = data?.reduce((prev, curr) => {
      if (curr[key]) prev = prev + Number(curr[key])
      return prev;
    }, (0)) || 0;
    return formattedValue ? getValueWithCurrency(percentage) : { currency, number: percentage }
  }

  const getGrandTotal = () => {
    const totalPercentageOfBase = getTotalPercentageOfBase(watch('estimated_percentage_bases'), 'calculated_estimated_amt', false)
    const totalIndirectFee = getTotalPercentageOfBase(watch('indirect_fees_searches'), 'calculation_amt')
    setValue('estimated_percentage_base', getFixedDecimal(totalPercentageOfBase.number))
    setValue('estimated_indirect_total_fee', getFixedDecimal(totalIndirectFee.number))
    setValue('estimated_revenue', getFixedDecimal(totalPercentageOfBase?.number + totalIndirectFee?.number))
    return getValueWithCurrency(totalPercentageOfBase?.number + totalIndirectFee?.number)
  }

  const getValueWithCurrency = (data) => {
    return `${getCurrencyPrefix(defaultValues.currency || '')} ${currencyWithCommas(getFixedDecimal(data || 0).toFixed(2))}`
  }
  
  return (
    <div className="section-content flex-grow-1">
      <BillingForm
        register={register}
        setValue={setValue}
        watch={watch}
        currentValues={defaultValues}
        renderLabel={renderLabel}
        renderActions={renderActions}
        editingField={editingField}
        currencyDetails={currencyDetails}
      />
      <div className='mt-5 w-50 ml-5'>
      </div>
      <div className='row'>
        <div className='col-9'>
          <PercentageOfBase
            register={register}
            setValue={setValue}
            currentValues={defaultValues}
            watch={watch}
            type='estimated'
            renderLabel={renderLabel}
            renderActions={renderActions}
            editingField={editingField}
            disabled={true}
            getValueWithCurrency={getValueWithCurrency}
          />
        </div>
        <div className='col-3'>
          <TotalRevenue
            watch={watch}
            getTotalPercentageOfBase={getTotalPercentageOfBase}
            getGrandTotal={getGrandTotal}
            renderLabel={renderLabel}
            renderActions={renderActions}
            editingField={editingField}
            getValueWithCurrency={getValueWithCurrency}
          />
          {
            is_fee_cap && Number(totalEstimate) > Number(fe_Cap_amount) && <div style={{ marginTop: "23px", marginLeft: "15px" }}>
              <span style={{ color: "red", marginTop: "20px" }}>{BILLING_VALIDATIONS.FEE_CAP_AMOUNT}</span>
            </div>
          }
        </div>
      </div>
      <div className='mt-5 w-100 '>
        <IndirectFee
          register={register}
          setValue={setValue}
          currentValues={defaultValues}
          watch={watch}
          getTotalPercentageOfBase={getTotalPercentageOfBase}
          renderLabel={renderLabel}
          renderActions={renderActions}
          editingField={editingField}
          disabled={true}
          getValueWithCurrency={getValueWithCurrency}
        />
      </div>
    </div>
  )
}

BillingDetails.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  watch: PropTypes.func,
  currentValues: PropTypes.object,
  defaultValues: PropTypes.object,
  renderActions: PropTypes.func,
  renderLabel: PropTypes.func,
  editingField: PropTypes.string,
  currencyDetails: PropTypes.object
}


export default BillingDetails