//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react'

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Tabs, Tab, ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails, withStyles } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { Link, useLocation } from 'react-router-dom';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import FiberManualRecordOutlinedIcon from '@material-ui/icons/FiberManualRecordOutlined';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import './index.scss';
function TabPanel(props) {
  const { children, value, index } = props;

  return (
    value === index && <React.Fragment>
      {children}
    </React.Fragment>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


export default function TabLayoutWithCollapseAndHeader(props) {
  const { header, headerLayout, currentTab, navItems, children, commonHeader, newTabChange, actions = [], tabChange, selectedTabIndex = 0, isActionsOnTop = false, classList, ...rest } = props;
  const [tabValue, setTabValue] = useState();
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(true);
  const toggleMenu = () => {
    setIsMenuOpen(prev => !prev);
  };

  useEffect(() => {
    if (tabValue) {
      setTabValue(currentTab)
    }
    else {
      setTabValue(currentTab || selectedTabIndex)
    }
  })

  useEffect(() => {
    if (location?.state?.isFromApproval) {
      setIsMenuOpen(false)
    }
  }, [location])
  const IconLeftExpansionPanelSummary = withStyles({
    root: {
      padding: 0,
      textAlign: 'left'
    },
    expandIcon: {
      order: -1,
      marginLeft: '12px',
      marginRight: '0'
    }
  })(ExpansionPanelSummary);

  const buildNavItems = () => {
    // let tabContents = children.filter(item => item)
    return navItems.map((item) => {
      // const disabled = tabContents[index].props.disabled ? tabContents[index].props.disabled : false;
      // if (disabled) {
      /* eslint-disable-next-line react/display-name */
      return <>
        <ExpansionPanel defaultExpanded={true} className="w-100 m-0" style={{ background: '#f4f4f5', color: '#fff' }}>
          <IconLeftExpansionPanelSummary
            expandIcon={
              <ExpandMoreIcon style={{ color: "#fff" }} />
            }
            aria-controls="panel1a-content"
            style={{ background: '#2a364c',  minHeight: "20px" }}
          >
            <div style={{ fontSize: "16px", width: '100%', textAlign: 'left' }}>{item.label}</div>
          </IconLeftExpansionPanelSummary>
          <ExpansionPanelDetails className='d-flex flex-column px-1' style={{ marginLeft: "16px" }} >
            {item.items.map((ele) => {
              /* eslint-disable-next-line react/display-name */
              return <Tab key={ele.label} component={React.forwardRef(() => {
                return (
                  <div onClick={() => handleChange(null, ele.position)} className={`d-flex align-items-center p-0 `} >
                    <div className='mr-2 ml-2'>
                      {ele.completed ? (<FiberManualRecordIcon style={{ color: '#26c137', height: "0.8rem", width: "0.8rem" }} />) : (<FiberManualRecordOutlinedIcon style={{ color: '#d9d9d9', height: "0.8rem", width: "0.8rem" }} />)}
                    </div>

                    <div className={`side-nav-item-collapse fz-14 ${ele.selected ? 'active' : ''}`} style={{ backgroundColor: ele.selected ? 'rgba(93, 89, 163, 0.15)' : 'initial' /* background: '#2a364c' */ /* padding: `20px 20px 0px 35px`, */ /* fontWeight: 500, */ /* letterSpacing: '0.09em' */ }}>
                      <span>{ele.label}</span>
                    </div>
                  </div>
                )
              })} disabled />
            })}
          </ExpansionPanelDetails>

        </ExpansionPanel>
      </>
      // }
      /* eslint-disable-next-line react/display-name */
      // return null
      // <Tab disableRipple={true} key={item.label} component={React.forwardRef((params, ref) => {
      //   return (
      //     <div ref={ref} className={`side-nav-item d-flex ${index === tabValue ? 'active' : ''}`} onClick={params.onClick}>
      //       <span className="side-nav-text">{item.label}</span>
      //     </div>
      //   )
      // })} />
    })
  }

  const buildTabDetails = () => {
    return children.filter(item => item).map((item, index) => {
      return <TabPanel key={index} index={index} value={tabValue}>
        {item}
      </TabPanel>
    })
  }

  const buildActionButtons = () => {
    return actions.map((item, index) => {
      const { label, type = "submit", ...rest } = item;
      return <button key={index} type={type} {...rest}>{label}</button>
    })
  }

  const handleChange = async (event, newValue) => {
    try {
      if (tabChange && await tabChange(tabValue, newValue) === false) {
        return;
      }
      setTabValue(newValue);
      newTabChange && newTabChange(newValue)
    } catch (e) {
      console.log("Error found in handleChange::", e);
    }
  };
  return (
    <div className="layout-wrapper d-flex flex-column h-100 w-100" {...rest}>
      <div className={`layout-header d-flex align-items-center ${classList}`}>
        {headerLayout ? headerLayout
          :
          <>
            <Link to={header.route}>
              <span className="header-back-icon">
                <ArrowBackIcon color="primary" />
              </span>
            </Link>
            <span className="header-text">{header.label}</span>
          </>
        }
      </div>
      <div className="layout-content-wrapper d-flex">
        <React.Fragment>
          <div className={`layout-side-nav d-flex flex-column h-100 ${isMenuOpen ? "open" : "closed"}`}>
            <div>
              <span className="hamburger-icon d-inline-block" onClick={toggleMenu}>
                <MenuIcon fontSize="inherit" color="primary" />
              </span>
            </div>
            <div className="layout-side-nav-items w-100">
              <Tabs
                // value={tabValue}
                orientation="vertical"
                onChange={handleChange}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "transparent"
                  }
                }}
              >
                {buildNavItems(props.navItems)}
              </Tabs>
            </div>
          </div>
          <div className={`d-flex main-content ${isActionsOnTop ? 'flex-column-reverse' : 'flex-column'}`}>
            <div className={`layout-content d-flex flex-column ${classList} ${actions.length > 0 ? "layout-with-actions" : ''}`}>
              {commonHeader}
              {buildTabDetails(props.children)}
            </div>
            {actions.length > 0 &&
              <div className={`layput-actions d-flex justify-content-end ${classList}`}>
                {buildActionButtons()}
              </div>
            }
          </div>
        </React.Fragment>
      </div>
    </div>
  )
}
TabLayoutWithCollapseAndHeader.propTypes = {
  children: PropTypes.node,
  commonHeader: PropTypes.node,
  header: PropTypes.object,
  headerLayout: PropTypes.node,
  navItems: PropTypes.array.isRequired,
  actions: PropTypes.array,
  tabChange: PropTypes.func,
  isActionsOnTop: PropTypes.bool,
  selectedTabIndex: PropTypes.number,
  currentTab: PropTypes.number,
  newTabChange: PropTypes.func,
  grid: PropTypes.string,
  classList: PropTypes.string
};
