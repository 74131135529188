
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from "react";

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from "prop-types";

//-----------------------------------------------------------// Internal Imports // ------------------------------

import CompensationDetails from "../../AddContact/Components/Compensation";


export default function Edit(props) {
  const {register, watch, setValue, control} = props;
  return(
    <CompensationDetails
      register={register}
      control={control}
      watch={watch}
      setValue={setValue}
    />
  )
}

Edit.propTypes = {
  control: PropTypes.object,
  register: PropTypes.func,
  watch: PropTypes.func,
  setValue: PropTypes.func
}
