//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useState, useEffect } from 'react'

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from "prop-types";
import { useSnackbar } from "notistack";
import { Container, Typography } from '@material-ui/core';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import RichTextPopup from "../../../../RichTextPopup";
import { searchDataApi } from '../../../../../services/ApiService';
import { SUCCESS, ERROR, PATCH } from "../../../../../services/constantService";
import { unableMessage, successMessage, VALIDATION_MESSAGE } from '../../../../../services/MessageService';

function PartnerBrief(props) {
  const { setValue, register, currentValues, searchInfo, setSfpaData, setCurrentValues, readOnly } = props;
  const { enqueueSnackbar } = useSnackbar()
  const [editorHtml, setEditorHtml] = useState(currentValues.partner_brief)
  const [isLastSaveSuccess, setLastSaveSuccess] = useState(true)

  useEffect(() => {
    register("partner_brief");
    setEditorHtml(currentValues.partner_brief);
  }, [currentValues.partner_brief, register])

  useEffect(() => {
    setValue('partner_brief', editorHtml)
  }, [editorHtml, setValue])

  const savePartnerBrief = async (payload, skipMessage, setAutoSaveLoading) => {
    if (searchInfo !== null && searchInfo.id) {
      delete payload.old
      let returnResult;
      if (setAutoSaveLoading) {
        setAutoSaveLoading(true)
      }
      const { status, data } = await searchDataApi(PATCH, searchInfo.id, payload, 'search-autosave-field');
      if (status && status === 200) {
        // setSFPAUpdated(data.updated_at);
        payload.updated_at = data.result.updated_at
        setCurrentValues({ ...currentValues, ...payload })
        setSfpaData(payload);
        const message = successMessage("Partner brief", VALIDATION_MESSAGE.updated_message)
        !skipMessage && enqueueSnackbar(message, { variant: SUCCESS });
        setLastSaveSuccess(true)
        returnResult = true
      } else {
        const message = unableMessage("partner brief", "update")
        enqueueSnackbar(data.message || message, { variant: ERROR });
        setLastSaveSuccess(false)
        returnResult = false
      }
      if (setAutoSaveLoading) {
        setTimeout(() => {
          setAutoSaveLoading(false)
        }, 500);
      }
      return returnResult;
    }
  }

  return (
    <React.Fragment>
      <div className={`partner-brief react-quill-container ${readOnly ? 'section-content read-only' : ''}`}>
        <Container style={{ marginTop: '20px', marginLeft: '5px', width: '80%' }}>
          <Typography color={"primary"}>Partner Brief</Typography>
          <div className='text-view-value p-0 w-100'>
            <div className='outlined-border transform p-0'>
              <RichTextPopup
                onChange={setEditorHtml}
                value={editorHtml}
                setOpen={readOnly ? false : true}
                onSave={savePartnerBrief}
                title="Partner Brief"
                name='partner_brief'
                autoSave={savePartnerBrief}
                saveStatus={isLastSaveSuccess}
                updatedDate={currentValues.updated_at}
                restrictHeight={false}
              />
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

PartnerBrief.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  currentValues: PropTypes.object,
  readOnly: PropTypes.bool,
  searchInfo: PropTypes.object,
  setSfpaData: PropTypes.func,
  setCurrentValues: PropTypes.func,
}

export default PartnerBrief;
