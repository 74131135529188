
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from 'react'

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from "prop-types";

//-----------------------------------------------------------// Internal Imports // ------------------------------

import './index.scss'
import RecentSearches from './RecentSearches'
import RecentActivities from './RecentActivities'

export default function RecentSearchesActivities(props) {
  const { contact } = props;
  return (
    <>
      <RecentSearches contact={contact}/>
      <RecentActivities contact={contact}/>
    </>
  )
}

RecentSearchesActivities.propTypes = {
  contact: PropTypes.object,
}
