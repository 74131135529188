//----------------------------------------------// In-built Imports // -------------------------------------------------
import React from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';

//----------------------------------------------// Internal Imports // -------------------------------------------------

import './index.scss';

function RightSideNav(props) {
  const {navItems, activeIndex = 0} = props
  
  const getNavItems = (navItems) => {
    return navItems.map((item, index) => {
      return <span key={index} className={`righ-nav-item ${activeIndex === index ? 'active' : ''}`}>{item}</span>
    });
  };

  return (
    <div className="right-nav-section d-flex flex-column">
      {getNavItems(navItems)}
    </div>
  );
}

RightSideNav.propTypes = {
  navItems: PropTypes.array,
  activeIndex: PropTypes.number
}

export default RightSideNav;