//----------------------------------------------// In-built Imports // -------------------------------------------------
import React from 'react'

//----------------------------------------------// External Imports // -------------------------------------------------
import { Link } from 'react-router-dom';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import Logo from '../../assets/images/logo.png';
import './index.scss'
import HeaderNavItems from './HeaderNavItems';
import HeaderProfile from './HeaderProfile';
import MasterSearchSelection from '../MasterSearch/MasterSearchSelection';
import UserNotification from '../UserNotification';

export default function Header() {
  return (
    <div className="header d-flex align-items-center">
      <Link to="/">
        <div className="logo-container">
          <img src={Logo} alt="logo" />
        </div>
      </Link>
      <HeaderNavItems className="header-nav-items" />
      <div className='header-master-search'>
        <MasterSearchSelection />
      </div>
      <UserNotification />
      <HeaderProfile className="profile-container" />
    </div>
  )
}
