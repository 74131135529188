
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useState, useEffect } from 'react'

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CircularProgress } from "@material-ui/core";
import { useSnackbar } from 'notistack';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import InputField from '../common/InputField';
import { userDataApi } from '../../services/ApiService';
import { GET, ERROR } from '../../services/constantService';
import { unableMessage } from '../../services/MessageService';

function UserSelection(props) {
  const { label = "User", placeholder = "User", required = false, disabled = false, InputProps, InputLabelProps, inputRef, header = null, list = [], option = [], variant = 'standard', ...rest } = props;
  const { enqueueSnackbar } = useSnackbar()
  const [options, setOptions] = useState(option ? option : []);
  const [value, setValue] = useState('');
  const [isLoading, setLoading] = useState(false);

  //do not add options in dependency. it will causing issue of infinite api call.
  const filterDataOptions = (data) => {
    return data.filter((item) => {
      return list.every(listItem => listItem?.user?.id !== item?.id)
    })
  }
  const filterSearchOptions = (data) => {
    return data.filter(item => item?.name?.match(new RegExp(value, "gi")))
  }
  useEffect(() => {
    if (header === "TeamInfo") {
      setOptions(option)
    }
  }, [option])

  useEffect(() => {
    setOptions(option)
    if (value.length > 0 || options.length === 0) {
      setLoading(true)
      const getUsers = async () => {
        const sub_route = `search?name=${value}`
        let { status, data } = await userDataApi(GET, '', '', sub_route)
        if (status && status === 200 && data?.data) {
          if (header === "TeamInfo" && list.length > 0) {
            const filterData = filterDataOptions(data.data)
            const SearchOption = filterSearchOptions(filterData)
            setOptions(SearchOption);
          } else {
            setOptions(data.data);
          }
        }
        else {
          const message = unableMessage("users", "fetch")
          enqueueSnackbar(message, { variant: ERROR });
        }
        setLoading(false)
      };
      getUsers()
    }
  }, [value, enqueueSnackbar]);

  return (
    <Autocomplete
      {...rest}
      options={options}
      filterOptions={(options) => options}
      getOptionLabel={(option) =>
        (option.first_name || "") + " " + (option.last_name || "")
      }
      getOptionSelected={(option, value) => option.id === value.id}
      onInputChange={(e, val, reason) => {
        if (reason === "input") {
          setValue(val);
        }
      }}
      disabled={disabled}
      renderInput={(params) => (
        <InputField
          {...params}
          ref={inputRef}
          InputProps={{
            ...params.InputProps,
            ...InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoading ? (
                  <CircularProgress color="primary" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            )
          }}
          variant={variant}
          InputLabelProps={InputLabelProps}
          required={required}
          label={label}
          placeholder={placeholder}
        />
      )}
    />
  );
  /* return (
    <Autocomplete
      {...rest}
      disabled={disabled}
      options={options}
      getOptionLabel={(option) => (option.first_name || '') + ' ' + (option.last_name || '')}
      getOptionSelected={(option, value) => (option.id === value.id)}
      renderInput={(params) => (
        <InputField
          {...params}
          ref={inputRef}
          InputProps={{ ...params.InputProps, ...InputProps }}
          variant="standard"
          InputLabelProps={InputLabelProps}
          required={required}
          label={label}
          placeholder={placeholder}
        />
      )}
    />
  ) */
}


UserSelection.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  option: PropTypes.array,
  InputLabelProps: PropTypes.object,
  InputProps: PropTypes.object,
  inputRef: PropTypes.object,
  disabled: PropTypes.bool,
  header: PropTypes.string,
  list: PropTypes.array,
  variant: PropTypes.string
};

export default UserSelection
