
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from 'react'

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from 'prop-types'

//-----------------------------------------------------------// Internal Imports // ------------------------------

import RecentSearchesActivities from './RecentSearchesActivities';
import RightSideNav from '../RightSideNav';
import RichTextPopup from '../RichTextPopup';

export default function PublishedBio(props) {
  const { contact, handleRichTextSave, individualFieldSave, saveStatus, handleMouseEnter, handleMouseLeave, renderLabel, renderActions, editingField } = props;

  return (
    <React.Fragment>
      <div className="d-flex contact-view">
        <div className="d-flex tab-view-content">
          <div className="section-container">
            <div className="section-header">Published Bio</div>
            <div className="d-flex contact-details-row">
              <div className="contact-details">
                {/* <div className="contact-view-label">Published Bio</div> */}
                {renderLabel('Published Bio', 'published_bio')}
                {editingField === 'published_bio' ?
                  <>
                    <div className="contact-view-value react-quill-container d-flex justify-content-between">
                      <div className='flex-grow-1'>
                        <div className='text-view-value p-0 w-100'>
                          <div className='outlined-border transform p-0 '>
                            <RichTextPopup
                              name="published_bio"
                              onSave={handleRichTextSave}
                              value={contact['published_bio']}
                              bounds={'.contact-view-value'}
                              title="Published Bio"
                              autoSave={individualFieldSave}
                              saveStatus={saveStatus}
                              updatedDate={contact.updated_at}
                            />
                          </div>
                        </div>

                      </div>
                      <div>
                        {renderActions('published_bio', false, { 'published_bio': contact.published_bio })}

                      </div>

                    </div>
                  </>
                  : <div
                    className="contact-view-value react-quill-container"
                    onMouseEnter={() => handleMouseEnter('published_bio')}
                    onMouseLeave={handleMouseLeave} >
                    <div className='outlined-border transform '>
                      <RichTextPopup
                        bounds={'.react-quill-container'}
                        name="published_bio"
                        value={contact.published_bio}
                        title={'Published Bio'}
                        setOpen={false}
                      // placeholder={''}
                      />
                    </div>
                  </div>}
              </div>
            </div>
          </div>
        </div>
        <div className="tab-view-right-nav">
          <RightSideNav navItems={['Published Bio']} />
          <RecentSearchesActivities contact={contact} />
        </div>
      </div>
    </React.Fragment>
  )
}

PublishedBio.propTypes = {
  contact: PropTypes.object,
  saveContact: PropTypes.func,
  handleRichTextSave: PropTypes.func,
  setContact: PropTypes.func,
  individualFieldSave: PropTypes.func,
  saveStatus: PropTypes.bool,
  handleMouseEnter: PropTypes.func,
  handleMouseLeave: PropTypes.func,
  renderActions: PropTypes.func,
  renderLabel: PropTypes.func,
  editingField: PropTypes.string,
}
