
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useState } from "react";

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from "prop-types";
import MenuIcon from "@material-ui/icons/Menu";
import { Checkbox, FormControlLabel, Popover, Tooltip } from "@material-ui/core";
import { makeStyles } from '@material-ui/core';



const useStyles = makeStyles(() => ({
  badge: {
    height: '10px', 
    width: '10px', 
    background: 'red', 
    borderRadius: '50%'
  },
  badgeWrapper: {
    height: '30px', 
    width: '30px', 
    position: 'fixed', 
    left: -14, 
    top: 17, 
    display: 'flex', 
    justifyContent: 'center', 
    alignItems: 'center'
  },
  menuWrapper: {
    position: 'relative'
  }
}));

export default function ColumnFilter(props) {
  const classes = useStyles();
  const { columnApi, defaultColumns = [], filterModel, showFilterCount } = props;
  const [anchorEl, setAnchorEl] = useState();
  // eslint-disable-next-line no-unused-vars
  const [visible, setVisible] = useState(false);
  const [viewColumns, setViewColumns] = useState(false);

  function handleMessage() {
    if (showFilterCount === 1) {
      return (`Filter is applied on ${Object.keys(filterModel)} column`)
    }
    else {
      return (`Filters are applied on ${Object.keys(filterModel).length} columns`)
    }
  }
  return (
    <React.Fragment>
      <div
        className={"columns-icon"}
        onClick={(event) => {
          setViewColumns((prevState) => !prevState);
          setAnchorEl(event.currentTarget);
        }}
      >
        {showFilterCount > 0 ? <Tooltip title={handleMessage()} placement="top-start">
          <div className={classes.menuWrapper}>
            <div className={classes.badgeWrapper}>
              <div className={classes.badge}>
              </div>
            </div>
            <MenuIcon style={{ height: "0.8em" }} />
          </div>
        </Tooltip> : <MenuIcon style={{ height: "0.8em" }} />}
      </div>
      
      <Popover
        onClose={() => {
          setViewColumns(false);
        }}
        open={viewColumns}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {columnApi
          .getAllColumns()
          .filter((column) => !column.colDef.suppressColumnsToolPanel)
          .map((column, index) => {
            if (!defaultColumns.includes(column.userProvidedColDef.field)) {
              return (
                <FormControlLabel
                  className="checkbox-container"
                  key={index}
                  style={{
                    display: "flex",
                    padding: "0 10px 0 20px",
                    margin: 0,
                  }}
                  control={
                    <Checkbox
                      className="checkbox-component"
                      color="primary"
                      onChange={() => {
                        columnApi.setColumnVisible(
                          column.colId,
                          !column.visible
                        );
                        columnApi.autoSizeColumn(column.colId, true);
                        setVisible((prevState) => !prevState);
                      }}
                      checked={column.visible}
                    />
                  }
                  label={column.userProvidedColDef.headerName}
                />
              );
            }
            return null;
          })}
      </Popover>
    </React.Fragment>
  );
}

ColumnFilter.propTypes = {
  columnApi: PropTypes.object,
  defaultColumns: PropTypes.array,
  filterModel:PropTypes.object,
  showFilterCount:PropTypes.number
};
