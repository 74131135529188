
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useRef, forwardRef, useImperativeHandle } from 'react'

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from 'prop-types'

//-----------------------------------------------------------// Internal Imports // ------------------------------

import Notes from '../../Notes';
import { useDispatch, useSelector } from 'react-redux';
import { companyActions } from '../../../store/companySlice';

/* eslint-disable-next-line react/display-name */
const CompanyNotes = forwardRef((props, ref) => {
  const { companyId } = props
  const companyNotes = useSelector(state => state.companyReducer.companyNotes)
  const dispatch = useDispatch()

  const companyDispatch = (data) => {
    dispatch(companyActions.updateCompanyNotes(data))
  }

  const notesRef = useRef();

  useImperativeHandle(ref, () => ({
    handleAddNotes() {
      notesRef.current.handleAddNotes()
    }
  }));

  return (
    <div className="d-flex notes-details notes-section">
      <div className="notes-attachment-label">Notes</div>
      <Notes ref={notesRef} dispatchDetails={companyDispatch} notesDetails={companyNotes} apiConfig={{ parentId: companyId, parentPath: "company" }} className="notes-attachment-content" />
    </div>
  )
})

CompanyNotes.propTypes = {
  companyId: PropTypes.string,
}

export default CompanyNotes;
