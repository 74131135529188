//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useRef, useState } from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import { Button, Typography, TextField } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close'
import { useForm, Controller } from "react-hook-form";
import { InputLabel } from '@material-ui/core';


//----------------------------------------------// Internal Imports // -------------------------------------------------
import CompanySelection from "../AddContact/Components/CompanySelection";
import YearSelection from "../YearSelection";
import CountrySelection from "../CountrySelection";
import ErrorPopup from "../AddCompany/ErrorPopup";
import Loader from "../common/Loader";
import IndustrySelection from "../IndustrySelection";
import JobFunctionSelection from "../jobFunctionSelection";
import { VALIDATION_MESSAGE } from "../../services/MessageService";

export default function QuickAddContact(props) {
  const { first_name, addContact, handleClose, currentCompany } = props
  const { register, unregister, control, handleSubmit, getValues } = useForm({ defaultValues: { first_name } })


  const companyRef = useRef();
  const countryRef = useRef();
  const startYearRef = useRef();
  const industryRef = useRef();
  const jobFunctionRef = useRef();
  const [duplicateContacts, setDuplicateContacts] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleAdd = async () => {

    setLoading(true);
    const duplicateContacts = await addContact({ ...getValues({ nest: true }) });
    if (duplicateContacts) {
      setDuplicateContacts(duplicateContacts);
    }
    setLoading(false);
    // enqueueSnackbar("Contact Added Successfully" ,{ variant: SUCCESS })
  };

  const handleCancel = () => {
    setDuplicateContacts(false);
    handleClose()
  };

  const handleAddDuplicate = async () => {
    try {
      await addContact({ ...getValues({ nest: true }) }, true);
    }
    catch (e) {
      console.log("Error found in handleAddDuplicate::", e);
    }
  };


  const handleShowContacts = () => {
    const idQuery = duplicateContacts.map(contact => `id=${contact.id}`).join('&');
    window.open(`/contacts?${idQuery}`);
  };

  useEffect(() => {
    register('current_company');
    return () => {
      unregister('current_company');
    }
  }, [register, unregister])

  return (
    <React.Fragment>
      <Loader show={loading} />
      {duplicateContacts &&
        <ErrorPopup onCancel={handleCancel} onView={handleShowContacts} onContinue={handleAddDuplicate}
          header={VALIDATION_MESSAGE.contact_already_exist}
          viewButtonText={"View/Edit existing contacts"} />}
     
      <div className="d-flex quick-add-header text-left">
        <Typography>Quick Add Contact</Typography>
        <CloseIcon cursor='pointer' onClick={handleClose} />
      </div>
       
         
      <div className='d-flex flex-column py-3'>
        <div className='d-flex contact-details-row'>
          <div className="d-flex intersection-page-view">
            <InputLabel className="page-label">First Name<span className="MuiInputLabel-asterisk"> *</span></InputLabel>
            <div className='page-label-value'>
              <TextField
                placeholder='First Name'
                // required={true}
                InputLabelProps={{ focused: true }}
                className='input-form-field'
                inputRef={register()}
                name={'first_name'}
                variant="outlined"
              />
            </div>
          </div>
          <div className="d-flex intersection-page-view">
            <InputLabel className="page-label">Last Name<span className="MuiInputLabel-asterisk"> *</span></InputLabel>
            <div className='page-label-value'>
              <TextField
                placeholder='Last Name'
                // required={true}
                InputLabelProps={{ focused: true }}
                className='input-form-field'
                inputRef={register()}
                name={'last_name'}
                variant="outlined"
              /></div>
          </div>
        </div>
        <div className='d-flex contact-details-row '>
          <div className="d-flex intersection-page-view">
            <InputLabel className="page-label">Company Name<span className="MuiInputLabel-asterisk"> *</span></InputLabel>
            <div className='page-label-value'>
              <Controller
                name='current_company'
                defaultValue={currentCompany || null}
                as={<CompanySelection
                  // required={true}
                  inputRef={companyRef}
                  defaultValue={currentCompany || null}
                  className='input-form-field'
                  placeholder={"Search"}
                  InputLabelProps={{ focused: true }}
                  variant="outlined"
                  label=''
                />}
                control={control}
                onFocus={() => { companyRef.current.focus() }}
                onChange={([, data]) => data ? data : null}
                // rules={{ required: true }}
              /></div>
          </div>
          <div className="d-flex intersection-page-view">
            <InputLabel className="page-label">Job Title<span className="MuiInputLabel-asterisk"> *</span></InputLabel>
            <div className='page-label-value'>
              <TextField
                // required
                placeholder='Job Title'
                className='input-form-field'
                inputRef={register()}
                name='current_job_title'
                InputLabelProps={{ focused: true }}
                variant="outlined"
              />
            </div>
          </div>
        </div>
        <div className='d-flex contact-details-row '>
          <div className="d-flex intersection-page-view">
            <InputLabel className="page-label">Job Start Year<span className="MuiInputLabel-asterisk"> *</span></InputLabel>
            <div className='page-label-value'>
              <Controller
                name={'current_job_start_year'}
                as={
                  <YearSelection
                    autoSelect
                    autoHighlight
                    className='input-form-field'
                    yearType='start'
                    inputRef={startYearRef}
                    variant="outlined"
                    // required={true}
                    label=''
                    InputLabelProps={{ focused: true }}
                  />
                }
                control={control}
                onFocus={() => { startYearRef.current.focus() }}
                onChange={([, data]) => data}
                // rules={{ required: true }}
              /></div>
          </div>
          <div className="d-flex intersection-page-view">
            <InputLabel className="page-label">Location<span className="MuiInputLabel-asterisk"> *</span></InputLabel>
            <div className='page-label-value'>
              <Controller
                name='country'
                as={<CountrySelection
                  // required={true}
                  inputRef={countryRef}
                  className='input-form-field'
                  placeholder={"country"}
                  InputLabelProps={{ focused: true }}
                  label=''
                  variant="outlined"
                />}
                control={control}
                onFocus={() => { countryRef.current.focus() }}
                onChange={([, data]) => data ? data.name : null}
                // rules={{ required: true }}
              /></div>
          </div>
        </div>
        <div className='d-flex contact-details-row '>
          <div className="d-flex intersection-page-view">
            <InputLabel className="page-label">Industry<span className="MuiInputLabel-asterisk"> *</span></InputLabel>
            <div className='page-label-value multiple-selection-list'>
              <Controller
                name="industries"
                as={
                  <IndustrySelection
                    multiple
                    // required={true}
                    placeholder={"Select"}
                    variant="outlined"
                    inputRef={industryRef}
                    size="small"
                    filterSelectedOptions={true}
                    className="input-form-field"
                  />
                }
                control={control}
                onFocus={() => {
                  industryRef.current.focus();
                }}
                onChange={([, data]) =>
                  Array.isArray(data) ? data.map((item) => item.name) : []
                }
                // rules={{ required: true, validate: d => d.length > 0 }}
              /></div>
          </div>
          <div className="d-flex intersection-page-view">
            <InputLabel className="page-label">Job function<span className="MuiInputLabel-asterisk"> *</span></InputLabel>
            <div className='page-label-value multiple-selection-list'>
              <Controller
                name="job_functions"
                as={
                  <JobFunctionSelection
                    multiple
                    variant="outlined"
                    // required={true}
                    inputRef={jobFunctionRef}
                    className="input-form-field "
                    filterSelectedOptions={true}
                    label=''
                  />
                }
                control={control}
                onFocus={() => {
                  jobFunctionRef.current.focus();
                }}
                onChange={([, data]) => {
                  return data ? data.map((item) => typeof item === "string" ? item : item.name) : []
                }}
                // rules={{ required: true, validate: d => d.length > 0 }}
              /></div>
          </div>


        </div>
      </div>
      <div className="page-content-footer">

        <Button className="page-btn-cancel"  onClick={handleCancel} >Cancel</Button>
        <Button variant="contained"  type="submit" className="page-btn-save" onClick={handleSubmit(handleAdd)}>Submit</Button>

      </div>
         
       
    </React.Fragment>
  )
}

QuickAddContact.propTypes = {
  first_name: PropTypes.string,
  addContact: PropTypes.func,
  handleClose: PropTypes.func,
  currentCompany: PropTypes.object

}
