//----------------------------------------------// In-built Imports // -------------------------------------------------
import React from 'react'

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types'

// -------------------------------------------------// External Imports// -------------------------------------------------

import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from "@material-ui/core";

function TimeZoneSelection(props) {
  const {
    label,
    placeholder = "Select Timezone",
    InputLabelProps,
    name = 'time_zone',
    disabled = false,
    register,
    options,
    ...rest
  } = props;
  return (
    <Autocomplete
      {...rest}
      options={options}
      getOptionSelected={(option, value) => {
        if (!value) {
          return false;
        }
        if (typeof value === "string") {
          return option.name === value;
        }
        return option.name === value.name;
      }}
      getOptionLabel={(option) => {
        if (!option) {
          return "";
        }
        if (typeof option === "string") {
          return option;
        }
        return option?.name;
      }}
      disabled={true || disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          inputRef={register}
          name={name}
          variant="standard"
          InputLabelProps={InputLabelProps}
          label={label}
          placeholder={placeholder}
        />
      )}
    />
  );
}
TimeZoneSelection.propTypes = {
  defaultValue: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  register: PropTypes.func,
  options: PropTypes.object,
  InputLabelProps: PropTypes.object,
  required: PropTypes.bool,
  inputRef: PropTypes.object,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool
};
export default TimeZoneSelection;