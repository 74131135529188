//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useRef } from 'react'

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from 'prop-types'

//-----------------------------------------------------------// Internal Imports // ------------------------------

import Attachments from '../../Attachments';
import { useDispatch, useSelector } from 'react-redux';
import { contactActions } from '../../../store/contactSlice';

const ContactOfferLetter = (props) => {

  const { contactId, disabled = false } = props;
  const contactOfferLetter = useSelector(state => state.contactReducer.contactOfferLetter)
  const dispatch = useDispatch();
  const offerlettersRef = useRef();

  const contactDispatch = (data) => {
    dispatch(contactActions.updateContactOfferLetter(data))
  }

  return (
    <div className="section-container mb-5">
      <div className="section-header">Offer Letter</div>
      <Attachments ref={offerlettersRef} dispatchDetails={contactDispatch} attachmentsDetails={contactOfferLetter} disabled={disabled} apiConfig={{ parentId: contactId, parentPath: "contact", attachmentType: "offerLetter" }} uiConfig={{ canEdit: false, showDescription: false, attachmentType: "OfferLetter" }} className="contact-attachments" />
      <button className="primary-btn" disabled={disabled} onClick={() => offerlettersRef.current.handleAddAttachment()}>Upload</button>
    </div>
  )
}

ContactOfferLetter.propTypes = {
  contactId: PropTypes.string,
  disabled: PropTypes.bool
}

export default ContactOfferLetter;
