
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useState, useCallback, useEffect } from 'react'
import * as yup from "yup";

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from 'prop-types'
import AttachFileIcon from '@material-ui/icons/AttachFile';
import PersonIcon from '@material-ui/icons/Person';
import DetailsIcon from '@material-ui/icons/LibraryBooks';
import SearchIcon from '@material-ui/icons/Search'
import { Tabs, Tab, Button } from '@material-ui/core'
import { useSnackbar } from 'notistack';
import { Helmet } from "react-helmet";
import { useParams, useHistory } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useForm } from "react-hook-form";
import Typography from "@material-ui/core/Typography";
import { useDispatch } from 'react-redux';

//-----------------------------------------------------------// Internal Imports // ------------------------------

import './index.scss'
import NotesAttachments from './NotesAttachments'
import CompanyDetails from './CompanyDetails'
import Searches from "./Searches"
import ContactList from './Contacts';
import Loader from '../common/Loader';
import { cleanupCompany } from "./CompanyDetails/utils";
import ConfirmationPopup from "../ConfirmationPopup";
import { GET, PUT, WARNING, ERROR, SUCCESS, API, POST, EMAIL_REGEX } from '../../services/constantService';
import { acquireLockApi, companyDataApi, releaseLockApi, renewLockApi } from '../../services/ApiService';
import { unableMessage, validateMessage, successMessage, VALIDATION_MESSAGE } from '../../services/MessageService';
import { companyActions } from '../../store/companySlice';
import BoardMembers from './BoardMembers/index';
import { getAccessToken } from '../../services/cognitoService';
import { SPECIAL_CHARACTERS_REPLACE_REGEX, REPLACE_SPACE_REGEX } from '../../services/constantService';
import { customFormValidator, requireValidMessage } from '../../utils/common';

const NAV_ITEMS = ["Details", "Searches", "Notes & Attachments", "Contacts", "Board Members"];
function TabPanel(props) {
  const { children, value, index } = props;
  return (
    value === index && <React.Fragment>
      {children}
    </React.Fragment>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function ViewCompany() {

  const { id, currentTabs } = useParams();
  const history = useHistory();
  const newNavItems = NAV_ITEMS.map((item) => {

    item = item.replace(SPECIAL_CHARACTERS_REPLACE_REGEX, 'and');

    return item.toLowerCase().replace(REPLACE_SPACE_REGEX, '-')
  })
  let currentTabIndex = newNavItems.findIndex(item => item === currentTabs);
  currentTabIndex = currentTabIndex === -1 ? history.push(`/companies/${id}/details`) : currentTabIndex;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();


  const [company, setCompany] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [showConfirmBack, setShowConfirmBack] = useState(false)
  const { register, setValue, handleSubmit, errors, watch, reset, control, getValues, triggerValidation, formState } = useForm();
  const { dirty } = formState
  const [value, setTabValue] = useState();
  const [isLastSaveSuccess, setLastSaveSuccess] = useState(true)
  const dispatch = useDispatch();
  const [newProfilePic, setNewProfilePic] = useState(null)
  const [profileUrl, setProfileUrl] = useState(null);
  const [checkPic, setCheckPic] = useState(false);

  useEffect(() => {
    if (value) {
      setTabValue(currentTabIndex)
    }
    else {
      setTabValue(currentTabIndex || 0)
    }
  })
  // useEffect(() => {
  //   if (location.search) {
  //     const defaultTab = location.search.split('defaultTab=')[1].split("&")[0];
  //     setTabValue(parseInt(defaultTab));
  //   }
  // }, [location.search])

  const updateProfileUrl = useCallback(async () => {
    if (company?.image_id || profileUrl) {
      const token = await getAccessToken();
      setProfileUrl(`${API.companies}/${company.id}/image?token=${token}&t=${Date.now()}`);
    } else {
      setProfileUrl(null)
    }
  }, [profileUrl])

  const cancelProfileUrl = useCallback(async () => {
    if (company?.image_id || checkPic) {
      const token = await getAccessToken();
      setProfileUrl(`${API.companies}/${company.id}/image?token=${token}&t=${Date.now()}`);
    } else {
      setProfileUrl(null)
    }
  })
  useEffect(() => {
    const getProfileURL = async () => {
      const token = await getAccessToken();
      setProfileUrl(`${API.companies}/${company.id}/image?token=${token}&t=${Date.now()}`);
    }

    if (company?.image_id) {
      getProfileURL();
    }
  }, [company?.image_id])

  const uploadProfilePic = async () => {
    if (newProfilePic) {
      var bodyFormData = new FormData();
      bodyFormData.append('profile', newProfilePic);
      setCheckPic(true);
      setIsLoading(true)
      const sub_route = 'image'
      const { status, data } = await companyDataApi(POST, company.id, bodyFormData, sub_route);
      setIsLoading(false);
      setNewProfilePic(null);
      if (status === 200) {
        const message = successMessage("Profile pic", "uploaded")
        enqueueSnackbar(data?.message || message, { variant: SUCCESS });
      } else {
        const message = unableMessage("profile pic", "upload")
        enqueueSnackbar(data?.message || message, { variant: ERROR });
      }
      updateProfileUrl().then(null);
    }
  }

  useEffect(() => {
    register({ name: "is_rock_star" });
    return () => {
      register("is_rock_star");
    };
  }, [register])

  useEffect(() => {
    return () => {
      releaseLock();
    };
  }, [isEditing])

  useEffect(() => {
    return () => {
      dispatch(companyActions.resetCompanyState())
    };
  }, []);

  const handleChange = async (event, newValue) => {
    try {
      if (isEditing && dirty && value === 0) {
        await triggerValidation()
        if (Object.keys(errors).length !== 0) {
          const message = validateMessage("required fields", "fill all")
          enqueueSnackbar(message, { variant: WARNING })
          return false;
        }
        await updateCompany(getValues({ nest: true }))
      }
      if (newValue !== undefined || newValue !== null) {
        history.push(`/companies/${id}/${newNavItems[newValue]}`)
      }
      setTabValue(newValue);
    } catch (e) {
      console.log("Error found in handleChange::", e);
    }
  };

  const getCompanyDetails = useCallback(async (id) => {
    setIsLoading(true)
    let { status, data } = await companyDataApi(GET, id);
    if (status && status === 200) {
      // if (data === null) enqueueSnackbar(VALIDATION_MESSAGE.company_not_available_message, { variant: ERROR });
      setCompany(data);
    } else {
      const message = unableMessage("company details", "fetch")
      enqueueSnackbar(data.message || message, { variant: ERROR });
    }
    setIsLoading(false)
  }, [enqueueSnackbar, setCompany, setIsLoading])

  const validateEmail = (email) => {
    return email && !email.match(EMAIL_REGEX) ? false : true;
  }

  const CompanySchema = yup.object().shape({
    name: yup.string().required(),
    website: yup.string().required(),
    country: yup.string().required(),
    industries: yup.array().of(yup.string()).required(),
  });

  const saveCompanyDetails = async (formData, inlineEdit = false, skipEditCancel) => {
    try {
      if (!inlineEdit) {
        let isValid = await CompanySchema.isValid(formData);
        if (!isValid) {
          let requiredField = [
            { fieldName: "name", label: "Name", type: String },
            { fieldName: "website", label: "Website", type: String },
            { fieldName: "country", label: "Country", type: String },
            { fieldName: "industries", label: "Industry", type: [] },
          ]
          let dirtyField = customFormValidator(formData, requiredField)
          if (dirtyField) {
            const message = requireValidMessage(dirtyField)
            enqueueSnackbar(message, { variant: ERROR })
            return
          }
        }
      }
      let validateEmailSyntax = true
      validateEmailSyntax = validateEmail(formData.email_syntax)
      if (!validateEmailSyntax) {
        const message = validateMessage("Valid Email Syntax", "enter")
        enqueueSnackbar(message, { variant: ERROR })
        return
      }
      !skipEditCancel && setIsLoading(true);
      const response = await companyDataApi(PUT, id, formData);
      if (response) {
        const { status, data } = response;
        if (status === 200) {
          // Here call get api due to performance issue not getting updated details at prod env
          // setCompany(data);
          await getCompanyDetails(id)
          setLastSaveSuccess(true)
          await uploadProfilePic(newProfilePic)
          if (!skipEditCancel) {
            setIsEditing(false);
            const message = successMessage("Company", VALIDATION_MESSAGE.updated_message)
            enqueueSnackbar(message, { variant: SUCCESS });
          }
          setIsLoading(false)
          return
        } else if (status === 409) {
          const message = unableMessage("company", "update")
          enqueueSnackbar(data?.message || message,
            {
              persist: true,
              variant: ERROR,
              // eslint-disable-next-line react/display-name
              action: key => (
                <Button
                  size='small'
                  variant='text'
                  color='inherit'
                  onClick={() => {
                    closeSnackbar(key)
                  }}
                >
                  Dismiss
                </Button>
              )
            }
          );
        } else {
          const message = unableMessage("company", "update")
          enqueueSnackbar(data?.message || message, { variant: ERROR });
        }
        !skipEditCancel && setIsLoading(false)
      }
      setLastSaveSuccess(false);
    } catch (e) {
      console.log("Error found in saveCompanyDetails::", e);
    }
  }

  const updateCompany = async (data) => {
    data = cleanupCompany(data)
    data.updated_at = company.updated_at;
    await saveCompanyDetails(data)
    releaseLock();
  }

  const releaseLock = async () => {
    if (isEditing || editLockRenewTimer) {
      await releaseLockApi(id);
      editLockRenewTimer = clearInterval(editLockRenewTimer);
    }
    window.removeEventListener('beforeunload', releaseLock, true);
  }

  let retryCount = 0;
  const halfAnHour = 30 * 60 * 1000;
  let editLockRenewTimer;

  const handleEdit = async () => {
    await reloadData();
    let { status, data } = await acquireLockApi(id);
    if (status && status === 200) {
      setIsEditing(true)
      window.addEventListener("beforeunload", releaseLock);
      editLockRenewTimer = setInterval(renewLock, halfAnHour);
    } else {
      enqueueSnackbar(data.message, { variant: WARNING });
    }
  }

  const renewLock = () => {
    if (document.hidden) {
      if (retryCount > 3) {
        clearInterval(editLockRenewTimer);
      }
      retryCount++;
    } else {
      retryCount = 0;
      renewLockApi(id);
    }
  }

  const handleCancel = () => {
    releaseLock();
    cancelProfileUrl()
    setIsEditing(false)
  }

  useEffect(() => {
    getCompanyDetails(id)
  }, [getCompanyDetails, id])

  const reloadData = async () => {
    try {
      await getCompanyDetails(id)
    } catch (e) {
      console.log("Error found in reloadData::", e);
    }
  }

  const handleBack = () => {
    if (isEditing && dirty) {
      setShowConfirmBack(true)
    } else {
      goBack()
    }
  }

  const goBack = () => {
    setShowConfirmBack(false)
    history.push("/companies")
  }

  const handleConfirm = async () => {
    try {
      setShowConfirmBack(false)
      handleSubmit(async data => {
        setIsLoading(true)
        await updateCompany(data)
        setIsLoading(false)
        goBack()
      })()
    } catch (e) {
      console.log("Error found in handleConfirm::", e);
    }
  }

  const handleClose = () => {
    setShowConfirmBack(false)
  }

  return (
    <div className="company-view">
      <Loader show={isLoading} />
      {showConfirmBack &&
        <ConfirmationPopup
          header={VALIDATION_MESSAGE.unsave_changes_message}
          onConfirm={handleConfirm}
          onCancel={goBack}
          onClose={handleClose}
          cancelText='Discard'
          confirmText='Save'
        />
      }
      {company && <>
        <Helmet>
          <title>{(company && company.name) || ''} - KG Galaxy</title>
        </Helmet>
        <div className="d-flex company-view-header align-items-center">
          <div className='cursor-pointer' onClick={handleBack}>
            <span className="header-back-icon">
              <ArrowBackIcon color="primary" />
            </span>
          </div>
          <div className="view-company-header">{company.name || ''} </div>
        </div>
        <React.Fragment>
          <Tabs
            value={value}
            indicatorColor="secondary"
            textColor="primary"
            onChange={handleChange}
            aria-label="disabled tabs example"
          >
            {/* eslint-disable-next-line react/display-name */}
            <Tab component={React.forwardRef((params, ref) => {
              return (
                <div ref={ref} className="details-section align-items-center" onClick={params.onClick}>
                  <span className="section-icon">
                    <DetailsIcon fontSize="inherit" />
                  </span>
                  <span>Details</span>
                </div>
              )
            })} />
            {/* eslint-disable-next-line react/display-name */}
            <Tab disabled component={React.forwardRef((params, ref) => {
              return (
                <div ref={ref} className="details-section align-items-center" onClick={params.onClick}>
                  <span className="section-icon">
                    <SearchIcon fontSize="inherit" />
                  </span>
                  <span>Searches</span>
                </div>
              )
            })} />
            {/* eslint-disable-next-line react/display-name */}
            <Tab component={React.forwardRef((params, ref) => {
              return (
                <div className="details-section align-items-center " ref={ref} onClick={params.onClick} >
                  <span className="section-icon">
                    <AttachFileIcon fontSize="inherit" />
                  </span>
                  <span>Notes & Attachments</span>
                </div>
              )
            })} />
            {/* eslint-disable-next-line react/display-name */}
            <Tab component={React.forwardRef((params, ref) => {
              return (
                <div ref={ref} className="details-section align-items-center" onClick={params.onClick}>
                  <span className="section-icon">
                    <PersonIcon fontSize="inherit" />
                  </span>
                  <span>Contacts</span>
                </div>
              )
            })} />
            {/* eslint-disable-next-line react/display-name */}
            <Tab component={React.forwardRef((params, ref) => {
              return (
                <div ref={ref} className="details-section align-items-center" onClick={params.onClick}>
                  <span className="section-icon">
                    <PersonIcon fontSize="inherit" />
                  </span>
                  <span>Board Members</span>
                </div>
              )
            })} />
          </Tabs>
          {company.off_limits &&
            <Typography className='off-limit-badge'>
              {company && company.name} is Off Limits. Review company details for more information.
            </Typography>
          }
          <div className="company-view-container">
            <TabPanel index={0} value={value}>
              <CompanyDetails
                key={company?.id}
                setProfileUrl={setProfileUrl}
                cancelProfileUrl={cancelProfileUrl}
                setNewProfilePic={setNewProfilePic}
                profileUrl={profileUrl}
                uploadProfilePic={uploadProfilePic}
                isEditing={isEditing}
                company={company}
                saveCompanyDetails={saveCompanyDetails}
                handleCancel={handleCancel}
                handleEdit={handleEdit}
                updateCompany={updateCompany}
                setCheckPic={setCheckPic}
                useForm={{ register, setValue, handleSubmit, errors, watch, reset, control, getValues }}
                isLastSaveSuccess={isLastSaveSuccess}
              />
            </TabPanel>
            <TabPanel index={1} value={value}>
              <Searches key={company?.id} companyId={company.id} activeSearches />
            </TabPanel>
            <TabPanel index={2} value={value}>
              <NotesAttachments company={company} />
            </TabPanel>
            <TabPanel index={3} value={value}>
              <ContactList key={company?.id} company={company} />
            </TabPanel>
            <TabPanel index={4} value={value}>
              <BoardMembers key={company?.id} company={company} />
            </TabPanel>
          </div>
        </React.Fragment>
      </>}
    </div>
  )
}

export default ViewCompany
