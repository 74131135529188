//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from "prop-types";
import { withSnackbar } from "notistack";
import ReactPhoneInput from 'react-phone-input-material-ui';
import { Button, Checkbox, FormControl, FormControlLabel, TextField, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from '@material-ui/icons//Add';
//----------------------------------------------// Internal Imports // -------------------------------------------------
import ContactSelection from "../../../../ContactSelection/ContactSelection";
import InputField from "../../../../common/InputField";
import { determineInitialCountryGuess } from "../../../../../utils/phone";

function ClientContacts(props) {
  const { register, currentValues, setValue, isEditing ,watch} = props
  const [hiringManagerIndex, setHiringManagerIndex] = useState(-1)
  const [clientContacts, setClientContacts] = useState([])
  /* const [open, toggleOpen] = useState(false)
  const [prevManagerIndex, setPrevManagerIndex] = useState(-1)
  const [clientSuiteInviteClient, setClientSuiteInviteClient] = useState({})
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isAthenaInviteOpen, toggleAthenaInviteOpen] = useState(false)
  const [selectedDropDown, setSelectedDropDown] = useState('') */

  useEffect(() => {
    setValue('client_team', clientContacts)
  }, [clientContacts, setValue])

  const setManagerIndex = (clientTeam) => {
    let hiringManagerIndex = -1;
    for (let index = 0; index < clientTeam.length; index++) {
      if (clientTeam[index].is_hiring_manager) {
        hiringManagerIndex = index;
        break;
      }
    }
    setHiringManagerIndex(hiringManagerIndex)
    // setPrevManagerIndex(hiringManagerIndex)
  }

  useEffect(() => {
    const clientTeam = currentValues.client_team
    if (Array.isArray(clientTeam)) {
      setManagerIndex(clientTeam)
      setClientContacts(clientTeam);
    }
    register('client_team');
  }, [currentValues, register])

  const handleContactChange = (data, index) => {
    let previousData = [...clientContacts]
    previousData[index] = { contact: data, ...data }
    setClientContacts(previousData);
  }

  const handleEmailChange = (data, index) => {
    let previousState = [...clientContacts]
    previousState[index] = { ...previousState[index], work_email: data }
    setClientContacts(previousState)
  }

  const handlePhoneChange = (data, index) => {
    let previousState = [...clientContacts]
    previousState[index] = { ...previousState[index], mobile_phone: data }
    setClientContacts(previousState)
  }

  const handleHiringManagerChange = (isChecked, index) => {
    let previousState = [...clientContacts]
    if (hiringManagerIndex !== -1) {
      previousState[hiringManagerIndex] = { ...previousState[hiringManagerIndex], is_hiring_manager: false }
    }
    previousState[index] = { ...previousState[index], is_hiring_manager: isChecked }
    setClientContacts(previousState)
    isChecked ? setHiringManagerIndex(index) : setHiringManagerIndex(-1);
  }

  const addClientContact = () => {
    setClientContacts(prevState => [...prevState, {}])
  }

  const removeClientContact = (index) => {
    setClientContacts(prevState => {
      const clientContacts = [...prevState]
      clientContacts.splice(index, 1)
      setManagerIndex(clientContacts)
      return clientContacts
    })
  }

  /* const handleClick = (event, client) => {
    setClientSuiteInviteClient(client);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openAthenaPopUp = (client, selectedDropDown) => {
    if (!client.contact?.work_email && !client.contact?.private_email) {
      enqueueSnackbar(VALIDATION_MESSAGE.athena_email, { variant: ERROR });
      return;
    }
    setAnchorEl(null);
    setSelectedDropDown(selectedDropDown);
    toggleAthenaInviteOpen(true);
  } */

  return (
    <div className='team-information-table flex-grow-1 p-0 m-0'>
      <Typography className="w-100 m-0" style={{ background: '#2a364c', color: '#fff', border: "6px solid #2a364c" }}>
        <div className="d-flex align-items-center table-header" style={{ color: "#fff" }}>
        Client Contact Details
        </div>
      </Typography>
      <div className="client-info-table d-flex flex-column" id='client-contacts'>
        <div className="d-flex align-items-center table-main-header">
          <div className="col-0-5 text-center">&nbsp;</div>
          <div className="col-3 text-center">Name</div>
          <div className="col-3 text-center">Email</div>
          <div className="col-3 text-center">Phone</div>
          <div className="col-2 text-center">Hiring Manager</div>
          {/* Invite should be display in view screen only
          <div className="col-2 text-center">Athena Invite</div>
          <div className="col-2 text-center">ClientSuite Invite</div> */}
          <div className="close-action text-center col-0-5"> &nbsp;</div>
        </div>
        {clientContacts.map((item, index) => (
          <div className='d-flex align-items-baseline' key={index}>
            <div className='d-flex flex-grow-1' >
              <div className='d-flex flex-column col-12'>
                <div className={`table-content p-0`} >
                  <div className="d-flex align-items-center">
                    <div className="col-0-5 text-center">
                      <span style={{fontSize: "14px"}}>{index + 1}.</span>
                    </div>
                    <div className="col-3">
                      <ContactSelection
                        defaultValue={item.contact || null}
                        disabled={!isEditing}
                        // className='team-info-field name-field'
                        list = {watch("client_team") || []}
                        label={``}
                        InputLabelProps={{ focused: true }}
                        onChange={(e, data) => {
                          handleContactChange(data, index)
                        }}
                        variant="outlined"
                        client_Contact={true}
                      />
                    </div>
                    <div className="col-3">
                      <InputField
                        label=''
                        placeholder='Email'
                        disabled={!isEditing}
                        value={item.work_email || ''}
                        type='email'
                        onChange={(e) => { handleEmailChange(e.target.value, index) }}
                        InputLabelProps={{ focused: true }}
                        className='MuiFormControl-fullWidth'
                        variant="outlined"
                      />
                    </div>
                    <div className="col-3">
                      <ReactPhoneInput
                        variant="outlined"
                        placeholder='Phone Number'
                        disabled={!isEditing}
                        value={item.mobile_phone || ''}
                        onChange={(data, c, e, value) => {
                          if (data.length > 1) {
                            handlePhoneChange(value, index)
                          }
                          else {
                            handlePhoneChange('', index)
                          }
                        }}
                        enableSearch={true}
                        disableInitialCountryGuess={determineInitialCountryGuess(item.mobile_phone)}
                        inputProps={{
                          label: "",
                          variant: "outlined"
                        }}
                        component={TextField}
                      />
                    </div>
                    <div className="col-2">
                      <FormControl className='w-auto' style={{ margin: '11px 0px', marginLeft: '20px' }}>
                        <FormControlLabel
                          disabled={!isEditing}
                          control={
                            <Checkbox
                              size='small'
                              checked={hiringManagerIndex === index}
                              onChange={(e) => {
                                handleHiringManagerChange(e.target.checked, index)
                              }}
                            />
                          }
                          label=""
                          style={{ color: "black" }}
                        />
                      </FormControl>
                    </div>
                    {/* 
                    <div className="col-2">
                      {item.contact ? <>
                        <Button variant='outlined'  color='primary' style={{ textTransform: 'none', margin: '11px 60px 11px 0px' }} size="small" aria-controls="simple-menu" aria-haspopup="true" onClick={(event) => handleClick(event, item)}>Athena Invite <ArrowDropDownIcon /></Button>
                        <Menu
                          id="simple-menu"
                          anchorEl={anchorEl}
                          keepMounted
                          open={Boolean(anchorEl)}
                          onClose={handleClose}
                        >
                          <MenuItem onClick={() => { openAthenaPopUp(clientSuiteInviteClient, 'Athena') }}>Invite to Athena only</MenuItem>
                          <MenuItem onClick={() => { openAthenaPopUp(clientSuiteInviteClient, 'Redo Attribute Selector') }}>Invite to Attribute Selector only</MenuItem>
                          <MenuItem onClick={() => { openAthenaPopUp(clientSuiteInviteClient, 'Athena,Redo Attribute Selector') }}>Invite to Athena & Attribute Selector</MenuItem>
                        </Menu>
                      </> : <span>&nbsp;</span>}
                    </div>
                    <div className="col-2 d-flex">
                      {item.contact ?
                        <Button variant='outlined' className='w-auto align-item-center' size="small" color='primary' style={{ textTransform: 'none', margin: '11px 0' }} onClick={() => { toggleOpen(true); setClientSuiteInviteClient(item) }}>
                          {`${currentValues.type === 'Inside Track' ? 'Arena' : 'ClientSuite'} Invite`}
                        </Button> : <span>&nbsp;</span>}
                    </div> */}
                    {/* <div className="col-1">
                      <FormControl className='w-auto' style={{ margin: '11px 0' , marginLeft: '32px' }}>
                        <FormControlLabel
                          disabled={!isEditing}
                          control={
                            <Checkbox
                              size='small'
                              checked={hiringManagerIndex === index}
                              onChange={(e) => {
                                handleHiringManagerChange(e.target.checked, index)
                              }}
                            />
                          }
                          label=""
                          style={{ color: "black" }}
                        />
                      </FormControl>
                    </div> */}
                    {isEditing &&
                      <div className="col-0-5 text-center" style={{ margin: '11px 0' }} >
                        <CloseIcon variant="large" style={{ color: "red" }} cursor='pointer' fontSize='medium' onClick={() => { removeClientContact(index) }} />
                      </div>
                    }
                  </div>
                </div>
              </div>

            </div>
          </div>
        ))}
        {/* {open ? <ClientSuiteInvitePopUp client={clientSuiteInviteClient} type={currentValues?.type} onClose={() => toggleOpen(false)} /> : null}
        {isAthenaInviteOpen ? <AthenaInvitePopUp inviteFor={'client'} selectedDropDown={selectedDropDown} client={clientSuiteInviteClient} onClose={() => toggleAthenaInviteOpen(false)} /> : null} */}
        {isEditing &&
          <div className='text-start ml-3 margin-button'> <Button
            variant={"text"}
            startIcon={<AddIcon style={{ color: "red" }} />}
            onClick={addClientContact}
            className="button-text-capitalized"
            style={{ width: "175px" }}
          >
            <span style={{
              fontWeight: "600",
              fontSize: "13px",
              fontStyle: "italic",
             
            }}>Add New Client</span>
          </Button>
          </div>
        }
      </div>
    </div>
  )
}

ClientContacts.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  watch: PropTypes.func,
  control: PropTypes.object,
  currentValues: PropTypes.object,
  isEditing: PropTypes.bool,
  isFromViewSearch: PropTypes.bool,
  enqueueSnackbar: PropTypes.func
}

export default withSnackbar(ClientContacts);