//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useState } from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import { useSnackbar } from "notistack";
import * as yup from "yup";

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { cleanupWorkbenchData } from "../utils";
import WorkbenchAddScreen from "../../AddWorkbench/Components/WorkbenchAddScreen";
import { POST, SUCCESS, ERROR } from "../../../services/constantService";
import { workbenchDataApi } from "../../../services/ApiService";
import { unableMessage, successMessage } from "../../../services/MessageService";
import { customFormValidator, requireValidMessage } from "../../../utils/common";

export default function DuplicateWorkbench(props) {
  const { onClose, workbench } = props

  const { register, handleSubmit, watch, setValue, control } = useForm({
    defaultValues: { ...workbench, name: `${workbench.name} Duplicate` }
  })

  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar()

  const WorkbenchEditSchema = yup.object().shape({
    name: yup.string().required(),
    type: yup.string().required(),
    users: yup.array().of(yup.string()).required(),
  });

  const handleCreate = async (formData) => {
    const isValid = await WorkbenchEditSchema.isValid(formData)
    if (!isValid) {
      let requiredField = [
        { fieldName: "name", label: "Workbench Name", type: String },
        { fieldName: "type", label: "Type", type: String },
        { fieldName: "users", label: "Users", type: [] }
      ]
      let dirtyField = customFormValidator(formData, requiredField)
      if (dirtyField) {
        const message = requireValidMessage(dirtyField)
        enqueueSnackbar(message, { variant: ERROR })
        return
      }
    }
    setLoading(true)
    formData = cleanupWorkbenchData(formData)
    const sub_route = 'copy'
    const { status, data } = await workbenchDataApi(POST, workbench.id, formData, sub_route);
    if (status === 200) {
      const message = successMessage("Duplicate workbench", "created")
      enqueueSnackbar(message, { variant: SUCCESS })
      onClose()
    } else {
      const message = unableMessage("workbench", "duplicate")
      enqueueSnackbar(data?.message || message, { variant: ERROR })
    }
    setLoading(false)
  }

  return (
    <WorkbenchAddScreen
      title='Duplicate Workbench'
      handleSubmit={handleSubmit}
      onSubmit={handleCreate}
      onClose={onClose}
      watch={watch}
      users={workbench.tagged_users}
      setValue={setValue}
      register={register}
      control={control}
      buttonText={"Create Duplicate Workbench"}
      open={true}
      loading={loading}
      DefaultValue={workbench.type}
      Disabled={true}
    />
  )
}

DuplicateWorkbench.propTypes = {
  onClose: PropTypes.func,
  workbench: PropTypes.object
};
