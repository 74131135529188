//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from "prop-types";
import { TextField } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { withSnackbar } from "notistack";

//----------------------------------------------// Internal Imports // -------------------------------------------------
import { GET } from "../../../services/constantService";
import { userDataApi } from "../../../services/ApiService";
import { ERROR } from "jest-validate/build/utils";
import { unableMessage } from "../../../services/MessageService";

function Reference(props) {
  const {
    className = 'w-100',
    label,
    required = false,
    InputLabelProps,
    enqueueSnackbar,
    placeholder="Referred By",
    ...rest
  } = props
  const [options, setOptions] = useState([])


  useEffect(() => {
    const fetchUsers = async () => {
      const { status, data } = await userDataApi(GET);
      if (status === 200) {
        setOptions(data.data)
      }
      else {
        const message = unableMessage("users", "fetch")
        enqueueSnackbar(message, { variant: ERROR });
      }
    }

    fetchUsers().then(null)
  }, [enqueueSnackbar])

  return (
    <Autocomplete
      {...rest}
      className={className}
      options={options}
      getOptionLabel={option => `${option.first_name || ''} ${option.last_name || ''}`}
      getOptionSelected={(option, value) => option.id === value.id}
      renderInput={(params) => (
        <TextField
          required={required}
          {...params}
          InputLabelProps={InputLabelProps}
          label={label}
          placeholder={placeholder}
        />
      )}
    />
  )
}

Reference.propTypes = {
  register: PropTypes.func,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  InputLabelProps: PropTypes.object,
  enqueueSnackbar: PropTypes.func,
  required: PropTypes.bool
}

export default withSnackbar(Reference);

