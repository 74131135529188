//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useState, useEffect, useCallback } from 'react'

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types'
import { Button, FormControlLabel, Typography, Checkbox } from '@material-ui/core'
import { makeStyles } from "@material-ui/core/styles";
import PrintIcon from '@material-ui/icons/Print';
import { useParams } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import Grid from "@material-ui/core/Grid";
import { useForm } from 'react-hook-form';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { useDispatch, useSelector } from 'react-redux';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import Loader from '../../../common/Loader';
import PositionOverview from './Components/PositionOverview';
import { getDefaultValues, getUpdateSFPAPayload, initialSFPA } from './utils';
import CompanyDetails from './Components/CompanyOverview/CompanyDetails'
import Header from '../Header'
import './index.scss';
import ProfessionalExperience from './Components/ProfessionalExperience';
import ProductsServicesOverview from './Components/ProductsServicesOverview';
import EducationLicensesandCertifications from './Components/EducationLicensesandCertifications/EducationLicensesandCertifications'
import ReportOptions from '../../../common/ReportOptions';
import ActionsPopover from '../../../../Containers/Contacts/ActionsPopover';
import { searchDataApi } from '../../../../services/ApiService';
import { API, GET, PUT, ERROR, SUCCESS, WARNING, HELIA } from '../../../../services/constantService';
import { unableMessage, successMessage, notFoundMessage, VALIDATION_MESSAGE } from '../../../../services/MessageService';
import { localTimeZoneHandler } from '../../../../Containers/Commons/Utils';
import { UPDATE_SFPADATA, UPDATE_TIMEZONE_DATA } from '../../../../types';
import { searchActions } from '../../../../store/searchSlice';
const useStyles = makeStyles(() => ({
  label: {
    fontSize: 14,
    color: '#686A7E'
  },
  root: {
    paddingLeft: 42,
    paddingTop: 10
  }
}))
const PositionProfile = (props) => {
  const { search = {}, jobTitle, confidential, getSearch, enqueueSnackbar, closeSnackbar, setSFPADirty, setUpdateSFPA } = props;
  const { id } = useParams();
  const classes = useStyles()
  const [currentValues, setCurrentValues] = useState({})
  const [isApprovedByPartner, setApprovedByPartner] = useState(false)
  const { register, setValue, getValues, reset, watch, formState } = useForm()
  const { dirty } = formState;
  const [sfpa, setSFPA] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [isReportsOpen, setIsReportsOpen] = useState(false);
  const [reportsParams, setReportsParams] = useState({});
  // const [value, setTabValue] = useState(0);
  const [timeZoneOptions, setTimeZoneOptions] = useState([]);
  const [defaultLocalTime, setDefaultLocalTime] = useState({})
  const dispatch = useDispatch()
  const timezone = useSelector(state => state.rootReducer.timezone)
  const sfpaData = useSelector(state => state.rootReducer.sfpaData)
  useEffect(() => {
    const defaultValues = getDefaultValues(sfpa);
    setCurrentValues(defaultValues)
    reset(defaultValues)
  }, [sfpa, setCurrentValues, reset])

  useEffect(() => {
    if (!sfpaData) {
      getData()
    }
    else {
      if (sfpaData.skills && sfpaData.skills.length === 0) {
        sfpaData.skills = [{}]
      }
      if (sfpaData.off_limits === null) {
        sfpaData.off_limits = []
      }
      if (sfpaData.target_industries === null) {
        sfpaData.target_industries = []
      }
      if (sfpaData.target_locations === null) {
        sfpaData.target_locations = []
      }
      if (sfpaData.target_companies === null) {
        sfpaData.target_companies = []
      }
      setSFPA(sfpaData);
      setApprovedByPartner(sfpaData.is_approved_by_partner)
    }
  }, [search, sfpaData])

  useEffect(() => {
    const fetchData = async () => {
      try{
        if (!timezone) {
          const data = await localTimeZoneHandler(timeZoneOptions)
          dispatch({ type: UPDATE_TIMEZONE_DATA, payload: { key: 'timezone', data } })
          setTimeZoneOptions(data.timeZoneOptions);
          setDefaultLocalTime(data.defaultLocalTime);
        }
        else {
          setTimeZoneOptions(timezone.timeZoneOptions);
          setDefaultLocalTime(timezone.defaultLocalTime);
        }
      }catch(e){
        console.log("Error found in handleConfirmPopup::", e);
      }
    }
    if (!timeZoneOptions.length) {
      fetchData()
    }
  },   [timeZoneOptions, timezone, dispatch])
  
  const setSFPAUpdated = async (showLoader = true) => {
    try{
      await getData(showLoader)
    }catch(e){
      console.log("Error found in setSFPAUpdated::", e);
    }
  }

  // const handleChange = async (event, newValue) => {
  //   reset({ ...currentValues, ...getValues({ nest: true }) })
  //   setCurrentValues(prevState => ({ ...prevState, ...getValues({ nest: true }) }))
  //   setTabValue(newValue);
  //   if (dirty) {
  //     await updateSFPA()
  //   }
  // };

  const getData = useCallback(async (showLoader = true) => {
    setLoading(showLoader);
    const { status, data } = await searchDataApi(GET, id, {}, 'sfpa');
    if (status && status === 200) {
      if (data) {
        if (data.skills && data.skills.length === 0) {
          data.skills = [{}]
        }
        if (data.off_limits === null) {
          data.off_limits = []
        }
        if (data.target_industries === null) {
          data.target_industries = []
        }
        if (data.target_locations === null) {
          data.target_locations = []
        }
        if (data.target_companies === null) {
          data.target_companies = []
        }
        dispatch({ type: UPDATE_SFPADATA, payload: { key: 'sfpaData', data } })
        setSFPA(data);
        setApprovedByPartner(data.is_approved_by_partner)
      }
      else {
        setSFPA(initialSFPA)
      }
    } else {
      const message = unableMessage("Search Roadmap", "fetch")
      enqueueSnackbar(data?.message || message, { variant: ERROR });
    }
    setLoading(false);
  }, [id, enqueueSnackbar, dispatch])

  const setSfpaData = (field) => {
    let newSfpaData = { ...sfpaData, ...field }
    dispatch({ type: UPDATE_SFPADATA, payload: { key: 'sfpaData', data: newSfpaData } })
    // It impacts on update latest search info tab data 
    dispatch(searchActions.resetSearchState());
  }

  // useEffect(() => {
  //   getData().then(null)
  // }, [getData])

  // const goToTab = (tabNumber) => {
  //   if (tabNumber < 0 || tabNumber > 7) {
  //     return;
  //   }
  //   handleChange(null, tabNumber)
  // }

  const printSFPA = () => {
    setIsReportsOpen(true);
    setReportsParams({
      params: {
        "searchId": id,
      },
      options: {
        pageSize: true,
        locale: false,
      },
      url: `${API.reports}/sfpa-report`
    })
  }

  const updateSFPA = useCallback(async (dirty) => {
    try{
      if (dirty) {
        const formValues = Object.assign(currentValues, getValues({ nest: true }))
        let payload = getUpdateSFPAPayload(formValues);
        // delete payload.company[0].description
        // delete payload.company[0].news
        payload.updated_at = sfpa.updated_at
        payload = { company: payload.company, is_approved_by_partner: payload.is_approved_by_partner }
        setLoading(true);
        const { status, data } = await searchDataApi(PUT, id, payload, 'sfpa');
        if (status && status === 200) {
          const message = successMessage("Search Roadmap", VALIDATION_MESSAGE.updated_message)
          enqueueSnackbar(data?.message || message, { variant: SUCCESS });
          await getData();
          // It impacts on update latest search info tab data 
          dispatch(searchActions.resetSearchState());
        } else if (status === 409) {
          const message = unableMessage("activity info", "save")
          enqueueSnackbar(data?.message || message,
            {
              persist: true,
              variant: ERROR,
              // eslint-disable-next-line react/display-name
              action: key => (
                <Button
                  size='small'
                  variant='text'
                  color='inherit'
                  onClick={() => { closeSnackbar(key) }}
                >
                  Dismiss
                </Button>
              )
            }
          );
        } else {
          const message = unableMessage("Search Roadmap", "update")
          enqueueSnackbar(data?.message || message, { variant: ERROR });
        }
        setLoading(false);
      }
      else {
        const message = notFoundMessage('Changes')
        enqueueSnackbar(message, { variant: WARNING });
      }
    }catch(e){
      console.log("Error found in updateSFPA::", e);
    }

  }, [currentValues, sfpa, id, getValues, enqueueSnackbar, closeSnackbar, getData])

  useEffect(() => {
    setSFPADirty(dirty)
  }, [dirty, setSFPADirty])

  useEffect(() => {
    setUpdateSFPA(updateSFPA)
  }, [setUpdateSFPA, updateSFPA])

  const removeHtmlTags = (str) => {
    if ((str === null) || (str === ''))
      return false;
    else
      str = str.toString();
    return str.replace(/<[^>]*>/g, '');
  }


  return (
    <div className="position-relative h-100" style={{ display: 'flex', width: '98%', flexDirection: 'column', height: '100%' }}>
      <Loader show={isLoading} />
      {isReportsOpen && <ReportOptions timeZoneOptions={timeZoneOptions} defaultLocalTime={defaultLocalTime} onClose={() => { setIsReportsOpen(false); setReportsParams({}) }} {...reportsParams} />}
      {sfpa && <>
        <Header
          companyDetails={search.company}
          company={search.company && search.company.name}
          jobNumber={search.job_number}
          jobTitle={jobTitle}
          confidential={confidential}
        >
          <div className="d-flex">
            <div className="action-container d-flex align-items-center" onClick={printSFPA}>
              <span className="action-icon">
                <PrintIcon fontSize="inherit" />
              </span>
              <span className="action-text">Print</span>
            </div>
            <ActionsPopover
              className="action-container d-flex align-items-center"
              label={
                <div className="action-container d-flex align-items-center">
                  <span className="action-icon">
                    <InsertDriveFileIcon fontSize="inherit" />
                  </span>
                  <span className="action-text">Search Roadmap Reports</span>
                </div>
              }
              list={[{
                label: 'Athena Comparison Report Candidates',
                onClick: () => {
                  setIsReportsOpen(true);
                  setReportsParams({
                    params: {
                      "searchId": id,
                    },
                    url: `${API.reports}/athena-benchmark-comparison-multiple-candidates`
                  })
                }
              }, {
                label: 'Attribute Selector Comparison Report',
                onClick: () => {
                  setIsReportsOpen(true);
                  setReportsParams({
                    params: {
                      "searchId": id,
                    },
                    options: {
                      pageSize: true,
                      locale: false,
                    },
                    url: `${API.reports}/attribute-selector-results-comparison`
                  })
                }
              }, {
                label: 'Athena Executive Summary Report',
                onClick: () => {
                  setIsReportsOpen(true);
                  setReportsParams({
                    params: {
                      "searchId": id,
                    },
                    url: `${API.reports}/athena-executive-summary`
                  })
                }
              }, {
                label: 'Athena Summary Report Package',
                onClick: () => {
                  setIsReportsOpen(true);
                  setReportsParams({
                    params: {
                      "searchId": id,
                    },
                    url: `${API.reports}/athena-summary-package`
                  })
                }
              },
              search?.helia_version !== HELIA.VERSION_2 && {
                label: 'Search Roadmap Report (Legacy) ',
                onClick: () => {
                  setIsReportsOpen(true);
                  setReportsParams({
                    params: {
                      "searchId": id,
                      timeZone: true
                    },
                    url: `${API.reports}/sfpa-report`
                  })
                }
              },
              search?.helia_version === HELIA.VERSION_2 && {
                label: 'Search Roadmap',
                onClick: () => {
                  setIsReportsOpen(true);
                  setReportsParams({
                    params: {
                      "searchId": id,
                      "athenaVersion": search?.helia_version,
                      "oneLanguageSupport": false
                    },
                    url: `${API.reports}/success-profile-agreement-report`
                  })
                }
              },
              {
                label: 'Position Profile',
                onClick: () => {
                  setIsReportsOpen(true);
                  setReportsParams({
                    params: {
                      "searchId": id,
                    },
                    url: `${API.reports}/position-profile-report`
                  })
                }
              }
              ]}
            />
            <Button color='primary' className='button' variant='contained' onClick={() => updateSFPA(dirty)}>Save</Button>
          </div>
        </Header>
        <div className="section-container w-100">

          <FormControlLabel
            classes={classes}
            className='checkbox-container'
            control={
              <Checkbox
                className='checkbox-component'
                checked={isApprovedByPartner}
                size='small'
                style={{ padding: '0 5px' }}
                name="is_approved_by_partner"
                inputRef={register}
                onChange={(e) => setApprovedByPartner(e.target.checked)}
              />
            }
            label={
              <Typography
                style={{ color: watch('is_approved_by_partner') ? '#686A7E' : 'red', fontSize: '14px' }}>
                Approved By Partner to Publish 
              </Typography>
            }
          />

        </div>
        <div className="position-relative h-100">
          <div style={{ padding: 0, flex: 1, height: 'calc(100% - 70px)' }}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <CompanyDetails sfpa={sfpa} removeHtmlTags={removeHtmlTags} updateSFPA={updateSFPA} register={register} watch={watch} getSearch={getSearch} setValue={setValue} currentValues={currentValues} setCurrentValues={setCurrentValues} enqueueSnackbar={enqueueSnackbar} setSFPAUpdated={setSFPAUpdated} />
                <EducationLicensesandCertifications register={register} watch={watch} setValue={setValue} currentValues={currentValues} />
              </Grid>
              <Grid item xs={6} >
                <PositionOverview setSfpaData={setSfpaData} searchInfo={search} sfpa={sfpa} removeHtmlTags={removeHtmlTags} getSearch={getSearch} currentValues={currentValues} register={register} setValue={setValue} />
                <ProfessionalExperience setSfpaData={setSfpaData} searchInfo={search} sfpa={sfpa} removeHtmlTags={removeHtmlTags} getSearch={getSearch} currentValues={currentValues} register={register} setValue={setValue} />
                <ProductsServicesOverview setSfpaData={setSfpaData} searchInfo={search} sfpa={sfpa} removeHtmlTags={removeHtmlTags} getSearch={getSearch} currentValues={currentValues} register={register} setValue={setValue} />
              </Grid>
            </Grid>
          </div>
        </div>
      </>}
    </div>
  )
}

PositionProfile.propTypes = {
  id: PropTypes.string,
  search: PropTypes.object,
  jobTitle: PropTypes.string,
  confidential: PropTypes.bool,
  enqueueSnackbar: PropTypes.func,
  closeSnackbar: PropTypes.func,
  setSFPADirty: PropTypes.func,
  setUpdateSFPA: PropTypes.func,
  removeHtmlTags: PropTypes.func,
  getSearch: PropTypes.func
}

export default withSnackbar(PositionProfile);
