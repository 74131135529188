
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useState, useEffect, useRef } from 'react'

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from 'prop-types'
import axios from "axios";
import { makeStyles } from '@material-ui/core/styles'
import SearchIcon from '@material-ui/icons/Search'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { CircularProgress, TextField, Typography } from '@material-ui/core'

//-----------------------------------------------------------// Internal Imports // ------------------------------

import { SEARCH_TIMEOUT } from "../../utils/common";
import { API } from '../../services/constantService'
import { getMethodWithCancelTokenApi } from '../../services/ApiService'

const CancelToken = axios.CancelToken;

const useStyles = makeStyles(() => ({
  root: {
    top: 10,
    width: 320,
    height: 40,
    fontSize: 13,
    border: '1px solid #DDDDE2'
  },
  inputRoot: {
    height: 37,
    paddingLeft: 10,
    fontSize: 19,
  },
  input: {
    fontSize: 14,
    padding: 10
  },
  endAdornment: {
    paddingRight: 10
  }
}))

export default function CompanySearch(props) {
  const { placeholder = 'Search', type, ...rest } = props
  const [options, setOptions] = useState([])
  const [value, setValue] = useState('');
  const [isLoading, setLoading] = useState(false);
  const classes = useStyles()
  let call = useRef();

  useEffect(() => {
    if (call.current) {
      call.current.cancel();
    }
    if (value.length < 3) {
      setOptions([])
      setLoading(false)
      return;
    }
    call.current = CancelToken.source();
    setLoading(true)
    const timer = setTimeout(() => {
      getMethodWithCancelTokenApi(`${API.picklists_companies}`, { name: value }, {}, {}, call.current.token)
        .then(response => {
          const { status, data } = response;
          if (status === 200) {
            setOptions(data.data);
          }
          setLoading(false)
        })
    }, SEARCH_TIMEOUT)
    return () => clearTimeout(timer);
  }, [value])

  return (
    <div style={{ display: 'flex' }}>
      <Autocomplete
        {...rest}
        loading={isLoading}
        classes={classes}
        freeSolo
        filterOptions={(options) => options}
        options={options}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option
          }
          return option.name
        }}
        renderOption={(option) => {
          if (type === "quicksearches") {
            const companyName = option.name || "";
            const countryName = option.country || "";
            return (
              <div>
                <Typography>{companyName} {option.country ? <span style ={{ color: '#999' }}> - {countryName}</span> : ''}</Typography>
              </div>
            );
          } else {
            return (
              <div>
                <Typography>{option.name}</Typography>
              </div>
            );
          }
        }}
        onInputChange={(e, val) => setValue(val)}
        value={props.value ? props.value : ''}
        renderInput={(params) => (
          <TextField
            {...params}
            className="company-search"
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
              disableUnderline: true,
              endAdornment: (
                isLoading ? <CircularProgress color='primary' size={20} /> : params.InputProps.endAdornment
              ),
            }}
          />
        )}
      />
      <span onClick={(event) => { props.onChange(event, value) }} className={"search-icon"}><SearchIcon /></span>
    </div>
  )
}

CompanySearch.propTypes = {
  value: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func
}
