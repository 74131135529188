import { useEffect, useRef, useState } from "react";

export const useCustomScrollToPosition = (props) => {
  const { position = 'bottom' } = props;
  const [scroll, setScroll] = useState(false);
  const myEleRef = useRef();
  const scrollToBottom = () => {
    /* Will Improve logic to handle multiple type of scroll position */
    console.log('useCustomScrollToPosition', position);
    myEleRef.current && myEleRef.current.scrollTo({
      top: myEleRef.current.scrollHeight,
      behavior: 'smooth',
    });
    setScroll(false);
  };
  useEffect(() => {
    scrollToBottom();
  }, [scroll])

  return { myEleRef, setScroll }
}