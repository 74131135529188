//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useRef } from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";

//----------------------------------------------// Internal Imports // -------------------------------------------------
import UserSelection from "../../UserSelection";
// import { isEmpty } from "../../../utils/common";

export default function UsersSelection(props) {
  const { control, users } = props
  const usersRef = useRef()

  return (
    <Controller
      name='users'
      as={
        <UserSelection
          option={[]}
          multiple
          openOnFocus
          size='small'
          label={<label>Users<span className="MuiInputLabel-asterisk"> *</span></label>}
          className='input'
          // required={true}
          inputRef={usersRef}
          filterSelectedOptions={true}
          InputLabelProps={{ focused: true }}
        />
      }
      defaultValue={users}
      control={control}
      onFocus={() => {
        usersRef.current.focus()
      }}
      onChange={([, data]) => data}
      // rules={{ required: false, validate: d => !isEmpty(d) }}
    />
  )
}

UsersSelection.propTypes = {
  control: PropTypes.object,
  users: PropTypes.array
};
