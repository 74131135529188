//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useCallback, useEffect, useState } from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { useSnackbar } from "notistack";

//----------------------------------------------// Internal Imports // -------------------------------------------------
import TextPopup from "../../../TextPopup";
import { API, GET, UPDATED_AT, ARENA, PUT, ERROR, SUCCESS } from "../../../../services/constantService";
import { formatDate } from "../../../../utils/date";
import { clientFeedbackDataApi } from "../../../../services/ApiService";
import { unableMessage, successMessage } from "../../../../services/MessageService";
import { useDispatch, useSelector } from "react-redux";
import { UPDATE_ARENA_FEEDBACK, UPDATE_TRACK_COMMENTS } from "../../../../types";
import { forwardRef } from "react";
import { useImperativeHandle } from "react";
import { useForm } from "react-hook-form";
import TabLayoutFooter from "../../../TabLayoutFooter";

// eslint-disable-next-line react/display-name
const ArenaFeedback = forwardRef((props, ref) => {
  const { candidateId, setLoading, readOnly, handleBack } = props
  const [arenaFeedback, setArenaFeedback] = useState([])
  const [trackComments, setTrackComments] = useState([])
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const reduxTrackComments = useSelector(state => state.rootReducer.reduxTrackComments)
  const reduxArenaFeedback = useSelector(state => state.rootReducer.reduxArenaFeedback)
  const { getValues } = useForm({});
  const actions = [
    { label: 'Close', className: 'primary-btn mr-3', onClick: () => { handleBack(true) } },
  ]

  useImperativeHandle(ref, () => ({
    saveTabData: () => {
      return true
    },
    isDirty: () => {
      return false
    },
    formData: () => {
      return getValues({ nest: true })
    },
    reload: () => {
      fetchData();
    }
  }));

  const fetchData = useCallback(async () => {
    setLoading(true)
    {
      if (!reduxTrackComments) {
        const sub_route = `track-comments?sortBy=${UPDATED_AT}`
        const { status, data } = await clientFeedbackDataApi(GET, candidateId, {}, sub_route)
        if (status === 200 && Array.isArray(data)) {
          dispatch({ type: UPDATE_TRACK_COMMENTS, payload: { key: 'reduxTrackComments', data } })
          setTrackComments(data)
        } else {
          const message = unableMessage("track comments", "fetch")
          enqueueSnackbar(data.message || message, { variant: ERROR })

        }
      }
      else {
        setTrackComments(reduxTrackComments)
      }
    }
    if (!reduxArenaFeedback) {
      const sub_route = `client-feedback?type=${ARENA}`
      const { status, data } = await clientFeedbackDataApi(GET, candidateId, {}, sub_route)
      if (status === 200 && Array.isArray(data)) {
        dispatch({ type: UPDATE_ARENA_FEEDBACK, payload: { key: 'reduxArenaFeedback', data } })
        setArenaFeedback(data)
      } else {
        const message = unableMessage("arena feedback", "fetch")
        enqueueSnackbar(data.message || message, { variant: ERROR })

      }
    }
    else {
      setArenaFeedback(reduxArenaFeedback)
    }
    setLoading(false)
  }, [candidateId, setLoading, enqueueSnackbar])

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const renderField = (label, value) => {
    return (
      <div className="d-flex align-items-center mb-1">
        <Typography color="primary">{label}</Typography>
        <span className='ml-2 mr-4'>{value}</span>
      </div>
    )
  }

  const renderTextField = (label, value, onSave) => {
    return (
      <div className="mb-2">
        <Typography color="primary" style={{ fontSize: 14 }}>{label}</Typography>
        <TextPopup title={label} onSave={onSave} className='w-100' value={value} />
      </div>
    )
  }

  const updateComment = async (comments, url) => {
    try {
      setLoading(true)
      const sub_route = url.slice(21);
      const { status, data } = await clientFeedbackDataApi(PUT, '', { comments }, sub_route)
      if (status === 200) {
        const message = successMessage('Comments', 'updated')
        enqueueSnackbar(message, { variant: SUCCESS })
        await fetchData()
      } else {
        const message = unableMessage("comments", "update")
        enqueueSnackbar(data.message || message, { variant: ERROR })
      }
      setLoading(false)
    } catch (e) {
      console.log("Error found in updateComment::", e);
    }
  }


  const renderClientFeedback = (title, feedback, index, isTrackComment) => {
    return <div key={index} className='feedback'>
      <h6 className="mb-3">{title}</h6>
      <div>
        <div className='mb-2 d-flex justify-content-between'>
          {renderField('Client Name:', feedback.client_name)}
          {renderField('Date:', formatDate(feedback.created_at, 'DD/MM/YYYY'))}
        </div>
        {isTrackComment ? renderField('Track:', feedback.track) : null}
        {
          renderTextField('Comments', feedback.comments, (data) => {
            updateComment(data, `${API.client_feedback}/${isTrackComment ? 'track-comments/' + trackComments[index].id : arenaFeedback[index].id}`)
          })
        }
      </div>
    </div>
  }

  return (
    <div>
      <div className='client-suite-feedback'>
        <Typography className='heading'>Arena Feedback Log</Typography>
        <div className="d-flex flex-row">
          <div className="p-2">
            {
              trackComments.map((item, index) => renderClientFeedback('Track Comments', item, index, true))
            }
          </div>
          <div className="p-2">
            {
              arenaFeedback.map((item, index) => renderClientFeedback('General Comments', item, index, false))
            }
          </div>
        </div>
      </div>
      {!readOnly && <TabLayoutFooter actions={actions} />}
    </div>
  )
})

ArenaFeedback.propTypes = {
  candidateId: PropTypes.string,
  setLoading: PropTypes.func,
  readOnly: PropTypes.bool,
  handleBack: PropTypes.func,
}

export default ArenaFeedback;