
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from "react";

//-----------------------------------------------------------// External Imports // ------------------------------

import { Typography } from "@material-ui/core";

export default function Fallback() {
  return (
    <div className="error-container">
      <Typography className="error-title">
        Aaaah! Something went wrong
      </Typography>
      <Typography style={{ fontSize: 18 }}>
        Brace yourself till we get the error fixed.
      </Typography>
      <Typography style={{ fontSize: 18 }}>
        You may also refresh the page or try again later.
      </Typography>
    </div>
  );
}
