
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React from "react";

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

//-----------------------------------------------------------// Internal Imports // ------------------------------

import PopoverStickOnHover from "../../../../components/common/PopoverStickOnHover";
import { formatDate } from "../../../../utils/date";
import "./AthenaPopover.scss";

const AthenaPopover = (props) => {
  const { contactDetails, children, handleInvite } = props;
  const getAthenaSentUser = () => {
    const firstName = contactDetails?.athena_sent_user?.first_name
      ? contactDetails.athena_sent_user.first_name
      : "";
    const lastName = contactDetails?.athena_sent_user?.last_name
      ? contactDetails.athena_sent_user.last_name
      : "";
    return firstName || lastName ? firstName + " " + lastName : "--";
  };

  const renderPopoverContent = () => {
    return (
      <div className="athena-popover">
        <Typography variant="h5" className="title">
          Athena Invite Details
        </Typography>
        <div className="content d-flex flex-column">
          <div className="field">
            <Typography className="label">Invite Sent on</Typography>
            <Typography className="value">
              {formatDate(contactDetails?.athena_invitation_sent_on) || "N.A"}
            </Typography>
          </div>
          <div className="field">
            <Typography className="label">Invite Sent By</Typography>
            <Typography className="value">{getAthenaSentUser()}</Typography>
          </div>
          <div className="field">
            <Typography className="label">First Logged In On</Typography>
            <Typography className="value">
              {formatDate(contactDetails?.first_athena_login) || "N.A"}
            </Typography>
          </div>
          <div className="field">
            <Typography className="label">Completed On</Typography>
            <Typography className="value">
              {formatDate(contactDetails?.athena_completion_date) || "N.A"}
            </Typography>
          </div>
        </div>
        { 
          <Button
            className="invite-button"
            variant="contained"
            color="primary"
            onClick={handleInvite}
          >
            Invite to Athena
          </Button>
        }
      </div>
    );
  };

  return (
    <PopoverStickOnHover
      component={renderPopoverContent()}
      placement="bottom-start"
      onMouseEnter={() => {}}
      delay={100}
    >
      {children}
    </PopoverStickOnHover>
  );
};

AthenaPopover.propTypes = {
  contactDetails: PropTypes.object,
  children: PropTypes.node,
  handleInvite: PropTypes.func,
};

export default AthenaPopover;
