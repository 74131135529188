//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from "prop-types";
import CloseIcon from "@material-ui/icons/Close";
import { Button, Typography } from "@material-ui/core";
import AddCircleIcon from "@material-ui/icons/AddCircle";

//----------------------------------------------// Internal Imports // -------------------------------------------------
import InputField from "../../../common/InputField";
import YearSelection from "../../../YearSelection";
import CompanySelection from "../../../AddContact/Components/CompanySelection";

export default function EmploymentDetails(props) {
  const { register, setValue, currentValues, readOnly } = props;
  const [startYear, setStartYear] = useState('');
  const [jobHistory, setJobHistory] = useState([])

  useEffect(() => {
    register('job_history');
    setJobHistory(currentValues.job_history)
  }, [currentValues, register])

  useEffect(() => {
    setValue('job_history', jobHistory);
  }, [jobHistory, setValue])

  const handleChange = (index, field, value) => {
    const currentJobHistory = [...jobHistory]
    currentJobHistory[index][field] = value
    setJobHistory(currentJobHistory)
  }

  const addRecord = () => {
    setJobHistory(prevState => ([...prevState, {}]))
  }

  const removeRecord = (index) => {
    let details = [...jobHistory];
    details.splice(index, 1)
    setJobHistory(details)
  }

  return (
    <React.Fragment>
      <Typography className='section-label'>Employment History</Typography>
      <div className='section-header d-flex'>
        <Typography className='name-field header-label'>Previous Company</Typography>
        <Typography className='name-field header-label'>Job Title</Typography>
        <Typography className='field header-label'>Job Start Year</Typography>
        <Typography className='field header-label'>Job End Year</Typography>
        <div style={{ width: 24 }}></div>
        <div className='field'></div>
      </div>
      {jobHistory.map((item, index) => {
        return (
          <div key={index} className="section d-flex align-items-end">
            <CompanySelection
              startAdornment={<Typography style={{ padding: '3.5px 5px' }} color='primary'>{index + 1}.</Typography>}
              defaultValue={item.company}
              onChange={(e, company) => {
                handleChange(index, 'company', company);
              }}
              className='name-field'
              label='Company'
              placeholder=''
              InputLabelProps={{ focused: true }}
            />
            <InputField
              value={item.title || ''}
              className='name-field'
              InputLabelProps={{ focused: true }}
              onChange={(e) => { handleChange(index, 'title', e.target.value) }}
            />
            <YearSelection
              className='field'
              value={item.start_year || null}
              onChange={(e, data) => {
                setStartYear(data)
                handleChange(index, 'start_year', data);
              }}
              label=''
              placeholder=''
              InputLabelProps={{ focused: true }}
              yearType='start'
            />
            <YearSelection
              className='field'
              value={item.end_year || null}
              onChange={(e, data) => {
                handleChange(index, 'end_year', data);
              }}
              label=''
              placeholder=''
              InputLabelProps={{ focused: true }}
              yearType='end'
              selectedStartYear={startYear}
            />
            <span className="align-self-center">
              <CloseIcon cursor='pointer' fontSize='small' onClick={() => removeRecord(index)} />
            </span>
            <div className='field'></div>
          </div>
        );
      })}
      <Button
        disabled={readOnly}
        variant={"text"}
        style={{ marginLeft: 15 }}
        startIcon={<AddCircleIcon />}
        onClick={addRecord}
        color='primary'>
        Add Company
      </Button>
    </React.Fragment>
  )
}

EmploymentDetails.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  currentValues: PropTypes.object,
  watch: PropTypes.func,
  getValues: PropTypes.func,
  readOnly: PropTypes.bool
}
