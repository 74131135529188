//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { Component } from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from "prop-types";
import { Button, Checkbox, Typography, FormControlLabel } from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import { withSnackbar } from "notistack";
import { saveAs } from "file-saver";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import {
  addAsMyBDTarget,
  columnWorkbenchDefs,
  ContactNameRenderer, CustomLoadingOverlayComponent, defaultColumns, EmailRenderer, getFilterParamString,
  PAGE_LIMIT, PhoneRenderer, ResumeRenderer,
  SUCCESS_STATUS_CODE,
  acquire as acquireUtil
} from "../../../../Containers/Contacts/utils";
import ActionsPopover from "../../../../Containers/Contacts/ActionsPopover";
import { API, EXPORT_CONTACT_LIMIT, PUT, POST, BLOB, GET, ERROR, WARNING } from "../../../../services/constantService"
import {
  addContactsToWorkbench,
  DateTimeRenderer,
  DateRenderer,
  LinkedInRenderer,
  loadColumnStateFromLocalStorage, removeContactsFromWorkbench,
  saveColumnStateToLocalStorage
} from "../../../../Containers/Commons/Utils";
import ActivityHistories from "../../../ActivityLog/Components/GeneralInfo/Components/ActivityHistory/ActivityHistories";
import CopyCandidatesToSearch from "../../../../Containers/Searches/AllCandidates/CopyCandidatesToSearch";
import ColumnFilter from "../../../../Containers/Commons/ColumnFilter";
import GenericCellEditor from "../../../../Containers/Commons/CellEditors/GenericCellEditor";
import { CompensationRenderer, sortByChronologicalOrder } from "../../../../utils/common";
import { CompanyNameRenderer } from "../../../../Containers/Companies/utils";
import Header from "../Header";
import QuickAddContact from "../../../../Containers/Searches/AllCandidates/Components/QuickAddContact";
import AddContactToWorkbench from "../AddContactToWorkbench";
import ConfirmationPopup from "../../../ConfirmationPopup";
import Loader from "../../../common/Loader";
import AddContactsToWorkbench from '../../../../Containers/Commons/AddContactToWorkbench'
import CustomFilter from "../../../../Containers/Commons/CustomFilter";
import ColorLegend from "../../../../Containers/Commons/ColorLegend"
import PopoverStickOnHover from "../../../common/PopoverStickOnHover"
import AthenaPopover from "../../../../../src/Containers/Searches/AllCandidates/Components/AthenaPopover";
import { formatDate } from "../../../../../src/utils/date";
import AthenaInvitePopUpContact from "../../../ViewSearch/Components/SFPA/Components/ClientAppAccess/AthenaInvitePopUpContact";
import LinkedInDialog from "../../../../Containers/Commons/LinkedInDialog";
import { dataSourceUtils } from "../../../../utils/dataSource";
import { acquireLockApi, picklistsColorsDataApi, releaseLockApi, workbenchDataApi } from "../../../../services/ApiService";
import { unableMessage, exportMessage, notFoundMessage, VALIDATION_MESSAGE } from "../../../../services/MessageService";
import { pickListActions } from "../../../../store/pickListSlice";
import EmploymentHistoryConfirmationDialog from "../../../../Containers/Commons/EmploymentHistoryConfirmationDialog";
import { cleanupCompanyDetails } from "../../../AddContact/utils";

class Contacts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowCount: 0,
      isRowSelected: false,
      isQuickAddOpen: false,
      isLoading: false,
      isAllRowsSelected: false,
      reload: false,
      colorData: null,
      workBenchId: '',
      isAthenaPopupOpen: false,
      filterModel: {},
      linkedPopup: false,
      linkedInValue: {},
      showJobTitleChangedPopup: false,
      employmentHistoryMessageEndYearDate: null,
      isUpdateEmploymentHistory: false,
      skipCheckJobTitle: false,
      currentContact: null,
      newContactData: null,
      showFilterCount: 0,
      contacts: []
    }
    this.getTotalCount = this.props.getTotalCount
  }

  dataSource = {
    getRows: async params => {
      this.setState({ showFilterCount: Object.keys(this.state.filterModel).length })
      try {
        const getURLs = {
          listURl: `${API.workbench}/${this.props.id}/contacts`
        };
        const thisValue = { ...this };
        this.gridApi.showLoadingOverlay();
        const obj = {
          params: params,
          context: thisValue,
          pageLimit: PAGE_LIMIT,
          url: getURLs,
          subScreen: true
        }

        const { status, data } = await dataSourceUtils(obj, getFilterParamString);
        if (status === SUCCESS_STATUS_CODE) {
          if (data?.paging?.totalCount === 0) {
            const message = notFoundMessage("records")
            this.props.enqueueSnackbar(message, { variant: WARNING })
          }
          this.setState({ rowCount: data.paging.totalCount })
          params.successCallback(data.data, data.paging.totalCount);
          this.getTotalCount(data.paging.totalCount)
          this.state.isAllRowsSelected && this.setSelectAllRows(this.state.isAllRowsSelected);
        } else {
          params.failCallback();
        }
        this.gridApi.hideOverlay();
      } catch (e) {
        console.log("Error found in getRows::", e);
      }
    },
    rowCount: null
  };

  setSelectAllRows = (isAllRowsSelected) => {
    this.setState({ isAllRowsSelected })
    this.gridApi.forEachNode(node => {
      node.setSelected(isAllRowsSelected);
    });
  }

  handleAthenaPopupClose = () => {
    this.setState({ isAthenaPopupOpen: false });
    this.gridApi.onFilterChanged()
  }

  linkedInPopupHandler = (props) => {
    this.setState({ linkedPopup: true, linkedInValue: props.value })
  }

  LinkedInRenderer = (params) => {
    return (
      <LinkedInRenderer params={params} linkedInPopup={this.linkedInPopupHandler} />
    )
  }

  AthenaRenderer = (params) => {
    const handleInvite = () => {
      this.setState({
        isAthenaPopupOpen: true,
        athenaData: {
          candidate: { id: params.data?.candidates },
          contact: params.data
        }
      })
    }
    if (params.data) {
      if (params.data.athena_completion_date || params.data.athena_invitation_sent_on) {
        return (
          <AthenaPopover contactDetails={params.data} handleInvite={handleInvite}>
            <Typography style={{ fontSize: 'inherit', cursor: 'pointer' }} color="primary">
              {params.data.athena_completion_date ?
                `Completed on: ${formatDate(params.data.athena_completion_date)}`
                :
                `Invited on: ${formatDate(params.data.athena_invitation_sent_on)}`
              }
            </Typography>
          </AthenaPopover>
        )
      } else if (params.data && params.data.candidates && params.data.candidates.length > 0) {
        return (
          <Button
            className='invite-button athena-button'
            variant='contained'
            color='primary'
            onClick={handleInvite}
          >
            Invite to Athena
          </Button>
        )
      } else {
        return (
          <Typography style={{ fontSize: 'inherit', cursor: 'pointer' }} color="primary">Contact Is Not Associated With Any Search</Typography>
        )
      }

    }
    return null
  }
  onGridReady = params => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    this.setState({ isGridReady: true });
  };

  NameRenderer = params => {
    return (
      <ContactNameRenderer
        params={params}
        handleAddToSearch={this.handleAddToSearch}
      />
    );
  };

  AddContactToRenderer = (params) => {
    const list = [
      {
        label: 'To Search',
        onClick: () => this.handleAddToSearch(params?.data?.id)
      },
      {
        label: 'As BD Target',
        onClick: () => addAsMyBDTarget(params?.data, this.props.userData, this.gridApi, this.props.enqueueSnackbar)
      }
    ]
    return params.data ? <ActionsPopover list={list} /> : null
  }

  handleAddToSearch = contactId => {
    this.setState({ isAddToSearchOpen: true, contactIds: [contactId] });
  };

  handleAddToSearchClose = () => {
    this.setState({ isAddToSearchOpen: false, contactIds: [] });
    this.gridApi.onFilterChanged()

  };

  showContactActivityHistory = contact => {
    this.setState({
      activeContact: contact
    });
  };

  handleQuickAddContact = () => {
    this.setState({
      isQuickAddOpen: true
    });
  };

  addContactsToWorkbench = async (contacts) => {
    try {
      const { id, enqueueSnackbar } = this.props
      this.setState({ loading: true })
      const status = await addContactsToWorkbench(id, contacts, enqueueSnackbar)
      this.setState({ loading: false })
      return status
    } catch (e) {
      console.log("Error found in addContactsToWorkbench::", e);
    }
  }

  handleQuickAddContactClose = async (contact, addToWorkbench) => {
    this.setState({ isQuickAddOpen: false });
    if (addToWorkbench && contact) {
      const success = await this.addContactsToWorkbench([contact.id])
      if (success) {
        this.gridApi.onFilterChanged()
      }
    }
  };

  CompanyNameRenderer = (params) => {
    return <CompanyNameRenderer company={params?.data?.company} />
  }

  onFirstDataRendered = (params) => {
    const columnApi = params.columnApi
    const gridApi = params.api
    loadColumnStateFromLocalStorage('workbenchContactsColumns', { columnApi, gridApi })
    const locationState = this.props.location.state
    if (locationState) {
      const sortModel = locationState.sortModel
      if (sortModel) {
        this.gridApi.setSortModel(sortModel);
      }

      const filterModel = locationState.filterModel
      if (filterModel) {
        this.gridApi.setFilterModel(filterModel);
      }
    }
  }

  saveColumnState = async () => {
    try {
      this.gridApi && this.gridApi.showLoadingOverlay()
      const columnApi = this.columnApi;
      const gridApi = this.gridApi
      await saveColumnStateToLocalStorage('workbenchContactsColumns', { columnApi, gridApi })
      this.gridApi && this.gridApi.hideOverlay()
    } catch (e) {
      console.log("Error found in saveColumnState::", e);
    }
  }

  saveColumnStateForFilter = async () => {
    try {
      this.gridApi && this.gridApi.showLoadingOverlay()
      const columnApi = this.columnApi;
      const gridApi = this.gridApi
      await saveColumnStateToLocalStorage('workbenchContactsColumns', { columnApi, gridApi }, true, false)
      this.gridApi && this.gridApi.hideOverlay()
    } catch (e) {
      console.log("Error found in saveColumnState::", e);
    }
  }

  saveColumnStateForSort = async () => {
    try {
      this.gridApi && this.gridApi.showLoadingOverlay()
      const columnApi = this.columnApi;
      const gridApi = this.gridApi
      await saveColumnStateToLocalStorage('workbenchContactsColumns', { columnApi, gridApi }, false, true)
      this.gridApi && this.gridApi.hideOverlay()
    } catch (e) {
      console.log("Error found in saveColumnState::", e);
    }
  }

  handleAddToWorkbench = () => {
    this.setState({ isAddToWorkbenchOpen: true })
  }

  handleAddToWorkbenchClose = (added) => {
    if (added) {
      this.gridApi.onFilterChanged()
    }
    this.setState({ isAddToWorkbenchOpen: false })
  }

  getSelectedContactIds = () => {
    const contactIds = [];
    this.gridApi.forEachNode((node) => {
      if (node.selected) {
        contactIds.push(node.data.id)
      }
    })
    return contactIds
  }

  getSelectedContacts = () => {
    const selectedContacts = [];
    this.gridApi.forEachNode((node) => {
      if (node.selected) {
        selectedContacts.push(node.data)
      }
    })
    return selectedContacts
  }

  dispatchColorPickList = (data) => {
    this.props.dispatch(pickListActions.updateColorPickList(data))
  }


  componentDidMount = async () => {
    const sub_route = '?type=workbench'
    if (this.props && !this.props.color_pick_list) {
      const { status, data } = await picklistsColorsDataApi(GET, this.props.id, '', sub_route, this.dispatchColorPickList);
      if (status === 200 && data !== null) {
        this.setState({ colorData: data });
      } else if (status === 200) {
        const message = notFoundMessage("color details")
        this.props.enqueueSnackbar(message, { variant: ERROR });
      } else {
        const message = unableMessage("color details", "fetch")
        this.props.enqueueSnackbar(data?.message || message, { variant: ERROR });
      }
    }
    else {
      this.setState({ colorData: this.props.color_pick_list });
    }
  }

  componentWillUnmount = () => {
    this.props.dispatch(pickListActions.updateColorPickList(this.state.colorData))
  }

  componentDidUpdate() {
    if (this.state.skipCheckJobTitle) {
      this.handleUpdateJobHistory()
    }
  }

  setColorDesc = (event, color, index) => {
    if (event.target.value.length <= 20) {
      let newArr = [...this.state.colorData];
      newArr = newArr.map((item, i) => i === index ? { ...item, desc: event.target.value } : { ...item });
      this.setState({ colorData: newArr });
      const data = { data: { workbench_id: this.props.id, colorId: color.id, desc: event.target.value } };

      setTimeout(async () => {
        const sub_route = '?type=workbench'
        await picklistsColorsDataApi(PUT, this.props.id, data, sub_route)
      }, 500);
    }
  }

  getColorByValue = (value) => {
    return this.state.colorData?.find(item => item.value === Number(value)) || {}
  }

  RenderColorsList = (params) => {
    const handleChange = async (event) => {
      const color = event.target.name;
      const data = { contact_color: color, contactId: params.data?.id }
      const sub_route = 'workbench-contact'
      this.setState({ isLoading: true });
      const { status } = await workbenchDataApi(PUT, this.props.id, data, sub_route);
      if (status === 200) {
        this.setState({ isLoading: false });
        params.node.setDataValue('contact_color', color);
      } else {
        const message = unableMessage("candidate", "update")
        this.props.enqueueSnackbar(message, { variant: ERROR });
      }
    };

    return <div className="d-flex colors-container justify-content-between">
      {this.state.colorData?.map(item => {
        return <div key={item.hex_code} className="color-code-container d-flex" style={{ background: `#${item.hex_code}`, color: `#${item.hex_code}` }}>
          <FormControlLabel
            style={{ margin: 0 }}
            control={<Checkbox style={{ padding: 0, width: 16, height: 16 }} color="default" checked={params.value === item.value} onChange={handleChange} name={item.value} />}
            label=""
          />
        </div>
      })}
    </div>
  }

  ColorRenderer = (params) => {
    let color
    if (params.data && params.data?.contact_color) {
      const isArray = Array.isArray(params.data?.contact_color)
      if (isArray) {
        color = this.getColorByValue(params.data?.contact_color[0]?.contact_color);
      } else {
        color = this.getColorByValue(params.data?.contact_color);
      }
    }
    if (params?.data?.color) {
      color = this.getColorByValue(params?.data?.color);
    }
    return <div className="d-flex align-items-center">
      <div className="color-code-box" style={{ background: `#${color?.hex_code}` }} />
      <PopoverStickOnHover
        component={this.RenderColorsList(params)}
        placement="bottom"
        onMouseEnter={() => { }}
        delay={200}>
        <ExpandMoreIcon color="primary" className="cursor-pointer" />
      </PopoverStickOnHover>
    </div>
  }

  handleCopyCandidates = () => {
    const contactIds = this.getSelectedContactIds()
    const contacts = this.getSelectedContacts()
    this.setState({ isAddToSearchOpen: true, contactIds, contacts })
  }

  handleChange = (event) => {
    const checked = event.currentTarget.checked
    this.setSelectAllRows(checked)
  };
  HeaderCheckbox = () => {
    return (
      <Checkbox
        style={{ padding: 0, width: 16, height: 16 , color: "white" }}
        size="small"
        color="primary"
        onChange={this.handleChange}
      />
    )
  }

  handleRemoveContacts = () => {
    this.setState({ isRemoveConfirmationOpen: true })
  }

  handleConfirmPopupClose = () => {
    this.setState({ isRemoveConfirmationOpen: false })
  }

  removeContacts = async () => {
    try {
      const { id, enqueueSnackbar } = this.props
      const contactIds = this.getSelectedContactIds()

      this.setState({ isLoading: true })
      const removed = await removeContactsFromWorkbench(id, contactIds, enqueueSnackbar, { ...this.state.filterModel })
      if (removed) {
        this.setState({ isRowSelected: false })
        this.setSelectAllRows(false);
        this.gridApi.onFilterChanged()
      }

      this.setState({ isLoading: false })
      this.handleConfirmPopupClose()
    } catch (e) {
      console.log("Error found in removeContacts::", e);
    }
  }

  getExtraClassName = () => {
    return this.props.isLockedWorkbench ? 'locked' : ''
  }

  exportContacts = async () => {
    let selectedContactIds = []
    selectedContactIds = this.getSelectedContactIds();
    if (this.state.isAllRowsSelected && this.state.rowCount > EXPORT_CONTACT_LIMIT) {
      const message = exportMessage(`${EXPORT_CONTACT_LIMIT}`, "Workbench-Contact")
      this.props.enqueueSnackbar(message, { variant: ERROR })
    }
    else if (!this.state.isAllRowsSelected && selectedContactIds.length > EXPORT_CONTACT_LIMIT) {
      const message = exportMessage(`${EXPORT_CONTACT_LIMIT}`, "Workbench-Contact")
      this.props.enqueueSnackbar(message, { variant: ERROR })
    }
    else {
      let sub_route = `contacts/export?`;
      if (Object.keys(this.state.filterModel).length) {
        sub_route = sub_route.concat(getFilterParamString(this.state.filterModel));
      }
      if (this.state.isAllRowsSelected) selectedContactIds = [];
      const columnHeaders = this.columnApi.getAllDisplayedColumns().map(column => column.getColDef().headerName);
      this.setState({ isLoading: true })
      const payload = { headers: { columnHeaders: columnHeaders, selectedRows: { selectedContactIds }, allRowSelected: this.state.isAllRowsSelected, limit: EXPORT_CONTACT_LIMIT } }
      const { status, data } = await workbenchDataApi(POST, this.props.id, payload, sub_route, BLOB);
      if (status === 200) {
        saveAs(new File([data], 'workbench_contacts.xlsx'));
      } else if (status === 400) {
        const message = exportMessage(`${EXPORT_CONTACT_LIMIT}`, "Workbench-Contact")
        this.props.enqueueSnackbar(message, { variant: ERROR })
      }
      else {
        const message = unableMessage("Workbench-Contact", "Export")
        this.props.enqueueSnackbar(message, { variant: ERROR })
      }
      this.setState({ isLoading: false })
    }
  };

  handleCopyContactsToWorkbench = () => {
    const contactIds = this.getSelectedContactIds()
    this.setState({ isCopyToWorkbenchOpen: true, contactIds })
  }

  handleCopyContactsToWorkbenchClose = () => {
    this.setState({ isCopyToWorkbenchOpen: false })
  }

  navigateToActivityLog = (contactId, ReadOnly = false) => {
    const { sortModel, filterModel } = this.state
    let url;
    if (ReadOnly === true) {
      url = `/contacts/${contactId}/log-an-activity/read-only/general-info`
    } else {
      url = `/contacts/${contactId}/log-an-activity/general-info`
    }
    this.props.history.replace(
      url,
      {
        sortModel,
        filterModel,
        fromContact: true,
        previousPath: this.props.location.pathname
      }
    )
  }

  ActionsRenderer = (params) => {
    const list = [{
      label: "Log An Activity",
      onClick: () => {
        this.acquire(params?.data?.id)
      }
    }, {
      label: "Show History",
      onClick: params.colDef ? () => this.showContactActivityHistory(params.data) : null,
    }]
    return <ActionsPopover list={list} />;
  };

  acquire = async (contactId) => {
    let { status, data } = await acquireLockApi(contactId);
    if (status && status === 200 && data.message === undefined) {
      await releaseLockApi(contactId)
      this.navigateToActivityLog(contactId)
    } else {
      //if (data.isAdmin) {
      this.props.enqueueSnackbar(data.message, {
        variant: WARNING,
        // eslint-disable-next-line react/display-name
        action: key => (
          <>
            <Button
              size='small'
              variant='text'
              color='inherit'
              onClick={() => {
                this.props.closeSnackbar(key)
                this.releaseLock(contactId)
              }}
            >
              Force Unlock
            </Button>
            <Button
              size='small'
              variant='text'
              color='inherit'
              onClick={() => {
                let ReadOnly = true;
                this.props.closeSnackbar(key)
                this.navigateToActivityLog(contactId, ReadOnly)
              }}
            >
              Open Read Only View
            </Button>
            <Button
              size='small'
              variant='text'
              color='inherit'
              onClick={async () => {
                this.props.closeSnackbar(key)
              }}
            >
               CLOSE
            </Button>
          </>
        )
      });
      /* }
      else {
        this.props.enqueueSnackbar(data.message, {
          variant: WARNING,
          // eslint-disable-next-line react/display-name
          action: key => (
            <Button
              size='small'
              variant='text'
              color='inherit'
              onClick={() => {
                let ReadOnly=true;
                this.props.closeSnackbar(key)
                this.navigateToActivityLog(contactId,ReadOnly)
              }}
            >
              Open Read Only View
            </Button>
          )
        });
      } */
    }
  }
  releaseLock = async (contactId) => {
    await releaseLockApi(contactId)
    this.acquire(contactId)
  }
  onSortChanged = (params) => {
    const sortModel = params.api.getSortModel();
    this.setState({ sortModel })
    this.saveColumnStateForSort()
  }

  onFilterChanged = (params) => {
    const filterModel = params.api.getFilterModel();
    this.setState({ filterModel })
    this.saveColumnStateForFilter()
  }

  loaderChange = async (value) => {
    this.setState({ isLoading: value })
  }

  handleClose = () => {
    this.setState({ linkedPopup: false });
  }

  setEmploymentHistoryEndYearDate = (endDate) => {
    this.setState({ employmentHistoryMessageEndYearDate: endDate });
  }

  handleConfirmJobTitlePopup = async () => {
    this.setState({ isUpdateEmploymentHistory: true, showJobTitleChangedPopup: false, skipCheckJobTitle: true })
  }
  handleCancelJobTitlePopup = async () => {
    this.setState({ isUpdateEmploymentHistory: false, showJobTitleChangedPopup: false, skipCheckJobTitle: true })
  }

  setJobHistoryPopupStates = (data) => {
    this.setState({ ...data })
  }

  resetFilter = async () => {
    this.gridApi.setFilterModel(null);
    const customFilterField = this.columnApi.columnController.columnDefs;
    customFilterField.map(item => {
      if (item.filter === 'CustomFilter') {
        this.gridApi.destroyFilter(item.field);
      }
    })
  }

  resetSort = async () => {
    this.gridApi.setSortModel(null);
  }

  handleUpdateJobHistory = async () => {
    this.setState({ skipCheckJobTitle: false })
    const params = this.state.newContactData;
    if (this.state.isUpdateEmploymentHistory) {
      this.setState({ isLoading: true })
      const contact = this.state.currentContact
      let newJobHistory = [{
        company: contact.company,
        title: contact.current_job_title,
        start_year: contact.current_job_start_year,
        end_year: this.state.employmentHistoryMessageEndYearDate || new Date().getFullYear()
      }]
      newJobHistory = [...newJobHistory, ...contact.job_history].filter(item => item?.company)
      params.job_history = sortByChronologicalOrder(cleanupCompanyDetails(newJobHistory, contact.id))
    }
    await acquireUtil(params, 'current_job_title', this.props.enqueueSnackbar, this.props.closeSnackbar, true, this.loaderChange, undefined, undefined, true)
    this.setJobHistoryPopupStates({
      employmentHistoryMessageEndYearDate: null,
      isUpdateEmploymentHistory: false,
      currentContact: null,
      newContactData: null
    })
  }

  render() {
    const { workbench, id, reloadData, option, isLockedWorkbench } = this.props
    // const { filter } = this.state.filterModel
    const {
      linkedInValue, linkedPopup, isRowSelected, activeContact, isAddToSearchOpen, contactIds, isAddToWorkbenchOpen, isQuickAddOpen,
      isRemoveConfirmationOpen, isLoading, isCopyToWorkbenchOpen, isAllRowsSelected, isAthenaPopupOpen
    } = this.state
    const queryData = isAllRowsSelected ? Object.fromEntries(new URLSearchParams(getFilterParamString(this.gridApi.getFilterModel()))) : null
    const extraClassName = this.getExtraClassName()

    return (
      <div className="list-work-benches d-flex flex-column">
        <Loader show={isLoading} />
        <LinkedInDialog open={linkedPopup} linkedInValue={linkedInValue} handleClose={this.handleClose} ></LinkedInDialog>
        {this.state.showJobTitleChangedPopup &&
          <EmploymentHistoryConfirmationDialog
            header='Update'
            employmentHistoryMessageEndYearDate={this.state.employmentHistoryMessageEndYearDate}
            setEmploymentHistoryEndYearDate={this.setEmploymentHistoryEndYearDate}
            open={this.state.showJobTitleChangedPopup}
            contact={this.state.currentContact}
            handleSubmit={this.handleConfirmJobTitlePopup}
            handleClose={this.handleCancelJobTitlePopup}
            cancelText='No'
            confirmText='Yes'
            extraClassName="jobhistory-popup"
          />
        }
        {activeContact && (
          <ActivityHistories
            contact={{
              id: activeContact.id,
              first_name: activeContact.first_name,
              last_name: activeContact.last_name,
              company: activeContact.company,
              current_job_title: activeContact.current_job_title
            }}
            onPopupClose={() => this.showContactActivityHistory(false)}
          />
        )}
        {isAddToSearchOpen && (
          <CopyCandidatesToSearch
            header={VALIDATION_MESSAGE.add_contact_to_search}
            buttonText={VALIDATION_MESSAGE.add_contact_to_search}
            uiLabel="contact"
            open={isAddToSearchOpen}
            contactIds={contactIds}
            workbenchId={id ? id : null}
            handleClose={this.handleAddToSearchClose}
            queryData={queryData}
            hide={true}
            contacts={this.state.contacts}
          />
        )}
        {isRemoveConfirmationOpen &&
          <ConfirmationPopup
            message="Are you sure you want to remove the contact(s) from this workbench?"
            onClose={this.handleConfirmPopupClose}
            onConfirm={this.removeContacts}
          />
        }
        {
          isCopyToWorkbenchOpen &&
          <AddContactsToWorkbench
            open={isCopyToWorkbenchOpen}
            contactIds={contactIds}
            onClose={this.handleCopyContactsToWorkbenchClose}
            sourceWorkbenchId={isAllRowsSelected ? id : null}
            type="Contacts"
          />
        }
        {isAthenaPopupOpen ? <AthenaInvitePopUpContact inviteFor={'candidate'} selectedDropDown={'Athena'} client={this.state.athenaData} onClose={this.handleAthenaPopupClose} /> : null}
        <AddContactToWorkbench
          open={isAddToWorkbenchOpen}
          handleClose={this.handleAddToWorkbenchClose}
          addToWorkbench={this.addContactsToWorkbench}
        />
        <QuickAddContact
          open={isQuickAddOpen}
          handleClose={this.handleQuickAddContactClose}
          isAddToSearchEnabled={true}
          addToText='Create Contact & Add To Workbench'
        />
        <Header
          name={workbench.name}
          users={workbench.tagged_users}
          workbench={workbench}
          showEdit={false}
          reloadData={reloadData}
          isLockedWorkbench={isLockedWorkbench}
        >
          <Button
            variant='contained'
            color='primary'
            onClick={this.exportContacts}
            disabled={!isRowSelected}
          >
            Export
          </Button>
        </Header>
        <div className='d-flex align-items-center p-3'>
          <Button
            color="primary"
            variant="outlined"
            className='action-button'
            onClick={this.handleQuickAddContact}
            disabled={isLockedWorkbench}
          >
            Quick Add Contact
          </Button>
          <Button
            color='primary'
            variant='outlined'
            className='action-button'
            onClick={this.handleAddToWorkbench}
            disabled={isLockedWorkbench}
          >
            Add Contacts To Workbench
          </Button>
          <Button
            onClick={this.handleCopyContactsToWorkbench}
            className='action-button'
            disabled={!isRowSelected}
            variant='outlined'
            color='primary'
          >
            Copy Contacts to Workbench
          </Button>
          <Button
            disabled={!isRowSelected}
            onClick={this.handleCopyCandidates}
            className='action-button'
            variant='outlined'
            color='primary'
          >
            Copy Contacts to Search
          </Button>
          <Button
            color='primary'
            variant='outlined'
            className='action-button'
            disabled={!isRowSelected || isLockedWorkbench}
            onClick={this.handleRemoveContacts}
          >
            Remove Contacts From Workbench
          </Button>
        </div>
        <div className="count-container">
          <div
            className="action-container"
            style={{ minWidth: '0' }}
            onClick={() => this.resetFilter()}
          >
            <span className="action-text" >
              Reset Filter
            </span>
          </div>
          <div
            className="action-container"
            style={{ minWidth: '0' }}
            onClick={() => this.resetSort()}
          >
            <span className="action-text" >
              Reset Sort
            </span>
          </div>
          <ColorLegend colorList={this.state.colorData} setColorDesc={this.setColorDesc} extraClassName={extraClassName} />
          <Typography>Total count: {this.state.rowCount}</Typography>
        </div>
        <div className="list-view flex-grow-1">
          {this.state.isGridReady && <ColumnFilter columnApi={this.columnApi} defaultColumns={defaultColumns} showFilterCount={this.state.showFilterCount} filterModel={this.state.filterModel} />}
          <div id="myGrid" className="ag-theme-alpine">
            <AgGridReact
              onGridReady={this.onGridReady}
              enableBrowserTooltips={true}
              defaultColDef={{
                minWidth: 100,
                resizable: true,
                sortable: true,
                filterable: false,
                sortingOrder: ['asc', 'desc', null]
              }}
              cacheBlockSize={PAGE_LIMIT}
              loadingOverlayComponent={"CustomLoadingOverlayComponent"}
              frameworkComponents={{
                CustomLoadingOverlayComponent,
                LinkedInRenderer: this.LinkedInRenderer,
                PhoneRenderer,
                EmailRenderer,
                ActionsRenderer: this.ActionsRenderer,
                AddContactToRenderer: this.AddContactToRenderer,
                ColorRenderer: this.ColorRenderer,
                NameRenderer: this.NameRenderer,
                ResumeRenderer,
                DateTimeRenderer,
                DateRenderer,
                CompanyNameRenderer: this.CompanyNameRenderer,
                GenericCellEditor,
                HeaderCheckbox: this.HeaderCheckbox,
                CompensationRenderer,
                CustomFilter,
                AthenaRenderer: this.AthenaRenderer,
              }}
              scrollbarWidth={12}
              suppressHorizontalScroll={false}
              suppressMenuHide={true}
              rowModelType={"infinite"}
              datasource={this.dataSource}
              columnDefs={columnWorkbenchDefs(this.showContactActivityHistory, this.props.enqueueSnackbar, this.props.closeSnackbar, true, this.props.id, this.loaderChange, option, this.setJobHistoryPopupStates)}
              paginationPageSize={PAGE_LIMIT}
              getRowNodeId={data => data.id}
              suppressRowClickSelection={true}
              rowSelection={'multiple'}
              onRowSelected={(e) => {
                if (!e.node.selected) {
                  this.setState({ isAllRowsSelected: false })
                }
                this.setState({ isRowSelected: this.gridApi.getSelectedRows().length > 0 ? true : false })
              }}
              onDisplayedColumnsChanged={this.saveColumnState}
              onDragStopped={this.saveColumnState}
              onFirstDataRendered={this.onFirstDataRendered}
              onSortChanged={this.onSortChanged}
              onFilterChanged={this.onFilterChanged}
              blockLoadDebounceMillis={100}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    userData: state.commonReducer.userData,
    color_pick_list: state.pickListReducer.color_pick_list
  }
};

const mapDispatchToProps = (dispatch) => ({ dispatch });

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(withRouter(Contacts)));

Contacts.propTypes = {
  workbench: PropTypes.object,
  enqueueSnackbar: PropTypes.func,
  closeSnackbar: PropTypes.func,
  color_pick_list: PropTypes.func,
  dispatch: PropTypes.func,
  id: PropTypes.string,
  location: PropTypes.object,
  history: PropTypes.object,
  reloadData: PropTypes.func,
  userData: PropTypes.object,
  getTotalCount: PropTypes.func,
  option: PropTypes.array,
  isLockedWorkbench: PropTypes.bool
}
