
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { Component } from "react";

//-----------------------------------------------------------// External Imports // ------------------------------

import { withRouter } from "react-router";
import { connect } from "react-redux";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import { saveAs } from "file-saver";
import { AgGridReact } from "ag-grid-react";
import { Button, Checkbox, Typography } from "@material-ui/core";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import CloseIcon from "@material-ui/icons/Close";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import { withSnackbar } from "notistack";

//-----------------------------------------------------------// Internal Imports // ------------------------------

import "./index.scss";
import ColumnFilter from "../Commons/ColumnFilter";
import {
  CustomLoadingOverlayComponent,
  defaultColumns,
  PAGE_LIMIT,
  SUCCESS_STATUS_CODE,
} from "../Companies/utils";
import { SCROLL_TIMEOUT } from "../../utils/common";
import {
  DateRenderer,
  loadColumnStateFromLocalStorage,
  saveColumnStateToLocalStorage,
  ReplistRenderer,
} from "../Commons/Utils";
import {
  cleanupWorkbenchQuery,
  columnDefs,
  fieldDefinitions,
  getArchiveDateFrom,
  getFilterParamStringForWorkbench,
  getWorkbenchTitle,
  IdRenderer,
  getWorkbenchQueryParamsString,
} from "./utils";
import Search from "../Commons/Search";
import ActionsPopover from "../Contacts/ActionsPopover";
import ConfirmationPopup from "../../components/ConfirmationPopup";
import { checkWorkbenchAccess, getArchivedDate } from "../../components/ViewWorkbench/utils";
import CustomFilter from "../Commons/CustomFilter";
import WorkbenchQuery from "./WorkbenchQuery";
import AdvancedQuery from "../Commons/AdvancedQuery";
import { formatDate } from "../../utils/date";
import {
  EXPORT_LIMIT,
  API,
  PUT,
  POST,
  BLOB,
  GET,
  SUCCESS,
  ERROR,
  WARNING,
  WORKBENCHES_MESSAGE,
  WORKBENCH_TYPES,
} from "../../services/constantService";
import { fetchUserList } from "../../actions";
import {
  workbenchAdvanceSelectorApi,
  workbenchDataApi,
} from "../../services/ApiService";
import {
  unableMessage,
  exportMessage,
  VALIDATION_MESSAGE,
  notFoundMessage,
  successMessage,
} from "../../services/MessageService";
// import { workbenchActions } from "../../store/workbenchSlice";
import Loader from "../../components/common/Loader";

/* const WORKBENCH_TYPES = {
  'user': 'userWorkbenches',
  'user-contacts': 'userContactsWorkbenches',
  'user-searches': 'userSearchesWorkbenches',
  'archived': 'archivedWorkbenches',
  'archived-contacts': 'archivedContactsWorkbenches',
  'archived-searches': 'archivedSearchesWorkbenches',
  'kg': 'kgkgWorkbenches',
  'kg-contacts': 'kgContactsWorkbenches',
  'kg-searches': 'kgSearchesWorkbenches',
} */

class Workbenches extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rowCount: 0,
      isGridReady: false,
      queryData: {},
      uiSelector: false,
      isAllRowsSelected: false,
      selectedRowsCount: 0,
      queryRun: false,
      advanceSelector: false,
      classicSelector: false,
      searchValue: "",
      searchString: "",
      string: "",
      headerCheckbox: false,
      exportSingleValue: false,
      isLoading: false,
      isSearchWorkbench: [WORKBENCH_TYPES.kgSearches, WORKBENCH_TYPES.userSearches, WORKBENCH_TYPES.archivedSearches].includes(props.type) ? true : false,
      isContactWorkbench: [WORKBENCH_TYPES.kgContacts, WORKBENCH_TYPES.userContacts, WORKBENCH_TYPES.archivedContacts].includes(props.type) ? true : false,
      isRootWorkbench: [WORKBENCH_TYPES.kg, WORKBENCH_TYPES.user, WORKBENCH_TYPES.archived].includes(props.type) ? true : false,
      showFilterCount: 0,
      isQuerySelector: false
    };
  }
  componentDidMount() {
    this.setState({ uiSelector: false });
    if (this.props && this.props.location && this.props.location.state) {
      this.setState({ string: this.props.location.state });
    }
    if (!this.props.users) {
      this.props.fetchUserList("users");
    }
  }

  componentWillUnmount() {
    const columnApi = this.columnApi
    saveColumnStateToLocalStorage(`${this.props.type}Workbenches`, { columnApi })
  }

  getParams = (selectedRows) => {
    // const selectedRows = this.gridApi.getSelectedRows();
    // if (this.state.isAllRowsSelected || selectedRows.length === 0) {
    //   return selectedRows.map(row => `id=${row.id}`).join('&')
    //   // return getQueryParamsString(this.state.queryData).substring(1)
    // } else {
    //   return selectedRows.map(row => `id=${row.id}`).join('&');
    // }
    return selectedRows.map((row) => `id=${row.id}`).join("&");
  };
  updateWorkbench = async (formData, successMessage, errorMessage, params) => {
    this.setState({ isLoading: true })
    const { status, data } = await workbenchDataApi(
      PUT,
      params.data.id,
      formData
    );
    if (status === 200) {
      this.props.enqueueSnackbar(successMessage, { variant: SUCCESS });
      // this.props.dispatch(workbenchActions.resetWorkbenchList())
      this.gridApi.onFilterChanged();
      this.setState({ isLoading: false })
    } else {
      this.props.enqueueSnackbar(data?.message || errorMessage, {
        variant: ERROR,
      });
      this.setState({ isLoading: false })
    }
  };

  archiveWorkbench = async () => {
    try {
      const formData = { archive_date: formatDate(new Date(), "YYYY-MM-DD") };
      await this.updateWorkbench(
        formData,
        "Workbench archived successfully",
        "Unable to archive workbench",
        this.state.paramsData
      );
    } catch (e) {
      console.log("Error found in archiveWorkbench::", e);
    }
    this.handleArchivePopupClose();
  };

  resetFilter = async () => {
    this.gridApi.setFilterModel(null);
    const customFilterField = this.columnApi.columnController.columnDefs;
    customFilterField.map(item => {
      if (item.filter === 'CustomFilter') {
        this.gridApi.destroyFilter(item.field);
      }
    })
  }

  resetSort = async () => {
    this.gridApi.setSortModel(null);
  }

  handleExport = async () => {
    const selectedRows = this.gridApi.getSelectedRows();
    if (this.state.isAllRowsSelected && this.state.rowCount > EXPORT_LIMIT) {
      const message = exportMessage(`${EXPORT_LIMIT}`, "Workbench");
      this.props.enqueueSnackbar(message, { variant: ERROR });
    } else if (
      !this.state.isAllRowsSelected &&
      selectedRows.length > EXPORT_LIMIT
    ) {
      const message = exportMessage(`${EXPORT_LIMIT}`, "Workbench");
      this.props.enqueueSnackbar(message, { variant: ERROR });
    } else {
      const paramsString = getWorkbenchQueryParamsString(this.state.queryData);
      const classicQueryParams = paramsString.length > 0 && this.state.classicSelector ? paramsString : '';

      if (selectedRows.length > 0) {
        let sub_route = `export-as-excel?`;
        let type = this.props.type;

        switch (type) {
        case WORKBENCH_TYPES.archivedContacts:
          sub_route = classicQueryParams ? `${sub_route}${classicQueryParams}` : `${sub_route}${classicQueryParams ? classicQueryParams : `&sub_type=${"Contacts"}`}`;
          break;
        case WORKBENCH_TYPES.archivedSearches:
          sub_route = classicQueryParams ? `${sub_route}${classicQueryParams}` : `${sub_route}${classicQueryParams ? classicQueryParams : `&sub_type=${"Searches"}`}`;
          break;
        case WORKBENCH_TYPES.kg:
          sub_route = classicQueryParams ? `${sub_route}${classicQueryParams}` : `${sub_route}&archive_date_from=${getArchiveDateFrom()}${classicQueryParams}`;
          break;
        case WORKBENCH_TYPES.kgSearches:
          sub_route = classicQueryParams ? `${sub_route}${classicQueryParams}` : `${sub_route}&archive_date_from=${getArchiveDateFrom()}${classicQueryParams ? classicQueryParams : `&sub_type=${"Searches"}`}`;
          break;
        case WORKBENCH_TYPES.kgContacts:
          sub_route = classicQueryParams ? `${sub_route}${classicQueryParams}` : `${sub_route}&archive_date_from=${getArchiveDateFrom()}${classicQueryParams ? classicQueryParams : `&sub_type=${"Contacts"}`}`;
          break;
        case WORKBENCH_TYPES.user:
          !this.state.searchString && (classicQueryParams ? sub_route = `${sub_route}${classicQueryParams}` : (sub_route = `${sub_route}&archive_date_from=${getArchiveDateFrom()}${classicQueryParams}`))
          break;
        case WORKBENCH_TYPES.userContacts:
          sub_route = classicQueryParams ? `${sub_route}${classicQueryParams}` : `${sub_route}&archive_date_from=${getArchiveDateFrom()}${classicQueryParams ? classicQueryParams : `&sub_type=${"Contacts"}`}`;
          break;
        case WORKBENCH_TYPES.userSearches:
          sub_route = classicQueryParams ? `${sub_route}${classicQueryParams}` : `${sub_route}&archive_date_from=${getArchiveDateFrom()}${classicQueryParams ? classicQueryParams : `&sub_type=${"Searches"}`}`;
          break;
        default:
          sub_route = `${sub_route}${classicQueryParams}`;
        }

        if (this.state.searchString) {
          const value = encodeURIComponent(this.state.searchString);
          if (this.state.exportSingleValue) {
            sub_route = `${sub_route}&id=${value}&quickSearch=true`;
          } else {
            sub_route = `${sub_route}&searchValue=${value}&quickSearch=true`;
          }
          type = "kg";
        }
        if (Object.keys(this.state.filterModel).length) {
          sub_route = sub_route.concat(
            getFilterParamStringForWorkbench(
              this.state.filterModel,
              (this.state.showQuery ||
                this.state.searchValue ||
                this.props.type === "kg" ||
                this.props.type === "user")
            )
          );
        }
        let id = [];
        if (!this.state.isAllRowsSelected) {
          selectedRows.map((data) => id.push(data.id));
        }
        const columnHeaders = this.columnApi
          .getAllDisplayedColumns()
          .map((column) => column.getColDef().headerName);
        this.gridApi.showLoadingOverlay();
        const payload = {
          headers: {
            columnHeaders: columnHeaders,
            selectedRows: { id: id },
            allRowSelected: this.state.isAllRowsSelected,
            limit: EXPORT_LIMIT,
            type: type,

          },
        };
        if (!classicQueryParams) {
          payload.headers.userID = this.props.userData.id
        }
        if (this.state.advanceSelector) {
          payload.headers.queryData = this.state.queryData
        }
        let { status, data, headers } = await workbenchDataApi(
          POST,
          "",
          payload,
          sub_route,
          BLOB
        );
        if (status === 200) {
          let fileName = "Workbenches.xlsx";
          let fileNameHeader = headers["content-disposition"].split('"');
          if (fileNameHeader && fileNameHeader.length > 2) {
            fileName = fileNameHeader[1];
          }
          saveAs(new File([data], fileName));
          this.gridApi.hideOverlay();
        }
      } else {
        this.props.enqueueSnackbar(VALIDATION_MESSAGE.no_record_selected, {
          variant: ERROR,
        });
      }
    }
  };

  isSelectedFromList = false;

  setSelectAllRows = (isAllRowsSelected) => {
    this.setState({ isAllRowsSelected });
    this.gridApi.forEachNode((node) => {
      node.setSelected(isAllRowsSelected);
    });
  };

  handleChange = () => {
    this.setSelectAllRows(!this.state.isAllRowsSelected);
  };

  HeaderCheckbox = () => {
    return (
      <Checkbox
        style={{ padding: 0, width: 16, height: 16, color: "white" }}
        size="small"
        color="primary"
        onChange={this.handleChange}
      />
    );
  };
  classicQuery = (queryData) => {
    this.setState({
      advanceSelector: false,
      classicSelector: true,
      searchString: "",
    });
    this.handleQuery(queryData);
  };

  AdvancedQuery = (queryData) => {
    this.setState({
      advanceSelector: true,
      classicSelector: false,
      searchString: "",
    });
    this.handleQuery(queryData);
  };

  saveColumnsState = async () => {
    try {
      this.setState({ isLoading: true });
      const columnApi = this.columnApi;
      const gridApi = this.gridApi
      await saveColumnStateToLocalStorage(`${this.props.type}Workbenches`, { columnApi, gridApi });
      this.setState({ isLoading: false });
    } catch (e) {
      console.log("Error found in saveColumnsState::", e);
    }
  };

  saveColumnsStateForFilter = async () => {
    try {
      this.setState({ isLoading: true });
      const columnApi = this.columnApi;
      const gridApi = this.gridApi
      await saveColumnStateToLocalStorage(`${this.props.type}Workbenches`, { columnApi, gridApi }, true, false);
      this.setState({ isLoading: false });
    } catch (e) {
      console.log("Error found in saveColumnsState::", e);
    }
  };

  saveColumnsStateForSort = async () => {
    try {
      this.setState({ isLoading: true });
      const columnApi = this.columnApi;
      const gridApi = this.gridApi
      await saveColumnStateToLocalStorage(`${this.props.type}Workbenches`, { columnApi, gridApi }, false, true);
      this.setState({ isLoading: false });
    } catch (e) {
      console.log("Error found in saveColumnsState::", e);
    }
  };

  dataSource = {
    getRows: async (params) => {
      let value = localStorage.getItem(`${this.props.type}Workbenches`)
      if (value === 'true') {
        this.setState({ showQuery: true })
        // this.resetFilter();
        // this.resetSort();
        params.successCallback([], 0);
        this.setState({ rowCount: 0 });
        this.setState({ filterModel: params.filterModel });
        this.setState({ showFilterCount: Object.keys(this.state.filterModel).length })
        return;
      }
      this.setState({ isLoading: true });
      if (params.filterModel) {
        this.setState({ filterModel: params.filterModel });
        this.setState({ showFilterCount: Object.keys(this.state.filterModel).length })
        // this.gridApi.deselectAll();
      }
      if (params.filterModel && Object.keys(params.filterModel).length > 0)
        this.gridApi.deselectAll();
      if (this.state.string) {
        this.handleSearchChange("", this.state.string);
      } else {
        const { type } = this.props;
        // const workbenchType = WORKBENCH_TYPES[type]
        const { searchValue, showQuery, queryData, searchString, classicSelector } = this.state;

        const { filterModel, sortModel } = params;
        /*const isLoad = Object.keys(filterModel).length > 0 || sortModel.length > 0 || searchValue.length > 0 || Object.keys(queryData).length > 0;
        if (!this.props[workbenchType] || isLoad) {*/
        const paginationString = `limit=${PAGE_LIMIT}&page=${params.endRow / PAGE_LIMIT
          }`;
        let url = "";
        if (this.state.advanceSelector) {
          url = `${API.master_query}?${paginationString}`;
          if (this.state.isSearchWorkbench) {
            url = `${url}&sub_type=${"Searches"}`
          } else if (this.state.isContactWorkbench) {
            url = `${url}&sub_type=${"Contacts"}`
          }
          if (Object.keys(filterModel).length) {
            url = url.concat(
              getFilterParamStringForWorkbench(
                filterModel,
                showQuery || searchValue || type === "kg" || type === "user"
              )
            );
          }
          if (sortModel.length > 0) {
            url = url.concat(
              `&sortOn=${sortModel[0].colId
              }&sortType=${sortModel[0].sort.toUpperCase()}`
            );
          }
        } else {
          this.gridApi.showLoadingOverlay();
          const paramsString = getWorkbenchQueryParamsString(queryData);
          const classicQueryParams = paramsString.length > 0 && classicSelector ? paramsString : '';
          if (classicSelector && paramsString.length > 0) {
            url = `${API.workbench}?${paginationString}${classicQueryParams}`;
          } else {
            switch (type) {
            case WORKBENCH_TYPES.archived:
              url = url = `${API.workbench}/${type}?${paginationString}`;
              break;
            case WORKBENCH_TYPES.archivedContacts:
              url = `${API.workbench}/archived?${paginationString}&sub_type=${"Contacts"}`;
              break;
            case WORKBENCH_TYPES.archivedSearches:
              url = `${API.workbench}/archived?${paginationString}&sub_type=${"Searches"}`;
              break;
            case WORKBENCH_TYPES.kg:
              url = `${API.workbench}?${paginationString}&archive_date_from=${getArchiveDateFrom()}`;
              break;
            case WORKBENCH_TYPES.kgSearches:
              url = `${API.workbench}?${paginationString}&archive_date_from=${getArchiveDateFrom()}&sub_type=${"Searches"}`;
              break;
            case WORKBENCH_TYPES.kgContacts:
              url = `${API.workbench}?${paginationString}&archive_date_from=${getArchiveDateFrom()}&sub_type=${"Contacts"}`;
              break;
            case WORKBENCH_TYPES.user:
              url = `${API.workbench}/user?${paginationString}&archive_date_from=${getArchiveDateFrom()}`;
              break;
            case WORKBENCH_TYPES.userContacts:
              url = `${API.workbench}/user?${paginationString}&archive_date_from=${getArchiveDateFrom()}&sub_type=${"Contacts"}`;
              break;
            case WORKBENCH_TYPES.userSearches:
              url = `${API.workbench}/user?${paginationString}&archive_date_from=${getArchiveDateFrom()}&sub_type=${"Searches"}`;
              break;
            default:
              console.log("No Match Found")
            }
          }

          if (searchString) {
            const value = encodeURIComponent(searchString);
            url = `${API.workbench}?${paginationString}&${this.isSelectedFromList ? "id" : "searchValue" /* 'name' */
              }=${value}`;
            if (this.state.isSearchWorkbench) {
              url = `${url}&sub_type=${"Searches"}`
            } else if (this.state.isContactWorkbench) {
              url = `${url}&sub_type=${"Contacts"}`
            }
          }

          if (Object.keys(filterModel).length) {
            url = url.concat(
              getFilterParamStringForWorkbench(
                filterModel,
                showQuery || searchValue || type === "kg" || type === "user"
              )
            );
          }

          if (sortModel.length > 0 && !this.isSelectedFromList) {
            url = url.concat(
              `&sortOn=${sortModel[0].colId
              }&sortType=${sortModel[0].sort.toUpperCase()}`
            );
          }
        }
        const { status, data } = this.state.advanceSelector
          ? await workbenchAdvanceSelectorApi(POST, url, this.state.queryData)
          : await workbenchAdvanceSelectorApi(GET, url);
        if (status === SUCCESS_STATUS_CODE && data?.data) {
          if (data?.paging?.totalCount === 0) {
            const message = notFoundMessage("records");
            this.props.enqueueSnackbar(message, { variant: WARNING });
          }
          /*if (!isLoad) {
            this.props.dispatch(workbenchActions.updateWorkbenchLists({ key: workbenchType, data }))
          }*/
          params.successCallback(data.data, data.paging.totalCount);
          this.setState({ rowCount: data.paging.totalCount });
          this.state.isAllRowsSelected &&
            this.setSelectAllRows(this.state.isAllRowsSelected);
        }
        params.failCallback();
        this.gridApi.hideOverlay();
        if (this.state.defaultValue) {
          this.setState({ defaultValue: false });
        }
        /*}
        else {
          params.successCallback(this.props[workbenchType].data, this.props[workbenchType].paging.totalCount);
          this.setState({ rowCount: this.props[workbenchType].paging.totalCount });
          this.gridApi.hideOverlay();
        }*/

      }
      // localStorage.removeItem(`${this.props.type}Workbenches`)
      this.setState({ isLoading: false });
    },
    rowCount: null,
  };

  ActionsRenderer = (params) => {
    let list = [];
    if (params?.data?.archive_date && new Date(params?.data?.archive_date) <= new Date()) {
      list = [
        {
          label: "Restore",
          onClick: async () => {
            const isValidUser = await this.checkAccessWorkbench(params.data)
            params.api && params.api.refreshCells();
            if (!isValidUser) return null;
            this.setState({
              showConfirmPopup: true,
              workbenchId: params.data.id,
              users: params.data.tagged_users,
            });
          },
        },
      ];
    }
    else {
      list = [
        {
          label: "Archive Workbench",
          onClick: async () => {
            const isValidUser = await this.checkAccessWorkbench(params.data)
            params.api && params.api.refreshCells();
            if (!isValidUser) return null;
            this.setState({
              showArchivePopup: true,
              paramsData: params
            });
          },
        },
      ];
    }
    return params.data ? <ActionsPopover list={list} /> : null;
  };

  MaxWidthOfActionColumn = () => {
    if (
      this.props.type === "archived" ||
      this.props.type === "archived-contacts" ||
      this.props.type === "archived-searches"
    ) {
      return 90;
    } else {
      return 150;
    }
  };
  onGridReady = (params) => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    const columnApi = params.columnApi
    const gridApi = params.api
    loadColumnStateFromLocalStorage(
      `${this.props.type}Workbenches`,
      { columnApi, gridApi }
    );
    this.setState({ isGridReady: true });
  };

  handleSearchChange = (event, value) => {
    this.setState({ classicSelector: false, advanceSelector: false });
    this.isSelectedFromList = false;
    if (this.state.string) {
      this.setState({ string: "" });
    }
    if (typeof value === "string") {
      this.setState({
        searchString: value,
        searchValue: value,
        exportSingleValue: false,
      });
    } else if (value && value.id) {
      this.isSelectedFromList = true;
      this.setState({
        searchString: value.id,
        searchValue: value.name,
        exportSingleValue: true,
      });
    }
    this.setState(
      { searchString: value?.id ? value?.id : value ? value : "" },
      () => {
        this.gridApi.onFilterChanged();
      }
    );
    this.resetFilter();
    this.resetSort();
  };
  handleConfirmPopupClose = () => this.setState({ showConfirmPopup: false });

  handleArchivePopupClose = () => this.setState({ showArchivePopup: false })

  checkAccessWorkbench = async (workbench) => {
    try {
      const taggedUser = Object.prototype.hasOwnProperty.call(workbench, "tagged_users")
      if (!taggedUser) {
        const workbenchInfo = await this.getWorkbenchInfo(workbench.id);
        if (workbenchInfo) {
          workbench.tagged_users = workbenchInfo.tagged_users
        } else {
          workbench.tagged_users = []
        }
      }
      const workbenchAccess = checkWorkbenchAccess(workbench, this.props.userData)
      if (!workbenchAccess) {
        this.props.enqueueSnackbar(WORKBENCHES_MESSAGE.VALIDATION_MESSAGE, { variant: WARNING });
      }
      return workbenchAccess
    } catch (e) {
      console.log("Error found in checkAccessWorkbench::", e);
    }
  }

  getWorkbenchInfo = async (workbenchId) => {
    const { status, data } = await workbenchDataApi(GET, workbenchId);
    if (status === 200 && data !== null) {
      this.setState({ isLoading: false })
      return data
    }
    return false
  }

  restoreWorkbench = async () => {
    this.setState({ isLoading: true })
    const payload = { archive_date: getArchivedDate() };
    const { status, data } = await workbenchDataApi(
      PUT,
      this.state.workbenchId,
      payload
    );
    if (status === 200) {
      const message = successMessage("Workbench", "restored");
      this.props.enqueueSnackbar(message, { variant: SUCCESS });
      const typeOfUser = this.state.users.find(
        (user) => user.name === this.props.userData.name
      );
      // this.props.dispatch(workbenchActions.resetWorkbenchList())
      if (typeOfUser?.name === this.props.userData.name) {
        this.props.history.replace("/workbenches/my-workbenches");
      } else {
        this.props.history.replace("/workbenches/kg-workbenches");
      }
      this.setState({ isLoading: false })
    } else {
      const message = unableMessage("workbench", "restore");
      this.props.enqueueSnackbar(data?.message || message, { variant: ERROR });
      this.setState({ isLoading: false })
    }

    this.handleConfirmPopupClose();
  };

  handleStartQuery = async () => {
    await this.resetSort();
    await this.resetFilter()
    this.setState({ showQuery: true });
    localStorage.setItem(`${this.props.type}Workbenches`, true)
    this.setState({ string: "", searchString: "", searchValue: "" });
    // setTimeout(() => {
    //   window.location.reload(false);
    // }, 3000);
  };

  handleQueryClose = async () => {
    await this.resetSort();
    await this.resetFilter()
    this.setState({ classicSelector: false, advanceSelector: false });
    this.setState({ showQuery: false, queryData: {} });
    this.gridApi.onFilterChanged();
    if (this.state.uiSelector === true) {
      this.setState({ isQuerySelector: true })
    } else {
      this.setState({ isQuerySelector: false })
    }
    localStorage.removeItem(`${this.props.type}Workbenches`)
    // setTimeout(() => {
    //   window.location.reload(false);
    // }, 3000);
  };

  handleQuery = (queryData) => {
    if (this.state.advanceSelector === false) {
      if (this.props.type.includes("contacts")) {
        queryData.type = "Contacts";
      } else if (this.props.type.includes("searches")) {
        queryData.type = "Searches";
      }
    }
    if (this.state.isAllRowsSelected === true) {
      this.setSelectAllRows(false);
      this.setState({ isAllRowsSelected: true });
    } else {
      this.gridApi.forEachNode((node) => {
        node.setSelected(false);
      });
    }
    this.setState({ queryData: cleanupWorkbenchQuery(queryData) });
    this.gridApi.onFilterChanged();
    localStorage.removeItem(`${this.props.type}Workbenches`)
  };

  runQuery = () => {
    this.setState({ queryRun: true });
  };

  pullData = () => {
    if (this.state.uiSelector === false && this.state.isQuerySelector === false) {
      this.setState({
        uiSelector: true,
        advanceSelector: true,
        isQuerySelector: true
      });
    } else if (this.state.uiSelector === true && this.state.isQuerySelector === true) {
      this.setState({
        uiSelector: false,
        advanceSelector: false,
        isQuerySelector: false
      });
    }
  };
  getDefaultQuery = () => {
    if (this.state.isContactWorkbench) {
      return `sub_type=${"Contacts"}`
    } else if (this.state.isSearchWorkbench) {
      return `sub_type=${"Searches"}`
    } else return null;
  }

  render() {
    const {
      showConfirmPopup,
      showArchivePopup,
      showQuery,
      minimizeQuery,
      searchValue,
      rowCount,
      isGridReady,
      isLoading
    } = this.state;
    const {
      handleConfirmPopupClose,
      handleArchivePopupClose,
      restoreWorkbench,
      handleQueryClose,
      handleStartQuery,
      ActionsRenderer,
      handleSearchChange,
      columnApi,
      onGridReady,
      dataSource,
      saveColumnsState,
      MaxWidthOfActionColumn,
      archiveWorkbench
    } = this;
    const { type, users } = this.props;

    const maxWidth = MaxWidthOfActionColumn();
    let newFieldDefinitions = { ...fieldDefinitions };
    if (!this.state.isRootWorkbench) {
      delete newFieldDefinitions.Type
    }
    return (
      <div className="list-work-benches d-flex flex-column">
        <Helmet>
          <title>{getWorkbenchTitle(this.props.type)}</title>
        </Helmet>
        {showConfirmPopup && (
          <ConfirmationPopup
            message={VALIDATION_MESSAGE.restore_workbench}
            onClose={handleConfirmPopupClose}
            onConfirm={restoreWorkbench}
          />
        )}
        {showArchivePopup && (
          <ConfirmationPopup
            message={VALIDATION_MESSAGE.archive_workbench}
            onClose={handleArchivePopupClose}
            onConfirm={archiveWorkbench}
          />
        )}
        {showQuery ? (
          <div className="d-flex query-toolbar d-flex p-0 mb-2">
            <ExpansionPanel
              className="flex-grow-1 w-100 m-0"
              expanded={showQuery && !minimizeQuery}
            >
              <ExpansionPanelSummary
                expandIcon={
                  <ExpandMoreIcon
                    onClick={() => this.setState({ minimizeQuery: true })}
                  />
                }
                aria-controls="panel1a-content"
                id="panel1a-header"
                onClick={() => this.setState({ minimizeQuery: !minimizeQuery })}
                style={{ padding: "0px 14px", lineHeight: 1 }}
              >
                Workbenches Query
              </ExpansionPanelSummary>
              <ExpansionPanelDetails className="w-100 p-0 pr-3 pl-3">
                {this.state.uiSelector ? (
                  <AdvancedQuery
                    fieldDefinitions={newFieldDefinitions}
                    type={"work_bench"}
                    pullData={this.pullData}
                    handleQuery={this.AdvancedQuery}
                  />
                ) : (
                  <WorkbenchQuery
                    type={type}
                    handleQuery={this.classicQuery}
                    pullData={this.pullData}
                    option={users}
                  />
                )}
              </ExpansionPanelDetails>
            </ExpansionPanel>
            <div className="expansion-panel-close-container">
              <span
                style={{
                  display: minimizeQuery ? "inline" : "none",
                  padding: 12,
                }}
                className="cursor-pointer d-flex"
                onClick={handleQueryClose}
              >
                <CloseIcon />
              </span>
            </div>
          </div>
        ) : (
          <div className={"toolbar"}>
            <Search
              value={searchValue}
              onChange={handleSearchChange}
              type="workbenches"
              defaultQuery={this.getDefaultQuery()}
            />
            <Button
              className="start-query"
              variant="outlined"
              color="primary"
              onClick={handleStartQuery}
            >
              Start Query
            </Button>
          </div>
        )}
        <div className="d-flex align-items-center justify-content-end">
          <div
            className="action-container"
            style={{ minWidth: '0' }}
            onClick={() => this.resetFilter()}
          >
            <span className="action-text" >
              Reset Filter
            </span>
          </div>
          <div
            className="action-container"
            style={{ minWidth: '0' }}
            onClick={() => this.resetSort()}
          >
            <span className="action-text" >
              Reset Sort
            </span>
          </div>
          <Button
            disabled={!this.state.selectedRowsCount}
            className="mr-3"
            variant="outlined"
            color="primary"
            onClick={this.handleExport}
          >
            Export List
          </Button>
          <Typography className="mr-3">Total count: {rowCount}</Typography>
        </div>
        <div className="list-view flex-grow-1">
          {isGridReady && (
            <ColumnFilter
              columnApi={columnApi}
              defaultColumns={defaultColumns}
              filterModel={this.state.filterModel}
              showFilterCount={this.state.showFilterCount}
            />
          )}
          <div id="myGrid" className="ag-theme-alpine">
            <AgGridReact
              rowModelType={"infinite"}
              enableBrowserTooltips={true}
              blockLoadDebounceMillis={SCROLL_TIMEOUT}
              tooltipShowDelay={0}
              scrollbarWidth={12}
              paginationPageSize={20}
              suppressHorizontalScroll={false}
              suppressMenuHide={true}
              suppressDragLeaveHidesColumns={true}
              cacheBlockSize={PAGE_LIMIT}
              loadingOverlayComponent={"CustomLoadingOverlayComponent"}
              columnDefs={columnDefs(false, true, true, maxWidth, type)}
              datasource={dataSource}
              defaultColDef={{
                minWidth: 100,
                resizable: true,
                sortable: true,
                sortingOrder: ['asc', 'desc', null]
              }}
              frameworkComponents={{
                DateRenderer,
                ReplistRenderer,
                CustomLoadingOverlayComponent,
                IdRenderer,
                ActionsRenderer,
                CustomFilter,
                HeaderCheckbox: this.HeaderCheckbox,
              }}
              rowSelection={"multiple"}
              onGridReady={onGridReady}
              onDisplayedColumnsChanged={this.saveColumnsState}
              suppressRowClickSelection={true}
              onDragStopped={saveColumnsState}
              onSortChanged={this.saveColumnsStateForSort}
              onFilterChanged={this.saveColumnsStateForFilter}
              onRowSelected={() => {
                // if (!e.node.selected) {
                //   this.setState({ isAllRowsSelected: false })
                // }
                this.setState({
                  selectedRowsCount: this.gridApi.getSelectedRows().length,
                });
              }}
              getRowNodeId={(data) => data.id}
            />
          </div>
        </div>
        <Loader show={isLoading} />
      </div>
    );
  }
}

Workbenches.propTypes = {
  location: PropTypes.object,
  type: PropTypes.string,
  enqueueSnackbar: PropTypes.func,
  userData: PropTypes.object,
  history: PropTypes.object,
  users: PropTypes.array,
  fetchUserList: PropTypes.func,
  /*userWorkbenches: PropTypes.object,
  userContactsWorkbenches: PropTypes.object,
  userSearchesWorkbenches: PropTypes.object,
  archivedWorkbenches: PropTypes.object,
  archivedContactsWorkbenches: PropTypes.object,
  archivedSearchesWorkbenches: PropTypes.object,
  kgkgWorkbenches: PropTypes.object,
  kgContactsWorkbenches: PropTypes.object,
  kgSearchesWorkbenches: PropTypes.object,
  dispatch: PropTypes.func,*/
};

const mapStateToProps = (state) => {
  return {
    userData: state.commonReducer.userData,
    users: state.rootReducer.users,
    /*userWorkbenches: state.workbenchReducer.userWorkbenches,
    userContactsWorkbenches: state.workbenchReducer.userContactsWorkbenches,
    userSearchesWorkbenches: state.workbenchReducer.userSearchesWorkbenches,
    archivedWorkbenches: state.workbenchReducer.archivedWorkbenches,
    archivedContactsWorkbenches: state.workbenchReducer.archivedContactsWorkbenches,
    archivedSearchesWorkbenches: state.workbenchReducer.archivedSearchesWorkbenches,
    kgkgWorkbenches: state.workbenchReducer.kgkgWorkbenches,
    kgContactsWorkbenches: state.workbenchReducer.kgContactsWorkbenches,
    kgSearchesWorkbenches: state.workbenchReducer.kgSearchesWorkbenches,
    dispatch: PropTypes.func*/
  };
};
const mapDispatchToProps = () => ({
  fetchUserList
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withSnackbar(Workbenches)));
