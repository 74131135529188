//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect } from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types'
import Grid from "@material-ui/core/Grid";

//----------------------------------------------// Internal Imports // -------------------------------------------------
import InputField from "../../../../../common/InputField";

export default function HeliaDetails(props) {
  const { register, setValue, readOnly } = props;
  useEffect(() => {
    register('helia_title');
  }, [register])

  return (
    <div className={`section-content ${readOnly ? 'read-only' : ''}`}>
      <Grid item xs={12} >
        <div className="d-flex flex-grow-1 w-25">
          <div className='contact-details'>
            <InputField
              ref={register()}
              placeholder="Helia Title"
              className="input-form-field"
              label="Helia Title"
              InputLabelProps={{ focused: true }}
              name="helia_title"
              onChange={(e) => setValue('helia_title', e.target.value)}
            />
            {/* <div className='contact-details '> <div className="input-form-field"></div></div> */}
          </div>
        </div>
      </Grid>
    </div>
  );
}

HeliaDetails.propTypes = {
  register: PropTypes.func,
  readOnly: PropTypes.bool,
  setValue: PropTypes.func
};
