//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types'
import { InputLabel, Tooltip, Typography } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info';
import { useSnackbar } from "notistack";
import { useParams } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { Autocomplete } from '@material-ui/lab';
import { useFieldArray } from 'react-hook-form';

//----------------------------------------------// Internal Imports // -------------------------------------------------
import './index.scss';
import { candidateContactDataApi } from '../../../../services/ApiService';
import { ACTIVITIES_ATTRIBUTE, CANDIDATE, PATCH, ERROR, SUCCESS } from '../../../../services/constantService';
import TabLayoutFooter from "../../../TabLayoutFooter"
import RichTextPopup from "../../../../../src/components/RichTextPopup";
import InputField from '../../../common/InputField';
import Loader from '../../../../components/common/Loader';
import Attribute from '../../../ViewSearch/Components/SFPA/Components/Attributes/Attribute';
import { unableMessage, successMessage } from "../../../../services/MessageService";
import { attributesTabDefaultValues } from "../../utils";

// eslint-disable-next-line
const Attributes = forwardRef((props, ref) => {
  const { acquire, releaseLock, goBack, saveStatus, saveStatusSetter, readOnly, getActivityLogInfoTabData } = props;
  const [loading, setLoading] = useState(false)
  const [currentValues, setCurrentValues] = useState(null)
  const [attributeData, setAttributeData] = useState(currentValues);
  const { enqueueSnackbar } = useSnackbar();
  const { register, reset, getValues, setValue, control } = useForm({});

  const { fields: sfpaAttributes } = useFieldArray({
    control,
    name: "sfpa_attributes",
  });

  useFieldArray({
    control,
    name: "attributes",
  });

  const [tabInfo, setTabInfo] = useState(null)
  const { id, path, searchId, contactId } = useParams();

  const getActivityLogInfo = useCallback(async (hideLoader) => {
    const data = await getActivityLogInfoTabData(hideLoader, 'ATTRIBUTES_TAB')
    if (data) {
      setTabInfo(data)
    }
  }, [contactId, id, path, enqueueSnackbar])

  useEffect(() => {
    getActivityLogInfo();
  }, [getActivityLogInfo])

  useEffect(() => {
    let defaultValues = attributesTabDefaultValues(tabInfo);
    // if (defaultValues && isRichTextSaved) {
    //   defaultValues = { ...defaultValues, ...cleanupFormValues(getValues({ nest: true })) }
    // }
    if (defaultValues) {
      setCurrentValues(defaultValues)
      setAttributeData(defaultValues)
      reset(defaultValues)
    }
  }, [tabInfo, reset, getValues])

  useImperativeHandle(ref, () => ({
    saveTabData: () => {
      return true
    },
    isDirty: () => {
      return false
    },
    formData: () => {
      return getValues({ nest: true })
    },
    reload: (hideLoader) => {
      getActivityLogInfo(hideLoader);
    }
  }));

  useEffect(() => {
    if (currentValues) {
      reset(currentValues)
    }
  }, [currentValues])

  useEffect(() => {
    if (attributeData) {
      attributeData.attributes.map((item, attributeIndex) => {
        setValue(`attributes[${attributeIndex}].rating`, item.rating || null)
        setValue(`attributes[${attributeIndex}].comments`, item.comments || null)
        setValue(`attributes[${attributeIndex}].hl_comments`, item.hl_comments || null)
      })
      const newData = { ...attributeData };
      setCurrentValues(newData);
    }
  }, [attributeData])
  const getRating = () => {
    let count = 0
    const total = attributeData.attributes.reduce((previousRating, item) => {
      const rating = parseFloat(item.rating)
      if (!isNaN(rating) && rating !== null) {
        count += 1
        return previousRating + rating
      }
      return previousRating
    }, 0)
    if (count === 0) {
      return 0
    }
    return +(total / count).toFixed(2)
  }

  const saveCommentsOnSave = async (newData, position, flag, loaderFlag, waitForAllResults = false, setAutoSaveLoading) => {
    try {
      loaderFlag && setLoading(true)
      let returnResult;
      let payload = {};
      if (flag) {
        payload = { ...attributeData.attributes[position], 'comments': newData[`attributes[${position}].comments`] }
      } else {
        payload = { ...attributeData.attributes[position], 'rating': newData.rating }
      }
      payload.searchAttributeId = attributeData.attributes[position].search_attribute_id
      payload.searchId = searchId
      if (setAutoSaveLoading) {
        setAutoSaveLoading(true)
      }
      const { status, data } = await candidateContactDataApi(PATCH, id, CANDIDATE, payload, ACTIVITIES_ATTRIBUTE);
      if (status === 200) {
        saveStatusSetter(true)
        if (data && data.result) {
          await setAttributeInfo(data.result)
        }
        if (waitForAllResults) {
          returnResult = data.result;
        } else {
          await setAttributeInfo(data.result)
        }
      } else {
        saveStatusSetter(false)
        if (!waitForAllResults) {
          const message = unableMessage("leadership competencies details", "update")
          enqueueSnackbar(message || message, { variant: ERROR })
        }
        returnResult = null;
      }
      if (setAutoSaveLoading) {
        setTimeout(() => {
          setAutoSaveLoading(false)
        }, 1000);
      }
      loaderFlag && setLoading(false)
      return returnResult;
    }
    catch (e) {
      console.log("Error found in saveCommentsOnSave::", e);
    }
  }

  const setAttributeInfo = (attrData) => {
    delete attrData.created_at;
    delete attrData.candidate_id;

    let activityInfoWithSkills = { ...attributeData }
    activityInfoWithSkills.attributes = activityInfoWithSkills.attributes.map(attribute => {
      if (attribute.search_attribute_id === attrData.search_attribute_id) {
        return { ...attribute, ...attrData }
      } else return { ...attribute }
    })
    setAttributeData(activityInfoWithSkills);
  }

  const setRatings = (value, index) => {
    const ratingValue = { 'rating': value }
    saveCommentsOnSave(ratingValue, index, undefined, true)
  }

  const setCandidateActivity = (activityInfoWithAttributes, attribute_result) => {
    activityInfoWithAttributes.attributes = activityInfoWithAttributes.attributes.map(attribute => {
      const attrData = attribute_result.find(item => item.search_attribute_id === attribute.search_attribute_id);
      if (attrData) {
        return { ...attribute, ...attrData };
      } else {
        return { ...attribute };
      }
    })
    setAttributeData(activityInfoWithAttributes);
  }

  const updateAttributesForm = async (closeTab) => {
    try {
      const aquireData = await acquire()
      setLoading(true);
      const waitForAllResults = true;
      if (aquireData) {
        let attribute_result = [];
        for await (const [index, item] of attributeData.attributes.entries()) {
          const ratings = {
            ...item,
            'rating': (item.rating === null || item.rating === undefined) ? null : item.rating,
          }
          const result = await saveCommentsOnSave(ratings, index, undefined, false, waitForAllResults)
          if (result) {
            delete result.created_at;
            delete result.candidate_id;
            attribute_result = [...attribute_result, result];
          }
        }
        if (attribute_result.length === attributeData.attributes.length) {
          let activityInfoWithAttributes = { ...attributeData }
          setCandidateActivity(activityInfoWithAttributes, attribute_result)
          const message = successMessage("Leadership Competency details", "updated")
          enqueueSnackbar(message, { variant: SUCCESS })
          if (closeTab) {
            releaseLock()
            goBack()
          }
        } else {
          const message = unableMessage("Leadership competency details", "update")
          enqueueSnackbar(message || message, { variant: ERROR })
        }
        setLoading(false)
      }
    }
    catch (e) {
      console.log("Error found in updateAttributesForm::", e);
    }
  }

  const actions = [
    { label: 'Save & Close', className: 'primary-btn mr-3', onClick: () => updateAttributesForm(true) },
    { label: 'Save', className: 'primary-btn', onClick: () => updateAttributesForm(false) }
  ]

  return currentValues && (
    <div>
      <div className="d-flex contact-view input-form attributes flex-column">
        <Loader show={loading} />
        <div className='d-flex align-items-center mb-5'>
          <Typography className='rating-field section-label m-0 mr-3'>Leadership Attributes</Typography>
          <div className='d-flex'>
            <Typography className='rating-name'>Average Rating</Typography>
            <Typography className='rating-value'>{getRating()}</Typography>
          </div>
        </div>
        <div className="d-flex flex-column" style={{ width: '80%' }}>
          <div className="d-flex w-100">
            <div className="section-content attributes-section mb-2 mr-5">
              <Typography className="section-label">Leadership Competencies</Typography>
            </div>
            <div className="section-content partner-ratings-section mb-2">
              <Typography className="section-label">Partner Ratings</Typography>
            </div>
          </div>
          {sfpaAttributes.map((item, index) => {
            const attribute = { id: item.id, ...item.attribute }
            return <div className="d-flex  w-100" key={item.id}>
              <div className="section-content attributes-section mr-5">
                <Attribute position={index} attribute={attribute} readOnly={true} />
                <InputField
                  ref={register()}
                  hidden
                  defaultValue={item.id}
                  name={`attributes[${index}].search_attribute_id`}
                  InputLabelProps={{ focused: true }}
                  label=''
                />
              </div>
              <div className="section-content partner-ratings-section">
                {/* value={watch(`attributes[${index}].rating`) != null ? watch(`attributes[${index}].rating`) + "" : null} */}
                <div className="d-flex flex-column" >
                  <Autocomplete
                    className='input-form-field input-field-old'
                    // defaultValue={attributes[index]?.rating != null ? attributes[index].rating + "" : null}
                    value={attributeData.attributes[index].rating != null ? attributeData.attributes[index].rating + "" : null}
                    getOptionLabel={option => String(option)}
                    renderInput={(params) => (
                      <InputField
                        ref={register()}
                        name={`attributes[${index}].rating`}
                        {...params}
                        InputLabelProps={{ focused: true }}
                        label='Rating'
                      />
                    )}
                    options={Array.from(Array(51).keys()).map(key => key / 10)}
                    onChange={(e, value) => { setRatings(value, index, 'recruiter') }}
                  />
                  <div className='text-view-value p-0 w-100 '>
                    <div className='outlined-border transform p-0 mb-3'>
                      <RichTextPopup
                        // className='mb-3'
                        name={`attributes[${index}].comments`}
                        ref={register(`attributes[${index}].comments`)}
                        onChange={(data) => {
                          setValue(`attributes[${index}].comments`, data);
                        }}
                        item={item}
                        index={index}
                        // defaultValue={`attributes[${index}].comments` || ''}
                        value={attributeData.attributes[index].comments || ''}
                        title="Partner Comments"
                        placeholder='Comments Here'
                        autoSave={saveCommentsOnSave}
                        onSave={(data) => {
                          const loaderFlag = true
                          saveCommentsOnSave(data, index, 'comment', loaderFlag)
                        }}
                        saveStatus={saveStatus}
                        updatedDate={attributeData.attributes[index].updated_at}
                        payloadType="attribute"
                        commentType="comment"
                        restrictHeight={true}
                      />
                    </div>
                  </div>
                  <div className='mb-3'>
                    <InputLabel focused={true} className="field-title" color="primary" style={{ fontSize: 14 }}><span className='mr-1'>HL Partner Comments</span>
                      <Tooltip title="This comment will not be available in any reports"><InfoIcon style={{ fontSize: '1rem' }} /></Tooltip>
                    </InputLabel>
                    <div className='text-view-value p-0 w-100'>
                      <div className='outlined-border transform p-0 scroll-bar'>
                        <RichTextPopup
                          value={attributeData.attributes[index].hl_comments || ''}
                          name={`attributes[${index}].hl_comments`}
                          ref={register(`attributes[${index}].hl_comments`)}
                          setOpen={false}
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <InputLabel focused={true} className="field-title" color="primary" style={{ fontSize: 14 }}><span className='mr-1'>HL Recruiter Comments</span>
                      <Tooltip title="This comment will not be available in any reports"><InfoIcon style={{ fontSize: '1rem' }} /></Tooltip>
                    </InputLabel>
                    <div className='text-view-value p-0 w-100 '>
                      <div className='outlined-border transform p-0 scroll-bar'>
                        <RichTextPopup
                          value={attributeData.attributes[index].hl_recruiter_attribute_comments || ''}
                          name={`attributes[${index}].hl_recruiter_attribute_comments`}
                          ref={register(`attributes[${index}].hl_recruiter_attribute_comments`)}
                          setOpen={false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          })}
        </div>
      </div>
      {!readOnly && <TabLayoutFooter actions={actions} />}
    </div>
  )
})

Attributes.propTypes = {
  acquire: PropTypes.func,
  releaseLock: PropTypes.func,
  goBack: PropTypes.func,
  saveStatus: PropTypes.bool,
  saveStatusSetter: PropTypes.func,
  readOnly: PropTypes.bool,
  getActivityLogInfoTabData: PropTypes.func,
  handleSaveTabData: PropTypes.func,
}

export default Attributes;