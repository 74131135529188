export const isQueryDataEmpty = (queryTerms, fieldDefinitions) => {
  const isEmpty = (queryTerm) => {
    return (
      !queryTerm.key ||
      (fieldDefinitions[queryTerm.name].type?.includes("Range")
        ? !queryTerm.gte || !queryTerm.lte
        : !queryTerm.value)
    );
  };
  return queryTerms.some(isEmpty);
};
