//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useState, useEffect } from 'react'

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from "prop-types";

//----------------------------------------------// Internal Imports // -------------------------------------------------
import TeamInfoTable from './TeamInfoTable'
import { VALIDATION_MESSAGE } from '../../../../../services/MessageService';

export default function AdminInformation(props) {
  const { register, setValue, currentValues = {}, isEditing = true, option } = props

  const fields = {
    execution_credit: 100
  }

  const [admins, setAdmins] = useState([])
  const [selectedAdmin,setSelectedAdmin]=useState([])

  useEffect(() => {
    register('eas')
    setValue('eas', admins)
  }, [register, admins, setValue])

  useEffect(()=>{
    if(admins && admins.length > 0) {
      const  options  = option.filter((array_el)=>{
        return admins.every(anotherOne_el=>anotherOne_el?.user?.id !== array_el?.id)
      });
      setSelectedAdmin(options)
    }else {
      setSelectedAdmin(option)
    }
  },[register, admins, setValue])

  useEffect(() => {
    setAdmins(currentValues.eas)
  }, [currentValues.eas])

  return (
    <TeamInfoTable header={VALIDATION_MESSAGE.admin_information} label="Admin" inputConfig={{ list: admins, fields, setter: setAdmins }} isEditing={isEditing} option={selectedAdmin} />
  )
}

AdminInformation.propTypes = {
  register: PropTypes.func,
  control: PropTypes.object,
  setValue: PropTypes.func,
  watch: PropTypes.func,
  currentValues: PropTypes.object,
  isEditing: PropTypes.bool,
  option: PropTypes.array
}
