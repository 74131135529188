//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useState, useEffect } from 'react'

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useDispatch, useSelector } from "react-redux";

//----------------------------------------------// Internal Imports // -------------------------------------------------
import InputField from '../../../../../common/InputField';
import { fetchPickList } from "../../../../../../actions";

function CapitalStructure(props) {
  const name = 'Capital Structure'
  const { label = name, placeholder = name, required = false, inputRef, InputLabelProps, variant = "standard", ...rest } = props;

  const [options, setOptions] = useState([]);
  const capitalStructures = useSelector(state => state.commonReducer.capitalStructures)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!capitalStructures) {
      dispatch(fetchPickList('capital-structures', 'capitalStructures'))
    } else {
      setOptions(capitalStructures);
    }
  }, [capitalStructures, dispatch])

  return (
    <Autocomplete
      {...rest}
      options={options}
      getOptionSelected={(option, value) => {
        if (!value) {
          return false;
        }
        if (typeof value === 'string') {
          return option.name === value;
        }
        return option.name === value.name
      }}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option
        }
        return option.name
      }}
      renderInput={(params) => (
        <InputField
          {...params}
          ref={inputRef}
          variant={variant}
          InputLabelProps={InputLabelProps}
          required={required}
          label={label}
          placeholder={placeholder}
        />
      )}
    />
  )
}

CapitalStructure.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  InputLabelProps: PropTypes.object,
  inputRef: PropTypes.object,
  variant: PropTypes.string
}

export default CapitalStructure
