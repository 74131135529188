
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useEffect, useState } from 'react'

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from 'prop-types'
import { useSnackbar } from "notistack";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import AddCircleIcon from "@material-ui/icons/AddCircle";


//-----------------------------------------------------------// Internal Imports // ------------------------------

import CompanySelection from "../../../AddContact/Components/CompanySelection";
import InputField from "../../../common/InputField";
import YearSelection from "../../../YearSelection";
import { cleanupCompanyDetails } from "../../../AddContact/utils";
import { DELETE, PUT, SUCCESS, ERROR, WARNING, GET } from '../../../../services/constantService';
import { acquireLockApi, contactDataApi, releaseLockApi } from '../../../../services/ApiService';
import { unableMessage, validateMessage, successMessage, requireMessage } from '../../../../services/MessageService';
import { compareByPosition, RedirectToCompanies } from '../../../../utils/common';
import { showSnackbar } from '../../../../Containers/Commons/Utils';
import { validateStartYearEndYear } from '../../../../utils/date';
import { VALIDATION_MESSAGE } from '../../../../services/MessageService';

export default function BoardDetailsView(props) {
  const { boardDetails: board = [], contactId, setIsLoading, setContactData } = props;
  const [startYear, setStartYear] = useState('');
  const [boardDetails, setBoardDetails] = useState([]);
  const [editIndex, setEditIndex] = useState(-1)
  const [hoverIndex, setHoverIndex] = useState(-1)
  const [isAdding, setIsAdding] = useState(false)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const [oldRecords, setOldRecords] = useState([]);
  const sub_route = 'board-details';
  useEffect(() => {
    setBoardDetails(board)
    setOldRecords(JSON.parse(JSON.stringify(board)));
  }, [board])

  const handleChange = (index, field, value, updateProps = false) => {
    // updateProps for update on Parent component
    const details = [...boardDetails]
    details[index][field] = value
    if (details[index]['company'] === null) {
      details[index]['company_id'] = null
    }
    setBoardDetails(details)
    if (updateProps) {
      setContactData(details, 'board_details')
    }
  }

  const addBoardCompany = () => {
    setBoardDetails(prevState => ([...prevState, { position: boardDetails.length }]))
  }

  const removeBoardCompany = (index) => {
    let details = [...boardDetails];
    details.splice(index, 1)
    setBoardDetails(details?.sort(compareByPosition))
    setContactData(details?.sort(compareByPosition), 'board_details')
  }

  const deleteBoardCompany = async (index) => {
    setIsLoading(true);
    const { status, data } = await contactDataApi(DELETE, contactId, { id: boardDetails[index].id }, sub_route);
    if (status === 200) {
      const message = successMessage("", "Deleted")
      enqueueSnackbar(message, { variant: SUCCESS });
      removeBoardCompany(index)
      setEditIndex(-1)
    } else {
      const message = unableMessage("board details", "delete")
      enqueueSnackbar(data?.message || message, { variant: ERROR })
    }
    releaseLock()
    setIsLoading(false);
  }

  const handleAdd = () => {
    if (isAdding) {
      const message = validateMessage("add", "complete")
      enqueueSnackbar(message, { variant: WARNING })
      return
    }
    setEditIndex(boardDetails.length)
    addBoardCompany()
    setIsAdding(true)
  }

  const isSameUser = (a, b) => {
    if ((a.committee === b.committee) && (a.company === b.company) && (a.start_date === b.start_date) && (a.end_date === b.end_date) && (a.position === b.position) && (a.title === b.title)) {
      return true;
    } else {
      return false;
    }
  }

  const handleUpdate = async index => {
    if (!boardDetails[index].company || !boardDetails[index].company && (boardDetails[index].title || boardDetails[index].start_date || boardDetails[index].end_date || boardDetails[index].committee)) {
      const message = requireMessage("Company", "is")
      enqueueSnackbar(message, { variant: ERROR })
      return
    }
    let boardYear = {}
    boardYear = validateStartYearEndYear(boardDetails[index])
    if (oldRecords.length === boardDetails.length) {
      const result = isSameUser(oldRecords[index], boardDetails[index]);
      if (result === true) {
        handleCancel(index);
      } else {
        if (boardYear && Object.keys(boardYear).length !== 0) {
          enqueueSnackbar(VALIDATION_MESSAGE.start_end_year, { variant: ERROR });
          return true
        }
        else {
          const details = cleanupCompanyDetails(boardDetails)
          setIsLoading(true)
          const { status, data } = await contactDataApi(PUT, contactId, details[index], sub_route);
          if (status === 200) {
            const message = successMessage("Board History details", "saved")
            enqueueSnackbar(message, { variant: SUCCESS });
            if (isAdding) {
              handleChange(index, 'id', data.id, true)
              setIsAdding(false)
            } else {
              const board = JSON.parse(JSON.stringify(boardDetails?.sort(compareByPosition)))
              setContactData(board, 'board_details');
            }
            setOldRecords(JSON.parse(JSON.stringify(boardDetails?.sort(compareByPosition))))
          } else {
            const message = unableMessage("board details", "delete")
            enqueueSnackbar(data?.message || message, { variant: ERROR })
          }
          setIsLoading(false);
          releaseLock()
          setEditIndex(-1)
        }
      }
    }
    else {
      if (boardYear && Object.keys(boardYear).length !== 0) {
        enqueueSnackbar(VALIDATION_MESSAGE.start_end_year, { variant: ERROR });
        return true
      }
      else {
        const details = cleanupCompanyDetails(boardDetails)
        setIsLoading(true);
        const { status, data } = await contactDataApi(PUT, contactId, details[index], sub_route);
        if (status === 200) {
          const message = successMessage("Board History details", "saved")
          enqueueSnackbar(message, { variant: SUCCESS });
          if (isAdding) {
            handleChange(index, 'id', data.id, true)
            setIsAdding(false)
          }
          const board = JSON.parse(JSON.stringify(boardDetails?.sort(compareByPosition)))
          setContactData(board, 'board_details');
        } else {
          const message = unableMessage("board details", "delete")
          enqueueSnackbar(data?.message || message, { variant: ERROR })
        }
        setIsLoading(false);
        releaseLock()
        setEditIndex(-1)
      }
    }

  }
  const releaseLock = async () => {
    await releaseLockApi(contactId);
  }
  const handleCancel = async (index) => {
    if (isAdding) {
      removeBoardCompany(index)
      setIsAdding(false)
    }
    releaseLock()
    setIsLoading(true)
    const { status, data } = await contactDataApi(GET, contactId);
    if (status && status == 200)
      setBoardDetails(data.board_details?.sort(compareByPosition))
    setIsLoading(false)
    setEditIndex(-1)
  }

  const renderView = item => {
    return (
      <>
        <div className="d-flex contact-details-row">
          <div className="contact-details">
            <div className="contact-view-label">Company Name</div>
            <div className="contact-view-value">
              {item.company && item.company.name ?
                <RedirectToCompanies id={item.company.id} name={item.company.name} showInLink={true} />
                : '--'}
            </div>
          </div>
          <div className="contact-details">
            <div className="contact-view-label">Title</div>
            <div className="contact-view-value">{item.title || '--'}</div>
          </div>
        </div>
        <div className="d-flex contact-details-row">
          <div className="contact-details">
            <div className="contact-view-label">Start Year</div>
            <div className="contact-view-value">{item.start_date || '--'}</div>
          </div>
          <div className="contact-details">
            <div className="contact-view-label">End Year</div>
            <div className="contact-view-value">{item.end_date || '--'}</div>
          </div>
        </div>
        <div className="d-flex contact-details-row">
          <div className="contact-details">
            <div className="contact-view-label">Committee</div>
            <div className="contact-view-value">{item.committee || '--'}</div>
          </div>
          <div className="contact-details">
            <div className="contact-view-label">Company Ticker</div>
            <div className="contact-view-value">{(item.company && item.company.ticker_sym) || '--'}</div>
          </div>
        </div>
      </>
    )
  }

  const renderEdit = (item, index) => {
    return (
      <React.Fragment>
        <div className="d-flex flex-grow-1"  >
          <CompanySelection
            autoFocus={true}
            defaultValue={item.company}
            onChange={(e, company) => {
              handleChange(index, 'company', company)
            }}
            className='input-form-field input-field-old contact-view-value'
            label='Company Name'
            placeholder={"Search"}
            InputLabelProps={{ focused: true, shrink: true }}
          />
          <InputField
            value={item.title || ''}
            className='input-form-field input-field-old contact-view-value'
            label='Title'
            placeholder='Title'
            InputLabelProps={{ focused: true, shrink: true }}
            onChange={(e) => { handleChange(index, 'title', e.target.value) }}
          />
        </div>
        <div className='d-flex flex-grow-1'>
          <YearSelection
            className='input-form-field input-field-old contact-view-value'
            label='Start Year'
            InputLabelProps={{ focused: true, shrink: true }}
            value={item.start_date || null}
            onChange={(e, data) => {
              setStartYear(data)
              handleChange(index, 'start_date', data);
            }}
            yearType={"start"}
          />
          <YearSelection
            className='input-form-field input-field-old contact-view-value'
            label='End Year'
            InputLabelProps={{ focused: true, shrink: true }}
            value={item.end_date || null}
            onChange={(e, data) => {
              handleChange(index, 'end_date', data);
            }}
            yearType={"end"}
            selectedStartYear={startYear}
          />
        </div>
        <InputField
          value={item.committee || ''}
          className='input-form-field input-field-old contact-view-value w-50'
          label='Committee'
          placeholder='Committee'
          InputLabelProps={{ focused: true, shrink: true }}
          onChange={(e) => { handleChange(index, 'committee', e.target.value) }}
        />
      </React.Fragment>
    )
  }

  const renderBoardDetails = () => {
    if (boardDetails.length === 0) {
      return <div className="d-flex contact-details-row no-data-available">--</div>
    }
    return boardDetails.map((item, index) => {
      return (
        <div key={index} onMouseEnter={() => { setHoverIndex(index) }} onMouseLeave={() => { setHoverIndex(-1) }}>
          {renderSubHeader(index)}
          {editIndex === index ? renderEdit(item, index) : renderView(item)}
        </div>
      )
    })
  }

  const renderEditIcon = (index) => {
    return !(editIndex === index || hoverIndex !== index) &&
      <EditIcon className='ml-2 cursor-pointer' onClick={async () => {
        let { status, data } = await acquireLockApi(contactId);
        if (status && status === 200 && data.message && data.user === false) {
          //if (data.isAdmin) {
          const object = {
            enqueueSnackbar: enqueueSnackbar,
            closeSnackbar: closeSnackbar,
            message: data.message,
            id: contactId
          }
          showSnackbar(object)

          /* }
          else {
            enqueueSnackbar(data.message, { variant: WARNING });
          } */
        }
        else {
          setIsLoading(true)
          const { status, data } = await contactDataApi(GET, contactId);
          if (status && status == 200) {
            setBoardDetails(data.board_details?.sort(compareByPosition))
          }
          setIsLoading(false)
          if (isAdding) {
            setIsAdding(false)
          }
          setEditIndex(index)
        }
      }} fontSize='inherit' color='secondary' />
  }

  const refreshContact = async () => {
    setIsLoading(true)
    const { status, data } = await contactDataApi(GET, contactId);
    if (status && status == 200) {
      setBoardDetails(data.board_details?.sort(compareByPosition))
    }
    setIsLoading(false)
  }

  const renderActions = (index) => {
    return (
      editIndex === index ?
        <div className='ml-2' style={{ fontSize: 16 }}>
          {!isAdding && <DeleteIcon cursor='pointer' fontSize='inherit' onClick={async () => { await deleteBoardCompany(index) }} />}
          <DoneIcon cursor='pointer' fontSize="inherit" color='primary' onClick={async () => { await handleUpdate(index); await refreshContact(); }} />
          <CloseIcon cursor='pointer' fontSize="inherit" onClick={() => handleCancel(index)} />
        </div>
        :
        renderEditIcon(index)
    )
  }

  const renderSubHeader = (index) => {
    return (
      <div className="secion-sub-header d-flex">
        <span>BOARD COMPANY {index + 1}</span>
        {renderActions(index)}
      </div>
    )
  }

  return (
    <div className="section-container">
      <div className="section-header">
        <span>Board Details</span>
        <AddCircleIcon className='ml-2 cursor-pointer' fontSize='inherit' color='primary' onClick={handleAdd} />
      </div>
      {renderBoardDetails()}
    </div>
  )
}

BoardDetailsView.propTypes = {
  boardDetails: PropTypes.array,
  contactId: PropTypes.string,
  setIsLoading: PropTypes.func,
  setContactData: PropTypes.func,

}
