//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from "prop-types";
import { useSnackbar } from "notistack";
import { Typography } from "@material-ui/core";
//----------------------------------------------// Internal Imports // -------------------------------------------------
import { renderActions } from "../../../../ViewContact/Details/utils";
import InputField from "../../../../common/InputField";
import { searchDataApi } from "../../../../../services/ApiService";
import { PUT, DELETE, ERROR, SUCCESS, GET, CONSTANT_MESSAGE } from "../../../../../services/constantService";
import { unableMessage, successMessage } from "../../../../../services/MessageService";
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export default function NotesView(props) {
  const { isEditing, currentValues, setValue, reloadData, setSearch, setUpdatelLoader, register } = props
  const [notes, setNotes] = useState([])
  const [editIndex, setEditIndex] = useState(-1)
  const [isAdding, setIsAdding] = useState(false)
  const [hoverIndex, setHoverIndex] = useState(-1)
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (Array.isArray(currentValues.notes)) {
      setNotes(currentValues.notes.map(item => ({ ...item })))
    }
  }, [currentValues])

  useEffect(() => {
    setValue('notes', notes)
  }, [notes, setValue])

  useEffect(() => {
    register('notes')
  }, [register])

  // const addNote = (e) => {
  //   if (isAdding) {
  //     const message = validateMessage("new note", "complete adding ")
  //     enqueueSnackbar(message, { variant: ERROR })
  //     return;
  //   }
  //   setEditIndex(notes.length);
  //   setIsAdding(true)
  //   setNotes(prevState => [...prevState, {}])
  //   e.stopPropagation();
  // }

  const removeNote = (index) => {
    setNotes(prevState => {
      prevState.splice(index, 1)
      return prevState
    })
    setEditIndex(-1)
  }

  const handleChange = (index, field, value) => {
    setNotes(prevState => {
      prevState[index][field] = value
      return prevState
    })
  }

  const getData = (index) => {
    const { id, description, title } = notes[index]
    const data = { id, description, title }
    if (!isAdding) {
      const { id, description, title } = currentValues.notes[index]
      data.old = { id, description, title }
    }
    return data
  }

  const handleUpdate = async (index) => {
    const body = getData(index);
    if ((!body.description || body.description.trim() === '') && (!body.title || body.title.trim() === '')) {
      const message = CONSTANT_MESSAGE
      enqueueSnackbar(message, { variant: ERROR })
      return;
    }
    const { status, data } = await searchDataApi(PUT, currentValues.id, body, 'notes');
    if (status === 200) {
      const message = successMessage("Notes", "saved")
      enqueueSnackbar(message, { variant: SUCCESS });
      if (reloadData) {
        reloadData(true)
      }
      if (isAdding) {
        handleChange(index, 'id', data.id)
        setIsAdding(false)
      }
    } else {
      const message = unableMessage("notes", "delete")
      enqueueSnackbar(data?.message || message, { variant: ERROR })
    }
    setEditIndex(-1)
  }


  const handleCancel = async (index) => {
    const { status, data } = await searchDataApi(GET, currentValues.id);
    if (status === 200 && data !== null) {
      setSearch(data);
    }
    if (isAdding) {
      removeNote(index)
      setIsAdding(false)
    }
    setEditIndex(-1)
  }

  const handleDelete = async (index) => {
    const { status, data } = await searchDataApi(DELETE, currentValues.id, { id: notes[index].id }, 'notes');
    if (status === 200) {
      const message = successMessage("", "Deleted")
      enqueueSnackbar(message, { variant: SUCCESS });
      removeNote(index)
      if (reloadData) {
        reloadData(true)
      }
    } else {
      const message = unableMessage("education details", "delete")
      enqueueSnackbar(data?.message || message, { variant: ERROR })
    }
  }

  return (
    // <div className='section-content flex-grow-1'>
    //   <Typography className="section-label">
    //     Notes
    //     <AddCircleIcon color='primary' className='ml-2' cursor='pointer' fontSize='inherit' onClick={addNote} />
    //   </Typography>
    //   {notes.length > 0 ? notes.map((item, index) =>
    //     <div key={index} className="section-sub-content" onMouseEnter={() => { setHoverIndex(index) }} onMouseLeave={() => { setHoverIndex(-1) }}>
    //       <div className='d-flex align-items-center'>
    //         <Typography color='primary' style={{ fontSize: 12 }}>Note {index + 1}</Typography>
    //         {renderActions(index, { isAdding, editIndex, searchId: currentValues.id, deleteEmployment: handleDelete, handleUpdate, handleCancel, hoverIndex, setEditIndex, setSearch, setUpdatelLoader })}
    //       </div>
    //       {
    //         editIndex === index ?
    //           <div className='flex-grow-1 d-flex flex-column mt-1'>
    //             <InputField
    //               autoFocus={!isEditing}
    //               defaultValue={item.title}
    //               InputProps={{
    //                 style: {
    //                   fontWeight: 500,
    //                   lineHeight: 19
    //                 }
    //               }}
    //               className='input-form-field'
    //               onChange={(e) => {
    //                 handleChange(index, 'title', e.target.value)
    //               }}
    //               placeholder="Heading Text Here"
    //               InputLabelProps={{ focused: true }}
    //             />
    //             <InputField
    //               InputProps={{
    //                 style: {
    //                   fontSize: 14
    //                 },
    //               }}
    //               onChange={(e) => {
    //                 handleChange(index, 'description', e.target.value)
    //               }}
    //               defaultValue={item.description}
    //               className='input-form-field'
    //               placeholder='Text Here'
    //               InputLabelProps={{ focused: true }}
    //             />
    //           </div>
    //           :
    //           <div className='flex-grow-1 d-flex flex-column mt-1'>
    //             <Typography variant='h6' style={{ fontSize: 16 }}>
    //               {item.title || '--'}
    //             </Typography>
    //             <Typography style={{ fontSize: 14 }}>
    //               {item.description || '--'}
    //             </Typography>
    //           </div>
    //       }
    //     </div>
    //   ) : <div className="d-flex">--</div>}
    // </div>
    <div className='section-content flex-grow-1' id='clientInformation'>
      <ExpansionPanel defaultExpanded={true} className="w-100 mb-5" style={{ background: '#fff', color: '#fff' }}>
        <ExpansionPanelSummary
          expandIcon={
            <ExpandMoreIcon style={{ color: "#fff" }} />
          }
          aria-controls="panel1a-content"
          style={{ background: '#2a364c' }}
        >
          <div >Notes </div>
          {/* <AddCircleIcon style={{ color: "#fff" }} className='ml-2' cursor='pointer' fontSize='inherit' onClick={(e) => addNote(e)} /> */}
        </ExpansionPanelSummary>
        <ExpansionPanelDetails className='d-flex flex-column px-1 pb-0'>

          {notes.length > 0 ? notes.map((item, index) => {
            if (item.title !== null || item.description !== null) {
              return <div key={index} id='clientDetails' className="mx-2">
                <div className='section-content team-information-table flex-grow-1'>
                  <div className="d-flex align-items-center table-header p-0" style={{ color: "#fff" }} onMouseEnter={() => { setHoverIndex(index) }} onMouseLeave={() => { setHoverIndex(-1) }}>
                    <Typography className="m-0" style={{ background: '#2a364c', color: '#fff', border: "6px solid #2a364c" }} >
                      Note {index + 1}
                    </Typography>  {renderActions(index, { isAdding, editIndex, searchId: currentValues.id, deleteEmployment: handleDelete, handleUpdate, handleCancel, hoverIndex, setEditIndex, setSearch, setUpdatelLoader })} </div>
                  {
                    editIndex === index ?
                      <div className='flex-grow-1 d-flex flex-column mt-2 mb-0 ml-1 padding-label'>
                        <div className='d-flex bd-details-item align-items-center  text-left'>
                          <InputField
                            autoFocus={!isEditing}
                            variant="outlined"
                            defaultValue={item.title}
                            className="add-notes-input col-12"
                            placeholder="Heading Title Here"
                            InputLabelProps={{ focused: true }}
                            onChange={(e) => {
                              handleChange(index, 'title', e.target.value)
                            }}
                          />
                        </div>

                        <div className='d-flex bd-details-item align-items-center  text-left'>
                          <InputField
                            autoFocus={!isEditing}
                            variant="outlined"
                            defaultValue={item.description}
                            className="add-notes-input w-100 col-12 ml-0"
                            placeholder="Description..."
                            InputLabelProps={{ focused: true }}
                            onChange={(e) => {
                              handleChange(index, 'description', e.target.value)
                            }}
                          />
                        </div>

                      </div>
                      :
                      <div className="notes-content-view mt-2 mb-2">
                        <div className='notes-details-item-view w-50'>
                          <span className="input-view-label ml-0 pl-1" style={{ color: '#000000', fontWeight: 'bold' }}>{item.title || '--'}</span>
                        </div>
                        <div className='notes-details-item-view w-50'>
                          <span className="input-desc-label col-2 ml-0 pl-1" style={{ color: '#000000' }}>{item.description || '--'}</span>
                        </div>
                      </div>
                  }
                </div>
              </div>
            } else {
              return null
            }
          }
          ) : <div className="d-flex ml-2" style={{ color: "black" }}>--</div>}

        </ExpansionPanelDetails>
        {/* <Button
          variant={"text"}
          startIcon={<AddIcon style={{ color: "red" }} />}
          onClick={(e) => addNote(e)} 
          style={{
            position: "relative",
            bottom: "-6px"
          }}
        >
          <span style={{
            fontWeight: "600",
            fontSize: "13px",
            fontStyle: "italic"
          }}>Add new </span>
        </Button> */}
      </ExpansionPanel>
    </div>
  )
}

NotesView.propTypes = {
  setValue: PropTypes.func,
  control: PropTypes.object,
  isEditing: PropTypes.bool,
  currentValues: PropTypes.object,
  reloadData: PropTypes.func,
  setSearch: PropTypes.func,
  setUpdatelLoader: PropTypes.func,
  register: PropTypes.func
}
