//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useEffect, useState } from 'react'

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from 'prop-types'
import { FormControlLabel, Checkbox, TextField } from '@material-ui/core'
import ReactPhoneInput from "react-phone-input-material-ui";

//-----------------------------------------------------------// Internal Imports // ------------------------------

import { determineInitialCountryGuess } from "../../../../utils/phone";
import InputField from "../../../common/InputField";
import RichTextPopup from "../../../RichTextPopup";
import UserSelection from "../../../UserSelection";
import { getScreeningNotesDefaultValue } from '../../../ActivityLog/utils';
export default function CommunicationView(props) {
  const {
    contact, renderLabel, editingField, setValue, register, renderActions, userData, watch, defaultValues,
    handleRichTextSave, saveStatus, individualFieldSave, option, handleMouseLeave, handleMouseEnter
  } = props;
  const [timeStamp, setTimestamp] = useState()
  const [initialLength, setInitialLength] = useState()
  const [screeningNotesToggleUpdatedFlag, setScreeningNotesToggleFlag] = useState(false)

  useEffect(() => {
    register('bd_target')
    register('kg')
  }, [register])
  const showUsers = () => {
    const users = [];
    contact.kg.map(user => users.push(user.name));
    return users.join(',');
  }

  const update = async (data) => {
    try {
      let HTMLArray;
      data.screening_notes = getScreeningNotesDefaultValue(data.screening_notes)
      const doc = new DOMParser().parseFromString(data.screening_notes, "text/html");
      HTMLArray = [...doc.body.children].map(el => el.outerHTML);
      let newNotes = HTMLArray.slice(initialLength, HTMLArray.length).join('')
      const span = document.createElement('span');
      span.innerHTML = newNotes;
      newNotes = span.textContent
      newNotes = newNotes.trim();
      let stringCompare = newNotes.substring(0, timeStamp.length)
      if (newNotes.length <= timeStamp.length || stringCompare !== timeStamp) {
        HTMLArray = HTMLArray.splice(0, initialLength);
        HTMLArray = HTMLArray.join(' ');
        data.screening_notes = HTMLArray;
      }
      await handleRichTextSave(data)
      //used for screening notes rendering issue after onSave function
      setScreeningNotesToggleFlag(prevState => !prevState);
    } catch (e) {
      console.log("Error found in update::", e);
    }

  }

  return (
    <div className="section-container">
      <div className="section-header">Communication</div>
      <div className="d-flex contact-details-row">
        <div className="contact-details">
          {renderLabel('Direct Line', 'direct_line')}
          {
            editingField === 'direct_line' ?
              <div className='d-flex'>
                <div className='contact-view-value'>
                  <ReactPhoneInput
                    ref={register('direct_line')}
                    placeholder='Direct Line'
                    value={watch('direct_line') || ''}
                    onChange={(data, c, e, value) => {
                      if (data.length > 1) {
                        setValue('direct_line', value)
                      }
                      else {
                        setValue('direct_line', '')
                      }
                    }}
                    name='direct_line'
                    enableSearch={true}
                    disableInitialCountryGuess={determineInitialCountryGuess(defaultValues.direct_line)}
                    inputProps={{
                      label: '',
                      InputLabelProps: { focused: true }
                    }}
                    component={TextField}
                  />
                </div>
                {renderActions('direct_line')}
              </div>
              :
              <div className="contact-view-value">{contact.direct_line || '--'}</div>
          }
        </div>
        <div className="contact-details">
          {renderLabel('Mobile Phone', 'mobile_phone')}
          {editingField === 'mobile_phone' ?
            <div className='d-flex'>
              <div className='contact-view-value'>
                <ReactPhoneInput
                  ref={register('mobile_phone')}
                  placeholder='Mobile Phone'
                  value={watch('mobile_phone') || ''}
                  onChange={(data, c, e, value) => {
                    if (data.length > 1) {
                      setValue('mobile_phone', value)
                    }
                    else {
                      setValue('mobile_phone', '')
                    }
                  }}
                  enableSearch={true}
                  disableInitialCountryGuess={determineInitialCountryGuess(defaultValues.mobile_phone)}
                  inputProps={{
                    label: '',
                    InputLabelProps: { focused: true }
                  }}
                  component={TextField}
                />
              </div>
              {renderActions('mobile_phone')}
            </div>
            :
            <div className="contact-view-value">{contact.mobile_phone || '--'}</div>
          }
        </div>
      </div>
      <div className="d-flex contact-details-row">
        <div className="contact-details">
          {renderLabel('Home Phone', 'home_phone')}
          {editingField === 'home_phone' ?
            <div className='d-flex'>
              <div className='contact-view-value'>
                <ReactPhoneInput
                  ref={register('home_phone')}
                  placeholder='Home Phone'
                  value={watch('home_phone') || ''}
                  onChange={(data, c, e, value) => {
                    if (data.length > 1) {
                      setValue('home_phone', value)
                    }
                    else {
                      setValue('home_phone', '')
                    }
                  }}
                  enableSearch={true}
                  disableInitialCountryGuess={determineInitialCountryGuess(defaultValues.home_phone)}
                  inputProps={{
                    label: '',
                    InputLabelProps: { focused: true }
                  }}
                  component={TextField}
                />
              </div>
              {renderActions('home_phone')}
            </div>
            :
            <div className="contact-view-value">{contact.home_phone || '--'}</div>
          }
        </div>
        <div className="contact-details">
          {renderLabel('Skype Id', 'skype_id')}
          {
            editingField === 'skype_id' ?
              <div className='d-flex'>
                <InputField
                  ref={register}
                  name='skype_id'
                  className='contact-view-value'
                  label=""
                  InputLabelProps={{ focused: true }}
                />
                {renderActions('skype_id')}
              </div>
              :
              <div className="contact-view-value">{contact.skype_id || '--'}</div>
          }
        </div>
      </div>
      <div className="d-flex contact-details-row">
        <div className="contact-details">
          {renderLabel('Work Email', 'work_email')}
          {
            editingField === 'work_email' ?
              <div className='d-flex'>
                <InputField
                  ref={register()}
                  className='contact-view-value'
                  type='email'
                  label=''
                  InputLabelProps={{ focused: true }}
                  name='work_email'
                />
                {renderActions('work_email')}
              </div>
              :
              (contact.work_email && <a className='text-break' href={`mailto:${contact.work_email}`}>{contact.work_email}</a>) || '--'
          }
        </div>
        <div className="contact-details">
          {renderLabel('Private Email', 'private_email')}
          {
            editingField === 'private_email' ?
              <div className='d-flex'>
                <InputField
                  ref={register()}
                  className='contact-view-value'
                  type='email'
                  label=''
                  InputLabelProps={{ focused: true }}
                  name='private_email'
                />
                {renderActions('private_email')}
              </div>
              :
              (contact.private_email && <a className='text-break' href={`mailto:${contact.private_email}`}>{contact.private_email}</a>) || '--'
          }
        </div>
      </div>
      <div className="d-flex contact-details-row">
        <div className="contact-details">
          {renderLabel('Non Compete', 'is_non_compete')}
          {
            editingField === 'is_non_compete' ?
              <div className='d-flex'>
                <FormControlLabel
                  control={<Checkbox defaultChecked={defaultValues.is_non_compete} size="small" style={{ paddingRight: 5 }} name='is_non_compete' inputRef={register} />}
                  label='Yes'
                />
                {renderActions('is_non_compete')}
              </div>
              :
              <div className="contact-view-value">{contact.is_non_compete != null ? contact.is_non_compete ? 'Yes' : 'No' : '--'}</div>
          }
        </div>
      </div>
      <div className="d-flex contact-details-row">
        <div className="contact-details">
          {renderLabel('Contact Preference', 'do_not_call')}
          {
            editingField === 'do_not_call' ?
              <div className='d-flex'>
                <FormControlLabel
                  control={<Checkbox defaultChecked={defaultValues['do_not_call']} size="small" style={{ paddingRight: 5 }} name='do_not_call' inputRef={register} />}
                  label='Do Not Call'
                />
                {renderActions('do_not_call')}
              </div>
              :
              <FormControlLabel control={<Checkbox disableRipple={true} style={{ paddingTop: 0, paddingBottom: 0 }} checked={contact.do_not_call || false} color="primary" />} label="Do not call" />
          }
        </div>
        {contact.do_not_call && (<div className="contact-details">
          {renderLabel('Do Not Call Reason', 'do_not_call_reason')}
          {
            editingField === 'do_not_call_reason' ?
              <div className='d-flex'>
                <InputField
                  ref={register}
                  className='input-form-field input-field-old'
                  label=''
                  InputLabelProps={{ focused: true }}
                  name='do_not_call_reason'
                />
                {renderActions('do_not_call_reason')}
              </div>
              :
              <div className="contact-view-value">{contact.do_not_call_reason || '--'}</div>
          }
        </div>)}
      </div>
      <div className="d-flex contact-details-row">
        <div className="contact-details" style={{ flex: '1 0' }}>
          {renderLabel('Career Snapshot - Profile', 'career_snapshot_profile')}
          {/* <div className="contact-view-label">Career Snapshot - Profile</div> */}
          {editingField === 'career_snapshot_profile' ?
            <>
              <div className="contact-view-value react-quill-container d-flex justify-content-between">
                <div className='flex-grow-1 outlined-border transform p-0'>
                  <RichTextPopup
                    bounds={'.react-quill-container'}
                    name="career_snapshot_profile"
                    value={contact.career_snapshot_profile}
                    onSave={handleRichTextSave}
                    title={'Career Snapshot - Profile'}
                    autoSave={individualFieldSave}
                    updatedDate={contact.updated_at}
                    saveStatus={saveStatus}
                  />
                </div>
                <div>
                  {renderActions('career_snapshot_profile', false, { 'career_snapshot_profile': contact.career_snapshot_profile })}
                </div>
              </div>
            </>
            : <div
              className="contact-view-value react-quill-container"
              onMouseEnter={() => handleMouseEnter('career_snapshot_profile')}
              onMouseLeave={handleMouseLeave} >
              <div className='outlined-border transform p-0'>
                <RichTextPopup
                  bounds={'.react-quill-container'}
                  name="career_snapshot_profile"
                  value={contact.career_snapshot_profile}
                  title={'Career Snapshot - Profile'}
                  setOpen={false}
                />
              </div>
            </div>}
        </div>
        <div className="contact-details" style={{ flex: '1 0' }}>
          {renderLabel('Personal - Profile', 'personal_profile')}
          {/* <div className="contact-view-label">Personal - Profile</div> */}
          {editingField === 'personal_profile' ?
            <>
              <div className="contact-view-value react-quill-container d-flex justify-content-between">
                <div className='flex-grow-1 outlined-border transform p-0'>
                  <RichTextPopup
                    bounds={'.react-quill-container'}
                    name="personal_profile"
                    value={contact.personal_profile}
                    onSave={handleRichTextSave}
                    title={'Personal - Profile'}
                    autoSave={individualFieldSave}
                    updatedDate={contact.updated_at}
                    saveStatus={saveStatus}
                  />
                </div>
                <div>
                  {renderActions('personal_profile', false, { 'personal_profile': contact.personal_profile })}
                </div>
              </div>
            </>
            : <div
              className="contact-view-value react-quill-container"
              onMouseEnter={() => handleMouseEnter('personal_profile')}
              onMouseLeave={handleMouseLeave} >
              <div className='outlined-border transform p-0'>
                <RichTextPopup
                  bounds={'.react-quill-container'}
                  name="personal_profile"
                  value={contact.personal_profile}
                  title={'Personal - Profile'}
                  setOpen={false}
                />
              </div>
            </div>}
        </div>

      </div>
      <div className="d-flex contact-details-row">
        <div className="contact-details" style={{ flex: '1 0' }}>
          {renderLabel('Recommendations Profile', 'recommendations_profile')}
          {/* <div className="contact-view-label">Recommendation - Profile</div> */}
          {editingField === 'recommendations_profile' ?
            <>
              <div className="contact-view-value react-quill-container d-flex justify-content-between">
                <div className='flex-grow-1 outlined-border transform p-0'>
                  <RichTextPopup
                    bounds={'.react-quill-container'}
                    name="recommendations_profile"
                    value={contact.recommendations_profile}
                    onSave={handleRichTextSave}
                    title={'Recommendations Profile'}
                    autoSave={individualFieldSave}
                    updatedDate={contact.updated_at}
                    saveStatus={saveStatus}
                  />
                </div>
                <div>
                  {renderActions('recommendations_profile', false, { 'recommendations_profile': contact.recommendations_profile })}
                </div>
              </div>
            </>
            : <div
              className="contact-view-value react-quill-container"
              onMouseEnter={() => handleMouseEnter('recommendations_profile')}
              onMouseLeave={handleMouseLeave} >
              <div className='outlined-border transform p-0'>
                <RichTextPopup
                  bounds={'.react-quill-container'}
                  name="recommendations_profile"
                  value={contact.recommendations_profile}
                  title={'Recommendations Profile'}
                  setOpen={false}
                />
              </div>
            </div>}
        </div>
        <div className="contact-details" style={{ flex: '1 0' }}>
          {renderLabel('Screening Notes', 'screening_notes')}
          {/* <div className="contact-view-label">Screening Notes</div> */}
          {editingField === 'screening_notes' ?
            <>
              <div className="contact-view-value react-quill-container d-flex justify-content-between">
                <div className='flex-grow-1 outlined-border transform p-0'>
                  <RichTextPopup
                    bounds={'.react-quill-container'}
                    name="screening_notes"
                    userdata={userData}
                    eventBinding={true}
                    setTimestamp={setTimestamp}
                    setInitialLength={setInitialLength}
                    value={(contact.screening_notes === '<p><br /></p>' || contact.screening_notes === '<p><br></p>') ? null : contact.screening_notes}
                    onSave={update}
                    title={'Screening Notes'}
                    autoSave={individualFieldSave}
                    updatedDate={contact.updated_at}
                    saveStatus={saveStatus}
                    screeningNotesFlag={true}
                    screeningNotesToggleUpdatedFlag={screeningNotesToggleUpdatedFlag}
                  />
                </div>
                <div>
                  {renderActions('screening_notes', false, { 'screening_notes': contact.screening_notes })}
                </div>
              </div>
            </>
            : <div
              className="contact-view-value react-quill-container"
              onMouseEnter={() => handleMouseEnter('screening_notes')}
              onMouseLeave={handleMouseLeave} >
              <div className='outlined-border transform p-0'>
                <RichTextPopup
                  bounds={'.react-quill-container'}
                  name="screening_notes"
                  value={contact.screening_notes}
                  title={'Screening Notes'}
                  setOpen={false}
                />
              </div>
            </div>}
        </div>
      </div>
      <div className="d-flex contact-details-row">
        <div className="contact-details">
          {renderLabel('Executive Assistant Name', 'assistant_name')}
          {
            editingField === 'assistant_name' ?
              <div className='d-flex'>
                <InputField
                  ref={register}
                  className='contact-view-value'
                  label=''
                  InputLabelProps={{ focused: true }}
                  name='assistant_name'
                />
                {renderActions('assistant_name')}
              </div>
              :
              <div className="contact-view-value">{contact.assistant_name || '--'}</div>
          }
        </div>
        <div className="contact-details">
          {renderLabel('Executive Direct Line', 'assistant_direct_line')}
          {
            editingField === 'assistant_direct_line' ?
              <div className='d-flex'>
                <div className='contact-view-value'>
                  <ReactPhoneInput
                    ref={register('assistant_direct_line')}
                    value={watch('assistant_direct_line') || ''}
                    onChange={(data, c, e, value) => {
                      if (data.length > 1) {
                        setValue('assistant_direct_line', value)
                      }
                      else {
                        setValue('assistant_direct_line', '')
                      }
                    }}
                    enableSearch={true}
                    disableInitialCountryGuess={determineInitialCountryGuess(defaultValues.assistant_direct_line)}
                    component={TextField}
                  />
                </div>
                {renderActions('assistant_direct_line')}
              </div>
              :
              <div className="contact-view-value">{contact.assistant_direct_line || '--'}</div>
          }
        </div>
      </div>
      <div className="d-flex contact-details-row">
        <div className="contact-details">
          {renderLabel('Executive Mobile', 'assistant_mobile_phone')}
          {
            editingField === 'assistant_mobile_phone' ?
              <div className='d-flex'>
                <div className='contact-view-value'>
                  <ReactPhoneInput
                    ref={register('assistant_mobile_phone')}
                    value={watch('assistant_mobile_phone') || ''}
                    onChange={(data, c, e, value) => {
                      if (data.length > 1) {
                        setValue('assistant_mobile_phone', value)
                      }
                      else {
                        setValue('assistant_mobile_phone', '')
                      }
                    }}
                    enableSearch={true}
                    disableInitialCountryGuess={determineInitialCountryGuess(defaultValues.assistant_mobile_phone)}
                    component={TextField}
                  />
                </div>
                {renderActions('assistant_mobile_phone')}
              </div>
              :
              <div className="contact-view-value">{contact.assistant_mobile_phone || '--'}</div>
          }
        </div>
        <div className="contact-details">
          {renderLabel('Executive Work Email', 'assistant_email')}
          {
            editingField === 'assistant_email' ?
              <div className='d-flex'>
                <InputField
                  ref={register}
                  type="email"
                  className='contact-view-value'
                  label=''
                  InputLabelProps={{ focused: true }}
                  name='assistant_email'
                />
                {renderActions('assistant_email')}
              </div>
              :
              <div className="contact-view-value">{contact.assistant_email || '--'}</div>
          }
        </div>
      </div>
      <div className="d-flex contact-details-row">
        <div className="contact-details">
          {renderLabel('BD Target', 'bd_target')}
          {
            editingField === 'bd_target' ?
              <div className='d-flex'>
                <UserSelection
                  option={option}
                  value={watch('bd_target') || ''}
                  className='contact-view-value'
                  label=''
                  InputLabelProps={{ focused: true }}
                  onChange={(e, data) => { setValue('bd_target', data) }}
                />
                {renderActions('bd_target')}
              </div>
              :
              <div className="contact-view-value">{contact.bd_target ? contact.bd_target.name : '--'}</div>
          }
        </div>
        <div className="contact-details">
          {renderLabel('Relationship', 'relationship')}
          {
            editingField === 'relationship' ?
              <div className='d-flex'>
                <InputField
                  ref={register}
                  className='contact-view-value'
                  label=''
                  InputLabelProps={{ focused: true }}
                  name='relationship'
                />
                {renderActions('relationship')}
              </div>
              :
              <div className="contact-view-value">{contact.relationship || '--'}</div>
          }
        </div>
      </div>
      <div className="d-flex contact-details-row">
        <div className="contact-details">
          {renderLabel('KG 25', 'kg')}
          {
            editingField === 'kg' ?
              <div className='d-flex'>
                <UserSelection
                  option={option}
                  multiple
                  InputLabelProps={{ focused: true, shrink: true }}
                  filterSelectedOptions={true}
                  value={watch('kg') || []}
                  className='contact-view-value'
                  label=''
                  name='kg'
                  onChange={(e, data) => {
                    setValue('kg', Array.isArray(data) ? data : [])
                  }}
                />
                {renderActions('kg')}
              </div>
              :
              <div className="contact-view-value">{contact?.kg?.length > 0 ? showUsers() : '--'}</div>
          }
        </div>
      </div>
    </div>
  )
}
CommunicationView.propTypes = {
  contact: PropTypes.object,
  renderLabel: PropTypes.func,
  editingField: PropTypes.string,
  setValue: PropTypes.func,
  register: PropTypes.func,
  renderActions: PropTypes.func,
  watch: PropTypes.func,
  defaultValues: PropTypes.object,
  userData: PropTypes.object,
  handleRichTextSave: PropTypes.func,
  saveStatus: PropTypes.bool,
  individualFieldSave: PropTypes.func,
  option: PropTypes.array,
  handleMouseEnter: PropTypes.func,
  handleMouseLeave: PropTypes.func,
}