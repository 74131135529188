//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useRef, useState } from 'react'

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from "prop-types";
import { withStyles } from '@material-ui/core/styles';
import { FormControlLabel, Step, Stepper } from '@material-ui/core';

import Checkbox from '@material-ui/core/Checkbox';
import StepConnector from '@material-ui/core/StepConnector';
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import DecisionMatrixChart from '../../../../DecisionMatrixChart';
import { useEffect } from 'react';
import { sfpaDecisionApi } from '../../../../../services/ApiService';
import { ERROR, GET, POST } from '../../../../../services/constantService';
import { range } from '../../../../../utils/common';
import { unableMessage } from '../../../../../services/MessageService';
import { useSnackbar } from 'notistack';
import Loader from '../../../../common/Loader';
import { useDispatch } from 'react-redux';
import { searchActions } from '../../../../../store/searchSlice';
//----------------------------------------------// Internal Imports // -------------------------------------------------


function Decisions(props) {

  const ColorlibConnector = withStyles({

    alternativeLabel: {
      top: 41,
      right: 3,
      left: -17,
      width: 35
    },
    line: {
      height: 3,
      border: 0,
      backgroundColor: '#c3c3c35c',
      borderRadius: 1,
    },
  })(StepConnector);

  const { searchInfo, currentValues, readOnly } = props;

  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState([]);
  const [inputValues, setInputValues] = useState({})
  const [graphItem, setGraphItem] = useState()
  const [coordinatesId, setCoordinatesId] = useState()
  const [instruction, setInstruction] = useState(null)
  const decisionGraphRef = useRef()
  const dispatch = useDispatch()
  const fetchDecisionData = async (isUpdate = false) => {
    setLoading(true);
    if (searchInfo && searchInfo.id) {
      const { status, data } = await sfpaDecisionApi(GET, searchInfo.id, {}, 'get-all');

      if (status && status === 200) {
        if (data) {
          let inputValues = {}
          data.map((ele, index) => {
            ele.sfpa_decision_questions.map((item, i) => {
              //const arrayValue = range(2, 10, 2)
              let arrayValue = []
              if (item.yes_no) {
                arrayValue = [0, 1]
              } else {
                arrayValue = range(item.low_value_number, item.high_value_number, item.step_size)
              }
              item.rangeOfValue = arrayValue
              let itemValues = {}
              if (index === 0) {
                itemValues[`item_${i + 1}`] = item.search_sfpa_decisions && item.search_sfpa_decisions.answer_value ? item.search_sfpa_decisions.answer_value : 0
              } else if (index === 1) {
                const totalLength = data[index - 1].sfpa_decision_questions.length
                itemValues[`item_${totalLength + i + 1}`] = item.search_sfpa_decisions && item.search_sfpa_decisions.answer_value ? item.search_sfpa_decisions.answer_value : 0
              }
              inputValues = { ...inputValues, ...itemValues }
            })
          })
          setInputValues(inputValues)
          setSelected(data)
        }
      } else {
        //enqueueSnackbar(VALIDATION_MESSAGE.review_contact_fail, { variant: ERROR });
      }
    }
    if (isUpdate === false) {
      setLoading(false);
    }
  }

  const handleSave = async (payload) => {
    setLoading(true);
    const { status, data } = await sfpaDecisionApi(POST, '', payload, 'decision-answer/addOrUpdate');
    if (status === 200) {
      const isUpdate = true
      fetchDecisionData(isUpdate)
    } else if (status !== 200) {
      const message = unableMessage("Decision", "update")
      enqueueSnackbar(data?.message || message, { variant: ERROR });
    }
    setTimeout(() => { setLoading(false); }, 1000)
  }

  useEffect(() => {
    fetchDecisionData()
  }, [searchInfo])

  useEffect(() => {
    if (searchInfo?.id) {
      const item = {
        centerStyles: {
          width: "900px",
          // height: "600px",
          margin: "auto",
          marginTop: "0px"
        },
        input_values: inputValues,
        initialCoords: searchInfo?.search_sfpa_client_decisions, 
        sfpa: true,
      }
      setGraphItem(item)
    }
  }, [inputValues])

  const handleChange = async (index, i, value) => {
    const newData = [...selected]
    const newItem = graphItem
    if (index === 0) {
      newItem.input_values[`item_${i + 1}`] = value
    } else if (index === 1) {
      const totalLength = newData[index - 1].sfpa_decision_questions.length
      newItem.input_values[`item_${totalLength + i + 1}`] = value
    }
    let payload = {};
    if (newData[index].sfpa_decision_questions[i].search_sfpa_decisions === null) {
      payload.answer_value = value
      payload.yes_no = 'no'
      payload.search_id = searchInfo.id
      payload.sfpa_decision_question_id = newData[index].sfpa_decision_questions[i].id
      newData[index].sfpa_decision_questions[i].search_sfpa_decisions = {}
      newData[index].sfpa_decision_questions[i].search_sfpa_decisions.answer_value = value
    } else {
      payload.answer_value = value
      payload.yes_no = 'no'
      payload.search_id = searchInfo.id
      payload.sfpa_decision_question_id = newData[index].sfpa_decision_questions[i].id
      payload.id = newData[index].sfpa_decision_questions[i].search_sfpa_decisions.id
      newData[index].sfpa_decision_questions[i].search_sfpa_decisions.answer_value = value
    }

    setSelected(newData)
    setGraphItem(newItem)

    handleSave(payload)
    decisionGraphRef.current.resetCoordinates()
    setTimeout(() => {
      const Coordinates = decisionGraphRef.current.BoxCoordinates()
      BoxCoordinates(Coordinates)
    }, 1000)
  };

  const BoxCoordinates = async (boxCoordinates) => {
    const payload = {
      search_id: searchInfo.id,
      x_center: boxCoordinates.x,
      y_center: boxCoordinates.y,
    }
    if (currentValues.search_sfpa_client_decisions) {
      payload.id = currentValues.search_sfpa_client_decisions && currentValues.search_sfpa_client_decisions.id ? currentValues.search_sfpa_client_decisions.id : null
    } else if (coordinatesId) {
      payload.id = coordinatesId
    }

    setLoading(true);
    const { status, data } = await sfpaDecisionApi(POST, '', payload, 'decision-coordinate/addOrUpdate');
    if (status === 201 && data) {
      currentValues.search_sfpa_client_decisions = data
      setCoordinatesId(data.id)
    } else if (status !== 200) {
      const message = unableMessage("Decision", "update")
      enqueueSnackbar(data?.message || message, { variant: ERROR });
    }
    setTimeout(() => { setLoading(false); }, 1000)
    // It impacts on update latest search info tab data 
    dispatch(searchActions.resetSearchState())
  }

  return (
    <React.Fragment>

      {instruction && <div dangerouslySetInnerHTML={{__html: instruction.decision_instruction}} className='decision-instruction'></div>}
      <div className='d-flex col-12'>
        <Loader show={loading} />
        <div className='d-flex flex-wrap col-6 px-0' id='decision'>
          {selected.map((item, index) =>
            <div className="team-information-table d-flex flex-column px-0" key={index}>
              <div className={`d-flex table-header decision-container`}>
                {item.show_title && <span>{item.section_title}</span>}
              </div>
              <div className={`d-flex flex-column p-0 ${index === 1 ? "decision-sub" : "decision-sub-container"}`} style={{ background: `rgba(244, 244, 245, 1)` }}>
                {item && item.sfpa_decision_questions && item.sfpa_decision_questions.map((ele, i) => <div key={i} className={"p-3" + ((index === 0) ? ' text-left ' : '')}>
                  <div className='font-weight-bold'>
                    {ele.question_text}
                  </div>
                  <div className='d-flex py-2 px-1 justify-content-center align-items-center col-12' id='step'>
                    <div className='d-flex justify-content-center align-items-center col-3'>
                      {ele.show_labels ? ele.low_value_label : ''}
                    </div>
                    <div className='col-6 d-flex justify-content-center'>
                      <Stepper alternativeLabel activeStep={ele.rangeOfValue.length} connector={<ColorlibConnector />}>
                        {ele.rangeOfValue.map((label) => (
                          <Step className='d-flex justify-content-center' key={index} onClick={() => !readOnly && handleChange(index, i, label)}>
                            <FormControlLabel
                              className='MuiFormControlLabel-labelPlacementTop'
                              disabled={readOnly}
                              control={<Checkbox
                                value={label}
                                checked={ele.search_sfpa_decisions && ele.search_sfpa_decisions.answer_value === label}
                                icon={<CircleUnchecked />}
                                checkedIcon={<RadioButtonCheckedIcon fontSize="small" />}
                              // onChange={() => handleChange(index)}
                              />}
                              label={ele.yes_no ? (label === 0 ? 'No' : 'Yes') : label}
                            />
                          </Step>
                        ))}
                      </Stepper>
                    </div>
                    <div className='d-flex justify-content-center align-items-center col-3'>
                      {ele.show_labels ? ele.high_value_label : ''}
                    </div>
                  </div>
                </div>)}
              </div>
            </div>
          )}
        </div>
        <div className='col-6'>
          {graphItem && <div className='decision-box m-0 p-0'>
            <DecisionMatrixChart item={graphItem} ref={decisionGraphRef} setInstruction={setInstruction} />
          </div>}
          {/* <div >
            <div className='mx-auto'>
              <InputLabel className='page-label text-center'>Visionary</InputLabel>
              <div className='text-center'>
                <p>Marches toward a future that others might not believe in or understand; has the conviction to be held accountable for decisions that other organizations or leaders wouldn’t make</p>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </React.Fragment >
  )
}

Decisions.propTypes = {
  register: PropTypes.func,
  setValue: PropTypes.func,
  currentValues: PropTypes.object,
  readOnly: PropTypes.bool,
  searchInfo: PropTypes.object,
  setSfpaData: PropTypes.func,
  setCurrentValues: PropTypes.func,
}

export default Decisions;
