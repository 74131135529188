//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { Component } from "react";
import PropTypes from "prop-types";

//----------------------------------------------// External Imports // -------------------------------------------------
import { Typography } from "@material-ui/core";
import { AgGridReact } from "ag-grid-react";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import { withSnackbar } from "notistack";
import { Link } from "react-router-dom";

//----------------------------------------------// Internal Imports // -------------------------------------------------
import {
  DateRenderer,
  DateTimeRenderer,
  EmailRenderer, LinkedInRenderer, LinksRenderer,
  loadColumnStateFromLocalStorage,
  saveColumnStateToLocalStorage
} from "../../Containers/Commons/Utils";
import {
  CustomLoadingOverlayComponent,
  defaultColumns,
  IdRenderer,
  PAGE_LIMIT, renderActiveCount, renderTargetCount,
  SUCCESS_STATUS_CODE
} from "../../Containers/Searches/Utils";

import { CompanyNameRenderer } from "../../Containers/Companies/utils";
import { SCROLL_TIMEOUT } from "../../utils/common";
import CustomFilter from "../../Containers/Commons/CustomFilter";
import ColumnFilter from "../../Containers/Commons/ColumnFilter";
import { searchColumnDefs } from "./utils";
import { masterSearchApi } from "../../services/ApiService";

class Searches extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isGridReady: false,
      rowCount: 0
    }
  }

  componentWillUnmount() {
    const columnApi = this.columnApi;
    saveColumnStateToLocalStorage('masterSearchSearchesColumns', { columnApi })
  }

  componentDidUpdate(prevProps) {
    if (this.props.searchKey !== prevProps.searchKey) {
      this.gridApi && this.gridApi.onFilterChanged()
    }
  }

  dataSource = {
    getRows: async (params) => {
      this.gridApi.showLoadingOverlay();
      const value = encodeURIComponent(this.props.searchKey)
      let sub_route = `searches?limit=${PAGE_LIMIT}&page=${params.endRow / PAGE_LIMIT}&searchValue=${value}`
      if (params.sortModel.length > 0) {
        sub_route = sub_route.concat(`&sortOn=${params.sortModel[0].colId}&sortType=${params.sortModel[0].sort.toUpperCase()}`)
      }
      sub_route = `${sub_route}&mastersearch=true`
      const { status, data } = await masterSearchApi(sub_route)
      if (status === SUCCESS_STATUS_CODE) {
        params.successCallback(data.data, data.paging.totalCount)
        this.setState({ rowCount: data.paging.showTotal ? data.paging.showTotal : data.paging.totalCount })
        this.props.setSearchesCount(data.paging.showTotal ? data.paging.showTotal : data.paging.totalCount)
      }
      else {
        params.failCallback();
      }
      this.gridApi.hideOverlay()
    },
    rowCount: null
  };

  NameRenderer = (params) => {
    return <CompanyNameRenderer company={params?.data?.company} />
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
    const columnApi = params.columnApi
    params.columnApi.autoSizeAllColumns();
    loadColumnStateFromLocalStorage('masterSearchSearchesColumns', { columnApi })
    this.setState({ isGridReady: true });
  };

  LinkedInRenderer = (params) => {
    return (
      <LinkedInRenderer params={params} linkedInPopup={this.linkedInPopupHandler} />
    )
  }

  render() {
    const { height = '100%', minHeight = 180 } = this.props
    return (
      <div className="list-contacts d-flex flex-column mt-4" style={{ height: this.state.rowCount ? height : minHeight }}>
        <div className="list-header d-flex justify-content-between">
          <Typography className='table-header-text' style={{ paddingLeft: '1.5%' }}><ZoomInIcon style={{ fill: '#DE9F6D' }} />Searches</Typography>
          <Typography className='total-count' style={{ paddingRight: '1.5%' }}>Total count: {this.state.rowCount}</Typography>
        </div>
        <div className="list-view flex-grow-1">
          {this.state.isGridReady && <ColumnFilter columnApi={this.columnApi} gridApi={this.gridApi} defaultColumns={defaultColumns} />}
          <div id="myGrid" className="ag-theme-alpine">
            <AgGridReact
              onGridReady={this.onGridReady}
              enableBrowserTooltips={true}
              defaultColDef={{
                minWidth: 100,
                resizable: true,
                sortable: true,
                sortingOrder: ['asc', 'desc', null]
              }}
              blockLoadDebounceMillis={SCROLL_TIMEOUT}
              cacheBlockSize={PAGE_LIMIT}
              loadingOverlayComponent={'CustomLoadingOverlayComponent'}
              frameworkComponents={{
                CustomLoadingOverlayComponent,
                EmailRenderer,
                LinkedInRenderer: this.LinkedInRenderer,
                LinksRenderer,
                IdRenderer,
                DateTimeRenderer,
                NameRenderer: this.NameRenderer,
                DateRenderer,
                renderActiveCount,
                renderTargetCount,
                CustomFilter
              }}
              suppressMenuHide={true}
              scrollbarWidth={12}
              suppressHorizontalScroll={false}
              rowModelType={'infinite'}
              datasource={this.dataSource}
              columnDefs={searchColumnDefs}
              paginationPageSize={PAGE_LIMIT}
              rowSelection={'multiple'}
              suppressRowClickSelection={true}
              suppressDragLeaveHidesColumns={true}
              onDisplayedColumnsChanged={this.saveColumnState}
              onDragStopped={this.saveColumnState}
            >
            </AgGridReact>
            <Link to={{
              pathname: `/searches/my-searches`,
              state: this.props.searchKey
            }} style={{ fontSize: '18px', float: 'right' }} className="MuiTypography-colorPrimary mt-2">More Records&gt; </Link>
          </div>
        </div>
      </div >
    )
  }
}

Searches.propTypes = {
  searchKey: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  rowHeight: PropTypes.number,
  minHeight: PropTypes.number,
  setSearchesCount: PropTypes.func.isRequired
}

export default withSnackbar(Searches)
