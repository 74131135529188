//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, { useEffect, useState } from 'react'

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from 'prop-types'
import Popup from 'reactjs-popup'
import { withSnackbar } from 'notistack';
import { useForm } from "react-hook-form";

//----------------------------------------------// Internal Imports // -------------------------------------------------
import './index.scss'
import EditDocumentTypePopup from './EditDocumentTypePopup';
import ViewDocumentTypePopup from './ViewDocumentTypePopup';

function DocumentType(props) {
  const { onClose, documentTypeData, setDocumentTypeData } = props;
  const [isEditing, setIsEditing] = useState(false);
  const [localDocumentType] = useState(documentTypeData);
  const [isDataUpdated, setIsDataUpdated] = useState(false);
  const [defaultValues, setDefaultValues] = useState({});

  const { register, reset } = useForm({ defaultValues });

  const isFormInEditMode = () => {
    setIsEditing(true)
  }

  useEffect(() => {
    reset(defaultValues)
  }, [reset, defaultValues])

  useEffect(() => {
    setDefaultValues({
      org_id: localDocumentType.org_id,
      doctype_code: localDocumentType.doctype_code,
      doctype_desc: localDocumentType.doctype_desc,
      allow_extensions: localDocumentType.allow_extensions,
      upload_role: localDocumentType.upload_role,
      delete_role: localDocumentType.delete_role,
      view_role: localDocumentType.view_role,
      download_role: localDocumentType.download_role,
    })
  }, [localDocumentType, setDefaultValues])

  useEffect(() => {
    register({ name: "org_id" });
    register({ name: 'doctype_code' });
    register({ name: "doctype_desc" });
    register({ name: "allow_extensions" });
    register({ name: "upload_role" });
    register({ name: "delete_role" });
    register({ name: "view_role" });
    register({ name: "download_role" });
  }, [register]);

  const onPopupClose = () => {
    onClose(isDataUpdated);
  };
  return (
    <Popup open={props.visible} modal onClose={onPopupClose} className={"add-document-value"} closeOnDocumentClick={false} closeOnEscape={false}>
      {isEditing ? <EditDocumentTypePopup documentTypeData={documentTypeData} closePopup={onPopupClose} setIsEditing={setIsEditing} setDocumentTypeData={setDocumentTypeData} setIsDataUpdated={setIsDataUpdated} /> : <ViewDocumentTypePopup documentTypeData={documentTypeData} editForm={isFormInEditMode} closePopup={onPopupClose} />}
    </Popup>
  )
}

export default withSnackbar(DocumentType)

DocumentType.propTypes = {
  onClose: PropTypes.func,
  documentTypeData: PropTypes.object,
  visible: PropTypes.bool,
  enqueueSnackbar: PropTypes.func,
  closeSnackbar: PropTypes.func,
  setDocumentTypeData: PropTypes.func
};