
//-----------------------------------------------------------// In-built Imports // ------------------------------

import React, { useEffect, useState } from "react";

//-----------------------------------------------------------// External Imports // ------------------------------

import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";

//-----------------------------------------------------------// Internal Imports // ------------------------------

import ProfileDetailsEdit from "./components/ProfileDetailsEdit";
import CommunicationDetialsEdit from "./components/CommunicationEdit";
import IndustryAndJobFunctionEdit from "./components/IndustryAndJobFunctionEdit";
import EmploymentHistoryEdit from "./components/EmploymentHistoryEdit";
import BoardDetailsEdit from "./components/BoardDetailsEdit";
import EducationDetailsEdit from "./components/EducationDetailsEdit";
// import PartnerInput from "./components/PartnerInput";
import { rightNav } from "../../../Containers/Commons/styleUtils";
import { fetchUserList } from "../../../actions";
import LanguageDetailsEdit from "./components/LangugaeDetailsEdit";
export default function Edit(props) {
  const { defaultValues, register, watch, setValue, unregister, reset, control, handelScroll } = props;
  const users = useSelector(state => state.rootReducer.users)
  const dispatch = useDispatch()
  const [options, setOptions] = useState([]);
  useEffect(() => {
    if (!users) {
      dispatch(fetchUserList('users'))
    }
    else {
      setOptions(users);
    }
  }, [users, dispatch])
  return (
    <div className="input-form d-flex">
      <div className="tab-view-content col-sm-9 mt-2 ">
        <div id='profile'>
          <ProfileDetailsEdit
            option={options}
            register={register}
            defaultValues={defaultValues}
            watch={watch}
            setValue={setValue}
            unregister={unregister}
            reset={reset}
          />
        </div>
        <div id='communication'>
          <CommunicationDetialsEdit
            option={options}
            register={register}
            defaultValues={defaultValues}
            watch={watch}
            setValue={setValue}
            control={control}
            unregister={unregister}
            reset={reset}
          />
        </div>
        <div id='industryAndFunction'>
          <IndustryAndJobFunctionEdit
            register={register}
            defaultValues={defaultValues}
            watch={watch}
            setValue={setValue}
            control={control}
            unregister={unregister}
            reset={reset}
          />
        </div>
        <div id='jobExperience'>
          <EmploymentHistoryEdit
            register={register}
            defaultValues={defaultValues}
            watch={watch}
            setValue={setValue}
            control={control}
            unregister={unregister}
            reset={reset}
          />
        </div>
        <div>
          <BoardDetailsEdit
            register={register}
            defaultValues={defaultValues}
            watch={watch}
            control={control}
            setValue={setValue}
            unregister={unregister}
            reset={reset}
          />
        </div>
        <div id='education'>
          <EducationDetailsEdit
            register={register}
            defaultValues={defaultValues}
            watch={watch}
            setValue={setValue}
            control={control}
            unregister={unregister}
            reset={reset}
          />
        </div>
        <div id='languages'>
          <LanguageDetailsEdit
            register={register}
            defaultValues={defaultValues}
            watch={watch}
            control={control}
            setValue={setValue}
            unregister={unregister}
            reset={reset}
          />
        </div>
        {/* <div>
          <PartnerInput
            register={register}
            watch={watch}
            setValue={setValue}
            defaultValues={defaultValues}
            individualFieldSave={individualFieldSave}
            saveStatus={saveStatus}
          />
        </div> */}
      </div>
      <div className="tab-view-right-nav col-sm-3 mt-2" style={{ position: "absolute", right: 0 }}>
        <div className="right-nav-section right-nav-recent-activity-section d-flex flex-column">
          <a style={rightNav} href='/' className="right-nav-header" onClick={(e) => { e.preventDefault(); handelScroll('profile') }}>
            Profile
          </a>
          <a style={rightNav} href='/' className="right-nav-header" onClick={(e) => { e.preventDefault(); handelScroll('communication') }}>
            Communication
          </a>
          <a style={rightNav} href='/' className="right-nav-header" onClick={(e) => { e.preventDefault(); handelScroll('industryAndFunction') }}>
            Industry and Function
          </a>
          <a style={rightNav} href='/' className="right-nav-header" onClick={(e) => { e.preventDefault(); handelScroll('jobExperience') }}>
            Job Experience
          </a>
          <a style={rightNav} href='/' className="right-nav-header" onClick={(e) => { e.preventDefault(); handelScroll('education') }}>
            Education
          </a>
          <a style={rightNav} href='/' className="right-nav-header" onClick={(e) => { e.preventDefault(); handelScroll('languages') }}>
            Languages
          </a>
        </div>
      </div>
    </div>
  )
}


Edit.propTypes = {
  register: PropTypes.func,
  control: PropTypes.object,
  dynamicKey: PropTypes.string,
  watch: PropTypes.func,
  setValue: PropTypes.func,
  unregister: PropTypes.func,
  reset: PropTypes.func,
  defaultValues: PropTypes.object,
  individualFieldSave: PropTypes.func,
  saveStatus: PropTypes.bool,
  handelScroll: PropTypes.func
};
