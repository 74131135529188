import React from 'react';

//----------------------------------------------// External Imports // -------------------------------------------------
import CloseIcon from '@material-ui/icons/Close'
import { Helmet } from "react-helmet";
import EditIcon from "@material-ui/icons/Edit";
import { InputLabel,Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import './index.scss'

const ViewTranslatePopup = (props) => {
  const { editForm ,translateData , closePopup} = props;
  return(
    <>
      <Helmet>
        <title>View Translate Value - KG Galaxy</title>
      </Helmet>
     
      <div >
        <div style={{ background: '#2a364c', color: '#fff' }}>
          <div className="quick-add-header">
            <Typography >View Translate Value</Typography>
            <CloseIcon className="cursor-pointer" onClick={closePopup} />
          </div>
        </div>
        <div className='d-flex flex-column py-3'>
        
          <div className='d-flex contact-details-row '>
            <div className='d-flex intersection-page-view'>
              <InputLabel className="page-label" >Organization ID</InputLabel>
              <span className="page-label-value">{translateData.org_id ? translateData.org_id : '--'}</span>
            </div>
            <div className='d-flex intersection-page-view'>
              <InputLabel  className="page-label">Field Name</InputLabel>
              <span className="page-label-value">{translateData.field_name ? translateData.field_name : '--'}</span>

            </div>
          </div>
          <div className='d-flex contact-details-row '>
            <div className='d-flex intersection-page-view'>
              <InputLabel className="page-label">Field Value</InputLabel>
              <span className="page-label-value">{translateData.field_value ? translateData.field_value : '--'}</span>
            </div>
            <div className='d-flex intersection-page-view'>
              <InputLabel  className="page-label">Language CD</InputLabel>
              <span className="page-label-value">{translateData.lang_cd ? translateData.lang_cd : '--'}</span>

            </div>
          </div>
          <div className='d-flex contact-details-row '>
            <div className='d-flex intersection-page-view'>
              <InputLabel className="page-label">Short Description</InputLabel>
              <span className="page-label-value">{translateData.short_desc ? translateData.short_desc : '--'}</span>
            </div>
            <div className='d-flex intersection-page-view'>
              <InputLabel  className="page-label">Long Description</InputLabel>
              <span className="long-description-container ">{translateData.long_desc ? translateData.long_desc : '--'}</span>

            </div>
          </div>
        </div>
        
        <div className="page-content-edit-footer">
          <div className="d-flex align-items-center action-edit" onClick={editForm}>
            <span className="section-icon">
              <EditIcon fontSize="inherit" />
            </span>
            <p className='edit-details'>Edit Details</p>
          </div>
        </div>
      </div>
    </>
  );
}

export default ViewTranslatePopup;

ViewTranslatePopup.propTypes = {
  defaultValues :PropTypes.object,
  editForm : PropTypes.bool,
  closePopup: PropTypes.func,
  translateData : PropTypes.object
}
  