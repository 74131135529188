//----------------------------------------------// In-built Imports // -------------------------------------------------
import React, {useEffect, useState} from "react";

//----------------------------------------------// External Imports // -------------------------------------------------
import PropTypes from "prop-types";
import {TextField} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import {useDispatch, useSelector} from "react-redux";

//----------------------------------------------// Internal Imports // -------------------------------------------------
import {fetchPickList} from "../../../actions";

function SecondaryDepartmentSelection(props){
  const {
    className='w-100',
    label="Secondary Department",
    placeholder="Secondary Department",
    InputLabelProps,
    ...rest
  } =props
  const [options, setOptions] = useState([])

  const secondaryDepartments = useSelector(state => state.commonReducer.secondaryDepartments)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!secondaryDepartments) {
      dispatch(fetchPickList('secondarydepartments', 'secondaryDepartments'))
    } else {
      setOptions(secondaryDepartments);
    }
  }, [secondaryDepartments, dispatch])

  return(
    <Autocomplete
      {...rest}
      className={className}
      options={options}
      getOptionLabel={option => option.name}
      getOptionSelected={(option, value) => option.name===value.name}
      renderInput={(params) => (
        <TextField
          {...params}
          InputLabelProps={InputLabelProps}
          label={label}
          placeholder={placeholder}
        />
      )}
    />
  )
}

SecondaryDepartmentSelection.propTypes = {
  register:PropTypes.func,
  className:PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  InputLabelProps:PropTypes.object,
}

export default SecondaryDepartmentSelection;
